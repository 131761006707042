/**
 * @param {object} theme
 */
const styles = theme => ({
    menu: {
        width: 250,
        boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.25)',
        borderRadius: 20
    },
    menuList: {
        paddingTop: 10,
        paddingBottom: 10
    },
    menuItem: {
        paddingLeft: 15,
        paddingRight: 15,
        '&:last-of-type': {
            marginBottom: 7
        }
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 5,
        padding: '20px 12px 15px 17px',
        color: theme.palette.text.secondary,
        boxShadow: 'inset 0px 1px 0px rgba(133, 146, 154, 0.5)'
    }
})

export default styles
