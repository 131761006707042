<template>
	<div v-if="!alert || (alert && alert.level === 'success')" class="mb-10">
		<w-alert
	        v-if="everythingDeleted"
	        level="success"
	        :message="(items && items.total === 0) ? l.t('app.everything-deleted', 'Everything is deleted') : l.t('app.selected-items-successfully-deleted', 'Selected items successfully deleted')"
	        :closable="false"
	        class="mb-3"
            data-test-id="nothing-found-everything-deleted"
		/>
		<div v-else-if="filters_applied === false" class="mt-3">
			{{ l.t('app.no-results-found', 'No results found') }}.
			<w-link v-if="!disable_add_new" :to="createRoute" data-test-id="nothing-found-add-new">
				{{ l.t('app.add-new-?', 'Add new?') }}
			</w-link>
		</div>
		<slot v-else name="emptyArray" class="mt-3">
			<div data-test-id="nothing-found-no-results">
				{{ filters_applied ? l.t('app.no-results-found-for-search', 'No results found matching your search') : l.t('app.no-results-found', 'No results found') }}.
			</div>
		</slot>
	</div>
</template>

<script>
	import l from '../../libs/lang';

	export default {
		props: ['everythingDeleted', 'alert', 'filters_applied', 'disable_add_new', 'create_route'],
		created() {
			if (this.$props.create_route) {
				this.$data.createRoute = this.$props.create_route;
			} else if (this.$route) {
				this.$data.createRoute = { name: `${this.$route.name.split('.')[0]}.create` };
			}
			this.$parent.$on('itemsLoaded', (items) => {
				this.$data.items = items;
                if (this.$router && this.$props.everythingDeleted && items.total === 0) {
                    setTimeout(() => {
                        this.$router.push(this.$data.createRoute);
                    }, 4000);
                }
            });
		},
		data() {
			return {
				l,
				createRoute: null,
				items: null
			};
		},
	};
</script>
