<template>
	<div>
		<FormInput :label="l.t('listeners.auto-response-message', 'Auto-response message')">
			<w-text-field
				v-model="config.parameters.message"
				:rules="[$validation.required()]"
				hide-details='auto'
				data-test-id="sms-autoresponder-mess-input"
			/>
		</FormInput>
		<FormInput :label="l.t('listeners.block-repeat', 'Block repeat response window [sec]')">
			<w-text-field
				v-model="config.parameters['rate-limit']"
				type="number"
				min="60"
				max="2592000"
				hide-details='auto'
				data-test-id="sms-autoresponder-rate-limit-input"
			/>
			<p class="mt-2" data-test-id="sms-autoresponder-help-text">
				{{ l.t('listener.rate-limit-explanation', 'To reduce autoresponse disruption, once an autoresponse is sent, the autoresponder will not repeat the message to the same number for the defined "Block repeat response window" time [defined in seconds].') }}
			</p>
		</FormInput>
	</div>
</template>

<script>
import l from '../../../libs/lang';
import FormInput from '../../elements/form/FormInput.vue';

export default {
	props: ['_config'],
	components: {
		FormInput,
	},
	data() {
		return {
			l,
			config: null,
			loading: false,
		};
	},
	created() {
		this.$data.config = this.$props._config;
	},
	methods: {
		prepareConfig(val) {
			for (const key of Object.keys(val.parameters)) {
				if (!['message', 'rate-limit'].includes(key)) {
					delete val.parameters[key];
				}
			}
			if (val.parameters['rate-limit']) {
				val.parameters['rate-limit'] = parseInt(val.parameters['rate-limit']);
			} else {
				delete val.parameters['rate-limit'];
			}
			return val;
		}
	},
	watch: {
		config: {
			deep: true,
			handler(val) {
				if (this.$data.loading) return null;
				this.$emit('changed', this.prepareConfig(val));

				return true;
			}
		},
	},
};

</script>
