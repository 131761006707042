import React, { useState, useEffect } from 'react'

import { createStyles, makeStyles, Theme } from '@material-ui/core'

import NumberSearchApp from 'embedded-custom-number-search'

import {
    Switch,
    Route,
    Redirect,
    useHistory
} from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        addANumberWrapper: {
            display: 'flex',
            flexDirection: 'column',
            fontSize: 15,
            fontWeight: 600,
            width: '100%',
            height: '100%',
            overflow: 'hidden'
        },
        selectPickerWrapper: {
            maxWidth: '960px',
            '@media (min-width:1500px)': {
                maxWidth: 'none',
                padding: '0px 75px'
            },
            margin: 'auto',
            marginTop: '50px',
            borderBottom: '1px solid #eee',
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                marginTop: '15px'
            }
        },
        pickerOption: {
            textAlign: 'center',
            padding: '5px 20px',
            display: 'inline-block',
            color: '#85929A',
            fontSize: '14px',
            cursor: 'pointer',
            '&:hover': {
                color: '#3FAE29'
            },
            [theme.breakpoints.down('sm')]: {
                margin: 'auto'
            },
            '&.loading': {
                cursor: 'wait',
                '&:hover': {
                    color: '#85929A'
                }
            }
        }
    })
)

const tabs = [
    { label: 'Local', searchMode: 'local_nonvanity', path: 'local' },
    { label: 'Toll free', searchMode: 'tollfree_nonvanity', path: 'toll-free' },
    { label: 'Personalized', searchMode: 'vanity', path: 'vanity' }
]

interface AddANumberProps {
    switchTab: (tab: string) => void
    numbersBasePath: string
}

/**
 * Allows the user to add additional number(s), by reserving them, then later checking out the order.
 * This presents the user with a tabbed interface organizing the numbers by number type.
 */
const AddANumber = ({ switchTab, numbersBasePath }: AddANumberProps): JSX.Element => {
    const classes = useStyles()

    const history = useHistory()

    const [showHeaders, setShowHeaders] = useState<boolean>(true)
    const [numberSearchLoading, setNumberSearchLoading] = useState<boolean>(true)

    const basePath = `${numbersBasePath}/add-numbers`
    const searchType = tabs.find(tab => `${basePath}/${tab.path}` === window.location.pathname)?.searchMode

    const changeSearchType = (searchType: string) => {
        const tab = tabs.find(t => t.searchMode === searchType)
        history.push(`${basePath}/${tab.path}`)
    }

    useEffect(() => {
        if (!searchType) {
            history.push(`${basePath}/local`)
        }
    }, [searchType])

    return (
        <div className={classes.addANumberWrapper}>
            {showHeaders && (<div style={{ padding: '0px 20px' }}>
                <div className={classes.selectPickerWrapper}>
                    {tabs.map(tab => (
                        <div
                            key={tab.searchMode}
                            className={`${classes.pickerOption} ${numberSearchLoading ? 'loading' : ''}`}
                            data-search-tab={tab.searchMode}
                            style={searchType === tab.searchMode
                                ? { borderBottom: '2px solid #48B9D5', color: 'black' }
                                : {}}
                            onClick={() => changeSearchType(tab.searchMode)}>
                            {tab.label}
                        </div>
                    ))}
                </div>
            </div>)}
            <Switch>
                <Route exact path={basePath}>
                    <Redirect to={`${basePath}/local`} />
                </Route>
                {tabs.map(tab => (
                    <Route key={tab.path} path={`${basePath}/${tab.path}`}>
                        <NumberSearchApp
                            setLoading={setNumberSearchLoading}
                            toggleHeaders={setShowHeaders}
                            switchTab={switchTab}
                            source={'control_panel'}
                            mode={tab.searchMode}
                        />
                    </Route>
                ))}
            </Switch>
        </div>
    )
}

export default AddANumber
