import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import {getPhoneCom} from 'phonecom'
class API {
	static get_user = async (voip_phone_id) => {
		console.log(voip_phone_id)
		let phonecom = await getPhoneCom()
		let requestUrl		= `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/unified/video/get-user`
		let phonecom_voip_phone_id = phonecom.voip_phone_id

		phonecom.voip_phone_id = voip_phone_id; // hack to stop extension_id param from being overridden
		return ajax.post(requestUrl, {account_id: phonecom.voip_id, extension_id: voip_phone_id}).then(response => {
			phonecom.voip_phone_id = phonecom_voip_phone_id //hack to set back extension_id
			return response.data
		})
	}
}

export default API