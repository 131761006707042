import l from '../lang';

export default {
	'routes': {
		'create': 'schedules.create',
		'show': 'schedules.show',
		'index': 'schedules.index',
	},
	'delete-single': l.t('schedules.delete-single', 'Are you sure you want to delete this schedule?'),
	'delete-filtered': l.t('schedules.delete-filtered', 'Are you sure you want to delete all filtered schedules?'),
	'delete-all': l.t('schedules.delete-all', 'Are you sure you want to delete all schedules?'),
	'successfull-creation': l.t('schedules.successfully-created', 'Your schedule has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('schedules.failed-creation', 'There was a problem creating your schedule'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
	'currently-in-use': l.t('schedules.this-schedule-is-currently-in-use', 'This schedule is currently in use'),
	'to-delete-used': l.t('schedules.to-delete-this-schedule-remove-it', 'To delete this schedule, remove it from your call handling rules first, then try again.'),
};
