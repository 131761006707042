import { parsePhoneNumber } from 'libphonenumber-js';

// Takes a string and attempts to parse it as a phone number
// Returns arg if not a phone number
export default function formatPhoneNumber(phoneNumber) {
    let ret;

    try {
        let parsedNum = parsePhoneNumber(phoneNumber);
        if (phoneNumber.includes('+1'))
            ret = parsedNum.formatNational();
        else
            ret = parsedNum.formatInternational();
    } catch (error) {
        ret = phoneNumber;
    }

    return ret;
};