/**
 * @param {object} theme
 */
const styles = theme => ({
    startNewButton: {
        backgroundColor: theme.palette.primary.backgroundColor,
        color: theme.messagesApp.startConversation.textColor,
        boxShadow: theme.palette.primary.flatBottomShadow,
        cursor: 'pointer',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: 1.05,
        letterSpacing: '-0.3px',
        padding: '5px 15px',
        display: 'flex',
        alignItems: 'center',
        minHeight: 60,
        transition: 'all .3s ease-in-out',
        background: 'white',
        zIndex: 3,
        borderWidth: 0
    },
    iconContainer: {
        marginRight: '10px',
        width: '43px'
    },
    startNewIcon: {
        width: '43px',
        height: '32px',
        objectFit: 'contain'
    }
})

export default styles
