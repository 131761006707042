import { combineReducers } from 'redux'
import { currentVoicemail, voicemails } from './voicemails'
import { smallView } from './view'
import { currentExtension, isVoicemailConfigured } from './pdcuser'

export default combineReducers({
	currentVoicemail,
	voicemails,
	smallView,
	currentExtension,
	isVoicemailConfigured
})