const styles = theme => ({
  drawerWrapper: {
    maxHeight: 'calc(100% - 30px)'
  },
  drawerStyle: {
    borderTopRightRadius:	20,
    borderTopLeftRadius:	20
  },
  wrapper: {
    display:	'flex',
    flexDirection:	'column',
    height:	'100%'
  },
  searchWrapper: {
    display:	'flex',
    flexDirection:	'column',
    alignItems:	'center',
    padding:	'78px 35px',
    '&.has-results': {
      paddingBottom: 0
    }
  },
  searchButton: {
    width:	187,
    height:	52,
    marginTop:	28
  },
  xButtonWrapper: {
    padding:	14,
    cursor:	'pointer',
    borderRadius:	'50%',
    position:	'absolute',
    top:	14,
    right:	14,
    '&:hover': {
      background: theme.palette.secondary[-400]
    }
  }
})

export default styles
