<template>
    <w-loader v-if="loading"/>
    <div v-else>
        <w-alert
            v-if="alert"
            :message="alert.message"
            :level="alert.level"
            class="mb-6"
        />
        <w-form v-if="subaccount" @changed="may_submit = true" @submit="submitted" data-test-id="subaccount-form">
            <FormInput :label="l.t('app.username', 'Username')">
                <w-text-field type="text" v-model="subaccount.username" :rules="[$validation.required()]" hide-details='auto' data-test-id="subaccount-username-input"/>
            </FormInput>
            <FormInput :label="l.t('app.password', 'Password')">
                <w-text-field type="password" v-model="subaccount.password" :rules="[$validation.required()]" class="fs-exclude" hide-details='auto' data-test-id="subaccount-password-input"/>
            </FormInput>
            <AccountContact :_contact="subaccount.contact" :title="l.t('app.contact-info', 'Contact info')" :key="`contact-${l.detect()}`" @changed="subaccount.contact = $event" data-test-id="subaccount-contact-data"/>

            <AccountContact :_contact="subaccount.billing_contact" :title="l.t('subaccounts.billing-contact-info', 'Billing contact info')" :key="`billing-${l.detect()}`" @changed="subaccount.billing_contact = $event" data-test-id="subaccount-billing-contact-data"/>
            <FormInput>
                <w-btn :disabled="!may_submit" type="submit" color="primary" data-test-id="subaccount-submit-btn">{{ l.t('app.save', 'Save') }}</w-btn>
            </FormInput>
        </w-form>
    </div>
</template>

<script>
import l from '../../libs/lang';
import FormInput from '../elements/form/FormInput.vue';
import AccountContact from '../elements/AccountContact.vue';

export default {
    props: ['_subaccount'],
    components: {
        FormInput,
        AccountContact,
    },
    data() {
        return {
            l,
            loading: null,
            alert: null,
            subaccount: null,
            may_submit: false,
        };
    },
    created() {
        this.$data.subaccount = this.$props._subaccount;
    },
    methods: {
        submitted(e) {
            if (e) e.preventDefault();
            this.$emit('submitted', this.$data.subaccount);
        }
    }
};
</script>
