<template>
	<div>
		<FormInput :label="l.t('applications-google.tid', 'Measurement ID / Web Property ID')">
			<w-text-field v-model="config.parameters.tracking_id" placeholder="XX-0000000-1" :required="true" hide-details='auto' data-test-id="ga-tracking-id"/>
		</FormInput>
		<SectionTitle>{{ l.t('app.basic', 'Basic') }}</SectionTitle>
		<ContentTable
			:parameters="parameters"
			type="basic"
			:dynamic_values="dynamic_values"
			@changed="parameters[$event.param]['value'] = $event.value"
		/>
		<SectionTitle @click="open_advanced = !open_advanced" class="cursor-pointer" data-test-id="ga-advanced-collapse">
      <v-icon color="info" :class="{'rotate-180': open_advanced}" small>$vuetify.icons.triangle</v-icon>
			{{ l.t('app.advanced', 'Advanced') }}
		</SectionTitle>
		<ContentTable
			v-if="open_advanced"
			:parameters="parameters"
			type="advanced"
			:dynamic_values="dynamic_values"
			@changed="parameters[$event.param]['value'] = $event.value"
			data-test-id="ga-advanced"
		/>
		<CustomParams
			:title="l.t('applications-google.custom-dimensions', 'Custom dimensions')"
			:_dynamic_values="dynamic_values"
			:_config ="config"
			level="custom-dimension"
			@changed="cds = $event"
			data-test-id="ga-custom-dimension"
		/>
		<CustomParams
			:title="l.t('applications-google.custom-measurements', 'Custom measurements')"
			:_dynamic_values="dynamic_values"
			:_config ="config"
			level="custom-measurement"
			@changed="cms = $event"
			data-test-id="ga-custom-measurement"
		/>
	</div>
</template>

<script>
import l from '../../../../libs/lang';
import TableData from './data.vue';
import ContentTable from './table.vue';
import CustomParams from './custom-params.vue';
import FormInput from '../../../elements/form/FormInput.vue';
import SectionTitle from '../../../elements/SectionTitle.vue';

const dynamic_values = {
  call_duration: {
    name: l.t('applications-google.call-duration', 'Call duration'),
    explanation: l.t('applications-google.call-duration-exp', 'duration of the call. It will work for call-log events only.'),
  },
  direction: {
    name: l.t('app.direction', 'Direction'),
    explanation: l.t('applications-google.direction-exp', 'direction of the call/message - in or out'),
  },
  direction_text: {
    name: l.t('applications-google.direction-text', 'Direction text'),
    explanation: l.t('applications-google.direction-text-exp', 'direction of the call/message - inbound or outbound.'),
  },
  phone_number: {
    name: l.t('app.phone-number', 'Phone number'),
    explanation: l.t('applications-google.phone-exp', 'your phone number or extension (for outbound calls)'),
  },
  other_phone_number: {
    name: l.t('applications-google.other-phone', 'Other phone number'),
    explanation: l.t('applications-google.other-phone-exp', 'phone number of the other side'),
  },
};

export default {
	props: ['_config'],
	components: {
		FormInput,
		TableData,
		ContentTable,
		CustomParams,
		SectionTitle,
	},
	data() {
		return {
			l,
			config: null,
			loading: false,
			dynamic_values,
			cds: [],
			cms: [],
			open_advanced: false,
			parameters: {
			  ec: {
			    name: l.t('applications-google.ec', 'Event Category'),
			    input: 'text',
			    maxlength: 150,
			    required: true,
			    value: null,
			    type: null,
			    level: 'basic',
			  },
			  ea: {
			    name: l.t('applications-google.ea', 'Event Action'),
			    input: 'text',
			    maxlength: 500,
			    required: true,
			    value: null,
			    type: null,
			    level: 'basic',
			  },
			  el: {
			    name: l.t('applications-google.el', 'Event Label'),
			    input: 'number',
			    min: 1,
			    maxlength: false,
			    value: null,
			    type: null,
			    level: 'basic',
			  },
			  ev: {
			    name: l.t('applications-google.ev', 'Event Value'),
			    input: 'number',
			    maxlength: false,
			    value: null,
			    type: null,
			    level: 'basic',
			    dynamic: ['call_duration'],
			  },
			  cid: {
			    name: l.t('applications-google.cid', 'Client ID'),
			    input: 'text',
			    maxlength: false,
			    value: null,
			    type: null,
			    level: 'advanced',
			  },
			  uid: {
			    name: l.t('applications-google.uid', 'User ID'),
			    input: 'number',
			    value: null,
			    type: null,
			    level: 'advanced',
			  },
			  dh: {
			    name: l.t('applications-google.dh', 'Document location URL'),
			    input: 'text',
			    maxlength: 2048,
			    value: null,
			    type: null,
			    level: 'advanced',
			  },
			  dl: {
			    name: l.t('applications-google.dl', 'Document Host Name'),
			    input: 'text',
			    maxlength: 100,
			    value: null,
			    type: null,
			    level: 'advanced',
			  },
			  ni: {
			    name: l.t('applications-google.ni', 'Non-Interaction Hit'),
			    input: 'boolean',
			    value: null,
			    type: null,
			    level: 'advanced',
			    static_only: true,
			  },
			  tid: {
			    name: l.t('applications-google.tid', 'Measurement ID / Web Property ID'),
			    input: 'text',
			    static_only: true,
			    value: null,
			    type: null,
			    level: 'advanced',
			  },
			},
		};
	},
	created() {
		this.$data.config = this.$props._config;
		this.checkApplicationParameters();
	},
	methods: {
		checkApplicationParameters() {
			this.$data.looading = true;
			for (const param of Object.keys(this.$data.parameters)) {
				for (const paramType of ['static', 'dynamic']) {
					// eslint-disable-next-line no-continue
					if (!this.$data.config.parameters[paramType]) continue;

					if (this.$data.config.parameters[paramType][param] || this.$data.config.parameters[paramType][param] === 0) {
						this.$data.parameters[param].type = paramType;
						this.$data.parameters[param]['value'] = this.$data.config.parameters[paramType][param];
						if (this.$data.parameters[param]['level'] === 'advanced') {
							this.$data.open_advanced = true;
						}
					}
				}
			}
			if (this.parameters.tid && !this.parameters.tid.value) {
				delete this.parameters.tid;
			}
			this.$data.looading = false;
		},
		prepareConfig() {
			const dynamic = Object.keys(this.$data.dynamic_values);
			this.$data.config.parameters.static = {};
			this.$data.config.parameters.dynamic = {};
			this.$data.config.application = 'google-analytics';

			if (this.parameters.tid && !this.parameters.tid.value) {
				delete this.parameters.tid;
			}
			for (const param of Object.keys(this.$data.parameters)) {
				if (this.$data.parameters[param]['value'] || this.$data.parameters[param]['value'] === 0) {
					if (dynamic.includes(this.$data.parameters[param]['value'])) {
						this.$data.config.parameters.dynamic[param] = this.$data.parameters[param]['value'];
					} else {
						this.$data.config.parameters.static[param] = this.$data.parameters[param]['value'];
					}
				}
			}
			for (const cd of this.$data.cds) {
				if (cd['value'] || cd['value'] === 0) {
					if (dynamic.includes(cd['value'])) {
						this.$data.config.parameters.dynamic[`cd${cd['index']}`] = cd['value'];
					} else {
						this.$data.config.parameters.static[`cd${cd['index']}`] = cd['value'];
					}
				}
			}
			for (const cm of this.$data.cms) {
				if (cm['value'] || cm['value'] === 0) {
					if (dynamic.includes(cm['value'])) {
						this.$data.config.parameters.dynamic[`cm${cm['index']}`] = cm['value'];
					} else {
						this.$data.config.parameters.static[`cm${cm['index']}`] = cm['value'];
					}
				}
			}
			if (Object.keys(this.$data.config.parameters.static).length === 0) {
				delete this.$data.config.parameters.static;
			}
			if (Object.keys(this.$data.config.parameters.dynamic).length === 0) {
				delete this.$data.config.parameters.dynamic;
			}
			return this.$data.config;
		},
	},
	watch: {
		parameters: {
			deep: true,
			handler() {
				if (this.$data.loading) return null;
				return this.$emit('changed', this.prepareConfig());
			},
		},
		cds: {
			deep: true,
			handler() {
				if (this.$data.loading) return null;
				return this.$emit('changed', this.prepareConfig());
			},
		},
		cms: {
			deep: true,
			handler() {
				if (this.$data.loading) return null;
				return this.$emit('changed', this.prepareConfig());
			},
		},
	},
};

</script>
