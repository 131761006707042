/**
 * sendAnalyticsEvent pushes the event to GA and FullStory for analytics reports
 *
 * @param {json} data The event data
 * @param {json} options
 *    @param {string} options.fullStory options to pass to sendFSEvent
 * @public
 */
export default function gtmDataPush (data, options = {}) { // NOTE in the future rename this and the gtm-events package to reflect that it does more than GTM stuff
    console.debug('sendAnalyticsEvent: ', data)
    sendGTMEvent(data)
    sendFSEvent(data, options.fullStory)
}

/**
 * sendGTMEvent pushes to Google Tag Manager (GTM)
 *
 * @param {json} data The event data
 *    @param {string} data.PDC_Action the events action ex: 'message-sent'
 *    @param {string} data.PDC_Label the events label ex: PDC_Label: 'type: ' + eventType
 * @public
 */
export function sendGTMEvent (data) {
    if (!window.dataLayer) return

    if (window.V5PHONECOM) {
        const voipId = parseInt(window.V5PHONECOM.voip_id)
        const voipPhoneId = parseInt(window.V5PHONECOM.voip_phone_id)
        let userId = parseInt(window.V5PHONECOM.user_id)
        if (isNaN(userId)) userId = null
        data.event = data.event || 'PDC_my_phone_event'
        data.PDC_voip_id = voipId // Set the voip id
        data.PDC_voip_phone_id = voipPhoneId // Set the extension id (voip_phone id)
        data.PDC_user_id = userId // Set the user id
        const PDC_Label = data.PDC_Label ? `${data.PDC_Label}, ` : ''
        data.PDC_Label = `${PDC_Label}features: (${Array.from(window.V5PHONECOM.features || []).join(';')})`
        data.PDC_Value = (data.PDC_Value || data.PDC_Value === 0) ? data.PDC_Value : null
    }
    // return console.log('#### GTM DATA PUSH:', data)
    window.dataLayer.push(data)
}

/**
 * sendFSEvent pushes to Full Story (FS)
 *
 * @param {json} data The event data
 * @param {json} options
 *    @param {string} options.namespace the FS namespace to use to send the event
 * @public
 */
export function sendFSEvent (data, options = {}) {
    const fsNamespace = options.namespace || window._fs_namespace || 'FS'
    if (fsNamespace in window) {
        const eventName = data.event || 'event'
        window[fsNamespace].event(eventName, {
            name: data.name || 'unnamed event', // required
            properties: data // required
        })
    }
}
