import React, { Component } from 'react'
import App from './src/components/App.js'

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers'
import StatementsComponent from './src/components/Statements/Statements'
import PropTypes from 'prop-types'

const store = createStore(rootReducer)

class Company extends Component {
    render = () => {
        return (
            <Provider store={store}>
                <App
                    extension={this.props.extension}
                    screenViewType={this.props.screenViewType}
                    standalone={this.props.standalone}
                    redirect={this.props.redirect}
                    setHasChange={this.props.setHasChange}
                    routeProps={this.props.routeProps}
                    isCSR={this.props.isCSR}
                />
            </Provider>
        )
    }
}

Company.propTypes = {
    isCSR: PropTypes.bool,
    classes: PropTypes.object,
    extension: PropTypes.object,
    screenViewType: PropTypes.func,
    standalone: PropTypes.bool,
    redirect: PropTypes.func,
    setHasChange: PropTypes.func,
    routeProps: PropTypes.object
}

export default Company

class Statements extends Component {
    render = () => {
        return (
            <Provider store={store}>
                <StatementsComponent />
            </Provider>
        )
    }
}

/**
 *
 */
export { Statements }
