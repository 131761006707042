<template>
    <div class="dont-export-in-pdf">
        <w-switch
            v-model="val"
            v-bind="$attrs"
            v-on="$listeners"
            :class="{'do-not-print': !val}"
            :label="l.t('reports.logarithmic-representation', 'Logarithmic representation')"
            hide-details='auto'
            data-test-id="geo-logatithmic-switch"
        />
    </div>
</template>

<script>
    import l from '../../libs/lang';

    export default {
        props: ['value'],
        data() {
          return {
            l,
            val: !!this.$props.value,
          };
        },
  };
</script>

<style scoped>

</style>
