import React, { Component } from 'react'
import iconRemoveEnabled from '../images/icon-remove-enabled.svg'
import iconRemoveHover from '../images/icon-remove-hover.svg'
import noteIcon from '../images/icon-note-pinned.svg'
import fileIcon from '../images/fax-papers.svg'
import PDCButton from 'pdc-button'
import { uploadSteps, progressThresholds, intervalSpeeds } from '../util/uploadUtil.js'
import Spinner from 'spinner'
import ProgressBar from 'progress-bar'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	fileItemWrapper: {
		display:		'flex',
		background:		'white',
		alignItems:		'center',
		padding:		'7px 15px',
		height:			60,
		border:			'1px solid transparent',
		cursor:			'pointer',
		'& .thumbnail': {
			maxWidth: '80px !important'
		},
		'& .file-icon-wrapper': {
			width:		40,
			height:		54,
			fontSize:	9.5
		},
		'&:hover': {
			border:			'1px solid',
			borderColor:	theme.palette.primary.main,
			borderRadius:	5
		},
		'&.dragged': {
			border:			'2px solid',
			borderColor:	theme.palette.primary.main,
			borderRadius:	5
		}
	},
	filename: {
		fontSize:		20,
		fontWeight:		600,
		color:			theme.palette.primary.main,
		marginRight:	20,
		flex:			1,
		whiteSpace:		'nowrap',
		overflow:		'hidden',
		textOverflow:	'ellipsis'
	},
	removeFile: {
		width:	30,
		cursor:	'pointer',
		'& .remove-icon': {
			margin: '0 2px'
		},
		'&:hover .remove-icon': {
			display: 'none'
		},
		'&:not(:hover) .remove-icon-hover': {
			display: 'none'
		}
	},
	numPages: {
		fontSize:		14,
		lineHeight:		1.36,
		color:			theme.faxesApp.newFaxPanel.pdfFilePagesColor,
		marginRight:	30
	},
	itemUploadingSection: {
		marginLeft:		20,
		marginRight:	20,
		flex:			1,
		minWidth:		0
	},
	progressBar: {
		width:			'100%',
		height:			9,
		borderRadius:	4.5,
		background:		theme.faxesApp.newFaxPanel.progressBar.backgroundColor,
		marginTop:		5,
		'& .progress-fill': {
			height:			'100%',
			width:			0,
			background:		theme.palette.primary.main,
			borderRadius:	4.5
		}
	},
	uploadingInfo: {
		display:		'flex',
		justifyContent:	'space-between',
		fontSize:	14
	},
	uploadStatus: {
		fontWeight:	600,
		lineHeight:	1.36,
		whiteSpace:	'nowrap'
	},
	uploadFilename: {
		lineHeight:		1.36,
		color:			theme.faxesApp.newFaxPanel.uploadFilenameColor,
		margin:			'0 10px',
		flex:			1,
		whiteSpace:		'nowrap',
		overflow:		'hidden',
		textOverflow:	'ellipsis'
	},
	uploadPercentage: {
		fontWeight:	'bold',
		lineHeight:	1.36
	},
	cancelButton: {
		'&:hover': {
			borderColor:	'gray',
			background:		'gray',
			color:			'white'
		}
	}
})

class FaxUploadItem extends Component {

	state = {progressWidthPercentage: 0, intervalPeriod: 5}

	componentDidMount() {
		this._mounted = true
	}

	componentWillUnmount() {
		this._mounted = false
	}

	getUploadStatus = step => {
		if (step === uploadSteps.UPLOAD_TO_BROWSER) return 'Loading'
		if (step === uploadSteps.CONVERT_TO_PDF) return 'Converting'
		// if ([uploadSteps.AUTHORIZE, uploadSteps.UPLOAD_TO_SERVER, uploadSteps.FINISHED].includes(step)) return 'Uploading'
		return 'Uploading'
	}

	onClick = e => {
		e.persist()
		let elementClasses = e.target.className.split(' ')
		if (elementClasses.includes('remove-icon-hover') || elementClasses.includes('remove-icon')) return
		this.props.onClick()
	}

	onUploadProgress = progressWidthPercentage => {
		this.setState({progressWidthPercentage})
	}

	render() {
		const { classes, isDragged, provided, file, index } = this.props
		return (
			<div
				className	= {`${classes.fileItemWrapper} ${isDragged ? 'dragged' : ''}`}
				onClick		= {this.onClick}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				ref={provided.innerRef}
			>
				{file.type === 'text/plain' || (file.uploadStep === uploadSteps.FINISHED && this.state.progressWidthPercentage === 100) ?
					<>
						<img src={file.type === 'text/plain' ? noteIcon : fileIcon} alt='File icon' />
						<span className={classes.filename} style={{marginLeft: file.type === 'text/plain' ? 31 : 40}} title={file.filename}>
							{file.filename || 'unnamed'}
						</span>
						{file.numPages ? <span className={classes.numPages}>{file.numPages} pg{file.numPages !== 1 ? 's' : ''}</span> : null}
						<span className={classes.removeFile} onClick={() => this.props.removeFile(index)}>
							{!isDragged ?
								<>
								<img className='remove-icon' src={iconRemoveEnabled} alt='Remove' />
								<img className='remove-icon-hover' src={iconRemoveHover} alt='Remove' />
								</>
							: null}
						</span>
					</>
				:
					<>
						<Spinner size={this.props.size}/>
						<div className={classes.itemUploadingSection}>
							<div className={classes.uploadingInfo}>
								<span className={classes.uploadStatus}>{this.getUploadStatus(file.uploadStep)}</span>
								<span className={classes.uploadFilename} title={file.filename}>{file.filename} {/*this.state.intervalPeriod*/}</span>
								<span className={classes.uploadPercentage}>{this.state.progressWidthPercentage.toFixed(0)}%</span>
							</div>
							<div className={classes.progressBar}>
								<ProgressBar
									progressThresholds	= {progressThresholds}
									intervalSpeeds		= {intervalSpeeds}
									currentStep			= {this.props.file.uploadStep}
									lastStep			= {uploadSteps.FINISHED}
									onProgress			= {this.onUploadProgress}
								>
									<div className='progress-fill'></div>
								</ProgressBar>
							</div>
						</div>
						<PDCButton
							className	= {classes.cancelButton}
							variant		= 'outlined'
							color		= 'tertiary'
							onClick		= {() => this.props.removeFile(index)}
						>
							Cancel
						</PDCButton>
					</>
				}
			</div>
		)
	}
}

export default withStyles(styles)(FaxUploadItem)