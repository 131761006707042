<template>
    <div data-test-id="reports-show-page">
        <SMS v-if="$route.params.type === 'sms'" data-test-id="reports-sms"/>
        <Types v-else-if="$route.params.type === 'types'" data-test-id="type"/>
        <Total v-else-if="$route.params.type === 'total'" data-test-id="total"/>
        <Traffic v-else-if="$route.params.type === 'traffic'" data-test-id="traffic"/>
        <Summary v-else-if="$route.params.type === 'summary'" data-test-id="summary"/>
        <PerNumber v-else-if="$route.params.type === 'per-number'" data-test-id="per-number"/>
        <PerExtension v-else-if="$route.params.type === 'per-extension'" data-test-id="per-extension"/>
        <GeoDistribution v-else-if="$route.params.type === 'geo-distribution'" data-test-id="geo-distribution"/>
    </div>
</template>

<script>
  import { 
    SMS,
    Types,
    Total,
    Traffic,
    Summary,
    PerNumber,
    PerExtension,
    GeoDistribution,
   } from 'reports-components';

  export default {
    components: {
      SMS,
      Types,
      Total,
      Traffic,
      Summary,
      PerNumber,
      PerExtension,
      GeoDistribution,
    },
  };
</script>
