<template>
    <w-loader v-if="contact.loading"/>
    <div v-else class="show-page" data-test-id="contacts-create-page">
        <w-alert
          v-if="contact.alert"
          :message="contact.alert.message"
          :level="contact.alert.level"
          :closable="contact.enable_close_alert"
          @closed="contact.alert = null"
          class="mb-6"
        />
        <ContactFragment
          v-if="contact.item"
          :_contact="contact.item"
          :extension_id="extension"
          @contactSubmited="contact.update($event)"
        />
    </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import Contact from '../../../models/Contact';
  import ContactFragment from '../../fragments/Contact.vue';

  export default {
    props: ['id', 'extension_id', '_contact'],
    components: {
      ContactFragment,
    },
    data() {
      return {
        contact: new Contact(this.$session, vueComponent(this)),
        extension: null,
      };
    },
    async created() {
      this.$data.extension = this.$props.extension_id ? this.$props.extension_id : this.$route.params.extension_id;
      const contact_id = this.$props.id ? this.$props.id : this.$route.params.id;
      await this.$data.contact.getContact(
        this.$data.extension,
        contact_id,
        this.$props._contact || this.$route.params._contact
      );
    },
  };
</script>
