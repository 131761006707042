<template>
	<div v-if="contact">
        <div v-if="title" class="info--text text--darken-1 w-subtitle-1 mb-5" data-test-id="acc-contact-title">
            {{ title }}
        </div>
		<w-text-field
			v-model="contact.name"
			:disabled="!!disabled"
			:rules="[$validation.required()]"
            :label="l.t('app.first-and-last-name', 'First and last name')"
            class="w-100 mb-5"
			hide-details='auto'
			data-test-id="acc-contact-name-input"
		/>
		<w-text-field
			v-model="contact.company"
			:disabled="!!disabled"
            :label="l.t('app.company-name-optional', 'Company name (optional)')"
            class="w-100 mb-5"
			hide-details='auto'
			data-test-id="acc-contact-company-input"
		></w-text-field>
        <AddressForm
            v-model="contact.address"
            :with_labels="true"
            :available_countries="countries"
            class="mb-5"
        />
		<div class="d-flex mb-5">
            <Tel
                :value="contact.phone"
                :disabled="!!disabled"
                :label="l.t('app.phone-number', 'Phone number')"
                @changed="contact.phone = $event.clean_number"
                :required="true"
                :validation="true"
                class="w-100 mr-5"
                data-test-id="acc-contact-phone-input"
            />
            <Tel
                :value="contact.fax"
                :disabled="!!disabled"
                @changed="contact.fax = $event.clean_number"
                :required="false"
                :label="l.t('app.fax-number-optional', 'Fax number (optional)')"
                class="w-100"
                data-test-id="acc-contact-fax-input"
            />
        </div>
		<w-text-field
			type="email"
			v-model="contact.primary_email"
			:disabled="!!disabled"
			:rules="[$validation.required(), $validation.email()]"
            :label="l.t('app.email-address', 'Email address')"
            class="w-100 mb-5"
			hide-details='auto'
			data-test-id="acc-contact-email-input"
		></w-text-field>
		<w-text-field
			type="email"
			v-model="contact.alternate_email"
			:disabled="!!disabled"
            :label="l.t('app.secondary-email-address-optional', 'Secondary email address (optional)')"
			:rules="[$validation.email()]"
            class="w-100"
			hide-details='auto'
			data-test-id="acc-contact-secondary-email-input"
		></w-text-field>
	</div>
</template>

<script>
import l from '../../libs/lang';
import Tel from './Tel.vue';
import FormInput from './form/FormInput.vue';
import SectionTitle from './SectionTitle.vue';
import AddressForm from './form/AddressForm.vue'

export default {
	props: ['_contact', 'title', 'address_only', 'disabled', 'submittable', 'countries'],
	components: {
		Tel,
		FormInput,
        AddressForm,
		SectionTitle,
	},
	created() {
		this.$data.contact = this.$props._contact;
	},
	data() {
		return {
			l,
			contact: null,
		};
	},
	watch: {
		contact: {
			deep: true,
			handler(value) {
				const val = JSON.parse(JSON.stringify(value));
				if (!val.address.postal_code) val.address.postal_code = null;
				if (!val.alternate_email) val.alternate_email = null;

				this.$emit('changed', val);
			},
		},
	},
};
</script>
