import React, { Component, useEffect } from 'react'
import { TextField as MuiTextField, InputAdornment, FilledTextFieldProps as MuiTextFieldProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import styles from './styles'
import { CloseIcon, SoftAlertIcon } from 'svg-icons'
import { ThemeProvider } from 'theme-provider'
import Typography from 'typography'
import { theme } from 'get-theme'
import { formatAsYouType, convertNumberToE164, isValidPhoneNumber } from 'phone-numbers'
import Chip from 'chip-mui'

const useStyles = makeStyles(styles)

/**
 * How should the provided value be a formatted and displayed.
 * If not provided it will be displayed as is.
 */
export enum FormatType {
    PhoneNumberUS,
    PhoneNumberUSChip,
    PhoneNumbersUSChips
}

interface TextFieldProps extends Omit<MuiTextFieldProps, 'variant'> {
    /**
     * Use this for the property named 'classes' in material ui
     */
    classNames?: { [key: string]: string }
    /**
     * Called when the x buttons is clicked
     */
    onXClick?: () => void
    /**
     * Material ui property
     * If `error` is true then a red alert triangle icon will be placed before whatever you pass as `helperText`
     */
    helperText?: string | React.Component
    /**
     * If true then when the text field has a value and is not focused it will have to label
     */
    noLabelOnActiveOrResolved: boolean
    /**
     * Passed from the Autocomplete component in order the label to have variant 'label'
     */
    hasFormValue?: boolean
    formatType?: FormatType
    inputRef?: React.RefObject<HTMLInputElement>
    value: string
    onClear?: (index: number) => void
    fixedChipValues?: string[]
}

const getHelperText = (props: TextFieldProps): string | Component | JSX.Element => {
    const { helperText, error } = props
    if (!helperText || !error) return helperText
    return <><SoftAlertIcon/><Typography variant='helperText'>{helperText}</Typography></>
}

const getValue = (props: TextFieldProps) => {
    const { formatType } = props
    let value: string = props.value
    if (formatType !== undefined) {
        if (formatType === FormatType.PhoneNumberUS || formatType === FormatType.PhoneNumberUSChip) {
            value = convertNumberToE164(value).substring(0, 12)
            value = formatAsYouType(value)
            if (value.length === 5) value = value.substring(0, 4)
        } else if (formatType === FormatType.PhoneNumbersUSChips) {
            const validValues: string[] = []
            const invalidValues: string[] = []
            value.split(',').forEach(e => isValidPhoneNumber(e.trim()) ? validValues.push(e) : invalidValues.push(e))
            value = [...validValues, ...invalidValues].map(v => {
                let formatted = formatAsYouType(convertNumberToE164(v).substring(0, 12))
                if (formatted.length === 5) formatted = formatted.substring(0, 4)
                return formatted
            }).join(',')
        }
    }
    return value
}

/**
 * TextField component
 *
 * @param {TextFieldProps} props - props
 */
export const TextField = (props: TextFieldProps): JSX.Element => {
    const { classNames, hasFormValue, disabled, error, fixedChipValues } = props
    let clickingX = false
    const classes = useStyles()
    const inputRef = props.inputRef || React.useRef<HTMLInputElement>()
    const [fieldFocused, setFieldFocused] = React.useState(false)
    const [showChip, setShowChip] = React.useState(false)
    const helperText = getHelperText(props)
    const customClasses = classNames || {}

    const showChipIfNeeded = () => {
        const hasChipFormat = [FormatType.PhoneNumberUSChip, FormatType.PhoneNumbersUSChips].includes(props.formatType)
        const shouldShowChip = hasChipFormat && !fieldFocused && (isValidPhoneNumber(convertNumberToE164(props.value)))
        setShowChip(shouldShowChip)
    }

    useEffect(showChipIfNeeded, [props.value, fieldFocused])

    /**
     * Set is selected / focused
     *
     * @param {boolean} fieldFocused - fieldFocused
     */
    const setIsSelected = (fieldFocused: boolean): void => {
        if (!fieldFocused && clickingX) return
        setFieldFocused(fieldFocused)
    }

    const onFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
        // eslint-disable-next-line no-unused-expressions
        props.onFocus?.(e)
        setIsSelected(true)
        setShowChip(false)
    }

    const onBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
        // eslint-disable-next-line no-unused-expressions
        props.onBlur?.(e)
        setIsSelected(false)
    }

    const value = getValue(props)
    const typographyVariant = fieldFocused || value || hasFormValue ? 'label' : 'inputText'
    const label = ((props.noLabelOnActiveOrResolved && (fieldFocused || value)) || !props.label) ? null : <Typography color={error ? null : fieldFocused ? 'inherit' : theme.palette.text.tertiary} variant={typographyVariant}>{props.label}</Typography>

    const getInputProps = () => {
        const hasLabelClass = label ? 'has-label' : ''
        const inputPropsProp: any = {
            classes: { root: hasLabelClass },
            endAdornment: (
                <InputAdornment
                    classes = {{ root: `adorned-end-x ${!fieldFocused ? 'empty' : ''}` }}
                    onMouseDown = {(): boolean => (clickingX = true)}
                    onMouseUp = {(): void => {
                        inputRef.current.focus()
                        if (fieldFocused) props.onXClick?.()
                        clickingX = false
                    }}
                    position = 'end'
                    data-test-id='text-field-x-button'
                ><CloseIcon/></InputAdornment>
            ),
            ...(props.InputProps || {})
        }
        if (showChip || fixedChipValues) {
            const onClear = (index: number) => {
                props.onClear?.(index)
                setShowChip(false)
            }
            const chipDataTestIdProp = props['chip-test-id'] ? { 'data-test-id': props['chip-test-id'] } : {}
            inputPropsProp.startAdornment = <div className={classes.chipsWrapper}>
                {fixedChipValues?.map((v, i) => (
                    <Chip { ...chipDataTestIdProp } key={i} disabled color='primary' label={v} />
                ))}
                {showChip && value.split(',').map((v, i) => (
                    <Chip { ...chipDataTestIdProp } key={i} onDoubleClick={() => setShowChip(false)} color='primary' label={v} onDelete={() => onClear(i)} />
                ))}
            </div>
            if (props.formatType === FormatType.PhoneNumberUSChip) inputPropsProp.disabled = true
        }
        if (props.InputProps) {
            if (props.InputProps.classes) {
                if (props.InputProps.classes.root) inputPropsProp.classes.root += ` ${props.InputProps.classes.root}`
                const otherClasses = { ...props.InputProps.classes }
                delete otherClasses.root
                Object.assign(inputPropsProp.classes, otherClasses)
            }
            const otherInputProps = { ...props.InputProps }
            delete otherInputProps.classes
            if (!otherInputProps.endAdornment) delete otherInputProps.endAdornment
            Object.assign(inputPropsProp, otherInputProps)
        }
        return inputPropsProp
    }

    // Be careful about what props get passed on to the MuiTextField as invalid fields will pollute
    // the JS console with warnings/errors.
    const muiTextFieldProps = ['autoComplete', 'autoFocus', 'autoCapitalize', 'autoComplete', 'className', 'spellCheck', 'InputProps', 'inputProps', 'variant', 'onChange', 'onMouseDown', 'color', 'defaultValue', 'disabled', 'error', 'FormHelperTextProps', 'fullWidth', 'helperText', 'id', 'InputLabelProps', 'inputRef', 'label', 'margin', 'multiline', 'name', 'placeholder', 'rows', 'rowsMax', 'select', 'selectProps', 'size', 'type', 'value', 'variant']

    const propsToPass = {}
    Object.keys(props).forEach(prop => {
        if (muiTextFieldProps.includes(prop) || ['data-', 'aria-'].some(prefix => prop.startsWith(prefix))) {
            propsToPass[prop] = props[prop]
        }
    })

    return (
        <ThemeProvider>
            <MuiTextField
                {...propsToPass}
                inputRef = {inputRef}
                label = {!showChip && !fixedChipValues ? label : ''}
                value = {!showChip ? props.value : ''}
                disabled = {disabled}
                classes = {customClasses}
                variant = 'filled'
                InputProps = {getInputProps()}
                onFocus = {(e: React.FocusEvent<HTMLInputElement>): void => onFocus(e)}
                onBlur = {(e: React.FocusEvent<HTMLInputElement>): void => onBlur(e)}
                helperText = {helperText}
                FormHelperTextProps = {{ classes: { contained: classes.helperText }, 'data-test-id': 'field-validation-error' }}
            />
        </ThemeProvider>
    )
}

TextField.defaultProps = {
    classNames: {},
    onXClick: null,
    helperText: null,
    noLabelOnActiveOrResolved: false
}

export default TextField
