import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A8541
 *
 * @param {SvgIconProps} props - svg props
 */
export const CallTransferIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M6.32437 11.3725C7.62411 14.1638 9.79426 16.481 12.4878 17.982L14.6942 15.9244C14.965 15.6719 15.3557 15.5982 15.6909 15.7263C16.7637 16.1228 17.9296 16.3576 19.1313 16.3995C19.6643 16.4181 20.0852 16.8695 20.0665 17.4025L19.9484 20.7846C19.9298 21.3177 19.4785 21.7385 18.9455 21.7199C9.84558 21.4021 2.72822 13.7697 3.04599 4.66979C3.06461 4.13678 3.51593 3.71591 4.04894 3.73453L7.44081 3.85297C7.97382 3.87159 8.39469 4.32291 8.37608 4.85592C8.33377 6.0673 8.48698 7.237 8.80765 8.33492C8.90241 8.67783 8.81168 9.05307 8.53086 9.31495L6.32437 11.3725Z' fill={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M16.8725 4.47396C16.6905 4.2892 16.6901 3.98867 16.8716 3.80339L17.5227 3.13869C17.7038 2.95377 17.9975 2.95377 18.1786 3.13869L21.3713 6.41459C21.5525 6.59951 21.5525 6.89931 21.3713 7.08423L18.1775 10.3613C17.9968 10.5458 17.704 10.5463 17.5227 10.3625L16.8651 9.69589C16.6824 9.51062 16.6825 9.20883 16.8653 9.02368L18.6343 7.22291H12.7473C12.6092 7.22291 12.4973 7.11098 12.4973 6.97291L12.4973 6.52591C12.4973 6.38784 12.6092 6.27591 12.7473 6.27591H18.6356L16.8725 4.47396Z' fill={color}/>
        </SvgIcon>
    )
}

export default CallTransferIcon
