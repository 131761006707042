import { Styles } from '@material-ui/core/styles/withStyles'
import headerImage from '../../../imgs/header-background.png'
type StylesType = Styles<any, Record<string, unknown>>

/**
 * @param {object} theme
 */
const styles: StylesType = theme => ({
    incomingCallWrapper: {
        zIndex: '111111',
        position: 'absolute',
        top: '0px',
        left: '0px',
        height: '100%',
        width: '100%',
        backgroundColor: '#FFFFFF',
        overflow: 'hidden'
    },
    incomingCallActions: {
        flex: 1,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& > .action-button': {
            position: 'absolute',
            cursor: 'pointer',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 'fit-content',
            rowGap: 4,
            '& > button': {
                height: 90,
                width: 90
            },
            '&.answer-button': {
                top: 54,
                left: 50,
                '& > button > span > svg': {
                    fontSize: 30
                }
            },
            '&.decline-button': {
                top: 54,
                right: 50,
                '&.move-down': {
                    top: 149,
                    left: 120
                },
                '& > button > span > svg': {
                    fontSize: 35
                }
            },
            '&.hold-answer-button': {
                top: 34,
                left: 30,
                width: 98,
                alignItems: 'flex-start',
                '& > span': {
                    paddingLeft: 2
                }
            },
            '&.end-answer-button': {
                top: 34,
                right: 36,
                width: 98,
                alignItems: 'flex-start',
                '& > span': {
                    paddingLeft: 2
                }
            },
            '& > .combined-icons': {
                position: 'relative',
                width: 80,
                height: 80,
                '& > svg': {
                    position: 'absolute',
                    transition: 'color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    '&:first-child': {
                        color: theme.palette.error.main,
                        fontSize: 40,
                        position: 'absolute',
                        top: 0,
                        left: 0
                    },
                    '&:last-child': {
                        color: theme.palette.primary.main,
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        paddingTop: 3,
                        paddingLeft: 3,
                        fontSize: 60,
                        position: 'absolute',
                        bottom: 0,
                        right: 0
                    }
                }
            },
            '& > span': {
                textTransform: 'uppercase'
            },
            '&:hover': {
                '& > .combined-icons': {
                    '& > svg': {
                        '&:first-child': {
                            color: theme.palette.error.dark
                        },
                        '&:last-child': {
                            color: theme.palette.primary.dark
                        }
                    }
                }
            }
        },
        '& > .ignore-button': {
            position: 'absolute',
            bottom: 30,
            right: 30,
            border: `2px solid ${theme.palette.text.secondary}`,
            color: theme.palette.text.secondary,
            textTransform: 'uppercase',
            '&:hover': {
                backgroundColor: theme.palette.text.secondary,
                color: 'white'
            }
        }
    },
    incomingCallDescription: {
        display: 'flex',
        marginTop: '15px',
        width: '100%',
        color: theme.palette.text.secondary,
        justifyContent: 'center'
    },
    ignoreButton: {
        position: 'absolute',
        bottom: '25px',
        right: '20px'
    },
    incomingCallHeader: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 40,
        height: 215,
        color: 'white',
        backgroundImage: `url(${headerImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center'
    }
})

export default styles
