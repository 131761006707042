/**
 * @param {object} teammates
 */
export function addTeammates (teammates) {
    return {
        type: 'ADD_TEAMMATES',
        teammates
    }
}

/**
 * @param {object} teammates
 */
export function updateTeammates (teammates) {
    return {
        type: 'UPDATE_TEAMMATES',
        teammates
    }
}

/**
 * @param {object} teammateId
 */
export function switchTeammate (teammateId) {
    return {
        type: 'SWITCH_TEAMMATE',
        teammateId
    }
}
