import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.1.1?node-id=9254%3A21097
 *
 * @param {SvgIconProps} props - svg props
 */
export const AskFirstIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M10.9643 12.053C13.019 11.9528 14.5067 9.42227 14.3783 6.80303C14.2498 4.1838 12.5041 2.24359 10.2346 2.35429C7.96504 2.46499 6.467 4.3429 6.59545 6.96213C6.7239 9.58136 8.90961 12.1533 10.9643 12.053ZM7.63378 12.1048C7.74866 12.028 7.84455 11.9639 7.92261 11.9279C8.35061 13.5818 9.98135 15.4885 11.6523 15.3814C12.8864 15.3024 13.2656 14.3476 13.1478 12.302C13.2835 12.3689 13.3111 12.438 13.3408 12.5122C13.37 12.5852 13.4012 12.6631 13.5388 12.7488C14.0189 13.0006 14.4375 13.1564 14.8066 13.2938C16.2333 13.8249 16.9205 14.0807 17.558 18.5373C17.6812 19.3989 17.4207 20.3023 16.7217 20.8211C13.0303 23.5611 7.60454 21.7727 4.0156 20.5898C3.68536 20.4809 3.37067 20.3772 3.07432 20.2826C2.16026 19.9908 1.46885 19.1501 1.52226 18.1921C1.65219 15.8615 2.11557 14.7185 2.96677 13.729C3.76218 12.8044 4.76184 12.7716 5.62815 12.7431C5.77875 12.7382 5.92531 12.7334 6.06608 12.724C6.77817 12.6768 7.29064 12.3342 7.63378 12.1048Z' fill={color}/>
            <path d='M20.0102 13.1811L19.346 12.5168C19.1507 12.3215 19.1526 12.0058 19.3332 11.7969C21.5324 9.25342 21.5324 5.47357 19.3332 2.93012C19.1526 2.72123 19.1507 2.40545 19.346 2.21019L20.0102 1.54594C20.2055 1.35067 20.5234 1.34994 20.7074 1.55591C23.6571 4.8589 23.6571 9.86809 20.7074 13.1711C20.5234 13.377 20.2055 13.3763 20.0102 13.1811Z' fill={color}/>
            <path d='M17.2675 10.4383L16.6032 9.77409C16.408 9.57882 16.4124 9.26416 16.5719 9.03869C17.2805 8.03643 17.2805 6.69056 16.5719 5.6883C16.4124 5.46283 16.408 5.14817 16.6032 4.95291L17.2675 4.28865C17.4628 4.09339 17.7817 4.09193 17.9561 4.30607C19.4019 6.08185 19.4019 8.64514 17.9561 10.4209C17.7817 10.6351 17.4628 10.6336 17.2675 10.4383Z' fill={color}/>
        </SvgIcon>
    )
}

export default AskFirstIcon
