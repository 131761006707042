<template>
    <w-tooltip right size="small">
        <template v-slot:activator>
            <w-checkbox
                :disabled="true"
                hide-details="auto"
                v-bind="$attrs"
            />
        </template>
        <slot name="label"/>
    </w-tooltip>
</template>
<script>
export default {

};
</script>