/* eslint-disable jsdoc/require-param-type */
/* eslint-disable jsdoc/require-description */
import { formatTime, formatPhoneNumber } from 'formatters'
import l from '../libs/lang'
import SmartFilters from './SmartFilters'
import setup from '../libs/resources-setups/messages'
import CsvItemsDownloader from '../libs/csv-helpers/ItemsDownloader'

/**
 *
 */
export default class Message extends SmartFilters {
    /**
     * @param session
     * @param component
     */
    constructor (session, component) {
        super(session, component, '/messages')
        this.setup = setup
        this.filters = {
            type: 'forever',
            deleted: false,
            direction: null,
            read: false,
            from: null,
            to: null
        }
        this.apply_type_range()
        this.config_key = 'messages-filters'
        this.empty_filters = JSON.parse(JSON.stringify(this.filters))
        this.headers = {
            id: l.t('sms.message-id', 'Message ID'),
            extension: l.t('app.extension', 'Extension'),
            conversation_id: l.t('app.conversation-id', 'Conversation ID'),
            from: l.t('app.from', 'From'),
            to: l.t('app.to', 'To'),
            text: l.t('app.text', 'Text'),
            direction: l.t('app.direction', 'Direction'),
            status: l.t('sms.delivery-status', 'Delivery status'),
            type: l.t('app.type', 'Type'),
            sent_at: l.t('app.sent-at', 'Sent at'),
            read_at: l.t('app.read-at', 'Read at')
        }
        this.functions = {
            /**
             * @param item
             */
            extension: (item) => {
                if (!item.extension) return '—'
                return item.extension.extension
            },
            /**
             * @param item
             */
            from: (item) => formatPhoneNumber(item.from).replace(/-/g, ''),
            /**
             * @param item
             */
            to: (item) => formatPhoneNumber(item.to).replace(/-/g, ''),
            /**
             * @param item
             */
            text: (item) => `${item.text.replace(/,/g, ' ')}`,
            /**
             * @param item
             */
            sent_at: (item) => formatTime(item.sent_at).replace(',', ' '),
            /**
             * @param item
             */
            read_at: (item) => formatTime(item.read_at).replace(',', ' ')
        }

        this.request_all_extensions = 'request_all_extensions=1'
        this.deleting_item_id = null
    }

    // because account level messages have to include ?request_all_extensions=1
    // set extension(val) {
    //     console.log(val);
    //     this._extension = val;
    //     this.resetDeleting();
    //     this.keep_filters_open = false;
    //     this.apply_filters(); // this will change the URI based on baseUri
    //     return this._extension;
    // }

    /**
     * @param params
     */
    async loadItems (params) {
        this.loading = true
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page)
            let { uri } = this
            if (!this._extension && !uri.includes(this.request_all_extensions)) {
                uri = `${uri}?${this.request_all_extensions}`
            }
            const items = await this.session.get_list(uri, params.limit, offset)
            this.page = params.page
            if (this.page > 1 && !items.items.length) {
                this.component.emit('pageChanged', 1)
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1
                })
            }
            this.items = items.items
            this.finalizeLoadingItems(items)
            this.component.emit('itemsLoaded', JSON.parse(JSON.stringify(items)))
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false

        return this.items
    }

    /**
     *
     */
    apply_filters () {
        this.filters_applied = true
        let uri = this.baseUri
        if (!this._extension && !uri.includes(this.request_all_extensions)) {
            uri = `${uri}?${this.request_all_extensions}`
        }

        uri += uri.includes('?') ? '&' : '?'
        uri += 'mode=brief'

        if (this.filters.start) {
            const start = Math.floor(Date.parse(this.filters.start) / 1000)
            uri += `&filters[created_after]=${start}`
        }
        if (this.filters.end) {
            const end = Math.floor(Date.parse(this.filters.end) / 1000)
            uri += `&filters[created_before]=${end}`
        }
        if (this.filters.deleted) {
            uri = `${uri}&filters[deleted]=only`
        }
        if (this.filters.read) {
            uri = `${uri}&filters[read]=only`
        }
        if (this.filters.from) {
            uri = `${uri}&filters[from]=${this.filters.from}`
        }
        if (this.filters.to) {
            uri = `${uri}&filters[to]=${this.filters.to}`
        }
        if (this.filters.direction) {
            uri = `${uri}&filters[direction]=${this.filters.direction}`
        }
        this.uri = uri
        this.hide_filters = false
    }

    /**
     *
     */
    async deleteAll () {
        let { uri } = this
        if (!this._extension && !uri.includes(this.request_all_extensions)) {
            uri = `${uri}?${this.request_all_extensions}`
        }
        await super.deleteAll(uri)
    }

    /**
     * @param id
     */
    async delete_m (id) {
        this.deleting_item_id = id
        try {
            await this.session.delete_item(`${this.baseUri}/${id}`)
            this.items = this.items.filter((v) => v.id !== id)
        } catch (err) {
            this.validation_error(err)
        }
        this.deleting_item_id = null
    }

    /**
     * @param index
     */
    readMore (index) {
        this.items[index].read_all = true
        this.component.forceUpdate()
    }

    /**
     * @param index
     */
    readLess (index) {
        this.items[index].read_all = false
        this.component.forceUpdate()
    }

    /**
     * @param items
     */
    static prepareMessages (items) {
        const messages = []
        for (const item of items) {
            for (const to of item.to) {
                const newItem = item
                newItem.to = to.number
                newItem.sent_at = to.sent_at
                newItem.status = to.delivery_status
                messages.push(newItem)
            }
        }

        return messages
    }

    /**
     *
     */
    async generate_csv () {
        this.csv_downloader = new CsvItemsDownloader(this.session)
        this.component.emit('hidePagination')
        try {
            let { uri } = this
            if (!this._extension && !uri.includes(this.request_all_extensions)) {
                uri = `${uri}?${this.request_all_extensions}`
            }
            const res = await this.csv_downloader.get_list_all(uri)
            if (res === 'aborted') {
                this.csv_downloader = null
                return null
            }
            if (!this.csv_downloader.stop) {
                let messages = Message.prepareMessages(res.items)
                messages = await this.attachExtension(messages)
                this.constructor.download_csv(
                    this.build_csv(messages),
                    'messages-report.csv'
                )
                this.csv_downloaded_successfully()
            }
        } catch (err) {
            this.validation_error(err)
        }
        this.csv_downloader = null
        this.component.emit('showPagination')

        return true
    }

    /**
     * @param messages
     */
    async attachExtension (messages) {
        let extensions = [...new Set(messages.map((x) => x.extension_id).filter((x) => x))]
        if (extensions.length) {
            extensions = await this.session.get_list_all(`/extensions?filters[id]=in:${extensions.join(',')}`)
            extensions.items.map((x) => {
                for (const message of messages) {
                    if (message.extension_id === x.id) message.extension = x
                }

                return x
            })
        }

        return messages
    }
}
