import React from 'react';

export default function PDFUnavailable(props) {
    return (
        <div>
            <p>We're sorry! Your browser does not support PDF Viewing.
                <br/>
                <a href={props.url}>Click Here to download your fax.</a>
            </p>
            <br/>
            <b>Chrome Users</b> <a href={props.url}>Click Here</a> for instructions on how to enable pdf viewer.
        </div>
    );
}

