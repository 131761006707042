<template>
    <w-loader v-if="loading"/>
    <div v-else>
        <w-alert
            v-if="alert"
            :message="alert.message"
            :level="alert.level"
            class="mb-6"
        />
        <w-form @submit="update" @changed="may_submit = true" data-discard="true" data-test-id="queue-form">
            <p class="help" data-test-id="queue-help-text">
                {{ l.t('queues.five-min-latency', 'Changes made on this page may take up to 5 minutes to take effect.') }}
            </p>
            <FormInput :label="l.t('app.name', 'Name')">
                <w-text-field v-model="queue.name" :rules="[$validation.required()]" hide-details='auto' data-test-id="queue-name-input"/>
            </FormInput>
            <FormInput :label="l.t('app.greeting', 'Greeting')">
                <w-switch
                    v-model="media_enabled.greeting"
                    :label="l.t('app.enabled', 'Enabled')"
                    hide-details='auto'
                    data-test-id="queue-greeting-switch"
                />
                <SelectorWithCreate
                    v-if="media_enabled.greeting"
                    v-model="queue.greeting"
                    :return_object="true"
                    :rules="[$validation.required()]"
                    mode="greetings"
                    data-test-id="queue-greeting-selector"
                />
            </FormInput>
            <FormInput :label="l.t('app.hold-music', 'Hold music')">
                <w-switch
                    v-model="media_enabled.hold_music"
                    :label="l.t('app.enabled', 'Enabled')"
                    hide-details='auto'
                    data-test-id="queue-hold-music-switch"
                />
                <APIAutocomplete
                    v-if="media_enabled.hold_music"
                    v-model="queue.hold_music"
                    :return_object="true"
                    :before_list="[{ value: null, text: l.t('hold-music.default', 'Default ringtone') }]"
                    mode="hold_music"
                    key="hold_music"
                    data-test-id="queue-hold-music-selector"
                />
            </FormInput>
            <FormInput :label="l.t('queues.max-hold-time', 'Maximum Hold Time in minutes')">
                <w-select
                    :items="[60, 120, 180, 240, 300, 600, 900, 1200, 1800, 2700, 3600].map((x) => {
                        return { value: x, text: x / 60 }
                    }).concat(queue.max_hold_time === null ? [null] : [])"
                    v-model="queue.max_hold_time"
                    hide-details="auto"
                    data-test-id="queue-hold-time-input"
                />
            </FormInput>
            <FormInput :label="l.t('queues.caller-id', 'Caller ID to show Queue members')">
                <w-select
                    v-model="queue.caller_id_type"
                    :items="[
                        {value: 'called_number', text: l.t('app.called-number', 'Called number')},
                        {value: 'calling_number', text: l.t('app.calling-number', 'Calling number')},
                    ].concat(queue.caller_id_type === null ? [null] : [])"
                    hide-details="auto"
                    data-test-id="queue-caller-type-select"
                />
            </FormInput>

            <SectionTitle>{{ l.t("queues.members", "Queue Members") }}</SectionTitle>
            <FormInput :label="l.t('queues.member-ring-time-seconds', 'Members ringing time in seconds')">
                <w-select
                    v-model="queue.ring_time"
                    :items="[5, 10, 15, 20, 25, 30].concat(queue.ring_time === null ? [null] : [])"
                    hide-details="auto"
                    data-test-id="queue-ring-time-select"
                />
                <p class="mt-3" data-test-id="queue-ring-time-select-help">
                    {{
                        l.t(
                            "queues.ring-time-explintation",
                            "Ring each member as per the ring time settings. Hold the caller up to the max hold time you entered, or until the call is answered by a Queue member"
                        )
                    }}
                </p>
                <p data-test-id="queue-ring-time-select-help-2">{{ l.t('queues.round-robin-memory', 'Queues use a Round Robin Memory strategy - Starting at one member and continuing with all others in a circular fashion. The next call remembers which member was last and starts with the next.') }}</p>
            </FormInput>
            <FormInput
                :label="l.t('queues.members', 'Queue Members')"
                :explanation="l.t('queues.only-ip-phone-explanation', 'Only extensions that are using a device (IP phone or adapter), Phone number, Communicator or a softphone can be queue members.')"
            >
                <template v-for="(member, i) in queue.members">
                    <div v-if="queue.members[i]">
                        <Tel
                            v-if="
                                queue.members[i].hasOwnProperty(
                                    'phone_number'
                                )
                            "
                            :value="queue.members[i].phone_number"
                            @changed="phoneChanged($event, i)"
                            :closable="true"
                            @closed="deleteMember(i)"
                            :key="queue.members[i].iteration_key"
                            :data-test-id="`queue-member-${i}-tel-input`"
                        />
                        <APIAutocomplete
                            class="non-virtual-extension-selector"
                            v-else
                            :value="queue.members[i].extension"
                            :return_object="true"
                            @input="extensionChanged($event, i)"
                            mode="virtualExtensions"
                            :required="true"
                            :options_list="['remove']"
                            @click:remove="deleteMember(i)"
                            :key="`${queue.members[i].iteration_key}-else`"
                            :data-test-id="`queue-member-${i}-extension-selector`"
                        />
                    </div>
                </template>
                <v-row class="pa-0 ma-0">
                    <v-col md="6">
                        <AddToListButton @click="queue.members.push({
                            iteration_key:
                                queue.members.length +
                                1 +
                                Math.random(),
                            extension: {},
                        })"
                        center
                        data-test-id="queue-add-extension-btn"
                        >
                            {{ l.t("app.add-extension", "Add extension") }}
                        </AddToListButton>
                    </v-col>
                    <v-col md="6">
                        <AddToListButton @click="queue.members.push({
                            iteration_key:
                                queue.members.length +
                                1 +
                                Math.random(),
                            phone_number: '',
                        })"
                            center
                            data-test-id="queue-add-phone-btn"
                        >
                            {{ l.t("app.phone-add", "Add phone number") }}
                        </AddToListButton>
                    </v-col>
                </v-row>
            </FormInput>
            <FormInput class="mt-3">
                <w-btn :disabled="!may_submit" type="submit" class="primary" data-test-id="queue-submit-btn">{{ l.t("app.save", "Save") }}</w-btn>
            </FormInput>
        </w-form>
    </div>
</template>

<script>
import l from '../../libs/lang';
import Tel from '../elements/Tel.vue';
import FormInput from '../elements/form/FormInput.vue';
import SectionTitle from '../elements/SectionTitle.vue';
import AddToListButton from '../elements/AddToListButton.vue';
import APIAutocomplete from '../elements/form/APIAutocomplete.vue';
import SelectorWithCreate from '../elements/SelectorWithCreate.vue';

export default {
    props: ['_queue'],
    components: {
        Tel,
        FormInput,
        SectionTitle,
        APIAutocomplete,
        AddToListButton,
        SelectorWithCreate,
    },
    data() {
        return {
            l,
            loading: false,
            queue: null,
            alert: null,
            may_submit: false,
            media_enabled: {
                hold_music: false,
                greeting: false,
            },
        };
    },
    created() {
        this.$data.queue = this.$props._queue;
        for (const i in this.$data.queue.members) {
            if (this.$data.queue.members[i]) {
                this.$data.queue.members[i].iteration_key = i + Math.random();
            }
        }
        this.$data.media_enabled.greeting = !!this.$data.queue.greeting;
        this.$data.media_enabled.hold_music = !!this.$data.queue.hold_music;
    },
    methods: {
        deleteMember(index) {
            this.$data.queue.members.splice(index, 1);
        },
        // eslint-disable-next-line consistent-return
        extensionChanged(e, i) {
            this.$data.alert = null;
            const check = !!this.$data.queue.members.filter(
                (x) => x.extension && x.extension.id === e.id,
            ).length;

            if (check) {
                this.$data.queue.members[i]['iteration_key'] = i + Math.random();
                window.scroll({ top: 0 });
                this.$data.alert = {
                    level: 'error',
                    message: l.t(
                        'queues.distinct-extensions',
                        "You can't use the same extension for multiple queue members.",
                    ),
                };
                setTimeout(() => {
                    this.$data.alert = null
                }, 3000)
                return null;
            }

            this.$data.queue.members[i].extension = e;
        },
        // eslint-disable-next-line consistent-return
        phoneChanged(e, i) {
            if (e.clean_number === this.$data.queue.members[i].phone_number) return null;
            this.$data.alert = null;
            const check = !!this.$data.queue.members.filter(
                (x) => x.phone_number && x.phone_number === e.clean_number,
            ).length;
            if (check) {
                this.$data.queue.members[i]['phone_number'] = null;
                window.scroll({ top: 0 });
                this.$data.alert = {
                    level: 'error',
                    message: l.t(
                        'queues.distinct-phones',
                        "You can't use the same phone number for multiple queue members.",
                    ),
                };
                setTimeout(() => {
                    this.$data.alert = null
                }, 3000)
                return null;
            }

            this.$data.queue.members[i].phone_number = e.clean_number;
        },
        async update(e) {
            if (e) e.preventDefault();
            this.prepareQueueForUpdating();
            this.$emit('updateQueue', this.$data.queue);
        },
        prepareQueueForUpdating() {
            for (const member of this.$data.queue.members) {
                delete member.iteration_key;
                if (member.extension) {
                    member.extension = {
                        id: member.extension.id,
                    };
                }
                if (member.phone_number) {
                    member.phone_number = member.phone_number
                        .split('-')
                        .join('');
                }
            }

            if (!this.$data.media_enabled.greeting) {
                this.$data.queue.greeting = null;
            }
            if (!this.$data.media_enabled.hold_music) {
                this.$data.queue.hold_music = null;
            }
        },
    },
};
</script>
