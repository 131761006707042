import { is_csr } from 'phoenix-session-helpers'
import { formatPhoneNumber } from 'formatters'
import l from '../libs/lang'
import Resource from './Resource'
import RouteAnalyzer from './RouteAnalyzer'
import setup from '../libs/resources-setups/phone-numbers'

/**
 *
 */
export default class PhoneNumber extends Resource {
    /**
     *
     * @param {object} session PhoenixApiJsClient Object
     * @param {object} component helpers vueComponent
     */
    constructor (session, component) {
        super(session, component, '/phone-numbers')
        this.selector_mode = ['phone_numbers']
        this.setup = setup
        this.filters = {
            name: null,
            exact: null,
            number: null,
            name_or_number: null
        }
        this.item = null

        this.file = null
        this.numbersToBeDeleted = null
        this.deleting = null
        this.stopDeleting = false
        this.deleting_finished = false
        this.headers = {
            name: l.t('app.phone-number', 'Phone number'),
            message: l.t('app.message', 'Message')
        }
        this.functions = {
            /**
             *
             * @param {object} item
             * @returns
             */
            name: (item) => formatPhoneNumber(item.name),
            /**
             *
             * @param {object} item
             * @returns
             */
            message: (item) => item.message
        }
    }

    /**
     *
     * @param {object} params
     * @returns
     */
    async loadItems (params) {
        this.loading = true
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page)
            let { uri } = this
            if (await is_csr(this.session)) {
                const lrdn_filter = 'filters[lrdn_numbers]=include'
                uri = `${this.uri}${this.uri.includes('?') ? '&' : '?'}${lrdn_filter}`
            }
            const items = await this.session.get_list(uri, params.limit, offset)
            this.page = params.page
            if (this.page > 1 && !items.items.length) {
                this.component.emit('pageChanged', 1)
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1
                })
            }
            if (this.page === 1 && !this.filters_applied && !items.items.length) return this.component.routerPush('phone-numbers.create')

            this.items = items.items
            this.finalizeLoadingItems(items)
            this.items = this.items.map((x) => {
                const item = x
                item.route_analysis = new RouteAnalyzer(item.route)

                return item
            })

            this.component.emit('itemsLoaded', JSON.parse(JSON.stringify(items)))
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false

        return this.items
    }

    /**
     *
     * @param {object} data
     * @returns
     */
    async update_route (data) {
        this.item = await this.session.patch_item(
            `${this.baseUri}/${this.item.id}`,
            { route: { id: data.id } }
        )

        return this.item.route
    }

    /**
     *
     * @param {object} reservation
     */
    async reservation_handler (reservation) {
        this.loading = true
        try {
            const res = await this.session.create_item(
                '/phone-numbers',
                { phone_number: reservation.phone_number }
            )

            if (res.error) return this.validation_error(res.error)
            this.addToCache(res)
            this.component.routerPush(
                'phone-numbers.show', {
                    id: res.id
                }
            )
            this.loading = false
        } catch (err) {
            return this.validation_error(err)
        }

        return true
    }

    /**
     *
     * @param {object} data
     */
    async update (data) {
        this.loading = true
        try {
            this.item = await this.session.replace_item(
                `/phone-numbers/${data.id}`,
                data
            )
            this.updateCache(this.item)
            this.successfulUpdate('phone-numbers.index')
            this.item = null
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
    }

    /**
     *
     */
    apply_filters () {
        this.loading = true
        try {
            let uri = this.baseUri
            const filters = []

            if (this.filters.name) filters.push(`filters[name]=contains:${this.filters.name}`)
            if (this.filters.number) {
                filters.push(
                    `filters[number]=${this.filters.exact ? 'eq' : 'contains'}:${encodeURIComponent(this.filters.number.replace(/-/g, ''))}`
                )
            }
            if (filters.length) uri = `${uri}?${filters.join('&')}`
            this.uri = uri
            this.filters_applied = true
        } catch (err) {
            this.component.emit('failed', err)
        }
        this.loading = false
    }

    /**
     *
     */
    do_apply_dynamic_filters () {
        try {
            let uri = this.baseUri
            if (this.filters.name_or_number) {
                uri = `${uri}?filters[name-or-did]=contains:${this.filters.name_or_number}`
            }
            this.uri = uri
        } catch (err) {
            this.validation_error(err)
        }
    }

    /**
     *
     * @param {object} e js object
     * @returns
     */
    async retrieve_numbers_from_csv (e) {
        if (e) e.preventDefault()
        if (!this.file) return null
        this.loading = true
        this.numbersToBeDeleted = []
        const csvNumbers = this.extractNumbersFromCsv()
        const csvNumbersCopy = JSON.parse(JSON.stringify(csvNumbers))
        const csvInSmallerArrays = []
        const limit = 10
        try {
            while (csvNumbersCopy.length) csvInSmallerArrays.push(csvNumbersCopy.splice(0, limit))

            let numbers = []
            // eslint-disable-next-line no-restricted-syntax
            for (const part of csvInSmallerArrays) {
                const pulledNumbers = await this.session.get_list(
                    `${this.baseUri}?filters[number]=in:${encodeURIComponent(part.join(','))}&limit=${limit}`
                )
                numbers = numbers.concat(pulledNumbers.items)
            }

            csvNumbers.map((number) => {
                const found = numbers.find((x) => x.phone_number === number)
                if (found) {
                    this.numbersToBeDeleted.push({
                        message: l.t('app.found', 'Found'),
                        ...found
                    })
                } else {
                    this.numbersToBeDeleted.push({
                        phone_number: number,
                        message: l.t('app.not-found', 'Not found')
                    })
                }

                return number
            })
            this.loading = false
        } catch (err) {
            this.loading = false
            return this.validation_error(err)
        }

        return this.numbersToBeDeleted
    }

    /**
     *
     */
    async delete_from_csv () {
        this.loading = true
        this.deleting = {
            total: 0,
            status: []
        }
        this.deleting.total = this.numbersToBeDeleted.length
        const numbers = this.numbersToBeDeleted.filter((x) => x.id)
        if (numbers.length !== this.numbersToBeDeleted.length) {
            for (const n of this.numbersToBeDeleted.filter((x) => !x.id)) {
                this.deleting.status.push({
                    name: n.phone_number,
                    status: 'failed',
                    message: l.t('app.not-found', 'Not found')

                })
            }
        }

        this.numbersToBeDeleted = null

        for (const number of numbers) {
            try {
                if (this.stopDeleting) break
                await this.session.delete_item(`${this.baseUri}/${number.id}`)
                this.removeFromCache(number)
                this.deleting.status.push({
                    name: number.phone_number,
                    status: 'success',
                    message: l.t('app.successfully-deleted', 'Successfully deleted')

                })
                this.component.forceUpdate()
            } catch (err) {
                this.deleting.status.push({
                    name: number.phone_number,
                    status: 'failed',
                    message: Resource.findError(err, number)

                })
            }
        }

        this.alert = {
            message: l.t(
                'phone-numbers.successfully-deleted-numbers',
                'Deleting phone numbers finished.'
            ),
            level: 'success'
        }
        setTimeout(() => {
            this.alert = null
        }, 3000)

        setTimeout(() => {
            this.deleting_finished = true
            this.loading = false
        }, 500)
    }

    /**
     *
     */
    extractNumbersFromCsv () {
        return [...new Set(this.file.split('\n').map(PhoneNumber.normalizeCsvLine).filter((v) => !!v))]
    }

    /**
     *
     * @param {string} line
     * @returns
     */
    static normalizeCsvLine (line) {
        let phone_number = line.split(',')[0].replace(/^"/, '').replace(/"$/, '')
        const started_with_plus = phone_number[0] === '+'
        phone_number = phone_number.replace(/\D/g, '')
        if (!phone_number.length) return null

        phone_number = `+${phone_number}`
        if (!started_with_plus &&
            phone_number.length > 1 &&
            phone_number[1] !== '1'
        ) phone_number = `+1${phone_number.slice(1)}`

        return phone_number
    }

    /**
     *
     */
    download_deleting_results () {
        const csv = this.build_csv(this.deleting.status)
        Resource.download_csv(csv, `deleting-phone-numbers-report-${Date.now().toLocaleString().replace(/[^\d]/g, '-')}.csv`)
        this.csv_downloaded_successfully()
    }

    /**
     *
     */
    resetDeletingConf () {
        this.file = null
        this.numbersToBeDeleted = null
        this.deleting = null
        this.stopDeleting = false
        this.deleting_finished = false
        this.component.forceUpdate()
    }
}
