const styles = theme => ({
  hoverShadow: {
    '&:hover': {
      boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.05)'
    }
  },
  dialogModal: {
    position:	'relative',
    width:	'100%',
    top:	'5%',
    backgroundColor:	'rgba(255, 255, 255, 0.65)',
    fontFamily:	'Montserrat',
    '& .MuiBackdrop-root': {
      background:	'unset'
    },
    '& *': {
      fontFamily: 'Montserrat'
    }
  },
  dialogPaper: {
    borderRadius:	20,
    boxShadow:	'0px 7px 15px rgba(0, 0, 0, 0.1), 0px 20px 35px rgba(0, 0, 0, 0.15), 0px 10px 45px rgba(0, 0, 0, 0.1)'
  },
  dialogContent: {
    display:	'flex',
    flexDirection:	'column',
    padding:	'70px 0 72px !important',
    display:	'flex',
    justifyContent:	'center',
    position:	'relative'
  },
  searchWrapper: {
    position:	'relative',
    width:	'100%',
    display:	'flex',
    alignItems:	'center',
    justifyContent:	'space-between',
    padding:	'0 137px 0 60px'
  },
  boolFilter: {
    display:	'flex',
    flexDirection:	'column',
    margin:	'0 5px'
  },
  boolFilterItem: {
    color:	'gray',
    textAlign:	'center',
    padding:	'3px 10px',
    borderRadius:	4,
    cursor:	'pointer',
    userSelect:	'none',
    whiteSpace:	'nowrap',
    textDecoration:	'line-through',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.05)'
    },
    '&:first-child': {
      marginBottom: 3
    },
    '&.active': {
      fontWeight:	500,
      color:	theme.palette.primary.main,
      textDecoration:	'underline'
    }
  },
  searchInput: {
    extends:	'hoverShadow',
    position:	'relative',
    borderRadius:	10,
    backgroundColor:	'white',
    width:	'100%',
    marginRight:	'15px'
  },
  tags: {
    display: 'flex'
  },
  searchButton: {
    width:	213,
    height:	44,
    marginLeft:	40
  },
  xButtonWrapper: {
    padding:	14,
    cursor:	'pointer',
    borderRadius:	'50%',
    position:	'absolute',
    top:	18,
    right:	18,
    '&:hover': {
      background: theme.palette.secondary[-400]
    }
  }
})

export default styles
