import l from '../lang';

export default {
	'routes': {
		'create': 'api-requests.create',
		'show': 'api-requests.show',
		'index': 'api-requests.index',
	},
	'delete-single': l.t('scheduled-requests.delete-single', 'Are you sure you want to delete this request?'),
	'delete-filtered': l.t('scheduled-requests.delete-filtered', 'Are you sure you want to delete all filtered scheduled requests?'),
	'delete-all': l.t('scheduled-requests.delete-all', 'Are you sure you want to delete all scheduled requests?'),
	'successfull-creation': l.t('scheduled-requests.successfully-created', 'Your request has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('scheduled-requests.failed-creation', 'There was a problem creating your request'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
};
