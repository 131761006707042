// @flow

import LinkifyIt from 'linkify-it';
import tlds from 'tlds';

const linkify = new LinkifyIt();
linkify.tlds(tlds);

// Phone Number schema
linkify.add('+', {
    validate: function (text, pos, self) {
        var tail = text.slice(pos);

        if (!self.re.phonenumber) {
            self.re.phonenumber =  new RegExp(
                /^(([0-9]{1,3}[-]?)+[0-9]{2,4}\b)/,
            );
        }
        if (self.re.phonenumber.test(tail)) {
            return tail.match(self.re.phonenumber)[0].length;
        }
        return 0;
    },
    normalize: function (match) {
        match.url = 'tel:' + match.url;
    }
});


export default (text) => {
    return linkify.match(text);
};
