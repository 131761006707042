/**
 * @param {Theme} theme
 */
const styles = theme => ({
    containedWarning: {
        color: 'white',
        backgroundColor: theme.palette.warning.light,
        '&:hover': {
            backgroundColor: theme.palette.warning.main,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.warning.light
            }
        },
        '&:focus, &:focus-visible': {
            backgroundColor: theme.palette.warning.main
        }
    },
    containedUrgent: {
        color: 'white',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.error.main
            }
        },
        '&:focus, &:focus-visible': {
            backgroundColor: theme.palette.error.dark
        }
    },
    containedLightPrimary: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.action.primaryFocus,
        '&:hover': {
            backgroundColor: theme.palette.other.green,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.action.primaryFocus
            }
        },
        '&:focus, &:focus-visible': {
            backgroundColor: theme.palette.other.green
        }
    },
    containedLightSecondary: {
        color: theme.palette.secondary.dark,
        backgroundColor: theme.palette.action.secondaryFocus,
        '&:hover': {
            backgroundColor: theme.palette.secondary.background,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.action.secondaryFocus
            }
        },
        '&:focus, &:focus-visible': {
            backgroundColor: theme.palette.secondary.background
        }
    },
    containedLightWarning: {
        color: theme.palette.warning.main,
        backgroundColor: theme.palette.action.warningFocus,
        '&:hover': {
            backgroundColor: theme.palette.warning.background,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.action.warningFocus
            }
        },
        '&:focus, &:focus-visible': {
            backgroundColor: theme.palette.warning.background
        }
    },
    containedLightUrgent: {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.action.errorFocus,
        '&:hover': {
            backgroundColor: theme.palette.error.background,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.action.errorFocus
            }
        },
        '&:focus, &:focus-visible': {
            backgroundColor: theme.palette.error.background
        }
    },
    large: {
        width: 62,
        height: 62,
        maxWidth: 62,
        maxHeight: 62,
        '& svg': {
            fontSize: 24
        }
    },
    medium: {
        width: 42,
        height: 42,
        maxWidth: 42,
        maxHeight: 42,
        '& svg': {
            fontSize: 22
        }
    },
    small: {
        width: 32,
        height: 32,
        maxWidth: 32,
        maxHeight: 32,
        '& svg': {
            fontSize: 18
        }
    }
})

export default styles
