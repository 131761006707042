<template>
    <w-loader v-if="loading"/>
    <APIAutocomplete
        v-else
        mode="user_hold_music"
        :value="val"
        :return_object="true"
        :before_list="before_list"
        :after_list="after_list"
        @input="on_change"
        :suppress_no_items_alert="true"
        data-test-id="basic-moh-selector"
    />
</template>
<script>
import l from '../../libs/lang';
import SystemMOH from '../../libs/system-moh.json';
import APIAutocomplete from './form/APIAutocomplete.vue';

export default {
    props: {
        value: {
            type: Object,
            required: false,
        },
        nullable: {
            type: Boolean,
            default: false,
        },
        show_premium: {
            type: Boolean,
            default: false,
        },
        force_enable_premium: {
            type: Boolean,
            default: false,
        },
        force_disable_premium: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        APIAutocomplete,
    },
    data() {
        return {
            l,
            val: null,
            loading: false,
            before_list: [],
            after_list: [],
            premium_enabled: false,
            premium_enabled_for_account: false,
        };
    },
    async created() {
        this.$data.loading = true;

        if (this.$props.value) {
            this.$data.val = this.$props.value;
        }
        this.$data.premium_enabled_for_account = this.$session.user.account.features['premium-music'];
        this.$data.premium_enabled = this.$props.show_premium || this.$data.premium_enabled_for_account;
        this.$data.before_list = this.build_before_list();
        this.$data.after_list = this.build_after_list();

        this.$data.loading = false;
    },
    methods: {
        build_before_list() {
            const before_list = [];
            before_list.push({header: l.t('app.personal', 'Personal')});

            return before_list;
        },
        build_after_list() {
            let after_list = [];
            const standard_moh = SystemMOH.filter((x) => x.id < 12);
            const premium_moh = SystemMOH.filter((x) => x.id > 11);
            if (standard_moh.length) {
                let standard_items = [{header: l.t('app.standard', 'Standard')}];
                if (this.$props.nullable) {
                    standard_items.push({ value: null, text: `(${l.t('app.ringtome', 'ringtone')})` });
                }
                standard_items = standard_items.concat(standard_moh.map((x) => ({value: x.id, text: x.name})));
                after_list = after_list.concat(standard_items);
            }
            if (this.$data.premium_enabled && premium_moh.length) {
                let disabled = !(this.$data.premium_enabled || this.$props.force_enable_premium);
                if (this.$props.force_disable_premium) {
                    disabled = true;
                }
                const premium_items = [
                    {header: l.t('app.premium', 'Premium')},
                    ...premium_moh.map((x) => ({value: x.id, text: x.name, disabled})),
                ];
                after_list = after_list.concat(premium_items);
            }

            return after_list;
        },
        on_change(data) {
            if (typeof data === 'number') {
                this.$data.val = SystemMOH.find((x) => x.id === data) || {id: data};
            } else if (data && typeof data === 'object' && data.id) {
                this.$data.val = data;
            } else {
                this.$data.val = null;
            }
        },
    },
    watch: {
        val(v) {
            this.$emit('input', v);
        },
    }
};
</script>
