<template>
    <div>
      <CsvValidationErrors
          :errors="media.csv_incorrect_lines"
          :key="media.csv_incorrect_lines.length"
          data-test-id="media-loader-import-csv-results-errors"
      />
      <v-simple-table data-test-id="import-csv-results-table">
        <thead data-test-id="table-head">
          <tr data-test-id="head-row">
            <th data-test-id="acc-id">{{ l.t('app.account-id', 'Account ID') }}</th>
            <th data-test-id="acc-type">{{ l.t('app.account-type', 'Account type') }}</th>
            <th data-test-id="acc-name">{{ l.t('media.account-name', 'Account name') }}</th>
            <th data-test-id="media-id">{{ l.t('media.id', 'Media ID') }}</th>
            <th data-test-id="media-name">{{ l.t('media.name', 'Media name') }}</th>
            <th data-test-id="media-type">{{ l.t('media.type', 'Media type') }}</th>
            <th data-test-id="origin">{{ l.t('media.origin', 'Origin') }}</th>
            <th data-test-id="status">{{ l.t('app.status', 'Status') }}</th>
            <th data-test-id="message">{{ l.t('app.message', 'Message') }}</th>
          </tr>
        </thead>
        <tbody data-test-id="table-body">
          <tr v-for="(item, i) in media.csv_media" :data-test-id="item.media_id">
            <td data-test-id="acc-id">{{ item.account_id }}</td>
            <td :data-test-id="`media-loader-import-csv-results-item-${i}-accout-lvl`">{{ item.subaccount ? l.t('app.subaccount', 'Subaccount') : l.t('app.main-account', 'Main account') }}</td>
            <td data-test-id="acc-name">{{ item.account_name }}</td>
            <td data-test-id="media-id">{{ item.media_id }}</td>
            <td data-test-id="media-name">{{ item.media_name }}</td>
            <td data-test-id="media-type">{{ item.media_type }}</td>
            <td data-test-id="origin">{{ item.origin }}</td>
            <td :data-test-id="`media-loader-import-csv-results-item-${i}-status`">{{ item.status ? item.status.level : '&mdash;' }}</td>
            <td :data-test-id="`media-loader-import-csv-results-item-${i}-message`">{{ item.status ? item.status.message : '&mdash;' }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <div class="mt-3">
        <w-btn @click="media.backToCsvForm()" id="back" type="button" data-test-id="media-loader-import-csv-results-back-btn">
          {{ l.t('app.back', 'Back') }}
        </w-btn>
        <w-btn @click="media.import_media_from_csv()" :disabled="disabledSubmit" color="primary" class="ml-3" data-test-id="media-loader-import-csv-results-continue-btn">
          {{ l.t('app.continue', 'Continue') }}
        </w-btn>
      </div>
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import CsvValidationErrors from '../../elements/CsvValidationErrors.vue';

  export default {
    props: ['media'],
    components: { CsvValidationErrors },
    data() {
      return {
        l,
        disabledSubmit: false,
      };
    },
    created() {
      this.$data.disabledSubmit = !this.$props.media.csv_media.find((x) => x.is_found);
    },
  };
</script>
