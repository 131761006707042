<template>
    <w-loader v-if="queue.loading"/>
    <div v-else class="show-page" data-test-id="queues-show-page">
      <PageTitle class="nowrap">
        {{title()}}
      </PageTitle>
        <w-alert
          v-if="queue.alert"
          :message="queue.alert.message"
          :level="queue.alert.level"
          :closable="queue.enable_close_alert"
          @closed="queue.alert = null"
          class="mb-6"
        />
        <QueueFragment
          v-if="queue.item"
          :_queue="queue.item"
          @updateQueue="queue.update($event)"
        />
    </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import Queue from '../../../models/Queue';
  import QueueFragment from '../../fragments/Queue.vue';
  import PageTitle from '../../elements/PageTitle.vue';

  export default {
    props: ['id', '_queue'],
    components: {
      QueueFragment,
      PageTitle
    },
    data() {
      return {
        l,
        queue: new Queue(this.$session, vueComponent(this)),
        last_title: '',
      };
    },
    async created() {
        if (this.$props._queue || this.$route.params._queue) {
          this.$data.queue.item = this.$props._queue || this.$route.params._queue;
        } else {
          await this.$data.queue.getItem(this.$props.id ? this.$props.id : this.$route.params.id);
        }
    },
    methods: {
      title() {
        let title = '';
        if (this.$data.queue.item) {
          title += l.t('app.queue', 'Queue:');
          title += ` ${this.$data.queue.item.name ? this.$data.queue.item.name : ''}`;
          this.$data.last_title = title;
        } else {
          title = this.$data.last_title;
        }
        return title;
      },
    }
  };
</script>
