import l from '../lang';

export default {
	'routes': {
		'create': 'menus.create',
		'show': 'menus.show',
		'index': 'menus.index',
	},
	'delete-single': l.t('menus.delete-single', 'Are you sure you want to delete this menu?'),
	'delete-filtered': l.t('menus.delete-filtered', 'Are you sure you want to delete all filtered menus?'),
	'delete-all': l.t('menus.delete-all', 'Are you sure you want to delete all menus?'),
	'successfull-creation': l.t('menus.successfully-created', 'Your menu has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('menus.failed-creation', 'There was a problem creating your menu'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
	'currently-in-use': l.t('menus.this-menu-is-currently-in-use', 'This menu is currently in use'),
	'to-delete-used': l.t('menus.to-delete-this-menu-remove-it', 'To delete this menu, remove it from your call handling rules first, then try again.'),
};
