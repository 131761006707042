export function updateFaxes(faxes) {
	return {
		type: 'UPDATE_FAXES',
		faxes
	}
}

export function updateFax(fax) {
	return {
		type: 'UPDATE_FAX',
		fax
	}
}

export function deleteFax(fax) {
	return {
		type: 'DELETE_FAX',
		fax
	}
}

export function addFax(fax) {
	return {
		type: 'ADD_FAX',
		fax
	}
}

export function switchFax(fax) {
	return {
		type: 'SWITCH_FAX',
		fax
	}
}

export function updateFaxLinks(faxLinks) {
	return {
		type: 'UPDATE_FAX_LINKS',
		faxLinks
	}
}

export function addContactsToFaxes(contacts) {
	return {
		type: 'ADD_CONTACTS_TO_FAXES',
		contacts
	}
}

export function removeContactFromFaxes(contactId) {
	return {
		type: 'REMOVE_CONTACT_FROM_FAXES',
		contactId
	}
}