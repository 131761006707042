import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A8583
 *
 * @param {SvgIconProps} props - svg props
 */
export const VoicemailIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.23745 17.75C6.23903 17.75 6.24061 17.75 6.24219 17.75C6.24377 17.75 6.24534 17.75 6.24692 17.75H17.7531C17.7547 17.75 17.7562 17.75 17.7578 17.75C17.7594 17.75 17.761 17.75 17.7625 17.75H17.9766V17.7459C21.0508 17.6309 23.5078 15.1024 23.5078 12C23.5078 8.82436 20.9335 6.25 17.7578 6.25C14.5822 6.25 12.0078 8.82436 12.0078 12C12.0078 12.7985 12.1706 13.5589 12.4647 14.25H11.5353C11.8294 13.5589 11.9922 12.7985 11.9922 12C11.9922 8.82436 9.41783 6.25 6.24219 6.25C3.06655 6.25 0.492188 8.82436 0.492188 12C0.492188 15.0866 2.92423 17.6052 5.97656 17.744V17.75H6.23745ZM6.24219 14.25C7.48483 14.25 8.49219 13.2426 8.49219 12C8.49219 10.7574 7.48483 9.75 6.24219 9.75C4.99955 9.75 3.99219 10.7574 3.99219 12C3.99219 13.2426 4.99955 14.25 6.24219 14.25ZM17.7578 14.25C19.0005 14.25 20.0078 13.2426 20.0078 12C20.0078 10.7574 19.0005 9.75 17.7578 9.75C16.5152 9.75 15.5078 10.7574 15.5078 12C15.5078 13.2426 16.5152 14.25 17.7578 14.25Z" fill={color}/>
        </SvgIcon>
    )
}

export default VoicemailIcon
