import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A8546
 *
 * @param {SvgIconProps} props - svg props
 */
export const HangUpIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12 9.80587C10.5333 9.80587 9.1125 10.0316 7.78333 10.456V13.2551C7.78333 13.6072 7.5725 13.9233 7.27 14.0677C6.37167 14.5102 5.55583 15.079 4.83167 15.7381C4.66667 15.9007 4.4375 15.991 4.19 15.991C3.93333 15.991 3.70417 15.8916 3.53917 15.7291L1.26583 13.4898C1.10083 13.3363 1 13.1106 1 12.8578C1 12.605 1.10083 12.3792 1.26583 12.2167C4.06167 9.60722 7.83833 8 12 8C16.1617 8 19.9383 9.60722 22.7342 12.2167C22.8992 12.3792 23 12.605 23 12.8578C23 13.1106 22.8992 13.3363 22.7342 13.4989L20.4608 15.7381C20.2958 15.9007 20.0667 16 19.81 16C19.5625 16 19.3333 15.9007 19.1683 15.7472C18.4442 15.079 17.6192 14.5192 16.7208 14.0767C16.4183 13.9323 16.2075 13.6253 16.2075 13.2641V10.465C14.8875 10.0316 13.4667 9.80587 12 9.80587Z' fill={color}/>
        </SvgIcon>
    )
}

export default HangUpIcon
