

export enum CallManagerEvents {
    CALL_CREATED = 'CALL_CREATED',
    CALL_ANSWERED = 'CALL_ANSWERED',
    CALL_RECEIVED = 'CALL_RECEIVED',
    // for SignalInfo
    CALL_STAT_UPDATE = 'CALL_STAT_UPDATE',
    CALL_HANGUP = 'CALL_HANGUP',
    CONNECTING = 'CONNECTING',
    SWITCH_CALL = 'SWITCH_CALL',
    MERGE_CALL = 'MERGE_CALL',

    STATE_UPDATE = 'STATE_UPDATE'
}
