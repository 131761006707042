import LiveReceptionistIcon from '../icons/LiveReceptionist.svg'
import IntelligentAnswerBotIcon from '../icons/IntelligentAnswerBot.svg'
import ZohoIcon from '../icons/Zoho.svg'
import DevicesIcon from '../icons/Devices.svg'
import NumberManagerIcon from '../icons/NumberManager.svg'
import { log } from './utils'

interface AppDetails {
    averageRating: number,
    totalRatings: number,
    images?: string[],
    videos?: string[],
    overview: {
        general: string,
        note?: string
    }
}

/**
 *
 */
enum AppType {
    Console = 'CONSOLE',
    Configure = 'CONFIGURE',
    ThirdParty = 'THIRDPARTY',
    Embedded = 'EMBEDDED'
}

interface App {
    id: number,
    title: string,
    company: string,
    icon: string,
    inlineIcon?: string,
    hasInstalled: boolean,
    learnMoreText: string,
    learnMoreLink: string,
    details: AppDetails,
    userTypes: {
        nxt: {
            admin: boolean,
            nonAdmin: boolean
        },
        classic: {
            admin: boolean,
            nonAdmin: boolean
        }
    },
    url?: string,
    appType?: AppType
}

interface RouterProps {
    history: Record<string, any>
    location: Record<string, any>
    match: Record<string, any>
    staticContext?: any
}

const LiveReceptionist: App = {
    id: 1,
    title: 'Live Receptionist',
    company: 'Phone.com',
    icon: LiveReceptionistIcon,
    hasInstalled: true,
    learnMoreText: 'Learn more',
    learnMoreLink: '/live-receptionist/create/8',
    details: {
        averageRating: 4.5,
        totalRatings: 15,
        images: ['https://www.ringcentral.com/us/en/blog/wp-content/uploads/2021/07/Phone-blog.png', 'https://www.linguahouse.com/linguafiles/md5/032c7282f877b86c74e0a97d0b8bb7e8'],
        videos: ['https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4', 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4'],
        overview: {
            general: 'With Live Receptionist and Phone.com, your business can now have its very own live answering service, adding versatility, flexibility, and availability to your company no matter the size. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            note: 'The phone.com Live Receptionist agents are trained to take calls for all types of companies in various fields such as legal, medicine, and real estate.'
        }
    },
    userTypes: {
        nxt: {
            admin: true,
            nonAdmin: true
        },
        classic: {
            admin: true,
            nonAdmin: true
        }
    },
    url: '/live-receptionist/create/8'
}

const IntelligentAnswerBot = {
    id: 2,
    title: 'AI-Connect',
    company: 'Phone.com',
    icon: IntelligentAnswerBotIcon,
    hasInstalled: false,
    learnMoreText: 'Learn more',
    learnMoreLink: 'https://www.phone.com/splash-pages/add-intelligent-answer-bot/',
    details: {
        averageRating: 4.6,
        totalRatings: 20,
        images: ['https://www.ringcentral.com/us/en/blog/wp-content/uploads/2021/07/Phone-blog.png', 'https://www.linguahouse.com/linguafiles/md5/032c7282f877b86c74e0a97d0b8bb7e8'],
        videos: ['https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4', 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4'],
        overview: {
            general: 'Phone.com\'s AI-powered Intelligent Answer Bot routes callers according to your instructions 24*7 so that you can connect your callers with the right person. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        }
    },
    userTypes: {
        nxt: {
            admin: true,
            nonAdmin: true
        },
        classic: {
            admin: true,
            nonAdmin: true
        }
    },
    url: '/live-receptionist/create/4'
}

const ZohoCRM = {
    id: 3,
    title: 'Zoho CRM',
    company: 'Phone.com',
    icon: ZohoIcon,
    hasInstalled: true,
    learnMoreText: 'Learn more',
    learnMoreLink: '',
    details: {
        averageRating: 4.7,
        totalRatings: 25,
        images: ['https://www.ringcentral.com/us/en/blog/wp-content/uploads/2021/07/Phone-blog.png', 'https://www.linguahouse.com/linguafiles/md5/032c7282f877b86c74e0a97d0b8bb7e8'],
        videos: ['https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4', 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4'],
        overview: {
            general: 'Zoho CRM for Phone.com brings integrated cloud business communications and CRM together, for users to manage all the call related activities inside CRM. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        }
    },
    userTypes: {
        nxt: {
            admin: true,
            nonAdmin: true
        },
        classic: {
            admin: true,
            nonAdmin: true
        }
    },
    url: 'https://marketplace.zoho.com/app/crm/phone-com-for-zoho-crm',
    appType: AppType.Console
}

const Devices = {
    id: 4,
    title: 'Devices',
    company: 'Phone.com',
    icon: DevicesIcon,
    hasInstalled: false,
    learnMoreText: 'Learn more',
    learnMoreLink: '/phones/add-phones',
    details: {
        averageRating: 4.8,
        totalRatings: 25,
        images: ['https://www.ringcentral.com/us/en/blog/wp-content/uploads/2021/07/Phone-blog.png', 'https://www.linguahouse.com/linguafiles/md5/032c7282f877b86c74e0a97d0b8bb7e8'],
        videos: ['https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4', 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4'],
        overview: {
            general: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        }
    },
    userTypes: {
        nxt: {
            admin: true,
            nonAdmin: true
        },
        classic: {
            admin: true,
            nonAdmin: true
        }
    },
    url: '/phones/add-phones'
}

const NumberManager = {
    id: 5,
    title: 'Number Manager',
    company: 'Phone.com',
    icon: NumberManagerIcon,
    hasInstalled: false,
    learnMoreText: 'Learn more',
    learnMoreLink: '/phone-numbers/add-numbers/vanity',
    details: {
        averageRating: 4.9,
        totalRatings: 30,
        images: ['https://www.ringcentral.com/us/en/blog/wp-content/uploads/2021/07/Phone-blog.png', 'https://www.linguahouse.com/linguafiles/md5/032c7282f877b86c74e0a97d0b8bb7e8'],
        videos: ['https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4', 'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerFun.mp4'],
        overview: {
            general: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        }
    },
    userTypes: {
        nxt: {
            admin: true,
            nonAdmin: true
        },
        classic: {
            admin: true,
            nonAdmin: true
        }
    },
    url: '/phone-numbers/add-numbers/vanity'
}

const apps: App[] = [
    LiveReceptionist,
    IntelligentAnswerBot,
    ZohoCRM,
    Devices,
    NumberManager
]

const installedAppOptions = [
    {
        text: 'Uninstall',
        onClick: () => { log('Uninstall') },
        disabled: false,
        tooltipText: null
    },
    {
        text: 'Open',
        onClick: () => { log('Open') },
        disabled: false,
        tooltipText: null
    }
]
const installedAppStatus = [
    {
        content: 'Active',
        value: 1
    },
    {
        content: 'Inactive',
        value: 0
    }
]

/**
 *
 */
export { AppType, apps, installedAppOptions, installedAppStatus }
/**
 *
 */
export type { App, RouterProps }
