<template>
    <App v-if="account.item" v-bind="$attrs" v-on="$listeners">
        <AccountContacts
          v-if="page === 'account'"
          :_account="JSON.parse(JSON.stringify(account.item))"
          data-test-id="account-contacts"
        />
        <AccountSettings
          v-if="page === 'system'"
          :_account="JSON.parse(JSON.stringify(account.item))"
          data-test-id="account-contacts"
        />
    </App>
  </template>
  
  <script>
  import {vueComponent} from 'helpers';
  import { AccountContacts, AccountSettings }  from 'account-settings-components'
  import Account from '../console/src/models/Account';
  import App from '../elements/functional-components/App.vue';
  
  export default {
    props:{
      sessionId: {
        type: String,
        required: false,
      },
      page: {
        type: String,
        required: true,
        validator: (v) => ['system', 'account'].includes(v),
      },
    },
    components: {
        App,
        AccountContacts,
        AccountSettings
    },
    
    data() {
      return {
        account: new Account(this.$session, vueComponent(this)),
      };
    },
    async created() {
      await this.$data.account.getItem();
    },
  };
  </script>
  
  <style lang="scss">
      @import 'vue-apps-assets/styles/scss/styles.scss';
   .v-application--wrap {
        min-height: fit-content;
      }
  </style>