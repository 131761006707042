<template>
    <ReportsWrapper :report="report" data-test-id="call-reports-summary">
        <div>
            <v-simple-table data-test-id="summary-table">
                <tbody data-test-id="table-body">
                <template v-for="(item, i) in report.items">
                    <tr
                        v-for="key in Object.keys(item)"
                        :data-test-id="`summary-item-${i}-row`"
                        :key="`summary-item-${key}-${i}-row`"
                    >
                        <template v-if="!excluded_fields.includes(key)">
                            <th style="width:100%" :data-test-id="key">
                                {{ report.fields[key].translation }}
                            </th>
                            <td class="value" :data-test-id="`${key}-value`">{{ formatValue(item, key) }}</td>
                        </template>
                    </tr>
                </template>
                </tbody>
            </v-simple-table>
        </div>
    </ReportsWrapper>
</template>

<script>
  import Summary from '../../models/Summary';
  import ReportsWrapper from '../elements/Report.vue'
  import { vueComponent } from 'helpers';

  export default {
    components: {
        ReportsWrapper,
    },
    data() {
      return {
        excluded_fields: ['date', 'extension'],
        items: [],
        report: new Summary(this.$session, vueComponent(this)),
      };
    },
    async created() {
        await this.$data.report.loadItems();
        if (this.$data.report.items && this.$data.report.items.length) {
            // eslint-disable-next-line array-callback-return
            Object.keys(this.$data.report.items[0]).map((x) => {
                 if (!['min_call_duration', 'min_call_duration', 'total_call_duration'].includes(x)) {
                     if (!this.$data.report.items[0][x]) delete this.$data.report.items[0][x];
                 }
            });
        }
    },
    methods: {
        formatValue(item, key) {
            if (!item[key] && item[key] !== 0) return '—';
            if (['total', 'count_incoming', 'count_outgoing'].includes(key)) {
                return `${item[key]} ${item[key] === 1 ? this.$lang.t('app.call', 'call') : this.$lang.t('app.calls-lowercase', 'calls')}`;
            }
            if (['min_amount', 'avg_amount', 'max_amount', 'total_amount'].includes(key)) {
                return `$${(item[key] / 100).toFixed(2)}`;
            }

            return item[key];
        },
    }
  };
</script>

<style scoped>
    .value {
        white-space: nowrap;
        text-align: right;
    }
</style>
