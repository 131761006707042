import React, { useContext, useEffect, useState } from 'react'
import styles from './styles'
import { Grid, makeStyles } from '@material-ui/core'
import IconButton from 'icon-button-mui'
import { AddIcon, CallTransferIcon, Dialpad9Icon, PauseIcon, MicOffIcon, MicIcon as MicOnIcon, VolumeOffIcon, VolumeUpIcon } from 'svg-icons/src'
import Typography from 'typography'
import { CallAction, SoftphoneCallsContext } from '../../../SoftphoneCalls'
import { CallState, PdcCallContext } from 'pdc-calls/src'
import { useToggle } from 'hooks/src'
import { InputAudioSettings, OutputAudioSettings } from '../../../../AudioSettings'
import { FeatureEventsContext } from 'providers/src'

const useStyles = makeStyles(styles)

interface ControlButtonProps{
    disabled?: boolean
    onClick?: () => void
}
function AddCallButton (props: ControlButtonProps): JSX.Element {
    const classes = useStyles()
    const Calls = useContext(SoftphoneCallsContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    const onClick = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'call-controls-add-call-button-click' })
        Calls.OpenCallAction(CallAction.AddCall)
    }
    return (<IconButton
        disabled={props.disabled}
        className={`${classes.controlButton} ${props.disabled ? 'disabled' : ''} disable-dragging`}
        onClick={onClick}
        data-test-id='softphone-keys-add-call-button'
    >
        <div className='control-button-wrapper'>
            <AddIcon className='control-button-icon'/>
            <Typography variant='overline'>ADD</Typography>
        </div>
    </IconButton>)
}
function TransferCallButton (props: ControlButtonProps): JSX.Element {
    const classes = useStyles()
    const Calls = useContext(SoftphoneCallsContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    const onClick = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'call-controls-transfer-call-button-click' })
        Calls.OpenCallAction(CallAction.TransferCall)
    }
    return (<IconButton
        disabled={props.disabled}
        className={`${classes.controlButton} ${props.disabled ? 'disabled' : ''} disable-dragging`}
        onClick={onClick}
        data-test-id='softphone-keys-transfer-button'
    >
        <div className='control-button-wrapper'>
            <CallTransferIcon className='control-button-icon'/>
            <Typography variant='overline'>TRANSFER</Typography>
        </div>
    </IconButton>)
}
function HoldCallButton (props: ControlButtonProps): JSX.Element {
    const classes = useStyles()
    const PdcCall: any = useContext(PdcCallContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    const callId = PdcCall.activeCallId
    const callIsOnHold = () => { return PdcCall.callsOnHold.findIndex(c => c.callId === callId) >= 0 }
    const [onHold, toggleOnHold] = useToggle(callIsOnHold())
    useEffect(() => { toggleOnHold(callIsOnHold()) }, [PdcCall.callsOnHold, PdcCall.activeCallId])
    const onClick = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'call-controls-hold-button-click', value: !onHold })
        if (onHold) PdcCall.unhold()
        else PdcCall.hold()
        toggleOnHold(callIsOnHold())
    }
    return (<IconButton
        disabled={props.disabled}
        className={`${classes.controlButton} ${onHold ? 'onHold' : ''} ${props.disabled ? 'disabled' : ''} disable-dragging`}
        onClick={onClick}
        data-test-id='softphone-keys-hold-button'
    >
        <div className={`control-button-wrapper ${onHold ? 'onHold' : ''}`}>
            <PauseIcon className='control-button-icon'/>
            <Typography variant='overline'>{onHold ? 'UN' : ''}HOLD</Typography>
        </div>
    </IconButton>)
}
function KeypadToggleButton (props: ControlButtonProps): JSX.Element {
    const classes = useStyles()
    return (<IconButton
        disabled={props.disabled}
        className={`${classes.controlButton} ${props.disabled ? 'disabled' : ''} disable-dragging`}
        onClick={props.onClick}
        data-test-id='softphone-keys-keypad-button'
    >
        <div className='control-button-wrapper'>
            <Dialpad9Icon className='control-button-icon'/>
            <Typography variant='overline'>KEYPAD</Typography>
        </div>
    </IconButton>)
}
function AudioInputControl (props: ControlButtonProps): JSX.Element {
    const classes = useStyles()
    const PdcCall: any = useContext(PdcCallContext)
    const [muted, toggleMute] = useToggle(PdcCall.isMutedLocal)
    const featureEventsContext = useContext(FeatureEventsContext)
    const onClick = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'call-controls-mute-microphone-click', value: !muted })
        PdcCall.muteLocal(!muted)
    }
    const MicIcon = muted ? MicOffIcon : MicOnIcon
    const onMuteRemoteChange = () => toggleMute(PdcCall.isMutedLocal)
    useEffect(onMuteRemoteChange, [PdcCall.isMutedLocal])
    return (<IconButton
        disabled={props.disabled}
        className={`${classes.controlButton} ${muted ? 'muted' : ''} ${props.disabled ? 'disabled' : ''} disable-dragging`}
        onClick={onClick}
        data-test-id='softphone-keys-mute-button'
    >
        <div className='control-button-wrapper'>
            <MicIcon className='control-button-icon'/>
            <Typography variant='overline'>MUTE</Typography>
        </div>
    </IconButton>)
}
function AudioOutputContol (props: ControlButtonProps): JSX.Element {
    const classes = useStyles()
    const PdcCall: any = useContext(PdcCallContext)
    const [muted, toggleMute] = useToggle(PdcCall.isMutedRemote)
    const featureEventsContext = useContext(FeatureEventsContext)
    const onClick = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'call-controls-mute-speaker-click', value: !muted })
        PdcCall.muteRemote(!muted)
    }
    const AudioIcon = muted ? VolumeOffIcon : VolumeUpIcon
    const onMuteRemoteChange = () => toggleMute(PdcCall.isMutedRemote)
    useEffect(onMuteRemoteChange, [PdcCall.isMutedRemote])
    return (<IconButton
        disabled={props.disabled}
        className={`${classes.controlButton} ${muted ? 'muted' : ''} ${props.disabled ? 'disabled' : ''} disable-dragging`}
        onClick={onClick}
        data-test-id='softphone-keys-audio-button'
    >
        <div className='control-button-wrapper'>
            <AudioIcon className='control-button-icon'/>
            <Typography variant='overline'>AUDIO</Typography>
        </div>
    </IconButton>)
}
interface CallControlsProps{
    openKeypad: () => void
    disableActions?: boolean
}
/**
 *
 */
export default function CallControls (props: CallControlsProps): JSX.Element {
    const classes = useStyles()
    const PdcCall: any = useContext(PdcCallContext)
    const callId = PdcCall.activeCallId
    const [call, setActiveCall] = useState(PdcCall.calls[callId])
    const [audioSettingsAnchorEl, setAudioSettingsAnchorEl] = useState<HTMLDivElement | null>(null)
    const featureEventsContext = useContext(FeatureEventsContext)
    useEffect(() => {
        const callId = PdcCall.activeCallId
        setActiveCall(PdcCall.calls[callId] || null)
    }, [PdcCall.activeCallId, PdcCall.calls[PdcCall.activeCallId], PdcCall.callsCnt])
    const [disabled, toggleDisabled] = useToggle(call?.callState !== CallState.ACTIVE)
    const OnCallStateUpdate = () => toggleDisabled(call?.callState !== CallState.ACTIVE)
    useEffect(OnCallStateUpdate, [call?.callState])
    const isConference = call?.participants.length > 1
    const disableTransferCall = disabled || isConference || call?.isMerged || props.disableActions
    const disableAddCall = disabled || props.disableActions
    const disableKeypad = disabled
    if (call === null) return (<></>)
    return (<Grid container className={classes.callControlsWrapper} >
        <div className={classes.audioSettingsAnchor} ref={element => setAudioSettingsAnchorEl(element as HTMLDivElement)}/>
        <Grid item xs={4}>
            <div className={classes.audioControlWrapper}>
                <div className={`${classes.audioSettings} input`}>
                    <InputAudioSettings anchorEl={audioSettingsAnchorEl as HTMLDivElement}/>
                </div>
                <AudioInputControl disabled={disabled}/>
            </div>
        </Grid>
        <Grid item xs={4}>
            <KeypadToggleButton
                onClick={() => {
                    featureEventsContext.pushEvent({ appName: 'softphone', key: 'call-controls-keypad-click' })
                    props.openKeypad()
                }}
                disabled = {disableKeypad}
            />
        </Grid>
        <Grid item xs={4}>
            <div className={classes.audioControlWrapper}>
                <div className={`${classes.audioSettings} output`}>
                    <OutputAudioSettings anchorEl={audioSettingsAnchorEl as HTMLDivElement}/>
                </div>
                <AudioOutputContol disabled={disabled}/>
            </div>
        </Grid>
        <Grid item xs={4}>
            <AddCallButton disabled={disableAddCall}/>
        </Grid>
        <Grid item xs={4}>
            <HoldCallButton disabled={disabled}/>
        </Grid>
        <Grid item xs={4}>
            <TransferCallButton disabled={disableTransferCall}/>
        </Grid>
    </Grid>)
}
