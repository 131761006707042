<template>
  <ReportsWrapper :report="report" data-test-id="call-reports-total">
    <div class="mt-2">
        <TotalChart :items="report.items" :group_by="report.filters.group_by" :key="`logarithmic-key-${!!logarithmic}`"/>
        <!-- <LogarithmicSwitcher v-model="logarithmic"/> -->
        <v-simple-table v-if="report.filters.group_by" class="mt-3 nowrap" data-test-id="total-table">
            <thead data-test-id="table-head">
              <tr data-test-id="head-row">
                  <th data-test-id="incoming">{{ $lang.t('app.incoming', 'Incoming') }}</th>
                  <th style="width: 100%" data-test-id="outgoing">{{ $lang.t('app.outgoing', 'Outgoing') }}</th>
                  <th data-test-id="fitler">{{ upperFirstCase(report.filters.group_by) }}</th>
              </tr>
            </thead>
            <tbody data-test-id="table-body">
              <tr v-for="(item, i) in report.items" :data-test-id="`total-item-${i}-row`" :key="`total-item-${i}-row`">
                  <td data-test-id="incoming">{{ item.incoming }}</td>
                  <td data-test-id="outgoing">{{ item.outgoing }}</td>
                  <td data-test-id="value">
                    {{ report.formatTime(item[report.filters.group_by]) }}
                    <span v-if="report.filters.group_by === 'extension' && typeof report.filters.group_by === 'string' && item[report.filters.group_by][0] === '#'">
                      <br/>
                      {{ $lang.t('app.(deleted)', '(deleted)') }}
                    </span>
                  </td>
              </tr>
            </tbody>
        </v-simple-table>
    </div>
  </ReportsWrapper>
</template>

<script>
  import { vueComponent } from 'helpers';
  import { upperFirstCase} from 'formatters'
  import Total from '../../models/Total';
  import ReportsWrapper from '../elements/Report.vue'
  import TotalChart from '../elements/charts/Total.vue'
  // import LogarithmicSwitcher from 'console/src/components/elements/logarithmic-representation-switcher.vue';

  export default {
    components: {
      TotalChart,
      ReportsWrapper,
      // LogarithmicSwitcher,
    },
    data() {
      return {
        logarithmic: false,
        report: new Total(this.$session, vueComponent(this)),
      };
    },
    async created() {
      await this.$data.report.loadItems();
    },
    methods: {
      upperFirstCase(val) {
        return upperFirstCase(val).split('_').join(' ');
      },
    },
  };
</script>
