import React, { Component } from 'react'

import HeaderTabs from 'header-tabs'
import IncomingCalls from './IncomingCalls/IncomingCalls'
import Voicemail from './Voicemail/Voicemail'
import Notifications from './Notifications/Notifications.tsx'
import UserProfile from './UserProfile/UserProfile'
import AppWrapper from 'app-wrapper'
import ConfirmModal from 'confirm-modal'
import { ProfileIcon, IncomingCallsIcon, VoicemailTabIcon, NotificationIcon } from 'pdc-svg-icons'
import gtmDataPush from 'gtm-events'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
// import { ReplyAllIcon } from 'svg-icons'
import { getPhoneCom } from 'phonecom'
import PhoneComUser from 'phone-com-user'

const styles = theme => ({
    wrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto'
    },
    appWrapper: {
        padding: '50px 65px 65px',
        '&.tablet-view': {
            padding: '30px 50px 65px'
        },
        '&.mobile-view': {
            padding: '30px 25px 50px'
        }

    }
})

const GTM_APP_NAME = 'personal-settings'
const GTM_MAP = { TAB_SELECT: 1, SAME_TAB_SELECT: 0, DISCARD_CHANGES: 1, REJECT_DISCARD: 0 }

// const tabs = [
// { id: 'profile', icon: ProfileIcon, default: true },
// { id: 'incoming-calls', icon: IncomingCallsIcon },
// { id: 'voicemail', icon: VoicemailTabIcon }
// ]
class App extends Component {
    constructor (props) {
        super(props)
        this.state = { isBusy: false, tabs: [] }
        if (props.extension && props.extension.extension_id !== PhoneComUser.getExtensionId()) {
            PhoneComUser.changeExtension(props.extension.extension_id)
        }
    }

    getCurrentTabFromRoute = () => {
        const pathSplit = this.props.routeProps.location.pathname.split('personal-settings')
        const afterSettings = pathSplit.pop()
        if (!afterSettings) return
        const tabName = afterSettings.split('/').filter(e => e)[0]
        if (!tabName) return
        if (!this.state.tabs.map(t => t.id).includes(tabName)) return
        return tabName
    }

    componentDidMount = async () => {
        // This goes up to Communicator.js in order to hide the loading animation
        this.props.onLoaded()
        this.phoneCom = await getPhoneCom()
        this.setState({ tabs: this.fillTabsForPersonalSettings() })
        this.fixRoutePath()
    }

    fixRoutePath = () => {
        const currentTab = this.getCurrentTabFromRoute()
        if (!currentTab && this.state.tabs.length) {
            const currentTab = this.state.tabs.find(t => t.default).id
            this.updateRouterHistory(currentTab)
        }
    }

    componentDidUpdate = () => {
        this.fixRoutePath()
    }

    onTabSwitch = newTab => {
        const currentTab = this.getCurrentTabFromRoute()
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: `tab-click;${currentTab}->${newTab}`,
            PDC_Value: newTab !== currentTab ? GTM_MAP.TAB_SELECT : GTM_MAP.SAME_TAB_SELECT
        })
        if (currentTab === newTab) return
        if (this.state.isBusy) {
            this.setState({ exitAttempt: newTab })
            return false
        }
        this.updateRouterHistory(newTab)
    }

    updateRouterHistory = tabName => {
        // let extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
        // if (this.props.origin === 'configure-app') {
        //     extensionId = this.props.extension.extension_id
        // }
        this.props.routeProps.history.push(`${this.getBasePath()}/${tabName}`)
    }

    setBusy = isBusy => {
        this.setState({ isBusy })
        this.props.setHasChange(isBusy)
    }

     fillTabsForPersonalSettings= () => {
         //  const isBJCompanyExtensionSelected = Boolean(this.phoneCom.user_id)
         const hasUserId = Boolean(this.phoneCom.user_id)
         const tabs = []

         if (hasUserId) {
             tabs.push({ id: 'profile', icon: ProfileIcon, default: true })
             tabs.push({ id: 'incoming-calls', icon: IncomingCallsIcon })
             tabs.push({ id: 'voicemail', icon: VoicemailTabIcon })
         }
         tabs.push({ id: 'notifications', icon: NotificationIcon, default: true })
         return tabs
     }

    onExitAttemptAnswer = () => { /**/ }

    getBasePath = () => {
        const routerMatch = this.props.routeProps.match
        const extensionId = routerMatch.params.extensionId
        const basePath = routerMatch.path
            .replace(/\/+$/g, '')
            .replace(':extensionId', extensionId)
        return basePath
    }

    renderApp = () => {
        const basePath = this.getBasePath()
        return (
            <Switch>
                <Route path={`${basePath}/profile`} render={props => (
                    <UserProfile extension={this.props.extension} setBusy={this.setBusy}/>
                )}/>
                <Route path={`${basePath}/incoming-calls`} render={props => (
                    <AppWrapper>
                        <IncomingCalls setBusy={this.setBusy} />
                    </AppWrapper>
                )}/>
                <Route path={`${basePath}/voicemail`} render={props => (
                    <AppWrapper>
                        <Voicemail routeProps={props} setBusy={this.setBusy} />
                    </AppWrapper>
                )}/>
                <Route path={`${basePath}/notifications`} render={props => (
                    <AppWrapper>
                        <Notifications setBusy={this.setBusy} origin={this.props.origin} />
                    </AppWrapper>
                )}/>
            </Switch>
        )
    }

    discardChanges = () => {
        const newTab = this.state.exitAttempt
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: `discard-changes;${this.getCurrentTabFromRoute()}->${newTab}`,
            PDC_Value: GTM_MAP.DISCARD_CHANGES
        })
        this.setBusy(false)
        this.setState({ exitAttempt: null }, () => this.onTabSwitch(newTab))
    }

    renderDiscardChangesModal = () => {
        const newTab = this.state.exitAttempt
        const onReject = () => {
            gtmDataPush({
                PDC_Action: GTM_APP_NAME,
                PDC_Label: `discard-changes;${this.getCurrentTabFromRoute()}->${newTab}`,
                PDC_Value: GTM_MAP.REJECT_DISCARD
            })
            this.setState({ exitAttempt: null })
        }
        return (
            <ConfirmModal
                isShown = {Boolean(newTab)}
                title = 'Discard changes?'
                content = {null}
                noButtonText = 'Cancel'
                yesButtonText = 'Discard'
                yesButtonColor = 'attention'
                onReject = {onReject}
                onConfirm = {this.discardChanges}
                size = 'size2' // size440
            />
        )
    }

    render = () => {
        const { classes } = this.props
        const currentTab = this.getCurrentTabFromRoute()
        return (
            <div className={classes.wrapper}>
                {currentTab ? <HeaderTabs selectedTabId={currentTab} tabs={this.state.tabs} onTabSwitch={this.onTabSwitch}/> : null}
                {this.renderApp()}
                {this.renderDiscardChangesModal()}
            </div>
        )
    }
}

App.propTypes = {
    // Material UI classes
    classes: PropTypes.any,
    extension: PropTypes.any,
    setHasChange: PropTypes.func,
    routeProps: PropTypes.object,
    onLoaded: PropTypes.func,
    origin: PropTypes.string,
    userInfo: PropTypes.object
}

export default withStyles(styles)(App)
