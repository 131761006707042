import React, { Component, isValidElement, cloneElement } from 'react'
import InfiniteScroller from 'pdc-infinite-scroller'
import Spinner from 'spinner'

const ITEM_HEIGHT = 75 // Hardcoded for now
const NUM_SHOWN_OUT_OF_VIEW = 4

const PlaceholderDiv = props => <div style={{height: props.height}}/>

class ScrollerWrapper extends Component {

	childrenRefs = {}

	componentDidMount() {
		this.totalItems = this.props.items.length
	}

	componentDidUpdate(prevProps) {
		let hasNumItemsChange	= this.totalItems < this.props.items.length
		let willCollapse		= this.props.willCollapseIndex !== null && this.props.willCollapseIndex !== prevProps.willCollapseIndex
		let recalculate			= prevProps.recalculateScroll !== this.props.recalculateScroll
		if (this.scroller && (willCollapse || hasNumItemsChange || recalculate)) {
			this.manageViewItems()
		}
		this.scrollToItem(prevProps)
	}

	scrollToItem = prevProps => {
		if (!this.props.scrollItemIndex) return
		if (this.props.scrollItemIndex === prevProps.scrollItemIndex) return
		let itemRef = this.childrenRefs[this.props.scrollItemIndex]
		if (!itemRef) return
		itemRef.element.scrollIntoView({ behavior: 'smooth', block: 'center' })
	}

	getDOMElementHeight = element => {
		let elementStyles = window.getComputedStyle(element)
		let height = parseInt(elementStyles.height)
		let marginTop = parseInt(elementStyles.marginTop)
		let marginBottom = parseInt(elementStyles.marginBottom)
		return height + Math.max(marginTop, marginBottom)
	}

	getItemHeight = index => {
		if (!this.childrenRefs[index]) return ITEM_HEIGHT
		let height		= this.childrenRefs[index].height
		let oldHeight	= this.childrenRefs[index].oldHeight
		if (!height && oldHeight) height = oldHeight
		return height
	}

	getItemsHeight = (direction, index) => {
		let totalHeight = 0
		if (!direction) {
			for (let i = 0; i < index; i++) {
				totalHeight += this.getItemHeight(i)
			}
		} else {
			let lastIndex = Object.keys(this.childrenRefs).length - 1
			for (let i = index + 1; i <= lastIndex; i++) {
				totalHeight += this.getItemHeight(i)
			}
		}
		return totalHeight
	}

	getFirstIndexInView = scrollTop => {
		let totalPixels = 0
		let firstIndexInView = -1
		Object.keys(this.childrenRefs).forEach(index => {
			index = parseInt(index)
			if (firstIndexInView !== -1) return
			let childHeight = this.getItemHeight(index)
			if (childHeight + totalPixels > scrollTop) firstIndexInView = index
			totalPixels += childHeight
		})
		return firstIndexInView
	}

	getLastIndexInView = (scrollTop, windowHeight) => {
		let willCollapseIndex = this.props.willCollapseIndex
		let totalPixels = 0
		let lastIndexInView = 0
		Object.keys(this.childrenRefs).forEach(index => {
			index = parseInt(index)
			if (willCollapseIndex === index) return
			let childHeight = this.getItemHeight(index)
			if (childHeight + totalPixels > scrollTop + windowHeight) return
			lastIndexInView = index + 1
			totalPixels += childHeight
		})
		return lastIndexInView
	}

	manageViewItems = scroller => {

		if (!this.props.smallView) return

		if (scroller) this.scroller = scroller

		let scrollTop = this.scroller.scrollTop

		if (!this.scrollTop && this.scrollTop !== 0) {
			return this.scrollTop = scrollTop
		}

		let scrollPX = Math.abs(this.scrollTop - scrollTop)
		if (scroller && scrollPX < ITEM_HEIGHT) return

		let windowHeight		= this.scroller.offsetHeight
		let allItems			= JSON.parse(JSON.stringify(this.props.items))
		let firstIndexInView	= this.getFirstIndexInView(scrollTop) - NUM_SHOWN_OUT_OF_VIEW
		let lastIndexInView		= this.getLastIndexInView(scrollTop, windowHeight) + NUM_SHOWN_OUT_OF_VIEW
		if (firstIndexInView < 0) firstIndexInView = 0
		if (lastIndexInView > allItems.length - 1) lastIndexInView = allItems.length - 1

		allItems.forEach((item, index) => {
			if (index < firstIndexInView) {
				if (index === firstIndexInView - 1) {
					item.placeholderHeight = this.getItemsHeight(false, firstIndexInView)
					delete item.dontShow
				} else {
					item.dontShow = true
					delete item.placeholderHeight
				}
			} else if (index >= firstIndexInView && index <= lastIndexInView) {
				delete item.dontShow
				delete item.placeholderHeight
			} else { // index > lastIndexInView
				if (index === lastIndexInView + 1) {
					item.placeholderHeight = this.getItemsHeight(true, lastIndexInView)
					delete item.dontShow
				} else {
					item.dontShow = true
					delete item.placeholderHeight
				}
			}
		})

		this.totalItems	= allItems.length
		this.props.updateItems(allItems)
		this.scrollTop = scrollTop
	}

	setChildRef = (element, index) => {
		const setRef = f => {
			if (!element) return
			let childRef	= this.childrenRefs[index]
			let oldHeight	= childRef ? childRef.height : null
			let height		= this.getDOMElementHeight(element)
			if (f && !height) return
			if (childRef && childRef.oldHeight === oldHeight && childRef.height === height) {
				return
			}
			this.childrenRefs[index] = {element, oldHeight, height}
		}
		setRef()
		setTimeout(() => setRef(1), 1000)
	}

	render() {
		return (
			<InfiniteScroller
				reverseScroll	= {this.props.reverseScroll}
				loadMore		= {this.props.loadMore}
				hasMore			= {this.props.hasMore}
				loader			= {<Spinner/>}
				data-test-id	= {this.props['data-test-id'] || ''}
				onScroll		= {this.manageViewItems}
			>
				{this.props.smallView ? this.props.children.map((child, i) => {
					if (child.props.dontShow) return null
					if (child.props.placeholderHeight) {
						return <PlaceholderDiv key={child.key} height={child.props.placeholderHeight}/>
						// return <div key={child.key} style={{height: child.props.placeholderHeight}}/>
					}
					if (isValidElement(child)) {
						return cloneElement(child, { setRef: element => this.setChildRef(element, i) })
					}
					return child
				})
				: this.props.children.map((child, i) => {
					if (isValidElement(child)) {
						return cloneElement(child, { setRef: element => this.setChildRef(element, i) })
					}
					return child
				})}
			</InfiniteScroller>
		)
	}
}

export default ScrollerWrapper