import l from '../lang';

export default {
	'routes': {
		'create': 'groups.create',
		'show': 'groups.show',
		'index': 'groups.index',
	},
	'delete-single': l.t('groups.delete-single', 'Are you sure you want to delete this group?'),
	'delete-filtered': l.t('groups.delete-filtered', 'Are you sure you want to delete all filtered groups?'),
	'delete-all': l.t('groups.delete-all', 'Are you sure you want to delete all groups?'),
	'successfull-creation': l.t('groups.successfully-created', 'Your group has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('groups.failed-creation', 'There was a problem creating your group'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
	'currently-in-use': l.t('groups.this-group-is-currently-in-use', 'This group is currently in use'),
	'to-delete-used': l.t('groups.to-delete-this-group-remove-it', 'To delete this group, remove it from your call handling rules first, then try again.'),
};
