import { useState, useEffect } from 'react'

type ProgressiveImgType = [string, { imgLoading: boolean }]

/**
 *
 */
const useProgressiveImg = (lowQualitySrc: string, highQualitySrc: string): ProgressiveImgType => {
    const [src, setSrc] = useState(lowQualitySrc)

    useEffect(() => {
        const img = new Image()
        img.src = highQualitySrc
        img.onload = () => {
            setSrc(highQualitySrc)
        }
    }, [])

    return [src, { imgLoading: src === lowQualitySrc }]
}

export default useProgressiveImg
