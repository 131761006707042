<template>
  <div class="extensions">
    <Submenu
      v-if="routes && !$route.name.includes('visualizer') && !show_routes.includes($route.name)"
      :routes="routes"
      :key="`${l.detect()}${$route.name}`"
    />
    <ExtensionsFragment
      v-if="$route.name === 'extensions.index'"
      data-test-id="extensions-index"
    />
    <ExtensionsCreateFragment
      v-if="$route.name === 'extensions.create'"
      data-test-id="extensions-create"
    />
    <ExtensionsShowFragment
      v-if="show_routes.includes($route.name) && !$route.name.includes('visualizer')"
      data-test-id="extensions-show"
    />
    <Visualizer
    v-if="$route.name.includes('visualizer')"
      data-test-id="extensions-visualizer"
    />
  </div>
</template>

<script>
import l from '../../../libs/lang';
import ExtensionsFragment from './index.vue';
import ExtensionsShowFragment from './show.vue';
import Submenu from '../../elements/Submenu.vue';
import ExtensionsCreateFragment from './create.vue';
import Visualizer from '../visualizer/index.vue';

export default {
  components: {
    Submenu,
    ExtensionsFragment,
    ExtensionsCreateFragment,
    ExtensionsShowFragment,
    Visualizer,
  },
  data() {
    // const show_routes = {
    //   'extensions.show': { title: l.t('app.settings', 'Settings')},
    //   'extensions.visualizer': { title: l.t('call-handling.visualizer', 'Visualizer')},
    //   'extensions.call-flow': { title: l.t('call-handling.call-handling', 'Call Handling')},
    //   'extensions.type': { title: l.t('app.type', 'Type')},
    // };
    const show_routes = [
      'extensions.show',
      'extensions.visualizer',
      'extensions.call-flow',
      'extensions.type',
    ];
    const routes = {
      'extensions.index': {
        title: l.t('app.extensions', 'Extensions')
        },
      'extensions.visualizer-default': {
        title: l.t('app.visualizer', 'Visualizer')
        },
      'extensions.create': {
        title: l.t('app.add-extension', 'Add extension')
        },
    };
    // if (Object.keys(show_routes).includes(this.$route.name)) {
    //   routes = {...show_routes};
    // }
    return {
      l,
      show_routes,
      routes,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (window.deleting_in_progress) {
      this.$emit('userRedirected', to, from, next);
    } else {
      next();
    }
  },
};
</script>
