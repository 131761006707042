<template>
<div>
<div class="w-h4 font-weight-400">{{title || $lang.t('app.receptionist-scripts', 'Receptionist scripts')}}</div>
  <div class="mt-5 text-justify lr-help-text">
      <HelpText class="py-0">
        {{ $lang.t('lr.setting-up-receptionist-services-intelligent-answer-bot', 'Setting up receptionist services is a two-step process. First, you will create a script that will tell your Live{} how to handle your calls. Next, you will add your script to the call-handling rules for any of your phone numbers, extensions, or menus.', [!['avo', 'avo_master'].includes($branding.data.code) ? $lang.t('lr.or-virtual-with-leading-space', ' or the AI-Connect') : ''])}}
      </HelpText>
  </div>
  <DynamicFilters class="mt-10 d-flex justify-space-between">
    <DynamicFilterInput
      v-model="la.filters.name"
      :model="la"
      :disabled="la.loading"
      maxlength="256"
      :validation="[$validation.maxlength(256)]"
    />
    <w-btn @click="show_vendor_modal = true" class="success">
      {{$lang.t('lr.create-a-new-script', 'Create a new script')}}
    </w-btn>
  </DynamicFilters>
  <IndexPage
    :resource="la"
    :disable_add_new="true"
    :disable_filters="true"
    :disable_mass_operations="true"
    class="live-answer list-page"
  >
    <template v-slot:list>
      <v-simple-table class="list-table" data-test-id="lr-table">
        <thead data-test-id="table-head">
          <tr data-test-id="head-row">
           <th class="px-1" data-test-id="id">{{ $lang.t('app.id', 'ID') }}</th>
           <th data-test-id="name">{{ $lang.t('app.name', 'Name') }}</th>
           <th>{{$lang.t('app.phone-numbers', 'Phone numbers')}}</th>
           <th data-test-id="type">{{ $lang.t('live-answer.service-type', 'Service type') }}</th>
           <th data-test-id="appointments">{{ $lang.t('live-answer.appointments', 'Appointments') }}</th>
           <th data-test-id="greting">{{ $lang.t('app.greeting', 'Greeting') }}</th>
           <th data-test-id="status">{{ $lang.t('app.status', 'Status') }}</th>
           <th data-test-id="more-options"></th>
          </tr>
        </thead>
        <tbody data-test-id="table-body">
          <tr
          v-for="(item, i) of la.items"
          :key="`${item.id}-${Number(item.enabled)}`"
          :class="{ 'selected-row grey--text': !item.enabled }"
          :data-test-id="item.id"
          >
            <td class="px-1" :data-test-id="`lr-item-${item.id}-id-link`">
              <w-tooltip top small>
                <template v-slot:activator>
                  {{ item.id }}
                </template>
                {{ $lang.t('app.popup-id', 'Pop up ID') }}: {{ item.popup_id }}
              </w-tooltip>
            </td>
            <w-link
              tag="td"
              :to="{ name: 'live-answer.show', params: {id: item.id, _item: item } }"
              class="list-link"
              :data-test-id="`lr-item-${item.id}-name-link`"
            >
             {{ item.name }}
          </w-link>
            <td>
              <RelatedPhoneNumbers :id="item.id"/>
            </td>
            <td
              :data-test-id="`lr-item-${item.id}-vendor-link`"
            >
             {{ item.vendor ? getTypeServiceName(item.vendor) : '—' }}
            </td>
            <td
              :data-test-id="`lr-item-${item.id}-app-link`"
            >
             {{ item.appointments_enabled ? $lang.t('app.yes', 'Yes') : $lang.t('app.no', 'No') }}
            </td>
            <td
              :data-test-id="`lr-item-${item.id}-greeting-link`"
            >
             {{ item.greeting || '—' }}
            </td>
            <td
              :data-test-id="`lr-item-${item.id}-status-link`"
            >
            <v-icon size="60" :color="item.enabled ? 'info base' : 'error lighten1'">
              {{item.enabled ? '$vuetify.icons.enabled' : '$vuetify.icons.disabled'}}
            </v-icon>
            </td>
            <td :key="item['id']" class="minimal-width" :data-test-id="`lr-item-${item.id}-reactivate`">
              <MoreOptions :options="more_options(item)"/>
              <EnableScriptModal
                v-if="confirm_enable_script === item.id"
                :vendor_id="item.vendor.id"
                @close="confirm_enable_script = false"
                @confirmed="reactivate(item['id'])"
                :key="`enable-${item['id']}`"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
       <NxtRedirectionModal v-model="show_nxt_redirection_modal"/>
    </template>
  </IndexPage>
  <DisableScriptModal
    v-if="script_to_disable"
    :callback="la.deactivate.bind(la)"
    :value="true"
    :model="la"
    @processed="on_disabled"
    @input="(v) => !v ? script_to_disable = null : null"
    :item="script_to_disable"
    @close="script_to_disable = null"
    :key="`disable-${script_to_disable['id']}`"
  />
  <DeleteDisableScriptModal
    v-if="script_to_delete"
    :width="600"
    :callback="la.delete_item.bind(la)"
    :value="true"
    :model="la"
    :btn_text="$lang.t('app.delete-script', 'Delete script')"
    :success_message="$lang.t('lr.successfully-deleted', 'Successfully deleted')"
    @input="(v) => !v ? script_to_delete = null : null"
    :item="script_to_delete"
    @close="script_to_delete = null"
    :key="`delete-${script_to_delete['id']}`"
  >
    <template v-slot:title>
        {{$lang.t('app.delete-script', 'Delete script')}}
    </template>
    <template v-slot:question>
      {{ $lang.t('lr.are-you-sure-you-want-to-proceed-with-deleting-this-script', 'Are you sure you want to proceed with deleting this script?') }}
    </template>
  </DeleteDisableScriptModal>
  <ChooseVendorModal v-model="show_vendor_modal" @click:on-vendor="on_vendor_click"/>
</div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import {is_nxt} from 'phoenix-session-helpers';
  import {getFeatureEnabled} from 'feature-flag';

  import ChooseVendorModal from '../elements/ChooseVendorModal';
  import LiveAnswer from '../../../console/src/models/LiveAnswer';
  import EnableScriptModal from '../elements/EnableScriptModal.vue';
  import DisableScriptModal from '../elements/DisableScriptModal.vue';
  import NxtRedirectionModal from '../elements/NxtRedirectionModal.vue';
  import RelatedPhoneNumbers from '../elements/RelatedPhoneNumbers.vue';
  import LiveAnswerVendor from '../../../console/src/models/LiveAnswerVendor';
  import HelpText from '../../../console/src/components/elements/HelpText.vue';
  import DeleteDisableScriptModal from '../elements/DeleteDisableScriptModal.vue';
  import MoreOptions from '../../../console/src/components/elements/MoreOptions.vue';
  import FormInput from '../../../console/src/components/elements/form/FormInput.vue';
  import DynamicFilters from '../../../console/src/components/elements/DynamicFilters.vue';
  import CopyToClipboard from '../../../console/src/components/elements/CopyToClipboard.vue';
  import EventBus from 'event-bus';
  import DynamicFilterInput from 'console/src/components/elements/form/DynamicFilterInput.vue'

  export default {
    props: {
      vendor_id: {
        type: Number,
        required: false,
      },
      onCreateLinkClickCb: {
        type: Function,
      },
      title: {
        type: String,
        required: false,
      }
    },
    components: {
      HelpText,
      DynamicFilterInput,
      FormInput,
      MoreOptions,
      DynamicFilters,
      CopyToClipboard,
      EnableScriptModal,
      ChooseVendorModal,
      DisableScriptModal,
      RelatedPhoneNumbers,
      NxtRedirectionModal,
      DeleteDisableScriptModal,
      IndexPage: () => import('../../../console/src/components/elements/IndexPage.vue'),
    },
    data() {
      const la = new LiveAnswer(this.$session, vueComponent(this));
      if (this.$props.vendor_id) la.filters.vendor_id = this.$props.vendor_id;

      return {
        la: new LiveAnswer(this.$session, vueComponent(this)),
        deleting_item_id: null,
        confirm_enable_script: false,
        script_to_disable: false,
        script_to_delete: null,
        show_nxt_redirection_modal: false,
        user_can_delete_script: false,
        show_vendor_modal: false,
        vendor: new LiveAnswerVendor(),
      };
    },
    async created() {
      this.$data.user_can_delete_script = await getFeatureEnabled('console.delete_receptionist_script');
      // this.$data.user_can_delete_script = await getFeatureEnabled('console.i_dont_know');
      await this.$data.vendor.init();
    },
    async mounted() {
      if (
        this.$route
        && this.$route.params
        && this.$route.params.redirection_after_creation
        && await is_nxt(this.$session)
      ) {
        this.$data.show_nxt_redirection_modal = true;
      }
      this.$parent.$on('userRedirected', (to, from, next) => {
        this.$data.la.handleUserRedirect(to, from, next);
      });
    },
    methods: {
      more_options(item) {
        const options = [];
        options.push({
          title: this.$lang.t('lr.edit-script', 'Edit script'),
          cb: () => this.$router ?
            this.$router.push({ name: 'live-answer.show', params: {id: item.id, _item: item } })
            :
            EventBus.$emit('click:link', { name: 'live-answer.show', params: {id: item.id, _item: item } })
        });
        if (!item.enabled) {
          options.push({
            cb: () => this.$data.confirm_enable_script = item.id,
            title: this.$lang.t('lr.enable-script', 'Enable script'),
          });
        } else {
          options.push({
            cb: () => this.$data.script_to_disable = item,
            title: this.$lang.t('lr.disable-script', 'Disable script'),
          });
        }
        if (this.$data.user_can_delete_script) {
          options.push({
            cb: () => this.$data.script_to_delete = item,
            title: this.$lang.t('lr.delete-script', 'Delete script'),
          });
        }
        return options;
      },
      getTypeServiceName(item) {
        return this.$data.vendor.vendorName(item.id);
      },
      async reactivate(id) {
        this.$data.confirm_enable_script = false;
        await this.$data.la.reactivate(id);
        if (this.$data.la.alert.level === 'success') {
          const index = this.$data.la.items.findIndex((x) => x.id === id);
          if (index > -1) this.$data.la.items[index].enabled = true;
        }
      },
      on_disabled(id) {
        const index = this.$data.la.items.findIndex((x) => x.id === id);
        if (index > -1) this.$data.la.items[index].enabled = false;
        this.$data.script_to_disable = null;
        this.$forceUpdate();

      },
      on_vendor_click(id) {
        this.$data.show_vendor_modal = false;
        EventBus.$emit('click:vendor', id)
        if (this.$router) {
          this.$router.push({
            name: 'live-answer.create',
            params: {
              vendor_id: id
            },
          });
        } else if (typeof this.$props.onCreateLinkClickCb === 'function'){
          this.$props.onCreateLinkClickCb({
            vendor_id: id,
          });
        }
      },
    },
  };
</script>
