const styles = theme => ({
	loadingDiv: theme.loadingDiv,
	wrapper: {
		position:		'relative',
		display:		'flex',
		flexDirection:	'column',
		height:			'100%',
		'& .infinite-scroller': {
			maxHeight:	'calc(100% - 66px)',
			height:		'fit-content'
		},
		'&.has-tabs': {
			height: 'calc(100% - 48px)'
		}
	},
	searchFieldWrapper: {
		padding:		'0 15px',
		marginBottom:	15
	},
	searchField: {
		width:			'100%',
		marginRight:	20
	},
	letterHeader: {
		background:		theme.palette.secondary[-400],
		borderRadius:	3,
		padding:		'6px 20px 4px',
		margin:			'10px 0',
		fontSize:		20,
		lineHeight:		'18px',
		'-webkit-text-stroke-width': '0.5px',
		'&:not(.unnamed)': {
			fontFamily:		'NTR',
			letterSpacing:	4
		},
		'&.unnamed': {
			color:		theme.palette.secondary['+100'],
			fontSize:	16
		}
	},
	contactItem: {
		fontSize:		14,
		padding:		'10px 20px',
		display:		'flex',
		alignItems:		'center',
		cursor:			'pointer',
		borderRadius:	3,
		'&:hover': {
			background: theme.palette.secondary[-400]
		},
		'&.selected': {
			background: theme.palette.primary[-400]
		}
	},
	contactsLoader: {
		display:		'flex',
		justifyContent:	'center',
		padding:		'10px 5px 5px'
	},
	noContactMessage: {
		marginTop: "15px",
		padding: "0 10px",
		display: "inline-block",
		width: "100%",
		height: "100%",
		verticalAlign: "middle",
		textAlign: "center",
		"&>span": {
			color: "#b6c0c5",
			fontWeight: "bold",
			lineHeight: 1.29,
		},
	},
})

export default styles