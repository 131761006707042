<template>
  <div>
    <Link :lines="lines"/>
    <Block v-for="block in blocks"
      :key="block.id"
      v-bind.sync="block"
      ref="blocks"
      :options="optionsForChild"
      @select="blockSelect(block)"
      @openModal="openModal($event)"
    />
  </div>
</template>

<script>
  /* eslint-disable no-continue */
  import merge from 'deepmerge';
  import Visualizer from '../helpers/visualizer-helpers';
  import Block from './Block.vue';
  import Link from './Link.vue';

  export default {
    name: 'BlocksContainer',
    components: {
      Block,
      Link
    },
    props: {
      blocksContent: {
        type: Array,
        default() {
          return [];
        }
      },
      scene: {
        type: Object,
        default: {blocks: [], links: [], container: {}}
      },
      maxHeight: {
        type: Number,
        default: 0
      },
      maxWidth: {
        type: Number,
        default: 0
      },
      options: {
        type: Object
      },
    },
    mounted() {
      document.documentElement.addEventListener('mousemove', this.handleMove, true);
      document.documentElement.addEventListener('mousedown', this.handleDown, true);
      document.documentElement.addEventListener('mouseup', this.handleUp, true);
      document.documentElement.addEventListener('touchstart', this.handleDown, { passive: false });
      document.documentElement.addEventListener('touchmove', this.handleMove, { passive: false });
      document.documentElement.addEventListener('touchend', this.handleUp, { passive: false });
      document.documentElement.addEventListener('wheel', this.handleWheel, { passive: false });

      this.centerY = this.$el.clientHeight / 2 + 25;
      this.importBlocksContent();
      this.importScene();
    },
    beforeDestroy() {
      document.documentElement.removeEventListener('mousemove', this.handleMove, true);
      document.documentElement.removeEventListener('mousedown', this.handleDown, true);
      document.documentElement.removeEventListener('mouseup', this.handleUp, true);
      document.documentElement.removeEventListener('touchstart', this.handleDown, { passive: false });
      document.documentElement.removeEventListener('touchmove', this.handleMove, { passive: false });
      document.documentElement.removeEventListener('touchend', this.handleUp, { passive: false });
      document.documentElement.removeEventListener('wheel', this.handleWheel, { passive: false });
    },
    data() {
      return {
        dragging: false,
        centerScreen: false,
        centerX: 30,
        centerY: 0,
        scale: 1,
        defaultScale: 1,
        nodes: [],
        blocks: [],
        links: [],
        tempLink: null,
        selectedBlock: null,
        mouseX: 0,
        mouseY: 0,
        lastMouseX: 0,
        lastMouseY: 0,
        minScale: 0.4,
        maxScale: 5,
        linking: false,
        linkStartData: null,
        inputSlotClassName: 'inputSlot',
        defaultScene: {
          blocks: [],
          links: [],
          container: {}
        },
      };
    },
    computed: {
      optionsForChild() {
        return {
          width: 230,
          scale: this.scale,
          inputSlotClassName: this.inputSlotClassName,
          center: {
            x: this.centerX,
            y: this.centerY
          }
        };
      },
      container() {
        return {
          centerX: this.centerX,
          centerY: this.centerY,
          scale: this.scale
        };
      },
      // Links calculate
      lines() {
        const lines = [];
        for (const link of this.links) {
          const originBlock = this.blocks.find((block) => block.id === link.originID);
          const targetBlock = this.blocks.find((block) => block.id === link.targetID);

          if (!originBlock || !targetBlock) {
            console.log('Remove invalid link', link);
            this.removeLink(link.id);
            continue;
          }
          if (originBlock.id === targetBlock.id) {
            // console.log('Loop detected, remove link', link)
            this.removeLink(link.id);
            continue;
          }

          if (originBlock && targetBlock) {
            if (originBlock.outputs[link.originSlot]) {
              originBlock.outputs[link.originSlot].used = true;
              originBlock.used = true;

              if (targetBlock.x < originBlock.x + 230) {
                let {name} = targetBlock;
                if (targetBlock.title.includes('Extension/User ')) {
                  name = targetBlock.title.replace('Extension/User ', 'Ext/User ');
                } else if (targetBlock.title.includes('Phone Number: ')) {
                  name = targetBlock.title.replace('Phone Number: ', '');
                }

                originBlock.outputs[link.originSlot].backLink = name;
                originBlock.outputs[link.originSlot].backLinkColor = targetBlock.color;
              } else {
                originBlock.outputs[link.originSlot].backLink = null;
              }
            }
          }

          const originLinkPos = this.getConnectionPos(originBlock, link.originSlot, false);
          const targetLinkPos = this.getConnectionPos(targetBlock, link.targetSlot, true);

          if (!originLinkPos || !targetLinkPos) {
            console.log('Remove invalid link (slot not exist)', link);
            this.removeLink(link.id);
            continue;
          }

          const x1 = originLinkPos.x;
          const y1 = originLinkPos.y;

          const x2 = targetLinkPos.x;
          const y2 = targetLinkPos.y;

          lines.push({
            x1,
            y1,
            x2,
            y2,
            show: targetBlock.x >= originBlock.x + 230,
            name: link.name,
            style: {
              stroke: '#8C98A0',
              strokeWidth: 1.2 * this.scale,
              fill: 'none'
            },
            outlineStyle: {
              stroke: '#666',
              strokeWidth: 1.2 * this.scale,
              strokeOpacity: 0.6,
              fill: 'none'
            }
          });
        }
        return lines;
      }
    },
    methods: {
      getOffsetRect() {
        const box = this.$el.getBoundingClientRect();
        const scrollTop = window.scrollY;
        const scrollLeft = window.scrollX;
        const top = box.top + scrollTop;
        const left = box.left + scrollLeft;

        return { top: Math.round(top), left: Math.round(left) };
      },
      changeMousePosition(e) {
        const mouse = Visualizer.getMousePosition(e);
        const offset = this.getOffsetRect();
        this.mouseX = mouse.x - offset.left;
        this.mouseY = mouse.y - offset.top;
      },
      handleMove(e) {
        if (this.dragging) {
          this.changeMousePosition(e);

          const diffX = this.mouseX - this.lastMouseX;
          const diffY = this.mouseY - this.lastMouseY;

          this.lastMouseX = this.mouseX;
          this.lastMouseY = this.mouseY;

          this.centerX += diffX;
          this.centerY += diffY;
        }
      },
      handleDown(e) {
        const target = e.target || e.srcElement;
        if ((target === this.$el || target.matches('svg, svg *'))) {
          this.dragging = true;

          this.changeMousePosition(e);

          this.lastMouseX = this.mouseX;
          this.lastMouseY = this.mouseY;

          this.deselectAll();
          if (e.preventDefault) e.preventDefault();
        }
      },
      handleUp() {
        if (this.dragging) {
          this.dragging = false;
        }
      },
      center() {
        this.centerX = 30;
        this.centerY = this.$el.clientHeight / 2 + 25;
        this.centerScreen = !this.centerScreen;
      },
      moveScene(axis, amount) {
        if (axis === 'x') {
          this.centerX += amount;
        } else {
          this.centerY += amount;
        }
      },
      handleZoomChange(deltaScale, zoomingCenter) {
        const newScale = this.scale * deltaScale;

        if (newScale < this.minScale || newScale > this.maxScale) {
          return;
        }

        this.scale = newScale;

        const deltaOffsetX = (zoomingCenter.x - this.centerX) * (deltaScale - 1);
        const deltaOffsetY = (zoomingCenter.y - this.centerY) * (deltaScale - 1);
        this.centerX -= deltaOffsetX;
        this.centerY -= deltaOffsetY;
      },
      buttonScroll(val) {
        const deltaScale = 1.18 ** (val * 120 * -0.01);
        const widthAmount = this.$el.clientWidth < 960 ? 150 : this.$el.clientWidth / 3;
        const zoomingCenter = {
          x: 30 + (this.$el.clientWidth - widthAmount) / 2,
          y: this.$el.clientHeight / 2 + 25
        };

        this.handleZoomChange(deltaScale, zoomingCenter);
      },
      handleWheel(e) {
        const target = e.target || e.srcElement;
        if (!this.$el.contains(target)) {
          return;
        }

        if (e.preventDefault) {
          e.preventDefault();
        }

        const deltaScale = 1.12 ** (e.deltaY * -0.01);
        this.changeMousePosition(e);
        const zoomingCenter = {
          x: this.mouseX,
          y: this.mouseY
        };

        this.handleZoomChange(deltaScale, zoomingCenter);
      },
      // Processing
      getConnectionPos(block, slotNumber, isInput) {
        if (!block || slotNumber === -1) {
          return undefined;
        }

        let {x, y} = block;

        if (!['menu', 'call flow'].includes(block.objectType)) {
          y += 37;
        } else {
          y += 5;
        }

        if (isInput && block.inputs.length > slotNumber) {
          x -= 2.8;
          if (block.objectType === 'menu') {
            y += block.outputs.length ? 14 : 12;
          } else if (block.objectType !== 'call flow') {
            y -= 16;
          }
          y += (block.outputs.length ? (block.outputs.length - 1) : 0.23) * 10.5 + 2.5;
        } else if (!isInput && block.outputs.length > slotNumber) {
          x += this.optionsForChild.width + 5.8;
          y += 5;
          if (block.objectType === 'menu') {
            y += 32.2;
          }
        } else {
          console.error(`slot ${slotNumber} not found, is input: ${isInput}`, block);
          return undefined;
        }

        // (height / 2 + blockBorder + padding)
        y += (16 / 2 + 1 + 2);
        //  + (height * slotNumber)
        y += (slotNumber * 21);
        // if margin is increased or ioHeight just change e.g. ioHeight * slotNumber

        x *= this.scale;
        y *= this.scale;
        x += this.centerX;
        y += this.centerY;

        return {x, y};
      },
      removeLink(linkID) {
        this.links = this.links.filter((value) => !(value.id === linkID));
      },
      // Events
      blockSelect(block) {
        block.selected = true;
        this.selectedBlock = block;
        this.deselectAll(block.id);
        this.$emit('blockSelect', block);
      },
      openModal(event) {
        this.$emit('openModal', event);
      },
      blockDeselect(block) {
        block.selected = false;
        if (block && this.selectedBlock && this.selectedBlock.id === block.id) {
          this.selectedBlock = null;
        }
      },
      createBlock(node, id) {
        const inputs = node.fields.filter((field) => field.attr === 'input').map((field) => ({
          name: field.name,
          label: field.label || field.name
        }));

        const outputs = node.fields.filter((field) => field.attr === 'output').map((field) => ({
          name: field.name,
          actionType: field.actionType || null,
          actionName: field.actionName || null,
          key: field.key || null,
          used: field.used,
          backLink: field.backLink,
          collapse: field.collapse,
          device: field.device,
          label: field.label || field.name
        }));

        return {
          id,
          x: 0,
          y: 0,
          selected: false,
          name: node.name,
          title: node.title,
          inputs,
          outputs,
          values: {}
        };
      },
      deselectAll(withoutID = null) {
        for (const block of this.blocks) {
          if (block.id !== withoutID && block.selected) {
            this.blockDeselect(block);
          }
        }
      },
      prepareBlocks(blocks) {
        return blocks.map((block) => {
          const node = this.nodes.find((n) => n.name === block.name && n.title === block.title);

          if (!node) {
            return null;
          }

          let newBlock = this.createBlock(node, block.id);
          newBlock = merge(newBlock, block, {
            arrayMerge: (d, s) => (s.length === 0 ? d : s)
          });

          return newBlock;
        }).filter((b) => !!b);
      },
      prepareBlocksLinking(blocks, links) {
        if (!blocks) {
          return [];
        }

        return blocks.map((block) => {
          const inputs = links.filter((link) => link.targetID === block.id);
          const outputs = links.filter((link) => link.originID === block.id);

          const updatedInputs = block.inputs.map((input, index) => ({
            ...input,
            active: inputs.some((i) => i.targetSlot === index),
          }));

          const updatedOutputs = block.outputs.map((output, index) => ({
            ...output,
            active: outputs.some((i) => i.originSlot === index),
          }));

          return {
            ...block,
            inputs: updatedInputs,
            outputs: updatedOutputs,
          };
        });
      },
      importBlocksContent() {
        if (this.$props.blocksContent) {
          this.nodes = merge([], this.$props.blocksContent);
        }
      },
      importScene() {
        const scene = merge(this.defaultScene, this.$props.scene);

        let blocks = this.prepareBlocks(scene.blocks);
        blocks = this.prepareBlocksLinking(blocks, scene.links);

        // set last selected after update blocks from props
        if (this.selectedBlock) {
          const block = blocks.find((b) => this.selectedBlock.id === b.id);
          if (block) {
            block.selected = true;
          }
        }

        this.blocks = blocks;
        this.links = merge([], scene.links);
        this.updateScale();
      },
      updateScale() {
        this.scale = this.defaultScale;
        const widthAmount = this.$el.clientWidth / (this.$el.clientWidth / (this.$el.clientWidth < 960 ? 45 : 250));
        if (this.scale > (this.$el.clientHeight - 200) / this.$props.maxHeight) {
          this.scale = (this.$el.clientHeight - 200) / this.$props.maxHeight;
        }
        if (this.scale > (this.$el.clientWidth - widthAmount) / this.$props.maxWidth) {
          this.scale = (this.$el.clientWidth - widthAmount) / this.$props.maxWidth;
        }
        if (this.minScale > this.scale) {
          this.minScale = this.scale / 1.1;
        }
      }
    },
    watch: {
      blocksContent() {
        this.importBlocksContent();
      },
      centerScreen() {
        this.importScene();
      },
      scene() {
        this.importScene();
      }
    }
  };
</script>
