import React, { Component } from 'react'
import { connect } from 'react-redux'
import MusicOnHold from './settingComponents/MusicOnHold'
import Voicemail from './settingComponents/Voicemail'
import MusicAndMessages from './settingComponents/MusicAndMessages'
import Plan from './settingComponents/Plan'
import AddOns from './settingComponents/AddOns'
import CreditCard from './settingComponents/CreditCard'
import Invoices from './settingComponents/Invoices'
import Preferences from './settingComponents/Preferences'
import Security from './settingComponents/Security'
import BlockList from './settingComponents/BlockList'
import { switchSetting } from '../actions/settings.js'
import { switchCurrentView } from '../actions/view.js';

import { withStyles } from '@material-ui/core'

const styles = theme => ({
	contentWrapper: {
		flex:			1,
		display:		'flex',
		flexDirection:	'column'
	}
})

const mapStateToProps = state => ({
	currentSetting:		state.currentSetting,
	smallView:			state.smallView,
	switchCurrentView:	state.switchCurrentView

})
const mapDispatchToProps = dispatch => ({
	switchSetting:		setting => dispatch(switchSetting(setting)),
	switchCurrentView:	view => dispatch(switchCurrentView(view))
})

class SettingContent extends Component {

	renderSetting = () => {
		let SettingComponent = null
		switch (this.props.currentSetting.id) {
			case 'musiconhold':
				SettingComponent = MusicOnHold
				break
			case 'voicemail':
				SettingComponent = Voicemail
				break
			case 'musicandmessages':
				SettingComponent = MusicAndMessages
				break
			case 'plan':
				SettingComponent = Plan
				break
			case 'addons':
				SettingComponent = AddOns
				break
			case 'creditcard':
				SettingComponent = CreditCard
				break
			case 'invoices':
				SettingComponent = Invoices
				break
			case 'preferences':
				SettingComponent = Preferences
				break
			case 'security':
				SettingComponent = Security
				break
			case 'blocklist':
				SettingComponent = BlockList
				break
		}
		return <SettingComponent smallView={this.props.smallView} onLoaded={this.props.onLoaded} goBack={this.goBack}/>
	}

	goBack = () => {
		this.props.switchSetting(null)
		this.props.switchCurrentView('select')
	}

	render() {
		const { classes } = this.props
		if (!this.props.currentSetting) return null
		return (
			<div className={classes.contentWrapper}>
				{this.renderSetting()}
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SettingContent))