<template>
      <div class="show-page">
        <Listener :disable_extension_selector="true" data-test-id="apps-show-page"></Listener>
      </div>
</template>

<script>
  import Listener from '../../fragments/Listener.vue';

  export default {
    components: {
      Listener,
    },
  };
</script>
