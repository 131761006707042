<template>
  <App v-bind="$attrs" v-on="$listeners" :sessionId="sessionId" :onClickLink="onClickLink" :onClickVendor="onClickVendor">
    <LiveReceptionistCreate
        v-if="page === 'create'"
        :vendor_id="vendor_id"
        :postSaveCb="postSaveCb"
        :onClickCancel="onClickCancel"
    />
    <LiveReceptionistShow
        v-else-if="page === 'show'"
        :_id="id"
        :vendor_id="vendor_id"
        :postSaveCb="postSaveCb"
        :onClickCancel="onClickCancel"
    />
    <LiveReceptionistIndex
        v-else
        :vendor_id="vendor_id"
        :title="title"
    />
  </App>
</template>

<script>
import {LiveReceptionistCreate, LiveReceptionistShow, LiveReceptionistIndex} from 'live-receptionist-components'
import EventBus from 'event-bus';
import App from '../elements/functional-components/App.vue';

export default {
  props:{
    sessionId: {
      type: String,
      required: false,
    },
    id: {
      type: Number,
    },
    page: {
      type: String,
      required: true,
      validator: (v) => ['create', 'show', 'index'].includes(v),
    },
    vendor_id: {
      type: Number,
      required: false,
    },
    postSaveCb: {
        type: Function,
    },
    onClickCancel: {
      type: Function,
    },
    onCreateLinkClickCb: {
      type: Function,
    },
    onClickLink: {
      type: Function,
      required: false,
    },
    onClickVendor: {
      type: Function,
    },
    title: {
      type: String,
      required: false,
    }
  },
  created() {
    if (this.$props.onClickVendor) {
      EventBus.$on('click:vendor', (...args) => {
        this.$props.onClickVendor(...args)
      })
    }
  },
  components: {
    App,
    LiveReceptionistCreate,
    LiveReceptionistShow,
    LiveReceptionistIndex,
  },
};
</script>

<style lang="scss">
	@import 'vue-apps-assets/styles/scss/styles.scss';
 .v-application--wrap {
      min-height: fit-content;
    }
</style>