/**
 * @param {object} theme
 */
const styles = theme => ({
    popover: {
        zIndex: '3334 !important'
    },
    menu: {
        borderRadius: 6,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        '& > ul': {
            // width: 194
        }
    },
    audioSettingsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        padding: 12,
        borderRadius: 2
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        '& .title': {},
        '& .audio-source-item': {
            padding: '0 8px',
            paddingLeft: 30,
            cursor: 'pointer',
            '&.selected': {
                color: theme.palette.primary.main
            },
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.1)'
            }
        }
    },
    volumeWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '30px 16px 20px',
        columnGap: 25,
        '& > svg': {
            cursor: 'pointer'
        }
    },
    selectedMenuItem: {
        backgroundColor: `${theme.palette.other.green} !important`
    },
    icon: {
        color: theme.palette.text.tertiary,
        borderRadius: 2,
        height: 22,
        width: 22,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.action.primaryFocus,
            color: theme.palette.primary.dark
        }
    },
    audioSettingsMenuItem: {
        '& > div:nth-child(2)': {
            display: 'inline-block',
            boxSizing: 'border-box',
            whiteSpace: 'pre-line',
            wordWrap: 'break-word',
            '& p': {
                display: '-webkit-box',
                '-webkit-box-orient': 'vertical',
                '-webkit-line-clamp': 2,
                overflow: 'hidden'
            }
        }
    },
    slider: {
        paddingTop: 5,
        paddingBottom: 5,
        marginBottom: 12
    },
    sliderThumb: {
        outline: '3px solid #fff',
        '&:hover, &:focus, &:active, &:focus-visible': {
            boxShadow: 'none'
        }
    }
})

export default styles
