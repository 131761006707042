<template>
    <DefaultModal
      v-bind="$attrs"
      v-on="$listeners"
      v-model="show"
      @close="show=false"
      data-test-id="modal-with-trigger"
    >
          <template v-slot:activator="{ on, attrs }">
            <w-btn
              v-bind="attrs"
              v-on="on"
              :ripple="false"
              text
            >
              <slot name="activator_text">
                <v-icon>$vuetify.icons.edit</v-icon>
              </slot>
            </w-btn>
          </template>
           <!-- Pass on all named slots -->
          <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
          <!-- Pass on all scoped slots -->
          <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope"/></template>
    </DefaultModal>
  </template>
  <script>
    import DefaultModal from './DefaultModal.vue';

    export default {
      components: {
        DefaultModal,
      },
      data() {
        return {
          show: false,
        };
      },
    };
  </script>
