<template>
    <div class="device-buy-payment-method">
        <w-loader v-if="loading" />
        <div v-else-if="cards && cards.length">
            <div class="cards-container">
                <div class="w-h5 step-title">
                    {{ $lang.t('devices.provide-pm-info', 'Please provide your payment information') }}
                </div>
                <CardSelector v-model="selected_pm" :cards="cards" class="mx-auto" />
            </div>
        </div>
        <div v-else-if="cards && !cards.length" class="text-center py-12">
            <div class="w-body-2">
                {{ $lang.t('p-methods.you-currently-have-no-credit-card', 'You currently have no credit card or debit card on file.') }}
            </div>
        </div>
        <div class="add-card-btn-container" :class="{'text-center': cards && !cards.length}">
            <w-btn
                @click="show_add_p_method_modal = true"
                text
                :class="{'pl-0': cards && cards.length}"
                color="primary"
            >
                    <v-icon
                        size="18"
                    >
                        $vuetify.icons.add
                    </v-icon>
                    {{ $lang.t('p-methods.add-payment-method', 'Add payment method') }}
            </w-btn>
        </div>
        <FormModal v-model="show_add_p_method_modal" @close="show_add_p_method_modal = false" max-width="700">
            <PaymentMethodsCreate :prevent_back="true" @created="p_method_created"/>
        </FormModal>
        <w-alert v-if="error" :level="error.level" :message="error.message" :closable="error.closable" />
        <StepperButtons @continue="submitted" @back="$emit('back')" :disable_continue="!value"/>
    </div>
</template>
<script>
import CardSelector from '../../../console/src/components/elements/CardSelector.vue';
import FormModal from '../../../console/src/components/elements/modal/FormModal.vue';
import StepperButtons from '../../../console/src/components/elements/StepperButtons.vue';
import {PaymentMethodsCreate} from 'billing-components';

export default {
    props: {
        cart: {
			required: true,
			type: Array,
		},
		order: {
			required: true,
			type: Object,
		},
        value: {
            type: Object,
            required: false,
        },
    },
    components: {
        CardSelector,
        FormModal,
        PaymentMethodsCreate,
        StepperButtons,
    },
    data() {
        return {
            loading: false,
            error: false,
            cards: [],
            selected_pm: null,
            show_add_p_method_modal: false,
        };
    },
    async created() {
        this.$data.selected_pm = this.$props.value ? this.$props.value.id : null;
        await this.load_payment_methods();
    },
    methods: {
        submitted() {
            this.$props.order.gtm_data_push('PDC_payment_info', this.$props.cart, this.order.total_price(this.$props.cart))
            this.$emit('continue')
        },
        async load_payment_methods() {
            this.$data.loading = true;
            this.$data.cards = [];
            try {
                const methods = await this.$session.get_list_all('/payment-methods?with_details=1');
                this.$data.cards = methods.items;
                if(methods.items.length) {
                    this.$data.selected_pm = methods.items[0].id
                }
            } catch (err) {
                console.log(err);
                this.$data.error = {
                    level: 'error',
                    message: this.$lang.t('p-methods.encountered-problems-loading-payment-methods', 'We encountered problems while loading your payment methods.'),
                    closable: false,
                };
            }
            this.$data.loading = false;
        },
        // async p_method_created(item) {
        async p_method_created() {
            await this.load_payment_methods();
            this.$data.selected_pm = this.$data.cards[0].id;
            setTimeout(() => {
				this.show_add_p_method_modal = false;
			}, 3000);
        },
    },
    watch: {
        selected_pm(val) {
            const pm = this.$data.cards.find((x) => x.id === val);
            if (pm) {
                this.$emit('input', pm);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.device-buy-payment-method {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    .cards-container {
        .step-title {
            margin-bottom: 30px;
        }
    }
    .add-card-btn-container {
        width: 350px;
        margin: 0 auto;
        text-align: left;
    }
}
</style>
