export const uploadSteps = {
	UPLOAD_TO_BROWSER:	1,
	CONVERT_TO_PDF:		2,
	AUTHORIZE:			3,
	UPLOAD_TO_SERVER:	4,
	FINISHED:			5
}

export const uploadStepsNames = {
	[uploadSteps.UPLOAD_TO_BROWSER]:	'Uploading to browser',
	[uploadSteps.CONVERT_TO_PDF]:		'Converting to PDF',
	[uploadSteps.AUTHORIZE]:			'Authorizing upload',
	[uploadSteps.UPLOAD_TO_SERVER]:		'Uploading to server',
	[uploadSteps.FINISHED]:				'Finished'
}

// min and max percentage of progress bar filling depending on the upload step
export const progressThresholds = {
	[uploadSteps.UPLOAD_TO_BROWSER]:	{min: 0, max: 30},
	[uploadSteps.CONVERT_TO_PDF]:		{min: 30, max: 60},
	[uploadSteps.AUTHORIZE]:			{min: 60, max: 85},
	[uploadSteps.UPLOAD_TO_SERVER]:		{min: 60, max: 85},
	[uploadSteps.FINISHED]:				{min: 85, max: 100}
}

// export const progressThresholds = {
// 	[uploadSteps.UPLOAD_TO_BROWSER]:	{min: 0, max: 30},
// 	[uploadSteps.CONVERT_TO_PDF]:		{min: 30, max: 40},
// 	[uploadSteps.AUTHORIZE]:			{min: 40, max: 50},
// 	[uploadSteps.UPLOAD_TO_SERVER]:		{min: 40, max: 50},
// 	[uploadSteps.FINISHED]:				{min: 50, max: 100}
// }

// In miliseconds
export const intervalSpeeds = {
	SLOW:	80,
	MEDIUM:	16,
	FAST:	8,
	FASTER:	3
}