import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5864%3A4
 *
 * @param {SvgIconProps} props - svg props
 */
export const EndCallButtonIcon = (props: SvgIconProps): JSX.Element => {
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z' fill={'#E85646'}/>
            <path d='M12 10.6287C11.0667 10.6287 10.1625 10.7698 9.31667 11.035V12.7844C9.31667 13.0045 9.1825 13.202 8.99 13.2923C8.41833 13.5688 7.89917 13.9244 7.43833 14.3363C7.33333 14.4379 7.1875 14.4944 7.03 14.4944C6.86667 14.4944 6.72083 14.4323 6.61583 14.3307L5.16917 12.9312C5.06417 12.8352 5 12.6941 5 12.5361C5 12.3781 5.06417 12.237 5.16917 12.1354C6.94833 10.5045 9.35167 9.5 12 9.5C14.6483 9.5 17.0517 10.5045 18.8308 12.1354C18.9358 12.237 19 12.3781 19 12.5361C19 12.6941 18.9358 12.8352 18.8308 12.9368L17.3842 14.3363C17.2792 14.4379 17.1333 14.5 16.97 14.5C16.8125 14.5 16.6667 14.4379 16.5617 14.342C16.1008 13.9244 15.5758 13.5745 15.0042 13.298C14.8117 13.2077 14.6775 13.0158 14.6775 12.7901V11.0406C13.8375 10.7698 12.9333 10.6287 12 10.6287Z' fill='white'/>
        </SvgIcon>
    )
}

export default EndCallButtonIcon
