import l from '../lang';

export default {
	'routes': {
		'create': 'messages.create',
		'index': 'messages.index',
	},
	'delete-single': l.t('messages.delete-single', 'Are you sure you want to delete this message?'),
	'delete-filtered': l.t('messages.delete-filtered', 'Are you sure you want to delete all filtered messages?'),
	'delete-all': l.t('messages.delete-all', 'Are you sure you want to delete all messages?'),
	'successfull-creation': l.t('messages.successfully-created', 'Your message has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('messages.failed-creation', 'There was a problem creating your message'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
};
