import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from 'typography'
import { makeStyles } from '@material-ui/core/styles'
import { App } from '../../../utils/objects'

const useStyles = makeStyles(theme => ({
    leftContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem'
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '1rem'
    },
    amplifyBrandTitle: {
        fontWeight: '600!important',
        marginBottom: '.5rem'
    },
    tabDetails: {

    },
    tabDetailsTitle: {
        fontWeight: '600!important',
        marginBottom: '.5rem'
    }
}))

/**
 *
 */
const OverviewTab = ({ app }: { app: App }) => {
    const classes = useStyles()

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6} className={classes.leftContainer}>
                <Box className={classes.tabDetails}>
                    <Typography variant='h5' className={classes.tabDetailsTitle} remoteConfigID='apps_marketplace_app_general_info' />
                    <Typography variant='subtitle1'>
                        {app.details.overview.general}
                    </Typography>
                </Box>
                {
                    app.details.overview.note &&
                <Box className={classes.tabDetails}>
                    <Typography variant='h5' className={classes.tabDetailsTitle} remoteConfigID='apps_marketplace_app_note' />
                    <Typography variant='subtitle1'>
                        {app.details.overview.note}
                    </Typography>
                </Box>
                }
            </Grid>
            <Grid item xs={12} sm={6} className={classes.rightContainer}>
                <Typography variant='h5' className={classes.amplifyBrandTitle} remoteConfigID='apps_marketplace_app_amplify_brand_title' />
                <Box className={classes.tabDetails}>
                    <Typography variant='h6' className={classes.tabDetailsTitle}>
                  Appointment Scheduling
                    </Typography>
                    <Typography variant='subtitle1'>
                  In addition to answering and directing calls, our receptionist can schedule meetings and appointments on your
                  behalf, straight into your calendar.
                    </Typography>
                </Box>
                <Box className={classes.tabDetails}>
                    <Typography variant='h6' className={classes.tabDetailsTitle}>
                  Live Call Answering
                    </Typography>
                    <Typography variant='subtitle1'>
                  Our attendants are available to answer your business calls from Monday to Friday
                  between 8 AM to 9 PM EST, Saturday between 9 AM to 8 PM EST.
                    </Typography>
                </Box>
                <Box className={classes.tabDetails}>
                    <Typography variant='h6' className={classes.tabDetailsTitle}>
                  White-Glove Service
                    </Typography>
                    <Typography variant='subtitle1'>
                  Add a personal, professional touch to your business at affordable rates with
                  a Live Receptionist skilled in the nuances of customer service.
                    </Typography>
                </Box>
                <Box className={classes.tabDetails}>
                    <Typography variant='h6' className={classes.tabDetailsTitle}>
                  Block Spam Calls
                    </Typography>
                    <Typography variant='subtitle1'>
                  Bothered by pesky robocalls or solicitors? No need to worry. Our attendants
                  screen inbound calls and only forward those pertinent to your business.
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

export default OverviewTab
