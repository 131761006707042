import React from 'react'
import ReactFullStory from 'react-fullstory'

/***/
export function FullStory (): JSX.Element {
    const org = process.env.REACT_APP_FULLSTORY_ORG
    if (!org) return <></>
    return <ReactFullStory org={org}/>
}

export default FullStory
