import { combineReducers } from 'redux'
import { conversations, conversationTotals, selectedSendNumber, totalConversations } from './conversations'
import { smallView, currentView } from './view'
import { forwarding } from './forwarding'
import { bridgeId, selectedExtensionPhoneNumbersFeatures, currentExtension } from './pdcuser'

export default combineReducers({
  selectedSendNumber,
  conversations,
  conversationTotals,
  totalConversations,
  smallView,
  currentView,
  forwarding,
  bridgeId,
  selectedExtensionPhoneNumbersFeatures,
  currentExtension
})
