/**
 * @param {object} theme
 */
const styles = theme => ({
    root: {
        display: 'flex',
        position: 'relative',
        height: '100%',
        minHeight: 0
    },
    drawableContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    drawerWrapper: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            backgroundColor: 'transparent'
        }
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: theme.drawerWidth,
            flexShrink: 0
        }
    },
    appBar: theme.appBar,
    menuButton: {
        marginRight: theme.spacing(2),
        outline: 'none !important'
    },
    toolbar: {
        minHeight: theme.appBarHeight
    },
    drawerPaper: {
        ...theme.drawerPaper,
        backgroundColor: `${theme.palette.paper.gray} !important`
    },
    collapseWrapper: {
        marginLeft: '31px',
        borderLeft: `2px solid ${theme.palette.action.disabledText}`
    },
    content: {
        height: '100%',
        maxWidth: '100%',
        flexGrow: 1,
        '&:not(.mobile-view):not(.tablet-view)': {
            maxWidth: `calc(100% - ${theme.drawerPaper.width}px)`
        }
    },
    drawerList: {
        padding: 0
    },
    drawerListItemIcon: {
        minWidth: '44px'
    },
    drawIcon: {
        color: theme.palette.text.primary,
        '&.old-ui': {
            color: '#FFF'
        }
    },
    drawerItem: {
        padding: props => props.nested ? '8px 12px 8px 31px' : '14px 12px 14px 20px',
        position: 'relative',
        '&:not(.active):hover': { backgroundColor: theme.palette.secondary.background },
        '&.active': { backgroundColor: theme.palette.action.disabledText, color: theme.palette.secondary.main },
        '&.separate': { marginTop: 42 },
        '&.old-ui': {
            '&.active': { backgroundColor: '#B4E8A9' }
        }
    },
    drawerLinkItem: {
        padding: '9.5px 12px 9.5px 20px'
    },
    iconWrapper: {
        minWidth: 0,
        marginRight: 13
    },
    disabledLabel: {
        marginTop: '-10px',
        fontSize: theme.tinyText.fontSize,
        color: theme.disabledLabel.color
    },
    appLinkStyle: {
        textDecoration: 'none',
        color: theme.drawerPaper.color,
        '&:hover': {
            textDecoration: 'none',
            color: theme.drawerPaper.color
        }
    },
    unreadIcon: {
        backgroundColor: theme.notificationDotColor,
        width: '10px',
        height: '10px',
        borderRadius: '50%'
    },
    avatarWrapper: {
        marginLeft: 'auto',
        cursor: 'pointer'
    },
    statusDrawerStyle: {
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20
    },
    statusDrawer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    statusDrawerHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 130,
        borderBottom: '1px solid #DEE1E3',
        position: 'relative'
    },
    statusDrawerItem: {
        display: 'flex',
        height: 70,
        borderBottom: `1px solid ${theme.palette.secondary['-200']}`,
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.primary['-300']
        },
        '& .text': {
            paddingLeft: 40,
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '24px'
        },
        '& .icon': {
            paddingRight: 40
        },
        '& svg': {
            color: '#85929A'
        }
    },
    statusDrawerFooter: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        minHeight: 130
    },
    xIcon: {
        position: 'absolute',
        right: 26,
        top: 26,
        cursor: 'pointer',
        color: theme.palette.secondary['0']
    },
    navItemRoot: { margin: 0 },
    navItemText: {
        marginLeft: props => props.nested ? '0px' : undefined,
        position: 'relative',
        fontFamily: 'Montserrat-medium !important',
        fontSize: 14,
        fontWeight: 500,
        '&:not(.active)': { color: theme.palette.text.primary },
        '&.active': { color: theme.palette.text.primary },
        '&.old-ui': {
            '&:not(.active)': { color: '#FFF' },
            '&.active': { color: '#349122' }
        },
        '& [data-test-id=unread-badge-div]': {
            top: 0,
            width: 14,
            height: 14
        }
    },
    refinedNavItemText: {
        fontWeight: '200 !important',
        fontSize: 10,
        marginLeft: '25px'
    },
    unreadInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        borderRadius: '50%',
        background: theme.palette.attention[0],
        position: 'absolute',
        right: 12,
        '& > span': {
            color: 'white',
            fontFamily: 'NTR',
            fontSize: 14.5,
            letterSpacing: 0.1,
            lineHeight: 1,
            marginTop: 2
        }
    },
    linksSection: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 40,
        '& span': {
            textDecoration: 'none',
            fontFamily: 'Montserrat-medium',
            fontSize: '14px !important',
            fontWeight: '500 !important',
            lineHeight: '19px !important'
        }
    },
    footer: {
        height: '52px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderTop: '1px solid #E5E5E5',
        fontFamily: 'Montserrat-medium !important',
        fontSize: '10px',
        fontWeight: '600',
        lineHeight: '12px',
        '& p': {
            margin: '0 4px'
        },
        '& span': {
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.primary.main
        }
    }
})

export default styles
