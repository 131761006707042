<template>
    <div class="numeric-pagination" v-if="!hide && items.length && showPagination && !allItemsRetrieved" data-test-id="pagination">
        <template v-if="!show_only_arrows">
            <v-pagination
              v-if="pages && !show_all && pages!==1"
              v-model="page"
              :length="pages"
              total-visible="7"
              @input="pageChanged($event)"
              data-test-id="pagination-arrows-only"
            ></v-pagination>
            <template v-if="pages && show_all">
              <v-pagination
                v-model="page"
                :length="pages"
                :total-visible="pages"
                @input="pageChanged($event)"
                data-test-id="pagination-nums"
              ></v-pagination>
            </template>
        </template>
        <div class="text-center mt-2" v-if="pages > 7">
            <a
              v-if="show_all"
              @click="show_all=false"
              class="text--text text--lighten-1"
              data-test-id="pagination-show-less"
            >
              {{ l.t('app.show-less-pages', 'Show less pages') }}
            </a>
            <a
              v-else
              @click="show_all=true"
              class="text--text text--lighten-1"
              data-test-id="pagination-show-more"
            >
              {{ l.t('app.show-more-pages', 'Show more pages') }}
            </a>
        </div>
    </div>
</template>
<script>
  import l from '../../libs/lang';

  export default {
    props: ['limit', 'show_only_arrows', 'hide', 'everythingDeleted'],
    data() {
      return {
        l,
        items: [],
        page: 1,
        pages: null,
        show_all: false,
        showPagination: false,
        allItemsRetrieved: false,
      };
    },
    created() {
      this.sendLimitAndOffset();
    },
    mounted() {
      this.$parent.$on('itemsLoaded', (items) => {
        this.checkPagination(items);
        this.$data.showPagination = true;
      });
      this.$parent.$on('pageChanged', (page) => this.pageChanged(page));
      this.$parent.$on('hidePagination', () => this.$data.showPagination = false);
      this.$parent.$on('showPagination', () => this.$data.showPagination = true);
      this.$parent.$on('reloadPaginated', () => this.sendLimitAndOffset());
    },
    methods: {
      sendLimitAndOffset() {
        window.scrollTo({ top: 0 });
        return this.$emit('paramsPrepared', {
            limit: this.limit,
            offset: this.offset,
            page: this.page,
        });
      },
      checkPagination(items) {
        this.items = items.items;
        if (items.total) this.pages = Math.ceil(items.total / this.limit);
        this.lastPage = this.items.length < this.$props.limit;
        if (items.total && items.total <= this.limit) this.$data.allItemsRetrieved = true;
      },
      pageChanged(page) {
        this.$data.showPagination = false;
        this.page = page;
        this.sendLimitAndOffset();
      },
      previousPage() {
        this.$data.showPagination = false;
        this.page--;
        this.sendLimitAndOffset();
      },
      nextPage() {
        this.$data.showPagination = false;
        this.page++;
        this.sendLimitAndOffset();
      },
    },
    computed: {
      offset() {
        if (this.page === 1) return 0;
        return (this.page - 1) * this.$props.limit;
      },
    },
    watch: {
      everythingDeleted(val) {
          if (val) {
              setTimeout(() => {
                  if (this.$data.page === 1) this.$data.page++;
                  else this.$data.page = 1;
                  this.pageChanged(this.$data.page);
              }, 4000);
          }
      }
    }
  };
</script>
<style lang="scss">
  .numeric-pagination   {
    margin-top: 18px;
  }
</style>
