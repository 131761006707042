<template>
  <div class="menus">
    <w-loader v-if="user.loading"/>
    <w-alert
      v-else-if="user.alert"
      :level="user.alert.level"
      :message="user.alert.message"
      :closable="user.enable_close_alert"
      @closed="menu.alert = null"
    />
    <template v-else-if="user.item">
      <Submenu
        :routes="{
          'video.users.schedules.index': {
            title: l.t(
            'video.scheduled-conferences',
            'Scheduled conferences'
            ),
            subroutes: ['video.users.schedules.show']
          },
          'video.users.schedules.create': {
            title: l.t(
            'video.add-scheduled-conference',
            'Add scheduled conference'
            )
          },
        }"
        :route_params="{ user_id: user.item.id, extension: extension }"
        :key="`${l.detect()}${!!user.item}`"
        data-test-id="video-user-schedules-submenu"
      />
      <UserSchedulesFragment
        v-if="$route.name === 'video.users.schedules.index'"
        :user="user.item"
        :extension="extension"
        data-test-id="video-user-schedules-index"
      />
      <UserSchedulesCreateFragment
        v-if="$route.name === 'video.users.schedules.create'"
        :user="user.item"
        :extension="extension"
        data-test-id="video-user-schedules-create"
      />
      <UserScheduleFragment
        v-if="$route.name === 'video.users.schedules.show'"
        :user="user.item"
        :extension="extension"
        data-test-id="video-user-schedules-show"
      />
    </template>
  </div>
</template>

<script>
import {vueComponent} from 'helpers';
import l from '../../../../../libs/lang';
import Submenu from '../../../../elements/Submenu.vue';
import VideoUser from '../../../../../models/VideoUser';
import UserSchedulesFragment from './index.vue';
import UserSchedulesCreateFragment from './create.vue';
import UserScheduleFragment from './show.vue';

export default {
  components: {
    Submenu,
    UserSchedulesFragment,
    UserSchedulesCreateFragment,
    UserScheduleFragment,
  },
  data() {
    return {
      l,
      user: new VideoUser(
        this.$session,
        vueComponent(this),
      ),
      extension: null,
    };
  },
  async created() {
    await this.$data.user.getItem(this.$route.params.user_id);
    if (this.$route.params.extension) {
      this.$data.extension = this.$route.params.extension;
    } else {
      this.$data.extension = await this.$data.user.getExtension(
        this.$data.user.item.voip_phone_id,
      );
    }
  },
  beforeRouteLeave(to, from, next) {
    if (window.deleting_in_progress) {
      this.$emit('userRedirected', to, from, next);
    } else {
      next();
    }
  },
};
</script>
