let wordBreak = 'break-word'
if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    wordBreak = 'break-all' // hack fix for edge and internet explorer, we may want move the if statement to a shared function, called like browserDetector or something
}

/**
 * @param {object} theme
 */
const styles = theme => ({
    identityAreaWrapper: {
        position: 'relative',
        marginLeft: 10,
        '& > .identity': {
            display: 'flex',
            alignItems: 'center',
            columnGap: 10,
            width: 215,
            minWidth: 215,
            padding: '9px 0 10px',
            cursor: 'pointer',
            '& > div': {
                display: 'flex',
                flexDirection: 'column',
                '& > span, & > p': {
                    color: `${theme.palette.text.secondary} !important`,
                    fontFamily: 'Montserrat-medium !important'
                }
            },
            [theme.breakpoints.down('xs')]: {
                width: 'auto',
                minWidth: 'auto'
            }
        }
    },
    identityWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '& .identity-section': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            minHeight: 60,

            padding: '2px 25px 2px 15px',
            backgroundColor: theme.palette.tertiary[0],
            cursor: 'pointer',
            '& .identity-text': {
                display: 'flex',
                flexDirection: 'column',
                color: 'white',
                '& .super-identity-text': {
                    fontSize: 12,
                    fontWeight: 600,
                    fontFamily: 'Montserrat-semi-bold',
                    lineHeight: '17px',
                    wordBreak: wordBreak
                },
                '& .main-identity-text': {
                    fontSize: 12,
                    fontWeight: 500,
                    fontFamily: 'Montserrat-medium',
                    lineHeight: '17px',
                    wordBreak: wordBreak
                },
                '& .secondary-identity-text': {
                    fontSize: 10,
                    fontWeight: 500,
                    fontFamily: 'Montserrat-medium',
                    lineHeight: '14px',
                    letterSpacing: 0.1,
                    marginTop: 5
                }
            },
            '& .identity-avatar': {
                width: 45,
                height: 45
            }
        },
        '& .extension-selector-section': {
            position: 'relative',
            cursor: 'pointer',
            '&.expanded .extension-selector-header': {
                backgroundColor: theme.palette.secondary.main,
                color: 'white'
            },
            '&:not(.expanded) .extension-selector-wrapper': {
                display: 'none'
            },
            '& .extension-selector-header': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '20px 19px 16px 15px',
                fontSize: 16,
                fontWeight: 400,
                lineHeight: '14px',
                backgroundColor: theme.palette.secondary.light,
                color: theme.palette.text.primary,
                position: 'relative',
                '& > span': {
                    textTransform: 'uppercase',
                    fontFamily: 'NTR'
                },
                '& > svg': {
                    width: 13,
                    height: 7
                },
                '& .general-unread-dot': {
                    background: theme.palette.attention[0],
                    borderRadius: '50%',
                    width: 14,
                    height: 14,
                    position: 'absolute',
                    top: 6,
                    right: 6
                }
            },
            '& .extension-selector-wrapper': {
                position: 'absolute',
                width: '100%',
                zIndex: 1,
                padding: '0 12px 12px',
                background: theme.palette.secondary.main,
                '& .extension-selector-items-wrapper': {
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: 320,
                    overflowY: 'auto',
                    padding: '11px 0',
                    '&::-webkit-scrollbar': {
                        '-webkit-appearance': 'none !important',
                        width: 6,
                        height: 6
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(255, 255, 255, 0.35)',
                        borderRadius: 3
                    },
                    '& .extension-selector-item': {
                        fontSize: 12,
                        fontWeight: 500,
                        lineHeight: '38px',
                        letterSpacing: 0.05,
                        padding: '0 3px',
                        color: 'white',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        minHeight: 38,
                        position: 'relative',
                        '&.has-unread': {
                            paddingRight: 30
                        },
                        '&.selected': {
                            fontWeight: 700,
                            color: 'white'
                        }
                    }
                }
            }
        }
    },
    identityText: {
        fontFamily: 'Montserrat-medium !important'
    },
    identityPopup: {
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        width: 280,
        height: 555,
        background: 'white',
        color: 'black',
        overflowY: 'auto',
        top: 20,
        zIndex: 10001,
        transform: 'translateX(110%)',
        boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.2), 0px 20px 35px rgba(0, 0, 0, 0.15), 0px 10px 45px rgba(0, 0, 0, 0.1)',
        borderRadius: 30,
        transition: 'opacity .2s',
        '& .identity-popup-actions': {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'end',
            marginTop: 5,
            marginBottom: 5,
            '& svg': {
                marginTop: 10
            }
        },
        '& .x-icon-wrapper': {
            position: 'absolute',
            top: 12,
            right: 12,
            padding: 12.45,
            lineHeight: 1,
            borderRadius: '50%',
            cursor: 'pointer',
            '&:hover': {
                background: theme.palette.secondary[-400]
            },
            '& .x-icon': {
                transform: 'scale(1.5)',
                width: 18.41,
                height: 18.41,
                color: theme.palette.secondary[0]
            }
        },
        '&.mobile-view': {
            right: 0,
            left: 0,
            top: 0,
            bottom: 0,
            position: 'fixed',
            transform: 'unset',
            width: 'unset',
            borderRadius: 'unset'
        }
    },
    identityPopupUserInfo: {
        paddingBottom: 14,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px 0 15px',
        '& > div': {
            marginBottom: 10
        },
        '& .main-text': {
            fontSize: 12,
            fontWeight: 600,
            lineHeight: '19px'
        },
        '& .secondary-text': {
            fontFamily: 'Montserrat-medium !important',
            fontSize: 10.5,
            fontWeight: 500,
            lineHeight: '16px',
            letterSpacing: 0.1,
            color: theme.palette.text.primary
        },
        '&.mobile-view': {
            paddingBottom: 70,
            '& > div': {
                marginBottom: 15
            },
            '& .main-text': {
                fontSize: 20,
                lineHeight: '28px'
            },
            '& .secondary-text': {
                fontSize: 16,
                lineHeight: '24px',
                marginTop: 5
            }
        }
    },
    identityPopupOptions: {
        maxHeight: 'max(40vh, 150px)',
        padding: 50,
        '& .identity-popup-option': {
            fontSize: 12,
            fontWeight: 600,
            lineHeight: '38px',
            color: theme.palette.primary[0],
            textAlign: 'center',
            cursor: 'pointer'
        },
        '&.mobile-view': {
            '& .identity-popup-option': {
                padding: 0,
                fontSize: 16,
                fontWeight: 500,
                lineHeight: '51px'
            }
        }
    },
    identityPopupDesktopPosition: {
        right: -20
    },
    versionInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '25px 0 30px',
        '& .secondary-text': {
            fontFamily: 'Montserrat-medium !important',
            fontSize: 10,
            fontWeight: 500,
            lineHeight: '16px',
            letterSpacing: 0.1,
            color: theme.palette.text.primary
        }
    },
    unreadInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 24,
        height: 24,
        borderRadius: '50%',
        background: theme.palette.attention[0],
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        boxShadow: '-1px 1px 0px rgba(0, 0, 0, 0.5)',
        '& > span': {
            color: 'white',
            fontFamily: 'NTR',
            fontSize: 14.5,
            letterSpacing: 0.1,
            lineHeight: 1,
            marginTop: 2
        }
    },
    dialogPaper: {
        margin: 0,
        width: '100%',
        height: '100%',
        maxHeight: '100%'
    },
    signOutButton: {
        background: `${theme.palette.text.primary} !important`,
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '16px'
    },
    brandLogo: {
        width: 36,
        minWidth: 36,
        maxHeight: 50
    }
})

export default styles
