import l from '../lang';

export default {
	'routes': {
		'create': 'subaccounts.create',
		'show': 'subaccounts.show',
		'index': 'subaccounts.index',
	},
	'delete-single': l.t('subaccounts.delete-single', 'Are you sure you want to delete this subaccount?'),
	'delete-filtered': l.t('subaccounts.delete-filtered', 'Are you sure you want to delete all filtered subaccounts?'),
	'delete-all': l.t('subaccounts.delete-all', 'Are you sure you want to delete all subaccounts?'),
	'successfull-creation': l.t('subaccounts.successfully-created', 'Your subaccount has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('subaccounts.failed-creation', 'There was a problem creating your subaccount'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
};
