/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import Typography from 'typography'
import InfoModal from 'info-modal'
import SmsEnrollmentBackground from '../images/Frame_669.png' // '../images/sms_enrollement_help_bg.png'
import SmsBrandPicture from '../images/sms_enrollment_brand.png'
import SmsCampaignPicture from '../images/sms_enrollment_campaign.png'
import SmsNumbersAssigningPicture from '../images/sms_enrollment_numbers_assigning.png'
import Button from 'button'
import PropTypes from 'prop-types'
import { getValue } from 'remote-config-value'
import { ArrowForwardIosIcon } from 'svg-icons'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    titleContainer: { paddingLeft: '2rem' },
    gridContainer: { display: 'grid', padding: '2rem', minWidth: '95rem' },
    bannerContainer: { gridArea: '1 / 1' },
    fullWidthBanner: { height: '33rem', width: '100%' },
    getStartedContainer: { gridArea: '1 / 1', padding: '5rem 20rem 1rem 4rem' },
    helpContentsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        columnGap: '1rem',
        padding: '2rem',
        minWidth: '95rem'
    },
    oneThirdContainer: { flexGrow: 1, width: 'calc(33%)' },
    gridDisplay: { display: 'grid' },
    centeredGrid: { gridArea: '1 / 1', justifySelf: 'center' },
    modalContainer: {
        width: '30rem',
        whiteSpace: 'nowrap',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start'
    },
    labelContainer: { fontWeight: 'bold', flexGrow: 1, width: 'calc(66%)' },
    feeContainer: { flexGrow: 1, width: 'calc(33%)', textAlign: 'right' },
    helpTextContainer: {
        background: theme.infoPopup.color,
        // border: '1px solid #E7E7E7',
        border: `1px solid ${theme.palette.other.black}`,
        padding: '1rem',
        borderRadius: '0.5rem',
        marginTop: '8rem',
        width: '30rem'
    },
    boldText: {
        fontWeight: 'bold !important' // override mui font-weight, this is bad practice
    }
}))

/**
 *
 * @param {object} param0 - props
 * @param {Function} param0.proceedClicked
 * @param {object} param0.classes
 */
const SmsEnrollmentInstruction = ({ proceedClicked }): JSX.Element => {
    const classes = useStyles()
    const [showModal, setShowModal] = useState(false)
    const onProceedClicked = () => {
        setShowModal(true)
    }
    const renderModal = () => {
        const content = (
            <>
                <Typography variant='subtitle1' remoteConfigID='sms_enrollment_fee_will_be_charged' />
                <br />
                <div className={classes.modalContainer}>
                    <div className={classes.labelContainer}>
                        <Typography variant='subtitle2' remoteConfigID='sms_enrollment_brand_enrollment_fee_label' />
                        <Typography variant='subtitle2' remoteConfigID='sms_enrollment_campaign_enrollment_fee_label' />
                        <Typography variant='subtitle2' remoteConfigID='sms_enrollment_monthly_campaign_enrollment_fee_label' />
                        <Typography variant='subtitle2' remoteConfigID='sms_enrollment_total_fee_label' />
                    </div>
                    <div className={classes.feeContainer}>
                        <Typography variant='subtitle2' remoteConfigID='sms_enrollment_brand_enrollment_fee' />
                        <Typography variant='subtitle2' remoteConfigID='sms_enrollment_campaign_enrollment_fee' />
                        <Typography variant='subtitle2' remoteConfigID='sms_enrollment_monthly_campaign_enrollment_fee' />
                        <Typography variant='subtitle2' remoteConfigID='sms_enrollment_total_fee' />
                    </div>
                </div>
            </>
        )
        return (
            <InfoModal
                title="Enrollment fees"
                isShown={showModal}
                content={content}
                buttonText="Proceed"
                onConfirm={proceedClicked}
                size="size3"
            />
        )
    }
    const renderHelpContents = ({ labelID, descriptionID, image }) => (
        <div key={labelID} className={classes.oneThirdContainer}>
            <div className={classes.gridDisplay}>
                <div className={classes.centeredGrid}>
                    <img src={image} alt="screenshot" />
                </div>
                <div className={classes.centeredGrid}>
                    <div className={classes.helpTextContainer}>
                        <Typography className={classes.boldText} variant="h5" remoteConfigID={labelID} />
                        <br />
                        <Typography variant="subtitle1" remoteConfigID={descriptionID} />
                    </div>
                </div>
            </div>
        </div>
    )
    const helpContents = [
        {
            labelID: 'sms_enrollment_what_is_brand_title',
            descriptionID: 'sms_enrollment_what_is_brand_description',
            image: SmsBrandPicture
        },
        {
            labelID: 'sms_enrollment_what_is_campaign_title',
            descriptionID: 'sms_enrollment_what_is_campaign_description',
            image: SmsCampaignPicture
        },
        {
            labelID: 'sms_enrollment_assigning_numbers_title',
            descriptionID: 'sms_enrollment_assigning_numbers_description',
            image: SmsNumbersAssigningPicture
        }
    ]
    return (
        <>
            <br />
            <div className={classes.titleContainer}>
                <Typography variant="h3" remoteConfigID='sms_enrollment_title' />
            </div>
            <div className={classes.gridContainer}>
                <div className={classes.bannerContainer}>
                    <img className={classes.fullWidthBanner} src={SmsEnrollmentBackground} alt="screenshot" />
                </div>
                <div id='getStartedContainer' className={classes.getStartedContainer}>
                    <br />
                    <Typography variant="h6" remoteConfigID='sms_enrollment_mobile_carriers_require' />
                    <br />
                    <Typography variant="h6" remoteConfigID='sms_enrollment_before_your_registration' />
                    <Typography variant="h6" remoteConfigID='sms_enrollment_learn_more' />
                    <br />
                    <Button size='large' endIcon={<ArrowForwardIosIcon />} color='primary' onClick={onProceedClicked}>{`${getValue('sms_enrollment_get_started')}`}</Button>
                </div>
            </div>
            <div
                className={classes.helpContentsContainer}
            >
                {helpContents.map(x => renderHelpContents(x))}
            </div>
            {showModal && renderModal()}
        </>
    )
}

SmsEnrollmentInstruction.prototype = {
    proceedClicked: PropTypes.func,
    classes: PropTypes.object
}

export default SmsEnrollmentInstruction
