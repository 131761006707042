/* eslint-disable jsdoc/require-param-type */
/* eslint-disable jsdoc/require-description */
import { nxt_company_inbox_extension } from 'phoenix-session-helpers'
import l from '../libs/lang'
import Device from './Device'
import Resource from './Resource'
import RouteAnalyzer from './RouteAnalyzer'
import setup from '../libs/resources-setups/extensions'

class Extension extends Resource {
    /**
     * @param session
     * @param component
     */
    constructor (session, component) {
        super(session, component, '/extensions')
        this.selector_mode = ['extensions', 'virtualExtensions']
        this.setup = setup
        this.filters = {
            name: null,
            number: null,
            name_or_number: null
        }
        this.item = {
            name: l.t('app.new-extension', 'New extension'),
            extension: null,
            usage_type: 'limited',
            device_membership: null,
            timezone: 'America/Los_Angeles',
            name_greeting: null,
            include_in_directory: true,
            caller_id: 'private',
            local_area_code: null,
            enable_call_waiting: true,
            enable_outbound_calls: true,
            voicemail_notify_phone: false,
            presence_publish: true,
            presence_subscribe: false,
            voicemail: {
                enabled: true,
                password: Math.floor(100000 + Math.random() * 900000),
                greeting: {
                    type: null,
                    alternate: null,
                    standard: null,
                    enable_leave_message_prompt: true
                },
                attachments: null,
                notifications: null,
                transcription: null
            },
            call_notifications: null,
            bypass_hard_phone: false,
            is_park_ext: false,
            route: null,
            conference: null
        }
    }

    /**
     *
     */
    async checkAll () {
        const nxt_company_inbox_extension_id = await nxt_company_inbox_extension(this.session) // we must not delete company_inbox_extension for nxt users
        super.checkAll()
        this.items.map((x) => {
            if (x.is_nxt || (nxt_company_inbox_extension_id && x.id && x.id === nxt_company_inbox_extension_id)) {
                x.selected = false
            }
            return x
        })
    }

    /**
     * @param extension
     */
    async pre_delete (extension) {
        if (
            !extension.device_membership ||
      (extension.device_membership &&
          !extension.device_membership.device)
        ) {
            return false
        }

        try {
            const device = await this.session.get_item(`/devices/${extension.device_membership.device.id}`)
            if (device && device.lines && device.lines.length) {
                device.lines = device.lines.filter((x) => x.extension.id !== extension.id)
                await this.session.patch_item(`/devices/${device.id}`, { lines: device.lines })
            }
        } catch (err) {
            throw new Error(
                l.t(
                    'extensions.delete-non-virtual-extension-error',
                    'An error occurred while trying to update device connected to your extension.'
                )
            )
        }

        return true
    }

    /**
     * @param itms
     */
    async pre_delete_all_filter (itms) {
        let items = itms.filter((x) => !x.is_nxt)
        const nxt_company_inbox_extension_id = await nxt_company_inbox_extension(this.session)
        if (nxt_company_inbox_extension_id) { // we must not delete company_inbox_extension for nxt users
            items = items.filter((x) => x.id && x.id !== nxt_company_inbox_extension_id)
        }
        return items
    }

    /**
     * @param params
     */
    async loadItems (params) {
        this.loading = true
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page)
            const items = await this.session.get_list(this.uri, params.limit, offset)
            this.page = params.page
            if (this.page > 1 && !items.items.length) {
                this.component.emit('pageChanged', 1)
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1
                })
            }
            if (this.page === 1 && !this.filters_applied && !items.items.length) return this.component.routerPush('extensions.create')

            this.items = items.items
            this.finalizeLoadingItems(items)
            for (const item of this.items) item.route_analysis = new RouteAnalyzer(item.route)

            this.component.emit('itemsLoaded', JSON.parse(JSON.stringify(items)))
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false

        return this.items
    }

    /**
     *
     */
    async prepareEmptyExtension () {
        this.loading = true
        try {
            const extensionNumber = await this.session.get_list(
                '/extensions?sort[extension]=desc&mode=brief', 1
            )
            if (extensionNumber.items.length) {
                this.item.extension = extensionNumber.items[0].extension + 1
            } else {
                this.item.extension = 100
            }
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
    }

    /**
     * @param ext
     */
    async attach_device (ext) {
        try {
            const device = new Device(this.session, this.component.emit)
            const device_item = await device.create({
                name: `${ext.extension || ''}: ${ext.name || ''} ${l.t('app.device', 'Device')}`,
                lines: [
                    {
                        line: 1,
                        extension: {
                            id: ext.id
                        }
                    }
                ]
            })
            return device_item
        } catch (err) {
            return false
        }
    }

    /**
     *
     */
    extension_created_device_failed_alert () {
        this.alert = {
            level: 'warning',
            message: l.t('app.ext-successfully-created-device-failed', 'Extension successfully created, but device creation failed.'),
            status_code: 400
        }
        window.scrollTo(0, 0)
        this.loading = false
        window.successfulAlertTimeout = setTimeout(() => {
            this.component.routerPush('extensions.index')
        }, this.timeUntilRedirects)
    }

    /**
     * @param extension
     */
    async create (extension) {
        this.loading = true
        try {
            const newExtension = await this.session.create_item(this.baseUri, extension)
            let device_creation_success = false
            if (extension.add_generic_device) {
                const device = await this.attach_device(newExtension)
                device_creation_success = (device && device.id)
            }
            if (newExtension.device_membership || (extension.add_generic_device && device_creation_success)) {
                this.addToCache(newExtension, 'virtualExtensions')
            }
            this.addToCache(newExtension, 'extensions')
            if (extension.add_generic_device && !device_creation_success) {
                this.extension_created_device_failed_alert()
            } else {
                this.successfulCreation('extensions.index')
            }
            this.item = null
        } catch (err) {
            return this.validation_error(err)
        }

        return true
    }

    /**
     * @param extension
     */
    async update (extension) {
        await super.update(extension)
        if (this.alert.level === 'success') {
            if (extension.device_membership) {
                this.updateCache(extension, 'virtualExtensions')
            }
            this.updateCache(extension, 'extensions')

            this.item = null
            this.successfulUpdate('extensions.index')
        }
    }

    /**
     * @param data
     */
    async update_route (data) {
        this.item = await this.session.patch_item(
            `${this.baseUri}/${this.item.id}`,
            { route: data }
        )
        if (this.item.device_membership) {
            this.updateCache(this.item, 'virtualExtensions')
        }
        this.updateCache(this.item, 'extensions')
        return this.item.route
    }

    /**
     *
     */
    apply_filters () {
        this.clearMessages()
        this.filters_applied = true
        try {
            let uri = this.baseUri
            const filters = []
            if (this.filters.name) filters.push(`filters[name]=contains:${this.filters.name}`)
            if (this.filters.number) filters.push(`filters[extension]=${this.filters.number}`)
            if (filters.length) uri = `${uri}?${filters.join('&')}`
            this.uri = uri
        } catch (err) {
            this.component.emit('failed', err)
        }
    }

    /**
     *
     */
    do_apply_dynamic_filters () {
        try {
            let uri = this.baseUri
            if (this.filters.name_or_number) {
                uri = `${uri}?filters[name-or-extension]=contains:${this.filters.name_or_number}`
            }
            this.uri = uri
        } catch (err) {
            this.validation_error(err)
        }
    }

    /**
     * @param data
     */
    async updateType (data) {
        this.loading = true
        try {
            await this.session.replace_item(
                `${this.baseUri}/${this.item.id}`, data
            )
            const check = await this.checkIfExtensionTypeIsUpdated(data)
            if (check) {
                this.item = null
                this.successfulUpdate('extensions.index')
            } else {
                this.alert = {
                    level: 'error',
                    message: l.t('app.generic-error', 'Something went wrong')
                }

                this.hide_alert(5)
                window.scrollTo(0, 0)
            }
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
    }

    /**
     * @param  data
     * @param _attempt
     */
    async checkIfExtensionTypeIsUpdated (data, _attempt = 1) {
    // eslint-disable-next-line consistent-return
        return new Promise((resolve) => {
            if (_attempt > 12) return resolve(false)
            window.setTimeout(async () => {
                const newly_updated_extension = await this.session.get_item(`${this.baseUri}/${data.id}`)
                if (data.usage_type !== newly_updated_extension.usage_type) {
                    _attempt++
                    return resolve(this.checkIfExtensionTypeIsUpdated(data, _attempt))
                }
                return resolve(newly_updated_extension)
            }, _attempt > 3 ? 5000 : _attempt * 1000)
        })
    }
}

export default Extension
