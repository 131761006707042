<template>
  <ReportsWrapper :report="report" data-test-id="call-reports-geo-distribution">
    <GeoDistributionChart :items="report.items" :key="!!report.logarithmic"/>
    <LogarithmicSwitcher v-model="report.logarithmic" @input="logarithmic" :key="`logatithmic-key-${report.logarithmic}`"/>
    <v-simple-table v-if="report.items" class="mt-3" data-test-id="geo-table">
        <thead data-test-id="table-head">
            <tr data-test-id="head-row">
                <th style="width:25%" data-test-id="state-country">{{ $lang.t('app.province-country', 'State/Country') }}</th>
                <th style="width:75%" data-test-id="npa">{{ $lang.t('app.npa', 'NPA') }}</th>
                <th data-test-id="calls">{{ $lang.t('app.Calls', 'Calls') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, i) of report.items.items" :data-test-id="i" :key="`row-${i}`">
                <td data-test-id="state">{{ item.state_name ? item.state_name : item.state ? item.state : '&mdash;' }}</td>
                <td data-test-id="npa">{{ item.npa && item.npa.length ? item.npa.join(', ') : '&mdash;' }}</td>
                <td data-test-id="total">{{ item.total }}</td>
            </tr>
        </tbody>
    </v-simple-table>
  </ReportsWrapper>
</template>
<script>
  import {vueComponent} from 'helpers';
  import GeoDistribution from '../../models/GeoDistribution';
  import ReportsWrapper from '../elements/Report.vue'
  import GeoDistributionChart from '../elements/charts/GeoDistribution.vue'
  import LogarithmicSwitcher from 'console/src/components/elements/logarithmic-representation-switcher.vue';
  
  export default {
    components: {ReportsWrapper, LogarithmicSwitcher, GeoDistributionChart},
    data() {
      return {
        report: new GeoDistribution(this.$session, vueComponent(this))
      };
    },
    async created() {
      await this.$data.report.loadItems();
      if (this.report.items) {
        this.$data.report.items = this.$data.report.prepare_items();
      }
    },
    methods: {
      logarithmic() {
        this.$data.report.items = this.$data.report.prepare_items();
      },
    },
  };
</script>
