import React from 'react'
import { Grid } from '@material-ui/core'
import Typography from 'typography'
import StatusChip from './StatusChip'
import { EnrollmentType } from '../interface/EnrollmentType'
import { getValue } from 'remote-config-value'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    gridGap: {
        gap: '0.5rem'
    },
    gridJustifyContent: {
        justifyContent: 'space-between'
    }
}))

/**
 *
 */
const EnrollmentPreviewTitle = ({ enrollment, enrollmentType }) => {
    const classes = useStyles()
    return (
        <Grid
            className={classes.gridGap}
            container
            direction='column'
        >
            <Grid className={classes.gridJustifyContent} direction='row' container xs={12}>
                <Typography id='contactName' variant='h5'>{enrollmentType === EnrollmentType.Brand ? enrollment['contact.name'] : enrollment.brand_name}</Typography>
                <Typography variant='h5'>{`${getValue(enrollmentType === EnrollmentType.Brand ? 'sms_enrollment_brand_id_column_name' : 'sms_enrollment_campaign_id')}: ${enrollment.identifier}`}</Typography>
            </Grid>
            <Typography variant='h3'>{enrollment.name}</Typography>
            <StatusChip status={enrollmentType === EnrollmentType.Brand ? enrollment.registration_status : enrollment.status} enrollmentType={enrollmentType} deleted={enrollment.deleted_at != null} />
        </Grid>
    )
}
EnrollmentPreviewTitle.propTypes = {
    enrollment: PropTypes.object,
    enrollmentType: EnrollmentType
}

export default EnrollmentPreviewTitle
