<template>
    <w-loader v-if="loading"/>
    <div v-else>
      <w-form @submit="submited" @changed="may_submit = true" data-discard="true" data-test-id="bc-form">
        <w-alert v-if="alert" :level="alert.level" :message="alert.message"/>
        <FormInput :label="$lang.t('blocked-calls.pattern-type', 'Pattern type')">
          <w-radio-group
            v-model="call.pattern_type"
            row
            hide-details='auto'
          >
            <v-radio
              :label="$lang.t('blocked-calls.equals', 'Equals')"
              value="equals"
              data-test-id="bc-equal-radio"
            ></v-radio>
            <v-radio
              :label="$lang.t('app.starts-with', 'Starts with')"
              value="starts_with"
              data-test-id="bc-starts-with-radio"
            ></v-radio>
          </w-radio-group>
        </FormInput>
        <FormInput :label="$lang.t('app.pattern', 'Pattern')">
          <Tel
            :required="true"
            maxlength="14"
            @changed="call.pattern = $event.clean_number"
            :value="call.pattern"
            data-test-id="bc-tel-input"
          />
        </FormInput>
        <FormInput :label="$lang.t('app.notes', 'Notes')">
          <w-text-field v-model="call.notes" maxlength="80" hide-details='auto' data-test-id="bc-notes-input"/>
        </FormInput>
        <FormInput>
          <w-btn :disabled="!may_submit" type="submit" class="primary" data-test-id="bc-submit-btn">
            {{ $lang.t("app.save", "Save") }}
          </w-btn>
        </FormInput>
        <dl>
          <!-- <dt>{{ $lang.t('app.start-date', 'Start date') }}</dt>
          <dd>
            <input type="date" v-model="start_date" required>
          </dd> -->
          <!-- <dt>
            {{ $lang.t('app.type', 'Type') }}
          </dt>
          <dd>
            <label>
              <input type="radio" value="incoming" v-model="call.type">
              {{ $lang.t('app.incoming', 'Incoming') }}
            </label>
            <label>
              <input type="radio" value="outgoing" v-model="call.type">
              {{ $lang.t('app.outgoing', 'Outgoing') }}
            </label>
          </dd> -->
        </dl>
      </w-form>
    </div>
  </template>
  
  <script>
  import Tel from 'console/src/components/elements/Tel.vue';
  import FormInput from 'console/src/components/elements/form/FormInput.vue';
  
  export default {
    props: ['_call'],
    components: {
      Tel,
      FormInput,
    },
    data() {
      return {
        loading: false,
        call: null,
        alert: null,
        may_submit: false,
        // start_date: null,
      };
    },
    created() {
        this.$data.call = this.$props._call;
        // this.$data.start_date = this.$data.call.start_date ? new Date(this.$data.call.start_date*1000).toISOString().split('T')[0] : new Date().toISOString().split('T')[0];
    },
    methods: {
      async submited(e) {
        if (e) e.preventDefault();
        this.prepareData();
        this.$emit('submited', this.$data.call);
      },
      prepareData() {
        this.$data.call.notes = this.$data.call.notes ? this.$data.call.notes.trim() : '';
      },
    },
  };
  </script>
  