import React, { useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import styles from './styles'
import Typography from 'typography'
import { HangUpIcon, MicIcon, MicOffIcon, PauseIcon, VolumeUpIcon } from 'svg-icons'
import Timer from 'timer'
import DisplayName from 'display-name'
import { ISoftphoneContext } from 'softphone'
import { SoftphoneContext } from 'softphone'

import { CallState } from 'pdc-calls'
import { FeatureEventsContext } from 'providers/src'
import { PdcCallContext } from 'pdc-calls'

const useStyles = makeStyles(styles)

const getName = (participants): JSX.Element => {
    if (participants.length > 1) return <>{participants.length} CALLERS</>
    if (participants.length) return <DisplayName value={participants[0].phoneNumber}/>
    return <>NO CALLER ID</>
}

/***/
export const ActiveCall = (): JSX.Element | null => {
    const softphoneContext: ISoftphoneContext = useContext(SoftphoneContext)

    const [softphoneShown, setSoftphoneShown] = React.useState(false)
    const callActionsRef = React.useRef<HTMLDivElement>(null)
    const classes = useStyles()
    const featureEventsContext = useContext(FeatureEventsContext)
    const callContext: any = useContext(PdcCallContext)

    const { /* currentCall,  */isMutedLocal } = callContext
    const currentCall = callContext.currentCall || null // { callId: 'asd', participants: [{ phoneNumber: '+18583713665' }], callStartTime: Date.now() / 1000 }

    const MuteIcon = isMutedLocal ? MicOffIcon : MicIcon
    const toggleMute = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'top-bar-active-call-mute-button-click', value: !isMutedLocal })
        callContext.muteLocal(!isMutedLocal)
    }
    const hangUp = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'top-bar-active-call-hang-up-button-click' })
        callContext.hangupById(currentCall.callId, true)
    }
    const isSoftphoneHidden = softphoneContext.isSoftphoneHidden()
    useEffect(() => { setSoftphoneShown(!isSoftphoneHidden) }, [isSoftphoneHidden])
    useEffect(() => { if (softphoneShown) softphoneContext.ShowSoftphone() }, [softphoneShown])

    if (!currentCall) return null

    if (softphoneShown) return null

    const mainOnClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
        const clickedElement = e.target as Node
        if (Array.from(callActionsRef.current?.children || []).some(c => c.contains(clickedElement))) return
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'top-bar-active-call-area-click' })
        setSoftphoneShown(true)
    }

    const onHoldCount = callContext.sessions.filter(s => s.isOnHold).length
    const activeCallsCount = callContext.sessions.length - onHoldCount

    return (
        <div className={`${classes.activeCall} active-call`} onClick={mainOnClick} data-test-id='top-bar-active-call'>
            <div className='call-info'>
                <div className='name-and-duration'>
                    <Typography variant='body2'>{getName(currentCall.participants)}</Typography>
                    {currentCall.callState === CallState.ACTIVE
                        ? <Typography variant='body2'><Timer startTime={currentCall.callStartTime}/></Typography>
                        : null
                    }
                </div>
                <div className='participants'>
                    <div><VolumeUpIcon/><Typography variant='subtitle2'>{activeCallsCount}</Typography></div>
                    <div><PauseIcon/><Typography variant='subtitle2'>{onHoldCount.toString()}</Typography></div>
                </div>
            </div>
            <div ref={callActionsRef} className='call-actions'>
                <button data-test-id='top-bar-mute-button' className={`mute-button ${isMutedLocal ? 'muted' : ''}`} onClick={toggleMute}><MuteIcon/></button>
                <button data-test-id='top-bar-hangup-button' className='hangup-button' onClick={hangUp}><HangUpIcon/></button>
            </div>
        </div>
    )
}

export default ActiveCall
