import React, { Component } from 'react'
import InfiniteScroller from 'pdc-infinite-scroller'
import Spinner from 'spinner'
import { formatPhoneNumber } from 'phone-numbers'
import { withStyles } from '@material-ui/core'
import { FSPrivate } from 'privacy'
import styles from './styles'
import PropTypes from 'prop-types'

class ContactSelector extends Component {
    render = () => {
        const { classes, searchQuery, contacts, hasMore, loadMore, isLoading, smallView } = this.props
        if (!contacts) return
        return (
            <div className={classes.ContactSelectorBody}>
                <InfiniteScroller
                    loadMore={loadMore}
                    hasMore={hasMore}
                    loader={<Spinner/>}
                >
                    {contacts.map((contact, i) => {
                        if (contact === 'no-results') {
                            if (isLoading) return null
                            return <div key='searched-contacts-no-results' className={classes.noContactsResult}>No contacts match your query</div>
                        }
                        return (
                            <div key={`searched-contacts-${i}`} className={`${classes.contactWrapper} ${smallView ? 'small-view' : ''}`}>
                                <FSPrivate>
                                    <div className={classes.contactName}>
                                        {contact.name.display}
                                    </div>
                                    <div>
                                        {contact.numbers.filter(n => {
                                            const numberFound = n.number.includes(searchQuery)
                                            const nameFound = contact.name.display.toLowerCase().includes(searchQuery.toLowerCase())
                                            return n.number && (!searchQuery || numberFound || nameFound)
                                        }).map((n, i2) => {
                                            const number = n.number.split('-').join('')
                                            let type = n.type
                                            type = type ? `${type[0].toUpperCase()}${type.substring(1)}` : type
                                            return (
                                                <div
                                                    key = {`number-${i}-${i2}`}
                                                    onClick = {() => this.props.selectContact(number, contact.name.display)}
                                                    className = {classes.phoneNumberSection}
                                                >
                                                    <span className='number-type'>{type}</span>
                                                    <span className='number'>{formatPhoneNumber(number)}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </FSPrivate>
                            </div>
                        )
                    })}
                </InfiniteScroller>
            </div>
        )
    }
}

ContactSelector.propTypes = {
    /**
     * Material ui classes
     */
    classes: PropTypes.object,
    searchQuery: PropTypes.string,
    selectContact: PropTypes.func,
    contacts: PropTypes.array,
    loadMore: PropTypes.func,
    hasMore: PropTypes.bool,
    isLoading: PropTypes.bool,
    smallView: PropTypes.bool
}

export default withStyles(styles)(ContactSelector)
