import { formatTime, formatPhoneNumber } from 'formatters'
import lang from 'lang'
import Progress from 'console/src/libs/progress'
import BlockedCall from 'console/src/models/BlockedCall'
import SmartFilters from 'console/src/models/SmartFilters'
import setup from 'console/src/libs/resources-setups/call-logs'
import CsvItemsDownloader from 'console/src/libs/csv-helpers/ItemsDownloader'

/**
 *
 */
export default class CallLog extends SmartFilters {
    /**
     *
     * @param {object} session
     * @param {object} component
     */
    constructor (session, component) {
        super(session, component, '/call-logs')
        this.blockable = []
        this.setup = setup
        this.blocked = []
        this.allow_any_time_filter = false
        this.empty_filters = CallLog.buildEmptyFilters()
        this.filters = CallLog.buildEmptyFilters()
        this.opened_call = null
        this.reports_filters_cache_key = 'call-reports-filters'
        this.first_time = true
        this.loading_id = null
        this.loading_contact = false
        this.loading_blocked = false
        this.applied_filters = []
        this.config_key = 'call-logs-filters'
        this.contact_search = null
        this.limit = 10
        this.number_in_process = null
        this.headers = {
            type: lang.t('app.type', 'Type'),
            start_time_epoch: lang.t('calls-history.started_at', 'Started at'),
            call_duration: lang.t('app.duration-m', 'Duration [M]'),
            duration_in_seconds: lang.t('app.duration-in-seconds', 'Duration in seconds'),
            direction: lang.t('app.direction', 'Direction'),
            caller_id: lang.t('app.from', 'From'),
            called_number: lang.t('app.to', 'To'),
            action: lang.t('app.final-action', 'Final action')
        }
        /* eslint-disable */
        this.functions = {
            type: (item) => `${item['type'].slice(0, 1).toUpperCase()}${item['type']
                .slice(1)
                .split('_')
                .join(' ')}`,
            start_time_epoch: (item) => new Date(item['start_time_epoch'] * 1000).toLocaleString().replace(',', ' '),
            call_duration: (item) => item.call_duration_minutes + (item.call_duration_minutes === 1 ? lang.t('app.minute', ' minute') : lang.t('app.minutes', ' minutes')),
            duration_in_seconds: (item) => item.call_duration,
            direction: (item) => {
                if (item['direction'] === 'in') {
                    return lang.t('app.inbound', 'Inbound');
                }
                return lang.t('app.outbound', 'Outbound');
            },
            caller_id: (item) => formatPhoneNumber(item['caller_id']).replace(/-/g, ''),
            called_number: (item) => formatPhoneNumber(item['called_number']).replace(/-/g, ''),
            action: (item) => { // temporary removed, probably will be needed in the future
                if (item.type === 'fax') {
                    if (item.direction === 'out') {
                        return `${lang.t('call-logs.fax-sent-to', 'Fax sent to')} ${formatPhoneNumber(item['called_number'])}`;
                    }
                    return `${lang.t('call-logs.received-fax-from', 'Received fax from')} ${formatPhoneNumber(item['caller_id'])}`;
                }
                const action = item.final_action;
                if (!action || ['type mandatory_hold', 'type blocked'].includes(action)) return '—';
                if (action === 'type_menu') {
                    return lang.t('app.menu-item', 'Menu item');
                }
                if (action === 'type directory') {
                    return lang.t('call-logs.company-directory', 'Company directory');
                }
                if (action.startsWith('Extension ')) {
                    if (item.extension) {
                        return `${lang.t('app.extension', 'Extension')}: ${item.extension.name}`;
                    }
                    return action;
                }
                if (action.startsWith('dial_out ')) {
                    const number = action.split('dial_out ');
                    if (number[1]) {
                        return `${lang.t('app.forwarded-to', 'Forwarded to')}: ${number[1]}`;
                    }
                }

                 if (action === 'type voicemail_received') {
                    return `${lang.t('call-logs.voicemail-received-from', 'Voicemail received from')} ${formatPhoneNumber(item['caller_id'])}`;
                 }
                 if (action === 'type voicemail') {
                    if (item.direction === 'in') {
                        return `${lang.t('call-logs.voicemail-to', 'Voicemail to')} ${item.extension ? item.extension.name : formatPhoneNumber(item.called_number)}`;
                    }
                 }
                 if (action === 'type queue') {
                    if (item.details && item.details.find((x) => x.type === 'queue')) {
                        return `${lang.t('app.queue', 'Queue')} #${item.details.find((x) => x.type === 'queue')['id_value']}`;
                    }

                    return lang.t('app.queue', 'Queue');
                 }

                return '—';
            },
        };
        
        this.render_functions = {
            caller_id: (item) => formatPhoneNumber(item['caller_id']),
            called_number: (item) => formatPhoneNumber(item['called_number']),
            
        };
        /* eslint-enable */
    }

    /**
     *
     */
    async get_downloadable_items () {
        const limit = 300
        const uri = `${this.baseUri}${this.get_filters()}`
        this.stop = false
        this.finished = false
        let all = []
        let res
        this.progress = new Progress({
            title: lang.t('call-logs.loading-call-recording-s', 'Loading call recording(s)'),
            // progress_message: undefined,
            cancel_btn_text: lang.t('app.cancel', 'Cancel'),
            confirm_cancel_message: lang.t('calllogs.stop-loading-these-recordings', 'Stop loading these recordings'),
            chunk_size: limit
        })
        this.progress.total = 0
        do {
            if (this.progress.stop) break
            res = await this.session.get_list(
                uri,
                limit,
                res ? res.offset + res.limit : 0
            )
            this.progress.total = res.total
            for (let i = 0; i < res.items.length; i++) {
                this.progress.add_success({
                    message: i
                })
            }
            all = all.concat(res.items)
        } while (res.total > all.length && res.items.length)
        return {
            items: all.filter((x) => x.call_recording_cp_url || x.voicemail_cp_url),
            total: all.length
        }
    }

    /**
     *
     */
    async download_all () {
        /**
         *
         * @param {object} log
         * @param {string} url
         * @returns
         */
        const file_name = (log, url) => {
            const url_parts = url.split('.')
            const file_extension = url_parts[url_parts.length - 1]
            const start = new Date(log.start_time_epoch * 1000)
            const start_time = start.toLocaleString()
                .replaceAll(',', ' ')
                .replaceAll('/', '-')
                .replaceAll('  ', '-')
                .replaceAll(' ', '-')

            return `${start_time} -- ${lang.t('app.from', 'From')} ${log.caller_id} -- ${lang.t('app.to', 'To')} -- ${log.called_number} -- ${log.id} (${log.record_duration}sec).${file_extension}`
        }
        const items = await this.get_downloadable_items()
        if (this.progress.stop) {
            this.progress = null
            return null
        }
        this.progress = new Progress({
            title: lang.t('call-logs.downloading-call-recording-s', 'Downloading call recording(s)'),
            // progress_message: undefined,
            cancel_btn_text: lang.t('app.cancel-download', 'Cancel download'),
            confirm_cancel_message: lang.t('calllogs.stop-downloading-these-recordings', 'Stop downloading these recordings'),
            chunk_size: 1
        })

        const data = items.items.reduce((a, c) => {
            const url = c.call_recording_cp_url || c.voicemail_cp_url
            if (url) {
                a.push({
                    url,
                    name: file_name(c, url)
                })
            }

            return a
        }, [])
        this.progress.total = data.length
        for (const file of data) {
            if (this.progress.stop) break
            try {
                const response = await fetch(file.url)
                const blob = await response.blob()
                const url = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.setAttribute('href', url)
                a.setAttribute('download', file.name)
                a.click()
                a.remove()
                this.progress.add_success({
                    message: file.name
                })
            } catch (err) {
                this.progress.add_error({
                    html: lang.t(
                        'logs.error-downloading-recording',
                        'Something went wrong while downloading<br/>{}.<br/>You can download the recording manyally <a href="{}" target="_blank">here<a/>.',
                        [file.name, file.url]
                    )
                })
            }
        }
        setTimeout(() => {
            this.progress.show_results = true
        }, 2000)

        return true
    }

    /**
     *
     */
    static buildEmptyFilters () {
        const end = new Date()
        let offset = end.getTimezoneOffset()
        offset = Math.abs(offset / 60)
        end.setHours(end.getHours() + offset)
        const start = new Date()
        offset = start.getTimezoneOffset()
        offset = Math.abs(offset / 60)
        start.setDate(end.getDate() - 7)
        start.setHours(start.getHours() + offset)

        return {
            end: end.toISOString().substr(0, 16),
            start: start.toISOString().substr(0, 16),
            duration_type: 'gt',
            duration: null,
            type: 'custom',
            direction: null,
            called_number: null,
            caller: null,
            id: null,
            contact_with_numbers: null
        }
    }

    /**
     *
     */
    apply_filters () {
        this.filters_applied = true
        this.do_apply_filters()
    }

    /**
     *
     */
    clear_filters () {
        this.keep_filters_open = true
        this.filters_applied = false
        this.filters = CallLog.buildEmptyFilters()
        this.saved_filter_name = null
        this.apply_filters()
    }

    /**
     *
     * @param {string} string
     * @returns
     */
    static db_time_from_string (string) {
        return Math.floor(
            new Date(string).getTime() / 1000
        )
    }

    /**
     *
     */
    do_apply_filters () {
        const uri = `${this.baseUri}${this.get_filters()}`
        this.uri = uri
    }

    /**
     *
     * @param {object} params
     */
    async loadItems (params) {
        this.loading = true
        this.blockable = []
        this.blocked = []
        const uri = `${this.baseUri}${this.get_filters()}`
        try {
            const items = await this.session.get_list(
                uri,
                params.limit,
                params.offset
            )
            this.items = items.items.map((c) => {
                const call = c
                for (const key of Object.keys(call)) {
                    if (
                        ((typeof call[key] === 'string' || Array.isArray(call[key])) &&
                            !call[key].length) ||
                        call[key] === null
                    ) {
                        delete call[key]
                    }
                }
                return call
            })
            // without await on purpose, to prevent rendering block
            this.loadBlockedCalls()
            if (!this.items.length) { // to slide up filters
                this.filters_applied = false
                this.keep_filters_open = false
            }
            this.component.emit('itemsLoaded', JSON.parse(JSON.stringify(items)))
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
    }

    /**
     *
     */
    async loadBlockedCalls () {
        this.loading_blocked = true
        this.blockable = []
        this.blocked = []
        try {
            const inbound = this.items.filter((x) => x.direction === 'in' && x.caller_id)
            const numbers = inbound.map((x) => {
                const number = x.caller_id
                return number.replace(/-/g, '').trim()
            }).filter((x) => x.match(/^([+\d].*)?\d$/gm))
            const uri = `/blocked-calls?mode=brief&filters[number]=in:${encodeURIComponent(numbers.join(','))}`
            const already_blocked_calls = await this.session.get_list_all(uri)
            if (already_blocked_calls && already_blocked_calls.items && already_blocked_calls.items.length) {
                const already_blocked = already_blocked_calls.items.map((x) => x.pattern)
                for (const num of numbers) {
                    if (already_blocked.includes(num)) {
                        this.blocked.push(num)
                    } else {
                        this.blockable.push(num)
                    }
                }
            } else {
                this.blockable = [...numbers]
            }
        } catch (err) {
            this.validation_error(err)
        }
        this.loading_blocked = false
    }

    /**
     *
     */
    generate_csv_file_name () {
        let filename = 'call-logs'
        if (this.extension) filename += `-extension-#${this.extension}`
        const start = formatTime(this.filters.start)
        const end = formatTime(this.filters.end)
        if (start) filename += `-from-${start}`
        if (end) filename += `-to-${end}`

        return filename
    }

    /**
     *
     * @param {number} value
     */
    async extensionChanged (value) {
        this.extension = value
        this.filters.contact_with_numbers = null
        await this.load_saved_filters()
    }

    /**
     *
     */
    async generate_csv () {
        this.csv_downloader = new CsvItemsDownloader(this.session, 300)
        try {
            const uri = `${this.baseUri}${this.get_filters()}`
            const res = await this.csv_downloader.get_list_all(uri)
            if (res === 'aborted') {
                this.csv_downloader = null
                return null
            }
            const filename = this.generate_csv_file_name()
            CallLog.download_csv(
                this.build_csv(res.items),
                `${filename}.csv`
            )
            this.csv_downloaded_successfully()
        } catch (err) {
            this.validation_error(err)
        }
        this.csv_downloader = null
        return true
    }

    /**
     *
     * @param {object} event
     * @param {string} call_id
     * @returns
     */
    toggleOpened (event, call_id) {
        const element = event.target
        if (element && element.classList && element.classList.contains('prevent-toggle')) return false
        const btn = element.closest('.v-btn')
        if (btn && btn.classList && btn.classList.contains('prevent-toggle')) return false
        const button = element.closest('button')
        if (button && button.classList && button.classList.contains('prevent-toggle')) return false

        const td = element.closest('td')
        if (td && td.classList && td.classList.contains('prevent-toggle')) return false
        this.opened_call = this.opened_call !== call_id ? call_id : null
        return true
    }

    /**
     *
     */
    get_filters () {
        let start
        let end
        if (this.filters.type && this.filters.type !== 'custom' && !this.saved_filter_name) {
            const quick = this.get_filter_by_type_or_default(this.filters.type)
            const time = quick.time()
            start = Math.floor(time.start.getTime() / 1000)
            let offset = time.end.getTimezoneOffset()
            offset = Math.abs(offset / 60)
            time.end.setHours(time.end.getHours() + offset)
            offset = time.start.getTimezoneOffset()
            offset = Math.abs(offset / 60)
            time.start.setHours(time.start.getHours() + offset)

            this.filters.start = time.start.toISOString().substr(0, 16)
            this.filters.end = time.end.toISOString().substr(0, 16)
        }
        const cached_filters = this.cachier.getItem(this.reports_filters_cache_key)
        if (this.first_time) {
            this.first_time = false
            if (!cached_filters) {
                const stored_filters = {
                    start: Math.floor(new Date(this.filters.start).getTime() / 1000),
                    end: Math.floor(new Date(this.filters.end).getTime() / 1000),
                    type: this.filters.type
                }
                this.cachier.setItem(this.reports_filters_cache_key, stored_filters)
            } else {
                this.filters.start = new Date(cached_filters.start * 1000).toISOString().substring(0, 16)
                this.filters.end = new Date(cached_filters.end * 1000).toISOString().substring(0, 16)
            }
        } else {
            const stored_filters = {
                start: Math.floor(new Date(this.filters.start).getTime() / 1000),
                end: Math.floor(new Date(this.filters.end).getTime() / 1000),
                type: this.filters.type
            }
            this.cachier.setItem(this.reports_filters_cache_key, stored_filters)
        }
        this.applied_filters = []
        if (this.filters.start) {
            this.applied_filters.push({
                name: 'start_time',
                value: start || CallLog.db_time_from_string(this.filters.start),
                operator: 'gte'
            })
        }
        if (this.filters.end) {
            this.applied_filters.push({
                name: 'start_time',
                value: end || CallLog.db_time_from_string(this.filters.end),
                operator: 'lt'
            })
        }
        if (this.filters.duration_type && this.filters.duration) {
            this.applied_filters.push({
                name: 'call_duration',
                value: this.filters.duration,
                operator: this.filters.duration_type
            })
        }
        if (this.filters.called_number) {
            this.applied_filters.push({
                name: 'called_number',
                value: this.filters.called_number,
                operator: 'eq'
            })
        }
        if (this.filters.caller) {
            this.applied_filters.push({
                name: 'caller',
                value: this.filters.caller,
                operator: 'eq'
            })
        }
        if (this.filters.direction) {
            this.applied_filters.push({
                name: 'direction',
                value: this.filters.direction
            })
        }
        if (this.filters.id) {
            this.applied_filters.push({
                name: 'id',
                value: this.filters.id.trim()
            })
        }
        if (this.filters.contact_with_numbers) {
            this.applied_filters.push({
                name: 'other_number',
                value: this.filters.contact_with_numbers.phone_numbers.map((p) => p.number.replace(/-/g, '')).join(','),
                operator: 'in'
            })
        }
        return this.applied_filters.length
            ? `?${this.applied_filters
                .map((f) => {
                    let value
                    if (f.operator === 'in') {
                        value = f.value.split(',').map((x) => encodeURIComponent(x)).join(',')
                    } else {
                        value = encodeURIComponent(f.value)
                    }
                    return `filters[${f.name}][]=${f.operator || 'eq'}:${value}`
                })
                .join('&')}`
            : ''
    }

    /**
     *
     * @param {Array} array_value
     * @returns
     */
    static allKeysOfArraysObject (array_value) {
        const keys = new Set()
        for (const d of array_value) {
            Object.keys(d).map((v) => keys.add(v))
        }
        return [...keys]
    }

    /**
     *
     * @param {object} call
     */
    async addToBlocked (call) {
        this.loading_id = call.id
        this.number_in_process = call.caller_id
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const blocked_call = new BlockedCall(this.session, () => {}, () => {})
        try {
            if (call.direction === 'in' && call.caller_id) {
                const blocked = await blocked_call.addToBlocked(call)
                if (blocked) {
                    this.blockable = this.blockable.filter((x) => x !== call.caller_id)
                    this.blocked.push(call.caller_id)
                }
            }
        } catch (err) {
            this.alert = blocked_call.alert
            this.hide_alert(5)
        }
        this.number_in_process = null
        this.loading_id = null
    }

    /**
     *
     * @param {object} call
     */
    async removeFromBlocked (call) {
        this.loading_id = call.id
        this.number_in_process = call.caller_id
        const uri = `/blocked-calls?mode=brief&filters[number]=${encodeURIComponent(call.caller_id.replace(/[-]/g, ''))}&limit=25`
        const blocked_call_item = await this.session.get_list(uri)
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        const blocked_call = new BlockedCall(this.session, () => {}, () => {})
        blocked_call.items = [blocked_call_item]
        try {
            for (const item of blocked_call_item.items) {
                await blocked_call.deleteItemCallLogs(item.id)
            }
            if (blocked_call_item.items.length) {
                await this.loadBlockedCalls()
            }
        } catch (err) {
            if (blocked_call.alert) {
                this.alert = blocked_call.alert
            } else {
                this.validation_error(err)
            }
            this.hide_alert(5)
        }
        this.number_in_process = null
        this.loading_id = null
    }
}
