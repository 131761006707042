import React, { useEffect, useState, useContext } from 'react'
import InvoiceStep from './InvoiceStep'
import PropTypes from 'prop-types'
import LoaderFull from 'loader-full'
import { CampaignContext } from '../contexts/CampaignContext'
import { useStepWizardAutoScrollUp } from '../hooks/useStepWizardAutoScrollUp'
/**
 * @param {object} props
 */
const CampaignPaymentStep = (props) => {
    const campaignInvoiceSavedData = props.passData.campaign_invoice
    const [confirm, setConfirm] = useState(campaignInvoiceSavedData || false)

    const {
        campaignPayment,
        fetchCampaignPaymentDetails,
        paymentInfoFetched
    } = useContext(CampaignContext)

    useStepWizardAutoScrollUp(paymentInfoFetched === true)

    useEffect(() => {
        props.update({ disableNextButton: () => !confirm })
    }, [confirm])

    useEffect(() => {
        if (props.saveId) props.update({ completed: true, gotoNext: true, saveData: confirm })
    }, [props.saveId])

    useEffect(() => {
        if (campaignPayment === null) {
            fetchCampaignPaymentDetails()
        }
    }, [])

    return (
        paymentInfoFetched
            ? (<InvoiceStep
                update={props.update}
                passData={props.passData}
                showConfirmation={props.showConfirmation}
                confirm={confirm}
                onChangeHandler={setConfirm}
                showAlert={props.showAlert}
                alertID='sms_enrollment_campaign_enrollment_info'
                price={15.00}
                tax={2.25}
                payment={campaignPayment}
            />)
            : (
                <LoaderFull styles={{ loaderFull: { left: '50%' } }} size='big'/>
            )
    )
}

CampaignPaymentStep.propTypes = {
    passData: PropTypes.object,
    update: PropTypes.func,
    saveId: PropTypes.number,
    showConfirmation: PropTypes.bool,
    showAlert: PropTypes.bool
}

export default CampaignPaymentStep
