<template>
  <div class="visualizerClass">
    <v-container class="visualizer-container mt-7" fluid>
      <v-row class="top-row">
        <v-col class="callFlowCol" cols="auto">
          <BackButton class="backButton" style="color: #197F9F !important;"/>
          <w-tooltip class="cursor-pointer tooltip" bottom :nudge-bottom="4" :max-width="250">
            <template v-slot:activator class="pb-5 mt-n5">
              <h2 class="callFlowHeading">
                Visual Call Flow:
              </h2>
            </template>
            Double click to open item's modal.
            <br>
            Click and hold on an item to move it.
            <br>
            Click and hold on the background to move the whole visual.
            <br>
            Scroll up and down to zoom in and out.
          </w-tooltip>
        </v-col>
        <v-col cols="auto" class="object-type center-autocomplete">
          <w-select
            v-model="filterType"
            :items="['Phone numbers', 'Extensions/Users']"
            item-text="name"
            class="mb-2"
            hide-details="auto"
            return-object
          />
        </v-col>
        <v-col cols="auto" class="center-autocomplete" :key="!!chosenItem">
          <APIAutocomplete
            v-if="filterType === 'Extensions/Users'"
            v-model="chosenItem"
            :return_object="true"
            :no_update="true"
            :style="{ minWidth: computedWidth + 'px' }"
            mode="extensions"
            key="extensions"
            data-test-id="visualizer-extension-selector"
          />
          <APIAutocomplete
            v-else-if="filterType === 'Phone numbers'"
            v-model="chosenItem"
            :return_object="true"
            :no_update="true"
            :style="{ minWidth: computedWidth + 'px' }"
            mode="phone_numbers"
            data-test-id="visualizer-number-selector"
          />
        </v-col>
      </v-row>
      <VisualizerController
        v-if="toShow && !loading"
        @zoomScene="zoomBlocks"
        @centerScene="centerBlocks"
        @moveScene="moveBlocks"
      />
      <BlocksContainer
        v-if="toShow && !loading"
        ref="container"
        :blocksContent="blocks"
        :scene.sync="scene"
        :maxHeight="maxHeight"
        :maxWidth="maxWidth"
        @blockSelect="selectBlock"
        @openModal="openModal"
        class="block-container"
      />
    </v-container>
    <w-loader v-if="loading"/>
    <DefaultModal
      v-if="modal_type && modal_type === 'device'"
      v-model="show_modal"
      :value="true"
      @click:cancel="show_modal = false"
      @close="modal_type = null"
      :width="500"
      data-test-id="modal-with-trigger"
    >
      <template v-slot:title>
        {{sip_can_be_visible(currentDevice.code, currentDevice.type) ? l.t('devices.devide-details-sip', 'Device details/SIP') : l.t('devices.devide-details', 'Device details')}}
      </template>
      <VerticalTable
        :items="details_and_sip(currentDevice)"
      >
      </VerticalTable>
      <template v-slot:buttons>
        <w-btn color="secondary" @click="show_modal = false">{{l.t('app.cancel', 'Cancel')}}</w-btn>
        <w-btn text>
          <CopyToClipboard
            :copy="text_to_be_copied(currentDevice)"
            :data-test-id="`devices-item-${currentDevice.id}-copy-to-clipboard`"
          />
        </w-btn>
      </template>
    </DefaultModal>
    <FormModal
      v-else-if="modal_type && modal_type !== 'device'"
      v-model="show_modal"
      :with_x="true"
      @close="modal_type = null"
      width="1000"
    >
      <MultiResourceModalContent
        v-if="modal_type === 'extension'"
        :id="actionId"
        type="extension"
        @updated="update_screen"
        @click:cancel="show_modal = false"
        data-test-id="autocomplete-edit-extension"
      />
      <MultiResourceModalContent
        v-if="modal_type === 'preset'"
        :id="actionId"
        type="route"
        @updated="update_screen"
        @click:cancel="show_modal = false"
        data-test-id="autocomplete-edit-route"
      />
      <MultiResourceModalContent
        v-if="modal_type === 'phone_number'"
        :id="actionId"
        type="phone_number"
        @updated="update_screen"
        @click:cancel="show_modal = false"
        data-test-id="autocomplete-edit-phone-num"
      />
      <Menu 
        v-if="modal_type === 'menu'"
        :id="actionId"
        @click:cancel="show_modal = false"
        :full_menu="true"
        @updated="update_screen"
        data-test-id="selector-edit-menu" />
      <Trunk 
        v-if="modal_type === 'trunk'"
        :id="actionId"
        @click:cancel="show_modal = false"
        @updated="update_screen"
        data-test-id="selector-edit-trunk">
      </Trunk>
      <Queue 
        v-if="modal_type === 'queue'"
        :id="actionId"
        @click:cancel="show_modal = false"
        @updated="update_screen"
        data-test-id="selector-edit-queue"/>
      <LiveReceptionistShow 
        v-if="modal_type === 'receptionist'"
        :_id="actionId"
        @click:cancel="show_modal = false"
        @updated="update_screen"
        data-test-id="selector-edit-lr"/>
    </FormModal>
  </div>
</template>

<script>
  import {is_csr} from 'phoenix-session-helpers';
  import {formatPhoneNumber} from 'formatters'
  import BlocksContainer from './components/BlocksContainer.vue';
  import VisualizerController from './components/VisualizerController.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import l from '../../../libs/lang';
  import Visualizer from './helpers/visualizer-helpers';
  import VerticalTable from '../../elements/VerticalTable.vue';
  import CopyToClipboard from '../../elements/CopyToClipboard.vue';
  import DefaultModal from '../../elements/modal/DefaultModal.vue';
  import FormModal from '../../elements/modal/FormModal.vue';
  import BackButton from '../../elements/BackButton.vue';
  import APIAutocomplete from '../../elements/form/APIAutocomplete.vue';
  import { LiveReceptionistShow } from 'live-receptionist-components';

  export default {
    name: 'visualizerApp',
    components: {
      FormInput,
      BackButton,
      FormModal,
      DefaultModal,
      VerticalTable,
      LiveReceptionistShow,
      APIAutocomplete,
      CopyToClipboard,
      BlocksContainer,
      VisualizerController,
      Menu: () => import('../menus/show.vue'),
      Trunk: () => import('../trunks/show.vue'),
      Queue: () => import('../queues/show.vue'),
      MultiResourceModalContent: () => import('../../elements/MultiResourceModalContent.vue'),
    },
    mounted() {
      const element = document.getElementById('page-content');
      if (element) {
        element.classList.add('visualizer-content');
      }
    },
    destroyed() {
      const element = document.getElementById('page-content');
      if (element) {
        element.classList.remove('visualizer-content');
      }
    },
    data() {
      return {
        l,
        show_modal: false,
        codes_that_can_see_sip: [17349, 17390, 17392, 17393, 17394, 17396, 17397],
        is_csr: false,
        actionId: -1,
        loading: false,
        isStaggered: false,
        modal_type: null,
        filterType: this.$route.name.includes('phone-numbers') ? 'Phone numbers' : 'Extensions/Users',
        toShow: false,
        menuCallFlows: [],
        scripts: [],
        routes: [],
        blocks: [],
        menus: [],
        queues: [],
        devices: [],
        call_flow_counter: 0,
        extensions: [],
        phone_numbers: [],
        actionIds: {},
        chosenItem: null,
        currentDevice: null,
        callFlowBlockCounter: 1,
        startingX: 0,
        distanceBetween: 335,
        maxHeight: 0,
        newIDs: 1,
        maxWidth: 250,
        scene: {
          blocks: [],
          links: [],
        },
      };
    },
    async created() {
      this.loading = true;
      this.is_csr = await is_csr(this.$session);
      if (this.$route.params.id && this.$route.name === 'phone-numbers.visualizer') {
        this.chosenItem = await this.$session.get_item(`/phone-numbers/${this.$route.params.id}`);
      } else if (this.$route.params.id && this.$route.name === 'extensions.visualizer') {
        this.chosenItem = await this.$session.get_item(`/extensions/${this.$route.params.id}`);
      }
      this.loading = false;
    },
    computed: {
      computedWidth() {
        if (!this.chosenItem) return 200;

        const offScreenElement = document.createElement('span');
        offScreenElement.style.position = 'absolute';
        offScreenElement.style.visibility = 'hidden';
        offScreenElement.style.whiteSpace = 'nowrap';
        offScreenElement.style.letterSpacing = '0px';
        offScreenElement.style.fontFamily = 'Montserrat-medium, sans-serif, sans-serif';
        offScreenElement.style.fontWeight = '500';
        offScreenElement.style.fontSize = '12px';

        const text = this.chosenItem.phone_number ? `${this.chosenItem.name}: ${this.chosenItem.phone_number}` : `${this.chosenItem.extension}: ${this.chosenItem.name}`;
        offScreenElement.textContent = text;
        document.body.appendChild(offScreenElement);
        const width = offScreenElement.offsetWidth;
        document.body.removeChild(offScreenElement);
        const padding = 72;

        return width + padding;
      },
    },
    methods: {
      sip_can_be_visible(code, type) {
        return this.$data.is_csr || this.codes_that_can_see_sip.includes(code) || type === 'softphone';
      },
      device_name(item) {
        if (item.model) {
          return `${item.model.manufacturer} ${item.model.name}`;
        }
        if (item.type === 'generic') return l.t('device.generic-device', 'Generic device');

        return '—';
      },
      details_and_sip(item) {
        let items = [
          {text: l.t('app.ID', 'ID'), value: item.id},
          {text: l.t('devices.device-name', 'Device name'), value: this.device_name(item)},
          {text: l.t('app.mac-address', 'MAC address'), value: item.mac_address || '–'},
          {text: l.t('app.serial-number', 'Serial number'), value: item.serial_number || '–'},
        ];
        for (const line of item.lines) {
          items.push({
            text: `${l.t('app.line', 'Line')} ${line.line}`,
            value: `${l.t('app.extension', 'Extension')} ${line.extension.extension}`,
          });
        }
        if (this.sip_can_be_visible(item.code, item.type)) {
          items = items.concat([
            {text: l.t('devices.sip-proxy', 'SIP proxy'), value: item.sip_authentication.host},
            {text: l.t('app.port', 'Port'), value: item.sip_authentication.port},
            {text: l.t('app.username', 'Username'), value: item.sip_authentication.username},
            {text: l.t('app.password', 'Password'), value: item.sip_authentication.password},
          ]);
        }

        return items;
      },
      text_to_be_copied(device) {
        const text = [];
        const details = this.details_and_sip(device);
        for (const detail of details) {
          text.push(`${detail.text}: ${detail.value}`);
        }
        return text.join('\n');
      },
      zoomBlocks(val) {
        this.$refs.container.buttonScroll(val);
      },
      centerBlocks() {
        this.$refs.container.center();
      },
      moveBlocks(val) {
        this.$refs.container.moveScene(val.axis, val.amount);
      },
      async update_screen(data) {
        setTimeout(() => {
          this.modal_type = null;
          this.show_modal = false;
          this.loading = true;
        }, 1000);
        if (data && data.phone_number) {
          const foundIndex = this.phone_numbers.findIndex((item) => item.id === data.id);
          this.phone_numbers[foundIndex] = await this.$session.get_item(`/phone-numbers/${data.id}`);
        } else if (data && data.extension) {
          const foundIndex = this.extensions.findIndex((item) => item.id === data.id);
          this.extensions[foundIndex] = await this.$session.get_item(`/extensions/${data.id}`);
        } else if (data && data.members) {
          const foundIndex = this.queues.findIndex((item) => item.id === data.id);
          this.queues[foundIndex] = await this.$session.get_item(`/queues/${data.id}`);
        } else if (!data) {
          this.menus = [];
          this.routes = [];
          this.scripts = [];
          this.devices = [];
        }
        if (this.chosenItem.phone_number) {
          this.chosenItem = await this.$session.get_item(`/phone-numbers/${this.chosenItem.id}`);
        } else {
          this.chosenItem = await this.$session.get_item(`/extensions/${this.chosenItem.id}`);
        }
      },
      async changeMapOption(type) {
        try {
          this.scene.blocks = [];
          this.scene.links = [];
          this.blocks = [];
          this.menuCallFlows = [];
          this.call_flow_counter = 0;
          this.callFlowBlockCounter = 1;
          this.actionIds = {};
          this.newIDs = 1;
          this.maxHeight = 0;
          this.maxWidth = 250;
          this.isStaggered = false;
          this.toShow = false;
          this.loading = true;

          await this.startRoute(this.chosenItem);

          this.loading = false;
          this.toShow = true;
        } catch (error) {
          console.warn(error);
          this.loading = false;
        }
      },
      async startRoute(response) {
        if (response.phone_number) {
          this.addPhoneNumberBlock(response, 0);
        } else {
          this.addExtensionBlock(response, 0);
        }

        await this.loopBlocks(this.startingX, 1);
        this.staggerBlocksv2(this.scene.blocks[0]);
        if (this.isStaggered) {
          this.scene.blocks.sort((a, b) => a.newID - b.newID);
        }
        this.alignObjects(this.startingX);
      },
      async loopBlocks(amount, sceLen) {
        const sceneBLocks = this.scene.blocks.filter((block) => block.x === amount);
        for (const sceneBlock of sceneBLocks) {
          const block = this.blocks[sceneBlock.id - 1];
          const outputs = block.fields.filter((item) => item.type);
          await this.configureBlocks(outputs, sceLen, sceneBlock);
        }
        if (sceneBLocks.length) {
          await this.loopBlocks(amount + this.distanceBetween, sceLen + 1);
        }
      },
      alignObjects(amount) {
        if (this.scene.blocks.length) {
          const blocks = this.scene.blocks.filter((b) => b.x === amount);
          if (blocks.length) {
            const total = blocks.reduce((a, c) => a + (c.height || 0), 0);
            let newY = (total / 2) * (-1);

            for (const block of blocks) {
              block.y = newY;
              newY += block.height;
            }

            this.maxHeight = total > this.maxHeight ? total : this.maxHeight;
            return this.alignObjects(amount + this.distanceBetween);
          }
          this.maxWidth = (this.scene.blocks.length > 1) ? amount : 250;
        }
        return true;
      },
      staggerBlocksv2(block) {
        if (!block.newID) {
          const outputs = this.blocks[block.id - 1].fields.filter((item) => item.type);
          block.newID = this.newIDs++;
          for (const output of outputs) {
            if (output.type === 'call flow') {
              this.call_flow_counter++;
              this.staggerBlocksv2(this.scene.blocks.find((b) => b.name === `Call Flow ${this.call_flow_counter}`));
            } else {
              this.staggerBlocksv2(this.scene.blocks[this.actionIds[output.actionId]]);
            }
          }
          this.alignBlocksv2(block);
        }
      },
      alignBlocksv2(lastSceneBlock) {
        const flagLinks = this.scene.links.filter((link) => link.originID === lastSceneBlock.id && !link.backlink);
        const seenTargetIds = {};
        const links = flagLinks.filter((link) => {
          if (!seenTargetIds[link.targetID]) {
            seenTargetIds[link.targetID] = true;
            return true;
          }
          return false;
        });
        const blocksHeight = links.reduce((a, c) => a + (c.targetHeight || 0), 0);
        const alignBlocks = this.scene.blocks.filter((b) => links.map((link) => link.targetID).includes(b.id));

        if (alignBlocks.length > 4 && lastSceneBlock.height < blocksHeight) {
          this.isStaggered = true;
          let i = 0;
          for (const block of alignBlocks) {
            if (i % 2 !== 0) {
              block.x += this.distanceBetween;
            }
            i++;
          }

          for (const block of this.scene.blocks.filter((b) => b.newID > lastSceneBlock.newID && !links.find((li) => li.targetID === b.id) && b.x > lastSceneBlock.x)) {
            block.x += this.distanceBetween;
          }
        }
      },
      async configureBlocks(outputs, sceLen, block) {
        for (const output of outputs) {
          const found = (output.type !== 'call flow' && this.actionIds[output.actionId] !== undefined) ? this.scene.blocks[this.actionIds[output.actionId]] : null;
          if (found) {
            this.addSceneLink(block, output.id, found);
          } else if (output.type === 'extension') {
            const extension = await this.getExtension(output.actionId);
            if (extension) this.addExtensionBlock(extension, sceLen, output.id, block);
          } else if (output.type === 'menu') {
            const menu = await this.getMenu(output.actionId);
            if (menu) this.addMenu(menu, sceLen, output.id, block);
          } else if (output.type === 'receptionist') {
            const script = await this.getScript(output.actionId);
            if (script) await this.addReceptionistBlock(script, sceLen, output.id, block);
          } else if (output.type === 'device') {
            const device = await this.getDevice(output.actionId);
            if (device) this.addDeviceBlock(device, sceLen, output.id, block);
          } else if (output.type === 'trunk') {
            this.addTrunkBlock({name: output.actionName, id: output.actionId}, sceLen, output.id, block);
          } else if (output.type === 'queue') {
            const queue = await this.getQueue(output.actionId);
            if (queue) this.addQueueBlock(queue, sceLen, output.id, block);
          } else if (output.type === 'preset') {
            const route = await this.getRoute(output.actionId);
            if (route) this.addPresetBlock(route, sceLen, output.id, block);
          } else if (output.type === 'call flow') {
            const call_flow = this.menuCallFlows.find((callFlow) => callFlow.name === output.name);
            if (call_flow) this.addCallFlowBlock(call_flow, sceLen, output.id, block);
          }
        }
      },
      async getMenu(id) {
        try {
          let menu = this.menus.find((m) => m.id === id);
          if (!menu) {
            menu = await this.$session.get_item(`/menus/${id}`);
            this.menus.push(menu);
          }
          return menu
        } catch (error) {
          console.log(error);
          return null;
        }
      },
      async getScript(id) {
        try {
          let script = this.scripts.find((d) => d.id === id);
          if (!script) {
            script = await this.$session.get_item(`/live-answer/${id}`);
            this.scripts.push(script);
          }
          return script;
        } catch (error) {
          console.log(error);
          return null;
        }
      },
      async getExtension(id) {
        try {
          let extension = this.extensions.find((d) => d.id === id);
          if (!extension) {
            extension = await this.$session.get_item(`/extensions/${id}`);
            this.extensions.push(extension);
          }
          return extension;
        } catch (error) {
          console.log(error);
          return null;
        }
      },
      async getDevice(id) {
        try {
          let device = this.devices.find((d) => d.id === id);
          if (!device) {
            device = await this.$session.get_item(`/devices/${id}`);
            this.devices.push(device);
          }
          return device;
        } catch (error) {
          console.log(error);
          return null;
        }
      },
      async getQueue(id) {
        try {
          let queue = this.queues.find((d) => d.id === id);
          if (!queue) {
            queue = await this.$session.get_item(`/queues/${id}`);
            this.queues.push(queue);
          }
          return queue;
        } catch (error) {
          console.log(error);
          return null;
        }
      },
      async getRoute(id) {
        try {
          let route = this.routes.find((d) => d.id === id);
          if (!route) {
            route = await this.$session.get_item(`/routes/${id}`);
            this.routes.push(route);
          }
          return route;
        } catch (error) {
          return null;
        }
      },
      getDeviceType(type) {
        switch (type) {
          case 'desk':
            return 'Desk phone';
          case 'iphone':
            return 'iPhone';
          case 'generic':
            return 'Generic device';
          default:
            return type;
        }
      },
      addExtensionBlock(extension, sceLen, outputID = null, lastBlock = null) {
        const options = {
          title: `Extension/User ${extension.extension}`,
          name: extension.name,
          actionId: extension.id,
          color: 'rgba(244, 245, 246, 0.8)',
          objectType: 'extension',
          fields: Visualizer.getBlockOutputs(extension)
        };
        const device_info = {
          device_membership: extension.device_membership,
          role: extension.role,
        };
        this.addBlocks(options, sceLen, outputID, lastBlock, device_info);
      },
      addPresetBlock(preset, sceLen, outputID = null, lastBlock = null) {
        const options = {
          title: 'Preset',
          name: preset.name,
          actionId: preset.id,
          color: 'rgba(216, 251, 201, 0.8)',
          objectType: 'preset',
          fields: Visualizer.getBlockOutputs({route: preset})
        };
        this.addBlocks(options, sceLen, outputID, lastBlock);
      },
      addPhoneNumberBlock(phone_number, sceLen, outputID = null, lastBlock = null) {
        const options = {
          title: `Phone Number: ${this.format_phone_number(phone_number.phone_number)}`,
          name: phone_number.name,
          actionId: phone_number.id,
          color: 'rgba(247, 255, 229, 0.8)',
          objectType: 'phone_number',
          fields: Visualizer.getBlockOutputs(phone_number)
        };
        this.addBlocks(options, sceLen, outputID, lastBlock);
      },
      addDeviceBlock(device, sceLen, outputID, lastBlock) {
        const title = this.getDeviceType((device.model && device.model.type) ? device.model.type : device.type);
        let name = device.name || '';
        if (device.model && device.model.name && device.model.manufacturer) {
          name = `${device.model.manufacturer} ${device.model.name}`;
        }
        const options = {
          title,
          name,
          actionId: device.id,
          color: 'rgba(231, 235, 255, 0.8)',
          objectType: 'device',
          fields: Visualizer.getDeviceOutputs(title)
        };
        this.addBlocks(options, sceLen, outputID, lastBlock);
      },
      addTrunkBlock(trunk, sceLen, outputID, lastBlock) {
        const options = {
          title: 'Trunk',
          name: trunk.name,
          actionId: trunk.id,
          color: 'rgba(231, 235, 255, 0.8)',
          objectType: 'trunk',
          fields: Visualizer.getDeviceOutputs('Trunk')
        };
        this.addBlocks(options, sceLen, outputID, lastBlock);
      },
      async addReceptionistBlock(script, sceLen, outputID, lastBlock) {
        const fields = Visualizer.getReceptionistOutputs(script);
        await this.updateReceptionistFields(fields);
        const options = {
          title: 'Receptionist services',
          name: script.name,
          actionId: script.id,
          color: 'rgba(247, 237, 255, 0.8)',
          objectType: 'receptionist',
          fields
        };
        this.addBlocks(options, sceLen, outputID, lastBlock);
      },
      addMenu(menu, sceLen, outputID, lastBlock) {
        const menuOutputs = Visualizer.getMenuOutputs(menu, this.callFlowBlockCounter);
        this.callFlowBlockCounter = menuOutputs.counter;
        this.menuCallFlows.push(...menuOutputs.menuCallFlows);
        const options = {
          title: 'Menu',
          name: menu.name,
          actionId: menu.id,
          color: 'rgba(234, 245, 248, 0.8)',
          objectType: 'menu',
          fields: menuOutputs.fields
        };
        this.addBlocks(options, sceLen, outputID, lastBlock);
      },
      addQueueBlock(queue, sceLen, outputID, lastBlock) {
        const options = {
          title: 'Queue',
          name: queue.name,
          actionId: queue.id,
          color: 'rgba(247, 235, 212, 0.8)',
          objectType: 'queue',
          fields: Visualizer.getQueueOutputs(queue)
        };
        this.addBlocks(options, sceLen, outputID, lastBlock);
      },
      addCallFlowBlock(call_flow, sceLen, outputID, lastBlock) {
        const options = {
          title: call_flow.name,
          name: call_flow.name,
          actionId: -1,
          color: 'rgba(253, 237, 235, 0.8)',
          objectType: 'call flow',
          fields: call_flow.fields
        };
        this.addBlocks(options, sceLen, outputID, lastBlock);
      },
      async updateReceptionistFields(fields) {
        for (const field of fields.filter((f) => f.actionId)) {
          const extension = await this.getExtension(field.actionId);
          field.actionName = extension ? extension.name : '';
          if (!extension) {
            field.actionId = null;
            field.type = null;
          }
        }
      },
      addBlocks(options, sceLen, outputID, lastBlock, device_info = null) {
        this.addFirstBlock(options, device_info);
        this.addSceneBlock(options, sceLen);
        if (outputID !== null) this.addSceneLink(lastBlock, outputID, this.scene.blocks[this.scene.blocks.length - 1]);
      },
      addFirstBlock(options, device_info) {
        if (this.blocks.length) options.fields.push({name: '', attr: 'input'});

        if (device_info && device_info.device_membership && options.fields.find((field) => field.actionId === options.actionId)) {
          const device_outputs = options.fields.filter((field) => field.actionId === options.actionId && field.actionType === 'Forward call');
          for (const field of device_outputs) {
            field.actionName = 'Ring my device';
            field.device = device_info.role;
            field.actionId = device_info.device_membership.device.id;
            field.type = 'device';
          }
        }

        this.blocks.push({...options});
      },
      addSceneBlock(options, sceLen) {
        this.actionIds[options.actionId] = this.scene.blocks.length;
        const { fields, ...rest } = options;

        this.scene.blocks.push({
          id: this.scene.blocks.length + 1,
          ...rest,
          x: this.startingX + sceLen * this.distanceBetween,
          y: this.scene.blocks.length + 1,
          height: fields.length * 21 + (options.objectType === 'call flow' ? 30 : 60),
        });
      },
      addSceneLink(originBlock, originSlot, targetBlock) {
        this.scene.links.push({
          id: this.scene.links.length + 1,
          originID: originBlock.id,
          backlink: targetBlock.x < originBlock.x + 230,
          targetID: targetBlock.id,
          targetSlot: 0,
          originSlot: parseInt(originSlot),
          targetHeight: targetBlock.height,
        });
      },
      customFilter(item, queryText, itemText) {
        const str = queryText.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
        const str2 = itemText.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
        return str2.includes(str) || str2.includes(str);
      },
      format_phone_number(number) {
        if (!number) return '';
        return formatPhoneNumber(number);
      },
      openModal(event) {
        if (event.actionId) {
          if (event.modal_type === 'device') {
            this.currentDevice = this.devices.find((device) => device.id === event.actionId);
          }
          this.actionId = event.actionId;
          this.modal_type = event.modal_type;
          this.show_modal = true;
        }
      },
      selectBlock(block) {
        console.log(`X: ${parseInt(block.x)}, Y: ${parseInt(block.y)}, ID: ${block.id}, newID: ${block.newID}`, block);
      },
    },
    watch: {
      chosenItem() {
        this.changeMapOption(this.chosenItem.phone_number ? 'phone_number' : 'extension');
      },
    }
  };
</script>

<!-- <style lang="scss">
@import "./styles/visualizer.scss";
</style> -->
<style lang='scss' scoped>
.visualizerClass {
  height: 100%;
  margin-top: -15px;
}
.visualizerClass::v-deep .v-input__slot {
  background-color: white !important;
}
.block-container {
  position: fixed;
  top: 65px;
  left: 222px;
  height: 100%;
  padding-left: 0px;
  width: 100%;
  flex-grow: 1;
  margin-top: -8px;
  @media only screen and (max-width: 961px) {
    left: 0px;
  }
}
.callFlowHeading {
  font-weight: 600;
  margin-top: 5px;
  font-size: 10px;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #197F9F;
}
.callFlowCol {
  display: flex;
  align-items: center;
}
.visualizer-container {
  display: flex;
  flex-direction: column;
  // margin-left: 29px;
}
.top-row {
  flex-shrink: 0;
  background-color: white;
  width: max-content;
  z-index: 2;
  border-radius: 9px;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
}
.object-type {
  max-width: 200px;
}
.center-autocomplete {
  margin-top: -6px;
}
.dynamic-width-autocomplete {
  width: auto !important;
}
.chosenItemCol {
  width: 360px;
}
.backButton {
  margin-top: -4px;
  font-weight: 600 !important;
  font-size: 10px !important;
  display: flex;
  align-items: center;
  letter-spacing: 0.4px !important;
  text-transform: uppercase !important;
  color: #197F9F !important;
}
</style>
