import React from 'react'

import ringSound from './sounds/ring-sound.mp3'
import ringBackSound from './sounds/ring-back-sound.mp3'
import callWaitingSound from './sounds/call-waiting-sound.mp3'
// import holdSound from './sounds/hold.mp3'
import { CallState, PdcCallContext } from 'pdc-calls'
import { useContext, useState, useEffect } from 'react'
import { db } from 'mypdc-dexie'
import { ringtones, RingtoneData, RingtoneDbObj } from './Ringtones'
import { useVolume } from '../AudioSettings/useDevices'

const ringBackSoundAudio: HTMLAudioElement = new Audio(ringBackSound)
const ringSoundAudio: HTMLAudioElement = new Audio(ringSound)
const callWaitingSoundAudio: HTMLAudioElement = new Audio(callWaitingSound)
let audioAllowed = false
let ringingInProgress: Promise<HTMLAudioElement> | null = null
/**
 * only exported for testing, do not access directly
 */
export { ringSoundAudio, ringBackSoundAudio, callWaitingSoundAudio, audioAllowed }

const NO_RINGTONE_VALUE = 'No ringtone' // eslint-disable-line

const setSavedRingTone = (value: RingtoneDbObj) => {
    if (value?.value === NO_RINGTONE_VALUE) {
        ringSoundAudio.src = ''
        return
    }
    const r: RingtoneData = ringtones.find(r => r.name === (value?.value as string)) || ringtones[0]
    ringSoundAudio.src = r.url
}

const onRingtoneChange = (modifications, object) => {
    if (object.key === 'ringtoneFilename') {
        if (modifications) object.value = modifications.value
        setSavedRingTone(object as RingtoneDbObj)
    }
}

db.mypdc.hook('creating').subscribe((primKey, object) => onRingtoneChange(null, object))
db.mypdc.hook('updating').subscribe((modifications, primKey, object) => onRingtoneChange(modifications, object))

/**
 *
 */
export default function AudioRinger (): JSX.Element {
    const callContext: any = useContext(PdcCallContext)
    const [ringing, setRinging] = useState(false)
    const [audioGranted, setAudioGranted] = useState(false)
    const hasIncomingCall = () => (callContext.incomingCalls.length > 0)
    const hasOutgoingCall = () => (callContext.currentCall?.callState === CallState.CONNECTING)
    const { getVolume } = useVolume()

    useEffect(() => {
        checkAudioAccess().then(() => ringBasedOnState())
    }, [callContext.currentCall, callContext.incomingCalls])

    useEffect(() => {
        checkAudioAccess()
        if (!ringtones) return
        db.mypdc.get({ key: 'ringtoneFilename' }).then((value) => {
            setSavedRingTone(value as RingtoneDbObj)
        })

        return () => {
            if (ringingInProgress != null) {
                ringingInProgress.then(audioElement => {
                    ringingInProgress = null
                    audioElement.pause()
                    audioElement.currentTime = 0
                }
                )
            }
        }
    }, [])

    const pauseRinging = (): Promise<void> => {
        if (ringingInProgress) {
            const t = ringingInProgress
            ringingInProgress = null
            return t.then((audioElement) => {
                audioElement.pause()
                audioElement.currentTime = 0
                setRinging(false)
                return Promise.resolve()
            })
        }
        return Promise.resolve()
    }

    const startRinging = async (soundAudio: HTMLAudioElement): Promise<HTMLAudioElement> => {
        if (ringingInProgress) {
            return ringingInProgress
        }
        setRinging(true)
        soundAudio.volume = getVolume() / 100
        soundAudio.loop = true
        ringingInProgress = soundAudio.play().then(() => {
            return soundAudio
        }).catch((err) => {
            console.log('Autoplay Call Ring Error', err.message)
            return soundAudio
        })
        return ringingInProgress
    }

    const checkAudioAccess = async (): Promise<boolean> => {
        if (audioGranted) return Promise.resolve(true)
        if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
            navigator.mediaDevices.enumerateDevices().then(devices => {
                devices.forEach((device) => {
                    if (device.kind === 'audioinput' && device.deviceId) {
                        setAudioGranted(true)
                        audioAllowed = true
                        return true
                    }
                })
            })
            return false
        }
        return false
    }

    const ringBasedOnState = () => {
        if (hasOutgoingCall()) {
            db.mypdc.get({ key: 'ringbackTone' }).then(value => {
                if (!value || value.value) {
                    startRinging(ringBackSoundAudio)
                }
            })
        } else if (hasIncomingCall()) {
            if (callContext.currentCall?.callState === CallState.ACTIVE) {
                db.mypdc.get({ key: 'callWaitingBeep' }).then(value => {
                    if (!value || value.value) {
                        startRinging(callWaitingSoundAudio)
                    }
                })
            } else {
                db.mypdc.get({ key: 'ringtoneFilename' }).then(value => {
                    if (value?.value !== NO_RINGTONE_VALUE) {
                        startRinging(ringSoundAudio)
                    }
                })
            }
        }

        if (!(hasOutgoingCall() || hasIncomingCall()) && ringing) {
            pauseRinging()
        }
    }

    return <React.Fragment/>
}
