<template>
    <div class="calls-reports">
        <PageTitle v-if="report.title">
          {{ report.title }}
        </PageTitle>
        <w-loader v-if="report.loading"/>
        <div v-else v-bind="$attrs" v-on="$listeners">
            <CallReportsFilters :call="report" data-test-id="call-reports-filter"/>
            <w-alert
              v-if="report.alert"
              :message="report.alert.message"
              :level="report.alert.level"
              :closable="report.enable_close_alert"
              @closed="report.alert = null"
              class="mb-6"
            />
            <div v-else-if="(report.items)" id="pdf-export">
                <slot />
            </div>
            <NothingFound
              v-else
              :alert="report.alert"
              :everythingDeleted="false"
              :filters_applied="false"
              :disable_add_new="true"
              data-test-id="call-reports-nothing-found"
            />
        </div>
    </div>
</template>

<script>
  import NothingFound from 'console/src/components/elements/NothingFound.vue';
  import PageTitle from 'console/src/components/elements/PageTitle.vue';
  import CallReportsFilters from 'console/src/components/elements/CallReportsFilters.vue';

  export default {
    components: {
      PageTitle,
      NothingFound,
      CallReportsFilters,
    },
    props: {
        report: {
            type: Object,
            required: true,
        },
    },
  };
</script>
