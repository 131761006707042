/* eslint-disable @typescript-eslint/ban-ts-comment */
import { SimpleUser } from 'sip.js/lib/platform/web'
import { CallFeatures } from './CallManager'
import { CallState } from './enums/CallState'
import { CallerInfo } from './interfaces/CallerInfo'
import { SipCallSession } from './SipCallSession'
import { CallType } from './enums/CallType'
import { UserAgent, RegistererRegisterOptions, UserAgentOptions, Web, RequestPendingError } from 'sip.js'
import CallSession from './CallSession'
// @ts-ignore
import { sendElectronNotification } from 'pdc-electron-utils'
// @ts-ignore
import { formatPhoneNumber } from 'phone-numbers'
// @ts-ignore
import PDCOpenConnection from 'pdc-open-connection'
// @ts-ignore
import PhoneComUser from 'phone-com-user'
// @ts-ignore
import api from './util/api_v2'

// @ts-ignore
import Api from 'api'

import { CallManagerEvents } from './enums/CallManagerEvents'
// import { register } from '../serviceWorker'
import { CallEventsDelegate } from './interfaces/CallEventsDelegate'
// import { SessionState } from 'sip.js'
// @ts-ignore
import { removeNotification, pushNotification, pushCallNotification } from 'notification-pusher'
import { isValidPhoneNumber, convertNumberToE164, formatToDigits } from 'phone-numbers'
declare global {
    interface Window {
        V5PHONECOM: any;
        safari: boolean;
        Rollbar: any;
        AudioContext: any;
        webkitAudioContext: any;
        micStream?: MediaStream | null;
        selectedMusic?: any;
        pdc?: any;
        cordova?: any;
        newrelic: any;

    }
}

class SipCallManager {
    public doNotDisturb = false
    public callMode: CallType = CallType.SIP
    public calls: Map<string, SipCallSession> = new Map<string, SipCallSession>()
    public activeCallId: string | null = null
    public topicCallbacks: any[] = []
    private mergedAudioPlayers : HTMLAudioElement[] = []
    private isWsSetup = false;
    // private userAgent: any = {};
    public myCallerInfo: CallerInfo = {
        phoneNumber: '',
        callerId: ''
    }

    public simpleUser?: SimpleUser
    public callEventsDelegate: CallEventsDelegate = {
        onCallReceived: (e: any) => {
            console.log(this)
            const { notification } = e
            const callId = notification.call.linked_uuid
            const call = this.calls[callId]
            if (!call) return
            call.callState = CallState.INCOMING
            call.callAnswered = false
            call.callInviteEvent = notification
            call.callStartTime = Date.now() / 1000 // this is a temp value until answered to handle order of multiple incoming calls
            this.emit(CallManagerEvents.CALL_RECEIVED, e)
            this.pushIncomingCallNotification(call)
        },
        onCallConnecting: (callId: string) => {
            const call = this.calls[callId]
            if (!call) return
            call.callState = CallState.CONNECTING
            this.activeCallId = callId
            this.emit(CallManagerEvents.CONNECTING, callId)
        },
        onCallAnswered: (callId: string) => {
            const call = this.calls[callId]
            if (!call) return
            call.callState = CallState.ACTIVE
            call.callAnswered = true
            call.callStartTime = Date.now() / 1000
            // session is private, is it time to remove simpleUser?
            this.setupRemoteMedia(call.session)
            // @ts-ignore
            this.simpleUser!.session = call.session
            this.emit(CallManagerEvents.CALL_ANSWERED, null)
            this.calls[callId].showCallStats()
            this.rbDebugLog('answered_call', { callId: callId })
            if (window.cordova && window.pdc) {
                // eslint-disable-next-line no-unused-expressions
                window.pdc.startCall()
            }
            removeNotification('connecting-call')
        },
        onCallHangup: (callId: string) => {
            const call = this.calls[callId]
            if (!call) return
            this.checkPossibleSystemErrorHangUp(call)
            if (!call.session) {
                this.simpleUser!.unregister().catch((error: RequestPendingError) => this.rbDebugLog('Error on unregister: ', error))
                delete this.calls[callId]
                this.emit(CallManagerEvents.CALL_HANGUP, null)
                return
            }
            // this.cleanupMedia()
            if (this.getCallsArray().length === 1) {
                // this is last call
                // console.log(this.simpleUser!.localMediaStream)
                this.simpleUser!.unregister().catch((error: RequestPendingError) => console.error('Error on unregister: ', error))
                // this.simpleUser!.disconnect()
                window.micStream = null
                this.cleanupMedia()
            }
            if (call.isMerged) {
                const participantNumber = call.participants[0].phoneNumber
                console.log('pdcCall: call is merged ', participantNumber, this.calls)
                const calls = this.getCallsArray()
                calls.forEach(session => {
                    // look for host session and remove this call from participants
                    console.log('pdcCall: look for host session ', session)
                    if (session.mergedCallIDs[participantNumber]) {
                        delete session.mergedCallIDs[participantNumber]
                        session.participants = session.participants.filter(p => p.phoneNumber !== participantNumber)
                        console.log('pdcCall: found host session, delete this session ', session)
                    }
                })
            } else if (this.activeCallId === callId && call.participants.length === 1) {
                this.activeCallId = null
            } else if (call.participants.length > 1) {
                // if its greater than one, and the hung up call was the active call - you need to reassign the active call using one of the participants. and flip isMerged status.
                const oldHost = call
                const oldHostNumber = oldHost.participants[0].phoneNumber
                const newHostNumber = oldHost.participants[1].phoneNumber
                const newHostID = oldHost.mergedCallIDs[newHostNumber]
                delete oldHost.mergedCallIDs[newHostNumber]
                const newHost = this.calls[newHostID]
                this.activeCallId = newHostID
                newHost.isMerged = false
                // remove self from merged and participants list, then assign the lists to the new host.
                newHost.mergedCallIDs = oldHost.mergedCallIDs
                delete newHost.mergedCallIDs[newHostNumber]
                newHost.participants = oldHost.participants.filter(p => p.phoneNumber !== oldHostNumber)
            }
            if (call.statsIntervalId) clearInterval(call.statsIntervalId)

            const sdh = call?.session?.sessionDescriptionHandler as any
            const pc = sdh.peerConnection as RTCPeerConnection
            pc.getSenders().forEach((t: RTCRtpSender) => t.track!.stop())

            call.session = undefined
            call.callState = null
            call.callAnswered = false

            delete this.calls[callId]
            this.emit(CallManagerEvents.CALL_HANGUP, null)
            if (this.activeCallId === null) this.switchToAnyActiveCall()
        },
        onCallCreated: (callId: string) => {
            const call = this.calls[callId]
            if (!call || call.callState === CallState.CONNECTING) return
            call.callState = CallState.INACTIVE
            this.emit(CallManagerEvents.CALL_CREATED, null)
        },
        onCallDTMFReceived: async (tone: any, duration: any) => {
            this.emit('callDTMFReceived', [tone, duration])
        },
        onCallHold: async (held: any) => {
            this.emit('callHold', held)
        },
        onCallStatUpdate: (callId: string, stat: any) => {
            this.emit(CallManagerEvents.CALL_STAT_UPDATE, { callId, stat })
        },
        onManagerStateUpdate: (callId: string) => {
            this.emit(CallManagerEvents.STATE_UPDATE, {})
        },
        // this will setup the hold music and allow the child session to fetch and set the parent manager's hold music in the event an error happens in the child. if its called from child it can use the return value so it does not need to access the parent
        onHoldMusicLink: async (): Promise<string | null> => {
            try {
                const selectedMusic = await Api.getAccountHoldMusic()

                console.log('selectedMusic:', selectedMusic)

                if (!selectedMusic) return null

                const voipRecordingId = selectedMusic.id
                const res = await Api.getMusicOnHoldLink(voipRecordingId)

                const link = res.download_link

                // let app default to backup song
                if (!link) return null

                this.holdMusicLink = link
                return link
            } catch (error) {
                this.rbDebugLog('hold music error', error)
                return null
            }
        }
    }

    public callSetupInProgress = false
    public holdMusicLink?: string

    public deniedAudioPermissions = false
    public noDeviceFound = false

    /**
     *
     */
        public getCallsArray = (): CallSession[] => {
            return Object.keys(this.calls).map(key => this.calls[key])
        }

    /**
     * @param topic
     * @param message
     */
    public emit = (topic: any, message: any) => {
        const callbacks = this.topicCallbacks[topic]
        if (callbacks) { callbacks.forEach((callback: (arg0: string) => ((message)=> any)) => callback(message)) } else { console.log(`CallMode - no callback defined for topic ${topic}`) }
    };

    /**
     * @param topic
     * @param callback
     */
    public on = (topic: any, callback: any) => {
        // if (!this.connected) this.connect()
        if (!this.topicCallbacks[topic]) { this.topicCallbacks[topic] = [callback] } else if (!this.topicCallbacks[topic].includes(callback)) {
            this.topicCallbacks[topic].push(callback)
        }
    };

    /**
     * @param message
     * @param extraData
     */
    private async rbDebugLog (message: string, extraData: any = {}, level = 'debug') {
        console.log(message, extraData)
        if (window.Rollbar && typeof window.Rollbar.debug === 'function') {
            if (level === 'debug') {
                window.Rollbar.debug(message, extraData)
            } else if (level === 'error') {
                window.Rollbar.error(message, extraData)
            }
        }
    }

    /**
     *
     */
    private async pushIncomingCallNotification (call) {
        const participant = call.participants[0]
        const callerId = participant.callerId || participant.phoneNumber
        const phoneNumber = participant.phoneNumber
        const answerAction = () => {
            this.answerById(call.callId)
        }
        const hangupAction = () => {
            this.hangupById(call.callId)
        }
        console.log('call id', call.callId)
        pushCallNotification(callerId, phoneNumber, answerAction, hangupAction, call.callId)
    }

    // todo add call events to new relic and add alerting for normal usages.

    /**
     * Checks if the call was recently started before it was hung up.
     * This is more of a guess, since most issues that cause and auto hang up are caused by some issue.
     * This is made to track the trnd of those errors.
     *
     * @param {SipCallSession} call - current call to check that is being hung up
     */
    private async checkPossibleSystemErrorHangUp (call: SipCallSession): Promise<void> {
        if (call?.callStartTime) console.log('check if system hangup', { diff: Date.now() - call.callStartTime, startTime: call.callStartTime, current: Date.now() })
        if (!call?.callStartTime || (call?.callStartTime && Date.now() - call.callStartTime < 1000)) {
            this.rbDebugLog('Calls System Error Hang Up', call)
            console.log('most likely a system hangup')

            // todo we should move this to a shared component after testing how it works here.
            if (window?.newrelic?.setCustomAttribute && typeof window?.newrelic.setCustomAttribute === 'function' && window?.V5PHONECOM?.voip_id && window?.V5PHONECOM.voip_phone_id) {
                window?.newrelic.setCustomAttribute('voip_id', window.V5PHONECOM.voip_id)
                window?.newrelic.setCustomAttribute('voip_phone_id', window.V5PHONECOM.voip_phone_id)
            }
            if (window?.newrelic && typeof window?.newrelic?.noticeError === 'function') {
                window.newrelic.noticeError(new Error('Calls System Error Hang Up'), call)
            }
        }
    }

    /**
     *
     */
    private microphoneAccessNotGivenError (): void {
        console.log('caught media error')
        this.deniedAudioPermissions = true
        // wipe calls obj, cant make calls. update ui
        this.calls = {}
        this.emit(CallManagerEvents.STATE_UPDATE, {})
        sendElectronNotification('askForMediaAccess', true)
    }

    public connect = async (): Promise<null> => {
        try {
            await this.getMyNumberInfo()
        } catch (error) {
            this.rbDebugLog('getMyNumberError Wraper', error)
        }

        if (this.simpleUser) {
            return new Promise((resolve, reject) => {
                resolve(null)
            })
        }
        await Api.registerSipDevice()

        let currentExt = null
        if (window!.V5PHONECOM!.user_id && window!.V5PHONECOM!.user_default_extension_id) {
            currentExt = parseInt(window!.V5PHONECOM!.user_default_extension_id)
        } else {
            currentExt = parseInt(PhoneComUser.getExtensionId())
        }
        console.log(currentExt)
        const extension = await api.getExtension(currentExt)
        if (
            !extension ||
            !extension.device_membership ||
            !extension.device_membership.device ||
            !extension.device_membership.device.sip_authentication
        ) {
            console.log('no device')
            this.noDeviceFound = true
            return null
        }

        console.log('connect', extension)
        const auth = extension.device_membership.device.sip_authentication
        const username = auth.username + 'x0'
        const host = 'sip.phone.com'
        const port = '9998'
        const password = auth.password
        const ua = {
            traceSip: true,
            // uri: username + '@' + host,
            uri: username + '@' + 'phone.com',
            wsServers: ['wss://' + host + ':' + port],
            authorizationUser: username,
            password: password,
            realm: 'phone.com',
            userAgentString: 'CommunicatorWeb/' + 'v1'
        }
        const uri = 'sip:' + ua.uri

        // // Create media stream factory
        const myMediaStreamFactory: Web.MediaStreamFactory = (
            // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-undef
            constraints: MediaStreamConstraints,
            sessionDescriptionHandler: Web.SessionDescriptionHandler
        ): Promise<MediaStream> => {
            // const mediaStream = new MediaStream(); // my custom media stream acquisition
            sendElectronNotification('askForMediaAccess', true)
            // addElectronEventListener('mediaAccessResponse', () => {
            //     this.microphoneAccessNotGivenError()
            // })

            return navigator.mediaDevices.getUserMedia({ audio: true })
                .then((stream: MediaStream) => {
                    this.deniedAudioPermissions = false
                    window.micStream = stream
                    return Promise.resolve(stream)
                })
                .catch(err => {
                    this.rbDebugLog('catching mic permission error', err)
                    this.microphoneAccessNotGivenError()
                    return Promise.reject(err)
                })
        }

        // Create session description handler factory
        const mySessionDescriptionHandlerFactory: Web.SessionDescriptionHandlerFactory =
            Web.defaultSessionDescriptionHandlerFactory(myMediaStreamFactory)
        // Create user agent
        // const myUserAgent = new UserAgent({
        //     sessionDescriptionHandlerFactory: mySessionDescriptionHandlerFactory
        // });

        const userAgentOptions: UserAgentOptions = {
            uri: UserAgent.makeURI(uri),
            authorizationPassword: auth.password,
            authorizationUsername: auth.username + 'x0',
            logBuiltinEnabled: true,
            userAgentString: 'CommunicatorWeb/' + 'v1',
            displayName: extension.name,
            transportOptions: {
                server: ua.wsServers[0]
            },
            // dtmfType: 'rtp', // I do not think this is used since we do it manually
            autoStop: true,
            logLevel: 'debug',
            logConfiguration: true,
            builtinEnabled: true,
            // delegate: {
            //     onInvite: this.onInvite
            // }
            sessionDescriptionHandlerFactory: mySessionDescriptionHandlerFactory,
            sessionDescriptionHandlerFactoryOptions: {
                iceGatheringTimeout: 1
            }
        }

        // delegates https://github.com/onsip/SIP.js/blob/master/docs/simple-user/sip.js.simpleuserdelegate.md
        const options = {
            // delegate: this.callEventsDelegate,
            media: {
                local: {},
                remote: {
                    audio: document.getElementById('remoteAudio') as HTMLAudioElement
                }
            },
            connectionRecoveryMinInterval: 10,
            aor: 'sip:' + ua.uri,
            userAgentOptions: userAgentOptions
        }

        PDCOpenConnection.on('call_received', this.onCallNotification)
        const simpleUser = new SimpleUser(ua.wsServers[0], options)
        this.simpleUser = simpleUser
        simpleUser
            .connect()
            .then(() => {
                return simpleUser
            }).then(() => {
                this.isWsSetup = true
                window.setTimeout(() => {
                    if (window.pdc) { // added lag time because there seems to be a weird race condition I can not find
                        window.pdc.callsReady()
                    }
                }, 1000)
            })
            .catch((err: any) => this.rbDebugLog('connecting issue', err))

        await this.callEventsDelegate.onHoldMusicLink()

        return null
    }

    public changeMicStream = (micStream: MediaStream) => {
        if (!micStream) {
            window.Rollbar.error('attempted to change to invalid or non existing mic stream', { micStream })
            console.error('attempted to change to invalid or non existing mic stream')
            return
        }
        const activeSession = this.calls[this.activeCallId!] as any
        if (!activeSession?.session) return
        const sdh: any = activeSession.session.sessionDescriptionHandler
        const pc: RTCPeerConnection = sdh.peerConnection
        const senders = pc.getSenders()
        const newMicTrack = micStream.getAudioTracks()[0]
        senders[0].replaceTrack(newMicTrack).then(() => {
            window.micStream = micStream
            activeSession.muteLocal(activeSession.isMutedLocal)
        })
    }

    protected onCallNotification = async (extensionId: number, notification: any): Promise<void> => {
        console.log('call notification', extensionId, notification)

        if (
            this.doNotDisturb ||
            notification.call.status === 'canceled' ||
            notification.call.status === 'missed' ||
            (notification.call.status === 'answered' &&
                (
                    (this.calls[notification.call.linked_uuid] && this.calls[notification.call.linked_uuid].callState === CallState.INCOMING) ||
                    (this.calls[notification.call.from] && this.calls[notification.call.from].callState === CallState.INCOMING)
                )
            )
        ) {
            this.hangupById(notification.call.linked_uuid)
            removeNotification(notification.call.linked_uuid).then(() => {
                if (notification.call.status === 'answered') {
                    window.setTimeout(() => {
                        pushNotification('Call Answered on Another Device', notification?.call?.caller_contact_name || notification?.call?.from || '', null, null, null, null, null, notification?.call?.linked_uuid)
                    }, 1500) // delay for mac not showing pop up messages close to each other in time
                    window.setTimeout(() => {
                        removeNotification(notification?.call?.linked_uuid)
                    }, 30000)
                } else {
                    pushNotification(notification.call.alert_str, notification.call.caller_contact_name || notification.call.from)
                }
            })
        } else if (!notification.call.status && this.getCallsArray().length < 4) {
            const theirCallInfo: CallerInfo = {
                phoneNumber: notification.call.from,
                callerId: notification.call.caller_contact_name
            }
            console.log('call notification', theirCallInfo)

            let myCallInfo: CallerInfo
            if (notification.call.called_number) {
                myCallInfo = {
                    callerId: notification.call.called_number_name,
                    phoneNumber: formatPhoneNumber(notification.call.called_number)
                }
            } else {
                myCallInfo = await this.getMyNumberInfo()
            }

            const session = new SipCallSession(
                [theirCallInfo],
                myCallInfo,
                notification.call.linked_uuid,
                CallState.INCOMING,
                this.callEventsDelegate
            )

            if (!this.calls[session.callId]) this.calls[session.callId] = session

            const e = { notification }
            this.calls[session.callId].callEventsDelegate.onCallCreated(e)
            this.calls[session.callId].callEventsDelegate.onCallReceived(e)

            // after 15s clear the call if it has not been answered
            // setTimeout(() => {
            //     if(this.calls[session.callId].callState === CallState.INCOMING) {
            //         delete this.calls[session.callId]
            //     }
            // }, 15000)
            console.log('call notification', this.calls[session.callId])
        }
    }

    /**
     *
     */
    private canPlaceCalls (): boolean {
        if (this.isWsSetup && this.simpleUser) {
            return true
        } else {
            this.rbDebugLog('not ready for calls, blocking call until resovled', { isWsSetup: this.isWsSetup, simpleUser: this.simpleUser })
            return false
        }
    }

    // place a call
    public call = async (callee: string) => {
        if (!this.canPlaceCalls()) return
        callee = formatToDigits(callee)
        if (isValidPhoneNumber(callee)) {
            callee = convertNumberToE164(callee)
        }
        const specialChars = callee.match(/[#]/g)
        if (specialChars && specialChars.length > 0) {
            return
        }
        if (
            this.calls[callee] ||
            this.getCallsArray().length > 4 ||
            this.getCallsArray().filter((c) => c.callState === CallState.INACTIVE).length > 0
        ) {
            return
        }
        // lock call ui
        this.callSetupInProgress = true
        this.emit(CallManagerEvents.STATE_UPDATE, null)
        await this.initPreCallCheck(callee)

        // let myNumberInfo = await this.getMyNumberInfo()
        // set teh callerId to passed in value, wait for response, then continue process only if there is a success.

        if (!this.myCallerInfo) return

        const theirNumberInfo: CallerInfo = {
            callerId: callee,
            phoneNumber: callee
        }
        // let callee just be ID youre searching for from session hashmap.
        const sessionId: string = callee
        const session = new SipCallSession(
            [theirNumberInfo],
            this.myCallerInfo,
            sessionId,
            CallState.CONNECTING,
            this.callEventsDelegate
        )
        this.calls[sessionId] = session

        this.registerAndPrepareInvite(sessionId)
        this.callSetupInProgress = false
        this.emit(CallManagerEvents.STATE_UPDATE, {})
        this.rbDebugLog('made_call', { callId: callee, callerId: this.myCallerInfo })
    }

    /**
     * @param callId
     */
    public async switchCall (callId: string): Promise<void> {
        if (!callId || !this.calls[callId]) {
            return
        }
        if (callId === this.activeCallId) return
        // hold active call - function already accounts for multi call scenarios
        await this.hold()

        // wipe old incoming audio
        this.cleanupMedia()

        // switch to new incoming audio
        this.setupRemoteMedia(this.calls[callId].session)

        // unhold current call
        await this.unhold(callId)

        // if the length is greater than one, you need to remerge the audio tracks bc of how hold tracks are handled
        const call = this.calls[callId]
        const sessions = [call]
        if (call.participants.length > 1) {
            for (const p of call.participants) {
                const otherId = call.mergedCallIDs[p.phoneNumber]
                if (otherId !== callId && this.calls[otherId]) sessions.push(this.calls[otherId])
            }
        }
        // this.calls[activeSession.callId] = activeSession

        await this.configureRemoteAudioTracks(sessions)
        // switch the current call
        this.activeCallId = callId
        // @ts-ignore
        this.simpleUser!.session = this.calls[callId].session
        // render update
        this.emit(CallManagerEvents.SWITCH_CALL, { callId })
        this.rbDebugLog('switch_call', { callId: callId })
    }

    /**
     *
     */
    private async configureRemoteAudioTracks (sessions: SipCallSession[]) {
        await this.sleep(1000) // needed to allow merging of audio stream - todo figure out a way to do this without blocking
        const receivedTracks = this.getAllReceiverTracks(sessions)
        // use the Web Audio API to mix the received tracks
        // eslint-disable-next-line new-cap
        const audioContext = (new window.AudioContext()) || (new window.webkitAudioContext())
        const allReceivedMediaStreams: [MediaStream] | [] = []
        await this.mixAllAudioForMerge(sessions, audioContext, receivedTracks, allReceivedMediaStreams)
        await this.initStream(allReceivedMediaStreams)
    }

    // cann only hold active call
    /**
     * @param id
     */
    public async hold (id: string | null = this.activeCallId): Promise<any> {
        if (!id || !this.calls[id] || this.calls[id].isOnHold) {
            return
        }
        // skipping this for now
        // eslint-disable-next-line no-constant-condition
        if (false && this.calls && Object.keys(this.calls[id].participants).length === 1) {
            await this.simpleUser?.hold()
            this.calls[id].isOnHold = true
        } else {
            await this.calls[id].hold(this.holdMusicLink)
            // if youre on a merged call there is additional calls to hold
            if (id && !this.calls[id].isOnHold && this.calls[id].participants.length > 1) {
                const call = this.calls[id]
                for (const participant of call.participants) {
                    const callId = call.mergedCallIDs[participant.phoneNumber]
                    if (callId) {
                        const mergedCall = this.calls[callId]
                        if (mergedCall) await mergedCall.hold(this.holdMusicLink)
                    }
                }
            }
        }
        this.rbDebugLog('hold_call', { callId: id })
    }

    // can unhold inactive call to prepare for a merge.
    /**
     * @param id
     */
    public async unhold (id: string | null = this.activeCallId): Promise<any> {
        if (!id || !this.calls[id] || !this.calls[id].isOnHold) {
            return
        }
        // skiping this for now
        // eslint-disable-next-line no-constant-condition
        if (false && id === this.activeCallId && this.simpleUser?.isHeld()) {
            await this.simpleUser?.unhold()
            this.calls[id].isOnHold = false
        } else {
            await this.calls[id].unhold()

            if (this.calls[id].participants.length > 1) {
                const call = this.calls[id]
                for (const participant of this.calls[id].participants) {
                    const callId = call.mergedCallIDs[participant.phoneNumber]
                    if (callId) {
                        await this.calls[callId].unhold()
                    }
                }
            }
        }
        this.rbDebugLog('unhold_call', { callId: id })
    }

    /**
     * @param tone
     */
    public async sendDTMF (tone: string): Promise<void> {
        // needed to universal support as inband is not really support by everyone -- even us
        // await this.simpleUser?.sendDTMF(tone).catch((e) => { console.error('dtmf failed', e) }) // send as info (out band)
        const activeCall: SipCallSession = this.calls[this.activeCallId]
        const requests: Promise<void>[] = []
        if (activeCall) {
            if (activeCall.mergedCallIDs) {
                activeCall.participants.forEach((p) => {
                    const callId = activeCall.mergedCallIDs[p.phoneNumber]
                    if (callId && (callId in this.calls) && callId !== activeCall.callId) {
                        const call: SipCallSession = this.calls[callId]
                        console.log(`sending DTFM Group ${tone} for ${p.phoneNumber}`)
                        requests.push(call.sendDTMF(tone))
                    }
                })
            }
            // send for active call
            requests.push(activeCall.sendDTMF(tone))
            console.log(`sending DTFM single ${tone} for`, activeCall)
        }
        await Promise.all(requests)
        return Promise.resolve()
        // const success: boolean = this.simpleUser?.session?.sessionDescriptionHandler.sendDtmf(tone) // send as rfc2833 (in band)
        // if (!success) await this.simpleUser?.sendDTMF(tone).catch((e) => { console.error('dtmf failed', e) }) // send as info (out band)
    }

    /**
     * @param callIdToMerge
     */
    public async mergeCall (callIdToMerge: string): Promise<void> {
        // unhold background call
        await this.unhold()
        await this.unhold(callIdToMerge)

        // take all received tracks from the sessions you want to merge
        const activeSession = this.calls[this.activeCallId]
        const sessiontoMerge = this.calls[callIdToMerge]
        // make sure nothing is muted
        activeSession.muteLocal(false)
        activeSession.muteRemote(false)

        sessiontoMerge.muteLocal(false)
        sessiontoMerge.muteRemote(false)

        const sessions: SipCallSession[] = [activeSession, sessiontoMerge]
        // Update merged IDs list
        const participants = sessiontoMerge.participants
        const par = participants[0]
        activeSession.mergedCallIDs[par.phoneNumber] = callIdToMerge
        activeSession.participants.push(par)
        for (let i = 1; i < participants.length; i++) {
            const p = participants[i]
            activeSession.participants.push(p)
            const otherId = sessiontoMerge.mergedCallIDs[p.phoneNumber]
            if (otherId) {
                sessions.push(this.calls[otherId])
                activeSession.mergedCallIDs[p.phoneNumber] = otherId
            }
        }
        sessiontoMerge.isMerged = true
        sessiontoMerge.mergedCallIDs = {}
        sessiontoMerge.participants = [par]
        await this.configureRemoteAudioTracks(sessions) // does the audio mixing

        this.emit(CallManagerEvents.MERGE_CALL, {})
        this.rbDebugLog('merge_call', { callIdToMerge: callIdToMerge })
    }

    /**
     * used for hack
     */
    sleep (ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    // gather all receivers for merge - all incoming audio tracks
    /**
     * @param callSessions
     */
    private getAllReceiverTracks (callSessions: SipCallSession[]): MediaStreamTrack[] {
        const receivedTracks: MediaStreamTrack[] = []

        for (const session of callSessions) {
            if (session && session.session) {
                const sdh: any = session.session.sessionDescriptionHandler
                const pc: RTCPeerConnection = sdh.peerConnection
                pc.getReceivers().forEach((receiver: RTCRtpReceiver) => {
                    receivedTracks.push(receiver.track)
                })
            }
        }
        return receivedTracks
    }

    /**
     * @param sessions
     * @param context
     * @param receivedTracks
     * @param allReceivedMediaStreams
     */
    private async mixAllAudioForMerge (
        sessions: SipCallSession[],
        context: AudioContext,
        receivedTracks: MediaStreamTrack[],
        allReceivedMediaStreams: MediaStream[]
    ): Promise<void> {
        for (const session of sessions.reverse()) {
            if (session) {
                // create an incoming audio output to <audio/> using all callers incoming audio
                const mixedOutput = context.createMediaStreamDestination()
                const sdh: any = session?.session?.sessionDescriptionHandler
                const pc: RTCPeerConnection = sdh?.peerConnection
                const receivers = pc?.getReceivers()
                const senders = pc?.getSenders()
                const mediaStream = new MediaStream()

                if (!receivers || !senders) {
                    console.warn('call is in a bad state, no mixing audio for merge', { receivers, senders })
                    return // call is in a bad state
                }
                // connect all incoming audio

                receivers.forEach((receiver: any) => {
                    receivedTracks.forEach((track) => {
                        mediaStream.addTrack(receiver.track)
                        if (receiver.track.id !== track.id) {
                            const sourceStream = context.createMediaStreamSource(new MediaStream([track]))
                            sourceStream.connect(mixedOutput)
                        }
                    })
                })

                // mixing your voice with all the received audio
                senders.forEach((sender: any) => {
                    const sourceStream = context.createMediaStreamSource(new MediaStream([sender.track]))
                    // mediaStream.addTrack(sender.track)

                    sourceStream.connect(mixedOutput)
                })

                const tracks = mixedOutput.stream.getTracks()
                // const allReceivedMediaStreamsTracks = allReceivedMediaStreams.getTracks()
                // debugger
                await senders[0]?.replaceTrack(tracks[0])
                allReceivedMediaStreams?.push(mediaStream)
            }
        }
    }

    /**
     * @param allReceivedMediaStreams
     * @param callIdToMerge
     */
    private async initStream (allReceivedMediaStreams: [MediaStream] | []): Promise<void> {
        allReceivedMediaStreams.forEach((mediaStream: MediaStream) => {
            const audioPlayer: HTMLAudioElement = new Audio()
            audioPlayer.id = `remoteAudio-${mediaStream.id}`
            audioPlayer.srcObject = mediaStream
            audioPlayer.play() // the above
            console.log('playing', mediaStream)
            this.mergedAudioPlayers.push(audioPlayer)
        })
    }

    public muteCurrentLocal = (isMuted: boolean): void => {
        if (this.activeCallId) {
            const call = this.calls[this.activeCallId]
            call.muteLocal(isMuted)
            call.participants.forEach(p => {
                const mergedCallId = call.mergedCallIDs[p.phoneNumber]
                const mergedCall = this.calls[mergedCallId]
                if (mergedCall) mergedCall.muteLocal(isMuted)
            })
        }
    }

    public muteCurrentRemote = (isMuted: boolean): void => {
        if (this.activeCallId) {
            const call = this.calls[this.activeCallId]
            call.muteRemote(isMuted)
            call.participants.forEach(p => {
                const mergedCallId = call.mergedCallIDs[p.phoneNumber]
                const mergedCall = this.calls[mergedCallId]
                if (mergedCall) mergedCall.muteRemote(isMuted)
            })
        }
    }

    /**
     * @param id
     */
    public answerById (id: string): void {
        if (!this.canPlaceCalls()) return

        this.initPreCallCheck(id).then(() => {
            this.callEventsDelegate.onCallConnecting(id)
            this.registerAndPrepareInvite(id)
            this.rbDebugLog('answered_incoming_call', { callId: id })
            this.removeNotification(`${id}`)
        })
    }

    /**
     * @param id
     */
    private async removeNotification (id: string) {
        try { removeNotification(id) } catch (e) {
            this.rbDebugLog('removeNotification error', e)
        }
    }

    /**
     * @param id
     * @param endAll
     */
    public async hangupById (id: string, endAll = false): Promise<any> {
        console.log('hanging up', id)
        this.removeNotification(`${id}`)

        const call = this.calls[id]
        if (!call) return
        if (call.callState === CallState.INCOMING) {
            this.callEventsDelegate.onCallHangup(id)
            return
        }
        // if call was merged, send bye to others
        if (endAll) {
            const participants = call.participants
            participants.forEach(async (p) => {
                const pId = call.mergedCallIDs[p.phoneNumber]
                if (this.calls[pId]) {
                    await this.calls[pId].hangup()
                }
            })
        }
        await call.hangup()
        if (call.callState === CallState.INACTIVE) {
            if (call.callId === this.activeCallId) {
                this.activeCallId = null
            }
        }
    }

    // Switches to any active call in the call list with call.callState === CallState.ACTIVE
    /**
     *
     */
    public async switchToAnyActiveCall (): Promise<void> {
        const activeCall = this.calls[this.activeCallId]
        if (!activeCall || activeCall.callState !== CallState.ACTIVE) {
            for (const callId in this.calls) {
                const call = this.calls[callId]
                if (call.callState === CallState.ACTIVE && !call.isMerged) {
                    await this.switchCall(callId)
                    await this.hold() // lets make sure that the call that is switched to is on hold - to avoid awkward moments :)
                }
            }
        }
    }

    /**
     * @param callId
     */
    private async initPreCallCheck (callId: string) {
        // if there is an active call, put that session on hold, and then switch
        if (this.activeCallId) {
            await this.hold()
            // testing if we can remove the below remote
            // this.muteCurrentRemote(true)
            // // wipe old incoming audio
            // this.cleanupMedia()
            this.emit(CallManagerEvents.SWITCH_CALL, { callId: callId })
        }
    }

    /**
     * @param sessionId
     */
    private registerAndPrepareInvite (sessionId: string) {
        this.activeCallId = sessionId
        const registererOpts: RegistererRegisterOptions = {
            requestDelegate: {
                onAccept: () => {
                    console.log('register options worked')
                    try {
                        // @ts-ignore
                        this.calls[sessionId].prepareInvite(this.simpleUser!.userAgent)
                            .catch(err => {
                                console.log(err)
                                console.log(typeof err)
                                this.rbDebugLog('failed_to_prepare_invite', { callId: sessionId })
                            })
                    } catch (error) {
                        this.rbDebugLog('failed_to_prepare_invite', { callId: sessionId }, 'error')
                    }
                }
            }
        }
        this.simpleUser.register(undefined, registererOpts).catch((err) => this.rbDebugLog('register_error', { error: err }))
    }

    /**
     *
     */
    public async getMyNumberInfo (): Promise<any> {
        let activeCallerId = null
        try {
            const response = await api.fetchActiveCallerId()
            if (response.caller_id) activeCallerId = response.caller_id
        } catch (error) {
            this.rbDebugLog('getMyNumberInfo error', error)

            return {
                phoneNumber: '',
                callerId: ''
            }
        }

        const selected = activeCallerId
        const number = formatPhoneNumber(selected)
        const myNumberInfo: CallerInfo = {
            phoneNumber: number,
            callerId: number
        }
        if (!this.activeCallId) {
            this.myCallerInfo = myNumberInfo
            this.callEventsDelegate.onManagerStateUpdate(null)
        }
        return myNumberInfo
    }

    /**
     * @param callerId
     */
    public async setMyCallerInfo (callerId: string) {
        let activeCallerId = null
        let response = null

        if (!callerId) return

        if (window.V5PHONECOM && window.V5PHONECOM.user_id && window.V5PHONECOM.user_default_extension_id) {
            response = await api.setActiveCallerId(callerId, window.V5PHONECOM.user_default_extension_id)
        } else {
            response = await api.setActiveCallerId(callerId)
        }

        console.log('setMyCallerInfo', callerId, response)
        if (response && response.message && response.message === 'success') {
            activeCallerId = callerId
            const selected = activeCallerId
            const number = formatPhoneNumber(selected)
            this.myCallerInfo = {
                phoneNumber: number,
                callerId: number
            }
        }
    }

    // setActiveNumber(phoneNumber: string) {
    //     this.activePhoneNumber = phoneNumber
    // }

    /**
     *
     */
    public cleanupMedia (): void {
        const mediaElements = document.querySelectorAll('[id^="remoteAudio"]')!
        mediaElements.forEach((mediaElement: HTMLAudioElement) => {
            if (!mediaElement?.paused) {
                console.debug('cleaned up media', mediaElement)
                mediaElement.pause()
                mediaElement.srcObject = null
            }
        }
        )
        this.mergedAudioPlayers?.forEach(audio => {
            console.debug('stopped aduio player', audio)

            audio?.pause()
            audio = null
        })
    }

    /**
     * @param session
     */
    public setupRemoteMedia (session: any): void {
        const mediaElement: HTMLAudioElement = document.getElementById('remoteAudio')! as HTMLAudioElement
        const remoteStream = new MediaStream()
        session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver: any) => {
            if (receiver.track) {
                remoteStream.addTrack(receiver.track)
            }
        })
        mediaElement.srcObject = remoteStream
        mediaElement.play().catch(e => this.rbDebugLog('audio stream error: ', e))
    }

    /**
     *
     */
    public test (): void {
        throw new Error('Method not implemented.')
    }

    /**
     * @param id
     */
    public muteById (id: string): void {
        throw new Error('Method not implemented.')
    }

    /**
     * @param session
     */
    public addSession (session: CallSession): void {
        throw new Error('Method not implemented.')
    }

    /**
     * @param session
     */
    public removeSession (session: CallSession): void {
        throw new Error('Method not implemented.')
    }

    /**
     * @param callId
     */
    public setActiveCall (callId: string): void {
        throw new Error('Method not implemented.')
    }

    public supportsById = (callId: string): Map<string, boolean> => {
        console.log(callId)
        return this.calls[callId].supports()
    }

    public isCallingEnabled = (): boolean => {
        return !process.env.REACT_APP_IS_CALLING_DISABLED
    }

    public isOutboundCallingEnabled = (): boolean => {
        return this.isCallingEnabled() && !window!.safari
    }

    public supports = (isExtensionVirtual: boolean): Map<CallFeatures, boolean> => {
        const ret = new Map<CallFeatures, boolean>()
        ret.set(CallFeatures.outboundCalling, isExtensionVirtual ? false : this.isOutboundCallingEnabled())
        return ret
    }

    /**
     * @param target
     * @param attended
     */
    public async transfer (target: string, attended = false): Promise<void> {
        if (this.activeCallId) {
            console.log('transfer', target, attended)
            if (attended) {
                await this.attendedTransfer(this.activeCallId, target)
            } else {
                const call = this.calls[this.activeCallId]
                if (call.session) {
                    await call.transfer(target)
                }
            }
        }
    }

    /**
     *
     */
    public getMergedSessions (call: CallSession): CallSession[] {
        const merged = [call]
        const ids: Record<string, string> = call.mergedCallIDs
        for (const [, value] of Object.entries(ids)) {
            if (this.calls[value]) merged.push(this.calls[value])
        }
        return merged
    }

    /**
     * @param target
     * @param attended
     */
    private async attendedTransfer (activeCallTarget: string, targetCallId: string): Promise<void> {
        console.log('attendedTransfer', activeCallTarget, targetCallId)
        console.log('attendedTransfer', targetCallId)
        const activeCallSession = this.calls[activeCallTarget]?.session

        console.log(this.calls, this.calls[targetCallId])

        const targetCallSession = this.calls[targetCallId]?.session

        if (activeCallSession && targetCallSession) {
            await activeCallSession.refer(targetCallSession)
        } else {
            console.error('activeCallSession or targetCallSession is null', { activeCallSession, targetCallSession })
        }
    }
}

// @ts-ignore
export default SipCallManager
