import React, { createContext, useState } from 'react'
import PhoneComUser from 'phone-com-user'
import ajax from 'ajax'

/**
 *
 */
interface IEnrollmentEvent {
    eventName: string
    eventDescription: string
    description: string
    createdAt: string
}

/**
 *
 */
interface IEnrollmentEventCollection {
    id: string
    type: 'brand' | 'campaign' | 'invalid'
    events: IEnrollmentEvent[]
    hasMore: boolean
    // list: () => Promise<void>
    // onUpdate: (id: string, events: IEnrollmentEvent) => Promise<void>
}

const EnrollmentEventsContext = createContext({
    list: async (entityType: 'brand' | 'campaign', id: string) => Promise.resolve({
        id: id,
        type: entityType,
        events: [],
        hasMore: false
    } as IEnrollmentEventCollection),
    events: new Map<string, IEnrollmentEventCollection>()
})

/**
 *
 */
const EnrollmentEventsContextProvider = (props: { children }) => {
    const [enrollmentEvents, setEnrollmentEvents] = useState<Map<string, IEnrollmentEventCollection>>(new Map())
    const fetchEvents = async (requestUrl, request): Promise<IEnrollmentEvent[]> => {
        return await ajax.post(requestUrl, request).then((response) => {
            const events = [] as IEnrollmentEvent[]
            response.data?.events?.forEach((event) => {
                events.push({
                    eventName: event.event_name,
                    eventDescription: event.event_description,
                    description: event.description,
                    createdAt: event.created_at
                })
            })
            return events
        })
    }
    const list = async (entityType: 'brand' | 'campaign', id: string): Promise<IEnrollmentEventCollection> => {
        console.log('list events api state', enrollmentEvents)
        let eventCollection: IEnrollmentEventCollection = {
            id,
            type: entityType,
            events: [],
            hasMore: true
        }
        if (enrollmentEvents.has(id)) {
            eventCollection = enrollmentEvents.get(id) as IEnrollmentEventCollection
        }
        if (eventCollection.type === 'brand') {
            const request = {
                // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
                csp_brand_id: id,
                limit: 50,
                offset: eventCollection.events.length
            }
            const requestUrl = PhoneComUser.getv5ApiRoot() + '/messages/10dlc/list-brand-events'
            const events = await fetchEvents(requestUrl, request)
            eventCollection.events = eventCollection.events.concat(events)
            eventCollection.hasMore = events.length === request.limit
        } else if (eventCollection.type === 'campaign') {
            const request = {
                // eslint-disable-next-line @typescript-eslint/naming-convention, camelcase
                csp_campaign_id: id,
                limit: 50,
                offset: eventCollection.events.length
            }
            const requestUrl = PhoneComUser.getv5ApiRoot() + '/messages/10dlc/list-campaign-events'
            const events = await fetchEvents(requestUrl, request)
            eventCollection.events = eventCollection.events.concat(events)
            eventCollection.hasMore = events.length === request.limit
        }
        enrollmentEvents.set(id, eventCollection)
        setEnrollmentEvents(enrollmentEvents)
        console.log('list events api', eventCollection)
        return eventCollection
    }
    return (
        <EnrollmentEventsContext.Provider value={{
            list,
            events: enrollmentEvents
        }}>
            {props.children}
        </EnrollmentEventsContext.Provider>
    )
}

/**
 *
 */
export { IEnrollmentEvent, IEnrollmentEventCollection, EnrollmentEventsContext, EnrollmentEventsContextProvider }
