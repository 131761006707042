import { Styles } from '@material-ui/core/styles/withStyles'
type StylesType = Styles<any, Record<string, unknown>>

/**
 * @param {object} theme
 */
const styles: StylesType = theme => ({
    phonebar: {
        display: 'fixed',
        minHeight: '40px',
        width: '100%',
        backgroundColor: 'black',
        zIndex: '4444'
    },
    phonebarWrapper: {
        backgroundColor: '#F4F5F6'
    },
    sessionsBar: {
        position: 'relative',
        top: '10px',
        left: '12px',
        height: '18px',
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flexStart'
    },
    sessionsCount: {
        height: '18px',
        width: '50px',
        margin: '0px 7px'
    },
    sessionsCountIcon: {
        height: '18px',
        width: '28px',
        position: 'relative'
    },
    sessionsCounters: {
        height: '18px',
        margin: '0px 7px'
    },
    sessionStateIcon: {
        height: '11.625px',
        width: '12px',
        left: '3px',
        top: '3.1875px'
    },
    sessionControls: {
        minWidth: '0px',
        height: '40px',
        padding: '0.1px'
    },
    sessionControlButton: {
        width: '26px',
        height: '26px',
        paddingLeft: '5px',
        justifyContent: 'right'
    },
    dropdownIcon: {
        width: '24px',
        height: '24px',
        marginRight: '1px',
        '&.open': {
            transform: 'scale(1, -1)'
        }
    },
    counterIcon: {
        width: '18px',
        height: '18px',
        margin: '0px 5px'
    }
})

export default styles
