<template>
  <div class="d-flex">
    <w-text-field
      type="email"
      v-model="email"
      :rules="validation_rules"
      placeholder="info@example.org"
      @focus="$emit('focus', $event)"
      not-clearable
      hide-details='auto'
      data-test-id="email-input"
    />
    <RemoveFromListButton v-if="closable" @click="$emit('closed')" icon class="my-auto ml-3" />
  </div>
</template>

<script>
  import RemoveFromListButton from './RemoveFromListButton.vue';

  const EMAIL_PATTERN = 'p+{1}.{0}@pdc-partners.xyz';

  export default {
    props: ['value', 'closable'],
    components: {
      RemoveFromListButton,
    },
    data() {
      return {
        email: this.$props.value ? this.decode_email(this.$props.value) : '',
        validation_rules: [this.$validation.email()],
      };
    },
  created() {
      if (Array.isArray(this.$attrs.rules)) {
        this.$data.validation_rules = this.$data.validation_rules.concat(this.$attrs.rules);
      }
    },
    watch: {
      email(value) {
        // this.$emit('input', value ? this.encode_email(value) : '');
        this.$emit('input', value);
      },
    },
    methods: {
      decode_email(value) {
        const code = ['avo', 'avo_master'].includes(this.$branding.data.code) ? 'avo' : this.$branding.data.code;
        const r = value.match(
          `^${EMAIL_PATTERN.replace('+', '\\+')
            .replace('{0}', '([^@]+)')
            .replace('{1}', code)}$`,
        );
        if (r) {
          try {
            value = atob(r[1]);
          } catch (e) {
            value = '';
          }
        }
        return value;
      },
      // encode_email(value) {
      //   return EMAIL_PATTERN.replace('{0}', btoa(value)).replace(
      //     '{1}',
      //     this.$branding.data.code,
      //   );
      // },
    },
  };
</script>
