<template>
  <draggable v-model="rule.actions" handle=".dragging-action-element" @update="$global_emitter.$emit('form_input_changed')" ghost-class="dropzone" @start="$emit('dragging')" :forceFallback="true">
<!-- :key="`${route['id']}_${rule_index}_${action_index}`" -->
    <div class="actions" v-for="(action, j) in rule.actions">
      <div
        class="w-100 d-flex action-selector-container"
        :class="{'bottom-separator': rule.actions.length > 1 && j < rule.actions.length - 1}"
      >
        <div class="w-50 d-flex mr-7">
          <w-btn
            v-if="rule.actions.length > 1 && !readonly"
            @click="$delete(rule.actions, j)"
            icon
            small
			changes-form
            class="mt-3 pa-0 ma-0 mr-2"
			:data-test-id="`delete-action-${rule_index}-${j}`"
          >
            <v-icon color="error">mdi-close</v-icon>
          </w-btn>
          <w-btn icon small class="mt-3 pa-0 ma-0 mr-3 error info white--text">
            <span class="font-weight-600">
              {{ number_to_letters(j + 1) }}
            </span>
          </w-btn>
          <v-icon v-if="rule.actions.length > 1" class="mr-3 mt-1 cursor-grab dragging-action-element">$vuetify.icons.two_bars</v-icon>
          <RuleActionsSelector
			v-model="action['action']"
			@change="action_action_changed($event, j)"
			:data-test-id="`handle-call-route-rule-filter-${rule_index}`"
          />
        </div>
        <div class="w-50">
          <RuleActionAction
			:action="action"
			:rule_index="rule_index"
			:readonly="readonly"
			:action_index="j"
			:route="route"
			:extension="extension"
			:key="JSON.stringify(action)"
          />
        </div>
      </div>
    </div>
</draggable>
</template>

<script>
	import draggable from 'vuedraggable';
	import l from '../../../libs/lang';
	import FormInput from '../form/FormInput.vue';
	import RuleActionAction from './RuleActionAction.vue';
	import RuleActionsSelector from './RuleActionsSelector.vue';

  export default {
    props: {
		rule: {
			type: Object,
			required: true,
		},
		route: {
			type: Object,
			required: true,
		},
		rule_index: {
			type: Number,
			required: true,
		},
		extension: {
			type: Object,
			required: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
    },
	components: {
		draggable,
		FormInput,
		RuleActionsSelector,
		RuleActionAction,
    },
    data() {
		return {
			l,
		};
    },
	methods: {
		action_action_changed(data, action_index) {
			if (data === 'forward') {
				if (
					!(this.$props.route.rules[this.$props.rule_index]
					&& this.$props.route.rules[this.$props.rule_index].actions[action_index]
					&& Array.isArray(this.$props.route.rules[this.$props.rule_index].actions[action_index].items))
				) {
					this.$props.route.rules[this.$props.rule_index].actions[action_index].items = [{ type: 'phone_number' }];
				}
			}
			if (data === 'hold' && !(this.$props.route.rules[this.$props.rule_index].actions[action_index].hold_music)) {
				this.$props.route.rules[this.$props.rule_index].actions[action_index].hold_music = null;
			}
			this.$forceUpdate();
		},
		number_to_letters(number) {
			let num = number;
			let columnLetter = '';
			let t;

			while (num > 0) {
				t = (num - 1) % 26;
				columnLetter = String.fromCharCode(65 + t) + columnLetter;
				// eslint-disable-next-line no-bitwise
				num = (num - t) / 26 | 0;
			}
			return columnLetter || undefined;
		},
	},
  };
</script>
<style lang="scss" scoped>
    @import '~vuetify/src/styles/styles.sass';
	@import 'vue-apps-assets/styles/scss/vars.scss';

  .action-selector-container {
    .v-select, .v-icon {
      height: $input-height;
    }
  }
  .actions:not(:first-child) {
    padding-top: 30px;
  }
  .actions:first-child {
		padding-top: 12px;
  }
  .bottom-separator {
    padding-bottom: 30px;
    border-bottom: thin solid rgba(133, 146, 154, 0.2);
  }
</style>
