import l from '../libs/lang'
import Resource from './Resource'
import setup from '../libs/resources-setups/applications'

class Listener extends Resource {
    /**
     * @param {object} session
     * @param {object} component
     */
    constructor (session, component) {
        super(session, component, '/integrations/events/listeners')
        this.setup = setup
        this.item = {
            ':callback': {},
            ':subscriptions': []
        }
        this.loading = false
        this._application = null
        this.level = 'account'

        this.subscriptions = {
            'google-analytics': {
                start: {
                    label: l.t('listeners.phone-is-ringing', 'phone is ringing'),
                    values: ['call', 'new-call', 'first-leg']
                },
                end: {
                    label: l.t('listeners.call-is-finished', 'call is finished'),
                    values: ['call-log']
                }
            },
            'sms-autoresponder': {
                'Incoming SMS': {
                    label: l.t('listeners.incoming-sms', 'Incoming SMS'),
                    values: ['message', '*ms-message', 'inbound']
                },
                'Incoming Call': {
                    label: l.t('listeners.incoming-call', 'Incoming call'),
                    values: ['call', 'completed-call', 'inbound', 'first-leg']
                }
            }
        }
        this[':filters'] = []
        this.subscriptionsType = null
        this.empty_callbacks = {
            'google-analytics': {
                mode: 'APPLICATION',
                config: {
                    application: 'google-analytics',
                    parameters: {
                        tracking_id: '',
                        static: {},
                        dynamic: {}
                    }
                }
            },
            'sms-autoresponder': {
                mode: 'APPLICATION',
                config: {
                    application: 'sms-autoresponder',
                    parameters: {
                        message: '',
                        'rate-limit': 300
                    }
                }
            }
        }
    }

    /**
     *
     */
    set application (val) {
        this._application = val
        this.item[':callback'].mode = this.empty_callbacks[val].mode
        this.item[':callback'].config = this.empty_callbacks[val].config
        if (Object.keys(this.subscriptions[this.application]).length === 1) {
            this.subscriptionsType = Object.keys(this.subscriptions[this.application])[0]
        }
    }

    /**
     *
     */
    get application () {
        return this._application
    }

    /**
     *
     */
    set callback (val) {
        this.item[':callback'] = val
    }

    /**
     *
     */
    get callback () {
        return this.item[':callback']
    }

    /**
     * Loads all listeners and filters only those that have 'application' as callback mode.
     * Then, among them filters only those that have application in Object.keys(this.subscriptions)
     * Then filters only those that have exact match of one of this.subscriptions[someAppType]
     */
    async loadItems () {
        this.loading = true
        try {
            let items = await this.session.get_list_all(
                '/integrations/events/overview'
            )
            items = items.items.filter(
                (x) => x[':callback'].mode === 'APPLICATION'
            )
            let our_application_items = []
            for (const item of items) {
                for (const type in this.subscriptions) {
                    if (this.subscriptions[type]) {
                        for (const tags in this.subscriptions[type]) {
                            if (this.subscriptions[type][tags]) {
                                // eslint-disable-next-line array-callback-return, no-loop-func
                                item[':subscriptions'].map((x) => {
                                    const subscriptions = x[':tags']
                                    if (
                                        subscriptions.length === this.subscriptions[type][tags].values.length &&
                                        this.subscriptions[type][tags].values.every((y) => subscriptions.includes(y))
                                    ) {
                                        our_application_items.push(item)
                                    }
                                })
                            }
                        }
                    }
                }
            }
            our_application_items = Listener.removeDuplicates(our_application_items)
            if (!our_application_items.length) {
                this.component.routerPush('applications.create')
            }
            this.items = our_application_items
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
    }

    /**
     * @param {Array} items
     */
    static removeDuplicates (items) {
        const uniqueItems = []
        for (const item of items) {
            if (!uniqueItems.find((x) => x.id === item.id)) {
                uniqueItems.push(item)
            }
        }

        return uniqueItems
    }

    /*
    Submits the form: if this.item has ID, it updates its callback.
    If its subscriptions are changed,
    deletes them and creates new ones and updates listener with new subscriptions
    If there is no this.ite.id, it will create callback first,
    then listener, and finally subscriptions
    Checks  if user changes account/extension level, handles this situation
     */
    /**
     * @param {object} e
     */
    async submitted (e) {
        if (e) e.preventDefault()
        this.loading = true
        try {
            const changedLevel = await this.deleteOtherLevelProperties()
            const extension = this.extension ? this.extension : this.callback.extension_id
            const eventsBaseUri = extension
                ? `/extensions/${extension}/integrations/events`
                : '/integrations/events'
            if (this.callback.id) {
                const callback = JSON.parse(JSON.stringify(this.callback))
                delete callback.extension_id
                delete callback.voip_id
                delete callback.id
                this.callback = await this.session.patch_item(
                    `${eventsBaseUri}/callbacks/${this.callback.id}`,
                    callback
                )
            } else {
                this.callback = await this.session.create_item(`${eventsBaseUri}/callbacks/`, this.callback)
            }

            if (!this.item.id) {
                const newListener = await this.session.create_item(
                    `${eventsBaseUri}/listeners`, {
                        callback_id: this.callback.id
                    }
                )
                if (!extension && this[':filters'].length) {
                    for (const filter of this[':filters']) {
                        await this.session.create_item(
                            `/integrations/events/listeners/${newListener.id}/filters`,
                            filter
                        )
                    }
                }
                newListener[':callback'] = this.callback

                const subscriptions = await this.session.create_item(
                    `${eventsBaseUri}/listeners/${newListener.id}/subscriptions`, {
                        ':tags': this.subscriptions[this.application][this.subscriptionsType].values
                    }
                )
                newListener[':subscriptions'] = newListener[':subscriptions'] ? newListener[':subscriptions'] : []

                newListener[':subscriptions'].push(subscriptions)
                if (changedLevel) {
                    this.redirectAfterChangedLevel({
                        id: newListener.id
                    })
                    this.item = null
                    return null
                }
                this.successfulCreation('applications.index')
                this.item = null
            } else {
                // if tags are not the same, delete existing, create new ones and then update listener
                const subs = this.subscriptions[this.application][this.subscriptionsType].values
                let shouldUpdate = !this.item[':subscriptions'].length
                for (const tags of this.item[':subscriptions']) {
                    if (!subs.every((x) => tags[':tags'].includes(x))) {
                        shouldUpdate = true
                        await this.session.delete_item(
                            `${eventsBaseUri}/listeners/${this.item.id}/subscriptions/${tags.id}`
                        )
                    }
                }
                if (!extension && (this[':filters'].length || this.original_filters_relation.length)) {
                    await this.update_filters()
                }
                if (shouldUpdate) {
                    const subscriptions = await this.session.create_item(
                        `${eventsBaseUri}/listeners/${this.item.id}/subscriptions`, {
                            ':tags': subs
                        }
                    )
                    this.item[':tags'] = subscriptions
                }
                this.item = null
                this.successfulUpdate('applications.index')
            }
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false

        return true
    }

    /**
     *
     */
    async update_filters () {
        let to_delete = []
        if (this.original_filters_relation && this.original_filters_relation.length) {
            const existing_ids = this[':filters'].map((x) => x.id).filter((x) => x)
            to_delete = this.original_filters_relation.filter((x) => !existing_ids.includes(x.id))
        }
        const to_create = this[':filters'].filter((x) => !x.id)
        const to_update = this[':filters'].map((x) => {
            if (!x.id) return null
            const old_value = this.original_filters_relation.find((y) => x.id === y.id)
            if (JSON.stringify(old_value) !== JSON.stringify(x)) {
                return x
            }
            return null
        }).filter((x) => x)
        if (to_delete.length) {
            for (const f of to_delete) {
                await this.session.delete_item(`/integrations/events/listeners/${this.item.id}/filters/${f.id}`)
            }
        }
        if (to_update.length) {
            for (const f of to_update) {
                const data = {
                    filter_type: f.filter_type,
                    filter_value: f.filter_value
                }
                if (data.filter_type === 'schedule' && data.filter_value.id) {
                    data.filter_value = { id: data.filter_value.id } // doesnt work with other params sent
                }
                await this.session.patch_item(
                    `/integrations/events/listeners/${this.item.id}/filters/${f.id}`,
                    data
                )
            }
        }
        if (to_create.length) {
            for (const f of to_create) {
                const new_item = await this.session.create_item(
                    `/integrations/events/listeners/${this.item.id}/filters`,
                    f
                )
                this[':filters'].push(new_item)
            }
        }
    }

    /*
    If user switched from account to extension level,
    it will delete resources on the previous resources and their ids
    So they will be created from scratch
     */
    /**
     *
     */
    async deleteOtherLevelProperties () {
        let eventsBaseUri = ''

        if (this.level === 'account' && this.extension) {
            // that means that it's changes from ext lvl
            eventsBaseUri = `/extensions/${this.extension}/integrations/events`
        } else if (this.level === 'extension' && this.extension && Object.prototype.hasOwnProperty.call(this.item, 'extension_id') && !this.item.extension_id) {
            // changed from acc lvl
            eventsBaseUri = '/integrations/events'
        } else if (
            (this.item.extension_id && this.level === 'extension' && this.item.extension_id !== this.extension)
        ) {
            // changed extension
            eventsBaseUri = `/extensions/${this.item.extension_id}/integrations/events`
        }

        if (!eventsBaseUri) return null

        if (this.item.id) {
            const subs = this.subscriptions[this.application][this.subscriptionsType].values
            for (const tags of this.item[':subscriptions']) {
                if (!subs.every((x) => tags[':tags'].includes(x))) {
                    await this.session.delete_item(
                        `${eventsBaseUri}/listeners/${this.item.id}/subscriptions/${tags.id}`
                    )
                }
            }
            await this.session.delete_item(`${eventsBaseUri}/listeners/${this.item.id}`)
            delete this.item.id
        }
        if (this.callback.id) {
            await this.session.delete_item(`${eventsBaseUri}/callbacks/${this.callback.id}`)
            delete this.callback.id
        }

        if (this.level === 'account') this.extension = null

        return true
    }

    /**
     * @param {object} params
     */
    redirectAfterChangedLevel (params) {
        this.alert = {
            level: 'success',
            message: `${l.t('app.successfully-updated', 'Successfully updated')}.`
        }
        window.scrollTo(0, 0)
        this.loading = false
        window.successfulAlertTimeout = setTimeout(() => {
            this.component.routerPush('applications.show', params)
        }, this.timeUntilRedirects)
    }

    /*
    Loads listener, its callback and its subscriptions
    Creates new object on this.item that has structure like items returned from /events/.../overview
     */
    /**
     * @param {number} id
     */
    async getItem (id) {
        this.loading = true
        try {
            const listener = await this.session.get_item(`${this.baseUri}/${id}`)
            const callback = await this.session.get_item(`/integrations/events/callbacks/${listener.callback_id}`)
            delete listener.callback
            const subscriptions = await this.session.get_list(`${this.baseUri}/${listener.id}/subscriptions`)
            const filters = await this.session.get_list_all(`/integrations/events/listeners/${listener.id}/filters`)
            this[':filters'] = filters.items
            this.original_filters_relation = JSON.parse(JSON.stringify(filters.items))
            listener[':callback'] = callback
            listener[':subscriptions'] = subscriptions.items
            this.extension = listener.extension_id
            if (this.extension) this.level = 'extension'
            this.application = callback.config.application
            this.item = listener

            for (const key in this.subscriptions[this.application]) {
                if (this.subscriptions[this.application][key]) {
                    const subs = this.subscriptions[this.application][key].values
                    for (const tags of this.item[':subscriptions']) {
                        if (
                            tags[':tags'].length === subs.length &&
                            subs.every((x) => tags[':tags'].includes(x))
                        ) {
                            this.subscriptionsType = key
                            break
                        }
                    }
                }
            }
        } catch (err) {
            this.validation_error(err, true)
        }
        this.loading = false
    }
}

export default Listener
