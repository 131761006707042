<template>
    <div>
        <div class="d-flex justify-center">
          <div id="types-chart" class="chart-container" data-test-id="types-chart"></div>
        </div>
        <ChartLegend :items="legend"/>
    </div>
</template>
<script>
  import { OrangeChartPie } from 'orange-charts';
  import { distinct_colors } from '../../../libs/helpers';
  import ChartLegend from 'console/src/components/fragments/ChartLegend.vue';
  
  export default {
    props: {
        items: {
            type: Array,
        }
    },
    data() {
        const [incoming, outgoing] = distinct_colors(this)
        return {
            colors: {incoming, outgoing},
        };
    },
    components: {
        ChartLegend
    },
    mounted() {
      if (!this.items || (this.items && !this.items.length)) {
          return null;
      }
      const chart = new OrangeChartPie(this.items);
      chart
      .assignSource('count', {
        property: 'name',
        mapping: this.legend,
      })
      .setDataCallback('mouseover', (_, data) => {
        let details = document.getElementById('orange-charts-details');
        if (!details) {
            details = document.createElement('div');
            details.setAttribute('id', 'orange-charts-details');
            document.body.appendChild(details);
        }
        details.innerText = `${data.label}: ${data.value}`;
      })
      .setDataCallback('mousemove', (e) => {
        const details = document.getElementById(
            'orange-charts-details',
        );
        details.style.top = `${e.pageY}px`;
        details.style.left = `${e.pageX}px`;
      })
      .setDataCallback('mouseleave', () => {
        document.getElementById('orange-charts-details').innerText = '';
      })
      .render('types-chart', {
        label: 5,
        diameter: 300,
        height: 320,
        fair: false,
      });
    },
    computed: {
      legend() {
        const obj = {};
        obj[
          this.$lang.t('app.incoming-calls', 'Incoming calls')
          ] = this.$data.colors.incoming;
        obj[
          this.$lang.t('app.outgoing-calls', 'Outgoing calls')
          ] = this.$data.colors.outgoing;
        return obj;
      },
    },
  };
</script>

<style scoped>

</style>