import Dexie, { Table } from 'dexie'

if (!Dexie.dependencies.indexedDB || !Dexie.dependencies.IDBKeyRange) {
    Dexie.dependencies.indexedDB = require('fake-indexeddb')
    Dexie.dependencies.IDBKeyRange = require('fake-indexeddb/lib/FDBKeyRange')
}

interface DefaultTable {
    id?: number,
    key: string,
    value: number | string | boolean
}
const DefaultTableScheme = '++id, key, value'
class MyPdcDexie extends Dexie {
    mypdc: Table<DefaultTable>
    constructor () {
        super('MyPdc')
        this.version(1).stores({
            mypdc: DefaultTableScheme
        })
    }
}

/***/
const db = new MyPdcDexie()
/**
 *
 */
export { db }
