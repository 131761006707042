import { useState } from 'react'

type Response = [boolean, (v?: boolean | ((currentValue: boolean) => boolean)) => void]

/***/
export const useToggle = (defaultValue: boolean | (() => boolean)): Response => {
    const [value, setValue] = useState(defaultValue)

    const toggleValue = (newValue?: boolean | ((currentValue: boolean) => boolean)) => {
        if (newValue != null) setValue(newValue)
        else setValue(!value)
    }

    return [value, toggleValue]
}

export default useToggle
