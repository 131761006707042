<template>
    <w-loader v-if="loading"/>
    <div v-else>
        <w-alert
            v-if="alert"
            :message="alert.message"
            :level="alert.level"
            class="mb-6"
        />
        <w-form @changed="may_submit = true" v-if="user" @submit="submitted" data-discard="true" data-test-id="video-user-form">
            <FormInput :label="l.t('app.extension', 'Extension')">
              <APIAutocomplete
                v-model="user.extension_id"
                mode="extensions"
                @changed="user.extension_id = $event.id"
                :filtered_values="exclude_extensions"
                data-test-id="video-user-extensions-selector"
              />
            </FormInput>
            <FormInput :label="l.t('app.name', 'Name')">
                <w-text-field v-model="user.name" :rules="[$validation.required()]" hide-details='auto' data-test-id="video-user-name-input"/>
            </FormInput>
            <FormInput :label="l.t('app.email', 'Email')">
                <w-text-field :rules="[$validation.email()]" type="email" v-model="user.email" hide-details='auto' data-test-id="video-user-email-input"/>
            </FormInput>
            <FormInput>
                <w-btn :disabled="!may_submit" color="primary" type="submit" data-test-id="video-user-submit-btn">
                    {{ l.t("app.save", "Save") }}
                </w-btn>
            </FormInput>
            <!-- <FormInput :label="l.t('video.plan-code', 'Plan code')">
                <w-select
                    v-model="user.plan_code"
                    :items="[
                        {value: 'MEETINGS_BASE', text: l.t('video.base', 'Base')},
                        {value: 'MEETINGS_PLUS', text: l.t('video.plus', 'Plus')},
                        {value: 'MEETINGS_PRO', text: l.t('video.pro', 'Pro')},
                    ]"
                    hide-details="auto"
                />
            </FormInput> -->
        </w-form>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import FormInput from '../elements/form/FormInput.vue';
  import APIAutocomplete from '../elements/form/APIAutocomplete.vue';

  export default {
    props: {
      _user: {
        type: Object,
      },
      exclude_extensions: {
        type: Array,
        default: [],
      }
    },
    components: {
      FormInput,
      APIAutocomplete,
    },
    data() {
      return {
        l,
        loading: false,
        alert: null,
        user: null,
        may_submit: false,
      };
    },
    created() {
      this.$data.user = this.$props._user;
    },
    methods: {
      async submitted(e) {
        if (e) e.preventDefault();
        this.$emit('submitted', this.$data.user);
      },
    },
  };
</script>
