import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { App } from '../../../utils/objects'
import { getValue } from 'remote-config-value'
import { Tabs, Tab } from 'tabs'
import OverviewTab from './OverviewTab'
import RatingsTab from './RatingsTab'
import VersionTab from './VersionTab'

const useStyles = makeStyles(theme => ({
    tabsRoot: {
        width: '100%',
        "& [role='tablist']": {
            overflowX: 'auto'
        }
    },
    tabDetailsContainer: {
        padding: '2rem 1rem'
    }
}))

/**
 *
 */
const ContentTabs = ({ app }: { app: App }) => {
    const classes = useStyles()
    const [tab, setTab] = useState(0)

    const renderTab = () => {
        let component = <></>
        switch (tab) {
                case 0:
                    component = <OverviewTab app={app} />
                    break
                case 1:
                    component = <RatingsTab />
                    break
                case 2:
                    component = <VersionTab />
                    break
        }
        return component
    }

    return (
        <>
            <Tabs
                value={tab}
                onChange={(event, value) => setTab(value)}
                classes={{ root: classes.tabsRoot }}
            >
                <Tab label={getValue('apps_marketplace_app_tab_overview')} />
                <Tab label={getValue('apps_marketplace_app_tab_ratings')} />
                <Tab label={getValue('apps_marketplace_app_tab_version')} />
            </Tabs>
            <Box className={classes.tabDetailsContainer}>
                {renderTab()}
            </Box>
        </>
    )
}

export default ContentTabs
