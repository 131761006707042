import React, { Component } from 'react'
import { connect } from 'react-redux'
import { switchSetting } from '../actions/settings.js'
import { switchCurrentView } from '../actions/view.js'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	selectorWrapper: {
		display:		'flex',
		flexDirection:	'column',
		minWidth:		theme.selector.width,
		maxWidth:		theme.selector.width,
		boxShadow:		'0 0 0 1px #e0e0e0',
		height:			'100%',
		'&.full-width': {
			width:		'100%',
			maxWidth:	'100%'
		}
	},
	header: {
		fontSize:	20,
		fontWeight:	'bold',
		padding:	'10px 20px',
		boxShadow:	'inset 0px -1px 0px 0px #ccd6db'
	},
	settings: {
		flex:		1,
		overflowY:	'auto'
	},
	navItem: {
		padding:	'10px 20px',
		fontSize:	18,
		color:		theme.settingsApp.selectorItem.color,
		cursor:		'pointer',
		'&.selected': {
			background:	theme.settingsApp.selectorItem.selectedBackgroundColor
		},
		'&:hover': {
			background:	theme.settingsApp.selectorItem.hoverBackgroundColor
		}
	}
})

const mapStateToProps = state => ({
	settings:		state.settings,
	currentSetting:	state.currentSetting,
	smallView:		state.smallView
})
const mapDispatchToProps = dispatch => ({
	switchSetting:		navItem => dispatch(switchSetting(navItem)),
	switchCurrentView:	view => dispatch(switchCurrentView(view))
})

class SettingSelector extends Component {

	componentDidMount() {
		let pathnameSplit = window.location.pathname.split('/').filter(e => e)
		let settingName = ''
		if (pathnameSplit.length > 2) {
			settingName = pathnameSplit[2]
			let setting = this.props.settings.find(s => settingName.toLowerCase() === s.text.split(' ').join('').split('&').join('And').toLowerCase())
			if (!setting) return
			this.props.switchSetting(setting)
			this.props.switchCurrentView('settings')
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.smallView && !this.props.smallView && !this.props.currentSetting) {
			this.props.switchSetting(this.props.settings[0])
			this.props.switchCurrentView('settings')
		}
	}

	switchSetting = navItem => {
		this.props.switchSetting(navItem)
		this.props.switchCurrentView('settings')
	}

	isSettingSelected = navItem => {
		return this.props.currentSetting && navItem.id === this.props.currentSetting.id
	}

	render() {
		const { classes } = this.props
		return (
			<div className={`${classes.selectorWrapper} ${this.props.smallView ? 'full-width' : ''}`}>
				<div className={classes.header}>Settings</div>
				<div className={classes.settings}>
					{this.props.settings.map(navItem => (
						<div
							key			= {navItem.id}
							className	= {`${classes.navItem} ${this.isSettingSelected(navItem) ? 'selected' : ''}`}
							onClick		= {() => this.switchSetting(navItem)}
						>
							{navItem.text}
						</div>
					))}
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SettingSelector))