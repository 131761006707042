import React, { useContext, useEffect, ChangeEvent, useState } from 'react'
import FromNumberSelector from '../utils/FromNumberSelector/FromNumberSelector'
import { useToggle, useOnLongPress } from 'hooks'
import { PdcCallContext } from 'pdc-calls'
import { Grid, makeStyles } from '@material-ui/core'
import IconButton from 'icon-button-mui'
import styles from './styles'
import Typography from 'typography'
import Dialpad from '../utils/Dialpad/Dialpad'
import { BackspaceIcon, CallIcon } from 'svg-icons'
import ContactSelector from '../utils/ContactSelector/ContactSelector'
import { SoftphoneContext, SoftphoneHeader } from '../../../Softphone'
import InputField from '../utils/InputField/InputField'
import { db } from 'mypdc-dexie'
import { theme } from 'get-theme'
import { formatPhoneNumber, formatAsYouType, formatToDigits, isValidPhoneNumber } from 'phone-numbers'
import { FeatureEventsContext } from 'providers/src'
const useStyles = makeStyles(styles)

interface RemoveCharProps {
    onClick: () => void
    onLongPress: () => void
    disabled: boolean
}
/**
 *
 */
export function RemoveCharacterButton (props: RemoveCharProps): JSX.Element {
    const classes = useStyles()
    const options = {
        shouldPreventDefault: true,
        delay: 700
    }
    const longPressEvent = useOnLongPress(props.onLongPress, props.onClick, options)
    return (<IconButton
        {...longPressEvent}
        disabled = {props.disabled}
        classes = {{ root: `${classes.removeCharButton} disable-dragging` }}
    >
        <BackspaceIcon secondaryColor={props.disabled ? theme.palette.text.disabled : 'white'}/>
    </IconButton>)
}
interface CalleeProps {
    callee: string
    fillText: (text: string) => void
}
function PlaceCallButton (props: CalleeProps): JSX.Element {
    const PdcCall: any = useContext(PdcCallContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    const classes = useStyles()
    const onClick = () => {
        const extensionId = window.V5PHONECOM.voip_phone_id
        const voipId = window.V5PHONECOM.voip_id
        const DBKEY = `${voipId}_${extensionId}_RedialNumber`
        if (props.callee) {
            featureEventsContext.pushEvent({ appName: 'softphone', key: 'place-call-make-call-click', value: 'success' })
            PdcCall.call(props.callee)
        } else {
            db.mypdc.get({ key: DBKEY }).then(entry => {
                if (!entry?.value) {
                    featureEventsContext.pushEvent({ appName: 'softphone', key: 'place-call-make-call-click', value: 'void' })
                    return
                }
                featureEventsContext.pushEvent({ appName: 'softphone', key: 'place-call-make-call-click', value: 'insert-number' })
                props.fillText(formatPhoneNumber(entry.value))
            })
        }
    }
    return (<IconButton
        onClick = {onClick}
        classes = {{ root: `${classes.callButton} disable-dragging` }}
        data-testid='placeCall'
    >
        <CallIcon/>
    </IconButton>)
}
/**
 *
 */
export function SelectorPlaceCallButton (props: { placeCall: (number: string) => void, callee: string }): JSX.Element {
    const featureEventsContext = useContext(FeatureEventsContext)
    const callee = props.callee
    const hidden = callee.length < 1
    const classes = useStyles()
    const maxLength = 16
    const PlaceCall = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'place-call-selector-place-call-click' })
        props.placeCall(callee)
    }
    const dialText = `Dial: ${(callee.length > maxLength
        ? `${callee.slice(0, maxLength)}...`
        : callee)}`
    /**
     *
     */
    return (<div
        className={`${classes.selectorCallButtonWrapper} disable-dragging`}
        onClick = {PlaceCall}
        data-test-id="place-call-button"
        hidden={hidden}
    >
        <CallIcon/>
        <Typography variant='subtitle2'>{dialText}</Typography>
    </div>)
}
/**
 *
 */
export function ParseUserInput (userInput: string) {
    let parseInput = userInput
    if (parseInput.length === 1) parseInput = parseInput.trim()
    const countryCode = (parseInput[0] === '+' || parseInput.substring(0, 3) === '011') ? null : 'US'
    if (isValidPhoneNumber(parseInput) || (parseInput.length > 5 && !/[a-zA-Z]/g.test(parseInput)) || !countryCode) {
        parseInput = formatAsYouType(parseInput, countryCode)
    }
    return parseInput
}
/**
 *
 */
export function ParseCalleePhoneNumber (calleeNumber: string) {
    let number = formatToDigits(calleeNumber)
    const isInternational = (calleeNumber[0] === '+' || calleeNumber.substring(0, 3) === '011')
    if (isInternational) {
        number = calleeNumber
    } else if (isValidPhoneNumber(number)) {
        number = formatPhoneNumber(number)
    }
    return number
}
/***/
function PlaceCallView (): JSX.Element {
    const [inputValue, setInputValue] = useState('')
    const [callee, setCallee] = useState('')
    const [showDialpad, toggleShowDialpad] = useToggle(true)
    const classes = useStyles()
    const PdcCalls: any = useContext(PdcCallContext)
    const Softphone: any = useContext(SoftphoneContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    const PlaceCall = (number: string) => PdcCalls.call(number)
    const UpdateInputValue = (update: string) => {
        setInputValue((ParseUserInput(update)))
    }
    const onInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const number = event?.target?.value
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'place-call-input-field-change' })
        UpdateInputValue(number)
    }
    useEffect(() => {
        setCallee(ParseCalleePhoneNumber(inputValue))
    }, [inputValue])
    const onKeyUp = (event) => {
        // Enter Key
        if (callee.length > 1 && event.keyCode === 13) {
            featureEventsContext.pushEvent({ appName: 'softphone', key: 'place-call-on-enter' })
            PlaceCall(callee)
        }
    }
    const onKeyDown = (event) => {
        const BACKSPACE = 8
        if (inputValue.length <= 1 && event.keyCode === BACKSPACE) toggleShowDialpad(true)
        else if (event.key.length === 1 && !event.ctrlKey && !event.altKey) toggleShowDialpad(false)
    }
    const onDialpadClick = (char: string) => UpdateInputValue(inputValue + char)
    const onRemoveCharClick = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'place-call-remove-char-button-click', value: 'short' })
        UpdateInputValue(inputValue.slice(0, inputValue.length - 1))
    }
    const onRemoveWholeText = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'add-call-remove-char-button-click', value: 'long' })
        UpdateInputValue('')
    }
    const onContactSelect = (number: string) => {
        UpdateInputValue(number)
        PlaceCall(number)
    }
    return (<Grid container direction='column'>
        <Grid item>
            <SoftphoneHeader title={<Typography variant={'subtitle2'}>Make a call</Typography>}>
                {Softphone.isSoftphoneHidden()
                    ? <></>
                    : <div className={classes.inputFieldWrapper}>
                        <InputField onChange={onInputChange} onKeyUp={onKeyUp} onKeyDown={onKeyDown} value={inputValue}/>
                    </div>}
            </SoftphoneHeader>
        </Grid>
        <Grid item>
            <FromNumberSelector/>
        </Grid>
        {showDialpad
            ? <>
                <Grid item>
                    <Dialpad onClick={onDialpadClick}/>
                </Grid>
                <Grid item className={classes.buttons}>
                    <PlaceCallButton callee={callee} fillText={(text) => setInputValue(text)}/>
                    <RemoveCharacterButton
                        disabled = {inputValue.length < 1}
                        onClick = {() => {
                            featureEventsContext.pushEvent({ appName: 'softphone', key: 'place-call-remove-char-button-click', value: 'short' })
                            onRemoveCharClick()
                        }}
                        onLongPress = {() => {
                            featureEventsContext.pushEvent({ appName: 'softphone', key: 'place-call-remove-char-button-click', value: 'long' })
                            onRemoveWholeText()
                        }}
                    />
                </Grid>
            </>
            : <>
                <Grid item>
                    <SelectorPlaceCallButton placeCall={PlaceCall} callee={callee}/>
                </Grid>
                <Grid item>
                    <ContactSelector onSelect={onContactSelect} keyword={inputValue} height={317}/>
                </Grid>
            </>
        }
    </Grid>)
}

export default PlaceCallView
