<template>
    <v-container fluid class="filters-container pa-0">
        <div>
            <div class="text-right">
                <slot name="additionalOptions"></slot>
                <w-btn
                  color="primary lighten-5"
                  @click="show = !show"
                  :class="{'filters-shown': show, 'filters-hidden': !show}"
                  class="d-inline mb-3 primary--text text--darken-1"
                  id="filters-button"
                  data-test-id="filters-button"
                >
                  <v-icon small class="mr-1">$vuetify.icons.filter</v-icon> {{ l.t('filters.filters', 'Filters') }}
                </w-btn>
            </div>
            <transition name="collapsable">
                <w-form v-if="show" @submit.prevent="apply_filters" class="filters pa-3 w-100">
                  <slot name="fields"></slot>
                  <div id="filters-buttons" class="d-md-flex justify-center" data-test-id="filters-buttons">
                    <w-btn
                      @click.prevent="clearFilters"
                      class="primary--text text--darken-1 primary lighten-5 mr-5"
                      :class="button_classes"
                      data-test-id="filters-clear-filters"
                    >
                      {{ l.t("filters.clear-filters", "Clear filters") }}
                    </w-btn>
                    <slot name="additionalButtons"></slot>
                    <w-btn
                      depressed
                      color="primary"
                      type="submit"
                      :class="button_classes"
                      data-test-id="filters-apply-filters"
                    >
                      {{ l.t("app.apply-filters", "Apply filters") }}
                    </w-btn>
                  </div>
                </w-form>
            </transition>
        </div>
    </v-container>
</template>

<script>
  import l from '../../libs/lang';
  import FormInput from './form/FormInput.vue';

  export default {
    props: ['showFilters', 'label_cols', 'button_classes'],
    components: { FormInput },
    data() {
      return {
        show: false,
        l,
      };
    },
    mounted() {
        this.$parent.$on('closeFilters', () => this.show = false);
    },
    created() {
      if (this.$props.showFilters) this.$data.show = true;
    },
    methods: {
      apply_filters() {
        this.$emit('filtersApplied');
      },
      clearFilters() {
        this.show = false;
        this.$emit('clearFilters');
      },
    },
    watch: {
        show(val) {
            this.$emit('toggled', val);
        },
    },
  };
</script>
<style lang="scss">
  @import '~vuetify/src/styles/settings/_variables';
  .filters-container, .test-number-container {
      margin-top: 15px;
  }
  #filters-buttons, .filters-buttons {
    margin-top: 43px;
    margin-bottom: 32px;
  }
  @media only screen and (min-width: #{map-get($grid-breakpoints, 'md')}) {
    $filters-label-width: 130px;
    $filters-input-width: 300px;
    .filters {
      .form-field-container {
        margin-left: auto;
        margin-right: auto;
        max-width: calc($filters-label-width + $filters-input-width);
        .form-input-label, .form-label {
          width: $filters-label-width;
          max-width: $filters-label-width;
        }
        .form-input {
          width: $filters-input-width;
          max-width: $filters-input-width;
        }
      }
      .large-filter-field {
        margin-bottom: 17px;
        .form-input {
          position: absolute;
          width: calc(730px - $filters-label-width);
          max-width: calc(730px - $filters-label-width);
          margin-left: $filters-label-width;
        }
      }
    }
  }
</style>
