<template>
  <div class="video" data-test-id="video-list-page">
    <w-alert
      v-if="video.alert"
      :message="video.alert.message"
      :level="video.alert.level"
      :closable="video.enable_close_alert"
      @closed="video.alert = null"
      class="mb-6"
    />
    <div v-if="video.loading">
        <w-loader/>
    </div>
    <VideoFragment
      v-else-if="video.item"
      :_video="video.item"
      @submitted="video.save($event)"
    ></VideoFragment>
  </div>
</template>
<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import VideoConference from '../../../models/VideoConference';
  import VideoFragment from '../../fragments/Video.vue';

  export default {
    components: {
      VideoFragment,
    },
    data() {
      return {
        l,
        video: new VideoConference(this.$session, vueComponent(this)),
      };
    },
    async created() {
      await this.$data.video.getItem();
    },
  };
</script>
