import Statements from './components/screens/statements.vue'
import PaymentMethodsIndex from './components/screens/payment-methods/index.vue'
import PaymentMethodsCreate from './components/screens/payment-methods/create.vue'

/**
 *
 */
export {
    Statements,
    PaymentMethodsIndex,
    PaymentMethodsCreate
}
