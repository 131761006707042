import l from '../lang';

export default {
	'routes': {
		'create': 'video.users.create',
		'index': 'video.users.index',
	},
	'delete-single': l.t('video-users.delete-single', 'Are you sure you want to delete this user?'),
	'delete-filtered': l.t('video-users.delete-filtered', 'Are you sure you want to delete all filtered users?'),
	'delete-all': l.t('video-users.delete-all', 'Are you sure you want to delete all users?'),
	'successfull-creation': l.t('video-users.successfully-created', 'Your user has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('video-users.failed-creation', 'There was a problem creating your user'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
};
