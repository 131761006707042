import React from 'react'
import { noNumberIconSvg } from 'pdc-icons'
import { makeStyles } from '@material-ui/core'
import { theme } from 'get-theme'

const useStyles = makeStyles(theme => ({
	noSetup: {
		display:		'flex',
		flexDirection:	'column',
		padding:		'60px 25px',
		'& .info-title': {
			display:	'flex',
			'& span': {
				marginLeft:	15,
				fontSize:	16,
				fontWeight:	600,
				lineHeight:	1.31
			}
		},
		'& .link': {
			fontSize:	14,
			fontWeight:	'bold',
			lineHeight:	1.29,
			color:		theme.palette.primary.main,
			margin:		'10px 0'
		},
		'& .support-message': {
			fontSize:		12,
			lineHeight:		1.33
		}
	}
}))

export default function NoSetup(props) {
	const classes = useStyles()

	// TODO: Change the links
	const data = {
		messages: {
			title:			'Ooops! You currently do not have a phone number connected to your extension.',
			linkText:		'Learn how to connect your phone number',
			link:			'https://www.phone.com/phone-support/getting-started-entrepreneurs-1-2-employees/',
			supportMessage:	'If you continue having trouble, please contact our Support Center (800) 998-7087 or email support@phone.com'
		},
		voicemail: {
			title:			'Ooops! Looks like you do not have voicemail set up for this extension.',
			linkText:		'Learn how to create a greeting and set up call handling rules',
			link:			'https://www.phone.com/phone-support/how-do-i-set-up-my-voicemail/',
			supportMessage:	'If you continue having trouble, please contact our Support Center (800) 998-7087 or email support@phone.com'
		},
		faxes: theme.faxesApp.noSetup,
		calls: {
			title:			'Ooops! You currently do not have a phone number connected to your extension.',
			linkText:		'Learn how to connect your phone number',
			link:			'https://www.phone.com/phone-support/getting-started-entrepreneurs-1-2-employees/',
			supportMessage:	'If you continue having trouble, please contact our Support Center (800) 998-7087 or email support@phone.com'
		},
		meetings: {
			title:			'Meetings is our brand new online meeting tool allowing you to connect with your customers and colleagues online and share your webcam or your screen.',
			linkText:		'Find out more here',
			link:			'https://www.phone.com/features/video-conferencing-service',
			supportMessage:	'And contact your Phone.com administrator to get access.'
		},
		callsVirtual: {
			title:			'Virtual Extension calling is coming soon!',
			linkText:		'Learn how to setup your extension for making calls',
			link:			'https://www.phone.com/phone-support/adding-extensions-employees-departments/',
			supportMessage:	'If you continue having trouble, please contact our Support Center (800) 998-7087 or email support@phone.com'
		}
	}

	let title			= data[props.type].title
	let link			= data[props.type].link
	let linkText		= data[props.type].linkText
	let supportMessage	= data[props.type].supportMessage

	return (
		<div className={classes.noSetup}>
			<div className='info-title'>
				<div style={{display: 'flex'}}>
					<img src={noNumberIconSvg} alt='No number' />
				</div>
				<span>{title}</span>
			</div>
			<a className='link' href={link} target='_blank'>
				{linkText}
			</a>
			<div className='support-message'>
				{supportMessage}
			</div>
		</div>
	)
}