<template>
    <DefaultModal
      v-bind="$attrs"
      v-on="$listeners"
      @closed="$emit('closed')"
      :with_x="false"
      max-width="350"
      data-test-id="delete-confirmation-modal"
    >
      <template v-slot:title>
          <slot name="title">
            {{l.t('app.are-you-sure-you-want-to-delete-selected-item-s', 'Are you sure you want to delete the selected item(s)?')}}
          </slot>
      </template>
      <slot>{{l.t('app.this-action-cant-be-undone','This action cannot be undone.')}}</slot>
      <template v-slot:buttons>
          <w-btn color="secondary" @click="$emit('closed')">{{l.t('app.cancel', 'Cancel')}}</w-btn>
          <w-btn color="error" @click="on_confirm_deletion">{{l.t('app.delete', 'Delete')}}</w-btn>
      </template>
    </DefaultModal>
</template>
<script>
import l from '../../../libs/lang';
import DefaultModal from './DefaultModal.vue';

  export default {
    components: {DefaultModal},
    data() {
      return {
        l,
      };
    },
    methods: {
        on_confirm_deletion() {
            this.$emit('confirmed');
            this.$emit('closed');
        }
    }
  };
</script>
