import React, { useCallback, useContext, useMemo } from 'react'
import Select from 'select-mui'
import MenuItem from 'menu-item-mui'
import { useInputDevices, useOutputDevices, useVolume } from '../AudioSettings'
import { VolumeDownIcon, VolumeUpIcon } from 'svg-icons/src'
import RemoteConfigValue from 'remote-config-value'
import { makeStyles, Slider } from '@material-ui/core'
import styles from './styles'
import { FeatureEventsContext } from 'providers/src'

const useStyles = makeStyles(styles)

/***/
export function MicrophoneSelector (): JSX.Element {
    const classes = useStyles()
    const { inputDevices, onMicSelect } = useInputDevices()
    const selectedDevice = useMemo(() => inputDevices.find(d => d.selected) || inputDevices[0], [inputDevices])
    const featureEventsContext = useContext(FeatureEventsContext)

    const onChange = useCallback((event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
        const deviceId = event.target.value as string
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'preferences-microphone-select' })
        onMicSelect(deviceId)
    }, [inputDevices])

    if (!inputDevices.length) return <></>

    return (
        <Select
            label = {<RemoteConfigValue valueId='softphone_preferences_input_source_label'/>}
            value = {selectedDevice?.deviceId}
            onChange = {onChange}
            MenuProps = {{ classes: { list: classes.dropdownItemsWrapper } }}
            formControlClasses = {{ root: `${classes.dropdownMenu} size-md` }}
        >
            {inputDevices.map(device => (
                <MenuItem
                    key = {device.deviceId}
                    value = {device.deviceId}
                    checked = {device.selected}
                >{device.label}</MenuItem>
            ))}
        </Select>
    )
}

/***/
export function SpeakerSelector (): JSX.Element {
    const classes = useStyles()
    const { outputDevices, onSpeakerSelect } = useOutputDevices()
    const selectedDevice = useMemo(() => outputDevices.find(d => d.selected) || outputDevices[0], [outputDevices])
    const featureEventsContext = useContext(FeatureEventsContext)

    const onChange = useCallback((event: React.ChangeEvent<{ name?: string, value: unknown }>) => {
        const deviceId = event.target.value as string
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'preferences-speaker-select' })
        onSpeakerSelect(deviceId)
    }, [outputDevices])

    if (!outputDevices.length) return <></>

    return (
        <Select
            label = {<RemoteConfigValue valueId='softphone_preferences_output_source_label'/>}
            value = {selectedDevice?.deviceId}
            onChange = {onChange}
            MenuProps = {{ classes: { list: classes.dropdownItemsWrapper } }}
            formControlClasses = {{ root: `${classes.dropdownMenu} size-md` }}
        >
            {outputDevices.map(device => (
                <MenuItem
                    key = {device.deviceId}
                    value = {device.deviceId}
                    checked = {device.selected}
                >{device.label}</MenuItem>
            ))}
        </Select>
    )
}

/***/
export function VolumeControl (): JSX.Element {
    const classes = useStyles()
    const { getVolume, setVolume, increase, decrease } = useVolume()
    const featureEventsContext = useContext(FeatureEventsContext)

    return (
        <div className={classes.volumeWrapper}>
            <VolumeDownIcon onClick={() => decrease()}/>
            <Slider
                value = {getVolume()}
                onChange = {(e, volume) => {
                    featureEventsContext.pushEvent({ appName: 'softphone', key: 'preferences-volume-change', value: volume as number })
                    setVolume(volume as number)
                }}
                classes = {{ root: classes.slider, thumb: classes.sliderThumb }}
                aria-labelledby = 'continuous-slider'
            />
            <VolumeUpIcon onClick={() => increase()}/>
        </div>
    )
}
