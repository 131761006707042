import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import InfiniteScroller from 'pdc-infinite-scroller'
import Spinner from 'spinner'
import SearchResultItem from './SearchResultItem'

const styles = theme => ({
    wrapper: {
        height: '100%',
        marginBottom: 10,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '& .results-status': {
            marginTop: 40,
            fontFamily: 'Montserrat-semi-bold',
            fontWeight: 600,
            fontSize: 14,
            lineHeight: '21px',
            paddingLeft: 60
        },
        '&.mobile-view': {
            '& .results-status': {
                paddingLeft: 35
            }
        }
    },
    mainSeparator: {
        width: '100%',
        height: 5,
        borderTop: '1px solid #C7CDD1',
        background: '#F4F5F6',
        margin: 0,
        marginTop: 11
    }
})

class SearchResults extends Component {
    removeDuplicates (items) {
        // const start = Date.now()
        const keys = ['to', 'text', 'created_at']
        // let unique = []
        /*
        && Math.abs(u.created_at - i.created_at) < 60
                items.map(i => unique.filter(u => i.to == u.to && i.text == u.text ).length > 0 ? null : console.log(i))

         */

        // BELOW WORKS,
        // EDGE CASE: except if created_at off by one second, need a way to Math.abs() the created time
        const unique = items.filter(
            (s => o => (k => !s.has(k) && s.add(k))(keys.map(k => o[k]).join('|')))(new Set())
        )
        // console.log(items.length, ' -> ', unique.length, `in ${Date.now() - start} ms` )
        // console.log(unique)
        return unique
    }

    renderResultsStatus (inboxes, totalItems) {
        let content = null
        if (totalItems && totalItems > 0 && inboxes && inboxes.length > 0) { content = <span>Showing {inboxes.length} of {totalItems} results ({this.props.queryTime} seconds)</span> } else content = <span>No results found</span>
        return <div className='results-status'>{content}</div>
    }

    renderResults = (inboxes) => {
        if (!inboxes.length) return null
        const totalItems = this.props.totalItems
        return (
            <InfiniteScroller
                loader = {<Spinner/>}
                hasMore = {totalItems > inboxes.length}
                loadMore = {this.props.onSearch}
            >
                {inboxes.map((inbox, i) => {
                    if (!(inbox && inbox.text && inbox.text.trim().length > 0)) return null
                    return (
                        <SearchResultItem
                            key = {`${inbox.created_at}${i}`}
                            inbox = {inbox}
                            text = {inbox.text}
                            participants = {inbox.direction === 'in' ? [{ number: inbox.from }] : [{ number: inbox.to }]}
                            timestamp = {inbox.created_at}
                            showMyNums = {false}
                            active = {true}
                            bold = {true}
                            isNew = {false}
                            onClick = { () => {} }
                            goToConversation = {this.props.goToConversation}
                            isMobile = {this.props.isMobile}
                        />
                    )
                })}
            </InfiniteScroller>
        )
    }

    render () {
        const { classes, isMobile } = this.props
        const inboxes = this.props.inboxes.filter(item => item.text && item.text.trim().length > 0)
        // inboxes = this.removeDuplicates(inboxes)
        // helps account for the total result reducing with cusor included.
        const totalItems = this.props.totalItems

        return (
            <div className={`${classes.wrapper} ${isMobile ? 'mobile-view' : ''}`}>
                {this.renderResultsStatus(inboxes, totalItems)}
                <hr className={classes.mainSeparator}/>
                {this.renderResults(inboxes)}
            </div>
        )
    }
}

export default withStyles(styles)(SearchResults)
