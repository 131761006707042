import Resource from './Resource'
import setup from '../libs/resources-setups/conversations'

/**
 *
 */
export default class Conversation extends Resource {
    /**
     * @param {object} session
     * @param {object} component
     */
    constructor (session, component) {
        super(session, component, '/conversations')
        this.setup = setup
        this.limit = 10
        this.loading_refresh = false
        this.extension = null
        this.mode = 'sms'
        this.messages = {}
        this._opened = null
        this.loading_messages = {}
        this.loading_more = false
        this.inbox_timeout = null
        this.updated_at = new Date()
        this.caller_id = null
        this.opened_conversation = null // need this for expansion panels
    }

    /**
     *  @param {boolean} val
     */
    set opened (val) {
        this._opened = val
        if (!val) {
            setTimeout(() => {
                this.opened_conversation = null
            }, 1)
        } else {
            const index = this.items.findIndex((x) => x.id === val)
            if (index > -1) {
                setTimeout(() => {
                    this.opened_conversation = index + 1
                }, 1) // because of the new conversation panel
            }
        }
    }

    /**
     *
     */
    get opened () {
        return this._opened
    }

    /**
     * @param {object} e
     */
    extensionChanged (e) {
        if (e.id === this.extension) return false
        this.extension = e.id
        this.caller_id = e.caller_id !== 'private' ? e.caller_id : null

        return true
    }

    /**
     *
     */
    apply_filters () {
        this.uri = this.baseUri
    }

    /**
     * @param {object} params
     */
    async loadItems (params) {
        if (!this.extension) return null
        this.resetDeleting()
        this.loading = true
        const offset = this.checkIfUserDeletedItems(params.offset, params.page)
        try {
            const items = await this.session.get_list(this.uri, params.limit, offset)
            this.page = params.page
            if (this.page > 1 && !items.items.length) {
                this.component.emit('pageChanged', 1)
                return this.loadItems({ limit: this.limit, offset: 0, page: 1 })
            }
            this.items = items.items
            this.finalizeLoadingItems(items)
            this.component.emit('itemsLoaded', JSON.parse(JSON.stringify(items)))
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false

        return this.items
    }

    /**
     *
     */
    async refresh () {
        this.loading_refresh = true
        this.component.emit('reloadPaginated')
        await this.load_inbox(null, true)
        this.loading_refresh = false
    }

    /**
     * @param {number} c_id
     * @param {boolean} refresh_mode
     */
    async load_inbox (c_id, refresh_mode) {
        if (refresh_mode) {
            this.loading_refresh = true
        }
        if (this.extension) {
            this.updated_at = new Date()
            if (refresh_mode) {
                for (const m_c_id of Object.keys(this.messages)) {
                    if (this.messages[m_c_id] && this.messages[m_c_id].length) {
                        await this.more_messages(m_c_id, true)
                    }
                }
            }
            this.loading_refresh = false
            if (c_id) {
                await this.toggle_conversation(null, c_id, 0, true)
            }
        } else {
            this.updated_at = null
            this.items = []
            this.loading = false
            this.loading_refresh = false
        }
    }

    /**
     * @param {object} e
     */
    async sent (e) {
        if (this.messages[e.conversation_id]) {
            this.messages[e.conversation_id] = [e].concat(
                this.messages[e.conversation_id]
            )
        } else {
            this.component.emit('reloadPaginated')
            this.uri = `/extensions/${this.extension}/conversations`
            await this.load_inbox(e.conversation_id)
        }
        for (const item of this.items) {
            if (item.id === e.conversation_id) {
                item.last_message = e
                break
            }
        }
    }

    /**
     * @param {object} conversation
     */
    async created (conversation) {
        this.component.emit('reloadPaginated')
        this.uri = `/extensions/${this.extension}/conversations`
        if (this.items) {
            const index = this.items.findIndex((x) => x.id === conversation.conversation_id)
            if (index > -1) {
                this.items[index] = conversation
            } else {
                this.items.unshift(conversation)
            }
        }
        await this.load_inbox(conversation.conversation_id)
    }

    /**
     * @param {string} c_id
     * @param {object} new_messages
     */
    async more_messages (c_id, new_messages) {
        const params = {
            // 'extension_id': this.extension,
            conversation_id: c_id
        }
        let limit = 10
        let offset = 0
        if (new_messages) {
            params.created_after = this.messages[c_id][0].created_at - 1
            limit = 500
            await this.mark_read(c_id)
        } else {
            offset = Object.prototype.hasOwnProperty.call(this.messages, c_id)
                ? this.messages[c_id].length
                : 0

            this.loading_messages[c_id] = true
            this.component.forceUpdate()
        }

        let uri = `/extensions/${this.extension}/messages?filters[conversation_id]=${c_id}`
        if (params.created_after) {
            uri = `${uri}&filters[created_after]=${params.created_after}`
        }
        const res = await this.session.get_list(uri, limit, offset)
        if (!Object.prototype.hasOwnProperty.call(this.messages, c_id)) {
            this.messages[c_id] = []
        }
        const ids = this.messages[c_id].map((v) => v.message_id)
        this.messages[c_id] = this.messages[c_id].concat(
            res.items.filter((v) => !ids.includes(v.message_id))
        )
        this.loading_messages[c_id] = false
        this.component.forceUpdate()
    }

    /**
     * @param {number} c_id
     */
    async delete_conversation (c_id) {
        try {
            await this.session.delete_item(`${this.baseUri}/${c_id}`)
            this.items = this.items.filter((v) => v.id !== c_id)
        } catch (err) {
            this.validation_error(err)
        }
    }

    // eslint-disable-next-line consistent-return
    /**
     * @param {object} event
     * @param {number} c_id
     * @param {boolean} unread
     * @param {boolean} open_only
     */
    async toggle_conversation (event, c_id, unread, open_only) {
        if (
            event &&
            event.target.classList.contains('multiple-select-checkbox')
        ) {
            return true
        }
        this.opened = (open_only || this.opened !== c_id) ? c_id : null
        if (
            this.opened === c_id &&
            !(this.messages[c_id] && this.messages[c_id].length)
        ) {
            await this.more_messages(c_id)
        } else {
            delete this.messages[c_id]
        }
        if (this.opened === c_id && unread) {
            this.mark_read(c_id)
        }
        this.component.forceUpdate()
    }

    /**
     * @param {number} c_id
     */
    async mark_read (c_id) {
        await this.session.patch_item(
            `/extensions/${this.extension}/conversations/${c_id}`,
            { read: true }
        )
        for (const conversation of this.items) {
            if (conversation.id === c_id) {
                conversation.unread_messages = 0
            }
        }
        this.component.forceUpdate()
    }

    // eslint-disable-next-line class-methods-use-this
    /**
     * @param {object} c
     */
    get_participants (c) {
        const p = [c.last_message.from].concat(
            c.last_message.to.map((v) => v.number)
        )
        let found_one = false
        return p.filter((v) => {
            if (found_one) {
                return true
            }
            if (c.my_numbers.includes(v)) {
                found_one = true
                return false
            }
            return true
        })
    }
}
