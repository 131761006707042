import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A7621
 *
 * @param {SvgIconProps} props - svg props
 */
export const TranscriptIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M2 6.33325C2 4.6764 3.34315 3.33325 5 3.33325H19C20.6569 3.33325 22 4.6764 22 6.33325V14.9999C22 16.6568 20.6569 17.9999 19 17.9999H18.9465C17.6675 17.9999 16.4071 18.3066 15.2711 18.8942L9.9011 21.6718C9.40258 21.9296 8.9229 21.2968 9.30586 20.8865L10.4298 19.6822C11.0264 19.043 10.5732 17.9999 9.69878 17.9999H5C3.34315 17.9999 2 16.6568 2 14.9999V6.33325Z' fill={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M18.6668 7.33317C18.6668 6.96498 18.3684 6.6665 18.0002 6.6665H6.00016C5.63197 6.6665 5.3335 6.96498 5.3335 7.33317C5.3335 7.70136 5.63197 7.99984 6.00016 7.99984H18.0002C18.3684 7.99984 18.6668 7.70136 18.6668 7.33317ZM5.3335 10.6665C5.3335 10.2983 5.63197 9.99984 6.00016 9.99984H18.0002C18.3684 9.99984 18.6668 10.2983 18.6668 10.6665C18.6668 11.0347 18.3684 11.3332 18.0002 11.3332H6.00016C5.63197 11.3332 5.3335 11.0347 5.3335 10.6665ZM5.3335 13.9998C5.3335 13.6316 5.63197 13.3332 6.00016 13.3332H12.6668C13.035 13.3332 13.3335 13.6316 13.3335 13.9998C13.3335 14.368 13.035 14.6665 12.6668 14.6665H6.00016C5.63197 14.6665 5.3335 14.368 5.3335 13.9998Z' fill='white'/>
        </SvgIcon>
    )
}

export default TranscriptIcon
