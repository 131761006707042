/* eslint-disable jsdoc/check-examples */
/* eslint-disable @typescript-eslint/no-use-before-define */
// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app'
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics'

// Add the Firebase products that you want to use
import 'firebase/auth'
import 'firebase/remote-config'

import RemoteConfigDefaults from './RemoteConfigDefaults'
import { reableAppClientVersion, readableAppVersion } from 'phonecom'
import { isElectron } from 'pdc-electron-utils'
import { useEffect, useState } from 'react'

// to add a firebase config go to firebase console my.phone repo add web app and take the "firebaseConfig" they give you and use JSON.stringify,
// then put it in the deployment.config as REACT_APP_FIREBASE_CONFIG one line
// console.log(process.env.REACT_APP_FIREBASE_CONFIG)
const FIREBASE_CONFIG = process.env.REACT_APP_FIREBASE_CONFIG ? JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG) : null
const FIREBASE_REMOTE_CONFIG_FETCH_INTERVAL = process.env.NODE_ENV === 'development' ? 36000 : 300000 // set to 36 seconds for dev and 5 min for prod
let firebaseInit = null
let remoteConfigInstance = null
let fetchAndActivateRemoteValuesCompleted = false
const remoteValueChangeListeners = []
// Load app config, then load user info and set it to window
/**
 * @param {object} firebaseConfig
 */
const initializeFirebase = (firebaseConfig = FIREBASE_CONFIG) => {
    if (!firebaseInit && !firebase.apps.length) { firebaseInit = firebase.initializeApp(firebaseConfig) }
    // try {
    //     firebase.analytics()
    // } catch {
    //     console.error('could not start firebase analytics')
    // }
    return firebaseInit
}

async function fetchAndActivateRemoteValues (remoteConfigInstance) {
    return remoteConfigInstance.fetchAndActivate().then(() => {
        remoteValueChangeListeners.map(callback => callback ? callback(remoteConfigInstance) : null)
    }).catch(error => {
        if (error.code === 'remoteconfig/fetch-client-network') {
            console.warn('Network error while fetching remote config. This error is suppressed.')
        } else if (error.code === 'installations/app-offline') {
            console.warn('Application is offline. This error is suppressed.')
        } else if (error.code === 'installations/not-registered') {
            console.warn('Firebase Installation is not registered. This error is suppressed.')
        } else {
            throw error // Rethrow any other errors
        }
    })
}

async function activateRemoteValues (remoteConfigInstance) {
    return remoteConfigInstance.activate().then(() => {
        remoteValueChangeListeners.map(callback => callback && typeof callback === 'function' ? callback(remoteConfigInstance) : null)
    })
}

/**
 * @param {Function} fetchAndActivateRemoteValuesCompletedCallback
 */
function initFirebaseRemoteConfig (fetchAndActivateRemoteValuesCompletedCallback = () => { return null }) {
    const defaults = RemoteConfigDefaults
    if (!remoteConfigInstance) {
        try {
            initializeFirebase()
            remoteConfigInstance = firebase.remoteConfig()

            remoteConfigInstance.settings.minimumFetchIntervalMillis = FIREBASE_REMOTE_CONFIG_FETCH_INTERVAL
            remoteConfigInstance.settings.fetchTimeoutMillis = 60 * 1000 // 1 minute

            remoteConfigInstance.defaultConfig = defaults
        } catch (e) {
            if (global.isJest) {
                console.error('No FIREBASE_CONFIG set up for this app, falling back to defaults only', e)
            }

            // gererate a remote config stub so teh apps will still work and return the default strings if firebase fails
            remoteConfigInstance = {
                getString: (val) => { return defaults[val] },
                activateRemoteValues: (stub) => { return null }
            } // todo evaluate if we should even have this or just let it error
        }
    }
    remoteValueChangeListeners.push(fetchAndActivateRemoteValuesCompletedCallback)

    if (!fetchAndActivateRemoteValuesCompleted) {
        fetchAndActivateRemoteValuesCompleted = true
        try {
            remoteConfigInstance.activate()
            activateRemoteValues(remoteConfigInstance).then(fetchAndActivateRemoteValues(remoteConfigInstance))
        } catch (e) {
            console.error(`could not fetch or active firebase configs ${e}`)
        }
    }
    return remoteConfigInstance
}

/**
 * returns the remote config value from firebase if there exists a config, else returns the default
 *
 * @param {string} valueId - id in remote config for the value - reference "remoteConfigInstance.defaultConfig" for defaults, each id should have a default
 * @param {Function} valueUpdatedCallBack - call back if the value is updated
 * @param {boolean} defaultRequired
 * @example
 *   // returns value that is currently loaded
 *   getValue('messages_tab_title')
 * @example
 *   //  gets current value and updates state if any updates occur
 *   this.setState({
 *     value: getValue(
 *            this.props.valueId,
 *            (newValue) => {this.setState({ value: newValue })}
 *         )
 *     })
 */
function getValue (valueId, valueUpdatedCallBack = null, defaultRequired = true) {
    // console.log('getValue', valueId)
    const remoteConfig = initFirebaseRemoteConfig((remoteConfigInstance) => {
        const val = remoteConfigInstance.getString(valueId)
        console.debug('value update', valueId, val)
        if (typeof valueUpdatedCallBack === 'function') valueUpdatedCallBack(val)
    })
    // [START rc_get_values]
    const val = remoteConfig.getString(valueId)
    // console.debug(valueId, val)
    // [END rc_get_values]
    if (defaultRequired && (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') && typeof (RemoteConfigDefaults[valueId]) === 'undefined') {
        throw new Error(`no default value for ${valueId}. Please add it to the RemoteConfigDefaults object, located in firebase-utils/RemoteConfigDefaults.js`)
    }

    return val
}

/**
 * React hook to read/listen to a firebase remote config value.
 *
 * @param {*} valueId
 * @returns
 */
function useValue (valueId) {
    const [value, setValue] = useState(getValue(valueId))

    useEffect(() => {
        getValue(valueId, newVal => setValue(newVal))
    }, [])

    return value
}

/**
 * @example setUserProperties({"voip_id": 12345}}
 * @param {object} setUserProperties
 */
function setUserProperty (setUserProperties) {
    initializeFirebase()
    firebase.analytics().logEvent('test_event')
    firebase.analytics().setUserProperties(setUserProperties)
}

/**
 * sets the default values for firebase Analitics from a user info object
 *
 * @param {object} userInfo
 */
function setFirebaseAnaliticsUserProperties (userInfo) {
    setUserProperty({
        voip_id: userInfo.account_id,
        voip_phone_id: userInfo.extension_id,
        app_version: readableAppVersion(),
        branding_id: userInfo.account.branding_id,
        is_nxt: userInfo.user_tiered
    })

    if (isElectron) {
        setUserProperty({ app_electron_client_version: reableAppClientVersion() })
    }
    if (userInfo.user_id) {
        setUserProperty({ user_id: userInfo.userId })
    }

    clear_remote_config_cache()
}

function clear_remote_config_cache () {
    remoteConfigInstance.settings.minimumFetchIntervalMillis = 1000
    remoteConfigInstance.activate()
    // trys for 20 seconds, trys every 1 sec for the first 5 seconds, the every 5 for the remaining 20 seconds
    // try every 1 second for 5 seconds
    let interval = setInterval(() => {
        activateRemoteValues(remoteConfigInstance).then(fetchAndActivateRemoteValues(remoteConfigInstance))
    }, 1000)
    // change to try every 5 seconds for the remaining 15 seconds
    window.setTimeout(() => {
        clearInterval(interval)
        interval = setInterval(() => {
            activateRemoteValues(remoteConfigInstance).then(fetchAndActivateRemoteValues(remoteConfigInstance))
        }, 5000)
    }, 5000)

    window.setTimeout(() => {
        activateRemoteValues(remoteConfigInstance).then(fetchAndActivateRemoteValues(remoteConfigInstance))

        remoteConfigInstance.settings.minimumFetchIntervalMillis = FIREBASE_REMOTE_CONFIG_FETCH_INTERVAL
        clearInterval(interval)
    }, 20000)
}

/**
 * Custom phone.com util that wraps many of the firebase functions.
 */
export { getValue, useValue, initFirebaseRemoteConfig, setUserProperty, setFirebaseAnaliticsUserProperties }
