import React, { useState, useEffect } from 'react'
import { Alert, Color as AlertColor } from 'alert-mui'

import RemoteConfigValue, { getValue } from 'remote-config-value'
import Typography from 'typography'
import { makeStyles } from '@material-ui/core/styles'
import { Textarea } from 'textarea-mui'
import ConfirmModal from 'confirm-modal'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import CampaignEnrollmentStepContainer from './CampaignEnrollmentStepContainer'
import { useFormInput } from '../hooks/useFormInput'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        gap: '1rem',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        paddingTop: '1rem',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column'
        }
    },
    columnContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        gap: '1rem',
        paddingTop: '1rem'
    },
    messageContainer: {
        flex: 1,
        "& [class^='MuiFormControl-root']": {
            width: '100%'
        },
        [theme.breakpoints.only('sm')]: { width: '33%' }
    },
    messageFlowContainer: {
        flexGrow: 1,
        width: '100%',
        display: 'grid'
    },
    noWrapWhitespace: { whiteSpace: 'nowrap' },
    smallFontSize: { [theme.breakpoints.only('sm')]: { fontSize: '12px!important' } },
    fullWidthTextArea: { resize: 'none', width: '100%' },
    titleContainer: { paddingTop: '1rem', paddingBottom: '1rem' },
    marginBottom: {
        marginBottom: '1.25rem'
    }
}))

/**
 * @param {object} props
 */
const CampaignSampleMessageStep = (props) => {
    const smsContentData = props.passData.campaign_sms_content
    const { readOnly = false, showTitle = true, showContainer = true } = props
    const classes = useStyles()
    const { editing } = props

    const defaultMessageFlow = smsContentData ? smsContentData.messageFlow : (editing != null ? editing.messageflow : '')
    const { value: messageFlow, onChange: setMessageFlow, validValue: validMessageFlow } = useFormInput(
        defaultMessageFlow,
        (value) => value.length >= 41
    )

    const defaultMessageOne = smsContentData ? smsContentData.messageOne : (editing != null ? editing.samplesms1 : '')
    const { value: messageOne, onChange: onMessageOneChange, validValue: validMessageOne } = useFormInput(
        defaultMessageOne,
        (value) => value.length >= 20
    )

    const defaultMessageTwo = smsContentData ? smsContentData.messageTwo : (editing != null ? editing.samplesms2 : '')
    const { value: messageTwo, onChange: setMessageTwo, validValue: validMessageTwo } = useFormInput(
        defaultMessageTwo,
        (value) => value.length === 0 || value.length >= 20
    )

    const defaultMessageThree = smsContentData ? smsContentData.messageThree : (editing != null ? editing.samplesms3 : '')
    const { value: messageThree, onChange: setMessageThree, validValue: validMessageThree } = useFormInput(
        defaultMessageThree,
        (value) => value.length === 0 || value.length >= 20
    )

    const [showConfirmModal, setShowConfirmModal] = useState(false)

    const invalid = (!validMessageFlow ||
        !validMessageOne ||
        !validMessageTwo ||
        !validMessageThree)

    const gotoNextStep = () => {
        setShowConfirmModal(false)
        props.update({ completed: true, gotoNext: true, saveData: getSaveData() })
    }

    const onRejectHandler = () => {
        setShowConfirmModal(false)
    }

    const messages = [
        {
            titleID: 'sms_enrollment_message_one_title',
            value: messageOne,
            placeholderID: 'sms_enrollment_message_one_placeHolder',
            onChangeHandler: onMessageOneChange,
            error: !validMessageOne,
            id: 'messageOneTxtArea'
        },
        {
            titleID: 'sms_enrollment_message_two_title',
            value: messageTwo,
            placeholderID: 'sms_enrollment_message_two_placeHolder',
            onChangeHandler: setMessageTwo,
            error: !validMessageTwo,
            id: 'messageTwoTxtArea'
        },
        {
            titleID: 'sms_enrollment_message_three_title',
            value: messageThree,
            placeholderID: 'sms_enrollment_message_three_placeHolder',
            onChangeHandler: setMessageThree,
            error: !validMessageThree,
            id: 'messageThreeTxtArea'
        }
    ]

    const renderMessageSection = ({
        titleID,
        value,
        placeholderID,
        onChangeHandler,
        error,
        id
    }) => (
        <div key={titleID} className={classes.messageContainer}>
            <Typography className={`${classes.noWrapWhitespace} ${classes.smallFontSize}`} variant='h6' remoteConfigID={titleID}></Typography>
            <Textarea
                id={id}
                error={value.length > 0 && error}
                helperText={error ? getValue('sms_enrollment_valid_opt_message_helper_text') : null}
                maxLength={1024}
                value={value}
                className={classes.fullWidthTextArea}
                rowsMin={10}
                placeholder={getValue(placeholderID)}
                onChange={onChangeHandler}
            />
        </div>
    )

    const renderReadonlyMessage = ({
        titleID,
        value
    }) => (
        <div key={titleID}>
            <Typography variant='h6' remoteConfigID={titleID} />
            <Typography variant='subtitle3'>{value}</Typography>
        </div>
    )

    const getSaveData = () => {
        return {
            messageOne,
            messageTwo,
            messageThree,
            messageFlow
        }
    }
    useEffect(() => {
        if (props.saveId) {
            if (props.gotoStepIndex > props.stepIndex) {
                setShowConfirmModal(true)
            } else {
                gotoNextStep()
            }
        }
    }, [props.saveId, props.gotoStepIndex, props.stepIndex])

    useEffect(() => {
        props.update({ disableNextButton: () => invalid })
    }, [invalid])

    return (
        <CampaignEnrollmentStepContainer readOnly={readOnly} showContainer={showContainer} >
            {
                readOnly && showTitle && (
                    <div className={classes.titleContainer}>
                        <Typography id='title' variant='h4' remoteConfigID='sms_enrollment_sample_message' />
                    </div>

                )
            }
            <Alert
                soft
                color={AlertColor.INFO}
                content={<RemoteConfigValue valueId={'sms_enrollment_sample_messages_alert'} />}
                classes={{ root: classes.marginBottom }} />
            <Grid container direction="column" justifyContent="flex-start" classes={{ root: classes.marginBottom }}>
                <Typography className={classes.noWrapWhitespace} variant='h6' remoteConfigID={'sms_enrollment_message_flow'}></Typography>
                <Typography variant='subtitle3' remoteConfigID='sms_enrollment_sample_message_flow_subtitle' />
                {readOnly
                    ? (
                        <Typography variant='subtitle1'>{messageFlow}</Typography>
                    )
                    : (<div className={classes.messageFlowContainer}>
                        <Textarea
                            id='messageFlowTxtArea'
                            error={messageFlow.length > 0 && !validMessageFlow}
                            helperText={!validMessageFlow ? getValue('sms_enrollment_valid_campaign_description_helper_text') : null}
                            value={messageFlow}
                            maxLength={1024}
                            className={classes.fullWidthTextArea}
                            rowsMax={5}
                            rowsMin={5}
                            cols={50}
                            placeholder={getValue('sms_enrollment_message_flow_placeholder')}
                            onChange={setMessageFlow}
                        />
                    </div>)}
            </Grid>
            <Typography variant='h6' remoteConfigID='sms_enrollment_sample_message_heading' />
            <Typography variant='subtitle3' remoteConfigID='sms_enrollment_sample_message_subtitle' classes={{ root: classes.marginBottom }} />
            <div className={readOnly ? classes.columnContainer : classes.container}>
                {messages.map(x => readOnly ? renderReadonlyMessage(x) : renderMessageSection(x))}
            </div>
            <ConfirmModal
                title='Confirmation'
                isShown={showConfirmModal}
                content={(<RemoteConfigValue valueId='sms_enrollment_please_confirm_campaign_registration' />)}
                onConfirm={gotoNextStep}
                onReject={onRejectHandler}
                yesButtonText='Proceed'
            />
        </CampaignEnrollmentStepContainer>
    )
}

CampaignSampleMessageStep.propTypes = {
    passData: PropTypes.object,
    update: PropTypes.func,
    saveId: PropTypes.number,
    readOnly: PropTypes.bool,
    gotoStepIndex: PropTypes.number,
    stepIndex: PropTypes.number,
    editing: PropTypes.object,
    showTitle: PropTypes.bool,
    showContainer: PropTypes.bool
}

export default CampaignSampleMessageStep
