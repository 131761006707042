import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './teammates-styles'
import LoaderFull from 'loader-full'
import TextField from 'text-field-mui'
import api from '../../util/api_v5'
import { addTeammates/* , switchTeammate */ } from '../../actions/users'
// import { switchView } from '../../actions/people'
import InfiniteScroller from 'pdc-infinite-scroller'
import Spinner from 'spinner'
import { withStyles } from '@material-ui/core'
import gtmDataPush from 'gtm-events'
import PropTypes from 'prop-types'

const GTM_APP_NAME = 'people;teammate-selector'
const GTM_MAP = {
    TEAMMATE_SELECT: 1,
    SAME_TEAMMATE_SELECT: 0
}

const mapStateToProps = state => ({
    teammates: state.teammates
    // currentTeammate: state.teammates.items.find(t => t.selected)
    // currentTeammateId:
})

const mapDispatchToProps = dispatch => ({
    addTeammates: teammates => dispatch(addTeammates(teammates))
    // switchTeammate: teammateId => dispatch(switchTeammate(teammateId)),
    // switchView: view => dispatch(switchView(view))
})

class TeammateSelector extends Component {
    constructor (props) {
        super(props)
        const areTeammatesLoaded = this.props?.teammates?.items?.length || !this.props.teammates?.hasMore
        this.state = {
            searchValue: '',
            loaded: areTeammatesLoaded
        }
        this.loadTeammates(true)
    }

    loadTeammates = async isInitial => {
        let { teammates } = this.props
        if (teammates && teammates.items.length && isInitial) return setTimeout(() => this.setState({ loaded: true }), 1)
        const limit = 20
        const offset = teammates.items.length
        teammates = await api.loadUsers(limit, offset)
        teammates = teammates.filter(t => t.status !== 'new')
        const teammatesRedux = {
            hasMore: teammates.length === limit,
            items: teammates
        }
        this.props.addTeammates(teammatesRedux)
        this.setState({ loaded: true })
    }

    onSearchValueChange = searchValue => this.setState({ searchValue })

    renderSearchField = () => {
        const { classes } = this.props
        return (
            <div className={classes.searchFieldWrapper}>
                <TextField
                    label = 'Search'
                    value = {this.state.searchValue}
                    onChange = {(e) => this.onSearchValueChange(e.target.value)}
                    onXClick = {() => this.onSearchValueChange('')}
                    classNames = {{ root: classes.searchField }}
                />
            </div>
        )
    }

    onTeammateClick = teammate => {
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: 'teammate-click',
            PDC_Value: (!this.props.currentTeammateId || teammate.id !== this.props.currentTeammateId) ? GTM_MAP.TEAMMATE_SELECT : GTM_MAP.SAME_TEAMMATE_SELECT
        })
        this.props.switchTeammate(teammate.id)
        // this.props.switchView('content')
    }

    getFilteredTeammateItems = () => {
        const searchValue = this.state.searchValue
        const teammateItems = this.props.teammates && this.props.teammates.items
            ? this.props.teammates.items.filter(user => {
                const name = `${user.first_name} ${user.last_name}`.toLowerCase()
                const personalPhoneNumber = user.personal_phone_number
                const email = `${user.email}`.toLowerCase()
                return name.includes(searchValue.toLowerCase()) ||
                    personalPhoneNumber.includes(searchValue.toLowerCase()) ||
                    email.includes(searchValue.toLowerCase())
            })
            : []
        return teammateItems
    }

    renderTeammateItems = () => {
        const { classes, teammates, currentTeammateId } = this.props
        const teammateItems = this.getFilteredTeammateItems()
        const formattedItems = []
        let lastLetter = ''
        teammateItems.forEach(t => {
            if (lastLetter !== t.first_name[0].toUpperCase()) {
                lastLetter = t.first_name[0].toUpperCase()
                formattedItems.push({ letterHeader: true, letter: lastLetter })
            }
            formattedItems.push(t)
        })
        return (
            <InfiniteScroller
                reverseScroll = {false}
                loadMore = {this.loadTeammates}
                hasMore = {teammates?.hasMore}
                loader = {<Spinner/>}
                // onScroll = {this.onScrollX}
            >
                {formattedItems.map((t, index) => {
                    const isPending = t.status === 'invite_pending'
                    const selectedClass = t.id === currentTeammateId ? 'selected' : ''
                    return (
                        t.letterHeader
                            ? <div key={`${t.id}-${index}`} className={classes.letterHeader}>{t.letter}</div>
                            : <div key={`${t.id}-${index}`} className={`${classes.teammateItem} ${selectedClass}`} onClick={() => this.onTeammateClick(t)}>
                                <span>{t.first_name} {t.last_name}</span>
                                {isPending ? <span className='teammate-pending'>Has not joined yet</span> : null}
                            </div>
                    )
                })}
            </InfiniteScroller>
        )
    }

    renderLoader = () => {
        const { classes } = this.props
        if (this.state.loaded) return null
        return <div className={classes.loadingDiv}><LoaderFull/></div>
    }

    render () {
        const { classes } = this.props
        return (
            <div className={classes.wrapper}>
                {this.renderSearchField()}
                {this.renderTeammateItems()}
                {this.renderLoader()}
            </div>
        )
    }
}

TeammateSelector.propTypes = {
    classes: PropTypes.object,
    teammates: PropTypes.object,
    currentTeammateId: PropTypes.number,
    switchTeammate: PropTypes.func,
    addTeammates: PropTypes.func
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TeammateSelector))
