<template>
    <w-loader v-if="loading"/>
    <div v-else>
        <w-form @changed="may_submit = true" @submit="submitted" data-discard="true">
            <div v-if="video && !video.created_at">
                {{l.t('video.not-enabled-please', 'Your video conferencing is not enabled. Please fill the form to enable it.')}}
            </div>
            <FormInput :label="l.t('app.domain', 'Domain')">
                <w-text-field v-model="video.domain" :disabled="!!video.created_at" hide-details='auto' data-test-id="video-domain-input"/>
            </FormInput>
            <FormInput :label="l.t('app.business-name', 'Business name')">
                <w-text-field v-model="video.business_name" hide-details='auto' data-test-id="video-business-name-input"/>
            </FormInput>
            <FormInput :label="l.t('video.plan-code', 'Plan code')">
                <w-select
                    v-model="video.plan_code"
                    :items="[
                        {value: 'MEETINGS_BASE', text: 'Base'},
                        {value: 'MEETINGS_PLUS', text: 'Plus'},
                        {value: 'MEETINGS_PRO', text: 'Pro'},
                    ]"
                    hide-details="auto"
                    data-test-id="video-plan-select"
                />
            </FormInput>
            <FormInput>
                <w-btn :disabled="!may_submit" type="submit" class="primary" data-test-id="video-submit-btn">
                    {{ l.t("app.save", "Save") }}
                </w-btn>
            </FormInput>
        </w-form>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import FormInput from '../elements/form/FormInput.vue';

  export default {
    props: ['_video'],
    components: {
      FormInput,
    },
    data() {
      return {
        l,
        loading: false,
        video: null,
        may_submit: false,
      };
    },
    created() {
      this.$data.video = this.$props._video;
    },
    methods: {
      async submitted(e) {
        if (e) e.preventDefault();
        this.$emit('submitted', this.$data.video);
      },
    },
  };
</script>
