/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import App from './src/components/App.js'

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers'

let store = createStore(rootReducer)

class PersonalSettings extends Component {
    /**
     * @param {object} props
     */
    constructor (props) {
        super(props)
        store = createStore(rootReducer)
    }

    /**
     *
     */
    render () {
        return (
            <Provider store={store}>
                <App
                    extension={this.props.extension}
                    standalone={this.props.standalone}
                    onLoaded={this.props.onLoaded}
                    origin={this.props.origin}
                    basePathname={this.props.basePathname}
                    setHasChange={this.props.setHasChange}
                    routeProps={this.props.routeProps}
                />
            </Provider>
        )
    }
}

export default PersonalSettings
