import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { App } from '../utils/objects'

const useStyles = makeStyles(theme => ({
    carouseContainer: {
        '& .slick-prev': {
            left: '3%!important',
            zIndex: 1,
            '&:before': {
                fontSize: '1.5rem',
                color: theme.palette.primary.light,
                opacity: 1
            }
        },
        '& .slick-next': {
            right: '3%!important',
            zIndex: 1,
            '&:before': {
                fontSize: '1.5rem',
                color: theme.palette.primary.light,
                opacity: 1
            }
        },
        '& .slick-dots li button:before': {
            color: theme.palette.primary.light
        },
        '& .slick-dots li.slick-active button:before': {
            color: theme.palette.primary.dark
        }
    },
    carouseItemContainer: {
        '& > *': {
            height: '250px!important'
        },
        '& img': {
            width: '100%',
            height: '250px!important',
            objectFit: 'cover',
            objectPosition: 'top left'
        },
        '& video': {
            width: '100%',
            height: '250px!important',
            objectFit: 'fill'
        }
    }
}))

/**
 *
 */
const Carousel = ({ app }: { app: App }): JSX.Element => {
    const classes = useStyles()

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    }
    return (
        <Box className={classes.carouseContainer}>
            <Slider {...settings}>
                {
                    app.details.images?.map((image, index) => (
                        <Box className={classes.carouseItemContainer} key={index} p={2} >
                            <img src={image} alt={`${app.title} UI image`} loading='lazy' />
                        </Box>
                    ))
                }
                {
                    app.details.videos?.map((video, index) => (
                        <Box className={classes.carouseItemContainer} key={index} p={2} >
                            <video src={video} controls preload='metadata' data-testid='videoElement' />
                        </Box>
                    ))
                }
            </Slider>
        </Box>
    )
}

export default Carousel
