export const uploadSteps = {
	ATTACHING:	'attaching',
	UPLOADING:	'uploading',
	UPLOADED:	'uploaded',
	FAILED:		'failed'
}

export const progressThresholds = {
	[uploadSteps.ATTACHING]:	{min: 0, max: 20},
	[uploadSteps.UPLOADING]:	{min: 20, max: 80},
	[uploadSteps.UPLOADED]:		{min: 80, max: 100},
}

// In miliseconds
export const intervalSpeeds = {
	SLOW:	80,
	MEDIUM:	16,
	FAST:	8,
	FASTER:	5
}