<template>
	<div class="justify-md-center d-flex text-center">
		<w-form @submit.prevent="$emit('submitted')">
			<w-btn
				v-if="!file_name"
				rounded
				dark
				@click="handleFileImport"
				class="primary lighten-5 mt-5 mb-5"
				color="primary--text font-weight-600 text--darken-1"
				data-test-id="file-uploader-upload-btn"
			>
			    <v-icon left>
					mdi-cloud-upload
			    </v-icon>  {{ l.t('app.upload-file', 'Upload file') }}
			</w-btn>
			<div v-else class="mt-5 mb-5">
				<div class="w-body-2 info--text text--darken-1">
					<div>{{ file_name }}</div>
					<w-btn @click="remove_file" text color="error">
						<v-icon >$vuetify.icons.trash</v-icon>
						{{ l.t('app.remove', 'Remove') }}
					</w-btn>
				</div>
			</div>
			<p v-if="!uploaded" class="text--text text--lighten-1" data-test-id="file-uploader-no-file-chosen">
				{{ l.t('app.no-file-chosen', 'No file chosen') }}
			</p>
			<p class="text--text text--lighten-1">
			  <slot></slot>
			</p>
			<input
		      ref="uploader"
		      class="d-none"
		      type="file"
		      @change="attached"
			>
			<div v-if="back" class="d-flex justify-space-between">
			<w-btn @click="$emit('back')" data-test-id="file-uploader-back-btn">
				{{ l.t('app.back', 'Back') }}
			</w-btn>
			<w-btn
				:disabled="!uploaded"
				type="submit"
				color="primary"
				class="ml-3"
				data-test-id="file-uploader-save-btn"
			>
				{{ button_text || l.t('app.save', 'Save') }}
			</w-btn>
			</div>
			<w-btn
				v-else
				:disabled="!uploaded"
				type="submit"
				color="primary"
				data-test-id="file-uploader-save-btn"
			>
				{{ button_text || l.t('app.save', 'Save') }}
			</w-btn>
		</w-form>
	</div>
</template>

<script>
import l from '../../../libs/lang';

export default {
	props: ['button_text', 'back'],
	data() {
		return {
			l,
			uploaded: false,
			file_name: null,
		};
	},
	methods: {
		attached(e) {
			this.$data.uploaded = true;
	        if (e.target.files.length) {
	            const file = e.target.files[0];
	            this.$data.file_name = file.name || null;
	        }
			this.$emit('uplaoded', e);
		},
		handleFileImport() {
			this.$data.uploaded = false;
		    this.$refs.uploader.click();
		},
		remove_file() {
			this.$data.uploaded = false;
			this.$data.file_name = null;
		    this.$refs.uploader.value = null;
			this.$emit('removed');
		}
	},
	watch: {
		uploaded(val) {
			if (!val) this.$data.file_name = null;
		},
	}
};
</script>
