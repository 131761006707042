import l from '../lang';

export default {
	'routes': {
		'create': 'applications.create',
		'show': 'applications.show',
		'index': 'applications.index',
	},
	'delete-single': l.t('applications.delete-single', 'Are you sure you want to delete this application?'),
	'delete-filtered': l.t('applications.delete-filtered', 'Are you sure you want to delete all filtered applications?'),
	'delete-all': l.t('applications.delete-all', 'Are you sure you want to delete all applications?'),
	'successfull-creation': l.t('applications.successfully-created', 'Your application has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('applications.failed-creation', 'There was a problem creating your application'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
};
