export function updateVoicemails(voicemails) {
	return {
		type: 'UPDATE_VOICEMAILS',
		voicemails
	}
}

export function updateVoicemail(voicemail) {
	return {
		type: 'UPDATE_VOICEMAIL',
		voicemail
	}
}

export function expandVoicemail(voicemailId) {
	return {
		type: 'EXPAND_VOICEMAIL',
		voicemailId
	}
}

export function deleteVoicemail(voicemail) {
	return {
		type: 'DELETE_VOICEMAIL',
		voicemail
	}
}

export function addVoicemail(voicemail) {
	return {
		type: 'ADD_VOICEMAIL',
		voicemail
	}
}

export function switchVoicemail(voicemail) {
	return {
		type: 'SWITCH_VOICEMAIL',
		voicemail
	}
}

export function addContactsToVoicemails(contacts) {
	return {
		type: 'ADD_CONTACTS_TO_VOICEMAILS',
		contacts
	}
}

export function removeContactFromVoicemails(contactId) {
	return {
		type: 'REMOVE_CONTACT_FROM_VOICEMAILS',
		contactId
	}
}