/* eslint-disable no-tabs, indent, react/prop-types */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateVoicemail } from '../../actions/settings'
import BackButton from 'back-button'
import AudioPlayer from 'audio-player'
import LoadingBar from 'loading-bar'
import LoaderFull from 'loader-full'
import Recorder from 'react-mp3-recorder'
import { MediaModalXBtn } from 'pdc-svg-icons'
import { DefaultArrowTooltip } from 'tooltips'
import api from '../../util/api_v5'

import TextFieldsIcon from '@material-ui/icons/TextFields'
import PublishIcon from '@material-ui/icons/Publish'
import MicIcon from '@material-ui/icons/Mic'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { withStyles } from '@material-ui/core'
import { ScreenSizeContext } from 'providers'

const styles = theme => ({
	header:	theme.settingsApp.header,
	settingWrapper:	theme.settingsApp.settingWrapper,
	webButton: theme.webButton,
	loadingDiv:	theme.loadingDiv,
	container: {
		borderRadius: 6,
		width: 540,
		'&.small-view': {
			width: '100%',
			'& .tab': {
				padding: 10,
				'&:not(.selected)': {
					flex: 'unset',
					padding: '10px 20px'
				}
			}
		}
	},
	voicemailHeader: {
		display: 'flex',
		border: '1px solid lightgray',
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		'&.round-corners': {
			borderRadius: 6,
			'& .tab:first-child': {
				borderBottomLeftRadius: 6
			},
			'& .tab:last-child': {
				borderBottomRightRadius: 6
			}
		},
		'& .tab': {
			flex: 1,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			textAlign: 'center',
			padding: '16px 24px',
			margin: 0,
			fontSize: 18,
			color: 'black',
			cursor:	'pointer',
			'&.selected': {
				color: 'white',
				background:	'#1140A1'
			},
			'&:first-child': {
				borderTopLeftRadius: 6
			},
			'&:last-child': {
				borderTopRightRadius: 6
			},
			'&:not(:last-child)': {
				borderRight: '1px solid lightgray'
			}
		}
	},
	ttsContent: {
		border:	'1px solid lightgray',
		borderTop: 'none',
		borderBottomLeftRadius:	6,
		borderBottomRightRadius: 6,
		'& textarea': {
			padding: 10,
			border:	'none',
			fontSize: 20,
			width: '100%',
			minHeight: 125,
			maxHeight: 300,
			outline: 'none'
		}
	},
	audioPlayerWrapper: {
		padding: '20px 10px'
	},
	separator: {
		border: 'none',
		borderTop: '1px dashed lightgray',
		margin:	'0 10px'
	},
	languageOptions: {
		display: 'flex',
		padding: 10,
		'& > div': {
			flex: 1,
			fontSize: 18,
			fontWeight:	500,
			'&:first-child': {
				borderRight: '1px dashed lightgray'
			}
		}
	},
	autocomplete: {
		display: 'flex',
		alignItems:	'center',
		'&.lang-autocomplete': {
			paddingRight: 10
		},
		'&.voice-autocomplete': {
			paddingLeft: 10
		}
	},
	audioPlayerContainer: {
		width: '100%',
		padding: '20px 10px',
		borderTop: '1px solid lightgray'
	},
	customFileContent: {
		marginTop: 20,
		border:	'1px solid lightgray',
		borderRadius: 6
	},
	fileSection: {},
	selectButtonWrapper: {
		padding: 15,
		display: 'flex',
		justifyContent:	'center',
		alignItems:	'center'
	},
	selectFileButton: {
		padding: 10,
		border:	'1px solid #1140A1',
		borderRadius:	8,
		margin:	0,
		color: '#1140A1',
		fontSize: 16,
		cursor:	'pointer',
		'&:hover': {
			color: 'white',
			background:	'#1140A1'
		}
	},
	uploadedFileInfo: {
		padding: 10,
		display: 'flex',
		justifyContent:	'space-between',
		'& .filename': {
			fontWeight:	'bold'
		}
	},
	infoSection: {
		borderTop: '1px solid lightgray',
		padding: '8px 10px',
		color: 'gray',
		display: 'flex',
		alignItems:	'center'
	},
	infoIcon: {
		marginRight: 5
	},
	autocompleteInputParent: {
		'&:before, &:after': {
			border: 'none !important'
		},
		'& .MuiIconButton-root': {
			padding: 0
		}
	},
	autocompleteInput: {
		padding: 0
	},
	recordAudioContent: {
		padding: '20px 10px',
		border:	'1px solid lightgray',
		borderBottomLeftRadius:	6,
		borderBottomRightRadius: 6,
		borderTop: 'none',
		display: 'flex',
		alignItems: 'center'
	},
	recordAudioInfoMessage: {
		fontStyle: 'italic',
		color: 'gray',
		width: '100%',
		textAlign: 'center',
		padding: '10px 0'
	},
	recordSection: {
		flex: 1,
		display: 'flex',
		alignItems:	'center'
	},
	recorderWrapper: {
		borderRadius: 50,
		width: 'fit-content',
		marginRight: 10,
		'& > div': {
			borderRadius: 50,
			'& > div': {
				width: 47,
				height:	47
			}
		}
	},
	removeButton: {
		cursor:	'pointer',
		marginLeft:	10
	}
})

const mapStateToProps = state => ({
    voicemail: state.voicemail
})

const mapDispatchToProps = dispatch => ({
    updateVoicemail: voicemail => dispatch(updateVoicemail(voicemail))
})

const voices = ['Salli', 'Joanna', 'Joey', 'Matthew']
const languages = ['English']

class Voicemail extends Component {
	state = {
		selectedTab: 'tts',
		ttsText: '',
		ttsVoice: voices[0],
		language: languages[0],
		uploadedAudio: null,
		readyToPlay: false,
		loading: true,
		recording: { isRecording: false },
		removeBtnHover: false
	}

	static contextType = ScreenSizeContext

	componentDidMount = async () => {
		this.init()
	}

	init = async () => {
		this.setState({
			selectedTab: 'tts',
			ttsText: '',
			ttsVoice: voices[0],
			language: languages[0],
			uploadedAudio: null,
			readyToPlay: false,
			loading: true,
			recording: { isRecording: false },
			removeBtnHover: false
		})

		const textArea = document.getElementById('voicemail-textarea')
		if (textArea) textArea.focus()
		let voicemailConfig	= this.props.voicemail
		if (!voicemailConfig) voicemailConfig = await api.getVoicemailConfig()
		const voipRecording = voicemailConfig.voip_recording
		if (!voipRecording) return this.setState({ loading: false })
		const voipRecordingId	= voipRecording.voip_recording_id
		let selectedTab = 'tts'
		let voicemailLink = voicemailConfig.download_link
		if (!voicemailLink) voicemailLink = (await api.getMusicOnHoldLink(voipRecordingId)).download_link
		const updatedVoicemail = {
			download_link: voicemailLink,
			voip_recording_id:	voipRecordingId,
			type: 'file',
			filename: voipRecording.name,
			voip_recording:	voipRecording
		}
		if (voipRecording.tts_text) {
			updatedVoicemail.type = 'tts'
			this.setState({ ttsText: voipRecording.tts_text, ttsVoice: voipRecording.tts_voice })
		} else if (this.isRecordedAudio(voipRecording)) {
			selectedTab = 'record'
			const recording = this.state.recording
			recording.recordedAudio = JSON.parse(JSON.stringify(updatedVoicemail))
			this.setState({ recording })
		} else {
			selectedTab = 'upload'
			this.setState({ uploadedAudio: JSON.parse(JSON.stringify(updatedVoicemail)) })
		}
		this.props.updateVoicemail(updatedVoicemail)
		this.setState({ selectedTab, loading: false })
	}

	isRecordedAudio = voipRecording => {
		const name = voipRecording.name || voipRecording.filename
		if (!name) return false
		const nameSplit = name.split(' ')
		if (name.length !== 23 || nameSplit.length !== 2) return false
		if (nameSplit[0] !== 'recording') return false
		const timestamp = nameSplit[1]
		for (let c = 0; c < timestamp.length; c++) {
			if (isNaN(parseInt(timestamp[c]))) return false
		}
		return true
	}

	onVoicemailMessageChange = e => this.setState({ ttsText: e.target.value })

	isSaveEnabled = () => {
		const ttsText = this.state.ttsText
		const language = this.state.language
		const ttsVoice = this.state.ttsVoice
		const voicemail = this.props.voicemail
		const selectedTab = this.state.selectedTab
		const uploadedAudio = this.state.uploadedAudio
		const recording = this.state.recording
		if (selectedTab === 'tts') {
			return Boolean(language && ttsText && ttsVoice && (
				!voicemail ||
				voicemail.type !== 'tts' ||
				ttsText !== voicemail.voip_recording.tts_text ||
				ttsVoice !== voicemail.voip_recording.tts_voice
			))
		}
		if (selectedTab === 'upload') {
			return Boolean(uploadedAudio && (
				!voicemail ||
				voicemail.type !== 'file' ||
				voicemail.voip_recording_id !== uploadedAudio.voip_recording_id ||
				voicemail.download_link !== uploadedAudio.download_link
			))
		}
		if (selectedTab === 'record') {
			return Boolean(recording.recordedAudio && recording.start && recording.end)
		}
	}

	onAudioUploaded = e => {
		e.persist()
		if (!e.target.value) return
		const filename = e.target.value.split('\\').pop()
		const inputElement = document.getElementById('upload-file')
		const reader = new FileReader()
		reader.onload = async () => {
			const result = reader.result
			const base64Data = result.split(';')[1].split(',')[1]
			const uploadedAudio = { download_link: result, filename, base64Data }
			this.setState({ uploadedAudio })
		}
		reader.readAsDataURL(inputElement.files[0])
		this.setState({ readyToPlay: false })
	}

	onSave = async () => {
		if (!this.isSaveEnabled()) return
		this.setState({ loading: true })
		let voip_recording_id = null
		let newVoicemail = null
		if (this.state.selectedTab === 'tts') {
			const { ttsText, ttsVoice, name } = this.state
			const response = await api.createTTSGreeting(ttsText, ttsVoice, name)
			voip_recording_id = response.voip_recording_id
		} else if (this.state.selectedTab === 'upload') {
			const uploadedAudio = this.state.uploadedAudio
			const response = await api.createFileGreeting(uploadedAudio.filename, uploadedAudio.base64Data)
			delete uploadedAudio.base64Data
			uploadedAudio.voip_recording_id	= response.voip_recording_id
			this.setState({ uploadedAudio })
			voip_recording_id = uploadedAudio.voip_recording_id
			newVoicemail = {
				download_link: uploadedAudio.download_link,
				type: 'file',
				voip_recording: { voip_recording_id, filename: uploadedAudio.filename },
				voip_recording_id
			}
		} else {
			const recordedAudio = this.state.recording.recordedAudio
			const download_link = recordedAudio.download_link
			const filename = recordedAudio.filename
			const base64Data = download_link.split(';')[1].split(',')[1]
			const response = await api.createFileGreeting(filename, base64Data)
			voip_recording_id = response.voip_recording_id
			this.removeRecordedAudio()
			newVoicemail = {
				download_link,
				type: 'file',
				voip_recording: { voip_recording_id, filename },
				voip_recording_id
			}
		}
		this.props.updateVoicemail(newVoicemail)
		const configureVoicemailResponse = await api.configureVoicemail(voip_recording_id) // eslint-disable-line no-unused-vars
		this.init()
	}

	renderTtsContent = () => {
		const { classes } = this.props
		return (
			<div className={classes.ttsContent}>
				<textarea
					id			= 'voicemail-textarea'
					value		= {this.state.ttsText}
					onChange	= {this.onVoicemailMessageChange}
				/>
				<hr className={classes.separator}/>
				<div className={classes.languageOptions}>
					<Autocomplete
						id				= 'language-autocomplete'
						options			= {languages}
						value			= {this.state.language}
						getOptionLabel	= {option => option}
						classes			= {{ root: `${classes.autocomplete} lang-autocomplete` }}
						onChange		= {(e, language) => this.setState({ language })}
						renderInput		= {params => (
							<TextField
								{...params}
								fullWidth
								InputProps = {{
									...params.InputProps,
									placeholder: 'Language',
									classes: {
										root: classes.autocompleteInputParent,
										focused: classes.autocompleteInputParent,
										input: classes.autocompleteInput
									}
								}}
							/>
						)}
					/>
					<Autocomplete
						id = 'ttsVoice-autocomplete'
						options = {voices}
						value = {this.state.ttsVoice}
						getOptionLabel = {option => option}
						classes = {{ root: `${classes.autocomplete} voice-autocomplete` }}
						onChange = {(e, ttsVoice) => this.setState({ ttsVoice })}
						renderInput = {params => (
							<TextField
								{...params}
								fullWidth
								InputProps = {{
									...params.InputProps,
									placeholder: 'Speaker',
									classes: {
										root: classes.autocompleteInputParent,
										focused: classes.autocompleteInputParent,
										input: classes.autocompleteInput
									}
								}}
							/>
						)}
					/>
				</div>
				{this.props.voicemail && this.props.voicemail.type === 'tts' ? this.renderAudioPlayer(this.props.voicemail) : null}
				<div className={classes.infoSection}>
					<InfoOutlinedIcon classes={{ root: classes.infoIcon }}/>At this time we only support English TTS voices
				</div>
			</div>
		)
	}

	renderAudioPlayer = voicemail => {
		const { classes } = this.props
		return <div className={classes.audioPlayerContainer}>
			<div style={{ display: this.state.readyToPlay ? 'block' : 'none' }}>
				<AudioPlayer
					key = {voicemail.voip_recording_id}
					url = {voicemail.download_link}
					onPlay = {() => {}} // eslint-disable-line @typescript-eslint/no-empty-function
					onReadyToPlay = {() => this.setState({ readyToPlay: true })}
				/>
			</div>
			{!this.state.readyToPlay ? <LoadingBar/> : null}
		</div>
	}

	renderCustomFileContent = () => {
		const { classes } = this.props
		let filename = ''
		const uploadedAudio = this.state.uploadedAudio
		if (uploadedAudio) {
			filename = uploadedAudio.filename
			if (!filename && uploadedAudio.voip_recording) filename = uploadedAudio.voip_recording.filename
		}
		return (
			<div className={classes.customFileContent}>
				<div className={classes.fileSection}>
					<div className={classes.selectButtonWrapper}>
						<label className={classes.selectFileButton} htmlFor='upload-file'>SELECT A FILE</label>
						<input
							type		= 'file'
							id			= 'upload-file'
							style		= {{ display: 'none' }}
							accept		= '.mp3,audio/*'
							onChange	= {this.onAudioUploaded}
						/>
					</div>
					{this.state.uploadedAudio
					? <>
							<hr className={classes.separator}/>
							<div className={classes.uploadedFileInfo}>
								<span>filename</span>
								<span className='filename'>{filename}</span>
							</div>
						</>
					: null}
					{this.state.uploadedAudio ? this.renderAudioPlayer(this.state.uploadedAudio) : null}
				</div>
				<div className={classes.infoSection}>
					<InfoOutlinedIcon classes={{ root: classes.infoIcon }}/>
					<span>
						Be sure you have you have permission from the rightful owner before uploading copyrighted audio.
						See our <a rel='noreferrer' href='https://www.phone.com/customer-terms/' target='_blank'>Terms of Service</a> for more info.
					</span>
				</div>
			</div>
		)
	}

	getRecordingDuration = () => {
		const start = this.state.recording.start / 1000
		const end	= (this.state.recording.end || (new Date()).getTime()) / 1000
		return (end - start).toFixed(2)
	}

	startRecording = () => {
		this.setState({
			recording: {
				isRecording: true,
				start: (new Date()).getTime(),
				end: 0
			},
			readyToPlay: false
		})
		this.recordingInterval = setInterval(() => {
			const recording = this.state.recording
			recording.currentDuration = this.getRecordingDuration()
			this.setState(recording)
		}, 25)
	}

	stopRecording = () => {
		clearInterval(this.recordingInterval)
		delete this.recordingInterval
		const recording = this.state.recording
		recording.isRecording = false
		recording.end = (new Date()).getTime()
		this.setState(recording)
	}

	onRecordingComplete = blob => {
		const reader = new FileReader()
		const filename = `recording ${(new Date()).getTime()}`
		reader.onload = async () => {
			const result = reader.result
			const recordedAudio = { download_link: result, filename }
			const recording = this.state.recording
			recording.recordedAudio	= recordedAudio
			this.setState({ recording })
		}
		reader.readAsDataURL(blob)
	}

	removeRecordedAudio = () => this.setState({ recording: { isRecording: false } })

	renderRecordAudioContent = () => {
		const { classes } = this.props
		const recordingDuration = this.state.recording.currentDuration
		return (
			<div className={classes.recordAudioContent}>
				{!this.state.recording.recordedAudio
				? <div className={classes.recordSection}>
						<div
							className	= {classes.recorderWrapper}
							onMouseDown = {this.startRecording}
							onMouseUp	= {this.stopRecording}
						>
							<Recorder
								onRecordingComplete	= {this.onRecordingComplete}
								onRecordingError	= {error => { /* console.error('Error ima:', error) */ }}
							/>
						</div>
						{this.state.recording.isRecording
						? <span>Recording {recordingDuration}s</span>
						: <span className={classes.recordAudioInfoMessage}>Press and hold the button to record audio</span>}
					</div>
				: <>
						<div style={{ display: this.state.readyToPlay ? 'block' : 'none', width: '100%' }}>
							<AudioPlayer
								url				= {this.state.recording.recordedAudio.download_link}
								onError			= {this.removeRecordedAudio}
								onReadyToPlay	= {() => this.setState({ readyToPlay: true })}
							/>
						</div>
						{!this.state.readyToPlay
						? <LoadingBar/>
						: <DefaultArrowTooltip
								title			= 'Remove'
								placement		= 'right'
								leaveDelay		= {1}
								enterTouchDelay	= {0}
							>
								<div
									className		= {classes.removeButton}
									onMouseEnter	= {() => this.setState({ removeBtnHover: true })}
									onMouseLeave	= {() => this.setState({ removeBtnHover: false })}
									onClick			= {this.removeRecordedAudio}
								>
									<MediaModalXBtn
										style			= {{ width: 20, height: 20 }}
										defaultcolor	= '#919191'
										hover			= {this.state.removeBtnHover ? 1 : 0}
									/>
								</div>
							</DefaultArrowTooltip>
						}
					</>
				}
			</div>
		)
	}

	render () {
		const { classes } = this.props
		const backButton = this.props.smallView ? <BackButton onClick={this.props.goBack}/> : ''
		const sidebarHidden = this.context.mobile || this.context.tablet

		return (
			<>
				<div className={classes.header}>
					<div>
						{backButton}
						<span className='header-title'>Voicemail</span>
					</div>
					<div
						className	= {`${classes.webButton} ${!this.isSaveEnabled() ? 'not-allowed' : ''}`}
						onClick		= {this.onSave}
					>Save</div>
				</div>
				<div className={`${classes.settingWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
					{this.state.loading
					? <div className={classes.loadingDiv}>
							<LoaderFull styles={{ loaderFull: { left: sidebarHidden ? '50%' : 'calc(50% + 120px)' } }} size='big'/>
						</div>
					:						<div className={`${classes.container} ${this.props.smallView ? 'small-view' : ''}`}>
							<div className={`${classes.voicemailHeader} ${this.state.selectedTab === 'upload' ? 'round-corners' : ''}`}>
								<div
									className	= {`tab ${this.state.selectedTab === 'tts' ? 'selected' : ''}`}
									onClick		= {() => this.setState({ selectedTab: 'tts' })}
								>
									{this.props.smallView && this.state.selectedTab !== 'tts' ? <TextFieldsIcon/> : 'Text To Speech'}
								</div>
								<div
									className	= {`tab ${this.state.selectedTab === 'upload' ? 'selected' : ''}`}
									onClick		= {() => this.setState({ selectedTab: 'upload' })}
								>
									{this.props.smallView && this.state.selectedTab !== 'upload' ? <PublishIcon/> : 'Custom File'}
								</div>
								<div
									className	= {`tab ${this.state.selectedTab === 'record' ? 'selected' : ''}`}
									onClick		= {() => this.setState({ selectedTab: 'record' })}
								>
									{this.props.smallView && this.state.selectedTab !== 'record' ? <MicIcon/> : 'Record audio'}
								</div>
							</div>
							{this.state.selectedTab === 'tts'
							? this.renderTtsContent()
							: this.state.selectedTab === 'upload'
							? this.renderCustomFileContent()
							: this.state.selectedTab === 'record'
							? this.renderRecordAudioContent()
							: null}
						</div>
					}
				</div>
			</>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Voicemail))
