import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	checkmark:{
		borderBottom:	`4em solid ${theme.palette.primary.main}`,
		borderLeft:		`4em solid ${theme.palette.primary.main}`,
		width:			'16em',
		height:			'10em',
		transform:		'rotate(315deg)',
		marginTop:		'3em',
		transition:		'0.2s opacity, 0.2s border'
	}
})

class Checkmark extends Component {
	render() {
		let { classes, color } = this.props
		let weight = this.props.weight || 4
		let style = {
			borderLeftWidth:	`${weight}em`,
			borderBottomWidth:	`${weight}em`
		}
		if (color) style.borderColor = color
		let className = this.props.className || ''
		return <div style={style} className={`${classes.checkmark} ${className}`}></div>
	}
}

export default withStyles(styles)(Checkmark)