import React from 'react'
import { Grid } from '@material-ui/core'
import Typography from 'typography'
import { Textarea } from 'textarea-mui'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    fullWidthTextArea: {
        width: '100%',
        resize: 'none',
        border: '1px solid lightgrey',
        borderRadius: '0.25rem'
    },
    topMarginSpacing: {
        marginTop: '1rem'
    },
    gridJustifyContent: {
        justifyContent: 'space-between'
    }
}))

/**
 * @param {Object} props
 * @param {Object} props.enrollment
 */
const PreviewCampaignMessageSettings = ({ enrollment }) => {
    const classes = useStyles()
    const messageSettingProps = ['start', 'stop', 'help']
    const remoteConfigMapper = {
        start: { keyword: 'sms_enrollment_opt_in_keywords', message: 'sms_enrollment_opt_in_message' },
        stop: { keyword: 'sms_enrollment_opt_out_keywords', message: 'sms_enrollment_opt_out_message' },
        help: { keyword: 'sms_enrollment_help_keywords', message: 'sms_enrollment_help_message' }
    }

    return <Grid
        className={classes.gridJustifyContent}
        container
        direction='column'
        spacing={1}>
        {messageSettingProps.map((x, index) => (
            <>
                <Typography variant='body1' className={classes.topMarginSpacing} remoteConfigID={remoteConfigMapper[x].keyword} />
                <Textarea className={classes.fullWidthTextArea} value={enrollment.keywords[x].Campaignkeyword} readOnly={true} />

                <Typography variant='body1' className={classes.topMarginSpacing} remoteConfigID={remoteConfigMapper[x].message} />
                <Textarea className={classes.fullWidthTextArea} value={enrollment.keywords[x].CampaignkeywordValue} readOnly={true} />
            </>
        ))}
    </Grid>
}

PreviewCampaignMessageSettings.propTypes = {
    enrollment: PropTypes.object
}
export default PreviewCampaignMessageSettings
