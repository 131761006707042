import l from '../lang';

export default {
	'routes': {
		'create': 'routes.create',
		'show': 'routes.show',
		'index': 'routes.index',
	},
	'delete-single': l.t('routes.delete-single', 'Are you sure you want to delete this route?'),
	'delete-filtered': l.t('routes.delete-filtered', 'Are you sure you want to delete all filtered routes?'),
	'delete-all': l.t('routes.delete-all', 'Are you sure you want to delete all routes?'),
	'successfull-creation': l.t('routes.successfully-created', 'Your route has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('routes.failed-creation', 'There was a problem creating your route'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
	'currently-in-use': l.t('routes.this-route-is-currently-in-use', 'This route is currently in use'),
	'to-delete-used': l.t('routes.to-delete-this-route-remove-it', 'To delete this route, remove it from your call handling rules first, then try again.'),
};
