<template>
	<div>
		<v-menu
			v-model="menu"
			:close-on-content-click="false"
			:nudge-width="200"
			offset-y
			top
		>
			<template v-slot:activator="{ on, attrs }">
				<w-btn
				  v-bind="attrs"
				  v-on="on"
				  text
				  :ripple="false"
				  class="pa-0 text--text text--lighten-1 font-weight-500"
				>
				  <v-icon small>$vuetify.icons.dots</v-icon>
				  <span class="ml-1">{{ l.t('app.preset', 'Preset') }}</span>
				</w-btn>
			</template>

			<v-card>
				<div class="preset-switcher-list-item" @click="on_show_apply_preset">
					{{ l.t('app.apply-preset', 'Apply a preset') }}
				</div>
				<div class="preset-switcher-list-item" @click="on_show_create_preset">
					{{ l.t('app.create-preset', 'Create a preset') }}
				</div>
			</v-card>
		</v-menu>
		
		<DefaultModal
			v-model="show_apply_preset_modal"
			:width="dialog_width"
			:with_x="false"
		>
			<template v-slot:title>
				{{ l.t('app.apply-preset', 'Apply a preset') }}
			</template>
			<div class="w-body-2 text--text mb-3">{{ l.t('menus.please-select-preset', 'Please select a preset you want to apply.') }}</div>
			<APIAutocomplete
			  v-model="preset"
			  :return_object="true"
			  mode="presets"
			  key="presets"
			  data-test-id="preset-modal-selector"
			/>
			<template v-slot:buttons>
				<w-btn color="secondary" @click="show_apply_preset_modal=null">
					{{ l.t('app.cancel', 'Cancel') }}
				</w-btn>
				<w-btn @click="$emit('apply', preset)" color="primary" class="ml-3">
					{{ l.t('app.apply', 'Apply') }}
				</w-btn>
			</template>
		</DefaultModal>
		<DefaultModal
		    v-model="show_create_preset_modal"
		    :width="dialog_width"
			:with_x="false"
		>
			<template v-slot:title>
				{{ l.t('app.create-preset', 'Create a preset') }}
			</template>
			<div class="w-body-2 text--text">{{ l.t('menus.please-select-preset-name', 'Please select a name for your preset.') }}</div>
			<w-text-field
			  v-model="preset"
			  hide-details="auto"
			  data-test-id="preset-modal-name-input"
			></w-text-field>
			<template v-slot:buttons>
				<w-btn color="secondary" @click="show_create_preset_modal=null">
					{{ l.t('app.cancel', 'Cancel') }}
				</w-btn>
				<w-btn @click="create_preset" color="primary" class="ml-3">
					{{ l.t('app.create', 'Create') }}
				</w-btn>
			</template>
		</DefaultModal>
	</div>
</template>

<script>
import l from '../../libs/lang';
import APIAutocomplete from './form/APIAutocomplete.vue';
import DefaultModal from './modal/DefaultModal.vue'

export default {
	components: { APIAutocomplete, DefaultModal },
	data() {
		return {
			l,
			menu: false,
			show_apply_preset_modal: false,
			show_create_preset_modal: false,
			applying_preset: false,
			preset: null,
			dialog_width: 450,
		};
	},
	methods: {
		create_preset() {
			if (this.$data.preset) this.$emit('create', this.$data.preset);
			this.$data.show_create_preset_modal = false;
		},
		apply_preset() {
			if (this.$data.preset) this.$emit('apply', this.$data.preset);
			this.$data.show_apply_preset_modal = false;
		},
		on_show_apply_preset() {
			this.$data.show_apply_preset_modal = true;
			this.$global_emitter.$emit('form_input_changed');
		},
		on_show_create_preset() {
			this.$data.show_create_preset_modal = true;
			this.$global_emitter.$emit('form_input_changed');
		}
	},
	watch: {
	    show_apply_preset_modal(val) {
	        if (!val) {
	            this.$data.preset = null;
	        }
	    },
	    show_create_preset_modal(val) {
	        if (!val) {
	            this.$data.preset = null;
	        }
	    },
	},
};
</script>

<style lang="scss" scoped>
	@import 'vue-apps-assets/styles/scss/vars.scss';

	.preset-switcher-list-item {
		background: $frame-background;
		height:  40px;
		padding: 10px 15px;
		font-size:  12px;
		cursor:  pointer;
		color:  var(--v-text-base);
	}
	.preset-switcher-list-item:hover {
		background:  var(--v-primary-lighten5);
	}
</style>
