import React, { useState, useEffect } from 'react'
import FeatureFlag from 'feature-flag'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import AppsContextProvider, { useAppsContext } from '../contexts/AppsContext'
import { App } from '../utils/objects'
import { isObjectEmpty } from '../utils/utils'
import Header from './components/Header'
import Content from './components/Content'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: (props: { appLoaded:boolean }) => props.appLoaded ? 0 : '-500px',
        right: 0,
        backgroundColor: '#fff',
        maxWidth: '100%',
        zIndex: 1200,
        transition: 'bottom 1s ease-out 30s',
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.12)',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '410px',
            right: '2rem'
        },
        [theme.breakpoints.up('xl')]: {
            maxWidth: '550px',
            right: '3rem'
        },
        '& *': {
            fontFamily: 'Inter, Montserrat, sans-serif !important'
        }
    }
}))

interface PopupInterface {
    app: string,
    appLoaded: boolean
}

/**
 *
 */
export const Popup = ({ app: appTitle, appLoaded }: PopupInterface): JSX.Element => {
    const [showPopup, setShowPopup] = useState(true)
    const [app, setApp] = useState<App|Record<string, any>>({})
    const classes = useStyles({ appLoaded })
    const appsContext = useAppsContext()

    const closePopup = () => setShowPopup(false)

    useEffect(() => {
        if (!appsContext.isLoadingApps) {
            const apps = appsContext.apps
            const app = apps.find(app => app.title.toLowerCase() === appTitle.toLowerCase()) || {}
            setApp(app)
        }
    }, [appsContext.isLoadingApps])

    const renderPopup = () => {
        if (isObjectEmpty(app)) {
            return <></>
        }
        return (
            <Box className={classes.root} data-testid='popupRoot'>
                <Header closePopup={closePopup} />
                <Content app={app as App} closePopup={closePopup} />
            </Box>
        )
    }

    return (
        !appsContext.isLoadingApps && showPopup
            ? renderPopup()
            : <></>
    )
}

/**
 *
 */
const Index = (props: PopupInterface): JSX.Element => {
    return (
        <FeatureFlag flag='apps_marketplace_enabled'>
            <AppsContextProvider>
                <Popup {...props} />
            </AppsContextProvider>
        </FeatureFlag>
    )
}

export default Index
