/* eslint-disable jsdoc/require-param-type */
/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import formatPhoneNumber from '../../util/phone_number_format'
import PhoneComUser from '../../util/PhoneComUser'
import CustomEvents from '../../util/customEvents'
import DisplayName from 'display-name'

// This is meant to be extended when more features roll out
class ClickableContact extends Component {
    /**
     * @param props
     */
    constructor (props) {
        super(props)
        this.number = this.props.num
    }

    call = () => {
        const features = PhoneComUser.getFeatures()
        if (features && typeof (features.includes) === 'function' && features.includes('messages_unstable')) {
            const call = window.confirm(`Call ${this.number}?`)
            if (call) {
                CustomEvents.trigger('call', this.number)
            }
        }
    }

    /**
     * @param props
     */
    // eslint-disable-next-line react/no-deprecated
    componentWillReceiveProps (props) {
        this.number = props.num
    }

    /**
     *
     */
    render () {
        const number = formatPhoneNumber(this.props.number || this.number)
        const name = this.props.name
        return (
            <span className='clickable-contact-label fs-block' onDoubleClick={this.call}>
                <DisplayName value={name || number}/>
            </span>
        )
    }
}

export default ClickableContact
