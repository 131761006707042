<template>
    <w-loader v-if="schedule.loading"/>
    <div v-else class="show-page" data-test-id="schedules-show-page">
      <PageTitle class="nowrap">
        {{title()}}
      </PageTitle>
        <w-alert
          v-if="schedule.alert"
          :message="schedule.alert.message"
          :level="schedule.alert.level"
          :closable="schedule.enable_close_alert"
          @closed="schedule.alert = null"
          clas="mb-6"
        />
        <ScheduleFragment
          v-if="schedule.item"
          :_schedule="schedule.item"
          :readonly="readonly"
          @submited="schedule.update($event)"
        />
    </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import Schedule from '../../../models/Schedule';
  import ScheduleFragment from '../../fragments/Schedule.vue';
  import PageTitle from '../../elements/PageTitle.vue';

  export default {
    props: ['id', '_schedule'],
    components: {
      ScheduleFragment,
      PageTitle,
    },
    data() {
      return {
        l,
        schedule: new Schedule(this.$session, vueComponent(this)),
        last_title: '',
        readonly: false,
      };
    },
    async created() {
        if (this.$props._schedule || this.$route.params._schedule) {
          this.$data.schedule.item = this.$props._schedule || this.$route.params._schedule;
        } else {
          await this.$data.schedule.getItem(this.$props.id ? this.$props.id : this.$route.params.id);
        }
        this.$data.readonly = this.$data.schedule.item.generator && this.$data.schedule.item.generator === 'configure-app';
    },
    methods: {
      title() {
        let title = '';
        if (this.$data.schedule.item) {
          title += l.t('app.schedule', 'Schedule:');
          title += ` ${this.$data.schedule.item.name ? this.$data.schedule.item.name : ''}`;
          this.$data.last_title = title;
        } else {
          title = this.$data.last_title;
        }
        return title;
      },
    }
  };
</script>
