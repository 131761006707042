import { Styles } from '@material-ui/core/styles/withStyles'
type StylesType = Styles<any, Record<string, unknown>>

/**
 * @param {object} theme
 */
const styles: StylesType = theme => ({
    callerInfo: {
        backgroundColor: 'transparent',
        justifyContent: 'center',
        margin: 'auto',
        '& > .contact-info': {
            display: 'flex',
            flexDirection: 'row'
        },
        '& .participant-info': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: 180,
            '& > p': {
                color: 'white',
                width: '100%',
                '&:first-child': {
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }
            }
        },
        '&:not(.has-avatar)': {
            '& > .participant-info': {
                rowGap: 5
            }
        },
        '&.shrink': {
            flexDirection: 'row',
            justifyContent: 'center',
            '& > .person-avatar': {
                height: 50,
                width: 50
            },
            '& > .participant-info': {
                alignItems: 'flex-start'
            }
        }
    },
    avatar: {
        height: 50,
        width: 50,
        '& > img': {
            pointerEvents: 'none'
        },
        marginRight: 10
    }
})

export default styles
