<template>
  <div v-if="errors && errors.length">
      <w-alert
				:message="l.t('contact.csv-igored-lines', 'Irregular lines are found in your csv. Lines with errors will be ignored.')"
				level="warning"
				:closable="false"
				data-test-id="csv-errors-title"
      />
      <div class="my-3">
          <p v-for="(e, i) in errors" :data-test-id="`csv-errors-${i}`"> - {{ e }}</p>
      </div>
  </div>
</template>

<script>
	import l from '../../libs/lang';

	export default {
	    props: ['errors'],
	    data() {
	      return {
			l,
	      };
	    },
	};
</script>
