import React, { Component } from 'react'
import HeaderTabs from 'header-tabs'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { setSmallView, switchView } from '../actions/view'
import { switchExtension } from '../actions/pdcuser'
import { PhonesTabIcon, BillingIcon, AddPhonesTabIcon } from 'pdc-svg-icons'
import ConfirmModal from 'confirm-modal'
import ResizeAware from 'react-resize-aware'
import { Switch, Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import api from './util/api_phones'
import { getValue } from 'remote-config-value'
import LoaderFull from 'loader-full'
import { theme } from 'get-theme'
import { OrdersIndex, OrdersShow, OrdersCreate } from 'orders-react-components'
import { getPhoneCom } from 'phonecom'

import MyPhones from './MyPhones/MyPhones'

const styles = () => ({
    appWrapper: {
        flex: 1,
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        width: '100%',
        position: 'relative'
    },
    appContent: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        padding: '0px min(65px, 5%) 0px',
        '&.small': {
            padding: '30px min(25px, 5%) 0px'
        },
        '&.tablet': {
            padding: '30px min(50px, 5%) 30px'
        }
    },
    addPhones: {
        minHeight: '100vh !important',
        height: 'unset !important'
    }
})

const mapStateToProps = state => ({
    smallView: state.smallView,
    currentView: state.currentView,
    currentExtensionRedux: state.currentExtension
})

const mapDispatchToProps = dispatch => ({
    setSmallView: boolVal => dispatch(setSmallView(boolVal)),
    switchView: view => dispatch(switchView(view)),
    switchExtension: extension => dispatch(switchExtension(extension))
})

class App extends Component {
    constructor (props) {
        super(props)
        this.state = {
            badges: { 'user-phones': this.props.incompleteE911Count },
            isBusy: false,
            tabs: [
                { id: 'add-phones', icon: AddPhonesTabIcon, default: true },
                { id: 'my-orders', icon: BillingIcon, default: false }
            ],
            loading: true,
            moreServicesTabLink: getValue('more_services_tab_link'),
            order_id: null,
            currentTab: null
        }
        this.fixRoutePath()
    }

    componentDidMount = async () => {
        const phoneCom = await getPhoneCom()
        const { role } = phoneCom
        const type = phoneCom.user_tiered ? 'nxt' : 'classic'
        if (type === 'nxt' && role === 'account') {
            const userTab = { id: 'user-phones', icon: PhonesTabIcon, default: true }
            this.setState({
                tabs: [
                    userTab,
                    ...this.state.tabs.map((x) => {
                        x.default = false
                        return x
                    })
                ]
            })
            this.setState({ currentTab: userTab.id })
            this.onTabSwitch(userTab.id)
            this.updateRouterHistory(userTab.id)
        } else {
            this.setState({ currentTab: this.getCurrentTabFromRoute() })
        }

        let phones = []
        await api.loadPhones(1, 0).then((phonesResponse) => {
            if (phonesResponse?.error) {
                console.warn('Something went wrong while fetching phones')
                this.setState({ loading: false })
            } else {
                phones = phonesResponse?.data || []
                if (phones?.length === 0) {
                    const new_default_tab = 'add-phones'
                    this.setState({
                        tabs: this.state.tabs.map((x) => {
                            x.default = x.id === new_default_tab
                            return x
                        })
                    })
                    this.onTabSwitch(new_default_tab)
                }
                this.setState({ loading: false })
            }
        })
    }

    componentDidUpdate = (prevProps) => {
        this.fixRoutePath()
        if (prevProps.incompleteE911Count !== this.props.incompleteE911Count) {
            const newBadges = this.state.badges
            newBadges['user-phones'] = this.props.incompleteE911Count
            this.setState({ badges: { ...newBadges } })
        }
    }

    getCurrentTabFromRoute = () => {
        const pathSplit = this.props.routeProps.location.pathname.split('/phones/')
        const afterPhones = pathSplit.pop()
        const tabName = afterPhones.split('/').filter(e => e)[0]
        if (!tabName) return
        if (!this.state.tabs.map(t => t.id).includes(tabName)) return
        return tabName
    }

    fixRoutePath = () => {
        const currentTab = this.getCurrentTabFromRoute()
        if (!currentTab) {
            const currentTab = this.state.tabs.find(t => t.default).id
            this.updateRouterHistory(currentTab)
        }
    }

    onTabSwitch = newTab => {
        if (this.state.currentTab === newTab) return
        if (this.state.isBusy) {
            this.setState({ exitAttempt: newTab })
            return false
        }
        this.setState({ currentTab: newTab })
        this.updateRouterHistory(newTab)
    }

    updateRouterHistory = path => {
        this.props.routeProps.history.push(`${this.props.routeProps.match.path}${path}`)
    }

    renderApp = () => {
        const defaultTabId = this.state.tabs.find(t => t.default).id
        const basePath = this.props.routeProps.match.path
        return (
            <Switch>
                <Route path={`${basePath}user-phones`} render={props => (
                    <MyPhones
                        routeProps = {props}
                        incompleteE911Count = {this.props.incompleteE911Count}
                        onTabSwitch={this.onTabSwitch}
                    />
                )}/>

                <Route path={`${basePath}add-phones`} render={(props) => (<OrdersCreate />)}/>
                <Route path={`${basePath}my-orders`} render={this.renderOrders} key={this.id}/>
                <Route path='/' render={() => <Redirect to={`${basePath}${defaultTabId}`}/>}/>
            </Switch>
        )
    }

    renderOrders = (props) => {
        const goBack = () => {
            this.updateRouterHistory('my-orders')
            this.setState({ order_id: null })
        }
        if (this.state.order_id) {
            return (<OrdersShow
                id={this.state.order_id}
                onClickBack={goBack}
            />)
        }
        return (<OrdersIndex
            onClickLink={this.onClickLink.bind(this)}
        />)
    }

    onClickLink (route) {
        if (route.name === 'devices.my-orders.show' && route.params?.id) {
            this.setState({ order_id: route.params.id })
            this.updateRouterHistory(`my-orders/${route.params.id}`)
        }
    }

    discardChanges = () => {
        const newTab = this.state.exitAttempt
        this.setState({ isBusy: false, exitAttempt: null }, () => this.onTabSwitch(newTab))
    }

    renderDiscardChangesModal = () => {
        return (
            <ConfirmModal
                isShown = {Boolean(this.state.exitAttempt)}
                title = 'Discard changes?'
                content = {null}
                noButtonText = 'Cancel'
                yesButtonText = 'Discard'
                yesButtonColor = 'attention'
                onReject = {() => this.setState({ exitAttempt: null })}
                onConfirm = {this.discardChanges}
                size = 'size2' // size440
            />
        )
    }

    handleResize = () => {
        const needToExpand = this.props.smallView && !this.props.screenViewType.isMobileView
        const needToShrink = !this.props.smallView && this.props.screenViewType.isMobileView

        if (needToShrink) {
            this.props.setSmallView(true)
            // if (this.props.currentConversation) this.props.switchView('content')
        } else if (needToExpand) {
            this.props.setSmallView(false)
        }
    }

    renderLoader = () => {
        const { classes, smallView } = this.props
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull text='Please wait...' color={theme.palette.secondary[0]} styles={!smallView ? { loaderFull: { left: 'calc(50% + 120px - 40px)' } } : {}} size='bigger'/>
            </div>
        )
    }

    render () {
        const { classes } = this.props
        const navigationHeight = this.props.smallView ? '0px' : '0px'
        if (this.state.loading) {
            return this.renderLoader()
        }

        return (
            <div className={`${classes.appWrapper} ${this.state.currentTab === 'add-phones' ? classes.addPhones : ''}`}>
                <ResizeAware
                    className='resize-aware'
                    style={{ height: `calc(100% - ${navigationHeight})` }}
                    onResize={this.handleResize}
                >
                    {this.state.currentTab && <HeaderTabs
                        smallView={this.props.smallView}
                        tabs={this.state.tabs}
                        onTabSwitch={this.onTabSwitch}
                        badges={this.state.badges}
                        incompleteE911Count={this.props.incompleteE911Count}
                        selectedTabId={this.state.currentTab}

                    />}
                    <div className={`${classes.appContent} ${this.props.smallView ? 'small' : ''} ${this.props.screenViewType.isTabletView ? 'tablet' : ''}` }>
                        {this.renderApp()}
                        {this.renderDiscardChangesModal()}
                    </div>
                </ResizeAware>
            </div>
        )
    }
}

App.propTypes = {
    classes: PropTypes.object,
    incompleteE911Count: PropTypes.number,
    hasActiveDevices: PropTypes.bool,
    routeProps: PropTypes.any,
    screenViewType: PropTypes.any,
    setSmallView: PropTypes.func,
    smallView: PropTypes.bool
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))
