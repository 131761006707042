import l from '../lang';

export default {
	'routes': {
		'create': 'phone-numbers.create',
		'show': 'phone-numbers.show',
		'index': 'phone-numbers.index',
		'visualizer': 'phone-numbers.visualizer',
	},
	'delete-single': l.t('phone-numbers.delete-single', 'Are you sure you want to delete this phone number?'),
	'delete-filtered': l.t('phone-numbers.delete-filtered', 'Are you sure you want to delete all filtered phone numbers?'),
	'delete-all': l.t('phone-numbers.delete-all', 'Are you sure you want to delete all phone numbers?'),
	'successfull-creation': l.t('phone-numbers.successfully-created', 'Your phone number has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('phone-numbers.failed-creation', 'There was a problem creating your phone number'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
	'currently-in-use': l.t('phone-numbers.this-phone-number-is-currently-in-use', 'This phone number is currently in use'),
	'to-delete-used': l.t('phone-numbers.to-delete-this-phone-number-remove-it', 'To delete this phone number, remove it from your call handling rules first, then try again.'),
};
