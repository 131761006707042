import l from 'lang'
import CallReport from './CallReport'

/**
 *
 */
export default class Type extends CallReport {
    /**
     *
     * @param {object} session
     * @param {object} component
     */
    constructor (session, component) {
        super(
            session,
            component,
            [
                'count_incoming',
                'count_outgoing',
                'total'
            ],
            'types',
            'date'
        )
        this.headers = {
            name: l.t('app.type', 'Type'),
            count: l.t('app.total', 'Total')
        }
    }

    /**
     *
     * @param {Array} items
     * @returns
     */
    // eslint-disable-next-line class-methods-use-this
    prepare_items (items) {
        const incoming = items.reduce((a, c) => a + c.count_incoming, 0)
        const outgoing = items.reduce((a, c) => a + c.count_outgoing, 0)
        return [
            {
                name: l.t('app.incoming-calls', 'Incoming calls'),
                count: incoming
            },
            {
                name: l.t('app.outgoing-calls', 'Outgoing calls'),
                count: outgoing
            }
        ]
    }
}
