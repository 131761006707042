import { Styles } from '@material-ui/core/styles/withStyles'
type StylesType = Styles<any, Record<string, unknown>>

/**
 * @param {object} theme
 */
const styles: StylesType = theme => ({
    callControlsWrapper: {
        height: '170px',
        width: '240px',
        margin: 'auto auto 30px auto'
    },
    controlButton: {
        borderRadius: 5,
        height: 70,
        width: 70,
        maxHeight: 70,
        maxWidth: 70,
        margin: 7.5,
        backgroundColor: 'transparent',
        '& svg': {
            color: theme.palette.text.primary
        },
        '& span': {
            color: theme.palette.text.secondary
        },
        '& .control-button-wrapper': {
            display: 'flex',
            flexDirection: 'column',
            color: theme.palette.text.secondary,
            padding: '5px 0px',
            '&.onHold': {
                color: theme.palette.error.main,
                '& .control-button-icon': {
                    color: theme.palette.error.main
                }
            },
            '& .control-button-icon': {
                width: 30,
                height: 30,
                margin: 'auto auto 10px auto',
                '&.disabled': {
                    color: theme.palette.text.secondary
                }
            }
        },
        '&:hover': {
            backgroundColor: theme.palette.action.primaryFocus,
            '& .control-button-wrapper, & svg': {
                color: theme.palette.primary.dark
            }
        },
        '&:focus:not(:hover):not(.onHold)': {
            backgroundColor: 'transparent'
        },
        '&:focus': {
            outline: `1px solid ${theme.palette.text.primary}`
        },
        '&.onHold': {
            backgroundColor: theme.palette.action.errorFocus
        },
        '&:disabled': {
            backgroundColor: 'transparent',
            '& .control-button-wrapper, & svg': {
                color: theme.palette.text.disabled
            }
        }
    },
    audioSettings: {
        zIndex: '20',
        position: 'absolute',
        top: 16.5,
        right: 2.5
    },
    audioControlWrapper: {
        position: 'relative'
    },
    audioSettingsAnchor: {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        width: 1,
        height: 1,
        zIndex: -1,
        pointerEvents: 'none'
    }
})

export default styles
