<template>
    <w-form @submit.prevent="$emit('updated', extension)" :readonly="readonly" @changed="may_submit = true" class="mt-3" data-discard="true">
        <FormInput :label="l.t('app.device', 'Device')">
            <w-text-field disabled :value="type(extension)" hide-details='auto' data-test-id="extension-type-device-input"/>
        </FormInput>
        <FormInput :label="l.t('extensions.calling-minutes', 'Calling Minutes')">
            <w-select
                v-model="extension.usage_type"
                :items="[
                    {value: 'limited', text: l.t('extensions.limited', 'Limited')},
                    {value: 'unlimited', text: l.t('extensions.unlimited', 'Unlimited')},
                ]"
                hide-details="auto"
                data-test-id="extension-type-usage-select"
            />
        </FormInput>
        <FormInput>
            <w-btn :disabled="readonly || !may_submit" type="submit" color="primary" data-test-id="extension-type-submit-btn">
                {{ l.t("app.save", "Save") }}
            </w-btn>
        </FormInput>
    </w-form>
</template>

<script>
  import { upperFirstCase } from 'formatters'
  import l from '../../libs/lang';
  import FormInput from '../elements/form/FormInput.vue';

  export default {
    props: ['_extension', 'readonly'],
    components: {
        FormInput,
    },
    created() {
        this.$data.extension = this.$props._extension;
    },
    data() {
      return {
        l,
          extension: null,
        may_submit: false,
      };
    },
    methods: {
        type(ext) {
            if (!ext.device_membership) return l.t('extensions.virtual', 'Virtual');
            const {type} = ext.device_membership.device;
            return upperFirstCase(type)
        },
    },
  };
</script>
