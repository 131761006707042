/*  eslint prefer-spread: "off" */
import CallReport from './CallReport'

/**
 *
 */
export default class Summary extends CallReport {
    /**
     *
     * @param {object} session
     * @param {object} component
     */
    constructor (session, component) {
        super(
            session,
            component,
            [
                'avg_call_duration',
                'max_call_duration',
                'min_amount',
                'avg_amount',
                'max_amount',
                'total_amount',
                'first_time',
                'last_time',
                'count_incoming',
                'count_outgoing',
                'total'
            ],
            'summary',
            'date'
        )
        this.headers = Object.keys(this.fields).reduce((a, c) => {
            a[c] = this.fields[c].translation
            return a
        }, {})
    }

    /**
     *
     * @param {Array} items
     * @returns
     */
    prepare_items (items) {
        const avg_amount = items.filter((x) => x.avg_amount)
        const avg_call_duration = items.filter((x) => x.avg_call_duration)
        const summary = {
            total: items.reduce(
                (a, c) => a + c.count_incoming + c.count_outgoing,
                0
            ),
            count_incoming: items.map((x) => x.count_incoming).reduce((a, c) => a + c, 0),
            count_outgoing: items.map((x) => x.count_outgoing).reduce((a, c) => a + c, 0),
            avg_call_duration: avg_call_duration.length
                ? avg_call_duration.reduce((a, c) => a + c.avg_call_duration, 0) / avg_call_duration.length
                : 0,
            max_call_duration: Math.max.apply(
                Math,
                items.map((x) => x.max_call_duration)
            ),
            first_time: Math.min.apply(Math, items.map((x) => x.first_time)),
            last_time: Math.max.apply(Math, items.map((x) => x.last_time)),
            total_call_duration: items.reduce(
                (a, c) => a + c.max_call_duration + c.min_call_duration,
                0
            ),
            avg_amount: avg_amount.length
                ? avg_amount.reduce((a, c) => a + c, 0) / avg_amount.length
                : 0,
            min_amount: Math.min.apply(
                Math,
                items.map((x) => x.min_amount)
            ),
            max_amount: Math.max.apply(
                Math,
                items.map((x) => x.max_amount)
            ),
            total_amount: items.reduce((a, c) => a + c.max_amount + c.min_amount, 0)
        }
        return this.format_values([summary])
    }

    /**
     *
     * @param {Array} items
     * @returns
     */
    format_values (items) {
        for (const item of items) {
            for (const key of Object.keys(item)) {
                if (this.fields[key]) {
                    item[key] = this.formatters[this.fields[key].type](
                        item[key]
                    )
                } else {
                    delete item[key]
                }
            }
        }

        return items
    }

    /**
     *
     * @param {Array} items
     * @returns
     */
    // eslint-disable-next-line class-methods-use-this
    prepare_csv_values (items) {
        if (items.length) {
            for (const item of items) {
                // eslint-disable-next-line array-callback-return
                for (const x of ['min_amount', 'avg_amount', 'max_amount', 'total_amount']) {
                    item[x] = item[x] || 0
                }
            }
        }

        return items
    }
}
