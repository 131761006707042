/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import styles from './contact-styles'
import LoaderFull from 'loader-full'
import HeaderBar from 'header-bar'
import ContactEditor from 'contact-editor'
import ConfirmModal from 'confirm-modal'
import Button from 'button'
import { NoContactsPersonIcon } from 'pdc-svg-icons'
import { EditIcon, TrashIcon } from 'pdc-svg-icons'
import { DefaultArrowTooltip } from 'tooltips'
import { PdcCallConsumer } from 'pdc-calls'
import { withStyles } from '@material-ui/core'
import gtmDataPush from 'gtm-events'
import api from 'api'

const GTM_APP_NAME = 'people;contact-content'

const GTM_MAP = { DISCARD_CHANGES: 1, REJECT_DISCARD: 0, DELETE_CONTACT: 1, CANCEL_DELETE: 0 }

class ContactContent extends Component {
    state = {
        discardChangesType: null,
        reinitializeContactEditor: false,
        deleteContactId: null,
        isEditing: false,
        editMode: false,
        updating: false,
        currentContact: null
    }

    componentDidMount = () => {
        this.loadContact()
    }

    componentDidUpdate = prevProps => {
        console.log('people content', 'trying update')
        if (prevProps.currentContactId !== this.props.currentContactId) {
            this.loadContact()
            this.setState({ editMode: false })
            console.log('people content', ' updated')
            if (this.props.currentContactId === 'create-new-contact') {
                this.setState({ currentContact: null })
            }
        }
    }

    onDeleteContactClick = (contact) => this.setState({ deleteContactId: contact.id })

    onGoToSelectClick = () => {
        if (this.state.isEditing) return this.setState({ discardChangesType: 'goto-select' })
        // this.props.switchView('select')
        this.props.switchContact()
    }

    renderHeader = () => {
        // TODO: this has to do a lookup on each render. even tho it may not have changed.
        // needs contact in state for sharing links -  pdc.com/contact/contactId url's. without fetching here.
        const { classes, currentContactId } = this.props
        const { currentContact } = this.state
        if (!currentContactId) return <HeaderBar />
        const contactName = currentContact ? currentContact.name.display : ''
        return (
            <HeaderBar returnFnc={this.onGoToSelectClick} smallView={this.props.smallView}>
                <div className='name-section'>{contactName}</div>
                {currentContact
                    ? (
                        <div className={classes.actionsSection}>
                            <DefaultArrowTooltip title='Edit contact' placement='bottom'>
                                <div
                                    className={classes.actionIconWrapper}
                                    onClick={() => {
                                        gtmDataPush({ PDC_Action: GTM_APP_NAME, PDC_Label: 'edit-contact-click' })
                                        this.setState({ editMode: true })
                                    }}
                                >
                                    <EditIcon className='edit-icon' />
                                </div>
                            </DefaultArrowTooltip>
                            <DefaultArrowTooltip title='Delete contact' placement='bottom'>
                                <div
                                    className={classes.actionIconWrapper}
                                    onClick={() => {
                                        gtmDataPush({ PDC_Action: GTM_APP_NAME, PDC_Label: 'delete-contact-click' })
                                        this.onDeleteContactClick(currentContact)
                                    }}
                                >
                                    <TrashIcon className='delete-icon' />
                                </div>
                            </DefaultArrowTooltip>
                        </div>
                    )
                    : null}
            </HeaderBar>
        )
    }

    cancelEdit = (hasChange) => {
        if (hasChange) return this.setState({ discardChangesType: 'cancel' })
        this.setState({ editMode: false })
        if (this.props.currentContactId === 'create-new-contact') {
            this.props.switchContact()
            // this.props.switchView('select')
        }
    }

    loadContact = async () => {
        const { currentContactId, contactsUtil } = this.props
        if (!currentContactId) return this.setState({ currentContact: null })
        if (!contactsUtil) return
        if (!contactsUtil.contacts) return

        let currentContact =
            contactsUtil.contacts.items.find((i) => i.id === currentContactId) ||
            contactsUtil.extraContacts.find((c) => c.id === currentContactId)

        if (currentContact) return this.setState({ currentContact })

        try {
            this.setState({ loading: true })
            const contact = await api.loadContact(currentContactId)
            if (!contact) this.setState({ contactIdError: true })
            currentContact = contact
            this.setState({ currentContact })
        } catch (error) {
            console.log(error)
        }
        this.setState({ loading: false })
    }

    saveContact = (contact) => {
        const { contactsUtil } = this.props
        contactsUtil.updateContact(contact)
        if (contact.group && contact.group.name) contactsUtil.addGroup({ ...contact.group })
        this.setState({ editContact: null })
    }

    renderNoContactsView = () => {
        const { classes } = this.props
        return (
            <div className={classes.noContactsView}>
                <NoContactsPersonIcon className='nc-person-icon' />
                <div className='nc-right-side'>
                    <span className='nc-text'>You haven’t added any contacts yet</span>
                    <Button variant='outlined' icon='add' onClick={this.props.onAddContactClick}>
                        Add a contact
                    </Button>
                </div>
            </div>
        )
    }

    renderNoContactSelectedView = () => <div className={this.props.classes.noSelectionView}>No contact selected.</div>

    onSave = (contactId) => {
        this.props.switchContact(contactId)
        this.setState({ editMode: false, discardChangesType: null })
        this.props.setEditing(false)
    }

    setEditing = (isEditing) => {
        this.setState({ isEditing })
        this.props.setEditing(isEditing)
    }

    renderContactDetails = () => {
        const { classes, contactsUtil, smallView, currentContactId } = this.props
        const { currentContact } = this.state
        // if (!currentContactId) return this.renderNoContactSelectedView()
        if (contactsUtil && contactsUtil.contactsLoaded && contactsUtil.contacts.items.length === 0 && currentContactId !== 'create-new-contact') {
            return this.renderNoContactsView()
        }

        const isCreatingNew = currentContactId === 'create-new-contact'
        if (!currentContact && !isCreatingNew) return this.renderNoContactSelectedView()

        const isEditing = this.state.editMode || isCreatingNew
        const editModeClass = isEditing ? 'edit-mode' : ''
        const smallViewClass = smallView ? 'small-view' : ''
        return (
            <div className={`${classes.contactDetailsSection} ${editModeClass} ${smallViewClass}`}>
                <div className="contact-editor-wrapper">
                    <PdcCallConsumer>
                        {(context) => (
                            <ContactEditor
                                contactGroupTypes={contactsUtil.groupTypes}
                                contact={currentContact}
                                reinitialize={this.state.reinitializeContactEditor}
                                editMode={isEditing}
                                saveContact={this.saveContact}
                                onCancel={this.cancelEdit}
                                onSave={this.onSave}
                                setEditing={this.setEditing}
                                addGroup={contactsUtil.addGroup}
                                updateGroupId={contactsUtil.updateGroupId}
                                makeCall={this.props.makeCall}
                                startConversation={this.props.startConversation}
                                isVirtualExtension={this.props.isVirtualExtension}
                                smallView={smallView}
                                canPlaceOutgoingCall={context.canPlaceOutgoingCall}
                                didConnectProcessed={context.didConnectProcessed}
                                origin="people;contact-content"
                                updateContact={this.props.updateContact}
                                deleteContact={this.props.deleteContact}
                            />
                        )}
                    </PdcCallConsumer>
                </div>
            </div>
        )
    }

    discardChanges = () => {
        const discardChangesType = this.state.discardChangesType
        this.setState(
            {
                discardChangesType: null,
                editMode: false,
                reinitializeContactEditor: true
            },
            () => this.setState({ reinitializeContactEditor: false })
        )
        if (this.props.currentContactId === 'create-new-contact' || discardChangesType === 'goto-select') {
            this.props.switchContact()
            // this.props.switchView('select')
        }
        this.props.setEditing(false)
        if (discardChangesType === 'goto-select') this.props.switchView('select')
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: `discard-changes;${discardChangesType}`,
            PDC_Value: GTM_MAP.DISCARD_CHANGES
        })
    }

    renderDiscardChangesModal = () => {
        const onReject = () => {
            gtmDataPush({
                PDC_Action: GTM_APP_NAME,
                PDC_Label: `discard-changes;${this.state.discardChangesType}`,
                PDC_Value: GTM_MAP.REJECT_DISCARD
            })
            this.setState({ discardChangesType: null })
        }
        return (
            <ConfirmModal
                title='Discard changes?'
                isShown={Boolean(this.state.discardChangesType)}
                content='Do you wish to continue? All changes will be lost. '
                noButtonText='Cancel'
                yesButtonText='Discard'
                yesButtonColor='attention'
                onReject={onReject}
                onConfirm={this.discardChanges}
                size='size550'
            />
        )
    }

    onDelete = async () => {
        gtmDataPush({ PDC_Action: GTM_APP_NAME, PDC_Label: 'delete-contact', PDC_Value: GTM_MAP.DELETE_CONTACT })
        const contactId = this.state.deleteContactId
        this.setState({ deleteContactId: null, updating: true })
        await this.props.deleteContact(contactId)
        // await Api.deleteContact(contactId)
        if (this.props.currentContactId === contactId) this.props.switchContact()
        this.setState({ updating: false })
        this.cancelEdit()
        this.props.contactsUtil.deleteContact(contactId)
    }

    renderConfirmDeleteModal = () => {
        return (
            <ConfirmModal
                title='Delete contact? This action cannot be undone.'
                isShown={Boolean(this.state.deleteContactId)}
                content={null}
                noButtonText='Cancel'
                yesButtonText='Delete Contact'
                yesButtonColor='attention'
                onReject={() => {
                    gtmDataPush({
                        PDC_Action: GTM_APP_NAME,
                        PDC_Label: 'delete-contact',
                        PDC_Value: GTM_MAP.CANCEL_DELETE
                    })
                    this.setState({ deleteContactId: null })
                }}
                onConfirm={this.onDelete}
                size='size550'
            />
        )
    }

    renderLoader = () => {
        const { classes } = this.props
        if (!this.state.updating) return null
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull />
            </div>
        )
    }

    render = () => {
        const { classes } = this.props
        return (
            <div className={classes.wrapper}>
                {this.renderLoader()}
                {this.renderHeader()}
                {this.renderContactDetails()}
                {this.renderDiscardChangesModal()}
                {this.renderConfirmDeleteModal()}
            </div>
        )
    }
}

export default withStyles(styles)(ContactContent)
