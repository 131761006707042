// NOTE: This whole file is coppied from voicemails and replaced 'voicemail(s)' with 'call(s)'. Each functio should be reviewed when worked on it.

/**
 * @param {object} state
 * @param {object} action
 */
export function calls (state = {
    filters: [],
    items: [],
    limit: 25,
    offset: 0,
    sort: { start_time: 'desc' },
    total: 0
}, action) {
    let calls = state
    let call = null
    let callItems = null
    let callIndex, index, recordings, contactId
    switch (action.type) {
            case 'UPDATE_CALLS':
                calls = action.calls
                return { ...calls }
            case 'UPDATE_CALL':
                call = action.call
                callIndex = calls.items.findIndex(v => v.id === call.id)
                if (callIndex >= 0) calls.items[callIndex] = call
                else {
                    index = calls.items.findIndex(c => c.start_time < call.start_time)
                    if (index !== -1) calls.items.splice(index, 0, call)
                    else {
                        calls.items.push(call)
                        calls.total++
                    }
                }
                return { ...calls }
            case 'DELETE_CALL':
                calls.total--
                if (action.call.is_new) calls.unread--
                calls.items = calls.items.filter(v => v.id !== action.call.id)
                return { ...calls }
            case 'ADD_CALL':
                call = action.call
                calls.total++
                if (call.is_new) calls.unread++
                calls.items.unshift(call)
                return { ...calls }
            case 'ADD_RECORDINGS':
                calls.items.forEach(c => {
                    delete c.recording.loading
                    delete c.voicemail.loading
                })
                recordings = action.recordings
                recordings.recordings.forEach(recording => {
                    if (!recording.url) return
                    call = calls.items.find(c => c.recording.id === recording.id)
                    if (!call) return
                    call.recording.url = recording.url
                })
                recordings.voicemails.forEach(voicemail => {
                    if (!voicemail.url) return
                    call = calls.items.find(c => c.voicemail.id === voicemail.id)
                    if (!call) return
                    call.voicemail.url = voicemail.url
                })
                return { ...calls }
            case 'ADD_CONTACTS_TO_CALLS':
                callItems = calls.items
                callItems.forEach(c => {
                    const theOther = c.type === 'outgoing' ? 'to' : 'from'
                    if (c[theOther].contact_id) return
                    const foundContact = action.contacts?.find(cd => cd.numbers?.find(n => n.number === c[theOther].number))
                    if (!foundContact) return
                    c[theOther].contact_id = foundContact.id
                })
                return { ...calls, items: [...callItems] }
            case 'REMOVE_CONTACT_FROM_CALLS':
                contactId = action.contactId
                callItems = calls.items
                callItems.forEach(c => {
                    const direction = c.type === 'outgoing' ? 'to' : 'from'
                    if (c[direction].contact_id === contactId) delete c[direction].contact_id
                })
                return { ...calls, items: [...callItems] }
            default:
                return state
    }
}

/**
 * @param {object} state
 * @param {object} action
 */
export function currentCallerId (state = '', action) {
    switch (action.type) {
            case 'SWITCH_CALLER_ID':
                return { callerId: action.callerId }
    }
}
