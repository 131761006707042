/**
 * @param {object} smallView
 */
export function setSmallView (smallView) {
    return {
        type: 'SET_SMALL_VIEW',
        smallView
    }
}

/**
 * @param {object} view
 */
export function switchView (view) {
    return {
        type: 'SWITCH_VIEW',
        view
    }
}
