export const getEmptyContactObject = () => {
	return {
		name: {
			display: '',
			first: '',
			middle: '',
			last: '',
			nick: ''
		},
		numbers: [],
		emails: [],
		job_title: '',
		organization: '',
		notes: '',
		addresses: {
			home: {
				address: '',
				city: '',
				state: '',
				country: '',
				zip_code: ''
			},
			business: {
				address: '',
				city: '',
				state: '',
				country: '',
				zip_code: ''
			}
		},
		group: {
			name: 'Other',
			id: 0
		}
	}
}