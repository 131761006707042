<template>
  <div class="videos">
    <Submenu
      :routes="{
        'video.index': {
          title: l.t('app.video-conferencing', 'Video conferencing')
          },
        'video.users.index': {
          title: l.t('app.users', 'Users')},
        'video.users.create': {
          title: l.t('video.add-user', 'Add user')
          },
      }"
      :key="`${l.detect()}${$route.name}`"
    />
    <VideoFragment
      v-if="$route.name === 'video.index'"
      data-test-id="video-index"
    />
    <VideoUsersFragment
      v-if="$route.name === 'video.users.index'"
      data-test-id="video-users-index"
    />
    <VideoUserFragment
      v-if="$route.name === 'video.users.create'"
      data-test-id="video-users-create"
    />
  </div>
</template>

<script>
  import l from '../../../libs/lang';
  import VideoFragment from './index.vue';
  import VideoUsersFragment from './users/index.vue';
  import VideoUserFragment from './users/create.vue';
  import Submenu from '../../elements/Submenu.vue';

  export default {
    components: {
      Submenu,
      VideoFragment,
      VideoUserFragment,
      VideoUsersFragment,
    },
    data() {
      return {
        l,
      };
    },
    beforeRouteLeave(to, from, next) {
      if (window.deleting_in_progress) {
        this.$emit('userRedirected', to, from, next);
      } else {
        next();
      }
    },
  };
</script>
