import * as React from 'react';
import { PDFObject } from 'react-pdfobject';
import './PDFEmbedded.css';

export default function PDFEmbedded(props) {
    return (
        <div className='pdf-object-wrapper' style={{height: '100vh'}}>
            <PDFObject url={props.url} />
        </div>
    );
}
