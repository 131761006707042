import React from 'react'
import { Grid } from '@material-ui/core'
import Typography from 'typography'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { EnrollmentType } from '../interface/EnrollmentType'

const styles = makeStyles((theme) => ({
    topMarginSpacing: {
        marginTop: '1rem'
    },
    gridJustifyContent: {
        justifyContent: 'space-between',
        "& [class*='MuiTypography-root']": {
            wordWrap: 'break-word'
        }
    }
}))

/**
 *
 */
const PreviewContactDetails = ({ enrollment, enrollmentType }) => {
    const classes = styles()
    return (
        <Grid
            className={classes.gridJustifyContent}
            container
            justifyContent="end"
            direction='row'
            spacing={1}>
            <Grid direction='column' item xs={12} sm={3}>
                <Typography variant='body1' remoteConfigID={enrollmentType === EnrollmentType.Brand ? 'sms_enrollment_email_address' : 'sms_enrollment_name'} />
                <Typography variant='subtitle1'>{enrollmentType === EnrollmentType.Brand ? enrollment.email : enrollment.contact_name}</Typography>
            </Grid>
            <Grid direction='column' item xs={12} sm={3}>
                <Typography variant='body1' remoteConfigID={ enrollmentType === EnrollmentType.Brand ? 'sms_enrollment_preview_support_phone_number' : 'sms_enrollment_email' } />
                <Typography variant='subtitle1'>{enrollmentType === EnrollmentType.Brand ? enrollment.phone : enrollment.contact_email}</Typography>
            </Grid>
            <Grid direction='column' item xs={12} sm={3}/>
        </Grid>
    )
}
PreviewContactDetails.propTypes = {
    enrollment: PropTypes.object,
    enrollmentType: EnrollmentType
}

export default PreviewContactDetails
