<template>
    <div class="create-page" data-test-id="api-req-create-page">
        <w-loader v-if="api.loading"/>
        <template v-else>
            <w-alert v-if="api.alert" :message="api.alert.message" :level="api.alert.level" :closable="api.enable_close_alert" @closed="api.alert = null" class="mb-6"/>
            <w-form @submit.prevent="api.submit()" data-test-id="api-req-form">
                <FormInput :label="l.t('app.uri', 'Uri')">
                    <div class="d-flex">
                        <w-select
                            v-model="api.item.request_method"
                            @change="api.api_response = null"
                            :items="['GET', 'POST', 'PATCH', 'PUT', 'DELETE']"
                            hide-details="auto"
                            data-test-id="api-req-method-select"
                        />
                        <w-text-field
                            disabled
                            value="api.phone.com/"
                            hide-details='auto'
                            data-test-id="api-req-base-uri-input-disabled"
                        />
                        <w-text-field
                            v-model="api.item.request_uri"
                            autocomplete="off"
                            :style="{width: '100%'}"
                            hide-details='auto'
                            data-test-id="api-req-uri-input"
                        />
                    </div>
                </FormInput>
                <template v-if="!['GET', 'DELETE'].includes(api.item.request_method)">
                    <FormInput :label="l.t('app.body', 'Body')">
                        <w-textarea
                          v-model="api.body_stringified"
                          :rules="[$validation.required()]"
                          auto-grow
                          rows="5"
                          hide-details="auto;"
                          data-test-id="api-req-body-input"
                        ></w-textarea>
                    </FormInput>
                </template>
                <FormInput :label="l.t('api-requests.scheduled-request', 'Scheduled request')">
                    <w-switch
                        v-model="api.scheduled_request"
                        :label="l.t('app.schedule', 'Schedule')"
                        hide-details='auto'
                        data-test-id="api-req-schedule-switch"
                    />
                </FormInput>
                <template v-if="api.scheduled_request">
                    <FormInput :label="l.t('api-requests.scheduled-at', 'Scheduled at')">
                        <DatetimePicker
                            :datetime="api.item.scheduled_at"
                            @input="api.item.scheduled_at = $event"
                            :required="true"
                            time_nudge_top="-250"
                            date_nudge_top="-150"
                            data-test-id="api-req-scheduled-at-input"
                        />
                    </FormInput>
                    <FormInput :label="l.t('app.additional-options', 'Additional options')">
                        <w-switch v-model="api.show_additional_options" :label="l.t('app.enable', 'Enable')" hide-details='auto' data-test-id="api-req-additional-options-switch"/>
                    </FormInput>
                    <template v-if="api.show_additional_options">
                        <FormInput :label="l.t('api-requests.notification-email', 'Notification email')">
                            <w-text-field type="email" v-model="api.item.notification_email" hide-details='auto' data-test-id="api-req-notifications-mail-input"/>
                            <span data-test-id="api-req-notification-mail-help-text">
                                {{ l.t('api-requests.notification-email-explanation', 'If not provided, default account\'s email will be used') }}
                            </span>
                        </FormInput>
                        <FormInput :label="l.t('api-requests.notification-policy', 'Notification policy')">
                            <w-select
                                v-model="api.item.notification_policy"
                                :items="[
                                    { value: 0, text: l.t('api-requests.no-notifications', 'No notifications') },
                                    { value: 1, text: l.t('api-requests.only-failed', 'Only if request failed') },
                                    { value: 2, text: l.t('app.always', 'Always') },
                                ]"
                                hide-details="auto"
                                data-test-id="api-req-notification-policy-select"
                            />
                        </FormInput>
                        <FormInput :label="l.t('app.comment', 'Comment')">
                            <w-textarea
                              v-model="api.item.comment"
                              auto-grow
                              rows="1"
                              hide-details="auto"
                            ></w-textarea>
                        </FormInput>
                    </template>
                </template>
                <FormInput>
                    <w-btn type="submit" color="primary" :disabled="submit_inactive" data-test-id="api-req-submit-btn">
                        {{ l.t('app.submit', 'Submit') }}
                    </w-btn>
                </FormInput>
            </w-form>
            <div v-if="api.api_response">
                <SectionTitle data-test-id="api-req-title">{{ l.t('api-requests.api-response', 'API response') }}</SectionTitle>
                <div v-if="api.api_response.status" class="d-flex">
                    <h6 class="w-body-2">
                        {{ l.t('api-requests.status-code' ,'Status code') }}:
                    </h6>
                    <h6 class="w-body-2 ml-3" :class="{
                            'success--text': (Object.prototype.hasOwnProperty.call(api.api_response, 'status') && api.api_response.status > 199 && api.api_response.status < 300) || api.api_response.success || Object.prototype.hasOwnProperty.call(api.api_response, 'id'),
                            'warning--text': (Object.prototype.hasOwnProperty.call(api.api_response, 'status') && api.api_response.status > 299 && api.api_response.status < 400) || api.api_response.success || Object.prototype.hasOwnProperty.call(api.api_response, 'id'),
                            'error--text': Object.prototype.hasOwnProperty.call(api.api_response, 'status') && api.api_response.status > 399 && api.api_response.status < 600,
                        }"
                        data-test-id="api-req-status-code"
                    >
                        {{ api.api_response.status }}
                    </h6>
                </div>
                <div v-for="(v,h) of api.api_response.headers" class="d-flex">
                    <h6 class="w-body-2">
                        {{ l.t('api-requests.header' ,'Header') }} "{{ h }}":
                    </h6>
                    <h6 class="w-body-2 ml-3" data-test-id="api-req-header">
                        {{ v }}
                    </h6>
                </div>
                <div v-if="api.api_response && Object.keys(api.api_response).length">
                    <h6 class="w-body-2">
                        {{ l.t('api-requests.response-body' ,'Response body') }}:
                    </h6>
                    <br>
                    <code data-test-id="api-req-response"><pre>{{ api.api_response }}</pre></code>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import FormInput from '../../elements/form/FormInput.vue';
  import SectionTitle from '../../elements/SectionTitle.vue';
  import DatetimePicker from '../../elements/form/DatetimePicker.vue';

  export default {
    props: ['api'],
    components: {
      FormInput,
      SectionTitle,
      DatetimePicker,
    },
    computed: {
      'submit_inactive': function () {
        return (['POST', 'PUT', 'DELETE'].includes(this.$props.api.item.request_method) && !this.$props.api.item.request_uri) || (['POST', 'PATCH', 'PUT'].includes(this.$props.api.item.request_method) && !this.$props.api.body) || (this.$props.api.scheduled_request && !this.$props.api.item.scheduled_at);
      }
    },
    data() {
      return {
        l,
      };
    },
  };
</script>
