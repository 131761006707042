const styles = theme => ({
	loadingDiv: theme.loadingDiv,
	wrapper: {
		display:		'flex',
		flexDirection:	'column',
		height:			'100%'
	},
	panelHeader: {
		minHeight:		58,
		boxShadow:		'inset 0px -1px 0px 0px #ccd6db',
		display:		'flex',
		justifyContent:	'space-between',
		alignItems:		'center',
		padding:		'0 50px 0 25px',
		'& .name-section': {
			fontWeight:		500,
			fontSize:		16,
			lineHeight:		'24px',
			letterSpacing:	-0.1
		}
	},
	actionsSection: {
		display:		'flex',
		justifyContent:	'space-between',
		alignItems:		'center',
		'& > *:not(:last-child)': {
			marginRight: 14
		}
	},
	actionIconWrapper: {
		padding:		11,
		borderRadius:	'50%',
		cursor:			'pointer',
		'& .edit-icon, & .delete-icon': {
			color: theme.palette.secondary[0]
		},
		'&:hover': {
			background: theme.palette.secondary[-400],
			'& .edit-icon': {
				color: theme.palette.primary[0]
			},
			'& .delete-icon': {
				color: theme.palette.attention[0]
			}
		}
	},
	contactDetailsSection: {
		height:		'100%',
		padding:	'50px 0',
		overflowY:	'auto',
		'& .contact-editor-wrapper': {
			display:		'flex',
			flexDirection:	'column',
			justifyContent:	'center',
			alignItems:		'center',
			width:			'100%',
			'& > div': {
				width:		'100%',
				maxWidth:	300
			}
		},
		'&.edit-mode .contact-editor-wrapper > div': {
			maxWidth: 500
		},
		'&.small-view': {
			paddingLeft:	40,
			paddingRight:	40
		}
	},
	noContactsView: {
		display:		'flex',
		alignItems:		'center',
		justifyContent:	'center',
		height:			'100%',
		overflowY:		'auto',
		'& .nc-right-side': {
			marginLeft:		-83,
			marginTop:		60,
			display:		'inline-flex',
			flexDirection:	'column',
			alignItems:		'center',
			'& .nc-text': {
				fontFamily:		'Montserrat-bold',
				fontWeight:		700,
				fontSize:		16,
				lineHeight:		'24px',
				letterSpacing:	-0.1,
				marginBottom:	42
			}
		},
		'& .nc-person-icon': {
			width:	260,
			height:	585
		}
	},
	noSelectionView: {
		marginTop:	50,
		textAlign:	'center'
	}
})

export default styles