
/* eslint-disable jsdoc/require-description */
/* eslint-disable jsdoc/require-param-description */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React, { Component } from 'react'
import InfiniteLoader from 'react-window-infinite-loader'
import { VariableSizeList } from 'react-window'
import { Container } from '@material-ui/core/'
import Typography from 'typography'
import CSS from 'csstype'
import CircularProgress from '@material-ui/core/CircularProgress'

const MINBATCHSIZE = 20
const THRESHOLD = 40

interface Props{
    list: (filter: string) => Promise<any>;
    next: (cursor: any) => Promise<any>;
    renderItemLoaded: (index: number, item: any) => JSX.Element;
    renderItemLoading: () => JSX.Element;
    itemSize: (index: any) => number;
    filter: string;
    onChange?: (lenght: number) => void;
    height: number;
}
interface VirtualSelectorState{
    items: any[];
    hasMore: boolean;
    isNextPageLoading: boolean;
    filter: string;
    cursor: any;
    total: number;
    typing: boolean;
}

/**
 *
 */
export default class VirtualSelector extends Component<Props, VirtualSelectorState> {
    unmounted = true
    listRef = null
    /**
     * @param props
     */
    constructor (props: Props) {
        super(props)
        this.state = {
            items: [],
            hasMore: false,
            isNextPageLoading: false,
            filter: '',
            cursor: null,
            total: 0,
            typing: false
        }
    }

    // Every row is loaded except for our loading indicator row.
    /**
     * @param index
     */
    isItemLoaded = (index: number) => !this.state.hasMore || index < this.state.items.length;

    /**
     *
     */
    async componentDidMount () {
        this.unmounted = false
        await this.load()
        await this.setFilter(this.props.filter)
    }

    /**
     *
     */
    componentWillUnmount () {
        this.unmounted = true
    }

    /**
     * @param prevProps
     */
    componentDidUpdate (prevProps: any) {
        if (prevProps && prevProps.filter !== this.props.filter) {
            this.setFilter(this.props.filter)
        }
    }

    sortItems = (items: any[]): any[] => {
        if (!items[0]?.__type) return items
        const typesMap = new Map<string, any[]>()
        items.forEach(item => {
            const type = item.__type
            if (!typesMap.has(type)) typesMap.set(type, [])
            const elements = typesMap.get(type) as any[]
            elements.push(item)
            typesMap.set(type, elements)
        })
        const sortedItems = [] as any[]
        for (const e of typesMap) {
            if (e[1][0]) e[1][0].firstOfType = true
            sortedItems.splice(sortedItems.length, 0, ...e[1])
        }
        return sortedItems
    }

    /**
     *
     */
    loadMore = async () => {
        this.setState({ isNextPageLoading: true })
        const filter = this.state.filter
        this.props.next(this.state.cursor).then(async (res) => {
            if (this.unmounted) {
                return
            }
            if (this.state.filter === filter) {
                // WILL THIS CAUSE A CRASH IF THE COMPONENT HAS BEEN UNMOUNTED????
                let items = this.state.items
                items = items.concat(res.items)
                items = this.sortItems(items)
                await this.setState({ isNextPageLoading: false, items: items, hasMore: res.hasMore, cursor: res.cursor })
                this.onChange(res.items.size)
                if (this.listRef) this.listRef.resetAfterIndex(0, true)
            }
        })
    }

    /**
     *
     */
    load = async () => {
        this.setState({ isNextPageLoading: true })
        this.props.list(this.state.filter).then(async (res) => {
            // WILL THIS CAUSE A CRASH IF THE COMPONENT HAS BEEN UNMOUNTED????
            await this.setState({ isNextPageLoading: false, items: res.items, hasMore: res.hasMore, cursor: res.cursor, total: res.total })
            this.onChange(res.items.size)
            if (this.listRef) this.listRef.resetAfterIndex(0, true)
        })
    }

    reload = this.load

    /**
     * @param filter
     */
    setFilter = async (filter: string) => {
        filter = filter.trim()
        await this.setState({ filter, items: [], hasMore: false, typing: true })
        setTimeout(() => {
            if (filter === this.state.filter) {
                if (this.listRef) this.listRef.scrollTo(0)
                this.load()
                this.setState({ typing: false })
            }
        }, 500)
    }

    onChange = (length: number) => {
        if (this.props.onChange) this.props.onChange(length)
    }

    // Render an item or a loading indicator.
    /**
     * @param root0
     * @param root0.index
     * @param root0.style
     * @param root0.data
     */
    renderItem = ({ data, index, style }: any) => {
        let content
        if (!this.isItemLoaded(index)) {
            content = this.props.renderItemLoading ? this.props.renderItemLoading() : null
        } else {
            content = this.props.renderItemLoaded(index, this.state.items[index])
        }
        return <span style={style}>{content}</span>
    };

    itemSize = (index) => {
        if (index > this.state.items.length) return 1
        const item = this.state.items[index]
        // console.log('itemsize', index, item)
        if (item) return this.props.itemSize(item)
        return 1
    }

    /**
     *
     */
    render () {
        // console.log('contact provider render')
        const itemCount = this.state.hasMore ? this.state.items.length + 1 : this.state.items.length
        const loadMoreItems = this.loadMore
        const listHeight = this.props.height || 245
        const showSpinner = this.state.items.length === 0 && (this.state.typing || this.state.isNextPageLoading)
        const showEmpty = this.state.items.length === 0 && !this.state.isNextPageLoading
        const showList = !(showEmpty || showSpinner)
        const noResultsStyle: CSS.Properties = {
            height: `${listHeight}px`,
            width: '100%',
            textAlign: showSpinner ? 'center' : 'start',
            paddingTop: showSpinner ? '20%' : '10px',
            paddingLeft: '20px',
            display: showEmpty || showSpinner ? 'block' : 'none',
            textTransform: 'uppercase'
        }
        return (<>
            <Container style={noResultsStyle}>
                {showSpinner
                    ? <CircularProgress/>
                    : <Typography variant='overline' remoteConfigID='virtual_selector_nothing_found_text'/>
                }
            </Container>
            <InfiniteLoader
                isItemLoaded={this.isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadMoreItems}
                minimumBatchSize={MINBATCHSIZE}
                threshold={THRESHOLD}
            >
                {({ onItemsRendered, ref }: any) => {
                    return <VariableSizeList
                        itemCount={itemCount}
                        onItemsRendered={onItemsRendered}
                        ref={list => {
                            ref(list)
                            this.listRef = list
                        }}
                        height={listHeight}
                        itemSize={this.itemSize}
                        estimatedItemSize={84}
                        style={showList ? {} : { display: 'none' }}
                    >
                        {this.renderItem}
                    </VariableSizeList>
                }}
            </InfiniteLoader>
        </>)
    }
}
