import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core'
import Button from 'button'
import BrowserDetection from 'react-browser-detection'
import PropTypes from 'prop-types'

const styles = theme => ({
    callIssuesDialogContent: {
        position: 'absolute',
        top: 20,
        left: 110,
        margin: 0,
        paddingTop: 20,
        background: 'transparent',
        boxShadow: 'none',
        '& > *': {
            background: 'white'
        },
        zIndex: 2000
    },
    dialogArrow: {
        position: 'absolute',
        top: 7,
        left: 50,
        width: 35,
        height: 35,
        transform: 'rotate(45deg)',
        zIndex: -1
    },
    dialogConent: {
        borderRadius: 20,
        padding: 15,
        marginLeft: -35
    }
})
const chromeEnableAudioMessage = <> Click the <b>lock</b> icon above, change the microphone permission to {'"allow"'}, and <b>refresh</b> the page.</>
const firefoxEnableAudioMessage = <> Click the <b>lock</b> icon above, then click the <b>{'"X"'}</b> button on the <b>{'"Use the Microphone"'}</b> permission, and <b>refresh</b> the page.</>
const androidEnableAudioMessage = <>Click the <b>lock icon</b> above, then click <b>Permissions</b> change the microphone permission to {'"allow"'}, and <b>refresh</b> the page.</>
const defaultEnableAudioMessage = <>Enable your <b>microphone permission</b> and <b>refresh</b></>
const enableAudioMessage = {
    chrome: (browser) => chromeEnableAudioMessage,
    edge: (browser) => chromeEnableAudioMessage,
    'android-chrome': (browser) => androidEnableAudioMessage,
    firefox: (browser) => firefoxEnableAudioMessage,
    default: (browser) => defaultEnableAudioMessage
}

/**
 *
 * Dialog to show when mic permission is denied, it will check the browser type and give hints on how to fix the mic permission
 *
 * @example```jsx
 * <AudioIssuesDialog
 *     open={true}
 *    onClose={()=>{'some function stuff'}}
 * />
 * ```
 *@public
 */
class AudioIssuesDialog extends Component {
    render () {
        const { classes } = this.props
        return (
            <Dialog
                open={this.props.open}
                classes={{ paper: classes.callIssuesDialogContent }}
                onClose={this.props.onClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <div className={classes.dialogArrow}/>
                <div className={classes.dialogConent}>
                    <DialogTitle id='alert-dialog-title'>Audio Permissions denied</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                        Please enable your microphone from your browser and try again.
                            <br/>
                            <br/>
                            <BrowserDetection>
                                {enableAudioMessage}
                            </BrowserDetection>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose} color='primary'>Close</Button>
                    </DialogActions>
                </div>
            </Dialog>
        )
    }
}
AudioIssuesDialog.propTypes = {
    open: PropTypes.bool.isRequired, // whether or not to have the dialog open or closed
    onClose: PropTypes.func.isRequired, // callback when close is clicked
    classes: PropTypes.object // material ui classes auotmatically passed in
}

export default withStyles(styles)(AudioIssuesDialog)
