<template>
    <div>
      <w-loader v-if="media.loading"/>
      <div>
          <w-alert
            v-if="media.alert"
            :level="media.alert.level"
            :message="media.alert.message"
            :closable="media.enable_close_alert"
            @closed="media.alert = null"
            class="mb-3"
          />
          <section class="">
            <FileUploader
              @submitted="media.import_csv()"
              @uplaoded="uploadCsv($event, 'file')"
              :button_text="l.t('media-loader.import-list-from-csv', 'Import list from .csv file')"
              :back="true"
              @back="media.back_to_main_form()"
              @removed="media.file = null"
              data-test-id="media-loader-import-csv-file-input"
            >
              <p class="help">
                  {{ l.t("media-loader.import-help-text", "Please upload a .csv file that contains list of files.") }}
              </p>
            </FileUploader>
          </section>
      </div>
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import FormInput from '../../elements/form/FormInput.vue';
  import FileUploader from '../../elements/form/FileUploader.vue';

  export default {
    props: ['media'],
    components: {
      FormInput,
      FileUploader,
    },
    data() {
      return {
        l,
        disableSubmit: false,
      };
    },
    created() {
      this.$data.disableSubmit = this.$props.media.file;
    },
    methods: {
      async uploadCsv(e) {
        await this.$props.media.csvFileUploaded(e, 'file');
        this.$data.disableSubmit = e.target.files.length && this.$props.media.possibleCsvMemeTypes.includes(e.target.files[0].type);
        this.$forceUpdate();
      },
    },
  };
</script>
