import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { switchView } from '../../actions/view.js'
import { switchConversation, addConversation, deleteConversations } from '../../actions/conversations.js'
import { detectOS } from 'os-detector'

import PhoneComUser from 'phone-com-user'

import {
    isValidNumber as isValidNumberCustom,
    getNumberType as getNumberTypeCustom,
    format as formatCustom,
    parse as parseCustom
} from 'libphonenumber-js/custom'

import metadata from 'libphonenumber-js/metadata.full.json'

import StartNewPanel from 'start-new-panel'

/**
 * @param {...any} args
 */
export const isValidNumber = (...args) => isValidNumberCustom(...args, metadata)
/**
 * @param {...any} args
 */
export const AsYouType = (...args) => getNumberTypeCustom(...args, metadata)
/**
 * @param {...any} args
 */
export const format = (...args) => formatCustom(...args, metadata)
/**
 * @param {...any} args
 */
export const parse = (...args) => parseCustom(...args, metadata)

const mapStateToProps = state => ({ conversations: state.conversations })
const mapDispatchToProps = dispatch => {
    return {
        switchConversation: conv => dispatch(switchConversation(conv)),
        addConversation: conv => dispatch(addConversation(conv)),
        deleteConversations: convs => dispatch(deleteConversations(convs)),
        switchView: view => dispatch(switchView(view))
    }
}

class NewConversationPanel extends Component {
    componentDidMount () {
        if (detectOS() !== 'iOS') document.getElementById('tags-input').focus()
        if (this.props.appData && this.props.appData.message) this.goToConversation()
    }

    componentDidUpdate (prevProps) {
        if (!this.props.appData) return
        if ((!prevProps.appData && this.props.appData.message) || (prevProps.appData && prevProps.appData.randomString !== this.props.appData.randomString)) {
            this.goToConversation()
        }
    }

    goToConversation = () => {
        const message = this.props.appData.message

        console.log('#### this.props.appData:', this.props.appData)

        const conversationId = message.conversation_id
        const from = message.from
        const to = message.to.map(t => typeof (t) === 'string' ? { number: t } : t)
        this.removeNewlyAddedConversation()
        this.startConversation(from, to, conversationId)
        this.props.appData.onProcessed()
    }

    startConversation = (selectedNumber, recipients, conversationId) => {
        // Find the conversation from props.conversations
        // In shownConversation will be kept the conversation if it is shown in the ConversationSelector
        let shownConversation = conversationId ? this.props.conversations.find(c => c.id === conversationId) : null
        if (!shownConversation && !conversationId) {
            this.props.conversations.forEach(conversation => {
                // If it is found, do not search for it
                if (shownConversation) {
                    return
                }

                const conversationParticipantNumbers = conversation.participants.map(p => p.number)
                const newParticipantsNumbers = Array.from(new Set(recipients.map(r => r.number).concat([selectedNumber])))

                if (conversationParticipantNumbers.length !== newParticipantsNumbers.length) {
                    return
                }

                shownConversation = conversation
                newParticipantsNumbers.forEach(phoneNumber => {
                    if (!conversationParticipantNumbers.includes(phoneNumber)) {
                        shownConversation = null
                    }
                })
            })
        }

        if (!shownConversation) {
            // If it is not found in the shown conversations then:
            // Add conversation in props (it will add it to the ConversationSelector) WITHOUT conversation_id
            const otherRecipients = this._removeMyPhoneNumbersFromRecipients(recipients)
            let myPhoneNumbers = selectedNumber ? [{ number: selectedNumber }] : []
            let recipientsPhoneNumbers = this._extractMyPhoneNumbersFromRecipients(recipients) || []
            recipientsPhoneNumbers = recipientsPhoneNumbers.filter(n => n !== selectedNumber).map(n => { return { number: n } })
            myPhoneNumbers = myPhoneNumbers.concat(recipientsPhoneNumbers)

            const newConversation = {
                checkedMessages: true,
                from: myPhoneNumbers,
                my_nums: myPhoneNumbers,
                id: 'new-conversation', // Will be added later
                participants: otherRecipients.concat(myPhoneNumbers),
                to: otherRecipients,
                not_my_nums: otherRecipients,
                newlyAdded: true,
                last_message: {}
            }
            this.props.addConversation(newConversation)
            this.props.loadExtraContacts()
            shownConversation = newConversation
        }

        this.props.switchView('content')
        // When new conversation is created it doesn't know the conversation id immediately so firstly it will be set to null in the path
        // and once the conversation id is loaded ConversationContent will add it
        this.props.updateRouterHistory(`c${shownConversation.id}`)
        this.props.switchConversation(shownConversation)
    }

    _extractMyPhoneNumbersFromRecipients = recipients => {
        const numbers = recipients.map(r => r.number)
        const allMyPhoneNumbers = PhoneComUser.getPhoneNumber()
        return allMyPhoneNumbers ? allMyPhoneNumbers.filter(n => numbers.includes(n)) : []
    }

    _removeMyPhoneNumbersFromRecipients = recipients => {
        const allMyPhoneNumbers = PhoneComUser.getPhoneNumber()
        const otherRecipients = recipients.filter(r => !allMyPhoneNumbers.includes(r.number))
        return otherRecipients.map(r => {
            return { name: r.nickname, number: r.number }
        })
    }

    onStartClick = (selectedNumber, recipients) => {
        this.removeNewlyAddedConversation()
        this.startConversation(selectedNumber, recipients)
    }

    removeNewlyAddedConversation = () => {
        const newlyAddedConversation = this.props.conversations.find(c => c.newlyAdded)
        if (newlyAddedConversation) {
            this.props.deleteConversations([newlyAddedConversation])
        }
    }

    closeNewConversationPanel = () => {
        this.props.switchView('select')
    }

    render () {
        const phoneNumbers = PhoneComUser.getPhoneNumber()
        const inputNotAllowed = Boolean(phoneNumbers === undefined || phoneNumbers.length === 0)
        return (
            <StartNewPanel
                extension={this.props.extension}
                contactsUtil={this.props.contactsUtil}
                showSendToField={true}
                allowShortCodes={true}
                inputNotAllowed={inputNotAllowed}
                goBackText='New Chat'

                onStartClick={this.onStartClick}
                goBack={this.closeNewConversationPanel}
                origin='new-conversation-panel'
            />
        )
    }
}

NewConversationPanel.propTypes = {
    appData: PropTypes.object,
    conversations: PropTypes.array,
    addConversation: PropTypes.func,
    updateRouterHistory: PropTypes.func,
    loadExtraContacts: PropTypes.func,
    switchView: PropTypes.func,
    switchConversation: PropTypes.func,
    deleteConversations: PropTypes.func,
    extension: PropTypes.object,
    contactsUtil: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(NewConversationPanel)
