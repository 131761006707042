import React from 'react'
import { withStyles } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import RemoteConfigValue from 'remote-config-value'
import { RefreshIcon } from 'svg-icons'
import styles from './styles'

const useStyles = makeStyles(styles)

const reload = (): void => window.location.reload()

/***/
export const OfflineBar = (): JSX.Element => {
    const classes = useStyles()
    return (
        <div className={`${classes.offlineBar}`}>
            <RemoteConfigValue valueId='offline_bar_text'/>
            <span className='refresh' onClick={reload}>Reload page <RefreshIcon/></span>
        </div>
    )
}

export default withStyles(styles)(OfflineBar)
