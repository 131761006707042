<template>
    <div class="blocked-calls">
      <Submenu
        :routes="{
            'blockedCalls.index': {
              title: $lang.t('app.blocked-numbers', 'Blocked numbers')
            },
            'blockedCalls.create': {
              title: $lang.t('blocked-calls.block-numbers', 'Block numbers')
            },
            'blockedCalls.recent': {
              title:$lang.t('blocked-calls.recent-calls', 'Recent calls')
            },
        }"
        :key="`${$route.name}`"
      />
      <BlockedNumbersIndex
        v-if="$route.name === 'blockedCalls.index'"
        data-test-id="bc-index"
      />
      <BlockedNumbersCreate
        v-if="$route.name === 'blockedCalls.create'"
        data-test-id="bc-create"
      />
      <RecentCalls
        v-if="$route.name === 'blockedCalls.recent'"
        data-test-id="bc-recent"
      />
    </div>
</template>

<script>
  import {BlockedNumbersIndex, BlockedNumbersCreate, RecentCalls} from 'blocked-numbers-components';
  import Submenu from '../../elements/Submenu.vue';

  export default {
    components: {
      Submenu,
      RecentCalls,
      BlockedNumbersIndex,
      BlockedNumbersCreate,
    },
    beforeRouteLeave(to, from, next) {
      if (window.deleting_in_progress) {
        this.$emit('userRedirected', to, from, next);
      } else {
        next();
      }
    },
  };
</script>
