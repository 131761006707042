import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_1.01.2?node-id=6024%3A8458
 *
 * @param {object} props - svg props
 */
export const BarGraphIcon = (props: SvgIconProps): JSX.Element => {
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13 4.75C13 3.7835 13.7835 3 14.75 3C15.7165 3 16.5 3.7835 16.5 4.75V19.25C16.5 20.2165 15.7165 21 14.75 21C13.7835 21 13 20.2165 13 19.25V4.75ZM9.25 7C8.2835 7 7.5 7.7835 7.5 8.75V19.25C7.5 20.2165 8.2835 21 9.25 21C10.2165 21 11 20.2165 11 19.25V8.75C11 7.7835 10.2165 7 9.25 7ZM3.75 12C2.7835 12 2 12.7835 2 13.75V19.25C2 20.2165 2.7835 21 3.75 21C4.7165 21 5.5 20.2165 5.5 19.25V13.75C5.5 12.7835 4.7165 12 3.75 12ZM20.25 9C19.2835 9 18.5 9.7835 18.5 10.75V19.25C18.5 20.2165 19.2835 21 20.25 21C21.2165 21 22 20.2165 22 19.25V10.75C22 9.7835 21.2165 9 20.25 9Z" fill="#262626"/>
        </SvgIcon>
    )
}

export default BarGraphIcon
