import React, { useContext, useEffect, ChangeEvent, useState } from 'react'
import { useToggle } from 'hooks'
import { PdcCallContext, SipCallSession } from 'pdc-calls'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import styles from './styles'
import Typography from 'typography'
import Dialpad from '../utils/Dialpad/Dialpad'
import ContactSelector from '../utils/ContactSelector/ContactSelector'
import { SoftphoneHeader } from '../../../Softphone'
import { SoftphoneCallsContext } from '../../SoftphoneCalls'
import { InputFieldHeader } from '../InCall/InCallView'
import { MergeIcon, AddIcon } from 'svg-icons/src'
import SessionsBarSelector from '../../../Phonebar/SessionsBarSelector'
import CallControls from '../utils/CallControls/CallControls'
import { RemoveCharacterButton, SelectorPlaceCallButton, ParseCalleePhoneNumber, ParseUserInput } from '../PlaceCall/PlaceCallView'
import { KeypadView, CallDescription } from '../InCall/InCallView'
import { SessionState } from 'sip.js'
import { FeatureEventsContext } from 'providers/src'

const useStyles = makeStyles(styles)

interface AddCallProps{
    call: (callee: string) => void
}
const AddCallDialpadView = (props: AddCallProps): JSX.Element => {
    const classes = useStyles()
    const [inputValue, setInputValue] = useState('')
    const [callee, setCallee] = useState('')
    const [showDialpad, setShowDialpad] = useToggle(true)
    const Softphone = useContext(SoftphoneCallsContext)
    const featureEventsContext = useContext(FeatureEventsContext)

    const BackToCall = () => Softphone.CloseCallAction()
    const PlaceCall = () => props.call(callee)
    const UpdateInputValue = (update: string) => { setInputValue(ParseUserInput(update)) }
    const onInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const number = event?.target?.value
        UpdateInputValue(number)
    }
    useEffect(() => { setCallee(ParseCalleePhoneNumber(inputValue)) }, [inputValue])
    const onKeyUp = (event) => {
        if (callee.length > 1 && event.keyCode === 13) {
            // Enter key pressed, initiate call
            featureEventsContext.pushEvent({ appName: 'softphone', key: 'add-call-place-call-enter-press' })
            PlaceCall()
        }
    }
    const onKeyDown = (event) => {
        const BACKSPACE = 8
        if (inputValue.length <= 1 && event.keyCode === BACKSPACE) setShowDialpad(true)
        else if (event.key.length === 1) setShowDialpad(false)
    }
    const onRemoveCharClick = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'add-call-remove-char-button-click', value: 'short' })
        UpdateInputValue(inputValue.slice(0, inputValue.length - 1))
    }
    const onRemoveWholeText = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'add-call-remove-char-button-click', value: 'long' })
        UpdateInputValue('')
    }
    const onDialpadClick = (char: string) => UpdateInputValue(inputValue + char)
    const disabledButtons = callee === ''
    return (<Grid container direction='column'>
        <Grid item>
            <SoftphoneHeader title={<Typography variant={'subtitle2'}>ADD CALL</Typography>}>
                <InputFieldHeader
                    onChange={onInputChange}
                    onBack={BackToCall}
                    onKeyDown={onKeyDown}
                    onKeyUp={onKeyUp}
                    value={inputValue}
                />
            </SoftphoneHeader>
        </Grid>
        {showDialpad
            ? <>
                <Grid item>
                    <Dialpad onClick={onDialpadClick}/>
                </Grid>
                <Grid item className={`${classes.addCallOptions} dialpad`}>
                    <div className={classes.addCallButton}>
                        <IconButton
                            onClick = {() => {
                                featureEventsContext.pushEvent({ appName: 'softphone', key: 'add-call-place-call-click' })
                                PlaceCall()
                            }}
                            disabled = {disabledButtons}
                            classes = {{ root: `${classes.addButton} disable-dragging` }}
                        >
                            <AddIcon/>
                        </IconButton>
                        <Typography color={'secondary'} variant={'overline'}>
                            ADD CALL
                        </Typography>
                    </div>
                    <RemoveCharacterButton
                        disabled = {inputValue.length < 1}
                        onClick = {onRemoveCharClick}
                        onLongPress = {onRemoveWholeText}
                    />
                </Grid>
            </>
            : <>
                <Grid item>
                    <SelectorPlaceCallButton placeCall={props.call} callee={callee}/>
                </Grid>
                <Grid item>
                    <ContactSelector onSelect={setCallee} keyword={inputValue} height={317}/>
                </Grid>
            </>}
    </Grid>)
}
interface InTransferProps{
    caller: string
    goBack: () => void
}
function InMergeView (props: InTransferProps) : JSX.Element {
    const classes = useStyles()
    const PdcCalls: any = useContext(PdcCallContext)
    const Softphone = useContext(SoftphoneCallsContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    const [mergeDisabled, setMergeDisabled] = useToggle(true)
    const GetConferenceSessions = () => {
        const session: SipCallSession = PdcCalls.calls[props.caller]
        if (session) {
            const sessions = [session]
            session.participants.forEach(p => {
                const mergedCallId = session.mergedCallIDs[p.phoneNumber]
                const mergedCall = PdcCalls.calls[mergedCallId]
                if (mergedCall) {
                    sessions.push(mergedCall)
                }
            })
            return sessions
        }
        return []
    }
    const [sessions, updateSessions] = useState(GetConferenceSessions())
    const [target, setTarget] = useState('')
    useEffect(() => {
        const callId = PdcCalls.activeCallId
        if (target === '') {
            if (callId) {
                setTarget(callId)
                sessions.push(PdcCalls.calls[callId])
                updateSessions(sessions)
            }
        } else if (!PdcCalls.calls[target] || PdcCalls.calls[target].isMerged) {
            PdcCalls.switchCall(props.caller).then(() => props.goBack())
        }
    }, [PdcCalls.callsCnt, PdcCalls.calls[target]?.isMerged])
    useEffect(() => {
        if (target !== '' && ![target, props.caller].includes(PdcCalls.activeCallId)) {
            Softphone.CloseCallAction()
        }
        setMergeDisabled(PdcCalls.calls[target]?.session?.state !== SessionState.Established)
    }, [PdcCalls.activeCallId, PdcCalls.calls[target]?.session?.state, target])
    const MergeCalls = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'add-call-merge-calls-click' })
        if (PdcCalls.activeCallId === target) {
            PdcCalls.mergeCall(props.caller)
            Softphone.CloseCallAction()
        } else if (PdcCalls.activeCallId === props.caller) {
            PdcCalls.mergeCall(target)
            Softphone.CloseCallAction()
        }
    }
    const [hideKeypad, toggleHideKeypad] = useToggle(true)
    const OpenKeypad = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'add-call-toggle-keypad', value: true })
        toggleHideKeypad(false)
    }
    const CloseKeypad = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'add-call-toggle-keypad', value: false })
        toggleHideKeypad(true)
    }
    if (!hideKeypad) {
        return (
            <KeypadView
                closeKeypad={CloseKeypad}
                title={<Typography variant={'subtitle2'}>Transfer</Typography>}
            />
        )
    }
    return (<Grid container direction='column'>
        <Grid item>
            <SoftphoneHeader title={<Typography variant={'subtitle2'}>MERGE CALL</Typography>}>
                <span><SessionsBarSelector sessions={sessions} disableMerge={true} variant={'call-view'}/></span>
            </SoftphoneHeader>
        </Grid>
        <Grid item>
            <CallDescription/>
        </Grid>
        <Grid item>
            <CallControls openKeypad={OpenKeypad} disableActions={true}/>
        </Grid>
        <Grid item className={classes.addCallOptions}>
            <div className={classes.addCallButton}>
                <IconButton
                    onClick={MergeCalls}
                    disabled={mergeDisabled}
                    classes = {{ root: `${classes.addButton} disable-dragging` }}
                >
                    <MergeIcon/>
                </IconButton>
                <Typography color={'primary'} variant={'overline'}>MERGE</Typography>
            </div>
        </Grid>
    </Grid>)
}
/**
 *
 */
function AddCallView (): JSX.Element {
    const PdcCalls: any = useContext(PdcCallContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    // eslint-disable-next-line no-unused-vars
    const [originalCallId, setCallId] = useState(PdcCalls.activeCallId)
    const [onCall, setOnCall] = useToggle(false)
    const Softphone = useContext(SoftphoneCallsContext)
    const PlaceCall = async (callee: string) => {
        await PdcCalls.hold().then(() => {
            PdcCalls.call(callee).then(() => setOnCall(true))
        })
    }
    const ReturnToAddCall = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'add-call-go-back-click' })
        setOnCall(false)
    }
    useEffect(() => {
        const call = PdcCalls.calls[originalCallId]
        if (!call || call.isMerged) {
            Softphone.CloseCallAction()
        }
    }, [PdcCalls.callsCnt, PdcCalls.calls[originalCallId]?.isMerged])
    return (<>
        {onCall
            ? <InMergeView
                caller={originalCallId}
                goBack={ReturnToAddCall}
            />
            : <AddCallDialpadView
                call={PlaceCall}
            />}
    </>)
}

export default AddCallView
