<template>
    <w-select
      v-bind="$attrs"
      v-on="$listeners"
      :value="value"
      :items="Object.keys(actions_ref).filter((v) => !actions_ref_ro.includes(v) || value === v).map((x) => ({value: x, text: actionName(x)}))"
      :rules="[$validation.required(), $validation.in(Object.keys(actions_ref))]"
      hide-details="auto"
      data-test-id="route-conf-rule-actions-select"
    />
  </template>

  <script>
      import l from '../../../libs/lang';

      const ACTIONS_REF = {
        forward: 'Forward call',
        voicemail: 'Leave voicemail',
        fax: 'Receive Fax',
        queue: 'Queue',
        menu: 'Menu',
        live_answer: 'Receptionist service',
        directory: 'Dial-by-name directory',
        trunk: 'Trunk',
        greeting: 'Play recording',
        hold: 'Play hold music',
        disconnect: 'Disconnect',
      };
    export default {
      props: {
          value: {
              type: String,
              required: true,
          },
      },
      data() {
          const ACTIONS_REF_RO = [];
          return {
              actions_ref: ACTIONS_REF,
              actions_ref_ro: ACTIONS_REF_RO,
        };
      },
      async created() {
        if (!this.$session.user['account']['features']['trunks-enabled']) {
          this.$data.actions_ref_ro.push('trunk');
        }
      },
      methods: {
          actionName(value) {
            let name = '';
            switch (value) {
              case 'forward': name = l.t('routes.forward-call', 'Forward call'); break;
              case 'voicemail': name = l.t('app.leave-voicemail', 'Leave voicemail'); break;
              case 'fax': name = l.t('routes.receive-fax', 'Receive Fax'); break;
              case 'queue': name = l.t('app.queue', 'Queue'); break;
              case 'menu': name = l.t('app.menu', 'Menu'); break;
              case 'directory': name = l.t('routes.dial-by-name-directory', 'Dial-by-name directory'); break;
              case 'trunk': name = l.t('app.trunk', 'Trunk'); break;
              case 'greeting': name = l.t('player.play-recording', 'Play recording'); break;
              case 'hold': name = l.t('app.play-hold-music', 'Play hold music'); break;
              case 'disconnect': name = l.t('app.disconnect', 'Disconnect'); break;
              case 'live_answer': name = l.t('app.receptionist-service', 'Receptionist service'); break;
              default: name = this.$data.actions_ref[value];
            }
            return name;
          },
      }
    };
  </script>
