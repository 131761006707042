import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { Switch, Route, Redirect } from 'react-router-dom'
import { PaymentMethodsIndex, PaymentMethodsCreate } from 'billing-react-components'

const styles = (theme) => ({
    appWrapper: {
        marginTop: '50px'
    }
})

class PaymentMethods extends Component {
    constructor (props) {
        super(props)
        this.state = {
            selectedTabId: 'index'
        }
    }

    pushRoute = newTab => {
        this.props.routeProps.history.push(`${this.props.routeProps.match.path}${newTab}`)
        this.setState({ selectedTabId: newTab })
    }

    onSave () {
        setTimeout(() => {
            this.pushRoute('')
        }, 3000)
    }

    onClickLink (route) {
        if (route.name === 'billing.payment-methods.create') {
            this.pushRoute('create')
        } else if (route.name === 'billing.payment-methods.index') {
            this.pushRoute('')
        }
    }

    onClickBack () {
        this.pushRoute('')
    }

    render () {
        const { classes } = this.props
        const basePath = this.props.routeProps.match.path
        return (
            <div className={classes.appWrapper}>
                <Switch className={'paymentMethodsContainer'}>
                    <Route path={`${basePath}create`} render={() => (<PaymentMethodsCreate postSaveCb={this.onSave.bind(this)} onClickBack={this.onClickBack.bind(this)}/>)}/>
                    <Route path={`${basePath}`} render={(props) => (<PaymentMethodsIndex onClickLink={this.onClickLink.bind(this)}/>)}/>
                    <Route path='/' render={() => <Redirect to={`${basePath}`}/>}/>
                </Switch>

            </div>
        )
    }
}

PaymentMethods.propTypes = {
    classes: PropTypes.object,
    extension: PropTypes.object,
    redirect: PropTypes.func.isRequired,
    routeProps: PropTypes.object,
    smallView: PropTypes.bool

}
export default withStyles(styles)(PaymentMethods)
