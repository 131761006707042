import { combineReducers } from 'redux'
import { smallView } from './view'
import { calls } from './calls'
import { currentExtension } from './pdcuser'

export default combineReducers({
    smallView,
    calls,
    currentExtension
})
