/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import api from '../../util/api_v5'
import Spinner from 'spinner'
import { updateInvoices } from '../../actions/account'
import Button from '@material-ui/core/Button'
import DescriptionIcon from '@material-ui/icons/Description'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
    header: theme.settingsApp.header,
    headerStatements: {
        paddingBottom: '15px',
        fontSize: '24px',
        fontWeight: '400'
    },
    settingWrapper: theme.settingsApp.settingWrapper,
    invoicesWrapper: {
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    invoicesTable: {
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: 15,
        marginBottom: 5,
        '&:not(.small-view)': {
            padding: '10px 20px',
            maxWidth: 440,
            boxShadow: '0px 0px 6px gray'
        }
    },
    invoiceRow: {
        display: 'flex',
        alignItems: 'center',
        width: '440px',
        color: 'gray',
        backgroundImage: 'linear-gradient(to right, #333 10%, rgba(255, 255, 255, 0) 0%)',
        backgroundPosition: 'bottom',
        backgroundSize: '10px 1px',
        backgroundRepeat: 'repeat-x',
        marginBottom: 8,
        paddingBottom: 8,
        '&>*:not(:last-child)': {
            marginRight: 20
        },
        '& .date': {
            flex: 1
        },
        '& .pdf-button': {
            color: 'black',
            border: '1px solid lightgray',
            borderRadius: 4,
            boxShadow: '0px 0px 5px lightgray',
            padding: '8px 12px',
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: 1,
            cursor: 'default',
            '&:not(.loading)': {
                cursor: 'pointer'
            }
        },
        '& .no-invoices': {
            fontSize: 20
        }
    },
    buttonWrapper: {
        margin: '15px 15px',
        width: '440px',
        display: 'flex',
        justifyContent: 'center'
    },
    loadMoreButton: {
        color: '#1140A1',
        '&:hover': {
            color: 'white',
            backgroundColor: '#1140A1'
        },
        '&:focus': {
            outline: 0
        }
    },
    spinnerWrapper: {
        display: 'flex',
        justifyContent: 'center',
        margin: 18
    },
    spinnerWrapperPdf: {
        display: 'flex',
        justifyContent: 'center',
        marginRight: 15
    }
})

const mapStateToProps = state => ({
    smallView: state.smallView,
    invoices: state.invoices
})

const mapDispatchToProps = dispatch => ({
    updateInvoices: card => dispatch(updateInvoices(card))
})

const perPage = 10

class Statements extends Component {
 state = {
     activeInvoices: [],
     currentPage: 0,
     loading: false,
     loadingPdf: false
 }

 componentDidMount () {
     this.getInvoices()
 }

 getInvoices = async () => {
     if (this.props.invoices.length) return this.paginateInvoices(false)
     this.setState({ loading: true })
     const response = await api.getInvoices()
     this.setState({ loading: false })
     if (response && response.statement_list) {
         this.props.updateInvoices(response.statement_list)
         this.paginateInvoices(false)
     }
 }

 fetchPdf = async invoice_id => {
     if (this.state.loading || this.state.loadingPdf) return
     const loadingSpecificPdf = `loadingPdf${invoice_id}`
     this.setState({ [loadingSpecificPdf]: true, loadingPdf: true })
     // Create a blank popup immediately on user action to avoid browser popup blockers
     const invoiceTab = window.open('', '_blank')
     invoiceTab.document.write('Loading invoice - please wait...')

     const response = await api.getInvoicePdf(invoice_id)
     this.setState({ [loadingSpecificPdf]: false, loadingPdf: false })
     if (response && response.invoice_link) {
         invoiceTab.location.href = response.invoice_link
     } else {
         invoiceTab.document.write('Could not load invoice')
     }
 }

 loadMore = () => {
     this.setState({ loading: true })
     this.paginateInvoices()
 }

 paginateInvoices = (scroll = true) => {
     const pageNumber = this.state.currentPage
     const invoices = this.props.invoices
     const newActiveInvoices = invoices.slice(pageNumber * perPage, (pageNumber + 1) * perPage)
     this.setState({
         activeInvoices: [...this.state.activeInvoices, ...newActiveInvoices],
         loading: false,
         currentPage: pageNumber + 1
     })
     if (scroll) this.scrollToBottom()
 }

 scrollToBottom = () => this.itemsEnd.scrollIntoView({ behavior: 'smooth' })

 render () {
     const { classes } = this.props
     const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
     let noInvoicesFound = ''
     if (this.state.activeInvoices.length === 0 && !this.state.loading) {
         noInvoicesFound = <div className={`${classes.invoiceRow} last-shown-row`}><span className='no-invoices'>You don&apos;t have any invoices yet</span></div>
     }
     return (
         <>
             <div className={classes.invoicesWrapper}>
                 <div className={`${classes.invoicesTable} ${this.props.smallView ? 'small-view' : ''}`}>
                     <div className={classes.headerStatements}>Statements</div>
                     {this.state.activeInvoices.map((statement, index) => {
                         let displayDate = 'Current activity'
                         if (statement.billing_period !== displayDate) {
                             const dateSplitted = statement.billing_period.split('/')
                             displayDate = dateSplitted[1] + ' ' + months[parseInt(dateSplitted[0]) - 1] + ' ' + dateSplitted[2]
                         }
                         // const totalChargedInDollars = statement.total_charges_in_cents / 100
                         // const formattedAmount = totalChargedInDollars.toFixed(2)
                         const isLastRow = index === this.state.activeInvoices.length - 1
                         return (
                             <div key={statement.statement_id} className={`${classes.invoiceRow} ${isLastRow ? 'last-shown-row' : ''}`}>
                                 <div className='icon'><DescriptionIcon/></div>
                                 <div className='date'>{displayDate}</div>
                                 {/* <div className='price'>${formattedAmount}</div> */}
                                 {this.state['loadingPdf' + statement.statement_id] ? <div className={classes.spinnerWrapperPdf}><Spinner/></div> : <div onClick={() => this.fetchPdf(statement.statement_id)} className={this.state.loadingPdf ? 'pdf-button loading' : 'pdf-button'}>PDF</div>}
                             </div>
                         )
                     })}
                     {noInvoicesFound}
                     <div style={{ float: 'left', clear: 'both' }}
                         ref={el => this.itemsEnd = el}>
                     </div>
                     {this.state.loading ? <div className={classes.spinnerWrapper}><Spinner/></div> : null}
                     {!this.state.loading && this.state.activeInvoices.length && this.state.activeInvoices.length < this.props.invoices.length
                         ? <div className={classes.buttonWrapper}>
                             <Button
                                 onClick = {this.loadMore}
                                 classes = {{ root: classes.loadMoreButton }}
                                 disabled = {Boolean(this.state.loading)}
                             >
                                    Load More
                             </Button>
                         </div>
                         : null}
                 </div>
             </div>
         </>
     )
 }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Statements))
