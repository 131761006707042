import OrdersIndex from './components/screens/index.vue'
import OrdersShow from './components/screens/show.vue'
import OrdersCreate from './components/screens/create.vue'

/**
 *
 */
export {
    OrdersIndex,
    OrdersShow,
    OrdersCreate
}
