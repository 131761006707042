/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
/* eslint-disable jsdoc/require-description */
import React, { useEffect, useState, useContext } from 'react'
import Typography from 'typography'
import { TextField, FormatType } from 'text-field-mui'
import { getValue } from 'remote-config-value'
import RemoteConfigValue from 'remote-config-value'
// import BusinessInfoStepBackground from '../images/sms_business_information_bg.png'
import { stateOptions, provinceOptions, isAmericaState, isCanadianState } from '../utils/util'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'
import PropTypes from 'prop-types'
import EnrollmentStepContainer from './EnrollmentStepContainer'
import Checkbox, { Variant as CheckboxVariant } from 'checkbox-mui'
import { convertNumberToE164, formatAsYouType, isValidPhoneNumber } from 'phone-numbers'
import { makeStyles } from '@material-ui/core/styles'
import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { Alert, Color as AlertColor } from 'alert-mui'
import { useAjax } from '../hooks/useAjax.js'
import { useFormInput } from '../hooks/useFormInput'
import ConfirmModal from 'confirm-modal'
import { ScreenSizeContext } from 'providers'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import LoaderFull from 'loader-full'

const useStyles = makeStyles((theme) => ({
    rowContainer: {
        marginBottom: screenSizeContext => !screenSizeContext.mobile ? '1.25rem' : '0'
    },
    businessAddressContainer:
        {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: screenSizeContext => !screenSizeContext.mobile ? 'nowrap' : 'wrap',
            justifyContent: 'flex-start',
            columnGap: '1rem'
        },
    officeNumberContainer: { flexGrow: 1, width: '50%', marginBottom: screenSizeContext => !screenSizeContext.mobile ? '0' : '1.25rem' },
    cityStatePostalCodeContainer: {
        flexGrow: 1,
        width: '50%',
        display: 'flex',
        gap: '0.5rem',
        marginBottom: screenSizeContext => !screenSizeContext.mobile ? '0' : '1.25rem',
        '& > *': { flexBasis: '33%' }
    },
    cityTextField: {},
    stateSelect: { width: '100%' },
    postalCodeTextField: {},
    phoneSubtitle: { visibility: 'hidden' },
    mockContainer: { paddingTop: '2rem' },
    spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: '15rem'
    },
    marginTop: { paddingTop: '0.5rem' },
    marginBottom: {
        marginBottom: '1.25rem'
    }
}))

/**
 * @param {object} props
 * @param {object} props.passData Passed data from previous steps
 * @param {Function} props.update Callback function used to complete the step
 * @param {number} props.saveId Step ID, if set the next was clicked
 */
const BusinessAddressAndContact = (props) => {
    const screenSizeContext = useContext(ScreenSizeContext)
    const classes = useStyles(screenSizeContext)
    const theme = useTheme()
    const aboveLg = useMediaQuery(theme.breakpoints.up('lg'))

    const phoneNumberFormatHelper = (value) => {
        return formatAsYouType(convertNumberToE164(value).substring(0, 12))
    }

    const { readOnly = false, showContainer = true } = props

    const bizAddressSavedData = props.passData.business_address
    const businessInfo = props.passData.business_info

    const defaultOfficeNumber = bizAddressSavedData ? bizAddressSavedData.officeNumber : (props.editing != null ? props.editing.address : null)
    const {
        value: officeNumber,
        onChange: setOfficeNumber,
        validValue: validOfficeNumber,
        onXClick: onOfficeNumberXClick
    } = useFormInput(
        defaultOfficeNumber,
        (value) => value?.length > 0)

    // Since UX dictates the state form and country form are in different steps (why!?), need to run additional check to see if the country changed
    const defaultState = bizAddressSavedData
        ? ((
            (businessInfo.registrationCountry.value === 'US' && isCanadianState(bizAddressSavedData.state)) ||
        (businessInfo.registrationCountry.value === 'CA' && isAmericaState(bizAddressSavedData.state))
        )
            ? null
            : bizAddressSavedData.state)
        : (props.editing != null ? props.editing.state : null)
    const {
        value: state,
        onChange: setState,
        validValue: validState
    } = useFormInput(
        defaultState,
        (value) => value as boolean
    )

    const validateAddress = () => {
        const params = {
            business_address: officeNumber,
            city: city,
            state: state,
            postal_code: postalCode,
            country_code: businessInfo.registrationCountry
        }
        const verifyAddressUrl = `${PhoneComUser.getv5ApiRoot()}/addresses/validate-address`
        return ajax.post(verifyAddressUrl, params, 'Bearer')
            .then(rv => {
                if (rv?.status === 200) {
                    return new Promise((resolve, reject) => {
                        if (rv.data.response.result.verdict?.addressComplete) {
                            resolve(rv.data.response)
                            props.update({ completed: true, gotoNext: true, saveData: getSaveData() })
                        } else {
                            setShowInvalidAddrModal(true)
                            reject(new Error('Failed to validate address'))
                        }
                    })
                }
            })
    }
    const { status: validateAddrStatus, execute } = useAjax(validateAddress, false)

    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const gotoNextStep = () => {
        setShowConfirmModal(false)
        props.update({ completed: true, gotoNext: true, saveData: getSaveData() })
    }

    const onConfirmHandler = () => {
        setShowConfirmModal(false)
        execute()
    }

    const onRejectHandler = () => {
        setShowConfirmModal(false)
    }

    const [showInvalidAddrModal, setShowInvalidAddrModal] = useState(false)
    const onInvalidAddrConfirmHandler = () => {
        setShowInvalidAddrModal(false)
        gotoNextStep()
    }
    const onInvalidAddrRejectHandler = () => {
        setShowInvalidAddrModal(false)
    }

    const defaultCity = bizAddressSavedData ? bizAddressSavedData.city : (props.editing != null ? props.editing.city : null)
    const {
        value: city,
        onChange: setCity,
        validValue: validCity
    } = useFormInput(
        defaultCity,
        (value) => value?.length > 0)

    const validPostalCodePattern = new RegExp('^\\d{5}-\\d{4}$|^\\d{5}$|^([A-Z]|[a-z])\\d([A-Z]|[a-z]) ?\\d([A-Z]|[a-z])\\d$')
    const defaultPostalCode = bizAddressSavedData ? bizAddressSavedData.postalCode : (props.editing != null ? props.editing.zip : '')
    const {
        value: postalCode,
        onChange: setPostalCode,
        validValue: validPostalCode
    } = useFormInput(
        defaultPostalCode,
        (value) => value.match(validPostalCodePattern) != null
    )

    const defaultPrimaryBizPhone = bizAddressSavedData ? bizAddressSavedData.primaryBusinessPhone : (props.editing != null ? phoneNumberFormatHelper(props.editing.mobile_phone) : '')
    const {
        value: primaryBusinessPhone,
        onChange: setPrimaryBusinessPhone,
        validValue: validPrimaryBizPhone,
        onXClick: onPrimaryBizPhoneXClick
    } = useFormInput(
        defaultPrimaryBizPhone,
        (value) => isValidPhoneNumber((convertNumberToE164(value)))
    )

    const defaultSupportPhone = bizAddressSavedData ? bizAddressSavedData.supportPhone : (props.editing != null ? phoneNumberFormatHelper(props.editing.phone) : '')
    const {
        value: supportPhone,
        onChange: setSupportPhone,
        validValue: validSupportPhoneNumber,
        onXClick: onSupportPhoneXClick
    } = useFormInput(
        defaultSupportPhone,
        (value) => isValidPhoneNumber((convertNumberToE164(value)))
    )

    const websitePattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
    const websiteRegExp = new RegExp(websitePattern)
    const defaultBizWebsite = bizAddressSavedData ? bizAddressSavedData.bizWebsite : (props.editing != null ? props.editing.company : '')
    const {
        value: bizWebsite,
        onChange: setBizWebsite,
        validValue: validBizWebsite,
        onXClick: onBizWebsiteXClick
    } = useFormInput(
        defaultBizWebsite,
        (value) => value.length === 0 || value.match(websiteRegExp) != null
    )

    const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    const emailRegExp = new RegExp(emailPattern)
    const defaultSupportEmail = bizAddressSavedData ? bizAddressSavedData.supportEmail : (props.editing != null ? props.editing.email : '')
    const {
        value: supportEmail,
        onChange: setSupportEmail,
        validValue: validSupportEmail,
        onXClick: onSupportEmailXClick
    } = useFormInput(
        defaultSupportEmail,
        (value) => value.match(emailRegExp) != null
    )
    const [mock, setMock] = useState(props.editing != null ? props.editing.is_mock === 'Y' : false)

    const invalidated = !validOfficeNumber || !validCity || !validState || !postalCode || !validPrimaryBizPhone || !validSupportPhoneNumber || !validBizWebsite || !validSupportEmail || (validateAddrStatus === 'pending')

    const showMockCheckbox = process.env.REACT_APP_10DLC_OPTIONAL_MOCK === 'true'

    // used for developers to skip steps for debugging/testing
    // eslint-disable-next-line no-unused-vars
    const onClickHandler = () => {
        gotoNextStep()
    }

    const onCityXClick = () => {
        const e = {
            target: {
                value: ''
            }
        }
        setCity(e)
    }

    const onPhoneNumberChangeHandler = (setter) => {
        return (event) => {
            const value = phoneNumberFormatHelper(event.target.value)
            setter({ target: { value } })
        }
    }

    const getSaveData = () => {
        return {
            officeNumber,
            state,
            city,
            postalCode,
            primaryBusinessPhone: convertNumberToE164(primaryBusinessPhone),
            supportPhone: convertNumberToE164(supportPhone),
            bizWebsite,
            supportEmail,
            mock
        }
    }

    useEffect(() => {
        props.update({ disableNextButton: () => invalidated })
    }, [invalidated])

    // Runs when the next/back button is clicked
    useEffect(() => {
        if (props.saveId) {
            if (props.gotoStepIndex > props.stepIndex) {
                setShowConfirmModal(true)
            } else {
                gotoNextStep()
            }
        }
    }, [props.saveId, props.gotoStepIndex, props.stepIndex])

    return (
        <EnrollmentStepContainer showContainer={showContainer}>
            { validateAddrStatus === 'pending'
                ? (
                    <LoaderFull text='Validating address...' styles={{ loaderFull: { left: '50%' } }} size='big'/>
                )
                : (
                    <>
                        <div className={classes.rowContainer}>
                            {validateAddrStatus === 'error' && (
                                <Alert
                                    soft
                                    classes={{ root: classes.marginBottom }}
                                    color={AlertColor.ERROR}
                                    content={<RemoteConfigValue valueId={'sms_enrollment_invalid_address'} />} />
                            )}
                            <Typography variant='body1' remoteConfigID='sms_enrollment_business_address' />

                            <div className={classes.businessAddressContainer} >
                                <div className={classes.officeNumberContainer}>
                                    <TextField InputProps={{ readOnly: readOnly }} id='officeNumberTxtField' fullWidth={true} label={getValue('sms_enrollment_number_office')} value={officeNumber} onChange={setOfficeNumber} onXClick={readOnly ? undefined : onOfficeNumberXClick} />
                                </div>
                                <span className={classes.cityStatePostalCodeContainer}>
                                    <TextField InputProps={{ readOnly: readOnly }} id='cityTextField' className={classes.cityTextField} fullWidth={false} label={aboveLg ? getValue('sms_enrollment_enter_city') : getValue('sms_enrollment_city')} value={city} onChange={setCity} onXClick={readOnly ? undefined : onCityXClick} />
                                    <Select readOnly={readOnly} id='stateSelect' className={classes.stateSelect} fullWidth={false} label={aboveLg ? getValue('sms_enrollment_enter_state') : getValue('sms_enrollment_state')} value={state} onChange={setState}>
                                        {(businessInfo.registrationCountry === 'US' ? stateOptions : provinceOptions).map((x) => (
                                            <MenuItem key={x.value} value={x.value} checked={state === x.value}>{x.content}</MenuItem>
                                        ))}
                                    </Select>
                                    <TextField
                                        id='postalCodeTxtField'
                                        InputProps={{ readOnly: readOnly }}
                                        className={classes.postalCodeTextField}
                                        fullWidth={false}
                                        label={getValue('sms_enrollment_postal_code')}
                                        value={postalCode}
                                        onChange={setPostalCode}
                                        error={postalCode.length > 0 && !validPostalCode}
                                        helperText={!validPostalCode ? getValue('sms_enrollment_invliad_postal_code') : null}
                                        onXClick={readOnly ? undefined : () => setPostalCode({ target: { value: '' } })} />
                                </span>
                            </div>
                        </div>
                        <div className={classes.rowContainer}>
                            <div className={classes.businessAddressContainer}>
                                <div className={classes.officeNumberContainer}>
                                    <Typography variant='body1' remoteConfigID='sms_enrollment_primary_business_phone' />
                                    {/* <Typography className={classes.phoneSubtitle} variant='subtitle3' remoteConfigID='sms_enrollment_primary_business_phone_subtitle' /> */}
                                    <TextField
                                        id='primaryBizPhoneTxtField'
                                        InputProps={{ readOnly: readOnly }}
                                        error={primaryBusinessPhone.length > 0 && !validPrimaryBizPhone}
                                        helperText={validPrimaryBizPhone ? null : getValue('sms_enrollment_phone_number_helper_text')}
                                        formatType={FormatType.PhoneNumberUS}
                                        fullWidth={true}
                                        label={getValue('sms_enrollment_enter_primary_business_phone')}
                                        value={primaryBusinessPhone}
                                        onChange={onPhoneNumberChangeHandler(setPrimaryBusinessPhone)}
                                        onXClick={readOnly ? undefined : onPrimaryBizPhoneXClick} />
                                </div>
                                <div className={classes.officeNumberContainer}>
                                    <Typography variant='body1' remoteConfigID='sms_enrollment_support_phone_number' />
                                    {/* {!screenSizeContext.mobile && <br />} */}
                                    <TextField
                                        id='supportPhoneTxtField'
                                        InputProps={{ readOnly: readOnly }}
                                        error={supportPhone.length > 0 && !validSupportPhoneNumber}
                                        helperText={validSupportPhoneNumber ? null : getValue('sms_enrollment_phone_number_helper_text')}
                                        formatType={FormatType.PhoneNumberUS}
                                        fullWidth={true}
                                        label={getValue('sms_enrollment_enter_support_phone_number')}
                                        value={supportPhone}
                                        onChange={onPhoneNumberChangeHandler(setSupportPhone)}
                                        onXClick={readOnly ? undefined : onSupportPhoneXClick} />
                                </div>
                            </div>
                        </div>
                        <div className={classes.rowContainer}>
                            <div className={classes.businessAddressContainer} >
                                <div className={classes.officeNumberContainer} >
                                    <Typography variant='body1' remoteConfigID='sms_enrollment_business_website' />
                                    <TextField
                                        type='url'
                                        InputProps={{ readOnly: readOnly }}
                                        id='websiteTxtField'
                                        fullWidth={true}
                                        error={bizWebsite.length > 0 && !validBizWebsite}
                                        helperText={!validBizWebsite ? getValue('sms_enrollment_website_helperText') : null}
                                        label={getValue('sms_enrollment_enter_business_website')}
                                        value={bizWebsite}
                                        onChange={setBizWebsite}
                                        onXClick={readOnly ? undefined : onBizWebsiteXClick} />
                                </div>
                                <div className={classes.officeNumberContainer} >
                                    <Typography variant='body1' remoteConfigID='sms_enrollment_support_email' />
                                    <TextField
                                        type='email'
                                        InputProps={{ readOnly: readOnly }}
                                        id='emailTxtField'
                                        fullWidth={true}
                                        error={supportEmail.length > 0 && !validSupportEmail}
                                        helperText={!validSupportEmail ? getValue('sms_enrollment_support_email_helperText') : null}
                                        label={getValue('sms_enrollment_enter_support_email')}
                                        value={supportEmail}
                                        onChange={setSupportEmail}
                                        onXClick={readOnly ? undefined : onSupportEmailXClick} />
                                </div>
                            </div>
                        </div>
                        {showMockCheckbox &&
                                            <div
                                                className={classes.mockContainer}>
                                                <Checkbox
                                                    variant={CheckboxVariant.PRIMARY}
                                                    checked={mock}
                                                    id='mockChkBox'
                                                    readOnly={readOnly}
                                                    onChange={readOnly ? undefined : (type, checked) => setMock(!mock)}
                                                    label='Mock brand' />
                                            </div>
                        }
                    </>
                )}

            <ConfirmModal
                title='Confirmation'
                id='confirmModal'
                isShown={showConfirmModal}
                content={(<RemoteConfigValue valueId='sms_enrollment_please_confirm_business_registration' />)}
                onConfirm={onConfirmHandler}
                onReject={onRejectHandler}
                yesButtonText='Proceed' />
            <ConfirmModal
                title='Confirmation'
                id='rejectedAddrModal'
                isShown={showInvalidAddrModal}
                content={(<RemoteConfigValue valueId='sms_enrollment_invalid_business_addr' />)}
                onConfirm={onInvalidAddrConfirmHandler}
                onReject={onInvalidAddrRejectHandler}
                yesButtonText='Proceed'
            />
        </EnrollmentStepContainer>
    )
}

BusinessAddressAndContact.propTypes = {
    passData: PropTypes.object,
    update: PropTypes.func,
    saveId: PropTypes.number,
    gotoStepIndex: PropTypes.number,
    stepIndex: PropTypes.number,
    editing: PropTypes.object,
    readOnly: PropTypes.bool,
    showContainer: PropTypes.bool
}

export default BusinessAddressAndContact
