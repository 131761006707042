import React, { Component } from 'react';
import DropdownExtensionsMenu from './DropdownExtensionsMenu'
import { connect } from 'react-redux';
import api from '../util/api_v2'
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  appHeaderTheme: {
    boxShadow: theme.palette.primary.flatBottomShadow
  },
});

const mapStateToProps = state => {
	return {
		smallView: state.smallView
	};
};

class Header extends Component {

	state = {
		loggedUser: ''
	}

	render() {
		const { classes } = this.props;

		return (
			<div className={`header-bar ${classes.appHeaderTheme}` + (this.props.smallView ? ' small-view' : '') }>
				<div className="conversation-app-title"> <span> {this.props.smallView ? this.state.loggedUser : 'Messages'} </span> </div>
				<div className="need-help-link">Questions? <span style={{cursor: 'pointer'}} onClick={this.popoutHelp}>Chat with us</span></div>
				<div className='logged-user-info'>
					{!this.props.smallView ? <div className='logged-user fs-block'>{this.state.loggedUser}</div> : null}
					<span className="extension-select-dropdown options-menu-toggle-button">
						<DropdownExtensionsMenu/>
					</span>
				</div>
			</div>
		);
	}

	componentDidMount() {
		this._getLoggedUser();
	}

	_getLoggedUser() {
		let me = this;
		api.getAccountContactInfo().then(response => {
			response = response.data.response;
			if (response.status_code !== 404) {
				me.setState({
					loggedUser: response.company ? response.company : response.name
				});
			}
		});
	}

	popoutHelp = () => {
		window.open('https://secure.livechatinc.com/licence/7407731/open_chat.cgi?groups=4','_blank','height=540,width=400');
	}
}
export default withStyles(styles)(connect(mapStateToProps)(Header));