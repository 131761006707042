import React, { useEffect } from 'react'
import CampaignUsageStep from './CampaignUsageStep.tsx'
import MessageSettingsStep from './MessageSettingsStep.tsx'
import CampaignSampleMessageStep from './CampaignSampleMessageStep.tsx'
import CampaignPoliciesStep from './CampaignPoliciesStep'
import CampaignContactStep from './CampaignContactStep.tsx'
import PropTypes from 'prop-types'

/**
 * @param {object} props
 */
const CampaignPreviewStep = (props) => {
    const { editing, passData, update } = props
    const stepComponents = [CampaignUsageStep, MessageSettingsStep, CampaignSampleMessageStep, CampaignPoliciesStep, CampaignContactStep]
    useEffect(() => {
        if (props.saveId) props.update({ completed: true, gotoNext: true, saveData: {} })
    }, [props.saveId])
    return (<>
        {
            stepComponents.map((comp, index) => {
                const StepComponent = comp
                return <React.Fragment key={index}>
                    <StepComponent update={update} passData={passData} readOnly={true} editing={editing} />
                    <br/>
                </React.Fragment>
            })
        }
    </>)
}

CampaignPreviewStep.propTypes = {
    passData: PropTypes.object,
    update: PropTypes.func,
    saveId: PropTypes.number,
    editing: PropTypes.object,
    preview: PropTypes.bool
}

export default CampaignPreviewStep
