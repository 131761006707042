import l from 'lang'
import CallReport from './CallReport'

/**
 *
 */
export default class Total extends CallReport {
    /**
     *
     * @param {object} session
     * @param {object} component
     */
    constructor (session, component) {
        super(
            session,
            component,
            [
                'count_incoming',
                'count_outgoing'
            ],
            'total',
            'date'
        )
        this.logarithmic = false
    }

    /**
     *
     */
    set headers (v) {
        console.log(`${v} is not a proper header value`)
    }

    /**
     *
     */
    get headers () {
        const headers = {}
        Object.keys(this.fields).map(
            (x) => {
                headers[x] = this.fields[x].translation
                return x
            }
        )
        const group_by = this.filters.group_by.charAt(0).toUpperCase() +
            this.filters.group_by
                .slice(1)
                .split('_')
                .join(' ')
                .split('-')
                .join(' ')
        headers[this.filters.group_by] = `${l.t(`reports.${this.filters.group_by}`, group_by)}`

        return headers
    }

    /**
     *
     * @param {Array} itms
     * @returns
     */
    async prepare_items (itms) {
        this.has_deleted_extensions = false
        let items = itms
        const { group_by } = this.filters
        if (!group_by) {
            throw new Error(l.t(
                'reports.please-provide-group-by',
                'Please provide group by parameter.'
            ))
        }
        items.sort((a, b) => (a[group_by] > b[group_by] ? 1 : -1))
        let extensions = null
        if (this.filters.group_by === 'extension') {
            extensions = await this.load_extensions(items)
        }
        items = items.map((item) => this.prepare_item(item, extensions)).filter((x) => x)

        return items
    }

    /**
     *
     * @param {object} item
     * @param {object} extensions
     * @returns
     */
    prepare_item (item, extensions) {
        for (const key of Object.keys(item)) {
            if (key === 'count_incoming') {
                item.incoming = item.count_incoming
                delete item.count_incoming
            } else if (key === 'count_outgoing') {
                item.outgoing = item.count_outgoing
                delete item.count_outgoing
            } else if (key === this.filters.group_by) {
                if (this.filters.group_by === 'extension') {
                    if (item.extension === 0) {
                        item[key] = l.t('app.no-extension', 'No extension')
                    } else if (extensions && extensions[item.extension.toString()]) {
                        item[key] = extensions[item.extension.toString()].extension
                    } else {
                        this.has_deleted_extensions = true
                        item[key] = `#${item[key]}`
                        if (!this.filters.show_deleted_extensions) {
                            return null
                        }
                    }
                } else if (this.filters.group_by === 'npa') {
                    if (item[key] === '000') {
                        item[key] = l.t(
                            'reports.non-USA-npa',
                            'Non USA NPA'
                        )
                    }
                } else if (key === 'direction') {
                    item[key] = item.direction === 'in' ? l.t('app.in', 'In') : l.t('app.out', 'Out')
                } else {
                    item[key] = item[this.filters.group_by]
                }
            } else if (
                !['incoming', 'outgoing', this.filters.group_by].includes(
                    key
                )
            ) delete item[key]
        }

        item.display_incoming = item.incoming
        item.display_outgoing = item.outgoing

        return item
    }

    /**
     *
     * @param {Array} items
     * @returns
     */
    async load_extensions (items) {
        if (items && items.length) {
            const ids = items.map((x) => x.extension).filter((y) => y)
            if (!ids.length) return null

            const res = await this.session.get_list_all(`/extensions?mode=brief&filters[id]=in:${ids}`)
            if (res && res.items && res.items.length) {
                const data = {}
                for (const e of res.items) {
                    data[e.id] = e
                }
                return data
            }
        }

        return null
    }

    /**
     *
     * @param {Array} items
     * @returns
     */
    prepare_csv_values (items) {
        const data = items.map((x) => {
            const group_by_value = [
                'npa',
                'direction',
                'extension'
            ].includes(this.filters.group_by)
                ? x[this.filters.group_by]
                : this.formatTime(x[this.filters.group_by])
            const item = {
                count_incoming: x.incoming,
                count_outgoing: x.outgoing
            }
            item[this.filters.group_by] = group_by_value
            return item
        })

        return data
    }
}
