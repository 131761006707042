import Api from 'api'

export const loadContacts = async communicator => {
  const isLoading = communicator.state.loadingContacts
  // We currently have 'isLoading' because we want the contacts to get reloaded on app change
  // Once we have push notifications for contacts changes we will not need to reload them on app change
  // and can remove anything that is connected to loadingContacts state
  if (isLoading) return
  const limit = parseInt(window.innerHeight / 50)
  communicator.setState({ loadingContacts: true })
  const contactsResponse = await Api.loadContacts({}, limit) || { items: [], total: 0 }
  if (contactsResponse === 'network-error') return
  const contacts = {
    items:	contactsResponse.items,
    total:	contactsResponse.total,
    hasMore:	contactsResponse.items.length < contactsResponse.total
  }
  const contactsInfo			= { ...communicator.state.contactsInfo }
  contactsInfo.contacts		= { ...contacts }
  contactsInfo.contactsLoaded	= true
  addToExtraContacts(contactsInfo, contacts)
  contactsInfo.groupTypes		= [...contactsResponse.group_types]
  communicator.setState({ contactsInfo: { ...contactsInfo }, loadingContacts: false })
}

export const loadMore = async communicator => {
  let contactsInfo	= { ...communicator.state.contactsInfo }
  let contacts		= contactsInfo.contacts
  if (!contacts) return console.error('No contacts loaded')
  contacts			= JSON.parse(JSON.stringify(contacts))
  const contactItems	= contacts.items
  if (contactItems.length === 0) return console.warn('No contacts loaded')
  let cursor = null
  for (let i = contactItems.length - 1; i >= 0; i--) {
    if (contactItems[i].cursor) {
      cursor = contactItems[i].cursor
      break
    }
  }
  const contactsResponse	= await Api.loadContacts({}, 10, cursor)
  if (contactsResponse === 'network-error') return
  // contacts.items.push(...contactsResponse.items)

  // Concat the contactsResponse items to the already loaded ones but in the already loaded ones there may be
  // a contact without a cursor and we should update it to have a cursor. Item(s) without a cursor may be there
  // in case the user created a contact and it got added to the list but the create endpoint doesn't add a cursor in it.
  // If the contactsResponse doesn't have that contact in the list that means that that contact will be in some other page
  // so by scrolling more that contact will get loaded and updated then.
  const addedContactIds = []
  const newContactItems = []
  contactsInfo		= { ...communicator.state.contactsInfo }
  contacts			= contactsInfo.contacts
  contacts.items.forEach(contactItem => {
    if (contactItem.cursor) {
      addedContactIds.push(contactItem.id)
      return newContactItems.push(contactItem)
    }
    const newContactItem = contactsResponse.items.find(c => c.id === contactItem.id)
    if (newContactItem) {
      addedContactIds.push(newContactItem.id)
      return newContactItems.push(newContactItem)
    }
    newContactItems.push(contactItem)
    addedContactIds.push(contactItem.id)
  })

  contactsResponse.items.forEach(contactItem => {
    if (addedContactIds.includes(contactItem.id)) return
    newContactItems.push(contactItem)
  })

  newContactItems.sort(sortContactItems)
  contacts.items = newContactItems

  contacts.hasMore		= contactsResponse.items.length < contactsResponse.total
  contactsInfo.contacts	= { ...contacts }
  addToExtraContacts(contactsInfo, contacts)
  communicator.setState({ contactsInfo: { ...contactsInfo } })
}

function addToExtraContacts (contactsInfo, contacts) {
  const extraContacts	= JSON.parse(JSON.stringify(contactsInfo.extraContacts))
  const contactItems	= JSON.parse(JSON.stringify(contacts.items)).filter(c => !extraContacts.find(ec => ec.id === c.id))
  extraContacts.push(...contactItems)
  contactsInfo.extraContacts = [...extraContacts]
}

export const addGroup = (communicator, groupType) => {
  const contactsInfo	= communicator.state.contactsInfo
  const groupTypes		= contactsInfo.groupTypes
  let exists			= false
  groupTypes.forEach(gt => {
    if (gt.name === groupType.name) {
      gt.id = groupType.id
      exists = true
    }
  })
  if (!exists) groupTypes.push(groupType)
  communicator.setState({ groupTypes })
}

export const updateGroupId = (communicator, currentId, newId) => {
  const contactsInfo	= communicator.state.contactsInfo
  const groupTypes		= contactsInfo.groupTypes
  const groupIndex		= groupTypes.findIndex(gt => gt.id === currentId)
  if (groupIndex === -1) return
  groupTypes[groupIndex].id = newId
  communicator.setState({ groupTypes: { ...groupTypes } })
}

// We need to know if the extension has a contact for each loaded conversation/fax/voicemail/call.
// These contacts are kept in communicator.state.extraContacts.
// They are not in state.contacts because state.contacts should have ordered contacts list.
export const loadExtraContacts = async (communicator, filters, limit = 20, cursor = null, returnResponse = false) => {
  const contactsResponse				= await Api.loadContacts(filters, limit, cursor)
  const contactsInfo					= { ...communicator.state.contactsInfo }
  if (contactsResponse === 'network-error') return
  const newContactsData					= contactsResponse.items
  let extraContacts					= [...contactsInfo.extraContacts, ...newContactsData]
  extraContacts						= removeContactDuplicates(extraContacts)
  contactsInfo.extraContacts			= extraContacts
  contactsInfo.groupTypes				= [...contactsResponse.group_types]
  contactsInfo.extraContactsLoaded	= true
  communicator.setState({ contactsInfo: { ...contactsInfo } })
  if (returnResponse) return contactsResponse
  return newContactsData
}

function removeContactDuplicates (contacts) {
  const newArray = []
  const contactIds = []
  contacts.forEach(c => !contactIds.includes(c.id) ? newArray.push(c) : null)
  return newArray
}

export const updateExtraContacts = (communicator, extraContacts) => {
  communicator.setState({
    contactsInfo: {
      ...communicator.state.contactsInfo,
      extraContacts: [...extraContacts]
    }
  })
}

export const updateContact = (communicator, contact) => {
  const contactsInfo	= { ...communicator.state.contactsInfo }
  const extraContacts	= [...contactsInfo.extraContacts]
  const contacts		= { ...contactsInfo.contacts, items: [...contactsInfo.contacts.items] }
  const contactId		= contact.id

  // Update the extraContacts data
  let isNew			= true
  extraContacts.forEach((c, i) => {
    if (c.id !== contactId) return
    extraContacts[i]	= contact
    isNew				= false
  })
  if (isNew) {
    contact.numbers.forEach(n => delete n.fixed)
    extraContacts.push(contact)
  }
  contactsInfo.extraContacts = extraContacts

  // Update the contacts data
  let isLoadedContact = false
  contacts.items.forEach((c, i) => {
    if (c.id !== contactId) return
    isLoadedContact		= true
    contacts.items[i]	= contact
  })
  if (!isLoadedContact) {
    const indexPush = contacts.items.findIndex(c => c.name.display.toLowerCase() >= contact.name.display.toLowerCase())
    if (indexPush !== -1) contacts.items.splice(indexPush, 0, contact)
    else contacts.items.push(contact)
  }
  contacts.items.sort(sortContactItems)
  contactsInfo.contacts = contacts
  communicator.setState({
    contactsInfo: {
      ...contactsInfo,
      contacts: {
        ...contactsInfo.contacts,
        items: [...contactsInfo.contacts.items]
      },
      extraContacts: [...contactsInfo.extraContacts]
    }
  })
}

function sortContactItems (c1, c2) {
  if (!c1.name.display) return -1
  if (!c2.name.display) return 1
  if (c1.name.display.toLowerCase() < c2.name.display.toLowerCase()) return -1
  if (c1.name.display.toLowerCase() > c2.name.display.toLowerCase()) return 1
  return 0
}

export const deleteContact = (communicator, contactId) => {
  const contactsInfo			= JSON.parse(JSON.stringify(communicator.state.contactsInfo))
  const contacts				= contactsInfo.contacts
  const contactItems			= contacts.items
  const extraContacts			= contactsInfo.extraContacts
  const deleteContactIndex		= contactItems.findIndex(c => c.id === contactId)
  if (deleteContactIndex !== -1) contactItems.splice(deleteContactIndex, 1)
  const deleteExtraContactIndex	= extraContacts.findIndex(c => c.id === contactId)
  if (deleteExtraContactIndex !== -1) extraContacts.splice(deleteExtraContactIndex, 1)
  contactsInfo.contacts		= { ...contacts }
  contactsInfo.extraContacts	= [...extraContacts]
  communicator.setState({ contactsInfo: { ...contactsInfo } })
}
