<template>
    <div>
        <div class="d-flex justify-center">
          <div id="total-chart" ref="chart" class="chart-container" data-test-id="total-chart"></div>
        </div>
        <ChartLegend :items="legend"/>
    </div>
</template>
<script>
  import { OrangeChartBars } from 'orange-charts';
  import { distinct_colors } from '../../../libs/helpers'
  import ChartLegend from 'console/src/components/fragments/ChartLegend.vue';

  export default {
    props: {
        items:{},
        group_by: {
            type: String,
            required: true,
        },
    },
    components: { ChartLegend, },
    data() {
      const [incoming, outgoing] = distinct_colors(this)

      return {
        original_incoming_color: incoming,
        colors: {incoming, outgoing},
      };
    },
    mounted() {
      this.$refs.chart.innerHTML = '';
      const max_y_value = Math.max(
        ...this.$props.items.map(
          (x) => x.display_outgoing + x.display_incoming
        )
      );
      let counter = 4;
      new OrangeChartBars(this.$props.items)
      .assignSource('display_outgoing', this.$data.colors['outgoing'])
      .assignSource('display_incoming', this.$data.colors['incoming'])
      .axis('x', {
        label: (v) => {
          if (['npa', 'direction', 'extension'].includes(this.$props.group_by)) return v;
          if (['hour', 'minute'].includes(this.$props.group_by)) {
            const d = new Date(v * 1000);
            counter++;
            if (counter % 5 === 0) {
            return d.toLocaleString(undefined, {
              month: 'short',
              day: 'numeric',
              hour: ['hour', 'minute'].includes(this.$props.group_by) ? 'numeric' : undefined,
              minute: this.$props.group_by === 'minute' ? 'numeric' : undefined,
            });
          }
          return '';
          }
          const d = new Date(v * 1000);
          return d.toLocaleString(undefined, {
            month: 'short',
            day: 'numeric',
            hour: ['hour', 'minute'].includes(this.$props.group_by) ? 'numeric' : undefined,
            minute: this.$props.group_by === 'minute' ? 'numeric' : undefined,
          });
        },
        source: this.$props.group_by,
        min: 0,
      })
      .axis('y', {
        cumulative: true,
        min: 0,
        lines: true,
        log: this.$data.logarithmic,
        label: (v) => {
          const label = Math.round(v);
          if (v * 1 === max_y_value) return this.$lang.t('x-calls', '{} calls', [label]);
          return label;
        },
      })
      .setDataCallback('mouseover', (_, data) => {
        let details = document.getElementById('orange-charts-details');
        if (!details) {
          details = document.createElement('div');
          details.setAttribute('id', 'orange-charts-details');
          document.body.appendChild(details);
        }
        let title = data.property;
        if (title === 'display_incoming') {
          title = this.$lang.t('app.incoming-calls', 'Incoming calls');
        } else if (title === 'display_outgoing') {
          title = this.$lang.t('app.outgoing-calls', 'Outgoing calls');
        }
        details.innerText = `${title.charAt(0)
          .toUpperCase()
        + title.slice(1)}: ${data.value}`;
      })
      .setDataCallback('mousemove', (e) => {
        const details = document.getElementById(
          'orange-charts-details',
        );
        details.style.top = `${e.pageY}px`;
        details.style.left = `${e.pageX}px`;
      })
      .setDataCallback('mouseleave', () => {
        document.getElementById('orange-charts-details').innerText = '';
      })
      .render('total-chart', {
        width: 768,
        height: 384,
      });
    },
    methods: {
        // ====================================
        // logarithmic representation removed for now. Partially functional, it counts it for incoiming+outgoing, shows it only as incoming. Now, we should make a proportion for every column, and display proportionally incoming and outgoing.
        // ====================================
        // logarithmic_representation() {
        //   this.$refs.chart.innerHTML = '';
        //   if (this.$data.logarithmic) {
        //     const values = this.$props.items.map((x) => x.incoming + x.outgoing);
        //     const log_values = this.$data.report.logarithmic_values([...values]);
        //     for (let i = 0; i < this.report.items.length; i++) {
        //       this.report.items[i].display_incoming = log_values[this.report.items[i].incoming + this.report.items[i].outgoing];
        //       this.report.items[i].display_outgoing = 0;
        //     }
        //     this.$data.colors.incoming = '#F66B16';
        //   } else {
        //     this.report.items = this.report.items.map((x) => {
        //       x.display_incoming = x.incoming;
        //       x.display_outgoing = x.outgoing;
        //       return x;
        //     });
        //     this.$data.colors.incoming = this.$data.original_incoming_color;
        //   }
        //   this.display_chart();
        // },
    },
    computed: {
      legend() {
          const obj = {};
          obj[
          this.$lang.t('app.incoming-calls', 'Incoming calls')
          ] = this.$data.colors.incoming;
          obj[
          this.$lang.t('app.outgoing-calls', 'Outgoing calls')
          ] = this.$data.colors.outgoing;
          return obj;
      },
    }
  };
</script>

<style scoped>

</style>