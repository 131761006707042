/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useContext, useEffect } from 'react'
import { Tabs, Tab } from 'tabs'
import SmsEnrollmentInstruction from './SmsEnrollmentInstruction'
import LoaderFull from 'loader-full'
import BrandsContext from '../contexts/BrandsContext'
import { CampaignContext } from '../contexts/CampaignContext'
import { SmsNumberCampaignContext } from '../contexts/SmsNumberCampaignContext'
import { getValue } from 'remote-config-value'
import { makeStyles } from '@material-ui/core/styles'
import Enrollment from './Enrollment'
import { EnrollmentType } from '../interface/EnrollmentType'
import { getPhoneCom } from 'phonecom'
import { getFeatureEnabled } from 'feature-flag'
import { ScreenSizeContext } from 'providers'

const styles = makeStyles((theme) => ({
    spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    smsEnrollmentInstructionContainer: { paddingTop: '1rem', height: '100%', overflowY: 'auto' },
    tabsRoot: {
        width: '100%',
        "& [role='tablist']": {
            overflowX: 'auto'
        }
    }
}))

/**
 *
 */
function SmsEnrollmentTabs (): JSX.Element {
    const classes = styles()
    const [currentTab, setCurrentTab] = useState(0)
    const [showHelp, setShowHelp] = useState(false)

    const [previewBrandFeature, setPreviewBrandFeature] = useState(false)
    const [previewCampaignFeature, setPreviewCampaignFeature] = useState(false)

    const [deleteSmsNumberFeature, setDeleteSmsNumberFeature] = useState(false)
    const [deleteCampaignFeature, setDeleteCampaignFeature] = useState(false)
    const [deleteBrandFeature, setDeleteBrandFeature] = useState(false)

    const [editBrandFeature, setEditBrandFeature] = useState(false)
    const [editCampaignFeature, setEditCampaignFeature] = useState(false)

    useEffect(() => {
        getFeatureEnabled('ten_dlc_delete_campaign_number_enabled').then(setDeleteSmsNumberFeature)
        getFeatureEnabled('ten_dlc_delete_campaign_enabled').then(setDeleteCampaignFeature)
        getFeatureEnabled('ten_dlc_delete_brand_enabled').then(setDeleteBrandFeature)
        getFeatureEnabled('ten_dlc_preview_brand_enabled').then(setPreviewBrandFeature)
        getFeatureEnabled('ten_dlc_preview_campaign_enabled').then(setPreviewCampaignFeature)
        getFeatureEnabled('ten_dlc_edit_brand_enabled').then(setEditBrandFeature)
        getFeatureEnabled('ten_dlc_edit_campaign_enabled').then(setEditCampaignFeature)
    }, [])

    const {
        networkPending: brandNetworkPending, verifiedBrandExists, enrollmentEnabled,
        brands, fetchBrands, deleteBrand, addBrand, editBrand,
        initialFetchParams: initialBrandFetchParams, brandNetworkError, setBrandNetworkError
    } = useContext(BrandsContext)
    const {
        networkPending: campaignNetworkPending, acceptedCampaignsExists,
        campaigns,
        fetchCampaigns, deleteCampaign, addCampaign, editCampaign,
        initialFetchParams: initialCampaignFetchParams, campaignNetworkError, setCampaignNetworkError
    } = useContext(CampaignContext)
    const {
        networkPending: smsnumberNetworkPending,
        campaignsWithNumber, deleteNumber, addNumber,
        numberNetworkError, setNumberNetworkError
    } = useContext(SmsNumberCampaignContext)

    const onTabChangeEventHandler = (event, value) => {
        setCurrentTab(value)
    }

    const getDeleteBrandParams = (brand) => {
        const params = {
            csp_brand_id: brand.csp_brand_id
        }
        return params
    }

    const getDeleteCampaignParams = (campaign) => {
        const params = {
            csp_campaign_id: campaign.csp_campaign_id,
            mock: campaign.is_mock === 'Y',
            onSuccess: () => {
                fetchBrands(initialBrandFetchParams)
            }
        }

        return params
    }

    const getDeleteSmsNumberParams = async (smsNumber) => {
        const phonecom = await getPhoneCom()
        const params = {
            account_id: phonecom.voip_id,
            csp_campaign_id: smsNumber?.csp_campaign_id,
            voip_did_ids: [smsNumber?.voip_did_id],
            delete: true,
            onSuccess: () => {
                fetchCampaigns(initialCampaignFetchParams)
            }
        }

        return params
    }

    const getAddBrandParams = async (brandStepWizardData) => {
        const phonecom = await getPhoneCom()
        brandStepWizardData.account_id = phonecom.voip_id
        const networkParams = {
            ...brandStepWizardData,
            mock: brandStepWizardData.business_address.mock
        }
        delete networkParams.business_address.mock
        return networkParams
    }

    const getEditBrandParams = async (brandStepWizardData, editing) => {
        const brand = { ...brandStepWizardData }
        const networkParams = {
            ...brandStepWizardData,
            csp_brand_id: editing.csp_brand_id,
            email: brand.business_address.supportEmail,
            website: brand.business_address.bizWebsite,
            street: brand.business_address.officeNumber,
            city: brand.business_address.city,
            state: brand.business_address.state,
            postalCode: brand.business_address.postalCode,
            mobile_phone: brand.business_address.primaryBusinessPhone,
            phone: brand.business_address.supportPhone,
            country: brand.business_info.registrationCountry,
            displayName: brand.business_info.brandName,
            companyName: brand.business_info.legalBizName,
            ein: brand.business_info.federalBusinessID,
            vertical: brand.business_info.selectedVerticle,
            entityType: brand.business_info.selectedBizType
        }
        return networkParams
    }

    const getAddCampaignParams = async (campaignStepWizardData) => {
        const phonecom = await getPhoneCom()
        campaignStepWizardData.account_id = phonecom.voip_id
        const params = {
            ...campaignStepWizardData,
            campaign_submit: {},
            brandId: campaignStepWizardData.campaign_usage.selectedBrand.csp_brand_id,
            onSuccess: () => {
                fetchBrands(initialBrandFetchParams)
            }
        }
        return params
    }

    const getEditCampaignParams = async (campaignStepWizardData, editing) => {
        const phonecom = await getPhoneCom()
        campaignStepWizardData.account_id = phonecom.voip_id
        const networkParams = {
            ...campaignStepWizardData,
            csp_campaign_id: editing.csp_campaign_id,
            description: campaignStepWizardData.campaign_usage.campaignDescription,
            sample1: campaignStepWizardData.campaign_sms_content.messageOne,
            sample2: campaignStepWizardData.campaign_sms_content.messageTwo,
            sample3: campaignStepWizardData.campaign_sms_content.messageThree,
            messageFlow: campaignStepWizardData.campaign_sms_content.messageFlow,
            optinKeywords: campaignStepWizardData.campaign_message.optInKeywords,
            optoutKeywords: campaignStepWizardData.campaign_message.optOutKeywords,
            helpKeywords: campaignStepWizardData.campaign_message.helpKeywords,
            optinMessage: campaignStepWizardData.campaign_message.optInMessage,
            optoutMessage: campaignStepWizardData.campaign_message.optOutMessage,
            helpMessage: campaignStepWizardData.campaign_message.helpMessage
        }
        return networkParams
    }

    const brandEditDisabledHelper = (row) => {
        const isDeleted = row.deleted_at != null
        const disabled = isDeleted || !enrollmentEnabled

        return {
            editDisabled: disabled,
            editTooltip:
                disabled
                    ? !enrollmentEnabled
                        ? getValue('sms_enrollment_feature_disabled_tooltip')
                        : isDeleted
                            ? getValue('sms_enrollment_enrollment_status_deleted_tooltip')
                            : null
                    : null
        }
    }

    const includeBrandEditInMenu = (row) => {
        return row.registration_status !== '' && !['pending-review', 'submitted', 'processing'].includes(row.registration_status)
    }

    const includeCampaignEditInMenu = (row) => {
        return row.status !== '' && !['pending-review', 'submitted', 'processing'].includes(row.status)
    }

    const campaignEditDisabledHelper = (row) => {
        const associatedBrand = brands.find(x => row.csp_brand_id === x.csp_brand_id)
        const isDeleted = row.deleted_at != null
        const invalidBrandStatus = associatedBrand?.registration_status === 'pending-review'
        const disabled = isDeleted || invalidBrandStatus || !enrollmentEnabled

        return {
            editDisabled: disabled,
            editTooltip:
                disabled
                    ? (
                        !enrollmentEnabled
                            ? getValue('sms_enrollment_feature_disabled_tooltip')
                            : isDeleted
                                ? getValue('sms_enrollment_enrollment_status_deleted_tooltip')
                                : invalidBrandStatus
                                    ? getValue('sms_enrollment_enrollment_status_pending_tooltip')
                                    : null)
                    : null
        }
    }

    const getAddSmsNumberParams = async (smsNumber) => {
        const phonecom = await getPhoneCom()
        const params = {
            account_id: phonecom.voip_id,
            csp_campaign_id: smsNumber.campaign_step.selectedCampaign.csp_campaign_id,
            voip_did_ids: smsNumber.number_step.selectedPhoneNumber.map(x => x.voip_did_id),
            delete: false,
            onSuccess: () => {
                fetchCampaigns(initialCampaignFetchParams)
            }
        }
        return params
    }

    const brandTableHeadColumns = [
        { content: getValue('sms_enrollment_brand_id_column_name') },
        { content: getValue('sms_enrollment_brand_column_name') },
        { content: getValue('sms_enrollment_registered_column_name') },
        { content: getValue('sms_enrollment_status_column_name') },
        { content: '' }
    ]
    const campaignTableHeadColumns = [
        { content: getValue('sms_enrollment_campaign_id_column') },
        { content: getValue('sms_enrollment_status_column_name') },
        { content: getValue('sms_enrollment_brand_column_name') },
        { content: getValue('sms_enrollment_registered_column_name') },
        { content: '' }
    ]
    const smsNumberTableHeadColumns = [
        { content: getValue('sms_enrollment_campaign_id_column') },
        { content: getValue('sms_enrollment_sms_number_column_name') },
        { content: '' }
    ]

    const renderCurrentTab = () => {
        switch (currentTab) {
                case EnrollmentType.Brand:
                    return (<Enrollment
                        key='brandEnrollment'
                        enrollmentType={EnrollmentType.Brand}
                        itemType='10dlc_sms_brand_delete'
                        acknowledgedTitle='Delete brand'
                        deleteEnrollmentFeature={deleteBrandFeature}
                        previewEnrollmentFeature={previewBrandFeature}
                        editEnrollmentFeature={editBrandFeature}
                        getDeleteParams={getDeleteBrandParams}
                        deleteEnrollment={deleteBrand}
                        getAddParams={getAddBrandParams}
                        getEditParams={getEditBrandParams}
                        addEnrollment={addBrand}
                        editEnrollment={editBrand}
                        networkError={brandNetworkError}
                        setNetworkError={setBrandNetworkError}
                        editDisabledHelper={brandEditDisabledHelper}
                        enrollmentEnabled={enrollmentEnabled}
                        enrollmentDisabledTooltip={getValue('sms_enrollment_feature_disabled_tooltip')}
                        includeEditInMenu={includeBrandEditInMenu}
                        enrollments={brands}
                        newEnrollmentRemoteID='sms_enrollment_add_brand_button'
                        tableHeadColumns={brandTableHeadColumns}
                    />)
                case EnrollmentType.Campaign:
                    return (<Enrollment
                        key='campaignEnrollment'
                        enrollmentType={EnrollmentType.Campaign}
                        itemType='10dlc_campaign_delete'
                        acknowledgedTitle='Delete campaign'
                        deleteEnrollmentFeature={deleteCampaignFeature}
                        previewEnrollmentFeature={previewCampaignFeature}
                        editEnrollmentFeature={editCampaignFeature}
                        getDeleteParams={getDeleteCampaignParams}
                        deleteEnrollment={deleteCampaign}
                        getAddParams={getAddCampaignParams}
                        getEditParams={getEditCampaignParams}
                        addEnrollment={addCampaign}
                        editEnrollment={editCampaign}
                        networkError={campaignNetworkError}
                        setNetworkError={setCampaignNetworkError}
                        editDisabledHelper={campaignEditDisabledHelper}
                        enrollmentEnabled={enrollmentEnabled}
                        enrollmentDisabledTooltip={getValue('sms_enrollment_feature_disabled_tooltip')}
                        includeEditInMenu={includeCampaignEditInMenu}
                        enrollments={campaigns}
                        newEnrollmentRemoteID='sms_enrollment_add_new_campaign'
                        tableHeadColumns={campaignTableHeadColumns}
                    />)
                case EnrollmentType.SmsNumber:
                    return (<Enrollment
                        key='smsNumberEnrollment'
                        enrollmentType={EnrollmentType.SmsNumber}
                        itemType='10dlc_sms_number_delete'
                        acknowledgedTitle='Delete association'
                        deleteEnrollmentFeature={deleteSmsNumberFeature}
                        getDeleteParams={getDeleteSmsNumberParams}
                        deleteEnrollment={deleteNumber}
                        getAddParams={getAddSmsNumberParams}
                        addEnrollment={addNumber}
                        networkError={numberNetworkError}
                        setNetworkError={setNumberNetworkError}
                        enrollmentEnabled={enrollmentEnabled}
                        enrollmentDisabledTooltip={getValue('sms_enrollment_feature_disabled_tooltip')}
                        enrollments={campaignsWithNumber}
                        newEnrollmentRemoteID='sms_enrollment_add_new_number'
                        tableHeadColumns={smsNumberTableHeadColumns}
                    />)
        }
    }

    const screenSize = useContext(ScreenSizeContext)
    const sidebarHidden = screenSize.mobile || screenSize.tablet

    return (
        <div className={classes.smsEnrollmentInstructionContainer}>
            { (brandNetworkPending || campaignNetworkPending || smsnumberNetworkPending)
                ? <LoaderFull styles={{ loaderFull: { left: sidebarHidden ? '50%' : 'calc(50% + 120px)' } }} size='big'/>
                : showHelp
                    ? (
                        <SmsEnrollmentInstruction proceedClicked={() => setShowHelp(false)} />
                    )
                    : (
                        <>
                            <Tabs value={currentTab} onChange={onTabChangeEventHandler} classes={{ root: classes.tabsRoot }}>
                                <Tab label={getValue('sms_enrollment_brand_tab_title')} />
                                <Tab
                                    disabled={!verifiedBrandExists}
                                    label={getValue('sms_enrollment_campaign_tab_title')} />
                                <Tab
                                    disabled={!acceptedCampaignsExists}
                                    label={getValue('sms_enrollment_did_tab_title')} />
                            </Tabs>
                            {renderCurrentTab()}
                        </>
                    )}

        </div>)
}

export default SmsEnrollmentTabs
