/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { getPhoneCom } from 'phonecom'

import { connect } from 'react-redux'
import {
    updateMessages, insertMessages, updateMediaInfo, updateConversationTotal, switchSendNumber, updateConversations,
    updateConversation, switchConversation, addContactsToConversations, removeContactFromConversations
} from '../../actions/conversations.js'

import api from '../../util/api_v2'
import Api from 'api'

import { isPhoneNumberValid } from 'phone-numbers'

import ConversationHeader from './ConversationHeader'
import ConversationMessages from './ConversationMessages'
import SendMessage from './SendMessage'
import SearchMessages from './SearchMessages'
import ParticipantsModal from 'participants-modal'
import LoaderFull from 'loader-full'
import PDCOpenConnection from 'pdc-open-connection'
import ReactResizeDetector from 'react-resize-detector'
import PhoneComUser from 'phone-com-user'
import EditContactModal from 'edit-contact-modal'
import { PdcCallConsumer } from 'pdc-calls'
import { getFeatureEnabled } from 'feature-flag'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
    searchedMessagesWrapper: {
        '&>div': {
            height: 'initial'
        }
    }
})

const mapStateToProps = state => {
    return {
        conversations: state.conversations,
        sendNumber: state.selectedSendNumber,
        smallView: state.smallView
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateMessages: (messages, conv_id) => dispatch(updateMessages(messages, conv_id)),
        insertMessages: (p, si, msg, conv_id) => dispatch(insertMessages(p, si, msg, conv_id)),
        updateMediaInfo: (mediaInfo, conv_id) => dispatch(updateMediaInfo(mediaInfo, conv_id)),
        updateTotal: (total, conv_id) => dispatch(updateConversationTotal(total, conv_id)),
        switchSendNumber: num => dispatch(switchSendNumber(num)),
        updateConversations: convs => dispatch(updateConversations(convs)),
        updateConversation: conv => dispatch(updateConversation(conv)),
        switchConversation: conv => dispatch(switchConversation(conv)),
        addContactsToConversations: contacts => dispatch(addContactsToConversations(contacts)),
        removeContactFromConversations: contactId => dispatch(removeContactFromConversations(contactId))
    }
}

class ConversationContent extends Component {
    constructor (props) {
        super(props)
        this.state = {
            messages: [],
            loading: false,
            hasMoreMessages: false,
            refreshIntervalIsSet: false,
            toNumbers: this.props.conversation.to.map(t => t ? t.number : null),
            messageToBeSent: null,
            hoverOverParticipants: false,
            showConfigureBridgePopup: false,
            editContact: null,
            loadingMessagesStats: [],
            showFindBox: true,
            searchEnabled: false
        }
        PDCOpenConnection.onReconnect(this.reloadMessages)
        this.markingRead = false
    }

    componentDidMount () {
        this._ismounted = true
        getFeatureEnabled('search-messages').then(enabled => this.setState({ searchEnabled: enabled }))
        if (this.props.conversation) {
            if (!this.props.conversation.mediaInfo) {
                this.updateMediaInfoState(0, [], { first_cursor: 0, last_cursor: 0 })
            }
            this.loadInitialMessages(true)
            if (this.props.conversation.from[0]) {
                this.props.switchSendNumber(this.props.conversation.from[0].number)
            }
        }
        this.check10dlcBlock()
    }

    check10dlcBlock = async () => {
        await getPhoneCom().then(res => {
            const canSendMessage = res?.features?.has('send_message_enabled')
            const notBasicUser = res?.user_account_type !== 'BASIC_USER_TIERED'
            console.log('10dlc', canSendMessage, notBasicUser, !(canSendMessage && notBasicUser))
            this.setState({ show10dlcBlockMessage: !(canSendMessage && notBasicUser) })
        })
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        function areArraysDifferent (a1, a2) {
            if (a1.length !== a2.length) {
                return true
            }

            let response = false
            a1.forEach(e => {
                if (!a2.includes(e)) {
                    response = true
                }
            })

            return response
        }

        if (
            !prevState.conversation ||
            nextProps.conversation.id !== prevState.conversation.id ||
            areArraysDifferent(nextProps.conversation.to.map(t => t.number), prevState.conversation.to.map(t => t.number))
        ) {
            return {
                conversation: nextProps.conversation,
                loading: true
            }
        }
        return {}
    }

    componentDidUpdate (prevProps) {
        if (this.props.extension.phone_number !== prevProps.extension.phone_number) {
            if (typeof (this.props.sendNumber) === 'string' && !(this.props.sendNumber in this.props.extension.phone_number)) {
                this.props.switchSendNumber(null)
            } else if (!this.props.sendNumber) {
                const myNumbers = this.props.conversation.participants.filter(p => p.number in this.props.extension.phone_number).map(p => p.number)
                this.props.switchSendNumber(myNumbers)
            }
        }

        this.updateEditContactIfNeeded(prevProps)

        const currentConversation = this.props.conversation
        this.scrollToMessage()

        if (currentConversation && !currentConversation.mediaInfo) {
            this.updateMediaInfoState(0, [], { first_cursor: 0, last_cursor: 0 })
        }

        if (this.state.loading && (!currentConversation.messages || !currentConversation.messages.length)) {
            this.loadInitialMessages()
        } else if (this.state.loading) {
            this.setState({ loading: false })
        }

        const from_numbers = currentConversation.from.map(f => f ? f.number : null)
        const first_from = currentConversation.from[0]
        if (!from_numbers.includes(this.props.sendNumber)) {
            this.props.switchSendNumber(first_from ? first_from.number : null)
        }

        const conversation = this.props.conversation
        if (conversation.messages && conversation.messages.filter(m => m.read_at === null).length && this.props.userActive) {
            let read_at = `${(new Date()).getTime()}`
            read_at = parseInt(read_at.substring(0, read_at.length - 3), 10)

            if (this.markingRead) {
                return
            }

            this.markingRead = true

            if (!currentConversation.pauseMarkingRead) {
                currentConversation.messages.forEach(m => {
                    if (!m.read_at) {
                        m.read_at = parseInt((new Date()).getTime() / 1000)
                    }
                })
                currentConversation.readStatusChangedAt = parseInt(Date.now())
                this.props.updateConversation(currentConversation)
                api.markConversationRead(currentConversation.id, read_at)
                    .then(response => {
                        if (response.data && response.data.status === 'OK') {
                            conversation.messages.forEach(m => {
                                if (m.read_at === null) {
                                    m.read_at = read_at
                                }
                            })
                            conversation.unread_messages = 0
                        }
                        this.markingRead = false
                    }, error => {
                        this.markingRead = false
                    })
            }
        }

        if (this.props.deletedMessages.randomString !== prevProps.deletedMessages.randomString && this.props.deletedMessages.num) {
            this.loadMoreMessages(this.props.deletedMessages.num)
        }

        if (prevProps.isOffline && !this.props.isOffline) this.adjustView()
    }

    scrollToMessage = () => {
        const currentConversation = this.props.conversation
        if (!currentConversation || !currentConversation.scrollToMessage) return

        const scrollElement = document.getElementsByClassName('scroll-here')[0]
        const contentItems = document.getElementById('content-items')
        if (!scrollElement) return
        if (!contentItems) return

        const infiniteScroller = contentItems.childNodes[0]
        infiniteScroller.scrollTop = scrollElement.offsetTop - 75
        currentConversation.scrollToMessage = null
        this.props.updateConversation(currentConversation)
    }

    adjustView = () => {
        this.loadInitialMessages()
    }

    componentWillUnmount () {
        clearInterval(this.refreshInterval)
        this._ismounted = false
    }

    updateEditContactIfNeeded = prevProps => {
        if (prevProps.extraContacts.length === this.props.extraContacts.length || !this.state.editContact || this.state.editContact.id) return
        const editContact = this.state.editContact
        const number = editContact.number
        let contactId = null
        this.props.extraContacts.forEach(cd => {
            if (cd.numbers.find(n => n.number === number)) contactId = cd.id
        })
        if (!contactId) return
        editContact.id = contactId
        this.setState({ editContact })
    }

    reloadMessages = () => {
        if (this._ismounted) {
            this.loadInitialMessages()
        }
    }

    loadInitialMessages = async onMount => {
        // Don't refresh if there are already messages stored
        if (this.props.conversation.messages && this.props.conversation.checkedMessages && this._ismounted) {
            this.setState({
                loading: false,
                hasMoreMessages: true
            })
            return
        }

        const conversationBeforeLoad = this.props.conversation

        let filters = {}
        if (this.props.conversation.id && this.props.conversation.id !== 'new-conversation') {
            filters = { conversation_id: this.props.conversation.id }
        } else {
            filters = { participants: this.props.conversation.participants.map(p => p.number).filter(p => p) }
        }

        // TODO: This happens twice on load. The second call is redundant. Fix it
        if (this.isLoadingAlready(filters)) return
        const loadingMessagesStats = this.state.loadingMessagesStats
        loadingMessagesStats.push(filters)
        this.setState({ loadingMessagesStats })

        const messagesReponse = await api.loadMessages(0, 30, filters)
        if (messagesReponse === 'network-error') return

        if (onMount === true) this.props.onLoaded() // This goes up to Communicator

        const messages = messagesReponse.messages
        const total = messagesReponse.total

        this.props.updateMessages(messages, conversationBeforeLoad.id)
        this.props.updateTotal(total, conversationBeforeLoad.id)

        if (conversationBeforeLoad.id !== this.props.conversation.id) return

        if (!this.props.conversation.id || this.props.conversation.id === 'new-conversation') {
            this.assignIdToNewConversation(messagesReponse)
        }

        this.storeMedia(messages, messagesReponse.mediaIndexes)
        this.removeFromLoadingState(filters)
        if (this._ismounted) {
            this.setState({
                messages,
                loading: false,
                hasMoreMessages: messages.length < total
            })
        }
    }

    assignIdToNewConversation = messagesReponse => {
        const conversations = this.props.conversations
        const foundConversation = conversations.find(conversation => {
            let isFound = true
            const conversationParticipantNumbers = conversation.participants.map(p => p.number)
            const thisParticipantsNumbers = this.props.conversation.participants.map(p => p.number)

            if (conversationParticipantNumbers.length !== thisParticipantsNumbers.length) return null
            thisParticipantsNumbers.forEach(phoneNumber => {
                if (!conversationParticipantNumbers.includes(phoneNumber)) isFound = false
            })
            return isFound
        })

        if (!foundConversation) return

        if (!messagesReponse.filters) console.log('Rollbar log:', JSON.stringify(messagesReponse))
        foundConversation.id = messagesReponse.filters.conversation_id
        const lastMessage = messagesReponse.messages.length ? messagesReponse.messages[messagesReponse.messages.length - 1] : {}
        foundConversation.last_message = lastMessage

        if (window.location.pathname.split('/')[3] !== 'new-conversation') {
            // By this the conversation id will be added to the pathname
            this.props.switchConversation(foundConversation)
        }
    }

    isLoadingAlready = filters => {
        const loadingMessagesStats = this.state.loadingMessagesStats
        return loadingMessagesStats.find(s => {
            if (filters.conversation_id) return s.conversation_id === filters.conversation_id
            return s.participants.length === filters.participants.length && s.participants.find(p => filters.participants.includes(p))
        })
    }

    removeFromLoadingState = filters => {
        const loadingMessagesStats = this.state.loadingMessagesStats
        const newLoadingMessagesStats = loadingMessagesStats.filter(s => {
            if (filters.conversation_id) return s.conversation_id !== filters.conversation_id
            return s && s.participants && (s.participants.length !== filters.participants.length || !s.participants.find(p => filters.participants.includes(p)))
        })
        this.setState({ loadingMessagesStats: newLoadingMessagesStats })
    }

    generateModalContentData (url) {
        if (!this.props.conversation.mediaInfo) return null

        const conversation = this.props.conversation
        conversation.mediaInfo.media.forEach(item => {
            item.selected = item.url === url
        })
        return conversation.mediaInfo
    }

    loadMoreMessages = async (limit = 30) => {
        const messages = this.props.conversation.messages
        if (!messages || !messages.length) return new Promise(() => { return null })

        const earliestMsgTime = messages[0].created_at
        const filters = {
            conversation_id: { in: this.props.conversation.id },
            created_before: earliestMsgTime
        }
        return api.loadMessages(0, limit, filters).then(
            response => {
                if (response === 'network-error') return
                messages.unshift(...response.messages)
                const total = response.total
                response.mediaIndexes.first_cursor = this.props.conversation.mediaInfo.firstCursor
                this.storeMedia.bind(this)(messages, response.mediaIndexes)
                this.props.updateMessages(messages, this.props.conversation.id)
                this.props.updateTotal(total, this.props.conversation.id)
                if (this._ismounted) {
                    this.setState({
                        messages,
                        hasMoreMessages: response.messages.length === limit
                    })
                }
            }
        )
    }

    storeMedia = (messages, mediaIndexes) => {
        let media = []
        messages.filter(m => m.media.length).forEach(m => {
            m.media.forEach(md => {
                md.created_at = m.created_at
                md.from = m.from
                md.message_id = m.message_id
            })
            media = media.concat(m.media)
        })
        const updateMediaInfoState = this.updateMediaInfoState
        // Load media in order to see total number of media
        const filters = { file_type: 'not-in:smil' }
        api.loadMedia(this.props.conversation.id, filters, null, null, 0, 1)
            .then(response => {
                // Set width and height of all media if it is image
                media.forEach(m => {
                    if (m.type.substring(0, 5) === 'image' && !m.width) {
                        const image = new Image()
                        image.onload = function () {
                            m.width = this.width
                            m.height = this.height
                            try {
                                updateMediaInfoState(response.total, media, mediaIndexes)
                            } catch (e) {
                                console.error('Error on load image.', e)
                            }
                        }
                        image.src = m.url
                    } else {
                        updateMediaInfoState(response.total, media, mediaIndexes)
                    }
                })

                if (!media.length) {
                    this.updateMediaInfoState(0, [], { first_cursor: 0, last_cursor: 0 })
                }
            })
    }

    updateMediaInfoState = (total, media, mediaIndexes) => {
        const mediaInfo = {
            media: media,
            total: media.length,
            conversationId: this.props.conversation.id,
            dbTotal: total,
            lastCursor: mediaIndexes.last_cursor,
            firstCursor: mediaIndexes.first_cursor,
            offset: 0
        }

        this.props.updateMediaInfo(mediaInfo, this.props.conversation.id)
    }

    storeNewMedia (messageResponse) {
        const mediaInfo = this.props.conversation.mediaInfo

        messageResponse.media.forEach(m => {
            if (m.type.substring(0, 5) === 'image' && !m.width) {
                const newMedia = {
                    created_at: messageResponse.created_at,
                    size: m.size,
                    type: m.type,
                    url: m.url,
                    from: messageResponse.from
                }

                const image = new Image()
                image.onload = function () {
                    newMedia.width = this.width
                    newMedia.height = this.height

                    if (!mediaInfo) {
                        console.error(`Media info not initialized for conversation id: ${this.props.conversation.id} yet`)
                        return
                    }

                    mediaInfo.media.push(newMedia)
                    mediaInfo.offset++
                    mediaInfo.dbTotal++
                    mediaInfo.total++

                    this.props.updateMediaInfo(mediaInfo, this.props.conversation.id)
                }
                image.src = newMedia.url
            }
        })
    }

    renderConversationMessages = () => (
        <ConversationMessages
            messages = {this.props.conversation.messages || []}
            loadMore = {this.loadMoreMessages}
            hasMoreMessages = {this.state.hasMoreMessages}
            isGroup = {Object.keys(this.props.conversation.participants).length > 1}
            participants = {this.props.conversation.participants}
            openModal = {this.props.openModal}
            generateModalContentData = {this.generateModalContentData.bind(this)}
            setMessageToBeSent = {this.setMessageToBeSent}
            changeMessageReadStatus = {this.props.changeMessageReadStatus}
            extraContacts = {this.props.extraContacts}
            updateRouterHistory = {this.props.updateRouterHistory}
            onMessageDeleted = {() => this.loadMoreMessages(1)}
            makeCall = {this.props.makeCall}
            extension = {this.props.extension}
            goTo = {this.props.goTo}
        />
    )

    changeNumber = num => {
        return new Promise(resolve => {
            this.props.switchSendNumber(num)
            resolve(true)
        })
    }

    setMessageToBeSent = (message) => {
        this.setState({
            messageToBeSent: message
        })
    }

    removeMessageToBeSent = () => {
        this.setState({
            messageToBeSent: null
        })
    }

    toggleConfigureBridgePopup = showConfigureBridgePopup => {
        this.setState({ showConfigureBridgePopup })
        if (showConfigureBridgePopup) {
            setTimeout(() => { this.setState({ showConfigureBridgePopup: false }) }, 10000) // Have it shown 10 seconds if not clicked
        }
    }

    renderConfigureBridgePopup () {
        return this.state.showConfigureBridgePopup
            ? (
                <div className='configure-bridge-popup'>
                    <a href={PhoneComUser.getControlPanelConferenceUrl()} target='_blank' onClick={() => this.toggleConfigureBridgePopup(false) } rel="noopener noreferrer">
                        <span>Enable video integration on the extensions conferencing page</span>
                    </a>
                </div>
            )
            : null
    }

    startChat = phoneNumber => {
        const extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
        const redirectPath = `/e${extensionId}/messages/new-conversation/${phoneNumber}`
        this.props.redirect(redirectPath)
    }

    editContact = (id, number) => {
        this.setState({ editContact: { id, number: id ? '' : number } })
    }

    toggleParticipantsHover = hoverOverParticipants => {
        if (this.state.hoverOverParticipants !== hoverOverParticipants) {
            this.setState({ hoverOverParticipants })
        }
    }

    renderAllParticipants () {
        const fromNumbers = this.props.conversation.from
        const recipients = JSON.parse(JSON.stringify(this.props.conversation.to))
            .filter(r => {
                if (typeof r === 'string') {
                    const parsedValue = isPhoneNumberValid(r)
                    return parsedValue.isValid()
                }
                return true
            })
        // const extraContacts = this.props.extraContacts
        recipients.forEach((r, index, array) => {
            if (typeof r === 'string') {
                array[index] = {
                    contactId: -1,
                    number: r
                }
            } else {
                r.contactId = r.voip_contact_id
                if (!r.contactId) {
                    const matchedContact = this.props.extraContacts?.find(extraContact => {
                        const matchedContact = extraContact.numbers.some(numberObject => {
                            if (numberObject.number === r.number) {
                                return true
                            }
                            return false
                        })
                        return matchedContact
                    })
                    if (matchedContact) {
                        r.contactId = matchedContact.id
                    }
                    console.log('matchedContact', matchedContact)
                }
                delete r.voip_contact_id
            }
        })
        fromNumbers.forEach(n => {
            const extensionPhoneNumbers = this.props.extension.phone_number
            n.numberNickname = extensionPhoneNumbers[n.number] ? extensionPhoneNumbers[n.number].name : ''
        })

        return (
            <PdcCallConsumer>
                {(context) => (
                    <ParticipantsModal
                        selectedNumber = {this.props.sendNumber}
                        myNumbers = {fromNumbers}
                        otherNumbers = {recipients}
                        participantsHovered = {this.state.hoverOverParticipants}
                        changeNumber = {this.changeNumber}
                        startChat = {this.startChat}
                        editContact = {this.editContact}
                        makeCall = {this.props.makeCall}
                        extension = {this.props.extension}
                        isCallButtonDisabled={!context.canPlaceOutgoingCall}
                    />
                )}
            </PdcCallConsumer>
        )
    }

    messagesSectionScrolled = () => {
        const messagesSectionInfiniteScroller = document.getElementsByClassName('conversation-messages-wrapper')[0].children[0].children[0]
        this.scrollDetails = {
            scrollTop: messagesSectionInfiniteScroller.scrollTop,
            scrollHeight: messagesSectionInfiniteScroller.scrollHeight,
            offsetHeight: messagesSectionInfiniteScroller.offsetHeight
        }
    }

    messagesSectionResized = () => {
        if (!this.scrollDetails) return
        const messagesSectionInfiniteScroller = document.getElementsByClassName('conversation-messages-wrapper')[0].children[0].children[0]
        const offsetChange = this.scrollDetails.offsetHeight - messagesSectionInfiniteScroller.offsetHeight
        messagesSectionInfiniteScroller.scrollTop += offsetChange
        this.scrollDetails = {
            scrollTop: messagesSectionInfiniteScroller.scrollTop,
            scrollHeight: messagesSectionInfiniteScroller.scrollHeight,
            offsetHeight: messagesSectionInfiniteScroller.offsetHeight
        }
    }

    getContact = () => {
        const extraContacts = this.props.extraContacts
        const editContact = this.state.editContact
        const contactId = editContact ? editContact.id : null
        if (!editContact || !contactId || !extraContacts) return null
        let contact = null
        // eslint-disable-next-line no-return-assign
        this.props.extraContacts.forEach(c => c.id === contactId ? contact = c : null)
        return contact
    }

    saveContact = contact => {
        const extraContacts = this.props.extraContacts
        const isNew = !extraContacts.find(c => c.id === contact.id)
        this.props.updateContact(contact)
        if (isNew) this.props.addContactsToConversations([contact])
        this.setState({ editContact: null })
    }

    deleteContact = contactId => {
        this.props.deleteContact(contactId)
        this.setState({ editContact: null })
    }

    loadNextMessages = async (direction, message) => {
        const currentConversation = this.props.conversation
        const messages = currentConversation.messages
        // eslint-disable-next-line no-return-assign
        messages.forEach(m => m.message_id === message.message_id ? m.loadGapMessages = 'loading' : null)
        this.props.updateConversation(currentConversation)

        const filters = { conversation_id: currentConversation.id }
        const messageId = message.message_id
        const messageIndex = messages.findIndex(m => m.message_id === messageId)

        let responseMessages
        if (direction === 'next') {
            const searchResults = await Api.listInboxes(filters, 'message', message.cursor, 15, { created_at: 'asc' }, true)
            responseMessages = searchResults.items
        } else {
            const searchResults = await Api.listInboxes(filters, 'message', messages[messageIndex + 1].cursor, 15, { created_at: 'desc' }, true)
            responseMessages = searchResults.items
        }

        delete messages[messageIndex].loadGapMessages
        let newGapMessageIndex
        let numNewMessages = 0
        if (direction === 'next') {
            responseMessages.forEach(m => {
                if (m.created_at > messages[messageIndex + 1].created_at || (
                    m.created_at === messages[messageIndex + 1].created_at && m.message_id >= messages[messageIndex + 1].message_id
                )) return
                numNewMessages++
            })
            const newIndex = messageIndex + numNewMessages
            newGapMessageIndex = newIndex
        } else {
            responseMessages.forEach(m => {
                if (m.created_at < messages[messageIndex].created_at || (
                    m.created_at === messages[messageIndex].created_at && m.message_id <= messages[messageIndex].message_id
                )) return
                numNewMessages++
            })
            newGapMessageIndex = messageIndex
        }

        if (direction === 'next') {
            this.props.insertMessages('after', messageIndex + 1, responseMessages, this.props.conversation.id)
        } else {
            this.props.insertMessages('before', messageIndex, responseMessages, this.props.conversation.id)
        }

        const newGapMessage = messages[newGapMessageIndex]
        if (numNewMessages === responseMessages.length) newGapMessage.loadGapMessages = direction => this.loadNextMessages(direction, newGapMessage)
        this.props.updateConversation(currentConversation)

        // response.mediaIndexes.first_cursor = this.props.conversation.mediaInfo.firstCursor
        // this.storeMedia.bind(this)(messages, response.mediaIndexes)
        if (this._ismounted) {
            this.setState({ messages })
        }
    }

    showSearchedMessage = message => {
        // If message is shown then just highlight it
        const messageId = message.message_id
        const currentConversation = this.props.conversation
        const messages = currentConversation.messages

        const shownMessageIndex = messages.findIndex(m => m.message_id === messageId)
        if (shownMessageIndex !== -1) {
            let element = window.document.getElementById(`msg-${messages[shownMessageIndex].message_id}`)
            if (element.previousElementSibling) element = element.previousElementSibling
            element.scrollIntoView()

            messages[shownMessageIndex].isHighlighted = true
            this.props.updateConversation(currentConversation)
            setTimeout(() => {
                delete messages[shownMessageIndex].isHighlighted
                this.props.updateConversation(currentConversation)
            }, 2000)
            return
        }

        message.loadGapMessages = direction => this.loadNextMessages(direction, message)
        message.isHighlighted = true

        if (!messages || !messages.length) return
        // response.mediaIndexes.first_cursor = this.props.conversation.mediaInfo.firstCursor
        // this.storeMedia.bind(this)(messages, response.mediaIndexes)
        const afterIndex = messages.findIndex(m => m.created_at > message.created_at || (m.created_at === message.created_at && m.message_id > message.message_id))
        this.props.insertMessages('after', afterIndex, [message], currentConversation.id)
        if (this._ismounted) {
            this.setState({ messages })
            setTimeout(() => {
                let element = window.document.getElementById(`msg-${messageId}`)
                if (element.previousElementSibling) element = element.previousElementSibling
                element.scrollIntoView()
            }, 10)
            setTimeout(() => {
                delete message.isHighlighted
                this.props.updateConversation(currentConversation)
            }, 2000)
        }
    }

    scrollToNewest = () => {
        const contentItems = document.getElementById('content-items')
        if (contentItems) {
            const infiniteScroller = contentItems.childNodes[0]
            infiniteScroller.scrollTop = infiniteScroller.scrollHeight
        }
    }

    // onKeyDown = e => {
    //     if (e.key === 'Control') {
    //         this.setState({ctrlPressed: true})
    //     } else if (e.key === 'f') {
    //         this.setState({showFindBox: true})
    //         e.preventDefault()
    //     }
    // }

    // onKeyUp = e => e.key === 'Control' ? this.setState({ctrlPressed: false}) : null

    startConversation = phoneNumber => {
        const extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
        const redirectPath = `/e${extensionId}/messages/new-conversation/${phoneNumber}`
        this.props.redirect(redirectPath)
    }

    render () {
        return (
            <div className={'conversation-content' + (this.props.smallView ? ' mobile-view' : '')}>
                <ConversationHeader
                    currentConversation = {this.props.conversation}
                    setMessageToBeSent = {this.setMessageToBeSent}
                    participants = {this.props.conversation.to}
                    toggleParticipantsHover = {this.toggleParticipantsHover}
                    toggleConfigureBridgePopup = {this.toggleConfigureBridgePopup}
                    updateUnreadCounts = {this.props.updateUnreadCounts}
                    changeMessageReadStatus = {this.props.changeMessageReadStatus}
                    onConversationDeleted = {this.props.onConversationDeleted}
                    extraContacts = {this.props.extraContacts}
                    updateRouterHistory = {this.props.updateRouterHistory}
                />
                {this.state.showFindBox && this.state.searchEnabled
                    ? <SearchMessages
                        conversation = {this.props.conversation}
                        showMessage = {this.showSearchedMessage}
                        onClose = {() => this.setState({ showFindBox: false || true })}
                    />
                    : null}

                {this.renderAllParticipants()}
                {this.renderConfigureBridgePopup()}

                <div onScroll={this.messagesSectionScrolled} className='conversation-messages-wrapper'>
                    {!this.state.loading
                        ? this.renderConversationMessages()
                        : <LoaderFull/>}
                    <ReactResizeDetector handleWidth={false} handleHeight={true} onResize={this.messagesSectionResized}></ReactResizeDetector>
                </div>
                <SendMessage
                    isOffline = {this.props.isOffline}
                    participantNumbers = {this.props.conversation.participants.map(p => p.number)}
                    to_numbers = {this.state.toNumbers}
                    conversation = {this.props.conversation}
                    messageToBeSent = {this.state.messageToBeSent}
                    removeMessageToBeSent = {this.removeMessageToBeSent}
                    scrollToNewest = {this.scrollToNewest}
                    blockSendMessage = {false}
                />

                <EditContactModal
                    type = {this.state.editContact ? this.state.editContact.id ? 'Edit' : 'Add' : false}
                    onClose = {() => this.setState({ editContact: null })}
                    fixedNumber = {this.state.editContact ? this.state.editContact.number : null}
                    contact = {this.getContact()}
                    contactGroupTypes = {this.props.contactGroupTypes}
                    saveContact = {this.saveContact}
                    deleteContact = {this.deleteContact}
                    makeCall = {this.props.makeCall}
                    startConversation = {this.startConversation}
                    addGroup = {this.props.addGroup}
                    isVirtualExtension = {this.props.extension.is_virtual}
                    smallView = {this.props.smallView}
                />
            </div>
        )
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ConversationContent))
