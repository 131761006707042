import React, { useContext, useEffect, ChangeEvent, useState } from 'react'
import { useToggle } from 'hooks'
import { PdcCallContext } from 'pdc-calls'
import { Grid, IconButton, makeStyles } from '@material-ui/core'
import styles from './styles'
import Typography from 'typography'
import Dialpad from '../utils/Dialpad/Dialpad'
import ContactSelector from '../utils/ContactSelector/ContactSelector'
import { SoftphoneHeader } from '../../../Softphone'
import { SoftphoneCallsContext } from '../../SoftphoneCalls'
import { InputFieldHeader } from '../InCall/InCallView'
import { CallTransferIcon, AskFirstIcon, CheckIcon } from 'svg-icons/src'
import Spinner from 'spinner-2'
import SessionsBarSelector from '../../../Phonebar/SessionsBarSelector'
import CallControls from '../utils/CallControls/CallControls'
import { KeypadView, CallDescription } from '../InCall/InCallView'
import { SessionState } from 'sip.js'
import { ParseCalleePhoneNumber, ParseUserInput } from '../PlaceCall/PlaceCallView'
import { FeatureEventsContext } from 'providers/src'

const useStyles = makeStyles(styles)

interface TransferCallProps{
    call: (callee: string) => void
    transfer: (callee: string) => void
}
const TransferDialpadView = (props: TransferCallProps): JSX.Element => {
    const classes = useStyles()
    const [inputValue, setInputValue] = useState('')
    const [callee, setCallee] = useState('')
    const [showDialpad, setShowDialpad] = useToggle(true)
    const Softphone = useContext(SoftphoneCallsContext)
    const BackToCall = () => Softphone.CloseCallAction()
    const UpdateInputValue = (update: string) => { setInputValue(ParseUserInput(update)) }
    const onInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const number = event?.target?.value
        UpdateInputValue(number)
    }
    useEffect(() => { setCallee(ParseCalleePhoneNumber(inputValue)) }, [inputValue])
    const onKeyDown = (event) => {
        const BACKSPACE = 8
        if (inputValue.length <= 1 && event.keyCode === BACKSPACE) setShowDialpad(true)
        else if (event.key.length === 1) setShowDialpad(false)
    }
    const onDialpadClick = (char: string) => UpdateInputValue(inputValue + char)
    const TransferCall = () => props.transfer(callee)
    const CallFirst = () => props.call(callee)
    const disabledButtons = callee === ''
    return (<Grid container direction='column'>
        <Grid item>
            <SoftphoneHeader title={<Typography variant={'subtitle2'}>Transfer</Typography>}>
                <InputFieldHeader
                    onChange={onInputChange}
                    onBack={BackToCall}
                    value={inputValue}
                    onKeyDown={onKeyDown}
                />
            </SoftphoneHeader>
        </Grid>
        <Grid item>
            {showDialpad
                ? <Dialpad onClick={onDialpadClick}/>
                : <ContactSelector onSelect={UpdateInputValue} keyword={inputValue} height={317}/>}
        </Grid>
        <Grid item className={classes.transferCallOptions}>
            <div className={classes.transferCallButton}>
                <IconButton
                    onClick = {CallFirst}
                    disabled = {disabledButtons}
                    classes = {{ root: `${classes.transferButton} disable-dragging` }}
                >
                    <AskFirstIcon/>
                </IconButton>
                <Typography color={'primary'} variant={'overline'}>
                        CALL FIRST
                </Typography>
            </div>
            <div className={classes.transferCallButton}>
                <IconButton
                    onClick = {TransferCall}
                    disabled = {disabledButtons}
                    classes = {{ root: `${classes.transferButton} disable-dragging` }}
                >
                    <CallTransferIcon/>
                </IconButton>
                <Typography color={'primary'} variant={'overline'}>
                        TRANSFER
                </Typography>
            </div>
        </Grid>
    </Grid>)
}
interface InTransferProps{
    transferee: string
    transfer: (callee: string, warm: boolean) => void
    goBack: () => void
    transfered: boolean
}
function InTransferView (props: InTransferProps) : JSX.Element {
    const classes = useStyles()
    const PdcCalls: any = useContext(PdcCallContext)
    const Softphone = useContext(SoftphoneCallsContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    const transfereeSession = PdcCalls.calls[props.transferee]
    const [sessions, updateSessions] = useState([transfereeSession])
    const [target, setTarget] = useState('')
    const [transferDisabled, setTransferDisabled] = useToggle(true)
    useEffect(() => {
        const callId = PdcCalls.activeCallId
        if (target === '') {
            if (callId) {
                setTarget(callId)
                sessions.push(PdcCalls.calls[callId])
                updateSessions(sessions)
            }
        } else if (!props.transfered && !PdcCalls.calls[target]) {
            PdcCalls.switchCall(props.transferee)
            props.goBack()
        }
    }, [PdcCalls.callsCnt])
    const [hideKeypad, toggleHideKeypad] = useToggle(true)
    const OpenKeypad = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'transfer-call-toggle-keypad', value: true })
        toggleHideKeypad(false)
    }
    const CloseKeypad = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'transfer-call-toggle-keypad', value: true })
        toggleHideKeypad(true)
    }
    const Transfer = () => props.transfer(props.transferee, true)
    useEffect(() => {
        if (target !== '' && ![target, props.transferee].includes(PdcCalls.activeCallId)) {
            Softphone.CloseCallAction()
        }
        setTransferDisabled(PdcCalls.activeCallId !== target || PdcCalls.calls[target]?.session?.state !== SessionState.Established)
    }, [PdcCalls.activeCallId, PdcCalls.calls[target]?.session?.state, target])
    if (!hideKeypad) {
        return (
            <KeypadView
                closeKeypad={CloseKeypad}
                title={<Typography variant={'subtitle2'}>Transfer</Typography>}
            />
        )
    }
    return (<Grid container direction='column'>
        <Grid item>
            <SoftphoneHeader title={<Typography variant={'subtitle2'}>TRANSFER</Typography>}>
                <span><SessionsBarSelector sessions={sessions} disableMerge={true} variant={'call-view'}/></span>
            </SoftphoneHeader>
        </Grid>
        <Grid item>
            <CallDescription/>
        </Grid>
        <Grid item>
            <CallControls openKeypad={OpenKeypad} disableActions={true}/>
        </Grid>
        <Grid item className={classes.transferCallOptions}>
            <div className={classes.transferCallButton}>
                <IconButton
                    onClick={Transfer}
                    disabled={transferDisabled}
                    classes = {{ root: `${classes.transferButton} disable-dragging` }}
                >
                    <CallTransferIcon/>
                </IconButton>
                <Typography color={'primary'} variant={'overline'}>
                    TRANSFER
                </Typography>
            </div>
        </Grid>
    </Grid>)
}
function TransferInProgress (): JSX.Element {
    const classes = useStyles()
    return (<>
        <Spinner color={'#364047'} className={classes.progressIcon}/>
        <Typography variant={'body1'}>
            Transferring...
        </Typography>
    </>)
}
function TransferComplete (): JSX.Element {
    const classes = useStyles()
    return (<>
        <CheckIcon className={classes.progressIcon}/>
        <Typography variant={'body1'}>
            Transfer Complete
        </Typography>
    </>)
}
function TransferProgess (props: { transfered: boolean}): JSX.Element {
    const classes = useStyles()
    const [complete, setComplete] = useToggle(false)
    const [hidden, toggleHidden] = useToggle(true)
    const Calls = useContext(SoftphoneCallsContext)
    useEffect(() => {
        if (props.transfered) {
            toggleHidden(false)
            setTimeout(() => {
                setComplete(true)
            }, 3000)
        }
    }, [props.transfered])
    useEffect(() => {
        if (complete) {
            setTimeout(() => {
                Calls.CloseCallAction()
            }, 1500)
        }
    }, [complete])
    return (<div className={classes.progressOverlay} hidden={hidden}>
        {complete ? <TransferComplete/> : <TransferInProgress/>}
    </div>)
}
/**
 *
 */
function TransferCallView (): JSX.Element {
    const PdcCalls: any = useContext(PdcCallContext)
    const [onCall, setOnCall] = useToggle(false)
    const [transfered, setTransfered] = useToggle(false)
    const [transferee] = useState(PdcCalls.activeCallId)
    const Softphone = useContext(SoftphoneCallsContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    const CallFirst = async (callee: string) => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'transfer-call', value: 'call-first' })
        await PdcCalls.hold().then(() => {
            PdcCalls.call(callee).then(() => setOnCall(true))
        })
    }
    const TransferCall = (callee: string, warm = false) => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'transfer-call', value: 'transfer' })
        PdcCalls.transfer(callee, warm)
        setOnCall(false)
        setTransfered(true)
    }
    const ReturnToTransfer = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'transfer-call', value: 'return-to-transfer' })
        setOnCall(false)
    }
    useEffect(() => {
        if (!transfered && !PdcCalls.calls[transferee]) {
            Softphone.CloseCallAction()
        }
    }, [PdcCalls.callsCnt])
    return (<>
        {<TransferProgess transfered={transfered}/>}
        {onCall
            ? <InTransferView
                transferee={transferee}
                transfer={TransferCall}
                goBack={ReturnToTransfer}
                transfered={transfered}
            />
            : <TransferDialpadView
                call={CallFirst}
                transfer={TransferCall}
            />}
    </>)
}

export default TransferCallView
