import React, { Component } from 'react'
import TagsInput from 'tags-input'
import { formatPhoneNumber } from 'phone-numbers'
import PropTypes from 'prop-types'

class RecipientsInput extends Component {
    hasTextError = () => {
        if (!this.props.recipients.length) return false
        const tollFreeSuffixes = ['800', '833', '844', '855', '866', '877', '888']
        let value = this.props.inputValue
        if (value[0] === '+') value = value.substring(1)
        if (value[0] === '1') value = value.substring(1)
        return tollFreeSuffixes.includes(value.substr(0, 3))
    }

    render = () => {
        const tagItems = this.props.recipients.map(c => {
            const letters = 'abcdefghijklmnopqrstuvwxyz'
            const hasLetter = Array.from(letters).some(letter => c.nickname.includes(letter) || c.nickname.includes(letter.toUpperCase()))
            let tagItem = { mainText: hasLetter ? c.nickname : formatPhoneNumber(c.nickname) }
            if (c.number !== c.nickname) tagItem.additionalText = formatPhoneNumber(c.number)
            if (c.number[0] === '#') tagItem = { mainText: c.number, additionalText: 'Extension' }
            return tagItem
        })

        const inputPlaceholder = this.props.inputPlaceholder || 'Contact or number'

        return (
            <TagsInput
                prompt= 'To'
                items={tagItems}
                updateItems={this.props.handleChange}
                onChangeInput={this.props.handleInputChange}
                submitEntry={this.props.submitRecipient}
                inputValue={this.props.inputValue}
                textError={this.hasTextError()}
                showLoading={this.props.showLoading}
                notAllowed={this.props.notAllowed}
                inputPlaceholder={inputPlaceholder}
                singleSelect={this.props.singleContact}
                showDialpad={this.props.showDialpad}
                origin={this.props.origin}
            />
        )
    }
}

RecipientsInput.propTypes = {
    recipients: PropTypes.object,
    inputValue: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    handleChange: PropTypes.func,
    handleInputChange: PropTypes.func,
    submitRecipient: PropTypes.func,
    showLoading: PropTypes.bool,
    notAllowed: PropTypes.bool,
    showDialpad: PropTypes.bool,
    singleContact: PropTypes.bool,
    origin: PropTypes.string
}

export default RecipientsInput
