import { CallerInfo } from './interfaces/CallerInfo'
// import { CallEventsDelegate } from '../interfaces/CallEventsDelegate'
import { CallState } from './enums/CallState'

/**
 *
 */
export default abstract class CallSession {
    public abstract participants: CallerInfo[]
    public abstract myCallInfo: CallerInfo | null
    public abstract callType: string
    public abstract callId: string
    public abstract callStartTime?: number
    public abstract callEndTime?: number
    public callState: CallState | null = CallState.INACTIVE
    public isNewCall: boolean = false
    public isMerged: boolean = false
    public isMutedLocal: boolean = false
    public isMutedRemote: boolean = false
    public isOnHold: boolean = false
    public abstract showCallStats(): void
    public mergedCallIDs: { [key: string]: string } = {}
    public abstract transfer(target: string, attended: boolean): Promise<void>

    public abstract supports(): Map<string, boolean>

    // protected abstract activeCallerId?: string
    // public abstract setActiveCallerId(callerId: string): Promise<any>;
    // public getActiveCallerId(): string | undefined {
    //     return this.activeCallerId
    // }
}
