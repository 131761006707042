import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * @param {SvgIconProps} props - svg props
 */
export const GlobeSimpleIcon = (props: SvgIconProps): JSX.Element => {
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.989 1C5.917 1 1 5.928 1 12C1 18.072 5.917 23 11.989 23C18.072 23 23 18.072 23 12C23 5.928 18.072 1 11.989 1ZM19.612 7.6H16.367C16.015 6.225 15.509 4.905 14.849 3.684C16.873 4.377 18.556 5.785 19.612 7.6ZM12 3.244C12.913 4.564 13.628 6.027 14.101 7.6H9.899C10.372 6.027 11.087 4.564 12 3.244ZM3.486 14.2C3.31 13.496 3.2 12.759 3.2 12C3.2 11.241 3.31 10.504 3.486 9.8H7.204C7.116 10.526 7.05 11.252 7.05 12C7.05 12.748 7.116 13.474 7.204 14.2H3.486ZM4.388 16.4H7.633C7.985 17.775 8.491 19.095 9.151 20.316C7.127 19.623 5.444 18.226 4.388 16.4V16.4ZM7.633 7.6H4.388C5.444 5.774 7.127 4.377 9.151 3.684C8.491 4.905 7.985 6.225 7.633 7.6V7.6ZM12 20.756C11.087 19.436 10.372 17.973 9.899 16.4H14.101C13.628 17.973 12.913 19.436 12 20.756ZM14.574 14.2H9.426C9.327 13.474 9.25 12.748 9.25 12C9.25 11.252 9.327 10.515 9.426 9.8H14.574C14.673 10.515 14.75 11.252 14.75 12C14.75 12.748 14.673 13.474 14.574 14.2ZM14.849 20.316C15.509 19.095 16.015 17.775 16.367 16.4H19.612C18.556 18.215 16.873 19.623 14.849 20.316V20.316ZM16.796 14.2C16.884 13.474 16.95 12.748 16.95 12C16.95 11.252 16.884 10.526 16.796 9.8H20.514C20.69 10.504 20.8 11.241 20.8 12C20.8 12.759 20.69 13.496 20.514 14.2H16.796Z" fill="#262626"/>
        </SvgIcon>
    )
}

export default GlobeSimpleIcon
