<template>
	<v-container fill-height data-test-id="404-page">
		<v-row justify="center" align="center">
		    <v-col cols="12" sm="4" class="text-center">
		        <h1 class="text-h1 primary--text mt-15">404</h1>
		        <v-divider class="ma-5"/>
		        <p class="text-h4">{{ l.t('app.page-not-found', 'Page not found') }}.</p>
		        <router-link :to="{name: 'homepage'}" tag="w-btn">
					{{ l.t('app.back-to-homepage', 'Back to homepage?') }}
		        </router-link>
		    </v-col>
		</v-row>
	</v-container>
</template>

<script>
	import l from '../../libs/lang';

	export default {
		data() {
			return {
				l,
			};
		},
	};
</script>
