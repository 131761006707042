import l from './lang';

export default class Progress {
    constructor(options) {
        const default_conf = {
            total: 0,
            title: undefined,
            progress_message: undefined,
            cancel_btn_text: l.t('app.cancel', 'Cancel'),
            confirm_cancel_message: l.t('app.are-you-sure'),
            chunk_size: 1,
        };
        Object.assign(default_conf, options);
        for (const key of Object.keys(default_conf)) {
            this[key] = default_conf[key];
        }
        this.options = options;
        this.stop = false;
        this.show_results = false;
        this.success = [];
        this.error = [];
        this.cancel = false;
        this._percents = 0;
    }

    get results() {
        return {
            success: this.success,
            error: this.error,
        };
    }

    get percents() {
        if (this.total === 0) return 0;
        if (!this.total) return null;
        return Math.round(((this.success.length + this.error.length) * 100) / this.total);
    }

    get completed() {
        return this.success.length + this.error.length;
    }

    add_success(obj) {
        this.success.push(obj);
    }

    add_error(obj) {
        this.error.push(obj);
    }
}
