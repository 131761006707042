/* eslint-disable jsdoc/require-description */
/* eslint-disable no-prototype-builtins */// Shows error on hasOwnProperty.
import { Typography as MUITypography, TypographyProps as MuiTypographyProps } from '@material-ui/core'
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { Component } from 'react'
import RemoteConfigValue from 'remote-config-value'
import { ThemeProvider, getTheme } from 'theme-provider'

const variantMapping: Record<string, string> = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    subtitle1: 'h6',
    subtitle2: 'h6',
    body1: 'p',
    body2: 'p',
    body3: 'p'
}

/***/
export type TypographyVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'body3' | 'caption' | 'button' | 'overline' | 'srOnly' | 'inherit' | 'subtitle3' | 'buttonSmall' | 'buttonMedium' | 'buttonLarge' | 'avatarLetter' | 'label' | 'helperText' | 'chip' | 'inputText' | 'alertText' | 'tooltip' | 'identityText'

interface TypographyProps extends Omit<MuiTypographyProps, 'variant'> {
    /**
     * Varaint controls to style of the Typograhpy.
     *
     * @default body1
     * @type {string}
     */
    variant?: TypographyVariant
    /**
     * Alignment of the Typography
     *
     * @default inherit
     * @type {string}
     */
    align?: 'inherit'
    | 'left'
    | 'center'
    | 'right'
    | 'justify'
    /**
     * Color Pallete of the Typograhpy.
     *
     * @default inherit
     * @type {string}
     */
    color?: 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error'
    /**
     * Display of the Typograhpy.
     *
     * @default initial
     * @type {string}
     */
    display?: 'initial'
    | 'block'
    | 'inline'
    /**
     * Provide a Remote Config ID to set the Typography's text to a remote value.
     * If provided this will override what is provided in the component's children.
     */
    remoteConfigID?: string
    /**
     * If true, the text will not wrap, but instead will truncate with a text overflow ellipsis.
     * Note that text overflow can only happen with block or inline-block level elements (the element needs to have a width in order to overflow).
     *
     * @default false
     * @type {boolean}
     */
    noWrap?: boolean
    /** Local override of the Typography's classes. */
    classes?
    /**
     * The DOM element the Typography Component will appear as.
     *
     * @default <p>
     * @type {string}
     */
    component?: string | Component
}

interface TypographyState {
    variant: TypographyVariant
    align: 'inherit'
    | 'left'
    | 'center'
    | 'right'
    | 'justify'
    color: 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error'
    display: 'initial'
    | 'block'
    | 'inline'
    component
    typographyChildren: React.ReactNode
    noWrap: boolean
    classes
    style
}
/**
 * https://material-ui.com/api/typography/
 */
export default class Typography extends Component<TypographyProps, TypographyState> {
    protected static defaultProps: TypographyProps ={
        variant: 'body1',
        align: 'inherit',
        color: 'inherit',
        display: 'initial',
        remoteConfigID: undefined,
        noWrap: false,
        component: 'p'
    }

    /**
     * @param props
     * Handle the initial configuration of styling based on props here.
     */
    public constructor (props: TypographyProps) {
        super(props)
        this.state = Typography.getState(props)
    }

    static getState = (props: TypographyProps): TypographyState => {
        const state: TypographyState = {
            variant: 'body1',
            align: 'inherit',
            color: 'inherit',
            display: 'initial',
            component: 'p',
            noWrap: false,
            classes: {},
            typographyChildren: '',
            style: null
        }
        const pdcVariants = getTheme().typography.pdcVariants
        if (props.remoteConfigID) {
            state.typographyChildren = <RemoteConfigValue valueId={props.remoteConfigID}/>
        } else if (props.children) {
            state.typographyChildren = props.children
        } else {
            console.warn('Typography component has no children or remote config ID.')
        }
        if (props.align) state.align = props.align
        if (props.color) state.color = props.color
        if (props.display) state.display = props.display
        if (pdcVariants.hasOwnProperty(props.variant)) {
            state.style = {
                ...pdcVariants.root,
                ...pdcVariants[props.variant!],
                textAlign: state.align,
                display: state.display,
                color: state.color
            }
        } else {
            if (props.variant) state.variant = props.variant
            if (props.classes) state.classes = props.classes
        }
        if (props.noWrap) state.noWrap = props.noWrap
        if (props.component) state.component = props.component
        else if (variantMapping.hasOwnProperty(state.variant)) state.component = variantMapping[state.variant]
        return state
    }

    /**
     *
     */
    static getDerivedStateFromProps (props: TypographyProps, state: TypographyState) {
        return Typography.getState(props)
    }

    /**
     *
     */
    public render (): React.ReactNode {
        const propsToPass = {}
        Object.keys(this.props).forEach(prop => {
            // Carefully control what props can be passed to the underlying MuiTypography (or span element)
            if (['className'].some(allowedProp => allowedProp === prop) || ['data-', 'aria-'].some(prefix => prop.startsWith(prefix))) {
                propsToPass[prop] = this.props[prop]
            }
        })

        if (this.state.style !== null) {
            return (
                <span style={this.state.style} {...propsToPass}>
                    {this.state.typographyChildren}
                </span>
            )
        }

        return (<>
            <ThemeProvider>
                <MUITypography
                    variant={this.state.variant}
                    align={this.state.align}
                    color={this.state.color}
                    display={this.state.display}
                    noWrap={this.state.noWrap}
                    classes={this.state.classes}
                    style={this.state.style}
                    component={this.state.component}
                    {...propsToPass}
                >
                    {this.state.typographyChildren}
                </MUITypography>
            </ThemeProvider>
        </>)
    }
}
