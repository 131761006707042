import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * @param {SvgIconProps} props - svg props
 */
export const DollarSignIcon = (props: SvgIconProps): JSX.Element => {
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1" y="1" width="22" height="22" rx="11" fill="#262626"/>
            <path d="M16 13.8511C16 14.5489 15.7251 15.1489 15.1753 15.6511C14.6254 16.1447 13.8328 16.4511 12.7973 16.5702V17.75C12.7973 17.8881 12.6853 18 12.5473 18H11.6727C11.5346 18 11.4227 17.8881 11.4227 17.75V16.6085C10.7446 16.5745 10.0985 16.4638 9.48454 16.2766C8.96088 16.1169 8.52388 15.9263 8.17354 15.7047C8.07107 15.6398 8.03859 15.5077 8.09154 15.3987L8.62933 14.2908C8.69605 14.1533 8.86923 14.1077 9.00085 14.1853C9.29131 14.3564 9.61749 14.5046 9.97938 14.6298C10.4467 14.7915 10.9278 14.8936 11.4227 14.9362V12.8043C10.7721 12.6596 10.2222 12.4979 9.7732 12.3191C9.32417 12.1404 8.94845 11.8809 8.64605 11.5404C8.34364 11.1915 8.19244 10.7319 8.19244 10.1617C8.19244 9.45532 8.46735 8.85106 9.01718 8.34894C9.57617 7.8383 10.378 7.52766 11.4227 7.41702V6.25C11.4227 6.11193 11.5346 6 11.6727 6H12.5473C12.6853 6 12.7973 6.11193 12.7973 6.25V7.39149C13.8277 7.46929 14.6935 7.69291 15.3946 8.06234C15.5063 8.12122 15.5496 8.25717 15.4989 8.37287L15.0086 9.49313C14.9502 9.62653 14.7908 9.68153 14.6595 9.61839C14.0495 9.32487 13.4287 9.14427 12.7973 9.0766V11.2468C13.4387 11.383 13.9794 11.5404 14.4192 11.7191C14.8683 11.8894 15.244 12.1489 15.5464 12.4979C15.8488 12.8383 16 13.2894 16 13.8511ZM10.4055 10.0596C10.4055 10.2723 10.4926 10.4468 10.6667 10.583C10.8408 10.7191 11.0928 10.834 11.4227 10.9277V9.11489C11.0745 9.19149 10.8179 9.31489 10.6529 9.48511C10.488 9.64681 10.4055 9.8383 10.4055 10.0596ZM12.7973 14.8851C13.1271 14.817 13.3746 14.7064 13.5395 14.5532C13.7045 14.4 13.7869 14.217 13.7869 14.0043C13.7869 13.7915 13.6999 13.617 13.5258 13.4809C13.3608 13.3447 13.118 13.2255 12.7973 13.1234V14.8851Z" fill="white"/>
        </SvgIcon>
    )
}

export default DollarSignIcon
