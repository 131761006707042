import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const styles = theme => ({
	preferencesBox: {
		boxShadow:	'0px 0px 6px gray',
		padding:	10,
		maxWidth:		440,
		'&>*:not(:last-child)': {
			marginBottom: 20
		}
	},
	labelSelectWrapper: {
		display:		'flex',
		flexDirection:	'column',
		'& .label': {
			marginBottom: 10
		}
	}
})

class Preferences extends Component {

	state = {
		selectedTimezone:	'Skopje',
		selectedLanguage:	'Macedonian'
	}

	setTimezone = e => {
		let timezone = e.target.value
		this.setState({selectedTimezone: timezone})
	}

	setLanguage = e => {
		let language = e.target.value
		this.setState({selectedLanguage: language})
	}

	render() {
		const { classes } = this.props
		return (
			<div>
				<div className={classes.preferencesBox}>
					<div className={classes.labelSelectWrapper}>
						<span>Timezone</span>
						<Select value={this.state.selectedTimezone} onChange={this.setTimezone} displayEmpty className='timezone-select'>
							<MenuItem value={'Skopje'}>Skopje</MenuItem>
							<MenuItem value={'Prilep'}>Prilep</MenuItem>
							<MenuItem value={'New York'}>New York</MenuItem>
						</Select>
					</div>
					<div className={classes.labelSelectWrapper}>
						<span>Language</span>
						<Select value={this.state.selectedLanguage} onChange={this.setLanguage} displayEmpty className='language-select'>
							<MenuItem value={'Macedonian'}>Македонски</MenuItem>
							<MenuItem value={'Ukrainian'}>Українська</MenuItem>
							<MenuItem value={'English'}>English</MenuItem>
						</Select>
					</div>
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(Preferences)