import { useEffect, useMemo, useState } from 'react'
import { useAjax } from '../hooks/useAjax'
import ajax from 'ajax'
import { EnrollmentType } from '../interface/EnrollmentType'

/**
 *
 */
export function useEnrollmentContext ({
    enrollmentFetchUrl,
    enrollmentAddUrl,
    enrollmentDeleteUrl,
    enrollmentEditUrl,
    enrollmentPaymentDetailsUrl,
    enrollmentType,
    verifiedEnrollmenPredicate,
    initialFetchParams,
    executeFetchImmediately = true
}) {
    const [enrollments, setEnrollments] = useState([])
    const [enrollmentNetworkError, setEnrollmentNetworkError] = useState(null)

    /**
     *
     */
    const executeFetchEnrollments = (params) => {
        return ajax.post(enrollmentFetchUrl, params, 'Bearer').then(rv => {
            return new Promise((resolve, reject) => {
                Array.isArray(rv.data) && setEnrollments(rv.data)
                resolve(rv.data)
            })
        }).catch((err) => {
            return new Promise((resolve, reject) => {
                reject(err)
            })
        })
    }
    const {
        execute: fetchEnrollments,
        status: fetchEnrollmentsStatus,
        error: fetchNetworkError
    } = useAjax(executeFetchEnrollments, executeFetchImmediately, initialFetchParams)

    /**
     *
     */
    const executeAddEnrollment = (params) => {
        const onSucess = params.onSuccess
        delete params.onSuccess
        return ajax.post(enrollmentAddUrl, params, 'Bearer')
            .then((rv) => {
                return new Promise((resolve, reject) => {
                    if (rv.status === 200 && rv?.data?.message === 'success') {
                        resolve(null)
                        fetchEnrollments(initialFetchParams)
                        onSucess && onSucess()
                    } else {
                        const err = rv.message || rv
                        reject(err)
                    }
                })
            })
            .catch((err) => {
                return new Promise((resolve, reject) => {
                    reject(err)
                })
            })
    }
    const { execute: addEnrollment, status: addEnrollmentNetworkStatus, error: addNetworkError } = useAjax(executeAddEnrollment, false)

    /**
     *
     */
    const executeDeleteEnrollment = (params) => {
        const onSucess = params.onSuccess
        delete params.onSuccess
        return ajax.post(enrollmentDeleteUrl, params, 'Bearer')
            .then(rv => {
                return new Promise((resolve, reject) => {
                    if (rv?.status === 200 && rv?.data?.message === 'success') {
                        resolve(null)
                        fetchEnrollments(initialFetchParams)
                        onSucess && onSucess()
                    } else {
                        const err = rv.message || rv
                        reject(err)
                    }
                })
            })
    }
    const { execute: deleteEnrollment, status: deleteEnrollmentNetworkStatus, error: deleteNetworkError } = useAjax(executeDeleteEnrollment, false)

    /**
     *
     */
    const executeEditEnrollment = (params) => {
        return ajax.post(enrollmentEditUrl, params, 'Bearer')
            .then((rv) => {
                return new Promise((resolve, reject) => {
                    if (rv.status === 200 && rv?.data?.message === 'success') {
                        resolve(null)
                        fetchEnrollments(initialFetchParams)
                    } else {
                        const err = rv.message || rv
                        reject(err)
                    }
                })
            })
            .catch((err) => {
                return new Promise((resolve, reject) => {
                    reject(err)
                })
            })
    }
    const { execute: editEnrollment, status: editEnrollmentNetworkStatus, error: editNetworkError } = useAjax(executeEditEnrollment, false)

    /**
     *
     */
    const executeFetchEnrollmentPaymentDetails = () => {
        const params = {
            type: enrollmentType === EnrollmentType.Brand ? 'brand' : 'campaign'
        }
        return ajax.post(enrollmentPaymentDetailsUrl, params, 'Bearer')
            .then(rv => {
                return new Promise((resolve, reject) => {
                    resolve(rv.data)
                })
            }).catch(err => {
                return new Promise((resolve, reject) => {
                    reject(err)
                })
            })
    }
    const {
        execute: fetchEnrollmentPaymentDetails,
        status: fetchEnrollmentPaymentNetworkStatus,
        value: enrollmentPayment
    } = useAjax(executeFetchEnrollmentPaymentDetails, false)

    const crudNetworkStatuses = [
        fetchEnrollmentsStatus,
        addEnrollmentNetworkStatus,
        deleteEnrollmentNetworkStatus,
        editEnrollmentNetworkStatus]
    const crudNetworkPending = crudNetworkStatuses.includes('pending')

    const paymentInfoSuccessfullyFetched = fetchEnrollmentPaymentNetworkStatus === 'success'

    const networkErrorDeps = [editNetworkError, deleteNetworkError, addNetworkError, fetchNetworkError]
    useEffect(() => {
        const error = networkErrorDeps.find(x => x != null)
        if (error != null) {
            setEnrollmentNetworkError(error)
        }
    }, networkErrorDeps)

    const verifiedEnrollments = useMemo(
        () => enrollments.filter(verifiedEnrollmenPredicate),
        [enrollments]
    )
    const verifiedEnrollmentExists = useMemo(
        () => verifiedEnrollments.length > 0,
        [verifiedEnrollments]
    )

    const updateCachedEnrollment = (callbackFn) => {
        const updatedEnrollments: never[] = enrollments.map(callbackFn)
        setEnrollments(updatedEnrollments)
    }

    return {
        enrollments,
        crudNetworkPending,
        paymentInfoSuccessfullyFetched,
        enrollmentPayment,
        verifiedEnrollments,
        verifiedEnrollmentExists,
        enrollmentNetworkError,

        fetchEnrollments,
        addEnrollment,
        deleteEnrollment,
        editEnrollment,
        fetchEnrollmentPaymentDetails,
        updateCachedEnrollment,
        setEnrollmentNetworkError
    }
}
