import React, { Component } from 'react'
import PDCButton from 'pdc-button'
import ContactEditor from 'contact-editor'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/core'
import { MyPhoneContactConsumer } from 'providers'

const defaultFontFamily = 'Montserrat, Helvetica, arial, sans-serif !important'

const styles = theme => ({
    dialogRoot: {
        backgroundColor: 'initial'
    },
    dialog: {
        width: 550,
        minHeight: 550,
        borderRadius: 10,
        fontFamily: theme.fontFamily || defaultFontFamily
    },
    dialogContent: {
        padding: '25px 33px !important'
    }
})

class EditContactModal extends Component {
    renderFooter = () => {
        const { classes } = this.props
        return (
            <div className={classes.footer}>
                <div>
                    {this.props.type === 'Edit'
                        ? <PDCButton
                            variant = 'outlined'
                            figma = {true}
                            color = 'attention'
                            onClick = {() => this.setState({ showDeleteModal: true })}
                        >Delete Contact</PDCButton>
                        : null}
                </div>
            </div>
        )
    }

    render = () => {
        const { classes } = this.props
        return (
            <Dialog
                open = {Boolean(this.props.type)}
                classes = {{ root: classes.dialogRoot, paper: `${classes.dialog} ${this.props.size || ''}` }}
            >
                <DialogContent classes={{ root: classes.dialogContent }}>
                    <MyPhoneContactConsumer>
                        {(contacts) => (
                            <ContactEditor
                                contactGroupTypes = {this.props.contactGroupTypes}
                                contact = {this.props.contact}
                                reinitialize = {false}
                                editMode = {true}
                                saveContact = {this.props.saveContact}
                                onCancel = {this.props.onClose}
                                onSave = {() => {}}
                                setEditing = {() => {}}
                                addGroup = {this.props.addGroup}
                                fixedNumber = {this.props.fixedNumber}
                                prefillData = {this.props.prefillData}
                                onDelete = {this.props.deleteContact}
                                makeCall = {this.props.makeCall}
                                startConversation = {this.props.startConversation}
                                isVirtualExtension = {this.props.isVirtualExtension}
                                smallView = {this.props.smallView}
                                origin = 'edit-contact-modal'
                                updateContact = {contacts.updateContact}
                                deleteContact = {contacts.deleteContact}
                                contactsUtil = {this.props.contactsUtil}
                            />
                        )}
                    </MyPhoneContactConsumer>
                </DialogContent>
            </Dialog>
        )
    }
}

export default withStyles(styles)(EditContactModal)
