import { Styles } from '@material-ui/core/styles/withStyles'
type StylesType = Styles<any, Record<string, unknown>>

/**
 * @param {object} theme
 */
const styles: StylesType = theme => ({
    mainField: {
        display: 'flex',
        '& .MuiInputBase-root.Mui-focused:not(.Mui-error)': {
            background: 'transparent'
        },
        '& .MuiInput-root input': Object.assign({
            color: 'white',
            caretColor: '#37CFFF',
            textAlign: 'center'
        }, theme.overrides.MuiTypography.h6),
        '& .MuiInputBase-root.MuiInput-root': {
            marginTop: '0px'//,
            // width: '90%'
        },
        '& .MuiInputLabel-root': {
            color: 'white',
            fontSize: '20px',
            fontWeight: 'bold'
        },
        '& .MuiInputLabel-shrink': {
            display: 'none'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'none'
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none'
        }
    },
    mainFieldLabel: {
        width: '100%',
        textAlign: 'center',
        left: 0
    },
    fromNumberSection: {
        height: 'fit-content',
        backgroundColor: 'white',
        position: 'relative',
        '&.expanded': {
            '& > .head-button': {
                '& > svg': {
                    transform: 'rotate(180deg)'
                }
            }
        },
        '& > .head-button': {
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            columnGap: 2,
            color: theme.palette.text.secondary,
            backgroundColor: 'white',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: theme.palette.other.green
            },
            '& > svg': {
                fontSize: 18,
                color: theme.palette.text.primary
            }
        },
        '& > .menu-wrapper': {
            position: 'absolute',
            top: '100%',
            width: 298,
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
            maxHeight: 200,
            overflowY: 'auto',
            backgroundColor: 'white',
            zIndex: 1,
            '& > .menu-item': {
                width: '100%',
                height: 50,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 25px 0 35px',
                color: theme.palette.text.primary,
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: theme.palette.other.green
                },
                '& > svg': {
                    fontSize: 20
                }
            }
        }
    },
    textFieldWrapper: {
        background: 'transparent',
        padding: 10,
        paddingTop: 23,
        position: 'relative',
        '& > svg': {
            position: 'absolute',
            bottom: 18,
            left: 12,
            color: 'white',
            cursor: 'pointer',
            zIndex: 1
        }
    },
    callTypedSection: {
        height: 40,
        backgroundColor: 'white',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            columnGap: 6,
            color: theme.palette.primary.main,
            cursor: 'pointer',
            height: '100%',
            '&:hover': {
                backgroundColor: theme.palette.action.primaryFocus
            }
        }
    },
    contactsSection: {
        backgroundColor: 'white',
        height: 357,
        display: 'flex',
        flexDirection: 'column',
        '&.shrink': {
            height: 288
        },
        '& > div:last-child': {
            flex: 1
        }
    },
    dialpad: {
        display: 'flex',
        flexDirection: 'column',
        padding: '9px 30px 0',
        '&.padding': {
            paddingTop: 49
        },
        '& .dialpad-row': {
            display: 'flex',
            justifyContent: 'space-between'
        },
        '& .key-item': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 70,
            height: 70,
            borderRadius: 5,
            color: theme.palette.text.primary,
            cursor: 'pointer',
            '&:hover': {
                color: theme.palette.primary.dark,
                background: theme.palette.action.primaryFocus
            },
            '& > .item-char': {
                '&.star > p': {
                    fontSize: '43px !important',
                    paddingTop: 16
                }
            },
            '& > .item-letters': {
                height: 24,
                color: theme.palette.text.secondary,
                marginTop: -10,
                '&.plus': {
                    paddingTop: 6,
                    '& > span': {
                        fontSize: '20px !important'
                    }
                }
            }
        }
    },
    personItem: {
        padding: '10px 15px 10px 20px',
        display: 'flex',
        alignItems: 'flex-start',
        columnGap: 10,
        '& > .person-avatar': {
            height: 40,
            width: 40
        },
        '& > .person-info': {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            '& > .person-info-item': {
                padding: '3px 10px 4px',
                display: 'flex',
                alignItems: 'center',
                '& > p': {
                    flex: 1,
                    color: theme.palette.text.secondary
                },
                '&.person-name': {
                    '&  > p': {
                        color: theme.palette.text.primary
                    }
                },
                '&:not(.person-name)': {
                    cursor: 'pointer',
                    '&:hover': {
                        backgroundColor: theme.palette.action.primaryFocus,
                        '& > p': {
                            color: theme.palette.primary.dark
                        }
                    }
                },
                '& > svg': {
                    fontSize: 19
                },
                '&.selected': {
                    backgroundColor: theme.palette.action.primaryFocus,
                    '& > p, & > svg': {
                        color: theme.palette.text.primary
                    }
                }
            }
        }
    },
    title: {
        padding: '10px 20px 5px',
        color: theme.palette.text.secondary
    }
})

export default styles
