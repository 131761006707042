<template>
	<div>
		<SectionTitle @click="open = !open" class="cursor-pointer" data-test-id="custom-params-title">
            <v-icon color="info" :class="{'rotate-180': open}" small>$vuetify.icons.triangle</v-icon>
			{{ title }}
		</SectionTitle>
		<template v-if="open">
			<ContentTable
				v-if="customs.length"
				:parameters="customs"
				:dynamic_values="dynamic_values"
				@changed="customs[$event.param]['value'] = $event.value"
				:key="customs.length"
				data-test-id="custom-params-content-table"
			>
				<template v-slot:first_field_title>
					{{ l.t('app.index', 'Index') }}
				</template>
				<template v-slot:fields>
					<TableData
						v-for="(conf, i) in customs"
						:key="i"
						:_parameters="customs"
						:conf="conf"
						:param="i"
						:dynamic_values="dynamic_values"
						@changed="conf['value'] = $event"
						:data-test-id="`custom-params-${i}-table-data`"
					>
						<template v-slot:first_field_value>
							<div class="d-flex">
								<v-icon @click="removeCustom(i)" color="error" class="mr-1" :data-test-id="`custom-params-${i}-remove`">
									mdi-close-circle
								</v-icon>
								<w-text-field not-clearable type="number" @keyup="customIndexChanged(i)" v-model="conf['index']" max="5" min="1" :rules="[$validation.required()]" hide-details='auto' :data-test-id="`custom-params-${i}-index-input`"/>
							</div>
						</template>
					</TableData>
				</template>
			</ContentTable>
			<DefaultModal v-if="error" v-model="show_error_modal" width="500" data-test-id="custom-params-modal">
				<template v-slot:title>{{l.t('app.error', 'Error')}}</template>
				{{ error }}
				<template v-slot:buttons>
					<w-btn @click="error = null" color="secondary">{{l.t('app.cancel', 'Cancel')}}</w-btn>
				</template>
			</DefaultModal>
			<AddToListButton center v-if="customs.length < 5" @click="addCustom()" data-test-id="custom-params-add-dimension-btn">
				{{ l.t("applications-google.add-custom-dimension", "Add custom dimension") }}
			</AddToListButton>
		</template>
	</div>
</template>

<script>
	import l from '../../../../libs/lang';
	import TableData from './data.vue';
	import ContentTable from './table.vue';
	import SectionTitle from '../../../elements/SectionTitle.vue';
	import DefaultModal from '../../../elements/modal/DefaultModal.vue';
	import AddToListButton from '../../../elements/AddToListButton.vue';

	export default {
		props: ['title', '_config', '_dynamic_values', 'level'],
		components: {
			TableData,
			DefaultModal,
			SectionTitle,
			ContentTable,
			AddToListButton,
		},
		data() {
			return {
				l,
				config: null,
				dynamic_values: null,
				customs: [],
				open: false,
				custom: {
				    name: null,
				    input: this.$props.level === 'custom-dimension' ? 'text' : 'number',
				    maxlength: this.$props.level === 'custom-dimension' ? 150 : null,
				    value: null,
				    type: null,
				    level: this.$props.level,
					dynamic: this.$props.level === 'custom-dimension' ? null : ['call_duration'],

				},
				error: null,
				show_error_modal: false,
			};
		},
		created() {
			this.$data.dynamic_values = this.$props._dynamic_values;
			this.$data.config = this.$props._config;
			this.$data.parameters = this.$props._parameters;
			this.checkCustomParams();
		},
		methods: {
			checkCustomParams() {
				for (const paramType of ['static', 'dynamic']) {
					// eslint-disable-next-line no-continue
					if (!this.$data.config.parameters[paramType]) continue;
					const customs = Object.keys(this.$data.config.parameters[paramType]).filter((x) => x.startsWith(this.type));
					// eslint-disable-next-line no-continue
					if (!customs.length) continue;
					this.$data.open = true;
					for (const custom of customs) {
						const num = custom.substring(2, 3);
						const obj = { ...this.$data.custom };
						obj.value = this.$data.config.parameters[paramType][custom];
						obj.index = num;
						obj.type = paramType;
						this.$data.customs.push(obj);
					}
				}
			},
			addCustom() {
				for (let i = 1; i < 6; i++) {
					// eslint-disable-next-line eqeqeq, no-continue
					if (this.$data.customs.find((x) => x.index == i)) continue;

					const customs = JSON.parse(JSON.stringify(this.$data.customs));
					const custom = { ...this.$data.custom };
					custom.index = i;
					customs.push(custom);
					this.$data.customs = customs;
					break;
				}
				this.$forceUpdate();
			},
			removeCustom(param) {
				const customs = JSON.parse(JSON.stringify(this.$data.customs));
				customs.splice(param, 1);
				this.$data.customs = customs;
				this.$global_emitter.$emit('form_input_changed');
				this.$forceUpdate();
			},
			// eslint-disable-next-line consistent-return
			customIndexChanged(custom) {
				const index = this.$data.customs[custom] ? this.$data.customs[custom]['index'] : null;
				if (index && (index > 5 || index < 1)) {
					this.$data.customs[custom]['index'] = null;
					this.$data.error = l.t('applications-google.index-greater-than-5', 'Your index must not be greater than 5.');
					return null;
				}

				// eslint-disable-next-line eqeqeq
				if (index && this.$data.customs.filter((x) => x.index == index).length > 1) {
					this.$data.customs[custom]['index'] = null;
					this.$data.error = l.t('applications-google.already-taken-index', 'Index with the number {} is already taken.', [index]);
				}
			},
		},
		watch: {
			customs: {
				deep: true,
				handler() {
					this.$emit('changed', this.$data.customs);
				},
			},
			open(val) {
				if (val && !this.$data.customs.length) {
					this.addCustom();
				}
			},
			error(val) {
				this.$data.show_error_modal = !!val;
			}
		},
		computed: {
			type() {
				const type = this.$props.level.split('-');

				return type[0][0] + type[1][0];
			},
		},
	};
</script>
