/* eslint-disable jsdoc/require-param-type */
/* eslint-disable jsdoc/require-description */
import { formFileToBase64 } from 'helpers'
import l from '../libs/lang'
import Resource from './Resource'

/**
 *
 */
export default class MessageSender extends Resource {
    /**
     * @param session
     * @param extension
     * @param component
     */
    constructor (session, extension, component) {
        super(session, component, '/messages')
        this.extension = extension
        this.media = []
    }

    /**
     * @param files
     */
    async fileUploaded (files) {
        try {
            if (files.length) {
                for (const file of files) {
                    const filename = file.name
                    const data = await formFileToBase64(file)
                    this.media = []
                    this.media.push({
                        filename, data
                    })
                }
            } else {
                this.media = []
            }
        } catch (err) {
            this.alert = {
                message: l.t('validation.error-reading-file', 'Error reading file.'),
                level: 'error'
            }
        }
    }

    /**
     * @param e
     * @param t
     * @param from
     * @param text
     */
    async send (e, t, from, text) {
        this.loading = true
        let res
        try {
            const to = [...new Set(t)]
            res = await this.session.create_item(
                this.baseUri,
                {
                    from,
                    to,
                    text,
                    media: this.media
                }
            )
        } catch (err) {
            this.validation_error(err)
            if (this.alert) this.alert.closable = err.status && err.status <= 500 && ![404, 201].includes(err.status)
        }
        this.loading = false
        return res
    }
}
