import React from 'react'
import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@material-ui/core'
import Typography from 'typography'
import { ThemeProvider } from 'theme-provider'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import styles from './styles'

const useStyles = makeStyles(styles)

interface Props extends Omit<MuiButtonProps, 'color' | 'variant'> {
    color?: 'primary' | 'secondary' | 'warning' | 'urgent'
    variant?: 'contained' | 'contained-light' | 'text' | 'outlined'
}

const dashedToCamelcase = (string: string) => {
    return string.split('-').map((e, i) => {
        if (!i) return e
        return `${e[0].toUpperCase()}${e.substring(1, e.length)}`
    }).join('')
}

/**
 * Button component
 *
 * @param {Props} props - props
 */
export const Button = (props: Props): JSX.Element => {
    const variant = props.variant || 'contained'
    const color = props.color || 'primary'
    const classes = useStyles()

    const buttonBaseAPIProps = ['action', 'centerRipple', 'classes', 'component', 'disableTouchRipple', 'focusRipple', 'focusVisibleClassName', 'LinkComponent', 'onFocusVisible', 'sx', 'TouchRippleProps', 'touchRippleRef', 'onClick']
    const buttonPropNames = ['disabled', 'disableElevation', 'disableFocusRipple', 'endIcon', 'fullWidth', 'href', 'size', 'startIcon']
    const propNames = buttonBaseAPIProps.concat(buttonPropNames)
    const propsToPass = {}
    Object.keys(props).forEach(propName => {
        if (['data-', 'aria-'].some(prefix => propName.startsWith(prefix)) || propNames.includes(propName)) {
            propsToPass[propName] = props[propName]
        }
    })

    return (
        <ThemeProvider>
            <MuiButton
                {...propsToPass}
                disableRipple
                variant = {variant !== 'contained-light' ? variant : 'contained'}
                color = {(['warning', 'urgent'].includes(color) ? 'primary' : color) as 'primary' | 'secondary'}
                className = {clsx(props.className, {
                    [classes[`${variant}Warning`]]: color === 'warning',
                    [classes[`${variant}Urgent`]]: color === 'urgent'
                }, {
                    [classes[`${dashedToCamelcase(variant)}${color[0].toUpperCase()}${color.substring(1, color.length)}`]]: variant === 'contained-light'
                })}
            >
                <Typography variant={`button${props.size === 'medium' ? 'Medium' : 'Large'}`}>{props.children}</Typography>
            </MuiButton>
        </ThemeProvider>
    )
}

export default Button
