/**
 * @param {object} theme
 */
const styles = theme => ({
    dialog: {
        width: 425
    },
    dialogTitle: {
        marginBottom: 19
    },
    content: {
        marginLeft: -40,
        marginRight: -40,
        padding: '0 40px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 20,
        '& > .main-section': {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 10,
            '& > .check-item': {
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                columnGap: 10,
                paddingTop: 10,
                minHeight: 60,
                '& > .text': {
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 2,
                    '& > p:first-child': {
                        color: theme.palette.text.primary
                    },
                    '& > p:nth-child(2)': {
                        color: theme.palette.text.secondary
                    }
                },
                '&.ok': {
                    '& > svg': {
                        color: theme.palette.info.dark
                    }
                },
                '&.error': {
                    '& > svg': {
                        color: theme.palette.error.main
                    },
                    '&:not(.test) > .text': { // There is no retry (as per figma) on the 3rd (test notification) check
                        '& > p:nth-child(2)': {
                            color: theme.palette.primary.main,
                            cursor: 'pointer'
                        }
                    }
                },
                '&.checking': {
                    '& > svg': {
                        color: theme.palette.secondary.dark,
                        animation: 'rotate-spinner-3298fadkj 1s infinite steps(12)'
                    }
                }
            }
        },
        '& > p.contact-support': {
            textAlign: 'center'
        },
        '& > p.check-permissions > a': {
            color: 'inherit',
            textDecoration: 'underline'
        },
        '&.checking': {
            '& > p.check-permissions': {
                color: theme.palette.text.secondary
            }
        },
        '& > hr': {
            width: '100%',
            borderTop: `1px solid ${theme.palette.secondary.border}`,
            padding: 0
        }
    },
    quickTipSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        rowGap: 4,
        padding: '20px 0',
        color: theme.palette.info.dark,
        backgroundColor: theme.palette.info.background,
        marginLeft: -40,
        marginRight: -40,
        '& > svg': {
            fontSize: 30
        },
        '& > p:last-child': {
            marginTop: 6,
            maxWidth: 191,
            textAlign: 'center'
        }
    },
    retryButtonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    issuesMessage: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 4,
        '&.align-left': {
            flexDirection: 'column',
            rowGap: 5,
            alignItems: 'flex-start'
        },
        '& div': {
            color: theme.palette.primary.main,
            cursor: 'pointer'
        }
    },
    clickLink: {
        color: theme.palette.primary.main,
        cursor: 'pointer'
    }
})

export default styles
