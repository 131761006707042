import React, { Component, createContext } from 'react'
import SipCallManager from './/SipCallManager'
import { CallFeatures } from './CallManager'
// import { CallerInfo } from './interfaces/CallerInfo'
import { CallManagerEvents } from './enums/CallManagerEvents'
import { CallType } from './enums/CallType'
import { CallState } from './enums/CallState'
// import { SipCallSession } from './SipCallSession'
import { LeaveLevel } from 'providers'
import RemoteConfigValue from 'remote-config-value'
import { db } from 'mypdc-dexie'

interface Props {
    // do we need to receive anything? user-related info?
    disableAutoConnect?: boolean;
    someAuthToken?: string;
    callMode: string;
}

// interface CallManagerState {
//     callState: string;
//     callInfo: CallerInfo;
//     callAnswered: false;
//     callStartTime?: number;
//     topicCallbacks: Record<string, any>[];
//     recentConnectionStats: Record<string, any>[];
// }

// interface SipCallManagerState extends CallManagerState {}

// PdcCallProvider??
/**
 *
 */
export const PdcCallContext = createContext<any>({})
/**
 *
 */
export const PdcCallConsumer = PdcCallContext.Consumer

// todo:  PDcCallcprovider - call-agnostic. change on configuration settings
// const CALLMODE = ''

declare global {
    interface Window {
        pdcCall: SipCallManager | null;
    }
}

function storeNumberForRedial (number) {
    const extensionId = window.V5PHONECOM.voip_phone_id
    const voipId = window.V5PHONECOM.voip_id
    const DBKEY = `${voipId}_${extensionId}_RedialNumber`
    db.mypdc.get({ key: DBKEY }).then(entry => {
        if (entry?.id) db.mypdc.update(entry.id, { value: number })
        else db.mypdc.add({ key: DBKEY, value: number })
    })
}

/**
 *
 */
export class PdcCallProvider extends Component<any, any> {
    // singleton observable.
    callManager: SipCallManager

    /**
     * @param props
     */
    constructor (props: Props) {
        super(props)

        this.state = {
            didConnect: false,
            callStats: null,
            canPlaceOutgoingCall: true,
            didConnectProcessed: false
        }

        this.callManager = new SipCallManager()
    }

    /**
     *
     */
    public async componentDidMount () {
        window.pdcCall = this.callManager
        this.callManager.on(CallManagerEvents.CALL_CREATED, () => this.forceUpdate())
        this.callManager.on(CallManagerEvents.CALL_RECEIVED, (e: any) => this.forceUpdate())
        this.callManager.on(CallManagerEvents.CONNECTING, (e: any) => this.forceUpdate())
        this.callManager.on(CallManagerEvents.CALL_ANSWERED, (e: any) => this.forceUpdate())
        this.callManager.on(CallManagerEvents.CALL_HANGUP, (e: any) => {
            if (!this.callManager.activeCallId) {
                this.setState({ callStats: null })
            }
            this.forceUpdate()
        })

        this.callManager.on(CallManagerEvents.CALL_STAT_UPDATE, (e: any) => {
            const { callId, stat } = e
            // call stats are being tracked every n seconds from each session. but only update the ui if its the active call.
            if (this.callManager.activeCallId === callId) this.setState({ callStats: stat })
        })

        this.callManager.on(CallManagerEvents.SWITCH_CALL, (e: any) => this.forceUpdate())

        this.callManager.on(CallManagerEvents.MERGE_CALL, (e: any) => this.forceUpdate())

        this.callManager.on(CallManagerEvents.STATE_UPDATE, (e: any) => this.forceUpdate())

        if (!this.props.disableAutoConnect) {
            await this.connect()
        }
    }

    /**
     *
     */
    public componentWillUnmount () {
        window.pdcCall = null
    }

    /***/
    setChange (hasChange: boolean): void {
        const changeInfo = {
            cancelText: <RemoteConfigValue valueId='leave_page_active_call_modal_cancel_text'/>,
            confirmText: <RemoteConfigValue valueId='leave_page_active_call_modal_confirm_text'/>,
            details: <RemoteConfigValue valueId='leave_page_active_call_modal_description'/>,
            question: <RemoteConfigValue valueId='leave_page_active_call_modal_question'/>
        }
        this.props.leaveContext.onChange({
            hasChange,
            changeInfo,
            level: LeaveLevel.USER,
            leaveCallback: () => this.callManager.hangupById(this.callManager.activeCallId, true)
        })
    }

    /***/
    updateLeaveProvider (): void {
        const hasActiveCalls = Object.keys(this.callManager.calls).length
        const canLeave = this.props.leaveContext.canLeave(LeaveLevel.USER)
        if (hasActiveCalls) {
            if (canLeave) this.setChange(true)
        } else {
            if (!canLeave) this.setChange(false)
        }
    }

    /***/
    componentDidUpdate (prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        this.updateLeaveProvider()
    }

    /**
     *
     */
    public connect = async () => {
        // TODO: await this and return empty resolved promise to not expose simpleuser.
        if (!this.state.didConnect) {
            return await this.callManager.connect().then(() => {
                this.setState({ didConnect: true })
            }).catch(err => {
                console.log('Error in DID connect', err.message)
            }).finally(() => {
                this.setState({ didConnectProcessed: true })
            })
        }
    }

    /**
     * @param callee
     */
    public call = async (callee: string) => {
        try { storeNumberForRedial(callee) } catch (e) { console.error('Failed saving number for redial', e) }
        await this.callManager.call(callee)
    }

    /**
     * @param callerId
     */
    public setMyCallerInfo = async (callerId: string) => {
        this.setState({ canPlaceOutgoingCall: false })
        await this.callManager.setMyCallerInfo(callerId)
        this.setState({ canPlaceOutgoingCall: true })
    }

    // TODO: should this be simplified into a single hold() func, letting call managers determine based on isOnHold field if the action should be a hold or unhold. ui is not concerned with specifics.
    /**
     *
     */
    public hold = async () => {
        await this.callManager.hold()
    }

    /**
     *
     */
    public unhold = async () => {
        await this.callManager.unhold()
    }

    /**
     * @param target
     * @param attended
     */
    public transfer = async (target: string, attended = false) => {
        await this.callManager.transfer(target, attended)
    }

    /**
     * @param id
     * @param endAll
     */
    public hangupById = (id: string, endAll = false): void => {
        this.callManager.hangupById(id, endAll)
    }

    /**
     * @param id
     */
    public answerById = async (id: string): Promise<any> => {
        if (window.pdc) {
            return await window.pdc.checkAudioPermissions(
                () => {
                    return this.callManager.answerById(id)
                }
            )
        } else return this.callManager.answerById(id)
    }

    /**
     * @param id
     */
    public switchCall = async (id: string): Promise<void> => {
        await this.callManager.switchCall(id)
    }

    /**
     * @param id
     */
    public mergeCall = async (id: string) => {
        await this.callManager.mergeCall(id)
    }

    /**
     * @param isMuted
     */
    public muteLocal = (isMuted: boolean) => {
        this.callManager.muteCurrentLocal(isMuted)
    }

    /**
     * @param isMuted
     */
    public muteRemote = (isMuted: boolean) => {
        this.callManager.muteCurrentRemote(isMuted)
    }

    /**
     * @param tone
     */
    public sendDTMF = (tone: string) => {
        this.callManager.sendDTMF(tone)
    }

    /**
     *
     */
    public getIncomingCalls = (): any[] => {
        return Object.values(this.callManager.calls)
            .filter((call) => call.callState === CallState.INCOMING)
            .sort((a, b) => b.callStartTime! - a.callStartTime!)
    }

    /**
     *
     */
    public getActiveCalls = (): any[] => {
        return Object.values(this.callManager.calls).filter((call) => call.callState === CallState.ACTIVE)
    }

    public switchToActiveCall = (): void => {
        const calls = this.getActiveCalls()
        if (calls.length > 0) {
            const call = calls[0]
            this.switchCall(call.callId)
        }
    }

    /**
     *
     */
    public getIsMutedLocal = () => {
        let isMutedLocal = true
        const id = this.callManager.activeCallId
        // is sip the exception to rule or is c2c?  && this.callManager.callMode !== CallType.C2C
        if (id && this.callManager.calls[id] && this.callManager.callMode === CallType.SIP) {
            const currentCall = this.callManager.calls[id]
            if (!currentCall.isMutedLocal) {
                isMutedLocal = false
            } else {
                for (const number in currentCall.mergedCallIDs) {
                    const pID = currentCall.mergedCallIDs[number]
                    if (this.callManager.calls[pID] && !this.callManager.calls[pID].isMutedLocal) isMutedLocal = false
                }
            }
        } else {
            isMutedLocal = false
        }
        return isMutedLocal
    }

    /**
     *
     */
    public getIsMutedRemote = () => {
        let isMutedRemote = true
        const id = this.callManager.activeCallId
        // is sip the exception to rule or is c2c?  && this.callManager.callMode !== CallType.C2C
        if (id && this.callManager.calls[id] && this.callManager.callMode === CallType.SIP) {
            const currentCall = this.callManager.calls[id]
            if (!currentCall.isMutedRemote) {
                isMutedRemote = false
            } else {
                for (const number in currentCall.mergedCallIDs) {
                    const pID = currentCall.mergedCallIDs[number]
                    if (this.callManager.calls[pID] && !this.callManager.calls[pID].isMutedRemote) isMutedRemote = false
                }
            }
        } else {
            isMutedRemote = false
        }
        return isMutedRemote
    }

    /**
     * @param callId
     */
    public supportsById = (callId: string): any => {
        return this.callManager.supportsById(callId)
    }

    /**
     * @param isExtensionVirtual
     */
    public supports = (isExtensionVirtual: boolean): Map<CallFeatures, boolean> => {
        return this.callManager ? this.callManager.supports(isExtensionVirtual) : new Map<CallFeatures, boolean>()
    }

    /**
     * @param micStream
     */
    public changeMicStream = (micStream: MediaStream) => {
        this.callManager.changeMicStream(micStream)
    }

    /**
     * @param doNotDisturb
     */
    public toggleDoNotDisturb = (doNotDisturb: boolean) => {
        this.setState({ doNotDisturb })
        if (this.callManager) this.callManager.doNotDisturb = doNotDisturb
    }

    /**
     *
     */
    render () {
        const {
            callManager,
            call,
            hangupById,
            hold,
            unhold,
            answerById,
            switchCall,
            connect,
            muteLocal,
            muteRemote,
            mergeCall,
            sendDTMF,
            getActiveCalls,
            getIncomingCalls,
            supportsById,
            supports,
            setMyCallerInfo,
            changeMicStream,
            toggleDoNotDisturb,
            transfer,
            switchToActiveCall
        } = this

        const myCallerInfo = callManager.myCallerInfo
        const activeCallId = callManager.activeCallId
        const nonStateCalls = { ...callManager.calls } // this is necessary for nested obj updates in react
        const getMergedSessions = callManager.getMergedSessions
        const sessions = []
        for (const [, session] of Object.entries(nonStateCalls)) {
            sessions.push(session)
        }
        const incomingCallsCnt = this.getIncomingCalls().length
        const activeCallsCnt = this.getActiveCalls().length

        const currentCall = nonStateCalls[activeCallId!] || null
        // const currentCallState = activeCallId ? this.callManager.calls[this.callManager.activeCallId!] : null
        const backgroundCalls = currentCall
            ? this.getActiveCalls().filter((c) => c.callId !== currentCall.callId)
            : this.getActiveCalls()
        const incomingCalls = this.getIncomingCalls()
        const callsOnHold = this.callManager.getCallsArray().filter(c => c.isOnHold).filter(c => !c.isMerged)
        const isMutedLocal = this.getIsMutedLocal()
        const isMutedRemote = this.getIsMutedRemote()
        const didConnectProcessed = this.state.didConnectProcessed
        const canPlaceOutgoingCall = this.state.canPlaceOutgoingCall && this.state.didConnect &&
            this.callManager.getCallsArray().filter((c) => c.callState === CallState.INACTIVE).length === 0 && !callManager.callSetupInProgress
        const deniedAudioPermissions = callManager.deniedAudioPermissions
        const noDeviceFound = callManager.noDeviceFound
        return (
            <PdcCallContext.Provider
                value={{
                    ...this.state,
                    // methods
                    call,
                    hangupById,
                    connect,
                    answerById,
                    muteLocal,
                    muteRemote,
                    switchCall,
                    mergeCall,
                    sendDTMF,
                    getActiveCalls,
                    getIncomingCalls,
                    hold,
                    unhold,
                    supportsById,
                    supports,
                    toggleDoNotDisturb,
                    transfer,
                    switchToActiveCall,
                    getMergedSessions,
                    didConnectProcessed,
                    myCallerInfo,
                    activeCallId,
                    currentCall,
                    callsCnt: Object.keys(nonStateCalls).length,
                    calls: nonStateCalls,
                    sessions,
                    callsOnHold,
                    incomingCallsCnt,
                    activeCallsCnt,
                    backgroundCalls,
                    incomingCalls,
                    isMutedLocal,
                    isMutedRemote,
                    canPlaceOutgoingCall,
                    deniedAudioPermissions,
                    noDeviceFound,
                    setMyCallerInfo,
                    changeMicStream
                }}
            >
                {this.props.children}
            </PdcCallContext.Provider>
        )
    }
}
export default PdcCallProvider
