import React, { useCallback, useEffect, useMemo } from 'react'

import Dialog from 'dialog-mui'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import Button from 'button-mui'
import Typography from 'typography'

import { makeStyles } from '@material-ui/core'
import styles from './troubleshootStyles'
import { AlertIcon, CheckAltIcon, SpinnerIcon2, TipOnIcon } from 'svg-icons'
import { useArray } from 'hooks'
import { pushNotification } from 'notification-pusher'
import { detectOS } from 'os-detector'
import PDCOpenConnection from 'pdc-open-connection'

const useStyles = makeStyles(styles)

interface Props {
    open: boolean
    onClose: () => void
    openDocs: () => void
}

enum Status { CHECKING = 'checking', OK = 'ok', ERROR = 'error', WAIT = 'wait' }

const QuickTip = () => {
    const classes = useStyles()
    return (
        <div className={classes.quickTipSection}>
            <TipOnIcon/>
            <Typography variant='subtitle3'>QUICK TIP</Typography>
            <Typography variant='body2'>Always check to see if you have your Do not disturb set to on</Typography>
        </div>
    )
}

interface CheckItem {
    id: string
    title: string
    description?: string
    status: Status
}

const sleep = (miliseconds: number): Promise<void> => {
    return new Promise(resolve => {
        // return resolve()
        setTimeout(resolve, miliseconds)
    })
}

/***/
const TroubleshootNotificationsModal = ({ open, onClose, openDocs }: Props): JSX.Element => {
    const classes = useStyles()
    const [checkItems, { push, clear, updateByKey, upsertByKey }] = useArray<CheckItem>()

    const checkInternetConnection = useCallback(async () => {
        console.log('#### Checking internet connection...')
        upsertByKey('id', { id: 'connection', title: 'Checking your internet connection', status: Status.CHECKING })
        await sleep(300)
        const connectionStatus = window.navigator.onLine && PDCOpenConnection.connected ? Status.OK : Status.ERROR
        updateByKey('id', 'connection', {
            title: 'Checked your internet connection',
            description: connectionStatus === Status.ERROR ? 'Retry connecting' : '',
            status: connectionStatus
        })
        return connectionStatus
    }, [open, onClose, checkItems])

    const checkNotificationPermission = useCallback(async () => {
        console.log('#### Checking notifications permission...')
        upsertByKey('id', { id: 'notifications', title: 'Checking your browser notification preferences', status: Status.CHECKING })
        await sleep(1000)
        let notificationPermission = typeof Notification !== 'undefined' ? Notification.permission : null
        if (notificationPermission === 'default') notificationPermission = await Notification.requestPermission()
        const permissionStatus = notificationPermission === 'granted' ? Status.OK : Status.ERROR
        updateByKey('id', 'notifications', {
            title: 'Checked your browser notification preferences',
            description: permissionStatus === Status.ERROR ? 'Check your browser notification preferences' : '',
            status: permissionStatus
        })
        return permissionStatus
    }, [open, onClose, checkItems])

    const testNotifications = useCallback(async (permissionsStatus = checkItems.find(item => item.id === 'notifications')?.status || Status.ERROR) => {
        console.log('#### Sending a test notification...')
        upsertByKey('id', { id: 'test', title: 'Sending a test notification', description: 'Look at your desktop notifications and click on the test notification we’ve sent you.', status: Status.CHECKING })
        if (permissionsStatus !== Status.OK) {
            updateByKey('id', 'test', { status: Status.ERROR })
            return Status.ERROR
        }
        await sleep(1000)
        // const notification = new Notification('Test Notification', { body: 'This is a test notification' })
        pushNotification('Test notification', 'Verifying the notifications work', '', '', '', [], null, 'test-notification')
        const testStatus = Status.OK
        updateByKey('id', 'test', { title: 'Sent a test notification', status: testStatus })
        return testStatus
    }, [open, onClose, checkItems])

    const check = useCallback(async () => {
        console.log('#### Calling check...')
        // Reset
        clear()
        push(
            { id: 'connection', title: 'Checking your internet connection', status: Status.CHECKING },
            { id: 'notifications', title: 'Checking your browser notification preferences', status: Status.CHECKING },
            { id: 'test', title: 'Sending a test notification', description: 'Look at your desktop notifications and click on the test notification we’ve sent you.', status: Status.CHECKING }
        )

        // Check the internet connection
        await checkInternetConnection()

        // Check the notifications permission
        const permissionsStatus = await checkNotificationPermission()

        // Send a test notification
        await testNotifications(permissionsStatus)
    }, [open, onClose, checkItems])

    useEffect(() => { if (open) check() }, [open])

    const browserPermissionsLink = useMemo(() => {
        const os = detectOS()
        if (os === 'Mac OS') return 'x-apple.systempreferences:com.apple.preference.notifications'
        if (os === 'Windows') return 'ms-settings:notifications' // ms-settings:privacy-notifications
        return null
    }, [])

    const checkTestNotification = useCallback(async () => {
        const permissionsStatus = await checkNotificationPermission()
        await testNotifications(permissionsStatus)
    }, [open, onClose, checkItems])

    const retryMap = useMemo(() => ({
        connection: checkInternetConnection,
        notifications: checkNotificationPermission,
        test: checkTestNotification
    }), [])

    const hasError = checkItems.some(item => item.status === Status.ERROR)
    const isChecking = checkItems.some(item => item.status === Status.CHECKING)

    return (
        <Dialog
            open = {open}
            onClose = {onClose}
            showCloseButton
            classes = {{ paper: classes.dialog }}
            data-testid = 'troubleshooting-notifications-modal'
        >
            <DialogTitle classes={{ root: classes.dialogTitle }}>
                <Typography variant='subtitle1'>Troubleshooting notifications</Typography>
            </DialogTitle>
            <DialogContent classes={{ root: `${classes.content} ${isChecking ? 'checking' : ''}` }}>
                {/* Show the checking status  */}
                {hasError && !isChecking &&
                    <div className={classes.retryButtonWrapper}>
                        <Button variant='contained-light' onClick={check}>Run again</Button>
                    </div>
                }
                <div className='main-section'>
                    {checkItems.map((item, i) => (
                        <div role='row' className={`check-item ${item.status} ${item.id}`} key={i}>
                            <div className='text'>
                                <Typography variant='body2'>{item.title}</Typography>
                                {item.description && <div className={classes.clickLink} onClick={retryMap[item.id]}><Typography variant='caption'>{item.description}</Typography></div>}
                            </div>
                            {item.status === Status.CHECKING
                                ? <SpinnerIcon2/>
                                : item.status === Status.OK
                                    ? <CheckAltIcon/>
                                    : item.status === Status.ERROR
                                        ? <AlertIcon/>
                                        : null
                            }
                        </div>
                    ))}
                </div>
                {(hasError && !isChecking) && <hr/>}
                <Typography classes={{ root: 'check-permissions' }} variant='body2'>Check your {browserPermissionsLink ? <a href={browserPermissionsLink}>browser permissions</a> : 'browser permissions'} if you have not received our test notification</Typography>
                {(!hasError || isChecking) && <QuickTip/>}
                <div className={`${classes.issuesMessage} ${hasError && !isChecking ? 'align-left' : ''}`}>
                    <Typography classes={{ root: 'contact-support' }} variant='body2'>Still having issues?</Typography>
                    <div onClick={openDocs}><Typography variant='body2'>Contact Support</Typography></div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} size='large' data-testid='close-troubleshooting-modal-button'>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TroubleshootNotificationsModal
