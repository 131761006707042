import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVert from '@material-ui/icons/MoreVert'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => {
	return {
		menuPaper: theme.dropdownMenu.paper,
		menuList: theme.dropdownMenu.list,
		menuItem: theme.dropdownMenu.list.item,
		downloadItem: {
			padding: 0,
			'& a': {
				color:		'#000',
				padding:	'8px 13px',
				width:		'100%'
			},
			'&:hover a': {
				color: theme.palette.primary.main
			}
		}
	}
})

const VoicemailHeaderDropdownMenu = props => {

	const classes = useStyles()
	const [anchorEl, setAnchorEl] = React.useState(null)

	function handleClose() {
		setAnchorEl(null)
	}

	function showDropdown(e) {
		setAnchorEl(e.currentTarget)
	}

	function deleteVoicemail() {
		props.deleteVoicemail()
		handleClose()
	}

	function changeReadStatus() {
		props.changeReadStatus.call()
		handleClose()
	}

	function downloadVoicemail() {
		handleClose()
	}

	function editContact() {
		props.editContact.call()
		handleClose()
	}

	function makeCall() {
		props.makeCall()
		handleClose()
	}

	return (
		<div>
			<MoreVert aria-controls={`options-dropdown-menu_${props.id}`} aria-haspopup='true' className='options-dropdown-btn' onClick={showDropdown} />
			<Menu
				id				= {`options-dropdown-menu_${props.id}`}
				anchorEl		= {anchorEl}
				open			= {Boolean(anchorEl)}
				onClose			= {handleClose}
				classes			= {{paper: classes.menuPaper, list: classes.menuList}}
				getContentAnchorEl = {null}
				anchorOrigin	= {{
					vertical:	'bottom',
					horizontal:	'left'
				}}
				transformOrigin	= {{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
			>
				{props.deleteVoicemail ? <MenuItem classes={{root: classes.menuItem}} dense onClick={deleteVoicemail}>Delete</MenuItem> : null}
				{props.changeReadStatus ? <MenuItem classes={{root: classes.menuItem}} dense onClick={changeReadStatus}>{props.changeReadStatus.text}</MenuItem> : null}
				{props.downloadVoicemail ? 
					<MenuItem classes={{root: `${classes.menuItem} ${classes.downloadItem}`}} dense onClick={downloadVoicemail}>
						<a href={props.downloadVoicemail.url} download="newname" target="_blank">Download</a>
					</MenuItem> : null}
				{props.editContact ? <MenuItem classes={{root: classes.menuItem}} dense onClick={editContact}>{props.editContact.type} Contact</MenuItem> : null}
				{props.makeCall ? <MenuItem classes={{root: classes.menuItem}} dense onClick={makeCall}>Call</MenuItem> : null}
			</Menu>
		</div>
	)
}

export default VoicemailHeaderDropdownMenu