import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5664%3A7205
 *
 * @param {SvgIconProps} props - svg props
 */
export const VideoIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.2006 10.7317C15.9082 9.69267 16.2193 8.5592 16.9695 7.93061L20.1113 4.93998C20.6367 4.49972 21.3779 4.89308 21.4541 5.65257L22.7364 14.4914C22.8096 15.2212 22.2132 15.7823 21.622 15.5398L18.1843 14.6564C17.4247 14.3449 16.8291 13.6331 16.5763 12.7346L16.2006 10.7317ZM1.25337 11.7644C1.09488 10.6283 1.73091 9.54878 2.69052 9.32513L13.2098 6.05982C14.3063 5.80427 15.3356 6.76632 15.3726 8.08117L15.7446 15.231C15.7446 18.4309 12.8406 18.9642 12.8406 18.9642H6.11754C4.18157 18.9642 3.18934 18.8054 2.69052 16.6721L1.25337 11.7644Z" fill={color}/>
        </SvgIcon>
    )
}

export default VideoIcon
