import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.1.1?node-id=10063%3A26718
 *
 * @param {SvgIconProps} props - svg props
 */
export const TroubleshootIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M14.646 12.4368C14.9323 12.142 15.3991 12.142 15.6854 12.4368L21.7853 18.8171C22.0716 19.1151 22.0716 19.5926 21.7853 19.8874L19.9152 21.714C19.6285 22.0088 19.1648 22.0088 18.8788 21.714L12.816 15.4107C12.5297 15.1155 12.5297 14.6384 12.816 14.3435L13.3451 13.7155L12.3772 12.706C10.5691 14.93 6.43302 20.4322 6.43302 20.4322L5.51183 21.3775C4.70889 22.2075 3.4049 22.2075 2.60197 21.3775C1.79934 20.5507 1.79934 19.208 2.60197 18.3813L3.52005 17.4327L10.5663 10.825L10.457 10.6295L5.07924 5.02121C4.59063 5.26828 3.90907 4.84207 3.90907 4.84207L3.01931 3.96403L4.70609 2.22364L5.59928 3.10201C6.01288 3.53142 5.77014 4.27807 5.77014 4.27807L11.2849 9.99818H11.3972C11.3972 9.99818 13.1148 8.11071 13.0868 8.04662C12.427 6.54978 12.1348 5.20419 14.0391 3.32312C15.2062 2.12142 16.8463 1.73046 18.2281 2.1823L15.8193 4.64339C15.5672 4.90617 15.5672 5.32916 15.8193 5.59194L16.8933 6.6943C17.1482 6.95707 17.559 6.95707 17.8145 6.6943L20.3288 4.14637C20.9979 5.64962 20.7271 7.55953 19.4418 8.88621C17.9825 10.3475 16.7128 10.716 14.9167 9.89564C14.8296 9.85718 13.1462 11.7607 13.1462 11.7607L14.1982 12.8566L14.646 12.4368ZM4.98587 18.8553C4.73098 18.5957 4.32298 18.5957 4.06778 18.8553C3.8157 19.1181 3.8157 19.5411 4.06778 19.8006C4.32298 20.0634 4.73098 20.0634 4.98587 19.8006C5.23795 19.5411 5.23795 19.1181 4.98587 18.8553ZM20.3883 19.6059C20.5096 19.7308 20.706 19.7308 20.8271 19.6059C20.9485 19.4809 20.9485 19.279 20.8271 19.154L15.3964 13.2798C15.275 13.1551 15.0758 13.1551 14.9545 13.2798C14.8334 13.4051 14.8334 13.607 14.9545 13.7319L20.3883 19.6059ZM13.8275 14.4398C13.7061 14.5648 13.7061 14.7699 13.8275 14.8949L19.2582 20.7688C19.3795 20.8938 19.579 20.8938 19.7001 20.7688C19.8215 20.6406 19.8215 20.4387 19.7001 20.3137L14.2663 14.4398C14.1452 14.3148 13.9488 14.3148 13.8275 14.4398Z' fill={color}/>
        </SvgIcon>
    )
}

export default TroubleshootIcon
