import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { switchConversation, addConversations, updateTotalConversations } from '../../actions/conversations.js'
import { switchView } from '../../actions/view.js'

import api from '../../util/api_v2'

import NoSetup from 'no-setup'

import StartNewButton from 'start-new-button'
import ConversationSelector from './ConversationSelector'

import { withStyles } from '@material-ui/core'

const styles = theme => ({
    noConversations: {
        marginTop: '15px',
        padding: '0 10px',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        verticalAlign: 'middle',
        textAlign: 'center',
        '&>span': {
            color: '#b6c0c5',
            fontWeight: 'bold',
            lineHeight: 1.29
        }
    },
    conversationsPanel: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        minHeight: 0,
        '& .conversation-selector-wrapper': {
            minHeight: 0
        }
    },
    conversationsPanelHeader: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: 'inset 0px -1px 0px 0px #ccd6db',
        '& > div:first-child': {
            flex: 1
        }
    }
})

const mapStateToProps = state => {
    return {
        conversations: state.conversations,
        totalConversations: state.totalConversations,
        currentConversation: state.conversations.find(c => c.selected),
        smallView: state.smallView,
        sendNumber: state.selectedSendNumber,
        currentView: state.currentView
    }
}

const mapDispatchToProps = dispatch => {
    return {
        switchConversation: conv => dispatch(switchConversation(conv)),
        updateTotalConversations: total => dispatch(updateTotalConversations(total)),
        addConversations: convs => dispatch(addConversations(convs)),
        switchView: view => dispatch(switchView(view))
    }
}

class ConversationsPanel extends Component {
    state = { loadingConversations: false }

    componentDidMount = async () => {
        this._ismounted = true
        if (this.props.conversations && this.props.conversations.length > 0) return

        if (!this._ismounted) return
        if (this.props.conversations.length && (this.props.currentView === 'content' || !this.props.smallView)) {
            this.switchToDefaultConversation()
        }
    }

    componentDidUpdate (prevProps) {
        if (this.props.deletedConversationId && prevProps.deletedConversationId !== this.props.deletedConversationId) { this.loadOneMoreConversation() }

        if (this.props.deletedConversations.randomString !== prevProps.deletedConversations.randomString && this.props.deletedConversations.num) {
            this.loadMoreConversations(this.props.deletedConversations.num)
        }
    }

    componentWillUnmount () {
        this._ismounted = false
    }

    loadOneMoreConversation = () => this.loadMoreConversations(1)

    loadMoreConversations = async (limit = 15) => {
        if (this.state.loadingConversations) return
        const offset = this.props.conversations.filter(c => !c.newlyAdded).length
        this.setState({ loadingConversations: true })
        const response = await api.loadConversations(offset, limit)
        if (!this._ismounted || !response || !response.conversations || response === 'network-error') return
        const conversations = response.conversations
        const total = response.total
        this.props.updateTotalConversations(total)
        this.props.addConversations(conversations)
        this.setState({ loadingConversations: false })

        // Load the new storred contacts of each newly-loaded conversation and store them in redux
        this.props.loadExtraContacts()
    }

    switchToDefaultConversation = () => {
        if (window.location.pathname.split('/')[3] === 'new-conversation') return

        let defaultConversation
        let urlConvId
        const sessionConvId = sessionStorage.getItem('session_conversation_id')
        const queryUrlSplit = document.location.search.split('conversation_id=')
        if (queryUrlSplit.length > 1) {
            urlConvId = queryUrlSplit[1].split('&')[0]
        }

        if (urlConvId) {
            defaultConversation = this.props.conversations.find(c => c.id === urlConvId)
        } else if (sessionConvId) {
            defaultConversation = this.props.conversations.find(c => c.id === sessionConvId)
        }

        if (!defaultConversation) {
            defaultConversation = this.props.conversations[0]
        }

        this.props.updateRouterHistory(`c${defaultConversation.id}`)
        this.props.switchConversation(defaultConversation)
    }

    onStartConversationClick = () => {
        // this.props.switchView('content')
        this.props.updateRouterHistory('new-conversation')
        this.props.switchConversation(null)
        this.props.switchView('new_conversation')
        const tagsInput = document.getElementById('tags-input')
        if (tagsInput) tagsInput.focus()
    }

    renderConversationSelector () {
        const hasMoreConversations = this.props.conversations.length < this.props.totalConversations
        return (
            <ConversationSelector
                loadMore = {this.loadMoreConversations}
                hasMore = {hasMoreConversations}
                currentConversation = {this.props.currentConversation}
                updateUnreadCounts = {this.props.updateUnreadCounts}
                changeMessageReadStatus = {this.props.changeMessageReadStatus}
                extraContacts = {this.props.extraContacts}
                updateRouterHistory = {this.props.updateRouterHistory}
            />
        )
    }

    render () {
        const { classes } = this.props
        return (
            <>
                <div className={classes.conversationsPanelHeader}>
                    {Object.keys(this.props.extension.phone_number).length
                        ? <StartNewButton app='messages' title='Send new message' onClick={this.onStartConversationClick}/>
                        : null}
                </div>
                <div className={classes.conversationsPanel}>
                    {this.props.conversations.length === 0
                        ? (
                            (!this.props.sendNumber || !this.props.sendNumber.length)
                                ? <NoSetup type='messages'/>
                                : <div className={classes.noConversations}>
                                    <span>No messages yet.<br/>Add recipients and start chatting.</span>
                                </div>
                        )
                        : this.renderConversationSelector()}
                </div>
            </>
        )
    }
}

ConversationsPanel.propTypes = {
    conversations: PropTypes.array,
    deletedConversationId: PropTypes.number,
    currentView: PropTypes.string,
    deletedConversations: PropTypes.object,
    smallView: PropTypes.bool,
    updateTotalConversations: PropTypes.func,
    addConversations: PropTypes.func,
    switchConversation: PropTypes.func,
    switchView: PropTypes.func,
    sendNumber: PropTypes.array,
    classes: PropTypes.object,
    updateRouterHistory: PropTypes.func,
    loadExtraContacts: PropTypes.func,
    currentConversation: PropTypes.object,
    extension: PropTypes.object,
    updateUnreadCounts: PropTypes.func,
    extraContacts: PropTypes.array,
    totalConversations: PropTypes.number,
    changeMessageReadStatus: PropTypes.func
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ConversationsPanel))
