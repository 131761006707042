/* react-linkify but I modified the decorators */
import LinkifyFork from 'linkify'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Linky extends Component {
    /**
     *
     */
    render () {
        return (
            <LinkifyFork>
                {this.props.children}
            </LinkifyFork>
        )
    }
}

Linky.propTypes = {
    children: PropTypes.any
}

export default Linky
