/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'button-mui'
import { getValue } from 'remote-config-value'
import { AddIcon } from 'pdc-svg-icons'
import { EnrollmentType } from '../interface/EnrollmentType'
import BusinessInformationStep from './BusinessInformationStep'
import BusinessAddressAndContact from './BusinessAddressAndContact'
import BusinessPaymentStep from './BusinessPaymentStep'
import CampaignUsageStep from './CampaignUsageStep'
import MessageSettingsStep from './MessageSettingsStep'
import CampaignSampleMessageStep from './CampaignSampleMessageStep'
import CampaignPoliciesStep from './CampaignPoliciesStep'
import CampaignContactStep from './CampaignContactStep'
import CampaignPreviewStep from './CampaignPreviewStep'
import CampaignPaymentStep from './CampaignPaymentStep'
import NumberCampaignStep from './NumberCampaignStep'
import NumberSelectStep from './NumberSelectStep'
import StepWizard from 'step-wizard'
import { CampaignContext } from '../contexts/CampaignContext'
import StatusChip from './StatusChip'
import DropdownMenu from 'dropdown-menu'
import Options from 'pdc-options'
import TableMui from 'pdc-table-mui'
import ConfirmDeleteModal from 'confirm-delete-modal'
import InfoModal from 'info-modal'
import EnrollmentPreviewContent from './EnrollmentPreviewContent'
import Typography from 'typography'
import { DefaultArrowTooltip } from 'tooltips'
import { ScreenSizeContext } from 'providers'

const styles = makeStyles((theme) => ({
    stepWizard: {
        height: `calc( 100% - ${theme.overrides.MuiTabs == null ? 0 : theme.overrides.MuiTabs.root.height}px)`, // todo figure out how to do this without calc, maybe flex boxes
        '& .stepper-nav-button': {
            display: 'none'
        },
        '& .MuiMobileStepper-no-padding': {
            justifyContent: 'center'
        }
    },
    container: {
        padding: '1rem'
    },
    brandActionsContainer: { display: 'flex', justifyContent: 'space-between' },
    searchContainer: { visibility: 'hidden' },
    editNumberColumn: {
        cursor: 'pointer',
        '& .edit-button': {
            borderRadius: '50%',
            width: 30,
            height: 30,
            minWidth: 30,
            minHeight: 30,
            display: 'grid',
            placeItems: 'center',
            backgroundColor: 'rgb(133 146 154 / 15%)',
            '& > svg': {
                fontSize: 20,
                color: '#6E7A82'
            },
            '&:hover': {
                backgroundColor: 'rgb(206 239 199 / 50%)',
                '& > svg': {
                    color: '#29921B'
                }

            }
        }
    }
}))

interface Props {
    enrollmentType: EnrollmentType
    itemType: string
    acknowledgedTitle: string

    getDeleteParams: (object) => Record<string, unknown>
    deleteEnrollment: (any) => void
    deleteEnrollmentFeature: boolean

    getAddParams: (object) => Record<string, unknown>
    addEnrollment: (params: any) => void

    getEditParams: (stepWizardData, editing) => Record<string, unknown>
    editDisabledHelper?: (enrollment) => {
        editDisabled: boolean
        editTooltip: string | null
    }
    editEnrollment: (parms: any) => void
    includeEditInMenu?: (params: any) => boolean
    editEnrollmentFeature?: boolean

    previewEnrollmentFeature?: boolean

    networkError: any
    setNetworkError: (value) => void

    enrollmentEnabled: boolean
    enrollmentDisabledTooltip: string

    enrollments: Array<any>
    newEnrollmentRemoteID: string
    tableHeadColumns: Array<any>
}
/**
 *
 */
const Enrollment = ({
    enrollmentType,
    itemType,
    acknowledgedTitle,
    getDeleteParams,
    deleteEnrollment,
    getAddParams,
    getEditParams,
    addEnrollment,
    editEnrollment,
    deleteEnrollmentFeature,
    previewEnrollmentFeature = false,
    editEnrollmentFeature = false,
    networkError,
    setNetworkError,
    editDisabledHelper = () => ({ editDisabled: false, editTooltip: null }),
    enrollmentEnabled,
    enrollmentDisabledTooltip,
    includeEditInMenu = () => true,
    enrollments,
    newEnrollmentRemoteID,
    tableHeadColumns
} : Props) => {
    const {
        acceptedCampaigns
    } = useContext(CampaignContext)

    const screenSizeContext = useContext(ScreenSizeContext)
    const isTabletSize = screenSizeContext.tablet

    const getValueByEnrollmentType = (brandValue, campaignValue, smsNumberValue) => {
        return (
            enrollmentType === EnrollmentType.Brand
                ? brandValue
                : enrollmentType === EnrollmentType.Campaign
                    ? campaignValue
                    : enrollmentType === EnrollmentType.SmsNumber
                        ? smsNumberValue
                        : null
        )
    }
    const classes = styles()
    const [editing, setEditing] = useState(null)
    const [deleting, setDeleting] = useState(null)
    const [preview, setPreview] = useState(null)

    const brandSteps = [
        {
            id: 'business_info',
            label: getValue('sms_enrollment_business'),
            secondaryLabel: getValue('sms_enrollment_information'),
            title: getValue('sms_enrollment_business_information'),
            component: BusinessInformationStep,
            additionalProps: {
                editing: editing
            }
        },
        {
            id: 'business_address',
            label: getValue('sms_enrollment_business'),
            secondaryLabel: getValue('sms_enrollment_address_and_contact'),
            title: getValue('sms_enrollment_business_address_and_contact'),
            component: BusinessAddressAndContact,
            additionalProps: {
                editing: editing
            }
        },
        {
            id: 'business_confirm',
            label: getValue('sms_enrollment_business'),
            secondaryLabel: getValue('sms_enrollment_details'),
            title: getValue('sms_enrollment_business_payment_confirmation'),
            component: BusinessPaymentStep,
            additionalProps: { showAlert: true, showConfirmation: true, editing }
        }
    ]
    const campaignSteps = useMemo(
        () => {
            return [
                {
                    id: 'campaign_usage',
                    label: getValue('sms_enrollment_sms'),
                    secondaryLabel: enrollmentType === EnrollmentType.Campaign && isTabletSize ? null : getValue('sms_enrollment_usage_category'),
                    title: getValue('sms_enrollment_how_using_SMS'),
                    component: CampaignUsageStep,
                    additionalProps: {
                        editing
                    }
                },
                {
                    id: 'campaign_message',
                    label: getValue('sms_enrollment_message'),
                    secondaryLabel: enrollmentType === EnrollmentType.Campaign && isTabletSize ? null : getValue('sms_enrollment_message_settings'),
                    title: getValue('sms_enrollment_message_settings'),
                    component: MessageSettingsStep,
                    additionalProps: {
                        editing
                    }
                },
                {
                    id: 'campaign_sms_content',
                    label: getValue('sms_enrollment_sms_content'),
                    secondaryLabel: enrollmentType === EnrollmentType.Campaign && isTabletSize ? null : getValue('sms_enrollment_examples'),
                    title: getValue('sms_enrollment_sample_message'),
                    component: CampaignSampleMessageStep,
                    additionalProps: {
                        editing
                    }
                },
                {
                    id: 'campaign_policies',
                    label: getValue('sms_enrollment_content'),
                    secondaryLabel: enrollmentType === EnrollmentType.Campaign && isTabletSize ? null : getValue('sms_enrollment_policies'),
                    title: getValue('sms_enrollment_content_and_messaging'),
                    component: CampaignPoliciesStep,
                    additionalProps: {
                        editing
                    }
                },
                {
                    id: 'campaign_contact',
                    label: getValue('sms_enrollment_contact'),
                    secondaryLabel: enrollmentType === EnrollmentType.Campaign && isTabletSize ? null : getValue('sms_enrollment_information'),
                    title: getValue('sms_enrollment_contact_information'),
                    component: CampaignContactStep,
                    additionalProps: {
                        editing
                    }
                },
                {
                    id: 'campaign_preview',
                    label: getValue('sms_enrollment_preview'),
                    secondaryLabel: enrollmentType === EnrollmentType.Campaign && isTabletSize ? null : getValue('sms_enrollment_information'),
                    component: CampaignPreviewStep,
                    additionalProps: {
                        editing
                    }
                },
                {
                    id: 'campaign_invoice',
                    label: getValue('sms_enrollment_invoice'),
                    secondaryLabel: enrollmentType === EnrollmentType.Campaign && isTabletSize ? null : getValue('sms_enrollment_details'),
                    component: CampaignPaymentStep,
                    additionalProps: { showAlert: true, showConfirmation: true, editing }
                }
            ]
        },
        [isTabletSize, editing]
    )
    const smsNumberSteps = [
        {
            id: 'campaign_step',
            label: getValue('sms_enrollment_campaign_tab_title'),
            secondaryLabel: getValue('sms_enrollment_number_step_1_secondaryLabel'),
            title: getValue('sms_enrollment_number_step_1_title'),
            component: NumberCampaignStep,
            additionalProps: {
                eligibleCampaigns: acceptedCampaigns
            }
        },
        {
            id: 'number_step',
            label: getValue('sms_enrollment_sms_number_column_name'),
            secondaryLabel: getValue('sms_enrollment_number_step_2_secondaryLabel'),
            title: getValue('sms_enrollment_number_step_2_title'),
            component: NumberSelectStep
        }
    ]
    const steps = getValueByEnrollmentType(brandSteps, campaignSteps, smsNumberSteps)

    const optionsDropdownButton = props => {
        return (
            <div {...props}>
                <Options data-button='options' variant='vertical'/>
            </div>
        )
    }
    const renderRegisteredOnColumn = (row) => {
        const createdDate = getValueByEnrollmentType(row.created_date, row.created_at, null)
        const d = new Date(0)
        d.setUTCSeconds(createdDate)

        const year = d.toLocaleString('default', { year: 'numeric' })
        const month = d.toLocaleString('default', { month: '2-digit' })
        const day = d.toLocaleString('default', { day: '2-digit' })

        return `${year}-${month}-${day}`
    }
    const getBrandMenuItems = (row) => {
        const hasCampaigns = row.campaign_count > 0
        const isDeleted = row.deleted_at != null
        const disableDelete = hasCampaigns || isDeleted
        const menuItems = []
        if (deleteEnrollmentFeature) {
            const tooltipText = disableDelete ? hasCampaigns ? getValue('sms_enrollment_brand_delete_tooltip') : isDeleted ? getValue('sms_enrollment_enrollment_status_deleted_tooltip') : null : null
            menuItems.push({
                text: 'Delete',
                onClick: () => setDeleting(row),
                disabled: disableDelete,
                tooltipText: tooltipText
            })
        }

        return menuItems
    }
    const getCampaignMenuItems = (row) => {
        const hasDIDs = row.active_did_count > 0
        const isDeleted = row.deleted_at != null
        const disableDelete = hasDIDs || isDeleted
        const menuItems = []
        if (deleteEnrollmentFeature) {
            const tooltipText = disableDelete ? hasDIDs ? getValue('sms_enrollment_campaign_delete_tooltip') : isDeleted ? getValue('sms_enrollment_enrollment_status_deleted_tooltip') : null : null
            menuItems.push(
                {
                    text: 'Delete',
                    onClick: () => setDeleting(row),
                    disabled: disableDelete,
                    tooltipText: tooltipText
                })
        }
        return menuItems
    }
    const getSmsNumberMenuItems = (row) => {
        const menuItems = []
        if (deleteEnrollmentFeature) {
            menuItems.push({
                text: 'Delete',
                onClick: () => {
                    setDeleting(row)
                }
            })
        }
        return menuItems
    }
    const renderOptionsColumn = (row) => {
        const menuItems = getValueByEnrollmentType(getBrandMenuItems(row), getCampaignMenuItems(row), getSmsNumberMenuItems(row))

        if (previewEnrollmentFeature) {
            menuItems.push({
                text: 'Preview',
                onClick: () => setPreview(row)
            })
        }

        if (editEnrollmentFeature && includeEditInMenu(row)) {
            const { editDisabled, editTooltip } = editDisabledHelper(row)
            menuItems.push({
                text: 'Edit',
                onClick: () => setEditing(row),
                disabled: editDisabled,
                tooltipText: editDisabled ? editTooltip : null
            })
        }

        return menuItems.length > 0
            ? (
                <DropdownMenu
                    button={optionsDropdownButton}
                    menuItems={menuItems}
                    dropdownStyle = 'configure'
                />
            )
            : <></>
    }
    const generateOneRow = (data) => {
        const brandColumns = [
            {
                key: 0,
                content: data.csp_brand_id
            },
            {
                key: 1,
                content: data.name
            },
            {
                key: 2,
                content: renderRegisteredOnColumn(data)
            },
            {
                key: 3,
                content: <StatusChip status={data.registration_status} enrollmentType={EnrollmentType.Brand} deleted={data.deleted_at != null } />
            },
            {
                key: 4,
                content: renderOptionsColumn(data)
            }
        ]
        const campaignColumns = [
            {
                key: 0,
                content: data.name
            },
            {
                key: 1,
                content: <StatusChip status={data.status} enrollmentType={EnrollmentType.Campaign} deleted={data.deleted_at != null } />
            },
            {
                key: 2,
                content: data.brand_name
            },
            {
                key: 3,
                content: renderRegisteredOnColumn(data)
            },
            {
                key: 4,
                content: renderOptionsColumn(data)
            }
        ]
        const smsNumberColumns = [
            {
                key: 0,
                content: data['csp_campaign.name']
            },
            {
                key: 1,
                content: data.did // data.number
            },
            {
                key: 2,
                content: renderOptionsColumn(data)
            }
        ]

        const columns = getValueByEnrollmentType(brandColumns, campaignColumns, smsNumberColumns)

        return { key: data.id, columns }
    }
    const generateTableRows = () => {
        const rows = !(enrollments?.length > 0 && typeof enrollments?.map === 'function')
            ? []
            : enrollments.map(x => {
                return generateOneRow(x)
            })
        return rows
    }

    const [showWizard, setShowWizard] = useState(false)

    const onClickHandler = async () => {
        setShowWizard(true)
    }

    const onSubmitHandler = async (data) => {
        setShowWizard(false)
        if (editing == null) {
            const addParams = await getAddParams(data)
            addEnrollment(addParams)
        } else {
            const editParams = await getEditParams(data, editing)
            editEnrollment(editParams)
        }
    }

    const onDeleteHandler = async () => {
        const deleteParms = await getDeleteParams(deleting)
        deleteEnrollment(deleteParms)
    }

    useEffect(() => {
        if (editing != null) {
            setShowWizard(true)
        }
    }, [editing])

    const smallView = enrollmentType === EnrollmentType.Campaign && screenSizeContext.mobile

    return showWizard
        ? (
            <StepWizard className={classes.stepWizard}
                steps={steps} smallView={smallView} onSubmit={onSubmitHandler} />
        )
        : (
            <div className={classes.container} >
                <div className={classes.brandActionsContainer}>
                    <button className={classes.searchContainer}>Search?</button>
                    <DefaultArrowTooltip id='addEnrollmentBtnTooltip' title={!enrollmentEnabled ? enrollmentDisabledTooltip : null}>
                        <span>
                            <Button
                                id='addEnrollmentBtn'
                                disabled={!enrollmentEnabled}
                                size='large'
                                startIcon={<AddIcon />}
                                onClick={onClickHandler}>
                                {getValue(newEnrollmentRemoteID)}
                            </Button>
                        </span>
                    </DefaultArrowTooltip>

                </div>
                <TableMui
                    headColumns={tableHeadColumns}
                    rows={generateTableRows()}
                    infiniteScroller={false}
                    reverseScroll={false}
                    hasMore={false}
                    listView={false}
                    fullWidth={1}
                />
                <InfoModal
                    title="Error"
                    isShown={networkError != null}
                    content={<Typography variant='h5'>{networkError}</Typography>}
                    buttonText="Ok"
                    onConfirm={() => {
                        setNetworkError(null)
                    }}
                    size="size3"
                />
                <InfoModal
                    isShown={preview != null}
                    id='previewModal'
                    title={null}
                    content={<EnrollmentPreviewContent enrollment={preview} enrollmentType={enrollmentType} />}
                    buttonText="Ok"
                    onConfirm={() => {
                        setPreview(null)
                    }}
                    size="size1200"
                />
                <ConfirmDeleteModal
                    itemType={itemType}
                    isShown={deleting != null}
                    acknowledgedTitle={acknowledgedTitle}
                    notAcknowledgedTitle={acknowledgedTitle}
                    acknowledgedMainContent=""
                    notAcknowledgedMainContent=""
                    onClose={() => setDeleting(null)}
                    onDelete={onDeleteHandler}
                />
            </div>
        )
}

export default Enrollment
