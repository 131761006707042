import AccountContacts from './components/screens/AccountContacts.vue'
import AccountSettings from './components/screens/Account.vue'

/**
 *
 */
export {
    AccountContacts,
    AccountSettings
}
