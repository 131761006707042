<template>
    <div class="form-validation-error action darken-2 w-body-2 error--text d-flex mx-auto">
        <w-btn fab width="24" height="24" color="error" class="mr-2 no-background-hover">
            <v-icon color="white">$vuetify.icons.exclamation</v-icon>
        </w-btn>
        <div class="w-100">
            <slot></slot>
        </div>
        <w-btn @click="$emit('closed')" fab width="24" height="24" class="action darken-2 ml-2 no-background-hover" text>
            <v-icon color="error">$vuetify.icons.close</v-icon>
        </w-btn>
    </div>
</template>
<script>
  export default {
    props: [],
    data() {
      return {
      };
    },
  };
</script>

<style scoped>
.form-validation-error {
    padding: 16px;
    max-width: 700px;
}
</style>
