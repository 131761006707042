// React
import React from 'react'

// Components
import SearchBar from './search-bar/search-bar'
import SearchResults from './search-results/search-results'
import SearchFilters from './search-filters/search-filters'
import PricingPage from './util/pricing-page/pricing-page'
import MessageLoader from './search-results/loader/message-loader'
import PropTypes from 'prop-types'

// Number Search App
class NumberSearch extends React.Component {
  state = {
      purchaseProgress: this.props.purchaseProgress
  };

  /**
   * @param {object} prevProps
   */
  componentDidUpdate (prevProps) {
      if (prevProps.purchaseProgress !== this.props.purchaseProgress) {
          this.setState({ purchaseProgress: this.props.purchaseProgress })
      }
  }

  /**
   *
   */
  render () {
      let searchBarJsx = null
      if (this.props.mode !== 'tollfree_nonvanity' &&
        this.props.mode !== 'local_nonvanity'
      ) {
          searchBarJsx = <SearchBar
              keywordSearch={this.props.keywordSearch.bind(this)}
              searchPosition={this.props.searchPosition.bind(this)}
              is_free={this.props.is_free}
              is_tollfree={this.props.is_tollfree}
              is_local={this.props.is_local}
              AreaCodeSearch={this.props.AreaCodeSearch.bind(this)}
              CitySearch={this.props.CitySearch.bind(this)}
              cities={this.props.cities}
              area_codes={this.props.area_codes}
              CityNumberSearch={this.props.CityNumberSearch.bind(this)}
              reset={this.props.reset}
              handleReset={this.props.handleReset}
              initialFilterBy={this.props.initialFilterBy}
              filterBy={this.props.filterBy}
          />
      }

      return (
          <>
              {this.props.purchasing && <div
                  className='purchasing-wrapper'
                  style={{ display: this.props.purchasing ? 'block' : 'none' }}
              >
                  <MessageLoader
                      data-test-id='checkout-loader'
                      message={'Hold on while we checkout your cart'}
                      submessage={`${this.state.purchaseProgress}/${this.props.reservedNumbers.length}`}
                  />
              </div>}
              <div
                  style={{ display: (this.props.showPricing && !this.props.purchasing) ? 'block' : 'none' }}
              >
                  {
                      this.props.showPricing &&
                    <PricingPage
                        toggleSummaryModal={this.props.toggleSummaryModal}
                        removeNumberAndReprice={this.props.removeNumberAndReprice}
                        reservedNumbers={this.props.reservedNumbers}
                        prettyFormat={this.props.E164toPDC}
                        pricingSummary={this.props.pricingSummary}
                        removingNumber={this.props.removingNumber}
                        purchasing={this.props.purchasing}
                    />
                  }
              </div>
              <div
                  style={{ display: (!this.props.showPricing && !this.props.purchasing) ? 'block' : 'none' }}
              >
                  <div className="number-search-wrapper">
                      <div className="search-bar row-wrap">
                          <SearchFilters
                              isMobile={this.props.isMobile}
                              handleCheckbox={this.props.handleCheckbox.bind(this)}
                              handleSelect={this.props.handleSelect}
                              handleNpaFilterUpdate={this.props.handleNpaFilterUpdate}
                              handleCitiesFilterUpdate={this.props.handleCitiesFilterUpdate}
                              sortBy={this.props.sortBy}
                              local={this.props.local}
                              tollfree={this.props.tollfree}
                              priceSlider={this.props.priceSlider}
                              price={this.props.price}
                              handleSlider={this.props.handleSlider}
                              handlePrice={this.props.handlePrice}
                              handleReset={this.props.handleReset}
                              handleResetCity={this.props.handleResetCity}
                              handleResetNpa={this.props.handleResetNpa}
                              handleResetNumberTypeFilter={this.props.handleResetNumberTypeFilter}
                              filteredCities={this.props.filteredCities}
                              resetNpa={this.props.resetNpa}
                              resetCity={this.props.resetCity}
                              is_free={this.props.is_free}
                              reset={this.props.reset}
                              mode={this.props.mode}
                              cities={this.props.cities}
                              area_codes={this.props.area_codes}
                              all_area_codes={this.props.all_area_codes}
                              CitySearch={this.props.CitySearch}
                              city_filter_loading={this.props.city_filter_loading}
                              invalidAreaCodeMessage={this.props.invalidAreaCodeMessage}
                          />
                      </div>
                      {searchBarJsx}
                      <div className="search-results-wrap row">
                          <SearchResults
                              resultData={this.props.resultData}
                              showPricing={this.props.showPricing}
                              reservedNumbers={this.props.reservedNumbers}
                              mode={this.props.mode}
                              alpha={this.props.alpha}
                              prettyFormat={this.props.prettyFormat.bind(this)}
                              E164toPDC={this.props.E164toPDC.bind(this)}
                              moveNumber={this.props.moveNumber.bind(this)}
                              loading={this.props.loading}
                              loadingMessage={this.props.loadingMessage}
                              purchasing={this.props.purchasing}
                              reservePop={this.props.reservePop}
                              newestReservation={this.props.newestReservation}
                              errors={this.props.errors}
                              resetErrors={this.props.resetErrors}
                          />
                      </div>
                  </div>
              </div>

          </>
      )
  }
}

NumberSearch.propTypes = {
    mode: PropTypes.string.isRequired,
    purchaseProgress: PropTypes.number.isRequired,
    keywordSearch: PropTypes.func.isRequired,
    searchPosition: PropTypes.func.isRequired,
    is_free: PropTypes.bool.isRequired,
    is_tollfree: PropTypes.bool.isRequired,
    is_local: PropTypes.bool.isRequired,
    AreaCodeSearch: PropTypes.func.isRequired,
    area_codes: PropTypes.array.isRequired,
    CitySearch: PropTypes.func.isRequired,
    cities: PropTypes.array.isRequired,
    CityNumberSearch: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
    initialFilterBy: PropTypes.object.isRequired,
    filterBy: PropTypes.object.isRequired,
    purchasing: PropTypes.bool.isRequired,
    reservedNumbers: PropTypes.array.isRequired,
    showPricing: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    E164toPDC: PropTypes.func.isRequired,
    price: PropTypes.number.isRequired,
    pricingSummary: PropTypes.object.isRequired,
    handleCheckbox: PropTypes.func.isRequired,
    handleSelect: PropTypes.func.isRequired,
    handleNpaFilterUpdate: PropTypes.func.isRequired,
    handleCitiesFilterUpdate: PropTypes.func.isRequired,
    handleSlider: PropTypes.func.isRequired,
    toggleSummaryModal: PropTypes.func.isRequired,
    removeNumberAndReprice: PropTypes.func.isRequired,
    removingNumber: PropTypes.bool.isRequired,
    local: PropTypes.bool.isRequired,
    tollfree: PropTypes.bool.isRequired,
    priceSlider: PropTypes.func.isRequired,
    handlePrice: PropTypes.func.isRequired,
    handleResetCity: PropTypes.func.isRequired,
    filteredCities: PropTypes.array.isRequired,
    resetNpa: PropTypes.func.isRequired,
    all_area_codes: PropTypes.array.isRequired,
    sortBy: PropTypes.string.isRequired,
    handleResetNpa: PropTypes.func.isRequired,
    handleResetNumberTypeFilter: PropTypes.func.isRequired,
    resetCity: PropTypes.func.isRequired,
    city_filter_loading: PropTypes.bool.isRequired,
    invalidAreaCodeMessage: PropTypes.string.isRequired,
    resultData: PropTypes.object.isRequired,
    alpha: PropTypes.string.isRequired,
    prettyFormat: PropTypes.func.isRequired,
    moveNumber: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loadingMessage: PropTypes.string.isRequired,
    reservePop: PropTypes.func.isRequired,
    newestReservation: PropTypes.bool.isRequired,
    errors: PropTypes.array.isRequired,
    resetErrors: PropTypes.func.isRequired
}

export default NumberSearch
