<template>
  <div>
    <w-textarea
        v-if="!is_onvego"
        v-bind="$attrs"
        v-on="$listeners"
    />
    <div v-else-if="has_onvego_apointments" ref="onvego_iframe_container" :id="onvego_iframe_container_id"></div>
  </div>
</template>
<script>
import {getFeatureEnabled} from 'feature-flag';
  export default {
    props: {
        is_onvego: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            required: true,
        }
    },
    data() {
      return {
        onvego_iframe_container_id: 'onvego-calendar',
        has_onvego_apointments: false,
        calendar_initiated: false,
        pdc_onvego_id: 'b00f7160-b1a8-400a-96a1-cc67a0f51bc0',
      };
    },
    async created() {
        if (!document.querySelectorAll(`script[src="${process.env.REACT_APP_ONVEGO_SERVER_URL}"]`).length) {
            this.addOnvegoScript();
        }
        this.$data.has_onvego_apointments = await getFeatureEnabled('console.show_onvego_appointments');
    },
    mounted() {
        this.show_onvego_calendar_if_needed();
        window.addEventListener('message', (data) => {
            try {
                if (data.data) {
                    let json;
                    try {
                        json = JSON.parse(data.data);
                    } catch (e) {
                        json = data.data;
                    }
                    if (json.action && ['calendar-info-update', 'working-hours-update', 'appointments-update'].includes(json.action)) {
                        this.$emit('change');
                        // let height = 0;
                        // if (json.appointments // default appointments, fired when user opens calendar
                        //     && json.appointments.length === 1
                        //     && json.appointments.every((x) => !x.appointmentType && !x.closingMessage && x.duration === 30)
                        // ) {
                        //     height = 270;
                        // } else {
                        //     const single_appointment_height = 50;
                        //     const default_elements_height = 60 + 16;
                        //     height = default_elements_height + json.appointments.length * single_appointment_height + (json.appointments.length + 1) * 34;
                        // }
                        // if (this.$refs.onvego_iframe_container) {
                        //     this.$refs.onvego_iframe_container.style.height = `${height}px`;
                        // }
                    }
                    if (json.iframeHeight || json.iframeHeight === 0) {
                        this.$refs.onvego_iframe_container.style.height = `${json.iframeHeight}px`;
                    }
                }
            } catch (err) {
                console.log(err);
            }
        });
    },
    methods: {
        addOnvegoScript() {
            const onvegoScript = document.createElement('script');
            onvegoScript.setAttribute('src', process.env.REACT_APP_ONVEGO_SERVER_URL);
            document.head.appendChild(onvegoScript);
        },
        show_onvego_calendar_if_needed() {
            if (
                !this.$data.calendar_initiated
                && this.$props.is_onvego
            ) {
                this.show_calendar();
                this.$data.calendar_initiated = true;
            }
        },
        show_calendar(ms = 10) {
            const valid_max_retry_time = ms < 6000
            setTimeout(() => {
                if (this.$refs.onvego_iframe_container) {
                    if (
                        window &&
                        window.buildAppointmentsPanel &&
                        typeof window.buildAppointmentsPanel === 'function'
                    ) {
                        try {
                            window.buildAppointmentsPanel(this.$refs.onvego_iframe_container, this.$session.user.id, this.$props.item.id, this.$data.pdc_onvego_id);
                        } catch (err) {
                            if (err.message === 'window.buildAppointmentsPanel is not a function' && valid_max_retry_time) {
                                return this.show_calendar(ms + 1000)
                            }
    
                            throw err;
                        }
                    } else if (valid_max_retry_time) {
                        return this.show_calendar(ms + 1000)
                    }
                }
            }, ms);
        }
    }
  };
</script>
<style scoped lang="scss">
#onvego-calendar{
    height: 270px;
}
</style>
