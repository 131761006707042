import React, { Component } from 'react'
import TextField from 'pdc-text-field'
import Button from 'button'
import { XIcon } from 'pdc-svg-icons'
import { theme } from 'get-theme'
import styles from './search-drawer-styles'
import SearchResults from './SearchResults'
import { SwipeableDrawer, withStyles } from '@material-ui/core'
import RemoteConfigValue from 'remote-config-value'

class SearchDrawer extends Component {
    constructor (props) {
        super(props)
        this.state = {
            sentFilterSelected:	true,
            receivedFilterSelected:	true,
            hasMediaFilterSelected:	true,
            noMediaFilterSelected:	true,
            contactsLoading:	false,
            searchText:	'',
            peopleInput:	'',
            contactItems:	[]
        }
        this.searchInputRef = React.createRef()
    }

	onClose = () => {
	    this.setState({ searchText: '' })
	    delete this.lastFilters
	    delete this.lastInboxType
	    this.props.onClose()
	}

	onSearch = () => {
	    // Inbox type
	    const location = this.props.location
	    let inboxType = {
	        messages:	'message',
	        voicemail:	'voicemail',
	        faxes:	'fax',
	        all:	'all'
	    }[location || 'all']
	    inboxType = 'message'

	    const searchText = this.state.searchText.trim()
	    const filters = { text: searchText }

	    // Use last filters and inbox type for loadMore when it is done
	    this.lastFilters	= JSON.parse(JSON.stringify(filters))
	    this.lastInboxType	= inboxType

	    this.props.onSearch(filters, inboxType)
	}

	renderSearchWrapper = () => {
	    const { classes, searchLoading } = this.props
	    const buttonText = searchLoading ? 'Searching...' : 'Search'
	    const resultsClass = this.props.searchResults && this.props.searchResults.length ? 'has-results' : ''
	    return (
	        <div className={`${classes.searchWrapper} ${resultsClass}`}>
	            <TextField
	                fullWidth
	                autoFocus
	                inputRef		= {this.searchInputRef}
	                content			= {this.state.searchText}
	                label			= {<RemoteConfigValue valueId="search_text_feild_label"/>}
	                onKeyPress		= {this.onKeyPress}
	                onInputChange	= {searchText => this.setState({ searchText })}
	            />

	            <Button
	                onClick		= {() => this.onSearch()}
	                className	= {classes.searchButton}
	                disabled	= {!this.state.searchText || searchLoading}
	            >{buttonText}</Button>
	        </div>
	    )
	}

	renderSearchResults = () => {
	    if (this.props.searchLoading || !this.lastFilters) return null
	    return this.props.renderSearchResults()
	}

	render () {
	    const { classes } = this.props
	    return (
	        <SwipeableDrawer
	            classes	= {{ paper: classes.drawerWrapper, paperAnchorBottom: classes.drawerStyle }}
	            anchor	= 'bottom'
	            open	= {this.props.isOpen}
	            onClose	= {this.onClose}
	            disableBackdropClick
	            onBackdropClick = {() => this.searchInputRef.current.focus()}
	        >
	            <div className={classes.wrapper}>
	                <div className={classes.xButtonWrapper} onClick={this.onClose}><XIcon color={theme.palette.secondary[0]}/></div>
	                {this.renderSearchWrapper()}
	                {this.renderSearchResults()}
	            </div>
	        </SwipeableDrawer>
	    )
	}
}

export default withStyles(styles)(SearchDrawer)
