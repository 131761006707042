<template>
	<div class="billing">
		<Submenu
			:routes="{
				'billing.index': {
					title: $lang.t('billing.statements', 'Statements'),
				},
				'billing.payment-methods': {
					title: $lang.t('billing.payment-methods', 'Payment methods'),
					subroutes: ['billing.payment-methods.create']
				},
			}"
			:key="`${$lang.detect()}${$route.name}`"
		/>
	<Statements v-if="$route.name === 'billing.index'" data-test-id="billing-invoices"/>
	<PaymentMethodsIndex
      v-if="$route.name === 'billing.payment-methods'"
      data-test-id="payment-methods-index"
    />
    <PaymentMethodsCreate
      v-if="$route.name === 'billing.payment-methods.create'"
      data-test-id="payment-methods-show"
    />
	</div>
</template>

<script>
import {Statements, PaymentMethodsIndex, PaymentMethodsCreate} from 'billing-components';
import Submenu from '../elements/Submenu.vue';

export default {
	components: {
		Submenu,
		Statements,
		PaymentMethodsIndex,
		PaymentMethodsCreate,
	},
};
</script>
