/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './app-styles'
import { setSmallView, switchView } from '../actions/people'
// import { switchContact } from '../actions/users'
// import ContactSelector from 'contact-selector'
import ContactSelector from './selectors/ContactSelector'
// import TeammateSelector from 'teammate-selector'
import TeammateSelector from './selectors/TeammateSelector'
import ContactContent from './contents/ContactContent'
import TeammateContent from './contents/TeammateContent'
import { AddContactIcon2 } from 'pdc-svg-icons'
import ConfirmModal from 'confirm-modal'

import PhoneComUser from 'phone-com-user'
import ResizeAware from 'react-resize-aware'
import { withStyles } from '@material-ui/styles'
import gtmDataPush from 'gtm-events'
import { Switch, Route, Redirect } from 'react-router-dom'
import { MyPhoneContactConsumer } from 'providers'

const GTM_APP_NAME = 'people'
const GTM_MAP = {
    ALREADY_ACTIVE: 0,
    NOT_ACTIVE: 1,
    ALREADY_EDITING: 0,
    ADD_CONTACT: 1,
    DISCARD_CHANGES: 1,
    REJECT_DISCARD: 0
}

const threshold = 768

const mapStateToProps = (state) => ({
    smallView: state.smallView,
    currentView: state.currentView
})
const mapDispatchToProps = (dispatch) => ({
    setSmallView: (isSmall) => dispatch(setSmallView(isSmall)),
    switchView: (view) => dispatch(switchView(view))
})

class App extends Component {
    constructor (props) {
        super(props)
        this.setTabs()
        this.fixRoutePath()
        this.state = {
            isEditingContact: false,
            discardChangesReason: null
        }
        if (props.extension && props.extension.extension_id !== PhoneComUser.getExtensionId()) {
            PhoneComUser.changeExtension(props.extension.extension_id)
        }
    }

    getCurrentTabFromRoute = () => {
        const pathSplit = this.props.routeProps.location.pathname.split('people')
        const afterSettings = pathSplit.pop()
        if (!afterSettings) return
        const tabName = afterSettings.split('/').filter(e => e)[0]
        if (!tabName) return
        if (!this.tabs.map(t => t.id).includes(tabName)) return
        return tabName
    }

    fixRoutePath = () => {
        const currentTab = this.getCurrentTabFromRoute()
        if (!currentTab) {
            const currentTab = this.tabs.find(t => t.default).id
            this.updateRouterHistory(currentTab)
        }
    }

    setTabs = () => {
        const tabs = [{ id: 'contacts', icon: null, default: true }]
        const tabs2 = { CONTACTS: 'contacts' }
        if (this.props.extension.userId) {
            tabs.push({ id: 'teammates', icon: null })
            tabs2.TEAMMATES = 'teammates'
        }
        this.tabs = tabs
        this.tabs2 = tabs2
    }

    componentDidMount () {
        // This goes up to Communicator.js in order to hide the loading animation
        this.props.onLoaded()
    }

    componentDidUpdate = () => {
        this.fixRoutePath()
    }

    onTabSwitch = newTab => {
        const { currentTab } = this.getCurrentTabFromRoute()
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: `tab-click;${currentTab}->${newTab}`,
            PDC_Value: currentTab !== newTab ? GTM_MAP.NOT_ACTIVE : GTM_MAP.ALREADY_ACTIVE
        })
        if (this.state.isEditingContact) return this.setState({ discardChangesReason: newTab })
        this.switchTab(newTab)
    }

    getBasePath = () => {
        const basePath = this.props.routeProps.match.path.replace(/\/+$/g, '')
        return basePath
    }

    updateRouterHistory = tabName => {
        this.props.routeProps.history.push(`${this.getBasePath()}/${tabName}`)
    }

    switchTab = (newTab = 'contacts') => {
        this.props.routeProps.history.push(`${this.getBasePath()}/${newTab}`)
    }

    switchContact = (contactId = '') => {
        const { routeProps } = this.props
        routeProps.history.push(`${routeProps.match.url}/contacts/${contactId}`)
    }

    switchTeammate = (teammateId = '') => {
        const { routeProps } = this.props
        routeProps.history.push(`${routeProps.match.url}/teammates/${teammateId}`)
    }

    startConversation = (phoneNumber) => {
        const extensionId = parseInt(this.getBasePath().split('/')[1].substring(1))
        const redirectPath = `/e${extensionId}/messages/new-conversation/${phoneNumber}`
        this.props.routeProps.history.push(redirectPath)
    }

    handleResize = (size) => {
        const needToExpand =
            this.props.smallView &&
            ((this.props.standalone && size.width >= threshold) || !this.props.screenViewType.isMobileView)
        const needToShrink =
            !this.props.smallView &&
            ((this.props.standalone && size.width < threshold) || this.props.screenViewType.isMobileView)
        if (needToShrink) {
            this.props.setSmallView(true)
        } else if (needToExpand) {
            this.props.setSmallView(false)
        }
    }

    renderTabs = () => {
        const { classes } = this.props
        const currentTab = this.getCurrentTabFromRoute()
        const tabs2 = this.tabs2
        const tabsValues = Object.values(tabs2)
        if (tabsValues.length < 2) return null
        let placeholderPosition = tabsValues.findIndex((t) => t === currentTab)
        if (placeholderPosition === 0) placeholderPosition = 'left'
        else if (placeholderPosition === tabsValues.length - 1) placeholderPosition = 'right'
        else placeholderPosition = 'center'
        return (
            <div className={classes.tabsSection}>
                <div className={classes.tabsWrapper}>
                    <div className={classes.tabs}>
                        <div
                            onClick={() => this.onTabSwitch(tabs2.CONTACTS)}
                            className={`tab ${currentTab === tabs2.CONTACTS ? 'selected' : ''}`}
                        >
                            Contacts
                        </div>
                        <div
                            onClick={() => this.onTabSwitch(tabs2.TEAMMATES)}
                            className={`tab ${currentTab === tabs2.TEAMMATES ? 'selected' : ''}`}
                        >
                            Teammates
                        </div>
                        <div className={`${classes.tabsSmallPlaceholder} ${placeholderPosition}`}></div>
                        <div className={`${classes.tabsBigPlaceholder} ${placeholderPosition}`}></div>
                    </div>
                </div>
            </div>
        )
    }

    onAddContactClick = () => {
        if (this.state.isEditingContact) {
            gtmDataPush({
                PDC_Action: GTM_APP_NAME,
                PDC_Label: 'add-contact-click',
                PDC_Value: GTM_MAP.ALREADY_EDITING
            })
            return this.setState({ discardChangesReason: 'add-contact' })
        }
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: 'add-contact-click',
            PDC_Value: GTM_MAP.ADD_CONTACT
        })
        this.switchContact('create-new-contact')

        // switch to add contact view
        this.props.switchView('content')
    }

    renderAddContactButton = () => {
        const { classes } = this.props
        return (
            <div className={classes.addContactButton} onClick={this.onAddContactClick}>
                <AddContactIcon2 />
                <span>Add contact</span>
            </div>
        )
    }

    renderSelector = (contactId) => {
        const currentTab = this.getCurrentTabFromRoute()
        const tabs2 = this.tabs2
        const areTeammatesShown = Object.values(tabs2).length > 1
        if (currentTab === this.tabs2.CONTACTS) {
            return (
                <ContactSelector
                    contactsUtil={this.props.contactsUtil}
                    isEditing={this.state.isEditingContact}
                    setEditing={this.setEditing}
                    hasTabs={areTeammatesShown}
                    switchTab={this.switchTab}
                    switchContact={this.switchContact}
                    currentContactId={contactId}
                    routeProps={this.props.routeProps}
                />
            )
        }
        if (currentTab === this.tabs2.TEAMMATES) return <TeammateSelector switchTeammate={this.switchTeammate} />
    }

    setEditing = (isEditingContact) => {
        this.setState({ isEditingContact })
        this.props.setHasChange(isEditingContact)
    }

    discardChanges = () => {
        const { discardChangesReason } = this.state
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: `discard-changes;${discardChangesReason}`,
            PDC_Value: GTM_MAP.DISCARD_CHANGES
        })
        if (discardChangesReason === 'add-contact') this.switchContact('create-new-contact')
        else this.switchTab(discardChangesReason)
        this.setState({ discardChangesReason: null, isEditingContact: false })
        this.setHasChange(false)
    }

    renderDiscardChangesModal = () => {
        const onReject = () => {
            gtmDataPush({
                PDC_Action: GTM_APP_NAME,
                PDC_Label: `discard-changes;${this.state.discardChangesReason}`,
                PDC_Value: GTM_MAP.REJECT_DISCARD
            })
            this.setState({ discardChangesReason: null })
        }
        return (
            <ConfirmModal
                title='Discard changes?'
                isShown={Boolean(this.state.discardChangesReason)}
                content={null}
                noButtonText='Cancel'
                yesButtonText='Discard'
                yesButtonColor='attention'
                onReject={onReject}
                onConfirm={this.discardChanges}
                size='size550'
            />
        )
    }

    renderLeftPanel = (contactId) => {
        const { classes } = this.props
        const isActive = true
        return (
            <div
                className={`${classes.leftPanel} ${!isActive ? 'hidden' : ''} ${
                    this.props.smallView ? 'small-view' : ''
                }`}
            >
                <div className={classes.panelHeader}>{this.renderAddContactButton()}</div>
                <div className={classes.leftPanelContent}>
                    {this.renderTabs()}
                    {this.renderSelector(contactId)}
                </div>
            </div>
        )
    }

    renderRightPanel = () => {
        const { classes } = this.props
        return (
            <div className={classes.rightPanel}>
                <Switch>
                    <Route
                        path={[`${this.getBasePath()}/contacts/create-new-contact`]}
                        render={() => (
                            <MyPhoneContactConsumer>
                                {(contacts) => (
                                    <ContactContent
                                        isVirtualExtension={this.props.extension.is_virtual}
                                        contactsUtil={this.props.contactsUtil}
                                        setEditing={this.setEditing}
                                        makeCall={this.props.makeCall}
                                        startConversation={this.startConversation}
                                        onAddContactClick={this.onAddContactClick}
                                        currentContactId={'create-new-contact'}
                                        switchContact={this.switchContact}
                                        smallView={this.props.smallView}
                                        updateContact={contacts.updateContact}
                                        deleteContact={contacts.deleteContact}
                                    />
                                )}
                            </MyPhoneContactConsumer>
                        )}
                    />
                    {/* <Route
                        path={[`${this.getBasePath()}/teammates/create-new-contact`]}
                        render={() => (
                            <TeammateContent
                                isVirtualExtension={this.props.extension.is_virtual}
                                makeCall={this.props.makeCall}
                                startConversation={this.startConversation}
                                currentContactId={'create-new-contact'}
                            />
                        )}
                    /> */}
                    {this.props.contactsUtil.contacts && <Route
                        path={[`${this.getBasePath()}/contacts/:contact_id?`]}
                        render={(props) => {
                            let { contact_id } = props.match.params
                            contact_id = contact_id ? parseInt(contact_id) : contact_id
                            return contact_id || (!contact_id && !this.props.smallView)
                                ? (
                                    <MyPhoneContactConsumer>
                                        {(contacts) => (
                                            <ContactContent
                                                isVirtualExtension={this.props.extension.is_virtual}
                                                contactsUtil={this.props.contactsUtil}
                                                setEditing={this.setEditing}
                                                makeCall={this.props.makeCall}
                                                startConversation={this.startConversation}
                                                onAddContactClick={this.onAddContactClick}
                                                currentContactId={contact_id}
                                                switchContact={this.switchContact}
                                                smallView={this.props.smallView}
                                                updateContact={contacts.updateContact}
                                                deleteContact={contacts.deleteContact}
                                            />
                                        )}
                                    </MyPhoneContactConsumer>
                                )
                                : null
                        }}
                    />}
                    <Route
                        path={[`${this.getBasePath()}/teammates/:current_teammate_id?`]}
                        render={(props) => {
                            const current_teammate_id = parseInt(props.match.params.current_teammate_id)
                            return current_teammate_id
                                ? (
                                    <TeammateContent
                                        isVirtualExtension={this.props.extension.is_virtual}
                                        makeCall={this.props.makeCall}
                                        startConversation={this.startConversation}
                                        currentTeammateId={current_teammate_id}
                                        switchTeammate={this.switchTeammate}
                                    />
                                )
                                : null // TODO: change to graphic on prod
                        }}
                    />
                </Switch>
            </div>
        )
    }

    render () {
        const { classes } = this.props
        return (
            <ResizeAware style={{ height: '100%' }} onResize={this.handleResize}>
                <div className={classes.wrapper2}>
                    {/* only select default tab, not first item on list like other selectors */}
                    <Route exact path={[`${this.props.routeProps.match.path}`]} render={() => <Redirect to={`${this.getBasePath()}/contacts`}/>}/>
                    <Route
                        path={[
                            `${this.getBasePath()}/contacts/:contact_id?`,
                            `${this.getBasePath()}/teammates/:contact_id?`
                        ]}
                        render={(props) => {
                            let { contact_id } = props.match.params
                            const { smallView } = this.props
                            contact_id = contact_id ? parseInt(contact_id) : contact_id
                            if (!smallView || (smallView && !contact_id)) return this.renderLeftPanel(contact_id)
                            if (smallView && !contact_id) return this.renderLeftPanel()
                        }}
                    />
                    {this.renderRightPanel()}
                    {this.renderDiscardChangesModal()}
                </div>
            </ResizeAware>
        )
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))
