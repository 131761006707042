<template>
    <div class="video list-page" data-test-id="video-users-list-page">
        <div class="">
            <w-alert
              v-if="user.alert"
              :message="user.alert.message"
              :level="user.alert.level"
              :closable="user.enable_close_alert"
              @closed="user.alert = null"
              class="mb-6"
            />
            <div v-if="user.loading">
                <w-loader v-if="!user.deleting"/>
                <ProgressBar
                  v-else
                  :key="user.deleting.status.length"
                  :processing="user.deleting"
                  @stopProcess="user.stopDeletion = true"
                  data-test-id="video-users-progressbar"
                />
            </div>
            <div v-else>
              <div v-if="user.items && user.items.length">
                <v-simple-table class="list-table" data-test-id="video-users-table">
                  <thead data-test-id="table-head">
                    <tr data-test-id="head-row">
                        <th class="select-table-item" data-test-id="checkbox">
                          <br>
                        </th>
                        <th data-test-id="email">{{ l.t("app.email", "Email") }}</th>
                        <th data-test-id="name">{{ l.t("app.name", "Name") }}</th>
                        <th data-test-id="extension">
                            {{ l.t("app.extension", "Extension") }}
                        </th>
                        <th data-test-id="bridge">{{ l.t("video.bridge", "Bridge") }}</th>
                        <th v-if="user.video && (user.video.plan_code === 'MEETINGS_PLUS' || user.video.plan_code === 'MEETINGS_PRO')" data-test-id="pro-plus"><br></th>
                        <th data-test-id="more-options"><br/></th>
                    </tr>
                  </thead>
                  <tbody data-test-id="table-body">
                    <tr v-for="(item, i) in user.items" :key="item['id']" :data-test-id="item.id">
                        <td class="select-table-item" data-test-id="checkbox">
                          <w-checkbox
                            v-model="item.selected"
                            @change="user.checkSubmission()"
                            class="multiple-select-checkbox"
                            :key="`users-item-${item.id}-${item.selected}`"
                            hide-details='auto'
                            :data-test-id="`video-users-item-${i}-checkbox`"
                          />
                        </td>
                        <td data-test-id="email">
                            {{ item.email }}
                        </td>
                        <td data-test-id="name">
                            {{ item.name }}
                        </td>
                        <td data-test-id="extensions">
                            {{ (user.extensions && Object.keys(user.extensions).length && user.extensions[item.voip_phone_id]) ? `#${user.extensions[item.voip_phone_id].extension}` : "—" }}
                        </td>
                        <td data-test-id="bridge">{{ item.access_code }}</td>
                        <td v-if="user.video && user.video.plan_code === 'MEETINGS_PLUS'" data-test-id="pro-plus">
                          <router-link :to="{
                            name:'video.users.schedules.index',
                            params:{
                              user_id:item.id,
                              extension: (user.extensions && Object.keys(user.extensions).length && user.extensions[item.voip_phone_id]) ? user.extensions[item.voip_phone_id] : null
                            }
                          }"
                            class="list-link"
                            :data-test-id="`video-users-item-${i}-schedules-link`"
                          >
                            {{ l.t('video.scheduled-conferences', 'Scheduled conferences') }}
                          </router-link>
                        </td>
                        <td data-test-id="more-options">
                          <MoreOptions :options="more_options(item)" />
                        </td>
                    </tr>
                  </tbody>
                </v-simple-table>
                <MassOperationButtons v-if="user.items.length" @selected="user.bulkDelete()" @all="user.deleteAll()" :selected="!user.disable_delete_button" data-test-id="video-users-mass-operations">
                  <w-checkbox v-model="user.check_all" :label="l.t('app.select-all', 'Select all')" class="ml-4" hide-details='auto'/>
                </MassOperationButtons>
              </div>
              <NothingFound
                v-else
                :alert="user.alert"
                :everythingDeleted="user.everythingDeleted"
                data-test-id="video-users-nothing-found"
              ></NothingFound>
            </div>
        </div>
        <ConfirmDeletion
          :value="!!deleting_item_id"
          @closed="deleting_item_id = null"
          @confirmed="user.delete_item(deleting_item_id)"
        />
    </div>
</template>
<script>
  import {vueComponent} from 'helpers';
  import l from '../../../../libs/lang';
  import VideoUser from '../../../../models/VideoUser';
  import ProgressBar from '../../../elements/ProgressBar.vue';
  import NothingFound from '../../../elements/NothingFound.vue';
  import MassOperationButtons from '../../../elements/MassOperationButtons.vue';
  import MoreOptions from '../../../elements/MoreOptions.vue';
  import ConfirmDeletion from '../../../elements/modal/ConfirmDeletion.vue';

  export default {
    components: {
      ProgressBar,
      MassOperationButtons,
      NothingFound,
      MoreOptions,
      ConfirmDeletion
    },
    data() {
      return {
        l,
        deleting_item_id: null,
        user: new VideoUser(this.$session, vueComponent(this)),
      };
    },
    mounted() {
      this.$parent.$on('userRedirected', (to, from, next) => {
        this.$data.user.handleUserRedirect(to, from, next);
      });
    },
    methods: {
      more_options(item) {
        return [
          {
            cb: () => this.$data.deleting_item_id = item.id,
            title: l.t('app.delete', 'Delete'),
          }
        ];
      },
    },
    async created() {
      await this.$data.user.getList();
      await this.$data.user.getVideo();
    },
  };
</script>
