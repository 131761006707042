<template>
<div>
  <IndexPage
    :resource="media"
    class="list-page"
    data-test-id="media-list-page"
  >
    <template v-slot:filters>
      <FormInput :label="l.t('app.name', 'Name')">
        <w-text-field v-model="media.filters.name" hide-details='auto' data-test-id="media-filters-name-input"/>
      </FormInput>
      <FormInput :label="l.t('app.extension', 'Extension')">
        <APIAutocomplete
          v-model="media.filters.extension"
          :before_list="[{text: l.t('app.all-extensions', 'All extensions'), value: null}]"
          mode="extensions"
          data-test-id="media-filters-extension-selector"
        />
      </FormInput>
      <FormInput v-if="type === 'hold_music'">
        <w-switch
          :label="l.t('media.include-system-moh', 'Include system MOH files')"
          v-model="media.filters.include_system_media"
        />
      </FormInput>
    </template>
    <template v-slot:list>
      <v-simple-table class="list-table" data-test-id="media-table">
        <thead data-test-id="table-head">
          <tr data-test-id="head-row">
              <th class="select-table-item" data-test-id="checkbox"><br/></th>
              <th data-test-id="id">{{ l.t('app.id', 'ID') }}</th>
              <th data-test-id="name">{{ l.t("app.name", "Name") }}</th>
              <th data-test-id="origin">{{ l.t("media.origin", "Origin") }}</th>
              <th data-test-id="duration">
                  {{ l.t("app.duration", "Duration") }}
              </th>
              <th data-test-id="extension">
                  {{ l.t("app.extension", "Extension") }}
              </th>
              <th data-test-id="created-at">
                  {{ l.t("app.created-at", "Created at") }}
              </th>
              <th data-test-id="player"><br/></th>
              <th data-test-id="more-options"><br/></th>
          </tr>
        </thead>
        <tbody data-test-id="table-body">
          <tr
            v-for="(item, i) in media.items"
            :key="`${item['id']}-${Number(item.selected)}`"
            :class="{'selected-row': item.selected, 'disabled-text': item.id < 24}"
            :data-test-id="`media-item-${item.id}-row`"
          >
            <td class="select-table-item">
              <w-checkbox
                v-model="item.selected"
                @change="checkChanged"
                :disabled="item.id < 24"
                class="multiple-select-checkbox"
                hide-details='auto'
                :data-test-id="`media-item-${item.id}-checkbox`"
            />
            </td>
            <td data-test-id="id">{{ item["id"] }}</td>
            <td data-test-id="link">
                <router-link
                  v-if="$branding.data.routes.includes('media.show') && item.id > 24"
                  :to="{
                    name: $route.name.includes('media.index') ? 'media.show' : 'media.music-on-hold-show',
                    params: {
                      id: item.id,
                      _media: item,
                    },
                  }"
                  class="list-link"
                  :data-test-id="`media-item-${item.id}-link`"
                >
                    {{ item.name }}
                </router-link>
                <template v-else>
                    {{ item.name }}
                </template>
            </td>
            <td data-test-id="origin">
                {{ item.origin === "file" ? l.t("app.file", "File") : l.t("media.tts", "Text to Speech") }}
            </td>
            <td data-test-id="duration">
                {{ item.duration | formatDuration }}
            </td>
            <td data-test-id="extension">
                {{ item.extension && item.extension.extension ? "#" + item.extension.extension : "&mdash;" }}
            </td>
            <td data-test-id="created-at">{{ locale_time(item.created_at) }}</td>
            <td data-test-id="player">
                <Player
                  :url="`/media/${item['id']}/download`"
                  :playing="media.playing"
                  @status="media.playing = $event.playing"
                  class="my-auto"
                  :no_download="item.id < 24"
                  :key="item['id']"
                  :data-test-id="`media-item-${item.id}-player`"
                />
            </td>
            <td data-test-id="more-options">
              <MoreOptions :options="more_options(item)" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </template>
  </IndexPage>
  <ConfirmDeletion
      :value="!!deleting_item_id"
      @closed="deleting_item_id = null"
      @confirmed="media.delete_item(deleting_item_id)"
    />
  </div>
</template>
<script>
  import {formatTime} from 'formatters';
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import Media from '../../../models/Media';
  import Player from '../../elements/Player.vue';
  import IndexPage from '../../elements/IndexPage.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import MoreOptions from '../../elements/MoreOptions.vue';
  import APIAutocomplete from '../../elements/form/APIAutocomplete.vue';
  import ConfirmDeletion from '../../elements/modal/ConfirmDeletion.vue';

  export default {
    props: ['type'],
    components: {
      Player,
      FormInput,
      IndexPage,
      APIAutocomplete,
      MoreOptions,
      ConfirmDeletion
    },
    data() {
      return {
        l,
        deleting_item_id: null,
        media: new Media(this.$session, this.$props.type, vueComponent(this)),
      };
    },
    mounted() {
      this.$parent.$on('userRedirected', (to, from, next) => {
        this.$data.media.handleUserRedirect(to, from, next);
      });
    },
    methods: {
      more_options(item) {
        return [
          {
            cb: () => this.$router.push({
              name: this.type === 'greetings' ? 'media.show' : 'media.music-on-hold-show',
              params: {
                id: item.id,
                _queue: item,
              },
            }),
            title: l.t('app.edit', 'Edit')
          },
          {
            cb: () => this.$data.deleting_item_id = item.id,
            title: l.t('app.delete', 'Delete'),
          }
        ];
      },
      locale_time: formatTime,
      checkChanged() {
        this.$data.media.checkSubmission();
        this.$emit('forceUpdate');
      }
    },
  };
</script>
