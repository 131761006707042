import React, { Component } from 'react'

import { uploadSteps, progressThresholds, intervalSpeeds } from './UploadUtil.js'
import Refresh from '@material-ui/icons/Refresh'
import ProgressBar from 'progress-bar'

import { audioIcon, videoIcon, pdfIcon, docIcon, xlsIcon, pptIcon, zipIcon, defaultFileIcon, loading } from 'pdc-icons'

import { withStyles } from '@material-ui/core'

const styles = theme => ({
	mediaContainer: {
		background:	'transparent',
		position:	'relative',
		'&:not(:last-child)': {
			marginRight:	5
		},
		'& .thumbnail': {
			borderWidth:	1,
			borderStyle:	'solid',
			borderColor:	theme.messagesApp.contentItems.thumbnailImageColor,
			maxWidth:		200,
			maxHeight:		85,
			'&.loading-thumbnail': {
				minWidth:	100,
				minHeight:	85
			}
		},
		'&.upload-in-progress img, &.upload-failed img': {
			opacity:	0.3
		},
		'&:not(.not-clickable) img:hover': {
			cursor:		'pointer',
			opacity:	0.7
		}
	},
	fileIconWrapper: {
		position:	'relative',
		width:		60,
		height:		80,
		'& img': {
			width:	'100%',
			height:	'100%'
		},
		'& .extension-text': {
			position:		'absolute',
			bottom:			'0.4em',
			left:			'0em',
			fontSize:		'1.4em',
			fontWeight:		500,
			textTransform:	'uppercase',
			color:			'white',
			textAlign:		'center',
			width:			'1.6em',
			transform:		'rotate(270deg)'
		}
	},
	uploadProgressBar: {
		position:		'absolute',
		bottom:			-14,
		fontSize:		10,
		fontWeight:		'bold',
		lineHeight:		1,
		textTransform:	'capitalize',
		color:			'white',
		background:		'#23caff',
		width:			'0%',
		height:			14,
		padding:		2,
		overflow:		'hidden'
	},
	retryUploadButton: {
		position:		'absolute',
		top:			0,
		bottom:			0,
		left:			0,
		right:			0,
		margin:			'auto',
		width:			'fit-content',
		height:			'fit-content',
		background:		'rgba(255, 255, 255, 0.8)',
		borderRadius:	15,
		cursor:			'pointer'
	},
	attachingPlaceholder: {
		width:			100,
		height:			85,
		border:			'1px solid black',
		display:		'flex',
		justifyContent:	'center',
		alignItems:		'center'
	}
})

class ThumbnailItem extends Component {

	state = {intervalPeriod: 5}

	openModal(url, e) {

		if (!!this.props.notClickable) {
			return
		}

		let image = e.currentTarget
		if (image.alt === 'Couldn\'t load image') {
			return this.reloadImage(image)
		} else if (image.alt === 'Reloading image') {
			return
		}

		if (url.substr(0, 5) === 'data:') return
		let currentMediaUrl = url
		let modalContentData = this.props.generateModalContentData(currentMediaUrl)
		if (modalContentData && modalContentData.total > 0) {
			this.props.openModal(JSON.stringify(modalContentData))
		}
	}

	reloadImage = image => {
		image.alt = 'Reloading image'
		image.title = 'Reloading image'
		return new Promise((resolve, reject) => {
			let retryImage = new Image()
			retryImage.onload = function() {
				image.src = retryImage.src
				resolve(true)
			}
			retryImage.onerror = function() {
				image.alt = 'Couldn\'t load image'
				image.title = 'Click to reload image'
				resolve(false)
			}
			retryImage.src = image.src
		})
	}

	hideLoadingThumbnail = function(e) {
		let image = e.currentTarget
		image.classList.remove('loading-thumbnail')
		image.style.background = 'none'
		if (image.title === 'Click to reload image') {
			image.title = ''
		}
	}

	onErrorLoadingImage = e => {
		this.hideLoadingThumbnail(e)
		let image = e.currentTarget
		image.alt = 'Couldn\'t load image'
		image.title = 'Click to reload image'
		let startTime = (new Date()).getTime()
		let interval = setInterval(async () => {
			let now = (new Date()).getTime()
			let response = await this.reloadImage(image)
			if (response || now - startTime > 5000) clearInterval(interval)
		}, 500)
	}


	getType = () => {

		let mimetype = this.props.mimetype
		let type = mimetype.split('/')[0]

		if (mimetype.includes('msword') || mimetype.includes('ms-word') || mimetype.includes('.document') || this.props.filename.split('.').pop().includes('doc')) {
			type = 'document'
		} else if (mimetype.includes('ms-excel') || mimetype.includes('.sheet') || this.props.filename.split('.').pop().includes('xls')) {
			type = 'sheet'
		} else if (mimetype === 'application/pdf') {
			type = 'pdf'
		} else if (mimetype === 'application/zip' || mimetype === 'application/x-7z-compressed') {
			type = 'archive'
		} else if (mimetype === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
			type = 'presentation'
		} else if (!['image', 'audio', 'video'].includes(type)) {
			type = 'other'
		}

		return type
	}

	renderThumbnail = () => {

		const { classes } = this.props

		if (this.props.uploadStatus === uploadSteps.ATTACHING) {
			return <div className={classes.attachingPlaceholder}>Attaching <img style={{marginLeft: 5}} src={loading} alt='...'/></div>
		}

		let type = this.getType()
		let icons = {
			document:		docIcon,
			sheet:			xlsIcon,
			presentation:	pptIcon,
			pdf:			pdfIcon,
			audio:			audioIcon,
			video:			videoIcon,
			archive:		zipIcon,
			other:			defaultFileIcon
		}

		let filenameSplit = this.props.filename.split('.')
		let fileExtension = filenameSplit.length > 1 ? filenameSplit.pop() : null

		let title = this.props.filename
		if (this.props.location === 'send-message' && this.props.uploadStatus === uploadSteps.UPLOADING) {
			title = `Uploading: ${title}`
		}

		switch (type) {
			case 'image':
				return (
					<img
						className	= 'thumbnail loading-thumbnail'
						src			= {this.props.source}
						onClick		= {this.openModal.bind(this, this.props.originalUrl)}
						alt			= {this.props.filename}
						title		= {title}
						onLoad		= {this.hideLoadingThumbnail}
						onError		= {this.onErrorLoadingImage}
						style		= {{background: `url(${loading}) no-repeat center center`}}
					/>
				)

			case 'document':
			case 'sheet':
			case 'presentation':
			case 'pdf':
			case 'audio':
			case 'video':
			case 'archive':
			case 'other':
				return (
					<div
						className	= {`${classes.fileIconWrapper} file-icon-wrapper`}
						title		= {title}
						onClick		= {this.openModal.bind(this, this.props.originalUrl)}
						location	= {this.props.location}
						type		= {type}
					>
						<img
							src={icons[type]}
							alt={this.props.filename}
						/>
						<span className={'extension-text not-selectable' + (type === 'other' ? '' : ' hidden')}>.{fileExtension || 'File'}</span>
					</div>
				)
			default:
				return
		}
	}

	renderUploadProgressInfo = () => {
		const { classes } = this.props
		return this.props.uploadStatus === uploadSteps.FAILED ?
			<div className={classes.retryUploadButton} title='Click to retry'>
				<Refresh onClick={() => this.props.retryUploadMedia(this.props.id)}/>
			</div> :
			<ProgressBar
				progressThresholds	= {progressThresholds}
				intervalSpeeds		= {intervalSpeeds}
				currentStep			= {this.props.uploadStatus}
				lastStep			= {uploadSteps.UPLOADED}
				onProgress			= {this.onUploadProgress}
			>
				<div
					id			= {this.props.id}
					className	= {`${classes.uploadProgressBar} ${this.state.progressBarHidden ? 'hidden' : ''}`}
					// style		= {{width: 0}}
					title		= {this.props.uploadStatus}
				>{this.props.uploadStatus}</div>
			</ProgressBar>
	}

	onUploadProgress = progressWidthPercentage => {
		if (progressWidthPercentage === 100) {
			this.setState({progressBarHidden: true})
		}
	}

	render() {
		const { classes } = this.props
		let classNames = `${classes.mediaContainer} media-container fs-block ${this.props.notClickable ? ' not-clickable' : ''}`
		if (this.props.location === 'send-message') {
			if (this.props.uploadStatus === uploadSteps.UPLOADING || this.progressBarInterval) {
				classNames += ' upload-in-progress'
			} else if (this.props.uploadStatus === uploadSteps.FAILED) {
				classNames += ' upload-failed'
			}
		}

		return (
			<div className={classNames}>
				{this.renderThumbnail()}
				{this.props.location === 'send-message' ? this.renderUploadProgressInfo() : null}
			</div>
		)
	}
}

export default withStyles(styles)(ThumbnailItem)