/**
 * @param {object} theme
 */
const styles = theme => ({
    phoneNumberSection: {
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: 600,
        paddingBottom: 5,
        paddingTop: 5,
        paddingLeft: 15,
        marginLeft: -15,
        lineHeight: 2.14,
        '&:hover': {
            cursor: 'pointer',
            background: 'rgba(0, 0, 0, 0.05)'
        },
        '& .number-type': {
            color: theme.palette.tertiary.main,
            fontWeight: 'normal',
            display: 'inline-block',
            minWidth: 60,
            marginRight: 20
        }
    },
    contactWrapper: {
        padding: '0 50px 20px',
        '&.small-view': {
            padding: '0 30px 10px'
        }
    },
    contactName: {
        fontSize: 16,
        fontWeight: 600,
        letterSpacing: -0.1,
        lineHeight: 1.31,
        marginBottom: 3
    },
    noContactsResult: {
        color: '#dc0000',
        fontStyle: 'italic',
        fontSize: '14px'
    },
    ContactSelectorBody: {
        height: '100%',
        minHeight: 0,
        marginTop: 20
    }
})

export default styles
