import React, { Component } from 'react'
import Spinner from 'spinner'
import SearchIcon from '@material-ui/icons/Search'
import { getFormattedTime } from 'time-format'
import Api from 'api'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	searchBar: {
		display:	'flex',
		alignItems:	'center',
		boxShadow:	'inset 1px -1px 0px 0px #ccd6db'
	},
	searchInput: {
		padding:	'4px 10px',
		border:		'none',
		boxShadow:	'inset 1px -1px 0px 0px #ccd6db',
		outline:	'none',
		color:		'#444',
		width:		'100%'
	},
	searchButton: {
		cursor:			'pointer',
		marginRight:	10,
		'& > svg': {
			width: 21
		}
	},
	searchResultsWrapper: {
		borderBottom:	'1px solid gray',
		boxShadow:		'inset 1px -1px 0px 0px #ccd6db',
		maxHeight:		200,
		overflowY:		'auto'
	},
	resultsTitle: {
		color:			'gray',
		fontSize:		16,
		fontStyle:		'italic',
		paddingLeft:	10,
		borderBottom:	'1px solid gray'
	},
	resultItem: {
		display:	'flex',
		alignItems:	'center',
		background:	'#fff',
		padding:	'4px 10px',
		boxShadow:	'inset 1px -1px 0px 0px #ccd6db'
	},
	messageDate: {
		marginRight:	10,
		color:			'gray',
		fontStyle:		'italic'
	},
	messageText: {
		flex: 1
	},
	goTo: {
		cursor:		'pointer',
		padding:	'4px 10px',
		'&:hover': {
			textDecoration: 'underline'
		}
	}
})

class SearchMessages extends Component {

	state = {searching: false, searchValue: '', searchResults: null}

	resetState = () => {
		this.setState({searching: false, searchValue: '', searchResults: null})
	}

	componentDidUpdate(prevProps) {
		if (prevProps.conversation.id !== this.props.conversation.id) {
			this.resetState()
		}
	}

	search = async () => {
		let searchValue = this.state.searchValue
		if (!searchValue || this.state.searching) return
		this.setState({searching: true, searchResults: null})
		let conversationId	= this.props.conversation.id
		let filters			= {conversation_id: conversationId, text: searchValue}
		let searchResults	= await Api.listInboxes(filters, 'message', null, 50, {created_at: 'desc'}, true)
		this.setState({searchResults, searching: false})
	}

	onInput = e => {
		let searchValue = e.target.value
		this.setState({searchValue})
	}

	goToMessage = message => {
		this.props.showMessage(message)
		this.setState({searchResults: null})
	}

	renderResults = () => {
		const { classes } = this.props
		let searchResults = this.state.searchResults
		if (!searchResults) return null
		return (
			<div className={classes.searchResultsWrapper}>
				{/* <div className={classes.resultsTitle}>Resutls</div> */}
				{searchResults.items.map((r, i) => {
					let time = getFormattedTime(r.created_at, true)
					return (
						<div key={i} className={classes.resultItem}>
							<span className={classes.messageDate}>{time}</span>
							<span className={classes.messageText}>{r.text}</span>
							<span className={classes.goTo} onClick={() => this.goToMessage(r)}>Go to message</span>
						</div>
					)
				})}
			</div>
		)
	}

	onKeyDown = e => {
		e.persist()
		if (e.key === 'Enter') this.search()
		else if (e.key === 'Escape') {
			this.resetState()
			this.props.onClose()
		}
	}

	render() {
		const { classes } = this.props
		return (
			<>
				<div className={classes.searchBar}>
					<input
						className	= {classes.searchInput}
						type		= 'text'
						placeholder	= 'Search here'
						onInput		= {this.onInput}
						onChange	= {this.onInput}
						value		= {this.state.searchValue}
						onKeyDown	= {this.onKeyDown}
					/>
					<div
						className	= {classes.searchButton}
						onClick		= {this.search}
					>
						{this.state.searching ? <Spinner/> : <SearchIcon/>}
					</div>
				</div>
				{this.renderResults()}
			</>
		)
	}
}

export default withStyles(styles)(SearchMessages)