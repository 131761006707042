import l from '../lang';

export default {
	'routes': {
		'create': 'blocked-calls.create',
		'index': 'blocked-calls.index',
	},
	'delete-single': l.t('blocked-calls.delete-single', 'Are you sure you want to delete this blocked number?'),
	'delete-filtered': l.t('blocked-calls.delete-filtered', 'Are you sure you want to delete all filtered blocked numbers?'),
	'delete-all': l.t('blocked-calls.delete-all', 'Are you sure you want to delete all blocked numbers?'),
	'successfull-creation': l.t('blocked-calls.successfully-created', 'Your blocked number has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('blocked-calls.failed-creation', 'There was a problem adding your blocked number'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
};
