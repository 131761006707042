<template>
    <div>
        <div class="d-flex justify-center">
          <div id="traffic-chart" class="chart-container" data-test-id="traffic-chart"></div>
        </div>
        <ChartLegend :items="legend"/>
    </div>
</template>
<script>
  import { OrangeChartLines } from 'orange-charts';
  import { distinct_colors } from '../../../libs/helpers';
  import ChartLegend from 'console/src/components/fragments/ChartLegend.vue';

  export default {
    props: {
        items: {
            // type: Object
        },
        group_by: {
            type: String,
        }
    },
    components: {
        ChartLegend
    },
    data() {
      const [incoming, outgoing] = distinct_colors(this)
      return {
        colors: {incoming, outgoing},
      };
    },
    mounted() {
      const max_y_value = Math.max(
        ...this.items.reduce((a, c) => {
          a.push(c.count_incoming);
          a.push(c.count_outgoing);
          return a;
        }, [])
      );
      const chart = new OrangeChartLines(this.items);
      chart
      .assignSource('count_incoming', this.$data.colors['incoming'])
      .assignSource('count_outgoing', this.$data.colors['outgoing'])
      .axis('x', {
        lines: true,
        source: this.group_by,
        label: (v) => {
          const d = new Date(v * 1000);
          return d.toLocaleString(undefined, {
            month: 'short',
            day: 'numeric',
            hour: ['hour', 'minute'].includes(this.$props.group_by) ? 'numeric' : undefined,
          });
        },
        dynamic: true,
      })
      .axis('y', {
        lines: true,
        label: (v) => {
          if (v * 1 === max_y_value) return this.$lang.t('x-calls', '{} calls', [v]);
          return v;
        },
        min: 0,
      })
      .render('traffic-chart', {
        width: 900,
        height: 384,
      });
    },
    computed: {
        legend() {
            const obj = {};
            obj[this.$lang.t('app.incoming-calls', 'Incoming calls')] = this.$data.colors.incoming;
            obj[this.$lang.t('app.outgoing-calls', 'Outgoing calls')] = this.$data.colors.outgoing;
            return obj;
        },
    }
  };
</script>

<style scoped>

</style>