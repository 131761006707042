import React, {Component} from 'react';
import loader from '../images/loader.gif';
import transparentPlaceholder from '../images/transparent-placeholder.png';

class MediaModalImage extends Component {

	constructor(props){
		super(props);
		this.state = {
			image: this.props.mediaUrl
		};
	}

	componentWillReceiveProps(props){
		if (this.state.image !== props.mediaUrl) {
			this.setState({image: props.mediaUrl});
		}
	}

	render() {
		let source = this.state.image || loader;
		let loadingClass = this.state.image ? '' : 'loading-media';
		return (
			<img
				className={`current-media not-selectable fs-block ${loadingClass}`}
				src={source}
				alt='Display media'
				style={{
					backgroundImage: "url(" + transparentPlaceholder + ")",
					backgroundRepeat: "repeat"
				}} />
		);
	}
}

export default MediaModalImage;