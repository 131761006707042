<template>
<div>
  <IndexPage
    :resource="device"
    class="list-page devices"
    data-test-id="devices-list-page"
  >
    <template v-slot:filters>
      <FormInput :label="$lang.t('app.name', 'Name')">
        <w-text-field v-model="device.filters.name" hide-details='auto' data-test-id="devices-filters-name-input"/>
      </FormInput>
      <FormInput :label="$lang.t('app.type', 'Type')">
        <w-select
          v-model="device.filters.type"
          :items="[
            {text: $lang.t('devices-all-types', 'All types'), value: null},
            {text: $lang.t('devices.generic', 'Generic'), value: 'generic'},
            {text: $lang.t('devices.softphone', 'Softphone'), value: 'softphone'},
            {text: $lang.t('devices.iphone', 'iPhone'), value: 'iphone'},
            {text: $lang.t('devices.communicator', 'Communicator'), value: 'communicator'},
            {text: $lang.t('devices.other', 'Other'), value: 'other'},
          ]"
          hide-details="auto"
          data-test-id="devices-filters-type-seelct"
        />
      </FormInput>
      <FormInput :label="$lang.t('devices.sip-username', 'SIP username')">
        <w-text-field
          v-model="device.filters.sip_username"
          type="number"
          hide-details='auto'
          data-test-id="devices-filters-sip-input"
        />
      </FormInput>
      <FormInput :label="$lang.t('app.extension-nickname', 'Extension nickname')">
        <w-text-field
          v-model="device.filters.extension_name"
          hide-details='auto'
          data-test-id="devices-filters-ext-nick-input"
        />
      </FormInput>
      <FormInput :label="$lang.t('app.extension-number', 'Extension number')">
        <w-text-field
           type="number"
           v-model="device.filters.extension_number"
           hide-details='auto'
           data-test-id="devices-filters-ext-num-input"
        />
      </FormInput>
    </template>
    <template v-slot:list>
      <v-simple-table v-if="device.items && device.items.length" class="list-table without-hover-effect">
        <thead data-test-id="table-head">
          <tr data-test-id="head-row">
            <th class="select-table-item" data-test-id="checkbox"><br/></th>
            <th data-test-id="device">{{ $lang.t('app.device', 'Device') }}</th>
            <th data-test-id="ext-num">{{ $lang.t('app.ext', 'Ext') }}</th>
            <th data-test-id="ext-nick">{{ $lang.t('app.user-nickname', 'User/nickname') }}</th>
            <th data-test-id="e911-adress">{{ $lang.t('app.e911-adress', 'E911 adress') }}</th>
            <th class="minimal-width" data-test-id="more-options"><br/></th>
          </tr>
        </thead>
        <tbody data-test-id="table-body">
          <tr
          v-for="(item, i) in device.items"
          :key="`${item.id}-${parseInt(item.selected)}`"
          :class="{
            'link': item.type === 'generic', 'not-a-link': item.type !== 'generic',
            'selected-row': item.selected,
          }"
          :data-test-id="item.id"
          >
            <td v-if="device.deleting_item === item.id" colspan="9" data-test-id="loader">
              <w-loader size="small"/>
            </td>
            <template v-else>
              <td class="select-table-item" data-test-id="checkbox">
                <w-checkbox
                  v-model="item.selected"
                  @click="checked($event, item)"
                  hide-details="auto"
                  :disabled="!device.constructor.device_is_deletable(item, nxt_user_extensions)"
                  :key="`selected-${item.id}-${item.selected}`"
                  :data-test-id="`devices-item-${i}-checkbox`"
                />
              </td>
              <router-link
                v-if="item.type === 'generic' && allow_show_route"
                tag="td"
                :to="{name: 'devices.show', params: { 'id': item.id, '_device': item }}"
                class="list-link nowrap"
                data-test-id="link-name"
              >
                {{ item.name || '–' }}
              </router-link>
              <td v-else class="nowrap" data-test-id="name">{{ item.name || '–' }}</td>
              <td data-test-id="lines">
                <span v-if="!item.lines.length">&mdash;</span>
                <span v-else>{{ item.lines[0].extension ? item.lines[0].extension.extension : '–' }}</span>
              </td>
              <td class="nowrap" data-test-id="ext">
                <span v-if="!item.lines.length">&mdash;</span>
                <span v-else>{{ item.lines[0].extension ? item.lines[0].extension.name : '–' }}</span>
              </td>
              <td data-test-id="e911">{{stringifyAddress(item.address)}}</td>
              <td data-test-id="more-options">
                <MoreOptions :options="more_options(item)" />
              </td>
            </template>
          </tr>
        </tbody>
        </v-simple-table>
        <DeviceInformationModal
          v-if="opened_device"
          :value="true"
          :device="opened_device"
          @close="opened_device = null"
          :width="500"
          data-test-id="modal-with-trigger"
        />
    </template>
  </IndexPage>
  <DefaultModal 
    v-model="modal_value"
    data-test-id="show_edit_address_modal"
    :width="500"
  >
    <template v-slot:title>{{ add_or_edit_address(edit_address) }}</template>
    <AddressSelector
      v-model="shipping_address"
      :items="addresses"
			class="my-4 w-100"
			:label="$lang.t('orders.e911-address', 'E911 address')"
      :key="JSON.stringify(shipping_address)"
		>
    <template v-slot:title>
			{{ $lang.t('app.enter-a-new-e911-address', 'Enter a new E911 address') }}
		</template>
    </AddressSelector>
    <template v-slot:buttons>
			<w-btn color="secondary" @click="close_modal">{{$lang.t('app.cancel', 'Cancel')}}</w-btn>
			<w-btn color="primary" :disabled="!shipping_address" @click="on_address_confirmation">{{$lang.t('app.confirm', 'Confirm')}}</w-btn>
		</template>
  </DefaultModal>
  <ConfirmDeletion
      :value="!!deleting_item_id"
      @closed="deleting_item_id = null"
      @confirmed="device.delete_item(deleting_item_id)"
    />
  </div>
</template>

<script>
  import {stringifyAddress} from 'formatters';
  import {nxt_user_extensions, is_csr} from 'phoenix-session-helpers';
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import Device from '../../../models/Device';
  import Contact from '../../../models/Contact';
  import IndexPage from '../../elements/IndexPage.vue';
  import MoreOptions from '../../elements/MoreOptions.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import ConfirmDeletion from '../../elements/modal/ConfirmDeletion.vue';
  import ValidationError from '../../elements/form/FieldValidationError.vue';
  import DeviceInformationModal from '../../elements/modal/device-information.vue';
  import AddressSelector from '../../elements/form/AddressSelector.vue';
  import DefaultModal from '../../elements/modal/DefaultModal.vue';

  export default {
    components: {
      IndexPage,
      FormInput,
      MoreOptions,
      ConfirmDeletion,
      ValidationError,
      DeviceInformationModal,
      AddressSelector,
      DefaultModal
    },
    data() {
      return {
        l,
        device: new Device(this.$session, vueComponent(this)),
        allow_show_route: false,
        opened_device: null,
        deleting_item_id: null,
        user_is_csr: false,
        nxt_user_extensions: null,
        edit_address: null,
        modal_value: false,
        address_errors: null,
        contact: new Contact(this.$session, vueComponent(this)),
        addresses: [],
        shipping_address: null,
      };
    },
    async created() {
      this.$data.allow_show_route = this.$branding.data.routes.includes('devices.show');
      this.$data.user_is_csr = await is_csr(this.$session);
      this.$data.nxt_user_extensions = await nxt_user_extensions(this.$session);
      this.$global_emitter.$on('form_input_changed', () => this.$data.address_errors = null);
      const fetched_addresses = await this.$data.contact.loadAddresses();
      if (Array.isArray(fetched_addresses) && fetched_addresses.length > 0) {
          for (const address of fetched_addresses) {
            this.$data.addresses.push(this.convert_address(address.address));
          }
      }
    },
    mounted() {
     this.$parent.$on('userRedirected', (to, from, next) => {
       this.$data.device.handleUserRedirect(to, from, next);
     });
    },
    methods: {
      convert_address(address) {
        const convertedAddress = {
          ...address,
          line_1: address.line_1.toUpperCase(),
          line_2: address.line_2.toUpperCase(),
          city: address.city.toUpperCase(),
        }
        return convertedAddress;
      },
      close_modal() {
        this.$data.modal_value = false;
        this.$data.shipping_address = null;
      },
      stringifyAddress(address) {
        return stringifyAddress(address);
      },
      device_name(item) {
        if (item.model) {
          return `${item.model.manufacturer} ${item.model.name}`;
        }
        if (item.type === 'generic') return this.$lang.t('device.generic-device', 'Generic device');

        return '—';
      },
      more_options(item) {
        const items = [
          {
            cb: () => this.$router.push({
              name: 'devices.show',
              params: {
                id: item.id,
                _queue: item,
              },
            }),
            title: this.$lang.t('app.edit', 'Edit')
          },
          {
            cb: () => this.$data.opened_device = item,
            title: Device.sip_can_be_visible(this.user_is_csr, item.code, item.type) ? this.$lang.t('devices.device-details-SIP', 'Device details/SIP') : this.$lang.t('devices.device-details', 'Device details'),
          },
        ];
        if (item.address || item.lines.length) {
          items.push({
            cb: () => {
              this.$data.edit_address = JSON.parse(JSON.stringify(item));
              this.$data.modal_value = true;
              this.$data.shipping_address = item.address || null;
            },
            title: this.add_or_edit_address(item),
          });
        }

        if (Device.device_is_deletable(item, this.$data.nxt_user_extensions)) {
          items.push({
            cb: () => this.$data.deleting_item_id = item.id,
            title: this.$lang.t('app.delete', 'Delete'),
          });
        }

        return items;
      },
      add_or_edit_address(item) {
        return (item && item.address) ? this.$lang.t('app.edit-address', 'Edit address') : this.$lang.t('app.add-address', 'Add address');
      },
      checked(event, item) {
        this.$data.device.checked(event, item);
        this.$emit('forceUpdate');
      },
      async on_address_confirmation() {
        this.$data.modal_value = false;
        await this.$data.device.update_address(this.edit_address.id, this.$data.shipping_address);
        if (this.$data.device.alert === null) this.$data.edit_address = null;
        this.$forceUpdate();
      }
    },
  };
</script>

<style lang="scss">
	@import 'vue-apps-assets/styles/scss/vars.scss';

  .devices {
    .copy-cell {
      white-space: nowrap;
    }
    .list-table {
      .active-row {
        td {
          border-bottom: 0 !important;
          height: 28px;
        }
        .partially-bordered-td:before {
          border-left:1px solid var(--v-secondary-lighten1) !important;
          padding-top: 17px !important;
          padding-bottom: 17px !important;
        }
        &:last-child {
          td {
            border-bottom: 3px solid black !important;
          }
        }
      }
      .first-active-row {
        .partially-bordered-td:before {
          padding-top: 7px !important;
        }
      }
      .last-active-row {
        .partially-bordered-td:before {
          padding-bottom: 7px !important;
        }
        td {
          border-bottom: $table-border !important;
        }
      }
    }
  }
</style>
