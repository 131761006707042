export function setSmallView(boolVal) {
	return {
		type: 'SET_SMALL_VIEW',
		smallView: boolVal
	};
}

// select/content/new_conversation
export function switchView(view) {
	return {
		type: 'SWITCH_VIEW',
		view
	};
}