var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menus"},[(_vm.user.loading)?_c('w-loader'):(_vm.user.alert)?_c('w-alert',{attrs:{"level":_vm.user.alert.level,"message":_vm.user.alert.message,"closable":_vm.user.enable_close_alert},on:{"closed":function($event){_vm.menu.alert = null}}}):(_vm.user.item)?[_c('Submenu',{key:("" + (_vm.l.detect()) + (!!_vm.user.item)),attrs:{"routes":{
        'video.users.schedules.index': {
          title: _vm.l.t(
          'video.scheduled-conferences',
          'Scheduled conferences'
          ),
          subroutes: ['video.users.schedules.show']
        },
        'video.users.schedules.create': {
          title: _vm.l.t(
          'video.add-scheduled-conference',
          'Add scheduled conference'
          )
        },
      },"route_params":{ user_id: _vm.user.item.id, extension: _vm.extension },"data-test-id":"video-user-schedules-submenu"}}),_vm._v(" "),(_vm.$route.name === 'video.users.schedules.index')?_c('UserSchedulesFragment',{attrs:{"user":_vm.user.item,"extension":_vm.extension,"data-test-id":"video-user-schedules-index"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'video.users.schedules.create')?_c('UserSchedulesCreateFragment',{attrs:{"user":_vm.user.item,"extension":_vm.extension,"data-test-id":"video-user-schedules-create"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'video.users.schedules.show')?_c('UserScheduleFragment',{attrs:{"user":_vm.user.item,"extension":_vm.extension,"data-test-id":"video-user-schedules-show"}}):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }