<template>
    <FormModal
        v-model="show"
        v-bind="$attrs"
        v-on="$listeners"
        max-width="500"
        data-test-id="fwd-item-modal"
        :discard_title="l.t('app.are-you-sure', 'Are you sure?')"
      >
        <template v-slot:title>
          {{ l.t('app.more-options', 'More options') }}
        </template>
        <div>
          <div class="d-flex">
            <w-switch v-model="screening" :label="l.t('forwarding-item.call-screening', 'Call screening')" hide-details='auto'
              data-test-id="fwd-item-screening-switch"/>
            <InfoLink max-width="300" top no-carret data-test-id="call-screening-text" activator_classes="mt-3">
              {{ l.t(
                'live-answer.call-screening-text',
                'Call Screening provides you an audio "pre-screening" of an incoming call, telling you what number was called, what the caller\'s number is and giving you the option to accept or reject the call. The caller does not hear this message and continues to hear the ring.'
              ) }}
            </InfoLink>
          </div>
            <v-divider class="light-divider mt-1 mb-1"/>
              <div class="d-flex">
                <span class="w-body-2 info--text text--darken-1 mr-2 my-auto">
                  {{ l.t('forwarding-item.call-screening-caller-id', 'Caller ID to show me:') }}
                </span>
                <w-radio-group v-model="caller_id" row hide-details="auto" class="radio-group ml-n1">
                  <v-radio :label="l.t('app.calling-number', 'Calling number')" color="secondary" value="calling_number"
                    data-test-id="fwd-item-calling-num-radio"></v-radio>
                  <v-radio :label="l.t('app.called-number', 'Called number')" color="secondary" value="called_number"
                    data-test-id="fwd-item-called-num-radio"></v-radio>
                </w-radio-group>
              </div>
              <w-text-field
                :placeholder="l.t('forwarding-item.voice-tag', 'Add a voice tag')"
                type="text"
                class="w-100"
                maxlength="40"
                v-model="voice_tag"
                hide-details="auto"
                data-test-id="fwd-item-voice-tag-input"
              />
              <w-select
                v-if="forwarding_type === 'extension'"
                v-model="distinctive_ring"
                class="w-100 mt-5"
                :placeholderl="l.t('forwarding-item.ringing-style', 'Ringing style')"
                :items="[
                    {value: 'DEFAULT', text: l.t('app.default-ring-style', 'Default ring style')},
                    {value: 'STYLE_2', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [2]) },
                    {value: 'STYLE_3', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [3]) },
                    {value: 'STYLE_4', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [4]) },
                    {value: 'STYLE_5', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [5]) },
                    {value: 'STYLE_6', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [6]) },
                    {value: 'STYLE_7', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [7]) },
                    {value: 'STYLE_8', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [8]) },
                    {value: 'STYLE_9', text: l.t('forwarding-item.ring-style {}', 'Ring style {}', [9]) },
                ]"
                hide-details="auto"
                data-test-id="fwd-item-distinctive-ring-select"
              />
        </div>
        <template v-slot:buttons>
          <w-btn color="secondary" @click="show = false" class="ml-5">{{l.t('app.cancel', 'Cancel')}}</w-btn>
          <w-btn color="primary" :disabled="readonly" @click="save_conf_changes">{{l.t('app.save-notifications-settings', 'Save notifications settings')}}</w-btn>
        </template>
    </FormModal>  
</template>
<script>
  import l from '../../../libs/lang';
  import InfoLink from '../InfoLink.vue';
  import FormModal from '../modal/FormModal.vue';

  export default {
    props: ['value', 'data', 'forwarding_type', 'readonly'],
    components: {
      InfoLink,
      FormModal,
    },
    data() {
      const value = this.$props.data ? JSON.parse(JSON.stringify(this.$props.data)) : null;
      return {
        l,
        show: !!this.$props.value,
        screening: value && value.screening ? value.screening : false,
        caller_id: value && value.caller_id ? value.caller_id : 'calling_number',
        voice_tag: value && value.voice_tag ? value.voice_tag : '',
        distinctive_ring: value && value.distinctive_ring ? value.distinctive_ring : 'DEFAULT',
      };
    },
    methods: {
        save_conf_changes() {
          this.$emit(
            'submitted',
            {
                type: this.$data.forwarding_type,
                caller_id: this.$data.caller_id,
                screening: this.$data.screening,
                voice_tag: this.$data.voice_tag,
            },
          );
          this.$data.show = false;
        //   this.$data.force_close = true;
        },
    },
  };
</script>

<style scoped>

</style>