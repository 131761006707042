<template>
    <w-alert
        :closable="false"
        level="warning"
        class="margin-top-30 margin-bottom-30"
        :message="$lang.t('lr.onvego-once-setup-is-complete', 'Once setup is complete, you will get calls from  (810) 627-7327. Add this number to your Phone.com and mobile device address books. Do not block this number.')"
    />
</template>
<script>
export default {
    data() {
        return {
        };
    },
};
</script>
