import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { deleteTempUser } from '../../actions/users'
import LoaderFull from 'loader-full'
import { theme } from 'get-theme'
import TableMui from 'pdc-table-mui'
import { TextField } from 'text-field-mui'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'
import { XIcon } from 'pdc-svg-icons'
import Tooltip from '@material-ui/core/Tooltip'
import AddCircleIcon from 'svg-icons/src/actions/AddCircleIcon'
import BottomNavigation from 'bottom-navigation'
import PropTypes from 'prop-types'

const styles = theme => ({
    addUsersContainer: {
        flexGrow: 1,
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '50px min(65px, 5%) 65px',
        overflowY: 'scroll',
        '&.small': {
            padding: '30px min(25px, 5%) 0px'
        },
        '&.tablet': {
            padding: '30px min(50px, 5%) 30px'
        }
    },
    addUserWrapper: {
        marginTop: 22,
        marginBottom: 22,
        '& .add-user-button': {
            display: 'inline-flex',
            alignItems: 'center',
            color: theme.palette.primary.main,
            cursor: 'pointer',
            '&.disabled': {
                color: theme.palette.text.disabled,
                pointerEvents: 'none'
            },
            '& svg': {
                marginRight: 5,
                fontSize: 30
            },
            '&:hover': {
                color: theme.palette.primary.light
            }
        }
    },
    planWrapper: {
        width: 120
    },
    tipContainer: {
        zIndex: '-1',
        fontSize: 14,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: '75px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        color: '#A1A1A1',
        '& .tip': {
            color: theme.palette.important[0],
            fontFamily: 'NTR !important',
            fontWeight: 'bold',
            fontSize: 22
        },
        '& .orangeTip': {
            color: theme.palette.important[0],
            fontWeight: 'bold'
        },
        '& svg': {
            color: theme.palette.important[0],
            width: 36,
            height: 36

        }
    },
    xIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
        width: 50,
        cursor: 'pointer',
        borderRadius: '50%',
        '& svg': {
            color: '#85929A'
        },
        '&:hover': {
            background: '#F4F5F6'
        }
    },
    addUserTD: {
        verticalAlign: 'top'
    }
})

const mapStateToProps = state => ({
    smallView: state.smallView,
    tempUsers: state.tempUsers
})

const mapDispatchToProps = dispatch => ({
    deleteTempUser: tempUserId => dispatch(deleteTempUser(tempUserId))
})

class AddUsersLarge extends Component {
    //
    generateTableHeadColumns = () => {
        const headColumns = [
            { content: 'First Name', testId: 'first-name-col' },
            { content: 'Last Name', testId: 'last-name-col' },
            { content: 'Email Address', testId: 'email-col' },
            { content: 'Plan', testId: 'plan-col' }
        ]
        if (this.props.tempUsers.length > 1) {
            headColumns.push({ content: '', testId: 'delete-col' })
        }
        return headColumns
    }

    generateTableRows = () => {
        const rows = this.props.tempUsers.map((user, index) => {
            return this.generateOneRow(user, index)
        })
        return rows
    }

    generateOneRow = (user, index) => {
        const columns = [
            { key: 0, content: this.renderInlineField(user, 'First name', user.first_name, 'first_name', user.first_name_error), primary: true, stringValue: user.first_name, testId: 'first-name-cell' },
            { key: 1, content: this.renderInlineField(user, 'Last name', user.last_name, 'last_name', user.last_name_error), testId: 'last-name-cell' },
            { key: 2, content: this.renderInlineField(user, 'e.g. john.doe@company.com', user.email, 'email', user.email_error), testId: 'email-cell' },
            { key: 3, content: this.renderPlanColumn(user), testId: 'plan-cell' }
        ]
        if (index > 0) { columns.push({ key: 4, content: this.renderDeleteColumn(user), testId: 'delete-row-cell' }) }
        return { key: user.id, columns }
    }

    renderInlineField = (user, label, value, field, error) => {
        return (
            <TextField
                label = {label}
                value = {value}
                onChange = {e => this.props.changeValueInUser(user, field, e.target.value)}
                onXClick = {() => this.props.changeValueInUser(user, field, '')}
                error = {error}
                helperText = {error}
                data-test-id = {field}
                noLabelOnActiveOrResolved
            />
        )
    }

    renderPlanColumn = (user) => {
        const { classes } = this.props

        const shouldDisablePlan = this.props.forceProPlan

        const planMenuItems = [
            { value: 1, content: 'Basic' },
            { value: 2, content: 'Plus' },
            { value: 3, content: 'Pro' }
        ]

        if (shouldDisablePlan) {
            return (
                <div
                    data-test-id='user-plan-name'
                    className='forcedPlanText'
                    style={{ paddingTop: '10px', fontSize: '16px' }}>
                    {planMenuItems.find(plan => plan.value === 3).content}
                </div>
            )
        }

        return (
            <div className={classes.planWrapper}>
                <Select
                    labelId="plan-select-label"
                    id="plan-select"
                    fullWidth
                    value={this.props.forceProPlan ? 3 : (user.user_plan_id ? user.user_plan_id : 1)}
                    label={''}
                    onChange={e => this.props.changeValueInUser(user, 'user_plan_id', e.target.value)}
                    disabled={shouldDisablePlan}
                >
                    {planMenuItems.map((item, i) => (
                        <MenuItem data-menu-item={item.content} key={i} value={item.value}>
                            {item.content}
                        </MenuItem>
                    ))}
                </Select>
            </div>
        )
    }

    renderDeleteColumn = (user) => {
        const { classes } = this.props
        return (
            <Tooltip classes={{ tooltip: classes.tooltip }} title='Delete' placement='right'>
                <div className={classes.xIconWrapper}>
                    <XIcon data-icon='remove-user-row' onClick={() => this.props.deleteTempUser(user.id)} />
                </div>
            </Tooltip>
        )
    }

    renderLoader = () => {
        const { classes } = this.props
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull data-test-id='add-users' text='Please wait...' color={theme.palette.secondary[0]} size='bigger'/>
            </div>
        )
    }

    renderAddUsersLargeContent = () => {
        const { classes } = this.props

        return (
            <div className={classes.addUsersContainer}>
                <div>
                    <TableMui
                        headColumns = {this.generateTableHeadColumns()}
                        rows = {this.generateTableRows()}
                        infiniteScroller = {false}
                        reverseScroll = {false}
                        listView = {false}
                        dataAttr = {'add-users-table'}
                        tdClasses = {classes.addUserTD}
                    />
                </div>
                <div className={classes.addUserWrapper}>
                    <div
                        className = {`add-user-button ${this.props.pendingAddUsers ? 'disabled' : ''}`}
                        onClick={() => this.props.addUserRow()}
                        data-test-id = 'add-user'
                    >
                        <AddCircleIcon/>Add new user
                    </div>
                </div>
            </div>
        )
    }

    render () {
        const main = this.props.pendingAddUsers ? this.renderLoader() : this.renderAddUsersLargeContent()
        return (
            <>
                {main}
                {!this.props.pendingAddUsers &&
                    <BottomNavigation
                        className = {this.props.classes.bottomNavigation}
                        nextDisabled = {this.props.pendingAddUsers}
                        hideBackButton = {true}
                        noIconInNextButton
                        noIconInBackButton
                        nextButtonText = 'Add users'
                        description = 'Users created without email addresses can be configured and added to call handling rules, but they will not receive an email invitation to my.phone.com or the mobile application.'
                        onNextClick = {this.props.onAddUsersClick}
                        smallView = {false}
                    />
                }
            </>
        )
    }
}

AddUsersLarge.propTypes = {
    classes: PropTypes.object,
    changeValueInUser: PropTypes.fucn,
    smallView: PropTypes.bool,
    users: PropTypes.array,
    tempUsers: PropTypes.array,
    pendingAddUsers: PropTypes.bool,
    onAddUsersClick: PropTypes.func,
    addUserRow: PropTypes.func,
    deleteTempUser: PropTypes.func,
    forceProPlan: PropTypes.bool
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddUsersLarge))
