<template>
    <w-loader v-if="blocked.loading"/>
    <div v-else class="create-page" data-test-id="bc-create-page">
        <w-alert
          v-if="blocked.alert"
          :message="blocked.alert.message"
          :level="blocked.alert.level"
          :closable="blocked.enable_close_alert"
          @closed="blocked.alert = null"
          class="mb-6"
        />
          <BlockedNumber
            v-if="!blocked.alert || (blocked.alert && blocked.alert.level !== 'success')"
            :_call="blocked.item"
            @submited="on_submit"
          />
    </div>
  </template>
  
  <script>
    import {vueComponent} from 'helpers';
    import BlockedCall from 'console/src/models/BlockedCall';
    import BlockedNumber from '../elements/BlockedNumber.vue';
  
    export default {
      props: {
        postSaveCb: {
          type: Function,
        },
      },
      components: {
        BlockedNumber,
      },
      data() {
        return {
          blocked: new BlockedCall(this.$session, vueComponent(this)),
        };
      },
      methods: {
        async on_submit(data) {
          const item = await this.$data.blocked.create(data)
          if (this.$data.blocked.alert && this.$data.blocked.alert.level === 'success' && typeof this.$props.postSaveCb === 'function') {
            await this.$props.postSaveCb(item)
          } 
        }
      }
    };
  </script>
  