import React, { useEffect, useState, useContext, useCallback } from 'react'
import { Avatar } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core'
import styles from './styles'
import Typography from 'typography'
import { SipCallSession } from 'pdc-calls'
import { formatPhoneNumber } from 'phone-numbers'
import { MyPhoneContactContext, MyPhoneContactContextProps } from 'providers'
import { useToggle } from 'hooks/src'
import baseAvatar from './baseAvatar.svg'

const useStyles = makeStyles(styles)

interface ContactInfoProps { contact, phoneNumber: string, shrink?: boolean }
const ContactInfo = ({ contact, phoneNumber, shrink }: ContactInfoProps): JSX.Element => {
    const classes = useStyles()
    const numberKey = `+${phoneNumber.replace(/\D/g, '')}`
    let phoneNumberType: string = contact.numbers.get(numberKey)?.type
    phoneNumberType = phoneNumberType ? `${phoneNumberType.substring(0, 1).toUpperCase()}${phoneNumberType.substring(1)}` : ''
    return (
        <div className={'contact-info'}>
            <Avatar classes={{ root: classes.avatar }} src={contact.avatarUrl || baseAvatar}/>
            <div className='participant-info'>
                <Typography variant='h5'>{contact.displayName}</Typography>
                <Typography variant={shrink ? 'body2' : 'body1'}>{phoneNumberType} {formatPhoneNumber(phoneNumber)}</Typography>
            </div>
        </div>
    )
}

interface FromInfoProps { callerId?: string, phoneNumber: string, shrink?: boolean }
const FromInfo = (props: FromInfoProps): JSX.Element => {
    const shrink = props.shrink
    const phoneNumber = formatPhoneNumber(props.phoneNumber)
    const callerId = formatPhoneNumber(props.callerId)
    const primaryText = callerId || phoneNumber // TODO: Maybe add here the DisplayName component
    const secondaryText = callerId ? phoneNumber : 'Placeholder for location' // TODO
    return (
        <div className='participant-info'>
            <Typography variant={shrink ? 'h6' : 'h5'}>{primaryText}</Typography>
            {!shrink && <Typography variant='body1'>{secondaryText}</Typography>}
        </div>
    )
}

interface Props { call: SipCallSession, shrink?: boolean }
/***/
const CallerInfo = ({ call, shrink }: Props): JSX.Element => {
    const classes = useStyles()
    const Contacts: MyPhoneContactContextProps = useContext(MyPhoneContactContext)
    const [hasContact, setHasContact] = useToggle(false)
    const [contactInfo, setContactInfo] = useState({ avatarUrl: '', displayName: '' })
    const callerId = call?.participants[0]?.callerId || 'UNKNOWN'
    const phoneNumber = call?.participants[0]?.phoneNumber || 'UNKNOWN'
    console.log(call)
    const findContactInfo = useCallback((phoneNumber: string) => {
        setHasContact(false)
        setContactInfo({ avatarUrl: '', displayName: '' })
        Contacts.findExactMatch(phoneNumber).then(contact => {
            if (contact) {
                setContactInfo(contact)
                setHasContact(true)
            }
        })
    }, [contactInfo, Contacts])

    useEffect(() => { findContactInfo(phoneNumber) }, [Contacts])
    useEffect(() => {
        const lookupNumber = call?.participants[0]?.phoneNumber
        if (lookupNumber) findContactInfo(lookupNumber)
        else setHasContact(false)
    }, [call, call?.callId])

    const avatarClass = hasContact ? 'has-avatar' : ''
    const shrinkClass = shrink ? 'shrink' : ''
    return (
        <div className={`${classes.callerInfo} ${avatarClass} ${shrinkClass}`}>
            {hasContact && <ContactInfo shrink={shrink} contact={contactInfo} phoneNumber={phoneNumber}/>}
            {!hasContact && <FromInfo shrink={shrink} callerId={callerId} phoneNumber={phoneNumber}/>}
        </div>
    )
}

export default CallerInfo
