import React, { useState, createContext, useContext, useEffect } from 'react'
import { App, apps as appsObj } from '../utils/objects'
import { getValue } from 'remote-config-value'
import { getPhoneCom } from 'phonecom'

interface AppsContextInterface {
    apps: App[],
    isLoadingApps: boolean
}

/**
 *
 */
export const AppsContext = createContext<AppsContextInterface>({
    apps: [],
    isLoadingApps: false
})

/**
 *
 */
export const useAppsContext = () => useContext(AppsContext)

/**
 *
 */
const AppsContextProvider = ({ children }: {children: JSX.Element}): JSX.Element => {
    const [isLoadingApps, setIsLoadingApps] = useState(true)
    const [apps, setApps] = useState<App[]>([])

    const setAppsState = (phoneCom: Record<string, any>, appsJson: string) => {
        if (typeof appsJson === 'string') {
            const parsedAppsJson = JSON.parse(appsJson)
            const apps = getFilteredApps(parsedAppsJson, phoneCom)
            setApps(apps)
        }
        setIsLoadingApps(false)
    }

    const getFilteredApps = (parsedAppsJson: App[], phoneCom: Record<string, any>) => {
        if (!(parsedAppsJson instanceof Array)) {
            return []
        }
        const filteredApps = parsedAppsJson.filter(app => {
            let isAppAllowed: boolean

            const isNxtUser = phoneCom?.user_tiered
            const isClassicUser = !phoneCom?.user_tiered
            const isAdmin = phoneCom?.role === 'account'
            const isNonAdmin = phoneCom?.role === 'extension'

            switch (true) {
                    case (isNxtUser && isAdmin) && app?.userTypes?.nxt?.admin:
                        isAppAllowed = true
                        break
                    case (isNxtUser && isNonAdmin) && app?.userTypes?.nxt?.nonAdmin:
                        isAppAllowed = true
                        break
                    case (isClassicUser && isAdmin) && app?.userTypes?.classic?.admin:
                        isAppAllowed = true
                        break
                    case (isClassicUser && isNonAdmin) && app?.userTypes?.classic?.nonAdmin:
                        isAppAllowed = true
                        break
                    default:
                        isAppAllowed = false
                        break
            }
            return isAppAllowed
        })

        return filteredApps
    }

    const setAppsForTestEnv = () => {
        if (process.env.NODE_ENV === 'test') {
            setApps(appsObj)
            setIsLoadingApps(false)
        }
    }

    useEffect(() => {
        getPhoneCom().then(phoneCom => {
            const appsJson: string | undefined = getValue('apps_marketplace_json', setAppsState.bind(this, phoneCom), false)

            if (appsJson) {
                setAppsState(phoneCom, appsJson)
            }
        })
        setAppsForTestEnv()
    }, [])

    return (
        <AppsContext.Provider value={{
            apps,
            isLoadingApps
        }} >
            {children}
        </AppsContext.Provider>
    )
}

export default AppsContextProvider
