import l from '../libs/lang'
import Resource from './Resource'
import RouteAnalyzer from './RouteAnalyzer'
import setup from '../libs/resources-setups/menus'

/**
 *
 */
export default class Menu extends Resource {
    /**
     * @param {object} session
     * @param {object} component
     * @param  {boolean}full_menu
     */
    constructor (session, component, full_menu) {
        super(session, component, '/menus')
        this.selector_mode = ['menus']
        this.setup = setup
        this.full_menu = full_menu
        this.filters = {
            name: null
        }
        this.buttons = {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
            6: false,
            7: false,
            8: false,
            9: false,
            0: false,
            '#': false
        }
        this.analysis = { ...this.buttons, '*': false }
        this.buttons_no_input = false
        this.item = {
            id: null,
            name: null,
            allow_extension_dial: false,
            keypress_wait_time: null,
            keypress_error: null,
            greeting: null,
            timeout_handler: null,
            options: []
        }
    }

    /**
     *
     * @param {object} params
     * @returns
     */
    async loadItems (params) {
        this.loading = true
        this.resetDeleting()
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page)
            const items = await this.session.get_list(
                this.uri, params.limit, offset
            )

            this.page = params.page
            if (this.page > 1 && !items.items.length) {
                this.component.emit('pageChanged', 1)
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1
                })
            }
            if (this.page === 1 && !this.filters_applied && !items.items.length) return this.component.routerPush('menus.create')

            this.items = items.items
            this.finalizeLoadingItems(items)
            this.component.emit('itemsLoaded', JSON.parse(JSON.stringify(items)))
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
        return this.items
    }

    /**
     *
     */
    apply_filters () {
        this.loading = true
        this.filters_applied = true
        try {
            let uri = `${this.baseUri}?mode=full`
            if (this.filters.name) uri = `${uri}&filters[name]=contains:${this.filters.name}`
            this.uri = uri
        } catch (err) {
            this.component.emit('failed', err)
        }
        this.loading = false
    }

    /**
     *
     */
    static get_default () {
        return {
            id: null,
            rules: [{
                actions: [{
                    action: 'forward',
                    hold_music: null,
                    timeout: 20,
                    items: [{
                        type: 'phone_number',
                        number: ''
                    }]
                }]
            }]
        }
    }

    /**
     * @param {object} res
     * @returns
     */
    prepare_menu_data (res) {
        const options = {
            1: Menu.get_default(),
            2: Menu.get_default(),
            3: Menu.get_default(),
            4: Menu.get_default(),
            5: Menu.get_default(),
            6: Menu.get_default(),
            7: Menu.get_default(),
            8: Menu.get_default(),
            9: Menu.get_default(),
            0: Menu.get_default(),
            '#': Menu.get_default()
        }
        for (const i in res.options) {
            if (res.options[i]) {
                options[res.options[i].key] = res.options[i].route
                this.buttons[res.options[i].key] = true
                this.analysis[res.options[i].key] = new RouteAnalyzer(res.options[i].route)
            }
        }
        res.options = options
        if (!res.timeout_handler) {
            res.timeout_handler = Menu.get_default()
            this.buttons_no_input = false
        } else {
            this.buttons_no_input = true
            this.analysis['*'] = new RouteAnalyzer(res.timeout_handler)
        }
        return res
    }

    /**
     *
     * @param {object} options
     * @returns
     */
    async convert_options_to_api_format (options) {
        const options_api = []
        for (const key in options) {
            if (this.buttons[key]) {
                if (!options[key].name || options[key].newPreset) {
                    if (!options[key].id) {
                        const new_route = await this.session.create_item('/routes', options[key])
                        options[key].id = (new_route).id
                        if (new_route.name) this.addToCache(new_route, 'presets')
                    } else {
                        const updated_route = await this.session.replace_item(`/routes/${options[key].id}`, options[key])
                        if (updated_route.name) this.updateCache(updated_route, 'presets')
                    }
                }

                options_api.push({
                    key,
                    route: options[key].id
                })
            }
        }

        return options_api
    }

    /**
     *
     * @returns
     */
    async save_general_data_only () {
        if (!this.item.id) return null
        try {
            this.component.emit('saving')
            this.loading = true
            const item = await this.session.get_item(`${this.baseUri}/${this.item.id}`)
            this.item.options = item.options
            this.item.timeout_handler = item.timeout_handler
            const res = await this.session.replace_item(`${this.baseUri}/${this.item.id}`, this.item)
            this.updateCache(res)
            this.alert = {
                message: l.t('app.changes-saved', 'Changes saved'),
                level: 'success'
            }
            this.hide_alert(3)
            this.item = this.prepare_menu_data(res)
            this.loading = false
            this.component.emit('saved', {
                id: res.id,
                value: res
            })
        } catch (err) {
            this.validation_error(err)
            this.component.emit('saving_failed', this.alert)
        }
        return true
    }

    /**
     *
     */
    async save () {
        try {
            this.component.emit('saving')
            this.loading = true
            const data = { ...this.item }
            data.options = await this.convert_options_to_api_format(data.options)
            if (this.buttons_no_input) {
                if (!data.timeout_handler.name || data.timeout_handler.newPreset) {
                    if (!data.timeout_handler.id) {
                        data.timeout_handler.id = (await this.session.create_item(
                            '/routes', data.timeout_handler
                        )).id
                    } else {
                        await this.session.replace_item(
                            `/routes/${data.timeout_handler.id}`,
                            data.timeout_handler
                        )
                    }
                }
            } else data.timeout_handler = null

            if (!data.id && !data.name) data.name = `Menu ${new Date().toLocaleString()}`

            let res
            if (data.id) {
                res = await this.session.replace_item(`${this.baseUri}/${data.id}`, data)
                this.updateCache(res)
            } else {
                res = await this.session.create_item(`${this.baseUri}`, data)
                this.addToCache(res)
            }
            if (!this.full_menu) {
                this.alert = {
                    message: l.t('app.changes-saved', 'Changes saved'),
                    level: 'success'
                }
                this.hide_alert(3)
            }
            this.item = this.prepare_menu_data(res)
            this.loading = false
            this.component.emit('saved', {
                id: res.id,
                value: res
            })
        } catch (err) {
            this.validation_error(err)
            this.component.emit('saving_failed', this.alert)
        }
    }

    /**
     *
     */
    async checkAll () {
        super.checkAll()
        this.items.map((x) => {
            if (x.generator && x.generator === 'configure-app') {
                x.selected = false
            }
            return x
        })
    }

    /**
     *
     * @param {Array} itms
     * @returns
     */
    async pre_delete_all_filter (itms) {
        const items = itms.filter((x) => !(x.generator && x.generator === 'configure-app'))
        return items
    }
}
