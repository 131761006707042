import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import BorderColorIcon from '@material-ui/icons/BorderColor'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'

const styles = theme => ({
	itemsWrapper: {
		boxShadow:		'0px 0px 6px gray',
		borderRadius:	7,
		maxWidth:			440
	},
	item: {
		display:		'flex',
		justifyContent:	'space-between',
		alignItems:		'center',
		padding:		'10px 15px',
		'&:not(:last-child)': {
			borderBottom: '1px dashed lightgray'
		}
	},
	textContent: {
		display:		'flex',
		flexDirection:	'column'
	},
	contentTitle: {
		fontSize:	18,
		fontWeight:	600
	},
	description: {
		fontSize:	14,
		color:		'gray'
	},
	buttonsWrapper: {
		display:	'flex',
		height:		'fit-content',
		marginLeft:	10,
		'& .button': {
			border:		'1px solid gray',
			cursor:		'pointer',
			display:	'flex',
			alignItems:	'center',
			padding:	'6px 12px',
			'&:hover': {
				background: '#fafafa'
			}
		},
		'& .edit': {
			borderTopLeftRadius:	4,
			borderBottomLeftRadius:	4,
		},
		'& .play': {
			borderTopRightRadius:		4,
			borderBottomRightRadius:	4,
			borderLeft:					'none'
		}
	}
})

class MusicAndMessages extends Component {
	render() {
		const { classes } = this.props
		return (
			<div>
				<div className={classes.itemsWrapper}>
					<div className={classes.item}>
						<div className={classes.textContent}>
							<span className={classes.contentTitle}>Music on hold</span>
							<span className={classes.description}>Music or message played when your customer are waiting on the line</span>
						</div>
						<div className={classes.buttonsWrapper}>
							<div className='button edit'><BorderColorIcon fontSize='small'/></div>
							<div className='button play'><PlayArrowIcon fontSize='small'/></div>
						</div>
					</div>
					<div className={classes.item}>
						<div className={classes.textContent}>
							<span className={classes.contentTitle}>Voicemail</span>
							<span className={classes.description}>Define your custom voicemail message</span>
						</div>
						<div className={classes.buttonsWrapper}>
							<div className='button edit'><BorderColorIcon fontSize='small'/></div>
							<div className='button play'><PlayArrowIcon fontSize='small'/></div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(MusicAndMessages)