<template>
  <w-btn
		class="phone-btn pa-0"
		:class="{
			'action lighten-2 phone-btn-inactive': !active,
			'info white--text phone-btn-active': active
		}"
		changes-form
		@click="$emit('click')"
	>
      <div
		    :class="{
					'text--text phone-btn-inactive': !active,
		    }"
				class="caller-selection-number"
      >
          <template v-if="number !== '*'">
						{{ number }}
          </template>
          <template v-else>
						{{ l.t('app.no', 'No') }}
						<br>
						{{ l.t('app.input', 'input') }}
          </template>
      </div>
      <div v-if="number !== '*'" class="caller-selection-letters">
				{{ keys[number] }}
      </div>
  </w-btn>
</template>

<script>
  import l from '../../libs/lang';

  export default {
    props: {
			number: {
				required: true,
				type: String,
			},
			active: {
				type: Boolean,
				default: false,
			}
    },
    data() {
      return {
				l,
				keys: {
					'0': '_',
					'1': '',
					'2': 'ABC',
					'3': 'DEF',
					'4': 'GHI',
					'5': 'JKL',
					'6': 'MNO',
					'7': 'PQRS',
					'8': 'TUV',
					'9': 'WXYZ',
					'#': '',
					'*': l.t('app.no-input', 'No input'),
				},
      };
    },
  };
</script>
