import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.1.1?node-id=9409%3A25690
 *
 * @param {SvgIconProps} props - svg props
 */
export const ShowDialerButtonIcon = (props: SvgIconProps & { secondaryColor?: string }): JSX.Element => {
    const color = props.color || 'currentColor'
    const secondaryColor = props.secondaryColor || 'white'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    delete svgProps.secondaryColor
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0 12C0 5.37258 5.37258 0 12 0V0C18.6274 0 24 5.37258 24 12V12C24 18.6274 18.6274 24 12 24V24C5.37258 24 0 18.6274 0 12V12Z' fill={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M16.6668 8.50016C16.6668 9.1445 16.1445 9.66683 15.5002 9.66683C14.8558 9.66683 14.3335 9.1445 14.3335 8.50016C14.3335 7.85583 14.8558 7.3335 15.5002 7.3335C16.1445 7.3335 16.6668 7.85583 16.6668 8.50016ZM15.5002 13.1668C16.1445 13.1668 16.6668 12.6445 16.6668 12.0002C16.6668 11.3558 16.1445 10.8335 15.5002 10.8335C14.8558 10.8335 14.3335 11.3558 14.3335 12.0002C14.3335 12.6445 14.8558 13.1668 15.5002 13.1668ZM9.66683 15.5002C9.66683 16.1445 9.1445 16.6668 8.50016 16.6668C7.85583 16.6668 7.3335 16.1445 7.3335 15.5002C7.3335 14.8558 7.85583 14.3335 8.50016 14.3335C9.1445 14.3335 9.66683 14.8558 9.66683 15.5002ZM12.0002 16.6668C12.6445 16.6668 13.1668 16.1445 13.1668 15.5002C13.1668 14.8558 12.6445 14.3335 12.0002 14.3335C11.3558 14.3335 10.8335 14.8558 10.8335 15.5002C10.8335 16.1445 11.3558 16.6668 12.0002 16.6668ZM15.5002 16.6668C16.1445 16.6668 16.6668 16.1445 16.6668 15.5002C16.6668 14.8558 16.1445 14.3335 15.5002 14.3335C14.8558 14.3335 14.3335 14.8558 14.3335 15.5002C14.3335 16.1445 14.8558 16.6668 15.5002 16.6668ZM13.1668 12.0002C13.1668 12.6445 12.6445 13.1668 12.0002 13.1668C11.3558 13.1668 10.8335 12.6445 10.8335 12.0002C10.8335 11.3558 11.3558 10.8335 12.0002 10.8335C12.6445 10.8335 13.1668 11.3558 13.1668 12.0002ZM8.50016 13.1668C9.1445 13.1668 9.66683 12.6445 9.66683 12.0002C9.66683 11.3558 9.1445 10.8335 8.50016 10.8335C7.85583 10.8335 7.3335 11.3558 7.3335 12.0002C7.3335 12.6445 7.85583 13.1668 8.50016 13.1668ZM12.0002 9.66683C12.6445 9.66683 13.1668 9.1445 13.1668 8.50016C13.1668 7.85583 12.6445 7.3335 12.0002 7.3335C11.3558 7.3335 10.8335 7.85583 10.8335 8.50016C10.8335 9.1445 11.3558 9.66683 12.0002 9.66683ZM9.66683 8.50016C9.66683 9.1445 9.1445 9.66683 8.50016 9.66683C7.85583 9.66683 7.3335 9.1445 7.3335 8.50016C7.3335 7.85583 7.85583 7.3335 8.50016 7.3335C9.1445 7.3335 9.66683 7.85583 9.66683 8.50016Z' fill={secondaryColor}/>
        </SvgIcon>
    )
}

export default ShowDialerButtonIcon
