export enum CallActions {
	// CALL = "CALL", manager action
	HANGUP = 'HANGUP',
	ANSWER = "ANSWER",
	HOLD = "HOLD",
	// UNHOLD = 'UNHOLD',
	MERGE = "MERGE",
	MUTE = "MUTE",
	SWITCH = "SWITCH",
	TRANSFER = "TRANSFER",
}
