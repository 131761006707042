import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = (readonly) => {
    return makeStyles((theme) => ({
        container: {
            padding: `${readonly ? 1 : 0}rem 3rem`,
            border: readonly ? '1px solid lightgrey' : null,
            margin: readonly ? '0rem 1rem' : null
        }
    }))
}

/**
 * @param {object} props
 * @param {object} props.children
 * @param {boolean} props.readOnly
 */
const CampaignEnrollmentStepContainer = ({ children, readOnly, showContainer = true }) => {
    const classes = useStyles(readOnly)()
    return (
        <div className={showContainer ? classes.container : null}>
            {children}
        </div>
    )
}

CampaignEnrollmentStepContainer.propTypes = {
    children: PropTypes.any,
    readOnly: PropTypes.bool,
    showContainer: PropTypes.bool
}

export default CampaignEnrollmentStepContainer
