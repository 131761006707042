import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from 'typography'
import Button from 'button'
import { makeStyles } from '@material-ui/core/styles'
import {
    ChevronBackIcon
} from 'svg-icons'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.grey[100],
        padding: '4px'
    },
    goBackBtn: {
        padding: '.5rem',
        border: 0
    },
    goBackIcon: {
        fontSize: '1.5rem'
    }
}))

/**
 *
 */
const GoBack = ({ title }: { title: string }) => {
    const classes = useStyles()
    const history = useHistory()

    const goBack = () => {
        history.goBack()
    }

    return (
        <Box className={classes.root}>
            <Button color='secondary' variant='outlined' className={classes.goBackBtn} onClick={goBack}>
                <ChevronBackIcon className={classes.goBackIcon} />
                <Typography variant='h6'>{title}</Typography>
            </Button>
        </Box>
    )
}

export default GoBack
