import { Styles } from '@material-ui/core/styles/withStyles'
type StylesType = Styles<string, Record<string, string>>

/**
 * @param {object} theme
 */
const styles: StylesType = theme => ({
    preferences: {
        zIndex: 5555,
        position: 'absolute',
        top: -1,
        right: -1,
        bottom: -1,
        left: -1,
        backgroundColor: 'white',
        width: 300,
        height: 565,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        borderRadius: 20,
        '& > .settings': {
            marginTop: 36,
            padding: '10px 10px 20px',
            height: 'calc(100% - 42px)',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            rowGap: 10,
            '& > .settings-section': {
                marginBottom: 3,
                '& > p': {
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold !important'
                },
                '& > .settings-section-body': {
                    borderRadius: 2,
                    boxShadow: 'rgb(0, 0, 0, 0.16) 0px 1px 4px'
                }
            }
        }
    },
    preferencesIcon: {
        position: 'fixed',
        top: 7,
        right: 56,
        zIndex: 1,
        color: 'white',
        '&.move': {
            right: 90
        },
        '& > svg': {
            fontSize: 18
        },
        cursor: 'pointer',
        transition: 'right .2s'
    },
    preferencesAnchor: {
        position: 'fixed',
        top: -2,
        left: '50%',
        transform: 'translateX(-50%)',
        width: 1,
        height: 1,
        zIndex: -1
    },
    audioSourceWrapper: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        position: 'relative'
    },
    audioSourceAnchor: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 1,
        height: 1,
        zIndex: -1,
        pointerEvents: 'none'
    },
    settingItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        borderTop: `1px solid ${theme.palette.secondary.background}`,
        '&.vertical': {
            flexDirection: 'column',
            alignItems: 'flex-start',
            rowGap: 10
        },
        '&:first-child': {
            borderTop: 'none'
        },
        '& > .labels': {
            paddingRight: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            '& > p': {
                '&:first-child': {
                    fontWeight: 'bold'
                },
                '&:nth-child(2)': {
                    color: theme.palette.secondary.main
                }
            }
        },
        '& .ringtone-selector-wrapper': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            rowGap: 10,
            '& > div': {
                width: '100%'
            }
        }
    },
    preferencesCloseButton: {
        position: 'absolute',
        top: 10,
        right: 10
    },
    dropdownMenu: {
        width: '100%'
    },
    volumeWrapper: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 15,
        width: '100%',
        '& > svg': {
            cursor: 'pointer',
            color: theme.palette.secondary.dark
        }
    },
    dropdownItemsWrapper: {
        width: '100%'
    }
})

export default styles
