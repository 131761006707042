<template>
    <App v-bind="$attrs" v-on="$listeners" :sessionId="sessionId" :onClickLink="onClickLink" :onClickBack="onClickBack">
      <Statements
          v-if="page === 'statements'"
          :postSaveCb="postSaveCb"
          :onClickCancel="onClickCancel"
      />
      <PaymentMethodsCreate
          v-else-if="page === 'create'"
          :postSaveCb="postSaveCb"
          :onClickCancel="onClickCancel"
      />
      <PaymentMethodsIndex v-else/>
    </App>
  </template>

  <script>
  import {Statements, PaymentMethodsCreate, PaymentMethodsIndex} from 'billing-components'
  import App from '../elements/functional-components/App.vue';
  
  export default {
    props:{
      sessionId: {
        type: String,
        required: false,
      },
      page: {
        type: String,
        required: true,
        validator: (v) => ['create', 'statements', 'index'].includes(v),
      },
      postSaveCb: {
          type: Function,
      },
      onClickCancel: {
        type: Function,
      },
      onClickLink: {
        type: Function,
      },
      onClickBack: {
        type: Function,
      }
    },
    components: {
      App,
      Statements,
      PaymentMethodsCreate,
      PaymentMethodsIndex
    },
  };
  </script>

  <style lang="scss">
      @import 'vue-apps-assets/styles/scss/styles.scss';
  </style>