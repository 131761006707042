import React from 'react'
import App from './src/components/App.js'

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers'

const store = createStore(rootReducer)

/**
 * @param {object} props
 */
export default function People (props) {
    return (
        <Provider store={store}>
            <App
                extension = {props.extension}
                contactsUtil = {props.contactsUtil}
                screenViewType = {props.screenViewType}
                standalone = {props.standalone}
                onLoaded = {props.onLoaded}
                subscribeForNotifications = {props.subscribeForNotifications}
                resetSubscription = {props.resetSubscription}
                updateUnreadCounts = {props.updateUnreadCounts}
                changeFaxReadStatus = {props.changeFaxReadStatus}
                redirect = {props.redirect}
                makeCall = {props.makeCall}
                setHasChange = {props.setHasChange}
                routeProps = {props.routeProps}
            />
        </Provider>
    )
}
