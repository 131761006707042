import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingSelector from './SettingSelector'
import SettingContent from './SettingContent'
import ResizeAware from 'react-resize-aware'
import LoaderFull from 'loader-full'
import PhoneComUser from 'phone-com-user'
import { setSmallView, switchCurrentView } from '../actions/view.js'

import { withStyles } from '@material-ui/core'

const styles = theme => ({
	wrapper: {
		height:		'100%',
		display:	'flex'
	},
	loadingDiv: theme.loadingDiv
})

const mapStateToProps = state => ({
	smallView:			state.smallView,
	currentView:		state.currentView,
	currentSetting:		state.currentSetting,
	switchCurrentView:	state.switchCurrentView
})
const mapDispatchToProps = dispatch => ({
	setSmallView:		bool => dispatch(setSmallView(bool)),
	switchCurrentView:	view => dispatch(switchCurrentView(view))
})
let threshold = 768;

class App extends Component {

	state = {loading: false}

	constructor(props) {
		super(props)
		if (props.extension && props.extension.extension_id !== PhoneComUser.getExtensionId()) {
			PhoneComUser.changeExtension(props.extension.extension_id)
		}
	}

	componentDidUpdate() {
		if (this.props.extension && this.props.extension.extension_id !== PhoneComUser.getExtensionId()) {
			PhoneComUser.changeExtension(this.props.extension.extension_id)
			let phoneNumbers = Object.keys(this.props.extension.phone_number)
			PhoneComUser.changePhoneNumber(!phoneNumbers.length ? '' : phoneNumbers)
			this.reload()
		}
	}

	reload = () => {
		this.setState({loading: true})
		setTimeout(() => this.setState({loading: false}), 678)
	}

	handleResize = size => {
		let needToExpand = this.props.smallView && ((this.props.standalone && size.width >= threshold) || !this.props.screenViewType.isMobileView)
		let needToShrink = !this.props.smallView && ( (this.props.standalone && size.width < threshold) || this.props.screenViewType.isMobileView)
		if (needToShrink) {
			this.props.setSmallView(true)
			this.props.switchCurrentView('settings')
		} else if (needToExpand) {
			this.props.setSmallView(false)
		}
	}

	render() {
		const { classes, smallView, currentView } = this.props
		return (
			<ResizeAware
				className	= 'resize-aware'
				style		= {{height: this.props.standalone ? 'calc(100% - 60px)' : '100%'}}
				onResize	= {this.handleResize}
			>
				<div className={`App ${classes.wrapper}`}>
					{this.state.loading ?
						<div className={classes.loadingDiv}>
							<LoaderFull styles={{loaderFull: {left: this.props.smallView ? '50%' : 'calc(50% + 120px)'}}} size='big'/>
						</div>
					: null}
					{!smallView || currentView === 'select' ? <SettingSelector/> : null}
					{!this.state.loading && (!smallView || currentView === 'settings') && this.props.currentSetting ?
						<SettingContent onLoaded={this.props.onLoaded}/>
					: null}
				</div>
			</ResizeAware>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))