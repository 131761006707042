import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from 'typography'
import useProgressiveImg from '../../../hooks/useProgressiveImg'
import headerBg from '../../../images/headerBg.png'
import { StorefrontIcon } from 'svg-icons'

const useStyles = makeStyles(theme => ({
    headerBox: {
        backgroundImage: ({ imgLoading }: {imgLoading: boolean}) => imgLoading ? 'linear-gradient(90deg, #155508, #3eab29)' : `url(${headerBg})`,
        backgroundSize: 'cover',
        padding: '3rem',
        "& [class*='MuiTypography-root']": {
            color: '#fff'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '4.5rem 3rem'
        },
        [theme.breakpoints.down('xs')]: {
            padding: '3rem 1.5rem'
        }
        // "&::before": {
        //     content: "''",
        //     position: 'absolute',
        //     inset: 0,
        //     backgroundColor: 'rgba(0,0,0,.2)'
        // }
    },
    headerGrid: {
        // justifyContent: 'space-between',
        position: 'relative',
        alignItems: 'center',
        maxWidth: '1100px',
        margin: 'auto',
        gap: '1rem',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '1.25rem'
        }
    },
    appsIcon: {
        color: '#fff',
        fontSize: '4.5rem'
    },
    typoGraphyGrid: {

    },
    title: {
        fontWeight: '600!important',
        letterSpacing: '-0.4px!important'
    },
    helpTitle: {
        fontWeight: '600!important'
    },
    connectTitle: {
        padding: '1rem 0',
        [theme.breakpoints.down('xs')]: {
            padding: '.5rem 0'
        }
    },
    requestBtn: {
        color: '#fff!important',
        backgroundColor: 'transparent!important',
        borderColor: '#fff!important',
        padding: '7px 20px 7px',
        borderWidth: '1px'
    }
}))

/**
 *
 */
const Header = () => {
    const [src, { imgLoading }] = useProgressiveImg('', headerBg)
    const classes = useStyles({ src, imgLoading })

    return (
        <Box className={classes.headerBox}>
            <Grid container className={classes.headerGrid}>
                <Grid item>
                    <StorefrontIcon className={classes.appsIcon} />
                </Grid>
                <Grid item className={classes.typoGraphyGrid}>
                    <Typography variant='h3' className={classes.title} remoteConfigID='apps_marketplace_title' />
                    <Typography variant='h6' remoteConfigID='apps_marketplace_subtitle' />
                </Grid>
                {/* <Grid item xs={12} sm={5} md={4}>
                    <Typography variant='h6' className={classes.helpTitle} remoteConfigID='apps_marketplace_help' />
                    <Typography variant='subtitle1' className={classes.connectTitle} remoteConfigID='apps_marketplace_connect' />
                    <OutlinedBtn color='white' icon='add' iconPlacement='right' remoteConfigID='apps_marketplace_request' />
                </Grid> */}
            </Grid>
        </Box>
    )
}

export default Header
