<template>
	<v-simple-table data-test-id="account-list-table">
		<thead data-test-id="table-head">
			<tr data-test-id="table-head=row">
				<th style="width: 25%" data-test-id="name">
					<slot name='first_field_title'>{{ l.t('app.name', 'Name') }}</slot>
				</th>
				<th style="width: 25%" data-test-id="type">{{ l.t('app.type', 'Type') }}</th>
				<th style="width: 100%" data-test-id="value">{{ l.t('app.value', 'Value') }}</th>
			</tr>
		</thead>
		<tbody data-test-id="body">
			<slot name="fields">
				<Data
					v-for="(conf, param) in parameters"
					v-if="type && conf.level === type"
					:key="param"
					:_parameters="parameters"
					:conf="conf"
					:param="param"
					:dynamic_values="dynamic_values"
					@changed="$emit('changed', {param:param, value: $event})"
					:data-test-id="`ga-data-${conf.name}-data`"
				 >
				 </Data>
			</slot>
		</tbody>
	</v-simple-table>
</template>

<script>
import l from '../../../../libs/lang';
import Data from './data.vue';

export default {
	props: ['parameters', 'dynamic_values', 'type'],
	components: { Data },
	data() {
		return {
			l,
		};
	},
};
</script>
