import { combineReducers } from 'redux'
import { settings, currentSetting, currentPrimaryCard, invoices, voicemail, security } from './settings'
import { smallView, currentView } from './view';

export default combineReducers({
	settings,
	currentSetting,
	smallView,
	currentView,
	currentPrimaryCard,
	invoices,
	voicemail,
	security
})