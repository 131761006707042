import React, {Component} from 'react';
import MediaModalOtherType from './MediaModalOtherType';

class MediaModalVideo extends Component {

	videoLoaded = this.props.mediaUrl;
	unplayableMimetypes = ['video/3gpp']

	render() {

		return (
			this.unplayableMimetypes.includes(this.props.mimetype) ? 
			<MediaModalOtherType mediaUrl={this.props.mediaUrl} mediaType='video' mimetype={this.props.mimetype}></MediaModalOtherType> :
			<video id='video-tag' className='current-media not-selectable fs-block' controls>
				<source id='video-source-tag' src={this.props.mediaUrl} type={this.props.mimetype} />
				Your browser does not support the video tag.
			</video>
		);
	}

	componentDidUpdate() {

		if (this.unplayableMimetypes.includes(this.props.mimetype)) {
			return
		}

		let sourceTag = document.getElementById('video-source-tag');
		// NOTE: This check is because componentDidUpdate gets called 2-3 times and the video renders 2-3 times.
		// With this check it will load only once.
		if (this.videoLoaded !== sourceTag.src) {
			let video = document.getElementById('video-tag');
			video.load();
			this.videoLoaded = sourceTag.src;
		}
	}

}

export default MediaModalVideo;