import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5850%3A11
 *
 * @param {SvgIconProps} props - svg props
 */
export const MergeIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M7.29949 8.27862C7.07912 8.27862 6.96525 8.02634 7.11614 7.87242L11.818 3.07602C11.9174 2.97466 12.0853 2.97466 12.1847 3.07603L16.8859 7.87243C17.0368 8.02636 16.9229 8.27862 16.7026 8.27862H13.3092V13.6185C13.3092 13.8516 13.2211 14.0767 13.0613 14.2524L7.07657 20.8302C6.88714 21.0384 6.55429 21.0518 6.34727 20.8596L5.15307 19.7509C4.95929 19.571 4.94805 19.2767 5.1276 19.0837L10.6178 13.1821C10.7 13.0937 10.7454 12.9795 10.7454 12.8611V8.27862H7.29949ZM15.1028 15.0905C15.0041 14.9859 14.8332 14.9845 14.7326 15.0875L13.265 16.5899C13.1759 16.6811 13.1743 16.8224 13.2612 16.9154L16.9253 20.8394C17.1158 21.0434 17.4451 21.0546 17.6503 20.8641L18.8469 19.7532C19.0417 19.5723 19.0519 19.2761 18.87 19.0834L15.1028 15.0905Z' fill={color}/>
        </SvgIcon>
    )
}

export default MergeIcon
