import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateFaxLinks, updateFax, removeContactFromFaxes, addContactsToFaxes } from '../actions/faxes'
import FaxHeader from './FaxHeader'
import ParticipantsModal from 'participants-modal'
import EditContactModal from 'edit-contact-modal'
import PDF from 'pdf'
import api from '../util/api_v5'
import Spinner from 'spinner'
import { getFormattedTime2 } from 'time-format'
import { formatPhoneNumber } from 'phone-numbers'
import downloadIcon from '../images/icon-received-000.svg'
import sentIcon from '../images/icon-sent-blk.svg'
import failedIcon from '../images/icon-alert-000.svg'
import { withStyles } from '@material-ui/core'
import { PdcCallConsumer } from 'pdc-calls'

const mapStateToProps = (state) => ({
    faxes: state.faxes,
    // currentFax: state.currentFax,
    smallView: state.smallView,
    faxLinks: state.faxLinks
})

const mapDispatchToProps = (dispatch) => ({
    updateFaxLinks: (links) => dispatch(updateFaxLinks(links)),
    updateFax: (fax) => dispatch(updateFax(fax)),
    addContactsToFaxes: (contacts) => dispatch(addContactsToFaxes(contacts)),
    removeContactFromFaxes: (contactId) => dispatch(removeContactFromFaxes(contactId))
})

const styles = (theme) => ({
    faxContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative'
    },
    mainContent: {
        padding: 20,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        overflow: 'scroll',
        '&.empty': {
            alignItems: 'center'
        }
    },
    spinnerWrapper: {
        display: 'table',
        margin: '0 auto'
    },
    faxInfoBar: {
        width: '100%',
        padding: '5px 20px',
        display: 'flex',
        alignItems: 'center',
        boxShadow: theme.palette.primary.flatBottomShadow,
        color: 'black'
    },
    dateTimeInfo: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: 15,
        borderRight: '1px solid',
        borderRightColor: theme.faxesApp.infoBorderColor,
        minWidth: 'fit-content',
        lineHeight: 1.36,
        '& img': {
            width: 24,
            marginLeft: 10
        }
    },
    contactInfo: {
        paddingLeft: 15,
        color: theme.faxesApp.contactInfoColor,
        lineHeight: 1.36
    }
})

class FaxContent extends Component {
    state = {
        editContact: null,
        hoverOverParticipants: false,
        currentFax: null,
        loading: false,
        faxIdError: false
    }

    componentDidMount () {
        this._ismounted = true
        // this.getFaxLink()
        this.loadFax()
    }

    componentWillUnmount () {
        this._ismounted = false
    }

    componentDidUpdate (prevProps, prevState) {
        // if the id prop changes reload the fax
        if (
            (!this.state.currentFax && !this.state.loading && !prevState.loading) ||
            (this.props.currentFaxId && prevProps.currentFaxId !== this.props.currentFaxId)
        ) {
            this.setState({ faxIdError: false })
            this.updateEditContactIfNeeded(prevProps)
            this.loadFax()
        }
        // if currentFax in props AND state AND ids are the same
        // AND their is_new flag does not match THEN update state with prop
        if (this.props.currentFax && this.state.currentFax &&
            this.props.currentFax.id === this.state.currentFax.id &&
            this.props.currentFax.is_new !== this.state.currentFax.is_new) {
            this.setState({ currentFax: this.props.currentFax })
        }
    }

    updateEditContactIfNeeded = (prevProps) => {
        if (
            prevProps.extraContacts.length === this.props.extraContacts.length ||
            !this.state.editContact ||
            this.state.editContact.id
        ) { return }
        const editContact = this.state.editContact
        const number = editContact.number
        let contactId = null
        this.props.extraContacts.forEach((cd) => {
            if (cd.numbers.find((n) => n.number === number)) contactId = cd.id
        })
        if (!contactId) return
        editContact.id = contactId
        this.setState({ editContact })
    }

    getFaxLink = () => {
        const fax = this.state.currentFax
        if (fax && fax.id && !this.props.faxLinks[fax.id]) {
            api.getFaxLink(fax.id).then((url) => {
                const faxLinks = this.props.faxLinks
                if (url === null) url = 'n/a'
                faxLinks[fax.id] = url
                if (this._ismounted) this.props.updateFaxLinks(faxLinks)
            })
        }
    }

    startConversation = (phoneNumber) => {
        if (!phoneNumber) phoneNumber = this.state.currentFax.fromNumber
        const extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
        const redirectPath = `/e${extensionId}/messages/new-conversation/${phoneNumber}`
        this.props.redirect(redirectPath)
    }

    formatFromContactInfo = () => {
        const fax = this.state.currentFax
        let info = [
            fax.from.from || fax.from.name,
            fax.from.company,
            formatPhoneNumber(fax.from.number || fax.from.phone)
        ]
        info = info.filter((i) => i)
        return info.join(' – ')
    }

    editContact = (id, number, prefillData) => {
        this.setState({ editContact: { id, number: id ? '' : number, prefillData } })
    }

    getContact = () => {
        const extraContacts = this.props.extraContacts
        const editContact = this.state.editContact
        const contactId = editContact ? editContact.id : null
        if (!editContact || !contactId || !extraContacts) return null
        let contact = null
        this.props.extraContacts.forEach((c) => (c.id === contactId ? (contact = c) : null))
        return contact
    }

    saveContact = (contact) => {
        const extraContacts = this.props.extraContacts
        const isNew = !extraContacts.find((c) => c.id === contact.id)
        this.props.updateContact(contact)
        if (isNew) this.props.addContactsToFaxes([contact])
        this.setState({ editContact: null })
    }

    deleteContact = (contactId) => {
        this.props.deleteContact(contactId)
        this.setState({ editContact: null })
    }

    toggleParticipantsHover = (hoverOverParticipants) => {
        if (this.state.hoverOverParticipants !== hoverOverParticipants) {
            this.setState({ hoverOverParticipants })
        }
    }

    renderAllParticipants = () => {
        const currentFax = this.state.currentFax
        const myNumber = { number: currentFax.direction === 'out' ? currentFax.from.number : currentFax.to.number }
        const otherNumber = {
            number: currentFax.direction === 'out' ? currentFax.to.number : currentFax.from.number,
            name: ''
        }
        const extraContacts = this.props.extraContacts
        extraContacts.forEach((c) => {
            if (otherNumber.name) return
            const contactNumbers = c.numbers.map((n) => n.number)
            if (contactNumbers.includes(otherNumber.number)) {
                otherNumber.name = c.name.display
                otherNumber.contactId = c.id
            }
        })
        const theOtherParticipant = [currentFax.direction === 'out' ? 'to' : 'from']
        if (!otherNumber.name && currentFax[theOtherParticipant].name) {
            otherNumber.name = currentFax[theOtherParticipant].name
        }
        if (currentFax[theOtherParticipant].city || currentFax[theOtherParticipant].state) {
            otherNumber.address = [currentFax[theOtherParticipant].city, currentFax[theOtherParticipant].state]
        }
        const extensionPhoneNumbers = this.props.extension.phone_number
        if (extensionPhoneNumbers[myNumber.number]) { myNumber.numberNickname = extensionPhoneNumbers[myNumber.number].name }

        return (
            <PdcCallConsumer>
                {(context) => (
                    <ParticipantsModal
                        selectedNumber={myNumber.number}
                        myNumbers={[myNumber]}
                        otherNumbers={[otherNumber]}
                        participantsHovered={this.state.hoverOverParticipants}
                        editContact={this.editContact}
                        makeCall={this.props.makeCall}
                        extension={this.props.extension}
                        canPlaceOutgoingCall={this.props.canPlaceOutgoingCall}
                        isCallButtonDisabled={!context.canPlaceOutgoingCall}
                    />
                )}
            </PdcCallConsumer>
        )
    }

    loadFax = async () => {
        const { currentFaxId } = this.props
        if (!currentFaxId) return null

        let currentFax = this.props.faxes['fax-incoming'].items.find((f) => f.id === currentFaxId)
        if (!currentFax) currentFax = this.props.faxes['fax-outgoing'].items.find((f) => f.id === currentFaxId)

        if (!currentFax) {
            try {
                this.setState({ loading: true })
                const res = await api.loadFaxes(0, 1, { id: currentFaxId })
                if (!res || !res.items || res.items.length < 1) this.setState({ faxIdError: true })
                currentFax = res.items[0] || null
            } catch (error) {
                console.error(error)
                currentFax = null
            }
        }

        this.setState({ currentFax, loading: false }, this.getFaxLink)
        // this.getFaxLink()
    }

    render () {
        const { classes } = this.props
        const { currentFax, faxIdError } = this.state
        // if (loading) return <Spinner />
        if (faxIdError) return <h1>could not find fax</h1>
        if (!currentFax) return (<Spinner />)

        return (
            <div className={classes.faxContent}>
                <FaxHeader
                    currentFax={currentFax}
                    deleteFax={this.props.deleteFax}
                    changeReadStatus={this.props.changeReadStatus}
                    startConversation={this.startConversation}
                    smallView={this.props.smallView}
                    toggleParticipantsHover={this.toggleParticipantsHover}
                    extraContacts={this.props.extraContacts}
                    routerProps={this.props.routerProps}
                    switchTab={this.props.switchTab}
                />

                {this.renderAllParticipants()}

                <div className={classes.faxInfoBar}>
                    <div className={classes.dateTimeInfo}>
                        <span>{getFormattedTime2(this.state.currentFax.created_at, true)}</span>
                        {/* <a href={this.props.faxLinks[currentFax.id]} target='_blank'> */}
                        <img
                            src={
                                currentFax.direction === 'in'
                                    ? downloadIcon
                                    : currentFax.delivery_status === 'failed'
                                        ? failedIcon
                                        : sentIcon
                            }
                            alt="Download"
                        />
                        {/* </a> */}
                    </div>
                    <div className={classes.contactInfo}>
                        {currentFax.direction === 'in'
                            ? formatPhoneNumber(currentFax.to.number)
                            : this.formatFromContactInfo()}
                    </div>
                </div>

                <div
                    className={`${classes.mainContent} ${this.props.faxLinks[currentFax.id] === 'n/a' ? 'empty' : ''}`}
                >
                    {this.props.faxLinks[currentFax.id]
                        ? (
                            this.props.faxLinks[currentFax.id] === 'n/a'
                                ? (
                                    'No fax preview available.'
                                )
                                : (
                                    <PDF url={this.props.faxLinks[currentFax.id]}>
                                        {console.log(this.props.faxLinks[currentFax.id])}
                                    </PDF>
                                )
                        )
                        : (
                            <div className={classes.spinnerWrapper}>
                                <Spinner />
                            </div>
                        )}
                </div>

                <EditContactModal
                    type={this.state.editContact ? (this.state.editContact.id ? 'Edit' : 'Add') : false}
                    onClose={() => this.setState({ editContact: null })}
                    fixedNumber={this.state.editContact ? this.state.editContact.number : null}
                    prefillData={this.state.editContact ? this.state.editContact.prefillData : null}
                    contact={this.getContact()}
                    contactGroupTypes={this.props.contactGroupTypes}
                    saveContact={this.saveContact}
                    deleteContact={this.deleteContact}
                    makeCall={this.props.makeCall}
                    startConversation={this.startConversation}
                    addGroup={this.props.addGroup}
                    isVirtualExtension={this.props.extension.is_virtual}
                    smallView={this.props.smallView}
                />
            </div>
        )
    }
}

FaxContent.propTypes = {
    addContactsToFaxes: PropTypes.func,
    addGroup: PropTypes.any,
    canPlaceOutgoingCall: PropTypes.any,
    changeReadStatus: PropTypes.any,
    classes: PropTypes.shape({
        contactInfo: PropTypes.any,
        dateTimeInfo: PropTypes.any,
        faxContent: PropTypes.any,
        faxInfoBar: PropTypes.any,
        mainContent: PropTypes.any,
        spinnerWrapper: PropTypes.any
    }),
    contactGroupTypes: PropTypes.any,
    currentFax: PropTypes.shape({
        id: PropTypes.any,
        is_new: PropTypes.any
    }),
    currentFaxId: PropTypes.any,
    deleteContact: PropTypes.func,
    deleteFax: PropTypes.any,
    extension: PropTypes.shape({
        is_virtual: PropTypes.any,
        phone_number: PropTypes.any
    }),
    extraContacts: PropTypes.shape({
        find: PropTypes.func,
        forEach: PropTypes.func,
        length: PropTypes.any
    }),
    faxLinks: PropTypes.any,
    faxes: PropTypes.any,
    makeCall: PropTypes.any,
    redirect: PropTypes.func,
    routerProps: PropTypes.any,
    smallView: PropTypes.any,
    switchTab: PropTypes.any,
    updateContact: PropTypes.func,
    updateFaxLinks: PropTypes.func
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FaxContent))
