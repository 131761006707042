import { Styles } from '@material-ui/core/styles/withStyles'
type StylesType = Styles<any, Record<string, unknown>>

/**
 * @param {object} theme
 */
const styles: StylesType = theme => ({
    transferCallOptions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '20px'
    },
    transferCallButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    transferButton: {
        height: '46px',
        width: '46px',
        backgroundColor: theme.palette.primary.dark,
        color: 'white',
        margin: '10px 25px'
    },
    progressOverlay: {
        zIndex: '9999',
        position: 'absolute',
        top: '0px',
        left: '0px',
        height: '100%',
        width: '100%',
        backgroundColor: '#FFFFFFE5',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        columnGap: 5
    },
    progressIcon: {
        color: theme.palette.text.primary
    }
})

export default styles
