import React, { useContext, useEffect, useState } from 'react'
import CallerInfo from '../utils/CallerInfo/CallerInfo'

import Typography from 'typography'
import { SipCallSession } from 'pdc-calls'
import { CallButtonIcon, CallIcon, CloseIcon, EndCallButtonIcon, HoldButtonIcon } from 'svg-icons'
import { formatPhoneNumber } from 'phone-numbers'
import { IconButton } from 'icon-button-mui'
import { Grid, makeStyles, Button } from '@material-ui/core'
import styles from './styles'
import { PdcCallContext } from 'pdc-calls'
import { FeatureEventsContext } from 'providers/src'
const useStyles = makeStyles(styles)

interface ActionButtonProps { onClick: () => void, hasActiveCall?: boolean }

const AnswerButton = (props: ActionButtonProps): JSX.Element | null => {
    if (props.hasActiveCall) return null
    return (
        <div className='action-button answer-button disable-dragging' onClick={props.onClick}>
            <IconButton><CallIcon/></IconButton>
            <Typography variant='overline'>Answer</Typography>
        </div>
    )
}

const DeclineButton = (props: ActionButtonProps): JSX.Element => {
    const moveDownClass = props.hasActiveCall ? 'move-down' : ''
    return (
        <div
            className={`action-button decline-button ${moveDownClass} disable-dragging`}
            onClick={props.onClick}
            data-test-id='softphone-decline-button'
        >
            <IconButton color='urgent'><CloseIcon/></IconButton>
            <Typography variant='overline'>Decline</Typography>
        </div>
    )
}

const HoldAnswerButton = (props: ActionButtonProps): JSX.Element | null => {
    if (!props.hasActiveCall) return null
    return (
        <div className='action-button hold-answer-button disable-dragging' onClick={props.onClick}>
            <div className='combined-icons'>
                <HoldButtonIcon/>
                <CallButtonIcon/>
            </div>
            <Typography variant='overline'>Hold & answer</Typography>
        </div>
    )
}

const EndAnswerButton = (props: ActionButtonProps): JSX.Element | null => {
    if (!props.hasActiveCall) return null
    return (
        <div className='action-button end-answer-button disable-dragging' onClick={props.onClick}>
            <div className='combined-icons'>
                <EndCallButtonIcon/>
                <CallButtonIcon/>
            </div>
            <Typography variant='overline'>{'End & answer'}</Typography>
        </div>
    )
}

const IgnoreButton = (): JSX.Element => {
    const classes = useStyles()
    const callContext: any = useContext(PdcCallContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    const onClick = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'incoming-call-ignore-button-click' })
        callContext.hangupById(callContext.incomingCalls[0]?.callId)
    }
    return (
        <Button
            variant = 'outlined'
            className = {classes.ignoreButton}
            onClick = {onClick}
            disableRipple
        >
            <Typography variant='overline'>Ignore</Typography>
        </Button>
    )
}

const Actions = (): JSX.Element => {
    const callContext: any = useContext(PdcCallContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    const classes = useStyles()
    const decline = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'incoming-call-decline-button-click' })
        callContext.hangupById(callContext.incomingCalls[0]?.callId)
    }
    const hasActiveCall = !!callContext.activeCallId
    const answer = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'incoming-call-answer-button-click' })
        callContext.answerById(callContext.incomingCalls[0]?.callId)
    }
    const holdAndAnswer = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'incoming-call-hold-and-answer-button-click' })
        callContext.hold().then(() => {
            callContext.answerById(callContext.incomingCalls[0]?.callId)
        })
    }
    const endAndAnswer = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'incoming-call-end-and-answer-button-click' })
        callContext.hangupById(callContext.activeCallId, true)
        callContext.answerById(callContext.incomingCalls[0]?.callId)
    }
    return (
        <div className={classes.incomingCallActions}>
            <AnswerButton hasActiveCall={hasActiveCall} onClick={answer}/>
            <HoldAnswerButton hasActiveCall={hasActiveCall} onClick={holdAndAnswer}/>
            <EndAnswerButton hasActiveCall={hasActiveCall} onClick={endAndAnswer}/>
            <DeclineButton hasActiveCall={hasActiveCall} onClick={decline}/>
        </div>
    )
}

const Description = (props: { incomingCall: SipCallSession }): JSX.Element => {
    const { incomingCall } = props
    const classes = useStyles()
    const calleeId = formatPhoneNumber(incomingCall.myCallInfo?.phoneNumber)
    const info = `Incoming call${calleeId ? ` on ${calleeId}` : ''}`
    return (<div className={classes.incomingCallDescription}>
        <Typography component='subtitle2'>{info}</Typography>
    </div>)
}

/***/
const IncomingCallView = (props: { hidden?: boolean}): JSX.Element => {
    const classes = useStyles()
    const PdcCall: any = useContext(PdcCallContext)
    const [incomingCall, setIncomingCall] = useState(PdcCall.incomingCalls[0])
    useEffect(() => {
        const incoming = PdcCall.incomingCalls.length > 0
        if (incoming) setIncomingCall(PdcCall.incomingCalls[0])
    }, [PdcCall.incomingCallsCnt])
    if (!incomingCall) return (<></>)
    return (<div className={classes.incomingCallWrapper} hidden={props.hidden}><Grid container>
        <Grid item xs={12}>
            <div className={classes.incomingCallHeader}>
                <CallerInfo
                    call = {incomingCall}
                />
            </div>
        </Grid>
        <Grid item xs={12}>
            <Description incomingCall={incomingCall}/>
        </Grid>
        <Grid item xs={12}>
            <Actions/>
        </Grid>
        <Grid item xs={12}>
            <IgnoreButton/>
        </Grid>
    </Grid></div>)
}

export default IncomingCallView
