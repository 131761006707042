import React from 'react'
import Button from 'button'
import Typography from 'typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    btn: {
        color: ({ color }: { color: string }) => color === 'white' ? '#fff!important' : undefined,
        backgroundColor: ({ color }: { color: string }) => color === 'white' ? 'transparent!important' : undefined,
        borderColor: ({ color }: { color: string }) => color === 'white' ? '#fff!important' : undefined,
        padding: '7px 20px 7px',
        borderWidth: '1px'
    }
}))

interface OutlinedBtnInterface {
    color: string
    icon: string
    iconPlacement: string
    remoteConfigID: string
}

/**
 *
 */
const OutlinedBtn = ({ color, icon, iconPlacement, remoteConfigID }: OutlinedBtnInterface): JSX.Element => {
    const classes = useStyles({ color })

    return (
        <Button className={classes.btn} variant='outlined' color={color} icon={icon} iconPlacement={iconPlacement}>
            <Typography variant='subtitle1' remoteConfigID={remoteConfigID} />
        </Button>
    )
}

export default OutlinedBtn
