/**
 * @param {object} theme
 */
const styles = theme => ({
    // loadingDiv: Object.assign({}, theme.loadingDiv, { background: 'white' }),
    loadingDiv: theme.loadingDiv,
    generalSettings: {
        position: 'relative',
        overflowY: 'auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '&.small': {
            height: 'calc(100% - 50px)'
        }
    },
    settingWrapper: {
        ...theme.personalSettingsApp.settingWrapper,
        padding: '50px 50px 100px',
        flexDirection: 'column',
        height: '100%',
        '&.hidden': {
            display: 'none'
        }
    },
    header: {
        background: theme.palette.secondary[-100],
        color: 'white',
        fontSize: 14,
        fontFamily: 'Montserrat-bold !important',
        fontWeight: 'bold',
        lineHeight: '18px',
        padding: 20,
        borderRadius: 2,
        textTransform: 'uppercase'
    },
    addressMenu: {
        marginLeft: 30
    },
    dropdownItemsWrapper: {
        maxHeight: 300,
        overflowY: 'auto'
    },
    menuItem: {},
    section: {
        display: 'flex',
        flexDirection: 'column'
    },
    sectionContent: {
        padding: '20px 25px 50px'
    },
    subsection: {
        display: 'flex',
        flexDirection: 'column',
        '&:not(:first-child)': {
            marginTop: 40
        },
        '&:not(:last-child)': {
            marginBottom: 40
        }
    },
    question: {
        fontSize: 16,
        marginBottom: 20,
        lineHeight: '27px',
        letterSpacing: -0.05,
        display: 'flex',
        columnGap: 5,
        '&.error': {
            color: '#EA0303',
            '& .em': {
                color: '#EA0303'
            }
        },
        '& .em': {
            fontFamily: 'Montserrat-bold',
            color: 'black',
            fontSize: 18,
            lineHeight: '26px'

        }
    },
    textField: {
        maxWidth: 300,
        flex: 1
    },
    businessFieldsWrapper: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 500,
        '& .address-row-2': {
            display: 'flex',
            margin: '25px 0',
            '& > *': {
                flex: 1,
                '&:first-child': {
                    flex: 2
                }
            }
        },
        '& .address-row-3': {
            display: 'flex',
            '& > *': {
                flex: 1,
                '&:last-child': {
                    flex: 2
                }
            }
        }
    },
    voiceFieldsWrapper: {
        display: 'flex',
        '& > *:first-child': {
            marginRight: 10
        }
    },
    meetingFieldsWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& > *:first-child': {
            marginRight: 10
        },
        '& .base-url': {
            fontSize: 16
        }
    },
    alert: {
        position: 'relative',
        padding: '25px 27px 27px 23px',
        fontSize: 12,
        fontWeight: 500,
        lineHeight: '19px',
        letterSpacing: 0.05,
        background: theme.palette.tertiary[-500],
        borderRadius: 5,
        width: 650,
        '&:first-child': {
            marginTop: 10,
            '&:not(:last-child)': {
                marginBottom: -10
            }
        },
        '&:last-child': {
            marginTop: 30
        },
        '& .x-icon': {
            position: 'absolute',
            top: 15,
            right: 15,
            width: 17.5,
            height: 17.5,
            color: theme.palette.tertiary[-100],
            cursor: 'pointer',
            '&:hover': {
                color: theme.palette.tertiary[0]
            }
        }
    },
    footer: {
        height: 83,
        background: theme.palette.secondary[-500],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: `2px solid ${theme.palette.secondary[-300]}`,
        '& button': {
            width: 150
        }
    },
    radioButtonsWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& > div': {
            display: 'flex',
            alignItems: 'center',
            '&:first-child': {
                marginRight: 30
            },
            '& > span': {
                fontSize: 14,
                lineHeight: '21px'
            }
        }
    },
    radioWrapper: {
        padding: 3,
        marginRight: 5
    },
    pdcMenu: {
        width: 285
    },
    pdcMenuItems: {
        maxHeight: 190,
        overflowX: 'hidden',
        overflowY: 'auto'
    }
})

export default styles
