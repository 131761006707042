import React, { useEffect, useState, useContext } from 'react'
import InvoiceStep from './InvoiceStep'
import PropTypes from 'prop-types'
import LoaderFull from 'loader-full'
import BrandsContext from '../contexts/BrandsContext'
import { useStepWizardAutoScrollUp } from '../hooks/useStepWizardAutoScrollUp'

/**
 * @param {object} props
 */
const BusinessPaymentStep = (props) => {
    const bizConfirmSavedData = props.passData.business_confirm
    const [confirm, setConfirm] = useState(bizConfirmSavedData || false)

    const { brandPayment, fetchBrandPaymentDetails, paymentInfoFetched } = useContext(BrandsContext)

    useStepWizardAutoScrollUp(paymentInfoFetched === true)

    useEffect(() => {
        props.update({ disableNextButton: () => !confirm })
    }, [confirm])

    // Runs when the next button is clicked
    useEffect(() => {
        if (props.saveId) props.update({ completed: true, gotoNext: true, saveData: confirm })
    }, [props.saveId])

    useEffect(() => {
        if (brandPayment === null) {
            fetchBrandPaymentDetails()
        }
    }, [])

    return (paymentInfoFetched
        ? (
            <InvoiceStep
                update={props.update}
                passData={props.passData}
                showConfirmation={props.showConfirmation}
                confirm={confirm}
                onChangeHandler={setConfirm}
                showAlert={props.showAlert}
                alertID='sms_enrollment_business_enrollment_payment_info'
                price={4.00}
                tax={0.60}
                payment={brandPayment}
            />
        )
        : (
            <LoaderFull text='Loading payment details...' styles={{ loaderFull: { left: '50%' } }} size='big'/>
        )
    )
}

BusinessPaymentStep.propTypes = {
    passData: PropTypes.object,
    update: PropTypes.func,
    saveId: PropTypes.number,
    showConfirmation: PropTypes.bool,
    showAlert: PropTypes.bool
}

export default BusinessPaymentStep
