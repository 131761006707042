import { NumberTypeOption, OnCallOption, OnNothingSelected, ScheduleOption } from './configure_util'
import { isValidPhoneNumber } from 'phone-numbers'

/***/
export interface ValidationErrors {
    [key: string]: boolean
}

interface ValidationInfo {
    isValid: boolean
    errors?: ValidationErrors
}

const validateScheduleRules = (configureNumberInstance, useSchedules, onlyCurrentTab: boolean): ValidationInfo => {
    const { props, state } = configureNumberInstance

    const validateRingUsersData = (scheduleName: string): boolean => {
        const scheduleState = state.onCallOptionGroups[scheduleName]
        if (scheduleState.forward[0].users.length === 0) return false
        if (scheduleState.forward[1].enabled && scheduleState.forward[1].users.length === 0) return false
        if (!scheduleState.assignVoicemailUser) return false
        return true
    }

    const validateMenuData = (scheduleName: string): boolean => {
        const scheduleState = state.onCallOptionGroups[scheduleName]
        // Menu greeting
        if (!scheduleState.menu.greeting.isValid()) return false
        // Menu actions/options
        const menuActions = scheduleState.menu.menuActions
        const actions = scheduleState.menu.actions
        const menuOptions = Object.keys(menuActions)
        if (menuOptions.length === 0) return false
        if (menuOptions.some((mo): boolean => {
            const menuActionValue = menuActions[mo]
            const menuActionValueSplit = menuActionValue.split('|')
            if ((!menuActionValueSplit[0] && actions[mo] === 'ring') || !menuActionValueSplit[1]) return true
            return false
        })) return false
        // Menu on nothing selected
        if (!scheduleState.menu.onNothingSelected.option) return false
        if (scheduleState.menu.onNothingSelected.option === OnNothingSelected.Forward) {
            if (scheduleState.menu.onNothingSelected.ringUsers.length === 0) return false
            if (!scheduleState.menu.onNothingSelected.voicemailUser) return false
        } else if (scheduleState.menu.onNothingSelected.option === OnNothingSelected.Voicemail) {
            if (!scheduleState.menu.onNothingSelected.voicemailUser) return false
        }
        return true
    }

    const validateSendToVoicemailData = (scheduleName: string): boolean => {
        const scheduleState = state.onCallOptionGroups[scheduleName]
        if (!scheduleState.assignVoicemailUser) return false
        return true
    }

    const validateForwardExternalData = (scheduleName: string): boolean => {
        const scheduleState = state.onCallOptionGroups[scheduleName]
        const { forwardExternal } = scheduleState
        if (!isValidPhoneNumber(forwardExternal.phoneNumber)) return false
        if (scheduleState.forward[1].enabled && scheduleState.forward[1].users.length === 0) return false
        if (!scheduleState.assignVoicemailUser) return false
        return true
    }

    const validateVirtualReceptionistData = (scheduleName: string): boolean => {
        const scheduleState = state.onCallOptionGroups[scheduleName]
        if (scheduleState.forward[1].enabled && scheduleState.forward[1].users.length === 0) return false
        const script = state.existingReceptionistScripts.find((x) => x.id === state.scriptId)
        if (script && !script.enabled) return false

        return true
    }

    const validateLiveReceptionistData = (scheduleName: string): boolean => {
        const scheduleState = state.onCallOptionGroups[scheduleName]
        const onCallOption = scheduleState.onCallOption
        if (!scheduleState.assignVoicemailUser && onCallOption !== OnCallOption.VirtualAnswer) return false
        if (scheduleState.forward[1].enabled && scheduleState.forward[1].users.length === 0 && onCallOption !== OnCallOption.VirtualAnswer) return false

        const script = state.existingReceptionistScripts.find((x) => x.id === state.scriptId)
        if (script && !script.enabled) return false

        return true
    }

    const validateScheduleData = (scheduleName: ScheduleOption): boolean => {
        const scheduleData = state.onCallOptionGroups[scheduleName]
        if ([ScheduleOption.LunchBreak, ScheduleOption.Holidays].includes(scheduleName) && scheduleData.sameAsClosedHours) return true
        const onCallOption = scheduleData.onCallOption
        if (onCallOption === OnCallOption.Forward) return validateRingUsersData(scheduleName)
        if (onCallOption === OnCallOption.Menu) return validateMenuData(scheduleName)
        if (onCallOption === OnCallOption.Voicemail) return validateSendToVoicemailData(scheduleName)
        if (onCallOption === OnCallOption.ForwardExternal) return validateForwardExternalData(scheduleName)
        if (onCallOption === OnCallOption.LiveAnswer) return validateLiveReceptionistData(scheduleName)
        if (onCallOption === OnCallOption.VirtualAnswer) return validateVirtualReceptionistData(scheduleName)
        if (onCallOption === OnCallOption.LiveAnswerPlus) return validateLiveReceptionistData(scheduleName)
        if (scheduleData.hasGreeting && !scheduleData.greeting.isValid()) return false
        return false
    }

    const activeSchedules = props.schedules.filter((s): boolean => s.status === 'on')
    const doesCompanyHaveSetSchedules = activeSchedules.length > 1 || (activeSchedules.length === 1 && !configureNumberInstance.isOpen24Schedule(activeSchedules))
    // If the company does not have set schedules then the rules the user sets are under during_schedule
    // and we should validate only that one

    const isValidOpenHours = (!onlyCurrentTab || ScheduleOption.OpenHours === state.selectedScheduleOption) ? validateScheduleData(ScheduleOption.OpenHours) : true
    const isValidAfterHours = (!onlyCurrentTab || ScheduleOption.ClosedHours === state.selectedScheduleOption) ? validateScheduleData(ScheduleOption.ClosedHours) || !doesCompanyHaveSetSchedules || !useSchedules : true
    const isValidateHolidays = (!onlyCurrentTab || ScheduleOption.Holidays === state.selectedScheduleOption) ? validateScheduleData(ScheduleOption.Holidays) || !doesCompanyHaveSetSchedules || !useSchedules : true
    const isValidateLunchBreak = (!onlyCurrentTab || ScheduleOption.LunchBreak === state.selectedScheduleOption) ? validateScheduleData(ScheduleOption.LunchBreak) || !doesCompanyHaveSetSchedules || !useSchedules : true
    const validationInfo: ValidationInfo = { isValid: isValidOpenHours && isValidAfterHours && isValidateHolidays && isValidateLunchBreak }
    if (!validationInfo.isValid) {
        validationInfo.errors = {}
        if (!isValidOpenHours) validationInfo.errors[ScheduleOption.OpenHours] = true
        if (!isValidAfterHours) validationInfo.errors[ScheduleOption.ClosedHours] = true
        if (!isValidateHolidays) validationInfo.errors[ScheduleOption.Holidays] = true
        if (!isValidateLunchBreak) validationInfo.errors[ScheduleOption.LunchBreak] = true
    }
    return validationInfo
}

/**
 * Validates the changes
 *
 * @param {object} configureNumberInstance - Instance of ConfigureNumber
 */
export const validate = (configureNumberInstance, onlyCurrentTab = false): ValidationInfo => {
    const validationInfo: ValidationInfo = { isValid: true, errors: {} }
    const { state } = configureNumberInstance
    if (!onlyCurrentTab && !state.smsForwardUser) {
        validationInfo.isValid = false
        validationInfo.errors = { assignTo: true }
    }
    const numberTypeOption = state.numberTypeOption
    if (!numberTypeOption) {
        validationInfo.isValid = false
        validationInfo.errors = { numberType: true }
        return validationInfo
    }
    if ([NumberTypeOption.MainCompanyNumber, NumberTypeOption.Other].includes(numberTypeOption)) {
        const useSchedules = Boolean(numberTypeOption === NumberTypeOption.MainCompanyNumber || state.useSchedules)
        const validationInfoMainCompanyNumber: ValidationInfo = validateScheduleRules(configureNumberInstance, useSchedules, onlyCurrentTab)
        if (!validationInfoMainCompanyNumber.isValid) {
            validationInfo.isValid = false
            Object.assign(validationInfo.errors, validationInfoMainCompanyNumber.errors)
        }
    } else if (numberTypeOption === NumberTypeOption.UserNumber) {
        // Handled above in smsForwardUser since the same is used for the direct line
        if (!state.smsForwardUser) validationInfo.isValid = false
    } else if (numberTypeOption === NumberTypeOption.FaxLine) {
        // Handled above in smsForwardUser since the same is used for the fax line
        if (!state.smsForwardUser) validationInfo.isValid = false
    }
    return validationInfo
}
