const styles = theme => ({
	startNewPanel: {
		display:		'flex',
		flexDirection:	'column',
		width:			'100%',
		height:			'100%',
		minWidth:		0,
		overflowY:		'auto',
		'& .start-new-header': {
			padding:	'18px 20px 0',
			display:	'flex',
			alignItems:	'center'
		},
		'& .recipients-input-wrapper': {
			boxShadow:		theme.palette.primary.flatBottomShadow,
			position:		'relative',
			flexDirection:	'column',
			padding:		'17px 20px'
		},
		'& .sending-as-wrapper': {
			boxShadow:	theme.palette.primary.flatBottomShadow,
			padding:	'8px 20px',
			display:	'flex',
			alignItems:	'center',
			'& .as-text': {
				color:			theme.palette.primary.main,
				fontSize:		14,
				fontWeight:		'bold',
				marginRight:	10
			}
		},
		'& .contacts-title': {
			margin:		'20px 50px 0',
			fontSize:	24,
			fontWeight:	500
		},
		'& .start-chat-button': {
			width:		116,
			marginTop:	25,
			marginLeft:	45,
			cursor:		'pointer',
			whiteSpace:	'nowrap',
			'&.not-allowed': {
				cursor:	'not-allowed'
			}
		},
		'&.small-view': {
			'& .sending-as-wrapper': {
				padding:	'11px 30px'
			},
			'& .recipients-input-wrapper': {
				padding:	'5px 30px'
			},
			'& .contacts-title': {
				margin:		'20px 30px'
			},
			'& .start-chat-button': {
				marginTop:	20,
				marginLeft:	30
			}
		}
	},	
	newConversationTitleTheme: {
		fontSize:	18,
		fontWeight:	400,
		marginLeft:	15
	},
	errorMessage: {
		marginTop:		25,
		marginLeft:		45,
		fontWeight:		'bold',
		color:			'#df1d08',
		fontSize:		'14px',
		lineHeight:		'1.29',
		letterSpacing:	'-0.1px',
		'&.small-view': {
			marginTop:	20,
			marginLeft:	30
		}
	},
	sendTo: {
		marginTop:		25,
		marginLeft:		45,
		fontSize:		'14px',
		fontWeight:		500,
		lineHeight:		1.29,
		display:		'flex',
		alignItems:		'center',
		color:			'#364047',
		cursor:			'pointer',
		'&.valid-number': {
			fontWeight:	600,
			color:		theme.palette.primary.main
		},
		'& img': {
			width:			'26px',
			height:			'22px',
			marginRight:	'10px'
		},
		'&.small-view': {
			marginTop:	20,
			marginLeft:	30
		}
	}
})

export default styles