// let betterSettings = ['Music and messages', 'Plan', 'Add ons', 'Credit card', 'Invoices', 'Preferences', 'Security', 'Block list'].map(e => {
// let defaultSettings = ['Music on hold', 'Voicemail', 'Plan & Add ons', 'Credit card', 'Invoices', 'Security', 'Block list'].map(e => {
let defaultSettings = ['Voicemail', 'Plan'/* & Add ons'*/, 'Credit card', 'Invoices', 'Security', 'Block list'].map(e => {
	return {
		text:	e,
		id:		e.toLowerCase().split(' ').join('').split('&').join('and')
	}
})

export function currentSetting(state = null, action) {
	switch (action.type) {
		case 'SWITCH_SETTING':
			let setting			= action.setting
			let pathnameSplit	= window.location.pathname.split('/').filter(e => e)
			let extensionId		= parseInt(pathnameSplit[0].substring(1))
			if (setting) {
				let settingName	= action.setting.text.split(' ').join('').split('&').join('And')
				window.history.replaceState(`Setting: ${action.setting.id}`, 'Setting Switched', `/e${extensionId}/settings/${settingName}`)
			} else {
				window.history.replaceState(`Selector`, 'Opened Selector', `/e${extensionId}/settings`)
			}
			return action.setting
		default:
			return state
	}
}

export function settings(state = defaultSettings, action) {
	switch (action.type) {
		default:
			return state
	}
}

export function currentPrimaryCard(state=null, action) {
	switch (action.type) {
		case 'UPDATE_CURRENT_PRIMARY_CARD':
			return action.currentPrimaryCard
		default:
			return state
	}
}

export function invoices(state=[], action) {
	switch (action.type) {
		case 'UPDATE_INVOICES':
			return action.invoices
		default:
			return state
	}
}

export function voicemail(state=null, action) {
	switch (action.type) {
		case 'UPDATE_VOICEMAIL':
			return action.voicemail
		default:
			return state
	}
}

export function security(state={email:'', phoneNumber: ''}, action) {
	switch (action.type) {
		case 'UPDATE_EMAIL':
			return {email: action.email, phoneNumber: state.phoneNumber}
		case 'UPDATE_PHONE_NUMBER':
			return {email: state.email, phoneNumber: action.phoneNumber}
		default:
			return state
	}
}