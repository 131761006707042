<template>
  <w-loader v-if="loading"/>
  <div v-else>
    <w-form @changed="may_submit = true" @submit="submitted" data-discard="true">
      <FormInput :label="l.t('app.subject', 'Subject')">
        <w-text-field v-model="schedule.subject" :required="true" hide-details='auto' data-test-id="user-schedule-subject-input"/>
      </FormInput>
      <FormInput :label="l.t('video.agenda', 'Agenda')">
        <w-textarea
          v-model="schedule.agenda"
          auto-grow
          rows="1"
          data-test-id="user-schedule-agenda-input"
        ></w-textarea>
      </FormInput>
      <FormInput :label="l.t('app.start-time', 'Start time')">
        <DatetimePicker
          :datetime="schedule.start"
          @input="schedule.start = $event"
          :max="maxStart"
          :min="minStart"
          date_nudge_top="-100"
          :required="true"
          data-test-id="user-schedule-start-time-input"
        />
      </FormInput>
      <FormInput :label="l.t('app.timezone', 'Timezone')">
        <TimezoneSelector
          :key="extension ? extension.timezone : null"
          :value="schedule.time_zone ? schedule.time_zone : extension ? extension.timezone : null"
          :rules="[$validation.required()]"
          @input="(value) => (schedule.time_zone = value)"
          data-test-id="user-schedule-timezone-input"
        />
      </FormInput>
      <FormInput :label="l.t('app.duration', 'Duration')">
        <w-text-field v-model="schedule.duration" type="number" min="0" hide-details='auto' data-test-id="user-schedule-duration-input"/>
      </FormInput>
      <FormInput :label="l.t('video.auto-record', 'Auto record')">
        <w-radio-group
          v-model="schedule.auto_record"
          row
          hide-details='auto'
        >
          <v-radio
            :label="l.t('app.none', 'None')"
            value="none"
            data-test-id="user-schedule-none-radio"
          ></v-radio>
          <v-radio
            :label="l.t('app.audio', 'Audio')"
            value="audio"
            data-test-id="user-schedule-audio-radio"
          ></v-radio>
          <v-radio
            :label="l.t('app.video', 'Video')"
            value="video"
            data-test-id="user-schedule-video-radio"
          ></v-radio>
        </w-radio-group>
      </FormInput>
      <FormInput :label="l.t('video.auto-transcribe', 'Auto transcribe')">
        <w-switch v-model="schedule.auto_transcribe" :label="l.t('app.enable', 'Enable')" hide-details='auto' data-test-id="user-schedule-transcribe-switch"/>
      </FormInput>
      <FormInput :label="l.t('video.one-time-access-code', 'One time access code')">
        <w-switch v-model="schedule.one_time_access_code" :label="l.t('app.enable', 'Enable')" hide-details='auto' data-test-id="user-schedule-one-time-access-switch"/>
      </FormInput>
      <FormInput :label="`${l.t('video.mute-mode', 'Mute Mode')} (${l.t('video.mute-mode-explanation', 'initial participant mute settings')})`">
        <w-radio-group
          v-model="schedule.mute_mode"
          column
          hide-details='auto'
        >
          <v-radio
            :label="`${l.t('app.conversation', 'Conversation')}: ${ l.t('video.conversation-explanation', 'all muted') }`"
            value="conversation"
            data-test-id="user-schedule-conversation-radio"
          ></v-radio>
          <v-radio
            :label="`${l.t('video.qa', 'Q&A')}: ${ l.t('video.qa-explanation', 'all muted, but can unmute')}`"
            value="q&a"
            data-test-id="user-schedule-qa-radio"
          ></v-radio>
          <v-radio
            :label="`${l.t('video.presentation', 'Presentation')}: ${ l.t('video.presentation-explanation', 'all muted and can\'t unmute')}`"
            value="presentation"
            data-test-id="user-schedule-presentation-radio"
          ></v-radio>
        </w-radio-group>
      </FormInput>
      <SectionTitle>
        {{ l.t('app.participants', 'Participants') }}
      </SectionTitle>
      <div v-for="(participant, i) in schedule.participants" class="mt-5" :key="participant.id">
        <h4 class="w-body-2 ml-1">
          {{ l.t('video.address', 'Participant') }}
          <span v-if="schedule.participants.length > 1">{{ i+1 }}</span>&nbsp;
          <RemoveFromListButton @click="$delete(schedule.participants, i)" class="my-auto" />
        </h4>
        <FormInput :label="l.t('app.email', 'Email')">
          <w-text-field type="email" v-model="participant.email" :required="true" hide-details='auto' :data-id="`video-participant-${i}-email-input`"/>
        </FormInput>
        <FormInput :label="l.t('app.name', 'Name')">
          <w-text-field v-model="participant.name" hide-details='auto' :data-id="`video-participant-${i}-name-input`"/>
        </FormInput>
        <FormInput :label="l.t('app.timezone', 'Timezone')">
          <TimezoneSelector
              v-model="participant.time_zone"
              :data-id="`video-participant-${i}-timezone-selector`"
          />
        </FormInput>
      </div>
      <FormInput>
              <AddToListButton @click="schedule.participants.push({
                        email: null,
                        name: null,
                        time_zone: null,
                      })" class="my-5" data-test-id="video-add-participant-btn">
                {{ l.t("video.add-participant", "Add participant") }}
              </AddToListButton>
      </FormInput>
      <FormInput>
        <w-btn :disabled="!may_submit" type="submit" class="primary" data-test-id="video-user-schedule-submit-btn">
          {{ l.t("app.save", "Save") }}
        </w-btn>
      </FormInput>
    </w-form>
  </div>
</template>

<script>
import l from '../../libs/lang';
import FormInput from '../elements/form/FormInput.vue';
import SectionTitle from '../elements/SectionTitle.vue';
import AddToListButton from '../elements/AddToListButton.vue';
import TimezoneSelector from '../elements/TimezoneSelector.vue';
import DatetimePicker from '../elements/form/DatetimePicker.vue';
import RemoveFromListButton from '../elements/RemoveFromListButton.vue';

export default {
  props: ['user', '_schedule', 'extension'],
  components: {
    FormInput,
    SectionTitle,
    DatetimePicker,
    AddToListButton,
    TimezoneSelector,
    RemoveFromListButton,
  },
  data() {
    const maxStart = new Date();
    maxStart.setMonth(maxStart.getMonth() + 18);
    return {
      l,
      loading: false,
      schedule: null,
      maxStart: maxStart.toISOString().substring(0, 16),
      minStart: new Date().toISOString().substring(0, 16),
      may_submit: false,
    };
  },
  created() {
    this.$data.schedule = this.$props._schedule;
  },
  methods: {
    async submitted(e) {
      if (e) e.preventDefault();
      if (this.$data.schedule.start && this.$data.schedule.start.length === 16) this.$data.schedule.start += ':00';
      if (this.$data.schedule.duration && !Number.isNaN(this.$data.schedule.duration)) this.$data.schedule.duration = parseInt(this.$data.schedule.duration);
      if (this.$data.schedule.participants && this.$data.schedule.participants.length) {
        this.$data.schedule.participants = this.$data.schedule.participants.map((x) => {
          if (!x.time_zone) x.time_zone = '';

          return x;
        });
      }
      this.$emit('submitted', this.$data.schedule);
    },
  },
};
</script>
