var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blocked-calls"},[_c('Submenu',{key:("" + (_vm.$route.name)),attrs:{"routes":{
        'blockedCalls.index': {
          title: _vm.$lang.t('app.blocked-numbers', 'Blocked numbers')
        },
        'blockedCalls.create': {
          title: _vm.$lang.t('blocked-calls.block-numbers', 'Block numbers')
        },
        'blockedCalls.recent': {
          title:_vm.$lang.t('blocked-calls.recent-calls', 'Recent calls')
        },
    }}}),_vm._v(" "),(_vm.$route.name === 'blockedCalls.index')?_c('BlockedNumbersIndex',{attrs:{"data-test-id":"bc-index"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'blockedCalls.create')?_c('BlockedNumbersCreate',{attrs:{"data-test-id":"bc-create"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'blockedCalls.recent')?_c('RecentCalls',{attrs:{"data-test-id":"bc-recent"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }