import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5628%3A783
 *
 * @param {SvgIconProps} props - svg props
 */
export const VolumeOffIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M16.5 12C16.5 10.5199 15.7868 9.21465 14.6878 8.39408C14.3899 8.17171 14 8.40733 14 8.77902V10.18L16.45 12.63C16.48 12.43 16.5 12.22 16.5 12ZM19 12C19 12.94 18.8 13.82 18.46 14.64L19.97 16.15C20.63 14.91 21 13.5 21 12C21 7.93838 18.3073 4.50715 14.6056 3.38988C14.2985 3.29719 14 3.53539 14 3.85618V4.91712C14 5.13834 14.1462 5.33155 14.3545 5.40596C17.0609 6.37253 19 8.96111 19 12ZM4.62355 3.35355C4.42829 3.15829 4.11171 3.15829 3.91645 3.35355L3.35355 3.91645C3.15829 4.11171 3.15829 4.42829 3.35355 4.62355L7.73 9H3.5C3.22386 9 3 9.22386 3 9.5V14.5C3 14.7761 3.22386 15 3.5 15H6.17157C6.70201 15 7.21071 15.2107 7.58579 15.5858L11.1464 19.1464C11.4614 19.4614 12 19.2383 12 18.7929V13.27L16.25 17.52C15.6762 17.9654 15.0437 18.33 14.3525 18.5826C14.1453 18.6583 14 18.851 14 19.0716V20.1352C14 20.4556 14.2975 20.6937 14.6041 20.601C15.7459 20.2557 16.7869 19.6827 17.69 18.95L19.3764 20.6447C19.5716 20.8408 19.8888 20.8412 20.0844 20.6456L20.6464 20.0836C20.8417 19.8883 20.8417 19.5717 20.6464 19.3764L12 10.73L4.62355 3.35355ZM12 5.20711C12 4.76165 11.4614 4.53857 11.1464 4.85355L9.91 6.09L12 8.18V5.20711Z' fill={color}/>
        </SvgIcon>
    )
}

export default VolumeOffIcon
