import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5874%3A6263
 *
 * @param {SvgIconProps} props - svg props
 */
export const BackspaceIcon = (props: SvgIconProps & { secondaryColor?: string }): JSX.Element => {
    const color = props.color || 'currentColor'
    const secondaryColor = props.secondaryColor || 'white'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    delete svgProps.secondaryColor
    return (
        <SvgIcon {...svgProps} width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.0066 5.94507C10.5754 5.31676 11.3833 4.95831 12.2308 4.95831H28.9997C31.7611 4.95831 33.9997 7.19689 33.9997 9.95831V24.0416C33.9997 26.8031 31.7611 29.0416 28.9997 29.0416H12.2308C11.3833 29.0416 10.5754 28.6832 10.0066 28.0549L1.2149 18.3421C0.52525 17.5803 0.52525 16.4197 1.21489 15.6578L10.0066 5.94507Z" fill={color}/>
            <path d="M25.4707 23.0213L26.5611 21.9308C26.7563 21.7355 26.7563 21.419 26.5611 21.2237L22.3382 17L26.5611 12.7763C26.7563 12.581 26.7563 12.2645 26.5611 12.0692L25.4707 10.9787C25.2754 10.7833 24.9588 10.7833 24.7635 10.9787L20.5408 15.2023L16.318 10.9787C16.1227 10.7833 15.8061 10.7833 15.6108 10.9787L14.5204 12.0692C14.3252 12.2645 14.3252 12.581 14.5204 12.7763L18.7434 17L14.5204 21.2237C14.3252 21.419 14.3252 21.7355 14.5204 21.9308L15.6108 23.0213C15.8061 23.2167 16.1227 23.2167 16.318 23.0213L20.5408 18.7977L24.7635 23.0213C24.9588 23.2167 25.2754 23.2167 25.4707 23.0213Z" fill={secondaryColor}/>
        </SvgIcon>
    )
}

export default BackspaceIcon
