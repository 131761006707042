/**
 * @param {object} state
 * @param {object} action
 */
export function smallView (state = true, action) {
    switch (action.type) {
            case 'SET_SMALL_VIEW':
                return action.smallView
            default:
                return state
    }
}
