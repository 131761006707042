import { formatPhoneNumber } from 'formatters'
import l from 'lang'
import CallReport from './CallReport'

/**
 *
 */
export default class PerPhoneNumber extends CallReport {
    /**
     *
     * @param {object} session
     * @param {object} component
     */
    constructor (session, component) {
        super(
            session,
            component,
            [
                'avg_amount',
                'max_amount',
                'total_amount',
                'count_incoming',
                'count_outgoing',
                'total'
            ],
            'per-number',
            'number'
        )
        this.numbers = []
        this.headers = Object.keys(this.fields).reduce((a, c) => {
            a[c] = this.fields[c].translation
            return a
        }, {
            number: l.t('app.number', 'Number')
        })
    }

    /**
     *
     * @param {Array} items
     * @returns
     */
    prepare_items (items) {
        this.numbers = []
        const keys = Object.keys(this.fields)
        const stats = {}
        for (const key of keys) {
            stats[key] = []
            for (const item of items) {
                const number = formatPhoneNumber(item.number)
                this.numbers.push(number)
                stats[key].push({
                    number,
                    value: item[key]
                })
            }
        }
        this.numbers = [...new Set(this.numbers)]
        return stats
    }

    /**
     *
     * @param {Array} items
     * @returns
     */
    prepare_csv_values (items) {
        const numbers = items[Object.keys(items)[0]].map((x) => x.number)
        const data = numbers.map((number) => {
            const data_item = {}
            data_item.number = number.replace(/-/g, '')
            for (const prop of Object.keys(this.fields)) {
                const item = items[prop] ? items[prop].find((x) => x.number === number) : false
                const value = item
                    ? this.formatters[this.fields[prop].type](item.value)
                    : '/'
                data_item[prop] = value
            }
            return data_item
        })

        return data
    }
}
