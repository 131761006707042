<template>
    <div data-test-id="advanced-tools">
        <PageTitle>
            {{ l.t('app.advanced-tools', 'Advanced tools') }}
        </PageTitle>
        <v-container fluid class="pa-0">
            <v-row>
                <v-col
                    v-for="app in apps"
                    :key="app.link"
                    md="4"
                >
                    <a
                        :href="app.link"
                        class="primary--text app-title"
                        target="_blank"
                        :data-test-id="`advanced-tools-${app.link}-link`"
                    >
                        {{ app.title }} <v-icon color="primary">mdi-chevron-right mdi-24px</v-icon>
                    </a>
                    <p class="w-body-2 mt-4" :data-test-id="`advanced-tools-${app.link}-description`">{{ app.description }}</p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import PageTitle from '../elements/PageTitle.vue';

  export default {
    components: { PageTitle },
    data() {
      return {
        l,
        apps: [
            {
                link: 'https://qb.pdc-partners.xyz/',
                title: l.t('tools.queue-builder', 'Queue Builder'),
                description: l.t('tools.queue-builder-description', 'The Phone.com available Queues provide basic queue functionality to allow for quick and straightforward configuration. The Queue Builder complements the primary queues and allows the creation of more sophisticated simulated queue policies.'),
            },
            {
                link: 'https://cloner.pdc-partners.xyz/',
                title: l.t('tools.account-cloner', 'Account Replicator'),
                description: l.t('tools.cloner-description', 'Account Replicator lets you export a complete call flow starting from a number or an extension and replicate that setup in the same account or on another one.'),
            },
            {
                link: 'https://api-management.cit-phone.com/',
                title: l.t('tools.api-management-panel', 'API Management Tool'),
                description: l.t('tools.api-cp-description', 'Tool for advanced use of the Phone.com API - it allows to configure events, provides access to API usage statistics, and more.'),
            }
        ],
      };
    }
  };
</script>

<style scoped>
    .app-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
    }
</style>
