import React, { useContext, useEffect, useState } from 'react'
import Typography from 'typography'
import styles from '../styles'
import { makeStyles, Paper } from '@material-ui/core'

import tone0 from './tones/dtmf-0.mp3'
import tone1 from './tones/dtmf-1.mp3'
import tone2 from './tones/dtmf-2.mp3'
import tone3 from './tones/dtmf-3.mp3'
import tone4 from './tones/dtmf-4.mp3'
import tone5 from './tones/dtmf-5.mp3'
import tone6 from './tones/dtmf-6.mp3'
import tone7 from './tones/dtmf-7.mp3'
import tone8 from './tones/dtmf-8.mp3'
import tone9 from './tones/dtmf-9.mp3'
import toneH from './tones/dtmf-hash.mp3'
import toneS from './tones/dtmf-star.mp3'
import { PdcCallContext } from 'pdc-calls'
import { FeatureEventsContext } from 'providers/src'

interface Props {
    enableDTMF?: boolean
    onClick: (char: string) => void
    enableKeyboardDTMF?: boolean
}

interface KeyProps {
    char: string
    onMouseDown: (e, char: string) => void
    onMouseUp: (e, char: string) => void
}

const useStyles = makeStyles(styles)

const numberLettersMap = { 1: ' ', 2: 'ABC', 3: 'DEF', 4: 'GHI', 5: 'JKL', 6: 'MNO', 7: 'PRQS', 8: 'TUV', 9: 'WXYZ', 0: '+', '*': '', '#': '' }
const validDTMFChars = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '#', '*']

const DTMFSounds = (): JSX.Element => (
    <>
        <audio id='sound0' style={{ display: 'none' }}><source src={tone0} type='audio/mp3'/></audio>
        <audio id='sound1' style={{ display: 'none' }}><source src={tone1} type='audio/mp3'/></audio>
        <audio id='sound2' style={{ display: 'none' }}><source src={tone2} type='audio/mp3'/></audio>
        <audio id='sound3' style={{ display: 'none' }}><source src={tone3} type='audio/mp3'/></audio>
        <audio id='sound4' style={{ display: 'none' }}><source src={tone4} type='audio/mp3'/></audio>
        <audio id='sound5' style={{ display: 'none' }}><source src={tone5} type='audio/mp3'/></audio>
        <audio id='sound6' style={{ display: 'none' }}><source src={tone6} type='audio/mp3'/></audio>
        <audio id='sound7' style={{ display: 'none' }}><source src={tone7} type='audio/mp3'/></audio>
        <audio id='sound8' style={{ display: 'none' }}><source src={tone8} type='audio/mp3'/></audio>
        <audio id='sound9' style={{ display: 'none' }}><source src={tone9} type='audio/mp3'/></audio>
        <audio id='sound#' style={{ display: 'none' }}><source src={toneH} type='audio/mp3'/></audio>
        <audio id='sound*' style={{ display: 'none' }}><source src={toneS} type='audio/mp3'/></audio>
    </>
)

const Key = (props: KeyProps): JSX.Element => {
    const { char, onMouseDown, onMouseUp } = props
    return (
        <div
            data-testid = {`dialpad${char}`}
            className = 'key-item noselect disable-dragging'
            onMouseDown = {(e) => onMouseDown(e, char)}
            onMouseUp = {(e) => onMouseUp(e, char)}
            onContextMenuCapture = {(e) => e.preventDefault()}
        >
            <div className={`item-char ${char === '*' ? 'star' : ''}`}>
                <Typography variant='h4'>{char}</Typography>
            </div>
            <div className={`item-letters ${numberLettersMap[char] === '+' ? 'plus' : ''}`}>
                <Typography variant='overline'>{numberLettersMap[char]}</Typography>
            </div>
        </div>
    )
}

/***/
const Dialpad = (props: Props): JSX.Element => {
    const keyGroups = [['1', '2', '3'], ['4', '5', '6'], ['7', '8', '9'], ['*', '0', '#']]
    const classes = useStyles()
    const PdcCall: any = useContext(PdcCallContext)
    const featureEventsContext = useContext(FeatureEventsContext)

    const handleKeyboardEvent = (event) => {
        const key = event.key
        sendDTMF(key)
    }
    const [keyboardListener, setKeyboardListener] = useState([handleKeyboardEvent])

    const onClick = char => {
        sendDTMF(char)
        props.onClick(char)
    }

    const sendDTMF = (char: string) => {
        if (props.enableDTMF && validDTMFChars.includes(char)) {
            PdcCall.sendDTMF(`${char}`)
            const domElement = document.getElementById(`sound${char}`) as HTMLAudioElement
            if (domElement) {
                domElement.pause()
                domElement.currentTime = 0
                domElement.play()
            }
        }
    }

    const onMouseUp = (e, char: string) => {
        const clickType = {
            0: 'left',
            1: 'middle',
            2: 'right',
            3: 'back',
            4: 'forward'
        }[e.button] || e.button
        featureEventsContext.pushEvent({ appName: 'softphone', key: `dialpad-${char}-click`, value: `${clickType}-click` })
        if (e.button === 0) onClick(char)
    }

    useEffect(() => {
        if (props.enableKeyboardDTMF && keyboardListener.length < 1) {
            const listener = [handleKeyboardEvent]
            setKeyboardListener(listener)
            document.addEventListener('keyup', listener[0])
        } else {
            document.removeEventListener('keyup', keyboardListener[0])
            setKeyboardListener([])
        }
    }, [props.enableKeyboardDTMF])

    return (<>
        <Paper square elevation={0} classes={{ root: `${classes.dialpad}` }}>
            {keyGroups.map((keyGroup, i: number) => (
                <div key={i} className='dialpad-row'>
                    {keyGroup.map(key => (
                        <Key
                            key = {key}
                            char = {key}
                            onMouseDown = {() => null}
                            onMouseUp = {onMouseUp}
                        />
                    ))}
                </div>
            ))}
        </Paper>
        <DTMFSounds/>
    </>)
}

export default Dialpad
