import React, { Component } from 'react'
import BackButton from 'back-button'
import Dialog from '@material-ui/core/Dialog'
import Switch from '@material-ui/core/Switch'
import AddIcon from '@material-ui/icons/Add'
import ConfirmModal from 'confirm-modal'
import Checkmark from 'checkmark'
import api from '../../util/api_v5'
import { WebArrowTooltip } from 'tooltips'
import { formatPhoneNumber } from 'phone-numbers'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	header:			theme.settingsApp.header,
	settingWrapper:	theme.settingsApp.settingWrapper,
	title: {
		fontSize:		20,
		marginBottom:	10,
		fontWeight:		'bold'
	},
	planBox: {
		display:		'flex',
		flexDirection:	'column',
		border:			'1px solid gray',
		borderRadius:	10,
		padding:		20,
		maxWidth:		440,
		boxShadow:		'0px 0px 6px gray',
		'&:not(:last-child)': {
			marginBottom: 15,
		}
	},
	boxHeader: {
		display:		'flex',
		justifyContent:	'space-between'
	},
	boxTitle: {
		fontSize:	24,
		fontWeight:	500
	},
	boxBody: {
		'& .price': {
			fontSize:	20,
			color:		theme.settingsApp.plan.priceColor
		},
		'& .disclaimer': {
			fontSize:	18,
			color:		theme.settingsApp.plan.disclaimerColor
		},
		'& .phone-number': {
			fontSize:	18,
			color:		theme.settingsApp.plan.priceColor
		}
	},
	planButton: {
		border:			'1px solid gray',
		borderRadius:	4,
		padding:		'8px 12px',
		fontSize:		18,
		fontWeight:		'bold',
		cursor:			'pointer',
		lineHeight:		1,
		'&:not(.selected)': {
			backgroundColor:	'#0A40A1',
			color:				'white'
		}
	},
	dialog: {
		padding:		15,
		borderRadius:	15
	},
	dialogTitle: {
		fontSize:		24,
		fontWeight:		'bold',
		color:			'gray',
		textAlign:		'center',
		marginBottom:	25
	},
	emphasizedText: {
		color:		'#0840a1',
		fontWeight:	'bold'
	},
	addOnsList: {
		display:		'flex',
		flexDirection:	'column',
		justifyContent:	'space-between',
		maxWidth:		440,
	},
	addOnItem: {
		border:			'1px solid gray',
		borderRadius:	10,
		padding:		'5px 20px',
		boxShadow:		'0px 0px 6px gray',
		display:		'flex',
		alignItems:		'center',
		'&:not(:last-child)': {
			marginBottom: 20
		},
		'&>:not(:last-child)': {
			marginRight: 20
		}
	},
	buttonWrapper: {
		position:		'relative',
		'& .checkmark': {
			position:	'absolute',
			top:		2,
			left:		6,
			fontSize:	0.6,
			zIndex:		1
		}
	},
	mainContent: {
		display:		'flex',
		flex:			1,
		flexDirection:	'column',
		'& .title': {
			fontSize:	20,
			fontWeight:	'bold'
		},
		'& .disclaimer': {
			fontSize:	12,
			color:		'gray',
			fontWeight:	600,
			lineHeight:	1.2
		}
	},
	price: {
		display:		'flex',
		flexDirection:	'column',
		width:			62,
		alignItems:		'center',
		'& .value': {
			fontSize:	20,
			fontWeight:	'bold'
		},
		'& .description': {
			fontSize:	11,
			color:		'gray',
			textAlign:	'center',
			'& span': {
				whiteSpace: 'nowrap'
			}
		}
	},
	addIcon: {
		color:		'#0A40A1',
		fontSize:	40,
		cursor:		'pointer'
	},
	toggleButtonSecondary: {
		color: '#ffffff !important',
	},
	toggleButtonTrackOn:{
		backgroundColor:	'#0A40A1 !important',
		opacity:			'1 !important'
	},
	switchRoot: {
		width:			38,
		height:			18,
		padding:		0,
		display:		'flex',
		borderRadius:	20
	},
	switchBase: {
		padding:	1,
		color:		theme.palette.grey[500],
		'&$checked': {
			transform:	'translateX(12px)',
			color:		theme.palette.common.white,
			'& + $track': {
				opacity:			1,
				backgroundColor:	theme.palette.primary.main,
				borderColor:		theme.palette.primary.main,
			},
		},
	},
	switchThumb: {
		width: 16,
		height: 16,
		boxShadow: 'none',
	}
})

class Plan extends Component {

	state = {
		showDialog:			false,
		crEnabled:			window.V5PHONECOM.features.has('call_recording'), // Call Recording Enabled
		pmohEnabled:		window.V5PHONECOM.features.has('premium_music_on_hold'), // Premium Music On Hold Enabled
		confirmModalState:	{}
	}

	componentDidMount() {
		this.props.onLoaded()
	}

	openChangePlan = () => this.setState({showDialog: true})

	closeDialog = () => this.setState({showDialog: false})

	renderPlanBox = options => {
		const { classes } = this.props
		return (
			<div className={classes.planBox}>
				<div className={classes.boxHeader}>
					<span className={classes.boxTitle}>{options.title}</span>
					{/*<div*/}
						{/*className	= {`${classes.planButton} ${options.button.selected ? 'selected' : ''}`}*/}
						{/*onClick		= {options.button.onClick}*/}
					{/*>*/}
						{/*{options.button.text}*/}
					{/*</div>*/}
				</div>
				<div className={classes.boxBody}>
					<div className='price'>{options.price}</div>
					{options.phoneNumber ? <div className='phone-number'>Phone number: {options.phoneNumber}</div> : null}
					<div className='disclaimer'>{options.disclaimer}</div>
				</div>
			</div>
		)
	}

	renderPlanSection = () => {
		const { classes } = this.props
		let phoneNumber = formatPhoneNumber(window.V5PHONECOM.phone_number[0])
		return (
			<>
				{this.renderPlanBox({
					title:	<span><span className={classes.emphasizedText}>Good</span> Plan</span>,
					button:	{
						text:		'UPGRADE',
						selected:	true,
						onClick:	this.openChangePlan
					},
					phoneNumber: phoneNumber,
					price:		<span><strong>$14.99</strong> per month</span>,
					disclaimer:	'Includes calls, voicemails and messages.'
				})}

				<Dialog classes={{paper: classes.dialog}} onClose={this.closeDialog} open={this.state.showDialog}>
					<div className={classes.dialogTitle}>Change your plan</div>
					{this.renderPlanBox({
						title:	<span><span className={classes.emphasizedText}>Good</span> Plan</span>,
						button:	{
							text:		'YOUR CURRENT PLAN',
							selected:	true,
							onClick:	() => {}
						},
						price:		<span><strong>$14.99</strong> per month</span>,
						disclaimer:	'Includes unlimited calls, voicemails and messages.'
					})}
					{this.renderPlanBox({
						title:	<span><span style={{color: 'blue', fontWeight: 'bold'}}>Better</span> Plan</span>,
						button:	{
							text:		'CHOOSE THIS PLAN',
							selected:	false,
							onClick:	() => {}
						},
						price:		<span><strong>$22.99</strong> per month</span>,
						disclaimer:	<span>2 included numbers, all <span className={classes.emphasizedText}>Good</span> plan features, plus.</span>
					})}
				</Dialog>
			</>
		)
	}

	renderAddOn = options => {
		const { classes } = this.props
		return (
			<div className={classes.addOnItem}>
				<div className={classes.buttonWrapper}>{options.button}</div>
				<div className={classes.mainContent}>
					<span className='title'>{options.title}</span>
					<span className='disclaimer'>{options.disclaimer.map((d, i) => <span key={i}>{i ? <br/> : null}<span>{d}</span></span>)}</span>
				</div>
				<div className={classes.price}>
					<span className='value'>{options.price.value}</span>
					<span className='description'>{options.price.description.map((d, i) => <span key={i}>{i ? <br/> : null}<span>{d}</span></span>)}</span>
				</div>
			</div>
		)
	}

	handleCheckedChange = (event, name) => {
		let isEnabled = event.target.checked
		let text = name === 'crEnabled' ? 'Call recording' : name === 'pmohEnabled' ? 'Premium music on hold' : ''
		this.setState({confirmModalState: {name, isEnabled, text}})
	}

	confirmChange = (isEnabled, name) => {
		console.log('Should', isEnabled ? 'enable' : 'disable', name)
		let code = name === 'pmohEnabled' ? 19044 : 11838
		if (isEnabled) {
			api.enableFeatures(code)
		} else {
			api.disableFeatures(code)
		}
		this.setState({ ...this.state, [name]: isEnabled, confirmModalState: {} })
	}

	renderConfirmModal = () => {
		let confirmModalState	= this.state.confirmModalState
		let isEnabled			= confirmModalState.isEnabled
		let changeStatus		= isEnabled ? 'enable' : 'disable'
		let title = `Are you sure you want to ${changeStatus} ${confirmModalState.text}?`
		return <ConfirmModal
			title			= {title}
			isShown			= {confirmModalState.isEnabled !== undefined}
			content			= {null}
			noButtonText	= 'Cancel'
			yesButtonText	= 'Confirm'
			onReject		= {() => this.setState({confirmModalState: {}})}
			onConfirm		= {() => this.confirmChange(isEnabled, confirmModalState.name)}
			size			= 'size2'
		/>
	}

	renderAddonsSection = () => {
		const { classes } = this.props
		return (
			(true)?
			<>{/*hiding addons*/}</>:
			<>
				<div className={classes.title} style={{marginTop: 30}}>Add ons</div>
				<div className={classes.addOnsList}>
					{this.renderAddOn({
						button: (
							<>
								{this.state.crEnabled ? <div className='checkmark'><Checkmark weight={1} color='white'/></div> : null}
								<Switch
									checked		= {this.state.crEnabled}
									onChange	= {event => this.handleCheckedChange(event, 'crEnabled')}
									value		= 'crEnabled'
									classes		= {{
										colorSecondary:	classes.toggleButtonSecondary,
										track:			this.state.crEnabled ? classes.toggleButtonTrackOn : classes.toggleButtonTrackOff,
										root:			classes.switchRoot,
										switchBase:		classes.switchBase,
										thumb:			classes.switchThumb
									}}
								/>
							</>
						),
						title:		'Call recording',
						disclaimer:	['Every call will be recorded and available to everyone.'],
						price: {
							value:			'$8.99',
							description:	['per month']
						}
					})}
					{this.renderAddOn({
						button: (
							<>
								{this.state.pmohEnabled ? <div className='checkmark'><Checkmark weight={1} color='white'/></div> : null}
								<WebArrowTooltip
									title			= {this.state.pmohEnabled ? 
										<span>To turn off <b>Premium music on hold</b> add on, first select a regular music on hold.</span>
									: ''}
									placement		= 'left'
									interactive
									leaveDelay		= {1}
									enterTouchDelay	= {0}
								>
									<Switch
										checked		= {this.state.pmohEnabled}
										onChange	= {event => this.handleCheckedChange(event, 'pmohEnabled')}
										value		= 'pmohEnabled'
										classes		= {{
											colorSecondary:	classes.toggleButtonSecondary,
											track:			this.state.pmohEnabled ? classes.toggleButtonTrackOn : classes.toggleButtonTrackOff,
											root:			classes.switchRoot,
											switchBase:		classes.switchBase,
											thumb:			classes.switchThumb,
											checked:		classes.toggleButtonChecked
										}}
									/>
								</WebArrowTooltip>
							</>
						),
						title:		'Premium music on hold',
						disclaimer:	['Licensed music creates a better user experience,', 'and makes your business feel profesional.'],
						price: {
							value:			'$4.99',
							description:	['per month']
						}
					})}
					{this.renderAddOn({
						button:		<AddIcon classes={{root: classes.addIcon}}/>,
						title:		'Add more numbers',
						disclaimer:	['You can add up to 3 numbers on your account.', 'Add a number for yourself, or a toll free.'],
						price: {
							value:			'$3.99',
							description:	['per month', 'per number']
						}
					})}
				</div>
				{this.renderConfirmModal()}
			</>
		)
	}

	render() {
		const { classes } = this.props
		let backButton = this.props.smallView ? <BackButton onClick={this.props.goBack}/> : ''
		return (
			<>
				<div className={classes.header}>
					<div>
						{backButton}
						<span className='header-title'>Plan</span>
					</div>
				</div>
				<div className={`${classes.settingWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
					{this.renderPlanSection()}
					{this.renderAddonsSection()}
				</div>
			</>
		)
	}
}

export default withStyles(styles)(Plan)