import BlockedNumbersCreate from './components/screens/create.vue'
import BlockedNumbersIndex from './components/screens/index.vue'
import RecentCalls from './components/screens/recent.vue'

/**
 *
 */
export {
    BlockedNumbersCreate,
    BlockedNumbersIndex,
    RecentCalls
}
