import React, { createContext, useCallback, useMemo } from 'react'
import { ScreenSizeContext, ScreenSizeProviderContext } from '../screenview-size/ScreenSizeProvider'
import Modal from './Modal'

/**
 * Each level prevents doing a change in its scope and in the scopes above that level.
 * Example: if APP is set to true then the user will be shown a confirm leave modal
 * in case of switching the app OR switching user / extension but not in case of tab switch inside the app
 */
export enum LeaveLevel {
    USER, // logged user -- stops changing user / extension
    APP, // current app -- stops switching app
    L1, // in app - level 1 -- stops switching in app (example: switch tab in the app)
    L2, // in app - level 2 -- stops switching in app (something more specific than L1)
    L3 // in app - level 3 -- stops switching in app (something more specific than L2)
}

const DEFAULT_LEVEL = LeaveLevel.L1 // eslint-disable-line

/***/
export interface ChangeInfo {
    question?: string | JSX.Element
    details?: string | JSX.Element
    cancelText?: string
    confirmText?: string
}

type OnChangeOptions = {
    hasChange: boolean,
    changeInfo: ChangeInfo,
    level: LeaveLevel,
    leaveCallback?: () => (Promise<void> | void) // eslint-disable-line
}

/***/
export interface LeaveProviderContext {
    leave: (level?: LeaveLevel) => boolean | Promise<boolean>
    onChange: (options: OnChangeOptions) => void
    canLeave: (level: LeaveLevel) => boolean
    leaveCurrentExtension: (newExtension: Record<string, any>) => void
}

/***/
export const LeaveContext = createContext<LeaveProviderContext>({ leave: () => true, onChange: () => { /**/ }, canLeave: () => true })

/***/
export const LeaveConsumer = LeaveContext.Consumer

export const DEFAULT_QUESTION = 'Discard changes?' // eslint-disable-line
export const DEFAULT_DETAILS = 'The changes will be lost if you continue' // eslint-disable-line
export const DEFAULT_CANCEL_TEXT = 'Cancel' // eslint-disable-line
export const DEFAULT_CONFIRM_TEXT = 'Confirm' // eslint-disable-line

const getDefaultChangeInfo = () => ({ question: DEFAULT_QUESTION, details: DEFAULT_DETAILS, cancelText: DEFAULT_CANCEL_TEXT, confirmText: DEFAULT_CONFIRM_TEXT })

/***/
export const LeaveProvider = (props: Record<string, unknown>): JSX.Element => {
    const [changeInfoMap, setChangeInfoMap] = React.useState<Map<LeaveLevel, ChangeInfo>>(new Map())
    const [callbackMap, setCallbackMap] = React.useState<Map<LeaveLevel, Function>>(new Map()) // eslint-disable-line
    const [showLevelModal, setShowLevelModal] = React.useState(-1)
    const [shouldReloadApp, setShouldReloadApp] = React.useState(false)
    const resolve = React.useRef(null)
    const screenSizeContext: ScreenSizeProviderContext = React.useContext(ScreenSizeContext)

    const canLeave = useCallback((level: LeaveLevel): boolean => {
        for (let i: LeaveLevel = level; i <= LeaveLevel.L3; i++) {
            if (changeInfoMap.has(i)) return false
        }
        return true
    }, [changeInfoMap])

    const leave = useCallback((level = DEFAULT_LEVEL) => {
        if (canLeave(level)) return true
        setShowLevelModal(level)
        return new Promise<boolean>(r => (resolve.current = r))
    }, [changeInfoMap])

    const onChange = useCallback(({
        hasChange,
        changeInfo = {},
        level = DEFAULT_LEVEL,
        leaveCallback
    }: OnChangeOptions) => {
        if (hasChange) {
            setChangeInfoMap(new Map(changeInfoMap.set(level, { ...getDefaultChangeInfo(), ...changeInfo })))
            setCallbackMap(new Map(callbackMap.set(level, leaveCallback)))
        } else {
            changeInfoMap.delete(level)
            callbackMap.delete(level)
            setChangeInfoMap(new Map(changeInfoMap))
            setCallbackMap(new Map(callbackMap))
        }
    }, [changeInfoMap, callbackMap])

    const leaveCurrentExtension = async (newExtension: Record<string, any>) => {
        try {
            setShouldReloadApp(true)

            const response = await makePromise(newExtension)
            goToNewExtension(response)
        } catch (error) {
            console.log('error switching to new extension', error.message)
        }
    }

    const makePromise = (newExtension: Record<string, any>) => new Promise((resolve, reject) => { resolve(newExtension) })

    const goToNewExtension = (newExtension: Record<string, any>) => {
        const pathnameSplit = window.location.pathname.split('/').filter((e) => e)
        pathnameSplit.shift()
        const path = pathnameSplit[0] || ''
        window.history.replaceState('Extension', 'Switched extension', `/e${newExtension.extension_id}/${path}/`)
        setShouldReloadApp(false)
    }

    const modalData = useMemo(() => new Map([...changeInfoMap].filter(item => item[0] >= showLevelModal)), [showLevelModal])

    return (
        <LeaveContext.Provider value={{ leave, onChange, canLeave, leaveCurrentExtension }}>
            {!shouldReloadApp && props.children}
            <Modal
                show={showLevelModal > -1}
                data={modalData}
                mobile={screenSizeContext.mobile}
                onReject={() => {
                    setShowLevelModal(-1)
                    resolve.current(false)
                }}
                onConfirm={async () => {
                    setShowLevelModal(-1)
                    // Call the leaveCallbacks
                    const promises = []
                    Object.keys(LeaveLevel)
                        .filter(level => callbackMap.has(LeaveLevel[level]))
                        .forEach(level => {
                            const callback = callbackMap.get(LeaveLevel[level])
                            promises.push(callback())
                        })
                    await Promise.all(promises)
                    // Reset the state after 100ms in order the fade-out of the modal to finish
                    setTimeout(() => {
                        setChangeInfoMap(new Map())
                        setCallbackMap(new Map())
                    }, 100)
                    resolve.current(true)
                }}
            />
        </LeaveContext.Provider>
    )
}
