<template>
    <div class="groups list-page mt-3">
        <FormInput :label="l.t('app.extension', 'Extension')" :tight="true">
          <ExtensionSelector
            :preselect_default="true"
            v-model="group.extension"
            :disabled="group.deleting && group.deleting.total > group.deleting.status.length"
            :key="!!group.deleting"
            class="main-extension-selector"
            data-test-id="groups-extension-selector"
          />
        </FormInput>
        <v-divider class="divider"></v-divider>
        <div class="mt-3">
            <w-alert
              v-if="group.alert"
              :message="group.alert.message"
              :level="group.alert.level"
              :closable="group.enable_close_alert"
              @closed="group.alert = null"
            />
            <template v-else>
                <w-alert
                  v-if="group.successful_deletions"
                  :message="group.successful_deletions.message"
                  level="success"
                  :closable="true"
                />
                <w-alert
                  v-if="group.failed_deletions"
                  :message="group.failed_deletions.message"
                  level="error"
                  :closable="true"
                />
            </template>
            <div v-if="group.loading">
              <w-loader v-if="!group.deleting"/>
              <ProgressBar
                v-else
                :key="group.deleting.status.length"
                :processing="group.deleting"
                @stopProcess="group.stopDeletion = true"
                data-test-id="groups-progressbar"
              />
            </div>
            <div v-else>
              <Filters
                v-if="!group.hide_filters"
                @filtersApplied="group.apply_filters()"
                @clearFilters="group.clear_filters()"
                :showFilters="group.filters_applied || group.keep_filters_open"
                data-test-id="groups-filters"
              >
                <template v-slot:fields>
                  <FormInput :label="l.t('app.name', 'Name')">
                    <w-text-field v-model="group.filters.name" hide-details='auto' data-test-id="groups-filters-name-input"/>
                  </FormInput>
                </template>
              </Filters>
              <div v-if="group.items !== null && group.items.length">
                <w-form @changed="may_submit = true" @submit.prevent="on_form_submit" data-discard="true">
                  <v-simple-table class="without-hover-effect list-table" data-test-id="groups-table">
                    <thead data-test-id="table-head">
                      <tr data-test-id="head-row">
                        <th checkbox>
                            <br/>
                        </th>
                        <th data-test-id="id">{{ l.t('app.id', 'ID') }}</th>
                        <th data-test-id="name">{{ l.t("app.name", "Name") }}</th>
                        <th data-test-id="contacts"><br/></th>
                      </tr>
                    </thead>
                    <tbody data-test-id="table-body">
                      <tr
                        v-for="(item, i) in group.items"
                        :key="item['id']"
                        :class="{ 'selected-row': item.selected }"
                        :data-test-id="item.id"
                      >
                        <td data-test-id="checkbox">
                          <w-checkbox
                            v-if="item.id"
                            v-model="item.selected"
                            @change="checkChanged"
                            :key="`groups-item-${item.id}-${item.selected}`"
                            hide-details="auto"
                            :data-test-id="`groups-item-${i}-checkbox`"
                          />
                        </td>
                        <td data-test-id="id">
                          {{ item.id ? item.id : '—' }}
                        </td>
                        <td style="width: 100%" class="group-name-input-container" data-test-id="name-input">
                            <w-text-field
                              type="text"
                              v-model="item.name"
                              not-clearable
                              @keydown="group.addToCorrected(item)"
                              :rules="[
                                $validation.required(),
                                $validation.not_in(
                                  group.items.filter((x) => x.id && x.id !== item.id).map((x) => x.name),
                                  false,
                                  l.t('groups.name-has-been-taken', 'The name has already been taken'),
                                  true
                                )
                              ]"
                              hide-details="auto"
                              :data-test-id="`groups-item-${i}-name-input`"
                            />
                        </td>
                        <td data-test-id="contacts-link">
                          <router-link v-if="item.id" :to="{name: 'groups.contacts', params: { extension_id: group.extension, id: item.id} }" :title="l.t('groups.see-contacts', 'See contacts')" class="app-icon" :data-test-id="`groups-item-${i}-group-cintacts-link`">
                            <v-icon color="primary">mdi-account-group</v-icon>
                          </router-link>
                          <RemoveFromListButton v-else @click="remove_group(i)" />
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <template v-if="group.items && group.items.length && group.items.filter((x) => x.id).length">
                    <p class="help" data-test-id="groups-help-text">
                      {{ l.t('groups.deleting-information', 'Deleting a group will ungroup the associated contacts, but won\'t delete them from the address book.') }}
                    </p>
                    <MassOperationButtons
                      @selected="group.bulkDelete()"
                      @all="group.deleteAll()"
                      :selected="!group.disable_delete_button"
                      :filters_applied="group.filters_applied"
                      data-test-id="groups-mass-operations"
                    >
                        <div class="d-flex">
                          <w-checkbox
                            v-model="group.check_all"
                            :label="l.t('app.select-all', 'Select all')"
                            class="mt-0 ml-4 select-all"
                            hide-details='auto'
                            data-test-id="groups-select-all-checkbox"
                          />
                          <w-btn @click="group.addNew()" class="ml-3 my-auto" small color="primary" data-test-id="groups-add-new-btn">
                            {{ l.t("app.add-new", "Add new") }}
                          </w-btn>
                        </div>
                    </MassOperationButtons>
                  </template>
                  <w-btn type="submit" :disabled="!group.disable_submit" class="mt-5" color="primary" data-test-id="groups-submit-btn">
                      {{ l.t("app.save", "Save") }}
                  </w-btn>
                </w-form>
              </div>
              <NothingFound
                v-else
                :alert="group.alert"
                :everythingDeleted="group.everythingDeleted"
                data-test-id="groups-nothing-found"
              >
                <template v-slot:emptyArray>
                  <div data-test-id="groups-no-results">
                      {{ l.t("app.no-results-found", "No results found") }}.
                      <a @click="group.addNew()" data-test-id="groups-add-new-text">{{ l.t("app.add-new", "Add new") }}?</a>
                  </div>
                </template>
              </NothingFound>
            </div>
            <NumericPagination
              v-if="group.uri"
              :limit="group.limit"
              :key="group.uri"
              @paramsPrepared="group.loadItems($event)"
              data-test-id="groups-numeric-pagination"
            />
        </div>
    </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import Group from '../../../models/Group';
  import Filters from '../../elements/Filters.vue';
  import ProgressBar from '../../elements/ProgressBar.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import NothingFound from '../../elements/NothingFound.vue';
  import NumericPagination from '../../elements/NumericPagination.vue';
  import ExtensionSelector from '../../fragments/ExtensionSelector.vue';
  import RemoveFromListButton from '../../elements/RemoveFromListButton.vue';
  import MassOperationButtons from '../../elements/MassOperationButtons.vue';

  export default {
    components: {
      Filters,
      FormInput,
      ProgressBar,
      NothingFound,
      NumericPagination,
      ExtensionSelector,
      MassOperationButtons,
      RemoveFromListButton,
    },
    data() {
      return {
        l,
        group: new Group(this.$session, vueComponent(this)),
      };
    },
    created() {
      this.$data.group.loading = true;
    },
    mounted() {
      this.$parent.$on('userRedirected', (to, from, next) => {
        this.$data.group.handleUserRedirect(to, from, next);
      });
    },
    methods: {
      checkChanged() {
        this.$data.group.checkSubmission();
        this.$forceUpdate();
      },
      remove_group(index) {
        this.$data.group.items.splice(index, 1);
        this.$data.group.disable_submit = this.$data.group.items.filter((x) => x.corrected).length || this.$data.group.items.filter((x) => x.newly_added).length;
      },
      async on_form_submit() {
        await this.$data.group.update();
        if (this.$data.group.alert && this.$data.group.alert.level === 'success') {
          this.$global_emitter.$emit('item_updated');
        }

      }
    },
  };
</script>
<!-- <style land="scss" scoped>
  .group-name-input-container >>> .v-input__slot::before {
    border-style: none !important;
  }
</style> -->
