import l from '../lang';

export default {
	'routes': {
		'create': 'queues.create',
		'show': 'queues.show',
		'index': 'queues.index',
	},
	'delete-single': l.t('queues.delete-single', 'Are you sure you want to delete this queue?'),
	'delete-filtered': l.t('queues.delete-filtered', 'Are you sure you want to delete all filtered queues?'),
	'delete-all': l.t('queues.delete-all', 'Are you sure you want to delete all queues?'),
	'successfull-creation': l.t('queues.successfully-created', 'Your queue has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('queues.failed-creation', 'There was a problem creating your queue'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
	'currently-in-use': l.t('queues.this-queue-is-currently-in-use', 'This queue is currently in use'),
	'to-delete-used': l.t('queues.to-delete-this-queue-remove-it', 'To delete this queue, remove it from your call handling rules first, then try again.'),
};
