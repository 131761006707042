/* eslint-disable */ 
export default {
  // "1":{ "code": 1, "alpha2": "US", "name": "United States" },
  "7":{ "code": 7, "alpha2": "RU", "name": "Russian Federation and Kazakhstan" },
  "20":{ "code": 20, "alpha2": "EG", "name": "Egypt" },
  "27":{ "code": 27, "alpha2": "ZA", "name": "South Africa" },
  "30":{ "code": 30, "alpha2": "GR", "name": "Greece" },
  "31":{ "code": 31, "alpha2": "NL", "name": "Netherlands" },
  "32":{ "code": 32, "alpha2": "BE", "name": "Belgium" },
  "33":{ "code": 33, "alpha2": "FR", "name": "France" },
  "34":{ "code": 34, "alpha2": "ES", "name": "Spain" },
  "36":{ "code": 36, "alpha2": "HU", "name": "Hungary" },
  "39":{ "code": 39, "alpha2": "IT", "name": "Italy" },
  "40":{ "code": 40, "alpha2": "RO", "name": "Romania" },
  "41":{ "code": 41, "alpha2": "CH", "name": "Switzerland" },
  "43":{ "code": 43, "alpha2": "AT", "name": "Austria" },
  "44":{ "code": 44, "alpha2": "GB", "name": "United Kingdom, Guernsey, Isle of Man, Jersey" },
  "45":{ "code": 45, "alpha2": "DK", "name": "Denmark" },
  "46":{ "code": 46, "alpha2": "SE", "name": "Sweden" },
  "47":{ "code": 47, "alpha2": "NO", "name": "Norway, Bouvet Island, Svalbard and Jan Mayen" },
  "48":{ "code": 48, "alpha2": "PL", "name": "Poland" },
  "49":{ "code": 49, "alpha2": "DE", "name": "Germany" },
  "51":{ "code": 51, "alpha2": "PE", "name": "Peru" },
  "52":{ "code": 52, "alpha2": "MX", "name": "Mexico" },
  "53":{ "code": 53, "alpha2": "CU", "name": "Cuba" },
  "54":{ "code": 54, "alpha2": "AR", "name": "Argentina" },
  "55":{ "code": 55, "alpha2": "BR", "name": "Brazil" },
  "56":{ "code": 56, "alpha2": "CL", "name": "Chile" },
  "57":{ "code": 57, "alpha2": "CO", "name": "Colombia" },
  "58":{ "code": 58, "alpha2": "VE", "name": "Venezuela, Bolivarian Republic of" },
  "60":{ "code": 60, "alpha2": "MY", "name": "Malaysia" },
  "61":{ "code": 61, "alpha2": "AU", "name": "Australia, Christmas Island, Cocos (Keeling) Islands" },
  "62":{ "code": 62, "alpha2": "ID", "name": "Indonesia" },
  "63":{ "code": 63, "alpha2": "PH", "name": "Philippines" },
  "64":{ "code": 64, "alpha2": "NZ", "name": "New Zealand" },
  "65":{ "code": 65, "alpha2": "SG", "name": "Singapore" },
  "66":{ "code": 66, "alpha2": "TH", "name": "Thailand" },
  "76":{ "code": 76, "alpha2": "KZ", "name": "Kazakhstan" },
  "77":{ "code": 77, "alpha2": "KZ", "name": "Kazakhstan" },
  "81":{ "code": 81, "alpha2": "JP", "name": "Japan" },
  "82":{ "code": 82, "alpha2": "KR", "name": "Korea, Republic of" },
  "84":{ "code": 84, "alpha2": "VN", "name": "Viet Nam" },
  "86":{ "code": 86, "alpha2": "CN", "name": "China" },
  "90":{ "code": 90, "alpha2": "TR", "name": "Turkey" },
  "91":{ "code": 91, "alpha2": "IN", "name": "India" },
  "92":{ "code": 92, "alpha2": "PK", "name": "Pakistan" },
  "93":{ "code": 93, "alpha2": "AF", "name": "Afghanistan" },
  "94":{ "code": 94, "alpha2": "LK", "name": "Sri Lanka" },
  "95":{ "code": 95, "alpha2": "MM", "name": "Myanmar" },
  "98":{ "code": 98, "alpha2": "IR", "name": "Iran, Islamic Republic of" },
  "211":{ "code": 211, "alpha2": "SS", "name": "South Sudan" },
  "212":{ "code": 212, "alpha2": "MA", "name": "Morocco and Western Sahara" },
  "213":{ "code": 213, "alpha2": "DZ", "name": "Algeria" },
  "216":{ "code": 216, "alpha2": "TN", "name": "Tunisia" },
  "218":{ "code": 218, "alpha2": "LY", "name": "Libya" },
  "220":{ "code": 220, "alpha2": "GM", "name": "Gambia" },
  "221":{ "code": 221, "alpha2": "SN", "name": "Senegal" },
  "222":{ "code": 222, "alpha2": "MR", "name": "Mauritania" },
  "223":{ "code": 223, "alpha2": "ML", "name": "Mali" },
  "224":{ "code": 224, "alpha2": "GN", "name": "Guinea" },
  "225":{ "code": 225, "alpha2": "CI", "name": "Côte d'Ivoire" },
  "226":{ "code": 226, "alpha2": "BF", "name": "Burkina Faso" },
  "227":{ "code": 227, "alpha2": "NE", "name": "Niger" },
  "228":{ "code": 228, "alpha2": "TG", "name": "Togo" },
  "229":{ "code": 229, "alpha2": "BJ", "name": "Benin" },
  "230":{ "code": 230, "alpha2": "MU", "name": "Mauritius" },
  "231":{ "code": 231, "alpha2": "LR", "name": "Liberia" },
  "232":{ "code": 232, "alpha2": "SL", "name": "Sierra Leone" },
  "233":{ "code": 233, "alpha2": "GH", "name": "Ghana" },
  "234":{ "code": 234, "alpha2": "NG", "name": "Nigeria" },
  "235":{ "code": 235, "alpha2": "TD", "name": "Chad" },
  "236":{ "code": 236, "alpha2": "CF", "name": "Central African Republic" },
  "237":{ "code": 237, "alpha2": "CM", "name": "Cameroon" },
  "238":{ "code": 238, "alpha2": "CV", "name": "Cape Verde" },
  "239":{ "code": 239, "alpha2": "ST", "name": "Sao Tome and Principe" },
  "240":{ "code": 240, "alpha2": "GQ", "name": "Equatorial Guinea" },
  "241":{ "code": 241, "alpha2": "GA", "name": "Gabon" },
  "242":{ "code": 242, "alpha2": "CG", "name": "Congo" },
  "243":{ "code": 243, "alpha2": "CD", "name": "Congo, the Democratic Republic of the" },
  "244":{ "code": 244, "alpha2": "AO", "name": "Angola" },
  "245":{ "code": 245, "alpha2": "GW", "name": "Guinea-Bissau" },
  "246":{ "code": 246, "alpha2": "IO", "name": "British Indian Ocean Territory" },
  "248":{ "code": 248, "alpha2": "SC", "name": "Seychelles" },
  "249":{ "code": 249, "alpha2": "SD", "name": "Sudan" },
  "250":{ "code": 250, "alpha2": "RW", "name": "Rwanda" },
  "251":{ "code": 251, "alpha2": "ET", "name": "Ethiopia" },
  "252":{ "code": 252, "alpha2": "SO", "name": "Somalia" },
  "253":{ "code": 253, "alpha2": "DJ", "name": "Djibouti" },
  "254":{ "code": 254, "alpha2": "KE", "name": "Kenya" },
  "255":{ "code": 255, "alpha2": "TZ", "name": "Tanzania, United Republic of" },
  "256":{ "code": 256, "alpha2": "UG", "name": "Uganda" },
  "257":{ "code": 257, "alpha2": "BI", "name": "Burundi" },
  "258":{ "code": 258, "alpha2": "MZ", "name": "Mozambique" },
  "260":{ "code": 260, "alpha2": "ZM", "name": "Zambia" },
  "261":{ "code": 261, "alpha2": "MG", "name": "Madagascar" },
  "262":{ "code": 262, "alpha2": "RE", "name": "French Southern Territories, Mayotte, and Réunio" },
  "263":{ "code": 263, "alpha2": "ZW", "name": "Zimbabwe" },
  "264":{ "code": 264, "alpha2": "NA", "name": "Namibia" },
  "265":{ "code": 265, "alpha2": "MW", "name": "Malawi" },
  "266":{ "code": 266, "alpha2": "LS", "name": "Lesotho" },
  "267":{ "code": 267, "alpha2": "BW", "name": "Botswana" },
  "268":{ "code": 268, "alpha2": "SZ", "name": "Swaziland" },
  "269":{ "code": 269, "alpha2": "KM", "name": "Comoros" },
  "290":{ "code": 290, "alpha2": "SH-H", "name": "Saint Helena, Ascension and Tristan da Cunha" },
  "291":{ "code": 291, "alpha2": "ER", "name": "Eritrea" },
  "297":{ "code": 297, "alpha2": "AW", "name": "Aruba" },
  "298":{ "code": 298, "alpha2": "FO", "name": "Faroe Islands" },
  "299":{ "code": 299, "alpha2": "GL", "name": "Greenland" },
  "350":{ "code": 350, "alpha2": "GI", "name": "Gibraltar" },
  "351":{ "code": 351, "alpha2": "PT", "name": "Portugal" },
  "352":{ "code": 352, "alpha2": "LU", "name": "Luxembourg" },
  "353":{ "code": 353, "alpha2": "IE", "name": "Ireland" },
  "354":{ "code": 354, "alpha2": "IS", "name": "Iceland" },
  "355":{ "code": 355, "alpha2": "AL", "name": "Albania" },
  "356":{ "code": 356, "alpha2": "MT", "name": "Malta" },
  "357":{ "code": 357, "alpha2": "CY", "name": "Cyprus" },
  "358":{ "code": 358, "alpha2": "FI", "name": "Finland and Åland Islands" },
  "359":{ "code": 359, "alpha2": "BG", "name": "Bulgaria" },
  "370":{ "code": 370, "alpha2": "LT", "name": "Lithuania" },
  "371":{ "code": 371, "alpha2": "LV", "name": "Latvia" },
  "372":{ "code": 372, "alpha2": "EE", "name": "Estonia" },
  "373":{ "code": 373, "alpha2": "MD", "name": "Moldova, Republic of" },
  "374":{ "code": 374, "alpha2": "AM", "name": "Armenia" },
  "375":{ "code": 375, "alpha2": "BY", "name": "Belarus" },
  "376":{ "code": 376, "alpha2": "AD", "name": "Andorra" },
  "377":{ "code": 377, "alpha2": "MC", "name": "Monaco" },
  "378":{ "code": 378, "alpha2": "SM", "name": "San Marino" },
  "379":{ "code": 379, "alpha2": "VA", "name": "Vatican City" },
  "380":{ "code": 380, "alpha2": "UA", "name": "Ukraine" },
  "381":{ "code": 381, "alpha2": "RS", "name": "Serbia" },
  "382":{ "code": 382, "alpha2": "ME", "name": "Montenegro" },
  "383":{ "code": 383, "alpha2": "XK", "name": "Kosovo" },
  "385":{ "code": 385, "alpha2": "HR", "name": "Croatia" },
  "386":{ "code": 386, "alpha2": "SI", "name": "Slovenia" },
  "387":{ "code": 387, "alpha2": "BA", "name": "Bosnia and Herzegovina" },
  "389":{ "code": 389, "alpha2": "MK", "name": "Macedonia, the Former Yugoslav Republic of" },
  "420":{ "code": 420, "alpha2": "CZ", "name": "Czech Republic" },
  "421":{ "code": 421, "alpha2": "SK", "name": "Slovakia" },
  "423":{ "code": 423, "alpha2": "LI", "name": "Liechtenstein" },
  "500":{ "code": 500, "alpha2": "FK", "name": "Falkland Islands (Malvinas), South Georgia and the South Sandwich Islands" },
  "501":{ "code": 501, "alpha2": "BZ", "name": "Belize" },
  "502":{ "code": 502, "alpha2": "GT", "name": "Guatemala" },
  "503":{ "code": 503, "alpha2": "SV", "name": "El Salvador" },
  "504":{ "code": 504, "alpha2": "HN", "name": "Honduras" },
  "505":{ "code": 505, "alpha2": "NI", "name": "Nicaragua" },
  "506":{ "code": 506, "alpha2": "CR", "name": "Costa Rica" },
  "507":{ "code": 507, "alpha2": "PA", "name": "Panama" },
  "508":{ "code": 508, "alpha2": "PM", "name": "Saint Pierre and Miquelon" },
  "509":{ "code": 509, "alpha2": "HT", "name": "Haiti" },
  "590":{ "code": 590, "alpha2": "GP", "name": "Guadeloupe, Saint Barthélemy, Saint Martin (French part)" },
  "591":{ "code": 591, "alpha2": "BO", "name": "Bolivia, Plurinational State of" },
  "592":{ "code": 592, "alpha2": "GY", "name": "Guyana" },
  "593":{ "code": 593, "alpha2": "EC", "name": "Ecuador" },
  "594":{ "code": 594, "alpha2": "GF", "name": "French Guiana" },
  "595":{ "code": 595, "alpha2": "PY", "name": "Paraguay" },
  "596":{ "code": 596, "alpha2": "MQ", "name": "Martinique" },
  "597":{ "code": 597, "alpha2": "SR", "name": "Suriname" },
  "598":{ "code": 598, "alpha2": "UY", "name": "Uruguay" },
  "599":{ "code": 599, "alpha2": "AN", "name": "Bonaire, Sint Eustatius and Saba, Curaçao" },
  "670":{ "code": 670, "alpha2": "TL", "name": "Timor-Leste" },
  "672":{ "code": 672, "alpha2": "NF", "name": "Norfolk Island" },
  "673":{ "code": 673, "alpha2": "BN", "name": "Brunei Darussalam" },
  "674":{ "code": 674, "alpha2": "NR", "name": "Nauru" },
  "675":{ "code": 675, "alpha2": "PG", "name": "Papua New Guinea" },
  "676":{ "code": 676, "alpha2": "TO", "name": "Tonga" },
  "677":{ "code": 677, "alpha2": "SB", "name": "Solomon Islands" },
  "678":{ "code": 678, "alpha2": "VU", "name": "Vanuatu" },
  "679":{ "code": 679, "alpha2": "FJ", "name": "Fiji" },
  "680":{ "code": 680, "alpha2": "PW", "name": "Palau" },
  "681":{ "code": 681, "alpha2": "WF", "name": "Wallis and Futuna" },
  "682":{ "code": 682, "alpha2": "CK", "name": "Cook Islands" },
  "683":{ "code": 683, "alpha2": "NU", "name": "Niue" },
  "685":{ "code": 685, "alpha2": "WS", "name": "Samoa" },
  "686":{ "code": 686, "alpha2": "KI", "name": "Kiribati" },
  "687":{ "code": 687, "alpha2": "NC", "name": "New Caledonia" },
  "688":{ "code": 688, "alpha2": "TV", "name": "Tuvalu" },
  "689":{ "code": 689, "alpha2": "PF", "name": "French Polynesia" },
  "690":{ "code": 690, "alpha2": "TK", "name": "Tokelau" },
  "691":{ "code": 691, "alpha2": "FM", "name": "Micronesia, Federated States of" },
  "692":{ "code": 692, "alpha2": "MH", "name": "Marshall Islands" },
  // "808":{ "code": 808, "alpha2": "",   "name": "- Shared Cost Services" },
  "850":{ "code": 850, "alpha2": "KP", "name": "Korea, Democratic People's Republic of" },
  "852":{ "code": 852, "alpha2": "HK", "name": "Hong Kong" },
  "853":{ "code": 853, "alpha2": "MO", "name": "Macao" },
  "855":{ "code": 855, "alpha2": "KH", "name": "Cambodia" },
  "856":{ "code": 856, "alpha2": "LA", "name": "Lao People's Democratic Republic" },
  // "878":{ "code": 878, "alpha2": "",   "name": "- Universal Personal Telecommunications services" },
  "880":{ "code": 880, "alpha2": "BD", "name": "Bangladesh" },
  // "881":{ "code": 881, "alpha2": "",   "name": "- Global Mobile Satellite System" },
  // "882":{ "code": 882, "alpha2": "",   "name": "\"iNum\"" },
  "883":{ "code": 883, "alpha2": "",   "name": "- iNum" },
  "886":{ "code": 886, "alpha2": "TW", "name": "Taiwan, Province of China" },
  "960":{ "code": 960, "alpha2": "MV", "name": "Maldives" },
  "961":{ "code": 961, "alpha2": "LB", "name": "Lebanon" },
  "962":{ "code": 962, "alpha2": "JO", "name": "Jordan" },
  "963":{ "code": 963, "alpha2": "SY", "name": "Syrian Arab Republic" },
  "964":{ "code": 964, "alpha2": "IQ", "name": "Iraq" },
  "965":{ "code": 965, "alpha2": "KW", "name": "Kuwait" },
  "966":{ "code": 966, "alpha2": "SA", "name": "Saudi Arabia" },
  "967":{ "code": 967, "alpha2": "YE", "name": "Yemen" },
  "968":{ "code": 968, "alpha2": "OM", "name": "Oman" },
  "970":{ "code": 970, "alpha2": "PS", "name": "Palestine, State of" },
  "971":{ "code": 971, "alpha2": "AE", "name": "United Arab Emirates" },
  "972":{ "code": 972, "alpha2": "IL", "name": "Israel" },
  "973":{ "code": 973, "alpha2": "BH", "name": "Bahrain" },
  "974":{ "code": 974, "alpha2": "QA", "name": "Qatar" },
  "975":{ "code": 975, "alpha2": "BT", "name": "Bhutan" },
  "976":{ "code": 976, "alpha2": "MN", "name": "Mongolia" },
  "977":{ "code": 977, "alpha2": "NP", "name": "Nepal" },
  "992":{ "code": 992, "alpha2": "TJ", "name": "Tajikistan" },
  "993":{ "code": 993, "alpha2": "TM", "name": "Turkmenistan" },
  "994":{ "code": 994, "alpha2": "AZ", "name": "Azerbaijan" },
  "995":{ "code": 995, "alpha2": "GE", "name": "Georgia" },
  "996":{ "code": 996, "alpha2": "KG", "name": "Kyrgyzstan" },
  "998":{ "code": 998, "alpha2": "UZ", "name": "Uzbekistan" },
  "4428":{ "code": 4428, "alpha2": "GB-NR",  "name": "Northern Ireland" },
  "441481":{ "code": 441481, "alpha2": "GG", "name": "Guernsey" },
  "441534":{ "code": 441534, "alpha2": "JE", "name": "Jersey" },
  "441624":{ "code": 441624, "alpha2": "IM", "name": "Isle of Man" },
  "1204":{ "code": 1204, "alpha2":  "CA", "name": "Canada, Monitoba (+204)" },
  "1226":{ "code": 1226, "alpha2":  "CA", "name": "Canada, Ontario (+226)" },
  "1236":{ "code": 1236, "alpha2":  "CA", "name": "Canada, British Columbia (+236)" },
  "1249":{ "code": 1249, "alpha2":  "CA", "name": "Canada, Ontario (+249)" },
  "1250":{ "code": 1250, "alpha2":  "CA", "name": "Canada, British Columbia (+250)" },
  "1289":{ "code": 1289, "alpha2":  "CA", "name": "Canada, Ontario, Toronto (+289)" },
  "1306":{ "code": 1306, "alpha2":  "CA", "name": "Canada, Saskatchewan (+306)" },
  "1343":{ "code": 1343, "alpha2":  "CA", "name": "Canada, Ontario (+343)" },
  "1365":{ "code": 1365, "alpha2":  "CA", "name": "Canada, Ontario, Toronto (+365)" },
  "1403":{ "code": 1403, "alpha2":  "CA", "name": "Canada, Alberta (+403)" },
  "1416":{ "code": 1416, "alpha2":  "CA", "name": "Canada, Ontario, Toronto (+416)" },
  "1418":{ "code": 1418, "alpha2":  "CA", "name": "Canada, Quebec (+418)" },
  "1431":{ "code": 1431, "alpha2":  "CA", "name": "Canada, Monitoba (+431)" },
  "1437":{ "code": 1437, "alpha2":  "CA", "name": "Canada, Ontario (+437)" },
  "1438":{ "code": 1438, "alpha2":  "CA", "name": "Canada, Quebec (+438)" },
  "1450":{ "code": 1450, "alpha2":  "CA", "name": "Canada, Quebec (+450)" },
  "1506":{ "code": 1506, "alpha2":  "CA", "name": "Canada, New Brunswick" },
  "1514":{ "code": 1514, "alpha2":  "CA", "name": "Canada, Quebec (+514)" },
  "1519":{ "code": 1519, "alpha2":  "CA", "name": "Canada, Ontario (+519)" },
  "1579":{ "code": 1579, "alpha2":  "CA", "name": "Canada, Quebec (+579)" },
  "1581":{ "code": 1581, "alpha2":  "CA", "name": "Canada, Quebec (+581)" },
  "1587":{ "code": 1587, "alpha2":  "CA", "name": "Canada, Alberta (+587)" },
  "1604":{ "code": 1604, "alpha2":  "CA", "name": "Canada, British Columbia (+604)" },
  "1613":{ "code": 1613, "alpha2":  "CA", "name": "Canada, Ontario (+613)" },
  "1639":{ "code": 1639, "alpha2":  "CA", "name": "Canada, Saskatchewan (+639)" },
  "1647":{ "code": 1647, "alpha2":  "CA", "name": "Canada, Ontario (+647)" },
  "1705":{ "code": 1705, "alpha2":  "CA", "name": "Canada, Ontario (+705)" },
  "1709":{ "code": 1709, "alpha2":  "CA", "name": "Canada, Newfoundland and Labrador" },
  "1778":{ "code": 1778, "alpha2":  "CA", "name": "Canada, British Columbia (+778)" },
  "1780":{ "code": 1780, "alpha2":  "CA", "name": "Canada, Alberta (+780)" },
  "1782":{ "code": 1782, "alpha2":  "CA", "name": "Canada, Nova Scotia and Prince Edward Island (+782)" },
  "1807":{ "code": 1807, "alpha2":  "CA", "name": "Canada, Ontario (+807)" },
  "1819":{ "code": 1819, "alpha2":  "CA", "name": "Canada, Quebec (+819)" },
  "1867":{ "code": 1867, "alpha2":  "CA", "name": "Canada, Yukon, Northwest Territories and Nunavut" },
  "1873":{ "code": 1873, "alpha2":  "CA", "name": "Canada, Quebec (+873)" },
  "1902":{ "code": 1902, "alpha2":  "CA", "name": "Canada, Nova Scotia and Prince Edward Island (+902)" },
  "1905":{ "code": 1905, "alpha2":  "CA", "name": "Canada, Ontario (+905)" },
  "1242":{ "code": 1242, "alpha2":  "BS", "name": "Bahamas" },
  "1246":{ "code": 1246, "alpha2":  "BB", "name": "Barbados" },
  "1264":{ "code": 1264, "alpha2":  "AI", "name": "Anguilla" },
  "1268":{ "code": 1268, "alpha2":  "AG", "name": "Antigua and Barbuda" },
  "1284":{ "code": 1284, "alpha2":  "BG", "name": "British Virgin Islands" },
  "1345":{ "code": 1345, "alpha2":  "KY", "name": "Cayman Islands" },
  "1441":{ "code": 1441, "alpha2":  "BM", "name": "Bermuda" },
  "1473":{ "code": 1473, "alpha2":  "GD", "name": "Grenada" },
  "1649":{ "code": 1649, "alpha2":  "TC", "name": "Turks and Caicos Islands" },
  "1664":{ "code": 1664, "alpha2":  "MS", "name": "Montserrat" },
  "1721":{ "code": 1721, "alpha2":  "SX", "name": "Sint Maarten" },
  "1758":{ "code": 1758, "alpha2":  "LC", "name": "Saint Lucia" },
  "1767":{ "code": 1767, "alpha2":  "DM", "name": "Dominica" },
  "1784":{ "code": 1784, "alpha2":  "VC", "name": "Saint Vincent and the Grenadines" },
  "1809":{ "code": 1809, "alpha2":  "DO", "name": "Dominican Republic (+809)" },
  "1829":{ "code": 1829, "alpha2":  "DO", "name": "Dominican Republic (+829)" },
  "1849":{ "code": 1849, "alpha2":  "DO", "name": "Dominican Republic" },
  "1868":{ "code": 1868, "alpha2":  "TT", "name": "Trinidad and Tobago" },
  "1869":{ "code": 1869, "alpha2":  "KN", "name": "Saint Kitts and Nevis" },
  "1876":{ "code": 1876, "alpha2":  "JM", "name": "Jamaica" }
}