import { useState } from 'react'

/**
 *
 */
export function useFormInput (initialValue, validation) {
    const [value, setValue] = useState(initialValue)
    const validValue = validation(value)

    /**
     *
     */
    function handleChange (e) {
        setValue(e.target.value)
    }

    function handleXClick () {
        setValue('')
    }

    return {
        value,
        onChange: handleChange,
        onXClick: handleXClick,
        validValue
    }
}
