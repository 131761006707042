<template>
    <div class="show-page" data-test-id="phone-numbers-show-page">
      <Submenu
        v-if="routes"
        @input="(v) => active_tab = v"
        :prevent_routing="Array.isArray(tabs) && tabs.length > 0"
        :routes="routes"
        :key="`${l.detect()}${$route.name}`"
      />
      <w-loader v-if="phone.loading"/>
      <div v-else>
        <PageTitle v-if="title" class="nowrap">
          {{title}}
        </PageTitle>
        <w-alert
          v-if="phone.alert"
          :level="phone.alert.level"
          :message="phone.alert.message"
          :closable="phone.enable_close_alert"
          @closed="phone.alert = null"
          class="mb-6"
        />
        <div v-if="phone.item">
            <CallHandlingRules
              v-if="$route.name === 'phone-numbers.call-flow' || active_tab === 'phone-numbers.call-flow'"
              :_route="phone.item.route"
              :id="phone.item.route ? phone.item.route.id : null"
              @click:cancel="$emit('click:cancel')"
              @updated="on_route_update"
              data-test-id="extensions-call-flow"
            />
            <PhoneNumberSettings
              v-else-if="($route.name === 'phone-numbers.show' || active_tab === 'phone-numbers.show') && phone.item"
              :_phone_number="phone.item"
              @submitted="phone.update($event)"
              data-test-id="phone-numbers-show-settings"
            />
            <MakeCall
              v-else-if="$route.name === 'phone-numbers.make-call' || active_tab === 'phone-numbers.make-call'"
              :caller_id="phone.item['phone_number']"
              data-test-id="phone-numbers-show-make-call"
            />
            <Visualizer
              v-else-if="$route.name === 'phone-numbers.visualizer'"
              data-testid="phone-numbers-visualizer"
            />
        </div>
      </div>
    </div>
</template>

<script>
  import {formatPhoneNumber} from 'formatters';
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import PhoneNumber from '../../../models/PhoneNumber';
  import Visualizer from '../visualizer/index.vue';
  import Submenu from '../../elements/Submenu.vue';
  import MakeCall from '../../fragments/MakeCall.vue';
  import PageTitle from '../../elements/PageTitle.vue';
  import SectionTitle from '../../elements/SectionTitle.vue';
  import PhoneNumberSettings from '../../fragments/PhoneNumberSettings.vue';
  import CallHandlingRules from '../../elements/routes/CallHandlingRules.vue';

  export default {
    props: {
      id: {
        type: [Number, String]
      },
      _phone_number: {
        type: Object
      },
      tabs: {
        type: Array,
      }
    },
    components: {
      Submenu,
      MakeCall,
      PageTitle,
      SectionTitle,
      CallHandlingRules,
      PhoneNumberSettings,
    },
    data() {
      return {
        l,
        phone: new PhoneNumber(this.$session, vueComponent(this)),
        title: null,
        routes: [],
        active_tab: null,
      };
    },
    async created() {
      this.$data.routes = this.prepare_routes();
      if (this.$props._phone_number) {
        this.$data.phone.item = this.$props._phone_number;
        this.$data.phone.loading = false;
      } else {
          await this.$data.phone.getItem(this.$props.id);
      }

      if (this.$data.phone.item) {
        this.$data.title = `${l.t('app.phone-number', 'Phone number')} ${formatPhoneNumber(this.$data.phone.item.phone_number)}`;
      }
    },
    methods: {
      prepare_routes() {
        const allowed_routes = {
          'phone-numbers.show': { title: l.t('app.settings', 'Settings')},
          'phone-numbers.visualizer': { title: l.t('app.visualizer', 'Visualizer')},
          'phone-numbers.call-flow': { title: l.t('app.call-handling', 'Call handling')},
          'phone-numbers.make-call': { title: l.t('make-call.make-a-call', 'Make a call'),subroutes: ['phone-numbers.wizard']},
        };
        let routes = {};
        if (Array.isArray(this.$props.tabs) && this.$props.tabs.length) {
          for (const tab of this.$props.tabs) {
            if (allowed_routes[tab]) routes[tab] = allowed_routes[tab];
          }
        } else {
          routes = allowed_routes;
        }

        return routes;
      },
      async on_route_update(data) {
        this.$data.phone.loading = true;
        try {
          if (!this.$data.phone.item.route || (this.$data.phone.item.route && this.$data.phone.item.route.id !== data.id)) {
            this.$data.phone.item.route = await this.$data.phone.update_route(data);
          }
          this.$global_emitter.$emit('item_updated');
          this.$data.phone.successfulUpdate();
        } catch (err) {
          this.$data.phone.validation_error(err);
        }
        this.$data.phone.loading = false;
      },
    }
  };
</script>
