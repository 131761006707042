<template>
    <ReportsWrapper :report="report" data-test-id="call-reports-extensions">
        <div>
            <!-- <LogarithmicSwitcher
                v-model="logarithmic"
                class="ml-5"
            /> -->
            <ExtensionChart
                :items="prepare_items({...report.items})"
                :extensions="report.extensions"
                :logarithmic="logarithmic"
                :formatters="report.formatters"
                :fields="report.fields"
                :key="`logarithmic-${logarithmic}-chart`"
            />
            <ExtensionsTable
                :items="prepare_items({...report.items})"
                :extensions="report.extensions"
                :logarithmic="logarithmic"
                :formatters="report.formatters"
                :fields="report.fields"
                :key="`logarithmic-${logarithmic}-table`"
            />
        </div>
    </ReportsWrapper>
</template>

<script>
  import { vueComponent } from 'helpers';
  import PerExtension from '../../models/PerExtension';
  import ReportsWrapper from '../elements/Report.vue'
  import ExtensionsTable from '../elements/tables/Extensions.vue';
  import ExtensionChart from '../elements/charts/PerExtension.vue';
  // import LogarithmicSwitcher from 'console/src/components/elements/logarithmic-representation-switcher.vue';

  export default {
    components: {
        ReportsWrapper,
        ExtensionsTable,
        ExtensionChart,
        // LogarithmicSwitcher,
    },
    data() {
        return {
            logarithmic: false,
            items: null,
            report: new PerExtension(this.$session, vueComponent(this)),
      };
    },
    async created() {
        await this.$data.report.loadItems();
    },
    methods: {
        prepare_items(itms) {
            if (!itms) return [];
            let items = {};
            for (const prop of Object.keys(itms)) {
                if (itms[prop].every((x) => !x.value)) delete itms[prop];
            }
            if (this.$data.logarithmic) {
                const values = [];
                for (const key of Object.keys(itms)) {
                    items[key].map((x) => values.push(x.value));
                }
                const log_values = this.$data.report.logarithmic_values([...values]);
                for (const key of Object.keys(itms)) {
                    items[key] = itms[key].map((x) => {
                        x.display_value = log_values[x.value];
                        return x;
                    });
                }
            } else {
                for (const key of Object.keys(itms)) {
                    items[key] = itms[key].map((x) => {
                        x.display_value = x.value;
                        return x;
                    });
                }
            }
            return items;
        },
    }
  }
</script>
