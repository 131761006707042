<template>
    <w-autocomplete v-bind="$attrs" v-on="$listeners" :items="items" :placeholder="l.t('selector.select-a-country', 'Select a country')" />
</template>
<script>
import l from '../../../libs/lang';
import CountriesCodes from '../../../libs/geo/countries-pdc-codes';

export default {
    props: [],
    data() {
        return {
            l,
            items: [],
        };
    },
    created() {
        const items = [];
        const countries = Object.values(CountriesCodes);
        countries.sort((a, b) => a['name'].localeCompare(b['name']));
        for (const country of countries) {
            items.push({
                text: country.name,
                value: country.code,
            });
        }
        this.items = items;
    },
};
</script>
