import { formatDate } from 'formatters'
import l from '../libs/lang'
import Invoice from './Invoice'
import Resource from './Resource'
import exportPdf from '../libs/pdf-exporter'

/**
 *
 */
export default class Billing extends Resource {
    /**
     *
     * @param {object} session
     * @param {object} component
     */
    constructor (session, component) {
        super(session, component, '/invoices')
        this.invoices = [] // invoices
        this.payment_methods = []
        this.loading_invoice = false
        this.active_list_tab = 0
        this.invoices_cache_key = 'billing-invoices'
        this.exporting_pdf = false
        this.filters = {
            ignore_zeros: false
        }
    }

    /**
     *
     */
    async getPaymentMethods () {
        this.loading = true
        try {
            const methods = await this.session.get_list_all('/payment-methods')

            this.payment_methods = methods.items
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
        return this.payment_methods
    }

    /**
     *
     */
    async loadInvoices () {
        const cached_invoices = this.cachier.getItem(this.invoices_cache_key)
        if (cached_invoices) {
            this.invoices = cached_invoices
        } else {
            this.loading = true
            try {
                const invoices = await this.session.get_list_all(this.uri)
                this.cachier.setItem(this.invoices_cache_key, invoices.items)
                this.invoices = invoices.items
            } catch (err) {
                this.validation_error(err)
            }
            this.loading = false
        }
        return this.invoices
    }

    /**
     *
     * @param {number} id
     * @param {string} status
     * @returns
     */
    async setStatus (id, status) {
        this.loading = true
        try {
            await this.session.patch_item(`/payment-methods/${id}`, { status })
            this.payment_methods = await this.getPaymentMethods()
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
        return true
    }

    /**
     *
     * @param {object} inv
     * @returns
     */
    async showInvoice (inv) {
        this.active_list_tab = this.invoices.findIndex((x) => x.id === Number.parseInt(inv))
        if (this.loading_invoice) return false
        this.loading_invoice = true
        try {
            this.invoices = this.invoices.map((x) => {
                x.opened_tab = inv.id === x.id
                return x
            })
            if (inv && typeof inv !== 'object') {
                inv = await this.session.get_item(`/invoices/${inv}`)
            }
            const data = await this.session.get_list_all(`/invoices/${inv.id}/transactions?filters[ignore_zero]=${this.filters.ignore_zeros ? '0' : '1'}`)
            this.invoice = new Invoice(inv, data.items)
        } catch (e) {
            this.validation_error(e, true)
        }
        this.loading_invoice = false
        this.component.forceUpdate()
        return this.invoice
    }

    /**
     *
     */
    async exportPdf () {
        this.exporting_pdf = false
        try {
            await exportPdf('pdf-export', this.generateFileName(), this.session)
            this.pdf_downloaded_successfully()
            this.exporting_pdf = false
        } catch (err) {
            this.alert = {
                message: err,
                level: 'error'
            }
            this.exporting_pdf = false
            this.hide_alert(3)
            window.scrollTo(0, 0)
            return false
        }

        return true
    }

    /**
     *
     */
    generateFileName () {
        const title = `${l.t('app.invoice', 'Invoice')} #${this.invoice.data.id}`
        if (this.invoice.data.status === 'closed') {
            return `${title} ${l.t('billing.closed-at', 'closed at')} ${formatDate(this.invoice.data.closed_at)}`
        // eslint-disable-next-line no-else-return
        } else if (this.invoice.data.status === 'deleted') {
            return `${title} (${l.t('app.deleted', 'Deleted')})`
        }

        return `${title} (${l.t('billing.active', 'Active')})`
    }
}
