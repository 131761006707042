import React from 'react'
import { Alert, Color as AlertColor } from 'alert-mui'
import Typography from 'typography'
import RemoteConfigValue, { getValue } from 'remote-config-value'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox, { Variant as CheckboxVariant } from 'checkbox-mui'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    headerRowBackground: {
        backgroundColor: '#F4F5F6'
    },
    headerRow: {
        '& > th': {
            padding: '0.5rem'
        }
    },
    tableBorder: {
        border: '1px solid #E7E7E7'
    },
    nonTableInvoiceContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '4rem',
        paddingRight: '4rem'
    },
    container: { padding: '0rem 3rem' },
    fullWidth: { width: '100%' },
    responsiveTable: {
        overflowX: 'auto',
        marginBottom: '1.25rem'
    },
    marginBottom: {
        marginBottom: '1.25rem'
    }
}))

/**
 * @param {object} props
 */
const InvoiceStep = (props) => {
    const classes = useStyles()
    const { payment = {} } = props
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { transactions = [], tax_transactions = [], total_fees_as_string = '' } = payment
    const totalCharges = transactions.reduce((a, b) => {
        return a + (b.amount / 100)
    }, 0)
    const totalTaxes = tax_transactions.reduce((a, b) => {
        return a + (b.amount / 100)
    }, 0)

    return (
        <div className={classes.container}>
            {props.showAlert && (<Alert soft color={AlertColor.ERROR} classes={{ root: classes.marginBottom }} content={<RemoteConfigValue valueId={props.alertID} />} />)}
            <div>
                <div className={classes.responsiveTable}>
                    <table className={`${classes.tableBorder} ${classes.fullWidth}` } >
                        <tbody>
                            <tr className={`${classes.headerRowBackground} ${classes.headerRow}`}>
                                <th colSpan='2'>{getValue('sms_enrollment_charges_and_credits')}</th>
                                <th>{getValue('sms_enrollment_unit_price')}</th>
                                <th>{getValue('sms_enrollment_quantity')}</th>
                                <th>{getValue('sms_enrollment_amount')}</th>
                            </tr>
                            {
                                transactions.map((t, index) => {
                                    // TODO: when quantity and period is being returned, revert these changes
                                    const quantity = t.quantity || 1
                                    return <tr id='transaction_row' className={`${classes.headerRow} ${classes.tableBorder}`} key={index} >
                                        <th colSpan='2'>{props.payment.labels[`${t.code}`]}</th>
                                        <th>{`$${(t.amount / 100 / quantity).toFixed(2)}`}</th>
                                        <th>{quantity}</th>
                                        <th>{`$${(t.amount / 100).toFixed(2)}`}</th>
                                    </tr>
                                })
                            }
                            <tr className={`${classes.headerRow}`}>
                                <th colSpan='4'>{getValue('sms_enrollment_charges_discounts_prorates')}</th>
                                <th>{`$${totalCharges.toFixed(2)}`}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={classes.responsiveTable}>
                    <table className={`${classes.tableBorder} ${classes.fullWidth}`} >
                        <tbody>
                            <tr className={`${classes.headerRowBackground} ${classes.headerRow}`}>
                                <th>Taxes</th>
                                <th>{getValue('sms_enrollment_amount')}</th>
                            </tr>
                            {/* <tr className={`${classes.headerRow} ${classes.tableBorder}`}>
                                <th>{`${getValue('sms_enrollment_state_local_tax_fees')} (View breakup)`}</th>
                                <th>{`$${props.tax.toFixed(2)}`}</th>
                            </tr> */}
                            {tax_transactions.map((tax, index) => {
                                return <tr id='tax_transaction_row' className={`${classes.headerRow} ${classes.tableBorder}`} key={index}>
                                    <th>{tax.description}</th>
                                    <th>{`$${(tax.amount / 100).toFixed(2)}`}</th>
                                </tr>
                            })}
                            <tr className={`${classes.headerRow}`}>
                                <th>{getValue('sms_enrollment_taxes_discounts_prorates')}</th>
                                <th>{`$${totalTaxes.toFixed(2)}`}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={classes.nonTableInvoiceContainer}>
                    <div>
                        <Typography variant='subtitle1' remoteConfigID='sms_enrollment_charges_discounts_prorates'/>
                        <Typography variant='subtitle1' remoteConfigID='sms_enrollment_state_local_tax_fees'/>
                        {/* TODO: Add this back when the back end provides the sub totals */}
                        {/* <Typography variant='h6' remoteConfigID='sms_enrollment_sub_total'/> */}
                    </div>
                    <div>
                        <Typography variant='subtitle1'>{`$${totalCharges.toFixed(2)}`}</Typography>
                        <Typography variant='subtitle1'>{`$${totalTaxes.toFixed(2)}`}</Typography>
                        {/* TODO: Add this back when the back end provides the sub totals */}
                        {/* <Typography variant='h6'>{`$${(props.price + props.tax).toFixed(2)}`}</Typography> */}
                    </div>
                </div>
                <hr />
                <div className={classes.nonTableInvoiceContainer}>
                    <div>
                        <Typography variant='h6' remoteConfigID='sms_enrollment_total_charges_credit_card'/>
                    </div>
                    <div>
                        <Typography variant='h6'>{total_fees_as_string}</Typography>
                    </div>
                </div>
                <hr />
                { props.showConfirmation && (
                    <>
                        <Typography variant='body1' className={classes.marginBottom} remoteConfigID='sms_enrollment_all_charges_appear_on_statement' />
                        <Typography variant='body1' remoteConfigID='sms_enrollment_excludes_account_credit_adjustments' />
                        <hr />
                        <Checkbox id='confirmation_checkbox' variant={CheckboxVariant.PRIMARY} checked={props.confirm} onChange={(type, checked) => props.onChangeHandler(checked)} label={getValue('sms_enrollment_business_acknowledgement')} />
                    </>

                ) }
            </div>
        </div>
    )
}

InvoiceStep.propTypes = {
    passData: PropTypes.object,
    update: PropTypes.func,
    saveId: PropTypes.number,
    readOnly: PropTypes.bool,
    showConfirmation: PropTypes.bool,
    confirm: PropTypes.bool,
    showAlert: PropTypes.bool,
    price: PropTypes.number,
    tax: PropTypes.number,
    onChangeHandler: PropTypes.func,
    alertID: PropTypes.string,
    payment: PropTypes.object
}

export default InvoiceStep
