<template>
    <v-simple-table data-test-id="per-ext-table">
        <thead data-test-id="table-head">
            <tr data-test-id="head-row">
                <th data-test-id="extension">{{ $lang.t('app.extension', 'Extension') }}</th>
                <th v-for="prop in Object.keys(items)" :data-test-id="prop" :key="`stats-th-${prop}`">
                    {{ fields[prop].translation }}
                </th>
            </tr>
        </thead>
        <tbody data-test-id="table-body">
            <tr v-for="(extension, i) in extensions" :data-test-id="i" :key="`stats-tr-${i}`">
                <td
                    :class="{ disabled: extension && typeof extension !== 'object'}"
                    :title="false"
                    data-test-id="extension"
                >
                    {{ extensionName(extension) }}
                </td>
                <td v-for="prop in Object.keys(items)" :data-test-id="prop" :key="`stats-td-${prop}`">
                    <div>
                        {{
                            Math.round(items[prop].find((x) => x.extension === extension).value)
                        }}
                    </div>
                </td>
            </tr>
        </tbody>
    </v-simple-table>
</template>
<script>
  export default {
    props: {
        items: {},
        extensions:{},
        logarithmic:{},
        formatters:{},
        fields:{},
    },
    data() {
      return {
      };
    },
    methods: {
        extensionName(extension) {
            if (extension && typeof extension === 'object') {
                return `${extension.extension}: ${extension.name}`;
            }
            if (extension === this.$lang.t('app.no-extension', 'No extension')) {
                return extension;
            }

            return `${this.$lang.t('app.deleted', 'Deleted')} ${extension}`;
        }
    }
  };
</script>

<style scoped>

</style>