import React, { useEffect, useContext } from 'react'
import RemoteConfigValue, { getValue } from 'remote-config-value'
import { Alert, Color as AlertColor } from 'alert-mui'
import Typography from 'typography'
import Checkbox, { Variant as CheckboxVariant } from 'checkbox-mui'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { Textarea } from 'textarea-mui'
import { Grid } from '@material-ui/core'
import CampaignEnrollmentStepContainer from './CampaignEnrollmentStepContainer'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from 'text-field-mui'
import BrandsContext from '../contexts/BrandsContext'
import { useFormInput } from '../hooks/useFormInput'

const useStyles = makeStyles((theme) => ({
    checkboxContainer: {
        display: 'flex',
        flexDirection: 'row',
        padding: '1rem 0rem',
        columnGap: '1rem',
        rowGap: '.25rem',
        flexWrap: 'wrap'
    },
    brandAutocompleteContainer: {

    },
    titleContainer: { paddingTop: '1rem', paddingBottom: '1rem' },
    noWhiteSpace: { whiteSpace: 'nowrap' },
    noResize: {
        resize: 'none',
        width: '100%'
    },
    smsCategoryLeftGrid: {
        "& [class^='MuiFormControl-root']": {
            width: '100%'
        }
    },
    marginBottom: {
        marginBottom: '1.25rem'
    }
}))

/**
 * @param {object} props
 */
const CampaignUsageStep = (props) => {
    const campaignUsageSavedData = props.passData.campaign_usage
    const { editing, showContainer = true, showTitle = true, readOnly = false } = props
    const disabledField = props.editing != null

    const { verifiedBrands } = useContext(BrandsContext)

    const defaultCampaignDescription = campaignUsageSavedData ? campaignUsageSavedData.campaignDescription : (editing != null ? editing.description : '')
    const {
        value: campaignDescription,
        onChange: setCampaignDescription,
        validValue: validCampaignDescription
    } = useFormInput(
        defaultCampaignDescription,
        (value) => value?.length > 40
    )

    const campaignNameRegExp = new RegExp(/^[a-zA-Z0-9! ]+$/g)
    const defaultCampaignName = campaignUsageSavedData ? campaignUsageSavedData.campaignName : (editing != null ? editing.name : '')
    const {
        value: campaignName,
        onChange: setCampaignName,
        validValue: validCampaignName,
        onXClick: onCampaignNameClear
    } = useFormInput(
        defaultCampaignName,
        (value) => value.length >= 3 && value.match(campaignNameRegExp) != null
    )

    const defaultCustomerCare = campaignUsageSavedData ? campaignUsageSavedData.customerCare : (editing != null ? editing.use_cases.includes('CUSTOMER_CARE') : false)
    const {
        value: customerCare,
        onChange: setCustomerCare,
        validValue: validCustomerCare
    } = useFormInput(
        defaultCustomerCare,
        (value) => value as boolean
    )

    const defaultAccountNotification = campaignUsageSavedData ? campaignUsageSavedData.accountNotification : (editing != null ? editing.use_cases.includes('ACCOUNT_NOTIFICATION') : false)
    const {
        value: accountNotification,
        onChange: setAccountNotification,
        validValue: validAccountNotification
    } = useFormInput(
        defaultAccountNotification,
        (value) => value as boolean
    )

    const defaultMarketing = campaignUsageSavedData ? campaignUsageSavedData.marketing : (editing != null ? editing.use_cases.includes('MARKETING') : false)
    const {
        value: marketing,
        onChange: setMarketing,
        validValue: validMarketing
    } = useFormInput(
        defaultMarketing,
        (value) => value as boolean
    )

    const defaultTwoFactorAuth = campaignUsageSavedData ? campaignUsageSavedData.twoFactorAuth : (editing != null ? editing.use_cases.includes('2FA') : false)
    const {
        value: twoFactorAuth,
        onChange: setTwoFactorAuth,
        validValue: validTwoFactorAuth
    } = useFormInput(
        defaultTwoFactorAuth,
        (value) => value as boolean
    )

    const defaultDeliveryNotification = campaignUsageSavedData ? campaignUsageSavedData.deliveryNotification : (editing != null ? editing.use_cases.includes('DELIVERY_NOTIFICATION') : false)
    const {
        value: deliveryNotification,
        onChange: setDeliveryNotification,
        validValue: validDeliveryNotification
    } = useFormInput(
        defaultDeliveryNotification,
        (value) => value as boolean
    )

    const defaultHigherEducation = campaignUsageSavedData ? campaignUsageSavedData.higherEducation : (editing != null ? editing.use_cases.includes('HIGHER_EDUCATION') : false)
    const {
        value: higherEducation,
        onChange: setHigherEducation,
        validValue: validHigherEducation
    } = useFormInput(
        defaultHigherEducation,
        (value) => value as boolean
    )

    const defaultSelectedBrand = campaignUsageSavedData ? campaignUsageSavedData.selectedBrand : (verifiedBrands.length === 1 ? verifiedBrands[0] : (editing != null ? verifiedBrands.find(x => x.csp_brand_id === editing.csp_brand_id) : null))
    const {
        value: selectedBrand,
        onChange: setSelectedBrand
    } = useFormInput(
        defaultSelectedBrand,
        (value) => value as boolean
    )

    const classes = useStyles()

    const invalid = !validCampaignName ||
        !validCampaignDescription ||
        !selectedBrand ||
        !(validCustomerCare ||
            validAccountNotification ||
            validMarketing ||
            validTwoFactorAuth ||
            validDeliveryNotification ||
            validHigherEducation)

    const getSaveData = () => {
        return {
            customerCare,
            accountNotification,
            marketing,
            twoFactorAuth,
            deliveryNotification,
            higherEducation,
            campaignDescription,
            campaignName,
            selectedBrand
        }
    }
    useEffect(() => {
        if (props.saveId) props.update({ completed: true, gotoNext: true, saveData: getSaveData() })
    }, [props.saveId])

    useEffect(() => {
        props.update({ disableNextButton: () => invalid })
    }, [invalid])

    const onBrandChangeHandler = (event, brand) => {
        const e = {
            target: {
                value: brand
            }
        }
        setSelectedBrand(e)
    }

    const renderCheckbox = ({ checked, onChangeHandler, labelID, id }) => {
        return <Checkbox
            id={id}
            key={labelID}
            disabled={disabledField}
            variant={CheckboxVariant.PRIMARY}
            checked={checked}
            onChange={readOnly ? null : (type, checked) => onChangeHandler(checked)}
            label={getValue(labelID)} />
    }

    const renderCheckboxSection = () => {
        console.log('CampaignUsageStep  readOnly renderCheckboxSection', readOnly)
        return (
            <>
                <Typography variant="h6" remoteConfigID="sms_enrollment_how_sms_service_used" />
                <Typography variant='subtitle2' remoteConfigID='sms_enrollment_max_service' classes={{ root: classes.marginBottom }} />
                <>
                    { (!readOnly || mostCommonUseCasesCount > 0) && (
                        <>
                            <Typography variant="subtitle1" remoteConfigID="sms_enrollment_most_common_use_case" />
                            <div className={classes.checkboxContainer}>
                                {(readOnly ? mostCommonUseCases.filter((x) => x.checked) : mostCommonUseCases).map((x) =>
                                    renderCheckbox(x)
                                )}
                            </div>
                        </>
                    ) }
                    { (!readOnly || otherUseCasesCount > 0) && (
                        <>
                            <Typography variant="subtitle2" remoteConfigID="sms_enrollment_other_use_case" />
                            <div className={classes.checkboxContainer}>
                                {(readOnly ? otherUseCases.filter((x) => x.checked) : otherUseCases).map((x) =>
                                    renderCheckbox(x)
                                )}
                            </div>
                        </>
                    )

                    }
                </>
            </>
        )
    }

    const onChangeHandler = (setter) => {
        return (checked) => {
            const event = {
                target: {
                    value: checked
                }
            }
            if (!checked) {
                setter(event)
            } else if (servicesCheckedCount < 5) {
                // allow only 5 cases
                setter(event)
            }
        }
    }

    const mostCommonUseCases = [
        {
            checked: customerCare,
            onChangeHandler: onChangeHandler(setCustomerCare),
            labelID: 'sms_enrollment_customer_care',
            id: 'customerCareChkBox'
        },
        {
            checked: accountNotification,
            onChangeHandler: onChangeHandler(setAccountNotification),
            labelID: 'sms_enrollment_account_notification',
            id: 'acctNotificationChkBox'
        },
        {
            checked: marketing,
            onChangeHandler: onChangeHandler(setMarketing),
            labelID: 'sms_enrollment_marketing',
            id: 'marketingChkBox'
        }
    ]
    const mostCommonUseCasesCount = mostCommonUseCases.map(x => x.checked).filter(Boolean).length

    const otherUseCases = [
        {
            checked: twoFactorAuth,
            onChangeHandler: onChangeHandler(setTwoFactorAuth),
            labelID: 'sms_enrollment_2fa',
            id: 'twoFactorChkBox'
        },
        {
            checked: deliveryNotification,
            onChangeHandler: onChangeHandler(setDeliveryNotification),
            labelID: 'sms_enrollment_delivery_notification',
            id: 'deliveryNotificationChkBox'
        },
        {
            checked: higherEducation,
            onChangeHandler: onChangeHandler(setHigherEducation),
            labelID: 'sms_enrollment_higher_education',
            id: 'higherEduChkBox'
        }
    ]
    const otherUseCasesCount = otherUseCases.map(x => x.checked).filter(Boolean).length
    const servicesCheckedCount = otherUseCasesCount + mostCommonUseCasesCount
    console.log('CampaignUsageStep readOnly', readOnly)
    return (
        <CampaignEnrollmentStepContainer readOnly={readOnly} showContainer={showContainer}>
            {readOnly && showTitle && (
                <div className={classes.titleContainer}>
                    <Typography id='title' variant="h4" remoteConfigID="sms_enrollment_how_using_SMS" />
                </div>
            )}
            <Alert
                soft
                color={AlertColor.INFO}
                content={<RemoteConfigValue valueId="sms_enrollment_campaign_enrollment_info" />}
                classes={{ root: classes.marginBottom }}
            />
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={5} className={classes.smsCategoryLeftGrid}>
                    <div className={`${classes.brandAutocompleteContainer} ${classes.marginBottom}`}>
                        <Typography
                            className={classes.noWhiteSpace}
                            variant='h6'
                            remoteConfigID={'sms_enrollment_select_brand'} />
                        <Autocomplete
                            id='brandAutocomplete'
                            options={verifiedBrands}
                            disabled={readOnly || disabledField}
                            getOptionLabel={option => option.name}
                            renderInput={(params) => (
                                <TextField
                                    label='Brand'
                                    {...params}
                                />
                            )}
                            onInputChange={(event, value, reason) => {
                                if (reason === 'clear') {
                                    setSelectedBrand(null)
                                }
                            }}
                            onChange={onBrandChangeHandler}
                            value={selectedBrand}
                        />
                    </div>
                    <div className={classes.marginBottom}>
                        <Typography
                            className={classes.noWhiteSpace}
                            variant="h6"
                            remoteConfigID={'sms_enrollment_campaign_name'}
                        ></Typography>
                        <TextField
                            id='campaignNameTxtArea'
                            value={campaignName}
                            error={campaignName.length > 0 && !validCampaignName}
                            helperText={validCampaignName ? null : getValue('sms_enrollment_campaign_name_helperText')}
                            disabled={disabledField || readOnly}
                            fullWidth={true}
                            inputProps={{ maxLength: 40 }}
                            label={getValue('sms_enrollment_campaign_name_placeholder')}
                            onChange={setCampaignName}
                            onXClick={onCampaignNameClear} />
                    </div>
                    <div>
                        <Typography
                            className={classes.noWhiteSpace}
                            variant="h6"
                            remoteConfigID={'sms_enrollment_campaign_description'}
                        ></Typography>
                        <Typography
                            variant="subtitle3"
                            remoteConfigID="sms_enrollment_campaign_description_subtitle"
                        />
                        <Textarea
                            id='campaignDescTxtArea'
                            value={campaignDescription}
                            error={campaignDescription.length > 0 && !validCampaignDescription}
                            helperText={validCampaignDescription ? null : getValue('sms_enrollment_valid_campaign_description_helper_text')}
                            maxLength={1024}
                            className={classes.noResize}
                            rowsMin={6}
                            cols={40}
                            readOnly={readOnly}
                            placeholder={getValue('sms_enrollment_campaign_description_placeholder')}
                            onChange={setCampaignDescription}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={7}>
                    {renderCheckboxSection()}
                </Grid>
            </Grid>
        </CampaignEnrollmentStepContainer>
    )
}

CampaignUsageStep.propTypes = {
    passData: PropTypes.object,
    update: PropTypes.func,
    saveId: PropTypes.number,
    readOnly: PropTypes.bool,
    editing: PropTypes.object,
    preview: PropTypes.object,
    showContainer: PropTypes.bool,
    showTitle: PropTypes.bool
}

export default CampaignUsageStep
