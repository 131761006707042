import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import MuiDialog from '@material-ui/core/Dialog'
import MuiDialogContent from '@material-ui/core/DialogContent'
import InlineExtensionSelector from 'inline-extension-selector'
import ControlPanelLinks from './ControlPanelLinks'
import ExtensionSelector from 'identity-area/ExtensionSelector'
import extBtn from '../images/btn-next@2x.png'
import extBtnWhite from '../images/btn-next-wht@2x.png'
import btnPrev from '../images/btn-back.png'
import btnPrevWhite from '../images/btn-back-hover.png'
import PropTypes from 'prop-types'

import Slide from '@material-ui/core/Slide'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { theme } from 'get-theme'

// I moved this out because I see it in other places. This goes in theme
const popTheme = {
    borderRadius: '4px',
    boxShadow: '2.5px 2.5px 0 0 rgba(43, 58, 67, 0.5)',
    border: 'solid 1.5px #000000',
    backgroundColor: '#ffffff'
}

const styles = theme => ({
    profileMenuWrapper: {
    },
    typography: {
        padding: theme.spacing(2)
    },
    popper: {
        zIndex: 1
    },
    paper: {
        height: '100%',
        ...popTheme
    },
    verticalRule: {
        width: '0.5px',
        backgroundColor: '#d8d8d8',
        position: 'absolute',
        left: '100%',
        height: '90%',
        borderLeft: '1px solid lightgray',
        margin: '5% auto'
    },
    infoCard: {
        cursor: 'pointer',
        height: '100%',
        width: '100%'
    },
    profileCardFull: {
        height: '100%',
        display: 'inline-block',
        width: '100%',
        margin: '0 auto',
        padding: '2%',
        float: 'left'
    },
    profileCardHalf: {
        height: '100%',
        display: 'inline-block',
        width: '48%',
        margin: '0 auto',
        padding: '2%'
    },
    selectExtensionButton: {
        'border-radius': '25px',
        'font-size': '18px',
        'font-weight': '500',
        padding: '9px 18px',
        border: `2px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        margin: '16px 0 100px 0',
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center'
    },
    mainElement: {
        height: '100%',
        overflow: 'auto'
    },
    mainElementMobile: {
        width: '100%'
    },
    selectorElement: {
        background: 'white',
        width: '100%'
    },
    gotoMainViewBtn: {
        '& .back-arrow-image-white': {
            minWidth: '50px',
            minHeight: '50px'
        },
        '&:hover': {
            borderRadius: '25px',
            background: theme.palette.primary.main,
            color: 'white'
        },
        '&:not(:hover) .arrow-image-white': {
            display: 'none'
        },
        '&:hover .arrow-image': {
            display: 'none'
        },
        '&:not(:hover) .back-arrow-image-white': {
            display: 'none'
        },
        '&:hover .back-arrow-image': {
            display: 'none'
        }
    }
})

class ProfileMenu extends Component {
    constructor (props) {
        super(props)
        this.state = {
            anchorEl: null,
            shownView: 'extension'
        }
        this.profileMenuRef = React.createRef()
        this.cardRef = React.createRef()
        this.dialogContentRef = React.createRef()
    }

    componentDidUpdate (prevProps, prevState) {
        // let wasOpen = Boolean(prevState.anchorEl)
        // let isOpen = Boolean(this.state.anchorEl)
        // if (!wasOpen && isOpen) setTimeout(this.goToExtensionView, 1)
    }

  onInfoCardClick = () => {
      if (!this.state.anchorEl) {
          this.setState({ anchorEl: this.cardRef.current })
      } else this.handleClose()
  }

  extensionSelectHandler = data => {
      this.setState({ anchorEl: null })
      this.props.extensionSelectHandler(data)
  }

  handleClose = () => this.setState({ anchorEl: null })

  checkIfDesktop = () => {
      const isMobileView = this.props.screenViewType.isMobileView
      const isTabletView = this.props.screenViewType.isTabletView
      const isDesktopView = !isMobileView && !isTabletView
      return isDesktopView
  }

  handleClickAway = () => {
      if (this.checkIfDesktop()) this.setState({ anchorEl: null })
  }

  goToExtensionView = () => {
      return this.setState({ shownView: 'extension' })
      // if (this.checkIfDesktop()) return
      // let dialogPaperElement = this.dialogContentRef.current
      // dialogPaperElement.parentElement.scroll({left: dialogPaperElement.offsetWidth, behavior: 'smooth' })
  }

  goToMainView = () => {
      return this.setState({ shownView: 'main' })
      // const isMobileView = this.props.screenViewType.isMobileView
      // const isTabletView = this.props.screenViewType.isTabletView
      // const isDesktopView = !isMobileView && !isTabletView
      // if (isDesktopView) return
      // let dialogPaperElement = this.dialogContentRef.current
      // dialogPaperElement.parentElement.scroll({left: 0, behavior: 'smooth' })
  }

  renderMobile = () => {
      const { classes, userInfo, screenViewType } = this.props
      const open = Boolean(this.state.anchorEl)
      const shouldShowSelector = userInfo.extensions.length > 1
      return (
          <Dialog
              fullWidth
              open = {open}
              onClose = {this.handleClose}
              aria-labelledby = 'simple-dialog-title'
              aria-describedby = 'alert-dialog-description'
              maxWidth = 'lg'
          >
              <DialogContent ref={this.dialogContentRef}>
                  <Slide direction='right' in={this.state.shownView === 'main'} mountOnEnter unmountOnExit>
                      <div className={`${classes.mainElement} ${classes.mainElementMobile}`} style={this.state.shownView === 'main' ? {} : { display: 'none' }}>
                          {shouldShowSelector && (
                              <div
                                  className = {`${classes.selectExtensionButton} ${classes.gotoMainViewBtn}`}
                                  onClick = {this.goToExtensionView}
                              >
                                  <span>Select Extension</span>
                                  <div style={{ display: 'flex' }}>
                                      <img className='arrow-image' src={extBtn} alt='next' />
                                      <img className='arrow-image-white' src={extBtnWhite} alt='next' />
                                  </div>
                              </div>
                          )}
                          <ControlPanelLinks
                              userInfo = {userInfo}
                              screenViewType = {screenViewType}
                              logout = {this.props.logout}
                          />
                      </div>
                  </Slide>
                  <Slide direction='left' in={this.state.shownView === 'extension'} mountOnEnter unmountOnExit>
                      <div className={classes.selectorElement} style={this.state.shownView === 'extension' ? {} : { display: 'none' }}>
                          <div
                              style = {{ display: 'inline-block', margin: '16px 0' }}
                              className = {classes.gotoMainViewBtn}
                              onClick = {this.goToMainView}
                          >
                              <img className='back-arrow-image' src={btnPrev} alt='prev' />
                              <img className='back-arrow-image-white' src={btnPrevWhite} alt='prev' />
                          </div>
                          <InlineExtensionSelector
                              extensionData = {userInfo.extensions}
                              onExtensionSelect = {this.extensionSelectHandler}
                              screenViewType = {screenViewType}
                          />
                      </div>
                  </Slide>
              </DialogContent>
          </Dialog>
      )
  }

  renderDesktop = () => {
      const { classes, userInfo, screenViewType } = this.props
      const open = Boolean(this.state.anchorEl)
      const id = open ? 'no-transition-popper' : undefined
      const shouldShowSelector = userInfo.extensions.length > 1
      return (
          <Popper
              id = {id}
              open = {open}
              anchorEl = {this.state.anchorEl}
              className = {`${classes.popper}`}
              placement = 'bottom-end'
              disablePortal = {true}
          >
              <Paper className={classes.paper} style={{ width: shouldShowSelector ? 700 : '100%', height: 'auto' }}>
                  <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                      {shouldShowSelector && (
                          <div className={classes.profileCardHalf}>
                              <InlineExtensionSelector
                                  extensionData = {userInfo.extensions}
                                  onExtensionSelect = {this.extensionSelectHandler}
                                  screenViewType = {screenViewType}
                              />
                          </div>
                      )}

                      {shouldShowSelector && <div className={classes.verticalRule}></div>}

                      <div className={shouldShowSelector ? classes.profileCardHalf : classes.profileCardFull}>
                          <ControlPanelLinks
                              userInfo = {userInfo}
                              screenViewType = {screenViewType}
                              logout = {this.props.logout}
                          />
                      </div>
                  </div>
              </Paper>
          </Popper>
      )
  }

  render () {
      const { classes, screenViewType } = this.props
      const isMobileView = screenViewType.isMobileView
      const isTabletView = screenViewType.isTabletView
      return (
          <ClickAwayListener onClickAway={this.handleClickAway}>

              <div className={`${classes.profileMenuWrapper}`} ref={this.profileMenuRef}>

                  {theme.drawerUserInfo.replaceWithLogo
                      ? (<img style={{ minHeight: 100, width: '100%', padding: 5, backgroundColor: theme.drawerUserInfo.replacement.backgroundColor }}
                          src={theme.drawerUserInfo.replacement.logo} alt='logo'
                      />)
                      // <div className={classes.infoCard} ref={this.cardRef} onClick={this.onInfoCardClick}>
                      : (<ExtensionSelector
                          screenViewType = {this.props.screenViewType}
                          userInfo = {this.props.userInfo}
                          onExtensionSelect = {this.extensionSelectHandler}
                          onIdentityClick = {this.props.onIdentityClick}
                          logout = {this.props.logout}
                          inboxesData = {this.props.inboxesData}

                          {...this.props.currentUser}
                      />)
                      // </div>
                  }

                  {(isMobileView || isTabletView) ? this.renderMobile() : this.renderDesktop()}
              </div>
          </ClickAwayListener>
      )
  }
}

const Dialog = withStyles(theme => ({
    paper: {
        'overflow-x': 'hidden',
        margin: '36px',
        width: 'calc(100% - 72px)'
    }
}))(MuiDialog)

const DialogContent = withStyles(theme => ({
    root: {
        'overflow-x': 'hidden',
        width: '100%',
        display: 'flex',
        padding: '8px 24px'
    }
}))(MuiDialogContent)

// todo finish this component
// Add switch between selection selector and main view on mobile

ProfileMenu.propTypes = {
    classes: PropTypes.object,
    userInfo: PropTypes.object,
    screenViewType: PropTypes.object,
    logout: PropTypes.func,
    onExtensionSelect: PropTypes.func,
    onIdentityClick: PropTypes.func,
    inboxesData: PropTypes.object,
    currentUser: PropTypes.object,
    extensionSelectHandler: PropTypes.func
}

export default withStyles(styles)(ProfileMenu)
