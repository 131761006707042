import { getPhoneCom } from 'phonecom'
import gtmDataPush from 'gtm-events'
import { App, AppType } from './objects'

/**
 *
 */
export const log = (...params: any[]): void => {
    console.log(...params)
}

/**
 *
 */
export const getBasePath = (routeMatch: Record<string, any>) => {
    const path = routeMatch.path
    const basePath = path.replace(/\/*$/, '').replace(/\/:.+$/, '')
    return basePath
}

/**
 *
 */
export const isObjectEmpty = (obj: any): boolean => {
    if (!(obj instanceof Object)) {
        throw new Error('Parameter must be valid Object.')
    }
    return !Object.keys(obj).length
}

/**
 *
 */
export const openExternalApp = (app: App): void => {
    const { appType, url } = app

    if (appType != null) {
        getPhoneCom().then(phoneCom => {
            let basePath = 'defaultPath'
            if (appType === AppType.Configure) {
                basePath = process.env.REACT_APP_CONFIGURE_APP_URL!
            } else if (appType === AppType.Console) {
                basePath = process.env.REACT_APP_CONSOLE_APP_URL!
            }

            const link = appType === AppType.ThirdParty ? url : `${basePath}${url}#access_token=${(phoneCom) ? phoneCom.ac_token : ''}&token_type=Bearer`
            window.open(link, '_blank')

            handleGTM({
                action: GTM_APP_LIST_NAME,
                label: 'marketplace-app-click',
                value: app.title,
                event: 'PDC_viewed_marketplace_app',
                other: {
                    ecommerce: {
                        value: 0,
                        items: [{ item_name: app.title }] // eslint-disable-line @typescript-eslint/naming-convention
                    }
                }
            })
        })
    }
}

/**
 *
 */
export const handleGTM = (gtmData: {action: string, label: string, value?: string, event?: string, other?: Record<string, any>}): void => {
    const gtmDataObj = {
        PDC_Action: gtmData.action, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_Label: gtmData.label, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_Value: gtmData.value, // eslint-disable-line @typescript-eslint/naming-convention
        event: gtmData.event,
        ...gtmData.other
    }
    gtmDataPush(gtmDataObj)
}

/**
 *
 */
export const GTM_APP_LIST_NAME = 'apps-marketplace-list' // eslint-disable-line @typescript-eslint/naming-convention
/**
 *
 */
export const GTM_APP_DETAILS_NAME = 'apps-marketplace-app-details' // eslint-disable-line @typescript-eslint/naming-convention
