import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A8544
 *
 * @param {SvgIconProps} props - svg props
 */
export const PhoneInTalk = (props: SvgIconProps): JSX.Element => {
    const fill = props.fill || 'white'
    const svgProps = Object.assign({}, props)
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.32461 11.3725C7.62435 14.1638 9.79451 16.481 12.488 17.982L14.6945 15.9244C14.9653 15.6719 15.356 15.5982 15.6911 15.7263C16.764 16.1228 17.9298 16.3576 19.1315 16.3995C19.6645 16.4181 20.0854 16.8695 20.0668 17.4025L19.9487 20.7846C19.9301 21.3177 19.4787 21.7385 18.9457 21.7199C9.84582 21.4021 2.72846 13.7697 3.04624 4.66979C3.06485 4.13678 3.51618 3.71591 4.04918 3.73453L7.44106 3.85297C7.97406 3.87159 8.39493 4.32291 8.37632 4.85592C8.33402 6.0673 8.48723 7.237 8.8079 8.33492C8.90265 8.67783 8.81193 9.05307 8.5311 9.31495L6.32461 11.3725Z" fill={fill}/>
            <path d="M20.6794 12.0456H19.74C19.4639 12.0456 19.2419 11.821 19.2219 11.5456C18.9785 8.19204 16.3057 5.51928 12.9522 5.27584C12.6768 5.25584 12.4521 5.0339 12.4521 4.75775V3.81836C12.4521 3.54222 12.6765 3.31687 12.9522 3.33245C17.3735 3.58227 20.9155 7.1243 21.1653 11.5456C21.1809 11.8213 20.9556 12.0456 20.6794 12.0456Z" fill={fill}/>
            <path d="M16.8006 12.0456H15.8612C15.5851 12.0456 15.3657 11.82 15.319 11.5478C15.1114 10.338 14.1598 9.38634 12.95 9.17874C12.6778 9.13204 12.4521 8.91268 12.4521 8.63654V7.69715C12.4521 7.421 12.6766 7.19443 12.9514 7.22257C15.2293 7.45591 17.0419 9.26843 17.2752 11.5464C17.3033 11.8211 17.0768 12.0456 16.8006 12.0456Z" fill={fill}/>
        </SvgIcon>
    )
}

export default PhoneInTalk
