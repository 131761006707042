<template>
    <w-loader v-if="sender.loading" :message="l.t('inbox.sending', 'Sending...')"/>
    <w-form v-else @submit.prevent="send" @changed="may_submit = true" data-discard="true" data-test-id="send-mess">
        <w-alert v-if="sender.alert" :message="sender.alert.message" :level="sender.alert.level" :closable="!!sender.alert.closable" class="mb-3"/>
        <div v-if="!!to && !!from">
          <w-text-field
            :rules="sender.media.length === 0 ? [$validation.required()] : []"
            type="text"
            class="fs-exclude"
            v-model="text"
            :placeholder="l.t('inbox.input-placeholder', 'Write a message...')"
            :label="from ? $options.filters.formatPhoneNumber(from_v['phone_number']) : ''"
            hide-details='auto'
            data-test-id="send-mess-message-input"
          />
          <div class="d-flex justify-space-between mt-3">
            <w-file-input @change="(e) => sender.fileUploaded(e)" multiple hide-details="auto" data-test-id="send-mess-file-input"/>
            <w-btn type="submit" color="primary" data-test-id="send-mess-submit-btn">{{ l.t("app.send", "Send") }}</w-btn>
          </div>
        </div>
        <div v-else>
          <FormInput :label="l.t('app.from', 'From')">
            <div v-if="from" class="mt-md-4">
              {{ from | formatPhoneNumber }}
            </div>
            <APIAutocomplete
              v-else
              mode="phone_numbers"
              v-model="from_v"
              :return_object="true"
              data-test-id="send-mess-from-selector"
            />
          </FormInput>
          <FormInput :label="l.t('app.to', 'To')">
            <Tel
              v-for="(t, i) in to_v"
              @changed="to_v[i] = $event.clean_number"
              :closable="to_v.length > 1"
              @closed="$delete(to_v, i)"
              :key="`to-${i + Math.random()}`"
              :value="t"
              :validation="true"
              :required="true"
              data-test-id="send-mess-to-input"
            />
            <AddToListButton @click="to_v.push('')" data-test-id="send-mess-add-phone-btn">
              {{ l.t("app.phone-add", "Add phone number") }}
            </AddToListButton>
          </FormInput>
          <FormInput :label="l.t('app.text', 'Text')">
            <w-text-field
              v-model="text"
              :placeholder="l.t('inbox.input-placeholder', 'Write a message...')"
              hide-details='auto'
              class="fs-exclude"
              :rules="sender.media && !sender.media.length ? [$validation.required(null, l.t('app.required-if-media-empty', 'The field must contain at least one non-whitespace character unless media is included'))] : []"
              data-test-id="send-mess-message-input"
            ></w-text-field>
          </FormInput>
          <FormInput>
            <w-file-input
              @change="(e) => sender.fileUploaded(e)"
              @click:append="sender.media = []"
              :label="l.t('app.attach-files', 'Attach files')"
              small-chips
              multiple
              hide-details="auto"
              class="fs-exclude"
              data-test-id="send-mess-files-input"
            ></w-file-input>
          </FormInput>
          <FormInput>
            <w-btn type="submit" :disabled="!may_submit" color="primary" data-test-id="send-mess-submit-btn">{{ l.t("app.send", "Send") }}</w-btn>
          </FormInput>
        </div>
    </w-form>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../libs/lang';
  import MessageSender from '../../models/MessageSender'
  import Tel from '../elements/Tel.vue';
  import FormInput from '../elements/form/FormInput.vue';
  import AddToListButton from '../elements/AddToListButton.vue';
  import APIAutocomplete from '../elements/form/APIAutocomplete.vue';

  export default {
    props: ['extension_id', 'from', 'to'],
    components: {
    Tel,
    FormInput,
    AddToListButton,
    APIAutocomplete
},
    data() {
      return {
        l,
        sender: new MessageSender(this.$session, this.$props.extension_id, vueComponent(this)),
        text: '',
        may_submit: false,
        from_v: { phone_number: this.$props.from } || null,
        to_v: this.$props.to || [''],
      };
    },
    methods: {
      async send(e) {
        const to = this.$props.to ? this.$props.to : this.$data.to_v;
        const from = this.$props.from
                ? this.$props.from
                : this.$data.from_v['phone_number']
        const res = await this.$data.sender.send(e, to, from, this.$data.text);
        if (res) {
          this.$data.text = '';
          this.$emit('sent', res);
        }
      }
    },
  };
</script>
