import React, { useState, useEffect } from 'react'
import Typography from 'typography'
import { Radio, Variant } from 'radio-mui'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import EnrollmentStepContainer from './EnrollmentStepContainer.tsx'

const styles = makeStyles((theme) => ({
    container: { display: 'flex', justifyContent: 'center' },
    marginBottom: {
        marginBottom: '1.25rem'
    }
}))

/**
 * @param {object} props
 */
const NumberCampaignStep = (props) => {
    const classes = styles()

    const campaignSavedData = props.passData.campaign_step
    const [selectedCampaign, setSelectedCampaign] = useState(campaignSavedData ? campaignSavedData.selectedCampaign : null)

    const invalid = selectedCampaign == null

    const getSaveData = () => {
        return {
            selectedCampaign
        }
    }

    useEffect(() => {
        if (props.saveId) props.update({ completed: true, gotoNext: true, saveData: getSaveData() })
    }, [props.saveId])

    useEffect(() => {
        props.update({ disableNextButton: () => invalid })
    }, [invalid])

    const handleCampaignOnChange = (campaign) => {
        setSelectedCampaign(campaign)
    }

    return (
        <EnrollmentStepContainer>
            <div className={classes.container}>
                <div>
                    <Typography variant='h5' remoteConfigID='sms_enrollment_number_campaign_step_instruction' classes={{ root: classes.marginBottom }} ></Typography>
                    {
                        props.eligibleCampaigns.map((x) => (
                            <div key={x.csp_campaign_id}>
                                <Radio
                                    variant={Variant.PRIMARY}
                                    checked={selectedCampaign?.csp_campaign_id === x.csp_campaign_id}
                                    onChange={() => handleCampaignOnChange(x)}
                                    value={x.csp_campaign_id}
                                    label={x.name}
                                />
                                <hr />
                            </div>
                        ))
                    }
                </div>
            </div>
        </EnrollmentStepContainer>
    )
}

NumberCampaignStep.propTypes = {
    passData: PropTypes.object,
    update: PropTypes.func,
    saveId: PropTypes.number,
    eligibleCampaigns: PropTypes.array
}

export default NumberCampaignStep
