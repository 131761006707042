<template>
    <w-loader v-if="subaccount.loading"/>
    <div v-else class="create-page" data-test-id="subaccounts-create-page">
      <w-alert
        v-if="subaccount.alert"
        :level="subaccount.alert.level"
        :message="subaccount.alert.message"
        :closable="subaccount.enable_close_alert"
        @closed="subaccount.alert = null"
        class="mb-6"
      />
      <SubaccountFragment
        v-if="subaccount.item"
        :_subaccount="subaccount.item"
        @submitted="subaccount.create($event)"
      />
    </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import Subaccount from '../../../models/Subaccount';
  import SubaccountFragment from '../../fragments/Subaccount.vue';

  export default {
    components: {
      SubaccountFragment,
    },
    data() {
      return {
        l,
        subaccount: new Subaccount(this.$session, vueComponent(this)),
      };
    },
  };
</script>
