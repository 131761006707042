<template>
    <w-loader v-if="blocked.loading"/>
    <div v-else-if="blocked.lastWeekCalls && blocked.lastWeekCalls.length" data-test-id="bc-recent-page">
      <p class="help" data-test-id="bc-recent-help-text">
        {{ $lang.t('blocked-calls.recent-explanation', 'List of your last week calls (up to 20 calls) that are not blocked from your part.') }}
      </p>
      <v-simple-table data-test-id="bc-recent-table">
          <thead data-test-id="table-head">
            <tr data-test-id="head-row">
                <th data-test-id="phone">{{ $lang.t('app.phone-number', 'Phone number') }}</th>
                <th class="w-100" data-test-id="time">{{ $lang.t('app.time', 'Time') }}</th>
                <th data-test-id="block">{{ $lang.t('app.block', 'Block') }}</th>
            </tr>
          </thead>
          <tbody data-test-id="table-body">
          <tr v-for="(call, i) in blocked.lastWeekCalls" :data-test-id="call.caller_id">
            <template v-if="blocking !== i">
              <td class="nowrap" :data-test-id="`bc-recent-item-${i}-phone`">{{ call.caller_id | formatPhoneNumber }}</td>
              <td :data-test-id="`bc-recent-item-${i}-time`">
                  {{ new Date(call.time*1000).toLocaleString() }}
              </td>
              <td data-test-id="block">
                <w-tooltip bottom no-carret>
                  <template v-slot:activator>
                    <v-icon
                      @click="prompt_question = call.caller_id"
                      class="error--text"
                      :data-test-id="`bc-recent-item-${i}-block-btn`"
                    >
                      mdi-block-helper
                    </v-icon>
                  </template>
                  <span>{{ $lang.t('app.block', 'Block') }}</span>
                </w-tooltip>
                <FormModal
                  v-if="prompt_question === call.caller_id"
                  :value="true"
                  width="500"
                  @close="prompt_closed"
                  :data-test-id="`bc-recent-item-${i}-modal`"
                >
                  <template v-slot:title>{{$lang.t('app.notes', 'Notes')}}</template>
                  <div class="question-label" :data-test-id="`bc-recent-item-${i}-modal-help-text`">
                    {{ $lang.t(
                      'blocked-calls.note-prompt',
                      'Please provide optional note for block of the number {}. Press "Cancel" if you don\'t want to block the number',
                      [prompt_question],
                    ) }}
                  </div>
                  <w-textarea v-model="block_notes" class="w-100" auto-grow rows="2" :data-test-id="`bc-recent-item-${i}-notes-input`" hide-details="auto"/>
                  <template v-slot:buttons>
                    <w-btn @click="prompt_question = null" color="secondary">{{$lang.t('app.cancel', 'Cancel')}}</w-btn>
                    <w-btn @click="addToBlocked(call, i)" color="primary">{{$lang.t('app.block', 'Block')}}</w-btn>
                  </template>
                </FormModal>
              </td>
            </template>
            <td colspan="3" v-else data-test-id="blocking">
              <w-loader size="small" :no_margins="true"/>
            </td>
          </tr>
          </tbody>
      </v-simple-table>
    </div>
    <div v-else>
      <p class="help" data-test-id="bc-recent-explanation">{{ $lang.t('blocked-calls.recent-explanation', 'List of your last week calls (up to 20 calls) that are not blocked from your part.') }}</p>
      <w-alert :message="$lang.t('app.no-results-found', 'No results found')" level="error" :closable="false" class="my-3"/>
    </div>
  </template>
  
  <script>
    import {vueComponent} from 'helpers';
    import IndexPage from 'console/src/components/elements/IndexPage.vue';
    import BlockedCall from 'console/src/models/BlockedCall';
    import FormModal from 'console/src/components/elements/modal/FormModal.vue';
  
    export default {
      components: {
        IndexPage,
        FormModal
      },
      data() {
        return {
          blocked: new BlockedCall(this.$session, vueComponent(this)),
          block_notes: null,
          prompt_question: null,
          blocking: null,
        };
      },
      async created() {
        await this.$data.blocked.checkLastWeekCalls();
      },
      methods: {
         prompt_closed() {
          this.$data.prompt_question = null;
          this.$data.block_notes = null;
        },
        async addToBlocked(call, i) {
          this.$data.blocking = i;
          this.$data.prompt_question = null;
          await this.$data.blocked.addToBlocked(call, this.$data.block_notes);
          this.$data.block_notes = null;
          this.$data.blocking = null;
        }
      },
      filters: {
        format_date(stamp) {
          if (!stamp) return '—';
          return new Date(stamp * 1000).toISOString().split('T')[0];
        }
      },
    };
  </script>
  