<template>
    <div>
      <TabsAsImages
        root_class="d-sm-flex justify-center"
        :items="modes"
        @change="mode = $event"
        :value="mode"
        class="picker-tabs pb-10 pt-3"
      />
      <form
        @submit.prevent="load_numbers_list"
        v-if="mode !== null"
      >
        <FormInput
          v-if="mode === 'area-code'"
        >
          <div class="d-flex">
          <w-text-field
            type="number"
            min="200"
            max="999"
            class="area-code-input"
            :placeholder="l.t('app.search-area-code', 'Search by area code')"
            v-model="area_code"
            hide-details='auto'
            data-test-id="number-picker-area-code-input"
          />
          <w-btn
              :disabled="!show_button"
              class="ml-4 mt-4"
              color="primary"
              type="submit"
              data-test-id="number-picker-search-btn"
            >
                 {{ l.t("app.search", "Search") }}
            </w-btn>
          </div>
        </FormInput>
        <template v-else-if="mode === 'us-city'">
          <FormInput
            :label="l.t('number-picker.city-name', 'City name')"
          >
            <v-chip
              v-if="us_city_selected"
              close
              close-icon="mdi-close-circle"
              @click:close="us_city_selected = null"
              :data-test-id="`number-picker-selected-city-${us_city_selected.area_code}`"
            >
              {{ us_city_selected.city }}, {{ us_city_selected.region }} ({{ us_city_selected.area_code }})
            </v-chip>
            <template v-else>
              <w-text-field
                v-model="us_city_name"
                hide-details='auto'
                data-test-id="number-picker-us-city-name-input"
              />
              <v-list v-if="us_city_list.length > 0" data-test-id="number-picker-us-sities-list">
                <v-list-item-group v-model="us_city_selected">
                  <v-list-item
                    v-for="c of us_city_list"
                    :value="c"
                    :key="c.city + '-' + c.region + '-' + c.area_code"
                    :data-test-id="`number-picker-us-city-list-item-${c.area_code}`"
                  >
                    <v-list-item-content>
                      <v-list-item-title v-text="`${c.city}, ${c.region} (${c.area_code})`"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </template>
          </FormInput>
        </template>
        <template v-else-if="mode === 'custom-numbers'">
          <FormInput
            :label="l.t('app.contains', 'Contains')"
          >
            <w-text-field
              v-model="custom_params.contains"
              hide-details='auto'
              data-test-id="number-picker-custom-number-contains-input"
            />
          </FormInput>
          <FormInput
            :label="l.t('number-picker.matches', 'Matches')"
          >
            <NumberMatcher :value="custom_params.pattern" @changed="custom_params.pattern = $event" data-test-id="number-picker-custom-number-matched-input"/>
          </FormInput>
          <FormInput
            :label="l.t('number-picker.number-types', 'Number types')"
          >
            <div class="d-flex">
              <w-switch
                v-model="custom_params.type_toll_free"
                :label="l.t('number-picker.toll-free-number', 'Toll free number')"
                class="mr-3"
                hide-details='auto'
                data-test-id="number-picker-custom-number-tool-free-switcher"
              />
              <w-switch
                v-model="custom_params.type_local"
                :label="l.t('number-picker.local', 'Local')"
                hide-details='auto'
                data-test-id="number-picker-custom-number-local-switcher"
              />
            </div>
          </FormInput>
          <FormInput
            :label="l.t('app.price', 'Price')"
          >
            <w-select
              v-model="custom_params.price_range"
              :items="[
                { value: [null, null], text: l.t('number-picker.any-price', 'Any price')},
                { value: [null, 20000], text: `${l.t('number-picker.price-up-to', 'Up to ')} $200`},
                { value: [20000, 50000], text: '$200 – $500'},
                { value: [50000, 100000], text: '$500 – $1000'},
                { value: [100000, null], text: `$1000 ${l.t('number-picker.price-and-more', 'and more')}`},
              ]"
              hide-details="auto"
              data-test-id="number-picker-custom-number-price-select"
            />
          </FormInput>
        </template>
        <template v-else-if="mode === 'international'">
          <FormInput
            :label="l.t('app.region', 'Region')"
          >
            <CountriesCodesSelector v-model="country_code" class="mb-5" data-test-id="number-picker-international-selector"/>
          </FormInput>
        </template>
        <!-- <dl v-else-if="mode === 'recommended'">
            <dt>{{ l.t("app.company", "Company") }}</dt>
            <dd><input type="text" v-model="recommended.company"/></dd>
            <dt>{{ l.t("app.domain", "Domain") }}</dt>
            <dd><input type="text" v-model="recommended.uri"/></dd>
            <dt>{{ l.t("number-picker.recommended-params-vertical", "Business domain") }}</dt>
            <dd><input type="text" v-model="recommended.domain"/></dd>
            <dt>{{ l.t("addressbook.zip", "ZIP") }}</dt>
            <dd><input type="text" v-model="recommended.zip_code"/></dd>
            <dt>{{ l.t("number-picker.area-code", "Area code") }}</dt>
            <dd><input type="text" v-model="recommended.area_code"/></dd>
        </dl> -->
        <template v-if="['custom-numbers', 'recommended'].includes(mode)">
          <FormInput
          class="mb-3"
          >
            <w-btn
              :disabled="!show_button"
              color="primary"
              type="submit"
              data-test-id="number-picker-search-btn"
            >
                 {{ l.t("app.search", "Search") }}
            </w-btn>
          </FormInput>
        </template>
      </form>
      <w-loader v-if="loading" class="mt-3" size="medium"/>
      <div v-else>
        <w-alert v-if="alert" :message="alert.message" :level="alert.level"/>
        <!-- <h4 v-if="mode !== 'international' && !rate_limit_alert && phone_numbers && phone_numbers.length && !phone_numbers.every(x => x.meta_info)">
            <a @click="load_meta_info">
                {{ l.t('phone-numbers.show-meta', 'Show numbers meta information') }}
            </a>
        </h4> -->
        <!-- <w-alert v-else-if="rate_limit_alert" :key="rate_limit_alert" :message="rate_limit_alert" level="warning"/> -->
        <NumberPickerList v-if="phone_numbers && phone_numbers.length" :_phone_numbers="phone_numbers" @reserved="$emit('reserved', $event)"
        />
        <!-- <NumberPickerList v-if="phone_numbers && phone_numbers.length" :_phone_numbers="phone_numbers" @reserved="$emit('reserved', $event)" @loadInfo="load_meta_info(false, [$event])" :show_number_meta_info="mode!== 'international' && !rate_limit_alert" :key="!!rate_limit_alert"
        /> -->
        <w-alert
            v-else-if="phone_numbers !== null"
            :message="l.t('app.no-results-found', 'No results found')"
            level="warning"
            class="mt-5"
            :closable="false"
        />
      </div>
      <NumericPagination
        v-if="uri"
        :limit="limit"
        :key="uri"
        :show_only_arrows="true"
        @paramsPrepared="retreive_numbers"
      />
    </div>
</template>

<script>
  import axios from 'axios';
  import l from '../../libs/lang';
  import Loader from '../elements/LoaderBar.vue';
  import NumberPickerList from './NumberPickerList.vue';
  import FormInput from '../elements/form/FormInput.vue';
  import NumericPagination from '../elements/NumericPagination.vue';
  import NumberMatcher from '../elements/NumberMatcher.vue';
  import TabsAsImages from '../elements/tabs-as-images/TabsAsImages.vue';
  import CountriesCodesSelector from '../elements/form/CountriesCodesSelector.vue';
  import {getFeatureEnabled} from 'feature-flag';

  export default {
    components: {
      Loader,
      FormInput,
      TabsAsImages,
      NumberMatcher,
      NumberPickerList,
      NumericPagination,
      CountriesCodesSelector,
    },
    data() {
      return {
        l,
        alert: null,
        phone_numbers: null,
        uri: null,
        limit: 25,
        loading: false,
        mode: 'area-code',
        area_code: null,
        country_code: null,
        us_city_name: '',
        us_city_selected: null,
        us_city_list: [],
        custom_params: {
          contains: '',
          pattern: ['', '', '', '', '', '', '', '', '', ''],
          type_toll_free: true,
          type_local: true,
          price_range: [null, null],
        },
        modes: [
          // {value: 'recommended', icon: '♡', text: l.t("number-picker.recommended", "Recommended") }, // Requires vendor_id:4 support
          {
            value: 'area-code',
            icon: '$vuetify.icons.location',
            text: l.t('app.search-area-code', 'Search by area code')
          },
          {
            value: 'us-city',
            icon: '$vuetify.icons.globe',
            text: l.t('app.search-us-city', 'Search by US city')
          },
          {
            value: 'custom-numbers',
            icon: '$vuetify.icons.landline',
            text: l.t('number-picker.custom-numbers', 'Custom numbers')
          },
          {
            value: 'toll-free',
            icon: '$vuetify.icons.phone_ringing',
            text: l.t('number-picker.toll-free-numbers', 'Toll free numbers')
          },
        ],
        // rate_limit_alert: null,
        // countdown: null,
        // countdownInterval: null,
        // recommended: {
        //   "company": '',
        //   "area_code": '',
        //   "domain": '',
        //   "uri": '',
        //   "zip_code": '',
        // },
      };
    },
    async created() {
      if (await getFeatureEnabled('console.add_phone_number_global_numbers')) {
        this.$data.modes.push({
          value: 'international',
          icon: '$vuetify.icons.company',
          text: l.t('app.international', 'International')
        });
      }
    },
    computed: {
      show_button() {
        return (
          ['toll-free', 'custom-numbers'].includes(this.$data.mode) // Automatic
          || (['area-code', 'us-city'].includes(this.$data.mode)
            && this.$data.area_code) // Area code-based
           // || (["recommended"].includes(this.$data.mode) &&
            // Object.values(this.$data.recommended).reduce((a, c) => {
            //   return a || !!c;
            // }, false))
        );
      },
    },
    watch: {
      mode(new_value) {
        this.$data.uri = null;
        this.$data.phone_numbers = null;
        this.$data.us_city_selected = null;
        // this.resetCountdown();
        if (['toll-free'].includes(new_value)) {
          this.load_numbers_list();
        }
      },
      country_code(val) {
        if (val) this.load_numbers_list();
      },
      us_city_selected(new_value) {
        this.$data.area_code = new_value ? new_value.area_code : null;
        if (this.$data.area_code) {
          this.load_numbers_list();
        }
      },
      us_city_name(new_value) {
        this.$data.us_city_list = [];
        if (new_value && new_value.length >= 4) {
          axios.post('https://services.phone.com/numbers/search-regions', {
            filter_by: {
              city: new_value,
            },
          }).then((r) => {
            this.$data.us_city_list = r.data;
            this.$data.loading = false;
          })
          .catch(this.show_generic_error());
        }
      },
      // countdown(val) {
      //   if (val && !this.$data.countdownInterval) {
      //     this.updateRateLimitAlert();
      //     this.$data.countdownInterval = setInterval(() => {
      //       this.$data.countdown -= 1;
      //       this.updateRateLimitAlert();
      //     }, 1000);
      //   } else if (val === 0) {
      //     this.resetCountdown();
      //   }
      // },
    },
    methods: {
      load_numbers_list(e) {
        if (e) e.preventDefault();
        this.$data.uri = null;
        if (this.$data.mode === 'us-city' && !this.$data.area_code) return null;
        if (this.$data.mode === 'international' && !this.$data.country_code) return null;
        this.$data.loading = true;
        // if (this.$data.mode === "recommended") {
        //   this.load_recommended_numbers();
        // } else {
          this.load_numbers();
        // }
        return true;
      },
      // load_recommended_numbers: function () {
      //   const data = {};
      //   if (this.$data.recommended.uri.length) {
      //     data['uri'] = this.$data.recommended.uri;
      //   }
      //   if (this.$data.recommended.area_code.length) {
      //     data['area_code'] = this.$data.recommended.area_code;
      //   }
      //   if (this.$data.recommended.zip_code.length) {
      //     data['zip_code'] = this.$data.recommended.zip_code;
      //   }
      //   if (this.$data.recommended.company.length) {
      //     if (!data.hasOwnProperty('keywords')) {
      //       data['keywords'] = [];
      //     }
      //     data['keywords'].push(...this.$data.recommended.company.toLowerCase().split(' '));
      //   }
      //   if (this.$data.recommended.domain.length) {
      //     if (!data.hasOwnProperty('keywords')) {
      //       data['keywords'] = [];
      //     }
      //     data['keywords'].push(this.$data.domain.domain);
      //   }
      //   axios.post("https://5vkv6khvxb.execute-api.us-west-2.amazonaws.com/v1/recommend-numbers", data)
      //     .then((r) => {
      //       this.$data.phone_numbers = r.data.items.map((p) => {
      //         const t_pos = p['format_mask'].lastIndexOf('.') + 1;
      //         const t_val = p['format_mask'].slice(t_pos);
      //         return {
      //           phone_number: p['phone_number'],
      //           price: p['price'],
      //           toll_free: p['toll_free'],
      //           vendor_id: p['vendor_id'],
      //           country: p['country'].toUpperCase(),
      //           t_pos, t_val
      //         };
      //       });
      //       this.$data.loading = false;
      //     })
      //     .catch(this.show_generic_error());
      // },
      async load_numbers() {
        if (this.$data.mode === 'us-city' && !this.$data.area_code) return null;
        if (this.$data.mode === 'international' && !this.$data.country_code) return null;
        this.$data.loading = true;
        try {
          const filters = {};
          if (['area-code', 'us-city'].includes(this.$data.mode)) {
            if (this.$data.area_code) {
              filters['npa'] = this.$data.area_code;
            }
          }
          if (this.$data.mode === 'custom-numbers') {
            filters['toll_free'] = this.$data.custom_params.type_toll_free;
            filters['local'] = this.$data.custom_params.type_local;
            if (this.$data.custom_params.contains) {
              filters['contains'] = this.$data.custom_params.contains;
            }
            if (!this.$data.custom_params.pattern.every((x) => x === '')) {
              filters['is_like'] = `+1${this.$data.custom_params.pattern.map((v) => (v.length ? v : '.')).join('')}`;
            }
            if (this.$data.custom_params.price_range[0] !== null) {
              filters['min_price'] = this.$data.custom_params.price_range[0];
            }
            if (this.$data.custom_params.price_range[1] !== null) {
              filters['max_price'] = this.$data.custom_params.price_range[1];
            }
          } else if (this.$data.mode === 'toll-free') {
            filters['toll_free'] = true;
          } else if (this.$data.mode === 'international' && this.$data.country_code) {
            filters['country_code'] = this.$data.country_code;
          }

          let search = [];
          if (filters['npa']) search.push(`filters[npa]=${filters['npa']}`);
          if (filters['country_code']) {
            search.push(`filters[country_code]=${filters.country_code}`);
          }
          if (filters['toll_free'] || filters['local']) {
            if (filters['toll_free'] && !filters['local']) {
              search.push('filters[is_toll_free]=1');
            } else if (!filters['toll_free'] && filters['local']) {
              search.push('filters[is_toll_free]=0');
            }
          }
          if (filters['contains'] || filters['is_like']) {
            if (filters['contains'] && filters['is_like']) {
              search.push(`filters[phone_number][]=contains:${filters['contains']}`);
              search.push(`filters[phone_number][]=${filters['is_like']}`);
            } else {
              if (filters['contains']) search.push(`filters[phone_number]=contains:${filters['contains']}`);
              if (filters['is_like']) search.push(`filters[phone_number]=${filters['is_like']}`);
            }
          }
          if (filters['min_price'] || filters['max_price']) {
            if (filters['min_price'] && filters['max_price']) {
              search.push(`filters[price]=between:${filters['min_price']},${filters['max_price']}`);
            } else if (filters['min_price'] && !filters['max_price']) {
              search.push(`filters[price]=gte:${filters['min_price']}`);
            } else if (!filters['min_price'] && filters['max_price']) {
              search.push(`filters[price]=lte:${filters['max_price']}`);
              search.push('filters[price]=gte:0');
            }
          } else {
              search.push('filters[price]=gte:0');
          }
          search = search.length ? `?${search.join('&')}` : '';
          this.uri = `/phone-numbers/available${search}`;
        } catch (err) {
          console.log(err);
          this.show_generic_error();
        }
        this.$data.loading = false;

        return true;
      },
      async retreive_numbers(params) {
        this.$data.loading = true;
        try {
          // this.resetCountdown();
          const items = await this.$session.get_list(this.uri, params.limit, params.offset, true);

           if (this.$data.mode === 'custom-numbers' && this.$data.custom_params.contains) {
            if (this.$data.custom_params.contains && this.$data.custom_params.contains.match(/[a-z]/g)) { // if there is '123-NAME-987'
              const numericContainsParam = this.lettersToDigits(this.$data.custom_params.contains);
              for (const item of items.items) {
                item.letters_in_number = item.phone_number.replace(numericContainsParam, this.$data.custom_params.contains);
              }
            }
           }
          this.$data.phone_numbers = items.items;
          this.$emit('itemsLoaded', JSON.parse(JSON.stringify(items)));
          this.$data.loading = false;
        } catch (err) {
         this.show_generic_error();
        }
        this.$data.loading = false;
      },
      show_generic_error() {
        this.$data.alert = {
              message: l.t('app.generic-error', 'Something went wrong'),
              level: 'error'
          }
          setTimeout(() => this.$data.alert = null, 10000)
          window.scrollTo(0, 0)
          this.loading = false;
      },
      // async load_meta_info(e, numbers, single_num) {
      //   numbers = numbers || this.$data.phone_numbers.map((x) => x.phone_number);
      //   single_num = numbers && numbers.length === 1;
      //   if (!single_num) {
      //     this.$data.loading = true;
      //     this.$emit('hidePagination');
      //   } else {
      //     this.$emit('infoLoading', numbers[0]);
      //   }
      //   try {
      //     this.$session.options.handle_rate_limit = false;
      //     const metaInfo = await this.$session.get_list(`/phone-numbers/meta?filters[phone_number]=in:${numbers.join(',')}`, 25, 0, true);
      //     for (const info of metaInfo.items) {
      //       const pairIndex = this.$data.phone_numbers.findIndex((x) => x.phone_number === info.number);
      //       if (pairIndex > -1) {
      //         this.$data.phone_numbers[pairIndex]['meta_info'] = info;
      //       }
      //     }
      //     this.$session.options.handle_rate_limit = true;
      //     if (!single_num) {
      //       this.$emit('showPagination');
      //     } else {
      //       this.$emit('infoLoaded', metaInfo.items);
      //     }
      //   } catch (err) {
      //     if (err.status === 429) {
      //       this.$emit('infoLoaded', []);
      //       this.$data.countdown = err['@error']['@rateLimit']['Retry-After'];
      //     } else {
      //       Helpers.validation_error.bind(this)(err); // this method is depricated
      //     }
      //   }
      //   this.$session.options.handle_rate_limit = true;
      //   if (!single_num) {
      //     this.$data.loading = false;
      //     this.$emit('showPagination');
      //   }
      // },
      lettersToDigits(input) {
            input = input.toLowerCase();
            let phonenumber = '';
            for (const character of input) {
              switch (character) {
                case 'a': case 'b': case 'c': phonenumber += '2'; break;
                case 'd': case 'e': case 'f': phonenumber += '3'; break;
                case 'g': case 'h': case 'i': phonenumber += '4'; break;
                case 'j': case 'k': case 'l': phonenumber += '5'; break;
                case 'm': case 'n': case 'o': phonenumber += '6'; break;
                case 'p': case 'q': case 'r': case 's': phonenumber += '7'; break;
                case 't': case 'u': case 'v': phonenumber += '8'; break;
                case 'w': case 'x': case 'y': case 'z': phonenumber += '9'; break;
                default: phonenumber += character;
              }
            }
          return phonenumber;
        },
      // updateRateLimitAlert() {
      //   this.$data.rate_limit_alert = l.t('phone-numbers.rate-limit-error', 'Currently there is too many requests for loading numbers info. Retry in {} seconds.', [this.$data.countdown]);
      // },
      // resetCountdown() {
      //   clearInterval(this.$data.countdownInterval);
      //   this.$data.countdownInterval = null;
      //   this.$data.countdown = null;
      //   // this.$data.rate_limit_alert = null;
      // },
    },
  };
</script>
<style lang="scss">
  .picker-tabs {
    .active-tab, .inactive-tab {
      margin-left: 15px !important;
      margin-right: 15px !important;
    }
  }
  .area-code-input{
      max-width: 250px !important;
      margin-left: 10px !important;
  }

</style>
