<template>
      <Listener class="create-page" data-test-id="apps-create-page"></Listener>
</template>

<script>
  import Listener from '../../fragments/Listener.vue';

  export default {
    components: {
      Listener,
    },
  };
</script>
