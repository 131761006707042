import React from 'react'
import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@material-ui/core'
import { ThemeProvider } from 'theme-provider'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import styles from './styles'

const useStyles = makeStyles(styles)

interface Props extends Omit<MuiIconButtonProps, 'color' | 'variant' | 'size'> {
    color?: 'primary' | 'secondary' | 'warning' | 'urgent'
    variant?: 'contained' | 'contained-light',
    size?: 'large' | 'medium' | 'small'
}

const dashedToCamelcase = (string: string) => {
    return string.split('-').map((e, i) => {
        if (!i) return e
        return `${e[0].toUpperCase()}${e.substring(1, e.length)}`
    }).join('')
}

/**
 * IconButton component
 *
 * @param {Props} props - props
 */
export const IconButton = (props: Props): JSX.Element => {
    const variant = props.variant || 'contained'
    const color = props.color || 'primary'
    const size = props.size || 'large'
    const classes = useStyles()
    return (
        <ThemeProvider>
            <MuiIconButton
                {...props}
                disableRipple
                color = {(['warning', 'urgent'].includes(color) ? 'primary' : color) as 'primary' | 'secondary'}
                className = {clsx(props.className, {
                    [classes[`${variant}Warning`]]: color === 'warning',
                    [classes[`${variant}Urgent`]]: color === 'urgent'
                }, {
                    [classes[`${dashedToCamelcase(variant)}${color[0].toUpperCase()}${color.substring(1, color.length)}`]]: true
                }, {
                    [classes[size]]: true
                })}
            >
                {props.children}
            </MuiIconButton>
        </ThemeProvider>
    )
}

export default IconButton
