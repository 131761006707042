import { useState, useEffect, useCallback } from 'react'

/**
 *
 * @param {Function} asyncFunction
 * @param {boolean} immediateValue
 * @param {object} immediateParams
 */
export function useAjax (asyncFunction, immediateValue = true, immediateParams = null) {
    const [status, setStatus] = useState('idle')
    const [value, setValue] = useState(null)
    const [error, setError] = useState(null)
    const [immediate, setImmediate] = useState(immediateValue)

    // The execute function wraps asyncFunction and
    // handles setting state for pending, value, and error.
    // useCallback ensures the below useEffect is not called
    // on every render, but only if asyncFunction changes.
    const execute = useCallback((params) => {
        setValue(null)
        setError(null)
        setStatus('pending')
        return asyncFunction(params)
            .then((response) => {
                setValue(response)
                setStatus('success')
            })
            .catch((error) => {
                setError(error)
                setStatus('error')
            })
    }, [asyncFunction])

    useEffect(() => {
        if (immediate) {
            setImmediate(false)
            execute(immediateParams)
        }
    }, [execute, immediate])

    return {
        execute,
        status,
        value,
        error
    }
}
