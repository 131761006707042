import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=6846%3A20203
 *
 * @param {SvgIconProps} props - svg props
 */
export const DocumentsIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_6846_20203)'>
                <path fillRule='evenodd' clipRule='evenodd' d='M14.5 0.000158753C14.7761 0.00016391 15 0.22402 15 0.500159V1.50017C15 1.77631 14.7761 2.00017 14.5 2.00017H6C4.89543 2.00017 4 2.8956 4 4.00017V15.5002C4 15.7763 3.77614 16.0002 3.5 16.0002H2.5C2.22386 16.0002 2 15.7763 2 15.5002V4C2 1.79083 3.79091 -4.12597e-05 6.00007 0L14.5 0.000158753ZM6 5.99993C6 4.89536 6.89543 3.99993 8 3.99993H15.5858C15.851 3.99993 16.1054 4.10528 16.2929 4.29282L21.7071 9.70703C21.8946 9.89457 22 10.1489 22 10.4141V21.9999C22 23.1045 21.1046 23.9999 20 23.9999H8C6.89543 23.9999 6 23.1045 6 21.9999V5.99993ZM15 4.99993L21 10.9999H16C15.4477 10.9999 15 10.5522 15 9.99993V4.99993Z' fill={color}/>
            </g>
            <defs>
                <clipPath id='clip0_6846_20203'>
                    <rect width='24' height='24' fill='white'/>
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default DocumentsIcon
