import React, { useCallback, useEffect, useRef, useState } from 'react'
import Button from 'button'
import { xBtnGray } from 'pdc-icons'
import { Dialog, makeStyles } from '@material-ui/core'
import styles from './styles'

const useStyles = makeStyles(styles)

interface Props {
    open: boolean
    onClose: () => void
    note
    addNote: (note, action: 'new' | 'update') => void
}

/***/
const AddNoteDialog = ({ open, onClose, note, addNote }: Props): JSX.Element => {
    const classes = useStyles()
    const [value, setValue] = useState(note?.content || '')
    const [lengthError, setLengthError] = useState(false)
    const timeoutRef = useRef<number>(-1)
    const lastNoteRef = useRef(note)

    useEffect(() => {
        if (!open) {
            setValue('')
            setLengthError(false)
        }
    }, [open])

    const addNoteLocal = useCallback(() => {
        if (note) addNote({ id: note.id, content: value }, 'update')
        else addNote(value, 'new')
        onClose()
        setValue('')
    }, [note, onClose, addNote, value])

    const onChange = useCallback(e => {
        let newValue = e.target.value
        if (newValue.length > 2500) {
            setLengthError(true)
            clearTimeout(timeoutRef.current)
            timeoutRef.current = window.setTimeout(() => setLengthError(false), 4000)
            newValue = newValue.substring(0, 2500)
        }
        setValue(newValue)
    }, [])

    useEffect(() => {
        if (!lastNoteRef.current && note) setValue(note.content)
        else if (lastNoteRef.current && !note) setValue('')
    }, [note])

    return (
        <Dialog
            open = {open}
            onClose = {onClose}
            classes = {{ paper: classes.addNoteDialog }}
        >
            <img className={classes.closeButton} src={xBtnGray} alt='Close' onClick={onClose} />
            <div className={classes.title}>Include a note with your fax</div>
            <div className={classes.infoText}>Consider adding the recipient&apos;s name, relevant contact information, and any notes that may be helpful following the receipt of this fax.</div>
            <div className={classes.content} onClick={() => document.getElementById('textarea')?.focus()}>
                {lengthError ? <span className='error'>Max size is 2500 characters</span> : null}
                <textarea autoFocus data-test-id='fax-add-note-textarea' id='textarea' value={value} onChange={onChange}/>
                <span className='characters-info'>{value.length} / 2500</span>
            </div>
            <div className={classes.buttonWrapper}>
                <Button
                    onClick = {addNoteLocal}
                    disabled = {!value.trim()}
                    data-test-id = 'fax-add-note-button'
                >
                    {note ? 'Update Note' : 'Add Note'}
                </Button>
            </div>
        </Dialog>
    )
}

export default AddNoteDialog
