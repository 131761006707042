const styles = theme => ({
	loadingDiv: theme.loadingDiv,
	wrapper: {
		position:		'relative',
		display:		'flex',
		flexDirection:	'column',
		height:			'calc(100% - 48px)',
		'& .infinite-scroller': {
			height: 'calc(100% - 66px)'
		}
	},
	searchFieldWrapper: {
		padding:		'0 15px',
		marginBottom:	15
	},
	searchField: {
		width:			'100%',
		marginRight:	20
	},
	letterHeader: {
		background:		theme.palette.secondary[-400],
		borderRadius:	3,
		padding:		'6px 20px 4px',
		margin:			'10px 0',
		fontFamily:		'NTR',
		fontSize:		20,
		lineHeight:		'18px',
		letterSpacing:	4,
		'-webkit-text-stroke-width': '0.5px'
	},
	teammateItem: {
		fontSize:		14,
		padding:		'10px 20px',
		display:		'flex',
		flexDirection:	'column',
		cursor:			'pointer',
		borderRadius:	3,
		'&:hover': {
			background: theme.palette.secondary[-400]
		},
		'&.selected': {
			background: theme.palette.primary[-400]
		},
		'& .teammate-pending': {
			color:		theme.palette.attention['+100'],
			fontSize:	12,
			fontWeight:	'bold'
		}
	}
})

export default styles