import React from 'react'
import App from './src/components/App'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers'

import withTheme from '@material-ui/core/styles/withTheme'

const store = createStore(rootReducer)

class Faxes extends React.Component {
  render () {
    return (
      <Provider store={store}>
        <App
          isOffline={this.props.isOffline}
          extension={this.props.extension}
          contactsUtil={this.props.contactsUtil}
          screenViewType={this.props.screenViewType}
          standalone={this.props.standalone}
          onLoaded={this.props.onLoaded}
          subscribeForNotifications={this.props.subscribeForNotifications}
          resetSubscription={this.props.resetSubscription}
          updateUnreadCounts={this.props.updateUnreadCounts}
          changeFaxReadStatus={this.props.changeFaxReadStatus}
          redirect={this.props.redirect}
          makeCall={this.props.makeCall}
          routerProps={this.props.routerProps}
        />
      </Provider>
    )
  }
}

export default withTheme(Faxes)
