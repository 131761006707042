<template>
	<v-menu
       v-model="menu2"
       :close-on-content-click="false"
       :nudge-right="40"
       transition="scale-transition"
       offset-y
       min-width="auto"
       hide-details="auto"
       data-test-id="datepicker"
     >
       <template v-slot:activator="{ on, attrs }">
         <w-text-field
           v-model="computedDateFormatted"
           :placeholder="label || l.t('app.pick-a-date', 'Pick a date')"
           not-clearable
           readonly
           :rules="rules"
           :class="menu2 && 'active-input'"
           v-bind="attrs"
           v-on="on"
           hide-details='auto'
           data-test-id="datepicker-computed-input"
         ></w-text-field>
       </template>
       <v-date-picker
				 no-title
				 color="primary"
         v-model="date"
         @input="menu2 = false"
         hide-details="auto"
         data-test-id="datepicker-date-input"
       ></v-date-picker>
     </v-menu>
</template>

<script>
export default {
	props: ['label', 'value', 'rules'],
	data(vm) {
		return {
			date: this.$props.value || null,
			menu: false,
			menu2: false,
			dateFormatted: vm.formatDate(new Date(Date.now()).toISOString().substr(0, 10)),
		};
	},
	computed: {
	  computedDateFormatted() {
	    return this.formatDate(this.date);
	  },
	},
	methods: {
	  formatDate(date) {
	    if (!date) return null;

	    const [year, month, day] = date.split('-');
	    return `${month}/${day}/${year}`;
	  },
	},
	watch: {
		date(val) {
			this.$emit('changed', val);
		}
	}
};
</script>
