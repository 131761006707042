<template>
    <w-loader v-if="loading" size="medium"/>
    <div v-else>
        <w-alert v-if="alert" :message="alert.message" :level="alert.level" class="mb-6"/>
        <w-form @changed="may_submit = true" @submit="submitted" data-discard="true">
          <SectionTitle data-test-id="extension-general-title">
            {{ l.t('extensions.general-settings', "General settings") }}
          </SectionTitle>
          <FormInput
            :label="l.t('app.nickname', 'Nickname')"
          >
            <w-text-field v-model="phone_number['name']" maxlength="29" autocomplete="off" hide-details='auto' data-test-id="phone-numbers-settings-name-input"/>
          </FormInput>
          <FormInput
            :label="l.t('phone-number-settings.block', 'Block calls')"
          >
            <w-switch
              v-model="phone_number['block_incoming']"
              :label="l.t('phone-number-settings.block-incoming', 'Block incoming calls')"
              hide-details='auto'
              data-test-id="phone-numbers-settings-block-incoming-switch"
            />
            <w-switch
              v-if="!phone_number['block_incoming']"
              v-model="phone_number['block_anonymous']"
              :label="l.t('phone-number-settings.block-anonymous', 'Block anonymous calls')"
              hide-details='auto'
              data-test-id="phone-numbers-settings-block-anonymus-switch"
            />
          </FormInput>
          <FormInput :label="l.t('phone-number-settings.publish', 'Publish number')">
            <w-switch
              v-model="caller_id_enabled"
              :label="l.t('phone-number-settings.publish-text', 'Update National CNAM database (Text Caller ID). Maximum 15 characters.')"
              hide-details='auto'
              data-test-id="phone-numbers-settings-publish-num-switch"
            />
            <div v-if="caller_id_enabled">
              <w-text-field
                v-model="phone_number['caller_id']['name']"
                maxlength="15"
                :placeholder="l.t('phone-number-settings.publish-name', 'Caller ID name')"
                required="required"
                hide-details='auto'
                data-test-id="phone-numbers-settings-caller-id-name-input"
              />
              <w-radio-group
                row
                class="pt-0 mt-3"
                v-model="phone_number['caller_id']['type']"
                hide-details='auto'
              >
                <v-radio
                  :label="l.t('app.personal', 'Personal')"
                  name="route_caller_id_type"
                  value="personal"
                  data-test-id="phone-numbers-settings-type-peronal-radio"
                ></v-radio>
                <v-radio
                  :label="l.t('app.business', 'Business')"
                  name="route_caller_id_type"
                  value="business"
                  data-test-id="phone-numbers-settings-type-business-radio"
                ></v-radio>
              </w-radio-group>
            </div>
          </FormInput>
          <FormInput
            :label="l.t('phone-number-settings.record-calls', 'Record calls')"
          >
            <w-switch
              v-model="phone_number['record_calls']"
              :label="l.t('app.enable', 'Enable')"
              @change="on_record_calls_change"
              hide-details='auto'
              data-test-id="phone-numbers-settings-record-calls-switch"
            />
          </FormInput>
          <FormInput :label="l.t('phone-number-settings.sms-forwarding', 'SMS Inbox')">
            <ExtensionSelector
              :value="phone_number.sms_forwarding && phone_number.sms_forwarding.extension ? phone_number.sms_forwarding.extension : null"
              @input="onExtensionInput"
              :return_object="true"
              :options_list="['notifications']"
              :null_text="l.t('app.none', 'None')"
              data-test-id="phone-numbers-settings-sms-inbox-extension-selector"
            />
          </FormInput>
          <FormInput :label="l.t('app.incoming-call-notifications', 'Incoming call notifications')">
            <SmsEmailNotifications
              :label="l.t('app.enable', 'Enable')"
              v-model="phone_number.call_notifications"
            />
          </FormInput>
          <FormInput>
            <w-btn :disabled="!may_submit" type="submit" class="primary" data-test-id="phone-numbers-settings-save-btn">{{ l.t('app.save', 'Save') }}</w-btn>
          </FormInput>
        </w-form>
        <ClassicOrNxt user="classic">
          <AccountSettingsModal
            v-model="show_system_settings_modal"
            :title="l.t('phone-numbers.to-record-calls-on-this-number', 'To record calls on this number, make sure to adjust your system settings accordingly.')"
            scroll_to="call-recording"
            @updated="on_account_update"
          />
        </ClassicOrNxt>
        <ClassicOrNxt user="nxt">
          <DefaultModal v-model="show_system_settings_modal" max-width="450">
            {{l.t('app.the-recording-feature-is-included-with-each-pro-user', 'The recording feature is included with each Pro User, if you enable more recordings than Pro Users on the account you will be charged extra per additional recording as per your NXT plan.')}}
            <template v-slot:buttons>
              <w-btn color="secondary" @click="show_system_settings_modal = false">{{l.t('app.close', 'Close')}}</w-btn>
            </template>
          </DefaultModal>
        </ClassicOrNxt>
    </div>
</template>

<script>
  import {get_account_information} from 'phoenix-session-helpers';
  import l from '../../libs/lang';
  import FormInput from '../elements/form/FormInput.vue';
  import ExtensionSelector from './ExtensionSelector.vue';
  import ClassicOrNxt from '../elements/ClassicOrNxt.vue';
  import DefaultModal from '../elements/modal/DefaultModal.vue';
  import SmsEmailNotifications from '../elements/SmsEmailNotifications.vue';
  import AccountSettingsModal from '../elements/modal/AccountSettings.vue';
  import SectionTitle from '../elements/SectionTitle.vue';

  export default {
    props: ['id', '_phone_number'],
    components: {
      FormInput,
      DefaultModal,
      ClassicOrNxt,
      ExtensionSelector,
      AccountSettingsModal,
      SmsEmailNotifications,
      SectionTitle
    },
    data() {
      return {
        l,
        alert: null,
        loading: true,
        phone_number: null,
        caller_id_enabled: false,
        may_submit: false,
        account_information: null,
        show_system_settings_modal: false,
      };
    },
    async created() {
      if (this.$props._phone_number) {
        this.$data.phone_number = this.prepare_phone_number_data(
          this.$props._phone_number,
        );
        this.$data.loading = false;
      } else {
        // eslint-disable-next-line no-unused-expressions
        this.$data.loading
        && this.$session
          .get_item(`/phone-numbers/${this.$props.id}`)
          .then((res) => {
            this.$data.phone_number = this.prepare_phone_number_data(res);
            this.$data.loading = false;
          })
          .catch((e) => this.$emit('failed', e));
      }
      await this.get_account_information();
    },
    methods: {
      async get_account_information() {
        this.$data.account_information = await get_account_information(this.$session);
      },
      async on_account_update() {
        await this.get_account_information();
        this.$global_emitter.$emit('form_input_changed');
      },
      prepare_phone_number_data(res) {
        this.$data.caller_id_enabled = !!res['caller_id']
          && (res['caller_id']['name'] || res['caller_id']['type']);
        if (!this.$data.caller_id_enabled) {
          res['caller_id'] = null;
        }
        if (res.call_notifications && typeof res.call_notifications === 'object') {
          if (!res.call_notifications.sms) {
            res.call_notifications.sms = '';
          }
          if (!res.call_notifications.emails) {
            res.call_notifications.emails = [];
          }
        }
        return res;
      },
      onExtensionInput(data) {
        if (!data) return this.$data.phone_number['sms_forwarding'] = null;
        return this.$data.phone_number['sms_forwarding'] = {
          type: 'extension',
          extension: { id: data.id },
        };
      },
      submitted(e) {
        if (e) e.preventDefault();
        const data = { ...this.$data.phone_number };
        if (data['route']) {
          data['route'] = {
            id: data['route']['id'],
          };
        }
        if (!this.$data.caller_id_enabled) {
          data['caller_id'] = null;
        }
        this.$data.phone_number = this.prepare_phone_number_data(data);
        this.$emit('submitted', data);
      },
      on_record_calls_change(val) {
        if (
          val
          && this.$data.account_information
          && this.$data.account_information.features
          && !this.$data.account_information.features['call-recording-on']
        ) {
          this.$data.show_system_settings_modal = true;
        }
      },
    },
    watch: {
      caller_id_enabled(val) {
        if (val && !this.$data.phone_number.caller_id) {
          this.$data.phone_number.caller_id = {
            name: null,
            type: 'business',
          };
        }
      },
    },
  };
</script>
