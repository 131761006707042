import React, { useContext, useState } from 'react'
import { CallState, PdcCallContext, PdcCallConsumer, SipCallSession } from 'pdc-calls'
import {
    MergeIcon, EndCallButtonIcon,
    CallIncomingIcon,
    PauseIcon, VolumeUpIcon
} from 'svg-icons/src'
import Timer from 'timer'
import Typography from 'typography'
import { makeStyles } from '@material-ui/core'
import styles from './SessionBarStyles'
import { MyPhoneContactContextProps, MyPhoneContactContext } from 'providers/src'
import { formatPhoneNumber } from 'phone-numbers'

const useStyles = makeStyles(styles)

type SessionBarVariant = 'primary' | 'call-view'
interface SessionIdProp{
    sessionId: string
}
function MergeCall (props: SessionIdProp): JSX.Element {
    const { sessionId } = props
    const classes = useStyles()
    return (<PdcCallConsumer>
        {(context: any) => (<>
            {(context.activeCallId && context.calls[sessionId]?.isOnHold && sessionId !== context.activeCallId)
                ? <span className={`${classes.sessionControlButton} merge`} onClick={() => context.mergeCall(sessionId)}>
                    <MergeIcon style={{ width: '16px', height: '16px' }}/>
                </span>
                : <></>}
        </>)}
    </PdcCallConsumer>)
}
function EndCall (props: SessionIdProp): JSX.Element {
    const classes = useStyles()
    return (<PdcCallConsumer>
        {(context: any) => (<>
            <EndCallButtonIcon className={`${classes.sessionControlButton} end`} onClick={() => context.hangupById(props.sessionId)}/>
        </>)}
    </PdcCallConsumer>)
}

function SessionStateIcon (props: {sessionId: string, variant?: SessionBarVariant}) {
    const { sessionId } = props
    const classes = useStyles()
    return (<PdcCallConsumer>
        {(context: any) => (<>
            {context.calls[sessionId]?.callState === CallState.INCOMING
                ? <CallIncomingIcon className={`${classes.statusIcon} ${props.variant} on-hold`}/>
                : context.calls[sessionId]?.isOnHold
                    ? <PauseIcon className={`${classes.statusIcon} ${props.variant} on-hold`}/>
                    : <VolumeUpIcon className={classes.statusIcon}/>}
        </>)}
    </PdcCallConsumer>)
}

interface SessionBarProps{
    session: SipCallSession
    disableMerge?: boolean
    variant?: SessionBarVariant
}
function SessionBar (props: SessionBarProps): JSX.Element {
    const { participants, callStartTime, callId, callState, isMerged, isOnHold } = props.session
    const classes = useStyles()
    const PdcCall = useContext(PdcCallContext)
    const Contacts: MyPhoneContactContextProps = useContext(MyPhoneContactContext)
    const theirInfo = participants[0]
    const phoneNumber = theirInfo?.phoneNumber
    const [callerId, setCallerId] = useState(formatPhoneNumber(theirInfo?.callerId))
    Contacts.findExactMatch(phoneNumber).then(res => { if (res) setCallerId(res.displayName) })
    const onHold = isOnHold || callState === CallState.INCOMING
    const listItemClass = `${classes.sessionsListItem} ${props.variant} ${isOnHold || callId !== PdcCall.activeCallId ? 'on-hold' : ''}`
    const callerIdClass = `${classes.callerId} ${props.variant} ${onHold ? 'on-hold' : ''}`
    const Controls = (): JSX.Element => {
        return (<span className={classes.sessionControls}>
            <EndCall sessionId={callId}/>
            {props.disableMerge || isMerged ? <></> : <MergeCall sessionId={callId}/>}
        </span>)
    }
    const onClick = () => {
        if (callState === CallState.INCOMING) PdcCall.answerById(callId)
        else if (!isMerged) PdcCall.switchCall(callId)
    }
    return (<div className={listItemClass}>
        <span onClick={onClick}>
            <SessionStateIcon sessionId={callId} variant={props.variant}/>
            <span className={callerIdClass}>
                <Typography variant={'body2'} component={'span'}>
                    {callerId}
                </Typography>
            </span>
            <span className={classes.timer}>
                <Typography variant={'body2'} component={'span'}>
                    {callState === CallState.ACTIVE ? <Timer startTime={callStartTime}/> : '00:00'}
                </Typography>
            </span>
        </span>
        <Controls/>
    </div>)
}
interface SessionsBarSelectorProps{
    sessions: SipCallSession[]
    disableMerge?: boolean
    variant?: SessionBarVariant
}
/**
 *
 */
export default function SessionsBarSelector (props: SessionsBarSelectorProps): JSX.Element {
    const classes = useStyles()
    const sessions = props.sessions
    return (<div className={`${classes.sessionsList} ${props.variant}`}>
        {sessions.map(s => <SessionBar key={s.callId} session={s} disableMerge={props.disableMerge} variant={props.variant}/>)}
    </div>)
}
