import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import { getPhoneCom } from 'phonecom'

class API {
    static loadFaxes = async (offset, limit, filters, sort) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/faxes/list-faxes`
        const response = await ajax.postAsGet(requestUrl, { filters, sort, limit, offset })
        if (response.message === 'Network Error') return 'network-error'
        return response.data
    }

    static isFaxConfigured = () => {
        return getPhoneCom().then(phonecom => {
            const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/fax/is-fax-configured`
            return ajax.post(requestUrl, { account_id: phonecom.voip_id, extension_id: phonecom.voip_phone_id }).then(response => response.data?.fax_configured_setup)
        })
    }

    static markFaxRead = (fax_id, is_new, fax_type) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/faxes/mark-fax-read`
        return ajax.post(requestUrl, { fax_id, is_new, fax_type }).then(response => !response.data?.errorMessage)
    }

    static deleteFax = (fax_id, direction) => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot()}/faxes/delete-fax`
        return ajax.post(requestUrl, { fax_id, direction }).then(response => !response.data?.errorMessage)
    }

    static getFaxLink = uuid => {
        const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/fax/get-fax-link`
        return ajax.post(requestUrl, { uuid: uuid, type: 'inbound' }).then(response => response.data)
    }

    static sendFax = (toNumber, media, from) => {
        const body = { to: toNumber, media }
        if (from) body.caller_id = from
        return ajax.post(`${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/fax/send-fax`, body).then(response => response.data ? response.data : response)
    }

    static authorizeUpload = () => ajax.post(`${PhoneComUser.getv5ApiRoot()}/media/authorize-upload`, {}).then(response => response?.data)

    static doc2pdf = (s3_bucket, s3_key) => {
        const body = { s3_bucket_name: s3_bucket, s3_key_name: s3_key }
        return ajax.post(`${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/fax/doc2pdf`, body).then(response => response?.data?.message)
    }
}
export default API
