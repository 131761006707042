import { RefObject, useCallback } from 'react'
import { useEventListener } from './useEventListener'

function doesElementContainElement (parent: HTMLElement | RefObject<HTMLElement>, target: HTMLElement | RefObject<HTMLElement>): boolean {
    const wrapperElement = parent instanceof HTMLElement ? parent : parent.current
    const targetElement = target instanceof HTMLElement ? target : target.current
    if (!wrapperElement || !targetElement) return false
    return wrapperElement.contains(targetElement)
}

/***/
export function useOnClickAway (
    target: HTMLElement | null | RefObject<HTMLDivElement>,
    onClickAway: () => void,
    parentTarget = document.getElementById('root') as HTMLElement,
    excludeElements: (HTMLElement | RefObject<HTMLElement>)[] = []
): void {
    const handleClick = useCallback((event: MouseEvent) => {
        if (!target) return
        if (
            event.target instanceof HTMLElement &&
            !doesElementContainElement(target, event.target) &&
            doesElementContainElement(parentTarget, event.target) &&
            !excludeElements.some(excludeElement => doesElementContainElement(excludeElement, event.target as HTMLElement))
        ) {
            onClickAway()
        }
    }, [target, onClickAway, parentTarget])
    useEventListener('click', handleClick, parentTarget)
}

export default useOnClickAway
