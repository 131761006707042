import React, {Component} from 'react'
import { videoIcon, pdfIcon, docIcon, xlsIcon, pptIcon, zipIcon, defaultFileIcon } from 'pdc-icons'
import openofficeIcon from '../images/openoffice.jpg'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	fileIconWrapper: {
		position:	'relative',
		width:		200,
		fontSize:	75
	},
	extensionText: {
		position:		'absolute',
		bottom:			'0.2em',
		left:			'0.3em',
		fontSize:		'1em',
		fontWeight:		500,
		textTransform:	'uppercase',
		color:			'white',
		textAlign:		'center',
		width:			'1em',
		height:			'1em',
		transform:		'rotate(270deg)',
		lineHeight:		1
	}
})

class MediaModalOtherType extends Component {

	render() {
		return (
			<div className='current-media other-type-media not-selectable'>
				<div>
					{this.renderText()}
				</div>
			</div>
		);
	}

	renderText() {

		const { classes } = this.props

		let mediaType = this.props.mediaType;
        let icons = {
            document:		docIcon,
			sheet:			xlsIcon,
			presentation:	pptIcon,
			openoffice:		openofficeIcon,
			archive:		zipIcon,
			video:			videoIcon,
			pdf:			pdfIcon,
			other:			defaultFileIcon
		}

		let filenameSplit = this.props.filename.split('.')
        let fileExtension = filenameSplit.length > 1 ? filenameSplit.pop() : null

		return (
			<div className={'other-type-media-content-wrapper' + (this.props.isMobileView ? ' mobile-view' : '')}>
				<div className={classes.fileIconWrapper} title={this.props.filename}>
					<img
						style	= {{width: '100%'}}
						src		= {icons[mediaType]}
						alt		= {fileExtension || mediaType}
					/>
					<span className={`${classes.extensionText} not-selectable` + (mediaType === 'other' ? '' : ' hidden')}>.{fileExtension || 'File'}</span>
				</div>
				<div className='media-content-info'>
					<span className='media-content-filename'>{this.props.filename}</span>
					<a href={this.props.mediaUrl} target='_blank' rel='noopener noreferrer'>Click to download</a>
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(MediaModalOtherType)