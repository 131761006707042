/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { switchView } from '../../actions/view.js'
import { switchConversation, deleteConversations, updateMessage, updateConversation } from '../../actions/conversations'
import HeaderBar from 'header-bar'
import ClickableContact from './ClickableContact'
import conferenceVideoIcon from '../../images/conference-video-icon.png'
import api from '../../util/api_v2'
import ConversationHeaderDropdownMenu from '../ConversationHeaderDropdownMenu'
import ConfirmDeleteModal from 'confirm-delete-modal'
import { withStyles } from '@material-ui/core'
import gtmDataPush from 'gtm-events'
import { FlagIcon } from 'pdc-svg-icons'
import { DefaultTooltip } from 'tooltips'

const styles = theme => ({
    participants: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        cursor: 'pointer'
    },
    actions: {
        display: 'flex'
    },
    readStatusButton: {
        cursor: 'pointer',
        '&:hover': {
            '& .mark-read, & .mark-unread': {
                display: 'none'
            }
        },
        '&:not(:hover)': {
            '& .mark-read-hover, & .mark-unread-hover': {
                display: 'none'
            }
        }
    }
})

const mapStateToProps = state => {
    return {
        smallView: state.smallView,
        bridgeId: state.bridgeId,
        sendNumber: state.selectedSendNumber,
        conversations: state.conversations
    }
}

const mapDispatchToProps = dispatch => {
    return {
        switchView: view => dispatch(switchView(view)),
        switchConversation: conv => dispatch(switchConversation(conv)),
        deleteConversations: convs => dispatch(deleteConversations(convs)),
        updateMessage: (message, conversation_id) => dispatch(updateMessage(message, conversation_id)),
        updateConversation: conv => dispatch(updateConversation(conv))
    }
}

class ConversationHeader extends Component {
    state = {
        showDeleteConversationDialog: false,
        unreadIconHovered: false
    }

    deleteAndSwitchConversation = async () => {
        this.hideDialog()

        const currentConversation = this.props.currentConversation
        const currentConversationId = currentConversation.id
        this.props.deleteConversations([currentConversation])

        let switchConversation = null
        if (this.props.conversations.length > 0) {
            switchConversation = this.props.conversations[0]
            if (switchConversation.id === currentConversationId) {
                if (this.props.conversations.length > 1) {
                    switchConversation = this.props.conversations[1]
                } else {
                    switchConversation = null
                }
            }

            if (switchConversation) {
                this.props.updateRouterHistory(`c${switchConversation.id}`)
                this.props.switchConversation(switchConversation)
            }
        }

        if (switchConversation === null) {
            this.props.switchView('new_conversation')
        }

        gtmDataPush({
            PDC_Action: 'conversation-deleted'
        })
        await api.deleteConversation(currentConversation.id)
        this.props.onConversationDeleted(currentConversationId)
    }

    renderParticipants () {
        const { classes } = this.props
        const hasParticipants = this.props.participants.length
        if (!hasParticipants) return 'Empty conversation'

        return (
            <span
                className = {classes.participants}
                onMouseEnter = {() => this.props.toggleParticipantsHover(true)}
                onMouseLeave = {() => this.props.toggleParticipantsHover(false)}
            >
                {this.props.participants.map((participant, index) => {
                    const contact = this.props.extraContacts.find(c => {
                        let isParticipantExists = c.id === participant.voip_contact_id
                        if (isParticipantExists) return true
                        isParticipantExists = c.numbers.some(numberObj => numberObj.number === participant.number)
                        return isParticipantExists
                    })
                    const name = contact ? contact.name.display : ''
                    const number = participant.number
                    return [
                        index > 0 && ', ',
                        <ClickableContact key={index} name={name} num={number}/>
                    ]
                })}
            </span>
        )
    }

    renderActions = () => {
        const { classes } = this.props
        const isRead = this.isLastShownIncomingMessageRead()
        return <div className={classes.actions}>
            <span
                className = {classes.readStatusButton}
                onClick = {this.markConversationUnread}
                onMouseEnter = {() => this.setUnreadIconHovered(true)}
                onMouseLeave = {() => this.setUnreadIconHovered(false)}
            >
                <DefaultTooltip
                    title = {'Mark conversation unread'}
                    placement = 'bottom'
                    interactive = {true}
                    leaveDelay = {1}
                    enterTouchDelay = {0}
                >
                    <span>{isRead ? <FlagIcon hover={this.state.unreadIconHovered ? 1 : 0} /> : null}</span>
                </DefaultTooltip>
            </span>
            <ConversationHeaderDropdownMenu deleteConversation={this.onDeleteClick}/>
        </div>
    }

    renderVideoConferenceButton () {
        return (
            <div className={`video-conference-button ${!this.props.bridgeId ? ' not-allowed' : ''}`}
                onClick={this.startVideoConference.bind(this)}><img src={conferenceVideoIcon} alt='Conference video icon'/></div>
        )
    }

    startVideoConference () {
        const bridgeId = this.props.bridgeId
        if (bridgeId === undefined) {
            // Still not loaded
            return console.warn('Bridge Id not loaded yet')
        }

        if (bridgeId === null) {
            return this.props.toggleConfigureBridgePopup(true)
        }

        const videoUrl = `https://video.phone.com/${bridgeId}`
        const message = `Let's do a video session. Bridge number: ${bridgeId} To join the video meeting: ${videoUrl} Otherwise, to join by phone, dial: +1 805 309 0111 then enter ${bridgeId}# Call link: +1-805-309-0111,,,${bridgeId}#`
        this.props.setMessageToBeSent({ text: message, media: [], eventType: 'start-video-session' })
        window.open(videoUrl, '_blank')
    }

    onDeleteClick = () => this.setState({ showDeleteConversationDialog: true })

    hideDialog = () => this.setState({ showDeleteConversationDialog: false })

    goToConversationSelector = () => {
        this.props.switchView('select')
        this.props.updateRouterHistory('')
        this.props.switchConversation(null)
    }

    markConversationUnread = async () => {
        const currentConversation = this.props.currentConversation
        const currentMessages = currentConversation.messages
        const lastIncomingMessage = currentMessages.slice().reverse().find(m => m.direction === 'in')
        if (!lastIncomingMessage || !lastIncomingMessage.read_at) return
        lastIncomingMessage.read_at = null
        const conversationId = currentConversation.id
        this.props.updateMessage(lastIncomingMessage, conversationId)
        const messageId = lastIncomingMessage.message_id
        currentConversation.pauseMarkingRead = true
        currentConversation.unread_messages++
        currentConversation.last_unread_message_id = messageId
        currentConversation.readStatusChangedAt = Date.now()
        this.props.updateConversation(currentConversation)
        this.props.changeMessageReadStatus('unread', 1)
        api.markMessageUnread(messageId)
    }

    isLastShownIncomingMessageRead = () => {
        const currentMessages = this.props.currentConversation.messages
        const lastIncomingMessage = currentMessages && currentMessages.slice().reverse().find(m => m.direction === 'in')
        return Boolean(lastIncomingMessage && lastIncomingMessage.read_at)
    }

    setUnreadIconHovered = unreadIconHovered => this.setState({ unreadIconHovered })

    render () {
        return (
            <div>
                <HeaderBar
                    returnFnc = {this.goToConversationSelector}
                    smallView = {this.props.smallView}
                >
                    {this.renderParticipants()}
                    {/* {(this.props.sendNumber && false) ? this.renderVideoConferenceButton() : null} */}
                    {this.props.children}
                    {this.renderActions()}
                </HeaderBar>
                <ConfirmDeleteModal
                    itemType = 'conversation'
                    isShown = {this.state.showDeleteConversationDialog}
                    acknowledgedTitle = 'Delete permanently?'
                    notAcknowledgedTitle = 'Delete permanently?'
                    acknowledgedMainContent = 'All messages with this person or group will be deleted.'
                    notAcknowledgedMainContent = {'Deleting a conversation with a person or group will remove the entire conversation. It\'s permanent and cannot be undone.'}
                    onClose = {this.hideDialog}
                    onDelete = {this.deleteAndSwitchConversation}
                    size = 'size2'
                />
            </div>
        )
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ConversationHeader))
