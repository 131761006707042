import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { formatPhoneNumber } from 'phone-numbers'
import { withStyles } from '@material-ui/core'
import InfiniteScroller from 'pdc-infinite-scroller'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import SelectionControl from 'selection-control'
import Options from 'pdc-options'
import Spinner from 'spinner'
import { XIcon } from 'pdc-svg-icons'
import PhoneNumberName from './PhoneNumberName'

const styles = theme => ({
    mainWrapper: {
        height: '100%',
        width: '100%',
        '& > div': {
            // padding: 20
        },
        '& .infinite-scroller': {
            paddingLeft: 10
        }
    },
    phoneNumberRow: {
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid #DEE1E3'
    },
    rowMain: {
        display: 'flex',
        alignItems: 'center',
        height: 90,
        cursor: 'pointer'
    },
    infoColumn: {
        display: 'inline-grid',
        marginLeft: 20,
        '& .number': {
            fontWeight: 600,
            fontSize: 16,
            maxWidth: 160,
            wordBreak: 'break-all'
        },
        '& .action': {
            fontSize: 14,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    optionsColumn: {
        marginLeft: 'auto'
    },
    // expandable area
    rowExpandableContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 20,
        paddingRight: 20,
        transition: 'max-height .2s, padding .2s',
        overflow: 'hidden'
    },
    expRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 25,
        '& .expRowLabel': {
            fontSize: 14,
            fontWeight: 600,
            width: '30%',
            maxWidth: 50,
            marginRight: 40
        },
        '& .expRowData': {
            fontSize: 14,
            marginRight: 'auto',
            '&.padding14': {
                paddingLeft: 14
            },
            '&.padding8': {
                paddingLeft: 8
            }

        }
    },
    // expandable area end
    // optionsDrawer
    optionsDrawer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    optionsDrawerHeader: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        height: 130,
        borderBottom: '1px solid #DEE1E3',
        position: 'relative'
    },
    drawerInfoColumn: {
        textAlign: 'center',
        '& .number': {
            fontWeight: 600,
            fontSize: 16,
            maxWidth: 160,
            wordBreak: 'break-all'
        },
        '& .action': {
            fontSize: 14,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    },
    optionsDrawerItem: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        height: 56,
        borderBottom: '1px solid #DEE1E3',
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.primary['-300']
        }
    },
    optionsDrawerFooter: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        alignItems: 'center',
        minHeight: 130
    },
    xIcon: {
        position: 'absolute',
        right: 15,
        top: 15,
        color: theme.palette.secondary['0'],
        cursor: 'pointer'
    }
    // options drawer end
})

const mapStateToProps = state => ({
    phoneNumbers: state.phoneNumbers
})

const mapDispatchToProps = dispatch => ({})

class MyNumbersSmall extends Component {
    constructor (props) {
        super(props)
        this.state = {
            openDrawer: false,
            drawerNumber: null,
            expandedNumber: null
        }
    }

    toggleExpandedPhneNumerArea = phoneNumber => {
        const oldNumber = this.state.expandedNumber
        if (oldNumber && oldNumber.id !== phoneNumber.id) {
            this.props.changeValueInNumber(oldNumber, 'expanded', !oldNumber.expanded)
        }
        this.setState({ expandedNumber: phoneNumber.expanded === true ? null : phoneNumber })
        this.props.changeValueInNumber(phoneNumber, 'expanded', !phoneNumber.expanded)
    }

    handleClick = (item) => {
        // Prevent a second click on Delete Number, since that's an error
        if (item.text === 'Delete Number') {
            this.setState({ openDrawer: false })
        }
        item.onClick()
    }

    renderOptionsDrawerItem = item => {
        const { classes } = this.props
        return (
            <div data-drawer-item={item.text} key={item.text} className={classes.optionsDrawerItem} onClick={() => this.handleClick(item)}>
                {item.text}
            </div>
        )
    }

    renderOptionsDrawer = () => {
        const { classes } = this.props

        const phoneNumber = this.state.drawerNumber
        if (!phoneNumber) return <div>Something went wrong...</div>

        const drawerItems = this.props.getDropdownItems(phoneNumber)

        return (
            <div data-drawer={phoneNumber.id} className={classes.optionsDrawer}>
                <div className={classes.optionsDrawerHeader}>
                    <div className={classes.drawerInfoColumn}>
                        <div className='number'>{formatPhoneNumber(phoneNumber.phone_number)}</div>
                        <div className='action'>{this.props.renderAction(phoneNumber)}</div>
                    </div>
                    <XIcon onClick={() => { this.setState({ openDrawer: false, drawerNumber: null }) }} className={classes.xIcon}/>
                </div>
                {drawerItems.map(this.renderOptionsDrawerItem)}
                {/* <div className={classes.optionsDrawerFooter}>
                        footer
                    </div> */}
            </div>
        )
    }

    renderExpandedArea = phoneNumber => {
        const { classes } = this.props

        const padding = phoneNumber.expanded ? 20 : 0
        return (
            <div
                data-expandable-area={phoneNumber.id}
                className={classes.rowExpandableContainer}
                style={{ maxHeight: phoneNumber.expanded ? 278 : 0, paddingTop: padding, paddingBottom: padding }}
            >
                <div className={classes.expRow}>
                    <div className='expRowLabel'>
                        Name
                    </div>
                    <div className='expRowData'>
                        <PhoneNumberName
                            phoneNumber={phoneNumber}
                            updatePhoneNumber={this.props.updatePhoneNumber}
                            isMobileView={true}
                        />
                    </div>
                </div>
                <div className={classes.expRow}>
                    <div className='expRowLabel'>
                        Action
                    </div>
                    <div className='expRowData padding14'>
                        {this.props.renderAction(phoneNumber)}
                    </div>
                </div>
                <div className={classes.expRow}>
                    <div className='expRowLabel'>
                        Inbox
                    </div>
                    <div className='expRowData padding14'>
                        Inbox Value
                    </div>
                </div>
                {!this.props.hideCallRecording && <div className={classes.expRow}>
                    <div className='expRowLabel'>
                        Record
                    </div>
                    <div className='expRowData padding8'>
                        {/* TODO: deprecate, use PdcSwitch */}
                        <SelectionControl
                            variant = 'switch'
                            checked = {Boolean(phoneNumber.record_calls)}
                            onClick = {() => this.props.onToggleCallRecordingClick(phoneNumber)}
                            name = 'record-switch'
                            value = {`switch-${phoneNumber.id}`}
                        />
                    </div>
                </div>}
            </div>
        )
    }

    renderNumberRow = phoneNumber => {
        const { classes } = this.props
        return (
            <div key={phoneNumber.id} className={`${classes.phoneNumberRow}`}>
                <div className={classes.rowMain} onClick={() => this.toggleExpandedPhneNumerArea(phoneNumber)}>
                    <div className={classes.infoColumn}>
                        <div className={'number'}>{formatPhoneNumber(phoneNumber.phone_number)}</div>
                        <div className={'action'}>{this.props.renderAction(phoneNumber)}</div>
                    </div>
                    <div className={classes.optionsColumn}><Options onClick={(e) => { this.setState({ openDrawer: true, drawerNumber: phoneNumber }) }} variant='vertical'/></div>
                </div>
                {this.renderExpandedArea(phoneNumber)}
            </div>

        )
    }

    render = () => {
        const { classes } = this.props
        return (
            <div className={classes.mainWrapper}>
                <InfiniteScroller
                    reverseScroll = {false}
                    loadMore = {this.props.loadMore}
                    hasMore = {this.props.hasMore}
                    loader = {<Spinner/>}
                >
                    {this.props.phoneNumbers.items.map((phoneNumber) => (
                        this.renderNumberRow(phoneNumber)
                    ))}
                </InfiniteScroller>
                <SwipeableDrawer
                    classes={{ paperAnchorBottom: classes.drawerStyle }}
                    anchor='bottom'
                    open={this.state.openDrawer}
                    onClose={() => this.setState({ openDrawer: false, drawerNumber: null })}
                    onOpen={() => this.setState({ openDrawer: true })}
                >
                    {this.renderOptionsDrawer()}
                </SwipeableDrawer>
            </div>
        )
    }
}

MyNumbersSmall.propTypes = {
    classes: PropTypes.object,
    phoneNumbers: PropTypes.object,
    loadMore: PropTypes.func,
    hasMore: PropTypes.bool,
    renderAction: PropTypes.func,
    onToggleCallRecordingClick: PropTypes.func,
    getDropdownItems: PropTypes.func,
    updatePhoneNumber: PropTypes.func,
    changeValueInNumber: PropTypes.func,
    hideCallRecording: PropTypes.bool
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MyNumbersSmall))
