<template>
	<v-row no-gutters class="pa-0 datetime-picker">
	    <v-col md="6" cols="12" class="py-0 pl-0 datetime-picker-date-container">
			<v-menu
				v-model="date_menu"
				:close-on-content-click="false"
				:nudge-right="40"
				:nudge-top="date_nudge_top"
				transition="scale-transition"
				offset-y
				top
				min-width="auto"
				class="w-100"
			>
				<template v-slot:activator="{ on, attrs }">
					<w-text-field
						v-model="display_date"
						not-clearable
						:placeholder="l.t('app.date', 'Date')"
						readonly
						color="secondary"
						:required="required"
						:disabled="disabled"
						v-bind="attrs"
						v-on="on"
						class="readonly-input w-100"
						:class="{'active-input': date_menu}"
						hide-details='auto'
					>
						<template v-slot:prepend-inner>
							<v-icon :color="date_menu ? 'primary' : 'text'" @click="date_menu = !date_menu" class="mt-2">$vuetify.icons.calendar</v-icon>
						</template>
					</w-text-field>
				</template>
				<v-date-picker
					v-if="date_menu"
					no-title
					:show-current="marked"
					v-model="date"
					color="primary"
					width="256"
					hide-details="auto"
				/>
			</v-menu>
	    </v-col>
	    <v-col md="6" cols="12" class="py-0 pr-0 datetime-picker-time-container">
			<TimeSelector class="w-100" v-model="time" :default_value="default_time" :key="`time-${!!time}`"/>
	    </v-col>
	</v-row>
</template>

<script>
	import l from '../../../libs/lang';
	import TimeSelector from './TimeSelector.vue';

	export default {
		props: ['datetime', 'required', 'marked_date', 'disabled', 'date_nudge_top', 'default_time'],
		components: { TimeSelector },
		created() {
			if (this.$props.datetime) {
				const parts = this.$props.datetime.split('T');
				this.$data.date = this.is_valiad_value(parts[0]) && parts[0];
				const time = this.is_valiad_value(parts[1]) && parts[1];
				if (time) {
					const time_parts = time.split(':');
					const hour = Number.parseInt(time_parts[0]);
					const minute = Number.parseInt(time_parts[1]);
					if (hour || minute || hour === 0 || minute === 0) {
						this.$data.time = `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`;
					} else {
						this.$data.time = null;
					}
				}
			}
			if (this.$props.marked_date) {
				this.$data.marked = this.$props.marked_date.split('T')[0];
			}
		},
		data() {
			return {
				l,
				hour: null,
				minute: null,
				date: null,
				display_date: null,
				date_menu: false,
				time: null,
				marked: null,
			};
		},
		methods: {
			value() {
				if (this.$data.date && this.$data.time) {
					return `${this.$data.date}T${this.$data.time}`;
				}
				return null;
			},
			is_valiad_value(val) {
				return val && !['null', 'undefined'].includes(val);
			}
		},
		watch: {
			date(val) {
				if (!val) {
					this.$data.display_date = null;
				} else {
					const parts = val.split('-');
					this.$data.display_date = `${parts[1]}/${parts[2]}/${parts[0]}`;
					if (!this.$data.time) {
						this.$data.time = this.$props.default_time || '00:00';
					}
				}
				this.$data.date_menu = false;
			},
			time() {
				if (this.$data.time && !this.$data.date) {
					const now = new Date();
					this.$data.date = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
				}
				const value = this.value();
				if (this.$props.datetime !== value) this.$emit('change', value);
				this.$emit('input', value);
			},
			date_menu(val) {
				if (!val) {
					if (this.$data.date && !this.$data.time) {
						this.$data.time = this.$props.default_time || '00:00';
					}
					const value = this.value();
					if (this.$props.datetime !== value) this.$emit('change', value);
					this.$emit('input', value);
				}
			},
		}
	};
</script>
<style lang="scss">
	>>> .v-input__slot::before {
		border-style: none !important;
	}
	.active-input {
		background: var(--v-primary-lighten5) !important;
		&.v-text-field input {
			color: var(--v-primary-base) !important;
		}
	}
	.readonly-input:hover {
		@extend .active-input;
	}
</style>
