<template>
    <w-loader v-if="schedule.loading"/>
    <div v-else class="create-page" data-test-id="schedules-create-page">
      <w-alert
        v-if="schedule.alert"
        :level="schedule.alert.level"
        :message="schedule.alert.message"
        :closable="schedule.enable_close_alert"
        @closed="schedule.alert = null"
        class="mb-6"
      />
      <ScheduleFragment
        v-if="schedule.item"
        :_schedule="schedule.item"
        @submited="schedule.create($event)"
      />
    </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import Schedule from '../../../models/Schedule';
  import ScheduleFragment from '../../fragments/Schedule.vue';

  export default {
    components: {
      ScheduleFragment,
    },
    data() {
      return {
        l,
        schedule: new Schedule(this.$session, vueComponent(this)),
      };
    },
  };
</script>
