import SmartFilters from './SmartFilters'
import setup from '../libs/resources-setups/voicemail'

/**
 *
 */
export default class Device extends SmartFilters {
    /**
     * @param {object} session Phoenix session
     * @param {object} component helpers vueComponent
     */
    constructor (session, component) {
        super(session, component, '/voicemail')
        this.setup = setup
        this.filters = {
            is_new: false,
            from: null,
            to: null,
            type: 'forever'
        }
        this.apply_type_range()
        this.empty_filters = JSON.parse(JSON.stringify(this.filters))
        this.config_key = 'voicemail-filters'
        this.deleting_item_id = null
    }

    /**
     * @param {object} params
     */
    async loadItems (params) {
        if (!this.extension) return null
        this.loading = true
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page)
            const items = await this.session.get_list(this.uri, params.limit, offset)
            this.page = params.page
            if (this.page > 1 && !items.items.length) {
                this.component.emit('pageChanged', 1)
                return this.loadItems({ limit: this.limit, offset: 0, page: 1 })
            }
            this.items = items.items
            this.finalizeLoadingItems(items)
            this.component.emit('itemsLoaded', JSON.parse(JSON.stringify(items)))
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false

        return this.items
    }

    /**
     * @param {number} id
     */
    async opened (id) {
        try {
            await this.session.patch_item(
                `/extensions/${this.extension}/voicemail/${id}`,
                { is_new: false }
            )
            for (const item of this.items) {
                if (item.id === id) {
                    item.is_new = false
                }
            }
        } catch (err) {
            this.show_generic_error(err)
        }
    }

    /**
     * @param {number} id
     */
    async delete_m (id) {
        this.deleting_item_id = id
        try {
            await this.session.delete_item(`${this.baseUri}/${id}`)
            this.items = this.items.filter((v) => v.id !== id)
        } catch (err) {
            this.validation_error(err)
        }
        this.deleting_item_id = null
    }

    /**
     *
     */
    apply_filters () {
        this.filters_applied = true
        let uri = `${this.baseUri}?mode=full`
        if (this.filters.is_new) {
            uri = `${uri}&filters[is_new]=${this.filters.is_new}`
        }
        if (this.filters.from) {
            uri = `${uri}&filters[from]=${this.filters.from}`
        }
        if (this.filters.to) {
            uri = `${uri}&filters[to]=${this.filters.to.phone_number}`
        }
        if (this.filters.start) {
            const start = Math.floor(Date.parse(this.filters.start) / 1000)
            uri = `${uri}&filters[created_at]=gte:${start}`
        }
        if (this.filters.end) {
            const end = Math.floor(Date.parse(this.filters.end) / 1000)
            uri = `${uri}&filters[created_at]=lte:${end}`
        }
        this.uri = uri
        this.hide_filters = false
    }
}
