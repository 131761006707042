import React, { useContext } from 'react'
import App from './src/components/App'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers'
import withTheme from '@material-ui/core/styles/withTheme'
import { PdcCallContext } from 'pdc-calls'
import { SoftphoneContext } from 'softphone'
import PropTypes from 'prop-types'

const store = createStore(rootReducer)
const Calls = (props) => {
    const softphoneContext = useContext(SoftphoneContext)
    const callContext = useContext(PdcCallContext)
    return (
        <Provider store={store}>
            <App
                isOffline={props.isOffline}
                extension={props.extension}
                contactsUtil={props.contactsUtil}
                screenViewType={props.screenViewType}
                standalone={props.standalone}
                onLoaded={props.onLoaded}
                subscribeForNotifications={props.subscribeForNotifications}
                resetSubscription={props.resetSubscription}
                updateUnreadCounts={props.updateUnreadCounts}
                changeVoicemailReadStatus={props.changeVoicemailReadStatus}
                redirect={props.redirect}
                setIsDialerOpen={props.setIsDialerOpen}
                appData={props.appData}
                makeCall={props.makeCall}
                openMakeACall={props.openMakeACall}
                openDialer={props.openDialer}
                setMyCallerInfo={callContext.setMyCallerInfo}
                myCallerInfo={callContext.myCallerInfo}
                currentCall={callContext.currentCall}
                routeProps={props.routeProps}
                softphoneContext={softphoneContext}
            />
        </Provider>
    )
}

Calls.propTypes = {
    isOffline: PropTypes.bool,
    extension: PropTypes.object,
    contactsUtil: PropTypes.object,
    screenViewType: PropTypes.object,
    standalone: PropTypes.bool,
    onLoaded: PropTypes.func,
    subscribeForNotifications: PropTypes.func,
    resetSubscription: PropTypes.func,
    updateUnreadCounts: PropTypes.func,
    changeVoicemailReadStatus: PropTypes.func,
    redirect: PropTypes.func,
    setIsDialerOpen: PropTypes.func,
    appData: PropTypes.object,
    makeCall: PropTypes.func,
    openMakeACall: PropTypes.func,
    openDialer: PropTypes.func,
    setMyCallerInfo: PropTypes.func,
    myCallerInfo: PropTypes.object,
    currentCall: PropTypes.object,
    routeProps: PropTypes.object,
    softphoneContext: PropTypes.object
}

export default withTheme(Calls)
