import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { Switch, Route } from 'react-router-dom'
import { LiveReceptionistIndex, LiveReceptionistCreate, LiveReceptionistShow } from 'live-receptionist-react-components'

const styles = (theme) => ({
    appWrapper: {}
})

class LiveReceptionistCommunicator extends Component {
    render () {
        const basePath = this.props.routeProps.match.path
        const pathName = this.props.routeProps.location.pathname
        let routeId = null
        if (pathName.includes('show') || pathName.includes('create')) {
            routeId = parseInt(pathName.substring(pathName.lastIndexOf('/') + 1))
        }
        const onClickLink = (route) => {
            if (route.name === 'live-answer.show' && route.params.id) this.props.routeProps.history.push(`${basePath}show/${route.params.id}`)
        }
        const onClickVendor = (id) => {
            this.props.routeProps.history.push(`${basePath}create/${id}`)
        }
        const onSave = () => {
            setTimeout(() => {
                this.props.routeProps.history.replace(basePath)
            }, 3000)
        }
        const onCancel = () => {
            this.props.routeProps.history.replace(basePath)
        }
        return (
            <>
                <Switch>
                    <Route path={`${basePath}`} exact render={(props) => (<LiveReceptionistIndex onClickLink={onClickLink.bind(this)} onClickVendor={onClickVendor.bind(this)} title={'Receptionist services'}/>)}/>
                    <Route path={`${basePath}show/${routeId}`} render={() => (<LiveReceptionistShow id={routeId} postSaveCb={onSave.bind(this)} onClickCancel={onCancel.bind(this)}/>)}/>
                    <Route path={`${basePath}create/${routeId}`} render={() => (<LiveReceptionistCreate vendor_id={routeId} postSaveCb={onSave.bind(this)} onClickCancel={onCancel.bind(this)}/>)}/>
                </Switch>
            </>
        )
    }
}

LiveReceptionistCommunicator.propTypes = {
    classes: PropTypes.object,
    extension: PropTypes.object,
    redirect: PropTypes.func.isRequired,
    routeProps: PropTypes.object,
    smallView: PropTypes.bool

}
export default withStyles(styles)(LiveReceptionistCommunicator)
