<template>
  <div class="common-progress">
    <div v-if="progress.title" class="progress-title info--text text--darken-1 w-body-1">
        {{progress.title}}
    </div>
    <div v-if="!progress.show_results">
        <div v-if="progress.percents || progress.percents === 0">
            <div class="primary--text text--darken-4 progress-percents w-h3">
                {{progress.percents}}%
            </div>
            <div class="bar-wrapper">
                <div class="bar" :style="{ width: progress.percents + '%' }">
                </div>
            </div>
            <transition name="slide-fade-right-left" mode="out-in">
                <div v-if="!progress.message && progress.total" class="progress-message w-caption text--text text--lighten-1">
                    {{
                        progress.message || l.t(
                            'app.progress-downloading-message',
                            'Downloading {} of {} recordings available',
                            [(progress.completed + progress.chunk_size) <= progress.total ? (progress.completed + progress.chunk_size) : progress.total, progress.total]
                        )
                    }}
                </div>
            </transition>
        </div>
        <w-btn
            @click="confirmation_modal = true"
            :disabled="progress.stop"
            color="error"
            class="progress-cancel-btn"
        >
            {{progress.stop ? l.t('app.canceling...', 'Canceling...') : progress.cancel_btn_text}}
        </w-btn>
        <DefaultModal v-model="confirmation_modal" max-width="350">
            <div class="w-subtitle-1">
                {{progress.confirm_cancel_message}}?
            </div>
            <template v-slot:buttons>
                <w-btn @clikc="confirmation_modal = false" color="secondary">{{l.t('app.cancel', 'Cancel')}}</w-btn>
                <w-btn @click="on_confirmed" color="error">{{l.t('app.yes', 'Yes')}}</w-btn>
            </template>
        </DefaultModal>
    </div>
    <div v-else class="progress-results">
        <div class="info--text text--darken-1 w-body-2">
            {{l.t('app.results', 'Results')}}
        </div>
        <div class="d-flex progress-results-wrapper">
            <div class="progress-successful-results">
                <div class="w-body-2 primary--text text--darken-1">
                    {{l.t('app.successfully-completed', 'Successfully completed')}}
                </div>
                <div  v-if="progress.results.success.length">
                    <div
                        v-for="mess in progress.results.success"
                        v-if="mess.html || mess.message"
                        v-html="mess.html || mess.message"
                        class="mt-3 w-body-2"
                    ></div>
                </div>
                <div v-else class="mt-3 w-body-2">
                    &mdash;
                </div>
            </div>
            <div class="progress-error-results text-right">
                <div class="w-body-2 error--text text--darken-1">
                    {{l.t('app.errors', 'Errors')}}
                </div>
                <div v-if="progress.results.error.length">
                    <div
                        v-for="mess in progress.results.error"
                        v-if="mess.html || mess.message"
                        v-html="mess.html || mess.message"
                        class="mt-3 w-body-2"
                    ></div>
                </div>
                <div v-else class="mt-3 w-body-2">
                    {{ l.t('app.no-errors', 'No errors') }}
                </div>
            </div>
        </div>
        <div class="text-center">
            <w-btn @click="$emit('closed')" color="secondary" class="progress-close-btn">{{l.t('app.close', 'Close')}}</w-btn>
        </div>
    </div>
  </div>
</template>
<script>
import l from '../../libs/lang';
import DefaultModal from './modal/DefaultModal.vue';

export default {
  props: {
      progress: {
          required: true,
          validator: (val) => val.constructor.name === 'Progress',
      }
  },
  components: {DefaultModal},
  data() {
    return {
        l,
        confirmation_modal: false,
    };
  },
  methods: {
    on_confirmed() {
        this.$props.progress.stop = true;
        this.$data.confirmation_modal = false;
    }
  }
};
</script>
