<template>
    <w-loader v-if="route.loading"/>
    <div v-else class="show-page" data-test-id="routes-show-page">
      <PageTitle class="nowrap">
        {{title()}}
      </PageTitle>
        <w-alert
          v-if="route.alert"
          :message="route.alert.message"
          :level="route.alert.level"
          :closable="route.enable_close_alert"
          @closed="route.alert = null"
          class="mb-6"
        />
        <w-form
          v-if="route.item"
          :validation="[form_validation.all_calls_required(route.item)]"
          :readonly="readonly"
          @invalid_form="on_invalid_form"
          @changed="may_submit = true"
          @submit.prevent="update_route"
        >
          <RouteConfigurationFragment
            :_route="route.item"
            :readonly="readonly"
            :disable_switcher="disable_switcher"
            @changed="route.item = $event"
            @click:cancel="$emit('click:cancel')"
          />
          <div class="d-flex justify-center mt-12">
            <SaveAsNewPreset
              v-if="route.item && !route.item.hasOwnProperty('name')"
              :get_route="() => route.item"
              data-test-id="chr-save-as-preset"
            />
            <CancelChanges @click:cancel="$emit('click:cancel')" class="mr-5"/>
            <w-btn :disabled="readonly || !may_submit" type="submit" color="primary" data-test-id="route-submit-btn">{{ l.t("app.save", "Save") }}</w-btn>
          </div>
        </w-form>
        <SameFiltersModal
          v-if="same_filters"
          :value="true"
          @input="same_filters = null"
          :filter="same_filters"
          @confirmed="do_update_route"
          :key="`same-filters-${same_filters}`"
        />
    </div>
</template>

<script>
  import {vueComponent, emptyRouteRule} from 'helpers';
  import l from '../../../libs/lang';
  import Route from '../../../models/Route';
  import FormValidation from '../../../libs/validation/form-rules';

  import CancelChanges from '../../elements/CancelChanges.vue';
  import SaveAsNewPreset from '../../elements/routes/SaveAsPreset.vue';
  import RouteConfigurationFragment from '../../elements/routes/RouteConfiguration.vue';
  import PageTitle from '../../elements/PageTitle.vue';
  import SameFiltersModal from '../../elements/routes/SameFiltersModal.vue';

  export default {
    name: 'routes-show',
    props: ['id', '_route', 'disable_switcher'],
    components: {
      PageTitle,
      CancelChanges,
      SaveAsNewPreset,
      SameFiltersModal,
      RouteConfigurationFragment,
    },
    data() {
      return {
        l,
        route: new Route(this.$session, vueComponent(this)),
        name: null,
        may_submit: false,
        form_validation: FormValidation,
        last_title: '',
        same_filters: false,
        readonly: false
      };
    },
    async created() {
      this.$data.route.loading = true;
      let route;
      if (this.$props._route || this.$route.params._route) {
        route = this.$props._route || this.$route.params._route;
      } else {
        route = await this.$data.route.getItem(this.$props.id ? this.$props.id : this.$route.params.id);
      }
      this.$data.route.item = route;
      this.$data.readonly = this.$data.route.item.generator && this.$data.route.item.generator === 'configure-app';
      this.$data.route.loading = false;
    },
    methods: {
      title() {
        let title = '';
        if (this.$data.route.item && this.$data.route.item.name) {
          title += l.t('app.route', 'Preset:');
          title += ` ${this.$data.route.item.name ? this.$data.route.item.name : ''}`;
          this.$data.last_title = title;
        } else {
          title = this.$data.last_title;
        }
        return title;
      },
      on_invalid_form(validation_rule_index) {
        if (validation_rule_index === 0) this.$data.route.item.rules.push(emptyRouteRule());
      },
      async update_route() {
        this.$data.same_filters = Route.has_the_same_filters(this.$data.route.item);

        if (!this.same_filters) await this.do_update_route();
        return true;
      },
      async do_update_route() {
        this.$data.same_filters = null;
        this.$data.route.loading = true;
        await this.$data.route.update();
        if (this.$data.route.alert.level !== 'error') this.$data.route.item = null;
      }
    },
  };
</script>
