import React, { createContext, useContext, useEffect, useState } from 'react'
import SoftphoneCalls from './Calls/SoftphoneCalls'

import Draggable from 'react-draggable'
import FeatureFlag from 'feature-flag'
import { CallStats, PdcCallContext } from 'pdc-calls'

import Typography from 'typography'
import { makeStyles, SvgIcon } from '@material-ui/core'
import styles from './styles'
import Phonebar from './Phonebar/Phonebar'
import { MinimizeIcon } from 'svg-icons'
import AudioRinger from './AudioRinger/AudioRinger'
import HashMicPermission from 'has-mic-permission'
import { getPhoneCom } from 'phonecom'
import Preferences from './Preferences'
import FullStory from 'full-story'
import { FeatureEventsContext } from 'providers/src'

/**
 *
 */
export interface ClassesProps{
    classes?: Record<string, string>
}
/***/
export interface ISoftphoneContext {
    HideSoftphone: () => void
    ShowSoftphone: () => void
    ToggleSoftphone: () => void
    isSoftphoneHidden: () => boolean
}

const useStyles = makeStyles(styles)

/**
 *
 */
export const SoftphoneContext = createContext<ISoftphoneContext>({
    /**
     *
     */
    HideSoftphone: () => console.log('hide'),
    /**
     *
     */
    ShowSoftphone: () => console.log('show'),
    /**
     *
     */
    ToggleSoftphone: () => console.log('toggle'),
    /**
     *
     */
    isSoftphoneHidden: () => false
})

interface Extension{
    // eslint-disable-next-line @typescript-eslint/naming-convention
    is_virtual: boolean
    extension: number
}
declare global {
    interface Window {
        innerHeight: number
        innerWidth: number
        addEventListener: () => null
        V5PHONECOM: {
            extension: number
            extensions: Extension[]
        }
    }
}

function VirtualExtensionMessage (): JSX.Element {
    const [loading, setLoading] = useState(true)
    const phoneComRef = React.useRef({ })

    useEffect(() => {
        const loadPhoneCom = async () => {
            const phoneCom = await getPhoneCom()
            phoneComRef.current = phoneCom
            setLoading(false)
        }
        loadPhoneCom()
    }, [])

    const classes = useStyles()

    if (loading) return <></>

    const extension = phoneComRef?.current?.extension
    const exts = phoneComRef?.current?.extensions
    const extInfo = exts?.find((e) => e.extension === extension)
    if (extInfo && !extInfo?.is_virtual) return (<></>)

    return (<div className={classes.virtualExtensionMessage}>
        <Typography variant={'h4'} color={'inherit'}>Virtual Extension Calling Is Coming Soon!</Typography>
    </div>)
}
/***/
export const SoftphoneConsumer = SoftphoneContext.Consumer

interface HeaderProps{
    children?: JSX.Element
    title?: JSX.Element
}
/**
 *
 */
export function SoftphoneHeader (props: HeaderProps): JSX.Element {
    const classes = useStyles()
    return (<div className={classes.softphoneHeader}>
        <div className={classes.headerTitle}>{props.title}</div>
        {props.children}
    </div>)
}
/**
 *
 */
function MinimizeButton (): JSX.Element {
    const classes = useStyles()
    const featureEventsContext = useContext(FeatureEventsContext)
    return (<SoftphoneConsumer>
        {(context: ISoftphoneContext) => (
            <div
                className = {`${classes.minimizeIcon} disable-dragging`}
                data-testid = 'hideSoftphone'
                onClick = {() => {
                    featureEventsContext.pushEvent({ appName: 'softphone', key: 'minimize-button-click' })
                    context.HideSoftphone()
                }}
            >
                <MinimizeIcon/>
            </div>
        )}
    </SoftphoneConsumer>)
}

interface CallConnectionStatsProps{
    callStats: CallStats
}
function CallConnectionStats (props: CallConnectionStatsProps): JSX.Element {
    if (props.callStats === null) return (<></>)
    const classes = useStyles()
    const mosScore = props.callStats.mosScore
    const opacity1 = mosScore < 1 ? 0.5 : 1
    const opacity2 = mosScore < 2 ? 0.5 : 1
    const opacity3 = mosScore < 3 ? 0.5 : 1
    const opacity4 = mosScore < 4 ? 0.5 : 1
    return (<span className={classes.callConnectionStats}>
        <SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path opacity={opacity4} d='M18.8499 7H18.1499C17.5976 7 17.1499 7.44772 17.1499 8V16C17.1499 16.5523 17.5976 17 18.1499 17H18.8499C19.4022 17 19.8499 16.5523 19.8499 16V8C19.8499 7.44772 19.4022 7 18.8499 7Z' fill={'white'}/>
            <path opacity={opacity1} d='M5.8499 13H5.1499C4.59762 13 4.1499 13.4477 4.1499 14V16C4.1499 16.5523 4.59762 17 5.1499 17H5.8499C6.40219 17 6.8499 16.5523 6.8499 16V14C6.8499 13.4477 6.40219 13 5.8499 13Z' fill={'white'}/>
            <path opacity={opacity3} d='M14.45 9H13.75C13.1977 9 12.75 9.44772 12.75 10V16C12.75 16.5523 13.1977 17 13.75 17H14.45C15.0023 17 15.45 16.5523 15.45 16V10C15.45 9.44772 15.0023 9 14.45 9Z' fill={'white'}/>
            <path opacity={opacity2} d='M8.44971 12C8.44971 11.4477 8.89742 11 9.44971 11H10.1497C10.702 11 11.1497 11.4477 11.1497 12V16C11.1497 16.5523 10.702 17 10.1497 17H9.44971C8.89742 17 8.44971 16.5523 8.44971 16V12Z' fill={'white'}/>
        </SvgIcon>
    </span>)
    /* add more info as pop up TODO
    const jitter = stats.jitter
    const averageLatency = call.getAverageLatency()
    const packetLost = stats.packetsLost
    const sendCodec = stats.sendCodec
    const recvCodec = stats.recvCodec
    const isActive = true */
}
/***/
const Softphone = (props: { children? }): JSX.Element => {
    const [hidden, setHidden] = useState(true)
    const classes = useStyles()
    const PdcCall: any = useContext(PdcCallContext)
    const isSoftphoneHidden = (): boolean => hidden
    const HideSoftphone = (): void => setHidden(true)
    const ShowSoftphone = (): void => setHidden(false)
    const ToggleSoftphone = (): void => setHidden(!hidden)
    const widthOffset = 150
    const heightOffset = 280
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const GetBounds = () => {
        return {
            top: 0,
            right: windowWidth - widthOffset,
            bottom: windowHeight - heightOffset,
            left: -widthOffset
        }
    }
    const draggableRef: any = React.useRef(null)
    const RepositionInBounds = () => {
        const draggableState = draggableRef?.current?.state
        if (draggableState) {
            const bounds = GetBounds()
            if (draggableState.x > bounds.right) draggableState.x = bounds.right > 0 ? bounds.right : 0
            if (draggableState.y > bounds.bottom) draggableState.y = bounds.bottom > 0 ? bounds.bottom : 0
        }
    }
    const HandleResize = () => {
        setWindowHeight(window.innerHeight)
        setWindowWidth(window.innerWidth)
        RepositionInBounds()
    }
    const defaultPosition = {
        x: (windowWidth / 2) - widthOffset,
        y: (windowHeight / 2) - heightOffset
    }
    useEffect(RepositionInBounds, [hidden])
    window.addEventListener('resize', HandleResize, false)
    return (
        <SoftphoneContext.Provider
            value={{
                HideSoftphone,
                ShowSoftphone,
                ToggleSoftphone,
                isSoftphoneHidden
            }}
        >
            {(!hidden) ? <HashMicPermission onClose={HideSoftphone}/> : null}
            <Draggable cancel='.disable-dragging' bounds={GetBounds()} defaultPosition={defaultPosition} ref={draggableRef}>
                <div id='softphone' className={`${classes.softphone} ${hidden ? 'hidden' : ''}`}>
                    <FeatureFlag flag='fullstory-enabled'><FullStory/></FeatureFlag>
                    <MinimizeButton/>
                    <Preferences move={!!PdcCall.callStats}/>
                    <CallConnectionStats
                        callStats={PdcCall.callStats}
                    />
                    {PdcCall.callsCnt > 1 ? <Phonebar/> : <></>}
                    <AudioRinger/>
                    <VirtualExtensionMessage/>
                    <SoftphoneCalls/>
                </div>
            </Draggable>
            {props.children}
        </SoftphoneContext.Provider>
    )
}

export default Softphone
