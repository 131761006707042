import l from 'lang'
import CallReport from './CallReport'

/**
 *
 */
export default class PerExtension extends CallReport {
    /**
     *
     * @param {object} session
     * @param {object} component
     */
    constructor (session, component) {
        super(
            session,
            component,
            [
                'avg_amount',
                'max_amount',
                'total_amount',
                'count_incoming',
                'count_outgoing',
                'total'
            ],
            'per-extension',
            'extension'
        )
        this.extensions = []
        this.has_deleted_extensions = false
        this.headers = Object.keys(this.fields).reduce((a, c) => {
            a[c] = this.fields[c].translation
            return a
        }, {
            extension: l.t('app.extension', 'Extension')
        })
    }

    /**
     *
     * @param {Array} items
     * @returns
     */
    async prepare_items (items) {
        this.extensions = []
        this.has_deleted_extensions = false
        if (!items.length) return []
        if (this.filters.show_all_extensions) {
            let extensions = items.map((x) => x.extension)
            extensions = extensions.filter((x) => x && !Number.isNaN(x))
            extensions = await this.session.get_list_all(
                `/extensions${
                    extensions.length
                        ? `?filters[id]=not-in:${extensions.join(',')}`
                        : ''
                }`
            )
            const fields = Object.keys(items[0])
            for (const extension of extensions.items) {
                const newItem = {}
                fields.map((x) => {
                    newItem[x] = 0
                    return true
                })
                newItem.extension = extension.id
                items.push(newItem)
            }
        }
        const keys = Object.keys(this.fields)
        let stats = {}
        for (const key of keys) {
            stats[key] = []
            for (const item of items) {
                stats[key].push({
                    extension: item.extension,
                    value: item[key]
                })
            }
        }
        stats = await this.loadExtensions(stats)
        return stats
    }

    /**
     *
     * @param {object} stats
     * @returns
     */
    async loadExtensions (stats) {
        const extensions = stats[Object.keys(stats)[0]]
            .map((x) => x.extension)
            .filter((x) => x)
        const ext_numbers = {}
        let exts = { items: [] }
        if (extensions.length) {
            exts = await this.session.get_list_all(
                `/extensions?filters[id]=in:${extensions.join(',')}`
            )
        }
        for (const e of exts.items) ext_numbers[e.id] = e
        for (const key of Object.keys(stats)) {
            stats[key] = stats[key].map((x) => {
                if (ext_numbers[x.extension]) {
                    x.extension = ext_numbers[x.extension]
                } else {
                    // eslint-disable-next-line no-lonely-if
                    if (x.extension === 0) {
                        x.extension = l.t(
                            'app.no-extension',
                            'No extension'
                        )
                    } else {
                        x.extension = `#${x.extension}`
                        this.has_deleted_extensions = true
                        if (!this.filters.show_deleted_extensions) return null
                    }
                }
                this.extensions.push(x.extension)
                return x
            })
            stats[key] = stats[key].filter((x) => x)
        }
        this.extensions = [...new Set(this.extensions)]

        return stats
    }

    /**
     *
     * @param {Array} items
     * @returns
     */
    prepare_csv_values (items) {
        const extensions = items[Object.keys(items)[0]].map((x) => {
            if (x.extension && typeof x.extension === 'object') {
                return x.extension.extension
            }
            return x.extension
        })
        const data = extensions.map((extension) => {
            const data_item = {}
            data_item.extension = extension
            for (const prop of Object.keys(this.fields)) {
                const item = items[prop].find((x) => {
                    if (x.extension && typeof x.extension === 'object') {
                        return x.extension.extension === extension
                    }

                    return x.extension === extension
                })
                const value = item
                    ? this.formatters[this.fields[prop].type](item.value)
                    : '—'
                data_item[prop] = value
            }
            return data_item
        })

        return data
    }
}
