/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import Typography from 'typography'
import CardActions from '@material-ui/core/CardActions'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Collapse from '@material-ui/core/Collapse'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useAjax } from '../hooks/useAjax'
import PhoneComUser from 'phone-com-user'
import ajax from 'ajax'
import Spinner from 'spinner'
import TableMui from 'pdc-table-mui'
import { EnrollmentType } from '../interface/EnrollmentType'
import PreviewCampaignDetails from './PreviewCampaignDetails'
import PreviewCampaignMessageSettings from './PreviewCampaignMessageSettings'
import PreviewSampleMessages from './PreviewSampleMessages'
import PreviewCompanyDetails from './PreviewCompanyDetails'
import PreviewContactDetails from './PreviewContactDetails'
import EnrollmentPreviewTitle from './EnrollmentPreviewTitle'
import { EnrollmentEventsViewer } from './EnrollmentEventsViewer'

const styles = makeStyles((theme) => ({
    cardActions: {
        backgroundColor: theme.palette.paper.teal,
        border: '1px solid #E7E7E7',
        borderBottom: 'none'
    },
    collapse: {
        border: '1px solid #E7E7E7',
        borderTop: 'none',
        borderBottom: 'none'
    },
    topMarginSpacing: {
        marginTop: '1rem'
    },
    contentContainer: {
        marginRight: '1rem'
    },
    card: {
        margin: '1rem 0rem',
        '&:nth-last-child(1)': {
            marginBottom: '0.1rem'
        }
    },
    lastCard: {
        margin: '0.1rem 0rem'
    }
}))

interface Props {
    enrollment: Record<string, unknown>
    enrollmentType: EnrollmentType
}

/**
 *
 */
const EnrollmentPreviewContent = ({ enrollment, enrollmentType } : Props) => {
    const classes = styles()

    const [companyDetailsExpanded, setCompanyDetailsExpanded] = useState(true)
    const [contactDetailsExpanded, setContactDetailsExpanded] = useState(true)

    const [campaignDetailsExpanded, setCampaignDetailsExpanded] = useState(true)
    const [messageSettingExpanded, setMessageSettingExpanded] = useState(true)
    const [sampleMessageExpanded, setSampleMessageExpanded] = useState(true)
    const [contactInfoExpanded, setContactInfoExpanded] = useState(true)
    const [brandEventsExpanded, setBrandEventsExpanded] = useState(true)
    const [campaignEventsExpanded, setCampaignEventsExpanded] = useState(true)

    const [enrollmentTypesExpanded, setEnrollmentTypesExpanded] = useState(false)

    const theme = useTheme()
    const belowSM = useMediaQuery(theme.breakpoints.down('sm'))
    const belowXS = useMediaQuery(theme.breakpoints.down('xs'))

    const fetchEnrollmentStatuses = () => {
        const params = {
            csp_brand_id: enrollmentType === EnrollmentType.Brand ? enrollment.csp_brand_id : undefined,
            csp_campaign_id: enrollmentType === EnrollmentType.Campaign ? enrollment.csp_campaign_id : undefined
        }
        const listBrandEventsUrl = `${PhoneComUser.getv5ApiRoot()}/messages/10dlc/list-brand-events`
        const listCampaignEventsUrl = `${PhoneComUser.getv5ApiRoot()}/messages/10dlc/list-campaign-events`

        return ajax.post(enrollmentType === EnrollmentType.Brand ? listBrandEventsUrl : listCampaignEventsUrl, params, 'Bearer')
            .then(rv => {
                return new Promise((resolve, reject) => {
                    if (rv.data.message === 'success') {
                        resolve(rv.data.events)
                    } else {
                        reject(new Error('Error'))
                    }
                })
            }).catch(err => {
                return new Promise((resolve, reject) => {
                    reject(err)
                })
            })
    }

    const { value, status } = useAjax(fetchEnrollmentStatuses, false)

    const brandSections = [
        {
            titleID: 'sms_enrollment_company_details',
            component: PreviewCompanyDetails,
            expanded: companyDetailsExpanded,
            setExpanded: setCompanyDetailsExpanded
        },
        {
            titleID: 'sms_enrollment_contact_details',
            component: PreviewContactDetails,
            expanded: contactDetailsExpanded,
            setExpanded: setContactDetailsExpanded
        },
        {
            titleID: 'sms_enrollment_events',
            component: () => <EnrollmentEventsViewer
                entityType={'brand'}
                entityId={enrollment.csp_brand_id as string}
                viewHeight={ belowXS ? 400 : (belowSM ? 300 : 200) }
                breakPoints={ { belowXS, belowSM } }
            />,
            expanded: brandEventsExpanded,
            setExpanded: setBrandEventsExpanded
        }
    ]

    const campaignSections = [
        {
            titleID: 'sms_enrollment_campaign_details',
            component: PreviewCampaignDetails,
            expanded: campaignDetailsExpanded,
            setExpanded: setCampaignDetailsExpanded
        },
        {
            titleID: 'sms_enrollment_campaign_message_settings',
            component: PreviewCampaignMessageSettings,
            expanded: messageSettingExpanded,
            setExpanded: setMessageSettingExpanded
        },
        {
            titleID: 'sms_enrollment_campaign_sample_messages',
            component: PreviewSampleMessages,
            expanded: sampleMessageExpanded,
            setExpanded: setSampleMessageExpanded
        },
        {
            titleID: 'sms_enrollment_contact_details',
            component: PreviewContactDetails,
            expanded: contactInfoExpanded,
            setExpanded: setContactInfoExpanded
        },
        {
            titleID: 'sms_enrollment_events',
            component: () => <EnrollmentEventsViewer
                entityType={'campaign'}
                entityId={enrollment.csp_campaign_id as string}
                viewHeight={ belowXS ? 400 : (belowSM ? 300 : 200) }
                breakPoints={ { belowXS, belowSM } }
            />,
            expanded: campaignEventsExpanded,
            setExpanded: setCampaignEventsExpanded
        }
    ]

    const sections = enrollmentType === EnrollmentType.Brand ? brandSections : campaignSections

    const generateTableHeadColumns = () => {
        const headColumns = [
            { content: 'Event name' },
            { content: 'Description' },
            { content: 'Date' }
        ]
        return headColumns
    }
    const renderRegisteredOnColumn = (row) => {
        const createdAt = row.created_at
        const d = new Date(0)
        d.setUTCSeconds(createdAt)

        const year = d.toLocaleString('default', { year: 'numeric' })
        const month = d.toLocaleString('default', { month: '2-digit' })
        const day = d.toLocaleString('default', { day: '2-digit' })

        return `${year}-${month}-${day}`
    }
    const generateOneRow = (data) => {
        const columns = [
            {
                key: 0,
                content: data.event_name
            },
            {
                key: 1,
                content: data.description
            },
            {
                key: 2,
                content: renderRegisteredOnColumn(data)
            }
        ]

        return { key: data.id, columns }
    }
    const generateTableRows = () => {
        const rows = !(value?.length > 0 && typeof value?.map === 'function')
            ? []
            : value.map(x => {
                return generateOneRow(x)
            })
        return rows
    }

    return (
        <div className={classes.contentContainer}>
            <EnrollmentPreviewTitle enrollment={enrollment} enrollmentType={enrollmentType} />
            {sections.map((section, index) => {
                const StepComponent = section.component
                const { expanded, setExpanded, titleID } = section
                return <React.Fragment key={index}>
                    <Card className={classes.card}>
                        <CardActions
                            onClick={() => setExpanded(!expanded)}
                            className={classes.cardActions}
                            disableSpacing>
                            <Typography variant='h5' remoteConfigID={titleID} />
                        </CardActions>
                        <Collapse className={classes.collapse} in={expanded} timeout='auto'>
                            <CardContent>
                                <StepComponent
                                    enrollment={enrollment}
                                    enrollmentType={enrollmentType}
                                />
                            </CardContent>
                        </Collapse>
                    </Card>
                </React.Fragment>
            })}
            {false && <>
                <Card>
                    <CardActions
                        onClick={() => setEnrollmentTypesExpanded(!enrollmentTypesExpanded)}
                        className={classes.cardActions}
                        disableSpacing>
                        <Typography variant='h5' remoteConfigID='sms_enrollment_events' />
                    </CardActions>
                    <Collapse className={classes.collapse} in={enrollmentTypesExpanded} timeout='auto'>
                        <CardContent>
                            {status === 'pending'
                                ? (<Spinner />)
                                : (
                                    <TableMui
                                        headColumns={generateTableHeadColumns()}
                                        rows={generateTableRows()}
                                        infiniteScroller={false}
                                        reverseScroll={false}
                                        hasMore={false}
                                        listView={false}
                                        fullWidth={true}
                                    />
                                ) }

                        </CardContent>
                    </Collapse>
                </Card>
                <br />
            </>}

        </div>
    )
}

export default EnrollmentPreviewContent
