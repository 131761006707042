/**
 * @param {object} theme
 */
const styles = theme => ({
    mainWrapper: {
        // overflowX: 'auto',
        width: '100%',
        padding: '50px min(65px, 5%) 65px'
    },
    table: {
        height: '100%'
    },
    tableHeadRow: {
        height: 50,
        fontSize: 16,
        fontWeight: 600
    },
    tableRow: {
        minHeight: 64
    },
    optionIcon: {
        cursor: 'pointer'
    },
    deleteMenuItem: {
        color: 'red',
        '&:hover': {
            color: 'red'
        }
    },
    pdcToggleWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    phoneNumberNameField: {
        cursor: 'text',
        minWidth: 160,
        '&.mobile': {
            width: 160
        }
    },
    phoneNumberNameTextField: {
        width: '100%'
    },
    phoneNumberNameTextFieldInput: {
        '&:not(.focused)': {
            '&:hover': {
                background: '#e8e8e8'
            },
            '& fieldset': {
                border: 'none'
            }
        }
    },
    bold: {
        fontWeight: 'bold'
    },
    loadingDiv: { ...theme.loadingDiv, position: 'fixed', zIndex: 3 },
    tableBody: {
        overflowX: 'auto',
        '& .pdc-loader-wrapper': {
            minHeight: 40,
            display: 'flex',
            alignItems: 'center',
            overflow: 'unset',
            margin: 'unset',
            transform: 'unset'
        }
    },
    tableShadow: {
        backgroundImage: '/* Shadows */ linear-gradient(to right, white, white), linear-gradient(to right, white, white), /* Shadow covers */ linear-gradient(to right, rgba(0,0,0,.25), rgba(255,255,255,0)), linear-gradient(to left, rgba(0,0,0,.25), rgba(255,255,255,0))',
        backgroundPosition: 'left center, right center, left center, right center',
        backgroundRepeat: 'no-repeat',
        backgroundColor: 'white',
        backgroundSize: '20px 100%, 20px 100%, 10px 100%, 10px 100%',
        backgroundAttachment: 'local, local, scroll, scroll'
    },
    numberCell: {
        minWidth: 160,
        boxSizing: 'border-box'
    },
    phoneNumber: {
        whiteSpace: 'nowrap'
    },
    typeCell: {
        minWidth: 145,
        boxSizing: 'border-box',
        '&.gray': {
            color: 'gray'
        }
    },
    nameCell: {
        minWidth: 220,
        boxSizing: 'border-box'
    },
    actionCell: {
        minWidth: 140,
        boxSizing: 'border-box'
    },
    inboxCell: {
        minWidth: 120
    },
    recordCell: {
        minWidth: 60,
        boxSizing: 'border-box',
        maxWidth: 90
    },
    optionsCell: {
        minWidth: 60,
        boxSizing: 'border-box',
        maxWidth: 90
    },
    editCell: {
        minWidth: 60,
        boxSizing: 'border-box',
        maxWidth: 90,
        paddingBottom: 8.5,
        paddingTop: 8.5
    },
    confirmSpinner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 50,
        '& svg': {
            color: theme.palette.primary[0]
        }
    },
    spinnerStyle: {
        paddingTop: 20,
        margin: 'auto',
        width: 100
    },
    processingActionSpinnerWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(255, 255, 255, 0.4)',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 2000,
        '& svg': {
            fontSize: 50
        }
    },

    loadingInputIcon: {
        marginRight: 14,
        padding: 3
    },
    editNumberColumn: {
        cursor: 'pointer',
        '& .edit-button': {
            borderRadius: '50%',
            width: 30,
            height: 30,
            minWidth: 30,
            minHeight: 30,
            display: 'grid',
            placeItems: 'center',
            backgroundColor: 'rgb(133 146 154 / 15%)',
            '& > svg': {
                fontSize: 20,
                color: '#6E7A82'
            },
            '&:hover': {
                backgroundColor: 'rgb(206 239 199 / 50%)',
                '& > svg': {
                    color: '#29921B'
                }

            }
        }
    }

})

export default styles
