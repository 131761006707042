import React, { Component } from 'react'
import { IconInfo } from 'pdc-svg-icons'
import { withStyles, Divider, Popover } from '@material-ui/core'

const styles = theme => ({
	paper: {
		padding:		'24px 20px',
		border:			'2px solid black',
		borderRadius:	6,
		fontFamily: theme.fontFamily || 'Montserrat, Helvetica, arial, sans-serif !important',

	},
	header: {
		fontSize:		20,
		lineHeight:		1.15,
		letterSpacing:	-0.2,
		marginBottom:	10
	},
	rowInfo: {
		fontSize:		14,
		lineHeight:		2.14,
		display:		'flex'
	},
	infoKey: {
		color:		'black',
		width:		60,
		fontWeight:	500,
	},
	infoValue: {
		color:			theme.messagesApp.mediaModal.infoValueColor,
		letterSpacing:	-0.1,
	},
	divider: {
		margin:				'5px 0',
		backgroundColor:	theme.messagesApp.mediaModal.dividerColor
	}
})

class MediaModalInformation extends Component {

	state = {anchorEl: null}

	render() {
		const { classes } = this.props
		return (
			<div>
				<div
					aria-describedby	= 'information-popover'
					onClick				= {e => this.setState({anchorEl: e.currentTarget})}
				>
					<IconInfo style={{width: 36, height: 36}} onClick={this.showInfo}/>
				</div>
				<Popover
					id			= 'information-popover'
					open		= {Boolean(this.state.anchorEl)}
					anchorEl	= {this.state.anchorEl}
					onClose		= {() => this.setState({anchorEl: null})}
					classes		= {{paper: classes.paper}}
					anchorOrigin	= {{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin	= {{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<div className={classes.header}>Details</div>
					<div className={classes.rowInfo}>
						<span className={classes.infoKey}>Sender</span>
						<span className={classes.infoValue}>{this.props.sender}</span>
					</div>
					<Divider classes={{root: classes.divider}}/>
					<div className={classes.rowInfo}>
						<span className={classes.infoKey}>Sent</span>
						<span className={classes.infoValue}>{this.props.dateTime}</span>
					</div>
					<Divider classes={{root: classes.divider}}/>
					<div className={classes.rowInfo}>
						<span className={classes.infoKey}>Size</span>
						<span className={classes.infoValue}>{this.props.size}</span>
					</div>
					{!this.props.otherDetails ? null : Object.keys(this.props.otherDetails).map((key, i) => {
						return (
							<div key={i} className={classes.rowInfo}>
								<span className={classes.infoKey}>{key}</span>
								<span className={classes.infoValue}>{this.props.otherDetails[key]}</span>
							</div>
						)
					})}
				</Popover>
			</div>
		)
	}
}

export default withStyles(styles)(MediaModalInformation)