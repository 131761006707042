import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('IndexPage',{staticClass:"queues list-page",attrs:{"resource":_vm.queue,"data-test-id":"queues-list-page"},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('FormInput',{attrs:{"label":_vm.l.t('app.name', 'Name')}},[_c('w-text-field',{attrs:{"hide-details":"auto","data-test-id":"queues-filters-name"},model:{value:(_vm.queue.filters.name),callback:function ($$v) {_vm.$set(_vm.queue.filters, "name", $$v)},expression:"queue.filters.name"}})],1)]},proxy:true},{key:"list",fn:function(){return [_c(VSimpleTable,{staticClass:"list-table",attrs:{"data-test-id":"queues-table"}},[_c('thead',{attrs:{"data-test-id":"table-head"}},[_c('tr',{attrs:{"data-test-id":"head-row"}},[_c('th',{attrs:{"data-test-id":"checkbox"}}),_vm._v(" "),_c('th',{attrs:{"data-test-id":"name"}},[_vm._v(_vm._s(_vm.l.t('app.name', 'Name')))]),_vm._v(" "),_c('th',{attrs:{"data-test-id":"members"}},[_vm._v(_vm._s(_vm.l.t('queues.members', 'Members')))]),_vm._v(" "),_c('th',{attrs:{"data-test-id":"more-options"}})])]),_vm._v(" "),_c('tbody',{attrs:{"data-test-id":"table-head"}},_vm._l((_vm.queue.items),function(item,i){return _c('tr',{key:item['id'],class:{'selected-row': item.selected},attrs:{"data-test-id":("queues-item-" + (item.id) + "-row")}},[_c('td',{staticClass:"select-table-item",attrs:{"data-test-id":"checkbox"}},[_c('w-checkbox',{key:("queues-item-" + (item.id) + "-" + (item.selected)),attrs:{"hide-details":"auto","data-test-id":("queues-item-" + (item.id) + "-checkbox")},on:{"click":function($event){return _vm.checked($event, item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1),_vm._v(" "),_c('td',{staticClass:"nowrap overflow-hidden",attrs:{"data-test-id":"description"}},[_c('IndexListItem',{key:item['id'],attrs:{"route":{
                name: 'queues.show',
                params: {
                  id: item.id,
                  _queue: item,
                },
              },"item":item,"title":item.name,"data-test-id":("queues-item-" + (item.id) + "-title")}})],1),_vm._v(" "),_c('td',{staticClass:"w-100",attrs:{"data-test-id":("queues-item-" + (item.id) + "-members")}},[_vm._v("\n            #"+_vm._s(item['id'])+": "+_vm._s(item.members.length)+" "+_vm._s(item.members.length  === 1 ? _vm.l.t('queues.member', 'member') : _vm.l.t('queues.memebers', 'members'))+"\n          ")]),_vm._v(" "),_c('td',{attrs:{"data-test-id":"more-options"}},[_c('MoreOptions',{attrs:{"options":_vm.more_options(item)}})],1)])}),0)])]},proxy:true}])}),_vm._v(" "),_c('ConfirmDeletion',{attrs:{"value":!!_vm.deleting_item_id},on:{"closed":function($event){_vm.deleting_item_id = null},"confirmed":function($event){return _vm.queue.delete_item(_vm.deleting_item_id)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }