import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.1.1?node-id=5522%3A1494
 *
 * @param {SvgIconProps} props - svg props
 */
export const MenuCollapsedIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M5.78508 9.84116C5.48678 9.52155 5.71342 9 6.15061 9L17.8494 9C18.2866 9 18.5132 9.52155 18.2149 9.84116L12.3655 16.1084C12.1679 16.3202 11.8321 16.3202 11.6345 16.1084L5.78508 9.84116Z' fill={color}/>
        </SvgIcon>
    )
}

export default MenuCollapsedIcon
