/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext, useContext, useEffect, useState } from 'react'
import ajax from 'ajax'
import { getPhoneCom } from 'phonecom'
import Button from 'button'
import Spinner from 'spinner'
import Typography from 'typography'
import PhoneNumberSelector from 'phone-number-selector'
import PdcOpenConnection from 'pdc-open-connection'

const INTEGRATION_WS_TOPIC = 'INTEGRATION_EVENT'

interface IZohoContext {
    accountConfigured: boolean
    userConfigured: boolean
    userData: IZohoUserData
    loading: boolean
    error?: string
    update: () => Promise<void>
    disableIntegration: () => Promise<void>
    enableIntegration: () => Promise<void>
}
interface IZohoContextProviderProps {
    children: React.ReactNode
}
const ZohoContext = createContext({} as IZohoContext)

interface IZohoUserData {
    userid: string
    email: string
    username: string
    extensions_array: Array<{
        extension: string
        extension_id: number
        device_id: number
        phone: string
        selected: boolean
    }>
}

interface IIntegrationDetails {
    accountConfigured: boolean
    userConfigured: boolean
    userData: IZohoUserData
}
const fetchIntegrationDetails = async (): Promise<IIntegrationDetails> => {
    const getDetailsUrl = 'https://integrations.phone.com/crm/zoho/actions/v2/get-details'
    const response = (await ajax.post(getDetailsUrl, {}))
    const details = response?.data?.details as IIntegrationDetails
    return details
}

const redirectAndIntegrate = async (): Promise<void> => {
    const getRedirectUrl = 'https://integrations.phone.com/crm/zoho/actions/v2/integrate-redirect'
    const response = (await ajax.post(getRedirectUrl, {}))
    const uri = response.data.uri
    window.open(uri, '_blank')
    return Promise.resolve()
}

const deleteIntegration = async (): Promise<void> => {
    const redirectUrl = 'https://integrations.phone.com/crm/zoho/actions/v2/delete-org'
    return ajax.post(redirectUrl, {})
}

const getCallerId = async (): Promise<string> => {
    const phoneCom = await getPhoneCom()
    const body = {
        voip_phone_id: phoneCom.voip_phone_id
    }
    return ajax.post('https://my.phone.com/api/app/communicator/calls/get-caller-id', body)
        .then(response => response?.data?.caller_id)
}

const setCallerId = async (number: string): Promise<void> => {
    const phoneCom = await getPhoneCom()
    const body = {
        caller_id: number,
        voip_phone_id: phoneCom.voip_phone_id
    }
    return ajax.post('https://my.phone.com/api/app/communicator/calls/set-caller-id', body)
        .then(response => response.data)
}

const getUserPhoneNumbers = async (): Promise<Array<{number: string, label: string}>> => {
    return ajax.post('https://my.phone.com/api/app/user/list-phone-numbers', {})
        .then(response => response?.data?.calling)
}

const ZohoContextProvider = (props: IZohoContextProviderProps): JSX.Element => {
    const [loading, setLoading] = useState(true)
    const [accountConfigured, setAccountConfigured] = useState(false)
    const [userConfigured, setUserConfigured] = useState(false)
    const [userData, setUserData] = useState({} as IZohoUserData)
    const [error, setError] = useState(null as string)
    const update = async (): Promise<void> => {
        setLoading(true)
        return fetchIntegrationDetails().then((details) => {
            setAccountConfigured(details.accountConfigured)
            setUserConfigured(details.userConfigured)
            setUserData(details.userData)
            setLoading(false)
        })
    }
    const enableIntegration = async (): Promise<void> => {
        setLoading(true)
        return redirectAndIntegrate().then(() => {
            return update().then(() => {
                setLoading(true)
                // wait for ws event to set loading to false
            })
        })
    }
    const disableIntegration = async (): Promise<void> => {
        setLoading(true)
        return deleteIntegration().then(() => {
            return update().then(() => {
                setLoading(false)
            })
        })
    }
    useEffect(() => {
        update()
        getPhoneCom().then(() => PdcOpenConnection.onAccount(INTEGRATION_WS_TOPIC, (voip_id, event: { event: string, error?: string }) => {
            if (event?.error) {
                setError(event.error)
            } else {
                setError(null)
            }
            update()
        }))
    }, [])
    // fetch integration details
    // add webhook listener
    return (
        <ZohoContext.Provider value={
            {
                accountConfigured: accountConfigured,
                userConfigured: userConfigured,
                userData: userData,
                loading: loading,
                error: error,
                update: update,
                disableIntegration: disableIntegration,
                enableIntegration: enableIntegration
            }
        }>
            {props.children}
        </ZohoContext.Provider>
    )
}

const Loader = (): JSX.Element => {
    return (<div style={{ margin: 'auto', alignItems: 'center', display: 'flex' }}>
        {<Spinner size='medium'/>}
    </div>)
}

const IntegrateWidget = (): JSX.Element => {
    // if account admin, add integrate button
    // else add request integration button
    const context = useContext(ZohoContext)
    const [isAdmin, setIsAdmin] = useState(false)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getPhoneCom().then((phoneCom) => {
            setIsAdmin(phoneCom.role === 'account')
            setLoading(false)
        })
    }, [])
    const redirect = async (): Promise<void> => {
        return context.enableIntegration()
    }
    if (loading) {
        return (<Loader/>)
    }
    return (<div>
        <div>
            {isAdmin ? <Button onClick={redirect}>Enable Integration</Button> : <div>Contact Adming to Integrate</div>}
        </div>
    </div>)
}

const UserIntegrateContent = (): JSX.Element => {
    // add integate button
    // fetch integration link from server
    // open link in new tab
    const context = useContext(ZohoContext)
    return (<Button onClick={context.enableIntegration}>Integrate User</Button>)
}

const UserConfigContent = (props: { user: IZohoUserData }): JSX.Element => {
    const user = props.user
    const [numberList, setNumberList] = useState([{ number: '', nickname: 'Unavailable', selected: true }] as Array<{ number: string, nickname: string, selected: boolean }>)
    const [loading, setLoading] = useState(true)
    const setSelectedNumber = (number: string): void => {
        numberList.forEach((num) => {
            num.selected = num.number === number
        })
        setNumberList(numberList)
    }
    const onSelect = (number: string): Promise<void> => {
        if (number === '') return Promise.resolve()
        setLoading(true)
        return setCallerId(number).then(() => {
            setSelectedNumber(number)
            setLoading(false)
        })
    }
    const updateNumberList = (): void => {
        setLoading(true)
        getUserPhoneNumbers().then((items) => {
            getCallerId().then((callerId) => {
                const numberList = []
                if (items.length === 0) {
                    numberList.push({ number: '', nickname: 'Unavailable', selected: true })
                } else {
                    items.forEach((item) => {
                        numberList.push({ number: item.number, nickname: item.label, selected: item.number === callerId })
                    })
                }
                setNumberList(numberList)
                setLoading(false)
            })
        })
    }
    useEffect(() => {
        updateNumberList()
    }, [])
    return (<div style={{ margin: 'auto' }}>
        <div style={{ alignItems: 'center', padding: '8px', content: '' }}>
            <div><Typography variant='h6'>Integration Config</Typography></div>
            <div><Typography>User: {user.username}</Typography></div>
        </div>
        <div style={{ alignItems: 'center', padding: '8px' }}>
            <div><Typography>Select Caller ID</Typography></div>
            <div>{loading ? <Loader/> : <PhoneNumberSelector numbers={numberList} select={onSelect}/>}</div>
        </div>
    </div>)
}

const IntegrationConfigContent = (props: {userConfigured: boolean, user: IZohoUserData}): JSX.Element => {
    // if account admin, show delete option
    const context = useContext(ZohoContext)
    const [isAdmin, setIsAdmin] = useState(false)
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getPhoneCom().then((phoneCom) => {
            setIsAdmin(phoneCom.role === 'account')
            setLoading(false)
        })
    }, [])
    if (loading) {
        return (<Loader/>)
    }
    return (<div style={{ margin: 'auto', display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
        <div style={{ padding: '16px' }}>
            {props.userConfigured ? <UserConfigContent user={props.user}/> : <UserIntegrateContent/>}
        </div>
        <div>
            {isAdmin ? <Button onClick={context.disableIntegration}>Delete Integration</Button> : null}
        </div>
    </div>)
}

const ZohoIntegrationWidgetContent = (): JSX.Element => {
    const zohoContext = useContext(ZohoContext)
    const loading = zohoContext.loading
    const accountConfigured = zohoContext.accountConfigured
    const userConfigured = zohoContext.userConfigured
    const userData = zohoContext.userData
    if (loading) {
        return (<Loader/>)
    }
    return (<div style={{ margin: 'auto' }}>
        <div style={{ color: 'red' }}>
            <Typography color={'error'} variant={'alertText'}>{zohoContext.error}</Typography>
        </div>
        <div style={{ margin: 'auto', display: 'flex' }}>
            <div style={{ alignItems: 'center', padding: '4px' }}>
                {accountConfigured
                    ? <IntegrationConfigContent userConfigured={userConfigured} user={userData}/>
                    : <IntegrateWidget />}
            </div>
        </div>
    </div>)
}

/**
 *
 */
const ZohoIntegrationWidget = (): JSX.Element => {
    return (<ZohoContextProvider>
        <div style={{ width: '100%', minHeight: '100px', display: 'flex' }}>
            <ZohoIntegrationWidgetContent />
        </div>
    </ZohoContextProvider>)
}

/**
 *
 */
export { ZohoIntegrationWidget }
