import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5666%3A129
 *
 * @param {SvgIconProps} props - svg props
 */
export const SupportIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.9999 1C5.92475 1 0.999878 5.92487 0.999878 12C0.999878 18.0751 5.92475 23 11.9999 23C18.075 23 22.9999 18.0751 22.9999 12C22.9999 5.92487 18.075 1 11.9999 1ZM10.7111 14.2822V14.4309H12.6487V14.2822C12.6526 13.878 12.7011 13.5386 12.7942 13.2641C12.8911 12.9857 13.0385 12.7436 13.2364 12.5376C13.4381 12.3317 13.696 12.1335 14.0102 11.9428C14.3671 11.7331 14.6755 11.4947 14.9353 11.2278C15.1952 10.9609 15.395 10.6559 15.5346 10.3127C15.6782 9.96949 15.7499 9.58055 15.7499 9.14585C15.7499 8.50142 15.5909 7.9447 15.2728 7.47568C14.9586 7.00285 14.5184 6.6387 13.952 6.38321C13.3896 6.12773 12.7379 5.99999 11.997 5.99999C11.3182 5.99999 10.6976 6.12201 10.1351 6.36606C9.57654 6.6101 9.12658 6.97616 8.78523 7.46424C8.44776 7.95233 8.26933 8.55862 8.24993 9.28312H10.3213C10.3407 8.92468 10.4299 8.62916 10.589 8.39656C10.7519 8.16014 10.9555 7.98474 11.1999 7.87035C11.4481 7.75214 11.71 7.69303 11.9854 7.69303C12.2841 7.69303 12.5537 7.75404 12.7942 7.87607C13.0385 7.99809 13.2325 8.16968 13.376 8.39084C13.5195 8.612 13.5913 8.87321 13.5913 9.17444C13.5913 9.44137 13.537 9.6835 13.4284 9.90085C13.3198 10.1144 13.1704 10.3089 12.9803 10.4843C12.7942 10.6559 12.5827 10.8141 12.3461 10.959C12.0009 11.1687 11.708 11.3994 11.4675 11.6511C11.227 11.8989 11.0409 12.2269 10.909 12.6349C10.781 13.0429 10.715 13.592 10.7111 14.2822ZM10.8217 17.6339C11.0738 17.878 11.3744 18 11.7236 18C11.9563 18 12.1677 17.9447 12.3578 17.8341C12.5517 17.7197 12.7069 17.5672 12.8232 17.3765C12.9435 17.1859 13.0036 16.9743 13.0036 16.7417C13.0036 16.3985 12.8756 16.1049 12.6196 15.8608C12.3675 15.6168 12.0688 15.4948 11.7236 15.4948C11.3744 15.4948 11.0738 15.6168 10.8217 15.8608C10.5696 16.1049 10.4435 16.3985 10.4435 16.7417C10.4435 17.0925 10.5696 17.3899 10.8217 17.6339Z" fill={color} />
        </SvgIcon>
    )
}

export default SupportIcon
