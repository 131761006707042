/**
 * @param {object} theme
 */
const styles = theme => ({
    addNoteDialog: {
        padding: '40px 50px',
        boxShadow: '5px 5px 0 0 rgba(0, 0, 0, 0.4)',
        borderRadius: 15,
        position: 'relative'
    },
    title: {
        fontSize: 24,
        letterSpacing: -0.4,
        lineHeight: 1.25,
        marginBottom: 10
    },
    infoText: {
        fontSize: 14,
        lineHeight: 1.36,
        color: theme.faxesApp.infoColor
    },
    content: {
        position: 'relative',
        marginTop: 25,
        border: '0.5px solid gray',
        borderRadius: 6,
        padding: '18px 24px',
        lineHeight: 1.71,
        outline: 'none',
        maxHeight: 500,
        overflow: 'initial',
        cursor: 'text',
        '& .error': {
            position: 'absolute',
            top: -12,
            left: 20,
            color: 'red',
            background: 'white',
            padding: '0 5px'
        },
        '& .characters-info': {
            position: 'absolute',
            bottom: -11,
            right: 20,
            background: 'white',
            padding: '0 5px'
        },
        '& textarea': {
            border: 'none',
            resize: 'none',
            outline: 'none',
            height: 114,
            width: '100%'
        }
    },
    buttonWrapper: {
        marginTop: 30,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    closeButton: {
        position: 'absolute',
        top: 28,
        right: 28,
        cursor: 'pointer'
    }
})

export default styles
