import React from 'react'
import { Select as MuiSelect, SelectProps as MuiSelectProps, FormControl, InputLabel, InputAdornment, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowDropdownIcon, SoftAlertIcon } from 'svg-icons'
import styles from './styles'
import Typography from 'typography'
import { Chip } from 'chip-mui'
import { ThemeProvider } from 'theme-provider'
import { theme } from 'get-theme'

const useStyles = makeStyles(styles)

interface SelectProps extends MuiSelectProps {
    helperText?: string
    useChips?: boolean
    formControlClasses?: any,
    label: string | React.ReactNode
}

const getFitElements = (elements, id) => {
    const selectElement = document.getElementById(id)
    if (!selectElement) return elements
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    context.font = '14px Montserrat'
    const fitElements = []
    const parentWidth = parseFloat(window.getComputedStyle(selectElement).width.split('px')[0]) - 80 // padding + place for the + part
    let stop = false
    elements.forEach((element, i) => {
        if (stop) return
        const text = `${fitElements.join(', ')}, ${element}`
        const width = context.measureText(text).width
        if (width < parentWidth || i === 0) fitElements.push(element)
        else stop = true
    })
    return fitElements
}

// The actual type is `MenuProps` but if that is put instead of `any` then it says that the prop `open` is required and
// if `open` is set as `true` then the menu will always be opened (it even adds another set of menu items);
// if it is set as `false` then the menu will always be closed.
/* eslint-disable-next-line */
const getMenuProps = (props: SelectProps, classes): any => {
    const dataTestId = props['data-test-id']
    const dataTestIdProp = dataTestId ? { 'data-test-id': `${dataTestId}-items-wrapper` } : {}
    const menuProps = {
        classes: { list: classes.ulList },
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
        },
        ...dataTestIdProp
    }
    if (props.MenuProps) {
        if (props.MenuProps.classes) {
            if (props.MenuProps.classes.list) menuProps.classes.list += ` ${props.MenuProps.classes.list}`
            const otherClasses = { ...props.MenuProps.classes }
            delete otherClasses.list
            Object.assign(menuProps.classes, otherClasses)
        }
        const otherMenuProps = { ...props.MenuProps }
        delete otherMenuProps.classes
        Object.assign(menuProps, otherMenuProps)
    }
    return menuProps
}

const getHelperText = (helperText, error): JSX.Element => {
    if (!helperText || !error) return helperText
    return <><SoftAlertIcon/><Typography variant='helperText'>{helperText}</Typography></>
}

const renderChipsValue = (classes, selected, children, open, error, disabled): JSX.Element => (
    <div className={classes.chips}>
        {(selected as string[]).map((value): JSX.Element => {
            const child = children.find((c): boolean => c.props.value === value)
            const label = child.props.children
            return (
                <Chip
                    key = {value}
                    label = {label}
                    error = {error}
                    disabled = {disabled}
                    color = {open ? 'primary' : 'secondary'}
                />
            )
        })}
    </div>
)

const renderStringValue = (selected, children, id): JSX.Element => {
    selected = Array.isArray(selected) ? selected : [selected]
    const selectedChildren = children.filter((c): boolean => selected.includes(c.props.value))
    const elements = selectedChildren.map(c => c.props.children)
    const fitElements = getFitElements([...elements], id)
    const numNotShown = elements.length - fitElements.length
    const extraText = numNotShown ? `+${numNotShown}` : ''
    return (
        <>
            <Typography key={selected} variant='inputText'>
                {fitElements.map((e, i) => (
                    <>{e}{selectedChildren.length > i + 1 ? ', ' : ''}</>
                ))}
            </Typography>
            <Typography variant='inputText'>&nbsp;{extraText}</Typography>
        </>
    )
}

const renderEndAdornment = (): JSX.Element => (
    <InputAdornment
        classes = {{ root: 'adorned-end-expand' }}
        position = 'end'
    ><ArrowDropdownIcon/></InputAdornment>
)

const getSelectClasses = (props) => {
    const { value, label, multiple, useChips } = props
    const classes = useStyles()
    const isResolved = ((Array.isArray(value) && value.length) || (!Array.isArray(value) && value))
    const labelClass = label ? 'has-label' : ''
    const resolvedClass = isResolved ? 'resolved' : ''
    const chipsClass = multiple && useChips ? 'use-chips' : ''
    const classesProp = { root: `${classes.select} ${labelClass} ${resolvedClass} ${chipsClass} ` }
    if (props.classes) {
        if (props.classes.root) classesProp.root += ` ${props.classes.root}`
        const otherClasses = { ...props.classes }
        delete otherClasses.root
        Object.assign(classesProp, otherClasses)
    }
    return classesProp
}

const getFormControlClasses = (props, open) => {
    const { error, disabled } = props
    const classes = useStyles()
    const disabledClass = disabled ? 'disabled' : ''
    const errorClass = error ? 'error' : ''
    const openClass = open ? 'open' : ''
    const formControlClassesProp = { root: `${classes.formControl} ${openClass} ${disabledClass} ${errorClass}` }
    if (props.formControlClasses) {
        if (props.formControlClasses.root) formControlClassesProp.root += ` ${props.formControlClasses.root}`
        const otherClasses = { ...props.formControlClasses }
        delete otherClasses.root
        Object.assign(formControlClassesProp, otherClasses)
    }
    return formControlClassesProp
}

/**
 * Select component
 *
 * @param {SelectProps} props - props
 */
export const Select = (props: SelectProps): JSX.Element => {
    const [open, setOpen] = React.useState(props.open)
    const [focused, setFocused] = React.useState(false)
    // eslint-disable-next-line
    const [id, setId] = React.useState(props.id || (Math.random() + 1).toString(36).substring(2))
    const { children, value, label, multiple, useChips, error, disabled, helperText } = props
    const classes = useStyles()

    const otherProps: SelectProps = { ...props }
    const removePropNames = ['id', 'classes', 'MenuProps', 'labelId', 'onOpen', 'onClose', 'variant', 'renderValue', 'endAdornment', 'useChips', 'helperText']
    removePropNames.forEach((propName: string): boolean => delete otherProps[propName])

    const isResolved = ((Array.isArray(value) && value.length) || (!Array.isArray(value) && value))
    const typographyVariant = open || focused || isResolved ? 'label' : 'inputText'
    const typographyLabel = <Typography color={theme.palette.text.tertiary} variant={typographyVariant}>{label}</Typography>

    const classesProp = getSelectClasses(props)
    const formControlClassesProp = getFormControlClasses(props, open)

    const otherMuiSelectProps = ['autoWidth', 'fullWidth', 'defaultValue', 'displayEmpty', 'IconComponent', 'input', 'inputProps', 'labelWidth', 'multiple', 'native', 'onChange', 'SelectDisplayProps', 'value', 'variant']
    const propsToPass = {}
    Object.keys(props).forEach(prop => {
        if (otherMuiSelectProps.includes(prop) || ['data-', 'aria-'].some(prefix => prop.startsWith(prefix))) {
            propsToPass[prop] = props[prop]
        }
    })

    const selectComponent = (
        <MuiSelect
            id = {`select-${id}`}
            labelId = {id}
            onOpen = {(e): void => {
                props.onOpen?.(e)
                setOpen(true)
            }}
            onClose = {props.onClose}
            onFocus = {() => setFocused(true)}
            onBlur = {() => {
                setOpen(false)
                setFocused(false)
            }}
            variant = 'filled'
            classes = {classesProp}
            MenuProps = {getMenuProps(props, classes)}
            renderValue = {multiple && useChips
                ? function r (selectedValue): JSX.Element { return renderChipsValue(classes, selectedValue, children, open, error, disabled) }
                : function r (selectedValue): JSX.Element { return renderStringValue(selectedValue, children, `select-${id}`) }
            }
            endAdornment = {renderEndAdornment()}
            {...propsToPass}
        >{children}</MuiSelect>
    )
    const formHelperTextClasses = disabled ? { root: classes.helperTextDisabled } : {}
    const fullWidth = !!props.fullWidth
    return (
        <ThemeProvider>
            <FormControl fullWidth={fullWidth} classes={formControlClassesProp}>
                <InputLabel
                    classes = {{ root: 'MuiInputLabel-root', shrink: 'MuiInputLabel-shrink' }}
                    id = {id}
                >{label ? typographyLabel : ''}</InputLabel>
                {selectComponent}
                {helperText || error ? <FormHelperText classes={formHelperTextClasses} error={error}>{getHelperText(helperText, error)}</FormHelperText> : null}
            </FormControl>
        </ThemeProvider>
    )
}

Select.defaultProps = {
    helperText: null,
    useChips: false
}

export default Select
