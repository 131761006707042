import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.1.1?node-id=9276%3A20999
 *
 * @param {SvgIconProps} props - svg props
 */
export const DoNotDisturbAllIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z' fill={color}/>
            <path d='M19.7645 14.8268C18.5381 17.7285 15.6654 19.7648 12.3171 19.7648C7.85366 19.7648 4.23535 16.1465 4.23535 11.6831C4.23535 8.33451 6.27186 5.46161 9.17388 4.23535C9.17388 4.23535 7.03028 9.35954 10.7007 13.03C14.3712 16.7004 19.7645 14.8268 19.7645 14.8268Z' fill='white'/>
        </SvgIcon>
    )
}

export default DoNotDisturbAllIcon
