<template>
	<tr>
		<td>
			<slot name="first_field_value">
				{{ conf.name }}
			</slot>
		</td>
		<td>
			<w-select
				v-model="conf.type"
				@change="changeType($event, param)"
				:rules="parameters[param].required ? [$validation.required()] : []"
				:items="[
					{value: null, text: l.t('app.none', 'None')},
					{value: 'static', text: l.t('app.static', 'Static')},
				].concat(!conf.static_only ? [{value: 'dynamic', text: l.t('app.dynamic', 'Dynamic')}] : [])"
				hide-details="auto"
				data-test-id="ga-data-type-select"
			/>
		</td>
		<td :key="conf.type">
			<span v-if="!conf.type">&mdash;</span>
			<template v-else>
				<div v-if="!parameters[param].static_only">
					<template
						v-if="(conf.input === 'text' || conf.input === 'number') && parameters[param]['type'] === 'static'"
					>
						<w-text-field
							v-if="parameters[param]['type'] === 'static'"
							not-clearable
							v-model="value"
							:type="conf.input"
							:maxlength="parameters[param]['maxlength']"
							:min="conf.min"
							:rules="parameters[param].required ? [$validation.required()] : []"
							hide-details='auto'
							data-test-id="ga-data-static-input"
						/>
					</template>
					<template v-else-if="conf.input === 'boolean' && parameters[param]['type'] === 'static'">
						<label>
							<v-radio type="radio" v-model="value" :value="1" :rules="parameters[param].required ? [$validation.required()] : []" :name="param"/ :label="l.t('app.true', 'True')" data-test-id="ga-data-1-radio">
						</label>
						<label>
							<v-radio type="radio" v-model="value" :value="0" :rules="parameters[param].required ? [$validation.required()] : []" :name="param" :label="l.t('app.false', 'False')" data-test-id="ga-data-0-radio"/>
						</label>
					</template>
					<w-select
						v-if="parameters[param]['type'] === 'dynamic'"
						v-model="value"
						:rules="parameters[param].required ? [$validation.required()] : []"
						:placeholder="l.t('app.please-choose-the-value', 'Please choose the value')"
						:items="retrieve_items(parameters[param])"
						hide-details="auto"
						data-test-id="ga-data-type-dynamic-select"
					/>
				</div>

				<div v-else>
					<template v-if="conf.input === 'text' || conf.input === 'number'">
						<w-text-field
							v-model="value"
							not-clearable
							:type="conf.input"
							:maxlength="parameters[param]['maxlength']"
							:min="conf.min"
							:rules="parameters[param].required ? [$validation.required()] : []"
							hide-details='auto'
							:data-test-id="`ga-data-${conf.name}-text-num-input`"
						/>
					</template>
					<template v-else-if="conf.input === 'boolean'">
						<w-radio-group
							v-model="value"
							:rules="parameters[param].required ? [$validation.required()] : []"
							:name="param"
							row
							hide-details='auto'
					    >
					      <v-radio
					        :label="l.t('app.true', 'True')"
					        :value="1"
					        :data-test-id="`ga-data-${conf.name}-1-radio`"
					      ></v-radio>
					      <v-radio
					        :label="l.t('app.false', 'False')"
					        :value="0"
					        :data-test-id="`ga-data-${conf.name}-0-radio`"
					      ></v-radio>
					    </w-radio-group>
					</template>
				</div>
			</template>
		</td>
	</tr>
</template>

<script>
import l from '../../../../libs/lang';

export default {
	props: ['_parameters', 'conf', 'param', 'dynamic_values'],
	data() {
		return {
			l,
			parameters: null,
			value: null,
		};
	},
	created() {
		this.$data.parameters = this.$props._parameters;
		if (!this.$data.parameters[this.$props.param]) {
			this.$data.value = null;
		} else {
			this.$data.value = this.$data.parameters[this.$props.param]['value'];
		}
	},
	methods: {
		changeType(value, param) {
			this.$data.parameters[param]['value'] = null;
			if (!value) {
				this.$data.parameters[param]['type'] = null;
			} else {
				this.$data.parameters[param]['type'] = value;
			}
			this.$forceUpdate();
		},
		retrieve_items(data) {
			if (data.dynamic && data.dynamic.length) {
				return data.dynamic.map((x) => ({ value: x, text: this.$props.dynamic_values[x].name }));
			}
			return Object.keys(this.$props.dynamic_values).map((x) => ({value: x, text: this.$props.dynamic_values[x].name}));
		},
	},
	watch: {
		value(val) {
			this.$emit('changed', val);
		},
	},
};
</script>
