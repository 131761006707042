<template>
    <w-loader v-if="queue.loading"/>
    <div class="create-page" v-else data-test-id="queues-create-page">
      <w-alert
        v-if="queue.alert"
        :level="queue.alert.level"
        :message="queue.alert.message"
        :closable="queue.enable_close_alert"
        @closed="queue.alert = null"
        class="mb-6"
      />
      <QueueFragment
        v-if="queue.item"
        :_queue="queue.item"
        @updateQueue="queue.create($event)"
      />
    </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import Queue from '../../../models/Queue';
  import QueueFragment from '../../fragments/Queue.vue';

  export default {
    components: {
      QueueFragment,
    },
    data() {
      return {
        l,
        queue: new Queue(this.$session, vueComponent(this)),
      };
    },
  };
</script>
