/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext } from 'react'
import PhoneComUser from 'phone-com-user'
import PropTypes from 'prop-types'
import { useEnrollmentContext } from '../hooks/useEnrollmentContext'
import { EnrollmentType } from '../interface/EnrollmentType'
import { useAjax } from '../hooks/useAjax'
import ajax from 'ajax'
import { getPhoneCom } from 'phonecom'

const BrandsContext = createContext({
    brands: [],
    verifiedBrands: [],
    brandPayment: null,
    brandNetworkError: null,
    verifiedBrandExists: false,
    networkPending: false,
    paymentInfoFetched: false,
    initialFetchParams: { include_deleted: false },
    enrollmentEnabled: false,

    addBrand: (params) => {},
    editBrand: (params) => {},
    updateBrand: (callbackFn) => {},
    fetchBrands: (params) => {},
    setBrandNetworkError: () => {},
    fetchBrandPaymentDetails: () => {},
    deleteBrand: (brand) => {}
})

/**
 * @param {object} props
 * @param {object} props.children
 * @param {boolean} props.csr
 */
export const BrandContextProvider = ({ children, csr }) => {
    // const [showInstruction, setShowInstruction] = useState(false)
    const apiRootUrl = PhoneComUser.getv5ApiRoot()
    const brandsListUrl = `${apiRootUrl}/messages/10dlc/list-brands`
    const addBrandUrl = `${apiRootUrl}/messages/10dlc/add-brand`
    const editBrandUrl = `${apiRootUrl}/messages/10dlc/edit-brand`
    const brandPaymentDetailsUrl = `${apiRootUrl}/messages/10dlc/billing-preview`
    const deleteBrandUrl = `${apiRootUrl}/messages/10dlc/delete-brand`
    const enrollmentEnabledUrl = `${apiRootUrl}/messages/10dlc/check-enrollments-enabled`

    const executeEnrollmentEnabled = async () => {
        const phonecom = await getPhoneCom()
        const fetchParams = {}
        return ajax.post(enrollmentEnabledUrl, fetchParams, 'Bearer').then(rv => {
            return new Promise((resolve, reject) => {
                resolve(rv.data.enrollments_enabled || phonecom.role === 'account')
            })
        }).catch((err) => {
            return new Promise((resolve, reject) => {
                resolve(false)
            })
        })
    }
    const {
        value: enrollmentEnabled
    } = useAjax(executeEnrollmentEnabled, true)
    const initialFetchParams = { include_deleted: csr }

    const {
        enrollments: brands,
        fetchEnrollments: fetchBrands,
        crudNetworkPending,
        addEnrollment: addBrand,
        deleteEnrollment: deleteBrand,
        editEnrollment: editBrand,
        fetchEnrollmentPaymentDetails: fetchBrandPaymentDetails,
        enrollmentPayment: brandPayment,
        paymentInfoSuccessfullyFetched,
        verifiedEnrollments: verifiedBrands,
        verifiedEnrollmentExists: verifiedBrandExists,
        updateCachedEnrollment: updateBrand,
        enrollmentNetworkError: brandNetworkError,
        setEnrollmentNetworkError: setBrandNetworkError
    } = useEnrollmentContext({
        enrollmentFetchUrl: brandsListUrl,
        enrollmentAddUrl: addBrandUrl,
        enrollmentDeleteUrl: deleteBrandUrl,
        enrollmentEditUrl: editBrandUrl,
        enrollmentPaymentDetailsUrl: brandPaymentDetailsUrl,
        enrollmentType: EnrollmentType.Brand,
        verifiedEnrollmenPredicate: (brand) => {
            return ['approved', 'verified'].includes(brand.registration_status?.toLowerCase())
        },
        initialFetchParams: initialFetchParams
    })

    return (
        <BrandsContext.Provider value={{
            brands,
            verifiedBrands,
            brandPayment,
            paymentInfoFetched: paymentInfoSuccessfullyFetched,
            initialFetchParams: initialFetchParams,
            addBrand,
            editBrand,
            fetchBrands,
            brandNetworkError,
            setBrandNetworkError,
            enrollmentEnabled,
            updateBrand,
            verifiedBrandExists,
            fetchBrandPaymentDetails,
            deleteBrand,
            networkPending: crudNetworkPending
        }} >
            {children}
        </BrandsContext.Provider>
    )
}

BrandContextProvider.propTypes = {
    children: PropTypes.any,
    csr: PropTypes.bool
}

export default BrandsContext
