<template>
    <div>
        <div v-if="device.is_backordered" class="d-flex backordered-wrapper">
            <v-spacer/>
            <span class="backordered w-label warning--text text--lighten-1 w-100">
                {{$lang.t('devices.abckordered', 'Backordered')}}
            </span>
        </div>
        <v-img
            :src="device.image" alt=""
            height="120"
            contain
            :data-test-id="`buy-device-item-${index}-image`"
        />
        <div
            class="w-subtitle-1 info--text text--darken-1 mt-5"
            :data-test-id="`buy-device-item-${index}-title`"
        >
            {{generate_device_name(device)}}
        </div>
        <span
            class="w-subtitle-1"
            :data-test-id="`buy-device-item-${index}-price`"
        >
            ${{ formatPrice(device.price.price) }}
        </span>
        <p v-if="device.description" class="product-description w-caption">{{ device.description }}</p>
        <w-btn
            @click="$emit('click:details', device)"
            text
            changes-form
            :color="'primary'"
            class="product-details-btn"
        >
        {{ $lang.t("call-flow.see-details", "See details") }} <v-icon size="16" color="primary">$vuetify.icons.chevron_forward</v-icon>
        </w-btn>
        <div class="d-flex mx-auto bottom-controls">
            <w-btn
                @click="$emit('click:minus', device)"
                :disabled="!current_cart.filter((x) => x.id === device.id).length"
                color="primary"
                class="my-auto"
                icon
            >
                <v-icon :data-test-id="`buy-device-item-${index}-minus`">mdi-minus-circle</v-icon>
            </w-btn>
            <w-text-field
                disabled
                :value="current_cart.filter((x) => x.id === device.id).length"
                type="number"
                class="devices-quanity-input centered-input hide-arrows small-input"
                hide-details="auto"
                :key="input_key"
                :data-test-id="`buy-device-item-${index}-quantity-input`"
            />
            <w-btn
                @click="$emit('click:plus', device)"
                :disabled="type === 'device' && !available_users_number()"
                color="primary"
                class="my-auto"
                icon
            >
                <v-icon :data-test-id="`buy-device-item-${index}-plus`">
                mdi-plus-circle
                </v-icon>
            </w-btn>
        </div>
    </div>  
</template>
<script>
    import { formatPrice as fPrice } from 'formatters';

  export default {
    props: {
        device: {
            required: true,
            type: Object,
        },
        index: {
            required: true,
            type: Number,
        },
        generate_device_name: {
            required: true,
            type: Function,
        },
        available_users_number: {
            required: true,
            type: Function,
        },
        current_cart: {
            required: true,
            type: Array,
        },
        input_key: {
            required: true,
            type: Number,
        },
        type: {
            type: String,
            validator: (v) => ['device', 'accessory'].includes(v),
            default: 'device',
        }
    },
    data() {
      return {
      };
    },
    methods: {
        formatPrice(val) {
			return fPrice(val);
		},
    }
  };
</script>
