/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext } from 'react'
import PhoneComUser from 'phone-com-user'
import PropTypes from 'prop-types'
import { useEnrollmentContext } from '../hooks/useEnrollmentContext'
import { EnrollmentType } from '../interface/EnrollmentType'

/**
 *
 */
export const CampaignContext = createContext({
    campaigns: [],
    campaignPayment: null,
    campaignNetworkError: null,
    acceptedCampaigns: [],
    acceptedCampaignsExists: false,
    paymentInfoFetched: false,
    networkPending: false,
    initialFetchParams: { include_deleted: false },

    addCampaign: (params) => {},
    deleteCampaign: (params) => {},
    editCampaign: (params) => {},
    fetchCampaigns: (params) => {},
    setCampaignNetworkError: () => {},
    updateCampaign: (callbackFn) => {},
    fetchCampaignPaymentDetails: () => {}
})

/**
 * @param {object} root0
 * @param {object} root0.children
 * @param {boolean} root0.csr
 */
export const CampaignContextProvider = ({ children, csr }) => {
    const campaignsListUrl = `${PhoneComUser.getv5ApiRoot()}/messages/10dlc/list-campaigns`
    const addCampaignUrl = `${PhoneComUser.getv5ApiRoot()}/messages/10dlc/add-campaign`
    const editCampaignUrl = `${PhoneComUser.getv5ApiRoot()}/messages/10dlc/edit-campaign`
    const deleteCampaignUrl = `${PhoneComUser.getv5ApiRoot()}/messages/10dlc/delete-campaign`
    const campaignPaymentDetailsUrl = `${PhoneComUser.getv5ApiRoot()}/messages/10dlc/billing-preview`
    const initialFetchParams = { include_deleted: csr }

    const {
        enrollments: campaigns,
        verifiedEnrollments: acceptedCampaigns,
        verifiedEnrollmentExists: acceptedCampaignsExists,
        fetchEnrollmentPaymentDetails: fetchCampaignPaymentDetails,
        fetchEnrollments: fetchCampaigns,
        enrollmentPayment: campaignPayment,
        deleteEnrollment: deleteCampaign,
        addEnrollment: addCampaign,
        editEnrollment: editCampaign,
        updateCachedEnrollment: updateCampaign,
        paymentInfoSuccessfullyFetched,
        crudNetworkPending,
        enrollmentNetworkError: campaignNetworkError,
        setEnrollmentNetworkError: setCampaignNetworkError
    } = useEnrollmentContext({
        enrollmentFetchUrl: campaignsListUrl,
        enrollmentAddUrl: addCampaignUrl,
        enrollmentDeleteUrl: deleteCampaignUrl,
        enrollmentEditUrl: editCampaignUrl,
        enrollmentPaymentDetailsUrl: campaignPaymentDetailsUrl,
        enrollmentType: EnrollmentType.Campaign,
        verifiedEnrollmenPredicate: (campaign) => {
            return ['approved', 'accepted'].includes(campaign.status?.toLowerCase())
        },
        initialFetchParams: initialFetchParams
    })

    return (
        <CampaignContext.Provider value={{
            campaigns,
            campaignNetworkError,
            acceptedCampaigns,
            acceptedCampaignsExists,
            campaignPayment,
            paymentInfoFetched: paymentInfoSuccessfullyFetched,
            networkPending: crudNetworkPending,
            initialFetchParams: initialFetchParams,
            addCampaign,
            deleteCampaign,
            editCampaign,
            fetchCampaigns,
            setCampaignNetworkError,
            updateCampaign,
            fetchCampaignPaymentDetails
        }}>
            {children}
        </CampaignContext.Provider>
    )
}

CampaignContextProvider.propTypes = {
    children: PropTypes.any,
    csr: PropTypes.bool
}
