import Resource from './Resource'

/**
 *
 */
export default class UserSchedule extends Resource {
    /**
     * @param {object} user
     * @param {object} session Phoenix session
     * @param {object} component helpers vueComponent
     */
    constructor (user, session, component) {
        super(session, component, '/video-conferences')
        this.user = user
        this.extension = user.extension
        this.item = {
            subject: '',
            agenda: '',
            start: null,
            time_zone: null,
            duration: 60,
            auto_record: 'none',
            one_time_access_code: false,
            auto_transcribe: false,
            mute_mode: 'conversation',
            participants: []
        }
    }

    /**
     * @param {object} params
     */
    async loadItems (params) {
        if (!this.extension) return null
        this.loading = true
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page)
            const items = await this.session.get_list(
                this.baseUri, params.limit, offset
            )

            this.page = params.page
            if (this.page > 1 && !items.items.length) {
                this.component.emit('pageChanged', 1)
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1
                })
            }

            this.items = items.items
            this.finalizeLoadingItems(items)
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false

        return this.items
    }

    /**
     * @param {object} schedule
     */
    async create (schedule) {
        this.loading = true
        try {
            await this.session.create_item(this.baseUri, schedule)
            this.item = null
            this.successfulCreation('video.users.schedules.index')
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
    }
}
