<template>
	<div>
		<SectionTitle data-test-id="media-loader-account-list-title">
			{{ l.t('media-loader.acc-list-title', 'List of accounts and subaccounts that need media loader') }}
		</SectionTitle>
		<v-simple-table>
			<thead>
				<tr>
					<th><w-checkbox v-model="media.checkAllAccounts" @change="checkAllAccounts" data-test-id="media-loader-account-list-select-all-checkbox"/></th>
					<th>{{ l.t('app.id', 'ID') }}</th>
					<th>{{ l.t('app.type', 'Type') }}</th>
					<th>{{ l.t('app.name', 'Name') }}</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(account, i) in media.accounts">
					<td>
						<w-checkbox v-model="account.selected" @click="checkSubmit" name="checkbox" :value="true" required :data-test-id="`media-loader-account-list-item-${i}-checkbox`"/>
					</td>
					<td>{{ account.id }}</td>
					<td>{{ account.main ? l.t('app.main-account', 'Main account') : l.t('app.subaccount', 'Subaccount') }}</td>
					<td>{{ account.name }}</td>
				</tr>
			</tbody>
		</v-simple-table>
		<div class="d-flex justify-end mt-3">
			<w-btn @click="$emit('submitted')" :disabled="disableSubmit" color="primary" data-test-id="media-loader-account-list-continue">
				{{ l.t('app.continue', 'Continue') }}
			</w-btn>
		</div>
		<w-btn @click="media.screen = 'show-import-csv-form'" :disabled="disableSubmit" color="info" block class="mt-3" data-test-id="media-loader-account-list-import-csv-btn">
			{{ l.t('media-loader.import-csv', 'Or import list of media from .csv file') }}
		</w-btn>
	</div>
</template>

<script>
	import l from '../../../libs/lang';
	import SectionTitle from '../../elements/SectionTitle.vue';

	export default {
		props: ['media'],
		components: { SectionTitle, },
		data() {
			return {
				l,
				disableSubmit: false,
			};
		},
		methods: {
			checkSubmit() {
				setTimeout(() => {
					this.$data.disableSubmit = true;
					this.$props.media.checkAllAccounts = true;
					for (const account of this.$props.media.accounts) {
						if (account.selected) this.$data.disableSubmit = false;
						if (!account.selected) this.$props.media.checkAllAccounts = false;
					}
				}, 1);
			},
			checkAllAccounts() {
				if (this.media.checkAllAccounts) {
					this.$props.media.selectAllAccounts();
					this.$data.disableSubmit = false;
				} else {
					this.$props.media.deselectAllAccounts();
					this.$data.disableSubmit = true;
				}
				this.$forceUpdate();
			},
		},
	};
</script>
