import React from 'react'
import { Grid } from '@material-ui/core'
import Typography from 'typography'
import { Textarea } from 'textarea-mui'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    fullWidthTextArea: {
        width: '100%',
        resize: 'none',
        border: '1px solid lightgrey',
        borderRadius: '0.25rem'
    },
    topMarginSpacing: {
        marginTop: '1rem'
    },
    gridJustifyContent: {
        justifyContent: 'space-between'
    }
}))

/**
 *
 */
const PreviewSampleMessages = ({ enrollment }) => {
    const classes = useStyles()
    const sampleMessageProps = ['messageflow', 'samplesms1', 'samplesms2', 'samplesms3']
    const remoteConfigMapper = {
        messageflow: 'sms_enrollment_message_flow',
        samplesms1: 'sms_enrollment_message_one_title',
        samplesms2: 'sms_enrollment_message_two_title',
        samplesms3: 'sms_enrollment_message_three_title'
    }
    return <Grid
        className={classes.gridJustifyContent}
        container
        direction='column'
        spacing={1}>
        {sampleMessageProps.map((x, index) => (
            <React.Fragment key={index}>
                <Typography variant='body1' className={classes.topMarginSpacing} remoteConfigID={remoteConfigMapper[x]} />
                <Textarea className={classes.fullWidthTextArea} value={enrollment[x]} readOnly={true} />
            </React.Fragment>
        ))}
    </Grid>
}

PreviewSampleMessages.propTypes = {
    enrollment: PropTypes.object
}

export default PreviewSampleMessages
