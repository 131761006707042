<template>
    <w-loader
      v-if="phone.loading"
      :message="l.t('phone-number-add.loading', 'Purchasing is in progress')"
    />
    <div v-else :class="{'create-page' : !($route.name === 'phone-numbers.create')}" data-test-id="phone-numbers-create-page">
      <w-alert
        v-if="phone.alert"
        :message="phone.alert.message"
        :level="phone.alert.level"
        :closable="phone.enable_close_alert"
        @closed="phone.alert = null"
        class="mb-2"
      />
      <NumberPicker
        v-if="$route.name === 'phone-numbers.create'"
        @reserved="number_chosen($event)"
        data-test-id="phone-numbers-create-number-picker"
      />
      <ConfirmationModal
        v-if="confirmation_modal"
        @confirmed="confirmed"
        @close="confirmation_modal = false"
        class="mt-3"
        :message="l.t(
            'phone-number-add.confirmation',
            'Do you want to buy number {}?',
            [reservation.phone_number]
        )"
        data-test-id="phone-numbers-create-confirmation-modal"
      />
    </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import PhoneNumber from '../../../models/PhoneNumber';
  import NumberPicker from '../../fragments/NumberPicker.vue';
  import ConfirmationModal from '../../elements/modal/ConfirmationModal.vue';

  export default {
    components: {
      NumberPicker,
      ConfirmationModal,
    },
    data() {
      return {
        l,
        phone: new PhoneNumber(this.$session, vueComponent(this)),
        reservation: null,
        confirmation_modal: false,
      };
    },
    methods: {
      number_chosen(reservation) {
        this.$data.reservation = reservation;
        this.confirmation_modal = true;
      },
      async confirmed() {
        this.confirmation_modal = false;
        await this.$data.phone.reservation_handler(this.$data.reservation);
        this.$data.reservation = null;
      }
    },
    watch: {
      // eslint-disable-next-line object-shorthand
      confirmation_modal: function (val) {
        if (!val) {
          this.$data.reservation = null;
        }
      }
    }
  };
</script>
