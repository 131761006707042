var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"faxes"},[_c('Submenu',{key:("" + (_vm.l.detect()) + (_vm.$route.name)),attrs:{"routes":{
      'fax.index': {
        title: _vm.l.t('fax.inbox', 'Fax inbox')
      },
      'fax.create': {
        title: _vm.l.t('fax.send', 'Send fax')
      }
    }}}),_vm._v(" "),(_vm.$route.name === 'fax.index')?_c('FaxesFragment',{attrs:{"data-test-id":"faxes-index"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'fax.create')?_c('FaxesCreateFragment',{attrs:{"data-test-id":"faxes-create"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }