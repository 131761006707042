/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react'
import Typography from 'typography'
import { TextField } from 'text-field-mui'
import RemoteConfigValue, { getValue } from 'remote-config-value'
import { Radio, Variant } from 'radio-mui'
import { Select } from 'select-mui'
import { MenuItem } from 'menu-item-mui'
import { Alert, Color as AlertColor } from 'alert-mui'
import { ScreenSizeContext } from 'providers'
// import BusinessInfoStepBackground from '../images/sms_business_information_bg.png'
import { countryOptions, verticalOptions, publiclyTradedBizType, businessTypeOptions } from '../utils/util'
import EnrollmentStepContainer from './EnrollmentStepContainer'
import { makeStyles } from '@material-ui/core/styles'
import { useFormInput } from '../hooks/useFormInput'

const styles = makeStyles((theme) => ({
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: screenSizeContext => !screenSizeContext.mobile ? 'nowrap' : 'wrap',
        justifyContent: 'flex-start',
        columnGap: '1rem',
        marginBottom: screenSizeContext => !screenSizeContext.mobile ? '1.25rem' : '0',
        '&:first-child': {
            marginBottom: '1.25rem'
        }
    },
    brandNameContainer: {
        width: screenSizeContext => !screenSizeContext.mobile ? '50%' : '100%'
    },
    halfRowContainer: {
        flexGrow: 1,
        width: screenSizeContext => !screenSizeContext.mobile ? '50%' : '100%',
        marginBottom: screenSizeContext => !screenSizeContext.mobile ? '0' : '1.25rem'
    },
    businessTypesContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: '1rem',
        '& > label': {
            width: '150px'
        }
    },
    asterisk: {
        color: theme.palette.error.main
    },
    marginBottom: {
        marginBottom: '1.25rem'
    }
}))

/**
 * @param {object} props
 */
const BusinessInformationStep = (props) => {
    const screenSizeContext = useContext(ScreenSizeContext)
    const classes = styles(screenSizeContext)
    const { readOnly = false, showContainer = true } = props
    const bizInfoSavedData = props.passData.business_info
    const disabledField = props.editing != null && (props.editing.registration_status === 'approved' || props.editing.campaign_count > 0)

    const defaultBrandName = bizInfoSavedData ? bizInfoSavedData.brandName : (props.editing != null ? props.editing.name : null)
    const defaultLegalBizName = bizInfoSavedData ? bizInfoSavedData.legalBizName : (props.editing != null ? props.editing['contact.name'] : null)
    const defaultRegistrationCountry = bizInfoSavedData ? bizInfoSavedData.registrationCountry : (props.editing != null ? props.editing.country : null)
    const {
        value: brandName,
        onChange: setBrandName,
        onXClick: onBrandXClick,
        validValue: validBrandName
    } = useFormInput(
        defaultBrandName,
        (value) => value as boolean
    )
    const {
        value: legalBizName,
        onChange: setLegalBizName,
        onXClick: onLegalXClick,
        validValue: validLegalBizName
    } = useFormInput(
        defaultLegalBizName,
        (value) => value as boolean
    )
    const {
        value: registrationCountry,
        onChange: setRegistrationCountry,
        validValue: validRegistrationCountry
    } = useFormInput(
        defaultRegistrationCountry,
        (value) => value as boolean
    )

    const defaultFederalBusinessID = bizInfoSavedData ? bizInfoSavedData.federalBusinessID : (props.editing != null ? props.editing.business_identifier : null)
    const nineDigitPattern = new RegExp('^\\d{9}$')
    const {
        value: federalBusinessID,
        onChange: setFederalBusinessID,
        onXClick: onFedBizIDXClear,
        validValue: validFederalBusinessID
    } = useFormInput(
        defaultFederalBusinessID,
        (value) => value == null ? false : value.match(nineDigitPattern) != null
    )

    const defaultBizType = bizInfoSavedData ? bizInfoSavedData.selectedBizType : (props.editing != null ? props.editing.business_type_name : null)
    const {
        value: selectedBizType,
        onChange: setSelectedBizType,
        validValue: validBizType
    } = useFormInput(
        defaultBizType,
        (value) => value as boolean
    )
    const handleBizTypeOnChange = (businessType) => {
        setSelectedBizType({ target: { value: businessType } })
    }

    const defaultVerticle = bizInfoSavedData ? bizInfoSavedData.selectedVerticle : (props.editing != null ? props.editing.business_vertical_name : null)
    const {
        value: selectedVerticle,
        onChange: setSelectedVerticle,
        validValue: validSelectedVerticle
    } = useFormInput(
        defaultVerticle,
        (value) => value as boolean
    )

    const defaultStockSymbol = bizInfoSavedData ? bizInfoSavedData.stockSymbol : (props.editing != null ? props.editing.stock_symbol : null)
    const {
        value: stockSymbol,
        onChange: setStockSymbol,
        onXClick: clearStockSymbol,
        validValue: validStockSymbol
    } = useFormInput(
        defaultStockSymbol,
        (value) => value as boolean
    )

    const invalid = !validBrandName || !validLegalBizName || !validRegistrationCountry || !validFederalBusinessID || !validBizType || !validSelectedVerticle || (selectedBizType === publiclyTradedBizType.value && !validStockSymbol)

    // eslint-disable-next-line no-unused-vars
    const onClickHandler = () => {
        props.update({ completed: true, gotoNext: true, saveData: getSaveData() })
    }

    const getSaveData = () => {
        return {
            legalBizName,
            registrationCountry,
            federalBusinessID,
            selectedBizType,
            selectedVerticle,
            stockSymbol,
            brandName
        }
    }

    useEffect(() => {
        props.update({ disableNextButton: () => invalid })
    }, [invalid])

    // Runs when the next button is clicked
    useEffect(() => {
        if (props.saveId) props.update({ completed: true, gotoNext: true, saveData: getSaveData() })
    }, [props.saveId])
    return (
        <EnrollmentStepContainer showContainer={showContainer}>
            {/* Removing this for now since I think it makes the page look bad - Chris
                <img
                style={{ width: '100%', paddingBottom: '1rem' }}
                src={BusinessInfoStepBackground} alt="screenshot" />
            */}
            <Alert soft classes={{ root: classes.marginBottom }} color={AlertColor.ERROR} content={<RemoteConfigValue valueId='sms_enrollment_business_enrollment_info' />} />
            <div>
                <div className={classes.rowContainer}>
                    <div className={classes.brandNameContainer}>
                        <Typography variant='body1'>{getValue('sms_enrollment_brand_name')} <span className={classes.asterisk}>*</span></Typography>
                        <TextField InputProps={{ readOnly: readOnly }} id='brandNameTxtField' fullWidth={true} label={getValue('sms_enrollment_enter_brand_name')} value={brandName} onChange={setBrandName} onXClick={readOnly ? undefined : onBrandXClick} />
                    </div>
                </div>
                <div className={classes.rowContainer}>
                    <div className={classes.halfRowContainer}>
                        <Typography variant='body1'>{getValue('sms_enrollment_legal_business_name')} <span className={classes.asterisk}>*</span></Typography>
                        <TextField
                            id='legalBizNameTxtField'
                            InputProps={{ readOnly: readOnly }}
                            fullWidth={true}
                            disabled={readOnly ? false : disabledField}
                            label={getValue('sms_enrollment_enter_legal_business_name')}
                            value={legalBizName}
                            onChange={setLegalBizName}
                            onXClick={readOnly ? undefined : onLegalXClick} />
                    </div>
                    <div className={classes.halfRowContainer}>
                        <Typography variant='body1'>{getValue('sms_enrollment_country_of_registration')} <span className={classes.asterisk}>*</span></Typography>
                        <div>
                            <Select
                                fullWidth={true}
                                readOnly={readOnly}
                                id='countrySelect'
                                label='Select country'
                                value={registrationCountry}
                                onChange={setRegistrationCountry}>
                                {
                                    countryOptions.map((x) => (
                                        <MenuItem key={x.value} value={x.value} checked={registrationCountry === x.value}>{x.content}</MenuItem>
                                    ))
                                }
                            </Select>
                        </div>
                    </div>
                </div>
                <div className={classes.rowContainer}>
                    <div className={classes.halfRowContainer}>
                        <Typography variant='body1'>{getValue('sms_enrollment_federal_business_id')} <span className={classes.asterisk}>*</span></Typography>
                        <Typography variant='subtitle3' remoteConfigID='sms_enrollment_federal_business_subtitle' />
                        <TextField
                            id='einTxtField'
                            disabled={readOnly ? false : disabledField}
                            error={federalBusinessID != null && !validFederalBusinessID}
                            helperText={validFederalBusinessID ? null : getValue('sms_enrollment_ein_error')}
                            fullWidth={true}
                            type='numeric'
                            InputProps={{ readOnly: readOnly }}
                            inputProps={{ maxLength: 9 }}
                            label={getValue('sms_enrollment_enter_federal_business_id')}
                            value={federalBusinessID}
                            onChange={setFederalBusinessID}
                            onXClick={readOnly ? undefined : onFedBizIDXClear} />
                    </div>
                    <div className={classes.halfRowContainer}>

                        <Typography variant='body1'>
                            {getValue('sms_enrollment_vertical')} <span className={classes.asterisk}>*</span>
                        </Typography>
                        <Typography variant='subtitle3' remoteConfigID='sms_enrollment_select_one' />
                        <div>
                            <Select id='verticalSelect' readOnly={readOnly} fullWidth={true} label='Select vertical' value={selectedVerticle} onChange={setSelectedVerticle}>
                                {verticalOptions.map((x) => (
                                    <MenuItem key={x.id} value={x.id} checked={selectedVerticle === x.id}>{x.displayName}</MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className={classes.rowContainer}>
                    <div className={classes.halfRowContainer}>
                        <Typography id='stockSymbolLabel' variant='body1'>{getValue('sms_enrollment_stock_symbol')} {selectedBizType === publiclyTradedBizType.value && (<span className={classes.asterisk}>*</span>)}</Typography>
                        <Typography variant='subtitle3' remoteConfigID='sms_enrollment_stock_symbol_subtitle' />
                        <TextField InputProps={{ readOnly: readOnly }} id='stockTxtField' fullWidth={true} label={getValue('sms_enrollment_enter_stock_symbol')} value={stockSymbol} onChange={setStockSymbol} onXClick={readOnly ? undefined : clearStockSymbol} />
                    </div>
                    <div className={classes.halfRowContainer}>
                        <Typography variant='body1' classes={{ root: classes.marginBottom }}>{getValue('sms_enrollment_business_type')} <span className={classes.asterisk}>*</span></Typography>
                        <div className={classes.businessTypesContainer}>
                            {businessTypeOptions.filter(x => readOnly ? selectedBizType === x.value : true).map((x, i) =>
                                (<Radio readOnly={readOnly} disabled={readOnly ? false : disabledField} key={`business_type_key_${i}`} variant={Variant.PRIMARY} checked={selectedBizType === x.value} onChange={() => handleBizTypeOnChange(x.value)} name={x.name} value={x.value} label={x.label} />)
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </EnrollmentStepContainer>)
}

export default BusinessInformationStep
