var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Submenu',{key:("" + (_vm.l.detect()) + (_vm.$route.name)),attrs:{"routes":{
      'media.index': {
        title : _vm.l.t('app.greetings', 'Greetings'),
        subroutes: ['media.show']
      },
      'media.hold-music': {
        title: _vm.l.t('app.music-on-hold', 'Music on hold'),
        subroutes: ['media.music-on-hold-show']
      },
      'media.create': {
        title: _vm.l.t('media.add-greetings-music', 'Add greetings & music')
      },
      'media.loader': {
        title: _vm.l.t('media.loader', 'Mass loader')
      },
    }}}),_vm._v(" "),(_vm.$route.name === 'media.index')?_c('MediaFragment',{attrs:{"type":"greetings","data-test-id":"greetings"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'media.hold-music')?_c('MediaFragment',{attrs:{"type":"hold_music","data-test-id":"hold-music"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'media.create')?_c('AddMediaFragment',{attrs:{"data-test-id":"media-create"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'media.show' || _vm.$route.name === 'media.music-on-hold-show')?_c('ShowMediaFragment',{attrs:{"data-test-id":"media-show"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'media.loader')?_c('LoaderFragment',{attrs:{"data-test-id":"media-loader"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }