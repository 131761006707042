import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { backBtnArrow, backBtnArrowHover } from 'pdc-icons'

const useStyles = makeStyles(theme => ({
	backButtonWrapper: {
		'&:hover': {
			'& .back-btn': {
				display: 'none'
			}
		},
		'&:not(:hover)': {
			'& .back-btn-hover': {
				display: 'none'
			}
		}
	}
}))

export default function BackButton(props) {
	const classes = useStyles()
	return (
		<span className={classes.backButtonWrapper} onClick={props.onClick}>
			<img className='back-btn' src={backBtnArrow} alt='Back' />
			<img className='back-btn-hover' src={backBtnArrowHover} alt='Back' />
		</span>
	)
}