import React from 'react'
import { FSPrivate } from 'privacy'
import searchIcon from '../images/icon-search.svg'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	wrapper: {
		height: '100%',
		'&.small-view': {
			height: 'fit-content'
		}
	},
	extensionList: {
		width:			'100%',
		maxHeight:		411,
		float:			'left',
		direction:		'rtl',
		paddingLeft:	15,
		display:		'flex',
		flexDirection:	'column',
		overflowY:		'auto'
	},
	extensionItemRow: {
		width:		'100%',
		display:	'block',
		lineHeight:	1.5,
		cursor:		'pointer',
		padding:	'0px 3px',
		'&:hover': {
			background: 'lightgray'
		},
		'&.small-view': {
			lineHeight: 2
		}
	},
	extensionNum: {
		width:			'45%',
		paddingRight:	10,
		display:		'block',
		float:			'left',
		fontWeight:		'bold'
	},
	extensionName: {
		width:			'55%',
		display:		'block',
		overflow:		'auto',
		verticalAlign:	'middle',
		wordBreak:		'break-word'
	},
	filterItem: {
		position:		'relative',
		borderBottom:	'1px solid lightgray',
		marginBottom:	5,
		'& input': {
			width:		'100%',
			padding:	'7px 5px',
			border:		'none',
			fontSize:	16,
			'&:focus': {
				outline: 'none'
			}
		}
	},
	searchIcon: {
		position:	'absolute',
		right:		10,
		top:		6,
		color:		'gray',
		cursor:		'default',
		width:		23
	}
})

class InlineExtensionSelector extends React.Component {

	constructor(props) {
		super(props)
		this.state = {extensions: props.extensionData || [], filterValue: ''}
	}

	extensionSelectHandler = (item, index) => {
		if (this.props.onExtensionSelect) this.props.onExtensionSelect(item, index)
	}

	renderExtensionItem = (item, i) => {
		const { classes } = this.props
		let { isMobileView, isTabletView } = this.props.screenViewType
		let smallScreen		= isMobileView || isTabletView
		let filterValue		= this.state.filterValue.toLowerCase()
		let isFilteredOut	= Boolean(filterValue)
		if (filterValue) {
			if (`EXT. ${item.extension}`.toLowerCase().includes(filterValue)) isFilteredOut = false
			if (item.extension_name.toLowerCase().includes(filterValue)) isFilteredOut = false
		}
		return (
			<div
				className={`${classes.extensionItemRow} ${smallScreen ? 'small-view' : ''} ${isFilteredOut ? 'hidden' : ''}`}
				onClick={() => this.extensionSelectHandler(item, i)}
			>
				<div className={classes.extensionNum}>{`EXT. ${item.extension}`}</div>
				<div className={classes.extensionName}>{`${item.extension_name}`}</div>
			</div>
		)
	}

	renderFilterItem() {
		const { classes } = this.props
		return (
			<div className={classes.filterItem}>
				<FSPrivate>
					<input autoFocus={true} type='text' placeholder='Search extensions' onChange={this.onFilterChange} />
				</FSPrivate>
				<img src={searchIcon} className={classes.searchIcon} alt='search icon' />
			</div>
		)
	}

	onFilterChange = e => {
		e.persist()
		this.setState({filterValue: e.target.value})
	}

	render() {
		let { classes } = this.props
		let { isMobileView, isTabletView } = this.props.screenViewType
		return (
			<div className={`${classes.wrapper} ${isMobileView || isTabletView ? 'small-view' : ''}`}>
				{this.state.extensions.length > 5 ? this.renderFilterItem() : null}
				<div className={classes.extensionList}>
					{this.state.extensions.map((item, i) => 
						<FSPrivate key={item.extension_id}>
							{this.renderExtensionItem(item, i)}
						</FSPrivate>
					)}
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(InlineExtensionSelector)