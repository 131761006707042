import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { MakeCallIcon, StartConversationIcon, SendFaxIcon } from 'pdc-svg-icons'
import PropTypes from 'prop-types'
import styles from './styles'

const useStyles = makeStyles(styles)

type AppName = 'calls' | 'faxes' | 'messages'

interface Props {
    app: AppName
    title: string
    onClick: () => void
    disabled?: boolean
    className?: string
    icon?: JSX.Element
}

/***/
const StartNewButton = (props: Props): JSX.Element | null => {
    const classes = useStyles()
    const [hovered, setHovered] = useState(false)
    let Icon = null
    let dataTestId = ''
    switch (props.app) {
            case 'calls':
                Icon = MakeCallIcon
                dataTestId = 'start-conversation'
                break
            case 'messages':
                Icon = StartConversationIcon
                dataTestId = 'start-conversation'
                break
            case 'faxes':
                Icon = SendFaxIcon
                dataTestId = 'start-conversation'
                break
            default:
                Icon = SendFaxIcon
    }
    return (
        <button
            className = {`${classes.startNewButton} ${props.className || ''}`}
            onClick = {props.onClick}
            onMouseEnter = {() => setHovered(true)}
            onMouseLeave = {() => setHovered(false)}
            data-test-id = {dataTestId}
            disabled = {props.disabled}
        >
            <div className={classes.iconContainer}>
                {props.icon || <Icon className={classes.startNewIcon} hover={hovered ? 1 : 0}/>}
            </div>
            <span>{props.title}</span>
        </button>
    )
}

StartNewButton.propTypes = {
    app: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
}

export default StartNewButton
