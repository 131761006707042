import React, { useEffect } from 'react'
import Typography from 'typography'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from 'text-field-mui'
import { getValue } from 'remote-config-value'
import PropTypes from 'prop-types'
import CampaignEnrollmentStepContainer from './CampaignEnrollmentStepContainer'
import { useFormInput } from '../hooks/useFormInput'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        gap: '1rem',
        marginTop: '1.25rem',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        }
    },
    item: {
        flexGrow: 0.5
    },
    item2: {
        flexGrow: 2
    },
    readOnlyContainer: { paddingTop: '1rem', paddingBottom: '1rem' }
}))

/**
 * @param {object} props
 */
const CampaignContactStep = (props) => {
    const classes = useStyles()
    const { readOnly = false, showTitle = true, showContainer = true } = props
    const campaignContactSavedData = props.passData.campaign_contact
    const { editing } = props
    const disabledField = props.editing != null

    const nameRegExp = new RegExp(/^[a-zA-Z ]+$/g)
    const defaultName = campaignContactSavedData ? campaignContactSavedData.name : (editing != null ? editing.contact_name : '')
    const {
        value: name,
        onChange: setName,
        onXClick: onNameXClick,
        validValue: validName
    } = useFormInput(
        defaultName,
        (value) => value.length >= 2 && value.match(nameRegExp) != null && value.trim().replace(/\s{2,}/g, ' ')
    )

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    const emailRegExp = new RegExp(emailPattern)
    const defaultEmail = campaignContactSavedData ? campaignContactSavedData.email : (editing != null ? editing.contact_email : '')
    const {
        value: email,
        onChange: setEmail,
        onXClick: onEmailXClick,
        validValue: validEmail
    } = useFormInput(
        defaultEmail,
        (value) => value.match(emailRegExp) != null
    )

    const getSaveData = () => {
        return {
            name,
            email
        }
    }

    const invalid = !validName || !validEmail

    useEffect(() => {
        if (props.saveId) props.update({ completed: true, gotoNext: true, saveData: getSaveData() })
    }, [props.saveId])

    useEffect(() => {
        props.update({ disableNextButton: () => invalid })
    }, [invalid])

    const renderReadonlySection = () => (
        <div className={classes.container}>
            <div className={classes.item2}>
                <Typography variant='h6' remoteConfigID='sms_enrollment_name' />
                <Typography variant='subtitle3'>{name}</Typography>
            </div>
            <div className={classes.item2}>
                <Typography variant='h6' remoteConfigID='sms_enrollment_email' />
                <Typography variant='subtitle3'>{email}</Typography>
            </div>
        </div>
    )

    return (
        <CampaignEnrollmentStepContainer readOnly={readOnly} showContainer={showContainer}>
            {
                readOnly && showTitle && (
                    <div className={classes.readOnlyContainer}>
                        <Typography id='title' variant='h4' remoteConfigID='sms_enrollment_contact_information' />
                    </div>
                )
            }
            <Typography variant='h6' remoteConfigID={'sms_enrollment_contact_info_heading'} />
            {readOnly
                ? renderReadonlySection()
                : (
                    <div className={classes.container}>
                        <div className={classes.item2}>
                            <Typography remoteConfigID='sms_enrollment_name' />
                            <TextField
                                id='nameTxtField'
                                disabled={disabledField}
                                fullWidth={true}
                                label={getValue('sms_enrollment_enter_name')}
                                value={name}
                                onChange={setName}
                                onXClick={onNameXClick}
                                error={name.length > 0 && !validName}
                                helperText={validName ? null : getValue('sms_enrollment_campaign_contact_name_helperText')}
                                inputProps={{ maxLength: 50 }}
                            />
                        </div>
                        <div className={classes.item2}>
                            <Typography remoteConfigID='sms_enrollment_email' />
                            <TextField
                                id='emailTxtField'
                                disabled={disabledField}
                                fullWidth={true}
                                label={getValue('sms_enrollment_enter_email')}
                                value={email}
                                onChange={setEmail}
                                onXClick={onEmailXClick}
                                error={email.length > 0 && !validEmail}
                                helperText={validEmail ? null : getValue('sms_enrollment_campaign_contact_email_helperText')} />
                        </div>
                    </div>
                )}

        </CampaignEnrollmentStepContainer>
    )
}

CampaignContactStep.propTypes = {
    passData: PropTypes.object,
    update: PropTypes.func,
    saveId: PropTypes.number,
    readOnly: PropTypes.bool,
    editing: PropTypes.object,
    showTitle: PropTypes.bool,
    showContainer: PropTypes.bool
}

export default CampaignContactStep
