import { formatPhoneNumber } from 'formatters'

/**
 *
 * @param {object} filter
 * @returns {string}
 */
const filterName = function (filter) {
    let name = ''
    switch (filter.type) {
            case 'schedule':
                name = `Schedule: ${filter.schedule && filter.schedule.name ? filter.schedule.name : ''}`; break
            case 'group':
            case 'contact_group':
                name = `Group: ${filter.group && filter.group.name ? filter.group.name : ''}`; break
            case 'contact': {
                const firstName = filter.contact && filter.contact.first_name ? filter.contact.first_name : ''
                const lastName = filter.contact && filter.contact.last_name ? filter.contact.last_name : ''
                name = `${firstName} ${lastName}`; break
            }
            case 'unknown':
                name = 'No valid caller ID'; break
            default:
                name = 'All Calls'; break
    }
    return name
}

/**
 *
 * @param {object} rule
 * @returns {string}
 */
const getFilter = function (rule) {
    return rule.filter ? filterName(rule.filter) : 'All Calls'
}

/**
 *
 * @param {object} action
 * @returns {object}
 */
const getAction = function (action) {
    let actionType = ''
    let actionName = ''
    let name = ''
    let itemsLength = 0
    let actionId = null
    let type = null

    if (action.action === 'forward') {
        actionType = 'Forward call'

        if (action.items && action.items.length) {
            const item = action.items[0]
            itemsLength = action.items.length

            if (item && item.number) {
                actionName = ` ${formatPhoneNumber(item.number)}`
            } else if (item && item.extension) {
                if (item.extension.name) {
                    actionName += ` ${item.extension.name}`
                }
                if (item.extension.extension) {
                    actionName += `: Ext ${item.extension.extension}`
                }
                actionId = item.extension.id
                type = actionId ? 'extension' : null
            } else {
                actionName = ' (Unknown?)'
            }
        } else {
            actionName = ' (Unknown?)'
        }
        name = `Forward:${actionName}`
    } else if (action.action === 'live_answer') {
        actionType = 'Receptionist'
        actionName = (action.script && action.script.name) ? ` ${action.script.name}` : ''
        actionId = (action.script && action.script.id) ? action.script.id : null
        name = `Receptionist:${actionName}`
        type = actionId ? 'receptionist' : null
    } else if (action.action === 'voicemail') {
        actionType = 'Voicemail'

        if (action.extension) {
            if (action.extension.name) {
                actionName += ` ${action.extension.name}`
            }
            if (action.extension.extension) {
                actionName += ` | Ext ${action.extension.extension}`
            }
            actionId = action.extension.id
        }
        name = `Voicemail:${actionName}`
    } else if (action.action === 'greeting') {
        actionType = 'Greeting'
        actionName = (action.greeting && action.greeting.name) ? ` ${action.greeting.name}` : ''
        name = `Greeting:${actionName}`
    } else if (action.action === 'menu') {
        actionType = 'Menu'
        actionName = (action.menu && action.menu.name) ? ` ${action.menu.name}` : ''
        actionId = (action.menu && action.menu.id) ? action.menu.id : null
        name = `Menu:${actionName}`
        type = actionId ? 'menu' : null
    } else if (action.action === 'queue') {
        actionType = 'Queue'
        actionName = (action.queue && action.queue.name) ? ` ${action.queue.name}` : ''
        actionId = (action.queue && action.queue.id) ? action.queue.id : null
        name = `Queue:${actionName}`
        type = actionId ? 'queue' : null
    } else if (action.action === 'hold') {
        actionType = 'Hold Music'
        actionName = (action.hold_music && action.hold_music.name) ? ` ${action.hold_music.name}` : ' Default Ringtone'
        name = `Hold Music:${actionName}`
    } else if (action.action === 'fax') {
        actionType = 'Fax'
        if (action.extension) {
            if (action.extension.name) {
                actionName += ` ${action.extension.name}`
            }
            if (action.extension.extension) {
                actionName += ` (${action.extension.extension})`
            }
            actionId = action.extension.id
        }
        name = `Fax:${actionName}`
    } else if (action.action === 'disconnect') {
        actionType = 'Disconnect'
        actionName = 'Disconnect'
        name = 'Disconnect'
    } else if (action.action === 'trunk') {
        actionType = 'Trunk'
        if (action.trunk) {
            actionName = `${action.trunk.name}`
            actionId = action.trunk.id
            name = `Trunk ${actionName}`
            type = action.trunk.id ? 'trunk' : null
        }
    }

    return {
        name,
        actionType,
        actionName,
        actionId,
        itemsLength,
        type,
        attr: 'output'
    }
}

/**
 *
 * @param {object} action
 * @param {number} i
 * @returns {Array}
 */
const getForwardItems = function (action, i) {
    let id = i
    const actionType = 'Forward call'

    const items = action.items.map((item) => {
        let actionName = ''
        let type = null
        let actionId = null

        if (item && item.number) {
            actionName = formatPhoneNumber(item.number)
        } else if (item && item.extension && item.extension.extension) {
            actionName = `${item.extension.name ? ` ${item.extension.name}` : ''}: Ext ${item.extension.extension}`
            type = 'extension'
            actionId = item.extension.id
        } else {
            actionName = ' (Unknown?)'
        }
        return {
            attr: 'output',
            actionName,
            actionType,
            actionId,
            id: id++,
            type
        }
    })
    return items
}

/**
 *
 * @param {object} route
 * @returns {Array}
 */
const createPresetOutputs = function (route) {
    return [{
        name: `Preset: ${route.name}`,
        attr: 'output',
        id: 0,
        type: 'preset',
        actionId: route.id
    }]
}

/**
 *
 * @returns {Array}
 */
const createEmptyOutputs = function () {
    return [{
        attr: 'output', label: 'All Calls', name: 'All Calls'
    },
    {
        attr: 'output', actionType: 'Disconnect', actionName: 'Disconnect', label: 'Disconnect', name: 'Disconnect'
    }]
}

/**
 *
 * @param {object} response
 * @returns {Array}
 */
const createBlockOutputs = function (response) {
    const rules = response.route.rules.slice(0, response.route.rules.findIndex((r) => !r.filter) + 1)
    const fields = []
    let id = 0

    for (const rule of rules) {
        const filterName = getFilter(rule)

        fields.push({
            name: filterName,
            filter: true,
            attr: 'output',
            id: id++
        })
        for (const action of rule.actions) {
            const fullAction = getAction(action)

            if (fullAction.itemsLength > 1) {
                fields.push(...getForwardItems(action, id))
                id += fullAction.itemsLength
            } else {
                fields.push({ ...fullAction, id: id++ })
            }
        }
    }
    return fields
}

/**
 *
 * @param {object} response
 * @returns {Array}
 */
const getBlockOutputs = function (response) {
    let fields = []
    if (response.route && response.route.rules && response.route.rules.length) {
        fields = createBlockOutputs(response)
    } else if (response.route && response.route.id && response.route.name) {
        fields = createPresetOutputs(response.route)
    } else {
        fields = createEmptyOutputs()
    }
    return fields
}

/**
 *
 * @param {object} queue
 * @returns {Array}
 */
const getQueueOutputs = function (queue) {
    let index = 0
    const outputs = queue.members.map((member) => {
        let type = null
        let actionId = null
        let actionName = ''
        if (member.phone_number) {
            actionName = formatPhoneNumber(member.phone_number)
        } else if (member.extension) {
            type = 'extension'
            actionId = member.extension.id || null
            actionName = `${member.extension.name || ''}: Ext ${member.extension.extension || ''}`
        }
        return {
            name: actionName,
            actionName,
            id: index++,
            key: member.priority,
            attr: 'output',
            type,
            actionId
        }
    })
    return outputs
}

/**
 *
 * @param {object} script
 * @param {Array} extensions
 * @returns {Array}
 */
const getReceptionistOutputs = function (script) {
    let fields = []
    let index = 0
    const collapse = script.contacts.length > 10
    for (const contact of script.contacts) {
        fields.push({
            name: `Contact: ${contact.first_name ? contact.first_name : ''} ${contact.last_name ? contact.last_name : ''}`,
            filter: true,
            attr: 'output',
            index
        })

        index++
        const startIndex = index
        let showRow = false

        for (const destination of contact.destinations) {
            let type = ''
            let destinationName = ''
            let extension = null

            if (destination.type === 'phone_number') {
                type = 'Number'
                destinationName = formatPhoneNumber(destination.destination)
            } else if (destination.type === 'extension') {
                type = 'Ext/User'
                extension = destination.id
                if (collapse && extension) showRow = true
            } else if (destination.type === 'voicemail') {
                type = 'Voicemail'
                extension = destination.id
            } else if (destination.type === 'email') {
                type = 'Email'
                destinationName = destination.destination
            }

            fields.push({
                name: destinationName,
                actionType: type,
                actionName: destinationName,
                id: index++,
                attr: 'output',
                type: (destination.type === 'extension' && extension) ? destination.type : null,
                actionId: extension ? parseInt(destination.destination) : null
            })
        }
        if (collapse && !showRow) {
            for (let i = startIndex; i < fields.length; i++) {
                fields[i].collapse = true
            }
        }
    }
    fields = fields.filter((f) => !f.collapse)
    if (collapse) {
        index = 0
        for (const field of fields) {
            field.id = index
            index++
        }
    }
    return fields
}

/**
 *
 * @param {object} menu
 * @param {number} callFlowBlockCounter
 * @returns {object}
 */
const getMenuOutputs = function (menu, callFlowBlockCounter) {
    let index = 0
    const fields = []
    const menuCallFlows = []
    let callFlowCounter = callFlowBlockCounter

    if (menu && (menu.options.length || menu.timeout_handler)) {
        const options = menu.options.filter((o) => parseInt(o.key) > 0 && parseInt(o.key) <= 9)

        if (menu.options.find((o) => parseInt(o.key) === 0)) {
            options.push(menu.options.find((o) => parseInt(o.key) === 0))
        }
        if (menu.timeout_handler) {
            options.push({
                route: menu.timeout_handler,
                key: '-',
                actionName: 'Timeout Handler'
            })
        }
        if (menu.options.find((o) => o.key === '#')) {
            options.push(menu.options.find((o) => o.key === '#'))
        }

        for (const option of options) {
            let flagFields = []
            const outputs = getBlockOutputs({ route: option.route })
            if (outputs.length > 2 || (option.route.name && option.route.id)) {
                flagFields = outputs
            } else {
                flagFields = outputs.filter((field) => !field.filter)
            }

            let name = ''
            if (option.route.name && option.route.id) {
                name = `Preset: ${option.route.name}`
            } else if (flagFields.length > 1) {
                name = `Call Flow ${callFlowCounter}`
                menuCallFlows.push({ name, fields: flagFields })
                callFlowCounter++
            } else {
                name = flagFields[0].actionName || ''
            }

            fields.push({
                key: option.key,
                actionName: name,
                actionType: flagFields[0].actionType,
                name,
                actionId: flagFields[0].actionId,
                attr: 'output',
                id: index++,
                type: name.toLowerCase().includes('call flow') ? 'call flow' : flagFields[0].type
            })
        }
    }
    return {
        counter: callFlowCounter,
        menuCallFlows,
        fields
    }
}

/**
 *
 * @param {string} title
 * @returns {Array}
 */
const getDeviceOutputs = function (title) {
    const fields = [{
        attr: 'output',
        actionName: title,
        label: '',
        name: ''
    }]
    return fields
}

/**
 *
 * @param {Event} event
 * @returns {object}
 */
const getMousePosition = function (event) {
    if (event) {
        if (['touchstart', 'touchmove', 'touchend', 'touchcancel'].includes(event.type)) {
            const touch = event.changedTouches[0]
            if (touch) {
                return { x: touch.pageX, y: touch.pageY }
            }
        } else if (event.pageX !== undefined && event.pageY !== undefined) {
            return { x: event.pageX, y: event.pageY }
        }
    }
    return { x: 0, y: 0 }
}

export default {
    getBlockOutputs,
    getReceptionistOutputs,
    getQueueOutputs,
    getDeviceOutputs,
    getMenuOutputs,
    getMousePosition
}
