import React, { Component } from 'react'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Spinner from 'spinner'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	messagesGapSection: {
		width:			'100%',
		display:		'flex',
		flexDirection:	'column'
	},
	loadMore: {
		padding:		5,
		display:		'flex',
		flexDirection:	'column',
		cursor:			'pointer',
		opacity:		0.7,
		textAlign:		'center',
		'&:hover': {
			opacity:	1
		}
	},
	loadIcon: {
		'& > svg': {fontSize: 50}
	},
	separator: {
		margin:		'10px 0',
		border:		'none',
		borderTop:	'1px solid gray'
	},
	spinnerWrapper: {
		display:		'flex',
		justifyContent:	'center',
		width:			'100%'
	}
})

class MessagesGapSection extends Component {
	render() {
		const { classes } = this.props
		let isLoading = this.props.loadGapMessages === 'loading'
		return (
			isLoading ? <div className={classes.spinnerWrapper}><Spinner/></div> :
			<div className={classes.messagesGapSection}>
				<div className={classes.loadMore} onClick={() => this.props.loadGapMessages('next')}>
					<span>Load Next</span>
					<span className={classes.loadIcon}><ExpandMoreIcon/></span>
				</div>
				<hr className={classes.separator}/>
				<div className={classes.loadMore} onClick={() => this.props.loadGapMessages('previous')}>
					<span className={classes.loadIcon}><ExpandLessIcon/></span>
					<span>Load previous</span>
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(MessagesGapSection)