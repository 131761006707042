import { Styles } from '@material-ui/core/styles/withStyles'
type StylesType = Styles<any, Record<string, unknown>>

/**
 * @param {object} theme
 */
const styles: StylesType = theme => ({
    addCallOptions: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '20px',
        '&.dialpad': {
            marginLeft: '60px'
        }
    },
    addCallButton: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    addButton: {
        height: '46px',
        width: '46px',
        backgroundColor: theme.palette.primary.dark,
        color: 'white',
        margin: '10px 30px'
    }
})

export default styles
