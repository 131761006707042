import { Styles } from '@material-ui/core/styles/withStyles'
type StylesType = Styles<any, Record<string, unknown>>

/**
 * @param {object} theme
 */
const styles: StylesType = theme => ({
    fromNumberSection: {
        height: 'fit-content',
        backgroundColor: 'white',
        maxWidth: '300px',
        position: 'relative',
        '&.expanded': {
            '& > .head-button': {
                '& > .clickable-area > svg': {
                    transform: 'rotate(180deg)'
                }
            }
        },
        '& > .head-button': {
            height: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            '& > .clickable-area': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                columnGap: 2,
                cursor: 'pointer',
                color: theme.palette.text.secondary,
                '& > svg': {
                    fontSize: 18,
                    color: theme.palette.text.primary
                }
            }
        },
        '& > .menu-wrapper': {
            position: 'absolute',
            top: '100%',
            width: 250,
            left: '50%',
            transform: 'translateX(-50%)',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
            maxHeight: 266,
            borderRadius: 6,
            overflowY: 'auto',
            backgroundColor: 'white',
            zIndex: 1,
            '& > .menu-item': {
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '12px 25px 12px 25px',
                borderBottom: `1px solid ${theme.palette.secondary.light}`,
                color: theme.palette.text.primary,
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: theme.palette.other.green
                },
                '& > svg': {
                    fontSize: 20
                },
                '& > .number-info': {
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 2
                }
            }
        }
    }
})

export default styles
