import { OrangeColor } from 'orange-colors'

/**
 *
 * @param {object} component
 * @returns
 */
const primary_color = (component) => {
    if (!component.$vuetify.theme.defaults.light.primary) return 'e5176e'
    if (typeof component.$vuetify.theme.defaults.light.primary === 'string') return component.$vuetify.theme.defaults.light.primary
    return component.$vuetify.theme.defaults.light.primary.base
}

/**
 *
 * @param {object} component
 * @returns
 */
const distinct_colors = (component) => {
    const c = new OrangeColor(primary_color(component))
    const incoming = c.html_string
    c.h = (c.h + 120) % 360
    const outgoing = c.html_string

    return [incoming, outgoing]
}

/**
 *
 */
export {
    primary_color,
    distinct_colors
}
