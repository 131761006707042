const styles = theme => ({
	toStyle: {
		fontSize:		'14px',
		color:			'#000000',
		fontWeight:		500,
		marginRight:	10
	},
	tagsInput:{
		width:		'100%',
		display:	'flex',
		flexWrap:	'wrap',
		position:	'relative',
		alignItems:	'center',
		cursor:		'text',
		'&.no-input': {
			cursor: 'default'
		}
	},
	dialpad: {
		marginRight:	5,
		opacity:		0.7,
		cursor:			'default',
		'&:not(.not-allowed)': {
			cursor:		'pointer',
			'&:hover': {
				opacity: 1
			}
		}
	},
	dialpadIcon: {
		color:	theme.palette.primary.main
	},
	removeContactButton: {
		width:		'27px',
		height:		'27px',
		marginLeft:	'10px',
		cursor:		'pointer'
	},
	tagsInputField: {
		minWidth: 		200,
		fontSize:		'16px',
		fontWeight:		500,
		background:		'white',
		border:			'none',
		outline:		'none',
		letterSpacing:	'-0.1px',
		color:			'#88969e',
		'&.text-error': {
			color:		'#df1d08',
			fontWeight:	'bold'
		},
		'&::placeholder': {
			fontWeight:	500,
			color:		'#88969e'
		},
		'&.not-allowed': {
			color:		'#ccd6db',
			cursor:		'not-allowed',
			'&::placeholder': {
				color:	'#ccd6db'
			}
		}
	},
	tagItem: {
		display:		'flex',
		alignItems:		'center',
		background:		'#f2f5f6',
		border:			'1px solid #b6c0c5',
		borderRadius:	'20px',
		boxShadow:		'-1.5px 1.5px 0 0 #b6c0c5',
		margin:			'3px 7px 3px 0px',
		padding:		'6px 6px 6px 15px',
		'& .main-text': {
			fontSize:		'12px',
			fontWeight:		500,
			lineHeight:		1.33,
			letterSpacing:	'-0.1px',
			color:			'#2b3a43'
		},
		'&.only-main .main-text': {
			fontSize:	'14px',
			lineHeight:	1.29,
			color:		'#364047'
		}
	},
	content: {
		display:		'flex',
		flexDirection:	'column'
	},
	additionalText: {
		fontSize:		'10px',
		fontWeight:		500,
		lineHeight:		1.2,
		letterSpacing:	'-0.1px',
		color:			'#88969e'
	},
	pdcLoader: {
		position:	'absolute',
		right:		'10px',
		top:		'3px',
		width:		'35px !important',
		height:		'30px !important'
	}
})

export default styles