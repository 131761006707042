import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * @param {object} props - svg props
 */
export const StorefrontIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2010_9448)">
                <path d="M21.9836 8.83239L20.9245 4.38146C20.7026 3.46479 19.9158 2.83331 18.9979 2.83331H16.7688H14.7414H13.0065H10.9892H9.25429H7.2168H4.98767C4.07988 2.83331 3.28304 3.47498 3.07122 4.38146L2.01213 8.83239C1.77005 9.87128 1.99195 10.9305 2.63749 11.7657C2.71819 11.8778 2.82914 11.9592 2.91992 12.0611V19.1296C2.91992 20.25 3.82771 21.1666 4.93723 21.1666H19.0584C20.168 21.1666 21.0758 20.25 21.0758 19.1296V12.0611C21.1665 11.9694 21.2775 11.8778 21.3582 11.7759C22.0037 10.9407 22.2357 9.87128 21.9836 8.83239ZM6.97472 4.87035L6.3897 9.82035C6.30901 10.4824 5.78451 10.9815 5.16923 10.9815C4.67498 10.9815 4.3623 10.6861 4.23117 10.5028C3.96892 10.1666 3.87814 9.72868 3.97901 9.31109L4.98767 4.87035H6.97472ZM18.9677 4.86016L20.0268 9.31109C20.1276 9.73887 20.0368 10.1666 19.7746 10.5028C19.6334 10.6861 19.3308 10.9815 18.8265 10.9815C18.2112 10.9815 17.6766 10.4824 17.606 9.82035L17.021 4.87035L18.9677 4.86016ZM15.5382 9.47405C15.5887 9.87128 15.4676 10.2685 15.2054 10.5639C14.9734 10.8287 14.6506 10.9815 14.2371 10.9815C13.5613 10.9815 13.0065 10.3805 13.0065 9.6472V4.87035H14.9835L15.5382 9.47405ZM10.9892 9.6472C10.9892 10.3805 10.4344 10.9815 9.68801 10.9815C9.34507 10.9815 9.03239 10.8287 8.79031 10.5639C8.53814 10.2685 8.4171 9.87128 8.45745 9.47405L9.01221 4.87035H10.9892V9.6472ZM18.0498 19.1296H5.94589C5.39113 19.1296 4.93723 18.6713 4.93723 18.1111V12.9879C5.01793 12.9981 5.08853 13.0185 5.16923 13.0185C6.04676 13.0185 6.8436 12.6518 7.42862 12.0509C8.03381 12.662 8.84074 13.0185 9.75862 13.0185C10.6362 13.0185 11.4229 12.6518 12.0079 12.0713C12.603 12.6518 13.41 13.0185 14.3178 13.0185C15.165 13.0185 15.972 12.662 16.5771 12.0509C17.1622 12.6518 17.959 13.0185 18.8365 13.0185C18.9172 13.0185 18.9878 12.9981 19.0685 12.9879V18.1111C19.0584 18.6713 18.6045 19.1296 18.0498 19.1296Z" fill={color} />
                <path d="M12 14.2344C8.7946 14.2344 8.67932 19.1824 8.67932 19.1824H15.2053C15.2053 19.1824 15.2053 14.2344 12 14.2344Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_2010_9448">
                    <rect width="22" height="22" fill="white" transform="translate(1 1)"/>
                </clipPath>
            </defs>
        </SvgIcon>
    )
}

export default StorefrontIcon
