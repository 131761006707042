<template>
    <v-simple-table data-test-id="botttom-table">
        <thead data-test-id="table-head">
        <tr data-test-id="head-row">
            <th data-test-id="number">{{ $lang.t('app.number', 'Number') }}</th>
            <th v-for="(prop, i) in Object.keys(items)" :data-test-id="prop" :key="`stats-th-${prop}`">
                {{ fields[prop].translation }}
            </th>
        </tr>
        </thead>
        <tbody data-test-id="table-body">
        <tr
            v-for="(number, i) in numbers"
            :data-test-id="i"
            :key="`stats-tr-${i}`"
        >
            <td
                :class="{disabled: !number}"
                data-test-id="number"
            >
                {{ number || '&mdash;' }}
            </td>
            <td v-for="prop in Object.keys(items)" :data-test-id="prop" :key="`stats-td-${prop}`">
                <div>
                    {{
                        Math.round(
                            items[prop].find((x) => x.number === number).value
                        )
                    }}
                </div>
            </td>
        </tr>
        </tbody>
    </v-simple-table>
</template>

<script>
export default {
    props: {
        items: {},
        numbers:{},
        logarithmic:{},
        formatters:{},
        fields:{},
    },
    data() {
      return {
      };
    },
}
</script>