<template>
	<w-loader v-if="log.filters_loading" :message="false" size="small"/>
	<div v-else class="smart-filters mb-3" data-test-id="smart-filters">
		<FormInput
			v-if="log.client_config && log.client_config[log.config_key] && log.client_config[log.config_key].length"
			:label="l.t('call-logs.saved-filters', 'Saved filters')"
			:label_cols="label_cols"
		>
			<div class="d-flex">
				<w-select
					v-model="log.saved_filter_name"
					@change="log.apply_saved_filters()"
					:items="[{text: l.t('app.select', 'Select'), value: null}].concat(log.client_config[log.config_key].map((x) => x.name))"
					hide-details="auto"
					data-test-id="smart-filters-saved-filters-select"
				/>
				<w-btn v-if="log.saved_filter_name" @click="confirmation_modal = true" color="error" class="my-auto ml-3" icon>
					<v-icon>$vuetify.icons.trash</v-icon>
				</w-btn>
			</div>
			<DefaultModal
				v-model="confirmation_modal"
				@close="confirmation_modal = false"
				max-width="500"
				data-test-id="smart-filters-delete-confirmation-modal"
				>
				<template v-slot:title>
					{{l.t('app.are-you-sure', 'Are you sure')}}?
				</template>
				{{l.t('app.are-you-sure-delete-filter','Are you sure you want to remove this filter? You will not be able to access it after removing.')}}
				<template v-slot:buttons>
					<w-btn color="secondary" @click="confirmation_modal = false">{{l.t('app.cancel', 'Cancel')}}</w-btn>
					<w-btn color="primary" @click="confirmed">{{l.t('app.confirm', 'Confirm')}}</w-btn>
				</template>
			</DefaultModal>
		</FormInput>
		<FormInput
			v-if="!log.saved_filter_name"
			:label="l.t('app.period', 'Period')"
			:label_cols="label_cols"
		>
			<w-select
				v-model="log.filters.type"
				@change="log.apply_type_range()"
				:items="log.quick_filters.map(x => {
					return {
						...x,
						text: x.translation,
					}
				})"
				hide-details="auto"
				data-test-id="smart-filters-period-select"
			/>
		</FormInput>
		<FormInput
			:label="l.t('app.time-range', 'Time range')"
			:label_cols="label_cols"
			class="large-filter-field"
		>
			<div class="d-flex">
				<DatetimePicker
					:datetime="log.filters.start"
					@input="datetime_changed('start', $event)"
					@change="handle_period_change()"
					:key="log.filters.start"
					date_nudge_top="-195"
					time_nudge_top="-196"
					default_time="08:00"
					data-test-id="smart-filters-start-input"
				/>
				<span class="px-2 my-auto">&mdash;</span>
				<DatetimePicker
					:datetime="log.filters.end"
					@input="datetime_changed('end', $event)"
					@change="handle_period_change()"
					:key="log.filters.end"
					date_nudge_top="-195"
					time_nudge_top="-196"
					default_time="17:00"
					data-test-id="smart-filters-end-input"
				/>
			</div>
		</FormInput>
	</div>
</template>

<script>
	import l from '../../libs/lang';
	import FormInput from './form/FormInput.vue';
	import DefaultModal from './modal/DefaultModal.vue';
	import DatetimePicker from './form/DatetimePicker.vue';

	export default {
		props: {
			log: {
				type: Object,
				required: true,
			},
			label_cols: {
				type: Number,
				requried: false,
			}
		},
		components: {
			FormInput,
			DefaultModal,
			DatetimePicker,
		},
		data() {
			return {
				l,
				confirmation_modal: false,
			};
		},
		methods: {
			handle_period_change() {
                this.$props.log.restartSavedFilterName();
                if (this.$props.log.filters.type !== 'custom') this.$props.log.filters.type = 'custom';
            },
			clear_selector() {
				this.$props.log.saved_filter_name = null;
				this.$props.log.apply_saved_filters();
			},
			confirmed() {
				this.$data.confirmation_modal = false;
				this.$props.log.delete_filter();
			},
			datetime_changed(prop, data) {
				this.$props.log.filters[prop] = data;
			}
		}
	};
</script>
