/**
 *
 */
export const appIconPlaceholder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAAXNSR0IB2cksfwAAAAlwSFlzAAAPYQAAD2EBqD+naQAAAIpQTFRFyvLCAAAAyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCyvLCWg5ysAAAAC50Uk5T/wAVChsF2eT26MdL4cv4k6ehYJCvIoPQiJhdpEBwnxHeGfF4MPJkhOw4gJ1o6+52L5AAAAExSURBVHic7drJdoJAFITh6qYRVAxTBE3inHl4/9eLwCFBJNnQ1av7r1jxrTgsbkFdZLL7ACML0sxcvhWd52U8GQu0TXIziHgrW0Jd+OJdI/PIqnEumvcQndomqlLdRfSCYQAL/Yt4M44BzPwf5JZlANsW+eQZQNYg+omJHHWNrJkGsK6QQ8hFQnNGcq4BrBT8Vzay9/HANoA3vPORDYgfYlsCa7+Qvysx5SNT3PARB4QkSZIkSZIkSZIkSZIkSZIkSf/m5CDg5LRR8pHSzbnpxEc2Lk6Aj/D3bCPyoQo2Uigowz4wH6pTecxF8ubof2QaX83RX2VM5K4dYmx5xofbSYnascYxu+7Mx0sYRuL1B0ujd139gv5gyf70CsXA9MryiCxeDo7I6szz+DlcdDWH+wa6MgmB48wCAwAAAABJRU5ErkJggg=='

/**
 *
 */
export const appsGridIcon = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTciIGhlaWdodD0iNTciIHZpZXdCb3g9IjAgMCA1NyA1NyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iNDEuNDM3NSIgeT0iNDEuNDM3NSIgd2lkdGg9IjE1LjEyNSIgaGVpZ2h0PSIxNS4xMjUiIHJ4PSIyLjUiIGZpbGw9IndoaXRlIi8+CjxyZWN0IHg9IjIwLjgxMjUiIHk9IjQxLjQzNzUiIHdpZHRoPSIxNS4xMjUiIGhlaWdodD0iMTUuMTI1IiByeD0iMi41IiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCB4PSIwLjE4NzUiIHk9IjQxLjQzNzUiIHdpZHRoPSIxNS4xMjUiIGhlaWdodD0iMTUuMTI1IiByeD0iMi41IiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCB4PSI0MS40Mzc1IiB5PSIyMC44MTI1IiB3aWR0aD0iMTUuMTI1IiBoZWlnaHQ9IjE1LjEyNSIgcng9IjIuNSIgZmlsbD0id2hpdGUiLz4KPHJlY3QgeD0iMjAuODEyNSIgeT0iMjAuODEyNSIgd2lkdGg9IjE1LjEyNSIgaGVpZ2h0PSIxNS4xMjUiIHJ4PSIyLjUiIGZpbGw9IndoaXRlIi8+CjxyZWN0IHg9IjAuMTg3NSIgeT0iMjAuODEyNSIgd2lkdGg9IjE1LjEyNSIgaGVpZ2h0PSIxNS4xMjUiIHJ4PSIyLjUiIGZpbGw9IndoaXRlIi8+CjxyZWN0IHg9IjQxLjQzNzUiIHk9IjAuMTg3NSIgd2lkdGg9IjE1LjEyNSIgaGVpZ2h0PSIxNS4xMjUiIHJ4PSIyLjUiIGZpbGw9IndoaXRlIi8+CjxyZWN0IHg9IjIwLjgxMjUiIHk9IjAuMTg3NSIgd2lkdGg9IjE1LjEyNSIgaGVpZ2h0PSIxNS4xMjUiIHJ4PSIyLjUiIGZpbGw9IndoaXRlIi8+CjxyZWN0IHg9IjAuMTg3NSIgeT0iMC4xODc1IiB3aWR0aD0iMTUuMTI1IiBoZWlnaHQ9IjE1LjEyNSIgcng9IjIuNSIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg=='

/**
 *
 */
export const arrowIcon = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMyAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuODYwMDI3IDQuNzcwNDdDMC40NTY5NDUgNC43NzA0NyAwLjEzMDE4MiA1LjA5NzIzIDAuMTMwMTgyIDUuNTAwMzFDMC4xMzAxODIgNS45MDMzOSAwLjQ1Njk0NSA2LjIzMDE2IDAuODYwMDI3IDYuMjMwMTZMOS43OTkzNCA2LjIzMDE2TDYuOTA1ODQgOS4xMjM2NkM2LjYxOTk2IDkuNDA5NTMgNi42MTk5NiA5Ljg3MzAyIDYuOTA1ODQgMTAuMTU4OUM3LjE5MTcxIDEwLjQ0NDggNy42NTUyIDEwLjQ0NDggNy45NDEwOCAxMC4xNTg5TDExLjg5MjYgNi4yMDc0MkMxMi4yODMxIDUuODE2ODkgMTIuMjgzMSA1LjE4MzczIDExLjg5MjYgNC43OTMyMUw3Ljk0MTA4IDAuODQxNzI2QzcuNjU1MiAwLjU1NTg1MiA3LjE5MTcxIDAuNTU1ODUzIDYuOTA1ODQgMC44NDE3MjdDNi42MTk5NiAxLjEyNzYgNi42MTk5NiAxLjU5MTA5IDYuOTA1ODQgMS44NzY5N0w5Ljc5OTM0IDQuNzcwNDdMMC44NjAwMjcgNC43NzA0N1oiIGZpbGw9IiMzMDk5MUMiLz4KPC9zdmc+Cg=='
