var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"phone-numbers"},[(!_vm.$route.name.includes('visualizer'))?_c('Submenu',{key:("" + (_vm.l.detect()) + (_vm.$route.name)),attrs:{"routes":{
      'phone-numbers.index': {
        title: _vm.l.t('app.phone-numbers', 'Phone numbers')
      },
      'phone-numbers.visualizer-default': {
        title: _vm.l.t('phone-numbers.visualizer-default', 'Visualizer')
      },
      'phone-numbers.create': {
        title: _vm.l.t('app.phone-add', 'Add phone number')
      },
      'phone-numbers.delete-csv': {
        title: _vm.l.t('phone-numbers.delete-phone-numbers', 'Delete phone numbers')
      },
    }}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'phone-numbers.index')?_c('PhoneNumbersFragment',{ref:"indexPage",attrs:{"data-test-id":"phone-numbers-index"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'phone-numbers.create')?_c('PhoneNumbersCreateFragment',{attrs:{"data-test-id":"phone-numbers-create"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'phone-numbers.delete-csv')?_c('PhoneNumbersDeleteCsvFragment',{attrs:{"data-test-id":"phone-numbers-delete-csv"}}):_vm._e(),_vm._v(" "),(_vm.$route.name.includes('visualizer'))?_c('Visualizer',{attrs:{"data-test-id":"phone-numbers-visualizer"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }