import React, { useState, useEffect } from 'react'
import { IEnrollmentEvent, IEnrollmentEventCollection, EnrollmentEventsContext } from '../contexts/EnrollmentEventsContext'
import InfiniteLoader from 'react-window-infinite-loader'
import { FixedSizeList } from 'react-window'
import { Grid } from '@material-ui/core'
import Typography from 'typography'
import Spinner from 'spinner'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    eventsViewerParent: {
        overflowX: 'auto',
        position: 'relative'
    },
    eventsViewerHeader: {
        width: '100%',
        borderBottom: '2px solid #00000044',
        "& [class*='MuiTypography-root']": {
            wordWrap: 'break-word'
        },
        [theme.breakpoints.down('xs')]: {
            width: '700px'
        }
    },
    loadingSpinner: {
        position: 'absolute',
        alignItems: 'center',
        paddingTop: '10px',
        marginLeft: '50%'
    },
    noEventsMessage: {
        alignItems: 'center',
        paddingTop: '20px'
    },
    eventsViewerContainer: {
        width: '100%',
        overflowX: 'scroll'
    }
}))
/**
 *
 */
const EnrollmentEventsViewer = (props: { entityType: 'brand' | 'campaign', entityId: string, viewHeight: number, breakPoints: {belowXS: boolean, belowSM: boolean} }): JSX.Element => {
    const classess = useStyles()
    const eventsContext = React.useContext(EnrollmentEventsContext)
    const [eventList, setEventList] = useState([] as IEnrollmentEvent[])
    const [hasMore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(false)

    const emptyList = eventList.length === 0
    const list = async () => {
        setLoading(true)
        const res = (await eventsContext.list(props.entityType, props.entityId)) as IEnrollmentEventCollection
        eventList.push(...res.events)
        setEventList(eventList)
        setHasMore(res.hasMore)
        setLoading(false)
        console.log('list events res', eventList, res)
    }
    const EventsViewerHeader = () => {
        return (<Grid container wrap='nowrap' spacing={1} className={classess.eventsViewerHeader}>
            <Grid item xs={2}><Typography variant={'h5'}>Date</Typography></Grid>
            <Grid item xs={3}><Typography variant={'h5'}>Event</Typography></Grid>
            <Grid item xs={7}><Typography variant={'h5'}>Description</Typography></Grid>
        </Grid>)
    }
    const EventsViewerItem = (props: { data: IEnrollmentEvent, index: number, style }) => {
        if (props.index > eventList.length) return (<div style={props.style}>Loading...</div>)
        const event = eventList[props.index]
        const date = new Date(parseInt(event.createdAt) * 1000)
        const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
        const style = {
            ...props.style,
            alignItems: 'center',
            borderBottom: '1px solid #00000022'
        }
        return (<Grid container wrap='nowrap' style={style} spacing={1}>
            <Grid item xs={2}><Typography variant={'subtitle2'}>{formattedDate}</Typography></Grid>
            <Grid item xs={3}><Typography variant={'subtitle2'}>{event.eventName}</Typography></Grid>
            <Grid item xs={7}><Typography variant={'subtitle2'}>{event.description}</Typography></Grid>
        </Grid>)
    }
    const LoadingSpinner = (props: { hidden: boolean }) => {
        return (<div hidden={props.hidden} className={classess.loadingSpinner}><Spinner size={'medium'} /></div>)
    }
    const NoEventsMessage = (props: { hidden: boolean }) => {
        return (<div hidden={props.hidden} className={classess.noEventsMessage}>
            <Typography variant='subtitle2'>No events found.</Typography>
        </div>)
    }
    // load initial data on mount
    useEffect(() => {
        list()
    }, [])
    return (
        <div className={classess.eventsViewerParent}>
            <div className={classess.eventsViewerHeader}>
                <EventsViewerHeader />
                {<LoadingSpinner hidden={!(loading && emptyList)}/>}
                {<NoEventsMessage hidden={loading || !emptyList} />}
                <InfiniteLoader
                    isItemLoaded={(index) => index < eventList.length}
                    itemCount={hasMore ? eventList.length + 1 : eventList.length}
                    loadMoreItems={list}
                >
                    {({ onItemsRendered, ref }) => (
                        <FixedSizeList
                            height={props.viewHeight}
                            itemCount={eventList.length}
                            itemSize={props.breakPoints.belowXS ? 150 : (props.breakPoints.belowSM ? 120 : 60)}
                            onItemsRendered={onItemsRendered}
                            ref={ref}
                        >
                            {EventsViewerItem}
                        </FixedSizeList>
                    )}
                </InfiniteLoader>
                {<LoadingSpinner hidden={(!loading || emptyList)}/>}
            </div>
        </div>
    )
}

/**
 *
 */
export { EnrollmentEventsViewer }
