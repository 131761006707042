import React, { useContext, useEffect, useState } from 'react'
import InCallView from './Views/InCall/InCallView'
import IncomingCallView from './Views/IncomingCall/IncomingCallView'
import PlaceCallView from './Views/PlaceCall/PlaceCallView'
import AddCallView from './Views/AddCall/AddCallView'
import TransferCallView from './Views/TransferCall/TransferCallView'

import { CallState, SipCallSession, PdcCallContext } from 'pdc-calls/src'

import Typography from 'typography'
import { PauseIcon, PhoneRingingIcon } from 'svg-icons/src'
import { useToggle } from 'hooks/src'
import { SoftphoneContext } from '../Softphone'
/***/
export interface ISoftphoneCallsContext {
    OpenCallAction: (a: CallAction) => void
    CloseCallAction: () => void
}
/**
 *
 */
export const SoftphoneCallsContext = React.createContext<ISoftphoneCallsContext>({
    OpenCallAction: (a: CallAction) => console.log('open'),
    CloseCallAction: () => console.log('close')
})
/***/
export enum CallAction{
    AddCall = 'add',
    TransferCall = 'transfer',
    PlaceCall = 'place',
    None = 'none'
}

interface SessionProps{
    session: SipCallSession
}
/**
 * REWRITE in inCall file and use call context.
 */
export function InCallTitle (props: SessionProps) {
    if (props.session === null || props.session.callState !== CallState.ACTIVE) return (<></>)
    if (props.session.participants.length > 1) { return (<Typography>Conference</Typography>) }
    if (props.session.isOnHold) {
        return (<span><PauseIcon/>
            <Typography variant={'subtitle3'}>On Hold</Typography></span>)
    }
    return (<span><PhoneRingingIcon/>
        <Typography variant={'subtitle3'}>Connected</Typography></span>)
}

interface CallViewProps{
    action: CallAction
}
function CallView (props: CallViewProps): JSX.Element {
    const action = props.action
    switch (action) {
            case CallAction.None:
                return <InCallView/>
            case CallAction.PlaceCall:
                return <PlaceCallView/>
            case CallAction.AddCall:
                return <AddCallView/>
            case CallAction.TransferCall:
                return <TransferCallView/>
            default:
                return (<PlaceCallView/>)
    }
}
/***/
const SoftphoneCalls = (): JSX.Element => {
    const [action, setCallAction] = useState(CallAction.PlaceCall)
    const Softphone: any = useContext(SoftphoneContext)
    const pdcCallContext: any = useContext(PdcCallContext)
    const [callIncoming, setCallIncoming] = useToggle(pdcCallContext.incomingCallsCnt > 0)
    const OpenCallAction = (a: CallAction) => { setCallAction(a) }
    const CloseCallAction = () => setCallAction(CallAction.None)
    console.log('softphone calls: ', action)
    useEffect(() => {
        if (pdcCallContext.incomingCallsCnt > 0) {
            setCallIncoming(true)
            Softphone.ShowSoftphone()
        } else setCallIncoming(false)
    }, [pdcCallContext.incomingCallsCnt])
    const NoActiveCall = () => (pdcCallContext.activeCallId === null ||
        !pdcCallContext.calls[pdcCallContext.activeCallId])
    const onCallsChange = () => {
        if (action === CallAction.PlaceCall && pdcCallContext.callsCnt > 0) CloseCallAction()
        else if (pdcCallContext.calls.length === 0 || (!callIncoming && NoActiveCall())) setCallAction(CallAction.PlaceCall)
    }
    useEffect(onCallsChange, [pdcCallContext.activeCallId, pdcCallContext.calls?.length])
    return (<SoftphoneCallsContext.Provider
        value={{
            OpenCallAction,
            CloseCallAction
        }}>
        <div>
            <IncomingCallView hidden={!callIncoming}/>
            <CallView action={action}/>
        </div>
    </SoftphoneCallsContext.Provider>)
}

export default SoftphoneCalls
