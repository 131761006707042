export function currentExtension(state = null, action) {
	switch (action.type) {
		case 'SWITCH_EXTENSION':
			return JSON.parse(JSON.stringify(action.extension))
		default:
			return state
	}
}

export function isVoicemailConfigured(state = null, action) {
	switch (action.type) {
		case 'SET_IS_VOICEMAIL_CONFIGURED':
			return action.isVoicemailConfigured
		default:
			return state
	}
}