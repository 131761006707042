<template>
  <div>
    <w-radio-group
      v-model="forwarding_type"
      row
      hide-details='auto'
      data-test-id="fwd-item-xt-radio-select"
    >
      <v-radio
        value="phone_number"
        data-test-id="fwd-item-xt-radio-select-phone"
      >
        <template v-slot:label>
          <span class="text--text">{{ l.t('app.phone-number', 'Phone number') }}</span>
        </template>
      </v-radio>
      <v-radio
        value="extension"
        data-test-id="fwd-item-xt-radio-select-extension"
      >
        <template v-slot:label>
          <span class="text--text">{{ l.t('app.extension', 'Extension') }}</span>
        </template>
      </v-radio>
    </w-radio-group>
    <div class="d-flex">
      <Tel
          v-if="forwarding_type === 'phone_number'"
          :value="phone_number"
          :validation="true"
          :additional_options="additional_options"
          :required="required"
          :closable="closable"
          :readonly="readonly"
          @changed="phone_number = $event.clean_number"
          @closed="$emit('closed')"
          class="w-100"
          data-test-id="fwd-item-tel-input"
      />
      <APIAutocomplete
          v-else-if="forwarding_type === 'extension'"
          :value="extension"
          @input="onExtensionInput"
          :return_object="true"
          mode="extensions"
          key="extensions"
          :rules="rules"
          :options_list="closable ? ['remove'] : []"
          :readonly="readonly"
          :before_list="ring_my_device_selector_option"
          :filtered_values="usedExtensions"
          @click:remove="$emit('closed')"
          :additional_options="additional_options"
          data-test-id="fwd-item-extension-selector"
          class="w-100"
      />
      <ForwardingItemMoreOptionsModal
        v-model="show_more_options_modal"
        :data="value"
        :forwarding_type="forwarding_type"
        :readonly="readonly"
        @submitted="more_options_submitted"
        data-test-id="more-options-modal"
        :key="`more-options-${show_more_options_modal}`"
      />
      <DeviceInformationModal
          v-if="device_extension && device_extension.device_membership && show_device_info_modal"
          :value="true"
          :id="device_extension.device_membership.device.id"
          :all_info="true"
          @close="show_device_info_modal = null"
          :width="500"
          data-test-id="device-information-modal"
        />
    </div>
  </div>
</template>

<script>
  import l from '../../../libs/lang';
  import Tel from '../Tel.vue';
  import APIAutocomplete from '../form/APIAutocomplete.vue';
  import FormInput from '../form/FormInput.vue';
  import DeviceInformationModal from '../modal/device-information.vue';
  import ForwardingItemMoreOptionsModal from './ForwardingItemMoreOptionsModal.vue';

  export default {
    props: ['value', 'required', 'closable', 'device_extension', 'usedExtensions', 'readonly'],
    components: {
      Tel,
      FormInput,
      APIAutocomplete,
      DeviceInformationModal,
      ForwardingItemMoreOptionsModal,
    },
    data() {
      const value = this.$props.value ? JSON.parse(JSON.stringify(this.$props.value)) : null;
      return {
        l,
        forwarding_type: value && Object.prototype.hasOwnProperty.call(value, 'extension') ? 'extension' : 'phone_number',
        phone_number: value && value.number ? value.number : '',
        extension: value && value.extension ? value.extension : null,
        ring_my_device_selector_option: null,
        additional_options: null,
        show_device_info_modal: false,
        show_more_options_modal: false,
        more_options_data: null,
      };
     },
     created() {
      if (this.$props.device_extension) {
        this.$data.ring_my_device_selector_option = [
          { text: l.t('app.ring-my-device', 'Ring my device'), value: this.$props.device_extension.id},
          { divider: true }
        ];
      }
      this.add_options();
    },
    methods: {
      add_options() {
        this.$data.additional_options = [];
        const more_options = {
          title: l.t('app.more-options', 'More options'),
          icons: '$vuetify.icons.gear',
          cb: () => this.$data.show_more_options_modal = true,
        };
        if (this.$data.extension && this.$data.extension.id) {
          this.$data.additional_options.push(more_options);
          if (
            this.$data.forwarding_type === 'extension'
            && this.$props.device_extension
            && this.$data.extension.id === this.$props.device_extension.id
          ) {
            this.$data.additional_options.push({
              title: l.t('app.device-information', 'Device information'),
              cb: () => this.$data.show_device_info_modal = true,
            })
          }
        }
        if (this.$data.forwarding_type === 'phone_number') {
          this.$data.additional_options.push(more_options);
        }
      },
      more_options_submitted(data) {
        this.$data.more_options_data = JSON.parse(JSON.stringify(data));
        this.process_changed();
        this.$data.show_more_options_modal = false;
      },
      process_changed(param, val) {
        const value = this.$data.more_options_data ? {...this.$data.more_options_data } : {};
        if (this.$data.forwarding_type === 'phone_number') {
          value['number'] = this.$data.phone_number;
        } else if (this.$data.forwarding_type === 'extension') {
          value['extension'] = this.$data.extension;
        }
        if (param) value[param] = val;
        this.$emit('changed', { value });
      },
      onExtensionInput(data) {
        let value;
        if (!Number.isNaN(value) && this.$props.device_extension && this.$props.device_extension.id === data) {
          value = { // because of the ring my device, click add new destination - it would resdet the ui
            id: data,
            name: this.$props.device_extension.name,
            voip_id: this.$props.device_extension.voip_id,
            extension: this.$props.device_extension.extension,
          }
          this.$forceUpdate();
        } else {
          value = data
        }
        this.$data.extension = value;
      }
    },
    computed: {
      rules() {
        const rules = [];
        if (this.$props.required) {
          rules.push(this.$validation.required());
        }
        rules.push(this.$validation.not_in(this.$props.usedExtensions, false, l.t('Value must be unique')));

        return rules;
      },
    },
    watch: {
      phone_number(value) {
        this.$data.more_options_data = null;
        this.process_changed('number', value);
        this.add_options();
      },
      extension(value) {
        this.$data.more_options_data = null;
        this.process_changed('extension', value);
        this.add_options();
      },
      forwarding_type(v) {
        this.$data.more_options_data = null;
        this.process_changed(v, this.$data[v]);
      },
    },
  };
</script>
