import { useEffect } from 'react'

/**
 *
 */
export function useStepWizardAutoScrollUp (dependency) {
    useEffect(() => {
        if (dependency === true) {
            const rv = document.getElementById('step-wizard-content-root')
            rv?.scrollTo(0, 0)
        }
    }, [dependency])
}
