<template>
  <IndexPage
    :resource="schedule"
    :disable_filters="true"
    :create_route="{name: 'video.users.schedules.create', params: {user_id: $route.params.user_id}}"
    class="video-users-schedules list-page"
    data-test-id="video-user-schedules-list-page"
  >
    <template v-slot:list>
      {{ l.t('video.only-conferences', 'Only conferences 18 months ahead are available.') }}
      <v-simple-table class="nowrap" data-test-id="video-user-schedules-table">
        <thead data-test-id="table-head">
         <tr data-test-id="head-row">
          <th class="select-table-item" data-test-id="checkbox"><br/></th>
          <th class="minimal-width" data-test-id="id">{{ l.t('app.id', 'ID') }}</th>
          <th data-test-id="subject">
              {{ l.t('app.subject', 'Subject') }}
          </th>
          <th data-test-id="start-time">{{ l.t('app.start-time', 'Start time') }}</th>
          <th data-test-id="timezone">{{ l.t('app.timezone', 'Timezone') }}</th>
          <th data-test-id="duration">{{ l.t('app.duration', 'Duration') }}</th>
          <th data-test-id="participants">{{ l.t('app.participants', 'Participants') }}</th>
         </tr>
        </thead>
        <tbody data-test-id="table-body">
          <tr v-for="(item, i) in schedule.items" :data-test-id="`video-user-schedules-item-${index}-row`">
            <td class="select-table-item" data-test-id="checkbox">
              <w-checkbox v-model="item.selected" @change="schedule.checkSubmission()" hide-details='auto' :data-test-id="`video-user-schedules-item-${index}-checkbox`"/>
            </td>
            <td data-test-id="id">{{ item.id }}</td>
            <td data-test-id="link">
              <router-link :to="{name: 'video.users.schedules.show', params: {id: item.id, extension: extension}}">
                {{ item.subject }}
              </router-link>
            </td>
            <td data-test-id="start">{{ new Date(item.start).toLocaleString() }}</td>
            <td data-test-id="timezone">{{ item.time_zone.replace(/_/g, " ") }}</td>
            <td data-test-id="duration">{{ item.duration }}s</td>
            <td :data-test-id="`video-user-schedules-item-${index}-participants`">
              <template v-if="item.participants && item.participants.length">
                <span v-for="participant in item.participants">
                  {{ participant.email }} <br>
                </span>
              </template>
              <span v-else>&mdash;</span>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </template>
  </IndexPage>
</template>
<script>
  import {vueComponent} from 'helpers';
  import l from '../../../../../libs/lang';
  import UserSchedule from '../../../../../models/UserSchedule';
  import IndexPage from '../../../../elements/IndexPage.vue';
  import VideoFragment from '../../../../fragments/Video.vue';

  export default {
    props: ['user', 'extension'],
    components: {
      VideoFragment,
      IndexPage,
    },
  data() {
      const schedule = new UserSchedule(this.$props.user, this.$session, vueComponent(this));
      return {
        l,
        schedule,
      };
    },
  };
</script>
