<template>
  <div class="messages">
    <Submenu
      :routes="{
        'messages.index': {
          title: l.t('app.messages', 'Messages')
        },
        'messages.create': {
          title: l.t('messages.send-message', 'Send message')
        },
      }"
      :key="`${l.detect()}${$route.name}`"
    />
    <MessagesFragment
      v-if="$route.name === 'messages.index'"
      type="greetings"
      data-test-id="messages-list"
    />
    <AddMessagesFragment
      v-if="$route.name === 'messages.create'"
      data-test-id="messages-create"
    />
  </div>
</template>

<script>
import l from '../../../libs/lang';
import MessagesFragment from './index.vue';
import AddMessagesFragment from './create.vue';
import Submenu from '../../elements/Submenu.vue';

export default {
  components: {
    Submenu,
    MessagesFragment,
    AddMessagesFragment,
  },
  data() {
    return {
      l,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (window.deleting_in_progress) {
      this.$emit('userRedirected', to, from, next);
    } else {
      next();
    }
  },
};
</script>
