import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { RouterProps } from '../../utils/objects'
import Header from './components/Header'
import Content from './components/Content'
// import Footer from './components/Footer'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        '& *': {
            fontFamily: 'Inter, Montserrat, sans-serif !important'
        }
    }
}))

interface ListingInterface {
    routeProps: RouterProps
}

/**
 *
 */
const AppsMarketPlace = (props: ListingInterface) => {
    const classes = useStyles()

    return (
        <Box className={classes.root}>
            <Header />
            <Content />
            {/* <Footer /> */}
        </Box>
    )
}

export default AppsMarketPlace
