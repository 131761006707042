import React from 'react'
import { Step, Variant as StepVariant } from 'step'
import { Stepper as MuiStepper, MobileStepper as MuiMobileStepper, StepperProps as MuiStepperProps } from '@material-ui/core'
import { ArrowBackIosIcon } from 'svg-icons'
import Typography from 'typography'
import { ThemeProvider } from 'theme-provider'

/***/
export enum Variant {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal'
}

/***/
export enum MobileVariant {
    DOTS = 'dots',
    TEXT = 'text'
}

/***/
export interface StepInfo {
    /**
     * Unique id
     */
    id: number | string
    /**
     * Main text below the step icon
     */
    title?: string
    /**
     * Secondary / alternative text below the title / main text
     */
    secondaryTitle?: string
    /**
     * Is the step done
     */
    done?: boolean
    /**
     * Has the step an error?
     */
    error?: boolean
    /**
     * Is the step the current active one?
     */
    active?: boolean
    content?: any
    'data-test-id'?: string
}

interface StepperProps extends Omit<MuiStepperProps, 'variant' | 'children'> {
    /**
     * Array with steps info
     */
    steps: StepInfo[];
    /**
     * The variant of the stepper
     */
    variant?: Variant.HORIZONTAL | Variant.VERTICAL;
    /**
     * The variant of the mobile stepper
     */
    mobileVariant?: MobileVariant.DOTS | MobileVariant.TEXT;
    /**
     * When true the mobile stepper will be rendered
     */
    smallView?: boolean;
    /**
     * Called on step click if the step is clickable
     */
    onStepClick?: (stepId: string) => void;
    /**
     * Called on next click if there is a next step. Shown for mobile stepper only.
     */
    onNextClick?: () => void;
    /**
     * Called on back click if there is a previous step. Shown for mobile stepper only.
     */
    onBackClick?: () => void;
    /**
     * If true, disables the stepper preventing navigation
     */
    disabled?: boolean;
}

const getStepVariant = (step, steps): StepVariant => {
    const activeStepId = steps.find((step): boolean => step.active)?.id
    const isStepActive = activeStepId === step.id
    const isStepDone = step.done
    const hasError = step.error
    const stepVariant: StepVariant = isStepActive
        ? StepVariant.ACTIVE
        : isStepDone
            ? StepVariant.DONE
            : hasError ? StepVariant.ERROR : StepVariant.INACTIVE
    return stepVariant
}

const isClickable = (step, steps): boolean => {
    const [stepIndex, activeStepIndex] = steps.reduce((values, s, index): number[] => {
        if (s.active) values[1] = index
        if (s.id === step.id) values[0] = index
        return values
    }, [-1, -1])
    const stepVariant: StepVariant = getStepVariant(step, steps)
    // The requirements are: The step should be clickable in case it is some previous step (relatively to the active one) OR it is completed / done.
    return stepIndex < activeStepIndex || stepVariant === StepVariant.DONE
}

const renderStep = (step, steps, isVertical, onStepClick, disabled = false): React.ReactNode => {
    const clickable = isClickable(step, steps)
    const stepVariant: StepVariant = isVertical ? StepVariant.ACTIVE : getStepVariant(step, steps)

    // Pass any all data- props as is. (including both data-testid and data-test-id, etc.)
    const dataProps = Object.fromEntries(Object.entries(step).filter(([key]) => key.startsWith('data-')))

    return (
        <Step
            key = {step.id}
            title = {step.title}
            secondaryTitle = {step.secondaryTitle}
            variant = {stepVariant}
            smallView = {isVertical}
            clickable = {clickable && !disabled}
            onClick = {(): void => onStepClick?.(step.id)}
            content = {step.content}
            {...dataProps}
        />
    )
}

const getOtherProps = (props: StepperProps, removeProps: string[]) => {
    const otherProps = { ...props }
    removeProps.forEach((prop: string): boolean => delete otherProps[prop])
    return otherProps
}

const DesktopStepper = (props: StepperProps): JSX.Element => {
    const { steps, onStepClick, disabled } = props
    const activeStepIndex = steps.findIndex((s): boolean => s.active)
    const otherProps = getOtherProps(props, ['steps', 'variant', 'mobileVariant'])
    return (
        <MuiStepper {...otherProps} nonLinear activeStep={activeStepIndex}>
            {steps.map((step): React.ReactNode => renderStep(step, steps, false, onStepClick, disabled))}
        </MuiStepper>
    )
}

const MobileStepper = (props: StepperProps): JSX.Element => {
    const { steps, mobileVariant, onNextClick, onBackClick, disabled } = props
    const activeStepIndex = steps.findIndex((s): boolean => s.active)
    const otherProps = getOtherProps(props, ['steps', 'classes', 'activeStep', 'variant', 'position', 'nextButton', 'backButton', 'mobileVariant', 'smallView'])
    return (
        <MuiMobileStepper
            {...otherProps}
            steps = {steps.length}
            classes = {{ dotActive: 'active', root: 'MuiMobileStepper-no-padding' }}
            activeStep = {activeStepIndex}
            variant = {mobileVariant}
            position = 'static'
            nextButton = {
                <div
                    className = {`stepper-nav-button ${(activeStepIndex === (steps.length - 1) || disabled) ? 'disabled' : ''}`}
                    onClick = {onNextClick}
                ><span><Typography variant='buttonLarge'>next</Typography></span><ArrowBackIosIcon/></div>
            }
            backButton = {
                <div
                    className = {`stepper-nav-button ${(activeStepIndex === 0) || disabled ? 'disabled' : ''}`}
                    onClick = {onBackClick}
                ><ArrowBackIosIcon/><span><Typography variant='buttonLarge'>back</Typography></span></div>
            }
        />
    )
}

const VerticalStepper = (props: StepperProps): JSX.Element => {
    const { steps, onStepClick } = props
    const activeStepIndex = steps.findIndex((s): boolean => s.active)
    const otherProps = getOtherProps(props, ['steps', 'mobileVariant', 'smallView'])
    return (
        <MuiStepper
            {...otherProps}
            orientation = 'vertical'
            nonLinear
            activeStep = {activeStepIndex}
            connector = {<div className='vertical-connector'/>}
        >
            {steps.map((step): React.ReactNode => renderStep(step, steps, true, onStepClick))}
        </MuiStepper>
    )
}

/**
 * Stepper component
 *
 * @param {StepperProps} props - props
 */
export const Stepper = (props: StepperProps): JSX.Element => {
    const { smallView, variant } = props
    return (
        <ThemeProvider>
            {variant === Variant.HORIZONTAL
                ? smallView
                    ? <MobileStepper {...props}/>
                    : <DesktopStepper {...props}/>
                : <VerticalStepper {...props}/>
            }
        </ThemeProvider>
    )
}

const defaultStepperProps = {
    mobileVariant: MobileVariant.DOTS,
    smallView: false,
    variant: Variant.HORIZONTAL
}

Stepper.defaultProps = defaultStepperProps

export default Stepper
