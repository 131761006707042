class CustomEvents {

    constructor() {
        this.topicCallbacks = {};
        
        if (window.customEvents) {
            this.topicCallbacks = window.customEvents.topicCallbacks;
        } else {
            window.customEvents = this;
        }
    };

    on = (topic, callback) => {
        if (!this.topicCallbacks[topic])
            this.topicCallbacks[topic] = [callback];
        else if (!this.topicCallbacks[topic].includes(callback)) {
            this.topicCallbacks[topic].push(callback);
        }
    }

    trigger = (topic, payload) => {
        let callbacks = this.topicCallbacks[topic];
        if (callbacks)
            callbacks.forEach(callback => callback(payload));
        else
            console.log(`no callback defined for topic ${topic}`);
    }

}

export default new CustomEvents();
