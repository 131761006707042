import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A8538
 *
 * @param {SvgIconProps} props - svg props
 */
export const CallIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.85368 10.6225C8.15343 13.4138 10.3236 15.731 13.0171 17.232L15.2236 15.1744C15.4944 14.9219 15.885 14.8482 16.2202 14.9763C17.2931 15.3728 18.4589 15.6076 19.6606 15.6495C20.1936 15.6681 20.6145 16.1195 20.5959 16.6525L20.4777 20.0347C20.4777 21 19.533 21.5186 19 21.5C9.94769 21.1839 2.75779 13.2891 2.99562 4.24866C3.0184 3.3826 3.76829 2.98454 4.57826 2.98454L7.97013 3.10299C8.50314 3.1216 8.924 3.57293 8.90539 4.10593C8.86309 5.31732 9.0163 6.48701 9.33697 7.58493C9.43172 7.92784 9.341 8.30309 9.06017 8.56496L6.85368 10.6225Z" fill={color} />
        </SvgIcon>
    )
}

export default CallIcon
