import React, { Component } from 'react'
import BackButton from 'back-button'
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
    header:			theme.settingsApp.header,
    settingWrapper:	theme.settingsApp.settingWrapper,
	webButton:      theme.webButton,
    container: {
		border:			'1px solid lightgray',
		borderRadius:	6,
		width:			540
    },
    musicOnHoldHeader: {
        display:				'flex',
		borderTopLeftRadius:	6,
		borderTopRightRadius:	6,
		'& > div': {
			flex:				1,
			display:			'flex',
			justifyContent:		'center',
			alignItems:			'center',
			padding:			'16px 24px',
			fontSize:			18,
			color:				'black',
			borderBottom:		'1px solid lightgray',
			textTransform:		'uppercase',
			cursor:				'pointer',
			'&.selected': {
				color:			'white',
				background:		'#1140A1'
			},
			'&:first-child': {
				borderRight:			'1px solid lightgray',
				borderTopLeftRadius:	6
			},
			'&:last-child': {
				borderTopRightRadius:	6
			},
			'& svg': {
				marginRight:	10
			}
		}
	},
	option: {
		display:	'flex',
		padding:	10
	},
	radioWrapper: {
		width:			'50%',
		cursor:			'pointer',
		display:		'flex',
		alignItems:		'center',
		paddingRight:	10,
		minHeight:		32
	},
	radioLabel: {
		textTransform:	'capitalize',
		marginLeft:		10
	},
	radio: {
		width:			26,
		height:			26,
		border:			'1px solid black',
		borderRadius:	15,
		display:		'flex',
		justifyContent:	'center',
		alignItems:		'center',
		cursor:			'pointer',
		'&.selected > div': {
			width:				18,
			height:				18,
			borderRadius:		10,
			backgroundColor:	'#1140A1'
		}
	},
	autocomplete: {
		width:			'50%',
		paddingLeft:	10
	}
})

class MusicOnHold extends Component {
    state = {
		selected:		'pick',
		selectedOption:	'standard'
    }
    
    isSaveEnabled = () => {
		return true // It should check if a change was made
	}

	renderRadio = name => {
		const { classes } = this.props
		return (
			<div
				className	= {classes.radioWrapper}
				onClick		= {() => this.setState({selectedOption: name})}
				style		= {this.state.selectedOption === name ? {borderRight: '1px dashed lightgray'} : {}}
			>
				<div className={`${classes.radio} ${this.state.selectedOption === name ? 'selected' : ''}`}><div></div></div>
				<span className={classes.radioLabel}>{name}</span>
			</div>
		)
	}

    render() {
		const { classes } = this.props
		let backButton = this.props.smallView ? <BackButton onClick={this.props.goBack}/> : ''
		return (
			<>
				<div className={classes.header}>
					<div>
						{backButton}
						<span className='header-title'>Music on hold</span>
					</div>
					<div
						className	= {`${classes.webButton} ${!this.isSaveEnabled() ? 'not-allowed' : ''}`}
						onClick		= {() => console.log('SAVING')}
					>Save</div>
				</div>
				<div className={classes.settingWrapper}>
					<div className={classes.container}>
						<div className={classes.musicOnHoldHeader}>
							<div
								className	= {this.state.selected === 'pick' ? 'selected' : ''}
								onClick		= {() => this.setState({selected: 'pick'})}
							>Pick from library</div>
							<div
								className	= {this.state.selected === 'upload' ? 'selected' : ''}
								onClick		= {() => this.setState({selected: 'upload'})}
							><BackupOutlinedIcon/> Upload a file</div>
						</div>
						<div className={classes.mainSection}>
							<div className={classes.option}>
								{this.renderRadio('standard')}
								{this.state.selectedOption === 'standard' ?
								<Autocomplete
									id				= 'premium-autocomplete'
									options			= {['Soft Rock']}
									value			= 'Soft Rock'
									getOptionLabel	= {option => option}
									classes			= {{root: classes.autocomplete}}
									onChange		= {(e, language) => this.setState({language})}
									renderInput		= {params => (
										<TextField
											{...params}
											fullWidth
										/>
									)}
								/>
								: null}
							</div>
							<div className={classes.option}>
								{this.renderRadio('premium')}
								{this.state.selectedOption === 'premium' ?
								<Autocomplete
									id				= 'premium-autocomplete'
									options			= {['Merry Holidays']}
									value			= 'Merry Holidays'
									getOptionLabel	= {option => option}
									classes			= {{root: classes.autocomplete}}
									onChange		= {(e, language) => this.setState({language})}
									renderInput		= {params => (
										<TextField
											{...params}
											fullWidth
										/>
									)}
								/>
								: null}
							</div>
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default withStyles(styles)(MusicOnHold)