import React, { useState, useEffect, useContext } from 'react'
import Select from 'select-mui'
import MenuItem from 'menu-item-mui'
import { AudioPlayerWithClasses as AudioPlayer } from 'audio-component-2'
import { ringtones, RingtoneData } from '../AudioRinger/Ringtones'
import { makeStyles } from '@material-ui/core'
import RemoteConfigValue from 'remote-config-value'
import { db } from 'mypdc-dexie'
import styles from './styles'
import { useVolume } from '../AudioSettings'
import { FeatureEventsContext } from 'providers/src'

const useStyles = makeStyles(styles)

/***/
const RingtoneSelector = (): JSX.Element => {
    const classes = useStyles()
    const [ringtone, setRingtone] = useState(ringtones?.[0])
    const [isOpen, setIsOpen] = useState(false)
    const [playingId, setPlayingId] = useState(-1)
    const DBKEY = 'ringtoneFilename'
    const { getVolume } = useVolume()
    const featureEventsContext = useContext(FeatureEventsContext)

    useEffect(() => {
        if (!ringtones) return
        db.mypdc.get({ key: DBKEY }).then(value => {
            if (!value?.value) return
            const r = ringtones.find(r => r.name === (value.value as string)) as RingtoneData
            if (r) setRingtone(r)
        })
    }, [])

    if (!ringtones) return <></>

    const onChange = (event) => {
        try {
            const elements = [...document.getElementsByClassName('embedded-audio-player-wrapper')]
            const isPlayButtonClick = elements.some(element => element.contains(event.target as HTMLElement))
            if (isPlayButtonClick) return
        } catch {}

        const filename = event.target.value as string
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'ringtone-select', value: filename })
        const r = ringtones.find(r => r.name === filename) as RingtoneData
        if (r !== ringtone) setPlayingId(-1)
        setRingtone(r)
        // ringSoundAudio.src = r.url
        // ringSoundAudio.volume = getVolume() / 150 // 150 because the ringtone is played around half the volume
        db.mypdc.get({ key: DBKEY }).then(value => {
            if (value?.id) db.mypdc.update(value.id, { value: filename })
            else db.mypdc.add({ key: DBKEY, value: filename })
        })
    }

    return (
        <div className='ringtone-selector-wrapper' data-test-id='ringtone-selector-wrapper'>
            <Select
                open = {playingId !== -1 || isOpen}
                label = {<RemoteConfigValue valueId='softphone_preferences_incoming_call_ringtone_label'/>}
                value = {ringtone.name}
                onChange = {onChange}
                onOpen = {() => setIsOpen(true)}
                onClose = {(e) => {
                    const elements = [...document.getElementsByClassName('embedded-audio-player-wrapper')]
                    const shouldClose = !elements.some(element => element.contains(e.target as HTMLElement))
                    if (shouldClose) {
                        setIsOpen(false)
                        setPlayingId(-1)
                    }
                }}
                MenuProps = {{ classes: { list: classes.dropdownItemsWrapper } }}
                formControlClasses = {{ root: `${classes.dropdownMenu} size-md` }}
            >
                {ringtones.map((ringtone, i) => {
                    const name = ringtone.name
                    return (
                        <MenuItem
                            key = {name}
                            value = {name}
                            checked = {true}
                            action = {name !== 'No ringtone' &&
                                <div className='embedded-audio-player-wrapper'>
                                    <AudioPlayer
                                        url = {ringtone.url}
                                        label = {ringtone.name}
                                        stopPlaying = {playingId !== -1 && playingId !== i}
                                        onPlay = {(isPlaying: boolean) => setPlayingId(isPlaying ? i : -1)}
                                        playButtonOnly
                                        size = 'small'
                                        volume = { getVolume() }
                                    />
                                </div>
                            }
                        >{name}</MenuItem>
                    )
                })}
            </Select>
        </div>
    )
}

export default RingtoneSelector
