<template>
  <div class="subaccounts">
    <Submenu
      :routes="{
        'subaccounts.index': {
          title: l.t('app.subaccounts', 'Subaccounts')
          },
        'subaccounts.create': {
          title: l.t('subaccounts.add-subaccount','Add subaccount')
          },
      }"
      :key="`${l.detect()}${$route.name}`"
    />
    <SubaccountsFragment
      v-if="$route.name === 'subaccounts.index'"
      data-test-id="subaccounts-index"
    />
    <SubaccountsCreateFragment
      v-if="$route.name === 'subaccounts.create'"
      data-test-id="subaccounts-create"
    />
  </div>
</template>

<script>
import l from '../../../libs/lang';
import SubaccountsFragment from './index.vue';
import Submenu from '../../elements/Submenu.vue';
import SubaccountsCreateFragment from './create.vue';

export default {
  components: {
    Submenu,
    SubaccountsFragment,
    SubaccountsCreateFragment,
  },
  data() {
    return {
      l,
    };
  },
};
</script>
