/* eslint-disable react/prop-types, no-tabs */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import api from '../util/api_v5'
import FaxContent from './FaxContent'
import FaxSelector from './FaxSelector'
import ConfirmDeleteModal from 'confirm-delete-modal'
import PhoneComUser from 'phone-com-user'
import {
    updateFaxes,
    addFax,
    updateFax,
    deleteFax,
    updateFaxLinks,
    addContactsToFaxes,
    removeContactFromFaxes
} from '../actions/faxes'
import { switchExtension, setIsFaxConfigured } from '../actions/pdcuser'
import ResizeAware from 'react-resize-aware'
import { setSmallView } from '../actions/view'
import LoaderFull from 'loader-full'
import { withStyles } from '@material-ui/core'
import { pushNotification } from 'notification-pusher'
import NewFaxPanel from './NewFaxPanel'
import StartNewButton from 'start-new-button'
import { theme } from 'get-theme'
import { ScreenSizeContext } from 'providers'

import { Switch, Route } from 'react-router-dom'

import { cacheUpdateListener } from 'service-worker-utils'

const threshold = 768

const mapStateToProps = (state) => ({
    faxes: state.faxes,
    smallView: state.smallView,
    currentExtensionRedux: state.currentExtension,
    isFaxConfigured: state.isFaxConfigured,
    currentFax: null
})

const mapDispatchToProps = (dispatch) => ({
    updateFaxes: (faxes) => dispatch(updateFaxes(faxes)),
    addFax: (fax) => dispatch(addFax(fax)),
    updateFax: (fax) => dispatch(updateFax(fax)),
    deleteFax: (fax) => dispatch(deleteFax(fax)),
    setSmallView: (boolVal) => dispatch(setSmallView(boolVal)),
    switchExtension: (extension) => dispatch(switchExtension(extension)),
    setIsFaxConfigured: (isConf) => dispatch(setIsFaxConfigured(isConf)),
    updateFaxLinks: (links) => dispatch(updateFaxLinks(links)),
    addContactsToFaxes: (contacts) => dispatch(addContactsToFaxes(contacts)),
    removeContactFromFaxes: (contactId) => dispatch(removeContactFromFaxes(contactId))
})

const styles = (theme) => ({
    appWrapper: {
        display: 'flex',
        height: '100%'
    },
    loadingDiv: theme.loadingDiv,
    faxesPanel: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: theme.selector.width,
        boxShadow: '0 0 0 1px #ccd6db',
        '&.small-view': {
            width: '100%'
        },
        '&:not(.small-view)': {
            maxWidth: theme.selector.width
        }
    }
})

class App extends Component {
    constructor (props) {
        super(props)

        this.state = {
            loading: false,
            loadedOnce: false,
            isConfirmDeleteFaxModalShown: false,
            newFax: null,
            deletedIncomingFaxes: { num: 0, randomString: '' },
            deletedOutgoingFaxes: { num: 0, randomString: '' }
        }

        this.props.resetSubscription(true) // eslint-disable-line react/prop-types
        if (props.extension && props.extension.extension_id !== PhoneComUser.getExtensionId()) { // eslint-disable-line react/prop-types
            PhoneComUser.changeExtension(props.extension.extension_id) // eslint-disable-line react/prop-types
        }
    }

	static contextType = ScreenSizeContext

	componentDidMount () {
	    this._ismounted = true
	    this.props.subscribeForNotifications('fax', this.faxUpdate, true)
	    this.initialLoad()
	    this.checkIfFaxConfigured()
	    this.props.contactsUtil.reload()

	    cacheUpdateListener('/faxes/list-faxes', data => {
	        console.log('fax updated from cache', data)
	        // this.loadFaxes([data])
	    })
	}

	componentWillUnmount () {
	    this._ismounted = false
	}

	checkIfFaxConfigured = () => {
	    api.isFaxConfigured().then((response) => {
	        console.log('isFaxConfigured returns:', response)
	        if (!this._ismounted) return
	        this.props.setIsFaxConfigured(Boolean(response))
	    })
	}

	componentDidUpdate (prevProps) {
	    if (this.props.extension && this.props.extension.extension_id !== PhoneComUser.getExtensionId()) {
	        PhoneComUser.changeExtension(this.props.extension.extension_id)
	        const phoneNumbers = Object.keys(this.props.extension.phone_number)
	        PhoneComUser.changePhoneNumber(!phoneNumbers.length ? '' : phoneNumbers)
	        this.switchFax()
	        this.props.contactsUtil.reload()
	        this.props.resetSubscription(true)
	        this.checkIfFaxConfigured()
	        this.initialLoad(true)
	    }

	    if (prevProps.isOffline && !this.props.isOffline) this.adjustView()

	    this.updateContactsInfoInFaxes()
	}

	updateContactsInfoInFaxes = () => {
	    if (!this.props.contactsUtil.extraContactsLoaded) return
	    let numNewContacts = 0
	    const faxesIncoming = this.props.faxes['fax-incoming']
	    const faxesOutgoing = this.props.faxes['fax-outgoing']
	    if (!faxesIncoming || !faxesOutgoing) return
	    const faxItemsArrays = [faxesIncoming.items, faxesOutgoing.items]
	    faxItemsArrays.forEach((faxItems) => {
	        const contactIdsToBeRemoved = []
	        faxItems.forEach((fax) => {
	            const theOtherParticipant = fax.direction === 'in' ? 'from' : 'to'
	            const extraContact = this.props.contactsUtil.extraContacts.find((contact) =>
	                contact.numbers.find((n) => n.number === fax[theOtherParticipant]?.number)
	            )
	            if (extraContact && !fax[theOtherParticipant].contact_id) {
	                numNewContacts++
	            } else if (!extraContact && fax[theOtherParticipant].contact_id) {
	                contactIdsToBeRemoved.push(fax[theOtherParticipant].contact_id)
	            }
	        })
	        if (numNewContacts > 0) {
	            this.props.addContactsToFaxes(this.props.contactsUtil.extraContacts)
	        }
	        if (contactIdsToBeRemoved.length) {
	            contactIdsToBeRemoved.forEach((contactId) => this.props.removeContactFromFaxes(contactId))
	        }
	    })
	}

	adjustView = () => {
	    this.initialLoad(true)
	}

	initialLoad = (force) => {
	    const extensionSwitched = Boolean(
	        !this.props.currentExtensionRedux ||
				this.props.extension.extension_id !== this.props.currentExtensionRedux.extension_id
	    )
	    if (
	        !extensionSwitched &&
			this.props.faxes['fax-incoming'] &&
			(this.props.faxes['fax-incoming'].items || this.props.faxes['fax-outgoing'].items) &&
			!force
	    ) {
	        this.makeDefaultSelection()
	        return this.setState({ loadedOnce: true })
	    }

	    if (extensionSwitched) {
	        this.props.switchExtension(this.props.extension)
	        this.props.updateFaxLinks({})
	    }

	    this.setState({ loading: true })
	    // limit so based on height so it always gets filled
	    const limit = Math.max(parseInt(window.innerHeight / 50), 15)
	    Promise.all([
	        api.loadFaxes(0, limit, { message_type: 'fax-incoming' }),
	        api.loadFaxes(0, limit, { message_type: 'fax-outgoing' })
	    ]).then(responses => { this.loadFaxes(responses) })
	}

	loadFaxes = async responses => {
	    if (!this._ismounted) return // console.log('Faxes App.js got unmounted')
	    if (responses[0] === 'network-error' || responses[1] === 'network-error') return
	    this.props.updateFaxes(responses[0])
	    this.props.updateFaxes(responses[1])
	    await this.loadExtraContacts()
	    this.setState({ loading: false, loadedOnce: true })
	    this.props.onLoaded()

	    const faxesIncoming = this.props.faxes['fax-incoming']
	    const faxesOutgoing = this.props.faxes['fax-outgoing']
	    if (this.props.faxes && (faxesIncoming.items || faxesOutgoing.items)) {
	        if (this.openUrlFax()) {
	            console.log('open url fax')
	            return
	        }
	    }

	    // if (this.props.smallView) {
	    // 	return this.switchFax()
	    // }

	    const regex = /\/(sent|received)\/\w+/gm
	    const hasMatch = this.props.routerProps.location.pathname.match(regex)

	    const shouldLoadDefault = !hasMatch
	    if (shouldLoadDefault) this.makeDefaultSelection()
	}

	makeDefaultSelection = () => {
	    // if youre in another view, no need to make default selection
	    if (this.props.routerProps.location.pathname.indexOf('new_fax') > 0) {
	        return
	    }

	    const faxesIncoming = this.props.faxes['fax-incoming']
	    const faxesOutgoing = this.props.faxes['fax-outgoing']
	    const themeDefaultTab = theme.faxesApp.tabs.defaultTab
	    const notDefaultTab = themeDefaultTab === 'sent' ? 'received' : 'sent' // eslint-disable-line no-unused-vars

	    const shownFaxes = themeDefaultTab === 'sent' ? faxesOutgoing : faxesIncoming
	    if (shownFaxes) {
	        const fax = shownFaxes.items.length ? shownFaxes.items[0] : null // eslint-disable-line no-unused-vars

	        // this.switchFax(fax ? fax.id : undefined)
	        this.switchTab(themeDefaultTab)
	    }
	}

	loadExtraContacts = async () => {
	    this.props.addContactsToFaxes(this.props.contactsUtil.extraContacts)
	    const incomingFaxes = this.props.faxes['fax-incoming']
	    const incomingFaxItems = incomingFaxes.items
	    const outgoingFaxes = this.props.faxes['fax-outgoing']
	    const outgoingFaxItems = outgoingFaxes.items
	    let phoneNumbers = []
	    // Collect all of the phone numbers which are not connected to a contact
	    incomingFaxItems.forEach((f) => {
	        if (f.from.contact_id) return
	        const extraContacts = this.props.contactsUtil.extraContacts
	        let alreadyLoaded = false
	        extraContacts.forEach((ec) => {
	            ec.numbers.forEach((ecn) => (ecn.number === f.from.number ? (alreadyLoaded = true) : null))
	        })
	        if (!alreadyLoaded) phoneNumbers.push(f.from.number)
	    })
	    outgoingFaxItems.forEach((f) => {
	        if (f.to.contact_id) return
	        const extraContacts = this.props.contactsUtil.extraContacts
	        let alreadyLoaded = false
	        extraContacts.forEach((ec) => {
	            ec.numbers.forEach((ecn) => (ecn.number === f.to.number ? (alreadyLoaded = true) : null))
	        })
	        if (!alreadyLoaded) phoneNumbers.push(f.to.number)
	    })
	    if (phoneNumbers.length === 0) return
	    // Remove duplicates
	    phoneNumbers = Array.from(new Set(phoneNumbers))
	    const filters = { keyword: phoneNumbers }
	    await this.props.contactsUtil.loadExtraContacts(filters)
	    this.props.addContactsToFaxes(this.props.contactsUtil.extraContacts)
	}

	openUrlFax = () => {
	    // If the url path links to a fax then open it
	    const pathname = window.location.pathname
	    const pathnameSplit = pathname.split('/').filter((e) => e)
	    let faxId
	    if (pathnameSplit.length > 2 && pathnameSplit[1] === 'faxes') {
	        faxId = pathnameSplit[2].substring(1)
	    }
	    const incomingFaxItems = this.props.faxes['fax-incoming'].items
	    const outgoingFaxItems = this.props.faxes['fax-outgoing'].items
	    if (!incomingFaxItems && !outgoingFaxItems) return false

	    let pathFax = incomingFaxItems.find((v) => `${v.id}` === faxId)
	    if (pathFax) {
	        this.switchFax(pathFax.id)
	        return true
	    }
	    pathFax = outgoingFaxItems.find((v) => `${v.id}` === faxId)
	    if (pathFax) {
	        this.switchFax(pathFax.id)
	        // this.props.switchView("content")
	        return true
	    }
	    return false
	}

	faxUpdate = (updatedFax) => {
	    updatedFax.direction = updatedFax.fax_type === 'fax-incoming' ? 'in' : 'out'

	    if (updatedFax.type === 'delete') {
	        return this.faxDeleted(updatedFax)
	    }

	    if (updatedFax.type === 'read_status') {
	        const faxIds = updatedFax.fax_id
	        faxIds.forEach((faxId) => {
	            const isNew = updatedFax.is_new
	            const fax = this.props.faxes[updatedFax.fax_type].items.find((updatedFax) => updatedFax.id === faxId)
	            if (!fax || Boolean(fax.is_new) === Boolean(isNew)) return
	            this.changeReadStatus(fax, false)
	            // if (fax.id === this.props.currentFax.id) this.switchFax(fax.id)
	        })
	        return
	    }

	    if (updatedFax.type === 'delivery_status') {
	        const faxId = updatedFax.id
	        const fax = this.props.faxes[updatedFax.fax_type].items.find((updatedFax) => updatedFax.id === faxId)
	        if (!fax) return
	        fax.delivery_status = updatedFax.delivery_status
	        fax.delivery_message = updatedFax.delivery_message
	        delete fax.justSent
	        this.props.updateFax(fax)
	        if (updatedFax.delivery_status === 'sent') {
	            fax.is_new = true
	            this.changeReadStatus(fax, false)
	        }
	        if (this.props.currentFax && fax.id === this.props.currentFax.id) this.switchFax(fax.id)
	        return
	    }

	    const incomingFaxItems = this.props.faxes['fax-incoming'].items
	    for (const fax of incomingFaxItems) {
	        if (fax.id === updatedFax.id) {
	            return this.faxUpdated(updatedFax)
	        }
	    }
	    const outgoingFaxItems = this.props.faxes['fax-outgoing'].items
	    for (const fax of outgoingFaxItems) {
	        if (fax.id === updatedFax.id) {
	            return this.faxUpdated(updatedFax)
	        }
	    }
	    this.newFaxReceived(updatedFax)
	}

	newFaxReceived = (fax) => {
	    this.props.addFax(fax)
	    this.setState({ newFax: fax.id })

	    if (window.V5PHONECOM.features.has('fax')) {
	        const extensionId = this.props.extension.extension_id
	        const faxId = fax.id
	        const url = `https://${window.document.location.host}/e${extensionId}/fax/f${faxId}`
	        const title = 'New fax from: ' + fax.fromNumber
	        pushNotification(title, fax.transcript || '', url, null, 'My Phone.com')
	    }
	}

	setNoNewFax = () => {
	    this.setState({ newFax: null })
	}

	faxUpdated = (fax) => {
	    this.props.updateFax(fax)
	}

	faxDeleted = (fax) => {
	    // let currentFaxId = this.props.currentFax.id
	    // let currentFaxType = this.props.currentFax.direction === "out" ? "fax-outgoing" : "fax-incoming"
	    const faxIds = fax.fax_ids.map((f) => `${f}`)
	    const incomingFaxes = this.props.faxes['fax-incoming'].items.filter((f) => faxIds.includes(`${f.id}`))
	    const outgoingFaxes = this.props.faxes['fax-outgoing'].items.filter((f) => faxIds.includes(`${f.id}`))
	    incomingFaxes.forEach((f) => this.props.deleteFax(f))
	    outgoingFaxes.forEach((f) => this.props.deleteFax(f))

	    // if (faxIds.includes(`${currentFaxId}`)) {
	    // 	let faxItems = this.props.faxes[currentFaxType].items
	    // 	let switchFax = null
	    // 	if (faxItems.length) switchFax = faxItems[0]
	    // 	this.switchFax(switchFax.id)
	    // }

	    const deletedIncomingFaxes = {
	        num: incomingFaxes.length,
	        randomString: `${Math.floor(Math.random() * 1000000)}${new Date().getTime()}`
	    }
	    const deletedOutgoingFaxes = {
	        num: outgoingFaxes.length,
	        randomString: `${Math.floor(Math.random() * 1000000)}${new Date().getTime()}`
	    }
	    if (incomingFaxes.length) this.setState({ deletedIncomingFaxes })
	    if (outgoingFaxes.length) this.setState({ deletedOutgoingFaxes })
	}

	handleResize = (size) => {
	    const needToExpand =
			this.props.smallView &&
			((this.props.standalone && size.width >= threshold) || !this.props.screenViewType.isMobileView)
	    const needToShrink =
			!this.props.smallView &&
			((this.props.standalone && size.width < threshold) || this.props.screenViewType.isMobileView)
	    if (needToShrink) {
	        this.props.setSmallView(true)
	    } else if (needToExpand) {
	        this.props.setSmallView(false)
	    }
	}

	deleteFax = async (fax) => {
	    const currentFax = fax
	    const currentFaxId = currentFax.id
	    const incomingFaxes = this.props.faxes['fax-incoming']
	    const outgoingFaxes = this.props.faxes['fax-outgoing']
	    const isIncoming = currentFax.direction === 'in'

	    this.props.deleteFax(currentFax)
	    const faxItems = isIncoming ? incomingFaxes.items : outgoingFaxes.items
	    let switchFax = null
	    if (faxItems.length > 0) {
	        switchFax = faxItems[0]
	        if (switchFax.id === currentFaxId) {
	            if (faxItems.length > 1) {
	                switchFax = faxItems[1]
	            } else {
	                switchFax = null
	            }
	        }

	        this.switchFax(switchFax.id)
	    }

	    await api.deleteFax(currentFax.id, currentFax.direction)
	    this.setState({ deletedFaxes: { id: currentFaxId, direction: isIncoming ? 'in' : 'out' } })

	    if (isIncoming) {
	        const deletedIncomingFaxes = {
	            num: 1,
	            randomString: `${Math.floor(Math.random() * 1000000)}${new Date().getTime()}`
	        }
	        this.setState({ deletedIncomingFaxes })
	    } else {
	        const deletedOutgoingFaxes = {
	            num: 1,
	            randomString: `${Math.floor(Math.random() * 1000000)}${new Date().getTime()}`
	        }
	        this.setState({ deletedOutgoingFaxes })
	    }
	}

	changeReadStatus = (fax, backendChange = true) => {
	    if (!fax) return
	    const isNew = !fax.is_new
	    fax.is_new = isNew
	    const newFax = { ...fax }
	    this.props.changeFaxReadStatus(isNew ? 'unread' : 'read', 1)
	    const faxType = fax.direction === 'in' ? 'fax-incoming' : 'fax-outgoing'
	    if (backendChange) {
	        api.markFaxRead(fax.id, isNew, faxType).then(statusChanged => {
	            if (!statusChanged) return console.error(`Error while marking fax ${isNew ? '' : 'un'}read`)
	        })
	    }
	    this.props.updateFax(newFax)
	    this.setState({ currentFax: newFax })
	}

	onCreateFaxClick = () => {
	    this.switchFax()
	    this.props.routerProps.history.push(`${this.props.routerProps.match.path}/new_fax`)
	    const tagsInput = document.getElementById('tags-input')
	    if (tagsInput) tagsInput.focus()
	}

	renderFaxSelector = (faxId) => {
	    const { classes } = this.props
	    let hiddenClass = ''
	    // if (this.props.smallView && this.props.currentView !== "select") hiddenClass = "hidden"
	    if (this.props.smallView && faxId) hiddenClass = 'hidden'
	    const currentTab = this.props.routerProps.location.pathname.indexOf('sent') > 0 ? 'sent' : this.props.routerProps.location.pathname.indexOf('received') > 0 ? 'received' : null
	    console.log(currentTab)
	    return (
	        <div className={`${classes.faxesPanel} ${hiddenClass} ${this.props.smallView ? 'small-view' : ''}`}>
	            <StartNewButton app="faxes" title="Send new fax" onClick={this.onCreateFaxClick} />
	            <FaxSelector
	                currentFaxId={faxId}
	                newFax={this.state.newFax}
	                setNoNewFax={this.setNoNewFax}
	                smallView={this.props.smallView}
	                screenViewType={this.props.screenViewType}
	                deleteFax={this.deleteFax}
	                changeReadStatus={this.changeReadStatus}
	                currentTab={currentTab}
	                switchTab={this.switchTab}
	                isFaxConfigured={this.props.isFaxConfigured}
	                extraContacts={this.props.contactsUtil.extraContacts}
	                loadExtraContacts={this.loadExtraContacts}
	                deletedIncomingFaxes={this.state.deletedIncomingFaxes}
	                deletedOutgoingFaxes={this.state.deletedOutgoingFaxes}
	                routerProps={this.props.routerProps}
	            />
	        </div>
	    )
	}

	switchFax = (faxId = '') => {
	    if (!faxId) {
	        return this.props.routerProps.history.push(
	            `${this.props.routerProps.match.path}/${theme.faxesApp.tabs.defaultTab}/${faxId}`
	        )
	    }

	    const fax =
			this.props.faxes['fax-incoming'].items.find((f) => f.id === faxId) ||
			this.props.faxes['fax-outgoing'].items.find((f) => f.id === faxId)
	    if (!fax) faxId = ''
	    const direction = fax.direction === 'out' ? 'sent' : 'received'
	    this.props.routerProps.history.push(`${this.props.routerProps.match.path}/${direction}/${faxId}`)
	}

	switchTab = (tabDirection = theme.faxesApp.tabs.defaultTab) => {
	    // whenever you switch tabs, you must first select the first item if there is one
	    const faxDirection = tabDirection === 'sent' ? 'outgoing' : 'incoming'
	    const { items } = this.props.faxes[`fax-${faxDirection}`]
	    const fax = items[0]
	    let faxId = ''

	    // only if its not mobile, select a default
	    if (!this.props.smallView) faxId = fax ? fax.id : ''
	    this.props.routerProps.history.push(`${this.props.routerProps.match.path}/${tabDirection}/${faxId}`)
	}

	render () {
	    console.info(this.props.routerProps)
	    const { classes } = this.props
	    const sidebarHidden = this.context.mobile || this.context.tablet
	    return (
	        <div className="App">
	            <ResizeAware
	                style={{ height: this.props.standalone ? 'calc(100% - 60px)' : '100%' }}
	                onResize={this.handleResize}
	            >
	                {this.state.loading
	                    ? (
	                    <div className={classes.loadingDiv}>
	                        <LoaderFull
	                            styles={{ loaderFull: { left: sidebarHidden ? '50%' : 'calc(50% + 120px)' } }}
	                            size="big"
	                        />
	                    </div>
	                )
	                    : null}

	                <div className={classes.appWrapper}>
	                    <Route
	                        exact path={`${this.props.routerProps.match.path}`}
	                        render={() => {
	                            this.makeDefaultSelection()
	                        }}
	                    />
	                    <Route
	                        path={[
	                            `${this.props.routerProps.match.path}/sent/:fax_id?`,
	                            `${this.props.routerProps.match.path}/received/:fax_id?`,
	                            `${this.props.routerProps.match.path}/:other_route`
	                        ]}
	                        render={(props) => {
	                            const faxId = props.match.params.fax_id
	                            const otherRoute = props.match.params.other_route
	                            console.log(otherRoute)
	                            // if theres an id, details/content view should be loaded, on mobile its on or the other.
	                            if (!this.props.smallView) return this.renderFaxSelector(faxId)

	                            if (this.props.smallView && !otherRoute) {
	                                return this.renderFaxSelector(faxId)
	                            }
	                        }}
	                    />
	                    <Switch>
	                        <Route
	                            exact
	                            path={`${this.props.routerProps.match.path}/new_fax`}
	                            render={() => (
	                                <NewFaxPanel
	                                    extension={this.props.extension}
	                                    contactsUtil={this.props.contactsUtil}
	                                    loadExtraContacts={this.loadExtraContacts}
	                                    switchTab={this.switchTab}
	                                />
	                            )}
	                        />
	                        <Route
	                            path={`${this.props.routerProps.match.path}/*/:fax_id`}
	                            render={(props) => {
	                                console.info(this.props.faxes)
	                                const faxId = props.match.params.fax_id

	                                return (
	                                    <>
	                                        {faxId
	                                            ? (
											    <FaxContent
											        currentFaxId={faxId}
											        currentFax={this.state.currentFax}
											        extension={this.props.extension}
											        deleteFax={this.deleteFax}
											        changeReadStatus={this.changeReadStatus}
											        redirect={this.props.redirect}
											        extraContacts={this.props.contactsUtil.extraContacts}
											        contactGroupTypes={this.props.contactsUtil.groupTypes}
											        updateContact={this.props.contactsUtil.updateContact}
											        deleteContact={this.props.contactsUtil.deleteContact}
											        addGroup={this.props.contactsUtil.addGroup}
											        makeCall={this.props.makeCall}
											        routerProps={this.props.routerProps}
											        switchTab={this.switchTab}
											    />
	                                        )
	                                            : null}
	                                    </>
	                                )
	                            }}
	                        />
	                    </Switch>
	                </div>
	            </ResizeAware>
	            <ConfirmDeleteModal
	                itemType="fax"
	                isShown={this.state.isConfirmDeleteFaxModalShown}
	                acknowledgedTitle="Permanently delete fax?"
	                notAcknowledgedTitle="Permanently delete fax?"
	                acknowledgedMainContent=""
	                notAcknowledgedMainContent=""
	            />
	        </div>
	    )
	}
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))
