import 'core-js'
import * as React from 'react'
import VueWrapper from 'vue-wrapper'
import App from './app.vue'

/**
 *
 *
 * @param {string} page - create, show or index
 */
const getVueWrapper = function (page) {
    /**
     * The main app for the console jsx to react from vuew
     *
     * @param {object | null} props  - react props
     */
    // eslint-disable-next-line react/display-name
    return function (props) {
        return (
            <div>
                {VueWrapper({ ...props, page }, App)}
            </div>
        )
    }
}

const Statements = getVueWrapper('statements')
const PaymentMethodsCreate = getVueWrapper('create')
const PaymentMethodsIndex = getVueWrapper('index')

/**
 *
 */
export {
    Statements,
    PaymentMethodsIndex,
    PaymentMethodsCreate
}
