<template>
	<div>
		<Filters
			v-if="!call.hide_filters"
			@filtersApplied="call.loadItems()"
			@clearFilters="call.clear_filters()"
			:label_cols="label_cols"
			:button_classes="button_classes"
            :showFilters="call.filters_applied || call.keep_filters_open"
            data-test-id="call-reports-filters"
		>
			<template v-slot:fields>
				<SmartFilters
					:log="call"
					:label_cols="label_cols"
					:key="JSON.stringify(call.client_config)"
					data-test-id="call-reports-smart-filters"
				/>
				<FormInput
					v-if="['geo-distribution'].includes(call.type)"
					:label="l.t('reports.call-direction', 'Call direction')"
					:label_cols="label_cols"
				>
					<w-select
						v-model="call.filters.direction"
						@change="call.restartSavedFilterName()"
						:items="[
							{value: null,text: l.t('app.all-calls', 'All calls')},
							{value: 'in',text: l.t('app.inbound', 'Inbound')},
							{value: 'out',text: l.t('app.outbound', 'Outbound')},
						]"
						hide-details="auto"
						data-test-id="call-reports-filters-group-bu-select"
					/>
				</FormInput>
				<FormInput
					v-if="['total', 'traffic'].includes(call.type) && call.filters.type !== 'today'"
					:label="l.t('reports.group-by', 'Group by')"
					:label_cols="label_cols"
				>
					<w-select
						v-model="call.filters.group_by"
						@change="call.restartSavedFilterName()"
						:items="prepareGroupByItems()"
						hide-details="auto"
						data-test-id="call-reports-filters-group-bu-select"
						:key="`group-by-${call.filters.start}-${call.filters.end}`"
					/>
				</FormInput>
				<template v-if="call.type === 'per-extension'">
					<FormInput :label_cols="label_cols">
						<w-switch v-model="call.filters.show_all_extensions" @change="call.restartSavedFilterName()" :label="l.t('reports.show-all-extensions', 'Show all extensions')" hide-details='auto' data-test-id="call-reports-filters-show-all-exts-switch"/>
					</FormInput>
					<FormInput v-if="has_deleted_extensions()" :label_cols="label_cols">
						<w-switch v-model="call.filters.show_deleted_extensions" @change="call.restartSavedFilterName()" :label="l.t('reports.show-deleted-extensions', 'There is some data found for deleted extensions. Show it?')" hide-details='auto' data-test-id="call-reports-filters-show-deleted-exts-switch"/>
					</FormInput>
				</template>
			</template>
			<template v-slot:additionalButtons>
				<SaveFilter
					v-if="call.filters_applied && !call.saved_filter_name"
					:generate_name_callback="call.generateNewFilterName.bind(call)"
					:class="button_classes"
					@confirmed="call.save_filters(null, $event)"
					data-test-id="call-reports-save-filter"
				/>
				<template v-if="call.items">
					<w-btn
						@click.prevent="call.exportCsv()"
						type="button"
						class="primary--text text--darken-1 primary lighten-5 mr-5"
						:class="button_classes"
						color="primary"
						data-test-id="call-reports-get-csv-btn"
						>
						{{ l.t("app.get-csv", "Get CSV") }}
					</w-btn>
					<w-btn
						@click.prevent="call.exportPdf()"
						type="button"
						:loading="call.exporting_pdf"
						:class="button_classes"
						class="primary--text text--darken-1 primary lighten-5 mr-5"
						color="primary"
						data-test-id="call-reports-get-pdv-btn"
					>
						{{ l.t("app.get-pdf", "Get PDF") }}
					</w-btn>
				</template>
			</template>
		</Filters>
	</div>
</template>
<script>
import l from '../../libs/lang';
import Filters from './Filters.vue';
import FormInput from './form/FormInput.vue';
import SmartFilters from './SmartFilters.vue';
import SaveFilter from './modal/SaveFilter.vue';

export default {
	props: ['call'],
	components: {
		Filters,
		FormInput,
		SaveFilter,
		SmartFilters,
	},
	data() {
		return {
			l,
			new_filter_name: null,
			label_cols: 2,
			button_classes: ''
		};
	},
	methods: {
		has_deleted_extensions() {
			if (this.call.has_deleted_extensions) {
				if (this.call.type === 'per-extension') return true;
				if (this.call.type === 'total' && this.call.filters.group_by === 'extension') return true;
			}
			return false;
		},
		prepareGroupByItems() {
			let items = [];
			if (['total'].includes(this.$props.call.type) && this.$props.call.filters.start && this.$props.call.filters.end) {
				const start = new Date(this.$props.call.filters.start);
				const end = new Date(this.$props.call.filters.end);
				const is_one_day = end.getTime() - start.getTime() <= (24 * 60 * 60 * 1000);
				if (is_one_day) {
					items.push({value: 'minute', text: l.t('reports.minute', 'Minute')});
				}
			}
			if (!this.$props.call.exclude_hour_filter.includes(this.$props.call.filters.type)) {
				items.push({value: 'hour', text: l.t('reports.hour', 'Hour')});
			}
			items = items.concat([
				{value: 'date', text: l.t('app.date', 'Date')},
				{value: 'week', text: l.t('reports.week', 'Week')}
			]);

			if (['total'].includes(this.$props.call.type)) {
				items = items.concat([
					{value: 'extension', text: l.t('app.extension', 'Extension')},
					{value: 'npa', text: l.t('app.npa', 'NPA')},
					{value: 'direction', text: l.t('reports.direction', 'Direction (inbound/outbound)')},
				]);
			}

			return items;
		},
	}
};
</script>
