import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5628%3A777
 *
 * @param {SvgIconProps} props - svg props
 */
export const VolumeDownIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M18.5 12C18.5 10.5199 17.7868 9.21465 16.6878 8.39408C16.3899 8.17171 16 8.40733 16 8.77902V15.2159C16 15.5864 16.3878 15.8222 16.6856 15.6017C17.7858 14.7871 18.5 13.4811 18.5 12ZM5.5 9C5.22386 9 5 9.22386 5 9.5V14.5C5 14.7761 5.22386 15 5.5 15H8.17157C8.70201 15 9.21071 15.2107 9.58579 15.5858L13.1464 19.1464C13.4614 19.4614 14 19.2383 14 18.7929V5.20711C14 4.76165 13.4614 4.53857 13.1464 4.85355L9.58579 8.41421C9.21071 8.78929 8.70201 9 8.17157 9H5.5Z' fill={color}/>
        </SvgIcon>
    )
}

export default VolumeDownIcon
