<template>
  <div class="live-answer">
    <LiveReceptionistIndex
      v-if="$route.name === 'live-answer.index'"
      data-test-id="live-answer-index"
    />
    <LiveReceptionistCreate
      v-if="$route.name === 'live-answer.create'"
      data-test-id="live-answer-create"
    />
    <LiveReceptionistShow
      v-if="$route.name === 'live-answer.show'"
      data-test-id="live-answer-show"
    />
  </div>
</template>

<script>
  import l from '../../../libs/lang';
  import { LiveReceptionistIndex, LiveReceptionistCreate, LiveReceptionistShow } from 'live-receptionist-components';

  export default {
    components: {
      LiveReceptionistIndex,
      LiveReceptionistCreate,
      LiveReceptionistShow,
    },
    beforeRouteLeave(to, from, next) {
      if (window.deleting_in_progress) return this.$emit('userRedirected', to, from, next);
      next();
      return true;
    },
    data() {
      return {
        l,
      };
    },
  };
</script>

<style scoped>

</style>
