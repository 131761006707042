import { theme } from 'get-theme'

//pick a color based on last 2 digits of number
export function getColorByNumber(number) {
	let colorIndex = parseInt(number, 10) % theme.messagesApp.profileImageColorList.length
	// had issues where theme was not always avaible through context imported directly
	return theme.messagesApp.profileImageColorList[colorIndex]
}

export function iconBackgroundStyle(numbers) {
    if (numbers.length === 0)
        return {backgroundColor: getColorByNumber(0)}
    if (numbers.length === 1)
        return {backgroundColor: getColorByNumber(numbers[0])}
    else {
		return {background: theme.messagesApp.profileGroupImageColor}
    }
}

export default { getColorByNumber, iconBackgroundStyle }