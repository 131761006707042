import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { updatePhoneNumber } from '../../actions/phone-numbers'
import { formatPhoneNumber } from 'phone-numbers'
import DropdownMenu from 'dropdown-menu'
import { Switch } from 'switch'
import styles from './myNumbersStyles'
import { Tooltip, withStyles } from '@material-ui/core'
import Options from 'pdc-options'
import TableMui from 'pdc-table-mui'
import { EditIcon } from 'svg-icons'
import PhoneNumberName from './PhoneNumberName'

const mapStateToProps = state => ({
    phoneNumbers: state.phoneNumbers
})

const mapDispatchToProps = dispatch => ({
    updatePhoneNumber: phoneNumber => dispatch(updatePhoneNumber(phoneNumber))
})

class MyNumbersLarge extends Component {
    getUserName = extensionId => {
        const user = this.props.users.find(user => user.extension.id === extensionId)
        return user ? `${user.first_name} ${user.last_name}` : ''
    }

    optionsDropdownButton = props => {
        return (
            <div {...props}>
                <Options data-button='options' variant='vertical'/>
            </div>
        )
    }

    renderPhoneNumber = phoneNumber => {
        const { classes } = this.props
        const formattedNumber = formatPhoneNumber(phoneNumber.phone_number)
        return (
            <div className={classes.phoneNumber}>
                {formattedNumber}
            </div>
        )
    }

    renderInboxColumn = phoneNumber => {
        const { classes } = this.props
        let extensionText = 'Unassigned'
        const companyExtension = this.props.companyExtension

        if (phoneNumber?.sms_forwarding?.extension) {
            if (!companyExtension) extensionText = ''
            else if (phoneNumber.sms_forwarding.extension.id === companyExtension.extension_id) {
                extensionText = 'Company Inbox'
            } else {
                const extensionNumber = phoneNumber.sms_forwarding.extension.extension
                const extensionName = phoneNumber.sms_forwarding.extension.name
                if (extensionNumber) extensionText = `EXT: ${extensionNumber} - ${extensionName}`
            }
        }

        return <div className={`${classes.inboxCell} ${extensionText === 'Unassigned' ? 'gray' : ''}`}>{extensionText}</div>
    }

    renderToggleColumn = phoneNumber => {
        const { classes } = this.props
        return (
            <div className={classes.pdcToggleWrapper}>
                <Switch
                    checked={Boolean(phoneNumber.record_calls)}
                    onChange={() => this.props.onToggleCallRecordingClick(phoneNumber)}
                    name='company-name-section-switch'
                    value={`switch-${phoneNumber.id}`}
                />
            </div>
        )
    }

    renderEditColumn = phoneNumber => {
        const { classes } = this.props
        return (
            <Tooltip title="Set your preferences for welcoming and handling callers" enterDelay={500}>
                <div className={classes.editNumberColumn}onClick = {() => this.props.onConfigureNumberClick(phoneNumber)}>
                    <div className='edit-button'><EditIcon/></div>
                </div>
            </Tooltip>
        )
    }

    renderOptionsColumns = phoneNumber => {
        return (
            <DropdownMenu
                button = {this.optionsDropdownButton}
                dropdownId = {phoneNumber.id}
                menuItems = {this.props.getDropdownItems(phoneNumber)}
                dropdownStyle = 'configure'
            />
        )
    }

    generateTableHeadColumns = () => {
        const headColumns = [
            { content: 'Number', testId: 'head-number-col' },
            { content: 'Name', testId: 'head-name-col' },
            { content: 'Action', testId: 'head-action-col' },
            { content: 'Inbox', testId: 'head-inbox-col' },
            !this.props.hideCallRecording && { content: 'Record', testId: 'head-record-col' },
            { content: 'Edit', testId: 'head-edit-col' },
            { content: 'Options', testId: 'head-options-col' }
        ].filter(Boolean)
        return headColumns
    }

    generateTableRows = () => {
        const rows = this.props.phoneNumbers.items.map(phoneNumber => {
            return this.generateOneRow(phoneNumber)
        })
        return rows
    }

    generateOneRow = (phoneNumber) => {
        const columns = [
            { key: 0, content: this.renderPhoneNumber(phoneNumber), primary: true, stringValue: formatPhoneNumber(phoneNumber.phone_number), testId: 'number' },
            { key: 1, content: <PhoneNumberName phoneNumber={phoneNumber} updatePhoneNumber={this.props.updatePhoneNumber} />, testId: 'name' },
            { key: 2, content: this.props.renderAction(phoneNumber), testId: 'action' },
            { key: 3, content: this.renderInboxColumn(phoneNumber), testId: 'inbox' },
            !this.props.hideCallRecording && { key: 4, content: this.renderToggleColumn(phoneNumber), testId: 'toggle' },
            { key: 5, content: this.renderEditColumn(phoneNumber), testId: 'edit' },
            { key: 6, content: this.renderOptionsColumns(phoneNumber), testId: 'options' }
        ].filter(Boolean)
        return { key: phoneNumber.id, columns }
    }

    render = () => {
        const { classes } = this.props
        return (
            <div className={classes.mainWrapper}>
                <TableMui
                    headColumns = {this.generateTableHeadColumns()}
                    rows = {this.generateTableRows()}
                    infiniteScroller = {true}
                    reverseScroll = {false}
                    loadMore = {this.props.loadMore}
                    hasMore = {this.props.hasMore}
                    listView = {false}
                    dataAttr = {'my-numbers-table'}
                />
            </div>
        )
    }
}

MyNumbersLarge.propTypes = {
    users: PropTypes.array,
    classes: PropTypes.object,
    phoneNumbers: PropTypes.object,
    loadMore: PropTypes.func,
    hasMore: PropTypes.bool,
    companyExtension: PropTypes.object,
    updatePhoneNumber: PropTypes.func,
    renderAction: PropTypes.func,
    onToggleCallRecordingClick: PropTypes.func,
    onConfigureNumberClick: PropTypes.func,
    getDropdownItems: PropTypes.func,
    hideCallRecording: PropTypes.bool
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MyNumbersLarge))
