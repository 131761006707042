export function updateBridgeId(bridgeId) {
	return {
		type: 'UPDATE_BRIDGE_ID',
		bridgeId
	}
}

export function updateSelectedExtensionPhoneNumbersFeatures(features) {
	return {
		type: 'UPDATE_SELECTED_EXTENSION_PHONE_NUMBERS_FEATURES',
		features
	}
}

export function switchExtension(extension) {
	return {
		type: 'SWITCH_EXTENSION',
		extension
	}
}