<template>
	<w-autocomplete
		ref="time_selector"
		v-bind="$attrs"
		v-on="$listeners"
		v-model="time"
		:items="sorted_items"
		@keydown="keydown"
		:search-input.sync="$data.typing_value"
		:no-data-text="error ? l.t('app.timexx:xx AM', 'Correct time format: 11:22 AM') : undefined"
		class="custom-time-selector"
		hide-details="auto"
		@blur="blur"
		@focus="focus"
		:disabled="disabled"
		:class="{'active-input' : active , 'disabled' : disabled }"
		data-test-id="time-selector"
	>
		<template v-if="icons" v-slot:prepend-inner>
			<v-icon :color="active ? 'primary' : 'text'" class="mt-2">$vuetify.icons.clock</v-icon>
		</template>
	</w-autocomplete>
</template>

<script>
import l from '../../../libs/lang';
// accepts 24h format 23:59
// returns the same format
export default {
	props: {
		disabled: {
            type: Boolean,
            default: false,
        },
		value: {
			type: String,
			required: false,
		},
		default_value: {
			type: String,
			required: false,
		},
		min_step: {
			type: Number,
			default: 30,
		},
		icons: {
			type: Boolean,
			default: true,
		}
	},
	data() {
		return {
			l,
			time: this.$props.disabled ? null : this.$props.value || this.$props.default_value,
			error: null,
			items: [],
			active: false,
		};
	},
	created() {
		this.$data.items = this.hours();
		const val = this.$data.items.find((x) => x.value === this.$data.time);
		this.$data.typing_value = val ? val.text : '';
		this.$emit('input', this.$data.time);
	},
	methods: {
		focus(e) {
			this.$data.active = true;
			this.$emit('focus', e);
		},
		blur(data) {
			this.set_typing_value();
			this.$data.active = false;
			if (!this.$data.typing_value && this.$data.time) {
				this.$data.typing_value = (this.$data.items.find((x) => x.value === this.$data.time)).text;
				this.$forceUpdate();
			}
			if (!this.$data.time) {
				this.$data.time = this.$props.default_value;
			}
			this.$emit('input', this.$data.time);
			this.$emit('blur', data);
		},
		keydown(event) {
			if (event.keyCode === 13) {
				event.preventDefault();
				setTimeout(this.set_typing_value, 5);
			}
			this.$emit('keydown', event);
		},
		set_typing_value() {
			if (this.$data.typing_value) {
				const time = this.$data.items.find((x) => x.text === this.$data.typing_value);
				if (time) {
					this.$data.time = time.value;
					this.$emit('input', this.$data.time);
					this.$refs.time_selector.$children[0].blur();
				}
			}
		},
		get_12_hours(interval) {
			let hours = 0;
			let minutes = 0;
			const values = [];
			for (let i = 0; i < 12 * 60; i += interval) {
				const h = hours === 0 ? 12 : hours;
				const display_minutes = this.add_zero_if_needed(minutes);
				const display_hours = this.add_zero_if_needed(h);
				values.push({
					hours,
					minutes,
					display_hours,
					display_minutes,
					text: `${display_hours}:${display_minutes}`,
				});
				minutes += interval;
				if (minutes >= 60) {
					hours++;
					minutes = 0;
				}
			}
			return values;
		},
		add_zero_if_needed(val) {
			return val <= 9 ? `0${val}` : val;
		},
		hours(intrvl) {
			const interval = intrvl || this.$props.min_step;
			const items = [];
			const hours = this.get_12_hours(interval);
			for (const meridiem of ['AM', 'PM']) {
				hours.map((hour) => {
					const time_24 = meridiem === 'AM' ? hour.hours : hour.hours + 12;
					items.push({
						value: `${this.add_zero_if_needed(time_24)}:${hour.display_minutes}`,
						text: `${hour.text} ${meridiem}`,
					});
					return true;
				});
			}
			if (this.$data.time && !items.find((x) => x.value === this.$data.time)) {
				const time = this.transform_to_12_format(this.$data.time);
				items.unshift({
					value: this.$data.time,
					text: time,
				});
			}
			return items;
		},
		transform_to_12_format(time) {
			return new Date(`1970-01-01T${time}:00Z`).toLocaleTimeString(
				'en-US',
			    {
					timeZone: 'UTC',
					hour12: true,
					hour: '2-digit',
					minute: '2-digit',
			    }
			  );
		},
		transform_to_24_format(time) {
			let hours = Number(time.match(/^(\d+)/)[1]);
			let minutes = Number(time.match(/:(\d+)/)[1]);
			if (hours > 12) {
				this.$data.error = true;
				return null;
			}
			const AMPM = time.slice(-2);
			if (AMPM.toLowerCase() === 'pm' && hours < 12) hours += 12;
			if (AMPM.toLowerCase() === 'am' && hours === 12) hours -= 12;
			hours = this.add_zero_if_needed(hours);
			minutes = this.add_zero_if_needed(minutes);
			return `${hours}:${minutes}`;
		},
	},
	computed: {
		sorted_items() {
			const items = [...this.$data.items];
			items.sort((a, b) => {
				if (a.value > b.value) return 1;
				if (a.value < b.value) return -1;
				return 0;
			});
			return items;
		},
	},
	watch: {
		disabled(val) {
            this.time = val ? null : this.$props.value || this.$props.default_value;
        },
		typing_value(val) {
			this.$data.error = false;
			const reg = /^([0-1][0-9]):[0-5][0-9]\s*[ap]m$/i;
			const regex_test = reg.test(val);
			this.$data.error = !regex_test;
			if (regex_test) {
				const time = this.transform_to_24_format(val);
				if (time && !this.$data.items.find((x) => x.value === time)) {
					this.$data.items.unshift({
						text: val,
						value: time,
						custom_value: true,
					});
				}
			}
		}
	}
};
</script>

<style lang="scss">
	.custom-time-selector {
		&.v-text-field .v-input__append-inner {
			padding-left: 0 !important;
		}
		&.v-text-field input {
			padding-bottom: 10px !important;
			letter-spacing: 0px;
		}
		.v-input__icon .v-input__icon--append {
			margin-right: 0 !important;
		}
		.v-input__append-inner {
			.primary--text { // arrows goes out of the input box
				display: none !important;
			}
		}
	}
	.disabled {
        opacity: 0.5;
	}
</style>
