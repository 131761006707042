import l from '../lang';

export default {
	'routes': {
		'index': 'call-logs',
	},
	'delete-single': l.t('call-logs.delete-single', 'Are you sure you want to delete this log?'),
	'delete-filtered': l.t('call-logs.delete-filtered', 'Are you sure you want to delete all filtered call logs?'),
	'delete-all': l.t('call-logs.delete-all', 'Are you sure you want to delete all call logs?'),
	'successfull-creation': l.t('call-logs.successfully-created', 'Your log has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('call-logs.failed-creation', 'There was a problem creating your log'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
};
