var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"messages"},[_c('Submenu',{key:("" + (_vm.l.detect()) + (_vm.$route.name)),attrs:{"routes":{
      'messages.index': {
        title: _vm.l.t('app.messages', 'Messages')
      },
      'messages.create': {
        title: _vm.l.t('messages.send-message', 'Send message')
      },
    }}}),_vm._v(" "),(_vm.$route.name === 'messages.index')?_c('MessagesFragment',{attrs:{"type":"greetings","data-test-id":"messages-list"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'messages.create')?_c('AddMessagesFragment',{attrs:{"data-test-id":"messages-create"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }