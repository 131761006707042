import SMS from './src/components/screens/SMS.vue'
import Types from './src/components/screens/Types.vue'
import Total from './src/components/screens/Total.vue'
import Summary from './src/components/screens/Summary.vue'
import Traffic from './src/components/screens/Traffic.vue'
import CallLogs from './src/components/screens/CallLogs.vue'
import PerNumber from './src/components/screens/PerNumber.vue'
import PerExtension from './src/components/screens/PerExtension.vue'
import GeoDistribution from './src/components/screens/GeoDistribution.vue'

/**
 *
 */
export {
    SMS,
    Types,
    Total,
    Summary,
    Traffic,
    CallLogs,
    PerNumber,
    PerExtension,
    GeoDistribution
}
