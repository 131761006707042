export function switchSetting(setting) {
	return {
		type: 'SWITCH_SETTING',
		setting
	}
}

export function updateCurrentPrimaryCard(currentPrimaryCard) {
	return {
		type: 'UPDATE_CURRENT_PRIMARY_CARD',
		currentPrimaryCard
	}
}

export function updateInvoices(invoices) {
	return {
		type: 'UPDATE_INVOICES',
		invoices
	}
}

export function updateVoicemail(voicemail) {
	return {
		type: 'UPDATE_VOICEMAIL',
		voicemail
	}
}

export function updateEmail(email) {
	return {
		type: 'UPDATE_EMAIL',
		email
	}
}

export function updatePhoneNumber(phoneNumber) {
	return {
		type: 'UPDATE_PHONE_NUMBER',
		phoneNumber
	}
}