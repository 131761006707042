<template>
  <div>
  <IndexPage
    :resource="queue"
    class="queues list-page"
    data-test-id="queues-list-page"
  >
    <template v-slot:filters>
      <FormInput :label="l.t('app.name', 'Name')">
        <w-text-field v-model="queue.filters.name" hide-details='auto' data-test-id="queues-filters-name"/>
      </FormInput>
    </template>
    <template v-slot:list>
      <v-simple-table class="list-table" data-test-id="queues-table">
        <thead data-test-id="table-head">
          <tr data-test-id="head-row">
            <th data-test-id="checkbox"></th>
            <th data-test-id="name">{{l.t('app.name', 'Name')}}</th>
            <th data-test-id="members">{{l.t('queues.members', 'Members')}}</th>
            <th data-test-id="more-options"></th>
          </tr>
        </thead>
        <tbody data-test-id="table-head">
          <tr
            v-for="(item, i) in queue.items"
            :key="item['id']"
            :class="{'selected-row': item.selected}"
            :data-test-id="`queues-item-${item.id}-row`"
          >
            <td class="select-table-item" data-test-id="checkbox">
              <w-checkbox
                v-model="item.selected"
                @click="checked($event, item)"
                :key="`queues-item-${item.id}-${item.selected}`"
                hide-details="auto"
                :data-test-id="`queues-item-${item.id}-checkbox`"
              />
            </td>
            <td class="nowrap overflow-hidden" data-test-id="description">
              <IndexListItem
                :route="{
                  name: 'queues.show',
                  params: {
                    id: item.id,
                    _queue: item,
                  },
                }"
                :item="item"
                :title="item.name"
                :key="item['id']"
                :data-test-id="`queues-item-${item.id}-title`"
              />
            </td>
            <td class="w-100" :data-test-id="`queues-item-${item.id}-members`">
              #{{ item['id'] }}: {{ item.members.length }} {{ item.members.length  === 1 ? l.t('queues.member', 'member') : l.t('queues.memebers', 'members') }}
            </td>
            <td data-test-id="more-options">
              <MoreOptions :options="more_options(item)" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </template>
  </IndexPage>
  <ConfirmDeletion
      :value="!!deleting_item_id"
      @closed="deleting_item_id = null"
      @confirmed="queue.delete_item(deleting_item_id)"
    />
  </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import Queue from '../../../models/Queue';
  import IndexPage from '../../elements/IndexPage.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import IndexListItem from '../../elements/IndexListItem.vue';
  import MoreOptions from '../../elements/MoreOptions.vue';
  import ConfirmDeletion from '../../elements/modal/ConfirmDeletion.vue';

  export default {
    components: {
      IndexPage,
      FormInput,
      IndexListItem,
      MoreOptions,
      ConfirmDeletion
    },
    data() {
      return {
        l,
        deleting_item_id: null,
        queue: new Queue(this.$session, vueComponent(this)),
      };
    },
    mounted() {
      this.$parent.$on('userRedirected', (to, from, next) => {
        this.$data.queue.handleUserRedirect(to, from, next);
      });
    },
    methods: {
      more_options(item) {
        return [
          {
            cb: () => this.$router.push({
              name: 'queues.show',
              params: {
                id: item.id,
                _queue: item,
              },
            }),
            title: l.t('app.edit', 'Edit')
          },
          {
            cb: () => this.$data.deleting_item_id = item.id,
            title: l.t('app.delete', 'Delete'),
          }
        ];
      },
      checked(event, item) {
        this.$data.queue.checked(event, item);
        this.$emit('forceUpdate');
      }
    },
  };
</script>
<style scoped>
    #app #main-container > main .content-container > ul {
        margin-bottom: 0;
    }
</style>
