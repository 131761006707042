import { Styles } from '@material-ui/core/styles/withStyles'
type StylesType = Styles<any, Record<string, unknown>>

/**
 * @param {object} theme
 */
const styles: StylesType = theme => ({
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 12,
        paddingLeft: '64px',
        '& > button': {
            '&:first-child': {
                gridColumnStart: 2,
                '& svg': {
                    fontSize: 30
                }
            },
            '&:nth-child(2)': {
                gridColumnStart: 3,
                '& svg': {
                    fontSize: 34
                }
            }
        }
    },
    callButton: {
        width: 60,
        height: 60,
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.primary.light
        },
        '&.Mui-disabled': {
            color: theme.palette.text.disabled,
            backgroundColor: theme.palette.action.disabledBg
        },
        margin: '0px 20px'
    },
    removeCharButton: {
        backgroundColor: 'transparent',
        '& svg': {
            color: theme.palette.secondary.main
        },
        '&:hover, &:focus, &:focus-visible': {
            backgroundColor: 'transparent'
        },
        '&.Mui-disabled': {
            backgroundColor: 'transparent',
            '& svg': {
                color: theme.palette.action.disabledBg
            }
        }
    },
    removeIconDisabled: {
        color: theme.palette.text.disabled
    },
    inputFieldWrapper: {
        position: 'relative',
        top: '40px'
    },
    selectorCallButtonWrapper: {
        display: 'flex',
        color: theme.palette.primary.main,
        height: '40px',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: 2,
        padding: '0px 20px',
        maxWidth: '300px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.action.primaryFocus
        },
        '& > svg': {
            fontSize: 18
        }
    }
})

export default styles
