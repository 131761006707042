export function parseContacts(contacts, currentExtension) {
	return contacts.filter(c => c.extension_id.toString() === currentExtension.toString()).map(c => {
		let contact = {};
		contact.id = c.id;
		contact.firstName = c.first_name || '';
		contact.lastName = c.last_name || '';
		contact.nickname = c.nickname || '';
		contact.displayName = c.display_name || '';
		contact.phoneNumbers = c.phone_numbers.reduce((obj, number) => {
			obj[number.type] = number.normalized;
			return obj;
		}, {});
		return contact;
	})
};