import React from 'react'
import formatPhoneNumber from '../util/phone_number_format';

const dropdownExtensionItem = (props) => {

	let item = props.item;

	if (!(item.phone_number instanceof Array))
		item.phone_number = [item.phone_number];

	return(
		<div className={'dropdown-extension-item' + (props.selected ? ' active' : '')} onClick={props.onClick}>
			<div className='item-labels'>
				<div>Extension:</div>
				<div>Name:</div>
				<div>Phone Number:</div>
			</div>
			<div className='item-values'>
				<div className='dropdown-extension fs-block'>{`#${item.extension}`}</div>
				<div className='dropdown-extension-name fs-block'>{`${item.extension_name}`}</div>
				<div className='dropdown-phone-number'>
					{item.phone_number.map((number, i) => {
						return (
						<div
							key={i}
							className={item.caller_id && i === 0 ? 'extension-caller-id' : ''}
							title={item.caller_id && i === 0 ? 'Outbound Caller ID' : ''}
							>
							<i className='fa fa-phone'></i>
							<span className='phone-number-text fs-block'> {number ? formatPhoneNumber(number) : 'N/A'}</span>
						</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default dropdownExtensionItem;