import React, {Component} from 'react';
import {CardElement, injectStripe} from 'react-stripe-elements';
import api from '../../util/api_v5'
import Button from '@material-ui/core/Button'
import Spinner from 'spinner'
import {withStyles} from "@material-ui/core";


const styles = theme => ({
  cardElementWrapper: {
    padding: '5px 0px 20px 0px'
  },
  buttonWrapper: {
    textAlign: 'center',
    paddingBottom: '10px'
  },
	errorMessage: {
		color:			'#df1d08',
		margin:			'0px 0px 10px 0px',
		fontSize:		14,
		fontWeight:		'bold',
		lineHeight:		1.29,
		letterSpacing:	-0.1,
		textAlign:		'center'
	},
	updateButton: {
		color:	'#1140A1',
		border:	'1px solid #1140A1',
		'&:hover': {
			color:				'white',
			backgroundColor:	'#1140A1',
			borderColor:		'#1140A1'
		},
		'&:focus': {
			outline: 0
		}
	}
});


class CreditCardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: '',
      complete: false,
      awaitingStripeResponse: false
    };
    this.submit = this.submit.bind(this);
  }

  async submit(event) {
    // User clicked submit
    let {token} = await this.props.stripe.createToken({name: "Name"});
    if (!token) {
      return;
    }

    this.setState({awaitingStripeResponse: true})

    const updateCardResponse = await api.updatePrimaryCard(token.id);
    this.setState({awaitingStripeResponse: false})
    if (updateCardResponse.success) {
      this.setState({complete: true});
      this.props.onSuccess();
    } else {
      this.setState({errorMessage: getCustomDeclineMessage(updateCardResponse.error)})
    }
  }

  render() {

    const { classes } = this.props;

    if (this.state.complete) return (
      <div className="checkout">
        <p>Your card has been updated successfully</p>
      </div>
    );

    if (this.state.awaitingStripeResponse) {
      return (<div className="checkout">
        <p>You can update your card below:</p>
        <div className={`${classes.buttonWrapper}`}>
          <Spinner/>
        </div>
      </div>);
    }
    else {
      return (<div className="checkout">
        <p>You can update your card below:</p>
        <div className={`${classes.cardElementWrapper}`}>
         <CardElement />
        </div>
        {this.state.errorMessage ? <div className={classes.errorMessage}>{this.state.errorMessage}</div> : null}
        <div className={`${classes.buttonWrapper}`}>
          <Button
            onClick={this.submit}
            variant="outlined"
            classes={{root: classes.updateButton}}
          >Update Credit Card</Button>
        </div>
      </div>);
    }
  }
}

function getCustomDeclineMessage(decline_code) {
    if (decline_code === "insufficient_fun" || decline_code === "insufficient_funds") {
        return("This card has insufficient funds");
    }
    else if (decline_code === "call_issuer" || decline_code === "do_not_honor") {
        return("This card was declined by your card issuer.  Call them at the number on the card to allow the charge from Phone.com, then retry your card.");
    }
    else if (decline_code === "incorrect_cvc") {
        return("The CVC code doesn't match");
    }
    else if (decline_code === "incorrect_zip") {
        return("The Zip Code doesn't match");
    }
    else if (decline_code === "expired_card") {
        return("This card has expired");
    }
    else if (decline_code === "prepaid_card_not_allowed") {
        return("We do not accept prepaid cards");
    }
    else {
        return("Your card was declined by your card issuer. Please contact them, or, enter another card.");
    }
}

export default withStyles(styles)(injectStripe(CreditCardForm));