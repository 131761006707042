<template>
    <w-loader v-if="loading_global"/>
    <div v-else class="mobile-apps-container" data-test-id="mofile-apps-index">
      <div class="info--text text--darken-1 w-h6 mb-10">{{ l.t('mobile-apps.title', 'Use your phone number on your mobile') }}</div>

      <div class="apps-container d-flex mx-auto">
        <div v-for="(link, os) in $branding.data.mobile" :key="os" class="os-container">
          <div class="os-image-container">
            <a :href="link" target="_blank">
              <v-img :src="images[os]" :alt="os" :data-test-id="`mobile-apps-${os}`"></v-img>
            </a>
          </div>
            <div
              @click="prompt_question = link"
              class="w-btn-base send-link-by-sms"
              :data-test-id="`mobile-apps-${os}-link`"
              :class="{'disabled-send-sms': loading}"
            >
              {{ l.t('mobile-apps.send-link', 'Send link by SMS') }} <v-icon color="white" size="18">$vuetify.icons.chevron_forward</v-icon>
            </div>
            <FormModal v-if="prompt_question === link" v-model="prompt_question_modal" @close="prompt_closed" width="500">
              <template v-slot:title>{{l.t('app.phone-number', 'Phone number')}}</template>
              <div v-if="first_phone_number">{{ l.t('mobile-apps.send-link-question', 'What is your cellphone number?') }}</div>
              <div v-if="first_phone_number">
                <Tel
                  :value="receiver_phone_number"
                  @changed="receiver_phone_number = $event.clean_number"
                  class="w-100"
                  data-test-id="mobile-apps-modal-tel-input"
                />
              </div>
              <div v-if="!first_phone_number">
                {{l.t('app.you-dont-have-any-phone-number-available', 'You don\'t have any phone number available')}}
              </div>
              <template v-slot:buttons>
                <w-btn @click="prompt_question_modal = false" color="secondary">{{l.t('app.close', 'Close')}}</w-btn>
                <w-btn v-if="first_phone_number" @click="send_sms(link, receiver_phone_number)" color="primary">{{l.t('app.send', 'Send')}}</w-btn>
              </template>
            </FormModal>
        </div>
      </div>
      <div>
        <w-alert v-if="alert" :message="alert.message" :level="alert.level" class="mb-6" />

        <ClassicOrNxt user="classic" tag="div">
          <div class="info--text text--darken-1 w-body-1 mb-1">
            {{ l.t('mobile-apps.extension-login-credentials', 'Extension login credentials') }}
          </div>

          <div class="w-body-2 mb-6">
            {{ l.t('mobile-apps.use-credentials', 'Use the following credentials to log in to your mobile app')}}
          </div>
          <div class="apps-credentials" data-test-id="mobile-apps-extension">
            <div class="credentials-label w-body-2">
              {{l.t('app.extension', 'Extension')}}
            </div>
            <ExtensionSelector
              v-model="mobile_extension"
              :return_object="true"
              :preselect_default="true"
              :no_update="true"
              data-test-id="mobile-apps-extension-selector"
            />
          </div>
        </ClassicOrNxt>

        <w-loader v-if="loading" size="small" />

        <template v-else-if="mobile_extension" data-test-id="mobile-apps-external-data">
          <div class="apps-credentials" data-test-id="mobile-apps-phone-number">
            <div class="credentials-label w-body-2">
              {{ l.t('app.phone-number', 'Phone number') }}
            </div>
            <div class="w-body-2">
              <span data-test-id="mobile-apps-caller-id">{{ format_number(phone_number_to_show) }}</span>
            </div>
          </div>

          <div class="apps-credentials" data-test-id="mobile-apps-extension-2nd">
            <div class="credentials-label w-body-2">
            {{ l.t('app.extension', 'Extension') }}
            </div>
            <div class="w-body-2">
              {{ mobile_extension.extension || `${l.t('app.ID', 'ID')}: ${mobile_extension.id}` }}
            </div>
          </div>

          <div v-if="mobile_extension.voicemail" class="apps-credentials" data-test-id="mobile-apps-pass">
            <div class="credentials-label w-body-2">
              {{ l.t('app.password', 'Password') }}
            </div>
            <div class="w-body-2">
              <a v-if="hide_password" @click="hide_password = false" data-test-id="mobile-apps-show-pass">
                {{ l.t('app.show', 'Show') }}
              </a>
              <span v-else class="fs-exclude" data-test-id="mobile-apps-password">{{ mobile_extension.voicemail.password }}</span>
            </div>
          </div>
         </template>
      </div>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import Tel from '../elements/Tel.vue';
  import ClassicOrNxt from '../elements/ClassicOrNxt.vue';
  import FormModal from '../elements/modal/FormModal.vue';
  import ExtensionSelector from '../fragments/ExtensionSelector.vue';
  import ios from '../../../../vue-apps-assets/images/mobile/ios.svg'
  import android from '../../../../vue-apps-assets/images/mobile/android.svg'

  export default {
    components: {
        Tel,
        FormModal,
        ClassicOrNxt,
        ExtensionSelector,
    },
    data() {
      return {
        l,
        images: {ios, android},
        loading_global: false,
        loading: false,
        mobile_extension: null,
        hide_password: true,
        prompt_question: null,
        prompt_question_modal: false,
        receiver_phone_number: null,
        first_phone_number: null,
        phone_number_to_show: null,
        alert: null,
        mdi_icons: {
            android: 'mdi-google-play',
            ios: 'mdi-apple',
        },
      };
    },
    async created() {
      this.$data.first_phone_number = await this.get_first_number_available();
      if (
        this.$data.mobile_extension
        && typeof this.$data.mobile_extension === 'object'
        && this.$data.mobile_extension.id
        && !this.$data.mobile_extension.extension // object with id prop only
      ) {
        this.$data.mobile_extension = await this.$session.get_item(`/extensions/${this.$data.mobile_extension.id}`);
      }
    },
    methods: {
      format_number(num) {
        if (num) return this.$options.filters.formatPhoneNumber(num);
        return l.t('app.no-phone-num-available', 'No phone numbers available');
      },
      async get_first_number_available() {
        const first_number = await this.$session.get_list('/phone-numbers', 1);
        if (!first_number.items.length) {
          return null;
        }
        return first_number.items[0].phone_number;
      },
      async send_sms(link, receiver_phone_number) {
        this.$data.prompt_question = null;
        if (receiver_phone_number) {
          this.$data.loading_global = true;
          try {
            const sender_phone_number = this.$data.first_phone_number || await this.get_first_number_available();
            await this.$session.create_item('/sms', {
              from: sender_phone_number,
              to: receiver_phone_number,
              text: `${l.t('mobile-apps.send-link-sms', 'Application link')}: ${link}`,
            });
            this.$data.alert = {
              'message': l.t('mobile-apps.message-sent', 'Message sent'),
              'level': 'success',
            };
          } catch (e) {
            console.log(e);
            this.$data.alert = {
              'message': l.t('app.generic-error', 'Something wen wrong'),
              'level': 'error',
            };
          }
          this.loading_global = false;
          this.$data.receiver_phone_number = null;
        }
      },
      prompt_closed() {
        this.$data.prompt_question = null;
        this.$data.receiver_phone_number = null;
      },
    },
    watch: {
      async mobile_extension(value) {
        if (value) {
          this.$data.hide_password = true;
          this.$data.phone_number_to_show = null;
          if (value.caller_id !== 'private') {
            this.$data.phone_number_to_show = value.caller_id;
          } else {
            this.$data.first_phone_number = this.$data.first_phone_number || await this.get_first_number_available();
            this.$data.phone_number_to_show = this.$data.first_phone_number;
          }
          this.$data.loading = false;
        }
      },
      // eslint-disable-next-line object-shorthand
      alert: function (val) {
        if (val) setTimeout(() => this.$data.alert = null, 5000);
      },
      prompt_question(val) {
        if (this.$data.loading) return null;
        this.$data.prompt_question_modal = !!val;
      }
    },
  };
</script>
<style lang="scss">
.mobile-apps-container {
  width: 520px;
  .os-container {
    height: 128px;
    width: 250px;
    background: white;
    border: 1px solid rgba(133, 146, 154, 0.25);
    border-radius: 10px;
    margin-bottom: 40px;
    &:not(:first-child) {
      margin-left: 20px;
    }
    .os-image-container {
      height: 98px;
      display: flex;
      justify-content: space-around;
      padding-top: 20px;
    }
    .send-link-by-sms {
      background: var(--v-primary-base);
      cursor: pointer;
      color: white;
      height: 34px;
      align-items: center;
      border-radius: 0 0 10px 10px;
      padding: 8px 0 8px 15px !important;
      &.disabled-send-sms {
        background: var(--v-action-lighten2);
        color: var(--v-text-lighten3);
      }
    }
  }
  .apps-credentials {
    display: flex;
    margin-bottom: 15px;
    .selector-wrapper {
      width: 300px;
    }
    .credentials-label {
      width: 115px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
</style>
