var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-test-id":"csv-imported"}},[_c('div',{staticClass:"status-alerts mb-6"},[(_vm.items.find(function (x) { return x.status === 'success'; }))?_c('w-alert',{attrs:{"level":"success","message":_vm.l.t(
				'app.csv-successes',
				'Successes: {}/{}',
				[_vm.items.filter(function (x) { return x.status === 'success'; }).length, _vm.items.length]
			),"closable":false,"data-test-id":"csv-imported-success-alert"}}):_vm._e(),_vm._v(" "),(_vm.items.find(function (x) { return x.status === 'failed'; }))?_c('w-alert',{attrs:{"level":"error","message":_vm.l.t(
				'app.csv-fails',
				'Failed: {}/{}',
				[_vm.items.filter(function (x) { return x.status !== 'success'; }).length, _vm.items.length]
			),"closable":false,"data-test-id":"csv-imported-error-alert"}}):_vm._e()],1),_vm._v(" "),_c('LongTable',{attrs:{"items":_vm.items,"data-test-id":"csv-imported-table"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('th',[_vm._v(_vm._s(_vm.l.t('app.name', 'Name')))]),_vm._v(" "),_c('th',[_vm._v(_vm._s(_vm.l.t('app.message', 'Message')))]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.l.t('app.success', 'Success')))])]},proxy:true},{key:"body",fn:function(){return _vm._l((_vm.items),function(item){return _c('tr',[_c('td',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('td',[_vm._v(_vm._s(item.message))]),_vm._v(" "),_c('td',{staticClass:"text-center",class:{'text-success': item.status === 'success', 'text-error': item.status !== 'success'}},[_vm._v("\n\t\t\t\t\t"+_vm._s(item.status === 'success' ? '✓' : '✖')+"\n\t\t\t\t")])])})},proxy:true}])}),_vm._v(" "),_c('div',{staticClass:"bottom-buttons",attrs:{"data-test-id":"csv-imported-btns"}},[_vm._t("commands")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }