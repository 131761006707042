<template>
	<w-loader v-if="loading" />
	<div v-else class="buy-device-shipping-details">
		<div class="w-h5 main-title text-center step-title mb-5">
			{{ $lang.t('devices.where-do-you-want-to-ship-devices', 'Where do you want to ship your devices?')}}
		</div>
		<div class="content mx-auto">
			<div class="w-h6 info--text text--darken-1">
				{{ $lang.t('devices.choose-your-shipping', 'Choose a shipping method')}}
			</div>
			<ShippingSelector
				class="w-100 my-5"
				:allowed_values="cart.accessories.length && !cart.devices.length ? ['FEDEXGROUND'] : null"
				:value="shipping ? shipping.value : null"
				@input="shipping_method_changed"
				@shipping_methods_items="(items) =>	shipping_methods = items"
			/>
			<div class="w-helper-text text--text text--lighten-1 mt-2">
				{{$lang.t('orders.please-allow-up-to-two-additional-business-days', 'Please allow up to two additional business days for processing (including configuration and testing). No shipping to PO Boxes, US territories, or outside the US.')}}
			</div>
			<div class="w-subtitle-1 info--text text--darken-1 mt-5">
				{{$lang.t('orders.where-would-you-like-to-send-devices', 'Where would you like to send your new devices?')}}
			</div>
			<div class="mt-5">
				<AddressSelector
					v-model="contact.address"
					:items="addresses"
					@new_address_added="new_address_added"
					class="my-4 w-100"
					:label="$lang.t('orders.shipping-address', 'Shipping address')"
					>
					<template v-slot:title>
            			{{ $lang.t('app.enter-a-new-shipping-address', 'Enter a new shipping address') }}
				  	</template>
				</AddressSelector>
				<w-form v-model="valid_form">
					<div class="d-md-flex">
						<w-text-field
							v-model="contact.name"
							:placeholder="$lang.t('app.first-and-last-name', 'First and last name')"
							:class="{'mr-2 w-45': $vuetify.breakpoint.mdAndUp}"
							:rules="[$validation.required()]"
						/>
						<Tel
							:value="contact.phone"
							@changed="(v) => contact.phone = v.clean_number"
							:required="true"
							:validation="true"
							:class="{'ml-3 w-45': $vuetify.breakpoint.mdAndUp}"
							:placeholder="$lang.t('app.phone-number', 'Phone number')"
						/>
					</div>
					<w-text-field
						v-model="contact.company"
						:placeholder="$lang.t('app.company-name-optional', 'Company name (optional)')"
						class="mt-5 w-100"
					/>
				</w-form>
			</div>
		</div>
		<StepperButtons
			:disable_continue="disable_submit()"
			@continue="submitted"
			@back="$emit('back')"
			:key="`submit-${contact ? JSON.stringify(contact) : contact}-${shipping_method_string_value}`"
		/>
	</div>
</template>

<script>
import {vueComponent} from 'helpers';
import Contact from '../../../console/src/models/Contact';
import StepperButtons from '../../../console/src/components/elements//StepperButtons.vue';
import ShippingSelector from '../../../console/src/components/elements//ShippingSelector.vue';
import AddressSelector from '../../../console/src/components/elements//form/AddressSelector.vue';
import Tel from '../../../console/src/components/elements/Tel.vue';

export default {
  props: {
		cart: {
			required: true,
			type: Object,
			validator: (v) => Array.isArray(v.devices) && Array.isArray(v.accessories)
		},
		order: {
			required: true,
			type: Object,
		},
		shipping_address_contact: {
			type: Object,
		},
		shipping: {
			type: Object,
		},
  },
	components: {
		Tel,
		StepperButtons,
		AddressSelector,
		ShippingSelector,
  },
  data() {
	const empty_contact = {
        name: '',
        company: '',
        address: {
            line_1: '',
            line_2: '',
            city: '',
            province: '',
            postal_code: '',
            country: '',
        },
        phone: '',
    };
    return {
			alert: null,
			valid_form: false,
			address: null,
			addresses: [],
			loading: false,
			contact: this.$props.shipping_address_contact ? this.$props.shipping_address_contact : empty_contact,
			shipping_method_string_value: this.$props.shipping ? this.$props.shipping.value : null,
			shipping_methods: [],
			addresses_contact: new Contact(this.$session, vueComponent(this)),
    };
  },
  async created() {
	let addresses = this.$props.cart.devices.filter((x) => x._custom_setup && x._custom_setup.address)
		.map((x) => x._custom_setup.address);
	if (this.$props.shipping_address_contact && this.$props.shipping_address_contact.address) {
		addresses.push(this.$props.shipping_address_contact.address);
	}
	addresses = addresses.filter((x) => ['city', 'country', 'line_1', 'postal_code', 'province'].every((y) => x[y]));
	addresses = [...new Set(addresses.map((x) => JSON.stringify(x)))];
	const addresses_as_string = addresses.map((x) => JSON.parse(x));
	this.$data.addresses = addresses_as_string;
	const fetched_addresses = await this.$data.addresses_contact.loadAddresses();
    if (Array.isArray(fetched_addresses) && fetched_addresses.length > 0) {
        for (const address of fetched_addresses) {
            if (!addresses_as_string.includes(JSON.stringify(address.address))) {
                this.$data.addresses.push(address.address);
            }
        }
    }
	this.$data.addresses = this.$data.addresses.filter((value, i) => {
		const _value = JSON.stringify(value);
		return i === this.$data.addresses.findIndex((obj) => JSON.stringify(obj) === _value);
	});
  },
  methods: {
	new_address_added() {
		this.$data.addresses = this.$data.addresses.filter((value, i) => {
		const _value = JSON.stringify(value);
		return i === this.$data.addresses.findIndex((obj) => JSON.stringify(obj) === _value);
	});
	},
	disable_submit() {
		if (!this.$data.shipping_method_string_value) return true;
		if (!this.$data.contact.name) return true;
		if (!this.$data.contact.phone) return true;
		if (!this.$data.contact.address) return true;
		if (!this.$data.valid_form) return true;
		for (const prop of ['city', 'country', 'line_1', 'postal_code', 'province']) {
			if (!this.$data.contact.address[prop]) return true;
		}
		return false;
	},
	  async submitted() {
		this.$data.loading = true;
		this.$emit('submitted', {
			shipping_address: this.$data.contact,
			shipping: this.$data.shipping_methods.find((x) => x.value === this.$data.shipping_method_string_value),
		});
		this.$props.order.gtm_data_push('PDC_shipping_info', this.$props.cart.devices, this.order.total_price(this.$props.cart.devices));
		this.$data.loading = false;
	},
	shipping_method_changed(data) {
		  this.$props.order.gtm_data_push('PDC_Shipping_Method_Selected', data)
		  this.$data.shipping_method_string_value = data;
		  this.emit_update();

		  return true;
		},
	emit_update() {
		this.$emit('updated', {
			shipping_address: this.$data.contact,
			shipping: this.$data.shipping_methods.find((x) => x.value === this.$data.shipping_method_string_value),
		});

		return true;
	},
	},
	watch: {
		contact: {
			deep: true,
			handler() {
				this.emit_update();
			},
		}
	}
};
</script>

<style lang="scss" scoped>
.buy-device-shipping-details {
	width: 500px;
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
	.main-title {
		margin-bottom: 50px;
	}
	.content {
		width: 500px;
		max-width: 500px;
	}
}
</style>
