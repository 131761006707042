import React, {Component} from 'react'
import { getFormattedTime } from 'time-format'
import Linky from 'linky'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVert from '@material-ui/icons/MoreVert'
import AlertIcon from '../images/icon-alert.svg'
// import ClickableContact from './ClickableContact';

import 'react-confirm-alert/src/react-confirm-alert.css'
import { withStyles } from '@material-ui/core' // Import css

const styles = theme => ({
	inBoundContentItemTheme: {
		backgroundColor: theme.messagesApp.contentItems.inBoundContentItemBackgroundColor,
		float: 'left',
		borderTopLeftRadius: '2px !important'
		// '&.first-item': {
		// 	borderBottomLeftRadius: '2px'
		// },
		// '&.middle-item': {
		// 	borderBottomLeftRadius: '2px',
		// 	borderTopLeftRadius: '2px'
		// },
		// '&.last-item': {
		// 	borderTopLeftRadius: '2px'
		// }
	},
	outBoundContentItemTheme: {
		backgroundColor: theme.messagesApp.contentItems.outBoundContentItemBackgroundColor,
		float: 'right',
		borderTopRightRadius: '2px !important'
		// '&.first-item': {
		// 	borderBottomRightRadius: '2px'
		// },
		// '&.middle-item': {
		// 	borderBottomRightRadius: '2px',
		// 	borderTopRightRadius: '2px'
		// },
		// '&.last-item': {
		// 	borderTopRightRadius: '2px'
		// }
	},
	contentItem: {
		margin:		'0 0 10px 0',
		width:		'100%',
		position:	'relative',
		'&.hightlight': {
			'&.in': {
				background: theme.messagesApp.contentItems.inBoundContentItemBackgroundColor
			},
			'&.out': {
				background: theme.messagesApp.contentItems.outBoundContentItemBackgroundColor
			}
		}
	},
	notAllowed: {
		position:	'absolute',
		left:		0,
		right:		0,
		top:		0,
		bottom:		0,
		background:	'white',
		opacity:	0.4
	},
	contentBar: {
		clear:			'both',
		overflow:		'hidden',
		display:		'flex',
		flexDirection:	'column',
		alignItems:		'flex-end'
	},
	itemInfoBar: {
		display:			'flex',
		flexDirection:		'row',
		fontSize:			10.5,
		fontWeight:			500,
		color:				theme.messagesApp.contentItems.itemInfoBarColor,
		height:				0,
		marginTop:			7,
		opacity:			0,
		overflowY:			'hidden',
		transition:			'opacity .15s, height .5s',
		transitionDelay:	'.1s',
		lineHeight:			0.95,
		letterSpacing:		0.2,
		'&.inbound': {
			justifyContent:	'flex-start'
		},
		'&.outbound': {
			justifyContent:	'flex-end'
		}
	},
	contentWrapper: {
		display:	'flex',
		width:		'100%',
		'&.outbound': {
			justifyContent: 'flex-end'
		},
		'&:not(:hover) .item-menu-button': {
			display: 'none'
		}
	},
	itemContent: {
		padding:		'8px 15px',
		borderRadius:	'20px',
		boxSizing:		'border-box',
		width:			'fit-content',
		maxWidth:		'80%'
	},
	itemText: {
		whiteSpace:		'pre-wrap',
		overflowWrap:	'break-word',
		maxWidth:		'80vh',
		wordBreak:		'break-word',
		fontSize:		'14px',
		color:			theme.messagesApp.contentItems.itemText
	},
	itemDate: {
		fontSize:		'10.5px',
		fontWeight:		500,
		textTransform:	'uppercase'
	},
	errorMessage: {
		fontSize:		14,
		fontWeight:		'bold',
		color:			theme.messagesApp.contentItems.errorMessageColor,
		lineHeight:		1.36,
		letterSpacing:	-0.1,
		cursor:			'pointer',
		display:		'flex',
    	alignItems:		'center',
		marginTop:		11,
		'& img': {
			marginLeft: 7
		}
	},
	itemMenuButtonWrapper: {
		display:	'flex',
		alignItems:	'center'
	},
	itemMenuButton: {
		width:	'30px',
		height:	'30px',
		cursor:	'pointer',
		color:	theme.messagesApp.contentItems.itemMenuButton,
		'&:hover': {
			color: theme.messagesApp.contentItems.itemMenuButtonActive
		}
	},
	colorBlack: {
		color:	theme.messagesApp.contentItems.itemMenuButtonActive
	},
	menuPaper: theme.dropdownMenu.paper,
	menuList: theme.dropdownMenu.list,
	menuItem: theme.dropdownMenu.list.item,
	audioTag: {
		'&:focus': {
			outline: 'none'
		}
	},
	transcription: {
		background:	'#fefefe',
		maxWidth:	'300px',
		border:		'1px solid lightgray'
	},
	transcriptionHeader: {
		padding:		'0 10px',
		color:			'gray',
		borderBottom:	'1px solid lightgray',
		fontWeight:		'bolder'
	},
	transcriptionBody: {
		padding:	'5px 10px',
		maxHeight:	'70px',
		overflowY:	'auto',
		transition:	'max-height 0.2s',
		'&:hover':	{
			maxHeight: '120px'
		}
	},
	contentStatusInfo: {
		textAlign:	'right',
		fontSize:	'12px',
		color:		'#333333',
		padding:	'0 20px'
	},
	contentItemMedia: {
		margin:		'12px 0',
		display:	'flex',
		flexWrap:	'wrap'
	},
	unreadMessagesIndicator: {
		height:			2,
		background:		theme.palette.primary.main,
		marginBottom:	15,
		position:		'relative',
		'& span': {
			position:	'absolute',
			background:	'white',
			padding:	'0 6px',
			color:		theme.palette.primary.main,
			lineHeight:	1,
			left:		'50%',
			fontSize:	12,
			fontWeight:	'bold',
			top:		2,
			transform:	'translate(-50%, -50%)'
		}
	}
})

class ContentItem extends Component {

	constructor(props) {
		super(props)

		this.state = {
			expanded:		false,
			participants:	[],
			retry:			'',
			dropdownRef:	null
		}

		// This is a list of file extensions which will not be rendered
		// useful for .smil but may want to parse them in the future
		this.hiddenAttachmentExtensions = ['.smil']
	}

	toggleExpansion = e => {
		// If retry button is clicked then do not expand
		e.persist()
		if (Array.from(e.target.classList).includes('error-retry')
			|| Array.from(e.target.parentElement.classList).includes('error-retry')) {
			return
		}
		this.setState({expanded: !this.state.expanded})
	}

	//Check if the url should be hidden
	isHiddenAttachment = url => {
		let file_ext_regex = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gi
		let extension = url.match(file_ext_regex)

		if (!extension) return false

		return this.hiddenAttachmentExtensions.includes(extension[0])
	}


	renderMedia = () => {

		const { classes, styles } = this.props

		let attachments = this.props.media && this.props.media.map((mediaItem, index) => {

			if (this.isHiddenAttachment(mediaItem.url)) return null
			if (mediaItem.type === 'application/smil' || mediaItem.type === 'application/smil-xml') return null

			let url = mediaItem.url

			if (mediaItem.type.includes('image') && mediaItem.has_thumbnail) {
				let originalFilename		= url.split('/').pop()
				let originalFilenameSplit	= originalFilename.split('.')
				originalFilenameSplit[originalFilenameSplit.length - 2] += '_thumbnail'
				let newFilename				= originalFilenameSplit.join('.')
				url							= url.replace(originalFilename, newFilename)
			}

			mediaItem.index		= index
			mediaItem.sourceUrl	= url

			return mediaItem.type.split('/')[0] === 'audio' ? (
				<div key={index}>
					<audio
						className={classes.audioTag}
						style={styles.audioTag}
						controls name='media' key={index}
						onPlay={this.props.onPlay || (() => {})}>
							<source src={url} type='audio/mpeg'></source>
					</audio>
					{mediaItem.transcription ? 
						<div className={classes.transcription} style={styles.transcription}>
							<div className={classes.transcriptionHeader} style={styles.transcriptionHeader}>Transcription</div>
							<div className={classes.transcriptionBody} style={styles.transcriptionBody}>{mediaItem.transcription}</div>
						</div>
					: null}
				</div>
			) : this.props.renderMediaItem(mediaItem)
			
		}).filter(mediaItem => mediaItem)

		return attachments.length ? <div className={classes.contentItemMedia}>{attachments}</div> : null
	}

	renderInfoBar() {

		const expandedStyle = {
			opacity: 1,
			height: 9 // with fit-content the height transition doesn't work
		}
		const hiddenStyle = {
			opacity: 0,
			height: 0
		}
		const { classes, styles } = this.props
		const formattedTime = getFormattedTime(this.props.date, true)

		return (
			<div
				className={`${classes.itemInfoBar} ${this.props.direction}bound`}
				style={Object.assign(this.state.expanded ? expandedStyle : hiddenStyle, styles.itemInfoBar)}
			>
				<span className={classes.itemDate} style={styles.itemDate}>{formattedTime}</span>
			</div>
		)
	}

	renderError = () => {
		const { classes, styles }	= this.props
		let error					= this.props.error
		let errorMessage			= error.text
		return (
			<div
				className	= {classes.errorMessage}
				style		= {styles.errorMessage}
				onClick		= {error.onClick}
			>
				{errorMessage}<img src={AlertIcon} alt='Error' />
			</div>
		)
	}

	renderContent() {
		const { classes, styles } = this.props
		let senderClass			= classes[`${this.props.direction}BoundContentItemTheme`]

		let positionClass		= this.props.position ? this.props.position + '-item' : ''
		let classNames			= [senderClass, positionClass, classes.itemContent].join(' ')
		// let formattedTime		= getFormattedTime(this.props.date, true)
		let itemContentStyles	= Object.assign(styles.itemContent || {}, styles[`${this.props.direction}BoundContentItemTheme`])

		return (
			<div
				className	= {classNames}
				style		= {itemContentStyles}
				// title		= {formattedTime}
				onClick		= {this.toggleExpansion}
			>
				<div className={`${classes.itemText} fs-block`} style={styles.itemText}>
					<Linky>{this.props.text}</Linky>
				</div>
				{this.renderMedia()}
			</div>
		)
	}

	showDropdown = e => this.setState({dropdownRef: e.currentTarget})
	hideDropdown = e => this.setState({dropdownRef: null})

	renderOptionsMenu = () => {
		let { classes } = this.props
		let isOpened = Boolean(this.state.dropdownRef)
		return (
			<div className={classes.itemMenuButtonWrapper}>
				<MoreVert
					className		= {`${classes.itemMenuButton} ${isOpened ? classes.colorBlack : 'item-menu-button'}`}
					aria-controls	= 'content-item-menu'
					aria-haspopup	= 'true'
					onClick			= {this.showDropdown}
				/>
				<Menu
					id				= 'content-item-menu'
					anchorEl		= {this.state.dropdownRef}
					open			= {isOpened}
					onClose			= {this.hideDropdown}
					classes			= {{paper: classes.menuPaper, list: classes.menuList}}
					getContentAnchorEl = {null}
					anchorOrigin	= {{
						vertical:	'bottom',
						horizontal:	'left'
					}}
					transformOrigin	= {{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					{this.props.menuItems.map((item, i) => {
						return (
							<MenuItem
								key		= {i}
								classes	= {{root: classes.menuItem}}
								onClick	= {() => {this.hideDropdown(); item.onClick()}}
								disableGutters
								dense
							>{item.text}</MenuItem>
						)
					})}
				</Menu>
			</div>
		)
	}

	render() {

		let direction = `${this.props.direction}bound`
		let { classes, styles } = this.props
		let idProp = this.props.itemId ? {id: this.props.itemId} : {}
		let highlightedClass = this.props.isHighlighted ? `hightlight ${this.props.direction}` : ''

		return (
			<div
				className	={`${classes.contentItem} ${this.props.scrollHere ? 'scroll-here' : ''} ${highlightedClass}`}
				style		={styles.contentItem}
				{...idProp}
			>
				{this.props.showUnreadIndicator ?
					<div className={classes.unreadMessagesIndicator}><span>NEW</span></div>
				: null}
				<div className={classes.contentBar} style={styles.contentBar}>
					<div className={`${classes.contentWrapper} ${direction}`}>
						{this.props.direction === 'out' && this.renderOptionsMenu()}
						{this.renderContent()}
						{this.props.direction === 'in' && this.renderOptionsMenu()}
					</div>
					{this.renderInfoBar()}
					{this.props.error ? this.renderError() : null}
				</div>
				{this.props.isSending ? (<div className={`${direction} ${classes.contentStatusInfo}`}>Sending...</div>) : null}
				{this.props.children}
				{this.props.notAllowed ? <div className={classes.notAllowed}></div> : null}
			</div>
		)
	}
}

export default withStyles(styles)(ContentItem)