import React from 'react'
import PropTypes from 'prop-types'
import SmsEnrollmentTabs from './src/components/SmsEnrollmentTabs.tsx'
import { CampaignContextProvider } from './src/contexts/CampaignContext.tsx'
import { BrandContextProvider } from './src/contexts/BrandsContext.tsx'
import { SmsNumberCampaignContextProvider } from './src/contexts/SmsNumberCampaignContext.tsx'
import { EnrollmentEventsContextProvider } from './src/contexts/EnrollmentEventsContext'
/**
 *
 * @param {object} param0
 * @param {boolean} param0.csr
 * @returns {JSX.Element}
 * @description This is the entry point for 10DLC enrollments
 */
const SmsEnrollment = ({
    // eslint-disable-next-line react/prop-types
    csr = false
}) => {
    return (<>
        <BrandContextProvider csr={csr}>
            <CampaignContextProvider csr={csr}>
                <EnrollmentEventsContextProvider>
                    <SmsNumberCampaignContextProvider>
                        <SmsEnrollmentTabs />
                    </SmsNumberCampaignContextProvider>
                </EnrollmentEventsContextProvider>
            </CampaignContextProvider>
        </BrandContextProvider>

    </>)
}

SmsEnrollment.prototype = {
    routeProps: PropTypes.object,
    csr: PropTypes.bool
}

export default SmsEnrollment
