import l from '../lang';

export default {
	'routes': {
		'create': 'extensions.create',
		'show': 'extensions.show',
		'index': 'extensions.index',
		'visualizer': 'extensions.visualizer',
	},
	'delete-single': l.t('extensions.delete-single', 'Are you sure you want to delete this extension?'),
	'delete-filtered': l.t('extensions.delete-filtered', 'Are you sure you want to delete all filtered extensions?'),
	'delete-all': l.t('extensions.delete-all', 'Are you sure you want to delete all extensions?'),
	'successfull-creation': l.t('extensions.successfully-created', 'Your extension has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('extensions.failed-creation', 'There was a problem creating your extension'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
	'currently-in-use': l.t('extensions.this-extension-is-currently-in-use', 'This extension is currently in use'),
	'to-delete-used': l.t('extensions.to-delete-this-extension-remove-it', 'To delete this extension, remove it from your call handling rules first, then try again.'),
};
