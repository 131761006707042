<template>
    <w-btn color="primary" class="w-btn-small page-back-btn" :class="{'page-back-btn-absolute': absolute_btn}" text @click="on_click" data-test-id="go-back-btn">
        <v-icon size="16">$vuetify.icons.chevron_backward</v-icon>{{l.t('app.back-lowercase', 'back')}}
    </w-btn>
  </template>
  <script>
  import l from '../../libs/lang';
  import EventBus from 'event-bus';

  export default {
    props: {
        absolute: {
                type: Boolean,
                default: false,
            }
      },
      data() {
          return {
            l,
            absolute_btn: this.$props.absolute === true || (Object.prototype.hasOwnProperty(this.$attrs, 'absolute') && this.$attrs.absolute !== false)
        };
      },
      methods: {
          on_click() {
              if (this.$router) {
                this.$router.go(-1);
              } else {
                EventBus.$emit('click:back');
              }
          },
      }
  };
  </script>
