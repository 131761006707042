import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from 'back-button'
import { updatePhoneNumber, updateEmail } from '../../actions/settings'
import Spinner from 'spinner'
import api from '../../util/api_v5'
import { HtmlTooltip } from 'tooltips'
import Button from '@material-ui/core/Button'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import ConfirmModal from 'confirm-modal'
import { confirmAlert } from 'react-confirm-alert'
import { withStyles } from '@material-ui/core'


const styles = theme => ({
	header:			theme.settingsApp.header,
	settingWrapper:	theme.settingsApp.settingWrapper,
	formWrapper: {
		padding:	'10px 20px',
		maxWidth:	440,
		boxShadow:	'0px 0px 6px gray',
		'&.small-view': {
			boxShadow:		'none',
			maxWidth:		'100%',
			paddingBottom:	30,
			borderBottom:	'1px solid lightgray'
		}
	},
	inputRow: {
		display:			'flex',
		flexDirection:		'column',
		color:				'gray',
		backgroundPosition:	'bottom',
		backgroundSize:		'10px 1px',
		backgroundRepeat:	'repeat-x',
		position:			'relative',
		marginBottom:		8,
		paddingBottom:		8,
		'&:not(.last-shown-row)': {
			backgroundImage: 'linear-gradient(to right, #333 10%, rgba(255, 255, 255, 0) 0%)',
		},
		'& .inner-row': {
			display:		'flex',
			justifyContent:	'space-between',
			alignItems:		'center'
		},
		'& .label': {
			flex: 1
		},
		'& .settingsInput': {
			flex:			1,
			fontSize:		16,
			maxWidth:		260,
			color:			'black',
			border:			'1px solid #1140A1',
			borderRadius:	8,
			padding:		'5px 12px',
			'&:focus':{
				boxShadow:	'0 0 1pt 0.3pt #1140A1',
				outline:	'none'
			}
		},
		'& .icon': {
			position:	'absolute',
		},
		'& .info-icon': {
			cursor: 'pointer',
			color: 'gray',
			marginLeft: 8
		}
	},
	buttonWrapper: {
		marginTop:		15,
		display:		'flex',
		justifyContent:	'center'
	},
	updateButton: {
		color:	'#1140A1',
		border:	'1px solid #1140A1',
		'&:hover': {
			color:				'white',
			backgroundColor:	'#1140A1',
			borderColor:		'#1140A1'
		},
		'&:focus': {
			outline: 0
		}
	},
	spinnerWrapper: {
		display:		'flex',
		justifyContent:	'center',
		margin:			18
	},	
	errorMessage: {
		color:			'#df1d08',
		margin:			'30px 45px 0',
		fontSize:		14,
		fontWeight:		'bold',
		lineHeight:		1.29,
		letterSpacing:	-0.1,
		textAlign:		'center'
	},

	errorMessageInput: {
		color:			'#df1d08',
		// margin:			'30px 45px 0',
		fontSize:		14,
		fontWeight:		'bold',
		lineHeight:		1.29,
		letterSpacing:	-0.1,
		textAlign:		'center'
	}
})

const mapStateToProps = state => ({
	smallView:	state.smallView,
	security:	state.security
})

const mapDispatchToProps = dispatch => ({
	updateEmail:		email		=> dispatch(updateEmail(email)),
	updatePhoneNumber:	phoneNumber	=> dispatch(updatePhoneNumber(phoneNumber))
})

class Security extends Component {
	state = {
		oldPw:						'',
		newPw:						'',
		newPwRe:					'',
		email:						this.props.security.email,
		phone:						this.props.security.phoneNumber,
		loginId:					'',
		emailExtensionLoginId:		'',
		phoneExtensionLoginId:		'',
		verifyPassword:				'',
		verifyCode:					'',
		errorMessage:				'',
		errorMessageEmail:			false,
		errorMessagePhone:			false,
		errorMessageVerifyCode:		false,
		errorMessageVerifyPassword:	false,
		loading:					false,
		emailLoading:				false,
		phoneLoading:				false,
		passwordLoading:			false,
		emailModalVisible:			false,
		phoneModalVisible:			false
	}

	componentDidMount() {
		this._mounted = true
		this.getCurrentLogins()
	}

	componentWillUnmount() {
		this._mounted = false
	}

	getCurrentLogins = async () => {
		this.setState({emailLoading: !Boolean(this.props.security.email), phoneLoading: !Boolean(this.props.security.phoneNumber)})
		let response = await api.getLogins()
		this.setState({emailLoading: false, phoneLoading: false})
		if (!this._mounted) return
		if (response.data) {
			// TODO - Today we expect 1 login per account, and 2 extension_logins,
			//  one for phone and one for email. This could change when we introduce
			//  user concept and this code should be updated to handle.
			if (response.data.logins.length === 1) {
				const login = response.data.logins[0]
				if (this.state.email === this.props.security.email) this.setState({email: login.username})
				this.setState({
					loginId:			login.login_id,
					errorMessageEmail:	''
				})
				this.props.updateEmail(login.username)
			}

			// TODO - We expect 2 extension logins for webphone accounts TODAY, but
			//  this should be fixed so not so brittle
			if (response.data.extension_logins.length === 2) {
				let phoneExtLogin
				let emailExtLogin

				for (let extension_login of response.data.extension_logins) {
					// HACK - We expect one that one of the extenson_logins is a telephone
					// number (beginning with a +)
					if (extension_login.extension_user[0] === '+') {
						phoneExtLogin = extension_login
					}
					else if (extension_login.extension_user.indexOf('@') >= 0) {  // Gross way of checking for email
						emailExtLogin = extension_login
					}
				}
				if (phoneExtLogin) {
					let phone = (phoneExtLogin.extension_user.match(/\d/g) || [])
					phone = this.liveFormatNumber(phone.join('').substring(1))
					if (this.state.phone === this.props.security.phoneNumber) this.setState({phone}) // An E164 phone number
					this.setState({
						phoneExtensionLoginId:	phoneExtLogin.extension_login_id,
						errorMessagePhone:		''
					})
					this.props.updatePhoneNumber(phone) // An E164 phone number
				} else {
					this.setState({errorMessagePhone: 'Could not get existing phone information'})
				}

				if (emailExtLogin) {
					this.setState({
						emailExtensionLoginId: emailExtLogin.extension_login_id,
						errorMessageEmail: ''
					})
				} else {
					this.setState({errorMessageEmail: 'Could not get existing phone information'})
				}

			}

		}

		if (!this.state.emailExtensionLoginId) {
				this.setState({errorMessageEmail: ''})
		}

		if (!this.state.phoneExtensionLoginId) {
				this.setState({errorMessagePhone: ''})
		}
	}


	inputChange = e => {
		let name		= e.target.name
		let validation	= false
		if (name === 'oldPw') {
			this.setState({oldPw: e.target.value}, 
				() => {
					validation = this.state.errorMessage !== '' ? this.validatePassword() : false
					if (validation === true){
						this.setState({errorMessage: ''})
					}
				})
		}
		if (name === 'newPw') {
			this.setState({newPw: e.target.value}, 
				() => {
					validation = this.state.errorMessage !== '' ? this.validatePassword() : false
					if (validation === true){
						this.setState({errorMessage: ''})
					}
				})
		}		
		if (name === 'newPwRe') {
			this.setState({newPwRe: e.target.value}, 
				() => {
					validation = this.state.errorMessage !== '' ? this.validatePassword() : false
					if (validation === true){
						this.setState({errorMessage: ''})
					}
				})
		}
		if (name === 'email') {
			this.setState({email: e.target.value, errorMessageEmail: false})
		}
		if (name === 'verifyPassword') {
			this.setState({verifyPassword: e.target.value, errorMessageVerifyPassword: false})
		}
		if (name == 'verifyCode') {
			this.setState({verifyCode: e.target.value, errorMessageVerifyCode: false})
		}
		if (name === 'phone') {
			let value	= e.target.value
			let number	= (value.match(/\d/g) || [])
			number		= number.join('')
			if (isNaN(number) || number.length > 10) return
			value		= this.liveFormatNumber(number)
			this.setState({phone: value, errorMessagePhone: false})
		}
	}

	validateEmail = () => {
		let email = this.state.email
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		if (!re.test(String(email).toLowerCase())) {
			let error = 'Please enter a valid email address.'
			return error
		}
		return false
	}

	validatePhone = () => {
		let number	= (this.state.phone.match(/\d/g) || [])
		number		= number.join('')
		if (number.length !== 10){
			let error = 'Please enter a valid phone number.'
			return error
		}
		return false
	}


	validatePassword = () => {
		let password	= this.state.newPw
		let passwordRe	= this.state.newPwRe
		let oldPassword	= this.state.oldPw

		if (!/.{8,}$/.test(password) || !/.{8,}$/.test(oldPassword)) {
			return 'Your password must be at least 8 characters and contain both uppercase and lowercase letters.'
		}
		if (!/[a-z]/.test(password) || !/[a-z]/.test(oldPassword)) {
			return 'Your password is missing one or more lowercase letters.'
		}
		if (!/[A-Z]/.test(password) || !/[A-Z]/.test(oldPassword)) {
			return 'Your password is missing one or more uppercase letters.'
		}
		if (password !== passwordRe) {
			return 'New passwords do not match'

		}
		if (password === oldPassword) {
			return 'New password must be different from current password.'

		}
		return true
	}

	liveFormatNumber = (number) =>{
		let length		= number.length
		let prevNumber	= (this.state.phone.match(/\d/g) || [])
		prevNumber		= prevNumber.join('')
		
		if (prevNumber.length === 3 && length === 3){
			number = number.slice(0, -1)
			length = number.length
		}
		if (length > 0){
			number = number.slice(0, 0) + '(' + number.slice(0)
		}
		if (length > 2){
			number = number.slice(0, 4) + ')' + number.slice(4)
		}
		if (length > 3){
			number = number.slice(0, 5) + ' ' + number.slice(5)
		}
		if (length > 6){
			number = number.slice(0, 9) + '-' + number.slice(9)
		}
		return number
	}


	changeInfo = async () => {
		let errorMessageEmail = this.state.email !== this.props.security.email ? this.validateEmail() : false
		let errorMessagePhone = this.state.phone !== this.props.security.phoneNumber ? this.validatePhone() : false
		if (errorMessageEmail || errorMessagePhone) {
			this.setState({errorMessageEmail, errorMessagePhone})
			return
		}
		if (this.state.email !== this.props.security.email) {
			this.setState({emailLoading: true})
		}
		if (this.state.phone !== this.props.security.phoneNumber) {
			this.setState({phoneLoading: true})
		}
		// TODO call api
		let logins = []
		let extension_logins = []
		if (this.state.loginId) {
			logins.push({'login_id': this.state.loginId, 'username': this.state.email})
		}
		if (this.state.emailExtensionLoginId) {
			extension_logins.push({'extension_login_id': this.state.emailExtensionLoginId, 'extension_user': this.props.security.email})
		}
		if (this.state.phoneExtensionLoginId) {
			let number	= (this.props.security.phoneNumber.match(/\d/g) || [])
			number		= number.join('')
			number		= '+1' + number
			extension_logins.push({'extension_login_id': this.state.phoneExtensionLoginId, 'extension_user': number})
		}
		let response = await api.updateLogins(logins, extension_logins)
		if (response.data) {
			this.setState({emailLoading: false, phoneLoading: false, errorMessageEmail: '', errorMessagePhone: ''})
		} else {
			this.setState({emailLoading: false, phoneLoading: false, errorMessageEmail: 'Could not update email', errorMessagePhone: 'Could not update phone'})
		}
	}

	changeEmail = async () => {
		let pass = this.state.verifyPassword
		if (pass.length < 8) {
			this.setState({errorMessageVerifyPassword: 'Please enter a valid password.'})
			return
		}

		let response = await api.verifyPassword(pass)
		if ( response.status === 'fail') {
			this.setState({errorMessageVerifyPassword: response.message})
			return
		}
		this.setState({emailModalVisible: false}, ()=>{
			confirmAlert({
				title: 'Confirm',
				message: `Are you sure you want to update your email to ${this.state.email} ?`,
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
							this.setState({verifyPassword: ''}, this.changeInfo)
							this.props.updateEmail(this.state.email)
						}
					},
					{
						label: 'No',
						onClick: () => this.setState({email: this.props.security.email, verifyPassword: ''})
					}
				]
			})
		})
		// this.changeInfo()
	}

	changePassword = async () => {
		let validation = this.validatePassword()
		if (validation !== true) {
			this.setState({errorMessage: validation})
			return
		}
		this.setState({loading: true})
		let response = await api.updatePassword(this.state.oldPw, this.state.newPw, this.state.newPwRe)
		this.setState({loading: false})
		if (response && response.status === 'fail') {
			this.setState({errorMessage: response.message})
		}
		else if (response && response.status === 'success') {
			this.setState({oldPw: '', newPw: '', newPwRe: ''})
		}
	}

	verifyPhoneCode = async () => {
		let code = this.state.verifyCode
		if (code.length === 0) {
			this.setState({errorMessageVerifyCode: 'Please enter a valid code.'})
			return
		}
		let response = await api.verifyPhoneCode(code)

		if (response.status === 'fail') {
			this.setState({errorMessageVerifyCode: 'The entered code is not valid.'})
			return
		}
		this.setState({phoneModalVisible: false}, () => {
			confirmAlert({
				title: 'Confirm',
				message: `Are you sure you want to update your number to ${this.state.phone} ?`,
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
							this.setState({verifyCode: ''}, this.changeInfo)
							this.props.updatePhoneNumber(this.state.phone)
						}
					},
					{
						label: 'No',
						onClick: () => this.setState({phone: this.props.security.phoneNumber, verifyCode: ''})
					}
				]
			})
		})
	}

	openLinkedPhoneModal = async () => {
		let errorMessagePhone = this.validatePhone()
		if (errorMessagePhone) {
			this.setState({errorMessagePhone})
			return
		}

		this.setState({phoneModalVisible: true})
		let number	= (this.state.phone.match(/\d/g) || [])
		number		= number.join('')
		number		= '+1' + number

		let response = await api.sendConfirmCode(number)
		// TODO if this fails give the user an option to resend a verification code
	}
	

	renderEmailConfirmModal = () => {
		const { classes } = this.props

		let content = (
			<div className={`${classes.inputRow} last-shown-row`}>
				<div className='inner-row'>
					<div className='label'>Verify password</div>
					<input
						onChange	= {this.inputChange}
						name		= 'verifyPassword'
						className	= 'settingsInput'
						placeholder	= 'Current password'
						value		= {this.state.verifyPassword}
						type		= 'password'
					/>
				</div>
				<div className='inner-row'>
					{this.state.errorMessageVerifyPassword ?
						<div className={classes.errorMessageInput}>{this.state.errorMessageVerifyPassword}</div>
					: null}
				</div>
			</div>
		)

		let title = 'Please verify your password.'
		return <ConfirmModal
			title			= {title}
			isShown			= {this.state.emailModalVisible}
			content			= {content}
			noButtonText	= 'Cancel'
			yesButtonText	= 'Confirm'
			onReject		= {() => this.setState({emailModalVisible: false, email: this.props.security.email, verifyPassword: ''})}
			onConfirm		= {() => this.changeEmail()}
			size			= 'size2'
		/>
	}

	renderLinkedPhoneConfirmModal = () => {
		const { classes } = this.props

		let content = (
			<div className={`${classes.inputRow} last-shown-row`}>
				<div className='inner-row'>
					<div className='label'>Code</div>
					<input
						onChange	= {this.inputChange}
						name		= 'verifyCode'
						className	= 'settingsInput'
						placeholder	= '123456'
						value		= {this.state.verifyCode}
						type		= 'text'
					/>
				</div>
				<div className='inner-row'>
					{this.state.errorMessageVerifyCode ? <div className={classes.errorMessageInput}>{this.state.errorMessageVerifyCode}</div> : null}
				</div>
			</div>
		)

		let title = `We sent a verification code to ${this.state.phone}`
		return <ConfirmModal
			title			= {title}
			isShown			= {this.state.phoneModalVisible}
			content			= {content}
			noButtonText	= 'Cancel'
			yesButtonText	= 'Confirm'
			onReject		= {() => this.setState({phoneModalVisible: false, phone: this.props.security.phoneNumber, verifyCode: ''})}
			onConfirm		= {() => this.verifyPhoneCode()}
			size			= 'size2'
		/>
	}

	render() {
		const { classes } = this.props
		let backButton = this.props.smallView ? <BackButton onClick={this.props.goBack}/> : ''
		return (
			<>
				<div className={classes.header}>
					<div>
						{backButton}
						<span className='header-title'>Security</span>
					</div>
				</div>
				<div className={`${classes.settingWrapper} ${this.props.smallView ? 'small-view' : ''}`}>

					{/* EMAIL */}
					<div
						className	= {`${classes.formWrapper} ${this.props.smallView ? 'small-view' : ''}`}
						style		= {{marginBottom: this.props.smallView ? 20 : 40}}
					>
						<div className={`${classes.inputRow} last-shown-row`}>
							<div className='inner-row'>
								<div className='label'>Email</div>
								<div className='icon' style={{left: 30}}>
									<HtmlTooltip
										title			= {<React.Fragment>This email can be used to log in to our mobile app or web.phone.com</React.Fragment>}
										placement		= 'top'
										interactive		= {false}
										leaveDelay		= {1}
										leaveTouchDelay	= {10000}
										enterTouchDelay	= {0}
										enterDelay		= {300}
									>
										<span className='info-icon'><InfoOutlinedIcon/></span>
									</HtmlTooltip>
								</div>
								<input
									onChange	= {this.inputChange}
									name		= 'email'
									className	= 'settingsInput'
									placeholder	= 'Email'
									value		= {this.state.email}
									type		= 'text'
								/>
							</div>
							<div className='inner-row'>
								{this.state.errorMessageEmail ? <div className={classes.errorMessageInput}>{this.state.errorMessageEmail}</div> : null}
							</div>
						</div>
						{this.state.emailLoading ? <div className={classes.spinnerWrapper}><Spinner/></div> : null}
						<div className={classes.buttonWrapper}>
							<Button 
								onClick		= {() => this.setState({emailModalVisible: true})} 
								disabled	= {Boolean(this.state.emailLoading || (this.state.email === this.props.security.email))}
								variant		= 'outlined'
								classes		= {{root: classes.updateButton}}
							>
								UPDATE EMAIL
							</Button>
						</div>
					</div>

					{/* LINKED PHONE */}
					<div
						className	= {`${classes.formWrapper} ${this.props.smallView ? 'small-view' : ''}`}
						style		= {{marginBottom: this.props.smallView ? 20 : 40}}
					>
						<div className={`${classes.inputRow} last-shown-row`}>
							<div className='inner-row'>
								<div className='label'>Linked phone</div>
								<div className='icon' style={{left: 80}}>
									<HtmlTooltip
										title			= {
											<React.Fragment>
												This phone number can be used for logging in to our mobile app.
												The number must be capable of receiving text messages.
											</React.Fragment>
										}
										placement		= 'top'
										interactive		= {false}
										leaveDelay		= {1}
										leaveTouchDelay	= {10000}
										enterTouchDelay	= {0}
										enterDelay		= {300}
									>
										<span className='info-icon'><InfoOutlinedIcon/></span>
									</HtmlTooltip>
								</div>
								<input
									onChange	= {this.inputChange}
									name		= 'phone'
									className	= 'settingsInput'
									placeholder	= 'Phone number'
									value		= {this.state.phone}
									type		= 'text'
								/>
							</div>
							<div className='inner-row'>
								{this.state.errorMessagePhone ? <div className={classes.errorMessageInput}>{this.state.errorMessagePhone}</div> : null}
							</div>
						</div>
						{this.state.phoneLoading ? <div className={classes.spinnerWrapper}><Spinner/></div> : null}
						<div className={classes.buttonWrapper}>
							<Button 
								onClick		= {() => this.openLinkedPhoneModal()} 
								disabled	= {Boolean(this.state.phoneLoading || (this.state.phone === this.props.security.phoneNumber))}
								variant		= 'outlined'
								classes		= {{root: classes.updateButton}}
							>
								UPDATE PHONE NUMBER
							</Button>
						</div>
					</div>

					{/* PASSWORD */}
					<div className={`${classes.formWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
						<div className={classes.inputRow}>
							<div className='inner-row' onClick={() => document.getElementById('oldPw').focus()}>
								<div className='label'>Current password</div>
								<input onChange={this.inputChange} id='oldPw' name='oldPw' className='settingsInput' placeholder='Current password' value={this.state.oldPw} type='password'></input>
							</div>
						</div>
						<div className={classes.inputRow}>
							<div className='inner-row' onClick={() => document.getElementById('newPw').focus()}>
								<div className='label'>New password</div>
								<input onChange={this.inputChange} id='newPw' name='newPw' className='settingsInput' placeholder='New Password' value={this.state.newPw} type='password'></input>
							</div>
						</div>
						<div className={`${classes.inputRow} last-shown-row`}>
							<div className='inner-row' onClick={() => document.getElementById('newPwRe').focus()}>
								<div className='label'>Confirm new password</div>
								<input onChange={this.inputChange} id='newPwRe' name='newPwRe' className='settingsInput' placeholder='Confirm New Password' value={this.state.newPwRe} type='password'></input>
							</div>
						</div>
						{this.state.loading ? <div className={classes.spinnerWrapper}><Spinner/></div> : null}
						{this.state.errorMessage ? <div className={classes.errorMessage}>{this.state.errorMessage}</div> : null}
						<div className={classes.buttonWrapper}>
							<Button 
								onClick		= {this.changePassword} 
								disabled	= {Boolean(this.state.loading || !this.state.oldPw || !this.state.newPw || !this.state.newPwRe)}
								variant		= 'outlined'
								classes		= {{root: classes.updateButton}}
							>
								Update Password
							</Button>
						</div>
					</div>
				</div>
				{this.renderEmailConfirmModal()}
				{this.renderLinkedPhoneConfirmModal()}
			</>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Security))