import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * @param {SvgIconProps} props - svg props
 */
export const OpenInNewTabIcon = (props: SvgIconProps): JSX.Element => {
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.40404 9.5C3.40404 9.22386 3.18018 9 2.90404 9H1.5C1.22386 9 1 9.22386 1 9.5V21.5C1 21.7761 1.21579 22 1.49193 22C3.86968 22 14.1922 22 17.5073 22C17.7834 22 18 21.7761 18 21.5V20.0404C18 19.7642 17.7761 19.5404 17.5 19.5404H3.40404V9.5Z" fill="#262626"/>
            <path d="M6.5 2C6.22386 2 6 2.22386 6 2.5V16.5C6 16.7761 6.22386 17 6.5 17H22.5C22.7761 17 23 16.7761 23 16.5V2.5C23 2.22386 22.7761 2 22.5 2L6.5 2ZM19.9643 13.875H9.03571V7.625H19.9643V13.875Z" fill="#262626"/>
        </SvgIcon>
    )
}

export default OpenInNewTabIcon
