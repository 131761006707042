<template>
    <div class="processing-stats" v-if="processing">
        <div class="progressbar mt-3">
            <div
                v-for="(status, i) in processing.status"
                :key="i"
                :class="{'success': status.status === 'success', 'failed error': status.status === 'failed', }"
                :style="{
                    'width': processing.total ? 100/processing.total+'%' : '0px'
                }"
                data-test-id="progressbar-progress"
            >
            </div>
            <span id="percentage" data-test-id="progressbar-percentage">
                {{ processing.total > 0 ? Math.round((processing.status.length*100)/processing.total) : 0 }}%
            </span>
        </div>
        <p v-if="processing.total" data-test-id="progressbar-total">
            {{ text_label }}: {{ processing.total }}
        </p>
        <div class="processing-stats-results" data-test-id="progressbar-results">
            <w-alert
                v-if="processing.status.filter(x => x.status === 'success').length"
                :message="success_message || `${l.t('app.successes', 'Successes')}: ${processing.status.filter(x => x.status === 'success').length}`"
                level="success"
                :closable="false"
                data-test-id="progressbar-results-success"
            />
            <w-alert
                v-if="processing.status.filter(x => x.status === 'failed').length"
                :message="failed_message || `${l.t('app.failed', 'Failed')}: ${processing.status.filter(x => x.status === 'failed').length}`"
                level="error"
                :closable="false"
                data-test-id="progressbar-results-errors"
            />
        </div>
        <div class="text-center mt-7">
            <w-btn :disabled="stopped" @click="stopProcess" color="error" data-test-id="progressbar-stop-the-process-btn">
                {{ stopped ? l.t("app.aborting-process", "Aborting the process...") : l.t("app.cancel", "Cancel") }}
            </w-btn>
        </div>
    </div>
</template>

<script>
  import l from '../../libs/lang';

  export default {
    props: [
        'processing',
        'label',
        'success_message',
        'failed_message',
    ],
    data() {
      return {
        l,
        stopped: false,
        text_label: l.t('app.total-items', 'Total items'),
      };
    },
    created() {
        if (this.$props.label) {
            this.$data.text_label = this.$props.label;
        }
    },
    methods: {
      stopProcess() {
        this.$data.stopped = true;
        this.$emit('stopProcess');
      },
    },
  };
</script>
<style lang="scss" scoped>
	@import 'vue-apps-assets/styles/scss/vars.scss';
    @import '~vuetify/src/styles/main.sass';
    .progressbar{
        position:relative;
        height: 2rem;
        background: $border-color;
        #percentage{
            color: #FFF;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        div{
           float: left;
            height: 100%;
        }
    }
    .processing-stats-results {
        display: flex;
        & > * {
            margin-left: 1rem;
            width: 100%;
            &:first-child {
                margin-left: 0;
            }
        }
    }
</style>
