// @flow

import * as React from 'react'
import { formatPhoneNumber } from 'phone-numbers'

const defaultComponentDecorator = (decoratedHref, decoratedText, key) => (
    <a target="_blank" rel="noreferrer" href={decoratedHref} key={key}>
        {decoratedHref.startsWith('tel:') ? formatPhoneNumber(decoratedText) : decoratedText}
    </a>
)

export default defaultComponentDecorator
