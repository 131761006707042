import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    container: { padding: '0rem 3rem', overflowX: 'auto' }
}))

/**
 * @param {object} props
 * @param {object} props.children
 */
const EnrollmentStepContainer = ({ children, showContainer = true }) => {
    const classes = useStyles()
    return (
        <div className={showContainer ? classes.container : null}>
            { children }
        </div>
    )
}

EnrollmentStepContainer.propTypes = {
    children: PropTypes.any,
    showContainer: PropTypes.bool
}

export default EnrollmentStepContainer
