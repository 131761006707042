import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSimpleTable,{attrs:{"id":"stats-table","data-test-id":"per-ext-graph-table"}},[_c('thead',{attrs:{"data-test-id":"table-head"}},[_c('tr',{attrs:{"data-test-id":"head-row"}},[_c('th',{attrs:{"data-test-id":"extension"}},[_vm._v(_vm._s(_vm.$lang.t('app.extension', 'Extension')))]),_vm._v(" "),_vm._l((Object.keys(_vm.items)),function(prop,i){return _c('th',{key:("th-" + i),attrs:{"data-test-id":i}},[_vm._v("\n                "+_vm._s(_vm.fields[prop].translation)+"\n            ")])})],2)]),_vm._v(" "),_c('tbody',{attrs:{"data-test-id":"table-body"}},[_vm._l((_vm.extensions),function(extension,i){return _c('tr',{key:("per-ext-graph-item-" + i + "-row"),attrs:{"data-test-id":("per-ext-graph-item-" + i + "-row")}},[_c('td',{class:{ disabled: extension && typeof extension !== 'object' },attrs:{"title":false,"data-test-id":"extension"}},[_vm._v("\n                "+_vm._s(_vm.extensionName(extension))+"\n            ")]),_vm._v(" "),_vm._l((Object.keys(_vm.items)),function(prop){return _c('td',{key:prop,attrs:{"data-test-id":prop}},[_c('HorizontalBar',{attrs:{"color":_vm.colors[_vm.findExtensionId(extension)],"item":_vm.items[prop].find(function (x) { return x.extension === extension; }),"title":_vm.title(_vm.items[prop].find(function (x) { return x.extension === extension; }), prop),"max":Math.max.apply(Math, _vm.items[prop].map(function (x) { return x.display_value; })),"data-test-id":("per-ext-graph-item-" + i + "-bar")}})],1)})],2)}),_vm._v(" "),_c('tr',{attrs:{"data-test-id":"per-ext-bottom-graph"}},[_c('td',{attrs:{"data-test-id":"empty-cell"}},[_c('br')]),_vm._v(" "),_vm._l((Object.keys(_vm.items)),function(prop){return _c('td',{key:("bottmo-" + prop),attrs:{"data-test-id":prop}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"min"},[_vm._v("\n                        "+_vm._s(Math.round(
                                _vm.formatters[_vm.fields[prop]['type']](
                                    Math.min.apply(
                                        Math,
                                        _vm.items[prop].map(function (x) { return x.value; })
                                    )
                                )
                            ))+"\n                    ")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$lang.t('app.calls-lowercase', 'calls')))]),_vm._v(" "),_c('span',{staticClass:"max"},[_vm._v("\n                        "+_vm._s(Math.round(
                                _vm.formatters[_vm.fields[prop]['type']](
                                    Math.max.apply(
                                        Math,
                                        _vm.items[prop].map(function (x) { return x.value; })
                                    )
                                )
                            ))+"\n                    ")])])])})],2)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }