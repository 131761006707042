
import React from 'react'
import { Grid } from '@material-ui/core'
import Typography from 'typography'
import { Textarea } from 'textarea-mui'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox, { Variant as CheckboxVariant } from 'checkbox-mui'
import PropTypes from 'prop-types'
import { useCasesOptions } from '../utils/util'

const useStyles = makeStyles((theme) => ({
    fullWidthTextArea: {
        width: '100%',
        resize: 'none',
        border: '1px solid lightgrey',
        borderRadius: '0.25rem'
    },
    topMarginSpacing: {
        marginTop: '1rem'
    },
    gridJustifyContent: {
        justifyContent: 'space-between'
    }
}))

/**
 *
 */
const PreviewCampaignDetails = ({ enrollment }) => {
    const classes = useStyles()
    return (
        <Grid
            className={classes.gridJustifyContent}
            container
            direction='column'
            spacing={1}>
            <Typography variant='body1' remoteConfigID='sms_enrollment_preview_campaign_description' />
            <Textarea className={classes.fullWidthTextArea} value={enrollment.description} readOnly={true} />

            <Typography variant='body1' className={classes.topMarginSpacing} remoteConfigID='sms_enrollment_use_cases' />
            <Grid container item direction='column'>
                {enrollment.use_cases.map((x, index) => <Checkbox
                    key={index}
                    readOnly
                    checked
                    variant={CheckboxVariant.PRIMARY}
                    label={useCasesOptions.find(y => y.id === x)?.label} />)}
            </Grid>
        </Grid>
    )
}
PreviewCampaignDetails.propTypes = {
    enrollment: PropTypes.object
}

export default PreviewCampaignDetails
