
import React, { useEffect, useState } from 'react'
import { MyPhoneContactContext } from 'providers'
import { DefaultArrowTooltip } from 'tooltips'
import { formatPhoneNumber } from 'phone-numbers'

interface DisplayNameProps {
    tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
    value: string;
    maxLength?: number;
    getDisplayName: (value: string) => Promise<string>;
    displayNames: Map<string, string>;
    className: string;
}

/**
 *
 */
function DisplayName (props: DisplayNameProps) {
    const contactContext = React.useContext(MyPhoneContactContext)
    const [displayName, setDisplayName] = useState(formatPhoneNumber(props.value))
    const formatName = (name: string): string => {
        let formatedName = name
        if (props.maxLength && formatedName.length > props.maxLength) {
            formatedName = formatedName.substring(0, 17) + '...'
        }
        return formatedName
    }
    const updateDisplayName = async () => {
        if (props.value) {
            contactContext.getDisplayName(props.value).then(
                contextDisplayName => {
                    if (contextDisplayName && formatName(contextDisplayName) !== displayName) setDisplayName(formatName(contextDisplayName))
                }
            ).catch(err => console.error(err))
        }
    }

    useEffect(() => {
        updateDisplayName()
    }, [props.value, props.maxLength, contactContext])
    const foundName = props.value !== displayName
    const tooltip = foundName ? formatPhoneNumber(props.value) : ''
    return (<>
        <DefaultArrowTooltip
            title = {tooltip}
            placement={props.tooltipPlacement || 'top'}
            className={props.className}
        >
            <span>{displayName}</span>
        </DefaultArrowTooltip>
    </>)
}

export default DisplayName
