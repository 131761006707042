import { Styles } from '@material-ui/core/styles/withStyles'
type StylesType = Styles<any, Record<string, unknown>>

/**
 * @param {object} theme
 */
const styles: StylesType = theme => ({
    endCallButton: {
        height: 60,
        width: 60,
        '& svg': {
            fontSize: 30
        }
    },
    endCallButtonWrapper: {
        height: '60px',
        minWidth: '60px',
        margin: '0px 120px'
    },
    callDescription: {
        height: 50,
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 15,
        backgroundColor: 'white',
        color: theme.palette.text.secondary
    },
    headerIcon: {
        width: '18px',
        height: '18px'
    },
    callerInfo: {
        minHeight: 175,
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 10,
        paddingTop: 10,
        '& > .person-avatar': {
            height: 90,
            width: 90,
            '& > img': {
                pointerEvents: 'none'
            }
        },
        '& > .participant-info': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > p': {
                color: 'white'
            }
        },
        '&:not(.has-avatar)': {
            paddingTop: 65,
            '& > .participant-info': {
                rowGap: 5
            }
        },
        '&.shrink': {
            flexDirection: 'row',
            paddingTop: 15,
            minHeight: 80,
            justifyContent: 'center',
            '& > .person-avatar': {
                height: 50,
                width: 50
            },
            '& > .participant-info': {
                alignItems: 'flex-start'
            }
        }
    },
    backButton: {
        width: '24px',
        height: '24px',
        color: 'white'
    },
    backButtonWrapper: {
        position: 'relative',
        top: '40px',
        zIndex: '9999',
        width: 'fit-content',
        height: 'fit-content',
        padding: 0,
        left: 12,
        background: 'transparent',
        '&:hover, &:focus': {
            background: 'transparent'
        }
    },
    headerWrapper: {
        position: 'relative',
        top: '15px'
    },
    dialpadWrapper: {
        marginBottom: '16px'
    }
})

export default styles
