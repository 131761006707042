import React, { Component } from 'react'
import { connect } from 'react-redux'
import api from '../util/api_v5.js'
import CallSelectorItem from './CallSelectorItem.js'
import { updateCalls, updateCall } from '../actions/calls'
import ScrollerWrapper from 'scroller-wrapper'
import { withStyles } from '@material-ui/core'
import gtmDataPush from 'gtm-events'
import PropTypes from 'prop-types'

const GTM_APP_NAME = 'calls-selector'
const GTM_MAP = { ALREADY_ACTIVE: 0, NOT_ACTIVE: 1 }

const mapStateToProps = state => ({
    calls: state.calls
})

const mapDispatchToProps = dispatch => ({
    updateCalls: calls => dispatch(updateCalls(calls)),
    updateCall: call => dispatch(updateCall(call))
})

const styles = theme => ({
    newCallLogInfoWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '60px',
        display: 'flex',
        zIndex: 1,
        background: 'linear-gradient(gray, transparent)',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'top .25s',
        '&>span': {
            fontWeight: 'bold',
            color: 'green',
            background: 'rgba(255,255,255,0.7)',
            padding: '3px 10px',
            border: '2px solid green',
            borderRadius: '17px'
        },
        '&.disappear': {
            top: '-60px'
        }
    },
    noCallMessage: {
        marginTop: '15px',
        padding: '0 10px',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        verticalAlign: 'middle',
        textAlign: 'center',
        '&>span': {
            color: '#b6c0c5',
            fontWeight: 'bold',
            lineHeight: 1.29
        }
    }
})

class CallsSelector extends Component {
    state = {}

    isActive = callLog => {
        if (!this.props.currentCall) return false
        const sameCallId = callLog.id === this.props.currentCall.id
        const sameStartTime = callLog.start_time === this.props.currentCall.start_time
        return sameCallId && sameStartTime
    }

    onCallLogClick = callLog => {
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: 'call-item-click',
            PDC_Value: (!this.props.currentCall || callLog.id !== this.props.currentCall.id) ? GTM_MAP.NOT_ACTIVE : GTM_MAP.ALREADY_ACTIVE
        })
        this.props.switchCallLog(callLog)
    }

    loadMore = async () => {
        const calls = this.props.calls
        const cursor = calls.items[calls.items.length - 1].cursor
        const responseCalls = await api.loadCalls({}, 15, cursor)
        if (responseCalls === 'network-error') return
        const responseCallItems = responseCalls?.items || []
        responseCallItems.forEach(c => {
            c.recording.loading = true
            c.voicemail.loading = true
            this.props.updateCall(c)
        })
        if (responseCallItems.length) this.props.getRecordings(responseCallItems)
        // Load the new storred contacts of each newly-loaded call and store them in redux
        this.props.loadExtraContacts()
    }

    scrollerUpdateCalls = callItems => {
        const calls = this.props.calls
        calls.items = callItems
        this.props.updateCalls(calls)
        calls.items.forEach((c) => {
            this.props.updateCall(c)
        })
    }

    render = () => {
        const { classes } = this.props
        const calls = this.props.calls
        const callItems = calls.items || []
        const hasMore = callItems.length ? calls.total - calls.items.length > 0 : false

        return (
            <div style={{ minHeight: 0, height: '100%' }}>
                {calls.items.length === 0
                    ? <div className={classes.noCallMessage}>
                        <span>
                            You have no calls
                            {/* <br/>Start a call by clicking on the button above. */}
                        </span>
                    </div>
                    : <>
                        <div className={`${classes.newCallLogInfoWrapper} ${this.state.newCall ? '' : 'disappear'}`}>
                            <span>New Call Log Received</span>
                        </div>
                        <ScrollerWrapper
                            reverseScroll = {false}
                            loadMore = {this.loadMore}
                            hasMore = {hasMore}
                            items = {this.props.calls.items}
                            updateItems = {this.scrollerUpdateCalls}
                            smallView = {this.props.smallView}
                        >
                            {callItems.map((callLog, index) =>
                                <CallSelectorItem
                                    key = {`${callLog.id}${callLog.start_time}`}
                                    call = {callLog}
                                    onClick = {() => this.onCallLogClick(callLog)}
                                    active = {this.isActive(callLog)}
                                    screenViewType = {this.props.screenViewType}
                                    deleteCall = {this.props.deleteCall}
                                    changeReadStatus = {this.props.changeReadStatus}
                                    extraContacts = {this.props.extraContacts}
                                    placeholderHeight = {callLog.placeholderHeight}
                                    dontShow = {callLog.dontShow}
                                    makeCall ={this.props.makeCall}
                                    openDialer ={this.props.openDialer}
                                    canPlaceOutgoingCall={this.props.canPlaceOutgoingCall}
                                    dataTestId ={`call-selector-item-${index}`}
                                    extension ={this.props.extension}
                                    myCallerInfo ={this.props.myCallerInfo}
                                    setMyCallerInfo ={this.props.setMyCallerInfo}
                                    onCallRecent={this.props.onCallRecent}
                                />
                            )}
                        </ScrollerWrapper>
                    </>
                }
            </div>
        )
    }
}

CallsSelector.propTypes = {
    currentCall: PropTypes.object,
    switchCallLog: PropTypes.func,
    calls: PropTypes.array,
    updateCall: PropTypes.func,
    getRecordings: PropTypes.func,
    loadExtraContacts: PropTypes.func,
    updateCalls: PropTypes.func,
    classes: PropTypes.object,
    smallView: PropTypes.bool,
    screenViewType: PropTypes.object,
    deleteCall: PropTypes.func,
    changeReadStatus: PropTypes.func,
    extraContacts: PropTypes.array,
    makeCall: PropTypes.func,
    openDialer: PropTypes.func,
    canPlaceOutgoingCall: PropTypes.bool,
    extension: PropTypes.object,
    myCallerInfo: PropTypes.object,
    setMyCallerInfo: PropTypes.func,
    onCallRecent: PropTypes.func

}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CallsSelector))
