<template>
    <div class="d-flex justify-space-between" data-test-id="mass-operations">
        <slot></slot>
        <div class="d-flex my-auto">
            <w-btn
                @click="show_delete_selected_modal = true"
                color="error"
                :disabled="!selected"
                text
                data-test-id="mass-operations-delete-selected"
            >
                <v-icon>$vuetify.icons.trash</v-icon>
                {{ l.t("app.delete-selected", "Delete selected") }}
            </w-btn>
            <w-btn
                v-if="filters_applied"
                color="error"
                @click="show_delete_filtered_modal = true"
                text
                data-test-id="mass-operations-delete-filtered"
            >
                <v-icon>$vuetify.icons.trash</v-icon>
                {{ l.t("app.delete-filtered", "Delete all filtered") }}
            </w-btn>
            <w-btn
                v-else-if="!disable_delete_all"
                color="error"
                @click="show_delete_all_modal = true"
                text
                data-test-id="mass-operations-delete-all"
            >
                <v-icon>$vuetify.icons.trash</v-icon> {{ l.t("app.delete-all", "Delete all") }}
            </w-btn>
        </div>
        <ConfirmDeletion
            v-model="show_delete_selected_modal"
            @closed="show_delete_selected_modal = false"
            @confirmed="on_delete_selected"
        >
            <template v-slot:title>
                {{l.t('app.are-you-sure-you-want-to-delete-selected-items', 'Are you sure you want to delete the selected items?')}}
            </template>
        </ConfirmDeletion>
        <ConfirmDeletion
            v-model="show_delete_filtered_modal"
            @closed="show_delete_filtered_modal = false"
            @confirmed="on_delete_filtered"
        >
            <template v-slot:title>
                {{l.t('app.are-you-sure-you-want-to-delete-filtered-items', 'Are you sure you want to delete the filtered items?')}}
            </template>
        </ConfirmDeletion>
        <ConfirmDeletion
            v-model="show_delete_all_modal"
            @closed="show_delete_all_modal = false"
            @confirmed="on_delete_all"
        >
            <template v-slot:title>
                {{l.t('app.are-you-sure-you-want-to-delete-all-items', 'Are you sure you want to delete all items?')}}
            </template>
        </ConfirmDeletion>
    </div>
</template>

<script>
  import l from '../../libs/lang';
  import ConfirmDeletion from './modal/ConfirmDeletion.vue';

  export default {
    props: ['selected', 'filters_applied', 'disable_delete_all'],
    components: { ConfirmDeletion },
    data() {
      return {
        l,
        show_delete_selected_modal: false,
        show_delete_filtered_modal: false,
        show_delete_all_modal: false,
      };
    },
    methods: {
        on_delete_selected() {
            this.$emit('selected');
            this.$data.show_delete_selected_modal = false;
        },
        on_delete_filtered() {
            this.$emit('all');
            this.$data.show_delete_selected_modal = false;
        },
        on_delete_all() {
            this.$emit('all');
            this.$data.show_delete_all_modal = false;
        }
    }
  };
</script>
