import { CallState } from './enums/CallState'
import { CallType } from './enums/CallType'
// import SipCallManager from "./SipCallManager";
import { CallerInfo } from './interfaces/CallerInfo'
import CallSession from './CallSession'
// import { Click2CallManager } from "./Click2CallManager";

/**
 *
 */
export enum CallFeatures {
    outboundCalling = 'outboundCalling',
    // in the future this could include video, peer2peer, etc
}

/**
 *
 */
export abstract class CallManager {
    abstract doNotDisturb: boolean
    abstract topicCallbacks: any[];
    // abstract callState: CallState;
    abstract calls: { [key: string]: CallSession }
    abstract activeCallId: string | null
    abstract callMode: CallType | null
    abstract callSetupInProgress: boolean
    abstract deniedAudioPermissions: boolean;
    abstract noDeviceFound: boolean;
    abstract myCallerInfo?: CallerInfo
    // protected abstract activeCallerId?: string
    // public abstract setActiveCallerId(callId: string, callerId: string): Promise<any>;
    abstract connect(): Promise<null>;
    abstract test(): void
    abstract hold(): Promise<any>
    abstract unhold(): Promise<any>
    abstract call(callee: string): Promise<void>
    abstract sendDTMF(tone: string): void
    abstract transfer(target: string, attended: boolean): Promise<void>
    abstract mergeCall(callIdToMerge: string): Promise<void>
    abstract muteCurrentLocal(isMuted: boolean): void
    abstract muteCurrentRemote(isMuted: boolean): void
    abstract answerById(id: string): void
    abstract hangupById(id: string, endAll: boolean): void
    abstract muteById(id: string): void
    abstract addSession(session: CallSession): void
    abstract removeSession(session: CallSession): void
    abstract setActiveCall(callId: string): void
    protected abstract onCallNotification(extensionId: number, notification: any): void;
    abstract switchCall(callId: string): Promise<void>
    abstract getMyNumberInfo(): Promise<CallerInfo>
    abstract setMyCallerInfo(callerId: string): void
    abstract supportsById(callId: string): Map<string, boolean>
    abstract supports(is_extension_virtual: boolean): Map<CallFeatures, boolean>;
    abstract changeMicStream(micStream: MediaStream): any

    /**
     * @param topic
     * @param message
     */
    public emit = (topic: any, message: any) => {
        const callbacks = this.topicCallbacks[topic]
        if (callbacks) { callbacks.forEach((callback: (arg0: string) => Function) => callback(message)) } else { console.log(`CallMode - no callback defined for topic ${topic}`) }
    };

    /**
     * @param topic
     * @param callback
     */
    public on = (topic: any, callback: any) => {
        // if (!this.connected) this.connect()
        if (!this.topicCallbacks[topic]) { this.topicCallbacks[topic] = [callback] } else if (!this.topicCallbacks[topic].includes(callback)) {
            this.topicCallbacks[topic].push(callback)
        }
    };

    /**
     *
     */
    public getCallsArray = (): CallSession[] => {
        return Object.keys(this.calls).map(key => this.calls[key])
    }

    /**
     *
     */
    public getActiveCall = (): CallSession | null => {
        if (this.activeCallId) {
            return this.calls[this.activeCallId]
        }
        return null
    }

    /**
     * @param id
     */
    public getCall = (id: string): CallSession | null => {
        if (this.calls[id]) {
            return this.calls[id]
        }
        return null
    }

    /**
     *
     */
    public hasNewIncomingCall = () => {
        return Object.keys(this.calls).map(key => this.calls[key]).filter((c: CallSession) => c.callState === CallState.INCOMING).length > 0
    }

    // public setActiveNumber = (phoneNumber: string) => {
    // 	this.activePhoneNumber = phoneNumber
    // }

    // public getActiveCallerId(): string | undefined {
    //     return this.activeCallerId
    // }
}
