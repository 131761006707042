import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.1.1?node-id=5835%3A5
 *
 * @param {SvgIconProps} props - svg props
 */
export const CallButtonIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z' fill={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M9.41328 11.3115C10.0632 12.7072 11.1482 13.8657 12.495 14.6162L13.5982 13.5874C13.7336 13.4612 13.929 13.4243 14.0965 13.4884C14.633 13.6866 15.2159 13.804 15.8167 13.825C16.0832 13.8343 16.2937 14.06 16.2844 14.3265L16.2253 16.0176C16.216 16.2841 15.9903 16.4945 15.7238 16.4852C11.1739 16.3263 7.61521 12.5101 7.77409 7.96014C7.7834 7.69363 8.00906 7.4832 8.27557 7.49251L9.9715 7.55173C10.238 7.56104 10.4484 7.7867 10.4391 8.0532C10.418 8.6589 10.4946 9.24374 10.6549 9.7927C10.7023 9.96416 10.6569 10.1518 10.5165 10.2827L9.41328 11.3115Z' fill='white'/>
        </SvgIcon>
    )
}

export default CallButtonIcon
