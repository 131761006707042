<template>
	<div>
		<SectionTitle data-test-id="media-loader-select-page-title">
			{{ l.t("media-loader.media-list-title", "Select media you want to update") }}
		</SectionTitle>
		<v-simple-table data-test-id="media-loader-select-page-table">
			<thead data-test-id="table-head">
				<tr data-test-id="head-row">
					<th data-test-id="checkbox">
						<w-checkbox
							:value="media.checkAllItems"
							@change="checkAllItems"
							hide-details="auto"
							data-test-id="media-loader-select-page-select-all-checkbox"
						/>
					</th>
					<th data-test-id="add-type">{{ l.t("app.account-type", "Account type") }}</th>
					<th data-test-id="acc-name">{{ l.t("media.account-name", "Account name") }}</th>
					<th data-test-id="media-name">{{ l.t("media.name", "Media name") }}</th>
					<th data-test-id="media-type">{{ l.t("media.type", "Media type") }}</th>
					<th data-test-id="origin">{{ l.t("media.origin", "Origin") }}</th>
					<th data-test-id="duration">{{ l.t("app.duration", "Duration") }}</th>
					<th data-test-id="extension">{{ l.t("app.extension", "Extension") }}</th>
					<th data-test-id="created">{{ l.t("app.created-at", "Created at") }}</th>
					<th data-test-id="player"><br /></th>
				</tr>
			</thead>
			<tbody data-test-id="table-body">
				<template v-for="(account, a) in media.accounts">
					<tr v-for="(item, i) in account.items" :data-test-id="item.id">
						<td data-test-id="checkbox">
							<w-checkbox
								v-model="item.selected"
								@change="checkSubmission"
								requried
								hide-details="auto"
								:key="`checkbox-${i}-${media.checkAllItems}`"
								:data-test-id="`media-loader-select-page-${i}-${a}-item-checkbox`"
							/>
						</td>
						<td :data-test-id="`media-loader-select-page-${i}-${a}-item-account-lvl`">
							{{
								account.main
									? l.t("app.main-account", "Main account")
									: l.t("app.subaccount", "Subaccount")
							}}
						</td>
						<td data-test-id="acc-name">{{ account.name }}</td>
						<td data-test-id="item-name">
							{{ item.name ? item.name : "&mdash;" }}
						</td>
						<td :data-test-id="`media-loader-select-page-${i}-${a}-item-type`">
							{{
								item.type === "hold_music"
									? l.t("app.music-on-hold", "Music on hold")
									: l.t("app.greeting", "Greeting")
							}}
						</td>
						<td data-test-id="origin">
							{{
								item.origin === "file"
									? l.t("app.file", "File")
									: l.t("media.tts", "Text to Speech")
							}}
						</td>
						<td data-test-id="duration">
							{{ item.duration | formatDuration }}
						</td>
						<td data-test-id="extension">
							{{
								item.extension
									? `#${item.extension.extension}`
									: "&mdash;"
							}}
						</td>
						<td data-test-id="created-at">{{ (new Date(item.created_at)).toLocaleString() }}</td>
						<td data-test-id="player">
							<Player
								:url="`/media/${item['id']}/download`"
								:playing="media.playing"
								@status="media.playing = $event.playing"
								:key="item['id']"
								:data-test-id="`media-loader-select-page-${i}-${a}-item-player`"
							/>
						</td>
					</tr>
				</template>
			</tbody>
		</v-simple-table>
		<div class="d-flex justify-space-between mt-3">
			<w-btn
				@click="
					media.screen = media.csv_media
						? 'results-of-csv-importing'
						: 'search-media'
				"
				id="back"
				type="button"
				data-test-id="media-loader-select-page-back-btn"
			>
				{{ l.t("app.back", "Back") }}
			</w-btn>
			<div>
				<w-btn @click="media.export_media_list()" color="info" data-test-id="media-loader-select-page-export-csv-btn">
					{{ l.t("media-loader.save-results-as-csv", "Save results as .csv file") }}
				</w-btn>
				<w-btn @click="$emit('submitted')" :disabled="disableSubmit" color="primary" class="ml-3" data-test-id="media-loader-select-page-continue-btn">
					{{ l.t("app.continue", "Continue") }}
				</w-btn>
			</div>
		</div>
	</div>
</template>

<script>
import l from '../../../libs/lang';
import Player from '../../elements/Player.vue';
import SectionTitle from '../../elements/SectionTitle.vue';

export default {
	props: ['media'],
	components: { Player, SectionTitle, },
	data() {
		return {
			l,
			disableSubmit: false,
		};
	},
	created() {
		this.checkSubmission();
	},
	methods: {
		checkSubmission() {
			setTimeout(() => {
				this.$data.disableSubmit = true;
				this.$props.media.checkAllItems = true;
				for (const account of this.$props.media.accounts) {
					for (const item of account.items) {
						if (item.selected) {
							this.$data.disableSubmit = false;
						} else {
							this.$props.media.checkAllItems = false;
						}
					}
				}
			}, 1);
			this.$forceUpdate();
		},
		checkAllItems(val) {
			this.$props.media.checkAllItems = val;
			if (this.$props.media.checkAllItems) {
				this.$props.media.selectAllItems();
				this.$data.disableSubmit = false;
			} else {
				this.$props.media.deselectAllItems();
				this.$data.disableSubmit = true;
			}
			this.$forceUpdate();
		},
	},
};
</script>
