<template>
  <div
    class="menus list-page"
    data-test-id="menus-list-page"
  >
    <IndexPage
      :resource="menu"
      :disable_delete_all="menu.items.filter((item) => item.generator && item.generator === 'configure-app').length > 0"
    >
      <template v-slot:filters>
        <FormInput :label="l.t('app.name', 'Name')">
          <w-text-field v-model="menu.filters.name" hide-details='auto' data-test-id="menus-filters-name-input"/>
        </FormInput>
      </template>
      <template v-slot:list>
        <v-simple-table class="list-table" data-test-id="menus-table">
          <thead data-test-id="table-head">
            <tr data-test-id="head-row">
              <th class="select-table-item" data-test-id="checkbox"><br/></th>
              <th data-test-id="name">{{ l.t('app.menu', 'Menu') }}</th>
              <th data-test-id="description">{{ l.t('app.description', 'Description') }}</th>
              <th data-test-id="more-options"></th>
            </tr>
          </thead>
          <tbody data-test-id="table-body">
            <tr
              v-for="(item, i) in menu.items"
              :key="item['id']"
              :class="{'selected-row': item.selected}"
              :data-test-id="`menus-item-${item.id}-row`"
            >
              <template v-if="menu.deleting_item !== item.id">
                <td class="select-table-item" data-test-id="checkbox">
                  <DisabledCheckbox
                    v-if="item.generator && item.generator === 'configure-app'"
                    :data-test-id="`menus-item-${item.id}-checkbox-tooltip-activator`"
                  >
                    <template v-slot:label>
                      {{ l.t('menus.cofigure-menu', 'Configure menu can\'t be deleted.') }}
                    </template>
                  </DisabledCheckbox>
                  <w-checkbox
                    v-else
                    v-model="item.selected"
                    @click="checked($event, item)"
                    :key="`menus-item-${item.id}-${item.selected}`"
                    hide-details="auto"
                    :data-test-id="`menus-item-${item.id}-checkbox`"
                  />
                </td>
                <td class="nowrap overflow-hidden" data-test-id="description">
                  <IndexListItem
                    :route="{
                      name: 'menus.show',
                      params: {
                        id: item.id,
                        _menu: item,
                      },
                    }"
                    :item="item"
                    :title="item.name"
                    :key="item['id']"
                    :data-test-id="`menus-item-${item.id}-title`"
                  />
                </td>
                <td class="w-100" :data-test-id="`menus-item-${item.id}-options`">
                  #{{ item['id'] }} - {{ item | options }}
                </td>
                <td>
                  <MoreOptions :options="more_options(item)" />
                </td>
              </template>
              <td v-else colspan="4" data-test-id="loader">
                <w-loader size="small"/>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
    </IndexPage>
    <ConfirmDeletion
      :value="!!deleting_item_id"
      @closed="deleting_item_id = null"
      @confirmed="menu.delete_item(deleting_item_id)"
    />
  </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import Menu from '../../../models/Menu';
  import IndexPage from '../../elements/IndexPage.vue';
  import MoreOptions from '../../elements/MoreOptions.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import IndexListItem from '../../elements/IndexListItem.vue';
  import DisabledCheckbox from '../../elements/DisabledCheckbox.vue';
  import ConfirmDeletion from '../../elements/modal/ConfirmDeletion.vue';

  export default {
    components: {
      IndexPage,
      FormInput,
      MoreOptions,
      IndexListItem,
      ConfirmDeletion,
      DisabledCheckbox,
    },
    data() {
      return {
        l,
        deleting_item_id: null,
        menu: new Menu(this.$session, vueComponent(this)),
      };
    },
    mounted() {
      this.$parent.$on('userRedirected', (to, from, next) => {
        this.$data.menu.handleUserRedirect(to, from, next);
      });
    },
    methods: {
      more_options(item) {
        const options = [
          {
            cb: () => this.$router.push({
              name: 'menus.show',
              params: {
                id: item.id,
                _menu: item,
              },
            }),
            title: l.t('app.edit', 'Edit')
          }
        ];
        if (!(item.generator && item.generator === 'configure-app')) {
          options.push({
            cb: () => this.$data.deleting_item_id = item.id,
            title: l.t('app.delete', 'Delete'),
          });
        }
        return options;
      },
      checked(event, item) {
        this.menu.checked(event, item);
        this.$emit('forceUpdate');
      }
    },
    filters: {
      'options': function (menu) {
        if (!Array.isArray(menu.options)) return '';
        if (menu.options.length === 0) {
          return l.t('menus.no-options-set', 'No options set');
        }
        if (menu.options.length === 1) {
          return l.t('menus.1-option-set', '1 option set');
        }
        return `${l.t('menus.options-set', '{} options set', [menu.options.length])}`;
      }
    }
  };
</script>
<style scoped>
    #app #main-container > main .content-container > ul {
        margin-bottom: 0;
    }
</style>
