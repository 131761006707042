<template>
    <ReportsWrapper :report="report" data-test-id="call-reports-number">
        <w-loader v-if="loading"/>
        <div v-else>
            <!-- <LogarithmicSwitcher
                v-model="logarithmic"
                class="ml-5"
            /> -->
            <NumberChart
                :items="prepare_items({...report.items})"
                :numbers="report.numbers"
                :logarithmic="logarithmic"
                :formatters="report.formatters"
                :fields="report.fields"
                :key="`logarithmic-${logarithmic}-chart`"
            />
    
            <NumberTable
                :items="prepare_items({...report.items})"
                :numbers="report.numbers"
                :logarithmic="logarithmic"
                :formatters="report.formatters"
                :fields="report.fields"
                :key="`logarithmic-${logarithmic}-table`"
                data-test-id="botttom-table"
          />
        </div>
    </ReportsWrapper>
</template>

<script>
  import { vueComponent } from 'helpers';
  import PerPhoneNumber from '../../models/PerPhoneNumber';
  import ReportsWrapper from '../elements/Report.vue'
  import NumberTable from '../elements/tables/Numbers.vue'
  import NumberChart from '../elements/charts/PerNumber.vue'
  // import LogarithmicSwitcher from '../../../elements/logarithmic-representation-switcher.vue';

  export default {
    components: {
        ReportsWrapper,
        NumberTable,
        NumberChart,
      // LogarithmicSwitcher,
    },
    data() {
      return {
        loading: false,
        logarithmic: false,
        items: null,
        report: new PerPhoneNumber(this.$session, vueComponent(this)),
      };
    },
    async created() {
        await this.$data.report.loadItems();
    },
    methods: {
        prepare_items(itms) {
            if (!itms) return [];
            for (const prop of Object.keys(itms)) {
                if (itms[prop].every((x) => !x.value)) delete itms[prop];
            }
            let items = {}
            if (this.$data.logarithmic) {
                const values = [];
                for (const key of Object.keys(itms)) {
                    items[key].map((x) => values.push(x.value));
                }
              const log_values = this.$data.report.logarithmic_values([...values]);
              for (const key of Object.keys(itms)) {
                items[key] = items[key].map((x) => {
                    x.display_value = log_values[x.value];
                    return x;
                });
              }
            } else {
                for (const key of Object.keys(itms)) {
                  items[key] = itms[key].map((x) => {
                      x.display_value = x.value;
                      return x;
                  });
                }
            }
            return items;
        },
    },
  };
</script>
