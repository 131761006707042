// @flow

import PropTypes from "prop-types";
import LinkifyFork from "../LinkifyFork";


let defaultHrefDecorator = (href) => {
    return href;
};

defaultHrefDecorator.propTypes = {
    href: PropTypes.string,
}

export default defaultHrefDecorator
