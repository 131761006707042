export function setSmallView(boolVal) {
	return {
		type: 'SET_SMALL_VIEW',
		smallView: boolVal
	};
}

export function switchCurrentView(view) {
	return {
		type: 'SWITCH_CURRENT_VIEW',
		view
	}
}