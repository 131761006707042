<template>
  <div v-if="items.length">
    <w-select :label="label" :items="selector_items" :value="val" @input="on_input" :key="`${!!val}-${show_modal}`"/>
    <ValidationError v-if="val && error" class="mt-2">
      {{ error }}
    </ValidationError>
    <AddressModal
      v-if="show_modal"
      :value="modal_value"
      @close="show_modal = false"
      @input="(v) => modal_value = v"
      :address_value="empty_address"
      :prefill_with_cache="false"
      :validation="true"
      :confirm_btn_text="l.t('app.add-address', 'Add address')"
      @confirmed="on_new_address_input"
			@error="on_validation_error"
      >
      <template v-slot:title>
      <slot name="title"/>
    </template>
  </AddressModal>
  </div>
</template>
<script>
  import {emptyAddress} from 'helpers';
  import {stringifyAddress} from 'formatters';
  import l from '../../../libs/lang';
  import AddressModal from '../modal/AddressModal.vue';
  import ValidationError from './FieldValidationError.vue';

  export default {
    props: {
      value: {
        type: Object,
        required: false,
      },
      items: {
        type: Array,
        required: true,
        validator: ((v) => v.every((x) => typeof x === 'object')),
      },
      label: {
        type: String,
      },
      enter_address_label: {
        type: String,
      }
    },
    components: {AddressModal, ValidationError},
    data() {
      return {
        l,
        val: this.$props.value,
        show_modal: false,
        modal_value: false,
        new_address_value: '_enter_new_address',
        error: null,
        empty_address: emptyAddress(),
      };
    },
    methods: {
        on_input(val) {
          this.$data.error = null;
          if (val === this.$data.new_address_value) {
            this.$data.show_modal = true;
            this.$data.modal_value = true;
            this.$data.val = null;
          } else if (val.line_1) {
            this.$data.val = val;
            this.$emit('input', this.$data.val);
          }
        },
        on_new_address_input(val) {
          this.$props.items.push(val);
          this.$data.val = val;
          this.$data.show_modal = false;
          this.on_input(this.$data.val);
          this.$emit('new_address_added', val);
        },
        on_validation_error(err) {
          this.$data.val = null;
          this.$data.error = err ? l.t('app.invalid-address', 'Invalid address') : null;
        },
    },
    computed: {
      selector_items() {
        const items = [];
        if (this.$props.items.length) {
          const unique_addresses = [];
          const stringified_addresses = [];
          for (const address of this.$props.items) {
            const str_addr = JSON.stringify(address).toLowerCase();
            if (!stringified_addresses.includes(str_addr)) {
              unique_addresses.push(address);
              stringified_addresses.push(str_addr);
            }
          }
          for (const addr of unique_addresses) {
            items.push({
              text: stringifyAddress(addr),
              value: addr,
            });
          }
        }
        items.push({
            text: this.$props.enter_address_label ? this.$props.enter_address_label : l.t('app.enter-a-new-address', 'Enter a new address'),
            value: this.$data.new_address_value,
        });
        return items;
      }
    }
  };
</script>
