import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import csrImage from './images/csr_only_back.gif'
const styles = theme => ({
	csrBar: {
		width:			'100%',
		height:			'fit-content',
		padding:		10,
		background:		`#8a8a8a url(${csrImage}) repeat`,
		color:			'#e00',
		fontWeight:		'bold',
		display:		'flex',
		justifyContent:	'center',
		alignItems:		'center',
		textAlign:		'center',
		zIndex:			3,
	}
})

class CSRBar extends Component {
	render = () => <div className={this.props.classes.csrBar}>You are in CSR mode.</div>
}

export default withStyles(styles)(CSRBar)