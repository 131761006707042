/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { daysMap/* , getFormattedScheduleItemsIntersectionTimes */, getScheduleItemsUnionTimes } from './util'
import { getUsDateFormat } from 'time-format'
import { TimeItem } from './timeItem'
import { ScheduleItems } from './scheduleItem'
import { ScheduleItem } from './scheduleItem'

const OpenHoursScheduleName = 'open-hours'
const HolidaysScheduleName = 'holidays'
const CustomHolidaysScheduleName = 'custom-holidays'
// const LunchBreakScheduleName = 'lunch-break'

/***/
export enum BusinessHoursType {
    Open24 = '24',
    OpenCustom = 'custom'
}

// TODO: OpenHours usage and add other types
/***/
export interface OpenHours {
    status: 'on' | 'off'
    items: ScheduleItem
}

/**
 * @param {object} openHoursSchedule
 */
export const getOpenHoursScheduleType = openHoursSchedule => {
    if (!openHoursSchedule) return BusinessHoursType.Open24
    const hasCustomScheduleItem = openHoursSchedule.items.find(scheduleDayItem => (
        scheduleDayItem.start_hour !== 0 ||
        scheduleDayItem.start_minute !== 0 ||
        scheduleDayItem.end_hour !== 0 ||
        scheduleDayItem.end_minute !== 0
    ))
    if (!hasCustomScheduleItem && openHoursSchedule.status === 'on') return BusinessHoursType.Open24
    return BusinessHoursType.OpenCustom
}

// eslint-disable-next-line
const getHoursObject = (start_hour, start_minute, end_hour, end_minute) => ({ start_hour, start_minute, end_hour, end_minute })

/**
 * @param {string} scheduleName
 * @param params
 * @returns {object}
 */
export const getDefaultSchedule = (scheduleName, params?) => {
    const scheduleItems = []
    if (scheduleName === HolidaysScheduleName) {
        scheduleItems.splice(0, 0, ...params.allHolidays.map(holiday => ({
            ...getHoursObject(1, 0, 1, 0),
            holiday: {
                id: holiday.id,
                name: holiday.name
            }
        })))
    } else if (scheduleName === CustomHolidaysScheduleName) {
        // It should be empty by default
    } else {
        // Add the 7 days
        daysMap.forEach(day => {
            const openHoursScheduleItem = {
                day: day.short,
                ...getHoursObject(0, 0, 0, 0)
            }
            const lunchScheduleItem = {
                day: day.short,
                ...getHoursObject(12, 0, 12, 30)
            }
            const scheduleItem = scheduleName === OpenHoursScheduleName ? openHoursScheduleItem : lunchScheduleItem
            scheduleItems.push(scheduleItem)
        })
    }
    const schedule = {
        items: scheduleItems,
        name: scheduleName,
        status: scheduleName === OpenHoursScheduleName ? 'on' : 'off'
    }
    return schedule
}

/***/
export interface ScheduleItemInfo {
    text?: string
    status: string
    from: string
    to: string
    itemStatus?: string
    hasDuplicateName?: boolean
    hasDuplicateDate?: boolean
    useOnce?: boolean
    id?: string
    date?: string
    name?: string
}

/***/
export const getScheduleItemInfo = (text: string, status: string, from: string, to: string): ScheduleItemInfo => ({ text, status, from, to })

/**
 * @param {object} state
 */
export const getSavedSchedules = (state) => {
    const isPlaceholderItem = (item, type = null) => {
        // eslint-disable-next-line
        const compareItem: any = { start_hour: 1, end_hour: 1, start_minute: 0, end_minute: 0 }
        if (type === 'custom-holiday') {
            compareItem.name = 'waph'
            compareItem.start_date = 1609401600
            compareItem.end_date = 1609488000
        } else if (['open-hours', 'lunch-break'].includes(type)) {
            compareItem.day = 'M'
        }
        let isPlaceholder = true
        Object.keys(compareItem).forEach(key => compareItem[key] !== item[key] ? (isPlaceholder = false) : null)
        return isPlaceholder
    }

    /**
     * @param {object} schedule
     */
    const handleLunchSchedule = schedule => {
        const openHoursStatus = state.openHoursSchedule?.status || 'off'
        const lunchPause = getScheduleItemInfo('Are you closed for lunch?', 'off', '10:00 am', '2:00 pm')
        if (schedule) {
            if (!isPlaceholderItem(schedule.items[0], 'lunch-break')) {
                const unionTimes = getScheduleItemsUnionTimes(schedule.items)
                lunchPause.status = schedule.status
                lunchPause.from = unionTimes.from
                lunchPause.to = unionTimes.to
            }
        }
        if (openHoursStatus === 'off') lunchPause.status = 'off'
        return lunchPause
    }

    /**
     * @param {object} schedule
     */
    const handleOpenHoursSchedule = schedule => {
        const status = schedule?.status || 'off'
        const openHours = { status, items: daysMap.map(day => (getScheduleItemInfo(day.day, 'off', '9:00 am', '5:00 pm'))) }
        if (schedule) {
            openHours.items.forEach(openHourItem => {
                const dayShort = daysMap.find(d => d.day === openHourItem.text).short
                const scheduleDayItem = schedule.items.find(scheduleDayItem => scheduleDayItem.day === dayShort)
                const placeholderItem = scheduleDayItem ? isPlaceholderItem(scheduleDayItem, 'open-hours') : null
                openHourItem.status = scheduleDayItem && !placeholderItem ? 'on' : 'off'
                if (!scheduleDayItem || placeholderItem) return
                openHourItem.from = TimeItem.generate12HoursString(scheduleDayItem.start_hour, scheduleDayItem.start_minute)
                openHourItem.to = TimeItem.generate12HoursString(scheduleDayItem.end_hour, scheduleDayItem.end_minute)
            })
        }
        return openHours
    }

    /**
     * @param {object} schedule
     */
    const handleHolidaysSchedule = schedule => {
        const holidays = { status: schedule?.status || 'off', items: [] }
        if (schedule) {
            state.allHolidays.forEach(holiday => {
                let holidayItem = schedule.items.find(scheduleHolidayItem => holiday?.id === scheduleHolidayItem?.holiday?.id)
                if (!holidayItem) {
                    const from = TimeItem.generate12HoursString(9, 0)
                    const to = TimeItem.generate12HoursString(17, 0)
                    holidayItem = getScheduleItemInfo(holiday.name, 'closed', from, to)
                    holidayItem.itemStatus = 'off'
                } else {
                    if (
                        holidayItem.start_hour === 1 &&
                        holidayItem.start_minute === 0 &&
                        holidayItem.end_hour === 1 &&
                        holidayItem.end_minute === 0
                    ) {
                        const from = TimeItem.generate12HoursString(9, 0)
                        const to = TimeItem.generate12HoursString(17, 0)
                        holidayItem = getScheduleItemInfo(holiday.name, 'closed', from, to)
                    } else {
                        const from = TimeItem.generate12HoursString(holidayItem.start_hour, holidayItem.start_minute)
                        const to = TimeItem.generate12HoursString(holidayItem.end_hour, holidayItem.end_minute)
                        holidayItem = getScheduleItemInfo(holiday.name, 'custom', from, to)
                    }
                    holidayItem.itemStatus = 'on'
                }
                holidays.items.push(holidayItem)
            })
        }
        return holidays
    }

    /**
     * @param {object} schedule
     */
    const handleCustomHolidaysSchedule = schedule => {
        const customHolidays = { status: schedule?.status || 'off', items: [] }
        if (schedule) {
            schedule.items.forEach(customHolidayInfo => {
                if (isPlaceholderItem(customHolidayInfo, 'custom-holiday')) return
                let from = TimeItem.generate12HoursString(customHolidayInfo.start_hour, customHolidayInfo.start_minute)
                let to = TimeItem.generate12HoursString(customHolidayInfo.end_hour, customHolidayInfo.end_minute)
                let status = 'custom'
                if (from === '1:00 am' && to === '1:00 am') {
                    from = '9:00 am'
                    to = '5:00 pm'
                    status = 'closed'
                }
                const date = getUsDateFormat(customHolidayInfo.start_date)
                const customHoliday = {
                    id: customHolidayInfo.id,
                    from,
                    to,
                    status,
                    date,
                    useOnce: customHolidayInfo.use_once,
                    name: customHolidayInfo.name
                }
                customHolidays.items.push(customHoliday)
            })
        }
        return customHolidays
    }

    const openHours = handleOpenHoursSchedule(state.openHoursSchedule)
    const lunchPause = handleLunchSchedule(state.lunchSchedule)
    const holidays = handleHolidaysSchedule(state.holidaysSchedule)
    const customHolidays = handleCustomHolidaysSchedule(state.customHolidaysSchedule)

    return { openHours, lunchPause, holidays, customHolidays }
}

/**
 * @param {object} state
 */
export const checkHasChange = (state): boolean => {
    if (state.allHolidays === null) return false

    /**
     * @param {object} item1
     * @param {object} item2
     */
    const areScheduleItemsSame = (item1, item2): boolean => {
        if (!item1 || !item2) return false
        const scheduleItems = new ScheduleItems([item1, item2])
        return scheduleItems.areSame()
    }

    /**
     * @param {object} savedOpenHours
     * @param {object} currentOpenHours
     */
    const checkOpenHoursChange = (savedOpenHours, currentOpenHours): boolean => {
        const hasCustomSavedOpenHoursItem: boolean = (new ScheduleItems(savedOpenHours.items)).hasCustomScheduleItem()
        const hasCustomCurrentOpenHoursItem: boolean = (new ScheduleItems(currentOpenHours.items)).hasCustomScheduleItem()
        const currentStateIsOpen24 = state.openHoursScheduleType === BusinessHoursType.Open24 || !hasCustomCurrentOpenHoursItem
        if (currentStateIsOpen24 === hasCustomSavedOpenHoursItem) return true
        let hasChange = false
        savedOpenHours.items.forEach(savedDayInfo => {
            if (hasChange) return
            const currentDayInfo = currentOpenHours.items.find(currentDayInfo => currentDayInfo.text === savedDayInfo.text)
            if (!areScheduleItemsSame(savedDayInfo, currentDayInfo)) {
                hasChange = true
            }
        })
        return hasChange
    }

    /**
     * @param {object} savedLunchPause
     * @param {object} currentLunchPause
     * @param savedOpenHours
     * @param currentOpenHours
     */
    const checkLunchChange = (savedLunchPause, currentLunchPause, savedOpenHours, currentOpenHours): boolean => {
        // const hasCustomSavedOpenHoursItem: boolean = (new ScheduleItems(savedOpenHours.items)).hasCustomScheduleItem()
        // const hasCustomCurrentOpenHoursItem: boolean = (new ScheduleItems(currentOpenHours.items)).hasCustomScheduleItem()
        // if (!hasCustomSavedOpenHoursItem && hasCustomCurrentOpenHoursItem) return false
        // if (currentOpenHours.status === savedLunchPause.status && savedLunchPause.status === 'off') return false
        const hasChange = !areScheduleItemsSame(savedLunchPause, currentLunchPause)
        return hasChange
    }

    /**
     * @param {object} savedHolidays
     * @param {object} currentHolidays
     */
    const checkHolidaysChange = (savedHolidays, currentHolidays): boolean => {
        if (savedHolidays.status !== currentHolidays.status) return true
        let hasChange = false
        savedHolidays.items.forEach(savedHolidayInfo => {
            if (hasChange) return
            const currentHolidayInfo = currentHolidays.items.find(currentHolidayInfo => currentHolidayInfo.text === savedHolidayInfo.text)
            if (!areScheduleItemsSame(savedHolidayInfo, currentHolidayInfo)) hasChange = true
        })
        return hasChange
    }

    /**
     * @param {object} savedCustomHolidays
     * @param {object} currentCustomHolidays
     */
    const checkCustomHolidaysChange = (savedCustomHolidays, currentCustomHolidays): boolean => {
        if (savedCustomHolidays.status === 'off' && currentCustomHolidays.status === 'on' && !currentCustomHolidays.items.length) return false
        if (savedCustomHolidays.status !== currentCustomHolidays.status) return true
        let hasChange = false
        if (savedCustomHolidays.items.length !== currentCustomHolidays.items.length) return true
        savedCustomHolidays.items.forEach(savedCustomHolidayInfo => {
            if (hasChange) return
            const currentCustomHolidayInfo = currentCustomHolidays.items.find(currentCustomHolidayInfo => currentCustomHolidayInfo.id === savedCustomHolidayInfo.id)
            if (!areScheduleItemsSame(savedCustomHolidayInfo, currentCustomHolidayInfo)) hasChange = true
        })
        return hasChange
    }

    const { openHours, lunchPause, holidays, customHolidays } = getSavedSchedules(state)
    const currentOpenHours = state.openHours
    const currentLunchPause = state.lunchPause
    const currentHolidays = state.holidays
    const currentCustomHolidays = state.customHolidays
    const openHoursChange = checkOpenHoursChange(openHours, currentOpenHours)
    const lunchChange = checkLunchChange(lunchPause, currentLunchPause, openHours, currentOpenHours)
    const holidaysChange = checkHolidaysChange(holidays, currentHolidays)
    const customHolidaysChange = checkCustomHolidaysChange(customHolidays, currentCustomHolidays)
    const hasChange = openHoursChange || lunchChange || holidaysChange || customHolidaysChange

    return hasChange
}

/**
 * @param {object} state
 */
export const generateSaveData = state => {
    const openHoursScheduleType = state.openHoursScheduleType

    const getOpenHoursScheduleData = () => {
        const openHoursSchedule = state.openHoursSchedule
        const openHours = { ...state.openHours }
        const openHoursScheduleData: any = {
            id: openHoursSchedule?.id,
            status: 'on' // It is always true for open-hours.
        }
        openHoursScheduleData.items = openHours.items.map(dayInfo => {
            dayInfo = { ...dayInfo }
            dayInfo.day = daysMap.find(d => d.day === dayInfo.text).short
            delete dayInfo.text
            const savedDayInfo = openHoursSchedule.items.find(savedDayInfo => savedDayInfo.day === dayInfo.day)
            if (savedDayInfo) dayInfo.id = savedDayInfo.id
            if (openHoursScheduleType === BusinessHoursType.Open24) {
                dayInfo.status = 'on'
                dayInfo.from = (new TimeItem({ hours: 0, minutes: 0 })).toString12()
                dayInfo.to = (new TimeItem({ hours: 0, minutes: 0 })).toString12()
            }
            dayInfo.from = dayInfo.from.replace(/\s/g, '')
            dayInfo.to = dayInfo.to.replace(/\s/g, '')
            return dayInfo
        })
        if (!openHoursScheduleData.items?.find(i => i.status === 'on')) openHoursScheduleData.status = 'off'
        return openHoursScheduleData
    }

    const getLunchScheduleData = () => {
        const lunchSchedule = state.lunchSchedule
        const openHours = { ...state.openHours }
        openHours.items = openHours.items.filter(i => i.status === 'on')
        const hasOpenHoursSet = openHours.items.length
        const lunchStatus = (!hasOpenHoursSet || openHoursScheduleType === BusinessHoursType.Open24) ? 'off' : state.lunchPause.status // openHoursScheduleType === BusinessHoursType.Open24 added afterwards
        const lunchScheduleData: any = {
            id: lunchSchedule?.id,
            status: lunchStatus
        }
        if (lunchStatus === 'on') {
            lunchScheduleData.items = openHours.items.map(dayInfo => {
                const lunchPause = { ...state.lunchPause }
                // const intersection = getFormattedScheduleItemsIntersectionTimes(dayInfo, lunchPause)
                // if (!intersection) return null
                // Object.assign(lunchPause, intersection)
                lunchPause.day = daysMap.find(d => d.day === dayInfo.text).short
                const savedLunchDayInfo = lunchSchedule.items.find(savedDayInfo => savedDayInfo.day === lunchPause.day)
                if (savedLunchDayInfo) lunchPause.id = savedLunchDayInfo.id
                lunchPause.from = lunchPause.from.replace(/\s/g, '')
                lunchPause.to = lunchPause.to.replace(/\s/g, '')
                return lunchPause
            }).filter(d => d)
        }
        return lunchScheduleData
    }

    const getHolidaysScheduleData = () => {
        const allHolidays = state.allHolidays
        const holidaysSchedule = state.holidaysSchedule
        const holidaysStatus = openHoursScheduleType === BusinessHoursType.Open24 ? 'off' : state.holidays.status
        const holidaysScheduleData: any = {
            id: holidaysSchedule?.id,
            status: holidaysStatus
        }
        if (holidaysStatus === 'on') {
            holidaysScheduleData.items = state.holidays.items.map(holidayInfo => {
                holidayInfo = { ...holidayInfo }
                const holiday = allHolidays.find(h => h.name === holidayInfo.text)
                holidayInfo.holiday = { ...holiday }
                delete holidayInfo.text
                if (holidayInfo.status === 'closed') {
                    holidayInfo.from = (new TimeItem({ hours: 1, minutes: 0 })).toString12()
                    holidayInfo.to = (new TimeItem({ hours: 1, minutes: 0 })).toString12()
                }
                holidayInfo.date = '11/11/2000'
                holidayInfo.status = holidayInfo.itemStatus
                delete holidayInfo.itemStatus
                const savedHolidayInfo = holidaysSchedule.items.find(savedHolidayInfo => savedHolidayInfo?.holiday?.name === holidayInfo?.holiday?.name)
                if (savedHolidayInfo) holidayInfo.id = savedHolidayInfo.id
                holidayInfo.from = holidayInfo.from.replace(/\s/g, '')
                holidayInfo.to = holidayInfo.to.replace(/\s/g, '')
                return holidayInfo
            })
        }
        if (!holidaysScheduleData.items?.some(item => item.status === 'on')) holidaysScheduleData.status = 'off'
        return holidaysScheduleData
    }

    const getCustomHolidaysScheduleData = (): any => {
        const customHolidaysStatus = openHoursScheduleType === BusinessHoursType.Open24 ? 'off' : state.customHolidays.status
        const customHolidaysSchedule = state.customHolidaysSchedule
        const customHolidaysScheduleData: any = {
            id: customHolidaysSchedule?.id,
            status: customHolidaysStatus
        }
        if (customHolidaysStatus === 'on') {
            customHolidaysScheduleData.items = state.customHolidays.items.map(customHolidayInfo => {
                customHolidayInfo = { ...customHolidayInfo }
                if (customHolidayInfo.status === 'open') customHolidayInfo.status = 'off'
                else {
                    if (customHolidayInfo.status === 'closed') {
                        customHolidayInfo.from = (new TimeItem({ hours: 1, minutes: 0 })).toString12()
                        customHolidayInfo.to = (new TimeItem({ hours: 1, minutes: 0 })).toString12()
                    }
                    customHolidayInfo.status = 'on'
                }
                const savedCustomHolidayInfo = customHolidaysSchedule.items.find(savedCustomHolidayInfo => savedCustomHolidayInfo.id === customHolidayInfo.id)
                customHolidayInfo.use_once = customHolidayInfo.useOnce
                delete customHolidayInfo.useOnce
                if (savedCustomHolidayInfo) customHolidayInfo.id = savedCustomHolidayInfo.id
                else delete customHolidayInfo.id
                customHolidayInfo.from = customHolidayInfo.from.replace(/\s/g, '')
                customHolidayInfo.to = customHolidayInfo.to.replace(/\s/g, '')
                return customHolidayInfo
            })
        }
        if (!customHolidaysScheduleData.items?.length) customHolidaysScheduleData.status = 'off'
        return customHolidaysScheduleData
    }

    return {
        openHoursSchedule: getOpenHoursScheduleData(),
        lunchSchedule: getLunchScheduleData(),
        holidaysSchedule: getHolidaysScheduleData(),
        customHolidaysSchedule: getCustomHolidaysScheduleData()
    }
}
