import React from 'react'
import { Grid } from '@material-ui/core'
import Typography from 'typography'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { verticalOptions, businessTypeOptions } from '../utils/util'

const styles = makeStyles((theme) => ({
    topMarginSpacing: {
        marginTop: '1rem'
    },
    gridJustifyContent: {
        justifyContent: 'space-between',
        "& [class*='MuiTypography-root']": {
            wordWrap: 'break-word'
        }
    },
    websiteWrap: {
        wordWrap: 'break-word'
    }
}))

/**
 *
 */
const PreviewCompanyDetails = ({ enrollment }) => {
    const classes = styles()

    const createdDate = enrollment.created_date
    const d = new Date(0)
    d.setUTCSeconds(createdDate)

    const year = d.toLocaleString('default', { year: 'numeric' })
    const month = d.toLocaleString('default', { month: '2-digit' })
    const day = d.toLocaleString('default', { day: '2-digit' })

    return (<Grid
        className={classes.gridJustifyContent}
        container
        justifyContent="end"
        direction='row'
        spacing={1}>
        <Grid direction='column' item xs={12} sm={3}>
            <Typography variant='h5' remoteConfigID='sms_enrollment_brand_details' />
            <Typography className={classes.topMarginSpacing} variant='body1' remoteConfigID='sms_enrollment_brand_column_name' />
            <Typography variant='subtitle1'>{enrollment.name}</Typography>
            <Typography className={classes.topMarginSpacing} variant='body1' remoteConfigID='sms_enrollment_preview_business_type' />
            <Typography variant='subtitle1'>{businessTypeOptions.find(x => x.value === enrollment.business_type_name)?.label }</Typography>
            <Typography className={classes.topMarginSpacing} variant='body1' remoteConfigID='sms_enrollment_ein' />
            <Typography variant='subtitle1'>{enrollment.business_identifier}</Typography>
            <Typography className={classes.topMarginSpacing} variant='body1' remoteConfigID='sms_enrollment_preview_vertical' />
            <Typography variant='subtitle1'>{verticalOptions.find(x => x.id === enrollment.business_vertical_name)?.displayName }</Typography>
            {enrollment.stock_symbol !== '' && (
                <>
                    <Typography className={classes.topMarginSpacing} variant='body1' remoteConfigID='sms_enrollment_preview_stock_symbol' />
                    <Typography variant='subtitle1'>{enrollment.stock_symbol}</Typography>
                </>
            )}
        </Grid>
        <Grid direction='column' item xs={12} sm={3}>
            <Typography variant='h5' remoteConfigID='sms_enrollment_address_details' />
            <Typography className={classes.topMarginSpacing} variant='body1' remoteConfigID='sms_enrollment_postal_code' />
            <Typography variant='subtitle1'>{enrollment.zip}</Typography>
            <Typography className={classes.topMarginSpacing} variant='body1' remoteConfigID='sms_enrollment_country' />
            <Typography variant='subtitle1'>{enrollment.country}</Typography>
            <Typography className={classes.topMarginSpacing} variant='body1' remoteConfigID='sms_enrollment_registered_column_name' />
            <Typography variant='subtitle1'>{`${year}-${month}-${day}`}</Typography>
            <Typography className={classes.topMarginSpacing} variant='body1' remoteConfigID='sms_enrollment_state' />
            <Typography variant='subtitle1'>{enrollment.state}</Typography>
            <Typography className={classes.topMarginSpacing} variant='body1' remoteConfigID='sms_enrollment_address' />
            <Typography variant='subtitle1'>{enrollment.address}</Typography>
            <Typography className={classes.topMarginSpacing} variant='body1' remoteConfigID='sms_enrollment_city' />
            <Typography variant='subtitle1'>{enrollment.city}</Typography>
        </Grid>
        <Grid direction='column' item xs={12} sm={3}>
            <Typography variant='h5' remoteConfigID='sms_enrollment_other_details' />
            {enrollment.company !== '' && (
                <>
                    <Typography className={classes.topMarginSpacing} variant='body1' remoteConfigID='sms_enrollment_website' />
                    <Typography className={classes.websiteWrap} variant='subtitle1'>{enrollment.company}</Typography>
                </>
            ) }
            <Typography className={classes.topMarginSpacing} variant='body1' remoteConfigID='sms_enrollment_reference_id' />
            <Typography variant='subtitle1'>{enrollment.csp_brand_id}</Typography>
            <Typography className={classes.topMarginSpacing} variant='body1' remoteConfigID='sms_enrollment_business_phone' />
            <Typography variant='subtitle1'>{enrollment.mobile_phone}</Typography>
        </Grid>
    </Grid>)
}
PreviewCompanyDetails.propTypes = {
    enrollment: PropTypes.object
}

export default PreviewCompanyDetails
