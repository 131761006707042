/**
 * @param {object} calls
 */
export function updateCalls (calls) {
    return {
        type: 'UPDATE_CALLS',
        calls
    }
}

/**
 * @param {object} call
 */
export function updateCall (call) {
    return {
        type: 'UPDATE_CALL',
        call
    }
}

/**
 * @param {object} call
 */
export function deleteCall (call) {
    return {
        type: 'DELETE_CALL',
        call
    }
}

/**
 * @param {object} call
 */
export function addCall (call) {
    return {
        type: 'ADD_CALL',
        call
    }
}

/**
 * @param {object} recordings
 */
export function addRecordings (recordings) {
    return {
        type: 'ADD_RECORDINGS',
        recordings
    }
}

/**
 * @param {object} contacts
 */
export function addContactsToCalls (contacts) {
    return {
        type: 'ADD_CONTACTS_TO_CALLS',
        contacts
    }
}

/**
 * @param {number} contactId
 */
export function removeContactFromCalls (contactId) {
    return {
        type: 'REMOVE_CONTACT_FROM_CALLS',
        contactId
    }
}

/**
 * @param {string} callerId
 */
export function switchCallerId (callerId) {
    return {
        type: 'SWITCH_CALLER_ID',
        callerId
    }
}
