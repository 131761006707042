import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A8568
 *
 * @param {SvgIconProps} props - svg props
 */
export const MicOffIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M15.9374 14.6376C15.0058 15.9141 13.5396 16.6836 12.0002 16.6836C9.64871 16.6836 7.46818 14.8882 7.10251 12.233C7.04268 11.7985 6.69331 11.4409 6.25475 11.4409C5.81618 11.4409 5.45606 11.7979 5.50435 12.2338C5.85243 15.3758 8.2361 17.8838 11.066 18.3489V20.5656C11.066 21.0815 11.4842 21.4998 12.0002 21.4998C12.5161 21.4998 12.9344 21.0815 12.9344 20.5656V18.3489C14.5671 18.086 16.0513 17.1402 17.0817 15.7819L15.9374 14.6376ZM14.722 13.4221C14.1406 14.2496 13.1421 14.7941 12.0003 14.7941C10.191 14.7941 8.73053 13.4268 8.73053 11.733V7.43072L14.722 13.4221ZM8.82244 4.83563C9.16935 3.49339 10.4581 2.5 12.0003 2.5C13.8096 2.5 15.2701 3.86729 15.2701 5.56109L15.26 11.2732L8.82244 4.83563ZM18.0469 14.0601L16.789 12.8022C16.8346 12.6173 16.8711 12.4275 16.8979 12.233C16.9577 11.7985 17.3071 11.4409 17.7456 11.4409C18.1842 11.4409 18.5443 11.7979 18.4963 12.2339C18.4258 12.873 18.2716 13.4856 18.0469 14.0601Z' fill={color}/>
            <path d='M3.35355 4.69682C3.15829 4.50155 3.15829 4.18497 3.35355 3.98971L3.98995 3.35331C4.18521 3.15805 4.50179 3.15805 4.69706 3.35331L20.607 19.2632C20.8022 19.4585 20.8022 19.7751 20.607 19.9703L19.9706 20.6067C19.7753 20.802 19.4587 20.802 19.2635 20.6067L3.35355 4.69682Z' fill={color}/>
        </SvgIcon>
    )
}

export default MicOffIcon
