<template>
  <w-loader v-if="loading"/>
  <div v-else>
    <w-form @submit="submited" @changed="may_submit = true" data-discard="true" data-test-id="trunk-form">
      <FormInput :label="l.t('app.name', 'Name')">
        <w-text-field v-model="trunk.name" :rules="[$validation.required(), $validation.minlength(1)]" hide-details='auto' data-test-id="trunk-name-input"/>
      </FormInput>
      <FormInput :label="l.t('app.uri', 'Uri')">
        <w-text-field v-model="trunk.uri" placeholder="ex. SIP/%e@1.2.3.4:5060" :rules="[$validation.required(), $validation.regex(/^SIP(.+)@(.+)$/)]" hide-details='auto' data-test-id="trunk-uri-input"/>
      </FormInput>
      <FormInput :label="l.t('trunks.max-concurrent-calls', 'Max. concurrent calls')">
        <w-text-field type="number" :rules="[$validation.numeric(), $validation.min(0)]" v-model="trunk.max_concurrent_calls" hide-details='auto' data-test-id="trunk-max-conc-calls-input"/>
      </FormInput>
      <FormInput :label="l.t('trunks.max-minutes-month', 'Max. minutes/month')">
        <w-text-field type="number" :rules="[$validation.numeric(), $validation.min(0)]" v-model="trunk.max_minutes_per_month" hide-details='auto' data-test-id="trunk-max-min-month-input"/>
      </FormInput>
      <FormInput :label="l.t('trunks.max-call-duration', 'Max. call duration')">
        <w-text-field type="number" :rules="[$validation.numeric(), $validation.min(0)]" v-model="trunk.max_call_duration" hide-details='auto' data-test-id="trunk-max-call-duration-input"/>
      </FormInput>
      <FormInput>
        <w-btn type="submit" :disabled="!may_submit" class="primary" data-test-id="trunk-submit-btn">
          {{ l.t("app.save", "Save") }}
        </w-btn>
      </FormInput>
    </w-form>
  </div>
</template>

<script>
import l from '../../libs/lang';
import FormInput from '../elements/form/FormInput.vue';

export default {
  props: ['_trunk'],
  components: {
    FormInput,
  },
  data() {
    return {
      l,
      loading: false,
      trunk: null,
      may_submit: false,
    };
  },
  created() {
    this.$data.trunk = this.$props._trunk;
  },
  methods: {
    async submited(e) {
      if (e) e.preventDefault();
      this.prepareTrunk();
      this.$emit('submited', this.$data.trunk);
    },
    prepareTrunk() {
      for (const property of [
        'max_concurrent_calls',
        'max_minutes_per_month',
        'max_call_duration',
      ]) {
        if (!Number.isNaN(this.$data.trunk[property])) {
          this.$data.trunk[property] = parseInt(this.$data.trunk[property]);
        } else {
          this.$data.trunk[property] = null;
        }
      }
    },
  },
};
</script>
