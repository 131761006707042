<template>
<div>
  <IndexPage
    :resource="contact"
    :withExtensionSelector="true"
    :extensionCb="'extensionChanged'"
    class="list-page contacts"
    data-test-id="contacts-list-page"
  >
    <template v-slot:filters>
      <FormInput :label="l.t('app.name', 'Name')">
        <w-text-field v-model="contact.filters.name" hide-details='auto' data-test-id="contacts-filters-name-input"/>
        <w-switch
          v-model="contact.filters.exact"
          :label="l.t('app.exact-match', 'Exact match')"
          hide-details='auto'
          data-test-id="contacts-filters-exact-match-switch"
        />
      </FormInput>
      <FormInput :label="l.t('app.phone-number', 'Phone number')">
        <w-text-field v-model="contact.filters.phone" hide-details='auto' data-test-id="contacts-filters-phone-input"/>
      </FormInput>
      <template v-if="contact.groups">
          <FormInput :label="l.t('app.contact-group', 'Contact group')">
            <w-select
              v-model="contact.filters.group_id"
              :items="[{value: null, text: l.t('app.all', 'All')}, {value: 0, text: l.t('app.no-group', 'No group')}].concat(contact.groups.map((x) => ({ value: x.id, text: x.name })))"
              hide-details="auto"
              data-test-id="contacts-filters-groups-select"
            />
          </FormInput>
      </template>
    </template>
    <template v-slot:additionalFilterButtons>
      <w-btn
          type="button"
          @click="contact.generate_csv()"
          class="primary--text text--darken-1 primary lighten-5 mr-5"
          color="primary"
          data-test-id="contacts-get-csv-btn"
      >
          {{ l.t("app.get-csv", "Get CSV") }}
      </w-btn>
    </template>

    <template v-slot:list>
      <v-simple-table v-if="contact.items && contact.items.length" class="list-table" data-test-id="contacts-table">
          <thead data-test-id="table-head">
              <tr data-test-id="head-row">
                  <th class="select-table-item" data-test-id="checkbox">
                      <br/>
                  </th>
                  <th data-test-id="id">{{ l.t("app.id", "ID") }}</th>
                  <th data-test-id="name">{{ l.t("app.name", "Name") }}</th>
                  <th data-test-id="numbers">
                      {{ l.t("app.phone-numbers", "Phone numbers") }}
                  </th>
                  <th data-test-id="company">{{ l.t("app.company", "Company") }}</th>
                  <th data-test-id="group">{{ l.t("app.group", "Group") }}</th>
                  <th data-test-id="more-options"></th>
              </tr>
          </thead>
          <tbody data-test-id="body">
              <tr
                v-for="(item, i) in contact.items"
                :key="item['id']"
                :class="{ 'selected-row': item.selected }"
                :data-test-id="item.id"
              >
                  <td class="select-table-item" data-test-id="checkbox">
                    <w-checkbox
                      v-model="item.selected"
                      @change="checkChanged"
                      :key="`contacts-item-${item.id}-${item.selected}`"
                      hide-details="auto"
                      :data-test-id="`contacts-item-${i}-checkbox`"
                    />
                  </td>
                  <td data-test-id="id">
                    {{ item.id }}
                  </td>
                  <td data-test-id="link-name">
                      <router-link
                        v-if="$branding.data.routes.includes('contacts.show')"
                        :to="{
                          name: 'contacts.show',
                          params: {
                              extension_id: contact.extension,
                              id: item.id,
                              _contact: item
                          },
                        }"
                        class="list-link"
                        :data-test-id="`contacts-item-${i}-link`"
                      >
                        {{item | name}}
                      </router-link>
                      <template v-else>
                        {{item | name}}
                      </template>
                  </td>
                  <td v-if="item.phone_numbers.length" data-test-id="phones">
                      <template v-for="phone in item.phone_numbers">
                          {{ phone.type }}: {{ phone.number | formatPhoneNumber }} <br/>
                      </template>
                  </td>
                  <td v-else data-test-id="empty-phones">
                      &mdash;
                  </td>
                  <td v-if="item.company" data-test-id="company">{{ item.company }}</td>
                  <td v-else data-test-id="no-company">&mdash;</td>
                  <td v-if="item.group" data-test-id="group">
                    <a @click="contact.filterByGroup(item.group.id)" :data-test-id="`contacts-item-${i}-group-link`">{{ item.group.name }}</a>
                  </td>
                  <td v-else data-test-id="empty-group">&mdash;</td>
                  <td data-test-id="more-options">
                  <MoreOptions :options="more_options(item)" />
                  </td>
              </tr>
          </tbody>
      </v-simple-table>
    </template>
  </IndexPage>
  <ConfirmDeletion
      :value="!!deleting_item_id"
      @closed="deleting_item_id = null"
      @confirmed="contact.delete_item(deleting_item_id)"
    />
  </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import { assemble_display_name } from 'formatters';
  import l from '../../../libs/lang';
  import Contact from '../../../models/Contact';
  import IndexPage from '../../elements/IndexPage.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import MoreOptions from '../../elements/MoreOptions.vue';
  import ConfirmDeletion from '../../elements/modal/ConfirmDeletion.vue';

  export default {
    components: {
      IndexPage,
      FormInput,
      MoreOptions,
      ConfirmDeletion
    },
    data() {
      return {
        l,
        deleting_item_id: null,
        contact: new Contact(this.$session, vueComponent(this)),
      };
    },
    created() {
      this.$data.contact.loading = true;
    },
    mounted() {
      this.$parent.$on('userRedirected', (to, from, next) => {
        this.$data.contact.handleUserRedirect(to, from, next);
      });
    },
    methods: {
      more_options(item) {
        return [
          {
            cb: () => this.$router.push({
              name: 'contacts.show',
              params: {
                extension_id: this.contact.extension,
                id: item.id,
                _contact: item
              },
            }),
            title: l.t('app.edit', 'Edit')
          },
          {
            cb: () => this.$data.deleting_item_id = item.id,
            title: l.t('app.delete', 'Delete'),
          }
        ];
      },
      checkChanged() {
        this.$data.contact.checkSubmission();
        this.$emit('forceUpdate');
      }
    },
    filters: {
      name(contact) {
        return assemble_display_name(contact);
      },
    },
  };
</script>
