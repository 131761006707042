<template>
    <div v-if="$branding.data.code === 'default'" class="cancel-account-note">
      <v-icon size="20" color="text lighten-1">$vuetify.icons.infotip_hot</v-icon>
      <span class="text--text text--lighten-1 w-caption">
        <slot name="cancelText"/>
      </span>
    </div>
  </template>
  <script>
    import l from '../../libs/lang';
  
    export default {
      data() {
        return {
          l,
        };
      },
    };
  </script>
  