/* eslint no-extend-native: "off" */
import l from 'lang'
import CallReport from './CallReport'

/**
 *
 */
export default class Traffic extends CallReport {
    /**
     *
     * @param {object} session
     * @param {object} component
     */
    constructor (session, component) {
        super(
            session,
            component,
            [
                'count_incoming',
                'count_outgoing',
                'total'
            ],
            'traffic',
            'hour'
        )
        Date.prototype.addHours = function (h) {
            this.setTime(this.getTime() + h * 60 * 60 * 1000)
            return this
        }
        Date.prototype.substractHours = function (h) {
            this.setTime(this.getTime() - h * 60 * 60 * 1000)
            return this
        }
        Array.prototype.insert = function (index, item) {
            this.splice(index, 0, item)
        }
    }

    /**
     *
     */
    set headers (v) {
        console.log(`${v} is not proper header value`)
    }

    /**
     *
     */
    get headers () {
        const headers = Object.keys(this.fields).reduce((a, c) => {
            a[c] = this.fields[c].translation
            return a
        }, {})
        const group_by = this.filters.group_by.charAt(0).toUpperCase() + this.filters.group_by.slice(1).split('_').join(' ').split('-').join(' ')
        headers[this.filters.group_by] = `${l.t(`reports.${this.filters.group_by}`, group_by)}`

        return headers
    }

    /**
     *
     * @param {Array} items
     * @returns
     */
    prepare_items (items) {
        if (!items.length) return items
        const { group_by } = this.filters
        if (!group_by) {
            return (this.alert = {
                level: 'error',
                message: l.t(
                    'reports.please-provide-group-by',
                    'Please provide group by parameter.'
                )
            })
        }
        items.sort((a, b) => (a[group_by] > b[group_by] ? 1 : -1))
        if (items.length === 1) return this.prepare_one_traffic_item(items)
        let time = new Date(items[0][group_by] * 1000)
        const control_time = new Date(items[items.length - 1][group_by] * 1000)
        for (let i = 0; i < items.length && time >= control_time; i++) {
            if (items[i][group_by] * 1000 !== time.getTime()) {
                const newItem = {
                    count_incoming: 0,
                    count_outgoing: 0,
                    total: 0
                }
                newItem[group_by] = Math.round(time.getTime() / 1000)
                items.insert(i, newItem)
            }
            time = this.constructor.addProperHours(time, group_by)
        }
        return items
    }

    /**
     *
     * @param {Array} items
     * @returns
     */
    prepare_one_traffic_item (items) {
        const { group_by } = this.filters
        // eslint-disable-next-line prefer-const
        let { start, end } = this.findProperStartAndEnd(items, group_by)
        const hours = []
        while (start <= end) {
            hours.push(new Date(start.getTime()))
            start = this.constructor.addProperHours(start, group_by)
        }
        for (let i = 0; i < hours.length; i++) {
            if (items[0][group_by] * 1000 !== hours[i].getTime()) {
                const newItem = {
                    count_incoming: 0,
                    count_outgoing: 0,
                    total: 0
                }
                newItem[group_by] = Math.round(hours[i].getTime() / 1000)
                items.push(newItem)
            }
        }
        items.sort((a, b) => (a[group_by] > b[group_by] ? 1 : -1))

        return items
    }

    /**
     *
     * @param {object} time
     * @param {string} group_by
     * @returns
     */
    static addProperHours (time, group_by) {
        if (group_by === 'date') {
            time.setDate(time.getDate() + 1)
        } else if (group_by === 'week') {
            time.setDate(time.getDate() + 7)
        } else {
            time.addHours(1)
        }

        return time
    }

    /**
     *
     * @param {Array} items
     * @param {string} group_by
     * @returns
     */
    findProperStartAndEnd (items, group_by) {
        const item_time = new Date(items[0][group_by] * 1000)
        let start = new Date(this.filters.start)
        let end = new Date(this.filters.end)
        start.setMinutes(0)
        start.setSeconds(0)
        end.setMinutes(0)
        const diff = Math.abs(start.getTime() - end.getTime()) / 3600000
        // eslint-disable-next-line no-nested-ternary
        const hoursToBeSubstracted = group_by === 'date' ? 24 : group_by === 'week' ? 24 * 7 : 1
        // eslint-disable-next-line no-nested-ternary
        const minimum_difference = group_by === 'date'
            ? 24 * 3
            : group_by === 'week'
                ? 24 * 3 * 7
                : 1 * 3
        if (diff < minimum_difference) {
            item_time.substractHours(hoursToBeSubstracted)
            start = new Date(item_time.getTime())
            item_time.addHours(hoursToBeSubstracted * 2)
            end = new Date(item_time.getTime())
        }
        return { start, end }
    }

    /**
     *
     * @param {Array} original_items
     * @returns
     */
    prepare_csv_values (original_items) {
        const items = JSON.parse(JSON.stringify(original_items.filter((x) => x.total)))
        for (const item of items) {
            item[this.filters.group_by] = this.formatTime(item[this.filters.group_by])
        }

        return items
    }
}
