<template>
	<div v-if="(devices && devices.length) || (accessories && accessories.length)" class="mt-3 step-1">
	  <div id="type-filters">
	    <div class="d-md-flex justify-space-between">
	      <div class="w-body-2 mt-4 px-2">
	        {{ $lang.t('devices.which-devices-suit-you', 'Which devices suit you best?') }} <!-- TODO grey line -->
	      </div>
	      <div class="text-field mx-2">
			<w-select
				v-model="type"
				:items="types"
				id="phone-type-select"
				@change="change_filter()"
				class="bg-white"
				hide-details="auto"
				data-test-id="buy-device-which-suits-you-select"
			/>
		  </div>
		  <v-spacer></v-spacer>
		  <div ref="cart_container" class="text--text text--lighten-1 cart-container" :class="{ 'mt-3': $vuetify.breakpoint.smAndDown}">
			<div @click="toggle_cart_modal" class="d-flex justify-end" :class="{'cursor-pointer': cartLength()}">
				<div class="cart-with-badge">
					<transition name="slide-fade-right-left">
						<span v-if="current_cart.length" id="cart-items-number">{{ cartLength() }}</span>
					</transition>
					<v-icon size="30" color="#29921B">$vuetify.icons.cart</v-icon>
				</div>
				<div class="w-body-2">
					<div class="text-right mr-2">
						<span class="nowrap text--text">{{$lang.t('devices.users-available', 'Users available')}}:&nbsp;</span>
						<span class="text--text display-available-users">{{ available_users_number() }}</span>
					</div>
					<div class="text--text text--lighten-1 cursor-pointer">
						<w-tooltip :activator="tooltip_activator" class="cursor-pointer tooltip" top :max-width="250">
							<template v-slot:activator class="pb-5 mt-n5">
								{{ $lang.t('app.what-is-this', 'What is this?') }}
							</template>
							{{ $lang.t('devices.available-devices-infotip', 'You can only purchase new phones if you have available users or extensions. Available users must be on a Pro or Plus plan and not have any phones assigned. Available extensions should be device enabled extensions.')}}
						</w-tooltip>
					</div>
				</div>
			</div>
		  </div>
	    </div>
	  </div>
	  <InfoAlert :value="!available_users_number()" :closable="false">
		{{$lang.t('devices.to-buy-additional-devices', 'To buy additional devices, please add more users or extensions to your account. You can then purchase and assign the device you’re interested in here.')}}
	  </InfoAlert>
	  <div class="products-container">
		<div v-for="(device, i) of devices" :key="`device-${i}`">
			<div
				v-if="!type || type === device.type" mode="out-in"
				class="order-product mr-3 ml-4"
			>
				<transition name="slide-fade">
					<!-- @input:quantity="on_number_input" -->
					<ItemThumbnail
						type="device"
						:device="device"
						:index="i"
						:generate_device_name="generate_device_name"
						:available_users_number="available_users_number"
						:current_cart="current_cart.devices"
						:input_key="input_key"
						@click:details="show_details"
						@click:minus="remove_device"
						@click:plus="add_device"
					/>
				</transition>
			</div>
		</div>
		<div v-for="(ac, i) of accessories" :key="`accessory-${i}`">
			<div
				v-if="!type || type === 'accessories'"
				class="order-product mr-3 ml-4"
			>
				<transition name="slide-fade" mode="out-in">
					<ItemThumbnail
						type="accessory"
						:device="ac"
						:index="i"
						:generate_device_name="generate_device_name"
						:available_users_number="available_users_number"
						:current_cart="current_cart.accessories"
						:input_key="input_key"
						@click:details="show_details"
						@click:minus="remove_accessory"
						@click:plus="add_accessory"
					/>
				</transition>
			</div>
		</div>
	  </div>
	  <StepperButtons
	    @continue="submitted"
		:disable_continue="!cartLength()"
	    :no_back="true"
	  />
	  <DefaultModal
	  	v-if="current_device"
		v-model="show_details_modal"
		@close="show_details_modal = false"
		max-width="500"
	>
		<div class="cart-modal-content device-description-modal-container" :class="{'has-features': !!this.current_device.features}">
			<v-row class="py-0 item_direction">
				<v-col md="5" lg="5" class="py-0">
					<v-img
					:src="this.current_device.image" alt=""
					height="120"
					contain
					/>
				</v-col>
				<v-col md="7" lg="7" class="py-0">
					<div
						class="w-subtitle-1 my-3 info--text text--darken-1"
					>
					{{generate_device_name(current_device)}}
					</div>
					<span class="w-subtitle-1 font-weight-bold">${{ formatPrice(current_device.price.price) }}</span>
					<div class="device-features">
						<p class="pb-0 caption" v-if="this.current_device.description">{{ this.current_device.description }}</p>
						<p class="mb-1 caption" v-if="device_features">
							<span v-for="feature in device_features" :key="feature.name" :data-testid="feature.name">
								{{ feature.name }} <span class="w-caption text--text text--lighten-1">{{ feature.value }}</span>
							</span>
						</p>
						<a
							v-if="current_device.features && current_device.features.user_guide_link"
							:href="current_device.features.user_guide_link"
							:color="'primary'"
							target="_blank"
							class="w-subtitle-3 pl-0"
						>
							{{ $lang.t("call-view-user-guide", "View user guide") }} <v-icon size="16" color="primary">$vuetify.icons.chevron_forward</v-icon>
						</a>
					</div>
				</v-col>
			</v-row>
		</div>
	  </DefaultModal>
	  <DefaultModal v-model="show_cart_modal" @close="show_cart_modal = false" max-width="550">
		<div class="cart-modal-content">
			<div class="w-h5 info--text text--darken-1 cart-modal-title">{{$lang.t('app.cart', 'Cart')}}</div>
			<div v-for="code in Object.keys(current_cart_object)" :key="code">
				<div v-if="current_cart_object[code].quantity" class="cart-modal-item d-flex">
					<v-img
						:src="current_cart_object[code].device.image" alt=""
						max-width="65"
						contain
						class="cart-modal-item-img"
						:data-test-id="`buy-device-cart-modal-item-${code}-image`"
					  />
					  <div>
						<div class="w-body-1">{{ generate_device_name(current_cart_object[code].device) }}</div>
						<div class="w-body-2">{{$lang.t('app.qty', 'Qty')}}: {{current_cart_object[code].quantity}}</div>
					  </div>
					  <v-spacer/>
					  <div class="cart-modal-prices">
						<div class="w-subtitle-2 mb-1">${{ formatPrice(current_cart_object[code].device.price.price * current_cart_object[code].quantity) }}</div>
						<div class="text--text text--lighten-1">(${{ formatPrice(current_cart_object[code].device.price.price) }} {{$lang.t('app.each-lowercase', 'each')}})</div>
					  </div>
				</div>
			</div>
			<div class="cart-modal-total">
				{{$lang.t('devices.sub-total', 'Sub-total (#{} devices)', [cartLength()])}}: (${{order.total_price(current_cart.devices)}})
			</div>
			<div class="cart-modal-buttons">
				<w-btn @click="show_cart_modal=false" text color="primary" class="px-0">
					{{$lang.t('app.continue-choosing-your-phones', 'Continue choosing your phones')}}
				</w-btn>
				<w-btn @click="submitted" color="primary">
					{{$lang.t('devices.set-up-your-phones', 'Set up your phones')}}
					<v-icon size="18">$vuetify.icons.chevron_forward</v-icon>
				</w-btn>
			</div>
		</div>
	  </DefaultModal>
	</div>
</template>

<script>
import { formatPrice as fPrice, formatDeviceModelName } from 'formatters';
import ItemThumbnail from './item-thumbnail.vue';
import InfoLink from '../../../console/src/components/elements/InfoLink.vue';
import InfoAlert from '../../../console/src/components/elements/InfoAlert.vue';
import StepperButtons from '../../../console/src/components/elements/StepperButtons.vue';
import DefaultModal from '../../../console/src/components/elements/modal/DefaultModal.vue';

export default {
	props: {
		types: {
			required: true,
			type: Array,
		},
		devices: {
			required: true,
			type: Array,
		},
		accessories: {
			required: true,
			type: Array,
		},
		_cart: {
			required: true,
			type: Object,
			validator: (v) => Array.isArray(v.devices) && Array.isArray(v.accessories)
		},
		available_users: {
			required: true,
			type: Number,
		},
		order: {
			type: Object,
		}
	},
	components: {
		InfoLink,
		InfoAlert,
		DefaultModal,
		ItemThumbnail,
		StepperButtons,
	},
	data() {
		return {
			type: null,
			current_cart: {devices: [], accessories: []},
			show_cart_modal: false,
			debouncer: null,
			input_key: Date.now(),
			show_details_modal: false,
			current_device: null,
			tooltip_activator: null
		};
	},
	mounted() {
		this.tooltip_activator = this.$refs.cart_container;
	},
	created() {
		this.$data.current_cart = this.$props._cart || {devices: [], accessories: []};
		this.order.gtm_data_push('PDC_Device_Type_Filter',this.devices, null, this.$lang.t('orders.all-devices', 'All devices'))
	},
	methods: {
		change_filter() {
			const filtered_devices = this.devices.filter((x) => x.type === this.type)
			const type_text = this.type ? this.types.find(t => t.value === this.type).text : this.$lang.t('orders.all-devices', 'All devices')
			if(filtered_devices.length) this.order.gtm_data_push('PDC_Device_Type_Filter',filtered_devices, null, type_text)
			else this.order.gtm_data_push('PDC_Device_Type_Filter',this.devices, null, type_text)
		},
		show_details(device) {
			this.current_device = device;
			this.show_details_modal = true;
			this.order.gtm_data_push('PDC_View_Full_Description', device, device.price.price);
		},
		toggle_cart_modal() {
			if (this.$data.current_cart && (this.$data.current_cart.devices.length + this.$data.current_cart.accessories.length)) {
				this.$data.show_cart_modal = true;
				this.order.gtm_data_push('PDC_View_Cart', this.$data.current_cart.devices, this.order.total_price(this.current_cart.devices));
			}
		},
		generate_device_name(device) {
			return formatDeviceModelName(device)
		},
		remove_device(device) {
			this.$emit('removed', device);
			this.order.gtm_data_push('PDC_Device_Removed', device, device.price.price);
		},
		add_device(device) {
			this.$emit('added', device);
			this.order.gtm_data_push('PDC_Device_Selected', device, device.price.price);
		},
		remove_accessory (ac) {
			this.$emit('removed_accessory', ac);
		},
		add_accessory (ac) {
			this.$emit('added_accessory', ac);
		},
		absolute_integer(num) {
			return Math.abs(num ? Number.parseInt(num) : 0);
		},
		// on_number_input(device, num) { // may be used in the future
		// 	if (this.$data.debouncer) {
		// 		clearTimeout(this.$data.debouncer);
		// 	}
		// 	this.$data.debouncer = setTimeout(() => {
		// 		let entered_number = this.absolute_integer(num);
		// 		const devices = this.$data.current_cart.devices.filter((x) => x.id === device.id);
		// 		if (devices.length > entered_number) {
		// 			for (let i = 1; i <= (devices.length - entered_number); i++) {
		// 				this.remove_device(device);
		// 			}
		// 		} else if (devices.length < entered_number) {
		// 			entered_number -= devices.length;
		// 			const available_users = this.$props.available_users - this.$data.current_cart.devices.length;
		// 			if (available_users > 0) {
		// 				if (entered_number > available_users) {
		// 					entered_number = available_users;
		// 				}
		// 				if (entered_number > 0) {
		// 					for (let i = 1; i <= entered_number; i++) {
		// 						this.add_device(device);
		// 					}
		// 				}
		// 			}
		// 		}
		// 		this.$data.input_key = Date.now();
		// 		this.$forceUpdate();
		// 	}, 500);
		// },
		submitted() {
			this.order.gtm_data_push('PDC_Proceed_To_Setup_Phones', this.$data.current_cart.devices, this.order.total_price(this.$data.current_cart.devices));
			this.$emit('submitted', this.$data.current_cart);
		},
		available_users_number() {
			const available_users = this.$props.available_users - this.$data.current_cart.devices.length;
			return available_users < 0 ? 0 : available_users;
		},
		formatPrice(val) {
			return fPrice(val);
		},
		cartLength() {
			return this.$data.current_cart.devices.length + this.$data.current_cart.accessories.length
		},
		total_price() {
			const reduceCb = (a, c) => a += c.price.price;
			return this.$data.current_cart.devices.reduce(reduceCb, 0) + this.$data.current_cart.accessories.reduce(reduceCb, 0);
		},
	},
	watch: {
		show_details_modal(v) {
			if (!v) this.$data.current_device = null;
		},
	},
	computed: {
		current_cart_object() {
			const obj = {};
			for (const type of ['devices', 'accessories']) {
				for (const item of this.$data.current_cart[type]) {
					if (!obj[item.id]) {
						obj[item.id] = {
							quantity: 0,
							device: item,
						};
					}
					obj[item.id].quantity++;
				}
			}
			return obj;
		},
		filtered_devices() {
            return this.devices.filter((x) => x.type === this.type).length;
        },
		device_features() {
			if (!this.current_device.features) return null;
            const filtered_features = [];
            const features = [
                {name: this.$lang.t('devices.supported-until', 'Supported Until: '), value: this.current_device.features.supported_until},
                {name: this.$lang.t('devices.line-key-count', 'Line Key Count: '), value: this.current_device.features.line_key_count},
                {name: this.$lang.t('devices.display-size', 'Display Size: '), value: `${this.current_device.features.display_size} ${this.$lang.t('devices.inches', ' inches')}`},
                {name: this.$lang.t('devices.backlit-display', 'Backlit Display: '), value: this.current_device.features.display_backlit},
                {name: this.$lang.t('devices.color-display', 'Color Display: '), value: this.current_device.features.display_color},
                {name: this.$lang.t('devices.touchscreen-display', 'Touchscreen Display: '), value: this.current_device.features.display_touchscreen},
                {name: this.$lang.t('devices.concurrent-call-limit', 'Concurrent Call Limit: '), value: this.current_device.features.sip_concurrent_calls},
                {name: this.$lang.t('devices.supports-video-calls', 'Supports Video Calls: '), value: this.current_device.features.supports_video_calls},
                {name: this.$lang.t('devices.ethernet', 'Ethernet: '), value: this.current_device.features.connections_ethernet},
                {name: this.$lang.t('devices.dect', 'Power over Ethernet (PoE): '), value: this.current_device.features.connections_poe},
                {name: this.$lang.t('devices.wi-fi', 'Wi-Fi: '), value: this.current_device.features.connections_wifi},
                {name: this.$lang.t('devices.bluetooth', 'Bluetooth: '), value: this.current_device.features.connections_bluetooth},
                {name: this.$lang.t('devices.dect', 'DECT: '), value: this.current_device.features.connections_dect},
                {name: this.$lang.t('devices.usb', 'USB: '), value: this.current_device.features.connections_usb},
                {name: this.$lang.t('devices.headset-connections', 'Headset Connections: '), value: this.current_device.features.connections_headset},
                {name: this.$lang.t('devices.dect-handsets-supported', 'DECT Handsets Supported: '), value: this.current_device.features.supports_dect_handsets},
                {name: this.$lang.t('devices.conference-microphone-range', 'Conference Microphone Range: '), value: this.current_device.features.conf_mic_range},
                {name: this.$lang.t('devices.recommended-participants', 'Recommended Participants: '), value: this.current_device.features.conf_participants},
                {name: this.$lang.t('devices.daisy-chain-support', 'Daisy Chain Support: '), value: this.current_device.features.conf_daisy_chain},
                {name: this.$lang.t('devices.expansion-microphones', 'Expansion Microphones: '), value: this.current_device.features.conf_exp_mics},
                {name: this.$lang.t('devices.hearing-aid-compatibility', 'Hearing Aid Compatibility (HAC): '), value: this.current_device.features.hearing_aid_compatible},
            ];
            for (const item of features) {
                if (item.value !== null) {
                    if (item.value === true) item.value = this.$lang.t('app.yes', 'Yes');
                    if (item.value === false) item.value = this.$lang.t('app.no', 'No');
                    filtered_features.push(item);
                }
            }
            return filtered_features;
        }
	},
};
</script>

<style scoped lang="scss">
.cart-container {
	.cart-with-badge {
		position: relative;
		margin-right: 10px;
		#cart-items-number {
			position: absolute;
			right: -6px;
			top: -5px;
			background: var(--v-primary-darken1);
			border-radius: 50%;
			color: #FFF;
			height: 20px;
			min-width: 20px;
			font-weight: 600;
			font-size: 10px;
			line-height: 20px;
			letter-spacing: -0.1 px;
			text-align: center;
			z-index: 1;
		}
	}
	.infolink-devices {
		margin-top: -5px !important;
	}
	.display-available-users {
		width: 10px;
		margin-right: 3px;
	}
}
.buy-device-no-extensions-alert {
	padding: 15px;
	max-width: 600px;
	margin: 14px auto 20px auto;
	.v-icon {
		margin-right: 6px;
	}
}
.text-field {
	max-width:250px !important;
	width: 250px !important;
}
.infotip-text {
	font-weight: 500;
	font-size: 10px;
	line-height: 14px;
}
.order-product {
	.backordered-wrapper {
		position: absolute;
		top: 20px;
		right: 30px;
		z-index: 1;
		.backordered {
			text-transform: uppercase;
			text-align: right;
			padding: 4px 6px;
			border-radius: 2px;
			background: rgba(255, 204, 177, 0.40);
			width: 100%;
		}
	}
}
.device-description-modal-container {
    overflow-x: hidden !important;
    overflow-y: auto;
    direction: rtl;
	&.has-features {
		height: 365px !important;
	}
}
.item_direction {
    direction:ltr;
}
</style>
