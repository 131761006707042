var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"billing"},[_c('Submenu',{key:("" + (_vm.$lang.detect()) + (_vm.$route.name)),attrs:{"routes":{
				'billing.index': {
					title: _vm.$lang.t('billing.statements', 'Statements'),
				},
				'billing.payment-methods': {
					title: _vm.$lang.t('billing.payment-methods', 'Payment methods'),
					subroutes: ['billing.payment-methods.create']
				},
			}}}),_vm._v(" "),(_vm.$route.name === 'billing.index')?_c('Statements',{attrs:{"data-test-id":"billing-invoices"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'billing.payment-methods')?_c('PaymentMethodsIndex',{attrs:{"data-test-id":"payment-methods-index"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'billing.payment-methods.create')?_c('PaymentMethodsCreate',{attrs:{"data-test-id":"payment-methods-show"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }