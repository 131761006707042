<template>
  <component v-if="!has_router" :is="$attrs.tag || 'span'" @click="on_click" v-bind="$attrs" v-on="$listeners"><slot/></component>
  <router-link v-else :to="to" v-bind="$attrs" v-on="$listeners"><slot/></router-link>
</template>
<script>
  import EventBus from 'event-bus';
  export default {
    props: {
      to: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        has_router: false,
      };
    },
    created() {
        this.$data.has_router = !!this.$router;
    },
    methods: {
      on_click() {
        EventBus.$emit('click:link', this.$props.to);
      }
    }
  };
</script>
