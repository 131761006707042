import BuoyantBop from './sounds/BuoyantBop.wav'
import CheerfulTones from './sounds/CheerfulTones.wav'
import CosmicRing from './sounds/CosmicRing.wav'
import DancingChimes from './sounds/DancingChimes.wav'

/***/
export type RingtoneData = { name: string, url: string }

/**
 *
 */
export type RingtoneDbObj = { id: number, key: string, value: string }

/***/
export const ringtones = [
    {
        name: 'Buoyant Bop',
        url: BuoyantBop
    },
    {
        name: 'Cheerful Tones',
        url: CheerfulTones
    },
    {
        name: 'Cosmic Ring',
        url: CosmicRing
    },
    {
        name: 'Dancing Chimes',
        url: DancingChimes
    },
    {
        name: 'No ringtone',
        url: ''
    }
]
