import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A8542
 *
 * @param {SvgIconProps} props - svg props
 */
export const CallIncomingIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M6.32437 11.3725C7.62411 14.1638 9.79426 16.481 12.4878 17.982L14.6942 15.9244C14.965 15.6719 15.3557 15.5982 15.6909 15.7263C16.7637 16.1228 17.9296 16.3576 19.1313 16.3995C19.6643 16.4181 20.0852 16.8695 20.0665 17.4025L19.9484 20.7846C19.9298 21.3177 19.4785 21.7385 18.9455 21.7199C9.84558 21.4021 2.72822 13.7697 3.04599 4.66979C3.06461 4.13678 3.51593 3.71591 4.04894 3.73453L7.44081 3.85297C7.97382 3.87159 8.39469 4.32291 8.37608 4.85592C8.33377 6.0673 8.48699 7.237 8.80765 8.33492C8.90241 8.67783 8.81168 9.05307 8.53086 9.31495L6.32437 11.3725Z' fill={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M19.3668 8.27694C19.6546 8.27912 19.8908 8.51465 19.8938 8.80249L19.9044 9.83516C19.9074 10.1224 19.6769 10.3529 19.3897 10.3499L14.313 10.2846C14.0257 10.2817 13.7904 10.0464 13.7875 9.7591L13.7221 4.68062C13.7192 4.39406 13.9485 4.16387 14.2351 4.16582L15.2743 4.17289C15.5632 4.17486 15.8 4.41179 15.8017 4.70061L15.8267 7.5022L20.4663 2.86258C20.5639 2.76495 20.7222 2.76495 20.8199 2.86258L21.2095 3.25225C21.3072 3.34988 21.3072 3.50817 21.2095 3.6058L16.5689 8.24644L19.3668 8.27694Z' fill={color}/>
        </SvgIcon>
    )
}

export default CallIncomingIcon
