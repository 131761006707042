import l from '../libs/lang'
import Resource from './Resource'
import setup from '../libs/resources-setups/scheduled-requests'

/**
 *
 */
export default class ApiRequest extends Resource {
    /**
     * @param {object} session
     * @param {object} component
     */
    constructor (session, component) {
        super(session, component, '/scheduled-requests')
        this.setup = setup
        this.body = null
        this._body_stringified = null
        this.api_response = null
        this.scheduled_request = false
        this.item = {
            request_method: 'GET',
            request_uri: `v4/accounts/${session.user.id}`,
            scheduled_at: null,
            tag: '',
            notification_email: null,
            notification_policy: 1
            // comment: '',
        }
        this.request_item = null // for show page
        this._show_additional_options = false
        this.filters = {
            start: null,
            end: null,
            processed_at_start: null,
            processed_at_end: null,
            is_processed: null
        }
    }

    /**
     *
     */
    get body_stringified () {
        return this._body_stringified
    }

    /**
     *
     */
    set body_stringified (val) {
        this.alert = null
        try {
            this._body_stringified = val
            this.body = JSON.parse(val)
        } catch (e) {
            this.alert = {
                level: 'error',
                message: l.t('api-requests.unvalid-json', 'Your JSON doesn\'t have valid structure.')
            }
            this.hide_alert(5)
            this.body = null
        }

        return true
    }

    /**
     *
     */
    get show_additional_options () {
        return this._show_additional_options
    }

    /**
     *
     */
    set show_additional_options (val) {
        this._show_additional_options = val
        if (!val) {
            this.item.notification_email = null
            delete this.item.notification_policy
            delete this.item.comment
        }
    }

    /**
     * @param {object} event
     */
    fileUploaded (event) {
        if (event.target.files.length) {
            if (event.target.files[0].type !== 'application/json') {
                this.alert = {
                    level: 'error',
                    message: l.t('api-requests.only-json-file', 'You can update only .json file.')
                }
                this.hide_alert(5)
            }
            const file = event.target.files[0]
            const reader = new FileReader()
            /**
             * @param {object} e
             */
            reader.onload = (e) => {
                this.body_stringified = e.target.result
            }
            /**
             *
             */
            reader.onerror = () => {
                this.alert = {
                    level: 'error',
                    message: l.t('api-requests.error-json-file', 'Error reading .json file.')
                }
                this.hide_alert(5)
            }
            reader.readAsText(file)
        }
    }

    /**
     * @param {object} params
     */
    async loadItems (params) {
        this.loading = true
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page)

            const items = await this.session.get_list(this.uri, params.limit, offset)

            this.page = params.page
            if (this.page > 1 && !items.items.length) {
                this.component.emit('pageChanged', 1)
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1
                })
            }
            if (this.page === 1 && !this.filters_applied && !items.items.length) {
                return this.component.routerPush('api-requests.create')
            }
            this.items = items.items
            this.finalizeLoadingItems(items)
            this.component.emit('itemsLoaded', JSON.parse(JSON.stringify(items)))
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false

        return this.items
    }

    /**
     *
     */
    async submit () {
        this.loading = true
        if (this.scheduled_request && this.item.scheduled_at) {
            this.api_response = null
            await this.create_item()
        } else {
            await this.send_request()
        }
        this.loading = false
        return true
    }

    /**
     *
     */
    async create_item () {
        if (!this.item.scheduled_at) return null
        try {
            // eslint-disable-next-line prefer-destructuring
            const { scheduled_at } = this.item
            this.item.scheduled_at = Date.parse(this.item.scheduled_at) / 1000
            this.item.request_uri = this.item.request_uri ? `/${this.item.request_uri}` : ''
            this.item.tag = this.item.tag ? this.item.tag : null
            this.item.request_body = this.body
            const new_request = await this.session.create_item(this.baseUri, this.item)
            this.alert = {
                message: l.t('api-requests.successfully-created-request', 'Your request #{} was scheduled for {}.',
                    [
                        new_request.id,
                        (new Date(Date.parse(scheduled_at)).toLocaleString('en-US',
                            {
                                month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'
                            }))
                    ]),
                level: 'success'
            }
            this.hide_alert(5)
            this.item.scheduled_at = scheduled_at
        } catch (err) {
            this.validation_error(err)
            this.hide_alert(5)
        }
        return true
    }

    /**
     *
     */
    async send_request () {
        try {
            this.api_response = await this.session.call_api(this.item.request_method, `/${this.item.request_uri}`, this.body, true)
        } catch (err) {
            if (err.response) {
                this.api_response = err.response
            } else {
                this.validation_error(err)
                this.hide_alert(5)
            }
        }
    }

    /**
     * @param {number} id
     */
    async get_item (id) {
        this.loading = true
        try {
            this.request_item = await this.session.get_item(`${this.baseUri}/${id}`)
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
    }

    /**
     * @param {string} filter
     * @param {string} uri
     */
    static addToUri (filter, uri) {
        if (uri.includes('?')) return `${uri}&${filter}`
        return `${uri}?${filter}`
    }

    /**
     *
     */
    apply_filters () {
        let uri = `${this.baseUri}`
        if (this.filters.start || this.filters.end) {
            const start = Date.parse(this.filters.start)
            const end = Date.parse(this.filters.end)
            if (this.filters.start && this.filters.end) {
                uri = this.constructor.addToUri(`filters[scheduled_at]=between:${start / 1000},${end / 1000}`, uri)
            } else if (!this.filters.end && this.filters.start) {
                uri = this.constructor.addToUri(`filters[scheduled_at]=gte:${start / 1000}`, uri)
            } else {
                uri = this.constructor.addToUri(`filters[scheduled_at]=lte:${end / 1000}`, uri)
            }
        }
        if (this.filters.processed_at_start || this.filters.processed_at_end) {
            const start = Date.parse(this.filters.processed_at_start)
            const end = Date.parse(this.filters.processed_at_end)
            if (this.filters.processed_at_start && this.filters.processed_at_end) {
                uri = this.constructor.addToUri(`filters[processed_at][]=gte:${start / 1000}`, uri)
                uri = this.constructor.addToUri(`filters[processed_at][]=lte:${end / 1000}`, uri)
            } else if (!this.filters.processed_at_end && this.filters.processed_at_start) {
                uri = this.constructor.addToUri(`filters[processed_at]=gte:${start / 1000}`, uri)
            } else {
                uri = this.constructor.addToUri(`filters[processed_at]=lte:${end / 1000}`, uri)
            }
        }
        if ([0, 1].includes(this.filters.is_processed)) {
            uri = this.constructor.addToUri(`filters[is_processed]=${this.filters.is_processed}`, uri)
        }

        this.filters_applied = true
        this.uri = uri
    }

    /**
     *
     */
    async deleteAll () {
        await super.deleteAll(`${this.baseUri}?filters[is_processed]=0`)
    }

    /**
     *
     */
    checkAll () {
        if (!this.items.length) return null

        for (const item of this.items) {
            if (item.processed_status === null) item.selected = true
        }
        if (this.items.find((x) => x.selected)) this.disable_delete_button = false

        return true
    }
}
