import React from 'react'
import Typography from 'typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    installedTitle: {
        color: '#fff!important',
        padding: '0.4rem 1rem',
        borderRadius: '1rem',
        backgroundColor: theme.palette.grey[400],
        top: '.5rem',
        right: '.5rem'
    }
}))

/**
 *
 */
const InstalledBadge = ({ className }: { className?: React.CSSProperties }): JSX.Element => {
    const classes = useStyles()

    return (
        <Typography className={`${classes.installedTitle} ${className}`} variant='subtitle3'>INSTALLED</Typography>
    )
}

export default InstalledBadge
