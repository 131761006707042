import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.1.1?node-id=5628%3A756
 *
 * @param {SvgIconProps} props - svg props
 */
export const HoldButtonIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z' fill={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M9.0835 15.5832C9.0835 15.8593 9.30735 16.0832 9.5835 16.0832H10.771C11.0471 16.0832 11.271 15.8593 11.271 15.5832V8.4165C11.271 8.14036 11.0471 7.9165 10.771 7.9165H9.5835C9.30735 7.9165 9.0835 8.14036 9.0835 8.4165V15.5832ZM12.7293 15.5832C12.7293 15.8593 12.9532 16.0832 13.2293 16.0832H14.4168C14.693 16.0832 14.9168 15.8593 14.9168 15.5832V8.4165C14.9168 8.14036 14.693 7.9165 14.4168 7.9165H13.2293C12.9532 7.9165 12.7293 8.14036 12.7293 8.4165V15.5832Z' fill='white'/>
        </SvgIcon>
    )
}

export default HoldButtonIcon
