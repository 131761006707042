import { getFirstAndLastName, emptyAddress } from 'helpers'
import l from '../libs/lang'
import Resource from './Resource'
import setup from '../libs/resources-setups/live-receptionist'
import LiveAnswerVendor from './LiveAnswerVendor'
class LiveAnswer extends Resource {
    /**
     *
     * @param {object} session PhoenixApiJsClient object
     * @param {object} component helpers vueComponent
     * @param {object} router vueRouter
     */
    constructor (session, component) {
        super(session, component, '/live-answer')
        this.selector_mode = ['live_answer']
        this.setup = setup
        this.filters = {
            name: null,
            vendor_id: null
        }
        this.appointments_loading = false
        this.empty_destination = {
            type: null,
            destination: '',
            is_warm_transfer: false
        }
        this.empty_contact = {
            first_name: '',
            last_name: '',
            title: '',
            call_handling_notes: '',
            destinations: [JSON.parse(JSON.stringify(this.empty_destination))]
        }
        this.empty_business_contact = {
            fax: '',
            address: {
                line_1: '',
                line_2: '',
                city: '',
                province: '',
                zip: '',
                country: ''
            }
        }
        this.original_item = null
        this.item = {
            code: null,
            name: '',
            business_name: '',
            enabled: true,
            business_contact: JSON.parse(JSON.stringify(this.empty_business_contact)),
            business_description: '',
            greeting: '',
            call_handling_notes: '',
            appointments_enabled: false,
            appointments_description: '',
            contacts: [JSON.parse(JSON.stringify(this.empty_contact))],
            vendor: null
        }
        this.avo_plan = {
            id: 551,
            code: 19086,
            type: 'periodic',
            retail: 0
        }
        this.virtual_generic_name = ''
        this.live_generic_name = ''
        this._appointments_loading = false
    }

    /**
     * @param {boolean} val
     */
    set appointments_loading (val) {
        this._appointments_loading = val
        window.scrollTo({ top: 0 })
    }

    /**
     *
     */
    get appointments_loading () {
        return this._appointments_loading
    }

    /**
     *
     * @param {object} params
     * @returns
     */
    async loadItems (params) {
        this.loading = true
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page)
            const prms = ['sort[id]=desc']
            if (this.filters.vendor_id) prms.push(`filters[vendor_id]=eq:${this.filters.vendor_id}`)
            const uri = `${this.uri}${this.uri.includes('?') ? '&' : '?'}${prms.join('&')}`
            const items = await this.session.get_list(uri, params.limit, offset)
            this.page = params.page
            if (this.page > 1 && !items.items.length) {
                this.component.emit('pageChanged', 1)
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1
                })
            }
            if (this.page === 1 && !this.filters_applied && !items.items.length && this.component.has_router) return this.component.routerPush('live-answer.create')

            this.items = items.items
            this.finalizeLoadingItems(items)

            this.component.emit('itemsLoaded', JSON.parse(JSON.stringify(items)))
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false

        return this.items
    }

    /**
     * @param {number} id
     */
    async getUsage (id) {
        let usage
        try {
            usage = this.session.get_list_all(`/live-answer/${id}/usage`)
        } catch (err) {
            this.validation_error(err)
        }
        return usage
    }

    /**
     *
     * @param {object} data
     * @returns
     */
    static prepareData (data) {
        if (!data.appointments_enabled) {
            data.appointments_description = ''
        }
        if (data.contacts && data.contacts.length) {
            data.contacts = data.contacts.map((x) => {
                if (x._unique_key) delete x._unique_key
                if (x.destinations && x.destinations.length) {
                    x.destinations = x.destinations.map((d) => {
                        if (d._unique_key) delete d._unique_key
                        return d
                    })
                }
                return x
            })
        }

        return data
    }

    /**
     *
     */
    async prepoluate_business_profile () {
        this.loading = true
        try {
            const profile = JSON.parse(JSON.stringify(this.session.user.account))
            const contact = profile ? profile.contact : null
            if (contact) {
                this.item.business_name = contact.company
                this.item.business_contact.fax = contact.fax
                this.item.business_contact.address = contact.address ?? emptyAddress()
            }
        } catch (err) {
            console.log(err)
        }
        this.loading = false
    }

    /**
     * @param {boolean} user_is_nxt
     */
    async prepopulate_contacts (user_is_nxt) {
        this.item.contacts = []
        const uri = user_is_nxt ? '/extensions?filters[role]=nxt-user' : '/extensions'
        const users = await this.session.get_list_all(uri)
        for (const user of users.items) {
            const { first_name, last_name } = user.name ? getFirstAndLastName(user.name) : { first_name: '', last_name: '' }
            const destinations = [
                {
                    type: 'extension',
                    destination: `${user.id}`,
                    is_warm_transfer: true
                },
                {
                    type: 'voicemail',
                    destination: `${user.id}`,
                    is_warm_transfer: false
                }
            ]
            const user_contact = {
                first_name: first_name,
                last_name: last_name,
                title: '',
                call_handling_notes: '',
                destinations: destinations
            }
            this.item.contacts.push(user_contact)
        }
        return true
    }

    /**
     *
     * @param {number} id
     */
    async deactivate (id) {
        try {
            const updated_item = await this.session.patch_item(
                `${this.baseUri}/${id || this.original_item.id}`, { enabled: false }
            )
            this.item = { ...updated_item }
            this.original_item = JSON.parse(JSON.stringify(updated_item))
            this.updateCache(updated_item)
            this.successfulAlert(l.t('app.successfully-disabled', 'Successfully disabled.'))
        } catch (err) {
            console.log(err, this)
            this.validation_error(err, true)
        }
    }

    /**
     * @param {Array} routes_ids
     * @param {number} script_id
     */
    async remove_script_from_routes (routes_ids = [], script_id) {
        if (!routes_ids.length) return null
        const results = { success: [], failed: [] }
        for (const id of routes_ids) {
            const url = `/routes/${id}`
            try {
                const route = await this.session.get_item(url)
                for (const rule of route.rules) {
                    rule.actions = rule.actions.filter(
                        (x) => x.action !== 'live_answer' && (!x.script || (x.script && x.script.id !== script_id))
                    )
                    if (!rule.actions.length) {
                        rule.actions.push({
                            action: 'disconnect'
                        })
                    }
                }
                await this.session.replace_item(url, route)
                results.success.push(id)
            } catch (err) {
                console.log(err)
                results.failed.push(id)
            }
        }

        return results
    }

    /**
     *
     * @param {number} id
     */
    async reactivate (id) {
        this.loading = true
        try {
            const updated_item = await this.session.patch_item(
                `${this.baseUri}/${id || this.original_item.id}`, { enabled: true }
            )
            this.item = { ...updated_item }
            this.original_item = JSON.parse(JSON.stringify(updated_item))
            this.updateCache(updated_item)
            this.successfulAlert(l.t('app.successfully-enabled', 'Successfully enabled.'))
        } catch (err) {
            this.validation_error(err, true)
        }
        this.loading = false
    }

    /**
     *
     * @param {object} item
     * @returns
     */
    async create (item) {
        this.appointments_loading = true
        let newScript
        try {
            const data = this.constructor.prepareData(JSON.parse(JSON.stringify(item)))
            newScript = await this.session.create_item(this.baseUri, data)

            const onvego_success = await this.updateOnvegoAppointments(item, newScript, 1000)
            this.appointments_loading = false

            this.successfulCreation('live-answer.index', null, onvego_success ? null : 4000, newScript)
            if (!onvego_success) {
                this.alert = {
                    level: 'warning',
                    message: l.t('lr.something-went-wrong-with-calendar', 'Something went wrong while saving calendar settings. Please edit the script and try saving it again')
                }
            }
            this.addToCache(newScript)
            this.item = null
        } catch (err) {
            this.appointments_loading = false
            this.item = item
            return this.validation_error(err)
        }
        this.appointments_loading = false

        return newScript
    }

    /**
     * @param {object} item
     * @param {object} script
     * @param {number} timeout
     */
    async updateOnvegoAppointments (item, script, timeout = 1000) {
        // await (new Promise(resolve => setTimeout(resolve, timeout)))
        let onvego_success = true
        if (
            item.vendor &&
            LiveAnswerVendor.vendor_group(item.vendor) === 'ONVEGO' &&
            item.appointments_enabled &&
            item.has_onvego_apointments
        ) {
            console.log('submitAppointments')
            try {
                const appointments_results = await window.submitAppointmentsPanel(this.session.user.id, script.id)
                console.log('appointments_results', appointments_results)
                if (appointments_results === 'submit-failed' && timeout < 30000) {
                    timeout += 1000
                    onvego_success = this.updateOnvegoAppointments(item, script, timeout)
                } else {
                    onvego_success = appointments_results === 'submit-successful'
                }
            } catch (err) {
                console.log('calendar error', err)
                onvego_success = false
            }
        }

        return onvego_success
    }

    /**
     *
     */
    async getVendors () {
        const cache_key = 'live-answer-vendors'
        const cached_vendors = this.cachier.getItem(cache_key)
        if (cached_vendors) return cached_vendors
        const vendors = await this.session.get_list_all('/live-answer-vendors')
        this.cachier.setItem(vendors)
        return vendors
    }

    /**
     * @param {number} id
     */
    async getVendor (id) {
        const vendors = await this.getVendors()
        return vendors.items.find((x) => x.id === id)
    }

    /**
     *
     * @param {object} item
     */
    async update (item) {
        const data = this.prepare_patch_data(this.constructor.prepareData(JSON.parse(JSON.stringify(item))))
        this.appointments_loading = true
        let updatedScript
        try {
            if (Object.keys(data).length) {
                console.log('updates script', this.original_item.id)
                updatedScript = await this.session.patch_item(
                    `${this.baseUri}/${this.original_item.id}`, data
                )
                console.log('script updated', updatedScript.id)
                this.item = JSON.parse(JSON.stringify(updatedScript))
                this.original_item = JSON.parse(JSON.stringify(updatedScript))
                this.updateCache(updatedScript)
            } else {
                this.item = { ...item }
                this.original_item = JSON.parse(JSON.stringify(item))
            }
            const onvego_success = await this.updateOnvegoAppointments(item, this.item, 1)
            this.successfulUpdate('live-answer.index', null, onvego_success ? null : 4000)
            if (!onvego_success) {
                this.alert = {
                    level: 'warning',
                    message: l.t('lr.something-went-wrong-with-calendar', 'Something went wrong while saving calendar settings. Please edit the script and try saving it again')
                }
            }
        } catch (err) {
            this.item = { ...item }
            this.validation_error(err)
        }
        this.appointments_loading = false
        return updatedScript
    }

    /**
     *
     * @param {object} data
     * @returns
     */
    prepare_patch_data (data) {
        delete data.has_onvego_apointments
        for (const prop of ['selected', 'enabled', 'id', 'popup_id', 'did', 'created_at', 'updated_at']) {
            delete data[prop]
        }
        if (data.business_contact) {
            for (const prop of ['id', 'primary_email', 'alternate_email', 'phone', 'selected']) {
                delete data.business_contact[prop]
            }
        }
        if (this.original_item) {
            for (const prop of ['code', 'business_description', 'call_handling_notes', 'greeting', 'name', 'business_name', 'appointments_enabled', 'appointments_description']) {
                if (this.original_item[prop] === data[prop]) {
                    delete data[prop]
                }
            }
            if (this.original_item.business_contact &&
                data.business_contact &&
                (JSON.stringify(this.original_item.business_contact) === JSON.stringify(data.business_contact))
            ) {
                delete data.business_contact
            }
            if (this.original_item.contacts &&
                data.contacts &&
                (JSON.stringify(this.original_item.contacts) === JSON.stringify(data.contacts))
            ) {
                delete data.contacts
            }

            if (this.original_item.vendor && data.vendor && this.original_item.vendor.id === data.vendor.id) {
                delete data.vendor
            }
        }
        return data
    }

    /**
     *
     */
    apply_filters () {
        this.clearMessages()
        this.filters_applied = true
        try {
            let uri = this.baseUri
            const filters = []
            if (this.filters.name) filters.push(`filters[name]=contains:${this.filters.name}`)
            if (this.filters.vendor_id) filters.push(`filters[vendor_id]=eq:${this.filters.vendor_id}`)
            if (filters.length) uri = `${uri}?${filters.join('&')}`
            this.uri = uri
        } catch (err) {
            this.component.emit('failed', err)
        }
    }

    /**
     *
     */
    do_apply_dynamic_filters () {
        try {
            let uri = this.baseUri
            const filters = []
            if (this.filters.name) filters.push(`filters[name]=contains:${this.filters.name}`)
            if (this.filters.vendor_id) filters.push(`filters[vendor_id]=eq:${this.filters.vendor_id}`)
            if (filters.length) uri = `${uri}?${filters.join('&')}`
            this.uri = uri
        } catch (err) {
            this.component.emit('failed', err)
        }
    }

    /**
     *
     * @param {number} id
     * @returns
     */
    async getItem (id) {
        this.loading = true
        try {
            const item = await this.session.get_item(`${this.baseUri}/${id}`)
            this.item = item
            this.original_item = JSON.parse(JSON.stringify(item))
            this.loading = false
            return this.item
        } catch (err) {
            this.item = null
            this.validation_error(err, true)
        }
        this.loading = false
        return false
    }

    /**
     *
     */
    async prepare_generic_names () {
        try {
            const live_name = l.t('lr.prefilled-script-name-virual', 'Live Receptionist script for {}', [this.session.user.account.contact.company])
            const virtual_name = l.t('lr.prefilled-script-name-live', 'AI-Connect script for {}', [this.session.user.account.contact.company])
            const virtual_names = await this.find_genericly_named_scripts(virtual_name)
            const live_names = await this.find_genericly_named_scripts(live_name)
            this.virtual_generic_name = this.constructor.generate_script_name(virtual_name, virtual_names)
            this.live_generic_name = this.constructor.generate_script_name(live_name, live_names)
        } catch (err) {
            console.log('An error occured while preparing generic script names', err)
        }
    }

    /**
     *
     * @param {string} name
     * @returns
     */
    async find_genericly_named_scripts (name) {
        const scripts = await this.session.get_list_all(`/live-answer?filters[name]=contains:${name}`)
        const lowercase_name = name.toLowerCase()

        return scripts.items.filter((x) => x.name.toLowerCase().startsWith(lowercase_name)).map((x) => x.name)
    }

    /**
     *
     * @param {string} base_name
     * @param {Array} names
     * @returns
     */
    static generate_script_name (base_name, names) {
        const lowercase_names = names.map((x) => x.toLowerCase())
        let valid_name = null
        let i = 1
        do {
            const name = `${base_name} ${i}`
            if (!lowercase_names.includes(name.toLowerCase())) {
                valid_name = name
            }
            i++
        } while (!valid_name)
        return valid_name
    }
}

export default LiveAnswer
