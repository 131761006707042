<template>
    <App v-bind="$attrs" v-on="$listeners" :sessionId="sessionId" :onClickLink="onClickLink" :onClickBack="onClickBack">
      <SMS v-if="page === 'sms'" />
      <Types v-else-if="page === 'types'" />
      <Total v-else-if="page === 'total'" />
      <Summary v-else-if="page === 'summary'" />
      <Traffic v-else-if="page === 'traffic'" />
      <CallLogs v-else-if="page === 'call-logs'"/>
      <PerNumber v-else-if="page === 'per-number'" />
      <PerExtension v-else-if="page === 'per-extension'" />
      <GeoDistribution v-else-if="page === 'geo-distribution'" />
    </App>
  </template>

  <script>
  import {
    SMS,
    Types,
    Total,
    Summary,
    Traffic,
    CallLogs,
    PerNumber,
    PerExtension,
    GeoDistribution
  } from 'reports-components'
  import App from '../elements/functional-components/App.vue';
  
  export default {
    props:{
      sessionId: {
        type: String,
        required: false,
      },
      page: {
        type: String,
        required: true,
        validator: (v) => [
            'sms',
            'total',
            'types',
            'summary',
            'traffic',
            'call-logs',
            'per-number',
            'per-extension',
            'geo-distribution',
        ].includes(v),
      },
      onClickLink: {
        type: Function,
      },
      onClickBack: {
        type: Function,
      }
    },
    components: {
      App,
      SMS,
      Types,
      Total,
      Summary,
      Traffic,
      CallLogs,
      PerNumber,
      PerExtension,
      GeoDistribution
    },
  };
  </script>

  <style lang="scss">
      @import 'vue-apps-assets/styles/scss/styles.scss';
  </style>