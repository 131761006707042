export function switchExtension(extension) {
	return {
		type: 'SWITCH_EXTENSION',
		extension
	}
}

export function setIsFaxConfigured(isFaxConfigured) {
	return {
		type: 'SET_IS_FAX_CONFIGURED',
		isFaxConfigured
	}
}