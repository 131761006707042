<template>
    <FormModal
      v-bind="$attrs"
      v-on="$listeners"
      max-width="1000"
      class="account-settings-modal"
      >
      <w-loader v-if="account.loading"/>
      <template v-else>
        <div v-if="title" class="w-h6 mb-5">{{title}}</div>
        <w-alert v-if="account.alert" :level="account.alert.level" :message="account.alert.message" :closable="account.enable_close_alert" @closed="account.alert = null"/>
        <AccountSettings
          v-if="account.item"
          :_account="JSON.parse(JSON.stringify(account.item))"
          :scroll_to="scroll_to"
          @submitted="updateSettings"
          @click:cancel="$emit('input', false)"
          data-test-id="account-settings"
        />
      </template>
    </FormModal>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import Account from '../../../models/Account';
  import FormModal from './FormModal.vue';
  import {AccountSettings} from 'account-settings-components';

  export default {
    props: {
      title: {
        type: String,
      },
      scroll_to: {
        type: String,
      },
    },
    components: {
      FormModal,
      AccountSettings,
    },
    async created() {
      await this.$data.account.getItem();
    },
    data() {
      return {
        l,
        account: new Account(this.$session, vueComponent(this)),
      };
    },
    methods: {
      async updateSettings(account) {
        await this.$data.account.updateSettings(account);
        if (this.$data.account.alert) {
          if (this.$data.account.alert.level === 'success') {
            this.$emit('updated');
          }
          if (this.$data.account.alert.level === 'error') {
            this.$emit('error-on-update');
          }
        }
        this.$global_emitter.$emit('session-user-updated');
        setTimeout(() => this.$emit('input', false), 2000);
      },
    },
  };
</script>
