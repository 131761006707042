import React, { ChangeEvent } from 'react'
import { makeStyles, TextField } from '@material-ui/core'
import styles from './styles'
import Typography from 'typography'
const useStyles = makeStyles(styles)

interface InputFieldProps {
    onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    onKeyUp?: (event) => void
    onKeyDown?: (event) => void
    value: string
    onFocus?: (inFocus: boolean) => void
}
/**
 *
 */
export default function InputField (props: InputFieldProps): JSX.Element {
    const { value, onChange, onKeyUp, onKeyDown } = props
    const classes = useStyles()
    const onFocus = (focus: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => props.onFocus ? props.onFocus(true) : focus
    const onBlur = (focus: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => props.onFocus ? props.onFocus(false) : focus
    return (
        <TextField
            onFocus={onFocus}
            onBlur={onBlur}
            data-test-id='floaing-soft-phone-input-field'
            fullWidth
            autoFocus
            classes={{ root: classes.mainField }}
            onChange={onChange}
            onKeyUp={onKeyUp}
            onKeyDown={onKeyDown}
            label={<Typography variant='subtitle1'>Enter a name or number</Typography>}
            value={value}
            InputProps={{ disableUnderline: true, classes: { root: 'disable-dragging' } }}
            InputLabelProps={{ classes: { root: classes.mainFieldLabel } }}
        />
    )
}
