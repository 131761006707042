import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import popUpCartoon from '../../images/popUpCartoon.svg'
import popUpCartoonBg from '../../images/popUpCartoonBg.jpg'
import Typography from 'typography'

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
        backgroundImage: `url(${popUpCartoonBg})`,
        backgroundSize: 'cover'
    },
    titleContainer: {
        alignSelf: 'center'
    },
    title: {
        color: '#fff',
        fontWeight: 500,
        letterSpacing: '-0.2px',
        textAlign: 'center'
    },
    closeIcon: {
        color: '#fff',
        cursor: 'pointer',
        position: 'absolute',
        top: 0,
        right: 0,
        fontSize: '2.5rem',
        padding: '0.5rem',
        zIndex: 1101,
        opacity: 0.8,
        '&:hover': {
            opacity: 1
        }
    }
}))

/**
 *
 */
const Header = ({ closePopup }: {closePopup: () => void}): JSX.Element => {
    const classes = useStyles()

    return (
        <Box className={classes.root}>
            {/* <img src={popUpCartoonWithBg} alt='Addon Popup cartoon' /> */}
            <CloseIcon className={classes.closeIcon} onClick={closePopup} data-testid='closeIcon' />
            <Grid container>
                <Grid item sm={7}>
                    <img src={popUpCartoon} alt='Popup Cartoon' width="100%" />
                </Grid>
                <Grid item sm={5} className={classes.titleContainer}>
                    <Typography variant='h5' className={classes.title} remoteConfigID='apps_marketplace_addon_popup_title' />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Header
