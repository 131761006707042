<template>
	<div class="mb-3">
	  <div :class="{ 'long-table': !expanded && items && items.length > limit }">
	      <v-simple-table data-test-id="long-table">
	          <thead data-test-id="long-table-head">
	              <tr>
	                <slot name="header"></slot>
	              </tr>
	          </thead>
	          <tbody data-test-id="long-table-body">
	              <slot name="body"></slot>
	          </tbody>
	      </v-simple-table>
	  </div>
      <div v-if="items.length > limit" class="mt-3">
		<w-btn ref="expand_button" @click="expanded = !expanded" id="expand-table-button" block color="info" data-test-id="long-table-show-more-less-btn">
			{{ expanded ? l.t('app.show-less', 'Show less') : l.t('app.show-all', 'Show all ({})', [items.length]) }}
		</w-btn>
		<w-btn
			v-if="expanded && show_additional_button"
			@click="expanded = !expanded"
			ref="additional_button"
			id="additional-button"
			color="info"
			data-test-id="long-table-show-less-btn"
		>
			{{ l.t('app.show-less', 'Show less') }}
		</w-btn>
      </div>
	</div>
</template>

<script>
	import l from '../../libs/lang';

	export default {
	    props: {
	      items: {
			type: Array,
			required: true
	      },
	      limit: {
	        type: Number,
	        default: 20
	      },
	    },
	    data() {
	      return {
			l,
			expanded: false,
			scrollpx: 0,
			show_additional_button: false,
			right_button_position: null,
	      };
	    },
	    methods: {
         handleScroll() {
			const { expand_button } = this.$refs;
			if (!expand_button) return null;
			const bounding = expand_button.$el.getBoundingClientRect();
			if (
				bounding.top >= 0
				&& bounding.left >= 0
				&& bounding.right <= window.innerWidth
				&& bounding.bottom <= window.innerHeight
			) {
				this.$data.show_additional_button = false;
			} else {
				this.$data.show_additional_button = true;
				if (this.$data.right_button_position && this.$refs.additional_button) {
					this.$refs.additional_button.$el.style.right = `${this.$data.right_button_position}px`;
				} else if (this.$refs.additional_button) {
					this.$refs.additional_button.$el.style.right = '3.35rem';
				}
			}
			return true;
         }
		},
		created() {
			const container = document.querySelector('#main-container main');
			const sidebar = document.querySelector('#main-container #sidebar');
			if (container && window.innerWidth > 1024) {
				this.$data.right_button_position = window.innerWidth - container.offsetWidth - (sidebar ? sidebar.offsetWidth : 0) + 50;
			}
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	};
</script>

<style lang="scss" scoped>
	.long-table {
	    height: 40rem;
	    max-height: 40rem;
	    overflow: hidden;
	    -webkit-mask-image: linear-gradient(
			to bottom,
			rgba(0,0,0,1),
			rgba(0,0,0,1) 50%,
			rgba(0,0,0,1) 60%,
			rgba(0,0,0,0.9) 70%,
			rgba(0,0,0,0.7) 80%,
			rgba(0,0,0,0.4) 90%,
			rgba(0,0,0, 0) 100%
		);
	}

	#additional-button {
	  display: block;
	  position: fixed;
	  bottom: 2rem;
	  z-index: 99;
	}
</style>
