import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5628%3A786
 *
 * @param {SvgIconProps} props - svg props
 */
export const VolumeUpIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M3.5 8.99998C3.22386 8.99998 3 9.22384 3 9.49998V14.5C3 14.7761 3.22386 15 3.5 15H6.17157C6.70201 15 7.21071 15.2107 7.58579 15.5858L11.1464 19.1464C11.4614 19.4614 12 19.2383 12 18.7929V5.20709C12 4.76163 11.4614 4.53855 11.1464 4.85353L7.58579 8.41419C7.21071 8.78927 6.70201 8.99998 6.17157 8.99998H3.5ZM17.5 12C17.5 10.5199 16.7868 9.21463 15.6878 8.39406C15.3899 8.17169 15 8.40731 15 8.779V15.2158C15 15.5864 15.3878 15.8221 15.6856 15.6017C16.7858 14.7871 17.5 13.481 17.5 12ZM14.6056 3.38986C14.2985 3.29717 14 3.53537 14 3.85616V4.9171C14 5.13832 14.1462 5.33153 14.3545 5.40594C17.0609 6.37251 19 8.96109 19 12C19 15.0389 17.0609 17.6274 14.3545 18.594C14.1462 18.6684 14 18.8616 14 19.0829V20.1438C14 20.4646 14.2985 20.7028 14.6056 20.6101C18.3073 19.4928 21 16.0616 21 12C21 7.93836 18.3073 4.50713 14.6056 3.38986Z' fill={color}/>
        </SvgIcon>
    )
}

export default VolumeUpIcon
