import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5666%3A114
 *
 * @param {object} props - svg props
 */
export const UserIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.2361 13.0222C16.9277 12.7328 18.784 9.6694 18.4185 6.49503C18.053 3.32066 15.6042 1.23738 12.9125 1.52677C10.2209 1.81616 8.30579 4.36864 8.67127 7.54301C9.03676 10.7174 11.5444 13.3116 14.2361 13.0222ZM9.24438 12.1563C9.25839 12.0255 9.38946 11.9367 9.51028 11.989C10.3423 12.3492 11.0751 13.1322 11.7985 13.9052C12.8739 15.0543 13.9284 16.181 15.2566 15.8627C16.5679 15.5484 16.6229 14.7579 16.5726 14.3931C16.5614 14.3124 16.6384 14.2348 16.7163 14.2589C17.0257 14.3547 17.6429 14.562 17.972 14.7841L18.5691 15.1991C18.9377 15.4553 19.3467 15.6476 19.779 15.768L20.536 15.9789C21.4494 16.2333 22.0589 17.094 21.9955 18.04L21.7905 21.1009C21.7377 21.8884 21.0837 22.5001 20.2944 22.5001H3.22602C2.10943 22.5001 1.0637 21.855 1.00742 20.7398C0.964457 19.8883 1.09673 18.775 1.7019 17.5058C2.42791 15.983 3.06996 15.6256 4.93814 15.3716C6.36898 15.177 6.44591 15.1595 8.16363 14.4799C8.94882 14.1692 9.18106 12.7479 9.24438 12.1563Z" fill={color} />
        </SvgIcon>
    )
}

export default UserIcon
