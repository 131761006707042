import React, {Component} from 'react';

class MediaModalAudio extends Component {
	render() {
		return (
			<audio id='video-tag' className='current-media not-selectable' controls>
				<source id='audio-source-tag' src={this.props.mediaUrl} type={this.props.mimetype} />
				Your browser does not support the audio tag.
			</audio>
		);
	}
}

export default MediaModalAudio;