<template>
	<div class="d-flex" data-test-id="timeinterval-picker">
		<TimeSelector
			v-model="start_time"
			:icons="!small"
			:key="`start-${!!start_time}`"
			data-test-id="timeinterval-start"
			:default_value="default_start_time"
			:disabled="disabled"
		/>
		<span v-if="!small" class="my-auto mx-3">—</span>
		<TimeSelector
			v-model="end_time"
			:icons="!small"
			:key="`end-${!!end_time}`"
			data-test-id="timeinterval-end"
			:default_value="default_end_time"
			:disabled="disabled"
		/>
	</div>
</template>

<script>
	import l from '../../libs/lang';
	import TimeSelector from './form/TimeSelector.vue';

	export default {
		props: [
			'startHour',
			'startMinute',
			'endHour',
			'endMinute',
			'required',
			'disabled',
			'preventClear',
			'small'
		],
		components: { TimeSelector },
		data() {
			return {
				l,
				start_time: null,
				end_time: null,
                default_start_time: null,
                default_end_time: null,
			};
		},
		created() {
			if (
				this.$props.startHour
				|| this.$props.startMinute
				|| this.$props.startHour === 0
				|| this.$props.startMinute === 0
			) {
				const start_hour = this.add_zero_if_needed(this.$props.startHour);
				const start_minute = this.add_zero_if_needed(this.$props.startMinute);
				this.$data.start_time = `${start_hour}:${start_minute}`;
                this.$data.default_start_time = this.$data.start_time;
			} else {
				this.$data.start_time = null;
                this.$data.default_start_time = '09:00';
			}
			if (
				this.$props.endHour
				|| this.$props.endMinute
				|| this.$props.endHour === 0
				|| this.$props.endMinute === 0
			) {
				const end_hour = this.add_zero_if_needed(this.$props.endHour);
				const end_minute = this.add_zero_if_needed(this.$props.endMinute);
				this.$data.end_time = `${end_hour}:${end_minute}`;
                this.$data.default_end_time = this.$data.end_time;
			} else {
				this.$data.end_time = null;
                this.$data.default_end_time = '17:00';
			}
		},
		methods: {
			add_zero_if_needed(val) {
				return `${val < 10 ? '0' : ''}${val}`;
			},
			reset() {
				this.$data.start_time = null;
				this.$data.end_time = null;
			},
			emit_values() {
				const time = {
					start: null,
					end: null,
				};
				if (this.$data.start_time) {
					time.start = this.split_time_to_h_and_min(this.$data.start_time);
				}
				if (this.$data.end_time) {
					time.end = this.split_time_to_h_and_min(this.$data.end_time);
				}
				this.$emit('changed', time);
			},
			split_time_to_h_and_min(val) {
				const time_parts = val.split(':');
				return {
					hour: Number.parseInt(time_parts[0]),
					minute: Number.parseInt(time_parts[1]),
				};
			},
		},
		watch: {
			start_time() {
				this.emit_values();
			},
			end_time() {
				this.emit_values();
			},
			disabled(val) {
                if (!val) this.reset();
                else {
                    this.$data.start_time = this.$data.default_start_time;
				    this.$data.end_time = this.$data.default_end_time;
                }
            }
		},
	};
</script>
<style lang="scss" scoped>
	>>> .v-input__slot::before {
		border-style: none !important;
	}
	.close-time {
		width: 20px;
		max-width: 20px;
	}
</style>
