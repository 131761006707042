
import React, { Component } from 'react'
import { getFormattedTime } from 'time-format'
import { formatPhoneNumber } from 'phone-numbers'
import { withStyles } from '@material-ui/core'
import { CallsNavIcon, VoicemailNavIcon, FaxesNavIcon, MessagesSearchResultIcon, PointingAngleIcon } from 'pdc-svg-icons'
import DisplayName from 'display-name'

const styles = theme => ({
    wrapper: {
        display: 'flex',
        borderBottom: `1px solid ${theme.palette.secondary[-300]}`,
        padding: '13px 60px 15px',
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
            background: theme.palette.secondary[-500],
            '& .go-to-icon-wrapper': {
                background: theme.palette.secondary[-400]
            }
        },
        '&.mobile-view': {
            padding: '20px 40px 20px 38px',
            '& .main-content': {
                marginLeft: 18
            }
        },
        '& .go-to-icon-wrapper': {
            padding: '13px 20px 13px 15px',
            position: 'absolute',
            right: 19,
            top: '50%',
            transform: 'translateY(-50%)',
            borderRadius: '50%',
            width: 50,
            height: 50
        },
        '& .main-content': {
            marginLeft: 26,
            position: 'relative',
            width: '100%',
            '& .participants': {
                fontFamily: 'Montserrat-semi-bold',
                fontWeight: 600,
                fontSize: 18,
                lineHeight: '26px',
                letterSpacing: -0.2,
                marginBottom: 7
            },
            '& .time-info': {
                color: theme.palette.secondary['+100'],
                fontSize: 12,
                lineHeight: '19px',
                letterSpacing: 0.1,
                marginBottom: 5
            },
            '& .text': {
                fontSize: 14,
                lineHeight: '21px'
            }
        }
    }
})

class SearchResultItem extends Component {
    constructor (props) {
        super(props)
        const timestamp = this.props.inbox.created_at
        this.state = {
            timeInfo: timestamp ? getFormattedTime(timestamp, true) : ''
        }
    }

    componentDidMount () {
        this.timeRefreshInterval = setInterval(this.formatTime, 6000)
    }

    componentWillUnmount () {
        clearInterval(this.timeRefreshInterval)
    }

	formatTime = () => {
	    const timestamp = this.props.inbox.created_at
	    if (!timestamp) return ''
	    const formattedTime = getFormattedTime(timestamp, true)
	    if (formattedTime !== this.state.timeInfo) {
	        this.setState({ timeInfo: formattedTime })
	    }
	}

	renderTypeIcon = () => {
	    const inboxType = this.props.inbox.type
	    const TypeIcon = {
	        voicemail:	VoicemailNavIcon,
	        call:	CallsNavIcon,
	        sms:	MessagesSearchResultIcon,
	        mms:	MessagesSearchResultIcon,
	        fax:	FaxesNavIcon
	    }[inboxType] || MessagesSearchResultIcon
	    return <TypeIcon/>
	}

	separateNums = () => {
	    const contact = null
	    const participants = this.props.participants
	    participants.sort(p => p.mine ? 1 : -1) // If the number is mine put it last
	    const allNums		= participants.map(p => formatPhoneNumber(p.number))
	    const myNums		= participants.filter(p => p.mine).map(p => p.name || formatPhoneNumber(p.number))
	    const notMyNums	= participants.filter(p => !p.mine).map(p => p.name || formatPhoneNumber(p.number))
	    return [allNums, myNums, notMyNums, contact]
	}

	renderParticipants = () => {
	    const [allNums, myNums, notMyNums, contact] = this.separateNums()
	    return (
	        <span title={allNums.join(', ')}>
	            {allNums.map((p, i) => {
	                return [i > 0 && ', ', <span key={`${p}${i}`}><DisplayName value={p}/></span>]
	            })}
	        </span>
	    )
	}

	renderContent = () => {
	    const { classes } = this.props
	    // let placeholderImageUrl	= 'https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-512.png'
	    return (
	        <div className='main-content'>
	            <div className='participants fs-block'>{this.renderParticipants()}</div>
	            <div className='time-info'>{this.state.timeInfo}</div>
	            <div className='text fs-block'>
	                <div>{this.props.text}</div>
	                {/* <img src={placeholderImageUrl} alt={imageWarning} className={classes.imagePreview}/> */}
	            </div>
	        </div>
	    )
	}

	renderPointingAngleIcon = () => {
	    if (this.props.isMobile) return null
	    return <div className='go-to-icon-wrapper'><PointingAngleIcon/></div>
	}

	render () {
	    const { classes, isMobile } = this.props
	    const mobileViewClass = isMobile ? 'mobile-view' : ''
	    return (
	        <div className={`${classes.wrapper} ${mobileViewClass}`} onClick={() => this.props.goToConversation(this.props.inbox)}>
	            {this.renderTypeIcon()}
	            {this.renderContent()}
	            {this.renderPointingAngleIcon()}
	        </div>
	    )
	}
}

export default withStyles(styles)(SearchResultItem)
