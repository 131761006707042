import React, {Component} from 'react'
import { connect } from 'react-redux'

import api from '../util/api_v2'
import loader from '../images/loader.gif'
import LoaderFull from 'loader-full'
import MediaModalImage from './MediaModalImage'
import MediaModalVideo from './MediaModalVideo'
import MediaModalAudio from './MediaModalAudio'
import MediaModalOtherType from './MediaModalOtherType'
import MediaModalInformation from './MediaModalInformation'

import { audioIcon, videoIcon, pdfIcon, docIcon, xlsIcon, pptIcon, zipIcon, defaultFileIcon, backBtn, nextBtn } from 'pdc-icons'
import openofficeIcon from '../images/openoffice.jpg'
import { IconDownload, MediaModalXBtn } from 'pdc-svg-icons'

import { Swipeable } from 'react-swipeable'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	mediaModalContent: {
		fontSize:	14,
		position:	'relative',
		height:		'calc(100% - 137px)',
		transition:	'height 0.15s ease-out',
		'& .nav-button': {
			zIndex:			1,
			cursor:			'pointer',
			position:		'absolute',
			top:			'50%',
			transform:		'translateY(-50%)',
			opacity:		0.5,
			width:			60,
			transition:		'visibility 0.5s, opacity 0.5s linear',
			'&.prev': {
				left:	60
			},
			'&.next': {
				right:	60
			},
			'&:hover': {
				opacity: 1
			},
			'&.not-visible': {
				visibility:	'hidden',
				opacity:	0
			}
		},
		'&.mobile-view': {
			fontSize: '9px !important',
			'& .nav-button': {
				'&.prev': {
					left:	30
				},
				'&.next': {
					right:	30
				}
			}
		},
		'&.media-gallery-hidden': {
			height: 'calc(100% - 1px)',
			'& .media-show-hide-caret': {
				borderBottom:	'0.5em solid',
				borderTop:		'none'
			},
			'& .media-gallery-items': {
				height:	0
			}
		}
	},
	mediaModalWrapper: {
		position:		'absolute',
		top:			0,
		bottom:			0,
		left:			0,
		right:			0,
		zIndex:			'1200',
		// background:		'rgba(0, 0, 0, 0.9)',
		background:		'black',
		display:		'flex',
		flexDirection:	'column'
	},
	mediaOptions: {
		position:		'absolute',
		top:			0,
		left:			0,
		right:			0,
		padding:		'20px 60px',
		fontSize:		16.8,
		zIndex:			2,
		transition:		'visibility 0.5s, opacity 0.5s linear',
		display:		'flex',
		justifyContent:	'flex-end',
		alignItems:		'center',
		'&.not-visible': {
			visibility:	'hidden',
			opacity:	0
		},
		'& .option': {
			cursor: 'pointer',
			'&:not(:last-child)': {
				marginRight: 25
			},
			'&:last-child': {
				marginLeft: 28
			}
		}
	},
	mediaNumeration: {
		position:		'fixed',
		left:			'50%',
		bottom:			147,
		fontSize:		14,
		fontWeight:		500,
		color:			'white',
		background:		'black',
		borderRadius:	7,
		padding:		'3px 7px',
		transform:		'translateX(-50%)',
		transition:		'visibility 0.5s, opacity 0.5s linear, bottom 0.15s ease-out',
		'&.not-visible': {
			visibility:	'hidden',
			opacity:	0
		},
		'&.bottom': {
			bottom: 11
		}
	},
	mediaGalleryItems: {
		backgroundColor:	theme.messagesApp.mediaModal.mediaGalleryBackgroundColor,
		height:				137,
		display:			'flex',
		alignItems:			'center',
		minWidth:			'100%',
		transition:			'height 0.15s ease-out'
	},
	galleryItem: {
		backgroundColor:	theme.messagesApp.mediaModal.mediaGalleryBackgroundColor,
		backgroundPosition:	'center',
		backgroundRepeat:	'no-repeat',
		width:				100,
		height:				100,
		margin:				'10px 15px',
		minWidth:			100,
		cursor:				'pointer',
		display:			'inline-block',
		position:			'relative',
		'&.file-icon': {
			width:		77,
			minWidth:	77,
			'& .gallery-item-placeholder': {
				width:	77
			}
		},
		'& .gallery-item-placeholder': {
			color:		'transparent',
			width:		100,
			height:		100,
			display:	'block',
			overflow:	'hidden'
		},
		'& .gallery-item-extension': {
			position:		'absolute',
			bottom:			'0em',
			left:			'0.3em',
			fontSize:		'1.9em',
			fontWeight:		500,
			textTransform:	'uppercase',
			color:			'white',
			textAlign:		'center',
			width:			'1em',
			transform:		'rotate(270deg)',
		},
		'&.selected': {
			outline:		'1px solid white',
			outlineOffset:	4
		}
	},
	mediaShowHide: {
		backgroundColor:	theme.messagesApp.mediaModal.mediaGalleryBackgroundColor,
		position:			'absolute',
		right:				40,
		top:				-25,
		border:				'1px solid #2f2f2f',
		borderBottom:		'none',
		color:				'white',
		padding:			'5px 6px',
		cursor:				'pointer',
		lineHeight:			'14px',
		'&:hover .media-show-hide-text': {
			display:		'inline !important'
		},
		'& .media-show-hide-text': {
			fontSize:		12.5,
			marginRight:	5,
			display:		'none'
		},
		'& .media-show-hide-caret': {
			borderRight:	'0.4em solid transparent',
			borderLeft:		'0.4em solid transparent',
			borderTop:		'0.5em solid',
			width:			0,
			height:			0,
			display:		'inline-block'
		}
	}
})

const mapStateToProps = state => ({smallView: state.smallView})
const mapDispatchToProps = dispatch => ({})

class MediaModal extends Component {

	constructor(props) {

		super(props)

		this.state = {
			mediaGalleryShown:	true,
			reachedFirstMedia:	false,
			reachedLastMedia:	true,
			autoScrollGallery:	true,
			smoothScroll:		false,
			updating:			false,
			closeButtonHovered:	false
		}

		this.lastDbOldLoadTimestamp = (new Date()).getTime()
		this.lastDbNewLoadTimestamp = (new Date()).getTime()
	}

	render() {
		const { classes } = this.props
		return (
			<div className={`${classes.mediaModalWrapper} ${!this.props.isOpened ? ' hidden' : ''}`}>
				{this.renderMediaModalContent()}
			</div>
		)
	}

	componentDidMount() {
		window.addEventListener('keydown', e => {

			if (!this.props.isOpened) return

			if (e.key === 'Escape') {
				this.setState({autoScrollGallery: true})
				this.props.hideModal()
			}

			if (e.key === 'ArrowLeft') {
				this.showPreviousMedia()
			}

			if (e.key === 'ArrowRight') {
				this.showNextMedia()
			}

			if (e.key === 'ArrowDown') {
				this.hideMediaGallery()
			}

			if (e.key === 'ArrowUp') {
				this.showMediaGallery()
			}

			if (e.key === 'f') {
				this.toggleMediaGallery()
			}

			if (e.key === 'Home') {
				this.showFirstShownMedia()
			}

			if (e.key === 'End') {
				this.showLastShownMedia()
			}
		})
	}

	componentDidUpdate() {
		if (this.state.autoScrollGallery) {
			this.scrollGallery()
		} else {

			// In case the gallery items are scrolled to left scroll one pixel to right
			// so that the user can scroll again to left and new items can be added
			let mediaGalleryItems = document.getElementsByClassName('media-gallery-items')[0].children[0]
			if (mediaGalleryItems.scrollLeft === 0) {
				mediaGalleryItems.scrollLeft = 1
			}
		}
	}

	scrollGallery() {
		// Scroll the media gallery so that the selected media is in the center
		let content = this.props.mediaModalContent;
		let element = document.getElementById(this.state.selectedMediaId);
		if (element) {
			let mg = document.getElementsByClassName('media-gallery-items')[0].children[0]
			mg.scrollLeft = element.offsetLeft + element.scrollWidth / 2 - mg.offsetWidth / 2 - 65

		} else {

			if (!content.total) return

			let selectedMediaId = null;
			content.media.forEach((m, i) => selectedMediaId = m.selected ? m.url + i : selectedMediaId);

			if (!selectedMediaId) return
			let element = document.getElementById(selectedMediaId);
			if (!element) return
			let mg = document.getElementsByClassName('media-gallery-items')[0].children[0];
			mg.scrollLeft = element.offsetLeft + element.scrollWidth / 2 - mg.offsetWidth / 2;
			// console.log('Selected Media:', element);
		}
	}

	onSwipe = (direction) => {
		if (direction === 'Left') {
			this.showNextMedia();
		} else {
			this.showPreviousMedia();
		}
	}

	renderMediaModalContent() {

		const { classes } = this.props

		let content = this.props.mediaModalContent
		if (!content.total) return

		let mediaModalContentClasses = `${classes.mediaModalContent} media-modal-content ${this.state.mediaGalleryShown ? '' : ' media-gallery-hidden'} ${this.props.smallView ? ' mobile-view': ''}`

		return (
			<div className = {mediaModalContentClasses}>
				<div className='media-content-wrapper'>
					<div className={`${classes.mediaOptions} ${this.state.hideOptions ? 'not-visible' : ''}`}>
						<div className='option'>
							<MediaModalInformation {...this.getMediaModalInfo()}/>
						</div>
						<div className='option'>
							<IconDownload style={{width: 36, height: 36}} onClick={this.downloadMedia}/>
						</div>
						<div
							className		= 'option'
							onClick			= {this.hideModal}
							onMouseEnter	= {() => this.setState({closeButtonHovered: true})}
							onMouseLeave	= {() => this.setState({closeButtonHovered: false})}
						>
							<MediaModalXBtn style={{width: 30, height: 30}} hover={this.state.closeButtonHovered ? 1 : 0}/>
						</div>
					</div>
					<img
						className	= {`nav-button prev ${this.state.hideOptions ? ' not-visible' : ''} ${(this.state.reachedFirstMedia && this.props.mediaModalContent.isFirstSelected) ? ' hidden' : ''}`}
						src			= {backBtn}
						alt			= 'Back'
						onClick		= {this.showPreviousMedia}
					/>
					<div className='media-content' onClick={this.toggleDisplayOptions}>
						<div className='shown-media loading-image'>
							<Swipeable onSwiped={e => this.onSwipe(e.dir)} delta={10} trackMouse={true} trackTouch={true} >
								{this.renderCentralMedia()}
							</Swipeable>
						</div>
					</div>
					<img
						className	= {`nav-button next ${this.state.hideOptions ? ' not-visible' : ''} ${(this.state.reachedLastMedia && this.props.mediaModalContent.isLastSelected) ? ' hidden' : ''}`}
						src			= {nextBtn}
						alt			= 'Next'
						onClick		= {this.showNextMedia}
					/>
					<div className={`${classes.mediaNumeration} ${!this.props.mediaModalContent.dbTotal ? 'hidden' : ''} ${this.state.hideOptions ? 'not-visible' : ''} ${!this.state.mediaGalleryShown ? 'bottom' : ''}`}>
						<span>{this.getMediaNumber()}</span>
					</div>
				</div>
				<div className='media-gallery'>
					<div className={classes.mediaShowHide} onClick={this.toggleMediaGallery}>
						<span className='media-show-hide-text'>
							{this.state.mediaGalleryShown ? 'Hide' : 'Show'}
						</span>
						<div className='media-show-hide-caret'></div>
					</div>
					<div className={`${classes.mediaGalleryItems} media-gallery-items ${this.state.smoothScroll ? ' smooth-scroll': ''}`}>
						<div style={{display: 'flex', alignItems: 'center'}} onScroll={this.onMediaGalleryScroll.bind(this)}>
							{this.renderMediaGalleryLoader()}
							{this.renderMediaGallery(content.media)}
							{this.renderMediaGalleryLoader()}
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderCentralMedia() {

		let content			= this.props.mediaModalContent
		let selectedMedia	= content.media.filter(m => m.selected)[0]
		if (!selectedMedia) return

		let mediaUrl	= selectedMedia.url
		let mediaType	= this.getType(selectedMedia.type)

		switch(mediaType) {
			case 'image':
				return <MediaModalImage mediaUrl={mediaUrl}></MediaModalImage>
			case 'video':
				return <MediaModalVideo mediaUrl={mediaUrl} mediaType={mediaType}></MediaModalVideo>
			case 'audio':
				return <MediaModalAudio mediaUrl={mediaUrl} mediaType={mediaType}></MediaModalAudio>
			default:
				return <MediaModalOtherType
							mediaUrl		= {mediaUrl}
							mediaType		= {mediaType}
							filename		= {selectedMedia.filename}
							isMobileView	= {this.props.smallView}
							getType			= {this.getType}
						/>
		}
	}

	onMediaGalleryScroll() {

		let mediaGalleryItems = document.getElementsByClassName('media-gallery-items')[0].children[0]

		let scrolledToRigth = mediaGalleryItems.scrollLeft === (mediaGalleryItems.scrollWidth - mediaGalleryItems.offsetWidth)
		let scrolledToLeft = mediaGalleryItems.scrollLeft === 0

		if (scrolledToRigth) {
			this.onReachRight()
		}

		if (scrolledToLeft) {
			this.onReachLeft()
		}
	}

	onReachLeft() {
		// console.log(' --- reached LEFT')
		let content = this.props.mediaModalContent
		if (!this.state.updating) {
			this.toggleAutoScrollGallery(false)
			this.loadMoreMedia(content);
		}
	}

	toggleSmoothScroll(smoothScroll) {
		this.setState({smoothScroll});
	}

	toggleAutoScrollGallery(autoScrollGallery) {
		this.setState({autoScrollGallery})
	}

	onReachRight() {
		// console.log(' --- reached RIGHT')
		let content = this.props.mediaModalContent
		if (!this.state.updating) {
			this.toggleAutoScrollGallery(false)
			this.loadMoreMedia(content, false)
		}
	}

	isDescendant = (parent, child) => {
		let node = child.parentNode
		while (node !== null) {
			if (node === parent) {
				return true
			}
			node = node.parentNode
		}
		return false
	}

	getMediaNumber = () => {
		// Returns the position / numeration of the media in all media
		let content = this.props.mediaModalContent
		if (!content.total) return

		let selectedMediaIndex
		content.media.forEach((m, i) => {
			if (m.selected) {
				selectedMediaIndex = i
			}
		})

		if (content.dbTotal) {
			selectedMediaIndex += content.dbTotal - content.total + 1
			return `${selectedMediaIndex} of ${content.dbTotal}`
		}

		return ''
	}

	getMediaModalInfo = () => {

		let content = this.props.mediaModalContent
		if (!content.total) return

		let selectedMedia = content.media.filter(m => m.selected)[0]
		if (!selectedMedia) return

		let info = {
			sender:		selectedMedia.from || '...',
			dateTime:	this.format_date_time(selectedMedia.created_at * 1000) || ' ... ',
			size:		this.formatFileSize(selectedMedia.size)
		}

		let mediaType = this.getType(selectedMedia.type)

		if (mediaType === 'image') {
			info.otherDetails = {
				Width:	`${selectedMedia.width || ' ... '}px`,
				Height:	`${selectedMedia.height || ' ... '}px`
			}
		}

		return info
	}

	formatFileSize = (size) => {

		if (size < 1000) {
			return `${this.roundToNDecimals(size, 2)}B`
		}

		if (size < 1000000) {
			return `${this.roundToNDecimals(size/1000, 2)}KB`
		}

		return `${this.roundToNDecimals(size/1000000, 2)}MB`
	}

	roundToNDecimals(number, n) {
		return +(Math.round(number + `e+${n}`)  + `e-${n}`)
	}

	showMediaGallery = () => this.setState({mediaGalleryShown: true})

	hideMediaGallery = () => this.setState({mediaGalleryShown: false})

	toggleMediaGallery = () => this.setState({mediaGalleryShown: !this.state.mediaGalleryShown})

	hideModal = e => {
		let target = e.target
		if (!Array.from(target.classList).includes('shown-media')) {
			this.setState({autoScrollGallery: true})
			this.props.hideModal()
		}
	}
	
	renderMediaGalleryLoader() {
		let position = this.state.updating === 'old' ? 'left' : (this.state.updating === 'new' ? 'right' : null)
		return position ? (
			<div className={`gallery-items-loader ${position}-loader`}>
				<div><LoaderFull/></div>
			</div>
		) : null;
	}

	getType = mimetype => {

		let type = mimetype.split('/')[0]

		if (mimetype.includes('msword') || mimetype.includes('ms-word') || mimetype.includes('.document')) {
			type = 'document'
		} else if (mimetype.includes('ms-excel') || mimetype.includes('.sheet')) {
			type = 'sheet'
		} else if (mimetype.includes('vnd.sun.xml')) {
			type = 'openoffice'
		} else if (mimetype === 'application/pdf') {
			type = 'pdf'
		} else if (mimetype === 'application/zip' || mimetype === 'application/x-7z-compressed') {
			type = 'archive'
		} else if (!['image', 'audio', 'video'].includes(type) || mimetype === 'video/3gpp') {
			type = 'other'
		}

		return type
	}

	renderMediaGallery = media => {

		const { classes } = this.props

		let icons = {
			document:		docIcon,
			sheet:			xlsIcon,
			presentation:	pptIcon,
			openoffice:		openofficeIcon,
			pdf:			pdfIcon,
			audio:			audioIcon,
			video:			videoIcon,
			archive:		zipIcon
		}

		return media.map((m, i) => {

			let mediaType		= this.getType(m.type)
			let backgroundImageUrl
			let backgroundSize	= 'cover'
			switch(mediaType) {
				case 'image':
					backgroundImageUrl = m.url
					if (m.has_thumbnail) {
						let originalFilename		= backgroundImageUrl.split('/').pop()
						let originalFilenameSplit	= originalFilename.split('.')
						originalFilenameSplit[originalFilenameSplit.length - 2] += '_thumbnail'
						let newFilename				= originalFilenameSplit.join('.')
						backgroundImageUrl			= backgroundImageUrl.replace(originalFilename, newFilename)
					}
					break
				case 'audio':
				case 'video':
				case 'document':
				case 'sheet':
				case 'openoffice':
				case 'presentation':
				case 'pdf':
				case 'archive':
					backgroundImageUrl = icons[mediaType]
					backgroundSize = 'contain'
					break
				default:
					backgroundImageUrl = defaultFileIcon
					backgroundSize = 'contain'
			}

			let filenameSplit = m.filename.split('.')
			let fileExtension = filenameSplit.length > 1 ? filenameSplit.pop() : null

			return (
				<div
					key			= {m.url + i}
					id			= {m.url + i}
					className	= {`${classes.galleryItem} ${mediaType !== 'image' ? 'file-icon' : ''} fs-block not-selectable ${m.selected ? ' selected' : ''}`}
					style		= {{
						backgroundImage:	`url(${backgroundImageUrl})`,
						backgroundSize:		backgroundSize
					}}
					onClick		= {this.selectMedia.bind(this, m.url)}
				>
					{/* <img style={{maxWidth: '100%', maxHeight: '100%'}} src={backgroundImageUrl} alt='Media icon' /> */}
					<span className='gallery-item-placeholder'>.</span>
					<span className={`gallery-item-extension ${mediaType === 'other' ? '' : ' hidden'}`}>.{fileExtension}</span>
				</div>
			);
		});
	}

	selectMedia = url => {
		this.showLoader()
		this.toggleSmoothScroll(true)
		this.toggleAutoScrollGallery(true)

		let content				= this.props.mediaModalContent
		let media				= content.media
		let selectedMediaIndex	= null
		let selectedMediaUrl
		content.isFirstSelected	= false
		content.isLastSelected	= false
		media.forEach((m, i) => {
			m.selected = m.url === url
			if (m.selected) {
				selectedMediaIndex	= i
				selectedMediaUrl	= m.url

				if (i === 0) {
					content.isFirstSelected = true
				} else if (i === media.length - 1) {
					content.isLastSelected = true
				}
			}
		})

		let selectedMediaId = selectedMediaUrl + selectedMediaIndex
		this.setState({
			selectedMediaId: selectedMediaId
		})

		this.props.updateModal(content)

		// Load more media if the selected media is in the 5 top left media
		if (!this.state.updating) {
			if (selectedMediaIndex < 5 && selectedMediaIndex + 1 !== content.dbTotal) {
				this.loadMoreMedia(content)
			} else if (selectedMediaIndex > content.total - 5) {
				this.loadMoreMedia(content, false)
			}
		}
	}

	showNextMedia = () => {
		this.showLoader()
		this.toggleSmoothScroll(true)
		this.toggleAutoScrollGallery(true)

		let content = this.props.mediaModalContent;
		let media = content.media;
		let selectedMediaIndex;
		let selectedMediaUrl;
		content.isFirstSelected = false;
		content.isLastSelected = false;
		media.forEach((m, i) => {

			if (m.selected) {
				selectedMediaIndex = i;
				selectedMediaUrl = m.url;

				if (media.length - 1 !== i) {
					m.selected = false;
				}

			} else if (i === selectedMediaIndex + 1) {
				selectedMediaUrl = m.url;
				m.selected = true;
			}

			if (m.selected) {
				if (i === 0) {
					content.isFirstSelected = true;
				} else if (i === media.length - 1) {
					content.isLastSelected = true;
				}
			}
		});

		let selectedMediaId = selectedMediaUrl + (selectedMediaIndex + 1);
		this.setState({
			selectedMediaId: selectedMediaId
		});

		this.props.updateModal(content);

		// Load more media if the selected media is in the 5 top right media
		if (selectedMediaIndex >= content.total - 5 && !this.state.updating) {
			this.loadMoreMedia(content, false);
		}
	};
	
	showFirstShownMedia() {
		this.showLoader()
		this.toggleSmoothScroll(false)
		this.toggleAutoScrollGallery(true)

		let content = this.props.mediaModalContent;
		let media = content.media;
		let selectedMediaUrl;
		content.isFirstSelected = false;
		content.isLastSelected = false;
		media.forEach((m, i) => {
			m.selected = i === 0;
			if (m.selected) {
				selectedMediaUrl = m.url;
				content.isFirstSelected = true;
				if (i === media.length - 1) {
					content.isLastSelected = true;
				}
			}
		});

		this.setState({
			selectedMediaId: selectedMediaUrl + '0'
		});

		this.props.updateModal(content, () => {
			if (!this.state.updating) {
				this.loadMoreMedia(content)
			}
		})
	};

	showLastShownMedia() {
		this.showLoader()
		this.toggleSmoothScroll(false)
		this.toggleAutoScrollGallery(true)

		let content = this.props.mediaModalContent;
		let media = content.media;
		let selectedMediaUrl;
		content.isFirstSelected = false;
		content.isLastSelected = false;
		media.forEach((m, i) => {
			m.selected = i === media.length - 1;
			if (m.selected) {
				selectedMediaUrl = m.url;
				content.isLastSelected = true;
				if (i === 0) {
					content.isFirstSelected = true;
				}
			}
		});

		let selectedMediaId = selectedMediaUrl + (media.length - 1);
		this.setState({
			selectedMediaId: selectedMediaId
		});

		this.props.updateModal(content, () => {
			if (!this.state.updating) {
				this.loadMoreMedia(content, false);
			}
		})
	};

	showPreviousMedia = () => {
		this.showLoader()
		this.toggleSmoothScroll(true)
		this.toggleAutoScrollGallery(true)

		let content = this.props.mediaModalContent;
		let media = content.media;
		let selectedMediaIndex;
		let selectedMediaUrl;
		content.isFirstSelected = false;
		content.isLastSelected = false;
		media.slice().reverse().forEach((m, i) => {

			if (m.selected) {
				selectedMediaIndex = i;
				selectedMediaUrl = m.url;

				if (media.length - 1 !== i) {
					m.selected = false;
				}

			} else if (i === selectedMediaIndex + 1) {
				m.selected = true;
				selectedMediaUrl = m.url;
			}

			if (m.selected) {
				if (i === media.length - 1) {
					content.isFirstSelected = true;
				} else if (i === 0) {
					content.isLastSelected = true;
				}
			}
		});

		let selectedMediaId = selectedMediaUrl + (media.length - selectedMediaIndex - 2);
		this.setState({
			selectedMediaId: selectedMediaId
		});

		this.props.updateModal(content);

		// Load more media if the selected media is in the 5 top left media
		if (selectedMediaIndex >= content.total - 5 && selectedMediaIndex + 1 !== content.dbTotal && !this.state.updating) {
			this.loadMoreMedia(content);
		}
	}

	toggleDisplayOptions = () => {
		if (!this.props.smallView) return
		let hideOptions = !this.state.hideOptions
		this.setState({hideOptions})
	}

	showLoader() {
		let shownImage = document.getElementsByClassName('shown-media')[0]
		if (!shownImage) return
		shownImage.src = loader
		shownImage.classList.add('loading-image')
	}
	
	loadMoreMedia(content, loadOld=true) {

		let currentTimestamp = (new Date()).getTime()
		if (currentTimestamp - this[`lastDb${loadOld ? 'Old' : 'New'}LoadTimestamp`] < 3000) return console.log('wait a little ...')

		this.setState({updating: loadOld ? 'old' : 'new'})

		let total			= content.total
		let offset			= !loadOld ? content.offset : 0
		let limit			= 25
		let conversationId	= content.conversationId
		let lastCursor		= loadOld ? content.lastCursor : null
		let firstCursor		= !loadOld ? content.firstCursor : null

		if (!conversationId) {
			console.error('No conversation id found in modal content');
			this.setState({autoScrollGallery: true})
			this.props.hideModal();
		}

		let filters = {file_type: 'not-in:smil'}

		this[`lastDb${loadOld ? 'Old' : 'New'}LoadTimestamp`] = currentTimestamp
		api.loadMedia(conversationId, filters, lastCursor, firstCursor, offset, limit)
			.then(response => {

				if (loadOld) {
					response.media = response.media.reverse();
				}

				let modalContent = {
					media:			loadOld ? response.media.concat(content.media) : content.media.concat(response.media),
					total:			total + response.media.length,
					offset:			content.offset,
					conversationId:	conversationId,
					dbTotal:		response.total,
					lastCursor:		content.lastCursor,
					firstCursor:	content.firstCursor
				}
				console.log('Loaded ' + response.media.length + (loadOld ? ' old' : ' new') + ' media')

				if (response.media.length < 25) {
					if (loadOld) {
						this.setState({
							reachedFirstMedia: true
						});
					} else {
						this.setState({
							reachedLastMedia: true
						});
					}
				} else {
					if (!loadOld) {
						this.setState({
							reachedLastMedia: false
						});
					}
				}

				if (response.media.length) {
					// Set the first and last file id of the given media
					if (loadOld) {
						modalContent.lastCursor = response.lastCursor;
					} else {
						modalContent.firstCursor = response.firstCursor;
					}
					this.toggleSmoothScroll(false);
					this.props.updateModal(modalContent, () => {
						this.setState({updating: false})
					});
				} else {
					this.setState({updating: false})
				}

				let me = this
				// Set the width and height of each media (image)
				modalContent.media.forEach(m => {
					if (!m.width) {
		
						let image = new Image();
						image.onload = function() {
							m.width = this.width;
							m.height = this.height;
							me.props.updateModal(modalContent);
						}
						image.src = m.url;
					}
				});
			});
	}

	downloadMedia = () => {
		let content = this.props.mediaModalContent;
		if (!content.total) return

		let selectedMedia = content.media.filter(m => m.selected)[0];
		if (!selectedMedia) return

		window.open(selectedMedia.url, '_blank');
	}

	format_date_time(d) {

		let date = new Date(d);

		let _twoDigits = (number) => {
			return (number > 9) ? number : "0" + number
		}

		return date.getFullYear() + '/' + _twoDigits((date.getMonth() + 1)) + '/' + _twoDigits(date.getDate())
			+ ' ' + _twoDigits(date.getHours()) + ':' + _twoDigits(date.getMinutes());
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MediaModal))