import React, { useContext, useState } from 'react'
import { Slider } from '@material-ui/core'
import Typography from 'typography'
import { VolumeUpIcon, MenuCollapsedIcon, MicIcon } from 'svg-icons'
import { Menu, makeStyles } from '@material-ui/core'
import MenuItem from 'menu-item-mui'
import styles from './styles'
import { useInputDevices, useOutputDevices, useVolume } from './useDevices'
import { FeatureEventsContext } from 'providers/src'

const useStyles = makeStyles(styles)

type Props = { anchorEl?: HTMLDivElement }

/***/
export function InputAudioSettings (props: Props): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const { inputDevices, onMicSelect } = useInputDevices()
    const classes = useStyles()
    const featureEventsContext = useContext(FeatureEventsContext)

    return (
        <div
            className = 'audio-settings-icon disable-dragging'
            onClick = {(e) => {
                featureEventsContext.pushEvent({ appName: 'softphone', key: 'audio-settings-open-microphone-select' })
                setAnchorEl(props.anchorEl || e.currentTarget)
            }}
            data-test-id = 'input-audio-settings-toggle'
        >
            <MenuCollapsedIcon className={classes.icon}/>
            <Menu
                open = {!!anchorEl}
                anchorEl = {anchorEl}
                onClose = {() => {
                    featureEventsContext.pushEvent({ appName: 'softphone', key: 'audio-settings-close-microphone-select' })
                    setTimeout(() => setAnchorEl(null), 0)
                }}
                classes = {{ paper: classes.menu }}
                PopoverClasses = {{ root: `${classes.popover} disable-dragging` }}
                anchorOrigin = {{ vertical: 'center', horizontal: 'right' }}
                transformOrigin = {{ vertical: props.anchorEl ? 'top' : 'center', horizontal: props.anchorEl ? 'center' : 'left' }}
            >
                {inputDevices.map((device: any, i) => (
                    <MenuItem
                        key = {device.deviceId}
                        icon = {MicIcon}
                        selected = {device.selected}
                        onClick = {() => {
                            featureEventsContext.pushEvent({ appName: 'softphone', key: 'audio-settings-microphone-select' })
                            onMicSelect(device.deviceId)
                        }}
                        classes = {{ root: classes.audioSettingsMenuItem, selected: classes.selectedMenuItem }}
                    ><Typography variant='body2'>{device.label}</Typography></MenuItem>
                ))}
            </Menu>
        </div>
    )
}

const VolumeMenuItem = (): JSX.Element => {
    const classes = useStyles()
    const { getVolume, setVolume } = useVolume()
    const featureEventsContext = useContext(FeatureEventsContext)

    return (
        <div className={classes.volumeWrapper}>
            <Slider
                value = {getVolume()}
                onChange = {(e, volume) => {
                    featureEventsContext.pushEvent({ appName: 'softphone', key: 'audio-settings-volume-change', value: volume as number })
                    setVolume(volume as number)
                }}
                classes = {{ root: classes.slider, thumb: classes.sliderThumb }}
                aria-labelledby = 'continuous-slider'
            />
            <Typography variant='overline'>VOLUME</Typography>
        </div>
    )
}

/***/
export function OutputAudioSettings (props: Props): JSX.Element {
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const { outputDevices, onSpeakerSelect } = useOutputDevices()
    const classes = useStyles()
    const featureEventsContext = useContext(FeatureEventsContext)

    return (
        <div
            className = 'audio-settings-icon disable-dragging'
            onClick = {(e) => {
                featureEventsContext.pushEvent({ appName: 'softphone', key: 'audio-settings-open-speaker-select' })
                setAnchorEl(props.anchorEl || e.currentTarget)
            }}
            data-test-id = 'output-audio-settings-toggle'
        >
            <MenuCollapsedIcon className={classes.icon}/>
            <Menu
                open = {!!anchorEl}
                anchorEl = {anchorEl}
                onClose = {() => {
                    featureEventsContext.pushEvent({ appName: 'softphone', key: 'audio-settings-close-speaker-select' })
                    setTimeout(() => setAnchorEl(null), 0)
                }}
                classes = {{ paper: classes.menu }}
                PopoverClasses = {{ root: `${classes.popover} disable-dragging` }}
                anchorOrigin = {{ vertical: 'center', horizontal: 'left' }}
                transformOrigin = {{ vertical: props.anchorEl ? 'top' : 'center', horizontal: props.anchorEl ? 'center' : 'left' }}
            >
                <VolumeMenuItem/>
                {outputDevices.map((device: any, i) => (
                    <MenuItem
                        key = {device.deviceId}
                        icon = {VolumeUpIcon}
                        selected = {device.selected}
                        onClick = {() => {
                            featureEventsContext.pushEvent({ appName: 'softphone', key: 'audio-settings-speaker-select' })
                            onSpeakerSelect(device.deviceId)
                        }}
                        classes = {{ root: classes.audioSettingsMenuItem, selected: classes.selectedMenuItem }}
                    ><Typography variant='body2'>{device.label}</Typography></MenuItem>
                ))}
            </Menu>
        </div>
    )
}
