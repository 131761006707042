import React, { useEffect, useContext } from 'react'

import { Alert, Color as AlertColor } from 'alert-mui'
import Typography from 'typography'
import { Textarea } from 'textarea-mui'
import { ScreenSizeContext } from 'providers'
import RemoteConfigValue, { getValue } from 'remote-config-value'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import CampaignEnrollmentStepContainer from './CampaignEnrollmentStepContainer'
import { useFormInput } from '../hooks/useFormInput'

const useStyles = makeStyles((theme) => ({
    keywordsMessageContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: screenSizeContext => !screenSizeContext.mobile ? 'nowrap' : 'wrap',
        justifyContent: 'space-between',
        gap: '1rem'
    },
    textAreaContainer: {
        flexGrow: 1,
        width: '50%',
        display: 'grid',
        "& [class^='MuiFormControl-root']": {
            marginTop: '1rem'
        }
    },
    container: {},
    fullWidthTextArea: { width: '100%', resize: 'none' },
    containerPaddingTop: { paddingTop: '1rem', paddingBottom: '1rem' },
    marginBottom: {
        marginBottom: '1.25rem'
    }
}))

/**
 * @param {string} val
 */
const hasLetters = (val) => {
    return /[A-Za-z]/.test(val)
}

/**
 * @param {object} props
 */
const MessageSettingsStep = (props) => {
    const screenSizeContext = useContext(ScreenSizeContext)
    const campaignUsageSavedData = props.passData.campaign_message
    const classes = useStyles(screenSizeContext)
    const { readOnly = false, showTitle = true, showContainer = true } = props
    const whitespaceRegExp = /\s/
    const { editing } = props

    const defaultOptInKeywords = campaignUsageSavedData ? campaignUsageSavedData.optInKeywords : (editing != null ? editing.keywords.start.Campaignkeyword : '')
    const { value: optInKeywords, onChange: setOptInKeywords, validValue: validOptInKeywords } = useFormInput(
        defaultOptInKeywords,
        (value) => value.length > 0 && hasLetters(value) && !whitespaceRegExp.test(value)
    )
    const defaultOptInMessage = campaignUsageSavedData ? campaignUsageSavedData.optInMessage : (editing != null ? editing.keywords.start.CampaignkeywordValue : '')
    const { value: optInMessage, onChange: setOptInMessage, validValue: validOptInMsg } = useFormInput(
        defaultOptInMessage,
        (value) => value.length >= 20
    )
    const defaultOptOutKeywords = campaignUsageSavedData ? campaignUsageSavedData.optOutKeywords : (editing != null ? editing.keywords.stop.Campaignkeyword : '')
    const { value: optOutKeywords, onChange: setOptOutKeywords, validValue: validOptOutKeywords } = useFormInput(
        defaultOptOutKeywords,
        (value) => value.length > 0 && hasLetters(value) && !whitespaceRegExp.test(value)
    )
    const defaultOptOutMessage = campaignUsageSavedData ? campaignUsageSavedData.optOutMessage : (editing != null ? editing.keywords.stop.CampaignkeywordValue : '')
    const { value: optOutMessage, onChange: setOptOutMessage, validValue: validOptOutMsg } = useFormInput(
        defaultOptOutMessage,
        (value) => value.length >= 20
    )
    const defaultHelpKeywords = campaignUsageSavedData ? campaignUsageSavedData.helpKeywords : (editing != null ? editing.keywords.help.Campaignkeyword : '')
    const { value: helpKeywords, onChange: setHelpKeywords, validValue: validHelpKeywords } = useFormInput(
        defaultHelpKeywords,
        (value) => value.length > 0 && hasLetters(value) && !whitespaceRegExp.test(value)
    )
    const defaultHelpMessage = campaignUsageSavedData ? campaignUsageSavedData.helpMessage : (editing != null ? editing.keywords.help.CampaignkeywordValue : '')
    const { value: helpMessage, onChange: setHelpMessage, validValue: validHelpMsg } = useFormInput(
        defaultHelpMessage,
        (value) => value.length >= 20
    )

    const invalid = !validOptInKeywords || !validOptInMsg || !validOptOutKeywords || !validOptOutMsg || !validHelpKeywords || !validHelpMsg

    const getSaveData = () => {
        return {
            optInKeywords,
            optInMessage,
            optOutKeywords,
            optOutMessage,
            helpKeywords,
            helpMessage
        }
    }

    useEffect(() => {
        if (props.saveId) props.update({ completed: true, gotoNext: true, saveData: getSaveData() })
    }, [props.saveId])

    useEffect(() => {
        props.update({ disableNextButton: () => invalid })
    }, [invalid])

    const renderTextAreaSection = ({
        alertID, left: {
            titleID,
            descriptionID,
            description2ID,
            placeholderID,
            helperTextID,
            value,
            onChangeHandler,
            error,
            id
        },
        right: {
            titleID: rightTitleID,
            descriptionID: rightDescriptionID,
            placeholderID: rightplaceholderID,
            helperTextID: rightHelperTextID,
            value: rightValue,
            onChangeHandler: rightOnChangeHandler,
            error: rightError,
            id: rightID
        }
    }) => (
        <div key={titleID} className={classes.container}>
            {alertID && (
                <>
                    <Alert
                        soft
                        color={AlertColor.INFO}
                        content={<RemoteConfigValue valueId={alertID} />}
                        classes={{ root: classes.marginBottom }} />
                </>
            )}
            <div className={classes.keywordsMessageContainer}>
                <div className={classes.textAreaContainer}>
                    <Typography variant='h6' remoteConfigID={titleID} />
                    <Typography variant='subtitle3' remoteConfigID={descriptionID} />
                    {description2ID && (<Typography variant='subtitle3' remoteConfigID={description2ID} />)}
                    <Textarea
                        value={value}
                        id={id}
                        error={value.length > 0 && error}
                        className={classes.fullWidthTextArea}
                        rowsMin={5}
                        readOnly={readOnly}
                        helperText={error
                            ? ((value.length > 0 && hasLetters(value))
                                ? getValue('sms_enrollment_keywords_error')
                                : getValue('sms_enrollment_keywords_error_required'))
                            : (helperTextID != null ? getValue(helperTextID) : null) }
                        placeholder={getValue(placeholderID)}
                        onChange={onChangeHandler}
                    />
                </div>
                <div className={classes.textAreaContainer}>
                    <Typography variant='h6' remoteConfigID={rightTitleID} />
                    <Typography variant='subtitle3' remoteConfigID={rightDescriptionID} />
                    <Textarea
                        value={rightValue}
                        id={rightID}
                        error={rightValue.length > 0 && rightError}
                        className={classes.fullWidthTextArea}
                        rowsMin={5}
                        readOnly={readOnly}
                        maxLength={320}
                        helperText={rightError ? getValue('sms_enrollment_valid_opt_message_helper_text') : getValue(rightHelperTextID)}
                        placeholder={getValue(rightplaceholderID)}
                        onChange={rightOnChangeHandler}
                    />
                </div>
            </div>
        </div>
    )

    const textAreaSections = [
        {
            alertID: 'sms_enrollment_message_settings_alert',
            left: {
                titleID: 'sms_enrollment_opt_in_keywords',
                descriptionID: 'sms_enrollment_opt_in_keywords_description',
                placeholderID: 'sms_enrollment_opt_in_text_placeholder',
                helperTextID: 'sms_enrollment_opt_in_keywords_description_cont',
                value: optInKeywords,
                onChangeHandler: setOptInKeywords,
                error: !validOptInKeywords,
                id: 'optInKeywordsTxtArea'
            },
            right: {
                titleID: 'sms_enrollment_opt_in_message',
                descriptionID: 'sms_enrollment_opt_in_message_description',
                placeholderID: 'sms_enrollment_opt_in_message_placeholder',
                helperTextID: 'sms_enrollment_opt_in_message_helperText',
                value: optInMessage,
                onChangeHandler: setOptInMessage,
                error: !validOptInMsg,
                id: 'optInMessageTxtArea'
            }
        },
        {
            alertID: 'sms_enrollment_opt_out_alert',
            left: {
                titleID: 'sms_enrollment_opt_out_keywords',
                descriptionID: 'sms_enrollment_opt_out_keywords_description',
                description2ID: 'sms_enrollment_opt_out_keywords_description_cont',
                placeholderID: 'sms_enrollment_opt_out_text_placeholder',
                helperTextID: 'sms_enrollment_opt_out_keywords_helperText',
                value: optOutKeywords,
                onChangeHandler: setOptOutKeywords,
                error: !validOptOutKeywords,
                id: 'optOutKeywordsTxtArea'
            },
            right: {
                titleID: 'sms_enrollment_opt_out_message',
                descriptionID: 'sms_enrollment_opt_out_message_description',
                placeholderID: 'sms_enrollment_opt_out_message_placeholder',
                helperTextID: 'sms_enrollment_opt_out_message_helperText',
                value: optOutMessage,
                onChangeHandler: setOptOutMessage,
                error: !validOptOutMsg,
                id: 'optOutMessageTxtArea'
            }
        },
        {
            alertID: 'sms_enrollment_help_alert',
            left: {
                titleID: 'sms_enrollment_help_keywords',
                descriptionID: 'sms_enrollment_help_keywords_description',
                placeholderID: 'sms_enrollment_help_keywords_placeholder',
                helperTextID: 'sms_enrollment_opt_out_keywords_helperText',
                value: helpKeywords,
                onChangeHandler: setHelpKeywords,
                error: !validHelpKeywords,
                id: 'helpKeywordsTxtArea'
            },
            right: {
                titleID: 'sms_enrollment_help_message',
                descriptionID: 'sms_enrollment_help_message_description',
                placeholderID: 'sms_enrollment_help_message_placeholder',
                helperTextID: 'sms_enrollment_opt_out_message_helperText',
                value: helpMessage,
                onChangeHandler: setHelpMessage,
                error: !validHelpMsg,
                id: 'helpMessageTxtArea'
            }
        }
    ]

    return (
        <CampaignEnrollmentStepContainer readOnly={readOnly} showContainer={showContainer} >
            {
                readOnly && showTitle && (
                    <div className={classes.containerPaddingTop}>
                        <Typography id='title' variant='h4' remoteConfigID='sms_enrollment_message_settings' />
                    </div>

                )
            }
            {textAreaSections.map(x => renderTextAreaSection(x))}
        </CampaignEnrollmentStepContainer>)
}

MessageSettingsStep.propTypes = {
    passData: PropTypes.object,
    update: PropTypes.func,
    saveId: PropTypes.number,
    readOnly: PropTypes.bool,
    editing: PropTypes.object,
    showTitle: PropTypes.bool,
    showContainer: PropTypes.bool

}

export default MessageSettingsStep
