<template>
    <w-loader v-if="user.loading"/>
    <div v-else class="create-page" data-test-id="video-users-create-page">
        <w-alert
          v-if="user.alert"
          :message="user.alert.message"
          :level="user.alert.level"
          :closable="user.enable_close_alert"
          @closed="user.alert = null"
          class="mb-6"
        />
        <VideoUserFragment
          v-if="user.alert === null || (user.alert && user.alert.level !== 'success')"
          :_user="user.item"
          :exclude_extensions="exclude_extensions"
          @submitted="on_submit"
        />
    </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../../libs/lang';
  import VideoUser from '../../../../models/VideoUser';
  import VideoUserFragment from '../../../fragments/VideoUser.vue';

  export default {
    components: {
      VideoUserFragment,
    },
    data() {
      return {
        l,
        user: new VideoUser(this.$session, vueComponent(this)),
        exclude_extensions: [],  
      };
    },
    async created() {
      this.$data.user.loading = true;
      try {
        const users = await this.$session.get_list_all('/video/users');
        this.$data.exclude_extensions = users.items.map((x) => x.voip_phone_id);
      } catch (err) {
        console.log('Error while filtering extensions');
        this.$data.exclude_extensions = [];  
      }
      this.$data.user.loading = false;
    },
    methods: {
      async on_submit(data) {
        await this.$data.user.create(data);
        const startsWith = 'User exists:';
        const endsWith = ' is already a user';
        if (
          this.$data.user.alert
          && this.$data.user.alert.level === 'error'
          && this.$data.user.alert.message.startsWith(startsWith)
          && this.$data.user.alert.message.endsWith(endsWith)
        ) {
          const isEmail = this.$data.user.alert.message.substring(startsWith.length, this.$data.user.alert.message.length - endsWith.length).includes('@');
          if (isEmail) {
            this.$data.user.alert.message = l.t('video-users.email-is-already-used-for-another-user', '{} is already used for another user', [this.$data.user.item.email]);
          } else {
            this.$data.user.alert.message = l.t('video-users.extension-is-already-used-for-another-user', 'This extension is already used for another user');
          }
        }
      }
    }
  };
</script>
