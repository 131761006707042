import l from './libs/lang'
import { CallLogs } from 'reports-components'
import Billing from './components/screens/Billing.vue'
import HomepageScreen from './components/screens/Homepage.vue'

import PhoneNumbersScreen from './components/screens/phone-numbers/master.vue'
import PhoneNumberScreen from './components/screens/phone-numbers/show.vue'
import Extensions from './components/screens/extensions/master.vue'
import Devices from './components/screens/devices/master.vue'
import AddressBook from './components/screens/contacts/master.vue'
import Faxes from './components/screens/faxes/master.vue'
import ReportsScreen from './components/screens/Reports.vue'
import QueuesScreen from './components/screens/queues/master.vue'
import MenusScreen from './components/screens/menus/master.vue'
import SchedulesScreen from './components/screens/schedules/master.vue'
import MediaScreen from './components/screens/media/master.vue'
import VideoScreen from './components/screens/video/master.vue'
import UserSchedulesScreen from './components/screens/video/users/schedules/master.vue'
import RoutesScreen from './components/screens/routes/master.vue'
import TrunksScreen from './components/screens/trunks/master.vue'
import BlockedCallsScreen from './components/screens/blocked-calls/master.vue'
import ApplicationsScreen from './components/screens/applications/master.vue'
import SubaccountScreen from './components/screens/subaccounts/master.vue'
import AccountSettings from './components/screens/AccountSettings.vue'
import MessagesScreen from './components/screens/messages/master.vue'
import ApiRequestsScreen from './components/screens/api-requests/master.vue'
import ConversationsScreen from './components/screens/Conversations.vue'
import VoicemailScreen from './components/screens/Voicemail.vue'
import ConferencingScreen from './components/screens/Conferencing.vue'
import LiveAnswerScreen from './components/screens/live-answer/master.vue'
import MobileAppsScreen from './components/screens/MobileApps.vue'
import AdvancedToolsScreen from './components/screens/AdvancedTools.vue'
import NotFound from './components/screens/NotFound.vue'

const dev_feature = 'console.show_developer_feature'
const visualizer = 'console.show_chr_visualizer'

const routes = [
    {
        path: '/phone-numbers',
        component: PhoneNumbersScreen,
        name: 'phone-numbers.index',
        meta: {
            features: [dev_feature],
            category: 'calls-handling',
            allowed_for_scopes: ['agent'],
            title: () => l.t('app.phone-numbers', 'Phone numbers')
        },
        props: true
    },
    {
        path: '/phone-numbers/visualizer-default',
        component: PhoneNumbersScreen,
        name: 'phone-numbers.visualizer-default',
        meta: {
            features: [visualizer],
            allowed_for_scopes: ['csr', 'agent']
        },
        props: true
    },
    {
        path: '/phone-numbers/new',
        component: PhoneNumbersScreen,
        name: 'phone-numbers.create',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/phone-numbers/delete',
        component: PhoneNumbersScreen,
        name: 'phone-numbers.delete-csv',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/phone-numbers/:id',
        component: PhoneNumberScreen,
        name: 'phone-numbers.show',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/phone-numbers/:id/flow',
        component: PhoneNumberScreen,
        name: 'phone-numbers.call-flow',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/phone-numbers/:id/visualizer',
        component: PhoneNumbersScreen,
        name: 'phone-numbers.visualizer',
        meta: {
            features: [visualizer],
            allowed_for_scopes: ['csr', 'agent']
        },
        props: true
    },
    {
        path: '/phone-numbers/:id/call',
        component: PhoneNumberScreen,
        name: 'phone-numbers.make-call',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/extensions',
        component: Extensions,
        name: 'extensions.index',
        meta: {
            category: 'calls-handling',
            title: () => l.t('app.extensions', 'Extensions'),
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/extensions/visualizer-default',
        component: Extensions,
        name: 'extensions.visualizer-default',
        meta: {
            features: [visualizer],
            allowed_for_scopes: ['csr', 'agent']
        },
        props: true
    },
    {
        path: '/extensions/create',
        component: Extensions,
        name: 'extensions.create',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/extensions/:id',
        component: Extensions,
        name: 'extensions.show',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/extensions/:id/flow',
        component: Extensions,
        name: 'extensions.call-flow',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/extensions/:id/visualizer',
        component: Extensions,
        name: 'extensions.visualizer',
        meta: {
            features: [visualizer],
            allowed_for_scopes: ['csr', 'agent']
        },
        props: true
    },
    {
        path: '/extensions/:id/type',
        component: Extensions,
        name: 'extensions.type',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/billing',
        component: Billing,
        name: 'billing.index',
        meta: {
            features: [],
            category: 'my-account',
            title: () => l.t('app.billing', 'Billing')
        },
        props: true
    },
    {
        path: '/billing/payment-methods',
        component: Billing,
        name: 'billing.payment-methods',
        meta: {
            features: []
        },
        props: true
    },
    {
        path: '/billing/payment-methods/create',
        component: Billing,
        name: 'billing.payment-methods.create',
        meta: {
            features: []
        },
        props: true
    },
    {
        path: '/devices',
        component: Devices,
        name: 'devices.index',
        meta: {
            category: 'my-account',
            title: () => l.t('app.devices', 'Devices'),
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/devices/create',
        component: Devices,
        name: 'devices.create',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/devices/buy',
        component: Devices,
        name: 'devices.buy',
        meta: {
            category: 'my-account',
            title: () => l.t('app.devices', 'Devices')
        },
        props: true
    },
    {
        path: '/devices/my-orders',
        component: Devices,
        name: 'devices.my-orders',
        meta: {
            category: 'my-account',
            title: () => l.t('app.devices', 'Devices')
        },
        props: true
    },
    {
        path: '/devices/my-orders/:id',
        component: Devices,
        name: 'devices.my-orders.show',
        meta: {
            category: 'my-account',
            title: () => l.t('app.devices', 'Devices')
        },
        props: true
    },
    {
        path: '/devices/:id',
        component: Devices,
        name: 'devices.show',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/address-book',
        component: AddressBook,
        name: 'contacts.index',
        meta: {
            category: 'people',
            title: () => l.t('app.address-book', 'Address book')
        },
        props: true
    },
    {
        path: '/address-book/create',
        component: AddressBook,
        name: 'contacts.create',
        props: true
    },
    {
        path: '/address-book/import',
        component: AddressBook,
        name: 'contacts.import',
        meta: {
            category: 'people',
            title: () => l.t('app.address-book', 'Address book')
        },
        props: true
    },
    {
        path: '/address-book/duplications',
        component: AddressBook,
        name: 'contacts.duplications',
        meta: {
            category: 'people',
            title: () => l.t('app.address-book', 'Address book')
        },
        props: true
    },
    {
        path: '/address-book/groups',
        component: AddressBook,
        name: 'groups.index',
        meta: {
            submenu: 'people',
            category: 'people',
            title: () => l.t('app.address-book', 'Address book')
        },
        props: true
    },
    {
        path: '/address-book/:extension_id/groups/:id/contacts',
        component: AddressBook,
        name: 'groups.contacts',
        props: true
    },
    {
        path: '/address-book/:extension_id/contacts/:id',
        component: AddressBook,
        name: 'contacts.show',
        props: true
    },
    {
        path: '/conversations',
        component: ConversationsScreen,
        name: 'conversations',
        meta: {
            features: [dev_feature],
            category: 'inbox',
            title: () => l.t('app.conversations', 'Conversations'),
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/messages',
        component: MessagesScreen,
        name: 'messages.index',
        meta: {
            features: [dev_feature],
            category: 'inbox',
            title: () => l.t('app.messages', 'Messages'),
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/messages/create',
        component: MessagesScreen,
        name: 'messages.create',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/voicemail',
        component: VoicemailScreen,
        name: 'voicemail',
        meta: {
            features: [dev_feature],
            category: 'inbox',
            title: () => l.t('app.voicemail', 'Voicemail'),
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/fax',
        component: Faxes,
        name: 'fax.index',
        meta: {
            features: [dev_feature],
            category: 'inbox',
            title: () => l.t('app.fax', 'Fax'),
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/fax/send',
        component: Faxes,
        name: 'fax.create',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/call-logs',
        component: CallLogs,
        name: 'call-logs',
        meta: {
            title: () => l.t('app.call-logs', 'Call logs'),
            category: 'my-account'
        },
        props: true
    },
    {
        path: '/reports/:type',
        component: ReportsScreen,
        name: 'reports.show',
        props: true
    },
    {
        path: '/queues',
        component: QueuesScreen,
        name: 'queues.index',
        meta: {
            features: [dev_feature],
            category: 'calls-handling',
            title: () => l.t('app.queues', 'Queues'),
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/queues/create',
        component: QueuesScreen,
        name: 'queues.create',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/queues/:id',
        component: QueuesScreen,
        name: 'queues.show',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/menus',
        component: MenusScreen,
        name: 'menus.index',
        meta: {
            features: [dev_feature],
            category: 'calls-handling',
            title: () => l.t('app.menus', 'Menus'),
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/menus/create',
        component: MenusScreen,
        name: 'menus.create',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/menus/:id',
        component: MenusScreen,
        name: 'menus.show',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/schedules',
        component: SchedulesScreen,
        name: 'schedules.index',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent'],
            category: 'calls-handling',
            title: () => l.t('app.schedules', 'Schedules')
        },
        props: true
    },
    {
        path: '/schedules/create',
        component: SchedulesScreen,
        name: 'schedules.create',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/schedules/:id',
        component: SchedulesScreen,
        name: 'schedules.show',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/media',
        component: MediaScreen,
        name: 'media.index',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent'],
            category: 'calls-handling',
            title: () => l.t('app.greetings-music', 'Greetings & music')
        },
        props: true
    },
    {
        path: '/media/create',
        component: MediaScreen,
        name: 'media.create',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/media/music-on-hold',
        component: MediaScreen,
        name: 'media.hold-music',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent'],
            category: 'calls-handling',
            title: () => l.t('app.greetings-music', 'Greetings & music')
        },
        props: true
    },
    {
        path: '/media/loader',
        component: MediaScreen,
        name: 'media.loader',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent'],
            category: 'calls-handling',
            title: () => l.t('app.greetings-music', 'Greetings & music')
        },
        props: true
    },
    {
        path: '/media/:id',
        component: MediaScreen,
        name: 'media.show',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/media/:id',
        component: MediaScreen,
        name: 'media.music-on-hold-show',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/video-conferencing',
        component: VideoScreen,
        name: 'video.index',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent'],
            category: 'conferencing',
            title: () => l.t('app.video-conferencing', 'Video conferencing')
        },
        props: true
    },
    {
        path: '/video-conferencing/users',
        component: VideoScreen,
        name: 'video.users.index',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent'],
            category: 'conferencing',
            title: () => l.t('app.video-conferencing', 'Video conferencing')
        },
        props: true
    },
    {
        path: '/video-conferencing/users/create',
        component: VideoScreen,
        name: 'video.users.create',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/video-conferencing/:user_id/schedules',
        component: UserSchedulesScreen,
        name: 'video.users.schedules.index',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent'],
            category: 'conferencing',
            title: () => l.t('app.video-conferencing', 'Video conferencing')
        },
        props: true
    },
    {
        path: '/video-conferencing/:user_id/schedules/create',
        component: UserSchedulesScreen,
        name: 'video.users.schedules.create',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/video-conferencing/:user_id/schedules/:id',
        component: UserSchedulesScreen,
        name: 'video.users.schedules.show',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/presets',
        component: RoutesScreen,
        name: 'routes.index',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent'],
            category: 'calls-handling',
            title: () => l.t('app.presets', 'Presets')
        },
        props: true
    },
    {
        path: '/presets/create',
        component: RoutesScreen,
        name: 'routes.create',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/presets/:id',
        component: RoutesScreen,
        name: 'routes.show',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/trunks',
        component: TrunksScreen,
        name: 'trunks.index',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent'],
            title: () => l.t('app.trunks', 'Trunks'),
            category: 'my-account'
        },
        props: true
    },
    {
        path: '/trunks/create',
        component: TrunksScreen,
        name: 'trunks.create',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/trunks/:id',
        component: TrunksScreen,
        name: 'trunks.show',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/blocked-numbers',
        component: BlockedCallsScreen,
        name: 'blockedCalls.index',
        meta: {
            category: 'people',
            title: () => l.t('app.blocked-numbers', ' Blocked numbers')
        },
        props: true
    },
    {
        path: '/blocked-numbers/create',
        component: BlockedCallsScreen,
        name: 'blockedCalls.create',
        props: true
    },
    {
        path: '/blocked-numbers/recent-calls',
        component: BlockedCallsScreen,
        name: 'blockedCalls.recent',
        meta: {
            category: 'people',
            title: () => l.t('app.blocked-numbers', ' Blocked numbers')
        },
        props: true
    },
    {
        path: '/applications',
        component: ApplicationsScreen,
        name: 'applications.index',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent'],
            category: 'tool-box',
            title: () => l.t('app.applications', 'Applications')
        },
        props: true
    },
    {
        path: '/applications/create',
        component: ApplicationsScreen,
        name: 'applications.create',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/applications/:id',
        component: ApplicationsScreen,
        name: 'applications.show',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/subaccounts',
        component: SubaccountScreen,
        name: 'subaccounts.index',
        meta: {
            // will be checked by account feature => session.user.account.features['subaccounts-enabled']
            title: () => l.t('app.subaccounts', 'Subaccounts'),
            category: 'single-route',
            icon: '$vuetify.icons.persons'
        },
        props: true
    },
    {
        path: '/subaccounts/create',
        component: SubaccountScreen,
        name: 'subaccounts.create',
        meta: {
        },
        props: true
    },
    {
        path: '/accounts/settings',
        component: AccountSettings,
        name: 'account.settings',
        meta: {
            features: [],
            title: () => l.t('app.settings', 'Settings'),
            icon: '$vuetify.icons.gear',
            category: 'my-account'
        },
        props: true
    },
    {
        path: '/accounts/contacts',
        component: AccountSettings,
        name: 'account.contacts',
        meta: {
            features: [],
            submenu: 'my-account'
        },
        props: true
    },
    {
        path: '/live-receptionist',
        component: LiveAnswerScreen,
        name: 'live-answer.index',
        props: true,
        meta: {
            features: [],
            category: 'single-route',
            title: () => l.t('app.receptionist-services', 'Receptionist services'),
            icon: '$vuetify.icons.headset'
        }
    },
    {
        path: '/live-receptionist/create',
        component: LiveAnswerScreen,
        name: 'live-answer.create',
        meta: {
            features: []
        },
        props: true
    },
    {
        path: '/live-receptionist/:id',
        component: LiveAnswerScreen,
        name: 'live-answer.show',
        meta: {
            features: []
        },
        props: true
    },
    {
        path: '/conferencing',
        component: ConferencingScreen,
        name: 'conferencing',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent'],
            category: 'conferencing',
            title: () => l.t('app.audio-conferencing', 'Audio conferencing')
        },
        props: true
    },
    {
        path: '/mobile-apps',
        component: MobileAppsScreen,
        name: 'mobile-apps',
        meta: {
            features: [],
            title: () => l.t('app.mobile-apps', 'Mobile apps'),
            category: 'my-account'
        },
        props: true
    },
    {
        path: '/advanced-tools',
        component: AdvancedToolsScreen,
        name: 'advanced-tools',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent'],
            category: 'tool-box',
            title: () => l.t('app.advanced-tools', 'Advanced tools')
        },
        props: true
    },
    {
        path: '/api-requests',
        component: ApiRequestsScreen,
        name: 'api-requests.create',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent'],
            category: 'tool-box',
            title: () => l.t('app.api-requests', 'API requests')
        },
        props: true
    },
    {
        path: '/scheduled-requests',
        component: ApiRequestsScreen,
        name: 'api-requests.index',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent'],
            category: 'tool-box',
            title: () => l.t('app.api-requests', 'API requests')
        },
        props: true
    },
    {
        path: '/api-requests/:id',
        component: ApiRequestsScreen,
        name: 'api-requests.show',
        meta: {
            features: [dev_feature],
            allowed_for_scopes: ['agent']
        },
        props: true
    },
    {
        path: '/',
        component: HomepageScreen,
        name: 'homepage',
        props: false
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: NotFound
    }

]

export default routes
