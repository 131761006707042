import React, { useCallback, useContext } from 'react'
import { MyPhoneContact } from 'models'
import VirtualSelector from 'virtual-selector'
import { FeatureEventsContext, MyPhoneContactConsumer } from 'providers'
import { ListItem, Avatar, Typography } from '@material-ui/core'
import { formatPhoneNumber } from 'phone-numbers'
import CircularProgress from '@material-ui/core/CircularProgress'
import { CheckIcon } from 'svg-icons'
import { makeStyles } from '@material-ui/core'
import styles from '../styles'

const useStyles = makeStyles(styles)

interface Props {
    keyword: string
    onSelect: (item: any) => void
    inbox?: number | null
    testContact?: any
    height: number
}

type ExtensionNumber = number
type PhoneNumber = string

interface ItemProps {
    contact: MyPhoneContact
    onClick: (number: ExtensionNumber | PhoneNumber) => void
}

interface NumberInfo {
    name: string
    number: string | number
    type: string
}

const Item = (props: ItemProps): JSX.Element => {
    const { contact, onClick } = props
    const classes = useStyles()
    const featureEventsContext = useContext(FeatureEventsContext)
    const allNumbers = Array.from(contact.numbers.values())
    const numbers = allNumbers.reduce((numbers, numberInfo) => {
        if (numberInfo.type === 'extension') numbers.extension = numberInfo
        else numbers.phoneNumbers.push(numberInfo)
        return numbers
    }, { extension: null as NumberInfo | null, phoneNumbers: [] as NumberInfo[] })
    const formatNumberString = (phoneNumber) => {
        const type = phoneNumber.type.charAt(0).toUpperCase() + phoneNumber.type.slice(1)
        return `${type} ${formatPhoneNumber(phoneNumber.number)}`
    }
    const extNum = numbers.extension?.number.toString()
    const isExtSelected = false
    return (
        <div className={classes.personItem}>
            <Avatar classes={{ root: 'person-avatar' }} src={contact.avatarUrl || undefined}/>
            <div className='person-info'>
                <div className='person-info-item person-name'><Typography variant='body1'>{contact.displayName}</Typography></div>
                {extNum
                    ? <div
                        className = {`person-info-item ${isExtSelected ? 'selected' : ''}`}
                        onClick = {() => {
                            featureEventsContext.pushEvent({ appName: 'softphone', key: 'contact-selector-item-click', value: 'extension' })
                            onClick(extNum)
                        }}
                    >
                        <Typography variant='body2'>ext {extNum}</Typography>
                        {isExtSelected && <CheckIcon/>}
                    </div>
                    : null
                }
                {numbers.phoneNumbers.map(pn => {
                    const isSelected = false
                    return (
                        <div
                            className = {`person-info-item ${isSelected ? 'selected' : ''}`}
                            key = {pn.number}
                            onClick = {() => {
                                featureEventsContext.pushEvent({ appName: 'softphone', key: 'contact-selector-item-click', value: 'phone-number' })
                                onClick(pn.number)
                            }}
                        >
                            <Typography variant='body2'>{formatNumberString(pn)}</Typography>
                            {isSelected && <CheckIcon/>}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const LoadingItem = (): JSX.Element => {
    return (
        <ListItem style={{ height: '56px', justifyContent: 'center' }}>
            <CircularProgress size={20}/>
        </ListItem>
    )
}

/***/
const ContactSelector = (props: Props): JSX.Element => {
    const classes = useStyles()
    const keyword = props.keyword
    const getTitle = useCallback((source) => source === 'pdcExtensions' ? 'teammates' : 'contacts', [])
    const renderItemLoaded = (index: number, contact: MyPhoneContact) => {
        return (
            <>
                {contact.firstOfType
                    ? <div className={classes.title}>
                        <Typography variant='overline'>{getTitle(contact.source)}</Typography>
                    </div>
                    : null
                }
                <Item
                    contact = {contact}
                    onClick = {props.onSelect}
                />
            </>
        )
    }
    if (props.testContact) return renderItemLoaded(0, props.testContact)
    const getItemSize = (contact: MyPhoneContact): number => {
        const headerSize = contact.firstOfType ? 27 : 0
        const paddingHeight = 20 // 2 * 10
        const avatarHeight = 40
        const nameHeight = 29
        const extensionHeight = contact.source === 'pdcExtensions' ? 26 : 0
        const numNumbers = contact.numbers.size + (contact.source === 'pdcExtensions' ? -1 : 0)
        const numbersHeight = numNumbers * 26
        const totalHeight = headerSize + paddingHeight + Math.max(avatarHeight, nameHeight + extensionHeight + numbersHeight)
        return totalHeight
    }
    return (
        <MyPhoneContactConsumer>
            {(context: any) => (
                <VirtualSelector
                    list={context.list}
                    next={context.next}
                    height={props.height}
                    renderItemLoaded={renderItemLoaded}
                    renderItemLoading={LoadingItem}
                    itemSize={getItemSize}
                    filter={keyword}
                />
            )}
        </MyPhoneContactConsumer>
    )
}

export default ContactSelector
