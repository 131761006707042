import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VHover,{attrs:{"open-delay":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c(VCard,{class:{ 'on-hover': hover },attrs:{"elevation":hover ? 16 : 2,"height":"100%"}},[_c(VCardText,{class:{'text-right': !_vm.second }},[_c('h3',{staticClass:"mb-4"},[_c('span',{class:{
            'primary--text':
              _vm.$options.filters.name(_vm.contact) === _vm.$options.filters.name(_vm.compared),
            'warning--text': _vm.contact.company !== _vm.compared.company,
          },attrs:{"data-test-id":"duplicate-name"}},[_vm._v("\n          "+_vm._s(_vm._f("name")(_vm.contact))+"\n        ")])]),_vm._v(" "),_c('h4',{staticClass:"font-weight-bold mb-4",attrs:{"data-test-id":"duplicate-id"}},[_vm._v(_vm._s(_vm.l.t("app.id", "ID"))+": "+_vm._s(_vm.contact.id))]),_vm._v(" "),_c('h4',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.l.t("app.company", "Company"))+":")]),_vm._v(" "),_c('p',[_c('span',{class:{
            'success--text':
              _vm.contact.company &&
              _vm.compared.company &&
              _vm.contact.company === _vm.compared.company,
            'warning--text': _vm.contact.company !== _vm.compared.company,
          },attrs:{"data-test-id":"duplicate-company"}},[_vm._v("\n          "+_vm._s(_vm.contact.company ? _vm.contact.company : "—")+"\n        ")])]),_vm._v(" "),_c('h4',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.l.t("app.phone-numbers", "Phone numbers"))+":")]),_vm._v(" "),_c('p',{attrs:{"data-test-id":"duplicate-phone-numbers"}},[(_vm.contact.phone_numbers && _vm.contact.phone_numbers.length)?_vm._l((_vm.contact.phone_numbers),function(number){return _c('span',{class:{
              'success--text': _vm.compared.phone_numbers.find(
                function (x) { return x.number === number.number; }
              ),
              'warning--text': !_vm.compared.phone_numbers.find(
                function (x) { return x.number === number.number; }
              ),
            }},[_vm._v("\n            "+_vm._s(number.number)+" "),_c('br')])}):_c('span',[_vm._v("—")])],2),_vm._v(" "),_c('h4',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.l.t("app.emails", "Emails"))+":")]),_vm._v(" "),_c('p',{attrs:{"data-test-id":"duplicate-emails"}},[(_vm.contact.emails && _vm.contact.emails.length)?_vm._l((_vm.contact.emails),function(email){return _c('span',{class:{
              'success--text': _vm.compared.emails.find(function (x) { return x.email === email.email; }),
              'warning--text': !_vm.compared.emails.find(
                function (x) { return x.email === email.email; }
              ),
            }},[_vm._v("\n            "+_vm._s(email.email)+" "),_c('br')])}):_c('span',[_vm._v("—")])],2),_vm._v(" "),_c('h4',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.l.t("app.group", "Group"))+":")]),_vm._v(" "),_c('p',{attrs:{"data-test-id":"duplicate-group"}},[_c('span',{class:{
            'success--text':
              _vm.contact.group &&
              _vm.compared.group &&
              _vm.contact.group.id === _vm.compared.group.id,
            'warning--text':
              _vm.contact.group &&
              _vm.compared.group &&
              _vm.contact.group.id !== _vm.compared.group.id,
          }},[_vm._v("\n          "+_vm._s(_vm.contact.group ? _vm.contact.group.name : "—")+"\n        ")])]),_vm._v(" "),_c('h4',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.l.t("app.created-at", "Created at"))+":")]),_vm._v(" "),_c('p',{attrs:{"data-test-id":"duplicate-created-at"}},[_vm._v("\n        "+_vm._s(_vm._f("time")(_vm.contact.created_at))+"\n      ")])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }