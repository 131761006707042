import React, { createContext, useContext } from 'react'
import { useRouteMatch, Redirect } from 'react-router-dom'
import { useAppsContext } from './AppsContext'
import { App } from '../utils/objects'
import { getBasePath, isObjectEmpty } from '../utils/utils'

/**
 *
 */
export interface AppContextInterface {
    app: App | Record<string, string|number|boolean>
}

/**
 *
 */
export const AppContext = createContext<AppContextInterface>({
    app: {}
})

/**
 *
 */
export const useAppContext = () => useContext(AppContext)

/**
 *
 */
const AppContextProvider = ({ children, slug }: { children: JSX.Element, slug: string }): JSX.Element => {
    const routeMatch = useRouteMatch()
    const basePath = getBasePath(routeMatch)

    const appsContext = useAppsContext()
    const appTitle = slug.replace(/-/g, ' ')
    const app = appsContext.apps.find(app => app.title.toLowerCase() === appTitle) || {}

    return (
        !isObjectEmpty(app)
            ? <AppContext.Provider value={{
                app: app as App
            }} >
                {children}
            </AppContext.Provider>
            : <Redirect to={basePath} />
    )
}

export default AppContextProvider
