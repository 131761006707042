import React from 'react'
import { withStyles } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import Divider from '@material-ui/core/Divider'
import tvPhoneIcon from '../images/imac-iphone.svg'
import { logout } from 'phonecom'

const useStyles = makeStyles(theme => ({
	list: {
		padding: '0px',
		'margin-bottom': '10px',
		'font-size': '18px'
	}
}))

const CPStyles = theme => ( {
	link: {
		color: theme.palette.primary.main,
		'font-weight': '500',
		'&:hover': {
			color: theme.palette.primary.main
		}
	},
})

const SOStyles = theme => ( {
	link: {
		color: 'black',
		'font-size': '16px',
		'font-weight': '400',
		'&:hover': {
			color: 'black'
		}
	}
})

const EXStyles = theme => ( {
	link: {
		color: 'black',
		'font-size': '16px',
		'font-weight': '400',
		'&:hover': {
			color: 'black'
		}
		// color: theme.primaryColor
	}
})

export default function ControlPanelLinks(props) {
	console.log('constol pannel', props)
	const classes		= useStyles()
	const cfg			= window.APP_CONFIG
	const userInfo		= props.userInfo
	console.log('user-info', props.userInfo)
	const session		= userInfo.cp_session_token
	const cpBase		= cfg.cpBase
	const isMobileView	= props.screenViewType.isMobileView
	const isTabletView	= props.screenViewType.isTabletView
	const cpLinks = [
		{
			name: 'Address Book',
			url: `${cpBase}/voip/extension?action=view_contacts;_voip_phone_id=${userInfo.extension_id};_sid=${session}`,
		},
		{
			name: 'Extension Settings',
			url: `${cpBase}/voip/extension?action=general_settings;_voip_phone_id=${userInfo.extension_id};_sid=${session}`,
		},
		{
			name: 'Help',
			url: 'https://www.phone.com/support/',
		}
	]

	let externalLinks = [
		{
			name: 'Get The Mobile App',
			url: 'https://www.phone.com/mobile/'
		}
	]
	let isCsr = this.props.userInfo?.csr

		if (!process.env.REACT_APP_DOWNLOAD_LINKS_HIDDEN || isCsr) {
		this.links =	[
			{
				name: 'Get Windows My Phone.com App',
				url: process.env.REACT_APP_WINDOWS_DESKTOP_APP_DOWNLOAD_LINK
			},
			{
				name: 'Get Mac My Phone.com App',
				url: process.env.REACT_APP_MAC_DESKTOP_APP_DOWNLOAD_LINK
			},
			{
				name: 'Get Linux My Phone.com App',
				url: process.env.REACT_APP_LINUX_DESKTOP_APP_DOWNLOAD_LINK
			}
		].concat(this.links)
	}

	// const signoutLink = `${cfg.redirect_url}&action=logout;_sid=${session}`

	return (
		<div style={{height: '100%', overflow: 'auto'}}>
			<List className={classes.list}>
				{cpLinks.map((link, i) => {
					return (
						<StyledControlPanelLink key={i} url={link.url}>
							<ListItem button>
								{link.name}
							</ListItem>
						</StyledControlPanelLink>
				)})}
			</List>

			{!isMobileView && !isTabletView &&
				<img style={{width: '100px'}} src={tvPhoneIcon} alt='pdc icon' />
			}

			<List>
				{externalLinks.map((link, i) => {
					return (
						<StyledExternalLink key={i} url={link.url}>
							<ListItem button>
								{link.name}
							</ListItem>
						</StyledExternalLink>
					)
				})}
			</List>

			<Divider></Divider>

			<List>
				<StyledSignOutLink logout={logout}> {/* url={signoutLink}>*/}
					<ListItem button>
						Sign Out
					</ListItem>
				</StyledSignOutLink>
			</List>
		</div>
	)
}

function Link(props) {
	const { classes } = props
	return (
		<a target='_blank' rel='noreferrer noopener' href={props.url} className={classes.link}>
			{props.name || props.children}
		</a>
	)
}

function Logout(props) {
	const { classes } = props

	const logout = () => {
		window.localStorage.removeItem('current_extension_id')
		window.localStorage.removeItem('current_phone_number')
		props.logout()
	}

	return (
		<div onClick={logout} className={classes.link}>
			{props.name || props.children}
		</div>
	)
}

const StyledControlPanelLink	= withStyles(CPStyles)(Link)
const StyledExternalLink		= withStyles(EXStyles)(Link)
const StyledSignOutLink			= withStyles(SOStyles)(Logout)
