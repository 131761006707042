import React, { useState, useEffect } from 'react'
import Typography from 'typography'
import Checkbox, { Variant as CheckboxVariant } from 'checkbox-mui'
import { makeStyles } from '@material-ui/core/styles'
import { getValue } from 'remote-config-value'
import PropTypes from 'prop-types'
import CampaignEnrollmentStepContainer from './CampaignEnrollmentStepContainer.tsx'

const useStyles = makeStyles((theme) => ({
    checkboxContainer: {
        paddingBottom: '1rem'
    },
    container: { paddingTop: '1rem', paddingBottom: '1rem' }
}))

/**
 * @param {object} props
 */
const CampaignPoliciesStep = (props) => {
    const campaignPoliciesData = props.passData.campaign_policies
    const readOnly = props.readOnly || false
    const classes = useStyles()

    const [checked, setChecked] = useState(campaignPoliciesData ? campaignPoliciesData.checked : null)
    const [checked2, setChecked2] = useState(campaignPoliciesData ? campaignPoliciesData.checked2 : null)
    const [checked3, setChecked3] = useState(campaignPoliciesData ? campaignPoliciesData.checked3 : null)

    const invalid = !checked || !checked2 || !checked3

    const renderCheckbox = ({
        labelID,
        state,
        onChangeHandler
    }) => (
        <div className={classes.checkboxContainer} key={labelID}>
            <Checkbox
                variant={CheckboxVariant.PRIMARY}
                checked={state}
                onChange={readOnly ? null : (type, checked) => onChangeHandler(checked)}
                label={getValue(labelID)} />
        </div>
    )

    const checkboxes = [
        {
            labelID: 'sms_enrollment_policies_checkbox_1',
            state: checked,
            onChangeHandler: setChecked
        },
        {
            labelID: 'sms_enrollment_policies_checkbox_2',
            state: checked2,
            onChangeHandler: setChecked2
        },
        {
            labelID: 'sms_enrollment_policies_checkbox_3',
            state: checked3,
            onChangeHandler: setChecked3
        }
    ]

    const getSaveData = () => {
        return {
            checked,
            checked2,
            checked3
        }
    }
    useEffect(() => {
        if (props.saveId) props.update({ completed: true, gotoNext: true, saveData: getSaveData() })
    }, [props.saveId])

    useEffect(() => {
        props.update({ disableNextButton: () => invalid })
    }, [invalid])

    return (
        <CampaignEnrollmentStepContainer readOnly={readOnly}>
            {
                readOnly && (
                    <div className={classes.container}>
                        <Typography variant='h4' remoteConfigID='sms_enrollment_content_and_messaging' />
                    </div>
                )
            }
            <Typography variant='h6' remoteConfigID='sms_enrollment_policies_heading' />
            <Typography variant='subtitle3' remoteConfigID='sms_enrollment_policies_subtitle' />
            {checkboxes.map(x => renderCheckbox(x))}
        </CampaignEnrollmentStepContainer>
    )
}

CampaignPoliciesStep.propTypes = {
    passData: PropTypes.object,
    update: PropTypes.func,
    saveId: PropTypes.number,
    readOnly: PropTypes.bool
}

export default CampaignPoliciesStep
