import React, { useState } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVert from '@material-ui/icons/MoreVert'
import { makeStyles } from '@material-ui/core/styles'
import Api from 'api'
import Spinner from 'spinner'
import Alert, { Color as AlertColor } from 'alert-mui'
import Snackbar from '@material-ui/core/Snackbar'

import axios from 'axios'
import fileDownload from 'js-file-download'
import { getValue } from 'remote-config-value'

const useStyles = makeStyles(theme => ({
    menuPaper: { ...theme.dropdownMenu.paper, minWidth: 200 },
    menuList: theme.dropdownMenu.list,
    menuItem: Object.assign({ columnGap: 10 }, theme.dropdownMenu.list.item),
    downloadItem: {
        padding: 0,
        '& a': {
            color: '#000',
            padding: '8px 13px',
            width: '100%'
        },
        '&:hover a': {
            color: theme.palette.primary.main
        }
    }
}))

interface Props {
    changeReadStatus?: { call: () => void, text: string }
    deleteFax?: () => void
    downloadFax?: { url: string }
    downloadReceipt?: boolean
    faxId: string
}

type AlertState = [string, (message: string, timeout?: number) => void]

const useAlertState = (): AlertState => {
    const [alertMessage, setAlertMessage] = useState('')
    const updateMessage = (newAlertMessage: string, timeout = 0) => {
        setTimeout(() => setAlertMessage(newAlertMessage), timeout)
    }
    return [alertMessage, updateMessage]
}

/***/
const FaxHeaderDropdownMenu = (props: Props): JSX.Element => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)
    const [confirmationLoading, setConfirmationLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useAlertState()

    const handleClose = () => setAnchorEl(null)

    const showDropdown = (e) => setAnchorEl(e.currentTarget)

    const deleteFax = () => {
        props.deleteFax?.()
        handleClose()
    }

    const changeReadStatus = () => {
        props.changeReadStatus?.call()
        handleClose()
    }

    const downloadFax = () => handleClose()

    const downloadReceipt = async () => {
        setConfirmationLoading(true)
        const response = await Api.getReceiptUrl(props.faxId)
        if (response.error) {
            setShowAlert(true)
            setAlertMessage('Fax info not found')
        } else {
            const fileName = response.url.split('/').pop().split('.pdf')[0] + '.pdf'
            const res = await axios.get(response.url, { responseType: 'blob' })
            fileDownload(res.data, fileName)
        }
        setConfirmationLoading(false)
        handleClose()
    }

    const closeAlert = () => {
        setShowAlert(false)
        setAlertMessage('', 300)
    }

    return (
        <div>
            <MoreVert aria-controls='options-dropdown-menu' aria-haspopup='true' className='options-dropdown-btn' onClick={showDropdown} />
            <Menu
                id = 'options-dropdown-menu'
                anchorEl = {anchorEl}
                open = {Boolean(anchorEl)}
                onClose = {handleClose}
                classes = {{ paper: classes.menuPaper, list: classes.menuList }}
                getContentAnchorEl = {null}
                anchorOrigin = {{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin = {{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                keepMounted
            >
                {props.deleteFax ? <MenuItem classes={{ root: classes.menuItem }} dense onClick={deleteFax}>Delete</MenuItem> : null}
                {props.changeReadStatus ? <MenuItem classes={{ root: classes.menuItem }} dense onClick={changeReadStatus}>{props.changeReadStatus.text}</MenuItem> : null}
                {props.downloadFax
                    ? <MenuItem classes={{ root: `${classes.menuItem} ${classes.downloadItem}` }} dense onClick={downloadFax}>
                        <a href={props.downloadFax.url} download='newname' rel='noreferrer' target='_blank'>Download</a>
                    </MenuItem>
                    : null
                }
                {props.downloadReceipt
                    ? <MenuItem disabled={confirmationLoading} classes={{ root: classes.menuItem }} dense onClick={downloadReceipt}>
                        {getValue('fax_download_receipt_menu_text')} {confirmationLoading ? <Spinner/> : null}
                    </MenuItem>
                    : null
                }
            </Menu>
            <Snackbar open={showAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} onClose={closeAlert}>
                <Alert
                    content = {alertMessage}
                    color = {AlertColor.ERROR}
                    onClose = {closeAlert}
                />
            </Snackbar>
        </div>
    )
}

export default FaxHeaderDropdownMenu
