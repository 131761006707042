import { emptyRouteRule } from 'helpers'
import l from '../libs/lang'
import Resource from './Resource'
import RouteAnalyzer from './RouteAnalyzer'
import setup from '../libs/resources-setups/routes'
/**
 *
 */
export default class Route extends Resource {
    /**
     * @param {object} session - PhoenixAPIJSClient Object
     * @param {object} component helpers vueComponent
     */
    constructor (session, component) {
        super(session, component, '/routes')
        this.selector_mode = ['presets']
        this.setup = setup
        this.filters = {
            name: null,
            extension: null,
            include_unnamed: false
        }

        this.item = {
            name: '',
            rules: [emptyRouteRule()]
        }
    }

    /**
     *
     */
    static generic_preset_name () {
        return `${l.t('app.preset', 'Preset')} ${new Date().toLocaleString()}`
    }

    /**
     *
     * @param {object} item route obj
     * @returns
     */
    static has_the_same_filters (item) {
        const { rules } = item
        if (rules) {
            if (rules.filter((x) => !x.filter).length > 1) return { type: 'all-calls' }
            const filters = rules.filter((x) => x.filter).map((x) => x.filter)
            for (let i = 0; i < filters.length; i++) {
                if (filters.find((x, j) => i !== j && x.type === filters[i].type && filters[i][filters[i].type] && filters[i][filters[i].type].id === x[x.type].id)) {
                    return filters[i]
                }
            }
        }

        return null
    }

    /**
     *
     * @param {object} params pagination params
     * @returns
     */
    async loadItems (params) {
        this.loading = true
        try {
            const offset = this.checkIfUserDeletedItems(params.offset, params.page)
            const presets_filter = 'filters[name]=not-empty&with_usage=1'
            let { uri } = this
            if (!this.uri.includes(presets_filter) && !this.filters.include_unnamed && !this.filters.name) {
                if (uri.includes('?')) {
                    uri += `&${presets_filter}`
                } else {
                    uri += `?${presets_filter}`
                }
            }
            const items = await this.session.get_list(
                uri, params.limit, offset
            )

            this.page = params.page
            if (this.page > 1 && !items.items.length) {
                this.component.emit('pageChanged', 1)
                return this.loadItems({
                    limit: this.limit,
                    offset: 0,
                    page: 1
                })
            }
            if (this.page === 1 && !this.filters_applied && !items.items.length) return this.component.routerPush('routes.create')

            this.items = items.items
            for (const item of this.items) {
                const analysis = new RouteAnalyzer({ ...item })
                if (!item.route_analysis) item.route_analysis = analysis
            }
            this.finalizeLoadingItems(items)
            this.component.emit('itemsLoaded', JSON.parse(JSON.stringify(items)))
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false

        return this.items
    }

    /**
     *
     * @param {object} item data
     * @returns
     */
    async create_route (item) {
        const new_route = await this.session.create_item(this.baseUri, item)
        if (new_route.name) this.addToCache(new_route)

        return new_route
    }

    /**
     *
     */
    async create () {
        this.loading = true
        try {
            const new_route = await this.create_route(this.item)

            this.successfulCreation('routes.index', null, null, new_route)
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
    }

    /**
     *
     * @param {object} data
     * @returns
     */
    async update_route (data) {
        if (!data.extension) this.extension = null
        const item = await this.session.replace_item(`${this.baseUri}/${data.id}`, data)
        if (item.name) this.updateCache(item, 'presets')

        return item
    }

    /**
     *
     */
    async update () {
        this.loading = true
        try {
            await this.update_route(this.item)
            this.item = null
            this.successfulUpdate('routes.index')
        } catch (err) {
            this.validation_error(err)
        }
        this.loading = false
    }

    /**
     *
     */
    apply_filters () {
        this.clearMessages()
        this.filters_applied = true

        try {
            let uri = `${this.baseUri}?mode=full&with_usage=1`
            if (this.filters.name) uri = `${uri}&filters[name]=contains:${this.filters.name}`
            if (this.filters.extension) uri = `${uri}&filters[extension]=${this.filters.extension}`
            if (!this.filters.include_unnamed && !this.filters.name) {
                uri = `${uri}&filters[name]=not-empty`
            }
            this.uri = uri
        } catch (err) {
            this.component.emit('failed', err)
        }
    }

    /**
     *
     */
    async checkAll () {
        super.checkAll()
        this.items.map((x) => {
            if (x.generator && x.generator === 'configure-app') {
                x.selected = false
            }
            return x
        })
    }

    /**
     *
     * @param {Array} itms
     * @returns
     */
    async pre_delete_all_filter (itms) {
        const items = itms.filter((x) => !(x.generator && x.generator === 'configure-app'))
        return items
    }
}
