import l from '../lang';

export default {
	'routes': {
		'create': 'devices.create',
		'show': 'devices.show',
		'index': 'devices.index',
	},
	'delete-single': l.t('devices.delete-single', 'Are you sure you want to delete this device?'),
	'delete-filtered': l.t('devices.delete-filtered', 'Are you sure you want to delete all filtered devices?'),
	'delete-all': l.t('devices.delete-all', 'Are you sure you want to delete all devices?'),
	'successfull-creation': l.t('devices.successfully-created', 'Your device has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('devices.failed-creation', 'There was a problem creating your device'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
};
