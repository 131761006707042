import React, { useEffect, useState, useContext } from 'react'
import Typography from 'typography'
import IconButton from '@material-ui/core/IconButton'
import type { App as AppObjInterface } from '../utils/objects'
import { Button, Dialog, DialogContent } from '@material-ui/core'
import { ZohoIntegrationWidget } from 'integration-widget'
import { CloseIcon } from 'svg-icons'
import { LiveReceptionistCreate } from 'live-receptionist-react-components'
import { OrdersCreate } from 'orders-react-components'
import { ScreenSizeContext } from 'providers'
const ZohoDialog = (props: { zoho: AppObjInterface }) => {
    const onClick = () => {
        window.open(props.zoho.url, '_blank')
    }
    return (
        <div>
            <Typography variant={'h1'}>Zoho CRM</Typography>
            <Typography>First have your Zoho Adminitstrator install the Phone.com Telephoney application.</Typography>
            <div style={{ padding: '4px' }}>
                <Button variant='outlined' onClick={onClick}><Typography variant='button'>Zoho Marketplace</Typography></Button>
            </div>
            <Typography>Second use the panel below to authorize the Phone.com Zoho Integration.</Typography>
            <Typography>Then have your Phone.com users integrate with their Zoho account.</Typography>
            <Typography>Please wait a few minutes for the integration to complete after authorizing.</Typography>
            <ZohoIntegrationWidget />
        </div>
    )
}

const RenderLRComponent = (props: { vendorId: number }) => {
    return (
        <LiveReceptionistCreate id={null} vendor_id={props.vendorId} />
    )
}

const RenderBuyPhonesComponent = () => {
    return (
        <OrdersCreate />
    )
}

/**
 *
 */
const AppDialog = (props: {app : AppObjInterface, onClose: () => void, open: boolean}): JSX.Element => {
    const { app, onClose, open } = props
    const screenSize = useContext(ScreenSizeContext)
    const DefaultDialog = () => {
        return (
            <div>
                <Typography>App Title: {app.title}</Typography>
                <Typography>App ID: {app.id}</Typography>
            </div>
        )
    }
    const [dialogContent, setDialogContent] = useState(<DefaultDialog />)
    const switchDialogContent = () => {
        switch (app.id) {
                case 1:
                // Live Receptionist
                    setDialogContent(<RenderLRComponent vendorId={1} />)
                    break
                case 2:
                    // IAB
                    setDialogContent(<RenderLRComponent vendorId={4} />)
                    break
                case 3:
                    // ZOHO
                    setDialogContent(<ZohoDialog zoho={app}/>)
                    break
                case 4:
                    // Buy Phones
                    setDialogContent(<RenderBuyPhonesComponent/>)
                    break
                default:
                    setDialogContent(<DefaultDialog />)
        }
    }
    useEffect(switchDialogContent, [])

    return (
        <Dialog
            maxWidth='md'
            fullWidth={true}
            id='AppDialog'
            aria-labelledby='app-dialog'
            onClose={onClose}
            open={open}
            fullScreen = {screenSize?.mobile}

        >
            <IconButton aria-label="close" style={{ position: 'absolute', top: '8px', right: '8px', padding: '8px' }} onClick={onClose}><CloseIcon /></IconButton>
            <DialogContent style={{ overflowX: 'hidden' }}>
                <div style={{ height: '80vh' }}>
                    {dialogContent}
                </div>
            </DialogContent>
        </Dialog>
    )
}

/**
 *
 */
export { AppDialog }
