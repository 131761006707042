import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Typography from 'typography'
import Button from 'button'
import {
    StarredIcon
} from 'svg-icons'
import { App } from '../../../utils/objects'
import { useAppContext } from '../../../contexts/AppContext'
import OutlinedBtn from '../../../components/OutlinedBtn'
import InstalledBadge from '../../../components/InstalledBadge'

const useStyles = makeStyles(theme => ({
    headerBox: {

    },
    OutlinedBtnContainer: {
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '1rem'
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'left',
            marginBottom: '1rem'
        }
    },
    appTitle: {
        fontWeight: '600!important'
    },
    appBasicDetailsContainer: {
        display: 'flex',
        gap: '2rem',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            gap: '1rem'
        }
    },
    appBasicDetails: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            gap: '.5rem'
        }
    },
    starredIcon: {
        color: theme.palette.primary.main,
        fontSize: '1.3rem'
    },
    ratingsContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        columnGap: '0.25rem',
        "& [class*='MuiTypography-root']": {
            display: 'inline',
            fontWeight: '600!important'
        }
    },
    installedBadge: {
        marginTop: 'auto'
    },
    installBtn: {
        fontSize: '1rem',
        marginTop: 'auto',
        padding: '7px 20px 7px'
    }
}))

/**
 *
 */
const Header = (): JSX.Element => {
    const classes = useStyles()
    const appContext = useAppContext()
    const app = appContext.app as App

    const renderAppBasicDetails = () => {
        return (
            <Box className={classes.appBasicDetails}>
                <Typography variant='h4' className={classes.appTitle}>{app.title}</Typography>
                <Typography variant='body1'>by {app.company}</Typography>
                <Box className={classes.ratingsContainer}>
                    <Typography variant='subtitle1'>{app.details.averageRating}</Typography>
                    <StarredIcon className={classes.starredIcon} data-testid='icon' />
                    <Typography color='primary' variant='subtitle1'>{app.details.totalRatings} Ratings</Typography>
                </Box>
                {app.hasInstalled
                    ? <InstalledBadge className={classes.installedBadge} />
                    : <Button variant='filled'className={classes.installBtn} >INSTALL</Button>
                }
            </Box>
        )
    }

    return (
        <Box className={classes.headerBox}>
            <Box className={classes.OutlinedBtnContainer}>
                <OutlinedBtn color='primary' icon='add' iconPlacement='right' remoteConfigID='apps_marketplace_get_support' />
            </Box>
            <Box className={classes.appBasicDetailsContainer}>
                {/* <AppIcon icon={app.icon} size='large' /> */}
                <img src={app.icon} alt={`${app.title} icon`} />
                {renderAppBasicDetails()}
            </Box>
        </Box>
    )
}

export default Header
