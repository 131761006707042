<template>
  <div class="menus">
    <Submenu
      :routes="{
        'menus.index': {
          title: l.t('app.menus', 'Menus'),
          subroutes: ['menus.show']
        },
        'menus.create': {
          title: l.t('menu.add-menu', 'Add menu')
        },
      }"
      :key="`${l.detect()}${$route.name}`"
    />
    <MenusFragment
      v-if="$route.name === 'menus.index'"
      data-test-id="menus-index"
    />
    <MenusCreateFragment
      v-if="$route.name === 'menus.create'"
      data-test-id="menus-create"
    />
    <MenusShowFragment v-if="$route.name === 'menus.show'" data-test-id="menus-show"/>
  </div>
</template>

<script>
import l from '../../../libs/lang';
import MenusFragment from './index.vue';
import MenusShowFragment from './show.vue';
import MenusCreateFragment from './create.vue';
import Submenu from '../../elements/Submenu.vue';

export default {
  components: {
    Submenu,
    MenusFragment,
    MenusCreateFragment,
    MenusShowFragment,
  },
  data() {
    return {
      l,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (window.deleting_in_progress) {
      this.$emit('userRedirected', to, from, next);
    } else {
      next();
    }
  },
};
</script>
