<template>
	<div class="csv-downloader mb-3">
		<SimpleProgressbar
			:key="downloader.items_loaded"
			:current="downloader.items_loaded"
			:total="downloader.total"
			:pervious="downloader.items_loaded_before"
			data-test-id="csv-downloader-simple-progressbar"
		/>
		<div v-if="downloader.downaloading_message" class="w-body-2" data-test-id="csv-downloader-downloading-message">
			{{ downloader.downaloading_message }}
			<div v-if="downloader.items_loaded !== downloader.total" data-test-id="csv-downloader-total">
				{{ l.t('app.total', 'Total') }}: {{ downloader.total }}
			</div>
			<div v-else-if="downloader.items_loaded !== 0 && downloader.total !== 0" data-test-id="csv-downloader-preparing">
				{{ l.t('app.preparing-csv', 'Preparing your csv...') }}
			</div>
		</div>
		<div v-if="downloader.items_loaded !== downloader.total || downloader.items_loaded === 0" class="d-flex justify-center" data-test-id="csv-downloader-abort-btn">
			<w-btn @click="downloader.stop = true;" :disabled="downloader.stop" color="error" class="mt-3">
				{{ downloader.stop ? l.t('app.aborting-process', 'Aborting the process...') : l.t('app.cancel', 'Cancel') }}
			</w-btn>
		</div>
	</div>
</template>

<script>
import l from '../../libs/lang';
import SimpleProgressbar from './SimpleProgressbar.vue';

export default {
	props: {
		'downloader': {
			'required': true,
			'type': Object,
			'validator': function (value) {
				return value.constructor.name === 'CsvItemsDownloader';
			}
		},
	},
	components: { SimpleProgressbar },
	data() {
		return { l };
	},
};
</script>
<style lang="scss" scoped>
	.csv-downloader {
		text-align: center;
		.form {
			text-align: left;
		}
	}
</style>
