<template>
    <ReportsWrapper :report="report" data-test-id="call-reports-types">
        <div class="mt-5">
            <TypesChart :items="report.items"/>
            <v-simple-table data-test-id="types-table">
                <thead data-test-id="table-head">
                  <tr data-test-id="head-row">
                      <th style="width: 100%" data-test-id="type">{{ $lang.t('app.type', 'Type') }}</th>
                      <th data-test-id="total">{{ $lang.t('reports.total-calls', 'Total calls') }}</th>
                  </tr>
                </thead>
                <tbody data-test-id="table-body">
                  <tr v-for="(item, i) in report.items" :data-test-id="`types-item-${i}-row`" :key="`types-item-${i}-row`">
                      <td data-test-id="name">{{ item.name }}</td>
                      <td data-test-id="count">{{ item.count }}</td>
                  </tr>
                </tbody>
            </v-simple-table>
        </div>
    </ReportsWrapper>
</template>

<script>
  import { vueComponent } from 'helpers';
  import Type from '../../models/Type';
  import ReportsWrapper from '../elements/Report.vue'
  import TypesChart from '../elements/charts/Types.vue'

  export default {
    components: { ReportsWrapper, TypesChart },
    data() {
      return {
        report: new Type(this.$session, vueComponent(this))
      };
    },
    async created() {
      await this.$data.report.loadItems();
    },
  };
</script>
