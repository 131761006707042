import Vue from 'vue'
import lang from 'lang'
import Rollbar from 'rollbar'
import * as React from 'react'
import EventBus from 'event-bus'
import vuetify from 'vuetify-theme'
import ValidationRules from './rules'
// eslint-disable-next-line no-unused-vars
import components from 'vuetify-theme/libs/components' // loads all global components
import { formatDuration, formatPhoneNumber } from 'formatters'
import { PhoenixSessionContext } from 'providers'

// loads all global components
Vue.prototype.GOOGLE_TRACKING_CODE = process.env.REACT_APP_GOOGLE
Vue.prototype.$global_emitter = EventBus
Vue.prototype.$lang = lang

Vue.prototype.$validation = ValidationRules

// rollbar - https://docs.rollbar.com/docs/vue-js
const rollbar_payload = {
    environment: process.env.VUE_APP_STAGE,
    client: {
        javascript: {
            code_version: process.env.REACT_APP_ROLLBAR_TOKEN
        }
    }
}
Vue.prototype.$rollbar = new Rollbar({
    autoInstrument: { log: false },
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: rollbar_payload
})
// eslint-disable-next-line no-unused-vars
Vue.config.errorHandler = (err, vm, info) => {
    if (err) {
        if (err.message) {
            if (err.message.includes('Avoided redundant navigation to current location')) return null
        }
        if (err.stack) {
            if (err.stack.includes('node_modules/vuetify/lib/mixins/')) return null
        }
    }
    vm.$rollbar.configure({
        payload: {
            ...rollbar_payload,
            person: {
                id: vm.$session && vm.$session.user ? vm.$session.user.id : null,
                name: vm.$session && vm.$session.user && vm.$session.user.account ? vm.$session.user.account.name : null,
                email: vm.$session && vm.$session.user && vm.$session.user.account && vm.$session.user.account.contact ? vm.$session.user.account.contact.primary_email : null
            },
            fullstory_url: (window.FS && typeof window.FS.getCurrentSessionURL === 'function') ? window.FS.getCurrentSessionURL() : 'Not available'
        }
    })
    vm.$rollbar.error(err)
    throw err // rethrow
}

Vue.config.productionTip = false

Vue.filter('formatDuration', formatDuration)
Vue.filter('formatPhoneNumber', formatPhoneNumber)

/**
 * Used to wrap VueJSX components and render them in react JSX
 *
 * @param {object | null} root0  -  componentProps that should be passed to the vue app
 * @param {VueComponentJSX} vueComponent VueComponentJSX - the vue component to render
 */
const VueWrapper = function (
    { ...componentProps }, vueComponent
) {
    const vueRef = React.useRef(null)
    const [vueInstance, setVueInstance] = React.useState(undefined)

    let session
    if (
        componentProps.session &&
        typeof componentProps === 'object' &&
        componentProps.constructor.name === 'PhoenixApiClient'
    ) {
        session = componentProps.session
    } else {
        const sessionContext = React.useContext(PhoenixSessionContext)
        session = sessionContext.session
    }
    Vue.prototype.$session = session
    // eslint-disable-next-line consistent-return
    React.useEffect(() => {
        async function createVueInstance () { return null }

        createVueInstance()
        const referenceMount = vueRef.current
        // debugger
        if (referenceMount) {
            const mainApp = new Vue({
                data () {
                    return {
                        props: componentProps
                    }
                },
                vuetify,
                render (h) {
                    return h(vueComponent, {
                        props: this.props
                    })
                }
            })
            mainApp.$mount(referenceMount)
            setVueInstance(mainApp)

            return () => {
                vueInstance?.$destroy()
            }
        }
    }, [vueRef])

    React.useEffect(() => {
        if (vueInstance) {
            const keys = Object.keys(componentProps)
            // eslint-disable-next-line no-return-assign
            keys.forEach((key) => vueInstance.props[key] = componentProps[key])
        }
    }, [Object.values(componentProps)])
    return <div id="vue-component" ref={vueRef}></div>
}

export default VueWrapper
