import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A7645
 *
 * @param {SvgIconProps} props - svg props
 */
export const TextSmIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M23 12C23 18.0751 18.0751 23 12 23C10.0664 23 8.24934 22.5011 6.67049 21.625C6.28283 21.4099 4.3194 22.1127 2.81044 22.7095C2.3288 22.9 1.86541 22.3054 2.16486 21.8828C2.9646 20.7541 3.84612 19.3908 3.6319 19.14C1.99085 17.2185 1 14.725 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM7.875 13.375C8.63439 13.375 9.25 12.7594 9.25 12C9.25 11.2406 8.63439 10.625 7.875 10.625C7.11561 10.625 6.5 11.2406 6.5 12C6.5 12.7594 7.11561 13.375 7.875 13.375ZM16.125 13.375C16.8844 13.375 17.5 12.7594 17.5 12C17.5 11.2406 16.8844 10.625 16.125 10.625C15.3656 10.625 14.75 11.2406 14.75 12C14.75 12.7594 15.3656 13.375 16.125 13.375ZM13.375 12C13.375 12.7594 12.7594 13.375 12 13.375C11.2406 13.375 10.625 12.7594 10.625 12C10.625 11.2406 11.2406 10.625 12 10.625C12.7594 10.625 13.375 11.2406 13.375 12Z" fill={color}/>
        </SvgIcon>
    )
}

export default TextSmIcon
