<template>
  <div>
    <IndexPage
      :resource="route"
      :disable_delete_all="true"
      class="routes list-page"
      data-test-id="routes-list-page"
    >
      <template v-slot:filters>
        <FormInput :label="l.t('app.name', 'Name')">
          <w-text-field v-model="route.filters.name" hide-details='auto' data-test-id="routes-fitlers-name-input"/>
        </FormInput>
        <FormInput :label="l.t('app.extension-s', 'Extension(s)')">
          <APIAutocomplete
            v-model="route.filters.extension"
            mode="extensions"
            :before_list="[{text: l.t('app.all-extensions', 'All extensions'), value: null}]"
            data-test-id="routes-filters-extension-selector"
          />
        </FormInput>
        <FormInput>
          <w-switch
            :label="l.t('routes.include innamed routes', 'Include unnamed routes')"
            v-model="route.filters.include_unnamed"
            hide-details='auto'
            data-test-id="routes-fitlers-only-named-switch"
          />
        </FormInput>
      </template>
      <template v-slot:list>
        <v-simple-table class="list-table nowrap" data-test-id="routes-table">
          <thead data-test-id="table-head">
            <tr data-test-id="head-row">
              <th class="select-table-item" data-test-id="checkbox"><br/></th>
              <th data-test-id="preset">{{ l.t('app.preset', 'Preset') }}</th>
              <th data-test-id="description">{{ l.t('app.description', 'Description') }}</th>
              <th data-test-id="status">{{ l.t('app.status', 'Status') }}</th>
              <th><br/></th>
            </tr>
          </thead>
          <tbody data-test-id="table-body">
            <tr
               v-for="(item, i) in route.items"
              :key="item['id']"
              :class="{'selected-row': item.selected}"
              :data-test-id="`routes-item-${i}-row`"
            >
                <td class="select-table-item" data-test-id="checkbox">
                  <DisabledCheckbox
                    v-if="item.generator && item.generator === 'configure-app'"
                    :data-test-id="`routes-item-${i}-checkbox-tooltip-activator`"
                  >
                    <template v-slot:label>
                      {{ l.t('routes.cofigure-presets', 'Configure presets can\'t be deleted.') }}
                    </template>
                  </DisabledCheckbox>
                  <w-checkbox
                    v-else
                    v-model="item.selected"
                    :key="`routes-item-${item.id}-${item.selected}`"
                    @click="checked($event, item)"
                    hide-details="auto"
                    :data-test-id="`routes-item-${i}-checkbox`"
                  />
                </td>
                <td class="nowrap overflow-hidden" data-test-id="link">
                  <IndexListItem
                    :route="{
                      name: 'routes.show',
                      params: {
                        id: item['id'],
                        _route: item,
                      },
                    }"
                    :item="item"
                    :title="`#${item.id}`"
                    :key="item['id']"
                    :data-test-id="`routes-item-${i}-title`"
                  />
                </td>
                <td class="w-100" :data-test-id="`routes-item-${i}-rules-actions`">
                  {{ item | commentFilter }}<template v-if="item.extension">; {{ l.t('app.extension', 'Extension') }} #{{ item.extension.extension }}</template>
                </td>
                <td data-test-id="in-use">
                  {{ item.usage && item.usage.length ? l.t('route.in-use', 'In use') : l.t('routes.not-in-use', 'Not in use') }}
                  <InfoLink
                    v-if="item.usage && item.usage.length"
                    max-width="250"
                  >
                    <div
                      v-for="(usage, u) of item.usage"
                      :class="u !== item.usage.length - 1 ? 'mb-3' : ''"
                      :key="`usage${i}-${u}`"
                      data-test-id="`usage-${i}-${u}`"
                    >
                      {{ usage.description }}
                      <br>
                      {{ usage.object_type | upperFirstCase }}: {{ usage.object.name }}
                    </div>
                  </InfoLink>
                </td>
                <td data-test-id="more-options">
                  <MoreOptions :options="more_options(item)" />
                </td>
            </tr>
          </tbody>
        </v-simple-table>
      </template>
  </IndexPage>
  <ConfirmDeletion
      :value="!!deleting_item_id"
      @closed="deleting_item_id = null"
      @confirmed="route.delete_item(deleting_item_id)"
    />
  </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import { upperFirstCase } from 'formatters'
  import l from '../../../libs/lang';
  import Route from '../../../models/Route';
  import InfoLink from '../../elements/InfoLink.vue';
  import IndexPage from '../../elements/IndexPage.vue';
  import MoreOptions from '../../elements/MoreOptions.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import IndexListItem from '../../elements/IndexListItem.vue';
  import DisabledCheckbox from '../../elements/DisabledCheckbox.vue';
  import APIAutocomplete from '../../elements/form/APIAutocomplete.vue';
  import ConfirmDeletion from '../../elements/modal/ConfirmDeletion.vue';

  export default {
    components: {
      InfoLink,
      IndexPage,
      FormInput,
      MoreOptions,
      IndexListItem,
      ConfirmDeletion,
      APIAutocomplete,
      DisabledCheckbox,
    },
    data() {
      return {
        route: new Route(this.$session, vueComponent(this)),
        l,
        deleting_item_id: null,
      };
    },
    mounted() {
      this.$parent.$on('userRedirected', (to, from, next) => {
        this.$data.route.handleUserRedirect(to, from, next);
      });
    },
    methods: {
      more_options(item) {
        const options = [
          {
            cb: () => this.$router.push({
              name: 'routes.show',
              params: {
                id: item.id,
                _queue: item,
              },
            }),
            title: l.t('app.edit', 'Edit')
          }
        ];
        if (!(item.generator && item.generator === 'configure-app')) {
          options.push({
            cb: () => this.$data.deleting_item_id = item.id,
            title: l.t('app.delete', 'Delete'),
          });
        }
        return options;
      },
      checked(event, item) {
        this.$data.route.checked(event, item);
        this.$emit('forceUpdate');
      }
    },
    filters: {
      commentFilter(route) {
        if (route['name'] || Object.prototype.hasOwnProperty.call(route, 'name')) {
          return route.name;
        } if (route.rules && route.rules.length) {
          const actions = route.rules.reduce((a, c) => {
            a += c.actions.length;
            return a;
          }, 0);
          if (route.rules.length === 1) {
            if (actions === 1) {
              return l.t('routes.one-rule-one-action', '1 rule, 1 action');
            }
            return l.t('routes.one-rule-multiple-actions', '1 rule, {} actions', [actions]);
          }
          return l.t('routes.rules-actions', '{} rules, {} actions', [route.rules.length, actions]);
        }

        return l.t('routes.no-rules', 'No rules');
      },
      upperFirstCase(val) {
        if (!val) return '—';
        return upperFirstCase(val).split('_').join(' ').split('-');
      },
    },
  };
</script>
