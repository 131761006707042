/* eslint-disable react/prop-types */
import React from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVert from '@material-ui/icons/MoreVert'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => {
    return {
        menuPaper: theme.dropdownMenu.paper,
        menuList: theme.dropdownMenu.list,
        menuItem: theme.dropdownMenu.list.item
    }
})

/**
 *
 * @param {object} props
 * @returns
 */
const ConversationHeaderDropdownMenu = props => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)

    function handleClose () {
        setAnchorEl(null)
    }

    function showDropdown (e) {
        setAnchorEl(e.currentTarget)
    }

    function deleteConversation () {
        props.deleteConversation()
        handleClose()
    }

    return (
        <div>
            <MoreVert aria-controls='options-dropdown-menu' aria-haspopup='true' className='options-dropdown-btn' onClick={showDropdown} />
            <Menu
                id='options-dropdown-menu'
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{ paper: classes.menuPaper, list: classes.menuList }}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                keepMounted
            >
                <MenuItem classes={{ root: classes.menuItem }} dense onClick={deleteConversation}>Delete Conversation</MenuItem>
            </Menu>
        </div>
    )
}

export default ConversationHeaderDropdownMenu
