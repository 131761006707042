<template>
  <w-text-field
		v-if="type === 'number'"
		v-model="number"
		type="number"
    min="0"
    @blur="type = 'text'"
    @change="value_changed"
    @keydown="$emit('keydown')"
    :placeholder="l.t('app.seconds', 'seconds')"
    :value="number"
    hide-details='auto'
    data-test-id="seconds-input"
  />
  <w-text-field
		v-else
		not-clearable
		@click="type = 'number'"
		@focus="type = 'number'"
		v-model="text"
    :placeholder="l.t('app.seconds', 'seconds')"
    @keydown="$emit('keydown')"
    hide-details='auto'
    data-test-id="seconds-input"
  />
</template>

<script>
  import l from '../../../libs/lang';

  export default {
    props: {
		value: {
			type: [Number, String],
		},
    },
    data() {
      return {
		l,
		number: this.$props.value,
		type: 'text',
		text: null,
      };
    },
    created() {
		this.value_changed();
    },
    watch: {
		number(val) {
			this.value_changed();
			this.$emit('change', val);
		}
    },
    methods: {
		value_changed() {
			if (this.$data.number) {
				const sec = Number.parseInt(this.$data.number) === 1 ? l.t('app.second', 'second') : l.t('app.seconds', 'seconds');
				this.$data.text = `${this.$data.number} ${sec}`;
			} else {
				this.$data.text = null;
			}
		},
    },
  };
</script>

<style scoped>

</style>
