import l from 'lang'
// https://laravel.com/docs/9.x/validation#available-validation-rules

const generate_error_message = (default_message, message, field, field_message) => {
    if (message) return message
    if (field && field_message) return field_message
    return default_message
}

const generic_error_message = (field, message) => generate_error_message(
    l.t('app.validation-generic-error-message', 'This field is invalid'),
    message,
    field,
    l.t('app.validation-generic-error-message-named-rule', '{} field is invalid', [field])
)

export default {
    /**
     * Add two numbers.
     *
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    required: (field, message) => (v) => {
        // const whiteSpaceRegEx = /^(\s+\S+\s*)*(?!\s).*$/;
        const error_message = generate_error_message(
            l.t('app.field-is-required', 'This field is required'),
            message,
            field,
            l.t('app.named-field-is-required-rule', '{} field is required', [field])
        )
        if (!v) {
            return error_message
        }
        // if (typeof v === 'string' && !whiteSpaceRegEx.test(v)) {
        //     return error_message;
        // }

        return true
    },
    /**
     * Add two numbers.
     *
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    string: (field, message) => (v) => {
        if (v && typeof v !== 'string') {
            return generate_error_message(
                l.t('app.string-rule', 'This field must be a string'),
                message,
                field,
                l.t('app.named-field-string-rule', '{} field must be a string', [field])
            )
        }

        return true
    },
    /**
     * Add two numbers.
     *
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    boolean: (field, message) => (v) => {
        if (typeof v !== 'boolean') {
            return generate_error_message(
                l.t('app.boolean-rule', 'This field must be a boolean'),
                message,
                field,
                l.t('app.named-field-boolean-rule', '{} field must be a boolean', [field])
            )
        }

        return true
    },
    /**
     * Add two numbers.
     *
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    area_code: (field, message) => (v) => {
        if (!v) return true
        if (!isNaN(parseFloat(v)) && v >= 200 && v <= 999) return true
        return generate_error_message(
            l.t('app.boolean-rule', 'This field must be a 3 digit number greater than 199'),
            message,
            field,
            l.t('app.named-field-boolean-rule', '{} field must be a 3 digit number greater than 199', [field])
        )
    },
    /**
     * Add two numbers.
     *
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    number: (field, message) => (v) => {
        if (typeof v !== 'number') {
            return generate_error_message(
                l.t('app.number-rule', 'This field must be a number'),
                message,
                field,
                l.t('app.named-field-number-rule', '{} field must be a number', [field])
            )
        }

        return true
    },
    /**
     * Add two numbers.
     *
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    array: (field, message) => (v) => {
        if (!Array.isArray(v)) {
            return generate_error_message(
                l.t('app.array-rule', 'This value must be an array'),
                message,
                field,
                l.t('app.named-array-rule', '{} field value must be an array', [field])
            )
        }

        return true
    },
    /**
     * Add two numbers.
     *
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    object: (field, message) => (v) => {
        if (v && typeof v === 'object') {
            return generate_error_message(
                l.t('app.object-rule', 'This value must be an object'),
                message,
                field,
                l.t('app.named-object-rule', '{} field value must be an object', [field])
            )
        }

        return true
    },
    /**
     * Add two numbers.
     *
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    numeric: (field, message) => (v) => {
        if (Number.isNaN(v)) {
            return generate_error_message(
                l.t('app.numeric-rule', 'This field must have numeric value'),
                message,
                field,
                l.t('app.named-numeric-rule', '{} field musthave numeric value', [field])
            )
        }

        return true
    },
    /**
     * Add two numbers.
     *
     * @param {number} num The max value.
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    max: (num, field, message) => (v) => {
        if (v > num) {
            return generate_error_message(
                l.t('app.max-field-rule', 'This field must not be greater than {}', [num]),
                message,
                field,
                l.t('app.named-field-max-rule', 'The {} field must not be greater than {}', [field, num])
            )
        }

        return true
    },
    // eslint-disable-next-line jsdoc/require-description
    /**
     * @param {number} num The min value.
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    min: (num, field, message) => (v) => {
        if (v < num) {
            return generate_error_message(
                l.t('app.min-field-rule', 'This field must be at least {}', [num]),
                message,
                field,
                l.t('app.named-field-min-rule', 'The {} field must be at least {}', [field, num])
            )
        }

        return true
    },
    // eslint-disable-next-line jsdoc/require-description
    /**
     * @param {number} num The minimal length value.
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    minlength: (num, field, message) => (v) => {
        if (v && v.length < num) {
            if (Array.isArray(v)) {
                return generate_error_message(
                    l.t('app.minlength-array-rule', 'This field must contain at least {} element', [num]),
                    message,
                    field,
                    l.t('app.named-field-min-length-array-rule', '{} must contain at least {} element', [field, num])
                )
            }
            return generate_error_message(
                l.t('app.minlength-rule', 'This field must contain at least {} characters', [num]),
                message,
                field,
                l.t('app.named-field-min-length-rule', '{} must contain at least {} characters', [field, num])
            )
        }

        return true
    },
    // eslint-disable-next-line jsdoc/require-description
    /**
     * @param {number} num The maximal length value.
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    maxlength: (num, field, message) => (v) => {
        if (v && v.length >= num) {
            if (Array.isArray(v)) {
                return generate_error_message(
                    l.t('app.max-length-array-rule', 'This field must not contain more than {} element', [num]),
                    message,
                    field,
                    l.t('app.named-field-max-length-array-rule', '{} must not contain more than {} element', [field, num])
                )
            }
            return generate_error_message(
                l.t('app.max-length-rule', 'This field must not contain more than {} characters', [num]),
                message,
                field,
                l.t('app.named-field-max-length-rule', '{} must not contain more than {} characters', [field, num])
            )
        }

        return true
    },
    /**
     * Add two numbers.
     *
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    email: (field, message) => (v) => {
        const emailRegEx = /.+@.+\..+/
        if (v && (!emailRegEx.test(v) || v.includes(','))) {
            return generate_error_message(
                l.t('app.email-rule', 'Invalid email format.'),
                message,
                field,
                l.t('app.named-field-email-rule', '{} field must be in correct email format.', [field])
            )
        }

        return true
    },
    // eslint-disable-next-line jsdoc/require-description
    /**
     * @param {number} regex Regex.
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    regex: (regex, field, message) => (v) => {
        if (!regex.test(v)) {
            return generate_error_message(
                l.t('app.regex-rule', 'This field has invalid pattern.'),
                message,
                field,
                l.t('app.named-field-regex-rule', '{} field has invalid pattern.', [field])
            )
        }

        return true
    },
    // eslint-disable-next-line jsdoc/require-description
    /**
     * @param {Array} arr The main array.
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    in: (arr, field, message) => (v) => {
        if (!arr.includes(v)) {
            return generate_error_message(
                l.t('app.in-rule', 'This value must be in: {}', [arr.join(', ')]),
                message,
                field,
                l.t('app.named-in-rule', '{} field value must be in: {}', [field, arr.join(', ')])
            )
        }

        return true
    },
    // eslint-disable-next-line jsdoc/require-description
    /**
     * @param {Array} arr The main array.
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @param {boolean} case_sensitive To check for case senstive.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    not_in: (arr, field, message, case_sensitive) => (v) => {
        const value = case_sensitive ? v.toLowerCase() : v
        const test_arr = case_sensitive ? arr.map((x) => x.toLowerCase()) : arr
        if (test_arr.includes(value)) {
            return generate_error_message(
                l.t('app.not-in-rule', 'This value must not be in: {}', [arr.join(', ')]),
                message,
                field,
                l.t('app.named-not-in-rule', '{} field value must not be in: {}', [field, arr.join(', ')])
            )
        }

        return true
    },
    /**
     * Add two numbers.
     *
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cc_number: (field, message) => (v) => {
        if (!/[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4}/.test(v)) {
            return generic_error_message(field, message)
        }

        return true
    },
    /**
     * Add two numbers.
     *
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    cc_expiry: (field, message) => (v) => {
        if (v) {
            const error_message = generic_error_message(field, message)
            if (v.length !== 5) return error_message
            if (!v.includes('/')) return error_message
            const today = new Date()
            const user_date = new Date()
            const month = Number.parseInt(v.split('/')[0])
            const year = Number.parseInt(`${today.getFullYear().toString().substring(0, 2)}${v.split('/')[1]}`)
            user_date.setFullYear(year, month - 1, 1)
            if (user_date < today) return error_message
        }

        return true
    },
    /**
     * Add two numbers.
     *
     * @param {string} field The gotten field.
     * @param {string} message The error message.
     * @returns {string | boolean} returned value.
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    phone_number: (field, message) => (v) => {
        if (v) {
            const regex = new RegExp('^((\\+[2-9][\\d]{8,15})|(\\+1[\\d]{10}))?$')
            if (!regex.test(v.replace(/[- )(]/g, ''))) {
                return generate_error_message(
                    l.t('app.phone-number-rule', 'This field has invalid value'),
                    message,
                    field,
                    l.t('app.named-phone-number-rule', '{} field has invalid value')
                )
            }
        }

        return true
    }
}
