import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'
import {getPhoneCom} from 'phonecom'
class API {

	// INVOICES
	static getInvoices = () => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/unified/get-invoice-list`
			return ajax.post(requestUrl, {account_id: phonecom.voip_id}).then(response => response.data)
		})
	}
	static getInvoicePdf = (invoice_id) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/unified/get-invoice2`
			return ajax.post(requestUrl, {account_id: phonecom.voip_id, invoice_id: invoice_id}).then(response => response.data)
		})
	}

	

	// PASSWORD CHANGE
	static updatePassword = (old_pw, new_pw, new_pw_re) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/change-password`
			return ajax.post(requestUrl, {
					account_id:		phonecom.voip_id, 
					old_password:	old_pw, 
					password:		new_pw, 
					password_re:	new_pw_re
				}).then(
					response => {
						if (response.errors){
							return {
								status:'fail',
								message:response.errors[Object.keys(response.errors)[0]]
							}
						} 
						return response.data
					}
				)
		})
	}


	// PASSWORD VERIFY
	static verifyPassword = (password) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/verify-password`
			return ajax.post(requestUrl, {
					account_id:	phonecom.voip_id, 
					password:	password
				}).then(
					response => {
						if (response.errors){
							return {
								status:'fail',
								message:response.errors[Object.keys(response.errors)[0]]
							}
						} 
						return response.data
					}
				)
		})
	}
	// PHONE(code) VERIFY
	static verifyPhoneCode = (code) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/security/verify-confirm-code`
			return ajax.post(requestUrl, {
					account_id:		phonecom.voip_id,
					extension_id:	phonecom.voip_phone_id, 
					code:			code
				}).then(
					response => {
						if (response.errors){
							return {
								status:'fail',
								message:response.errors[Object.keys(response.errors)[0]]
							}
						} 
						return response.data
					}
				)
		})
	}

	

	// Phone number verification
	static sendConfirmCode = (phone) => {
		console.log('TUKa', phone)
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/security/send-confirm-code`
			return ajax.post(requestUrl, {
					account_id:	phonecom.voip_id, 
					extension_id:	phonecom.voip_phone_id,
					phone:	phone
				}).then(
					response => {
						if (response.errors){
							return {
								status:'fail',
								message:response.errors[Object.keys(response.errors)[0]]
							}
						} 
						return response.data
					}
				)
		})
	}

	// USER LOGINS
	static getLogins = () => {
		return getPhoneCom().then(phonecom => {
			const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/get-logins`
			return ajax.post(requestUrl, {}, 'CP').then(response => {
				if (response.data) {
					console.log('getLogins - response.data', response.data)
					return response.data
				} else {
					// TODO: Handle error?
					console.log('getLogins - response.error', response.error)
					return {"error": response.error}
				}
			})
		})
	}

	static updateLogins = (logins, extension_logins) => {
		return getPhoneCom().then(phonecom => {
			const requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/update-logins`
			const payload = {
				"logins": logins,
				"extension_logins": extension_logins
			}
			return ajax.post(requestUrl, payload, 'CP').then(response => {
				if (response.data) {
					console.log('updateLogins - response.data', response.data)
					return response.data;
				} else {
					// TODO: Handle error?
					console.log('updateLogins - response.error', response.error)
					return response.error;
				}
			})
		})
	}

	// CREDIT CARD
	static getCurrentPrimaryCard = () => {
		return getPhoneCom().then(phonecom => {
			const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/account/credit-card/get-card-on-file`
			return ajax.post(requestUrl, {}, 'CP').then(response => response.data)
		})
	}

		static updatePrimaryCard = (stripeCardToken) => {
		return getPhoneCom().then(phonecom => {
			const requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/account/credit-card/update-primary-card`
			return ajax.post(requestUrl, {"token": stripeCardToken}, 'CP').then(response => {
				if (response.data) {
					return response.data;
				}
				else if (response.payment_decline) {
					return {"error": response.payment_decline}
				}
				else {
					return {"error": "We were unable to verify this card"}
				}
			})
		})
	};

	// BLOCKLIST
	static getBlocklist = (cursor) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/get-blocklist`
			return ajax.post(requestUrl, {account_id: phonecom.voip_id, cursor: cursor, limit: 5}).then(response => response.data)
		})
	}

	static removeFromBlocklist = (item_id) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/remove-from-blocklist`
			return ajax.post(requestUrl, {account_id: phonecom.voip_id, blocklist_id: item_id}).then(response => response.data)
		})
	}

	
	static addToBlocklist = (pattern) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/accounts/add-to-blocklist`
			return ajax.post(requestUrl, {
				account_id: phonecom.voip_id, 
				pattern: pattern,
				block_type: 'incoming',
				note: 'Blocked from web.com account settings'
			}).then(
				response => {
					if (response.errors){
						return {
							status:'fail',
							message:response.errors[Object.keys(response.errors)[0]]
						}
					} 
					return response.data
				}
			)
		})
	}

	// ADD ONS
	static enableFeatures = code => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/admin/features/enable-features`
			return ajax.post(requestUrl, {features: [{code}]}).then(response => response.data)
		})
	}

	static disableFeatures = code => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/admin/features/disable-features`
			return ajax.post(requestUrl, {features: [{code}]}).then(response => response.data)
		})
	}

	static getMusicOnHoldLink = voip_recording_id => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/greetings/get-music-on-hold-link`
			return ajax.post(requestUrl, {voip_recording_id}).then(response => response.data)
		})
	}

	static createFileGreeting = (name, file) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-greeting`
			return ajax.post(requestUrl, {name, file}).then(response => response.data)
		})
	}

	static createTTSGreeting = (tts_text, tts_voice, name) => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot()}/media/create-greeting`
			return ajax.post(requestUrl, {origin: 'tts', tts_text, tts_voice, name}).then(response => response.data)
		})
	}

	static getVoicemailConfig = () => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/voicemail/get-ext-vm-config`
			return ajax.post(requestUrl, {}).then(response => response.data)
		})
	}

	static configureVoicemail = voip_recording_id => {
		return getPhoneCom().then(phonecom => {
			let requestUrl = `${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/voicemail/configure-voicemail`
			return ajax.post(requestUrl, {voip_recording_id}).then(response => response.data)
		})
	}
}

export default API