export function switchExtension(extension) {
	return {
		type: 'SWITCH_EXTENSION',
		extension
	}
}

export function setIsVoicemailConfigured(isVoicemailConfigured) {
	return {
		type: 'SET_IS_VOICEMAIL_CONFIGURED',
		isVoicemailConfigured
	}
}