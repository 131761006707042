/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect, useContext } from 'react'
import Typography from 'typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import LoaderFull from 'loader-full'
import Chip from 'chip-mui'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import EnrollmentStepContainer from './EnrollmentStepContainer'
import { SmsNumberCampaignContext } from '../contexts/SmsNumberCampaignContext'

const styles = makeStyles((theme) => ({
    outerContainer: { display: 'flex', justifyContent: 'center' },
    innerContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
        '& .MuiAutocomplete-root': {
            width: '100%!important'
        },
        '& .MuiSvgIcon-root': {
            margin: 'auto'
        },
        [theme.breakpoints.down('sm')]: {
            width: '75%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    centerChip: {
        alignSelf: 'center',
        marginBottom: '1.25rem'
    },
    root: {
        '& .MuiInputBase-root': {
            height: 'auto'
        }
    }
}))

/**
 * @param {object} props
 */
const NumberSelectStep = (props) => {
    const classes = styles()

    const campaignSavedData = props.passData.campaign_step

    const { eligibleNumbers, fetchEligibleNumbersStatus, fetchEligibleNumbers } = useContext(SmsNumberCampaignContext)
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState([])

    const invalid = selectedPhoneNumber.length === 0
    const maxSelectedPhoneNumber = 49 // limited by back-end

    const getSaveData = () => {
        return {
            selectedPhoneNumber
        }
    }

    const phoneOnChangeHandler = (event, phoneNumber) => {
        setSelectedPhoneNumber(phoneNumber)
    }

    useEffect(() => {
        if (props.saveId) props.update({ completed: true, gotoNext: true, saveData: getSaveData() })
    }, [props.saveId])

    useEffect(() => {
        props.update({ disableNextButton: () => invalid })
    }, [invalid])

    useEffect(() => {
        fetchEligibleNumbers()
    }, [])

    return (
        <EnrollmentStepContainer>
            <div className={classes.outerContainer}>
                <div className={classes.innerContainer} style={{ height: fetchEligibleNumbersStatus !== 'success' ? '50px' : undefined }}>
                    {
                        fetchEligibleNumbersStatus !== 'success'
                            ? <LoaderFull styles={{ loaderFull: { left: '50%' } }} size='big'/>
                            : <>
                                <Chip
                                    className={classes.centerChip}
                                    color='primary'
                                    label={campaignSavedData.selectedCampaign.name}
                                    onDelete={null}
                                />
                                <Typography variant='h5' remoteConfigID='sms_enrollment_number_select_step_instruction'></Typography>
                                <Autocomplete
                                    id='sms-numbers-autocomplete'
                                    multiple
                                    options={eligibleNumbers}
                                    className={classes.root}
                                    filterSelectedOptions={true}
                                    getOptionLabel={option => eligibleNumbers?.length > 0 ? option.did : '' }
                                    getOptionDisabled={() => {
                                        return selectedPhoneNumber.length === maxSelectedPhoneNumber
                                    }}
                                    label='Select an outbound caller ID'
                                    style={{ width: 525 }}
                                    onChange={phoneOnChangeHandler}
                                    renderInput={params => {
                                        return (
                                            <TextField {...params} variant="filled"
                                                label={'Outbound Caller ID:'} />
                                        )
                                    }}
                                    value={selectedPhoneNumber}
                                />
                            </>
                    }
                </div>
            </div>
        </EnrollmentStepContainer>
    )
}

NumberSelectStep.propTypes = {
    passData: PropTypes.object,
    update: PropTypes.func,
    saveId: PropTypes.number
}

export default NumberSelectStep
