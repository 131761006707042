<template>
	<div data-test-id="csv-imported">
		<div class="status-alerts mb-6">
			<w-alert
				v-if="items.find((x) => x.status === 'success')"
				level="success"
				:message="l.t(
					'app.csv-successes',
					'Successes: {}/{}',
					[items.filter((x) => x.status === 'success').length, items.length]
				)"
				:closable="false"
				data-test-id="csv-imported-success-alert"
				/>
			<w-alert
				v-if="items.find((x) => x.status === 'failed')"
				level="error"
				:message="l.t(
					'app.csv-fails',
					'Failed: {}/{}',
					[items.filter((x) => x.status !== 'success').length, items.length]
				)"
				:closable="false"
				data-test-id="csv-imported-error-alert"
			/>
		</div>
		<LongTable :items="items" data-test-id="csv-imported-table">
			<template v-slot:header>
				<th>{{ l.t('app.name', 'Name') }}</th>
				<th>{{ l.t('app.message', 'Message') }}</th>
				<th class="text-center">{{ l.t('app.success', 'Success') }}</th>
			</template>
			<template v-slot:body>
				<tr v-for="item in items">
					<td>{{ item.name }}</td>
					<td>{{ item.message }}</td>
					<td class="text-center" :class="{'text-success': item.status === 'success', 'text-error': item.status !== 'success'}">
						{{ item.status === 'success' ? '✓' : '&#10006;' }}
					</td>
				</tr>
			</template>
		</LongTable>
		<div class="bottom-buttons" data-test-id="csv-imported-btns">
			<slot name='commands'></slot>
		</div>
	</div>
</template>

<script>
import l from '../../libs/lang';
import LongTable from './LongTable.vue';

export default {
	components: { LongTable, },
	props: {
		items: {
			required: true,
			type: Array,
		}
	},
	data() {
		return {
			l,
		};
	},
};
</script>
