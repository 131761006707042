import { combineReducers } from 'redux'
import { currentFax, faxes, faxLinks } from './faxes'
import { smallView, currentView } from './view'
import { currentExtension, isFaxConfigured } from './pdcuser'

export default combineReducers({
	currentFax,
	faxes,
	smallView,
	currentView,
	currentExtension,
	isFaxConfigured,
	faxLinks
})