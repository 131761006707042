export default class Invoice {
  constructor(data, transactions) {
    this.data = data;
    this._activity = null;
    this._taxes = null;
    this._payments = null;
    this._transfers = null;
    this._adjustments = null;
    this.transactions = transactions.map((v) => v);
  }

  get id() {
    return this.data['id'];
  }

  // eslint-disable-next-line class-methods-use-this
  t_description(t) {
    let description = t['description'] ? t['description'] : t[':code']['description'];
    if (description === 'Previous balance') {
      description = 'Previous balance from invoice';
    }
    return description;
  }

  get activity() {
    if (this._activity === null) {
      this._activity = this.transactions.filter((v) => !['payment', 'transfer', 'tax', 'adjustment'].includes(v.type));
    }
    return this._activity;
  }

  get adjustments() {
    if (this._adjustments === null) {
      this._adjustments = this.transactions.filter((v) => v.type === 'adjustment');
    }
    return this._adjustments;
  }

  get taxes() {
    if (this._taxes === null) {
      const taxes = {};
      for (const t of this.transactions.filter((v) => ['tax'].includes(v.type))) {
        const description = this.t_description(t);
        if (!taxes[description]) {
          taxes[description] = {
            'code': t.code,
            'start_date': t.start_date,
            'end_date': t.start_date,
            'description': description,
            'number': 1,
            'amount': t.amount
          };
        } else {
          taxes[description] = {
            'code': t.code,
            'start_date': Math.min(taxes[description].start_date, t.start_date),
            'end_date': Math.max(taxes[description].start_date, t.start_date),
            'description': description,
            'number': taxes[description].number + 1,
            'amount': taxes[description].amount + t.amount
          };
        }
      }
      this._taxes = Object.values(taxes);
    }
    return this._taxes;
  }

  get payments() {
    if (this._payments === null) {
      this._payments = this.transactions.filter((v) => v.type === 'payment');
    }
    return this._payments;
  }

  get transfers() {
    if (this._transfers === null) {
      this._transfers = this.transactions.filter((v) => ['transfer'].includes(v.type));
    }
    return this._transfers;
  }
}
