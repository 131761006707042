import React, { Component } from 'react'
import { connect } from 'react-redux'
import api from '../util/api_v5.js'
import PhoneComUser from 'phone-com-user'
import CallContent from './CallContent'
import CallsSelector from './CallsSelector'
import StartNewButton from 'start-new-button'
import ResizeAware from 'react-resize-aware'
import { setSmallView } from '../actions/view'
import { switchExtension } from '../actions/pdcuser'
import { updateCalls, updateCall, addRecordings, addContactsToCalls, removeContactFromCalls } from '../actions/calls'
import LoaderFull from 'loader-full'
import { withStyles } from '@material-ui/core'
import PdcOpenConnection from 'pdc-open-connection'
import { PdcCallConsumer } from 'pdc-calls'
import gtmDataPush from 'gtm-events'
import { cacheUpdateListener } from 'service-worker-utils'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getFeatureEnabled } from 'feature-flag'
import { ShowDialerButtonIcon } from 'svg-icons'
import { theme } from 'get-theme'
import { DefaultTooltip } from 'tooltips'
import Typography from 'typography'
import { ScreenSizeContext } from 'providers'

// const GTM_APP_NAME = 'calls'
// const GTM_MAP = { ALREADY_ACTIVE: 0, NOT_ACTIVE: 1 }

const threshold = 768

const mapStateToProps = (state) => ({
    calls: state.calls,
    smallView: state.smallView,
    currentExtensionRedux: state.currentExtension
})
const mapDispatchToProps = (dispatch) => ({
    setSmallView: (boolVal) => dispatch(setSmallView(boolVal)),
    updateCalls: (call) => dispatch(updateCalls(call)),
    updateCall: (call) => dispatch(updateCall(call)),
    addRecordings: (recordings) => dispatch(addRecordings(recordings)),
    switchExtension: (extension) => dispatch(switchExtension(extension)),
    addContactsToCalls: (contacts) => dispatch(addContactsToCalls(contacts)),
    removeContactFromCalls: (contactId) => dispatch(removeContactFromCalls(contactId))

})

const styles = (theme) => ({
    appWrapper: {
        display: 'flex',
        height: '100%',
        position: 'relative'
    },
    callsPanel: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: theme.selector.width,
        boxShadow: '0 0 0 1px #e0e0e0',
        position: 'relative',
        '&.small-view': {
            width: '100%'
        },
        '&:not(.small-view)': {
            maxWidth: theme.selector.width
        },
        '& > div > button': {
            width: '100%'
        }
    },
    callsSelectorTitle: {
        fontSize: 20,
        fontWeight: 600,
        lineHeight: 1.25,
        letterSpacing: -0.2,
        color: 'black',
        padding: '17px 20px',
        boxShadow: theme.palette.primary.flatBottomShadow
    },
    startNewButton: {
        '& > div > svg': {
            fontSize: 34
        },
        '&:disabled': {
            color: theme.palette.text.disabled
        }
    },
    tooltipPopper: {
        width: 211
    },
    tooltip: {
        padding: '8px 12px',
        textAlign: 'center'
    }
})

class App extends Component {
    constructor (props) {
        super(props)
        this.state = {
            loading: false,
            loadedOnce: false,
            callingEnabled: false
        }
        if (props.extension && props.extension.extension_id !== PhoneComUser.getExtensionId()) {
            PhoneComUser.changeExtension(props.extension.extension_id)
        }
    }

    static contextType = ScreenSizeContext

    componentDidMount = async () => {
        this._ismounted = true
        await this.initialLoad()
        console.log(this.props)

        PdcOpenConnection.on('call_sent', this.createCallWithOutgoingCall)
        PdcOpenConnection.on('call_received', this.createCallLogWithIncomingCall)

        // if calls loaded into dialer but theres no active call - edge case
        if (!this.props.currentCall && this.getCurrentView() === 'dialer') this.switchCallLog(null)

        cacheUpdateListener('/calls/list-calls', data => {
            console.log('calls updated from cache', data)
            // if(data) {
            //  this.getCalls(data)
            // }
        })

        getFeatureEnabled('REACT_APP_IS_CALLING_DISABLED').then(disabled => this.setState({ callingEnabled: !disabled }))
    }

    getCurrentView = () => {
        const currentCall = this.getCurrentCall()
        if (currentCall) return 'content'
        const pathname = this.props.routeProps.location.pathname
        const split = pathname.split('calls').map(s => s.replace(/(^\/|\/$)/g, '')).filter(e => e)
        if (split.length === 1) return 'select'
        const path = split[1].split('/')[0]
        if (path === 'make-call') return 'make-call'
        if (path === 'dialer') return 'dialer'
        return 'select'
    }

    componentWillUnmount () {
        this._ismounted = false
        PdcOpenConnection.removeCallback('call_sent', this.createCallWithOutgoingCall)
        PdcOpenConnection.removeCallback('call_received', this.createCallLogWithIncomingCall)
    }

    componentDidUpdate = async (prevProps) => {
        setTimeout(() => {
            if (
                this.props.calls.items &&
                prevProps.calls.items &&
                this.props.calls.items.length !== prevProps.calls.items.length
            ) {
                const element = document.getElementsByClassName('infinite-scroller')[0]
                if (element) {
                    // element.scrollTop = 0
                }
            }
        }, 1)
        // if(this.props.calls.items && prevProps.calls.items && this.props.calls.items.length !== prevProps.calls.items.length) {
        //  let element = document.getElementsByClassName('infinite-scroller')[0]
        //  element.scrollTop = 0;
        // }

        if (this.props.extension && this.props.extension.extension_id !== PhoneComUser.getExtensionId()) {
            PhoneComUser.changeExtension(this.props.extension.extension_id)
            // this.props.resetSubscription(true)
            this.initialLoad(true)
        }

        if (prevProps.isOffline && !this.props.isOffline) this.adjustView()

        this.updateContactsInfoInCalls()
    }

    updateContactsInfoInCalls = () => {
        if (!this.props.contactsUtil.extraContactsLoaded) return
        let numNewContacts = 0
        const callItems = this.props.calls.items
        if (!callItems) return
        const contactIdsToBeRemoved = []
        callItems.forEach((call) => {
            const theOther = call.type === 'incoming' ? 'from' : 'to'
            const extraContact = this.props.contactsUtil.extraContacts.find((contact) =>
                contact.numbers.find((n) => n.number === call[theOther].number)
            )
            if (extraContact && !call[theOther].contact_id) {
                numNewContacts++
            } else if (!extraContact && call[theOther].contact_id) {
                contactIdsToBeRemoved.push(call[theOther].contact_id)
            }
        })
        if (numNewContacts > 0) {
            this.props.addContactsToCalls(this.props.contactsUtil.extraContacts)
        }
        if (contactIdsToBeRemoved.length) {
            contactIdsToBeRemoved.forEach((contactId) => this.props.removeContactFromCalls(contactId))
        }
    }

    updateCallLogWithEvent = async (e) => {
        // TODO: once call.log passes extension info, we can fetch call log when event comes in, instead of onClick. 08/03

        // upon getting call log event, fetch the call log. bringing in call log into event would involve doing that for every single call
        const voipId = PhoneComUser.getAPIAccountId()
        const call = await api.getCall(this.props.currentCall.id, voipId)
        if (call) {
            let calls = this.props.calls.items

            // filter out the old version of this call, add new one
            calls = calls.filter((c) => c.id !== call.id)
            calls.unshift(call)
            calls.sort((a, b) => b.start_time - a.start_time)
            this.props.updateCall(call)
            // TODO: maybe force fetch the voicemail and recording
        }
    }

    createCallLogWithIncomingCall = (extensionId, e) => {
        console.log(this.time)
        if (this.props.calls.items.find((c) => c.id === e.call_id)) return
        if (e.call.status) {
            console.log('incoming call should have no status, ignoring event', e)
            return
        }

        console.log('call received')
        const callLog = {
            id: e.call.uuid,
            extension: e.voip_api_token.voip_phone_id,
            type: 'incoming',
            start_time: Date.now() / 1000,
            from: { number: e.call.from },
            to: { number: e.call.called_number },
            duration: 0,
            recording: {},
            voicemail: {},
            isTempCall: true
        }
        console.log('call answered event detected with id', e.call.uuid)
        this.props.updateCall(callLog)
    }

    getCurrentCall = () => {
        const pathname = this.props.routeProps.location.pathname
        const split = pathname.split('calls').map(s => s.replace(/(^\/|\/$)/g, '')).filter(e => e)
        if (split.length === 1) return null
        const callId = split[1].split('/')[0].substring(1)
        const currentCall = this.props.calls.items.find(c => c.id === callId)
        return currentCall
    }

    createCallWithOutgoingCall = (extension, event) => {
        const { payload } = event
        if (!payload) {
            console.error('no event payload')
            return
        }

        if (payload.direction !== 'out') {
            console.log('there should not be incoming call events coming from call.new')
            return
        }

        if (this.props.calls.items.find((c) => c.id === payload.call_id)) return

        // get the current callerId from call in question
        const { currentCall } = this.props
        if (!currentCall) return

        const callerId = currentCall.myCallInfo.phoneNumber

        console.log('call.new outgoing call event', payload)
        const callLog = {
            id: payload.call_id,
            extension: payload.from_extn_id,
            type: 'outgoing',
            start_time: Date.now() / 1000,
            from: { number: callerId || '' },
            to: { number: payload.to_did || payload.to_extn },
            duration: 0,
            recording: {},
            voicemail: {},
            isTempCall: true
        }
        console.log('call_sent event detected with id', payload.call_id)
        this.props.updateCall(callLog)
    }

    adjustView = () => {
        this.initialLoad(true)
    }

    initialLoad = async (force) => {
        const extensionSwitched = Boolean(
            !this.props.currentExtensionRedux ||
                this.props.extension.extension_id !== this.props.currentExtensionRedux.extension_id
        )
        if (!extensionSwitched && this.props.calls.items && !force) return this.setState({ loadedOnce: true })
        if (extensionSwitched) this.props.switchExtension(this.props.extension)
        this.setState({ loading: true })
        this.props.contactsUtil.reload()
        await this.getCalls()
        await this.loadExtraContacts()
        this.setState({ loading: false, loadedOnce: true })
    }

    loadExtraContacts = async () => {
        const calls = this.props.calls
        const callsItems = calls.items
        if (!callsItems) return
        let phoneNumbers = []
        // Collect all of the phone numbers which are not connected to a contact
        callsItems.forEach((c) => {
            const direction = c.type === 'outgoing' ? 'to' : 'from'
            if (c[direction].contact_id) return
            phoneNumbers.push(c[direction].number)
        })
        if (phoneNumbers.length === 0) return
        // Remove duplicates
        phoneNumbers = Array.from(new Set(phoneNumbers))
        const filters = { keyword: phoneNumbers }
        const extraContacts = await this.props.contactsUtil.loadExtraContacts(filters)
        this.props.addContactsToCalls(extraContacts)
    }

    getCalls = async (response) => {
        if (!this._ismounted) return // console.log('Calls App.js got unmounted')
        // limit so based on height so it always gets filled
        const extensionId = PhoneComUser.getExtensionId()
        const limit = Math.max(parseInt(window.innerHeight / 50), 15)
        const responseCalls = response || await api.loadCalls({}, limit)
        if (responseCalls === 'network-error' || !responseCalls || !responseCalls.items) return
        responseCalls.items.forEach((c) => {
            c.recording.loading = true
            c.voicemail.loading = true
        })
        this.getRecordings(responseCalls.items)
        if (extensionId !== PhoneComUser.getExtensionId()) {
            // This may happen if you change the extension while this extension calls are being loaded
            return // console.log('The extension got changed so stop.')
        }
        this.props.updateCalls(responseCalls)
        this.props.onLoaded()
    }

    getRecordings = async (callItems) => {
        const recordingIds = callItems.map((c) => (c.recording ? c.recording.id : null)).filter((c) => c)
        const voicemailIds = callItems.map((c) => (c.voicemail ? c.voicemail.id : null)).filter((c) => c)
        const recordings = await api.getCallRecordings(recordingIds, voicemailIds)
        if (recordings) await this.props.addRecordings(recordings)
    }

    retryRecordings = async (callItem) => {
        console.log('retry recordings', callItem)
        await this.getRecordings([callItem])
    }

    handleResize = (size) => {
        const needToExpand =
            this.props.smallView &&
            ((this.props.standalone && size.width >= threshold) || !this.props.screenViewType.isMobileView)
        const needToShrink =
            !this.props.smallView &&
            ((this.props.standalone && size.width < threshold) || this.props.screenViewType.isMobileView)
        if (needToShrink) {
            this.props.setSmallView(true)
        } else if (needToExpand) {
            this.props.setSmallView(false)
        }
    }

    onMakeCallClick = async () => {
        this.props.softphoneContext.ToggleSoftphone()
    }

    placeCall = async (theirNumber) => {
        console.log('clicked call: ', theirNumber)
        if (!theirNumber) return
        this.props.makeCall(theirNumber)
        this.props.openDialer()
        gtmDataPush({ PDC_Action: 'call-back-button', PDC_Label: 'call-button-click' })
    }

    updateRouterHistory = subPath => {
        // let extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
        // if (this.props.origin === 'configure-app') {
        //   extensionId = this.props.extension.extension_id
        // }
        this.props.routeProps.history.push(`${this.getBasePath()}/${subPath}`)
    }

    switchCallLog = callLog => {
        const pathname = callLog ? `c${callLog.id}` : ''
        this.updateRouterHistory(pathname)
    }

    renderLoader = () => {
        if (!this.state.loading) return null
        const { classes } = this.props
        const sidebarHidden = this.context.mobile || this.context.tablet
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull
                    styles={{ loaderFull: { left: sidebarHidden ? '50%' : 'calc(50% + 120px)' } }}
                    size='big'
                />
            </div>
        )
    }

    renderSelectorHeader = () => {
        const { classes, smallView, extension } = this.props
        const callingFeatureEnabled = this.state.callingEnabled
        if (!callingFeatureEnabled && smallView) return null
        if (!callingFeatureEnabled) return <div className={classes.callsSelectorTitle}>Calls</div>
        const isVirtualExtension = !!extension?.is_virtual
        return (
            <PdcCallConsumer>
                {context => {
                    const isDisabled = !context.canPlaceOutgoingCall || isVirtualExtension
                    const tooltipTitle = isVirtualExtension ? 'Outbound calling is not available for this extension type' : ''
                    const ButtonWrapper = isVirtualExtension ? 'div' : React.Fragment
                    return (
                        <DefaultTooltip
                            classes = {{ popper: classes.tooltipPopper, tooltip: classes.tooltip }}
                            title = {tooltipTitle ? <Typography variant='tooltip' remoteConfigID='disabled_call_buttons_tooltip_text_virtual_extension'/> : ''}
                            placement = 'bottom'
                        >
                            <ButtonWrapper>
                                <StartNewButton
                                    app='calls'
                                    title='Make a call'
                                    onClick={this.onMakeCallClick}
                                    disabled={isDisabled}
                                    className={classes.startNewButton}
                                    icon = {<ShowDialerButtonIcon
                                        color = {isDisabled ? theme.palette.action.disabledBg : theme.palette.primary.main}
                                        secondaryColor = {isDisabled ? theme.palette.action.disabledText : 'white'}
                                    />
                                    }
                                />
                            </ButtonWrapper>
                        </DefaultTooltip>
                    )
                }}
            </PdcCallConsumer>
        )
    }

    renderCallsSelector = () => {
        const { classes } = this.props
        let hiddenClass = ''
        if (this.props.smallView && this.getCurrentView() !== 'select') hiddenClass = 'hidden'
        return (
            <div className={`${classes.callsPanel} ${hiddenClass} ${this.props.smallView ? 'small-view' : ''} ${this.props.screenViewType.isTabletView ? 'tablet-view' : ''}`}>
                {this.renderSelectorHeader()}
                <PdcCallConsumer>
                    {context => (
                        <CallsSelector
                            currentCall={this.getCurrentCall()}
                            newCall={this.state.newCall}
                            setNoNewCall={this.setNoNewCall}
                            smallView={this.props.smallView}
                            screenViewType={this.props.screenViewType}
                            deleteCall={this.deleteCall}
                            changeReadStatus={this.changeReadStatus}
                            getRecordings={this.getRecordings}
                            loadExtraContacts={this.loadExtraContacts}
                            extraContacts={this.props.contactsUtil.extraContacts}
                            makeCall={this.props.makeCall}
                            canPlaceOutgoingCall={context.canPlaceOutgoingCall}
                            openDialer={this.props.openDialer}
                            extension={this.props.extension}
                            callSetupInProgress={context.callSetupInProgress}
                            myCallerInfo={this.props.myCallerInfo}
                            setMyCallerInfo={this.props.setMyCallerInfo}
                            switchCallLog={this.switchCallLog}
                            onCallRecent={this.placeCall}
                        />
                    )}
                </PdcCallConsumer>
            </div>
        )
    }

    getBasePath = () => {
        const basePath = this.props.routeProps.match.path.replace(/\/+$/g, '')
        return basePath
    }

    renderCallContent = () => {
        if (this.props.smallView && !this.getCurrentCall()) return null
        return (
            <PdcCallConsumer>
                {(context) => (
                    <CallContent
                        extension={this.props.extension}
                        deleteCall={this.deleteCall}
                        changeReadStatus={this.changeReadStatus}
                        redirect={this.props.redirect}
                        extraContacts={this.props.contactsUtil.extraContacts}
                        contactGroupTypes={this.props.contactsUtil.groupTypes}
                        updateContact={this.props.contactsUtil.updateContact}
                        deleteContact={this.props.contactsUtil.deleteContact}
                        makeCall={this.props.makeCall}
                        currentCallSession={context.currentCall}
                        currentCall={this.getCurrentCall()}
                        retryRecordings={this.retryRecordings}
                        switchCallLog={this.switchCallLog}
                        onCallRecent={this.placeCall}
                    />
                )}
            </PdcCallConsumer>
        )
    }

    render = () => {
        const { classes } = this.props
        const basePath = this.getBasePath()
        return (
            <div className='App' data-test-id='calls-app'>
                <ResizeAware
                    style={{ height: this.props.standalone ? 'calc(100% - 60px)' : '100%' }}
                    onResize={this.handleResize}
                >
                    {this.renderLoader()}
                    {this.state.loadedOnce
                        ? (
                            <div className={classes.appWrapper}>
                                {this.renderCallsSelector()}
                                <Switch>
                                    <Route path={`${basePath}/`} render={this.renderCallContent}/>
                                </Switch>
                            </div>
                        )
                        : null
                    }
                </ResizeAware>
            </div>
        )
    }
}

App.propTypes = {
    makeCall: PropTypes.func.isRequired,
    standalone: PropTypes.bool,
    classes: PropTypes.object,
    extension: PropTypes.object,
    redirect: PropTypes.func.isRequired,
    contactsUtil: PropTypes.object,
    currentCall: PropTypes.object,
    routeProps: PropTypes.object,
    calls: PropTypes.array,
    isOffline: PropTypes.bool,
    addContactsToCalls: PropTypes.func,
    removeContactFromCalls: PropTypes.func,
    updateCall: PropTypes.func,
    smallView: PropTypes.bool

}

App.propTypes = {
    setIsDialerOpen: PropTypes.func,
    screenViewType: PropTypes.object,
    setMyCallerInfo: PropTypes.func,
    myCallerInfo: PropTypes.object,
    openDialer: PropTypes.func,
    setSmallView: PropTypes.func,
    addRecordings: PropTypes.func,
    onLoaded: PropTypes.func,
    switchExtension: PropTypes.func,
    updateCalls: PropTypes.func,
    currentExtensionRedux: PropTypes.object,
    softphoneContext: PropTypes.object
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(App))
