import React from 'react'
import Chip from 'chip-mui'
import { getValue } from 'remote-config-value'
import { DefaultArrowTooltip } from 'tooltips'
import { makeStyles } from '@material-ui/core/styles'
import { EnrollmentType } from '../interface/EnrollmentType'

const useStyles = makeStyles(() => ({
    tooltip: {
        width: 'fit-content'
    }
}))

interface StatusChipProps {
    status: string,
    enrollmentType: EnrollmentType,
    deleted: boolean
}

/**
 *
 */
export const greenStatuses = ['approved', 'accepted', 'verified']
/**
 *
 */
export const redStatuses = ['rejected', 'registration-failed', 'payment-failed', 'unverified', 'deleted', 'register-failed']

/**
 *
 */
const StatusChip = ({ status, enrollmentType, deleted } : StatusChipProps) => {
    const classes = useStyles()
    const campaignStatusMapper = (status) => {
        let rv = 'unknown'
        // Remote Config IDs sms_enrollment_campaign_status_*
        switch (status?.toLowerCase()) {
                case 'approved':
                    rv = 'accepted'
                    break
                case 'rejected':
                    rv = 'rejected'
                    break
                case 'register-failed':
                    rv = 'creation_failure'
                    break
                case 'payment-failed':
                    rv = 'payment_failed'
                    break
                case 'processing':
                    rv = 'processing'
                    break
                case 'pending-review':
                    rv = 'pending'
                    break
                case 'deleted':
                    rv = 'deleted'
                    break
                case 'accepted': // legacy statuses
                    rv = 'accepted'
                    break
                case 'pending':
                    rv = 'pending'
                    break
                default:
                    break
        }

        return rv
    }

    const brandStatusMapper = (status) => {
        let rv = 'unknown'
        // remote config IDs sms_enrollment_brand_status_*
        switch (status?.toLowerCase()) {
                case 'approved':
                    rv = 'verified'
                    break
                case 'pending-review':
                    rv = 'submitted'
                    break
                case 'register-failed':
                    rv = 'unverified'
                    break
                case 'rejected':
                    rv = 'unverified'
                    break
                case 'payment-failed':
                    rv = 'payment_failed'
                    break
                case 'processing':
                    rv = 'processing'
                    break
                case 'deleted':
                    rv = 'deleted'
                    break
                case 'in-review': // legacy statuses below
                    rv = 'submitted'
                    break
                case 'submitted':
                    rv = 'submitted'
                    break
                case 'unverified':
                    rv = 'unverified'
                    break
                case 'verified':
                    rv = 'verified'
                    break
                default:
                    break
        }

        return rv
    }

    status = deleted ? 'deleted' : status.toLowerCase() || 'pending-review'
    const color = greenStatuses.includes(status) ? 'primary' : 'secondary'
    const tooltipTitle = enrollmentType === EnrollmentType.Brand && status === 'rejected' ? getValue('sms_enrollment_brand_status_unverified_tooltip') : (status === 'deleted' ? getValue('sms_enrollment_enrollment_status_deleted_tooltip') : '')
    const label = enrollmentType === EnrollmentType.Brand ? getValue(`sms_enrollment_brand_status_${brandStatusMapper(status)}`) : getValue(`sms_enrollment_campaign_status_${campaignStatusMapper(status)}`)

    return (
        <DefaultArrowTooltip title={tooltipTitle}>
            <span className={classes.tooltip} >
                <Chip
                    error={redStatuses.includes(status)}
                    color={color}
                    label={label}
                    onDelete={null}
                />
            </span>
        </DefaultArrowTooltip>
    )
}

export default StatusChip
