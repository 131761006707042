<template>
    <div class="visualizer-info">
      <v-icon @click="zoomClicked(-1)" size=25 class="icons plus-icon">mdi-plus-circle-outline</v-icon>
      <v-icon @click="zoomClicked(1)" size=25 class="icons minus-icon">mdi-minus-circle-outline</v-icon>
      <v-icon @click="arrowClicked('x', -50)" size=25 class="icons left-icon">mdi-arrow-left-bold-outline</v-icon>
      <v-icon @click="arrowClicked('y', -50)" size=25 class="icons up-icon">mdi-arrow-up-bold-outline</v-icon>
      <v-icon @click="arrowClicked('x', 50)" size=25 class="icons right-icon">mdi-arrow-right-bold-outline</v-icon>
      <v-icon @click="arrowClicked('y', 50)" size=25 class="icons down-icon">mdi-arrow-down-bold-outline</v-icon>
      <v-icon @click="centerClicked()" size=25 class="icons center-icon">mdi-record-circle-outline</v-icon>
    </div>
</template>
<script>
export default {
    name: 'VisualizerController',
    methods: {
        zoomClicked(val) {
            this.$emit('zoomScene', val);
        },
        centerClicked() {
            this.$emit('centerScene');
        },
        arrowClicked(axis, amount) {
            this.$emit('moveScene', {axis, amount});
        },
    }
};
</script>
<style>
.icons {
  position: fixed !important;
  color: #197F9F !important;
  cursor: pointer;
  z-index: 2;
  outline: none !important;
  box-shadow: none !important;
}
.plus-icon {
  left: 325px;
  bottom: 64px;
}
.minus-icon {
  left: 325px;
  bottom: 35px;
}
.left-icon {
  left: 256px;
  bottom: 50px;
}
.up-icon {
  left: 277px;
  bottom: 71px;
}
.right-icon {
  left: 298px;
  bottom: 50px;
}
.down-icon {
  left: 277px;
  bottom: 29px;
}
.center-icon {
  left: 277px;
  bottom: 50px;
}
.visualizer-info {
  width: 100px;
  left: 253px;
  height: 64px;
  position: fixed !important;
  bottom: 30px;
  box-shadow: 0 0px 1px 0 rgba(0, 0, 0, 0.15), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  background-color: white;
  z-index: 2;
  border-radius: 9px;
  padding: 8px;
}
@media only screen and (max-width: 1400px) {
  .visualizer-container {
    margin-left: -18px;
  }
  .visualizer-info {
    left: 256px;
  }
}
@media only screen and (max-width: 959px) {
  .visualizer-container {
    margin-left: -18px;
  }
  .visualizer-info {
    left: 30px;
  }
  .plus-icon {
    left: 100px;
  }
  .minus-icon {
    left: 100px;
  }
  .left-icon {
    left: 30px;
  }
  .up-icon {
    left: 51px;
  }
  .right-icon {
    left: 72px;
  }
  .down-icon {
    left: 51px;
  }
  .center-icon {
    left: 51px;
  }
}
@media only screen and (max-width: 480px) {
  .visualizer-container {
    margin-left: 10px;
  }
}
</style>
