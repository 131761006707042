<template>
    <w-loader v-if="billing.loading"/>
    <div v-else class="list-page billings">
        <w-alert
            v-if="billing.alert"
            :message="billing.alert.message"
            :level="billing.alert.level"
            :closable="billing.enable_close_alert"
            @closed="billing.alert = null"
            class="mb-6"
        />
        <v-container fluid>
            <v-row v-if="billing.invoices && billing.invoices.length">
                <v-col md="12">
                    <div class="w-body-1">
                        {{ $lang.t('billing.blling-periods', 'Billing periods') }}
                    </div>
                </v-col>
                <v-col md="6" class="pb-7">
                    <div class="d-flex w-print-100">
                        <w-select :items="billing_dates" v-model="current_invoice" class="mr-6" hide-details="auto" />
                        <!-- <w-select :items="billing_dates" v-model="current_invoice" @change="invoice_changed" class="mr-6" hide-details="auto" /> -->
                        <w-btn v-if="billing.invoice" @click="billing.exportPdf()" :loading="billing.exporting_pdf" color="primary" id="save-pdf"
                            class="primary--text text--darken-1 primary lighten-5 my-auto" data-test-id="invoice-export-pdf-btn">
                            <v-icon color="primary" class="mr-1" size="18">
                                $vuetify.icons.download_cloud_inverted
                            </v-icon>
                            {{ $lang.t('app.get-pdf', 'Get PDF') }}
                        </w-btn>
                    </div>
                    <w-switch
                        v-model="billing.filters.ignore_zeros"
                        @change="show_invoice(current_invoice)"
                        :label="$lang.t('billing.include-zero-transactions', 'Include transactions with the cost of $0.00')"
                    />
                </v-col>
                <v-col md="6" class="pb-7"></v-col>
                <v-col md="12" class="w-print-100">
                    <w-loader v-if="billing.loading_invoice" class="loading-invoice" size="small"/>
                    <Invoice
                        v-else-if="billing.invoice && billing.invoice.id === current_invoice"
                        :billing="billing"
                        data-test-id="billing-invoice-item"
                    />
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
  import Cachier from 'cachier';
  import {vueComponent} from 'helpers'
  import Billing from 'console/src/models/Billing';
  import Invoice from '../elements/Invoice.vue';
  import FormInput from 'console/src/components/elements/form/FormInput.vue';

  export default {
    components: {
      Invoice,
      FormInput,
    },
    data() {
      return {
        current_invoice: null,
		billing: new Billing(this.$session, vueComponent(this)),
        cachier: new Cachier(this.$session.user.id),
        latest_invoice_cache_key: 'latest_invoice_cache_key',
      };
    },
    async created() {
      this.$data.current_invoice = this.latest_invoice_id || null;
      await this.$data.billing.loadInvoices();
      await this.show_invoice(this.$data.current_invoice)
    },
    methods: {
        async show_invoice(id) {
            if (id) {
                await this.$data.billing.showInvoice(id);
            } else if (!id && this.$data.billing.invoices && this.$data.billing.invoices.length) {
                this.$data.current_invoice = this.$data.billing.invoices[0].id;
            }
        },
        format_date(val) {
            if (val === 0) return this.$lang.t('app.recent-activity', 'Recent activity');
            if (!val) return false;
            const date = new Date(val * 1000);
            return date.toLocaleString('en-US', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });
        },
    },
    watch: {
        current_invoice: async function (v) {
            this.$data.cachier.setItem(this.$data.latest_invoice_cache_key, v)
            await this.show_invoice(v);
        },
    },
    computed: {
        latest_invoice_id() {
            return this.$data.cachier.getItem(this.$data.latest_invoice_cache_key);
        },
        billing_dates() {
            return this.$data.billing.invoices.map(
                (x) => ({value: x.id, text: this.format_date(x.closed_at)})
            ).filter((x) => x.text);
        },
    },
  };
</script>
<style lang="scss" scoped>
.loading-invoice {
    margin-top: 25%;
}
</style>