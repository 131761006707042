export function smallView (state = true, action) {
  switch (action.type) {
    case 'SET_SMALL_VIEW':
      return action.smallView
    default:
      return state
  }
}

export function currentView (state = 'select', action) {
  switch (action.type) {
    case 'SWITCH_VIEW':
      return action.view
    default:
      return state
  }
}
