<template>
  <ReportsWrapper :report="report" data-test-id="call-reports-traffic">
    <div class="mt-2">
        <TrafficChart :items="report.items" :group_by="report.filters.group_by"/>
        <v-simple-table class="mt-3" v-if="report.filters.group_by" data-test-id="traffic-table">
            <thead data-test-id="table-head">
              <tr data-test-id="head-row">
                  <th style="width: 100%" data-test-id="filter">
                      {{ translated_filter(report.filters.group_by) }}
                  </th>
                  <th data-test-id="incoming">{{ $lang.t('app.incoming', 'Incoming') }}</th>
                  <th data-test-id="outgoing">{{ $lang.t('app.outgoing', 'Outgoing') }}</th>
                  <th data-test-id="total">{{ $lang.t('reports.total-calls', 'Total calls') }}</th>
              </tr>
            </thead>
            <tbody data-test-id="table-body">
              <template v-for="(item, i) in report.items">
                <tr v-if="item.total" :data-test-id="`traffic-item-${i}-row`" :key="`traffic-item-${i}-row`">
                    <td data-test-id="value">{{ report.formatTime(item[report.filters.group_by]) }}</td>
                    <td data-test-id="incoming">{{ item.count_incoming }}</td>
                    <td data-test-id="outgoing">{{ item.count_outgoing }}</td>
                    <td data-test-id="total">{{ item.total }}</td>
                </tr>
              </template>
            </tbody>
        </v-simple-table>
    </div>
  </ReportsWrapper>
</template>

<script>

  import { vueComponent } from 'helpers';
  import ReportsWrapper from '../elements/Report.vue'
  import Traffic from '../../models/Traffic';
  import TrafficChart from '../elements/charts/Traffic.vue'

  export default {
    components: { ReportsWrapper, TrafficChart },
    data() {
      return {
        report: new Traffic(this.$session, vueComponent(this)),
      };
    },
    async created() {
      await this.$data.report.loadItems();
    },
    methods: {
      upperFirstCase(val) {
        return (
          val.charAt(0)
            .toUpperCase()
          + val
            .slice(1)
            .split('_')
            .join(' ')
        );
      },
      translated_filter(filter) {
        if (!filter) return '';
        if (filter === 'hour') return this.$lang.t('reports.hour', 'Hour');
        if (filter === 'date') return this.$lang.t('app.date', 'Date');
        if (filter === 'week') return this.$lang.t('reports.week', 'Week');
  
        return filter;
      }
    },
  };
</script>

<style lang="scss" scoped>
    #traffic-chart {
        font-size: 0.75rem;
    }
</style>
