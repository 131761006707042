import React, { Component } from 'react'
import App from './src/components/App.js'

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers'
import PropTypes from 'prop-types'

const store = createStore(rootReducer)

class Company extends Component {
    render = () => {
        return (
            <Provider store={store}>
                <App
                    extensions={this.props.extensions}
                    screenViewType={this.props.screenViewType}
                    standalone={this.props.standalone}
                    redirect={this.props.redirect}
                    setHasChange={this.props.setHasChange}
                    routeProps={this.props.routeProps}
                    updateCompanyName={this.props.updateCompanyName}
                    showOnlyAccountSettings={this.props.showOnlyAccountSettings}
                />
            </Provider>
        )
    }
}

// add prop validation
Company.propTypes = {
    extensions: PropTypes.array.isRequired,
    screenViewType: PropTypes.object.isRequired,
    standalone: PropTypes.bool.isRequired,
    redirect: PropTypes.func.isRequired,
    setHasChange: PropTypes.func.isRequired,
    routeProps: PropTypes.object.isRequired,
    updateCompanyName: PropTypes.func.isRequired,
    showOnlyAccountSettings: PropTypes.bool.isRequired
}

export default Company
