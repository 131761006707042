import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { XIcon } from 'pdc-svg-icons'
import Dialog from '@material-ui/core/Dialog'
import { withStyles } from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import { LiveReceptionistCreate, LiveReceptionistShow } from 'live-receptionist-react-components'

const styles = theme => ({
    loadingDiv: theme.loadingDiv,
    dialog: Object.assign({
        '&.lr-modal': {
            padding: '50px 20px 30px 30px'
        },
        '&.min-height-50 .v-application--wrap': {
            'min-height': '50vh !important'
        }
    }, theme.confirmDialog.paper),
    dialogFooter: theme.confirmDialog.dialogFooter,
    xIcon: Object.assign({
        'z-index': '1'
    }, theme.confirmDialog.xIcon)
})

class LiveReceptionistModal extends Component {
    constructor (props) {
        super(props)
        this.dialogRef = React.createRef()
        this.state = {
            id: props.id,
            vendorId: props.vendorId,
            loading: props.loading
        }
    }

    componentDidUpdate () {
        if (!this.props.isShown) return
        if (this.props.id === this.state.id && this.props.vendorId === this.state.vendorId) return
        this.setState({
            id: this.props.id,
            vendorId: this.props.vendorId,
            loading: this.props.loading
        })
    }

    render () {
        const { classes, isShown } = this.props
        return (
            <Dialog
                onClose={this.props.onClose}
                disableBackdropClick
                open = {isShown}
                maxWidth='md'
                fullWidth={true}
                classes = {{ paper: `${classes.dialog} ${this.props.size || 'size800'} lr-modal min-width-50 min-height-50` }}
                fullScreen = {this.props.size === 'sizeMobile'}
            >
                <div className={`${classes.xIcon} ${this.props.size === 'sizeMobile' ? 'small' : ''}`} onClick={this.props.isShown ? this.props.onClose : null}><XIcon/></div>
                <DialogContent ref={this.dialogRef} classes={{ root: `${classes.dialogContent}` }}>
                    <div style={{ height: '80vh' }}>
                        {this.renderProperLRComponent()}
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    renderProperLRComponent = () => {
        const props = {
            id: this.props.id,
            postSaveCb: this.postSaveCb.bind(this),
            vendor_id: this.props.vendorId,
            onClickCancel: () => setTimeout(() => this.props.onClose(), 400)
        }
        if (this.props.id && this.props.id > -1) {
            return (
                <LiveReceptionistShow {...props}/>
            )
        }

        return (
            <LiveReceptionistCreate {...props}/>
        )
    }

    postSaveCb = (data) => {
        if (this.dialogRef.current) {
            this.dialogRef.current.scrollTo(0, 0)
        }
        if (this.props.postSaveCb) this.props.postSaveCb(data)
        setTimeout(() => this.props.onClose(), 1000)
    }
}

LiveReceptionistModal.propTypes = {
    // Material ui classes
    classes: PropTypes.object,
    /**
     * Is the modal shown
     */
    postSaveCb: PropTypes.func,
    onClose: PropTypes.func,
    isShown: PropTypes.bool,
    /**
     * The id of the script.
     */
    id: PropTypes.number,
    vendorId: PropTypes.number,
    size: PropTypes.oneOf(['size550', 'size800', 'sizeMobile']),
    /**
     * If True displays a spinner and hides the content
     */
    loading: PropTypes.bool
}

export default withStyles(styles)(LiveReceptionistModal)
