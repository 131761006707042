import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'

class API {
    static loadMessages = async (offset = 0, limit = 30, filters = {}, sort = {}) => {
        let response = await ajax.postAsGet(
            PhoneComUser.getv5ApiRoot().replace('services', 'app') + '/messaging/list-messages',
            { filters, sort, limit, offset }
        )

        if (!response || !response.data || response.message === 'Network Error') return 'network-error'

        if ('code' in response && response.code !== 200) {
            console.error('list-messages response:', response)
            return { messages: {}, total: 0 }
        }

        response = response.data
        return {
            messages: response?.items ? response.items.reverse() : [],
            mediaIndexes: response.media,
            filters: response.filters,
            total: response.total
        }
    }

    static loadConversations = async (offset = 0, limit = 0, filters = {}, sort = {}) => {
        const body = {
            filters,
            offset,
            limit
        }

        let response = await ajax.postAsGet(
            PhoneComUser.getv5ApiRoot().replace('services', 'app') + '/messaging/list-conversations',
            body
        )
        if (!response || response.message === 'Network Error' || !response.data) return 'network-error'

        if (('code' in response) && (response.code !== 200)) {
            return { conversations: {}, total: 0 }
        }

        response = response.data

        if (!response) {
            window.Rollbar.warn(`Empty conversation data (https://rollbar.com/phone-2/my.phone.com/items/6516/). ${JSON.stringify(response)}`)
            return { conversations: {}, total: 0 }
        }

        return {
            conversations: response.items,
            total: response.total,
            filters: response.filters
        }
    }

    static loadContacts = (offset = 0, limit = 30) => {
        const body = {
            offset,
            limit
        }

        return ajax.post(
            PhoneComUser.getv5ApiRoot() + '/contacts/get-contacts',
            body
        ).then(
            response => {
                response = response.data
                return {
                    contacts: response.items,
                    total: response.total
                }
            },
            error => {
                console.error('Error:', error)
            }
        )
    }

    static sendMessage = payload => {
        return ajax.post(PhoneComUser.getv5ApiRoot() + '/messages/send-message', payload)
            .then(response => response)
    };

    static deleteMessage = payload => {
        return ajax.post(PhoneComUser.getv5ApiRoot() + '/messages/delete-message', payload)
            .then(response => response)
    };

    static deleteConversation = (conversation_id) => {
        const body = {
            conversation_id: conversation_id
        }

        return ajax.post(
            PhoneComUser.getv5ApiRoot() + '/messages/delete-conversation',
            body
        ).then(response => response)
    }

    static markConversationRead = (conversation_id, read_at) => {
        const body = {
            conversation_id: conversation_id
        }

        if (read_at) {
            body.read_at = read_at
        }

        return ajax.post(PhoneComUser.getv5ApiRoot() + '/messages/mark-conversation-read', body).then(response => !response?.data?.errorMessage)
    }

    static markMessageUnread = message_id => {
        const body = { message_id }
        return ajax.post(PhoneComUser.getv5ApiRoot() + '/messages/mark-message-unread', body)
    }

    static getExtensions = () => {
        const voip_id = PhoneComUser.getAPIAccountId()
        const base_url = PhoneComUser.getv4ApiRoot()
        return ajax.get(
            `${base_url}/accounts/${voip_id}/extensions`, 'Bearer'
        ).then(response => response)
    }

    static getPhoneNumbers = () => {
        const voip_id = PhoneComUser.getAPIAccountId()
        const base_url = PhoneComUser.getv4ApiRoot()
        return ajax.get(
            `${base_url}/accounts/${voip_id}/phone-numbers`, 'Bearer'
        ).then(response => response)
    }

    static getExtensionsAndPhoneNumbers = () => {
        const url = (PhoneComUser.getv5ApiRoot() + '/messaging/conversation-app-helper').replace('services.phone.com', 'app.phone.com')
        const body = {
            account_id: PhoneComUser.getAPIAccountId(),
            type: 'extensions-and-phone-numbers'
        }
        return ajax.post(url, body).then(response => response)
    }

    static getAccountContactInfo = () => {
        const url = (PhoneComUser.getv5ApiRoot() + '/messaging/conversation-app-helper').replace('services.phone.com', 'app.phone.com')
        const body = {
            account_id: PhoneComUser.getAPIAccountId(),
            type: 'account-contact-info'
        }
        return ajax.post(url, body).then(response => response)
    }

    static getConferenceInfo = () => {
        const url = (PhoneComUser.getv5ApiRoot() + '/messaging/conversation-app-helper').replace('services.phone.com', 'app.phone.com')
        const body = {
            extension_id: PhoneComUser.getExtensionId(),
            type: 'conference-info'
        }
        return ajax.post(url, body).then(response => response)
    }

    static loadMedia = async (conversationId, filters = null, lastCursor = null, firstCursor = null, offset = 0, limit = 25) => {
        const url = `${PhoneComUser.getv5ApiRoot()}/messages/list-media`
        const data = {
            conversation_id: conversationId,
            filters: filters,
            last_cursor: lastCursor,
            first_cursor: firstCursor,
            limit,
            offset
        }
        return await ajax.post(url, data).then(
            (response) => {
                response = response?.data
                if (response) {
                    return {
                        media: response.items,
                        total: response.total,
                        firstCursor: response.first_cursor,
                        lastCursor: response.last_cursor
                    }
                }
                return { media: [], total: 0 }
            }
        ).catch(error => console.error(error))
    }

    static uploadMedia = (media) => {
        return ajax.post(
            PhoneComUser.getv5ApiRoot().replace('services', 'app') + '/messaging/upload-media',
            { media }
        ).then(response => response)
    }
}

export default API
