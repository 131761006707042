import React, { Component } from 'react'
import BackButton from 'back-button'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const styles = theme => ({
    headerBar: {
        boxShadow: theme.palette.primary.flatBottomShadow,
        width: '100%',
        padding: '0 20px',
        boxSizing: 'border-box',
        zIndex: 1,
        maxHeight: '60px',
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        /*
        * Setting height to a value less than min-height
        * will properly center flex items in IE.
        */
        height: '58px',
        minHeight: '59px',
        '&.small-view': {
            padding: '22px 20px',
            '&>*:first-child': {
                marginRight: 15
            }
        }

    },
    headerBarChildren: {
        width: '100%',
        flex: '0 1 auto',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center'
    },
    backButton: {
        display: 'flex',
        alignItems: 'center',
        float: 'left'
    }
})

class Header extends Component {
    render () {
        const { classes, smallView, children, returnFnc } = this.props
        return (

            <div className={`${classes.headerBar} ${smallView ? 'small-view' : ''}`}>
                {smallView ? <div className={classes.backButton}><BackButton onClick={returnFnc}/></div> : null}
                <div className={classes.headerBarChildren}>
                    {children}
                </div>
            </div>

        )
    }
}

Header.propTypes = {
    classes: PropTypes.object,
    smallView: PropTypes.bool,
    children: PropTypes.object,
    returnFnc: PropTypes.func
}

export default withStyles(styles)(Header)
