<template>
    <div class="applications">
      <Submenu
        :routes="{
         'applications.index': {
            title: l.t('app.applications', 'Applications'),
            subroutes: ['applications.show']
          },
          'applications.create': {
            title: l.t('applications.add', 'Add application')
          },
        }"
        :key="`${l.detect()}${$route.name}`"
      />
      <ApplicationsFragment
        v-if="$route.name === 'applications.index'"
        data-test-id="apps-index"
      />
      <ApplicationsCreateFragment
        v-if="$route.name === 'applications.create'"
        data-test-id="apps-create"
      />
      <ApplicationsShowFragment
        v-if="$route.name === 'applications.show'"
        data-test-id="apps-show"
      />
    </div>
</template>

<script>
  import l from '../../../libs/lang';
  import ApplicationsFragment from './index.vue';
  import Submenu from '../../elements/Submenu.vue';
  import ApplicationsShowFragment from './show.vue';
  import ApplicationsCreateFragment from './create.vue';

  export default {
    components: {
      Submenu,
      ApplicationsFragment,
      ApplicationsCreateFragment,
      ApplicationsShowFragment,
    },
    data() {
      return {
        l,
      };
    },
    beforeRouteLeave(to, from, next) {
      if (window.deleting_in_progress) {
        this.$emit('userRedirected', to, from, next);
      } else {
        next();
      }
    },
  };
</script>
