import ajax from 'ajax'
import PhoneComUser from 'phone-com-user'

class API {
    static loadMessages = (offset = 0, limit = 30, filters = {}, sort = {}) => {
        return ajax.post(
            PhoneComUser.getv5ApiRoot().replace('services', 'app') + '/messaging/list-messages',
            { filters, sort, limit, offset }
        ).then(
            response => {
                if (response && 'code' in response && response.code !== 200) {
                    console.error('list-messages response:', response)
                    return { messages: {}, total: 0 }
                }

                response = response.data
                return {
                    messages: response.items.reverse(),
                    mediaIndexes: response.media,
                    filters: response.filters,
                    total: response.total
                }
            },
            error => {
                console.error('Error:', error)
            }
        )
    };

    static loadConversations = (offset = 0, limit = 0, filters = {}, sort = {}) => {
        const body = {
            filters,
            offset,
            limit
        }

        return ajax.postAsGet(
            PhoneComUser.getv5ApiRoot().replace('services', 'app') + '/messaging/list-conversations',
            body
        )
            .then(
                response => {
                    if (response && 'code' in response && response.code !== 200) return { conversations: {}, total: 0 }

                    response = response.data

                    return {
                        conversations: response.items,
                        total: response.total,
                        filters: response.filters
                    }
                },
                error => {
                    console.error('Error loading conversations:', error)
                }
            )
    };

    static loadContacts = (offset = 0, limit = 30) => {
        const body = {
            offset,
            limit
        }

        return ajax.post(
            PhoneComUser.getv5ApiRoot() + '/contacts/get-contacts',
            body
        ).then(
            response => {
                response = response.data
                return {
                    contacts: response.items,
                    total: response.total
                }
            },
            error => {
                console.error('Error:', error)
            }
        )
    }

    static sendMessage = payload => {
        return ajax.post(PhoneComUser.getv5ApiRoot() + '/messages/send-message', payload)
            .then(response => response)
    };

    static deleteMessage = payload => {
        return ajax.post(PhoneComUser.getv5ApiRoot() + '/messages/delete-message', payload)
            .then(response => response)
    };

    static deleteConversation = (conversation_id) => {
        const body = {
            conversation_id: conversation_id
        }

        return ajax.post(
            PhoneComUser.getv5ApiRoot() + '/messages/delete-conversation',
            body
        ).then(response => response)
    }

    static markRead = (conversation_id, read_at) => {
        const body = {
            conversation_id: conversation_id
        }

        if (read_at) {
            body.read_at = read_at
        }

        return ajax.post(PhoneComUser.getv5ApiRoot() + '/messages/mark-conversation-read', body)
    }

    static markMessageUnread = message_id => {
        const body = { message_id }
        return ajax.post(PhoneComUser.getv5ApiRoot() + '/messages/mark-message-unread', body)
    }

	static getExtension = async extensionId => {
	    // if(window.APP_CONFIG.stage == 'STAGE') return // new v4 api only works in prod at the moment, should be fixed in a week or so
	    const voip_id		= PhoneComUser.getAPIAccountId()
	    const base_url = PhoneComUser.getv4ApiRoot()
	    const requestUrl	= `${base_url}/accounts/${voip_id}/extensions/${extensionId}`
	    const response	= await ajax.get(requestUrl, 'Bearer')
	    return response.data
	}

    static getExtensions = () => {
        const voip_id = PhoneComUser.getAPIAccountId()
        const base_url = PhoneComUser.getv4ApiRoot()
        return ajax.get(
            `${base_url}/accounts/${voip_id}/extensions?limit=500`, 'Bearer'
        ).then(response => response)
    }

    static getPhoneNumbers = () => {
        const voip_id = PhoneComUser.getAPIAccountId()
        const base_url = PhoneComUser.getv4ApiRoot()
        return ajax.get(
            `${base_url}/accounts/${voip_id}/phone-numbers`, 'Bearer'
        ).then(response => response)
    }

    static getExtensionsAndPhoneNumbers = () => {
        const url = (PhoneComUser.getv5ApiRoot() + '/messaging/conversation-app-helper').replace('services.phone.com', 'app.phone.com')
        const body = {
            account_id: PhoneComUser.getAPIAccountId(),
            type: 'extensions-and-phone-numbers'
        }
        return ajax.post(url, body).then(response => response)
    }

    static getAccountContactInfo = () => {
        const url = (PhoneComUser.getv5ApiRoot() + '/messaging/conversation-app-helper').replace('services.phone.com', 'app.phone.com')
        const body = {
            account_id: PhoneComUser.getAPIAccountId(),
            type: 'account-contact-info'
        }
        return ajax.post(url, body).then(response => response)
    }

    static getConferenceInfo = () => {
        const url = (PhoneComUser.getv5ApiRoot() + '/messaging/conversation-app-helper').replace('services.phone.com', 'app.phone.com')
        const body = {
            extension_id: PhoneComUser.getExtensionId(),
            type: 'conference-info'
        }
        return ajax.post(url, body).then(response => response)
    }

    static loadMedia = async (conversationId, filters = null, lastCursor = null, firstCursor = null, offset = 0, limit = 25) => {
        const url = `${PhoneComUser.getv5ApiRoot()}/messages/list-media`
        const data = {
            conversation_id: conversationId,
            filters: filters,
            last_cursor: lastCursor,
            first_cursor: firstCursor,
            limit,
            offset
        }
        return await ajax.post(url, data).then(
            (response) => {
                if ('data' in response) {
                    response = response.data
                    return {
                        media: response.items,
                        total: response.total,
                        firstCursor: response.first_cursor,
                        lastCursor: response.last_cursor
                    }
                }
                return { media: [], total: 0 }
            }
        )
    }

    static uploadMedia = (media) => {
        return ajax.post(
            PhoneComUser.getv5ApiRoot().replace('services', 'app') + '/messaging/upload-media',
            { media }
        ).then(response => response)
    }

    static fetchActiveCallerId = async () => {
        const body = {
            voip_phone_id: parseInt(PhoneComUser.getExtensionId())
        }
        return ajax.post(`${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/calls/get-caller-id`, body)
            .then(response => response.data)
    }

    static setActiveCallerId = async (callerId, extension_id = null) => {
        if (!extension_id && !parseInt(PhoneComUser.getExtensionId()) || !callerId) {
            return
        }

        const body = {
            voip_phone_id: extension_id || parseInt(PhoneComUser.getExtensionId()),
            caller_id: callerId
        }
        return ajax.post(`${PhoneComUser.getv5ApiRoot().replace('services', 'app')}/communicator/calls/set-caller-id`, body)
            .then(response => response.data)
    }
}

export default API
