import 'core-js'
import * as React from 'react'
import VueWrapper from 'vue-wrapper'
import App from './app.vue'

/**
 *
 *
 * @param {string} page - create, show or index
 */
const getVueWrapper = function (page) {
    /**
     * The main app for the console jsx to react from vuew
     *
     * @param {object | null} props  - react props
     */
    // eslint-disable-next-line react/display-name
    return function (props) {
        return (
            <div>
                {VueWrapper({ ...props, page }, App)}
            </div>
        )
    }
}

const SMS = getVueWrapper('sms')
const Total = getVueWrapper('total')
const Types = getVueWrapper('types')
const Summary = getVueWrapper('summary')
const Traffic = getVueWrapper('traffic')
const CallLogs = getVueWrapper('call-logs')
const PerNumber = getVueWrapper('per-number')
const PerExtension = getVueWrapper('per-extension')
const GeoDistribution = getVueWrapper('geo-distribution')

/**
 *
 */
export {
    SMS,
    Total,
    Types,
    Traffic,
    Summary,
    CallLogs,
    PerNumber,
    PerExtension,
    GeoDistribution
}
