import React from 'react'
import { Switch, Route } from 'react-router-dom'
import AppsContextProvider from './src/contexts/AppsContext'
import AppsListing from './src/pages/listing/Index'
import AppsDetails from './src/pages/details/Index'
import { RouterProps } from './src/utils/objects'
import { getBasePath } from './src/utils/utils'
import Popup from './src/popup/Index'

interface AppsMarketplaceInterface {
  csr: boolean,
  routeProps: RouterProps
}

/**
 *
 */
const AppsMarketplace = (props: AppsMarketplaceInterface) => {
    const renderAppsMarketplace = () => {
        const basePath = getBasePath(props.routeProps.match)

        return (
            <Switch>
                <Route path={`${basePath}/:appName`} render={(props) => {
                    return <AppsDetails routeProps={{ ...props }} />
                }}/>
                <Route path={basePath} render={(props) => {
                    return <AppsListing routeProps={{ ...props }} />
                }}/>
            </Switch>
        )
    }

    return (
        <AppsContextProvider>
            {renderAppsMarketplace()}
        </AppsContextProvider>
    )
}

export default AppsMarketplace

/**
 *
 */
export { Popup }
