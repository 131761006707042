import React, { useState, useRef, useEffect, useContext } from 'react'
import { CallState, PdcCallConsumer, SipCallSession } from 'pdc-calls'
import { ArrowDropdownIcon, CallIncomingIcon, PauseIcon, VolumeUpIcon } from 'svg-icons'
import SessionsBarSelector from './SessionsBarSelector'
import { makeStyles } from '@material-ui/core'
import styles from './PhonebarStyles'
import Typography from 'typography'
import { FeatureEventsContext } from 'providers/src'

const useStyles = makeStyles(styles)

interface CountProp{
    count: number
}
function IncomingSessionsIcon (props: CountProp): JSX.Element {
    if (props.count === 0) return (<></>)
    const classes = useStyles()
    return (<span className={classes.sessionsCountIcon}>
        <CallIncomingIcon className={classes.counterIcon}/>
        <Typography variant={'subtitle2'} component={'span'}>{props.count}</Typography>
    </span>)
}
function ActiveSessionsIcon (props: CountProp): JSX.Element {
    if (props.count === 0) return (<></>)
    const classes = useStyles()
    return (<span className={classes.sessionsCountIcon}>
        <VolumeUpIcon className={classes.counterIcon}/>
        <Typography variant={'subtitle2'} component={'span'}>{props.count}</Typography>
    </span>)
}
function OnHoldSessionsIcon (props: CountProp): JSX.Element {
    if (props.count === 0) return (<></>)
    const classes = useStyles()
    return (<span className={classes.sessionsCountIcon}>
        <PauseIcon className={classes.counterIcon}/>
        <Typography variant={'subtitle2'} component={'span'}>{props.count}</Typography>
    </span>)
}
function SessionsList (): JSX.Element {
    return (<PdcCallConsumer>
        {(context: any) => (
            <SessionsBarSelector
                sessions={context.sessions}
            />
        )}
    </PdcCallConsumer>)
}
function CallsCount (props: CountProp) {
    if (props.count <= 0) return (<></>)
    const classes = useStyles()
    const text = props.count > 1 ? 'Calls' : 'Call'
    return (<span className={classes.sessionsCount}>
        <Typography variant={'subtitle2'} component={'span'}>
            {`${props.count} ${text}`}
        </Typography>
    </span>)
}
interface SessionsProps{
    sessions: SipCallSession[]
}
function SessionsCount (props: SessionsProps) {
    const sessions = props.sessions
    const sessionsCount = sessions.length
    const incomingCount = sessions.filter(s => (s.callState === CallState.INCOMING)).length
    const onHoldCount = sessions.filter(s => s.isOnHold).length
    const activeCount = (sessionsCount - onHoldCount) - incomingCount
    const classes = useStyles()
    return (<>
        <CallsCount count={sessionsCount}/>
        <span className={classes.sessionsCounters}>
            <IncomingSessionsIcon count={incomingCount}/>
            <ActiveSessionsIcon count={activeCount}/>
            <OnHoldSessionsIcon count={onHoldCount}/>
        </span>
    </>)
}

/***/
const Phonebar = (): JSX.Element => {
    const classes = useStyles()
    const [expandSessionsList, setExpandSessionsList] = useState(false)
    const toggleSessionsList = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'toggle-expand-sessions-list', value: !expandSessionsList })
        setExpandSessionsList(!expandSessionsList)
    }
    const barRef = useRef<HTMLDivElement | null>(null)
    const featureEventsContext = useContext(FeatureEventsContext)

    const handleClickOutside = (event) => {
        if (barRef.current && !barRef.current.contains(event.target)) {
            featureEventsContext.pushEvent({ appName: 'softphone', key: 'close-expand-sessions-list-ouside-click' })
            setExpandSessionsList(false)
        }
    }
    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [barRef])
    const dropdownIconClass = `${classes.dropdownIcon} ${expandSessionsList ? 'open' : ''}`
    return (<div ref={barRef} className={classes.phonebarWrapper}>
        <div className={classes.phonebar}>
            <PdcCallConsumer>
                {(context: any) => (<>
                    <div className={classes.sessionsBar}>
                        <span className={dropdownIconClass}>
                            <ArrowDropdownIcon onClick={toggleSessionsList}/>
                        </span>
                        <SessionsCount sessions={context.sessions} />
                    </div>
                </>)}
            </PdcCallConsumer>
        </div>
        {expandSessionsList ? <SessionsList/> : <></>}
    </div>)
}

export default Phonebar
