<template>
    <div class="faxes">
      <IndexPage
        :resource="fax"
        :withExtensionSelector="true"
        :extensionCb="'load_saved_filters'"
        class="faxes list-page"
        :label_cols="label_cols"
        data-test-id="faxes"
      >
        <template v-slot:filters>
          <SmartFilters
            :log="fax" :key="JSON.stringify(fax.client_config)"
            :label_cols="label_cols"
            data-test-id="faxes-smart-filters"
          />
          <FormInput :label="l.t('app.new', 'New')" :label_cols="label_cols">
            <w-switch
              v-model="fax.filters.is_new"
              @change="fax.restartSavedFilterName()"
              :label="l.t('app.new', 'New')"
              hide-details='auto'
              data-test-id="faxes-filters-new-switch"
            />
          </FormInput>
          <FormInput :label="l.t('app.from', 'From')" :label_cols="label_cols">
            <Tel
              :value="fax.filters.from"
              @changed="fax.filter_param_changed('from', $event.clean_number)"
              :key="fax.saved_filter_name"
              data-test-id="faxes-filters-from-input"
            />
          </FormInput>
          <FormInput :label="l.t('app.to', 'To')" :label_cols="label_cols">
            <Tel
              :value="fax.filters['to']"
              @changed="fax.filter_param_changed('to', $event.clean_number)"
              :key="fax.saved_filter_name"
              data-test-id="faxes-filters-to-input"
            />
          </FormInput>
        </template>
        <template v-slot:additionalFilterButtons>
          <SaveFilter
            v-if="fax.filters_applied && !fax.saved_filter_name"
            :generate_name_callback="fax.generateNewFilterName.bind(fax)"
            @confirmed="fax.save_filters(null, $event)"
            data-test-id="faxes-filters-save-filter"
          />
        </template>

        <template v-slot:list>
          <v-simple-table class="list-table" data-test-id="faxes-table">
            <thead data-test-id="table-head">
              <tr data-test-id="head-row">
                <th class="select-table-item" data-test-id="checkbox"><br/></th>
                <th data-test-id="received-at">{{ l.t("app.received-at", "Received at") }}</th>
                <th data-test-id="from">{{ l.t("app.from", "From") }}</th>
                <th data-test-id="location">{{ l.t("app.location", "Location") }}</th>
                <th data-test-id="caller">{{ l.t("app.caller", "Caller") }}</th>
                <th data-test-id="pages">{{ l.t("fax.pages", "Pages") }}</th>
                <th data-test-id="more-options"><br/></th>
              </tr>
            </thead>
            <tbody class="nowrap" data-test-id="table-body">
              <tr
                v-for="(m, i) in fax.items"
                :key="`${m['id']}-${Number(m.selected)}`"
                :class="{ 'font-weight-black': m['is_new'], 'selected-row': m.selected  }"
                :data-test-id="m.id"
              >
                <td v-if="fax.deleting_item_id === m.id" colspan="9" data-test-id="loader">
                  <w-loader size="small" :no_margins="true"/>
                </td>
                <template v-else>
                  <td class="select-table-item" data-test-id="checkbox">
                    <w-checkbox
                      class="multiple-select-checkbox"
                      v-model="m.selected"
                      @change="checkChanged"
                      :key="`extensions-item-${m.id}-${m.selected}`"
                      hide-details="auto"
                      :data-test-id="`faxes-item-${i}-checkbox`"
                    />
                  </td>
                  <td data-test-id="created-at">
                      {{ new Date(m["created_at"] * 1000).toLocaleString() }}
                  </td>
                  <td data-test-id="from">{{ m["from"]["number"] | formatPhoneNumber }}</td>
                  <td data-test-id="location">
                      <template v-if="m['from']['city'] || m['from']['state']">
                          {{ m["from"]["city"] }}{{ m["from"]["city"] && m["from"]["state"] ? ", " : "" }}{{ m["from"]["state"] }}
                      </template>
                      <template v-else>
                          &mdash;
                      </template>
                  </td>
                  <td data-test-id="caller">{{ m["from"]["name"] }}</td>
                  <td data-test-id="pages">{{ m["pages"] }}</td>
                  <td data-test-id="more-options">
                    <MoreOptions
                      :options="more_options(m)"
                      :icon="downloading_id === m.id ? '$vuetify.icons.spinning_loader' : null"
                      :key="`${m.id}-${downloading_id}`"
                    />
                    <ConfirmationModal
                      :key="m['id']"
                      v-if="confirmation_modal === m.id"
                      @confirmed="confirmed(m['id'])"
                      @close="confirmation_modal = false"
                      :message="l.t('app.are-you-sure-delete-message','Are you sure you want to remove this message? You will not be able to access it after removing.')"
                      :data-test-id="`faxes-item-${i}-confirmation-modal`"
                    />
                  </td>
                </template>
              </tr>
            </tbody>
          </v-simple-table>
          <DefaultModal :value="!!show_message_id" @input="on_close_info_modal" max-width="425" :data-test-id="`faxes-item-info`">
            <template v-slot:title>{{l.t('app.fax-id', 'Fax ID')}}</template>
            {{ show_message_id }}
            <CopyToClipboard
              v-if="show_message_id"
              :copy="show_message_id"
              class="ml-3"
              :data-test-id="`copy-fax-id-copy-to-clipboard`"
            />
            <template v-slot:buttons>
              <w-btn @click="show_message_id = null" color="secondary">{{l.t('app.close', 'Close')}}</w-btn>
            </template>
          </DefaultModal>
          <DefaultModal :value="!!error" @input="on_close_error_modal" max-width="425">
            {{error}}
            <template v-slot:buttons>
              <w-btn @click="error = null" color="secondary">{{l.t('app.close', 'Close')}}</w-btn>
            </template>
          </DefaultModal>
        </template>
    </IndexPage>
  </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import Fax from '../../../models/Fax';
  import FileHandler from '../../../libs/FileHandler';
  import Tel from '../../elements/Tel.vue';
  import IndexPage from '../../elements/IndexPage.vue';
  import MoreOptions from '../../elements/MoreOptions.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import SmartFilters from '../../elements/SmartFilters.vue';
  import SaveFilter from '../../elements/modal/SaveFilter.vue';
  import CopyToClipboard from '../../elements/CopyToClipboard.vue';
  import DefaultModal from '../../elements/modal/DefaultModal.vue';
  import ConfirmationModal from '../../elements/modal/ConfirmationModal.vue';

  export default {
    components: {
      Tel,
      IndexPage,
      FormInput,
      SaveFilter,
      MoreOptions,
      SmartFilters,
      DefaultModal,
      CopyToClipboard,
      ConfirmationModal,
    },
    data() {
      return {
        l,
        fax: new Fax(this.$session, vueComponent(this)),
        confirmation_modal: null,
        label_cols: 2,
        downloading_id: null,
        show_message_id: null,
        error: null,
      };
    },
    created() {
      this.$data.fax.loading = true;
    },
    beforeRouteLeave(to, from, next) {
      return this.$data.fax.handleUserRedirect(to, from, next);
    },
    methods: {
      more_options(item) {
        item.player = item.player || new FileHandler(
          `/extensions/${this.$data.fax.extension}/fax/${item.id}/download`,
          this.$session,
        );
        let options = [{
          cb: async () => {
            this.$data.downloading_id = item.id;
            try {
              await item.player.download();
              this.$data.downloading_id = false;
              if (item.is_new) await this.$data.fax.opened(item.id);
            } catch (err) {
              console.log(err);
              this.$data.error = l.t('app.something-went-wrong-while-downlading', 'Something went wrong ');
            }
            this.$data.downloading_id = false;
          },
          title: l.t('app.download-fax', 'Download fax')
        }];
        options = options.concat([
          {
            cb: async () => this.$data.confirmation_modal = item.id,
            title: l.t('app.delete-fax', 'Delete fax'),
          },
          {
            cb: () => this.$data.show_message_id = item.id,
            title: l.t('app.info', 'Info'),
          },
        ]);
        return options;
      },
      on_close_info_modal(val) {
        if (!val) this.$data.show_message_id = null;
      },
      on_close_error_modal(val) {
        if (!val) this.$data.error = null;
      },
      async confirmed(id) {
        this.$data.confirmation_modal = false;
        await this.$data.fax.delete_m(id);
      },
      checkChanged() {
        this.$data.fax.checkSubmission();
        this.$emit('forceUpdate');
      }
    },
    watch: {
      extension(value) {
        this.$data.fax.extensionChanged(value);
      },
    },
  };
</script>
