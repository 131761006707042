import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5701%3A11673
 *
 * @param {SvgIconProps} props - svg props
 */
export const AddIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M11 4C10.7239 4 10.5 4.22386 10.5 4.5V10.5H4.5C4.22386 10.5 4 10.7239 4 11V13C4 13.2761 4.22386 13.5 4.5 13.5H10.5V19.5C10.5 19.7761 10.7239 20 11 20H13C13.2761 20 13.5 19.7761 13.5 19.5V13.5H19.5C19.7761 13.5 20 13.2761 20 13V11C20 10.7239 19.7761 10.5 19.5 10.5H13.5V4.5C13.5 4.22386 13.2761 4 13 4H11Z' fill={color}/>
        </SvgIcon>
    )
}

export default AddIcon
