<template>
  <div class="vue-block" :class="{selected: selected}" :title="name || title" :style="blockStyle" @click="doubleClick()" @touchend="doubleClick()">
    <header class="headerStyle">
      <div class="numberAndExtensionTitle">
        {{ blockTitle }}
      </div>
    </header>
    <header v-if="objectType !== 'call flow'" class="numberAndExtensionHeader">
      <div class="nameHolder" :style="{display: ['device', 'trunk'].includes(objectType) ? 'none' : ''}">
        {{ name }}
      </div>
    </header>
    <div class="inputs heightInput">
      <div class="input" v-for="(slot, index) in inputs" :key="index" :style="autoHeight">
        <div class="circle inputColor" v-if="slot.active" :class="{active: slot.active, deviceInput: ['device', 'trunk'].includes(objectType)}"></div>
        <div class="inputLabel">
          {{ slot.label }}
        </div>
      </div>
    </div>
    <div class="outputs outputsHeight">
      <div class="output" v-for="(slot, index) in outputs" :key="index">
        <div v-if="slot.used" class="circle" :class="{active: slot.active}"></div>
        <div v-if="slot.backLink" :title="slot.backLink" class="backLink" :style="{background: slot.backLinkColor}">
          {{ slot.backLink }}
        </div>
        <div class="outputLabel">
          <div v-if="['menu', 'queue'].includes(objectType)" class="menuRow">
            <span class="menuNameHolder">
              {{ slot.actionName }}
            </span>
            <span class="actionKey" :class="{queueKey: objectType === 'queue', menuKey: objectType === 'menu'}">
              {{ slot.key }}
            </span>
          </div>
          <div v-else class="nameHolder">
            <template v-if="slot.actionType">
              <Icon class="svgIcon actionIcon" :name="slot.actionType.toLowerCase()"/>
              <span v-if="slot.actionType.toLowerCase() !== 'disconnect'" class="actionType">
                {{ slot.actionType === 'receptionist' ? 'Receptionist' : slot.actionType }}:
              </span>
              <span>
                {{ slot.actionName }}
              </span>
            </template>
            <template v-else-if="['device', 'trunk'].includes(objectType)">
              <Icon class="svgIcon deviceIcon" :name="slot.actionName.toLowerCase()"/>
            </template>
            <template v-else>
              <div v-if="slot.label.includes('Preset')" class="filterName">
                {{ slot.label }}
              </div>
              <div v-else class="filterName leftSideLabel">
                {{ slot.label === 'All Calls' ? `Group: ${slot.label}` : slot.label }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Icon from '../icons/Icon.vue';
  import Visualizer from '../helpers/visualizer-helpers';

  export default {
    name: 'VueBlock',
    components: {
      Icon,
    },
    props: {
      x: {
        type: Number,
        default: 0,
        validator(val) {
          return typeof val === 'number';
        }
      },
      y: {
        type: Number,
        default: 0,
        validator(val) {
          return typeof val === 'number';
        }
      },
      selected: Boolean,
      title: {
        type: String,
        default: 'Title'
      },
      inputs: Array,
      outputs: Array,
      color: {
        type: String,
        default: ''
      },
      used: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Object
      },
      actionType: {
        type: String,
        default: ''
      },
      actionName: {
        type: String,
        default: ''
      },
      objectType: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: '',
      },
      actionId: {
        type: Number,
        default: -1
      }
    },
    mounted() {
      document.documentElement.addEventListener('mousemove', this.handleMove, true);
      document.documentElement.addEventListener('mousedown', this.handleDown, true);
      document.documentElement.addEventListener('mouseup', this.handleUp, true);
      document.documentElement.addEventListener('touchstart', this.handleDown, { passive: false });
      document.documentElement.addEventListener('touchmove', this.handleMove, { passive: false });
      document.documentElement.addEventListener('touchend', this.handleUp, { passive: false });
    },
    beforeDestroy() {
      document.documentElement.removeEventListener('mousemove', this.handleMove, true);
      document.documentElement.removeEventListener('mousedown', this.handleDown, true);
      document.documentElement.removeEventListener('mouseup', this.handleUp, true);
      document.documentElement.removeEventListener('touchstart', this.handleDown, { passive: false });
      document.documentElement.removeEventListener('touchmove', this.handleMove, { passive: false });
      document.documentElement.removeEventListener('touchend', this.handleUp, { passive: false });
    },
    data() {
      return {
        width: this.$props.options.width,
        dragging: false,
        counter: 0,
        timer: null,
        mouseX: 0,
        mouseY: 0,
        lastMouseX: 0,
        lastMouseY: 0,
        dragging: false,
      };
    },
    watch: {
      counter() {
        if (this.counter > 1) {
          this.openModal();
        }
      },
    },
    methods: {
      handleMove(e) {
        if (this.dragging) {
          const mouse = Visualizer.getMousePosition(e);
          this.mouseX = mouse.x;
          this.mouseY = mouse.y;

          const diffX = this.mouseX - this.lastMouseX;
          const diffY = this.mouseY - this.lastMouseY;

          this.lastMouseX = this.mouseX;
          this.lastMouseY = this.mouseY;

          this.moveWithDiff(diffX, diffY);
        }
      },
      handleDown(e) {
        const mouse = Visualizer.getMousePosition(e);
        this.mouseX = mouse.x;
        this.mouseY = mouse.y;
        this.lastMouseX = this.mouseX;
        this.lastMouseY = this.mouseY;

        const target = e.target || e.srcElement;
        if (this.$el.contains(target)) {
          this.dragging = true;
          this.$emit('select');

          if (e.preventDefault) e.preventDefault();
        }
      },
      openModal() {
        if (this.$props.actionId >= 0) {
          this.$emit('openModal', {actionId: this.$props.actionId, modal_type: this.$props.objectType});
        }
      },
      doubleClick() {
        this.counter++;
        if (this.counter) {
          this.timer = setTimeout(() => { this.counter = 0; }, 700);
        } else {
          clearTimeout(this.timer);
        }
      },
      handleUp() {
        if (this.dragging) {
          this.dragging = false;
        }
      },
      moveWithDiff(diffX, diffY) {
        const left = this.$props.x + diffX / this.$props.options.scale;
        const top = this.$props.y + diffY / this.$props.options.scale;

        this.$emit('update:x', left);
        this.$emit('update:y', top);
      },
      objectStroke() {
        switch (this.$props.objectType) {
          case 'menu':
            return '1px solid rgba(181, 211, 219, 1)';
          case 'queue':
            return '1px solid rgba(219, 200, 163, 1)';
          case 'preset':
            return '1px solid rgba(70, 112, 53, 0.25)';
          case 'extension':
            return '1px solid rgba(133, 146, 154, 0.25)';
          case 'device':
            return '1px solid rgba(133, 146, 154, 0.25)';
          case 'trunk':
            return '1px solid rgba(133, 146, 154, 0.25)';
          case 'phone_number':
            return '1px solid rgba(210, 219, 189, 1)';
          case 'receptionist':
            return '1px solid rgba(212, 188, 231, 1)';
          default:
            return '1px solid rgba(232, 208, 205, 1)';
        }
      }
    },
    computed: {
      blockTitle() {
        if (['device', 'trunk'].includes(this.$props.objectType)) {
          return this.$props.title + (this.$props.name ? ` -  ${this.$props.name}` : '');
        }
        return this.$props.title;
      },
      blockStyle() {
        return {
          top: `${this.$props.options.center.y + this.$props.y * this.$props.options.scale}px`,
          left: `${this.$props.options.center.x + this.$props.x * this.$props.options.scale}px`,
          width: (['device', 'trunk'].includes(this.$props.objectType)) ? '151px' : `${this.width}px`,
          transform: `scale(${this.$props.options.scale})`,
          'background': this.$props.color || 'rgba(234, 245, 248, 0.8)',
          'border': this.objectStroke(),
          transformOrigin: 'top left'
        };
      },
      autoHeight() {
        let inputHeight = 32;
        if (this.$props.objectType === 'menu') {
          inputHeight = 28;
        } else if (this.$props.objectType === 'call flow') {
          inputHeight = 0;
        }
        const outputs = this.$props.outputs.length ? this.$props.outputs.length * 21 : 21;
        return {
          'height': `${outputs + inputHeight}px`,
        };
      },
    }
  };
</script>

<style lang="scss" scoped>
  $blockBorder: 0px;

  $ioPaddingInner: 2px 0;
  $ioHeight: 21px;
  $ioFontSize: 11px;

  $circleBorder: 4px;
  $circleSize: 12px;
  $circleMargin: 2px; // left/right

  $circleNewColor: #364047;;
  $circleRemoveColor: #FF0000;
  $circleConnectedColor: #FFFF00;

  .vue-block {
    position: absolute;
    box-sizing: border-box;
    border: $blockBorder solid black;
    border-radius: 9px;
    color: white;
    border: 1px solid transparent;
    background: white;
    z-index: 1;
    opacity: 0.9;
    cursor: grab;

    &.selected {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      z-index: 2;
    }

    > header {
      text-align: center;

      > .delete {
        color: white;
        cursor: pointer;
        float: right;
        position: absolute;
        right: 5px;
        text-decoration: none;
      }
    }
    .inputs, .outputs {
      padding: $ioPaddingInner;
      color: #364047;
      display: block;
      width: 50%;

      > * {
        width: 100%;
      }
    }

    .circle {
      box-sizing: border-box;
      margin-top: $ioHeight / 2 - $circleSize / 2 - 2;

      width: $circleSize;
      height: $circleSize;

      border: $circleBorder solid rgba(54, 64, 71, 1);
      border-radius: 100%;

      // cursor: crosshair;
      background-color: white;
    }

    .inputs {
      float: left;
      text-align: left;
      width: 8% !important;
      margin-left: -($circleSize/2 + $blockBorder);
    }

    .input, .output {
      height: $ioHeight;
      overflow: hidden;
      font-size: $ioFontSize;
    }

    .input {
      float: left;
      display: flex;
      align-items: center;

      .circle {
        float: left;
        margin-right: $circleMargin;
        margin-left: 2px;
        margin-top: 0px !important;
        width: 5px !important;
        height: 5px !important;

        &:hover {
          background: $circleNewColor;

          &.active {
            background: $circleRemoveColor !important;
          }
        }
      }
    }

    .outputs {
      float: right;
      text-align: right;
      width: 92% !important;
      display: flex;
      flex-direction: column;
      margin-right: -7px;
    }

    .output {
      float: right;

      .outputLabel {
        margin-right: 19px;
        max-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .circle {
        float: right;
        margin-left: $circleMargin;

        &:hover {
          background: $circleNewColor;
        }
      }
    }
  }
  .actionKey {
    display: inline-flex;
    border-radius: 3px;
    width: 18px;
    justify-content: center;
    color: #FFFFFF;
  }
  .queueKey {
    background: rgb(223, 192, 132);
  }
  .menuKey {
    background: #2A9BB7;
  }
  .svgIcon {
    vertical-align: middle;
    float: left;
    width: 15px;
    height: 15px;
    margin-bottom: 1px;
    margin-right: 5px;
    margin-left: 8px;
    color: #364047;
    z-index: -1;
  }
  .actionIcon {
    position: absolute;
    left: 25px;
  }
  .deviceIcon {
    position: absolute;
    width: 50px;
    height: 50px;
    margin-top: -34px;
    margin-left: -90px;
  }
  .actionType {
    margin-left: 28px;
  }
  .nameHolder {
    // height: 20px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .menuNameHolder {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 7px;
    max-width: 85%;
  }
  .menuRow {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .numberAndExtensionTitle {
    margin-top: -21px;
    margin-left: 4px;
    font-size: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .numberAndExtensionHeader {
    color: #364047;
    background-color: transparent !important;
    font-weight: 500;
    font-size: 16px;
    width: 90% !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    height: 32px !important;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: center;
    float: right;
    border-bottom: none !important;
    margin-right: 9px;
    position: unset !important;
  }
  .outputsHeight {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }
  .heightInput {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 10px;
  }
  .inputColor {
    background-color: #8C98A0 !important;
    border: none !important;
  }
  .deviceInput {
    margin-right: -2px !important;
  }
  .headerStyle {
    font-weight: 700;
    color: #364047;
    font-size: 16px;
    display: flex;
    align-items: center;
    height: auto !important;
    border-bottom: none !important;
    text-align: center !important;
    background-color: transparent !important;
    position: unset !important;
  }
  .filterName {
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .leftSideLabel {
    text-align: left;
  }
  .backLink {
    border: 0.5px solid #8c98a0;
    position: absolute;
    padding-left: 3px;
    padding-right: 3px;
    width: 95px;
    max-height: 18px;
    border-radius: 14px 4px 4px 14px;
    margin-left: 211px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
