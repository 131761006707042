import React, { Component } from 'react'
import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

const styles = theme => ({
	addOnsList: {
		display:		'flex',
		flexDirection:	'column',
		justifyContent:	'space-between',
		maxWidth:			440,
	},
	addOnItem: {
		padding:		15,
		border:			'1px solid gray',
		borderRadius:	10,
		padding:		20,
		boxShadow:		'0px 0px 6px gray',
		display:		'flex',
		justifyContent:	'space-between',
		alignItems:		'center',
		'&:not(:last-child)': {
			marginBottom: 30,
		},
		'&>:not(:last-child)': {
			marginRight: 10,
		}
	},
	mainContent: {
		display:		'flex',
		flexDirection:	'column',
		'& .title': {
			fontSize:	20,
			fontWeight:	'bold'
		},
		'& .disclaimer': {
			fontSize:	12,
			color:		'gray'
		}
	},
	price: {
		display:		'flex',
		flexDirection:	'column',
		'& .value': {
			fontSize:	20,
			fontWeight:	'bold'
		},
		'& .description': {
			fontSize:	12,
			color:		'gray'
		}
	},
	addIcon: {
		color:		'#0A40A1',
		fontSize:	55,
		cursor:		'pointer'
	},
	toggleButtonSecondary: {
		color: '#ffffff !important',
	},
	toggleButtonPrimary: {
		color: '#0A40A1 !important',
	},
	toggleButtonTrack:{
		'background-color': '#0A40A1 !important',
		opacity: '1 !important',
	},
	switchBase: {
		padding: '9px !important'
	}
})

class AddOns extends Component {


	state={
		'checkedA': true,
		'checkedB': true,
	}


	renderAddOn = options => {
		const { classes } = this.props
		return (
			<div className={classes.addOnItem}>
				<div className={classes.buttonWrapper}>{options.button}</div>
				<div className={classes.mainContent}>
					<span className='title'>{options.title}</span>
					<span className='disclaimer'>{options.disclaimer}</span>
				</div>
				<div className={classes.price}>
					<span className='value'>{options.price.value}</span>
					<span className='description'>{options.price.description}</span>
				</div>
			</div>
		)
	}

	handleCheckedChange = name => event => {
		this.setState({ ...this.state, [name]: event.target.checked })
	}

	render() {
		const { classes } = this.props
		return (
			<div>
				<div className={classes.addOnsList}>
					{this.renderAddOn({
						button: (
							<Switch
								checked		= {this.state.checkedA}
								onChange	= {this.handleCheckedChange('checkedA')}
								value		= 'checkedA'
								classes		= {{ switchBase: classes.switchBase, colorSecondary: classes.toggleButtonSecondary, track: classes.toggleButtonTrack }}
							/>
						),
						title:		'Call recording',
						disclaimer:	'Every call will be recorded and available to everyone.',
						price: {
							value:			'$8.99',
							description:	'per month'
						}
					})}
					{this.renderAddOn({
						button: (
							<Switch
								checked		= {this.state.checkedB}
								onChange	= {this.handleCheckedChange('checkedB')}
								value		= 'checkedB'
								classes		= {{ switchBase: classes.switchBase, colorSecondary: classes.toggleButtonSecondary, track: classes.toggleButtonTrack, checked: classes.toggleButtonChecked }}
							/>
						),
						title:		'Premium music on hold',
						disclaimer:	'Our licensed music will create a better experience for your customers and make your business feel bigger.',
						price: {
							value:			'$4.99',
							description:	'per month'
						}
					})}
					{this.renderAddOn({
						button:		<AddIcon classes={{root: classes.addIcon}}/>,
						title:		'Add more numbers',
						disclaimer:	'You can add up to 3 numbers on your account. Numbers can help you seem local in a city.',
						price: {
							value:			'$3.99',
							description:	'per month per number'
						}
					})}
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(AddOns)