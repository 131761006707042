import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A7161
 *
 * @param {SvgIconProps} props - svg props
 */
export const SearchIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M16.6587 18.7029C15.2332 19.6661 13.5124 20.2289 11.6595 20.2289C6.7389 20.2289 2.75 16.2601 2.75 11.3644C2.75 6.46874 6.7389 2.5 11.6595 2.5C16.58 2.5 20.5689 6.46874 20.5689 11.3644C20.5689 13.5632 19.7643 15.575 18.4321 17.1242L20.7476 19.4242C21.0415 19.7161 21.0417 20.1896 20.748 20.4817L20.1314 21.0953C19.8377 21.3874 19.3615 21.3876 19.0676 21.0957L16.6587 18.7029ZM18.1927 11.3647C18.1927 14.9549 15.2675 17.8653 11.6591 17.8653C8.05071 17.8653 5.12551 14.9549 5.12551 11.3647C5.12551 7.77456 8.05071 4.86415 11.6591 4.86415C15.2675 4.86415 18.1927 7.77456 18.1927 11.3647Z' fill={color}/>
        </SvgIcon>
    )
}

export default SearchIcon
