var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"applications"},[_c('Submenu',{key:("" + (_vm.l.detect()) + (_vm.$route.name)),attrs:{"routes":{
     'applications.index': {
        title: _vm.l.t('app.applications', 'Applications'),
        subroutes: ['applications.show']
      },
      'applications.create': {
        title: _vm.l.t('applications.add', 'Add application')
      },
    }}}),_vm._v(" "),(_vm.$route.name === 'applications.index')?_c('ApplicationsFragment',{attrs:{"data-test-id":"apps-index"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'applications.create')?_c('ApplicationsCreateFragment',{attrs:{"data-test-id":"apps-create"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'applications.show')?_c('ApplicationsShowFragment',{attrs:{"data-test-id":"apps-show"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }