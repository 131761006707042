<template>
	<w-loader v-if="api.loading"/>
	<div v-else class="show-page" data-test-id="api-req-show-page">
		<w-alert v-if="api.alert" :message="api.alert.message" :level="api.alert.level" :closable="api.enable_close_alert" @closed="api.alert = null" class="mb-6"/>
		<div v-if="api.request_item" data-test-id="api-req-data">
			<FormInput :label="l.t('app.id', 'ID')" data-test-id="api-req-id">
				{{ api.request_item.id }}
			</FormInput>
			<FormInput :label="l.t('api-requests.request-method', 'Request method')" data-test-id="api-req-medtod">
				{{ api.request_item.request_method }}
			</FormInput>
			<FormInput :label="l.t('api-requests.request-uri', 'Request uri')" data-test-id="api-req-uri">
				{{ api.request_item.request_uri }}
			</FormInput>
			<FormInput :label="l.t('api-requests.request-body', 'Request body')" data-test-id="api-req-body">
				<span v-if="!api.request_item.request_body">—</span>
				<template v-else>
					<p v-if="show_request_body"><pre>{{ JSON.stringify(api.request_item.request_body, null, '\t') }}</pre>
						<a @click.prevent="show_request_body = !show_request_body" data-test-id="api-req-show-less">{{ l.t('app.show-less', 'Show less') }}</a>
					</p>
					<p v-else-if="show_request_body === null"><pre>{{ JSON.stringify(api.request_item.request_body, null, '\t') }}</pre></p>
					<p v-else-if="show_request_body === false"><pre>{{ JSON.stringify(api.request_item.request_body, null, '\t') | show_a_few_json_lines }}</pre>
						<a @click.prevent="show_request_body = !show_request_body" data-test-id="api-req-show-more">{{ l.t('app.show-more', 'Show more') }}</a>
					</p>
				</template>
			</FormInput>
			<FormInput :label="l.t('api-requests.scheduled-at', 'Scheduled at')" data-test-id="api-req-scheduled-at">
				{{ (new Date(api.request_item.scheduled_at * 1000)).toLocaleString() }}
			</FormInput>
			<FormInput :label="l.t('api-requests.notification-policy', 'Notification policy')" data-test-id="api-req-policy]">
				{{ api.request_item.notification_policy | policy }}
			</FormInput>
			<FormInput :label="l.t('api-requests.notification-email', 'Notification email')" data-test-id="api-req-email">
				{{ api.request_item.notification_email | valueOrDash  }}
			</FormInput>
			<FormInput :label="l.t('api-requests.processed-status', 'Processed status')" data-test-id="api-req-status">
				{{ api.request_item.processed_status | valueOrDash }}
			</FormInput>
			<FormInput :label="l.t('api-requests.response-code', 'Response code')" data-test-id="api-req-code">
				{{ api.request_item.response_code | valueOrDash }}
			</FormInput>
			<FormInput :label="l.t('api-requests.response-body', 'Response body')" data-test-id="api-req-res-body">
				<span v-if="!api.request_item.response_body">—</span>
				<template v-else>
					<template v-if="show_response_body">
						<pre>{{ api.request_item.response_body }}</pre>
						<a @click.prevent="show_response_body = !show_response_body" data-test-id="api-req-show-less-res">{{ l.t('app.show-less', 'Show less') }}</a>
					</template>
					<pre v-else-if="show_response_body === null">{{ api.request_item.response_body }}</pre>
					<template v-else-if="show_response_body === false">
						<pre>{{ api.request_item.response_body | show_a_few_json_lines }}</pre>
						<a @click.prevent="show_response_body = !show_response_body" data-test-id="api-req-show-more-res">{{ l.t('app.show-more', 'Show more') }}</a>
					</template>
				</template>
			</FormInput>
			<FormInput :label="l.t('app.comment', 'Comment')" data-test-id="api-req-comment">
				{{ api.request_item.comment | valueOrDash }}
			</FormInput>
		</div>
	</div>
</template>

<script>
import l from '../../../libs/lang';
import FormInput from '../../elements/form/FormInput.vue';

export default {
	props: ['api'],
	components: { FormInput },
	data() {
		return {
			l,
			show_request_body: null,
			show_response_body: null,
		};
	},
	async created() {
		await this.api.get_item(this.$props.id ? this.props.id : this.$route.params.id);
		this.$data.show_request_body = this.isJsonTooLong(this.$props.api.request_item.request_body) ? false : null;
		this.$data.show_response_body = this.$props.api.request_item.response_body && this.$props.api.request_item.response_body.length > 120 ? false : null;
	},
	methods: {
		isJsonTooLong(json) {
			return (JSON.stringify(json, null, '\t')).length > 120;
		}
	},
	filters: {
		valueOrDash(val) {
			return val || '—';
		},
		policy(val) {
			if (val === null) return '—';
			if (val === 1) return l.t('api-requests.only-failed', 'Only if request failed');
			if (val === 2) return l.t('app.always', 'Always');

			return l.t('api-requests.no-notifications', 'No notifications');
		},
		show_a_few_json_lines(stringified) {
			return stringified.split('\n').slice(0, 7).join('\n');
		}
	}
};
</script>
