/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { createContext } from 'react'
import PhoneComUser from 'phone-com-user'
import ajax from 'ajax'
import { getPhoneCom } from 'phonecom'
import PropTypes from 'prop-types'
import { useEnrollmentContext } from '../hooks/useEnrollmentContext'
import { EnrollmentType } from '../interface/EnrollmentType'
import { useAjax } from '../hooks/useAjax'

/**
 *
 */
export const SmsNumberCampaignContext = createContext({
    campaignsWithNumber: [],
    loadingSmsNumberCampaignContext: true,
    numberNetworkError: null,
    networkPending: false,
    eligibleNumbers: [],
    fetchEligibleNumbersStatus: 'idle',

    deleteNumber: (params) => { },
    addNumber: (params) => { },
    setNumberNetworkError: () => { },
    fetchEligibleNumbers: () => { }
})

/**
 * @param {object} root0
 * @param {object} root0.children
 */
export const SmsNumberCampaignContextProvider = ({ children }) => {
    const fetchCampaignsWithNumberUrl = `${PhoneComUser.getv5ApiRoot()}/messages/10dlc/list-phone-numbers`
    const enrollCampaignDidUrl = `${PhoneComUser.getv5ApiRoot()}/messages/10dlc/enroll-campaign-did`

    const getFetchParams = async () => {
        const phonecom = await getPhoneCom()
        const params = { account_id: phonecom.voip_id }
        return params
    }

    const {
        enrollments: campaignsWithNumber,
        deleteEnrollment: deleteNumber,
        addEnrollment: addNumber,
        enrollmentNetworkError: numberNetworkError,
        setEnrollmentNetworkError: setNumberNetworkError,
        crudNetworkPending
    } = useEnrollmentContext({
        enrollmentFetchUrl: fetchCampaignsWithNumberUrl,
        enrollmentAddUrl: enrollCampaignDidUrl,
        enrollmentDeleteUrl: enrollCampaignDidUrl,
        enrollmentEditUrl: '',
        enrollmentPaymentDetailsUrl: '',
        enrollmentType: EnrollmentType.SmsNumber,
        verifiedEnrollmenPredicate: () => {
            return false
        },
        initialFetchParams: getFetchParams()
    })

    const executeFetchNumbersWithoutCampaign = async () => {
        const phonecom = await getPhoneCom()
        const params = {
            account_id: phonecom.voip_id,
            campaign_id: null
        }

        return ajax.post(fetchCampaignsWithNumberUrl, params, 'Bearer')
            .then((rv) => {
                return new Promise((resolve, reject) => {
                    if (rv?.status === 200) {
                        resolve(rv.data)
                    } else {
                        const err = rv.message || rv
                        reject(err)
                    }
                })
            })
    }
    const { execute: fetchEligibleNumbers, value: eligibleNumbers, status: fetchEligibleNumbersStatus } = useAjax(executeFetchNumbersWithoutCampaign, false)

    return (
        <SmsNumberCampaignContext.Provider value={{
            campaignsWithNumber,
            networkPending: crudNetworkPending,
            numberNetworkError,
            fetchEligibleNumbers,
            eligibleNumbers,
            fetchEligibleNumbersStatus,
            deleteNumber,
            addNumber,
            setNumberNetworkError
        }}>
            {children}
        </SmsNumberCampaignContext.Provider>
    )
}

SmsNumberCampaignContextProvider.propTypes = {
    children: PropTypes.any
}
