import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A7225
 *
 * @param {SvgIconProps} props - svg props
 */
export const StarredIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M14.673 9.8L12.2392 1.78752C12.1674 1.55106 11.8326 1.55106 11.7608 1.78752L9.327 9.8H1.78073C1.53747 9.8 1.4375 10.1122 1.63552 10.2535L7.798 14.651L5.4436 22.2552C5.37168 22.4875 5.64011 22.6753 5.8337 22.5282L12 17.841L18.1778 22.5293C18.3714 22.6762 18.6396 22.4884 18.5677 22.2562L16.213 14.651L22.3657 10.2534C22.5635 10.112 22.4635 9.8 22.2203 9.8H14.673Z' fill={color}/>
        </SvgIcon>
    )
}

export default StarredIcon
