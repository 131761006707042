var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"schedules"},[_c('Submenu',{key:("" + (_vm.l.detect()) + (_vm.$route.name)),attrs:{"routes":{
      'schedules.index': {
        title: _vm.l.t('app.schedules', 'Schedules'),
        subroutes: ['schedules.show']
        },
      'schedules.create': {
        title: _vm.l.t('schedules.add-schedules', 'Add schedules')
        },
    }}}),_vm._v(" "),(_vm.$route.name === 'schedules.index')?_c('SchedulesFragment',{attrs:{"data-test-id":"schedules-index"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'schedules.create')?_c('SchedulesCreateFragment',{attrs:{"data-test-id":"schedules-create"}}):_vm._e(),_vm._v(" "),(_vm.$route.name === 'schedules.show')?_c('SchedulesShowFragment',{attrs:{"data-test-id":"schedules-show"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }