import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VScrollYTransition } from 'vuetify/lib/components/transitions';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VItemGroup,{class:_vm.$props.root_class || 'd-flex justify-space-between'},_vm._l((_vm.items),function(item,i){return _c(VItem,{key:("tab-as-image-" + i),staticClass:"cursor-pointer",attrs:{"data-test-id":("tab-as-image-tab-" + (item.value))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c(VScrollYTransition,[_c('div',{class:{'border-right' : (i < (_vm.items.length-1))}},[_c('div',{staticClass:"text-center aligment mx-1",on:{"click":function($event){return _vm.tab_changed(item.value, toggle)}}},[_c(VIcon,{staticClass:"ml-2 mt-n1 pr-1",attrs:{"size":"30","large":"","color":(_vm.value === item.value || (!_vm.value && !item.value)) ? '#000' : 'primary',"data-test-id":("tab-as-image-tab-" + (item.pattern) + "-icon")}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(item.icon)+"\n\t\t\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"tab-title mr-2 ml-1 my-auto",class:(_vm.value === item.value || (!_vm.value && !item.value)) ? 'font-text' : 'font-primary',attrs:{"data-test-id":("tab-as-image-tab-" + (item.value) + "-title")}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(item.text)+"\n\t\t\t\t\t")])],1)])])]}}],null,true)})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }