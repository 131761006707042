import l from '../lang';

export default {
	'routes': {
		'create': 'trunks.create',
		'show': 'trunks.show',
		'index': 'trunks.index',
	},
	'delete-single': l.t('trunks.delete-single', 'Are you sure you want to delete this trunk?'),
	'delete-filtered': l.t('trunks.delete-filtered', 'Are you sure you want to delete all filtered trunks?'),
	'delete-all': l.t('trunks.delete-all', 'Are you sure you want to delete all trunks?'),
	'successfull-creation': l.t('trunks.successfully-created', 'Your trunk has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('trunks.failed-creation', 'There was a problem creating your trunk'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
	'currently-in-use': l.t('trunks.this-trunk-is-currently-in-use', 'This trunk is currently in use'),
	'to-delete-used': l.t('trunks.to-delete-this-trunk-remove-it', 'To delete this trunk, remove it from your call handling rules first, then try again.'),
};
