<template>
	<div>
		<SectionTitle data-test-id="media-loader-search-title">{{ l.t('media.loader.search-title', 'Search media') }}</SectionTitle>
		<w-form @submit.prevent="$emit('submitted')">
			<FormInput :label="l.t('app.pattern', 'Pattern')">
				<w-select
					v-model="media.filters.pattern"
					:items="[
						{value: 'contains', text: l.t('app.contains', 'Contains')},
						{value: 'starts-with', text: l.t('app.starts-with', 'Starts with')},
						{value: 'ends-with', text: l.t('app.ends-with', 'Ends with')},
						{value: 'all', text: l.t('media-loader.return all media', 'Return all media')},
					]"
					hide-details="auto"
					data-test-id="media-loader-search-pattern-select"
				/>
			</FormInput>
			<FormInput v-if="media.filters.pattern !== 'all'" :label="l.t('app.name', 'Name')">
				<w-text-field v-model="media.filters.name" hide-details='auto' data-test-id="media-loader-search-name-input"/>
			</FormInput>
			<div class="d-flex justify-space-between mt-3">
				<w-btn @click="media.back_to_main_form()" id="back" type="button" data-test-id="media-loader-search-back-btn">
					{{ l.t('app.back', 'Back') }}
				</w-btn>
				<w-btn :disabled="!media.filters.name && media.filters.pattern!=='all'" type="submit"  color="primary" data-test-id="media-loader-search-continue-btn">
					{{ l.t('app.continue', 'Continue') }}
				</w-btn>
			</div>
			<w-btn @click="media.screen = 'show-import-csv-form'" type="button" block color="info" class="mt-3" data-test-id="media-loader-search-import-csv-btn">
				{{ l.t('media-loader.import-csv', 'Or import list of media from .csv file') }}
			</w-btn>
		</w-form>
	</div>
</template>

<script>
	import l from '../../../libs/lang';
	import FormInput from '../../elements/form/FormInput.vue';
	import SectionTitle from '../../elements/SectionTitle.vue';

	export default {
		props: ['media'],
		components: { FormInput, SectionTitle, },
		data() {
			return {
				l,
			};
		},
	};
</script>
