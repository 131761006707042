import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import AppContextProvider from '../../contexts/AppContext'
import { RouterProps } from '../../utils/objects'
import GoBack from '../../components/GoBack'
import Header from './components/Header'
import Content from './components/Content'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    detailsContainer: {
        padding: '3rem',
        [theme.breakpoints.down('lg')]: {
            padding: '1.5rem 3rem'
        }
    }
}))

interface DetailsInterface {
  routeProps: RouterProps
}

/**
 *
 */
const Index = (props: DetailsInterface) => {
    const classes = useStyles()
    const slug = props.routeProps?.match.params.appName || ''

    const renderAppDetails = () => {
        return (
            <Box className={classes.root}>
                <GoBack title={'App details'} />
                <Box className={classes.detailsContainer}>
                    <Header />
                    <Content />
                </Box>
            </Box>
        )
    }

    return (
        <AppContextProvider slug={slug}>
            {renderAppDetails()}
        </AppContextProvider>
    )
}

export default Index
