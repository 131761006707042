import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5666%3A113
 *
 * @param {object} props - svg props
 */
export const UsersTwoIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M16.6758 11.6017C18.6262 11.5066 20.0384 9.1026 19.9163 6.61432C19.7942 4.12605 18.137 2.28277 15.9826 2.38782C13.8283 2.49287 12.4063 4.27681 12.5284 6.76508C12.6505 9.25335 14.7254 11.6968 16.6758 11.6017ZM13.5137 11.6506C13.6228 11.5777 13.7138 11.5168 13.7879 11.4825C14.1943 13.0538 15.7424 14.8652 17.3286 14.7636C18.5 14.6885 18.8599 13.7815 18.748 11.8383C18.8768 11.9018 18.903 11.9674 18.9312 12.0379C18.9589 12.1072 18.9885 12.1813 19.1192 12.2627C19.5749 12.5019 19.9723 12.65 20.3227 12.7805C21.6696 13.2824 22.3223 13.5256 22.9249 17.6937C23.0493 18.5547 22.7895 19.4609 22.0856 19.9722C18.5829 22.5165 13.4686 20.8292 10.0796 19.7111C9.79551 19.6174 9.52356 19.5277 9.2657 19.4448C8.35241 19.1512 7.65995 18.3095 7.71734 17.3519C7.84676 15.1925 8.28563 14.1218 9.08364 13.1934C9.83864 12.315 10.7876 12.2839 11.6099 12.2569C11.7529 12.2522 11.892 12.2477 12.0256 12.2388C12.7016 12.1939 13.188 11.8685 13.5137 11.6506Z' fill={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M9.52609 6.30005C9.61309 8.0738 8.60646 9.78744 7.21613 9.85523C5.8258 9.92303 4.3467 8.18126 4.25969 6.40752C4.17268 4.63377 5.18629 3.36211 6.72201 3.28723C8.25773 3.21234 9.43908 4.52631 9.52609 6.30005ZM4.9623 9.89028L4.96229 9.89028C4.73011 10.0456 4.38335 10.2776 3.9015 10.3095C3.80625 10.3159 3.70708 10.3191 3.60518 10.3225H3.60517C3.01897 10.3417 2.34252 10.3639 1.80433 10.99C1.19374 11.7004 0.878107 12.5276 0.81362 14.306C0.796239 14.7853 1.13945 15.2004 1.59816 15.3406C1.87647 15.4257 2.18387 15.5271 2.51428 15.6361C3.63394 16.0055 5.01779 16.4621 6.42902 16.6651C6.71881 16.0574 6.93817 15.5632 7.11858 15.1568C7.70545 13.8345 7.88016 13.4409 8.72694 13.091C9.67624 12.6987 10.6481 12.7296 11.4397 12.8793C11.0634 11.1605 10.6081 10.9909 9.81597 10.6957C9.5662 10.6027 9.28293 10.4971 8.95809 10.3266C8.86493 10.2686 8.84383 10.2158 8.82407 10.1664C8.80397 10.1161 8.78527 10.0693 8.69347 10.024C8.77325 11.4092 8.51667 12.0558 7.68165 12.1093C6.55094 12.1818 5.4474 10.8905 5.15773 9.77045C5.10492 9.79488 5.04003 9.83828 4.9623 9.89028Z' fill={color}/>
        </SvgIcon>
    )
}

export default UsersTwoIcon
