import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from 'back-button'
import { Elements, StripeProvider } from 'react-stripe-elements'
import CreditCardForm from './CreditCardForm'
import CreditCardCurrentPrimary from './CreditCardCurrentPrimary'
import Spinner from 'spinner'
import { updateCurrentPrimaryCard } from '../../actions/settings'
import api from '../../util/api_v5'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	header:			theme.settingsApp.header,
	settingWrapper:	theme.settingsApp.settingWrapper,
	mainWrapper: {
		maxHeight: '100%',
		display: 'flex',
		flexDirection: 'column',
		'&:not(.small-view)': {
			padding: '10px 20px',
			maxWidth: 340,
			boxShadow: '0px 0px 6px gray'
		}
	},
	spinnerWrapper: {
		textAlign: 'center',
		padding: '20px 0px 20px 0px',
	},
	buttonWrapper: {
		textAlign: 'center'
	},
	errorMessage: {
		color:			'#df1d08',
		margin:			'30px 45px 0',
		fontSize:		14,
		fontWeight:		'bold',
		lineHeight:		1.29,
		letterSpacing:	-0.1,
		textAlign:		'center'
	},
	updateButton: {
		color:	'#1140A1',
		border:	'1px solid #1140A1',
		'&:hover': {
			color:				'white',
			backgroundColor:	'#1140A1',
			borderColor:		'#1140A1'
		},
		'&:focus': {
			outline: 0
		}
	}
})

const mapStateToProps = state => ({
	currentPrimaryCard: state.currentPrimaryCard
})
const mapDispatchToProps = dispatch => ({
	updateCurrentPrimaryCard: card => dispatch(updateCurrentPrimaryCard(card))
})

class CreditCard extends Component {

	state = {
		errorMessage:	'',
		complete:		false,
		loading:		false
	}

	componentDidMount() {
		this._mounted = true
		this.getCurrentPrimaryCard()
	}

	componentWillUnmount() {
		this._mounted = false
	}

	getCurrentPrimaryCard = async () => {
		if (this.props.currentPrimaryCard) return
		this.setState({loading: true})
		let response = await api.getCurrentPrimaryCard() // TODO check response
		if (!this._mounted) return
		this.setState({loading: false})
		this.props.updateCurrentPrimaryCard(response || null)
	}

	onSuccess = () => this.setState({complete: true})

	reset = () => {
		this.setState({
			errorMessage: '',
			complete: false,
		})
		this.getCurrentPrimaryCard()
	}

	render() {
		const { classes } = this.props

		let innerContent
		if (this.state.loading) {
			innerContent = <div className={`${classes.spinnerWrapper}`}><Spinner/></div>
		} else if (!this.state.complete) {
			innerContent = <div>
				<CreditCardCurrentPrimary card={this.props.currentPrimaryCard || {}} loading={this.state.loading}/>
				<Elements>
					<CreditCardForm onSuccess={this.onSuccess} />
				</Elements>
			</div>
		} else {
			innerContent = <div>
				<p>Your card has been updated successfully</p>
				<div className={`${classes.buttonWrapper}`}>
					<Button
						onClick={this.reset}
						variant='outlined'
						classes={{root: classes.updateButton}}
					>OK</Button>
				</div>
			</div>
		}
		let backButton = this.props.smallView ? <BackButton onClick={this.props.goBack}/> : ''
		return (
			<>
				<div className={classes.header}>
					<div>
						{backButton}
						<span className='header-title'>Credit card</span>
					</div>
				</div>
				<div className={`${classes.settingWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
					<div className={`${classes.mainWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
						<StripeProvider apiKey={window.APP_CONFIG.stripePublicKey}>
							{innerContent}
						</StripeProvider>
					</div>
				</div>
			</>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CreditCard))