import React from 'react'
import Typography from 'typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    tabDetailsTitle: {
        fontWeight: '600!important',
        marginBottom: '.5rem'
    }
}))

/**
 *
 */
const RatingsTab = () => {
    const classes = useStyles()

    return (
        <Typography variant='h5' className={classes.tabDetailsTitle}>
            Ratings and Reviews...
        </Typography>
    )
}

export default RatingsTab
