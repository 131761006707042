import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link:
 *
 * @param {SvgIconProps} props - svg props
 */
export const SmsEnrollmentIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d="M5.9008 9.67333C6.24426 9.67333 6.53114 9.55716 6.76146 9.32481C6.99177 9.09247 7.10693 8.80458 7.10693 8.46113C7.10693 8.11767 6.99076 7.83079 6.75842 7.60047C6.52608 7.37015 6.23818 7.25499 5.89473 7.25499C5.55128 7.25499 5.26439 7.37116 5.03408 7.60351C4.80376 7.83585 4.6886 8.12374 4.6886 8.46719C4.6886 8.81065 4.80477 9.09753 5.03711 9.32785C5.26945 9.55817 5.55735 9.67333 5.9008 9.67333ZM10.5647 9.67333C10.9082 9.67333 11.1951 9.55716 11.4254 9.32481C11.6557 9.09247 11.7709 8.80458 11.7709 8.46113C11.7709 8.11767 11.6547 7.83079 11.4223 7.60047C11.19 7.37015 10.9021 7.25499 10.5587 7.25499C10.2152 7.25499 9.92832 7.37116 9.698 7.60351C9.46769 7.83585 9.35253 8.12374 9.35253 8.46719C9.35253 8.81065 9.4687 9.09753 9.70104 9.32785C9.93338 9.55817 10.2213 9.67333 10.5647 9.67333ZM15.0559 9.67333C15.3994 9.67333 15.6863 9.55716 15.9166 9.32481C16.1469 9.09247 16.262 8.80458 16.262 8.46113C16.262 8.11767 16.1459 7.83079 15.9135 7.60047C15.6812 7.37015 15.3933 7.25499 15.0498 7.25499C14.7064 7.25499 14.4195 7.37116 14.1892 7.60351C13.9589 7.83585 13.8437 8.12374 13.8437 8.46719C13.8437 8.81065 13.9599 9.09753 14.1922 9.32785C14.4246 9.55817 14.7125 9.67333 15.0559 9.67333ZM0 21V2.3443C0 1.71154 0.225999 1.16263 0.677997 0.69759C1.12999 0.23253 1.6772 0 2.31962 0H18.6557C19.2885 0 19.8374 0.23253 20.3024 0.69759C20.7675 1.16263 21 1.71154 21 2.3443V14.7321C21 15.3581 20.7675 15.9011 20.3024 16.3614C19.8374 16.8216 19.2885 17.0517 18.6557 17.0517H3.9483L0 21ZM2.31962 15.9166L3.50411 14.7321H18.6557V2.3443H2.31962V15.9166Z" fill={color}/>
        </SvgIcon>
    )
}

export default SmsEnrollmentIcon
