import l from '../lang';

export default {
	'routes': {
		'create': 'conversations.create',
		'show': 'conversations.show',
		'index': 'conversations.index',
	},
	'delete-single': l.t('conversations.delete-single', 'Are you sure you want to delete this conversation?'),
	'delete-filtered': l.t('conversations.delete-filtered', 'Are you sure you want to delete all filtered conversations?'),
	'delete-all': l.t('conversations.delete-all', 'Are you sure you want to delete all conversations?'),
	'successfull-creation': l.t('conversations.successfully-created', 'Your conversation has been added'),
	'successfull-update': l.t('app.successful-update', 'Your changes have been saved'),
	'failed-creation': l.t('conversations.failed-creation', 'There was a problem creating your conversation'),
	'failed-update': l.t('app.update-error', 'An error occurred while attempting to save your changes. Please try again.'),
};
