<template>
    <div class="payment-methods list-page">
      <w-loader v-if="p_method.loading"/>
      <div v-else>
          <w-alert
              v-if="p_method.alert"
              :level="p_method.alert.level"
              :message="p_method.alert.message"
              class="mb-5"
          />
          <div
              v-if="!p_method.items.length"
              class="text-center no-card-container"
          >
              <div class="w-body-2 mb-8">
                  {{ $lang.t('p-methods.you-currently-have-no-credit-card', 'You currently have no credit card or debit card on file.') }}
              </div>
              <w-link
                  :to="{name: 'billing.payment-methods.create'}"
                  class="bg-primary white--text"
                  tag="w-btn"
              >
                      <v-icon
                          size="18"
                          class="mr-1"
                      >
                          $vuetify.icons.add
                      </v-icon>
                      {{ $lang.t('p-methods.add-payment-method', 'Add payment method') }}
              </w-link>
          </div>
          <div v-else class="payment-methods-wrapper mx-auto">
              <div v-for="(card, i) of p_method.items" class="mb-10" :key="card.id">
                  <CreditCard :card="card"/>
                  <div class="mt-2 d-flex justify-space-between w-caption text--text text--lighten-1 credit-card-actions">
                          <v-radio
                              :off-icon="primary_cc_id === card.id ? '$radioOn' : '$radioOff'"
                              color="text lighten-1"
                              :value="card.id"
                              :disabled="p_method.processing_status"
                              @click="ask_for_confirmation(card.id)"
                          >
                              <template v-slot:label>
                                  <div class="text--text">{{$lang.t('p.m.primary-payment-method', 'Primary payment method')}}</div>
                              </template>
                          </v-radio>
                          <span
                              v-if="card.status !== 'primary' && p_method.items.length > 1" @click="delete_item = card.id"
                              class="my-auto"
                          >
                              <v-icon size="13">$vuetify.icons.close</v-icon>
                              <span class="action-text">{{ $lang.t('app.remove', 'Remove') }}</span>
                          </span>
                      </div>
                  </div>
                  <w-link tag="w-btn" :to="{name: 'billing.payment-methods.create'}" text color="primary" class="pl-0 bg-white primary--text text--darken-1">
                      <v-icon size="18" class="mr-1">$vuetify.icons.add_circle</v-icon> {{$lang.t('payment-methods.add-payment-method', 'Add payment method')}}
                  </w-link>
              <DefaultModal
                  v-model="confirm"
                  max-width="350px"
                  :with_x="false"
                  class="confirmation-modal"
              >
                  <div class="w-body-1">
                      {{$lang.t('payment-methods.are-you-sure-youd-like-to-delete-this-card-we-have-on-file', 'Are you sure you\'d like to delete this card we have on file?')}}
                  </div>
                  <template v-slot:buttons>
                      <w-btn
                          color="secondary"
                          class="mr-5"
                          @click="confirm = false"
                      >
                          {{ $lang.t('app.cancel', 'Cancel') }}
                      </w-btn>
                      <w-btn
                          color="error"
                          @click="delete_method"
                      >
                          {{ $lang.t('app.yes', 'Yes') }}
                      </w-btn>
                  </template>
              </DefaultModal>
              <DefaultModal v-model="primary_change_confirmation" max-width="350" @close="primary_change_confirmation = false">
                  {{$lang.t('p-methods.you-are-changing-primary', 'You’re changing your primary payment method. Would you like to continue?')}}
                  <template v-slot:buttons>
                      <w-btn @click="primary_change_confirmation = false" color="secondary">{{$lang.t('app.cancel', 'Cancel')}}</w-btn>
                      <w-btn @click="change_primary_method" color="primary">{{$lang.t('app.yes', 'Yes')}}</w-btn>
                  </template>
              </DefaultModal>
          </div>
      </div>
    </div>
  </template>
  
  <script>
      import {vueComponent} from 'helpers';
      import PaymentMethod from 'console/src/models/PaymentMethod';
      import InfoLink from 'console/src/components/elements/InfoLink.vue';
      import CreditCard from 'console/src/components/elements/CreditCard.vue';
      import DefaultModal from 'console/src/components/elements/modal/DefaultModal.vue';
  
    export default {
      components: {
          InfoLink,
          CreditCard,
          DefaultModal,
      },
      data() {
        return {
          delete_item: null,
          confirm: false,
          p_method: new PaymentMethod(this.$session, vueComponent(this)),
          primary_cc_id: null,
          primary_change_confirmation: false,
          future_primary_cc: null,
        };
      },
      async created() {
          await this.$data.p_method.loadItems();
          const primary = this.$data.p_method.items.find((x) => x.status === 'primary');
          if (primary) this.$data.primary_cc_id = primary.id;
      },
      methods: {
          ask_for_confirmation(card_id) {
              if (
                  this.$data.primary_change_confirmation
                  || this.$data.primary_cc_id === card_id
              ) {
                  return true;
              }
              this.$data.future_primary_cc = card_id;
              this.$data.primary_change_confirmation = true;
  
              return true;
          },
          async change_primary_method() {
              const id = this.$data.future_primary_cc;
              this.$data.primary_change_confirmation = false;
  
              if (this.$data.primary_cc_id === id) return true;
              const original_cc_id = this.$data.primary_cc_id;
              this.$data.primary_cc_id = id;
  
              await this.p_method.set_status(id, 'primary');
              if (this.$data.p_method.alert && this.$data.p_method.alert.level === 'error') {
                  this.$data.primary_cc_id = original_cc_id;
              }
  
              return true;
          },
          async delete_method() {
              await this.$data.p_method.delete_item(this.$data.delete_item);
              this.$data.confirm = false;
              this.$data.delete_item = false;
          }
      },
      watch: {
              delete_item(val) {
                  if (val) this.$data.confirm = true;
              },
              confirm(val) {
                  if (!val) {
                      this.$data.delete_item = null;
                  }
              },
              primary_change_confirmation(val) {
                  if (!val) {
                      this.$data.future_primary_cc = null;
                  }
              }
      }
    };
  </script>
  
  <style scoped lang="scss">
      .payment-methods-wrapper {
          width: 350px;
          margin-top: 50px;
          .credit-card-actions {
              span, .v-icon svg {
                  color: var(--v-secondary-darken1);
              }
              &:hover {
                  // color: var(--v-secondary-darken4);
                  span, .v-icon svg {
                      color: var(--v-secondary-darken4);
  
                  }
              }
              span {
                  cursor: pointer;
              }
              .action-text{
                  margin-top: auto !important;
                  margin-bottom: auto !important;
              }
              .v-icon {
                  margin-right: 3px;
                  margin-top: -1px;
              }
          }
      }
      .no-card-container {
          margin-top: 38.5vh;
      }
  </style>
  