/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react'
import FaxSelectorItem from './FaxSelectorItem.js'
import NoSetup from 'no-setup'
import { connect } from 'react-redux'
import api from '../util/api_v5'
import { withStyles } from '@material-ui/core'
import { updateFax, updateFaxes, switchFax } from '../actions/faxes'
import { switchView } from '../actions/view.js'
import { FaxReceivedIcon, FaxSentIcon } from 'pdc-svg-icons'
import { LabelCountIcon } from 'pdc-svg-icons'
import LoaderFull from 'loader-full'
import { theme } from 'get-theme'

import ScrollerWrapper from 'scroller-wrapper'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

const mapStateToProps = (state) => ({
    faxes: state.faxes,
    // currentFax:    state.currentFax,
    smallView: state.smallView
})

const mapDispatchToProps = (dispatch) => ({
    updateFax: (fax) => dispatch(updateFax(fax)),
    updateFaxes: (faxes) => dispatch(updateFaxes(faxes)),
    switchFax: (fax) => dispatch(switchFax(fax)),
    switchView: (view) => dispatch(switchView(view))
})

const styles = (theme) => ({
    selectorWrapper: {
        boxShadow: '0 0 0 1px #e0e0e0',
        position: 'relative',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 0
    },
    newFaxInfoWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '60px',
        display: 'flex',
        zIndex: 2,
        background: 'linear-gradient(gray, transparent)',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'top .25s',
        '&>span': {
            fontWeight: 'bold',
            color: 'green',
            background: 'rgba(255,255,255,0.7)',
            padding: '3px 10px',
            border: '2px solid green',
            borderRadius: '17px'
        },
        '&.disappear': {
            top: '-60px'
        }
    },
    noFaxMessage: {
        marginTop: '15px',
        padding: '0 10px',
        display: 'inline-block',
        width: '100%',
        height: '100%',
        verticalAlign: 'middle',
        textAlign: 'center',
        '&>span': {
            color: '#b6c0c5',
            fontWeight: 'bold',
            lineHeight: 1.29
        }
    },
    tabsWrapper: {
        padding: 5,
        paddingBottom: 0,
        width: '100%'
    },
    tabs: {
        display: 'flex',
        width: '100%',
        borderRadius: 5,
        paddingBottom: 5,
        position: 'relative',
        '& .tab': {
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: 1.36,
            textAlign: 'center',
            padding: '8px 10px 3px',
            borderRadius: 5,
            flex: 1,
            zIndex: 1,
            cursor: 'pointer',
            background: 'white',
            position: 'relative',
            '&.selected': {
                background: theme.faxesApp.tabs.firstTabColor,
                color: 'white',
                cursor: 'default',
                '&.gray': {
                    background: theme.faxesApp.tabs.secondTabColor
                }
            },
            '& > svg': {
                width: 18,
                height: 18,
                position: 'absolute',
                left: 15
            },
            '& .label-count-icon-wrapper': {
                position: 'absolute',
                top: 0,
                right: 0,
                display: 'flex',
                '& .unread-text': {
                    position: 'absolute',
                    right: '0%',
                    fontSize: 10,
                    fontWeight: 'bold',
                    lineHeight: 1.1,
                    marginRight: 6,
                    marginTop: 3,
                    '&.white-color': {
                        color: 'white'
                    }
                },
                '& > svg': {
                    position: 'absolute',
                    right: 1,
                    width: 29,
                    height: 'initial'
                }
            },
            '&:not(.selected) .label-count-icon-wrapper': {
                // display:    'none'
            }
        }
    },
    tabsBigPlaceholder: {
        height: 20,
        position: 'absolute',
        bottom: 0,
        borderRadius: 5,
        width: '60%',
        '&.move-left': {
            background: theme.faxesApp.tabs.firstTabColor,
            left: 0
        },
        '&.move-right': {
            background: theme.faxesApp.tabs.secondTabColor,
            right: 0
        }
    },
    tabsSmallPlaceholder: {
        position: 'absolute',
        bottom: 0,
        width: '90%',
        height: 5,
        borderRadius: 5,
        '&.move-left': {
            background: theme.faxesApp.tabs.secondTabColor,
            right: '10%'
        },
        '&.move-right': {
            background: theme.faxesApp.tabs.firstTabColor,
            left: '10%'
        }
    },
    scrollerWrapper: {
        width: '100%',
        height: '100%',
        minHeight: 0
    },
    loadingDiv: theme.loadingDiv
})

const firstTab = theme.faxesApp.tabs.firstTabName
const secondTab = theme.faxesApp.tabs.secondTabName
class FaxSelector extends Component {
    state = {
        newFax: false,
        // selectedTab: null,
        loadingFaxes: false
    }

    lastNewFax = null

    componentDidUpdate (prevProps) {
        if (this.props.newFax !== null && this.props.newFax !== this.lastNewFax) {
            this.lastNewFax = this.props.newFax
            this.props.setNoNewFax()
            this.setState({ newFax: true })
            setTimeout(() => {
                this.setState({ newFax: false })
            }, 2000)
        }

        if (prevProps.currentTab && prevProps.currentTab !== this.props.currentTab) {
            this.setState({ selectedTab: this.props.currentTab })
        }

        const currentFax =
            this.props.faxes['fax-incoming'].items.find((f) => f.id === this.props.currentFaxId) ||
            this.props.faxes['fax-outgoing'].items.find((f) => f.id === this.props.currentFaxId) || null

        if (
            this.props.currentFaxId &&
            (!prevProps.currentFaxId || prevProps.currentFaxId !== this.props.currentFaxId) &&
            currentFax && currentFax.is_new &&
            !currentFax.justSent &&
            !currentFax.dontMarkRead
        ) {
            this.props.changeReadStatus(currentFax, true)
        }

        if (
            this.props.deletedIncomingFaxes.num &&
            this.props.deletedIncomingFaxes.randomString !== prevProps.deletedIncomingFaxes.randomString
        ) { this.loadMore('fax-incoming', this.props.deletedIncomingFaxes.num) }

        if (
            this.props.deletedOutgoingFaxes.num &&
            this.props.deletedOutgoingFaxes.randomString !== prevProps.deletedOutgoingFaxes.randomString
        ) { this.loadMore('fax-outgoing', this.props.deletedOutgoingFaxes.num) }
    }

    isActive = (fax) => {
        const { currentFaxId } = this.props
        return fax.id === currentFaxId
    }

    onFaxClick = (fax) => {
        delete fax.dontMarkRead
        const direction = fax.direction === 'out' ? 'sent' : 'received'
        this.props.routerProps.history.push(`${this.props.routerProps.match.path}/${direction}/${fax.id}`)
        // this.props.updateFax(fax)
        // this.props.switchFax(fax)
        // this.props.switchView('content')
        // if (fax.is_new && this.props.currentFax && fax.id === this.props.currentFax.id && !fax.justSent)
        if (fax.is_new && !fax.justSent) this.props.changeReadStatus(fax, true)
    }

    // faxType can be 'fax-incoming' or 'fax-outgoing'
    loadMore = async (faxType, limit = 15) => {
        if (this.state.loadingFaxes) return
        const faxes = this.props.faxes[faxType]
        const offset = faxes.items.length
        this.setState({ loadingFaxes: true })
        const response = await api.loadFaxes(offset, limit, { message_type: faxType })
        if (response === 'network-error') return
        faxes.items.push(...response.items)
        response.items = faxes.items
        this.props.updateFaxes(response)
        this.setState({ loadingFaxes: false })

        // Load the new storred contacts of each newly-loaded fax and store them in redux
        this.props.loadExtraContacts()
    }

    onTabChange = (tabName) => {
        // this should just history.push() to stack and router props do rest.
        this.props.switchTab(tabName)

        // this.setState({ selectedTab: tabName })
        // if (this.props.smallView) return
        // let faxType = tabName === "received" ? "fax-incoming" : "fax-outgoing"
        // let faxItems = this.props.faxes[faxType].items
        // let fax = faxItems.length ? faxItems[0] : null
        // if (fax) fax.dontMarkRead = true
        // this.props.switchFax(fax)
    }

    renderTab = (tabName) => {
        const faxes = this.props.faxes
        const selectedTab = this.props.currentTab || theme.faxesApp.tabs.defaultTab
        const faxType = tabName === 'received' ? 'fax-incoming' : 'fax-outgoing'
        let count = 0
        if (tabName === 'received') count = faxes[faxType].unread
        else {
            faxes[faxType].items.forEach((f) =>
                f.justSent || (f.delivery_status === 'failed' && f.is_new) ? count++ : null
            )
        }
        const isTabSelected = selectedTab === tabName
        const selectedClass = isTabSelected ? 'selected' : ''
        const grayClass = tabName === secondTab ? 'gray' : ''
        const showFlag = !isTabSelected && tabName === 'sent'
        return (
            <div className={`tab ${selectedClass} ${grayClass}`} onClick={() => this.onTabChange(tabName)}>
                {selectedTab === tabName ? tabName === 'received' ? <FaxReceivedIcon /> : <FaxSentIcon /> : null}
                <span>{tabName === 'received' ? 'Received' : 'Sent'}</span>
                {faxes[faxType] && (tabName === 'received' || count)
                    ? (
                        <div className="label-count-icon-wrapper">
                            <LabelCountIcon type={showFlag ? 'second' : 'transparent'} />
                            <span className={`unread-text ${showFlag ? 'white-color' : ''}`}>{count || ''}</span>
                        </div>
                    )
                    : null}
            </div>
        )
    }

    scrollerUpdateFaxes = (faxItems, faxType) => {
        const faxes = this.props.faxes[faxType]
        faxes.items = faxItems
        this.props.updateFaxes(faxes)
    }

    renderFaxItems = (direction) => {
        const { classes } = this.props
        const selectedTab = this.props.currentTab || theme.faxesApp.tabs.defaultTab
        const incomingFaxes = this.props.faxes['fax-incoming']
        const outgoingFaxes = this.props.faxes['fax-outgoing']
        const incomingFaxItems = incomingFaxes.items || []
        const outgoingFaxItems = outgoingFaxes.items || []
        const hasMoreIncoming = incomingFaxItems.length ? incomingFaxes.total - incomingFaxItems.length > 0 : false
        const hasMoreOutgoing = outgoingFaxItems.length ? outgoingFaxes.total - outgoingFaxItems.length > 0 : false
        const faxType = direction === 'in' ? 'fax-incoming' : 'fax-outgoing'
        const faxItems = direction === 'in' ? incomingFaxItems : outgoingFaxItems
        return (
            <div
                className={`${classes.scrollerWrapper} ${
                    selectedTab !== (direction === 'in' ? 'received' : 'sent') ? 'hidden' : ''
                }`}
            >
                <ScrollerWrapper
                    reverseScroll={false}
                    loadMore={() => this.loadMore(faxType)}
                    hasMore={direction === 'in' ? hasMoreIncoming : hasMoreOutgoing}
                    data-test-id={`infinite-scroller-${faxType}`}
                    items={this.props.faxes[faxType].items}
                    updateItems={(faxItems) => this.scrollerUpdateFaxes(faxItems, faxType)}
                    smallView={this.props.smallView}
                >
                    {faxItems.map((fax) => (
                        <FaxSelectorItem
                            key={fax.id}
                            fax={fax}
                            onClick={() => this.onFaxClick(fax)}
                            active={this.isActive(fax)}
                            screenViewType={this.props.screenViewType}
                            deleteFax={this.props.deleteFax}
                            changeReadStatus={this.props.changeReadStatus}
                            extraContacts={this.props.extraContacts}
                            placeholderHeight={fax.placeholderHeight}
                            dontShow={fax.dontShow}
                        />
                    ))}
                </ScrollerWrapper>
            </div>
        )
    }

    render () {
        const { classes } = this.props
        const incomingFaxes = this.props.faxes['fax-incoming']
        const outgoingFaxes = this.props.faxes['fax-outgoing']
        const incomingFaxItems = incomingFaxes.items || []
        const outgoingFaxItems = outgoingFaxes.items || []
        const selectedTab = this.props.currentTab || theme.faxesApp.tabs.defaultTab
        console.log(this.props.routerProps.match.path)
        return (
            <div className={classes.selectorWrapper}>
                {incomingFaxItems.length
                    ? (
                        <div className={`${classes.newFaxInfoWrapper} ${this.state.newFax ? '' : 'disappear'}`}>
                            <span>New Fax Received</span>
                        </div>
                    )
                    : null}
                <div className={classes.tabsWrapper}>
                    <div className={classes.tabs}>
                        {this.renderTab(firstTab)}
                        {this.renderTab(secondTab)}
                        <div
                            className={`${classes.tabsBigPlaceholder} ${
                                selectedTab === secondTab ? 'move-right' : 'move-left'
                            }`}
                        ></div>
                        <div
                            className={`${classes.tabsSmallPlaceholder} ${
                                selectedTab === secondTab ? 'move-left' : 'move-right'
                            }`}
                        ></div>
                    </div>
                </div>
                <Switch>
                    <Route
                        path={[
                            `${this.props.routerProps.match.path}/sent/*`,
                            `${this.props.routerProps.match.path}/sent`
                        ]}
                        render={(props) => {
                            console.log('route change')
                            // return this.renderTab(secondTab)
                            return outgoingFaxItems.length
                                ? (
                                    this.renderFaxItems('out')
                                )
                                : (
                                    <div className={`${classes.noFaxMessage} ${selectedTab !== 'sent' ? 'hidden' : ''}`}>
                                        <span>{theme.faxesApp.noSentFaxMessage}</span>
                                    </div>
                                )
                        }}
                    />
                    <Route
                        path={[
                            `${this.props.routerProps.match.path}/received/*`,
                            `${this.props.routerProps.match.path}/received`
                        ]}
                        render={(props) => {
                            console.log('route change to received')
                            return incomingFaxItems.length
                                ? (
                                    this.renderFaxItems('in')
                                )
                                : this.props.isFaxConfigured === null
                                    ? (
                                        <div className={classes.loadingDiv}>
                                            <LoaderFull />
                                        </div>
                                    )
                                    : this.props.isFaxConfigured
                                        ? (
                                            <div
                                                className={`${classes.noFaxMessage} ${selectedTab !== 'received' ? 'hidden' : ''}`}
                                            >
                                                <span>You haven't received any faxes</span>
                                            </div>
                                        )
                                        : selectedTab === 'received'
                                            ? (
                                                <NoSetup type="faxes" />
                                            )
                                            : null
                        }}
                    />

                    {/* fallback route for when no tab selected */}
                    <Route
                        path={[`${this.props.routerProps.match.path}/*`]}
                        render={(props) => {
                            const selectedTab = theme.faxesApp.tabs.defaultTab
                            const items = selectedTab === 'received' ? incomingFaxItems : outgoingFaxItems
                            return items.length
                                ? (
                                    this.renderFaxItems(selectedTab === 'received' ? 'in' : 'out')
                                )
                                : this.props.isFaxConfigured === null
                                    ? (
                                        <div className={classes.loadingDiv}>
                                            <LoaderFull />
                                        </div>
                                    )
                                    : this.props.isFaxConfigured
                                        ? (
                                            <div
                                                className={`${classes.noFaxMessage} ${selectedTab !== 'received' ? 'hidden' : ''}`}
                                            >
                                                <span>You haven't received any faxes</span>
                                            </div>
                                        )
                                        : selectedTab === 'received'
                                            ? (
                                                <NoSetup type="faxes" />
                                            )
                                            : null
                        }}
                    />
                </Switch>
            </div>
        )
    }
}
FaxSelector.propTypes = {
    routerProps: PropTypes.object,
    isFaxConfigured: PropTypes.func,
    classes: PropTypes.object,
    faxes: PropTypes.array,
    newFax: PropTypes.bool,
    setNoNewFax: PropTypes.func,
    currentTab: PropTypes.string,
    currentFaxId: PropTypes.string,
    currentFax: PropTypes.object,
    changeReadStatus: PropTypes.func,
    deletedIncomingFaxes: PropTypes.object,
    deletedOutgoingFaxes: PropTypes.object,
    updateFaxes: PropTypes.func,
    loadExtraContacts: PropTypes.func,
    switchTab: PropTypes.func,
    smallView: PropTypes.bool,
    screenViewType: PropTypes.string,
    deleteFax: PropTypes.func,
    extraContacts: PropTypes.array,
    match: PropTypes.object
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FaxSelector))
