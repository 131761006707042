import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5537%3A4491
 *
 * @param {SvgIconProps} props - svg props
 */
export const ChevronBackIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M14.3533 18.9904C14.1572 19.1807 13.8447 19.1784 13.6515 18.9852L7.02006 12.3537C6.82479 12.1585 6.8248 11.8419 7.02006 11.6466L13.6463 5.02038C13.8415 4.82512 14.1581 4.82512 14.3534 5.02038L14.9796 5.64661C15.1749 5.84187 15.1749 6.15845 14.9796 6.35371L9.33317 12.0002L14.9766 17.6805C15.1729 17.878 15.17 18.1978 14.9702 18.3917L14.3533 18.9904Z' fill={color}/>
        </SvgIcon>
    )
}

export default ChevronBackIcon
