import { Styles } from '@material-ui/core/styles/withStyles'
import headerImage from './imgs/header-background.png'
type StylesType = Styles<string, Record<string, string>>

/**
 * @param {object} theme
 */
const styles: StylesType = theme => ({
    softphone: {
        position: 'fixed',
        zIndex: '1201',
        background: 'white',
        width: '300px',
        height: '565px',
        border: '1px solid rgba(0, 0, 0, 0.5)',
        borderRadius: '20px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.3)',
        '-ms-overflow-style': 'none',
        'scrollbar-width': 'none',
        '&::-webkit-scrollbar': {
            WebkitAppearance: 'none !important',
            width: '0px'
        }
    },
    softphoneHeader: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: 120,
        color: 'white',
        backgroundImage: `url(${headerImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '300px'
    },
    headerTitle: {
        height: '18px',
        marginLeft: '12px',
        marginTop: '6px'
    },
    minimizeIcon: {
        position: 'fixed',
        top: '7px',
        right: '12px',
        width: '26px',
        height: '26px',
        zIndex: '5555',
        color: 'white',
        cursor: 'pointer'
    },
    callConnectionStats: {
        width: '26px',
        height: '26px',
        position: 'fixed',
        top: '7px',
        right: '50px'
    },
    virtualExtensionMessage: {
        width: '100%',
        height: '100%',
        padding: '40% 20%',
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main,
        color: 'white'
    }
})

export default styles
