import React, { useEffect, useState } from 'react'
import AudioIssuesDialog from 'audio-issues-dialog'
interface HasMicPermissionProps {
    children: JSX.Element | null | undefined
    onClose: () => void | undefined
    shouldCheckPermission: boolean | undefined
}

/**
 *
 */
const checkMicPermission = async (): Promise<boolean> => {
    if (navigator.mediaDevices !== undefined) {
        // Req microphone permissions
        console.log('Requesting microphone permissions...')
        return navigator.mediaDevices.getUserMedia({ audio: true }).then(function (stream) {
            console.log('Microphone permissions granted!')
            return true
            // Mic permissions granted, handle however you wish
        }).catch(function (err) {
            console.warn('no mic permissions', err)
            return false
        })
    } else {
        console.warn('no mic permissions')
        return new Promise(() => false)

        // Out of luck at this point, handle however you wish.
    }
}
/***/
const HasMicPermission = (props: HasMicPermissionProps): JSX.Element => {
    type MicPermissionType = boolean | undefined
    const [hasMicPermission, setHasMicPermission] = useState<MicPermissionType>(undefined)
    const [showAudioIssuesDialog, setShowAudioIssuesDialog] = useState(true)
    console.log('HasMicPermission props', props)
    const shouldCheckPermission = (props.shouldCheckPermission === undefined) ? true : props.shouldCheckPermission
    const onCloseAudioIssuesDialog = () => {
        if (showAudioIssuesDialog) {
            props.onClose()
        }
        setShowAudioIssuesDialog(!showAudioIssuesDialog)
    }
    useEffect(() => {
        if (shouldCheckPermission) {
            checkMicPermission()
                .then(micPermission => {
                    console.log('micPermission', micPermission)
                    if (micPermission !== hasMicPermission) {
                        setHasMicPermission(micPermission)
                    }
                })
        }
    }, [])

    return (
        <>
            {console.log('HasMicPermission hasMicPermission', hasMicPermission)}
            {(hasMicPermission === undefined || hasMicPermission)
                ? props.children
                : <AudioIssuesDialog open={showAudioIssuesDialog} onClose={onCloseAudioIssuesDialog}/>
            }
        </>
    )
}

export default HasMicPermission
/**
 *
 */
export { checkMicPermission }
