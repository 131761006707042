import PdcOpenConnection from 'pdc-open-connection'
import React, { useEffect, useState } from 'react'
import Alert, { Color } from 'alert-mui'
import { getPhoneCom } from 'phonecom'
import { getValue } from 'firebase-utils'

const TenDlcWsTopic = 'CSP_10DLC_EVENTS'

const enum AlertType {
    NONE,
    SUBMITTED,
    REJECTED,
    APPROVED,
    PAYMENTFAILED
}

/**
 *
 */
function SmsEnrollmentNotifications (): JSX.Element {
    const alertDuration = 5000
    const [alertState, setAlertState] = useState(AlertType.NONE)
    const [alertTitle, setAlertTitle] = useState('')
    const [alertContent, setAlertContent] = useState('')
    let alertId = 0
    const websocketHandler = (extensionId: string, event: { event: string, status: string, brandId: string, campaignId: string }) => {
        console.log('CSP WS EVENT', event)
        const eventType = event.event
        const status = event.status
        alertId++
        const thisAlertId = alertId
        if (eventType === 'brand_status') {
            const brandId = event.brandId
            switch (status) {
                    case 'approved':
                        setAlertState(AlertType.APPROVED)
                        setAlertTitle(`Brand ${brandId} Approved`)
                        setAlertContent(getValue('sms_enrollment_notif_brand_approved', setAlertContent))
                        break
                    case 'rejected':
                        setAlertState(AlertType.REJECTED)
                        setAlertTitle(`Brand ${brandId} Rejected`)
                        setAlertContent(getValue('sms_enrollment_notif_brand_rejected', setAlertContent))
                        break
                    case 'pending':
                        setAlertState(AlertType.SUBMITTED)
                        setAlertTitle(`Brand ${brandId} Submitted`)
                        setAlertContent(getValue('sms_enrollment_notif_brand_submitted', setAlertContent))
                        break
            }
        } else if (eventType === 'campaign_status') {
            const campaignId = event.campaignId
            switch (status) {
                    case 'approved':
                        setAlertState(AlertType.APPROVED)
                        setAlertTitle(`Campaign ${campaignId} Approved`)
                        setAlertContent(getValue('sms_enrollment_notif_campaign_approved', setAlertContent))
                        break
                    case 'rejected':
                        setAlertState(AlertType.REJECTED)
                        setAlertTitle(`Campaign ${campaignId} Rejected`)
                        setAlertContent(getValue('sms_enrollment_notif_campaign_rejected', setAlertContent))
                        break
                    case 'pending-review':
                        setAlertState(AlertType.SUBMITTED)
                        setAlertTitle(`Campaign ${campaignId} Submitted`)
                        setAlertContent(getValue('sms_enrollment_notif_campaign_submitted', setAlertContent))
                        break
            }
        }
        setTimeout(() => { if (thisAlertId === alertId) setAlertState(AlertType.NONE) }, alertDuration)
    }
    useEffect(() => {
        getPhoneCom().then(() => PdcOpenConnection.onAccount(TenDlcWsTopic, websocketHandler))
    }, [])
    return (<div style={{ position: 'absolute', width: '300px', bottom: '50px', right: '100px', zIndex: 9999 }}>
        { alertState !== AlertType.NONE
            ? <Alert
                color={alertState === AlertType.REJECTED ? Color.ERROR : Color.INFO}
                title={alertTitle}
                content={alertContent}
                showIcon={true}
            />
            : null }
    </div>)
}

/**
 *
 */
export { SmsEnrollmentNotifications }
