const defaultState = { hasMore: true, items: [] }
/**
 * @param {object} state
 * @param {object} action
 */
export function teammates (state = defaultState, action) {
    switch (action.type) {
            case 'SWITCH_TEAMMATE':
                state.items.forEach(t => (t.selected = t.id === action.teammateId))
                return { ...state }
            case 'ADD_TEAMMATES':
                state.items = state.items.concat(action.teammates.items)
                state.hasMore = action.teammates.hasMore
                return {
                    ...state,
                    items: [...state.items]
                }
            default:
                return state
    }
}
