import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * @param {SvgIconProps} props - svg props
 */
export const FaxIcon = (props: SvgIconProps): JSX.Element => {
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.87968 2.5H17.1203C17.8817 2.5 18.5962 2.86728 19.0394 3.48637C19.2305 3.75345 19.3333 4.07367 19.3333 4.40212V4.82843H4.66667V4.40212C4.66667 4.07367 4.76946 3.75345 4.96064 3.48637C5.40377 2.86728 6.11834 2.5 6.87968 2.5ZM15.8333 15.7255H18.4167V16.1911L14.2917 20.3823H13.8333V17.7255C13.8333 16.6209 14.7288 15.7255 15.8333 15.7255ZM3 4.82845C1.89543 4.82845 1 5.72388 1 6.82845V13.0735C1 14.1781 1.89543 15.0735 3 15.0735H4.66667V13.3481C4.66667 12.2436 5.5621 11.3481 6.66667 11.3481H15.9583C17.0629 11.3481 17.9583 12.2435 17.9583 13.3481V15.0735H21C22.1046 15.0735 23 14.1781 23 13.0735V6.82845C23 5.72388 22.1046 4.82845 21 4.82845H3ZM4.51389 8.24351C4.93577 8.24351 5.27778 7.89602 5.27778 7.46737C5.27778 7.03871 4.93577 6.69122 4.51389 6.69122C4.092 6.69122 3.75 7.03871 3.75 7.46737C3.75 7.89602 4.092 8.24351 4.51389 8.24351ZM6.95835 8.24351C7.38024 8.24351 7.72224 7.89602 7.72224 7.46737C7.72224 7.03871 7.38024 6.69122 6.95835 6.69122C6.53647 6.69122 6.19446 7.03871 6.19446 7.46737C6.19446 7.89602 6.53647 8.24351 6.95835 8.24351Z" fill="#262626"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.54044 12.7451C7.21895 12.7451 6.95833 13.0057 6.95833 13.3272C6.95833 13.6487 7.21895 13.9093 7.54044 13.9093H11.4179C11.7394 13.9093 12 13.6487 12 13.3272C12 13.0057 11.7394 12.7451 11.4179 12.7451H7.54044ZM6.95833 15.3297C6.95833 15.0083 7.21895 14.7476 7.54044 14.7476H11.4179C11.7394 14.7476 12 15.0083 12 15.3297C12 15.6512 11.7394 15.9119 11.4179 15.9119H7.54044C7.21895 15.9119 6.95833 15.6512 6.95833 15.3297ZM6.95833 17.3322C6.95833 17.0107 7.21895 16.7501 7.54044 16.7501H11.4179C11.7394 16.7501 12 17.0107 12 17.3322C12 17.6537 11.7394 17.9143 11.4179 17.9143H7.54044C7.21895 17.9143 6.95833 17.6537 6.95833 17.3322Z" fill="#262626"/>
            <path d="M17.9583 13.6765H19.3333V15.6312C19.3333 16.4209 19.022 17.1788 18.4668 17.7404L15.6307 20.6092C15.0672 21.1792 14.2989 21.5 13.4973 21.5H7.66667C6.00982 21.5 4.66667 20.1569 4.66667 18.5V17.0877C4.66667 16.708 4.97448 16.4002 5.35417 16.4002V16.4002C5.73387 16.4002 6.04167 16.708 6.04167 17.0877V18.1092C6.04167 19.2137 6.9371 20.1092 8.04167 20.1092H13.8333L17.9583 15.9366V13.6765Z" fill="#262626"/>
        </SvgIcon>
    )
}

export default FaxIcon
