import React from 'react';
import { connect } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import DropdownExtensionItem from './DropdownExtensionItem'
import api from '../util/api_v2'
import PhoneComUser from 'phone-com-user'
import PDCOpenConnection from 'pdc-open-connection'
import { updateConversations, switchConversation, switchSendNumber, updateTotalConversations } from '../actions/conversations';
import { switchView } from '../actions/view.js';
import { updateBridgeId, updateSelectedExtensionPhoneNumbersFeatures } from '../actions/pdcuser';
// import { updateContacts } from '../actions/contacts.js'
import { parseContacts } from '../util/contacts';
import 'mdn-polyfills/MouseEvent';

import {Dropdown, DropdownToggle,  DropdownMenu} from 'mdbreact';

const mapStateToProps = state => {
	return {
		currentView: state.currentView,
        smallView: state.smallView
	};
};
const mapDispatchToProps = dispatch => {
	return {
        updateConversations: convs => dispatch(updateConversations(convs)),
        updateTotalConversations: total => dispatch(updateTotalConversations(total)),
        switchConversation: conv => dispatch(switchConversation(conv)),
		switchView: view => dispatch(switchView(view)),
        switchSendNumber: num => dispatch(switchSendNumber(num)),
        updateBridgeId: bridgeId => dispatch(updateBridgeId(bridgeId)),
        // updateContacts: contacts => dispatch(updateContacts(contacts)),
        updateSelectedExtensionPhoneNumbersFeatures: extensionPhoneNumbersFeatures => dispatch(updateSelectedExtensionPhoneNumbersFeatures(extensionPhoneNumbersFeatures))
	};
};

class DropdownExtensionsMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: true,
            selectedIndex: null
        }

    }

    componentDidMount() {
        this.addExtensions();
        this.setExtensionsBridgeId();
    }

    render() {
        let selectedIndex = this.state.selectedIndex;
        let text = 'Loading...'
        if (!this.state.loading && selectedIndex >= 0) {
            let selectedItem = this.state.items[selectedIndex];
            text = `Ext #${selectedItem.extension}`
            if (!this.props.smallView) {
                text += `: ${selectedItem.extension_name}`;
            }
        }
        return (
			<Dropdown className='current-extension-dropdown' size="md">
                <DropdownToggle caret color="info"><span className='fs-block'>{text}</span>
                </DropdownToggle>
                <DropdownMenu size="md">
                    {this.state.items.length > 5 ? this.renderFilterItem() : null}
                    <div className='extension-menu-list'>
                        {this.state.items.map((item, i) => {
                            return (<DropdownExtensionItem
                                        key={i}
                                        selected={i === selectedIndex}
                                        item={item}
                                        onClick={(e) => this.reloadConversations(e, i)}
                                    />)
                        })}
                    </div>
                    {this.renderMenuOptions()}
                </DropdownMenu>
            </Dropdown>
        );
    }

    renderMenuOptions() {
        return (
            <div className='extension-menu-options'>
                <button className="btn btn-info extension-menu-options-btn control-panel-button" onClick={this.returnToControlPanel}>Control Panel</button>
                <button className="btn btn-danger extension-menu-options-btn logout-button" onClick={this.logout}>Logout</button>
            </div>
        );
    }

    logout() {
        window.localStorage.removeItem('current_extension_id');
        window.localStorage.removeItem('current_phone_number');
        window.location = PhoneComUser.getLogoutUrl();
    }

    returnToControlPanel() {
        window.location = PhoneComUser.getCPUrl();
    }

    renderFilterItem() {
        return (
            <div className='filter-item'>
                <input type="text" placeholder="Filter" onChange={this.onFilterChange} />
                <i className='fa fa-search search-icon'></i>
            </div>
        );
    }

    onFilterChange(e) {
        e.persist();
        let inputElement = e.target
        let filterValue = inputElement.value;
        Array.from(inputElement.parentElement.parentElement.children[1].children).forEach(element => {
            if (element.classList.contains('dropdown-extension-item')) {
                if (element.innerText.toLowerCase().includes(filterValue.toLowerCase())) {
                    element.classList.remove('hidden');
                } else {
                    element.classList.add('hidden');
                }
            }
        });
    }

    setExtensionsBridgeId() {
        api.getConferenceInfo().then(response => {
            this.props.updateBridgeId(response.data.response);
        });
    }

    addExtensions() {

        let current_extension_id = PhoneComUser.getExtensionId();

        api.getExtensionsAndPhoneNumbers().then(response => {
            if (!response.data) return {};

            let exts = response.data.response;

            let extension_phone_numbers = exts.map(item => {
                let phoneNumbers = Object.keys(item.phone_number);
                let sortedPhoneNumbers = phoneNumbers ? phoneNumbers.sort(a => item.caller_id && a === item.caller_id ? -1 : 1) : null;

                if (current_extension_id.toString() === item.extension_id.toString()) {
                    let features = {}
                    phoneNumbers.forEach(pn => {
                        features[pn] = item.phone_number[pn].features
                    })
                    this.props.updateSelectedExtensionPhoneNumbersFeatures(features);
                }

                return {
                    extension:          item.extension ? item.extension : '',
                    extension_id:       item.extension_id ? item.extension_id : '',
                    extension_name:     item.extension_name ? item.extension_name : '',
                    phone_number:       sortedPhoneNumbers,
                    selected:           current_extension_id.toString() === item.extension_id.toString(),
                    caller_id:          item.caller_id,
                    features:           item.phone_number
                };
            });

            extension_phone_numbers = this.sortExtensions(extension_phone_numbers);

            if (extension_phone_numbers) {
                let foundIndex = extension_phone_numbers.findIndex((e) => e.extension_id.toString() === current_extension_id);
                this.setState({
                    items: extension_phone_numbers,
                    selectedIndex: foundIndex,
                    loading: false
                });
            }

        });
    }
    
    sortExtensions(extension_phone_numbers) {

        let selected_extension = extension_phone_numbers.filter(e => e.selected);

        let have_numbers = extension_phone_numbers
            .filter(e => e.phone_number && !e.selected)
            .sort((e1, e2) => e1.extension > e2.extension ? 1 : -1);

        let dont_have_numbers = extension_phone_numbers
            .filter(e => !e.phone_number && !e.selected)
            .sort((e1, e2) => e1.extension > e2.extension ? 1 : -1);

        return selected_extension.concat(have_numbers.concat(dont_have_numbers));
    }

	reloadConversations(event, index) {
        event.persist();

        //Hide dropdown
        //https://github.com/react-bootstrap/react-bootstrap/issues/541#issuecomment-265821331
        document.dispatchEvent(new MouseEvent('click'));
    
        let element = event.currentTarget;

        this.setState({selectedIndex: index});

        if (Array.from(element.classList).includes('active')) {
            return;
        }

        let appWrapper = document.getElementsByClassName('app-wrapper')[0];
        let loadingDiv = document.getElementsByClassName('loading-div')[0];
        appWrapper.classList.add('blur');
        loadingDiv.classList.remove('hidden');

        Array.from(element.parentElement.children).forEach((child, index) => {
            if (child === element) {
                child.classList.add('active');
            } else {
                child.classList.remove('active');
            }
        });

        let item = this.state.items[index];
        let extension_id = item.extension_id;
        let phone_number = item.phone_number;
        PhoneComUser.changeExtension(extension_id);
        PhoneComUser.changePhoneNumber(phone_number === 'N/A' ? '' : phone_number);

        // Load 10 contacts
        api.loadContacts(0, 10)
            .then(
                response => {
                    let contacts = parseContacts(response.contacts, PhoneComUser.getExtensionId())
                    // this.props.updateContacts(contacts)
                }
            );

        let features = {}
        Object.keys(item.features).forEach(phoneNumber => {
            features[phoneNumber] = item.features[phoneNumber].features
        })
        this.props.updateSelectedExtensionPhoneNumbersFeatures(features);

        // Update the bridge id
        api.getConferenceInfo().then(response => {
            this.props.updateBridgeId(response.data.response);
        });

        PDCOpenConnection.hardReset();

        let extension_phone_numbers = this.state.items;
        extension_phone_numbers.forEach(e => {
            e.selected = e.extension_id === extension_id;
        });
        extension_phone_numbers = this.sortExtensions(extension_phone_numbers);

        window.localStorage.setItem('current_extension_id', extension_id);
        window.localStorage.setItem('current_phone_number', phone_number);

        let foundIndex = extension_phone_numbers.findIndex((e) => e.extension_id === extension_id);
        this.setState({
            items: extension_phone_numbers,
            selectedIndex: foundIndex,
            loading: false
        });

        this.props.switchSendNumber(phone_number);
		api.loadConversations(0, 15).then(response => {
            let conversations = response.conversations.length ? response.conversations : [];
            this.props.updateTotalConversations(response.total);
            this.props.updateConversations(conversations);
			if (!conversations.length) {
				this.props.switchView('new_conversation');
			} else {
                this.props.switchConversation(conversations[0]);
                this.props.switchView('content');
                let sendText = document.getElementsByClassName('send-message-text-input')[0];
                if (sendText) sendText.value = '';
			}
            appWrapper.classList.remove('blur');
			loadingDiv.classList.add('hidden');
		});
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownExtensionsMenu);