import { Styles } from '@material-ui/core/styles/withStyles'
type StylesType = Styles<any, Record<string, unknown>>

/**
 * @param {object} theme
 */
const styles: StylesType = theme => ({
    sessionsListItem: {
        borderRadius: '23px',
        backgroundColor: '#8C98A026',
        width: '280px',
        height: '32px',
        paddingTop: '3px',
        margin: '0.5px 0px',
        '&.call-view': {
            backgroundColor: 'transparent',
            margin: '0.5px 0px'
        },
        '&.on-hold': {
            backgroundColor: 'transparent',
            margin: '0px'
        }
    },
    sessionsList: {
        padding: '20px 10px',
        width: '300px',
        flexDirection: 'column-reverse' as const,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        position: 'absolute',
        zIndex: '4444',
        boxShadow: '0px 10px 25px rgba(0, 0, 0, 0.75)',
        '&.call-view': {
            backgroundColor: 'transparent',
            zIndex: '3333',
            boxShadow: 'none',
            padding: '5px 10px',
            position: 'relative'
        }
    },
    callerId: {
        height: '19px',
        width: '141px',
        display: 'inline-block',
        marginLeft: '7px',
        paddingRight: 10,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '&.on-hold': {
            color: '#E85646'
        },
        '&.call-view': {
            color: '#FFFFFF'
        }
    },
    timer: {
        height: '19px',
        width: '40px',
        display: 'inline-block'
    },
    sessionControls: {
        width: '65px',
        height: '26px',
        display: 'inline-block',
        position: 'fixed',
        paddingRight: '3px'
    },
    sessionControlButton: {
        width: '26px',
        height: '26px',
        float: 'right',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0px 2.5px',
        '&.merge': {
            color: 'white',
            backgroundColor: 'gray',
            borderRadius: '18px'
        }
    },
    statusIcon: {
        width: '18px',
        height: '18px',
        display: 'inline-block',
        marginLeft: '10px',
        '&.on-hold': {
            color: '#E85646'
        },
        '&.call-view': {
            color: '#FFFFFF'
        }
    }
})

export default styles
