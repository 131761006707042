<template>
	<div class="d-flex">
		<w-text-field
			v-for="n of [0, 1, 2]"
			:key="`number-${n}`"
			type="text"
			not-clearable
			v-model="pattern[n]"
			maxlength="1"
			:id="`pattern-container-${n+1}`"
			@keyup="next_digit"
			autocomplete="off"
			class="text-center mx-1"
			outlined dense
			hide-details='auto'
			:data-test-id="`number-matcher-${n}`"
		/>
		<span class="mt-3">&ndash;</span>
		<w-text-field
			v-for="n of [3, 4, 5]"
			:key="`number-${n}`"
			type="text"
			not-clearable
			v-model="pattern[n]"
			maxlength="1"
			:id="`pattern-container-${n+1}`"
			@keyup="next_digit"
			autocomplete="off"
			class="text-center mx-1 shrink"
			outlined dense
			single-line
			hide-details='auto'
			:data-test-id="`number-matcher-${n}`"
		/>
		<span class="mt-3">&ndash;</span>
		<w-text-field
			v-for="n of [6, 7, 8, 9]"
			:key="`number-${n}`"
			type="text"
			not-clearable
			v-model="pattern[n]"
			maxlength="1"
			:id="`pattern-container-${n+1}`"
			@keyup="next_digit"
			autocomplete="off"
			class="text-center mx-1"
			outlined dense
			hide-details='auto'
			:data-test-id="`number-matcher-${n}`"
		/>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: Array,
			default: ['', '', '', '', '', '', '', '', '', ''],
		}
	},
	data() {
		return {
			pattern: JSON.parse(JSON.stringify(this.$props.value)),
		};
	},
	methods: {
		next_digit(e) {
		  const id = e.target.id.split('-');
		  id[2] = parseInt(id[2]);
		  if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
		    id[2] += 1;
		    const next = document.getElementById(id.join('-'));
		    if (next) {
				const input = next.getElementsByTagName('input');
				if (input && input.length) input[0].focus();
		    }
		  }
		},
	},
	watch: {
		// eslint-disable-next-line object-shorthand
		pattern: function (val) {
			return this.$emit('changed', val);
		},
	}
};
</script>

<style scoped lang="scss">
	input {
		text-align: center;
		.v-input__slot {
			padding-left: 0;
			padding-right: 0;
		}
	}
</style>
