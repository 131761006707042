const stateOptions = [
    {
        content: 'Alabama',
        value: 'AL'
    },
    {
        content: 'Alaska',
        value: 'AK'
    },
    {
        content: 'American Samoa',
        value: 'AS'
    },
    {
        content: 'Arizona',
        value: 'AZ'
    },
    {
        content: 'Arkansas',
        value: 'AR'
    },
    {
        content: 'California',
        value: 'CA'
    },
    {
        content: 'Colorado',
        value: 'CO'
    },
    {
        content: 'Connecticut',
        value: 'CT'
    },
    {
        content: 'Delaware',
        value: 'DE'
    },
    {
        content: 'District Of Columbia',
        value: 'DC'
    },
    {
        content: 'Federated States Of Micronesia',
        value: 'FM'
    },
    {
        content: 'Florida',
        value: 'FL'
    },
    {
        content: 'Georgia',
        value: 'GA'
    },
    {
        content: 'Guam',
        value: 'GU'
    },
    {
        content: 'Hawaii',
        value: 'HI'
    },
    {
        content: 'Idaho',
        value: 'ID'
    },
    {
        content: 'Illinois',
        value: 'IL'
    },
    {
        content: 'Indiana',
        value: 'IN'
    },
    {
        content: 'Iowa',
        value: 'IA'
    },
    {
        content: 'Kansas',
        value: 'KS'
    },
    {
        content: 'Kentucky',
        value: 'KY'
    },
    {
        content: 'Louisiana',
        value: 'LA'
    },
    {
        content: 'Maine',
        value: 'ME'
    },
    {
        content: 'Marshall Islands',
        value: 'MH'
    },
    {
        content: 'Maryland',
        value: 'MD'
    },
    {
        content: 'Massachusetts',
        value: 'MA'
    },
    {
        content: 'Michigan',
        value: 'MI'
    },
    {
        content: 'Minnesota',
        value: 'MN'
    },
    {
        content: 'Mississippi',
        value: 'MS'
    },
    {
        content: 'Missouri',
        value: 'MO'
    },
    {
        content: 'Montana',
        value: 'MT'
    },
    {
        content: 'Nebraska',
        value: 'NE'
    },
    {
        content: 'Nevada',
        value: 'NV'
    },
    {
        content: 'New Hampshire',
        value: 'NH'
    },
    {
        content: 'New Jersey',
        value: 'NJ'
    },
    {
        content: 'New Mexico',
        value: 'NM'
    },
    {
        content: 'New York',
        value: 'NY'
    },
    {
        content: 'North Carolina',
        value: 'NC'
    },
    {
        content: 'North Dakota',
        value: 'ND'
    },
    {
        content: 'Northern Mariana Islands',
        value: 'MP'
    },
    {
        content: 'Ohio',
        value: 'OH'
    },
    {
        content: 'Oklahoma',
        value: 'OK'
    },
    {
        content: 'Oregon',
        value: 'OR'
    },
    {
        content: 'Palau',
        value: 'PW'
    },
    {
        content: 'Pennsylvania',
        value: 'PA'
    },
    {
        content: 'Puerto Rico',
        value: 'PR'
    },
    {
        content: 'Rhode Island',
        value: 'RI'
    },
    {
        content: 'South Carolina',
        value: 'SC'
    },
    {
        content: 'South Dakota',
        value: 'SD'
    },
    {
        content: 'Tennessee',
        value: 'TN'
    },
    {
        content: 'Texas',
        value: 'TX'
    },
    {
        content: 'Utah',
        value: 'UT'
    },
    {
        content: 'Vermont',
        value: 'VT'
    },
    {
        content: 'Virgin Islands',
        value: 'VI'
    },
    {
        content: 'Virginia',
        value: 'VA'
    },
    {
        content: 'Washington',
        value: 'WA'
    },
    {
        content: 'West Virginia',
        value: 'WV'
    },
    {
        content: 'Wisconsin',
        value: 'WI'
    },
    {
        content: 'Wyoming',
        value: 'WY'
    }
]

const provinceOptions = [
    {
        content: 'Alberta',
        value: 'AB'
    },
    {
        content: 'British Columbia',
        value: 'BC'
    },
    {
        content: 'Manitoba',
        value: 'MB'
    },
    {
        content: 'New Brunswick',
        value: 'NB'
    },
    {
        content: 'Newfoundland and Labrador',
        value: 'NL'
    },
    {
        content: 'Northwest Territories',
        value: 'NT'
    },
    {
        content: 'Nova Scotia',
        value: 'NS'
    },
    {
        content: 'Nunavut',
        value: 'NU'
    },
    {
        content: 'Ontario',
        value: 'ON'
    },
    {
        content: 'Prince Edward Island',
        value: 'PE'
    },
    {
        content: 'Quebec',
        value: 'QC'
    },
    {
        content: 'Saskatchewan',
        value: 'SK'
    },
    {
        content: 'Yukon Territory',
        value: 'YT'
    }
]

const countryOptions = [
    {
        content: 'United States',
        value: 'US'
    },
    {
        content: 'Canada',
        value: 'CA'
    }
]

const verticalOptions = [{
    id: 'AGRICULTURE',
    displayName: 'Agriculture',
    description: 'Agriculture businesses and services.'
}, {
    id: 'COMMUNICATION',
    displayName: 'Media and Communication',
    description: 'Communication and mass media.'
}, {
    id: 'CONSTRUCTION',
    displayName: 'Construction, Materials, and Trade Services',
    description: 'Construction, materials, and trade services.'
}, {
    id: 'EDUCATION',
    displayName: 'Education',
    description: 'Educational institutions providing education to students in educational settings.'
}, {
    id: 'ENERGY',
    displayName: 'Energy and Utilities',
    description: 'Energy and Utilities.'
}, {
    id: 'ENTERTAINMENT',
    displayName: 'Entertainment',
    description: 'Entertainment, including gaming and dating services.'
}, {
    id: 'FINANCIAL',
    displayName: 'Financial Services',
    description: 'Banking and financial institution.'
}, {
    id: 'GAMBLING',
    displayName: 'Gambling and Lottery',
    description: 'Gambling and Lotteries.'
}, {
    id: 'GOVERNMENT',
    displayName: 'Government Services and Agencies',
    description: 'Local, state or federal agencies, utilities.'
}, {
    id: 'HEALTHCARE',
    displayName: 'Healthcare and Life Sciences',
    description: 'Healthcare services, including hospital, health insurances.'
}, {
    id: 'HOSPITALITY',
    displayName: 'Hospitality and Travel',
    description: 'Travel, hospitality and transportation.'
}, {
    id: 'HUMAN_RESOURCES',
    displayName: 'HR, Staffing or Recruitment',
    description: 'Human resources, staffing, or recruitment.'
}, {
    id: 'INSURANCE',
    displayName: 'Insurance',
    description: 'Insurance products and services'
}, {
    id: 'LEGAL',
    displayName: 'Legal',
    description: 'Legal services and law firms.'
}, {
    id: 'MANUFACTURING',
    displayName: 'Manufacturing',
    description: 'Goods producing industries.'
}, {
    id: 'NGO',
    displayName: 'Non-profit Organization',
    description: 'Non-profit organization with valid tax-exempted status.'
}, {
    id: 'POLITICAL',
    displayName: 'Political',
    description: 'Political campaigns and services.'
}, {
    id: 'POSTAL',
    displayName: 'Postal and Delivery',
    description: 'Postal and delivery services.'
}, {
    id: 'PROFESSIONAL',
    displayName: 'Professional Services',
    description: 'Professional services.'
}, {
    id: 'REAL_ESTATE',
    displayName: 'Real Estate',
    description: 'Buying and Selling of residential and commercial properties.'
}, {
    id: 'RETAIL',
    displayName: 'Retail and Consumer Products',
    description: 'Consumer products and services, including Retails and eTails.'
}, {
    id: 'TECHNOLOGY',
    displayName: 'Information Technology Services',
    description: 'Information technology and information services.'
}, {
    id: 'TRANSPORTATION',
    displayName: 'Transportation or Logistics',
    description: 'Transportation or logistics services.'
}]

/**
 * @param {object} state to check if it's an american state
 */
const isAmericaState = (state) => {
    return stateOptions.some(e => e.value === state.value)
}

/**
 * @param {object} state to check if it's a canadian province
 */
const isCanadianState = (state) => {
    return provinceOptions.some(e => e.value === state.value)
}

const publiclyTradedBizType = {
    name: 'business_type',
    value: 'PUBLIC_PROFIT',
    label: 'Publicly Traded'
}

const businessTypeOptions = [
    publiclyTradedBizType,
    {
        name: 'business_type',
        value: 'NON_PROFIT',
        label: 'Non Profit'
    },
    {
        name: 'business_type',
        value: 'PRIVATE_PROFIT',
        label: 'Private company'
    },
    {
        name: 'business_type',
        value: 'GOVERNMENT',
        label: 'Government'
    }
]

const useCasesOptions = [
    {
        id: '2FA',
        label: '2FA'
    },
    {
        id: 'ACCOUNT_NOTIFICATION',
        label: 'Account Notification'
    },
    {
        id: 'AGENTS_FRANCHISES',
        label: 'Agents and Franchises'
    },
    {
        id: 'CARRIER_EXEMPT',
        label: 'Carrier Exemptions'
    },
    {
        id: 'CHARITY',
        label: 'Charity'
    },
    {
        id: 'CUSTOMER_CARE',
        label: 'Customer Care'
    },
    {
        id: 'DELIVERY_NOTIFICATION',
        label: 'Delivery Notification'
    },
    {
        id: 'EMERGENCY',
        label: 'Emergency'
    },
    {
        id: 'FRAUD_ALERT',
        label: 'Fraud Alert Messaging'
    },
    {
        id: 'HIGHER_EDUCATION',
        label: 'Higher Education'
    },
    {
        id: 'K12_EDUCATION',
        label: 'K-12 Education'
    },
    {
        id: 'LOW_VOLUME',
        label: 'Low Volume Mixed'
    },
    {
        id: 'M2M',
        label: 'Machine to Machine'
    },
    {
        id: 'MARKETING',
        label: 'Marketing'
    },
    {
        id: 'MIXED',
        label: 'Mixed'
    },
    {
        id: 'POLITICAL',
        label: 'Political'
    },
    {
        id: 'POLLING_VOTING',
        label: 'Polling and voting'
    },
    {
        id: 'PROXY',
        label: 'Proxy'
    },
    {
        id: 'PUBLIC_SERVICE_ANNOUNCEMENT',
        label: 'Public Service Announcement'
    },
    {
        id: 'SECURITY_ALERT',
        label: 'Security Alert'
    },
    {
        id: 'SOCIAL',
        label: 'Social'
    },
    {
        id: 'SOLE_PROPRIETOR',
        label: 'Sole Proprietor'
    },
    {
        id: 'SWEEPSTAKE',
        label: 'Sweepstake'
    },
    {
        id: 'TRIAL',
        label: 'Platform Free Trial'
    },
    {
        id: 'UCAAS_HIGH',
        label: 'UCaaS High Volume'
    },
    {
        id: 'UCAAS_LOW',
        label: 'UCaaS Low Volume'
    }
]

/**
 *
 */
export {
    publiclyTradedBizType,
    businessTypeOptions,
    stateOptions,
    provinceOptions,
    countryOptions,
    verticalOptions,
    useCasesOptions,
    isAmericaState,
    isCanadianState
}
