/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import RemoteConfigValue from 'remote-config-value'
import { getPhoneCom } from 'phonecom'

const styles = theme => ({
    notificationBar: {
        width: '100%',
        height: 'fit-content',
        minHeight: 60,
        padding: '16px 40px',
        background: theme.palette.info.background,
        zIndex: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: theme.palette.info.main,
        position: 'relative',
        '&.secondary': {
            padding: 10
        },
        '& .link': {
            textDecoration: 'underline',
            color: theme.palette.info.textDark,
            cursor: 'pointer'
        },
        '& .notAdminInfo': {
            color: theme.palette.info.main
        }
    },
    linkSeparator: {
        padding: '0 5px'
    },
    closeNotificationBar: {
        position: 'absolute',
        right: 15,
        top: '50%',
        transform: 'translateY(-50%)',
        fontSize: 26,
        cursor: 'pointer'
    }
})
const shouldNeverShow = () => {
    return localStorage.getItem('neverShowSmsEnrollmentBar')
}
class SmsEnrollmentBar extends Component {
    state = {
        shouldShow: !shouldNeverShow(),
        isAdmin: null
    }

    componentDidMount = () => {
        getPhoneCom().then(phoneCom => {
            this.setState({ isAdmin: phoneCom.role === 'account' })
        })
    }

    neverAskAgain = () => {
        localStorage.setItem('neverShowSmsEnrollmentBar', true)
        this.hideBar()
    }

    hideBar = () => {
        this.setState({ shouldShow: false })
    }

    goTo = () => {
        this.props.goTo('sms-registration', {})
    }

    render () {
        const { classes } = this.props
        return (
            (this.state.shouldShow && this.state.isAdmin !== null)
                ? <div className={`${classes.notificationBar} ${this.state.step}`} id='notification-bar'>
                    <span> <RemoteConfigValue valueId={'sms_enrollment_banner_content'}/><br/>
                        <span className='options'>
                            <span className='link' hidden={!this.state.isAdmin} onClick={this.goTo}><RemoteConfigValue valueId={'sms_enrollment_banner_enroll_now_button'}/></span>
                            <span className='notAdminInfo' hidden={this.state.isAdmin}><RemoteConfigValue valueId={'sms_enrollment_banner_enroll_as_admin'}/></span>
                            <span className={classes.linkSeparator}>•</span>
                            <span className='link' onClick={this.hideBar}><RemoteConfigValue valueId={'sms_enrollment_banner_ask_later_button'}/></span>
                            <span className={classes.linkSeparator}>•</span>
                            <span className='link' onClick={this.neverAskAgain}><RemoteConfigValue valueId={'sms_enrollment_banner_never_ask_again_button'}/></span>
                        </span>
                    </span>
                </div>
                : null
        )
    }
}

export default withStyles(styles)(SmsEnrollmentBar)
