/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-async-promise-executor */
/* eslint-disable react/prop-types */
/* eslint-disable no-tabs */
import React, { Component } from 'react'
import api from '../../util/api_v2'
import ThumbnailItem from 'thumbnail-item'
import TypeMessageField from './TypeMessageField'
import connect from 'react-redux/es/connect/connect'
import { addMessage, updateMessage, updateConversations } from '../../actions/conversations.js'
import { updateForwardingContent } from '../../actions/forwarding.js'
import PhoneComUser from 'phone-com-user'
import formatPhoneNumber from '../../util/phone_number_format'
import { ErrorTooltip } from 'tooltips'
import gtmDataPush from 'gtm-events'
import { generateRandomString } from 'random-generator'

import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import imageCompression from 'browser-image-compression'

import { xBtn } from 'pdc-icons'
import { AttachIcon, SendIcon } from 'pdc-svg-icons'

import { withStyles } from '@material-ui/core'
import { detectOS } from 'os-detector'

const TWO_MB = 2000000
const THREE_AND_HALF_MB = 3500000

const mapStateToProps = state => {
    return {
        conversations:	state.conversations,
        forwarding:	state.forwarding,
        selectedExtensionPhoneNumbersFeatures:	state.selectedExtensionPhoneNumbersFeatures,
        smallView:	state.smallView,
        fromNumber:	state.selectedSendNumber // This is the number that will be used to send. stored in the conversation object
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addMessage:	(message, convId)	=> dispatch(addMessage(message, convId)),
        updateMessage:	(message, convId)	=> dispatch(updateMessage(message, convId)),
        updateConversations:	conversations		=> dispatch(updateConversations(conversations)),
        updateForwardingContent:	content				=> dispatch(updateForwardingContent(content))
    }
}

const styles = theme => ({
    sendMessageContainer: {
        borderTop: theme.palette.primary.flatBorder,
        position: 'relative',
        background: 'none',
        boxShadow: 'none',
        '&:focus': {
            outline: 'none'
        },
        '&.dropzone--isActive': {
            border:	'3px dashed #7d7d7d',
            borderRadius:	'10px',
            fontSize:	'22px'
        },
        '& .drop-area': {
            position:	'absolute',
            top:	0,
            bottom:	0,
            left:	0,
            right:	0,
            padding:	'3px',
            background:	'rgba(255, 255, 255, 0.5)',
            zIndex:	1,
            '& div': {
                width:	'100%',
                height:	'100%',
                display:	'flex',
                justifyContent:	'center',
                alignItems:	'center',
                fontSize:	'22px'
            }
        }
    },
    sendMessageContent: {
        position:	'relative',
        display:	'flex',
        padding:	'25px 20px',
        '&.blur': {
            filter:	'blur(1px)'
        }
    },
    buttonIcon: {
        width:	'44px',
        height:	'44px'
    },
    sendMessageButton: {
        display:	'flex',
        alignItems:	'center',
        userSelect:	'none',
        cursor:	'pointer',
        '&.not-allowed': {
            cursor: 'not-allowed'
        }
    },
    fileInputButton: {
        display:	'flex',
        alignItems:	'center',
        userSelect:	'none',
        cursor:	'pointer',
        '&.not-allowed': {
            cursor: 'not-allowed'
        }
    },
    mediaContainer: {
        borderTopWidth: 1,
        borderTopStyle:	'solid',
        borderTopColor:	theme.messagesApp.sendMessage.mediaContainerBorderColor,
        backgroundColor:	theme.messagesApp.sendMessage.mediaContainerBackgroundColor,
        padding:	'15px 20px'
    },
    thumbnailContainer: {
        position:	'relative',
        display:	'inline-block',
        marginRight:	20,
        marginBottom:	20,
        '& .remove-file-button': {
            padding:	6,
            backgroundColor:	'black',
            borderRadius:	15,
            width:	26,
            position:	'absolute',
            right:	0,
            top:	0,
            zIndex:	1,
            transform:	'translate(50%, -50%)',
            cursor:	'pointer'
        },
        '&:not(:hover) .remove-file-button': {
            display: 'none'
        },
        '& .file-err-msg': {
            background:	'red',
            position:	'absolute',
            top:	-3,
            left:	-5,
            borderRadius:	15,
            width:	22,
            height:	22,
            textAlign:	'center',
            color:	'#fff',
            zIndex:	1,
            border:	'2px solid #efeeee',
            fontWeight:	'bold',
            cursor:	'help'
        }
    }
})

class SendMessage extends Component {
    constructor (props) {
        super(props)

        this.state = {
            text:	'',
            conversation_id:	'',
            media:	[],
            errorMessages:	{},
            error:	'',
            show_cant_send_message_error: false,
            from_number:	props.fromNumber,
            to_numbers:	props.participantNumbers.filter((p) => p !== props.fromNumber),
            dragOver:	false,
            attachHovered:	false,
            blockSendMessage: this.props.blockSendMessage
        }
        console.log('10dlc', this.state.blockSendMessage)
    }

	focusTypeMessage= () => {
	    if (detectOS() !== 'iOS') {
	        document.getElementById('type-message-field').focus()
	    }
	}

	componentDidMount () {
	    this.handleForwarding()
	    this.focusTypeMessage()
	}

	componentDidUpdate (prevProps) {
	    if (prevProps.currentView === 'new_conversation' && this.props.currentView === 'content') {
	        this.focusTypeMessage()
	    }

	    if (prevProps.conversation.id !== this.props.conversation.id) {
	        this.setState({ text: '', media: [], show_cant_send_message_error: false })
	    }

	    // If number not in phonecom, set to [0]
	    if (this.state.from_number !== this.props.fromNumber) {
	        this.setState({ from_number: this.props.fromNumber })
	    }

	    // Send message if messageToBeSent is set
	    if (this.props.messageToBeSent && (this.props.messageToBeSent.text || this.props.messageToBeSent.media)) {
	        this.sendMessage(this.props.messageToBeSent.text, this.props.messageToBeSent.media, this.props.messageToBeSent.eventType)
	        this.props.removeMessageToBeSent()
	    }

	    if (prevProps.blockSendMessage !== this.props.blockSendMessage) {
	        this.setState({ blockSendMessage: this.props.blockSendMessage })
	        console.log('10dlc', this.state.blockSendMessage)
	    }

	    this.updateTextAndMedia()
	    this.uploadAttachedMedia()
	    this.handleForwarding()
	}

	// Removes the text and/or the media from state in case text or media is not allowed to be sent in the current conversation
	updateTextAndMedia () {
	    const noFromNumber = !this.state.from_number
	    const selectedFromNumberFeatures = this.props.selectedExtensionPhoneNumbersFeatures[this.state.from_number]
	    const isGroupEnabled = selectedFromNumberFeatures && selectedFromNumberFeatures.includes('group')

	    const showCantSendGroupMessagesText = !noFromNumber && !isGroupEnabled && this.props.participantNumbers.length > 2
	    if ((noFromNumber || showCantSendGroupMessagesText) && this.state.text) {
	        this.setState({ text: '' })
	    }

	    const isMediaEnabled = !noFromNumber ? (selectedFromNumberFeatures && selectedFromNumberFeatures.includes('media')) : false
	    if (!isMediaEnabled && this.state.media.length) {
	        this.setState({ media: [] })
	    }
	}

	uploadAttachedMedia () {
	    const media = this.state.media
	    media.forEach(file => {
	        if (!('isValid' in file) || !file.isValid || !file.data) return // Not yet validated or invalid (big size)
	        if (['uploading', 'uploaded', 'failed'].includes(file.uploadStatus)) return // Being uploaded or is uploaded or failed
	        this.uploadFile(file)
	    })
	}

	uploadFile = file => {
	    // Start uploading and set uploadStatus to 'uploading'
	    file.uploadStatus = 'uploading'
	    const payload = {
	        data: file.data,
	        encoding: file.encoding,
	        filename: file.filename,
	        size: file.size,
	        type: file.type
	    }
	    api.uploadMedia([payload]).then(response => {
	        const errorMessages = this.state.errorMessages
	        if (response.message === 'Network Error' || response.data[0].status === 'failed') {
	            file.uploadStatus = 'failed'
	            errorMessages.uploadFailed = 'File upload failed'
	            this.setState({ errorMessages })
	        } else {
	            file.uploadedFileUrl = response.data[0].url
	            // Once it is uploaded set uploadStatus to 'uploaded
	            file.uploadStatus = 'uploaded'
	            delete errorMessages.uploadFailed
	            this.setState({ errorMessages })
	        }
	        this.setState({ media: this.state.media }) // If it is like this: this.setState({media}) it is buggy
	    })
	    this.setState({ media: this.state.media }) // If it is like this: this.setState({media}) it is buggy
	}

	retryUploadMedia (fileId) {
	    let foundFile = null
	    this.state.media.forEach(file => {
	        if (file.id !== fileId) return
	        if (['uploading', 'uploaded'].includes(file.uploadStatus)) return // Being uploaded or is uploaded
	        foundFile = file
	        this.uploadFile(file)
	    })

	    if (!foundFile) return console.error(`File with id: ${fileId} not found on retry upload`)
	}

	handleForwarding () {
	    const forwarding = this.props.forwarding
	    if (!forwarding) return

	    this.setState({ text: forwarding.text })
	    this.setState({
	        media: forwarding.media.slice(0, 10).map(m => {
	        return {
	            url: m.url,
	            type: m.type
	        }
	        })
	    })

	    this.props.updateForwardingContent(null)
	}

	updateTextInput = text => {
	    if (text === this.state.text) return

	    const from_number = PhoneComUser.getPhoneNumber()
	    this.setState({
	        text: text,
	        show_cant_send_message_error: !from_number && text
	    })
	}

	removeFile = (file) => {
	    const media = this.state.media
	    media.splice(media.indexOf(file), 1)
	    this.setState({ media }, this.validateMedia)
	}

	getPotentialSize = (media, newSize) => {
	    let size = 0
	    media.forEach(m => {
	        size += m.size
	    })
	    return size + newSize
	}

	sendMessage = async (messageText, messageMedia, eventType) => {
	    if (this.props.isOffline) return

	    if ((!this.state.text && this.state.media.length <= 0 && !messageText && !messageMedia) ||
			this.state.show_cant_send_message_error) {
	        return false
	    }

	    const from_number = this.state.from_number

	    if (this.state.media.length && !this.validateMedia()) {
	        return false
	    }

	    // Set the current conversation as not newlyAdded (it gets set as newlyAdded in NewConversationPanel.js)
	    const conversations = this.props.conversations
	    conversations.map(c => { c.newlyAdded = false; return c.newlyAdded })

	    // If it is a message to self then do not remove self's number from recipients
	    const uniqueParticipantNumbers = [...new Set(this.props.participantNumbers)]
	    let recipients = uniqueParticipantNumbers
	    if (uniqueParticipantNumbers.length > 1) {
	        recipients = uniqueParticipantNumbers.filter((p) => p !== from_number)
	    }

	    const text = messageText || this.state.text
	    const media = messageMedia || this.state.media

	    // If it has 'data' then it is an uploaded file not a forwarding
	    if (media.find(m => m.data && !m.uploadedFileUrl)) {
	        console.error('Send Message got called before the media got uploaded')
	        return false
	    }

	    const payload = {
	        to: recipients,
	        from: from_number,
	        text: text,
	        media: media,
	        tag: generateRandomString(40)
	    }

	    let currentTimestamp = `${(new Date()).getTime()}`
	    currentTimestamp = currentTimestamp.substring(0, currentTimestamp.length - 3)
	    const reactMessage = Object.assign({ created_at: currentTimestamp, direction: 'out', isPending: true }, payload)
	    this.props.addMessage(reactMessage, this.props.conversation.id)
	    setTimeout(() => this.props.scrollToNewest(), 10) // Scroll to bottom

	    payload.media = payload.media.slice(0, 10).map(m => {
	        if (m.uploadedFileUrl) {
	            return {
	                url: m.uploadedFileUrl,
	                temp: true
	            }
	        }

	        return m.url
	    })

	    this.setState({ text: '', media: [] })

	    gtmDataPush({
	        PDC_Action:	'message-sent',
	        PDC_Label:	'type: ' + eventType
	    })
	    const response = await api.sendMessage(payload)
	        .catch((error) => { /* error is a Promise */ })
	    if (response.status !== 200) {
	        reactMessage.to.forEach((to, index) => {
	            reactMessage.to[index] = {
	                number: reactMessage.to[index],
	                delivery_status: 'failed',
	                delivered_at: null,
	                delivered_errors: response?.errors,
	                sent_at: reactMessage.created_at,
	                name: '',
	                voip_contact_id: ''
	            }
	        })
	        this.props.updateMessage(reactMessage, this.props.conversation.id)

	        let errorMessage = 'Error sending message'
	        if (response.message === 'Network Error') errorMessage = 'Network Error'
	        console.error(errorMessage)
	    }

	    return true
	}

	getMaxMediaSizeAllowed = () => {
	    let maxFileSize = TWO_MB
	    let maxTotalMediaSize = THREE_AND_HALF_MB
	    let selectedFromNumberFeatures = null
	    if (this.props.selectedExtensionPhoneNumbersFeatures) {
	        selectedFromNumberFeatures = this.props.selectedExtensionPhoneNumbersFeatures[this.state.from_number]
	    }
	    if (!selectedFromNumberFeatures) {
	        return [maxFileSize, maxTotalMediaSize]
	    }

	    selectedFromNumberFeatures.forEach(feature => {
	        if (feature.substring(0, 13) === 'max_file_size') {
	            maxFileSize = parseInt(feature.split(':')[1], 10)
	        } else if (feature.substring(0, 20) === 'max_total_media_size') {
	            maxTotalMediaSize = parseInt(feature.split(':')[1], 10)
	        }
	    })

	    return [maxFileSize, maxTotalMediaSize]
	}

	renderInputtedMedia () {
	    const { classes } = this.props
	    if (this.state.media.length <= 0) return null
	    const maxFileSize = this.getMaxMediaSizeAllowed()[0]
	    const fileTooBigMessage = `This file is bigger than ${maxFileSize / 1000000}MB so it can't be sent`
	    return (
	        <div className={classes.mediaContainer}>
	            {this.state.media.map(
	                m =>
	                    <div key={m.id} className={`${classes.thumbnailContainer} ${(m.size > maxFileSize ? ' file-too-big' : '')}`}>
	                        {m.size > maxFileSize
	                            ? (
	                            <span className='file-err-msg' title={fileTooBigMessage}>?</span>
	                        )
	                            : null}
	                        {m.uploadStatus === 'failed'
	                            ? (
	                            <span className='file-err-msg' title='File upload failed'>?</span>
	                        )
	                            : null}
	                        <img className='remove-file-button' src={xBtn} alt='X icon' onClick={() => this.removeFile(m)} />
	                        <ThumbnailItem
	                            notClickable		= {true}
	                            mimetype			= {m.type}
	                            source				= {m.url}
	                            filename			= {m.filename || ''}
	                            location			= 'send-message'
	                            uploadStatus		= {m.uploadStatus}
	                            id					= {m.id}
	                            retryUploadMedia	= {this.retryUploadMedia.bind(this)}
	                        />
	                    </div>
	            )}
	        </div>
	    )
	}

	onDragOver (e) {
	    // console.log('onDragOver called')
	    e.stopPropagation()
	    e.preventDefault()

	    const selectedFromNumberFeatures = this.props.selectedExtensionPhoneNumbersFeatures[this.state.from_number]
	    const isMediaEnabled = selectedFromNumberFeatures && selectedFromNumberFeatures.includes('media')
	    if (!this.state.from_number || !isMediaEnabled) {
	        return
	    }

	    if (!this.state.dragOver) {
	        this.setState({
	            dragOver: true
	        })
	    }
	}

	onDragLeave (e) {
	    // console.log('onDragLeave called')
	    e.stopPropagation()
	    e.preventDefault()

	    const selectedFromNumberFeatures = this.props.selectedExtensionPhoneNumbersFeatures[this.state.from_number]
	    const isMediaEnabled = selectedFromNumberFeatures && selectedFromNumberFeatures.includes('media')
	    if (!this.state.from_number || !isMediaEnabled) {
	        return
	    }

	    if (this.state.dragOver) {
	        this.setState({
	            dragOver: false
	        })
	    }
	}

	onDrop = files => {
	    // console.log('onDrop called')
	    const selectedFromNumberFeatures = this.props.selectedExtensionPhoneNumbersFeatures[this.state.from_number]
	    const isMediaEnabled = selectedFromNumberFeatures && selectedFromNumberFeatures.includes('media')
	    if (!this.state.from_number || !isMediaEnabled) return

	    if (this.state.dragOver) this.setState({ dragOver: false })
	    const compressCalls = files.map(f => this.addFileToState(f))
	    Promise.all([...compressCalls]).then(files => { /* console.log('ZAVRSI') */ })
	}

	addFileToState = file => {
	    const fileId = generateRandomString(30)
	    const tempFileObject = {
	        filename:	file.name,
	        type:	file.type,
	        id:	fileId,
	        uploadStatus:	'attaching'
	    }
	    this.addMedia([tempFileObject])

	    return new Promise(async resolve => {
	        if (file.type.split('/')[0] === 'image' && file.size >= 500000) { file = await imageCompression(file, { maxSizeMB: 0.5 }) }

	        const reader		= new FileReader()
	        reader.onload	= () => {
	            const url			= reader.result
	            const data		= url.split('data:')[1]
	            const media		= this.state.media
	            const currentFile	= media.find(f => f.id === tempFileObject.id)
	            if (!currentFile) return resolve(null)
	            Object.assign(currentFile, {
	                url:	url,
	                encoding:	data.split(';')[1].split(',')[0],
	                data:	data.split(';')[1].split(',')[1],
	                size:	file.size
	            })
	            this.validateFile(currentFile)
	            this.setState({ media }, this.validateMedia)
	            gtmDataPush({
	                PDC_Action:	'attachment-added'
	            })
	            resolve(file)
	        }

	        reader.readAsDataURL(file)
	    })
	}

	addMedia = newMedia => {
	    const currentMedia = this.state.media

	    let maxNumberReached = false

	    newMedia.forEach(file => {
	        if (currentMedia.length < 10) {
	            currentMedia.push(file)
	        } else if (!maxNumberReached) {
	            maxNumberReached = true
	            const errorMessages = this.state.errorMessages
	            errorMessages.maxFilesNumber = 'Max number of files in 1 message is 10'
	            this.setState({ errorMessages })
	            setTimeout(() => {
	                delete errorMessages.maxFilesNumber
	                this.setState({ errorMessages })
	            }, 5000)
	        }
	    })
	    this.setState({ media: currentMedia }, this.validateMedia)
	}

	onPaste = e => {
	    // console.log('onPaste called')
	    e.stopPropagation()

	    if (!e.clipboardData || !e.clipboardData.files) return

	    const files = Array.from(e.clipboardData.files)

	    if (files.length) {
	        const selectedFromNumberFeatures = this.props.selectedExtensionPhoneNumbersFeatures[this.state.from_number]
	        const isMediaEnabled = selectedFromNumberFeatures && selectedFromNumberFeatures.includes('media')
	        if (!this.state.from_number || !isMediaEnabled) return
	    }

	    const compressCalls = files.map(f => this.addFileToState(f))
	    Promise.all([...compressCalls]).then(files => { console.log('ZAVRSI2') })
	}

	validateFile = file => {
	    const maxFileSize = this.getMaxMediaSizeAllowed()[0]
	    file.isValid = !file.size || file.size < maxFileSize
	    return file.isValid
	}

	validateMedia = () => {
	    const [maxFileSize, maxTotalMediaSize] = this.getMaxMediaSizeAllowed()
	    let fileWithInvalidSize = null
	    this.state.media.forEach(m => {
	        m.isValid = !m.size || m.size < maxFileSize
	        if (!m.isValid) fileWithInvalidSize = true
	    })
	    const errorMessages = this.state.errorMessages
	    if (fileWithInvalidSize) {
	        errorMessages.sizeError = `Attachment can't be bigger than ${maxFileSize / 1000000}MB.`
	        this.setState({ errorMessages })
	        return false
	    }
	    let totalSize = 0
	    this.state.media.forEach(m => {
	        totalSize += m.size
	    })
	    if (totalSize > maxTotalMediaSize) {
	        errorMessages.sizeError = `Total size of attachments for ${this.state.from_number} can't be bigger than ${maxTotalMediaSize / 1000000}MB.`
	        this.setState({ errorMessages })
	        return false
	    }

	    if (errorMessages.sizeError) {
	        delete errorMessages.sizeError
	        this.setState({ errorMessages })
	    }

	    return true
	}

	focusTypeMessageField = () => {
	    document.getElementById('type-message-field').focus()
	}

	isTollFreeNumber = phoneNumber => {
	    if (!phoneNumber || !phoneNumber.length) return false
	    phoneNumber = Array.isArray(phoneNumber) ? phoneNumber[0] : phoneNumber
	    const tollFreeNpas = ['800', '833', '844', '855', '866', '877', '888']
	    return tollFreeNpas.includes(phoneNumber.substring(2, 5))
	}

	sendMessageClicked = () => {
	    this.sendMessage(null, null, 'send-button')
	    this.focusTypeMessageField()
	}

	toggleAttachHover = attachHovered => this.setState({ attachHovered })

	renderAttachButton = (open, getInputProps, canAttach) => {
	    const disabled = !canAttach
	    const { classes } = this.props
	    return (
	        <span
	            id				= 'attach-button'
	            className		= {`${classes.fileInputButton} ${!canAttach ? 'not-allowed' : ''}`}
	            onClick			= {canAttach ? open : () => {}}
	            onMouseEnter	= {() => this.toggleAttachHover(true)}
	            onMouseLeave	= {() => this.toggleAttachHover(false)}
	        >
	            <AttachIcon disabled={!canAttach ? 1 : 0} hover={this.state.attachHovered ? 1 : 0} className={classes.buttonIcon}/>
	            <input type='file' {...getInputProps()} disabled={disabled} />
	        </span>
	    )
	}

	toggleSendHover = sendButtonHovered => this.setState({ sendButtonHovered })

	render () {
	    const isSendingDisabled				= (!this.state.text.trim() && this.state.media.length <= 0) || this.state.show_cant_send_message_error || this.state.blockSendMessage
	    const noFromNumber					= !this.state.from_number
	    const selectedFromNumberFeatures		= this.props.selectedExtensionPhoneNumbersFeatures[this.state.from_number]
	    const isMediaEnabled					= !noFromNumber ? (selectedFromNumberFeatures && selectedFromNumberFeatures.includes('media')) : false
	    const isGroupEnabled					= selectedFromNumberFeatures && selectedFromNumberFeatures.includes('group')
	    const showCantSendGroupMessagesText	= !noFromNumber && !isGroupEnabled && this.props.participantNumbers.length > 2
	    const isUploadingMedia				= this.state.media.find(m => m.isValid && m.uploadStatus !== 'uploaded')

	    const errorMessages	= this.state.errorMessages
	    const errors			= Object.keys(errorMessages)
	    let errorMessage	= ''
	    let hasMediaError	= false
	    if (!errors.length) {
	        if (noFromNumber) {
	            errorMessage = 'No number connected to this conversation'
	        } else if (showCantSendGroupMessagesText) {
	            errorMessage = 'Can\'t send group messages'
	        }
	    } else {
	        hasMediaError	= true
	        errorMessage	= errorMessages[errors.pop()]
	    }

	    const { classes } = this.props

	    const canSend		= !isSendingDisabled && !errorMessage && !isUploadingMedia && !this.props.isOffline
	    const canAttach	= !this.blockSendMessage && !noFromNumber && isMediaEnabled

	    return (
	        <Dropzone onDrop={this.onDrop} disabled={!isMediaEnabled || showCantSendGroupMessagesText}>
	            {({ getRootProps, getInputProps, isDragActive, open }) => {
	                return (
	                    <div
	                        {...getRootProps({ onClick: event => event.preventDefault() })}
	                        onClick={e => e.preventDefault()}
	                        className={`${classNames('dropzone', { 'dropzone--isActive': isDragActive })} ${classes.sendMessageContainer} ${!this.state.dragOver ? '' : ' dragging-over'}`}
	                        style={this.props.style}
	                    >

	                        {isDragActive
	                            ? <div className='drop-area'>
	                                <div>Drop media here</div>
	                            </div>
	                            : null
	                        }

	                        <div className={`${classes.sendMessageContent} ${this.state.blockSendMessage || isDragActive ? 'blur' : ''}`}>

	                            {!isMediaEnabled && this.state.from_number
	                                ? <ErrorTooltip
	                                    title		= {
	                                        this.isTollFreeNumber(this.state.from_number)
	                                            ? 'Sending media to and from Toll Free numbers is currently not available'
	                                            : `Sending media is disabled for ${!noFromNumber ? formatPhoneNumber(this.state.from_number) : ''}`
	                                    }
	                                    placement	= 'top-start'
	                                >
	                                    {this.renderAttachButton(open, getInputProps, canAttach)}
	                                </ErrorTooltip>
	                                : this.renderAttachButton(open, getInputProps, canAttach)}

	                            <TypeMessageField
	                                text		= {this.state.text}
	                                placeholder	= {(noFromNumber || showCantSendGroupMessagesText || this.state.blockSendMessage) ? '' : 'Type your message'}
	                                onChange	= {this.updateTextInput}
	                                disabled	= {this.state.blockSendMessage || noFromNumber || showCantSendGroupMessagesText}
	                                onPaste		= {this.onPaste}
	                                onEnter		= {this.state.blockSendMessage ? () => {} : this.sendMessage.bind(this, null, null, 'enter')}
	                                smallView	= {this.props.smallView}
	                            />

	                            <span
	                                id				= 'send-message-button'
	                                className		= {`${classes.sendMessageButton} ${!canSend ? 'not-allowed' : ''}`}
	                                onClick			= {() => canSend ? this.sendMessageClicked() : () => {}}
	                                title			= {!isUploadingMedia ? 'Send Message' : 'Wait until all media is uploaded'}
	                                onMouseEnter	= {() => this.toggleSendHover(true)}
	                                onMouseLeave	= {() => this.toggleSendHover(false)}
	                            >
	                                <SendIcon disabled={!canSend ? 1 : 0} hover={this.state.sendButtonHovered ? 1 : 0} className={classes.buttonIcon}/>
	                            </span>

	                            <span className={'cant-send-message-error' + (errorMessage ? '' : ' hidden') + (hasMediaError ? ' media-error' : '')}>
	                                {errorMessage}
	                            </span>
	                        </div>
	                        {this.renderInputtedMedia()}
	                    </div>
	                )
	            }}
	        </Dropzone>
	    )
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SendMessage))
