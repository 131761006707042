<template>
    <div data-test-id="contacts-duplications-page">
        <FormInput :label="l.t('app.extension', 'Extension')" :tight="true">
            <ExtensionSelector
                :preselect_default="true"
                @input="duplicate.extensionChanged($event)"
                :value="duplicate.extension"
                :key="!!duplicate.deleting"
                data-test-id="duplications-extension-selector"
            />
        </FormInput>
        <w-loader v-if="duplicate.loading"/>
        <div v-else :class="{'no-transitions': duplicate.pairs && !duplicate.pairs.length}" class="mt-3" :key="duplicate.extension">
            <w-alert
                v-if="duplicate.alert"
                :level="duplicate.alert.level"
                :message="duplicate.alert.message"
                :closable="duplicate.enable_close_alert"
                @closed="duplicate.alert = null"
                clas="mb-3"
            />
            <div v-if="!duplicate.mode">
                <h6 class="w-body-1 my-5" data-test-id="duplications-title">
                    {{ l.t('contacts.duplication-mode-explanation', 'Please select comparison mode') }}
                </h6>
                <v-list>
                      <v-list-item-group
                      >
                        <v-list-item
                          v-for="mode in duplicate.modes"
                          @click="duplicate.mode = mode.value"
                          :key="mode.value"
                          class="pl-0"
                        >
                            <v-list-item-content class="d-flex" :data-test-id="`duplications-mode-${mode.value}`">
                                <p>{{ mode.name }}</p>
                                <span class="grey--text mb-0">{{ mode.explanation }}</span>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </div>
            <div v-else-if="duplicate.total">
                <w-alert level="warning" :message="l.t('contacts.more-than-500-duplicates', 'We have found {} duplicates. Currently loaded: {}.', [duplicate.total, duplicate.items.length])" :closable="false"/>
                <div>
                    <w-btn @click="duplicate.findDuplications()" color="info" data-test-id="duplications-load-more-btn">
                        {{ l.t('app.load-more', 'Load more') }}
                    </w-btn>
                    <w-btn @click="duplicate.makePairs()" color="primary" data-test-id="duplications-proceed-btn">
                        {{ l.t('app.proceed', 'Proceed') }}
                    </w-btn>
                </div>
            </div>
            <div v-else-if="!duplicate.items.length" data-test-id="duplications-no-duplicates">
              {{ l.t('contacts.nothing-found-for-mode', 'There is no duplicates found for \"{}\" comparasion mode.', [duplicate.formatedMode()]) }} <a @click="duplicate.reset()">{{ l.t('contacts.choose-another-mode', 'Choose another mode?') }}</a>
            </div>
            <div v-else-if="duplicate.edit_to_merge" data-test-id="duplications-edit-to-merge">
                <ContactScreen :_contact="duplicate.edit_to_merge" :extension_id="duplicate.extension" :enable_canceling="true" @contactSubmited="duplicate.mergeContact($event)" @canceled="duplicate.edit_to_merge=null" data-test-id="duplications-contact-edit"></ContactScreen>
            </div>
            <div v-else-if="duplicate.pairs.length">
                <h6 v-if="duplicate.pairs.length === 1" class="w-body-2 mb-5" data-test-id="duplications-one-duplicate">
                    {{ l.t(
                        'contacts.has-one-duplicate',
                        'Your extension has 1 pair of duplicates for "{}" comparison mode.',
                        [duplicate.formatedMode()]
                    ) }}
                </h6>
                <h6 v-else class="w-body-1 mb-5" data-test-id="duplications-duplicates-total">
                    {{ l.t(
                        'contacts.has-more-than-one-duplicate',
                        'Your extension has {} pairs of duplicates for "{}" comparison mode.',
                        [duplicate.pairs.length.toLocaleString().replace(/,/g, ' '), duplicate.formatedMode()]
                    ) }}
                </h6>
                <transition name="slide-fade" mode="out-in">
                    <v-row :key="duplicate.pairs.length">
                        <v-col md="6">
                            <DuplicatedContact :contact="duplicate.activePair[0]" :compared="duplicate.activePair[1]" data-test-id="duplications-compare-1"/>
                        </v-col>
                        <v-col md="6">
                            <DuplicatedContact :contact="duplicate.activePair[1]" :second="true" :compared="duplicate.activePair[0]" data-test-id="duplications-compare-2"/>
                        </v-col>
                    </v-row>
                </transition>
                <w-loader v-if="duplicate.merging_loading" size="small" class="mt-3"/>
                <div class="pa-3 d-flex justify-center" v-else>
                    <w-btn @click="duplicate.useThis(0)" class="primary" data-test-id="duplications-use-first-btn">
                        {{ l.t('contacts.use', 'Use') }} #{{ duplicate.activePair[0].id }}
                    </w-btn>
                    <w-btn @click="duplicate.mergeIntoThis(0)" class="ml-3 primary" data-test-id="duplications-use-merge-first-btn">
                        {{ l.t('contacts.merge-into', 'Merge into') }} #{{ duplicate.activePair[0].id }}
                    </w-btn>
                    <w-btn @click="duplicate.pairs.shift()" class="ml-3 primary" data-test-id="duplications-skip-btn">
                        {{ l.t('app.skip', 'Skip') }}
                    </w-btn>
                    <w-btn @click="duplicate.mergeIntoThis(1)" class="ml-3 primary" data-test-id="duplications-merge-second-btn">
                        {{ l.t('contacts.merge-into', 'Merge into') }} #{{ duplicate.activePair[1].id }}
                    </w-btn>
                    <w-btn @click="duplicate.useThis(1)" class="ml-3 primary" data-test-id="duplications-use-second-btn">
                        {{ l.t('contacts.use', 'Use') }} #{{ duplicate.activePair[1].id }}
                    </w-btn>
                </div>
            </div>
            <div v-else data-test-id="duplications-no-more">
              {{ l.t('contacts.no-more-duplicates', 'There is no more duplicates found for \"{}\" comparation mode.', [duplicate.formatedMode()]) }}
              <a @click="duplicate.reset()" data-test-id="duplications-no-more-choose-another">{{ l.t('contacts.choose-another-mode', 'Choose another mode?') }}</a>
            </div>
        </div>
    </div>
</template>

<script>
  import {vueComponent} from 'helpers';
  import l from '../../../libs/lang';
  import ContactScreen from '../../fragments/Contact.vue';
  import FormInput from '../../elements/form/FormInput.vue';
  import ContactDuplicate from '../../../models/ContactDuplicate';
  import DuplicatedContact from '../../elements/DuplicatedContact.vue';
  import ExtensionSelector from '../../fragments/ExtensionSelector.vue';

  export default {
    components: {
      ContactScreen,
      FormInput,
      DuplicatedContact,
      ExtensionSelector,
    },
    data() {
      return {
        l,
        duplicate: new ContactDuplicate(this.$session, vueComponent(this)),
      };
    },
    mounted() {
      this.$parent.$on('userRedirected', (to, from, next) => {
        this.$data.duplicate.handleUserRedirect(to, from, next);
      });
    },
  };
</script>
