import Cachier from 'cachier'
import { formatPhoneNumber, assemble_display_name } from 'formatters'
import { isNumeric } from 'helpers'
import { create_default_contact_groups } from 'phoenix-session-helpers'
import l from '../libs/lang'
import Resource from './Resource'
import SelectorPromisesQueueSingleton from '../libs/SelectorPromisesQueueSingleton'

// TODO
// cache handlers

/**
 *
 */
export default class Autocomplete {
    /**
     * @param {string} mode - mode (phone_numbers, extensions, queues etc.)
     * @param {object} session - Phoenix object
     * @param {number} extension - extension id, so we will search on extension level
     * @param {object} conf - configuration parameters, it contains
     {
       "value": number - id of preselected value,
       "reload": boolean - ignores cache, reloads items
       "multiple": boolean - if component has multiple attr
       "value_prop" - prop of the object that should be returned as value
       "return_object" - puts the whole object as selector value; higher priority that value_prop
     }
     * @param {Function} component_helpers - object, like $emit
     */
    constructor (mode, session, extension, conf = {}, component_helpers) {
        this.component_helpers = component_helpers
        this.session = session
        this.extension = extension
        this.conf = conf
        this.mode = this.choose_mode(mode)
        if (!this.mode) {
            throw new Error('Unsupported mode for autocomplete component')
        }
        this.cachier = new Cachier(this.session.user.id)
        this.response = null
        this.loading = false
        this.search_loading = false
        this.search_timer = null
    }

    /**
     *
     */
    set uri (val) {
        this.mode.uri = val
    }

    /**
     *
     */
    get uri () {
        return this.extension ? `/extensions/${this.extension}${this.mode.uri}` : this.mode.uri
    }

    /**
     *
     */
    get base_uri () {
        return this.uri.split('?')[0]
    }

    /**
     *
     */
    get cache_key () {
        let key = this.mode.name
        if (this.extension) key += `-${this.extension}`
        return `${key}-autocomplete`
    }

    /**
     *
     */
    get value_object () {
        if (!this.response) return null
        if (!this.conf.return_object) {
            return this.response.items.find((x) => x[this.mode.value_prop] === this.conf.value)
        }
        if (this.conf.value_prop === 'id') {
            return this.response.items.find((x) => x.id === this.conf.value)
        }
        const value_stringified = JSON.stringify(this.conf.value)
        return this.response.items.find((x) => JSON.stringify(x) === value_stringified)
    }

    /**
     *
     */
    get autocomplete_items () {
        if (this.response) {
            return this.response.items.map((x) => ({
                id: x.id,
                text: this.mode.text(x),
                value: x[this.conf.value_prop]
            }))
        }
        return []
    }

    /**
     *
     */
    async load_items () {
        this.loading = true
        try {
            if (this.conf.reload) {
                this.cachier.removeItem(this.cache_key)
            }
            let response
            const cache = this.cachier.getItem(this.cache_key)
            if (cache) {
                response = cache
            } else {
                response = await SelectorPromisesQueueSingleton.enqueue(
                    this.uri,
                    () => this.retrieve_items(this.uri)
                )
                if (typeof this.mode.filter === 'function') {
                    response = this.mode.filter(response)
                }
                if (typeof this.mode.sort === 'function') {
                    response.items.sort(this.mode.sort)
                }
            }
            const passed_value = await this.search_for_passed_value(response)
            if (passed_value) response.items.push(passed_value)
            if (!cache) {
                this.cachier.setItem(this.cache_key, response)
            }

            this.response = response
            this.loading = false

            return response
        } catch (err) {
            console.error('Error loading autocomplete items')
            this.loading = false
            throw err
        }
    }

    /**
     * @param {object} response - API response
     */
    async search_for_passed_value (response) {
        let value
        if (
            this.conf.value &&
            typeof this.conf.value === 'object' &&
            this.conf.value[this.conf.value_prop]
        ) {
            value = this.conf.value[this.conf.value_prop]
        } else {
            value = this.conf.value
        }
        if (
            this.conf.value &&
            this.mode.search_missing_value &&
            !response.items.find((x) => x[this.conf.value_prop] === value) &&
            this.conf.value_prop === 'id'
        ) {
            try {
                let search_response = await this.session.get_item(
                    `${this.base_uri}/${value}`
                )
                if (this.mode.search_for_passed_value_cb && typeof this.mode.search_for_passed_value_cb === 'function') {
                    search_response = this.mode.search_for_passed_value_cb(search_response)
                }
                if (search_response) {
                    return search_response
                }
            } catch (err) {
                console.log('Default value not found.')
            }
        }

        return null
    }

    /**
     * @param {string} data - user's field input
     */
    async search (data) {
        if (!data) return null
        if (this.search_timer) clearTimeout(this.search_timer)
        if (!this.search_loading) {
            await new Promise((resolve) => {
                this.search_timer = setTimeout(async () => {
                    this.search_loading = true
                    const search_results = await this.session.get_list(this.mode.search_uri(data))
                    if (search_results.items.length) {
                        this.response.items = this.response.items.concat(search_results.items)
                    }
                    this.search_loading = false
                    resolve()
                }, 500)
            })
        }

        return true
    }

    /**
     * @param {string} uri
     */
    async retrieve_items (uri) {
        let res = null
        if (this.mode.load_all) {
            res = await this.session.get_list_all(uri)
        } else {
            const limit = this.mode.limit || 100
            res = await this.session.get_list(uri, limit)
        }

        if (this.mode.response_callback) {
            res = await this.mode.response_callback(res)
        }

        return res
    }

    /**
     * @param {string} mode
     */
    choose_mode (mode) {
        // ex. of possible values
        // {
        // uri: '/media?filters[type]=hold_music', => uri with filters to retrieve items
        // search_uri: function, accepts search_term, '/routes', => uri for search functionality, ex. /routes?filters[name]=not-empty' retrieves presets, but will break the name search
        // search_for_passed_value_cb: function, accepts api response from .../id. Performs additional check on api reposne, ex. presets and route.name
        // text: (value) => `${value['id'] ? `#${value['id']} ` : ''}${value['name']}`, => display name cb
        // sort: (a, b) => (a['name'] === '(none)' ? -1 : a['name'] - b['name']), => sort cb
        // load_all: false, => careful. this will load all rss from the account
        // model: new Resource(this.session, this.component_helpers, '/media', null), => needed for edit modal
        // edit_route: 'media.show', // if route ius not allowed for the client, wont display the modal popup
        // placeholder: l.t('selector.select-a-music-on-hold', 'Select a music on hold'), => selectgor placeholder
        // playable: '/media', => for rss that can be played
        // filter: (res) => res.items.filter((x) => !x.name.toLowerCase().startsWith('lrdn-')); => filters loaded items
        // search_missing_value: set as false, prevents send api requetst to search for the value of selector and putting it to cahce; can be set as condition: this.value_v > 24 - for hold music not to search for the system built in values
        // response_callback: async (api_response) => return api_response; => api response modifier, ex. if contact-groups are empty create default groups
        // limit: limits the number of items returned by the API, default 100
        // }
        const default_mode_conf = {
            text: (value) => value.name || `#${value.id}`,
            limit: 100,
            load_all: false,
            sort: (a, b) => a.name.localeCompare(b.name),
            search_missing_value: true
        }
        const extensions_conf = {
            ...default_mode_conf,
            uri: '/extensions',
            search_uri: (search_term) => `/extensions?filters[name-or-extension]=contains:${search_term}`,
            text: (value) => `${value.extension}: ${value.name}`,
            sort: (a, b) => a.extension - b.extension,
            model: new Resource(this.session, this.component_helpers, '/extensions', null),
            edit_route: 'extensions.show',
            placeholder: l.t('selector.select-an-extension', 'Select an extension'),
            limit: 200
        }
        const media_conf = {
            ...default_mode_conf,
            uri: '/media',
            search_uri: (search_term) => `/media?filters[name]=contains:${search_term}`,
            text: (value) => `${value.name} ${value.id ? `#${value.id} ` : ''}`,
            sort: (a, b) => (a.name === '(none)' ? -1 : a.name - b.name),
            model: new Resource(this.session, this.component_helpers, '/media', null),
            edit_route: 'media.show',
            placeholder: l.t('selector.select-a-media', 'Select a media'),
            playable: '/media',
            modal_title: l.t('app.media', 'Media'),
            search_missing_value: !Number.isNaN(this.conf.value) && this.conf.value > 24
        }
        const modes = {
            phone_numbers: {
                ...default_mode_conf,
                uri: '/phone-numbers',
                search_uri: (search_term) => `/phone-numbers?filters[name-or-did]=contains:${search_term}`,
                text: (v) => `${formatPhoneNumber(v.phone_number)} / ${v.name}`,
                sort: (a, b) => a.phone_number.localeCompare(b.phone_number),
                placeholder: this.conf.multiple ? l.t('app.select-your-phone-numbers', 'Select your phone numbers') : l.t('app.select-a-phone-number', 'Select a phone number'),
                model: new Resource(this.session, this.component_helpers, '/phone-numbers', null),
                edit_route: 'phone-numbers.show'
            },
            schedules: {
                ...default_mode_conf,
                uri: '/schedules',
                search_uri: (search_term) => `/schedules?filters[name]=contains:${search_term}`,
                text: (value) => `${value.name}`,
                placeholder: this.conf.multiple ? l.t('selector.select-your-schedules', 'Select your schedules') : l.t('selector.select-a-schedule', 'Select a schedule'),
                model: new Resource(this.session, this.component_helpers, '/schedules', null),
                edit_route: 'schedules.show'
            },
            extensions: {
                ...extensions_conf,
                maxlength: '28'
            },
            virtualExtensions: {
                ...extensions_conf,
                uri: '/extensions?filters[virtual]=0',
                search_uri: (search_term) => `/extensions?filters[virtual]=0&filters[name-or-extension]=contains:${search_term}`

            },
            hold_music: {
                ...media_conf,
                uri: '/media?filters[type]=hold_music',
                search_uri: (search_term) => `/media?filters[type]=hold_music&filters[name]=contains:${search_term}`,
                placeholder: l.t('selector.select-a-music-on-hold', 'Select a music on hold'),
                modal_title: l.t('app.music-on-hold', 'Music on hold'),
                maxlength: '80'
            },
            user_hold_music: {
                ...media_conf,
                uri: '/media?filters[type]=hold_music&filters[ownership]=user',
                search_uri: (search_term) => `/media?filters[type]=hold_music&filters[ownership]=user&filters[name]=contains:${search_term}`,
                placeholder: l.t('selector.select-a-music-on-hold', 'Select a music on hold'),
                modal_title: l.t('app.music-on-hold', 'Music on hold')
            },
            greetings: {
                ...media_conf,
                uri: '/media?filters[type]=greeting&filters[ownership]=user',
                search_uri: (search_term) => `/media?filters[type]=greeting&filters[ownership]=user&filters[name]=contains:${search_term}`,
                placeholder: l.t('selector.select-a-greeting', 'Select a greeting'),
                modal_title: l.t('app.greeting', 'Greeting'),
                maxlength: '80'
            },
            queues: {
                ...default_mode_conf,
                uri: '/queues',
                search_uri: (search_term) => `/queues?filters[name]=contains:${search_term}`,
                model: new Resource(this.session, this.component_helpers, '/queues', null),
                edit_route: 'queues.show',
                placeholder: l.t('selector.select-a-queue', 'Select a queue'),
                modal_title: l.t('app.queue', 'Queue'),
                maxlength: '30'
            },
            menus: {
                ...default_mode_conf,
                uri: '/menus',
                search_uri: (search_term) => `/menus?filters[name]=contains:${search_term}`,
                sort: (a, b) => {
                    if (!a.name) {
                        return 0
                    }
                    return a.name.localeCompare(b.name)
                },
                model: new Resource(this.session, this.component_helpers, '/menus', null),
                edit_route: 'menus.show',
                placeholder: l.t('selector.select-a-menu', 'Select a menu'),
                modal_title: l.t('app.menu', 'Menu'),
                maxlength: '30'
            },
            presets: {
                ...default_mode_conf,
                uri: '/routes?filters[name]=not-empty',
                search_uri: (search_term) => `/routes?filters[name]=contains:${search_term}`,
                search_for_passed_value_cb: (res) => {
                    if (!res) return null
                    if (!res.name) return null
                    return res
                },
                sort: (a, b) => {
                    if (!a.name) {
                        return -1
                    }
                    return a.name.localeCompare(b.name)
                },
                model: new Resource(this.session, this.component_helpers, '/routes', null),
                edit_route: 'routes.show',
                placeholder: l.t('selector.select-a-preset', 'Select a preset'),
                modal_title: l.t('app.preset', 'Preset')
            },
            live_answer: {
                ...default_mode_conf,
                uri: '/live-answer',
                search_uri: (search_term) => `/live-answer?filters[name]=contains:${search_term}`,
                text: (value) => {
                    let { name } = value
                    if (!value.enabled) {
                        name += ` (${l.t('app.disabled', 'Disabled')})`
                    }

                    return name
                },
                model: new Resource(this.session, this.component_helpers, '/live-answer', null),
                edit_route: 'live-answer.show',
                placeholder: l.t('selector.select-a-script', 'Select a script'),
                modal_title: l.t('app.receptionist-script', 'Receptionist script'),
                maxlength: '256'
            },
            contacts: {
                ...default_mode_conf,
                text: (v) => assemble_display_name(v),
                uri: '/contacts',
                search_uri: (search_term) => `/extensions/${this.extension}/contacts?filters[name]=contains:${search_term}`,
                sort: (a, b) => a.id - b.id,
                model: new Resource(this.session, this.component_helpers, '/contacts', null),
                edit_route: 'contacts.show',
                placeholder: l.t('selector.select-a-contact', 'Select a contact'),
                modal_title: l.t('app.contact', 'Contact')
            },
            groups: {
                ...default_mode_conf,
                uri: '/contact-groups',
                search_uri: (search_term) => `/extensions/${this.extension}/contact-groups?filters[name]=contains:${search_term}`,
                model: new Resource(this.session, this.component_helpers, '/contact-groups', null),
                edit_route: 'groups.index',
                placeholder: l.t('selector.select-a-group', 'Select a group'),
                modal_title: l.t('app.group', 'Group'),
                response_callback: async (res) => {
                    if (!res.items.length) {
                        res.items = await create_default_contact_groups(this.session, this.extension)
                    }
                    return res
                }
            }
        }
        if (this.session.user && this.session.user.account && this.session.user.account.features && this.session.user.account.features['trunks-enabled']) {
            modes.trunks = {
                ...default_mode_conf,
                uri: '/trunks',
                model: new Resource(this.session, this.component_helpers, '/trunks', null),
                edit_route: 'trunks.show',
                placeholder: l.t('selector.select-a-trunk', 'Select a trunk'),
                modal_title: l.t('app.trunk', 'Trunk')
            }
        }
        if (modes[mode]) {
            return {
                name: mode,
                ...modes[mode]
            }
        }
        return null
    }

    /**
     * @param {object} new_value - same as API res.items object
     */
    add_to_cache (new_value) {
        const cache = this.cachier.getItem(this.cache_key)
        if (cache) {
            cache.items.push(new_value)
            if (typeof this.mode.sort === 'function') {
                cache.items.sort(this.mode.sort)
            }
            this.cachier.setItem(this.cache_key, cache)
            return true
        }
        return false
    }

    /**
     * @param {object} new_value - same as API res.items object
     */
    update_cache (new_value) {
        if (!new_value.id) return null
        const cache = this.cachier.getItem(this.cache_key)
        if (cache) {
            const index = cache.items.findIndex((x) => x.id === new_value.id)
            if (index > -1) {
                cache.items[index] = new_value
                this.cachier.setItem(this.cache_key, cache)
                return true
            }
        }
        return false
    }

    /**
     * @param {object} item - searched by ID
     */
    remove_from_cache (item) {
        if (!item.id) return null
        const cache = this.cachier.getItem(this.cache_key)
        if (cache) {
            const index = cache.items.findIndex((x) => x.id === item.id)
            if (index > -1) {
                cache.items.splice(index, 1)
                this.cachier.setItem(this.cache_key, cache)

                return true
            }
        }

        return false
    }

    /**
     *
     * @param {number, string} val
     */
    get_from_cache (val) {
        if (!val) return null
        const cache = this.cachier.getItem(this.cache_key)
        if (cache) {
            if (isNumeric(val)) {
                const id = val
                const index = cache.items.findIndex((x) => x.id === id)
                if (index > -1) {
                    return cache.items[index]
                }
                return null
            }
            return cache
        }

        return null
    }
}
