<template>
    <div>
        <APIAutocomplete
            v-bind="$attrs"
            @input="on_selector_change"
            :return_object="true"
            :before_list="before_list"
            :suppress_no_items_alert="true"
            :key="`selector-create-${show_modal}`"
        >
            <!-- Pass on all named slots -->
            <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
            <!-- Pass on all scoped slots -->
            <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope"><slot :name="slot" v-bind="scope"/></template>
        </APIAutocomplete>
        <FormModal
            v-if="mode && modes[mode]"
            v-model="show_modal"
            :with_x="true"
            @close="show_modal = false"
            width="1000"
        >
            <div class="w-h5 info--text text--darken-1 mb-5">
            {{ modes[mode].title }}
            </div>
            <Menu
                v-if="mode === 'menus'"
                :full_menu="true"
                @created="on_created"
                @click:cancel="show_modal = false"
                data-test-id="selector-create-menu"
            />
            <Queue
                v-if="mode === 'queues'"
                @created="on_created"
                data-test-id="queues-selector-create"
            />
            <LiveReceptionistCreate
                v-if="mode === 'live_answer'"
                @created="on_created"
                @click:cancel="show_modal = false"
                data-test-id="live-answer-selector-create"
            />
            <Media
                v-if="mode === 'greetings'"
                force_type="greeting"
                @created="on_created"
                data-test-id="live-answer-selector-create"
            />
        </FormModal>
  </div>
</template>
<script>
    import l from '../../libs/lang';
    import APIAutocomplete from './form/APIAutocomplete.vue';
    import FormModal from './modal/FormModal.vue';
    import { LiveReceptionistCreate } from 'live-receptionist-components';

    export default {
        components: {
            FormModal,
            APIAutocomplete,
            LiveReceptionistCreate,
            Menu: () => import('../screens/menus/create.vue'),
            Queue: () => import('../screens/queues/create.vue'),
            Media: () => import('../screens/media/create.vue'),
        },
        data() {
            return {
                l,
                mode: this.$attrs.mode,
                show_modal: false,
                before_list: [],
                modes: {
                    menus: {
                        title: l.t('app.create-a-menu', 'Create a menu'),
                    },
                    queues: {
                        title: l.t('app.create-a-queue', 'Create a queue'),
                    },
                    live_answer: {
                        title: l.t('app.create-a-live-receptionist-script', 'Create a receptionist script'),
                    },
                    greetings: {
                        title: l.t('greeting-selector.new', 'Create a new greeting'),
                    },
                }
        };
    },
    created() {
        if (Object.keys(this.$data.modes).includes(this.$attrs.mode)) {
            this.$data.before_list = [
                { value: this.$attrs.mode, text: this.$data.modes[this.$attrs.mode].title },
                { divider: true },
            ];
            if (Array.isArray(this.$attrs.before_list) && this.$attrs.before_list.length) {
                this.$data.before_list = this.$data.before_list.concat(this.$attrs.before_list);
            }
        }
    },
    methods: {
        on_selector_change(data) {
            if (Object.keys(this.$data.modes).includes(data)) {
                this.$data.mode = data;
                this.$data.show_modal = true;
            } else {
                this.$emit('input', data);
            }
        },
        on_created(data) {
            setTimeout(() => {
                this.$data.show_modal = false;
                this.$emit('input', data);
            }, 1000);
        },
    },
};
</script>
