<template>
	<router-link
	  tag="v-list-item-content"
	  :to="route"
	  class="list-row"
	  data-test-id="list-item-link"
	>
	  <div class="d-flex">
	    <span class="mr-5 list-link" data-test-id="list-item-title">{{ title }}</span>
	    <w-tooltip v-if="item && item.route_analysis && item.route_analysis.vulnerabilities" top>
	      <template v-slot:activator>
			<v-badge
		        color="error"
		        overlap
		        offset-y="6"
		        :content="item.route_analysis.vulnerabilities.count"
		        class="cursor-pointer mt-n4"
		        data-test-id="list-item-vulnerabilities-badge"
			/>
	      </template>
	      <span
	        v-html="item.route_analysis.vulnerabilities.html"
	      ></span>
	    </w-tooltip>
	    <slot></slot>
	  </div>
	</router-link>
</template>

<script>
export default {
	props: {
		route: {
			required: true,
			type: Object,
		},
		title: {
			required: true,
			type: String,
		},
		item: {
			required: false,
			type: Object,
		}
	},
	data() {
		return {};
	},
};
</script>
