export function bridgeId(state = null, action) {
	switch (action.type) {
		case 'UPDATE_BRIDGE_ID':
			return action.bridgeId
		default:
			return state
	}
}

export function selectedExtensionPhoneNumbersFeatures(state = null, action) {
	switch (action.type) {
		case 'UPDATE_SELECTED_EXTENSION_PHONE_NUMBERS_FEATURES':
			if (action.features) {
				return action.features
			}
			return {}
		default:
			return state || []
	}
}

export function currentExtension(state = null, action) {
	switch (action.type) {
		case 'SWITCH_EXTENSION':
			return JSON.parse(JSON.stringify(action.extension))
		default:
			return state
	}
}