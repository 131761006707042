<template>
    <component v-if="user === 'nxt' && user_is_nxt === true" :is="tag" v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </component>
    <component v-else-if="user === 'classic' && user_is_nxt === false" :is="tag" v-bind="$attrs" v-on="$listeners">
        <slot></slot>
    </component>
</template>
<script>
import {is_nxt} from 'phoenix-session-helpers';

export default {
    props: {
        tag: {
            type: String,
            default: 'div',
        },
        user: {
            type: String,
            required: true,
            validator: (val) => ['classic', 'nxt'].includes(val),
        },
    },
    data() {
        return {
            show: false,
            loading: false,
            user_is_nxt: null,
        };
    },
    async created() {
        this.$data.loading = true;
        try {
            this.user_is_nxt = await is_nxt(this.$session);
        } catch (err) {
            this.$data.user_is_nxt = null;
            console.log(err);
            console.error('We encountered an error while checking the account');
        }
        this.$data.loading = false;

        return true;
    },
    watch: {
        loading(val) {
            this.$emit('loading', val);
        }
    },
};
</script>
