<template>
	<IndexPage
	  :resource="api"
	  :create_route="'api-requests'"
	  class="scheduld-requests list-page"
	>
	  <template v-slot:filters>
		<FormInput :label="l.t('api-requests.scheduled-between', 'Scheduled between')" class="large-filter-field">
			<div class="d-flex">
				<DatetimePicker
					:datetime="api.filters.start"
					@input="api.filters.start = $event"
					date_nudge_top="-218"
					time_nudge_top="-320"
					data-test-id="scheduled-req-filters-start-input"
				/>
				<span class="px-2 my-auto">&mdash;</span>
				<DatetimePicker
					:datetime="api.filters.end"
					date_nudge_top="-218"
					time_nudge_top="-320"
					@input="api.filters.end = $event"
					data-test-id="scheduled-req-fitlers-end-input"
				/>
			</div>
		</FormInput>
		<FormInput :label="l.t('api-requests.processed-between', 'Processed between')" class="large-filter-field">
			<div class="d-flex">
				<DatetimePicker
					:datetime="api.filters.processed_at_start"
					@input="api.filters.processed_at_start = $event"
					time_nudge_top="-252"
					date_nudge_top="-150"
					data-test-id="scheduled-req-filters-processed-start-input"
				/>
				<span class="px-2 my-auto">&mdash;</span>
				<DatetimePicker
					:datetime="api.filters.processed_at_end"
					@input="api.filters.processed_at_end = $event"
					time_nudge_top="-252"
					date_nudge_top="-150"
					data-test-id="scheduled-req-processed-end-input"
				/>
			</div>
		</FormInput>
		<FormInput class="large-filter-field">
			<w-radio-group
		      v-model="api.filters.is_processed"
		      row
		      hide-details='auto'
		    >
		      <v-radio
		        :label="l.t('api-requests.only-scheduled', 'Only scheduled')"
		        :value="0"
				hide-details='auto'
				data-test-id="scheduled-req-filters-only-scheduled-radio"
		      ></v-radio>
		      <v-radio
		        :label="l.t('api-requests.only-processed', 'Only processed')"
		        :value="1"
				hide-details='auto'
				data-test-id="scheduled-req-filters-only-processed-radio"
		      ></v-radio>
		      <v-radio
		        :label="l.t('app.all', 'All')"
		        :value="null"
				hide-details='auto'
				data-test-id="scheduled-req-filters-all-radio"
		      ></v-radio>
		    </w-radio-group>
		</FormInput>
	  </template>
	  <template v-slot:list>
	    <v-simple-table data-test-id="scheduled-req-table">
			<thead data-test-id="table-head">
				<tr data-test-id="head-row">
					<th data-test-id="checkbox" class="select-table-item"><br/></th>
					<th data-test-id="id">{{ l.t('app.id', 'ID') }}</th>
					<th data-test-id="request">{{ l.t('api-requests.request', 'Request') }}</th>
					<!-- <th>{{ l.t('api-requests.request-body', 'Request body') }}</th> -->
					<th data-test-id="tag">{{ l.t('api-requests.tag', 'Tag') }}</th>
					<th data-test-id="scheduled-at">{{ l.t('api-requests.scheduled-at', 'Scheduled at') }}</th>
					<!-- <th>{{ l.t('api-requests.notification-policy', 'Notification policy') }}</th> -->
					<!-- <th>{{ l.t('api-requests.notification-email', 'Notification email') }}</th> -->
					<th data-test-id="status">{{ l.t('api-requests.processed-status', 'Processed status') }}</th>
					<th data-test-id="code">{{ l.t('api-requests.response-code', 'Response code') }}</th>
					<!-- <th>{{ l.t('api-requests.response-body', 'Response body') }}</th> -->
					<!-- <th>{{ l.t('app.comment', 'Comment') }}</th> -->
				</tr>
			</thead>
			<tbody data-test-id="">
				<tr v-for="(item, i) in api.items" :data-test-id="`scheduled-req-item-${i}-row`">
					<td class="select-table-item" data-test-id="checkbox">
						<w-checkbox
							class="multiple-select-checkbox"
							v-model="item.selected"
							@change="api.checkSubmission()"
							:disabled="!!item.processed_status"
							:key="`api-requests-item-${item.id}-${item.selected}`"
							hide-details="auto"
							:data-test-id="`scheduled-req-item-${i}-checkbox`"
						/>
					</td>
					<td :data-test-id="`scheduled-req-item-${i}-id-link`">
						{{ item.id }}
					</td>
					<td data-test-id="uri">
						<router-link :to="{ name: 'api-requests.show', params: { id: item.id } }" class="list-link">
							<span class="mr-2">{{ item.request_method }}</span> {{ item.request_uri }}
						</router-link>
					</td>
					<!-- <td>{{ item.request_body }}</td> -->
					<td data-test-id="tag">{{ item.tag || '—' }}</td>
					<td>{{ (new Date(item.scheduled_at * 1000)).toLocaleString() }}</td>
					<!-- <td>{{ item.notification_policy }}</td> -->
					<!-- <td>{{ item.notification_email }}</td> -->
					<td :data-test-id="`scheduled-req-item-${i}-status`">{{ item.processed_status ? item.processed_status : l.t('api-requests.still-not-processed', 'Still not processed') }}</td>
					<td :data-test-id="`scheduled-req-item-${i}-status-code`">{{ item.response_code ? item.response_code : '—' }}</td>
					<!-- <td>{{ item.response_body }}</td> -->
					<!-- <td>{{ item.comment }}</td> -->
				</tr>
			</tbody>
	    </v-simple-table>
	  </template>
	</IndexPage>
</template>

<script>
import l from '../../../libs/lang';
import IndexPage from '../../elements/IndexPage.vue';
import FormInput from '../../elements/form/FormInput.vue';
import DatetimePicker from '../../elements/form/DatetimePicker.vue';

export default {
	props: ['api'],
	components: {
		IndexPage,
		FormInput,
		DatetimePicker,
	},
	data() {
		return {
			l,
		};
	},
	mounted() {
	  this.$parent.$on('userRedirected', (to, from, next) => {
	    this.$data.device.handleUserRedirect(to, from, next);
	  });
	},
};
</script>
