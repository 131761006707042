import React, { useState, useRef, useCallback, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core'
import { CloseIcon, SettingsIcon } from 'svg-icons'
import Switch from 'switch'
import styles from './styles'
import { useToggle } from 'hooks'
import { db } from 'mypdc-dexie'
import Typography from 'typography'
import IconButton from 'icon-button-mui'
import RingtoneSelector from './RingtoneSelector'
import { MicrophoneSelector, SpeakerSelector, VolumeControl } from './AudioSources'
import { FeatureEventsContext } from 'providers/src'

const useStyles = makeStyles(styles)

type SettingItemProps = { pirmaryLabelId?: string, secondaryLabelId?: string, vertical?: boolean, children: JSX.Element }
const SettingItem = (props: SettingItemProps): JSX.Element => {
    const classes = useStyles()
    const { pirmaryLabelId, secondaryLabelId, vertical } = props
    const hasLabel = !!pirmaryLabelId || !!secondaryLabelId
    return (
        <div className={`${classes.settingItem} ${vertical ? 'vertical' : ''} disable-dragging`}>
            <div className={`labels ${!hasLabel ? 'hidden' : ''}`}>
                {!!pirmaryLabelId && <Typography variant='body1' remoteConfigID={pirmaryLabelId}/>}
                {!!secondaryLabelId && <Typography variant='body2' remoteConfigID={secondaryLabelId}/>}
            </div>
            {props.children}
        </div>
    )
}

type SettingsSectionProps = { titleId?: string, children: React.ReactElement | React.ReactElement[] }
const SettingsSection = (props: SettingsSectionProps): JSX.Element => {
    return (
        <div className='settings-section'>
            {!!props.titleId && <Typography variant='body2' remoteConfigID={props.titleId}/>}
            <div className='settings-section-body'>
                {props.children}
            </div>
        </div>
    )
}

/***/
const Preferences = ({ move }: { move: boolean }): JSX.Element => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null)
    const preferencesAnchorRef = useRef<HTMLDivElement>(null)
    const [callWaitingBeep, toggleCallWaitingBeep] = useToggle(true)
    const [ringbackTone, toggleRingbackTone] = useToggle(true)
    const featureEventsContext = useContext(FeatureEventsContext)

    useEffect(() => {
        db.mypdc.get({ key: 'callWaitingBeep' }).then(value => {
            if (value && !value.value) toggleCallWaitingBeep(false)
        })
        db.mypdc.get({ key: 'ringbackTone' }).then(value => {
            if (value && !value.value) toggleRingbackTone(false)
        })
    }, [])

    const handleCallWaitingBeepClick = useCallback((): void => {
        toggleCallWaitingBeep(currentValue => {
            const newValue = !currentValue
            db.mypdc.get({ key: 'callWaitingBeep' }).then(value => {
                if (value?.id) db.mypdc.update(value.id, { value: newValue })
                else db.mypdc.add({ key: 'callWaitingBeep', value: newValue })
            })
            return newValue
        })
    }, [callWaitingBeep])

    const handleRingbackToneClick = useCallback((): void => {
        toggleRingbackTone(currentValue => {
            const newValue = !currentValue
            db.mypdc.get({ key: 'ringbackTone' }).then(value => {
                if (value?.id) db.mypdc.update(value.id, { value: newValue })
                else db.mypdc.add({ key: 'ringbackTone', value: newValue })
            })
            return newValue
        })
    }, [ringbackTone])

    return (
        <>
            <div
                className = {`${classes.preferencesIcon} ${move ? 'move' : ''} disable-dragging`}
                data-test-id = 'softphone-preferences-button'
                onClick = {() => {
                    featureEventsContext.pushEvent({ appName: 'softphone', key: 'preferences-open-button-click' })
                    setAnchorEl(preferencesAnchorRef.current)
                }}
            >
                <SettingsIcon/>
            </div>
            <div className={classes.preferencesAnchor} ref={preferencesAnchorRef}/>
            {anchorEl && (
                <div className={classes.preferences}>
                    <IconButton
                        variant = 'contained-light'
                        size = 'small'
                        color = 'secondary'
                        onClick = {() => {
                            featureEventsContext.pushEvent({ appName: 'softphone', key: 'preferences-close-button-click' })
                            setAnchorEl(null)
                        }}
                        classes = {{ root: `${classes.preferencesCloseButton} disable-dragging` }}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <div className='settings'>

                        <SettingsSection titleId='softphone_preference_standard_tones_title'>
                            <SettingItem
                                pirmaryLabelId = 'softphone_preferences_call_waiting_beep_primary_label'
                                secondaryLabelId = 'softphone_preferences_call_waiting_beep_secondary_label'
                            >
                                <Switch
                                    checked={callWaitingBeep}
                                    onChange={() => {
                                        featureEventsContext.pushEvent({ appName: 'softphone', key: 'call-waiting-beep-toggle', value: !callWaitingBeep })
                                        handleCallWaitingBeepClick()
                                    }}
                                    name='call-waiting-beep'
                                    value={callWaitingBeep}
                                />
                            </SettingItem>

                            <SettingItem
                                pirmaryLabelId = 'softphone_preferences_ringback_tone_primary_label'
                                secondaryLabelId = 'softphone_preferences_ringback_tone_secondary_label'
                            >
                                <Switch
                                    checked={ringbackTone}
                                    onChange={() => {
                                        featureEventsContext.pushEvent({ appName: 'softphone', key: 'ringback-tone-toggle', value: !ringbackTone })
                                        handleRingbackToneClick()
                                    }}
                                    name='ring-out-sound'
                                    value={ringbackTone}
                                />
                            </SettingItem>
                        </SettingsSection>

                        <SettingsSection titleId='softphone_preferences_audio_sources_section_label'>
                            <SettingItem><MicrophoneSelector/></SettingItem>
                            <SettingItem><SpeakerSelector/></SettingItem>
                            <SettingItem vertical pirmaryLabelId='softphone_preferences_volume_control_label'><VolumeControl/></SettingItem>
                        </SettingsSection>
                        <SettingsSection titleId='softphone_preferences_ringtone_section_label'>
                            <SettingItem><RingtoneSelector/></SettingItem>
                        </SettingsSection>
                    </div>
                </div>
            )}
        </>
    )
}

export default Preferences
