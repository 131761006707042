import React, { Component } from 'react'
import styles from './styles'
import SelectionControl from 'selection-control'
import TextField from 'pdc-text-field'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { withStyles } from '@material-ui/core'
import { Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
/**
 * @deprecated - Use newer version [Menu](/#menu-1)
 */
class Menu extends Component {
    constructor (props) {
        super(props)
        this.wrapperRef = React.createRef()
        this.inputRef = React.createRef()
        this.buttonContentRef = React.createRef()
        this.itemsWrapperRef = React.createRef()
        this.state = {
            flipped: false,
            expanded: false,
            inputValue: this.getDefaultText(),
            selectedValues: this.getDefaultVal(),
            shownElementValues: []
        }
    }

    getDefaultText = () => {
        const { variant, items } = this.props
        const defaultVal = this.props.default
        // NOTE: The inputValue (for which we call this function) is used only in case variant is 'input'
        if ((!defaultVal && defaultVal !== 0) || variant !== 'input') return ''
        const item = items.find(i => i.value === defaultVal)
        return this.itemToString('', item)
    }

    getDefaultVal = () => {
        let defaultVal = this.props.default
        if (!defaultVal && defaultVal !== 0) defaultVal = []
        return Array.isArray(defaultVal) ? defaultVal : [defaultVal]
    }

    componentDidMount () {
        this.setButtonContentWidth()
    }

    componentDidUpdate (prevProps, prevState) {
        this.setFlipped(prevState)

        // Change the selection if the menu is controlable
        this.changeSelection(prevProps)

        // Set button content width
        const prevSelectedValues = prevState.selectedValues
        const selectedValues = this.state.selectedValues
        const prevItems = prevProps.items
        const currentItems = this.props.items
        if (!this.areArraysSame(prevSelectedValues, selectedValues) || !this.areArraysSame(prevItems, currentItems)) return this.setButtonContentWidth()

        // On open scroll to the selected item (if any)
        if (!prevState.expanded && this.state.expanded && this.state.selectedValues.length) {
            this.scrollToFirstSelection()
        }
    }

    scrollToFirstSelection = () => {
        const itemsWrapperElement = this.itemsWrapperRef ? this.itemsWrapperRef.current : null
        if (!itemsWrapperElement) return
        const firstSelectedValue = this.state.selectedValues[0]
        const firstSelectedElement = Array.from(itemsWrapperElement.children).find(e => e.getAttribute('value') === `${firstSelectedValue}`)
        if (!firstSelectedElement) return
        let allignOffset = (itemsWrapperElement.offsetHeight - firstSelectedElement.offsetHeight) / 2
        if (allignOffset < 0) allignOffset = 0
        itemsWrapperElement.scrollTop = firstSelectedElement.offsetTop - allignOffset
    }

    changeSelection = prevProps => {
        if (!this.props.controlable) return
        const variant = this.props.variant
        const prevDefault = prevProps.default
        const thisDefault = this.props.default
        if (['single', 'input'].includes(variant) && prevDefault === thisDefault) return
        if (variant === 'multiple' && this.areArraysSame(prevDefault, thisDefault)) return
        this.setState({
            inputValue: this.getDefaultText(),
            selectedValues: this.getDefaultVal()
        })
    }

    setFlipped = prevState => {
        if (prevState.expanded || !this.state.expanded) return
        let flipped = false
        const viewHeight = window.innerHeight
        const hasChildren = this.wrapperRef.current.children.length
        // Reverse direction if the menu would go offscreen
        if (hasChildren) {
            const lastChild = this.wrapperRef.current.children[this.wrapperRef.current.children.length - 1]
            if (this.wrapperRef.current.getBoundingClientRect().top + this.wrapperRef.current.offsetHeight + lastChild.offsetHeight > viewHeight) {
                flipped = true
            }
        }
        if (flipped !== this.state.flipped) this.setState({ flipped })
    }

    setButtonContentWidth = () => {
        const selectionElement = this.buttonContentRef.current
        if (!selectionElement) return
        const selectionElements = Array.from(selectionElement.children)
        const calculationDivElement = selectionElements.find(e => {
            const classAttr = e.getAttribute('class')
            if (!classAttr) return false
            return classAttr.includes('calculation-div')
        })
        const calculationElements = Array.from(calculationDivElement.children)
        const wrapperWidth = this.getWrapperWidth()
        const selectionWidth = wrapperWidth - 50
        const mappedElements = calculationElements.map(element => {
            const classAttr = element.getAttribute('class')
            if (!classAttr) return false
            return {
                width: this.getElementWidth(element),
                value: classAttr.split('menu-value-')[1].split(' ')[0]
            }
        }).filter(e => e)
        const shownElementValues = []
        let widthSum = 0
        let stop = false
        mappedElements.forEach((me, i) => {
            if (stop) return
            const margin = i < mappedElements.length - 1 ? 5 : 0
            const addWidth = me.width + margin
            // i !== 0 means that the first element will always be shown. If wider than the max-width then it will have ellipsis
            if (i !== 0 && widthSum + addWidth > selectionWidth) return (stop = true)
            widthSum += addWidth
            shownElementValues.push(`${me.value}`)
        })
        this.setState({ shownElementValues })
    }

    areArraysSame = (a1, a2) => {
        return a1 === a2
    }

    getWrapperWidth = () => {
        const wrapperElement = this.wrapperRef.current
        return this.getElementWidth(wrapperElement)
    }

    getElementWidth = element => {
        const styles = window.getComputedStyle(element)
        return parseFloat(styles.width.split('px')[0])
    }

    toggle = (value, e) => {
        if (this.props.disabled) return
        if (value === false && e.currentTarget.parentNode.contains(e.relatedTarget)) return
        const expanded = value !== null ? !value : this.state.expanded
        if (expanded && ['INPUT', 'svg', 'path'].includes(e?.target?.nodeName) && this.props.variant === 'input') return
        this.setState({ expanded: !expanded, flipped: false }, () => {
            if (this.props.variant === 'input') {
                if (expanded) this.inputRef.current.blur()
                else this.inputRef.current.focus()
            }
            if (this.props.emptyOnOpen) this.setState({ inputValue: '' })
        })
    }

    onClickAway = () => this.setState({ expanded: false, flipped: false, inputValue: this.getDefaultText() })

    itemToString = (defaultValue, item) => {
        let inputValue = ''
        if (item) {
            inputValue = item.content
            if (typeof (item.content) !== 'string') {
                if (item.string) inputValue = item.string
                else inputValue = defaultValue
            }
        }
        return inputValue
    }

    onSelect = (event, index) => {
        const { items, variant } = this.props

        const item = items[index]
        let selectedValues = this.state.selectedValues

        if (item.notSelectable) {
            if (item.onClick) item.onClick()
            return
        }

        const itemValue = item.value
        if (variant === 'multiple') {
            if (selectedValues.includes(itemValue)) selectedValues = selectedValues.filter(v => v !== itemValue)
            else selectedValues.push(itemValue)
        } else {
            selectedValues = [itemValue]
        }

        if (!this.props.controlable) {
            const inputValue = this.itemToString(event.target.innerText, item)
            this.setState({ selectedValues: [...selectedValues], inputValue })
        }
        if (variant !== 'multiple') {
            this.setState({ expanded: false })
            if (this.props.emptyOnOpen) this.setState({ inputValue: this.getDefaultText() })
        }

        if (this.props.onChange) {
            const selectedItems = items.filter(item => selectedValues.includes(item.value))
            if (variant === 'multiple') this.props.onChange(selectedItems, index)
            else this.props.onChange(item, index)
        }

        this.setState({ flipped: false })
    }

    onInputChange = inputValue => {
        this.setState({ inputValue })
        if (!inputValue) this.setState({ selectedValues: [] })
    }

    renderButtonContent = () => {
        const { classes, items, label, inputId } = this.props
        const selectedValues = this.state.selectedValues
        const selectedItems = items.filter(i => selectedValues.includes(i.value))
        const hasLabelClass = label ? 'has-label' : ''
        const idProps = inputId ? { id: inputId } : {}

        if (!selectedValues.length) return null
        const renderValue = this.props.renderValue
        const shownElementValues = this.state.shownElementValues
        const notShownItems = selectedValues.length - shownElementValues.length
        const onlyShownClass = selectedItems.length === 1 || (selectedItems[1] && !shownElementValues.includes(`${selectedItems[1].value}`)) ? 'only-shown' : ''

        return (
            <div ref={this.buttonContentRef} className={`content ${hasLabelClass}`} {...idProps}>
                {renderValue
                    ? renderValue(selectedItems)
                    : selectedItems.map((selectedItem, i) => {
                        return shownElementValues.includes(`${selectedItem.value}`)
                            ? (
                                <span className={onlyShownClass} key={i}>
                                    {selectedItem.content}
                                    {selectedItems.length - 1 > i ? ', ' : ''}
                                </span>
                            )
                            : null
                    })
                }
                {(!renderValue && notShownItems) ? <span>+{notShownItems}</span> : null}
                <div className={`${classes.calculationDiv} calculation-div`}>
                    {selectedItems.map((selectedItem, i) => {
                        return (
                            <span className={`menu-value-${selectedItem.value}`} key={i}>
                                {selectedItem.content}
                                {selectedItems.length - 1 > i ? ', ' : ''}
                            </span>
                        )
                    })}
                </div>
            </div>
        )
    }

    getMenuButtonContent = () => {
        const variant = this.props.variant
        const content = variant === 'input' ? this.state.inputValue : this.renderButtonContent()
        return content
    }

    renderButton = () => {
        const { label, placeholder, error, variant, inputId } = this.props
        const expanded = this.state.expanded
        const content = this.getMenuButtonContent()
        const idProps = inputId ? { id: inputId } : {}
        return (
            <TextField
                showBackground = {this.props.showBackground}
                inputRef = {this.inputRef}
                fullWidth = {true}
                flipped = {this.state.flipped}
                label = {label}
                placeholder = {placeholder}
                active = {expanded}
                error = {error}
                content = {content}
                boxShadow = {true}
                editable = {variant === 'input'}
                showExpandIcon = {variant !== 'input'}
                onClick = {e => this.toggle(null, e)}
                onInputChange = {this.onInputChange}
                className = {{ wrapper: 'menu-head' }}
                onBlur = {e => this.toggle(false, e)}
                disabled = {this.props.disabled}
                {...idProps}
            />
        )
    }

    renderCheckbox = (selected, item, index) => {
        let { classes, variant, selectionStyle } = this.props
        if (!selectionStyle) selectionStyle = 'checkbox'
        if (variant !== 'multiple' || selectionStyle !== 'checkbox') return null
        return (
            <SelectionControl
                variant = 'checkbox'
                checked = {selected}
                name = {`mic-${index}}`}
                value = {`mic-${item.value}`}
                disableHover = {true}
                onClick = {() => { /**/ }}
                className = {{ wrapper: classes.checkbox }}
            />
        )
    }

    onMenuItemKeyDown = e => {
        if (e.keyCode === 32 /* Space */ || e.keyCode === 13 /* Enter */) {
            e.preventDefault()
        }
    }

    onMenuItemKeyUp = (e, i) => {
        if (e.keyCode === 32 /* Space */ || e.keyCode === 13 /* Enter */) {
            e.preventDefault()
            this.onSelect(e, i)
        }
    }

    onMenuItemBlur = e => {
        if (e.relatedTarget?.classList && Array.from(e.relatedTarget.classList).includes('menu-item')) return
        if (e.relatedTarget?.parentNode.contains(e.target)) return
        this.toggle(false, e)
    }

    render = () => {
        let { classes, items, variant, selectionStyle, className, fullWidth } = this.props
        const { expanded } = this.state
        const dataTestIdProps = this.props['data-test-id'] ? { 'data-test-id': this.props['data-test-id'] } : {}
        if (!selectionStyle) selectionStyle = 'checkbox'
        let wrapperClassNames = classes.menuWrapper
        let menuItemsWrapperClassNames = this.state.flipped ? 'menu-items-wrapper-flipped' : 'menu-items-wrapper'
        const multyCheckClass = (variant === 'multiple' && selectionStyle === 'checkbox') ? 'multi-check' : ''
        let menuItemClassNames = `menu-item ${multyCheckClass}`
        if (fullWidth) wrapperClassNames += ' full-width'
        if (className) {
            if (className.wrapper) wrapperClassNames += ` ${className.wrapper}`
            if (className.itemsWrapper) menuItemsWrapperClassNames += ` ${className.itemsWrapper}`
            if (className.item) menuItemClassNames += ` ${className.item}`
        }
        const buttonHeight = expanded ? this.wrapperRef.current.children[0].offsetHeight : 52

        return (
            <ClickAwayListener onClickAway={this.onClickAway}>
                <div data-select={this.props.dataAttr || 'select'} ref={this.wrapperRef} className={wrapperClassNames} {...dataTestIdProps}>
                    {this.renderButton()}
                    {expanded
                        ? <div
                            ref = {this.itemsWrapperRef}
                            className = {menuItemsWrapperClassNames}
                            style = {this.state.flipped ? { bottom: buttonHeight } : { top: buttonHeight }}
                            data-test-id = 'menu-items-wrapper'
                        >
                            {items.map((item, i) => {
                                if (!item) return null
                                if (item.type === 'separator') return <hr key={i}/>
                                if (!item.content) return null
                                const inputValueLowerCase = this.state.inputValue.toLowerCase()
                                const itemTextLowerCase = this.itemToString('', item).toLowerCase()
                                if (variant === 'input' && !itemTextLowerCase.includes(inputValueLowerCase)) return null
                                const selectedValues = this.state.selectedValues
                                const isSelected = selectedValues.includes(item.value)
                                const selectedClass = isSelected ? 'selected' : ''
                                const isDisabled = Boolean(item.disabled)
                                const disabledClass = isDisabled ? 'not-allowed' : ''
                                const tooltipTitle = item.tooltip?.title || ''
                                const tooltipPlacement = item.tooltip?.placement || 'top'
                                const dataTestIdProp = { 'data-test-id': item['data-test-id'] || `menu-item-${i}` }
                                return (
                                    <Tooltip key={i} title={tooltipTitle} placement={tooltipPlacement}>
                                        <div
                                            data-menu-item = {item.content}
                                            value = {item.value}
                                            onClick = {e => !item.disabled ? this.onSelect(e, i) : null}
                                            className = {`${menuItemClassNames} ${selectedClass} ${disabledClass}`}
                                            disabled = {isDisabled}
                                            tabIndex = {0}
                                            onKeyDown = {e => !item.disabled ? this.onMenuItemKeyDown(e) : null}
                                            onKeyUp = {e => !item.disabled ? this.onMenuItemKeyUp(e, i) : null}
                                            onBlur = {e => this.onMenuItemBlur(e)}
                                            {...dataTestIdProp}
                                        >{this.renderCheckbox(isSelected, item, i)}{item.content}</div>
                                    </Tooltip>
                                )
                            })}
                        </div>
                        : null}
                </div>
            </ClickAwayListener>
        )
    }
}

Menu.propTypes = {
    /**
     * Material ui classes
     */
    classes: PropTypes.any,
    /**
     * The variant of the `Menu`
     */
    variant: PropTypes.oneOf(['input', 'multiple']),
    /**
     * Array of objects that have Required: `value`, `content`; Optional: `disabled`, `tooltip`
     */
    items: PropTypes.array,
    /**
     * The `value` of the currently selected item
     */
    default: PropTypes.string,
    /**
     * Is it contorlable from above
     */
    controlable: PropTypes.bool,
    /**
     * Should the button have no text on open?
     */
    emptyOnOpen: PropTypes.bool,
    /**
     * Is the field disabled?
     */
    disabled: PropTypes.bool,
    /**
     * Function called on change: function (selectedItem(s), index)
     */
    onChange: PropTypes.func,
    /**
     * Label
     */
    label: PropTypes.string,
    /**
     * Placeholder
     */
    placeholder: PropTypes.string,
    /**
     * Data test id for atomation tests
     */
    'data-test-id': PropTypes.string,
    inputId: PropTypes.string,
    selectionStyle: PropTypes.oneOf(['checkbox']),
    dataAttr: PropTypes.string,
    /**
     * Contains: wrapper, itemsWrapper, item
     */
    className: PropTypes.object,
    /**
     * Should it take full width
     */
    fullWidth: PropTypes.bool,
    /**
     * If you want to render the selected value in the button with custom function
     */
    renderValue: PropTypes.func,
    /**
     * Does it have an error
     */
    error: PropTypes.bool,
    showBackground: PropTypes.bool
}

export default withStyles(styles)(Menu)
