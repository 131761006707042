<template>
    <DefaultModal v-bind="$attrs" v-on="$listeners" max-width="350">
        <template v-slot:title>
            {{l.t('app.same-filters', 'Same filters')}}
        </template>
        {{l.t('', 'You have multiple rules set to "{}". Only first of these will be applied.', [same_filter_name])}}
        <br/>
        {{l.t('app.do-you-want-to-conitnue', 'Do you want to continue?')}}
        <template v-slot:buttons>
            <w-btn color="secondary" @click="$emit('input', false)">{{l.t('app.close', 'Close')}}</w-btn>
            <w-btn @click="$emit('confirmed')" color="primary">{{l.t('app.ok', 'OK')}}</w-btn>
        </template>
    </DefaultModal>
</template>
<script>
import l from '../../../libs/lang';
import DefaultModal from '../modal/DefaultModal.vue';

export default {
    props: {
        filter: {
            requried: true,
            type: Object,
        }
    },
    components: {
        DefaultModal,
    },
    data() {
        return {
            l,
            translations: {
                'all-calls': l.t('app.all-calls-lowercase', 'All calls'),
                schedule: l.t('app.schedule-lowercase', 'schedule'),
                contact_group: l.t('routes.contact-group-lowercase', 'contact-group'),
                contact: l.t('routes.contact-lowercase', 'contact'),
                unknown: l.t('routes.no-valid-caller-id', 'No valid caller ID'),
            }
        };
    },
    computed: {
        same_filter_name() {
            let name = this.$data.translations[this.$props.filter.type];
            const {type} = this.filter;
            if (this.filter[type] && this.filter[type].name) {
                name += ` ${this.filter.name}`;
            } else if (this.filter[type] && this.filter[type].id) {
                name += ` #${this.filter[type].id}`;
            }

            return name;
        }
    }
};
</script>
